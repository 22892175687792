import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  InputGroup,
  Form,
  OverlayTrigger,
  FormControl,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./index.scss";

const PaymentResponse = (props) => {
  
  const { data} = props;
  return (
    <div className="w-100 mt-5">
      <div className="d24" />
      <div className="d24" />
      <div className="d24" />

      <>
        <div className="text-center">
          {data && data.mr.Success ? (
            <img src="/images/rsuccess.svg" alt="" />
          ) : (
            <img src="/images/rfail.svg" alt="" />
          )}

          <p className="txtfail mt-4 resultText" style={{ color: `${data.ResultColour}` }}>
            {data.ResultText}
          </p>
        </div>
        <div className="responseList mt-4">
          <p>
            {data.DescriptionText}
            {/* <Link to="/create_support_ticket">
              <span className="txt-blue">support ticket</span>.
            </Link> */}
          </p>
          <Button
            onClick={() => {
              if (data.mr.Success) {
                props.isClose();
              } else {
                props.setFields();
              }
            }}
            className="w-100 blubtn mt-4"
          >
            {data.ButtonText}
          </Button>
          <div className="d24" />
          <div className="d24" />
          <div className="d24" />
          <div className="d24" />
        </div>
      </>

    </div>
  );
};

export default PaymentResponse;
