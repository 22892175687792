import React, { useState, useEffect, useRef } from "react";
import Header from "../Header";
import WelcomeModal from "./Action_center_modals/WelcomeModal";
import ActionCenterModal from "./Action_center_modals/ActionCentreSettings";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ContentLoader from "react-content-loader";
import "./test.scss";
import "./index.scss";
import {
  Col,
  Row,
  Button,
  Form,
  InputGroup,
  Dropdown,
  ListGroup,
  Badge,
  Spinner,
} from "react-bootstrap";
import { tabnames } from "./data";
import ResetModal from "./Action_center_modals/ResetModal";
import DropdownMenu from "./dropdown";
import DatePicker from "../Theme/datepicker";
import {
  // AddRemoveSecurityPack as getMoreTasks,
  AddRemoveSecurityPack as ArchiveStatus,
  getAPIData,
  getScenarioPacks as deleteTaskApi,
  getScenarioPacks as deleteSectionApi,
  getScenarioPacks as getComments,
  getScenarioPacks as deleteComment,
  getScenarioPacks as deleteStatusApi,
  // getScenarioPacks as SynctaskApi,
  postRequest,
  // postRequest as _UpdateTask,
} from "../../Utils/commonApi";
import { useAuth } from "../context/Auth";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import ImageWithInitials from "../../Utils/ImageWithInitials";
import { TrimString } from "../../Utils/StringTrimmer";
import ActionCenterHomePageLoader from "./ActionCenterHomePageLoader";
import SectionTabLoader from "./SectionTabLoader";
import { useDispatch } from "react-redux";
import { setactioncentredata } from "../../redux/actionCentre/actions";
import CommentsLoader from "./CommentsLoader";
import { useHistory } from "react-router-dom";
import SkipModal from "./SkipModal";
import PostEditor from "./PostEditor";
// import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { getHTMLfromRawState } from "./PostEditor/postEditor";
import { DeleteModal as DeleteTaskModal } from "../DeleteModal/DeleteModal";
import TasksLoader from "./TasksLoader";
import { useSelector } from "react-redux";
import SplitSectionTabLoader from "./SplitSectionTabLoader";
import { TrackUser } from "../../Utils/SegmentFunctions";
import UpgradeToPremiumModal from "../Integrationsv2/UpgradeToPremiumModal";



const ActionCenter = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const allowDrilldownActionCentre= useSelector((state) => state?.user?.userDetail?.AccessObjList?.filter((item)=> item?.Question==="Action Centre Visibility")?.[0]);

  // const RenameDiv = useRef();
  const createCommentRef = useRef();
  const editCommentRef = useRef();
  const taskDetailRef = useRef();
  const { authTokens } = useAuth();

  // API_integration Start-----------
  const [skipModal, setSkipModal] = useState(false);

  const [showTaskDescription, setShowTaskDescription] = useState(true);

  // Condition for Welcome Modal

  // Welcome Modal
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  // Loaders
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const [sectionTabLoading, setSectionTabLoading] = useState(false);
  const [creatingNewTask, setCreatingNewTask] = useState(false);
  const [tasksLoader, setTasksLoader] = useState(false);
  // Main data of action centre page
  const [actionCentreData, setActionCentreData] = useState({});
  // Task Title

  const [selectedTaskTitle, setSelectedTaskTitle] = useState("");
  // Module dropdown setup
  const [moduleList, setModuleList] = useState([]);
  const [selectedModule, setSelectedModule] = useState({});
  const [moduleSearch, setModuleSearch] = useState("");

  //Status Dropdown Setup
  const [statusList, setStatusList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState({});

  // Owner List setup
  const [ownerList, setOwnerList] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState({});
  // Section List setup
  const [sectionList, setSectionList] = useState([]);
  const [selectedSection, setSelectedSection] = useState({});
  // Date of Task
  const [selectedTaskDate, setSelectedTaskDate] = useState("");
  // Details of task
  const [selectedTaskId, setSelectedTaskId] = useState("");
  const [selectedTaskDetails, setSelectedTaskDetails] = useState("");
  const [selectedTask, setSelectedTask] = useState({});

  // section Tabs setup
  const [selectedSectionTab, setSelectedSectionTab] = useState({
    ObjId:
      (window.localStorage.getItem("selectedSectionTab") &&
        JSON.parse(window.localStorage.getItem("selectedSectionTab"))) ||
      0,
  });
  const [sectionName, setSectionName] = useState("");
  const [editSectionId, setEditSectionId] = useState("");
  const [showUpgradeToPremiumModal, setShowUpgradeToPremiumModal] = useState(false)
  // new Task title
  const [updatedTaskTitle, setUpdatedTaskTitle] = useState("");
  const [isEditTaskTitle, setIsEditTaskTitle] = useState(false);
  // Filter drop down setup

  const [showFilter, setshowFilter] = useState(false);
  const [search, setSearch] = useState("");

  const [filterTask, setFilterTask] = useState({
    selectedOwner: {},
    selectedStatus: {},
    selectedDate: "",
    selectedSection: {},
    selectedModule: {},
  });
  const [tabWiseLoader, setTabWiseLoader] = useState({
    title: false,
    owner: false,
    duedate: false,
    module: false,
    status: false,
  });
  // Setup Filter code Version 2.0 Start-----
  const [firstLoad, setFirstLoad] = useState(true);

  const [filterParameter, setFilterParameter] = useState({
    TaskNumber: 0,
    isArchived: false,
    // FilterText: window.localStorage.getItem("TaskFilter") || 0,
    TaskFilter_List:
      window.localStorage.getItem("TaskFilter_List") ||
      JSON.stringify([
        { TaskFilter: "Title", FilterText_List: [] },
        { TaskFilter: "Status", FilterText_List: [] },
        { TaskFilter: "Due_Date", FilterText_List: [] },
        { TaskFilter: "Section", FilterText_List: [] },
        { TaskFilter: "Module", FilterText_List: [] },
        { TaskFilter: "Task_Owner", FilterText_List: [] },
      ]),
    // TaskFilter: window.localStorage.getItem("TaskFilter") || 0,
    TaskSort: window.localStorage.getItem("TaskSort") || "Title",
    TaskSortOrder: window.localStorage.getItem("TaskSortOrder") || 0,
  });

  function getSearchValuefromFilter(filterParameter) {
    let updated_data = { ...filterParameter };
    updated_data.TaskFilter_List = JSON.parse(filterParameter?.TaskFilter_List);
    if (updated_data.TaskFilter_List[0].FilterText_List[0]) {
      return updated_data.TaskFilter_List[0].FilterText_List[0];
    } else {
      return undefined;
    }
  }

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      return;
    }
    setTasksLoader(true);
    postRequest(authTokens, "GetActionTaskList", {
      ObjectId: selectedSectionTab.ObjId,
      ...filterParameter,
      // TaskFilter_List: filterParameter2.TaskFilter_List,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    }).then((response) => {
      setTasksLoader(false);
      if (response?.mr?.Success) {
        setTabWiseLoader({
          title: false,
          owner: false,
          duedate: false,
          module: false,
          status: false,
        });
        setActionCentreData({
          ...actionCentreData,
          ActionTaskList: response.ActionTaskList,
          TaskNumber: response.TaskNumber,
          NoData: response?.NoData,
        });

        // setTimeout(() => {
        //   setActionCentreData({
        //     ...actionCentreData,
        //     ActionTaskList: response.ActionTaskList,
        //     TaskNumber: response.TaskNumber,
        //     NoData: response?.NoData,
        //   });
        // }, 10);
      }
    });
  }, [filterParameter]);

  // Update Tasks when new task is created by Modal:--------
  const newTaskCreatedInModal = useSelector(
    (state) => state.actioncentre?.newTaskCreatedInModal
  );
  useEffect(() => {
    if (newTaskCreatedInModal) {
      RefreshTaskListData();
    }
  }, [newTaskCreatedInModal]);

  // console.log(actionCentreData);

  // Setup Filter code Version 2.0 Stop-----

  const toggleFilter = (e) => {
    e.stopPropagation();
    setshowFilter(!showFilter);
  };

  // New Task to Create
  const [createTask, setCreateTask] = useState(false);
  const [newTaskTitle, setNewTaskTitle] = useState(false);
  // To create New Status
  const [createStatus, setCreateStatus] = useState(false);
  const [newStatus, setNewStatus] = useState("");

  // fetching new tasks
  const [fetchingNewTasks, setFetchingNewTasks] = useState(false);
  // Task Comments
  const [newComment, setNewComment] = useState("");
  const [commentsData, setCommentsData] = useState({});
  const [editCommentId, setEditCommentId] = useState("");
  const [editComment, setEditComment] = useState("");
  const [gettingComments, setGettingComments] = useState(false);
  const [editingComment, setEditingComment] = useState(false);
  const [creatingNewComment, setCreatingNewComment] = useState(false);

  // Delete Modal Setup
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [deleteType, setDeleteType] = useState(""); //task,section,status
  const [deleteID, setDeleteID] = useState("");
  const [archiveStatus, setArchiveStatus] = useState(0);

  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };
  function ArchiveTaskFunc() {
    setDeleteMessage("You are about to archive this task.");
    setArchiveStatus(0);
    setShowDeleteModal(true);
    setDeleteType("archive");
  }
  function UnArchiveTaskFunc() {
    setDeleteMessage("You are about to unarchive this task.");
    setArchiveStatus(1);
    setShowDeleteModal(true);
    setDeleteType("unarchive");
  }
  function DeleteTaskFunc(message, taskID) {
    setDeleteMessage(message);
    setDeleteID(taskID);
    setShowDeleteModal(true);
    setDeleteType("task");
  }
  function DeleteSectionFunc(message, sectionID) {
    setDeleteMessage(message);
    setDeleteID(sectionID);
    setShowDeleteModal(true);
    setDeleteType("section");
  }
  function DeleteStatusFunc(message, statusID) {
    setDeleteMessage(message);
    setDeleteID(statusID);
    setShowDeleteModal(true);
    setDeleteType("status");
  }
  function DeleteCommentFunc(message, commentID) {
    setDeleteMessage(message);
    setDeleteID(commentID);
    setShowDeleteModal(true);
    setDeleteType("comment");
  }

  function selectSectionTab(section) {
    setSelectedSectionTab(section);
    setSelectedSection(section);
    setStateVal({
      ...stateVal,
      activeTab: section?.ObjId,
    });
  }
  function selectAllTab() {
    setStateVal({ ...stateVal, activeTab: "all" });

    setSelectedSection({});
    setSelectedSectionTab({ ObjId: 0 });
  }

  // const changeArchiveStatus = (archiveStatus, taskId = selectedTaskId) => {
  //   if (taskId == "") {
  //     return;
  //   }
  //   setArchiveStatus(authTokens, 252, taskId, archiveStatus).then(
  //     (response) => {
  //       if (response.Success) {
  //         RefreshTaskListData();
  //         if (archiveStatus == 0) {
  //           CallToast("Task archived successfully", false, false, "v2style");
  //           setStateVal({ ...stateVal, showSidebar: false });
  //         }
  //         if (archiveStatus == 1) {
  //           CallToast("Task unarchived successfully", false, false, "v2style");
  //           setStateVal({ ...stateVal, showSidebar: false });
  //         }
  //       }
  //     }
  //   );
  // };

  const changeArchiveStatus = (archiveStatus, taskId = selectedTaskId) => {
    return new Promise((resolve, reject) => {
      if (taskId == "") {
        return;
      }
      ArchiveStatus(authTokens, 252, taskId, archiveStatus).then((response) => {
        if (response.Success) {
          RefreshTaskListData();
          resolve("archived");
          if (archiveStatus == 0) {
            CallToast("Task archived successfully", false, false, "v2style");
            setStateVal({ ...stateVal, showSidebar: false });
          }
          if (archiveStatus == 1) {
            CallToast("Task unarchived successfully", false, false, "v2style");
            setStateVal({ ...stateVal, showSidebar: false });
          }
        }
      });
    });
  };

  function getLogo(msg) {
    if (msg?.split(".")[0].toLowerCase() == "jira") {
      return "/images/settings-v2/jira-logo.svg";
    }
    if (msg?.split(".")[0].toLowerCase() == "asana") {
      return "/images/settings-v2/asana-logo.svg";
    }
    if (msg?.split(".")[0].toLowerCase() == "trello") {
      return "/images/settings-v2/trello-logo.svg";
    }
  }

  // Setup User Mention List

  const [mentionList, setMentionList] = useState([]);
console.log(mentionList, "mentionList")
  const getMentionList = () => {
    getAPIData("251", authTokens).then((response) => {
      if (response.mr.Success) {
        if (response?.TaskOwnerList?.length > 0) {
          let mentions = response?.TaskOwnerList.map(
            ({ OwnerName: name, ...rest }) => ({
              name,
              ...rest,
            })
          );
          setMentionList(mentions);
        }
      }
    });
  };
  const getCommentLength = (comment) => {
    if (comment) {
      if (comment?.blocks?.length > 1 || comment?.blocks[0]?.text !== "") {
        return 100;
      } else {
        return 0;
      }
    }
  };

  // Refresh Task list data
  const initialPageLoad = () => {
    // start dealing with Task ID in query params
    let taskId = new URLSearchParams(window.location.search).get("taskid");

    // stop
    postRequest(authTokens, "GetActionTaskList", {
      ObjectId: selectedSectionTab.ObjId,
      ...filterParameter,
      TaskId: taskId,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((response) => {
        setMainPageLoading(false);
        if (response.mr.Success) {
          setActionCentreData(response);
          setModuleList(response?.ModuleList);
          setStatusList(response?.StatusList);
          setOwnerList(response?.TaskOwnerList);
          setSectionList(response?.SectionList);
          // Condition for Welcome modal
          if (response.ShowModal !== undefined) {
            setShowWelcomeModal(response.ShowModal);
          } else {
            setShowWelcomeModal(true);
          }
          // Setup to open Task modal for specific Task Id in URL
          if (taskId) {
            let task =
              response?.ActionTaskList.filter(
                (task) => task?.ObjectId == taskId
              ).length > 0 &&
              response?.ActionTaskList.filter(
                (task) => task?.ObjectId == taskId
              )[0];

            if (task) {
              setCommentsData({});
              createCommentRef.current.reset();
              setSelectedTask(task);
              setSelectedTaskId(task?.ObjectId);
              setUpdatedTaskTitle(task?.Title);
              setSelectedOwner(
                response?.TaskOwnerList.filter(
                  (owner) => owner.OwnerUserName === task?.OwnerUserName
                )[0] || {}
              );
              setSelectedSection(
                response?.SectionList.filter(
                  (section) => section.ObjId === task?.ObjId_Section
                )[0] || {}
              );
              setSelectedModule(
                response?.ModuleList.filter(
                  (module) => module.DDLText == task?.Module_Text
                )[0] || {}
              );
              setSelectedStatus(
                response?.StatusList.filter(
                  (status) => status?.DDLText === task?.Status_Text
                )[0] || {}
              );
              setSelectedTaskTitle(task?.Title);
              setSelectedTaskDetails(task?.Detail);
              try {
                taskDetailRef.current.setValue(JSON.parse(task?.Detail));
              } catch (err) {
                taskDetailRef.current.reset();
              }
              setSelectedTaskDate("");

              setTimeout(() => {
                setSelectedTaskDate(task?.DueDate?.split(" ")[0]);
              }, 1);

              setCreateTask(false);
              setNewTaskTitle(false);

              // If task is archived open the Archuved list

              if (task.Archived) {
                setFilterParameter((prevState) => {
                  return {
                    ...prevState,
                    isArchived: true,
                  };
                });
                setStateVal({
                  ...stateVal,
                  isArchivedTasks: true,
                  showSidebar: true,
                });
              } else {
                setStateVal({
                  ...stateVal,
                  showSidebar: true,
                });
              }
            }
          }

          // Setup for filtered section selection
          let filterData = window.localStorage.getItem("TaskFilter_List");
          if (filterData) {
            let filters = JSON.parse(filterData);
            let SectionIndex = filters.findIndex(
              (item) => item.TaskFilter == "Section"
            );

            if (filters[SectionIndex]?.FilterText_List?.length > 0) {
              let section = response?.SectionList?.filter((item) => {
                return (
                  item.DDLText == filters[SectionIndex]?.FilterText_List[0]
                );
              })[0];

              if (typeof section == "object") {
                setSelectedSectionTab(section);
                setSelectedSection(section);
                setStateVal({
                  ...stateVal,
                  activeTab: section?.ObjId,
                });
              }
            }
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        setMainPageLoading(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  };

  const RefreshTaskListData = () => {
    postRequest(authTokens, "GetActionTaskList", {
      ObjectId: selectedSectionTab.ObjId,
      ...filterParameter,
      // TaskFilter_List: filterParameter2.TaskFilter_List,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((response) => {
        setMainPageLoading(false);
        if (response.mr.Success) {
          setActionCentreData(response);
          setModuleList(response?.ModuleList);
          setStatusList(response?.StatusList);
          setOwnerList(response?.TaskOwnerList);
          setSectionList(response?.SectionList);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        setMainPageLoading(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  };

  // refresh Task list and Status data together

  const RefreshSection = (status) => {
    postRequest(authTokens, "GetActionTaskList", {
      ObjectId: selectedSectionTab.ObjId,
      ...filterParameter,
      // TaskFilter_List: filterParameter2.TaskFilter_List,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((response) => {
        setMainPageLoading(false);
        if (response.mr.Success) {
          setStatusList(response?.StatusList);
          setSectionList(response?.SectionList);
          if (status && status?.ObjId) {
            setSelectedStatus(
              response?.StatusList?.filter(
                (item) => item.ObjId == status?.ObjId
              )[0]
            );
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        setMainPageLoading(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  };

  // Refresh Section list data

  const RefreshSectionList = () => {
    postRequest(authTokens, "GetActionTaskList", {
      ObjectId: selectedSectionTab.ObjId,
      ...filterParameter,
      // TaskFilter_List: filterParameter2.TaskFilter_List,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((response) => {
        setMainPageLoading(false);
        if (response.mr.Success) {
          setSectionList([...response.SectionList]);
          dispatch(
            setactioncentredata({ SectionList: [...response.SectionList] })
          );
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        setMainPageLoading(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  };

  // Get All Comments

  const GetComments = () => {
    getComments(authTokens, 250, selectedTaskId)
      .then((response) => {
        setGettingComments(false);
        if (response.mr.Success) {
          let updated_comments = { ...response };
          updated_comments.TaskCommentGroupList.forEach((group, id) =>
            group.TaskComments.forEach((comment) => {
              comment.usefulComment = getHTMLfromRawState(
                comment?.Comments,
                mentionList
              );
            })
          );
          setCommentsData(updated_comments);
          if (updated_comments?.SyncHeader_Logo_Left == "syncing.svg") {
            setTimeout(() => {
              RefreshComments();
            }, 9000);
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  };
  // refersh Comments on task selected

  const RefreshComments = () => {
    if (selectedTaskId == "") return;
    getComments(authTokens, 250, selectedTaskId)
      .then((response) => {
        setSyncing(false);
        if (response.mr.Success) {
          let updated_comments = { ...response };
          updated_comments.TaskCommentGroupList.forEach((group, id) =>
            group.TaskComments.forEach((comment) => {
              comment.usefulComment = getHTMLfromRawState(
                comment?.Comments,
                mentionList
              );
            })
          );
          setCommentsData(updated_comments);
          setEditingComment(false);
          setEditComment("");
          setEditCommentId("");

          if (updated_comments?.SyncHeader_Logo_Left == "syncing.svg") {
            setTimeout(() => {
              RefreshComments();
            }, 9000);
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  };

  useEffect(() => {
    if (selectedTaskId !== "") {
      setGettingComments(true);
      GetComments();
    }
  }, [selectedTaskId]);

  useEffect(() => {
    getMentionList();
    setMainPageLoading(true);
    initialPageLoad();
  }, []);

  // Get Section wise Data and append it to main data

  const GetSectionWiseData = async (sectionID) => {
    setSectionTabLoading(true);

    await postRequest(authTokens, "GetActionTaskList", {
      ObjectId: sectionID,
      ...filterParameter,
      // TaskFilter_List: filterParameter2.TaskFilter_List,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((response) => {
        setActionCentreData({
          ...actionCentreData,
          ActionTaskList: [
            ...actionCentreData.ActionTaskList,
            ...response.ActionTaskList,
          ],
          TaskNumber: response.TaskNumber,
          NoData: response?.NoData,
        });
        setSectionTabLoading(false);
      })
      .catch((err) => {
        setSectionTabLoading(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  };

  // Task Syncing code
  const [syncing, setSyncing] = useState(false);
  async function SyncTask(taskId) {
    setSyncing(true);
    await getAPIData("257", authTokens, { optionEnum1: taskId }).then(
      (response) => {
        if (response.Success) {
          RefreshComments();
        } else {
          setSyncing(false);
        }
      }
    );
  }

  const [stateVal, setStateVal] = useState({
    welcomeModal: true,
    isIntegration: false,
    resetModal: false,
    actionCenterModal: false,
    isArchivedTasks: false,
    isEditTab: false,
    tabName: "",
    activeTab: "all",
    search: "",
    isRename: false,
    renameKey: false,
    createStatus: false,
    newStatus: "",
    showSidebar: false,
    comments: [
      {
        user: "Jane Cooper",
        user2: "@Siamon Bridger ",
        time: "1h ago",
        day: "Yesterday",
        msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porttitor at mauris ultrices egestas. Est posuere ipsum massa sollicitudin ipsum risus est sit.",
        img: "/images/actioncenter/avatar.svg",
      },
    ],
    comment: "",
  });
  const [tabNames, setTabNames] = useState();
  const [statusDropdownValue, setStatusDropdownValue] = useState("");
  const [editStatusId, setEditStatusId] = useState("");

  const Colors = [
    { color: "#616778", name: "Gray" },
    { color: "#FFAC2C", name: "Yellow" },
    { color: "#DF6844", name: "Orange" },
    { color: "#40D88E", name: "Green" },
    { color: "#008FD4", name: "Blue" },
    { color: "#5244B3", name: "Purple" },
  ];

  useEffect(() => {
    setTabNames(tabnames);
  }, []);

  // To Create and edit Comment

  const CreateEditComment = (commentId, comment, TaskId) => {
    const CommentData = {
      ObjectId: commentId,
      CommentDetail: JSON.stringify(comment),
      ActionTaskId: TaskId,
      OriginatedExternally: false,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      CommentType: "FullComment",
    };

    postRequest(authTokens, "CreateActionComment", CommentData)
      .then((response) => {
        if (response.Success) {
          if (commentId == 0) {
            CallToast("Comment added successfully", false, false, "v2style");
          }

          RefreshComments();
          setCreatingNewComment(false);

          setNewComment("");
          createCommentRef.current.reset();
        } else {
          setCreatingNewComment(false);
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        setCreatingNewComment(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  };

  //  To Delete a comment

  const DeleteComment = (id) => {
    deleteComment(authTokens, 236, id).then((response) => {
      if (response.Success) {
        RefreshComments();
        CallToast("Comment deleted successfully", false, false, "v2style");
      }
    });
  };

  const filterActive = () => {
    const filterLength =
      Number(Object.keys(filterTask?.selectedOwner).length > 0) +
      +Number(Object.keys(filterTask?.selectedModule).length > 0) +
      +Number(Object.keys(filterTask?.selectedSection).length > 0) +
      Number(Object.keys(filterTask?.selectedStatus).length > 0) +
      Number(filterTask.selectedDate.length > 0);
    return filterLength ? `• ${filterLength}` : "";
  };

  const DataInSpecificSection =
    actionCentreData?.ActionTaskList?.filter((item) => {
      if (stateVal.activeTab == "all") {
        return true;
      } else {
        if (item?.ObjId_Section === selectedSectionTab?.ObjId) {
          return true;
        }
      }
    }).length > 0;
  // Scrolling Pagination
  const ScrollFunction = () => {
    if (fetchingNewTasks) return false;
    const divv = document?.getElementById("scrollable");
    const TotalHeight = divv?.scrollHeight;

    const ScrolledHeight = divv?.scrollTop + divv?.clientHeight + 10;

    if (ScrolledHeight > TotalHeight) {
      if (stateVal.activeTab == "all" && actionCentreData.TaskNumber > 0) {
        setFetchingNewTasks(true);

        postRequest(authTokens, "GetActionTaskList", {
          ObjectId: selectedSectionTab.ObjId,
          ...filterParameter,
          // TaskFilter_List: filterParameter2.TaskFilter_List,
          TaskNumber: actionCentreData.TaskNumber || 0,
          accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        }).then((response) => {
          setActionCentreData({
            ...actionCentreData,
            ActionTaskList: [
              ...actionCentreData.ActionTaskList,
              ...response.ActionTaskList,
            ],
            TaskNumber: response.TaskNumber,
          });

          setFetchingNewTasks(false);
        });
      }
    }
  };

  const NewTaskData = {
    ObjectId: 0,
    TaskName: updatedTaskTitle || "Untitled",
    Description: selectedTaskDetails,
    StatusId: selectedStatus?.ObjId || 0,
    ModuleId: selectedModule?.DDLId || 13363,
    SectionId: selectedSection?.ObjId || 0,
    DueDate: selectedTaskDate,
    Owner: selectedOwner?.OwnerUserName,
    AutoGenerated: false,
    accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
  };
  //  To Create a task
  const CreateTask = () => {
    if (Object.keys(selectedOwner).length == 0) {
      CallToast("Please select an owner", false, false, "v2style");
      return;
    }
    if (Object.keys(selectedStatus).length == 0) {
      CallToast("Please select a status", false, false, "v2style");
      return;
    }
    if (selectedTaskDate == "") {
      CallToast("Please select a due date", false, false, "v2style");
      return;
    }

    // if (Object.keys(selectedSection).length == 0) {
    //   CallToast("Please select a section", false, false, "v2style");
    //   return;
    // }

    // if (Object.keys(selectedModule).length == 0) {
    //   CallToast("Please select a module", false, false, "v2style");
    //   return;
    // }
    setCreatingNewTask(true);
    postRequest(authTokens, "CreateActionTask", NewTaskData)
      .then((response) => {
        if (response.Success) {
          RefreshTaskListData();
          setCreatingNewTask(false);
          CallToast("Task Created Successfully", false, false, "v2style");
          TrackUser("Added a task to Action Centre");
          setStateVal({ ...stateVal, showSidebar: false });
        } else {
          setCreatingNewTask(false);
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        setCreatingNewTask(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  };

  // Update Task

  const Taskdata = {
    ObjectId: selectedTaskId,
    TaskName: updatedTaskTitle || "Untitled",
    Description: selectedTaskDetails,
    StatusId: selectedStatus?.ObjId || 0,
    ModuleId: selectedModule?.DDLId || 0,
    SectionId: selectedSection?.ObjId || 0,
    DueDate: selectedTaskDate,
    Owner: selectedOwner?.OwnerUserName,
    AutoGenerated: false,
    accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
  };

  const UpdateTask = (fieldname, fieldvalue, type, taskId = selectedTaskId) => {
    if (taskId == "") {
      return false;
    }

    if (fieldname === "OwnerUserName") {
      TrackUser("Sent a team member a task", {
        userEmail: fieldvalue,
      });
    }

    const UpdatedData = {
      ObjectId: taskId,
      FieldName: fieldname,
      FieldValue: fieldvalue,
      DataType: type,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    };
    postRequest(authTokens, "UpdateTaskFieldLevel", UpdatedData).then(
      (response) => {
        if (response.Success) {
          RefreshTaskListData();
          if (fieldname === "ObjId_Section" && fieldvalue === 0) {
            CallToast("Section removed successfully", false, false, "v2style");
          }
        }
      }
    );
  };

  // To Delete a task

  const DeleteTask = (taskId) => {
    return new Promise((resolve, reject) => {
      deleteTaskApi(authTokens, 235, taskId)
        .then((response) => {
          if (response.Success) {
            CallToast("Task deleted successfully", false, false, "v2style");
            RefreshTaskListData();
            setStateVal({ ...stateVal, showSidebar: false });
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    });
  };
  // To Create Update Status
  const NewStatusData = {
    // ObjectId: 3202495, // selectedTaskId || 0, // 3201454,
    StatusName: newStatus,
    // TextColour: "Blue",
    // Colour: "#1200ff",
    accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
  };
  const CreateUpdateStatus = (status_ObjId, color = "#B6B6BF") => {
    postRequest(authTokens, "CreateActionCustomStatus", {
      ...NewStatusData,
      ObjectId: status_ObjId,
      StatusName: status_ObjId == 0 ? newStatus : statusDropdownValue,
      // TextColour: color,
      Colour: color,
    }).then((response) => {
      if (response.Success) {
        setNewStatus("");
        {
          status_ObjId == 0 &&
            CallToast("Status created successfully", false, false, "v2style");
        }
        RefreshTaskListData();
      } else {
        setNewStatus("");
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  };
  // CreateUpdateStatus();

  // To delete status
  const DeleteStatus = (statusID) => {
    return new Promise((resolve, reject) => {
      deleteStatusApi(authTokens, 247, statusID).then((response) => {
        if (response.Success) {
          CallToast("Status deleted successfully", false, false, "v2style");
          RefreshTaskListData();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      });
    });
  };
  // To Create and Update a Section

  const CreateUpdateSection = async (sectionID, sectionName, order) => {
    const SectionData = {
      ObjectId: sectionID,

      Name: sectionName,
      SortOrder: sectionID == 0 ? sectionList?.length : order,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    };

    postRequest(authTokens, "CreateActionSection", SectionData)
      .then((response) => {
        if (response.Success) {
          if (sectionID === 0) {
            TrackUser("Action Center added a Section");
            CallToast("Section created successfully", false, false, "v2style");
            RefreshSectionList();
            setStateVal({
              ...stateVal,
              isEditTab: false,
            });
            setSectionName("");
          }
          if (sectionID > 0) {
            CallToast("Section updated successfully", false, false, "v2style");
            RefreshSectionList();

            setSectionName("");
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
          setStateVal({
            ...stateVal,
            isEditTab: false,
          });
          setSectionName("");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
        setStateVal({
          ...stateVal,
          isEditTab: false,
        });
        setSectionName("");
      });
  };

  // To Delete Section

  const DeleteSection = (sectionId) => {
    return new Promise((resolve, reject) => {
      deleteSectionApi(authTokens, 234, sectionId)
        .then((response) => {
          if (response.Success) {
            CallToast("Section deleted successfully", false, false, "v2style");
            RefreshSectionList();
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    });
  };
  // To sort a section

  const SortSection = (sortedSectionList) => {
    postRequest(authTokens, "UpdateActionSectionSorting", {
      ObjectId: 0,
      ActionSection_List: JSON.stringify(sortedSectionList),
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    }).then((response) => {
      RefreshTaskListData();
      if (response.Success) {
      }
    });
  };

  useEffect(() => {
    function FilterDropDownCloser(e) {
      const element = document.getElementById("filter-task-dropdown");
      const datepicker = e.target.closest("tr");

      if (e.target !== element && !element?.contains(e.target)) {
        if (datepicker) {
          return null;
        } else {
          setshowFilter(false);
        }
      }
    }

    document.addEventListener("click", FilterDropDownCloser);

    return () => {
      document.removeEventListener("click", FilterDropDownCloser);
    };
  }, []);
  // To hide dragable drop down

  useEffect(() => {
    function CloseDraggableDropdown(e) {
      const DraggableDropdownParent =
        document.getElementById("dropdown-parent");
      const DraggableDropdown = document.getElementById("drag-drop-dropdown");

      const Parent2 = document.getElementById("parent-2");

      if (
        e.target !== DraggableDropdownParent &&
        !DraggableDropdownParent?.contains(e.target)
      ) {
        if (e.target.id == "rename-div") {
          return null;
        } else {
          DraggableDropdown && DraggableDropdown.classList.add("d-none");
        }
      }
    }

    document.addEventListener("click", CloseDraggableDropdown);

    return () => {
      document.removeEventListener("click", CloseDraggableDropdown);
    };
  }, []);

  // Setup Draggable section list
  const grid = 8;
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // CreateUpdateSection(
    //   sectionList[result.source.index]?.ObjId,
    //   sectionList[result.source.index]?.DDLText,
    //   result.destination.index
    // );

    const items = reorder(
      sectionList,
      result.source.index,
      result.destination.index
    );

    setSectionList(items);

    let sortedSectionList = items.map((item, index) => ({
      ObjectId: item.ObjId,
      SortOrder: index + 1,
    }));
    SortSection(sortedSectionList);
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: 250,
  });

  const SearchedTaskList =
    actionCentreData?.ActionTaskList &&
    [
      ...new Map(
        actionCentreData?.ActionTaskList.map((item) => [item["ObjectId"], item])
      ).values(),
    ]
      .filter((item) =>
        item?.Title?.toLowerCase().includes(search?.toLowerCase())
      )
      .filter((item) => {
        if (stateVal.activeTab == "all") {
          return true;
        } else {
          if (item?.ObjId_Section === selectedSectionTab?.ObjId) {
            return true;
          }
        }
      }).length;

  return (
    <>
      {mainPageLoading ? (
        <ActionCenterHomePageLoader />
      ) : (
        <Col xs={12} className="px-0 h-100">
          <div
            className={`dashboard fullHeight action_center  ${allowDrilldownActionCentre?.Option === false? "disabled-due-to-subscription"  :""}`}
            id="scrollable"
            onScroll={ScrollFunction}
          >
            <header
              className="d-flex justify-content-between navbar sticky-top pb-0"
              style={{ paddingTop: "1em", background: "#FFFFFF" }}
            >
              <h1>Action Centre</h1>
              <Header />
            </header>

            <div className={`filter_header mt-3  `}>
              <div>
                <div className={`${stateVal.isArchivedTasks ? "" : "d-none"} `}>
                  <span
                    className="cursor-pointer f-black"
                    style={{ paddingTop: "7px" }}
                    onClick={
                      () => {
                        setFilterParameter((prevState) => {
                          return {
                            ...prevState,
                            isArchived: false,
                          };
                        });
                        setStateVal({ ...stateVal, isArchivedTasks: false });
                      }
                      // setStateVal({ ...stateVal, isArchived: false })
                    }
                  >
                    <img
                      alt=""
                      src="/images/icons/arrowLeft.svg"
                      height="11"
                      onClick={() => {}}
                    />
                    &nbsp; Back
                  </span>
                  <span className="archivedtasktxt ml-4">Archived Tasks</span>
                </div>
              </div>
              <div className="inner_header d-flex align-items-center">
                <Form.Group    style={{
                    pointerEvents:`${allowDrilldownActionCentre?.Option===false ? "none" :"all"}`
                    
                  }}>
                  <InputGroup className="search_input">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="greyText inptLbl pr-1">
                        <img
                          alt=""
                          src="/images/calculator/search.svg"
                          height="15"
                        />
                      </InputGroup.Text>
                    </InputGroup.Prepend>

                    <Form.Control
                      as="input"
                      value={
                        getSearchValuefromFilter(filterParameter)?.length > 0
                          ? getSearchValuefromFilter(filterParameter)
                          : ""
                      }
                      placeholder="Search..."
                      onChange={(e) => {
                        // if(typeof filterParameter.)
                        let updated_data = { ...filterParameter };
                        if (
                          typeof filterParameter.TaskFilter_List == "string"
                        ) {
                          // let updated_data = { ...filterParameter };
                          updated_data.TaskFilter_List = JSON.parse(
                            filterParameter?.TaskFilter_List
                          );
                          updated_data.TaskFilter_List[0].FilterText_List[0] =
                            e.target.value;

                          setFilterParameter((prevState) => {
                            return {
                              ...prevState,
                              TaskFilter_List: JSON.stringify(
                                updated_data?.TaskFilter_List
                              ),
                            };
                          });
                        } else {
                          // updated_data.TaskFilter_List[0].FilterText_List[0] =
                          //   e.target.value;
                          // setFilterParameter(updated_data);
                        }

                        // setSearch(e.target.value);
                      }}
                      className="search"
                    />

                    <InputGroup.Append>
                      <InputGroup.Text>
                        {getSearchValuefromFilter(filterParameter)?.length >
                          0 && (
                          <img
                            alt=""
                            src="/images/settings-v2/header-search-cross.svg"
                            className="pointer"
                            onClick={() => {
                              let updated_data = { ...filterParameter };
                              updated_data.TaskFilter_List = JSON.parse(
                                filterParameter?.TaskFilter_List
                              );
                              updated_data.TaskFilter_List[0].FilterText_List =
                                [];

                              setFilterParameter((prevState) => {
                                return {
                                  ...prevState,
                                  TaskFilter_List: JSON.stringify(
                                    updated_data?.TaskFilter_List
                                  ),
                                };
                              });
                            }}
                          />
                        )}
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>

                {/* filter */}
                <div
                  className="filter_wrapper d-flex align-items-center mt-1 ml-2 pl-1"
                  id="filter-task-dropdown"
                  style={{
                    pointerEvents:`${allowDrilldownActionCentre?.Option===false ? "none" :"all"}`
                    
                  }}
                >
                  <DropdownMenu
                    data={actionCentreData}
                    setFilterTask={setFilterTask}
                    filterTask={filterTask}
                    setFilterParameter={setFilterParameter}
                    filterParameter={filterParameter}
                    activeFilter={filterActive() ? true : false}
                    show={showFilter}
                    toggleFilter={toggleFilter}
                    GetSectionWiseData={GetSectionWiseData}
                    selectedSectionTab={selectedSectionTab}
                    selectSectionTab={selectSectionTab}
                    selectAllTab={selectAllTab}
                  >
                    {filterActive() ? (
                      <img
                        alt=""
                        src="/images/actioncenter/filter-active.svg"
                      />
                    ) : (
                      <img alt="" src="/images/actioncenter/filter.svg" />
                    )}

                    <span className="pl-2 greytxt mr-2">
                      Filter {filterActive()}
                    </span>
                  </DropdownMenu>
                </div>
                <div className="pl-3">
                  <img
                    alt=""
                    onClick={() => {
                      if(allowDrilldownActionCentre?.Option===false){
                        setShowUpgradeToPremiumModal(true)
                        return;
                      }
                      setFilterParameter((prevState) => {
                        return {
                          ...prevState,
                          isArchived: true,
                        };
                      });
                      setStateVal({ ...stateVal, isArchivedTasks: true });
                    }}
                    src="/images/actioncenter/folder.svg"
                    className="pr-3 crsrpoint"
                  />
                  <img
                    alt=""
                    src="/images/actioncenter/cog.svg"
                    className="pr-3 crsrpoint"
                    onClick={() =>
                     {
                      
                      if(allowDrilldownActionCentre?.Option===false){
                        setShowUpgradeToPremiumModal(true)
                        return;
                      }
                      
                      setStateVal({ ...stateVal, actionCenterModal: true })}
                    }
                  />
                  <button
                    className="replotposture"
                    onClick={() => {
                      if(allowDrilldownActionCentre?.Option===false){
                        setShowUpgradeToPremiumModal(true)
                        return;
                      }
                      setSelectedModule({});

                      setSelectedStatus(
                        statusList?.filter(
                          (item) => item.DDLText.toLowerCase() == "new"
                        )[0] || {
                          ObjId: 0,
                          DDLId: 13362,
                          DDLText: "New",
                          DDLSort: 0,
                          DDLValue: 3,
                          DDLColour: "#B6B6BF",
                          DDLImage: "NA",
                        }
                      );
                      if (stateVal.activeTab == "all") {
                        setSelectedSection({});
                        setUpdatedTaskTitle("");
                      } else {
                        setSelectedSection(selectedSectionTab);
                        setIsEditTaskTitle(true);
                        setUpdatedTaskTitle("Title");
                      }

                      setSelectedOwner({});
                      setSelectedTaskDate("");
                      setSelectedTaskDetails("");
                      taskDetailRef.current.reset();
                      setSelectedTaskTitle("Untitled");

                      setSelectedTaskId("");
                      setCreateTask(true);
                      setNewTaskTitle(true);
                      setStateVal({ ...stateVal, showSidebar: true });
                      setSelectedTask({});
                    }}
                  >
                    <img
                      alt=""
                      src="/images/actioncenter/plus.svg"
                      className="pr-1"
                    />
                    Create Task
                  </button>
                </div>
              </div>
            </div>

            {/* content */}

            <div className={`action_center_wrapper`}>
              <div
                className={`${
                  stateVal.showSidebar
                    ? " table-move "
                    : "w-100 table-move-righ-t"
                } `}
              >
                <div className="tabletabs mt-4" style={{ flexWrap: "wrap" }}>
                  <div
                    className={`navtab d-flex justify-content-center align-items-center ${
                      selectedSectionTab.ObjId == 0 ? "active" : ""
                    }`}
                    onClick={() => {
                      setStateVal({ ...stateVal, activeTab: "all" });

                      setSelectedSection({});
                      setSelectedSectionTab({ ObjId: 0 });
                      GetSectionWiseData(0);
                      window.localStorage.setItem(
                        "selectedSectionTab",
                        JSON.stringify(0)
                      );
                    }}
                    style={{ height: "31px", marginTop: "3px" }}
                  >
                    All
                  </div>

                  {sectionList?.length > 0 &&
                    sectionList.map((section, i) => (
                      <div
                        onClick={() => {
                          setSelectedSectionTab(section);
                          setSelectedSection(section);
                          setStateVal({
                            ...stateVal,
                            activeTab: section?.ObjId,
                          });
                          GetSectionWiseData(section.ObjId);
                          window.localStorage.setItem(
                            "selectedSectionTab",
                            JSON.stringify(section?.ObjId)
                          );
                        }}
                        className={`navtab mt-1 d-flex justify-content-center align-items-center ${
                          selectedSectionTab.ObjId == section.ObjId
                            ? "active"
                            : ""
                        }`}
                        key={i}
                        title={section?.DDLText}
                      >
                        {stateVal.showSidebar
                          ? TrimString(section?.DDLText, 7)
                          : section?.DDLText}
                      </div>
                    ))}

                  {stateVal.isEditTab && (
                    <div className="d-inline-flex mt-1">
                      <div className="navtabinput">
                        <input
                          type="text"
                          value={sectionName}
                          onBlur={() => {
                            setStateVal({
                              ...stateVal,
                              isEditTab: false,
                            });
                            setSectionName("");
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              if (sectionName !== "") {
                                CreateUpdateSection(0, sectionName);
                              } else {
                                setStateVal({
                                  ...stateVal,
                                  isEditTab: false,
                                });
                                setSectionName("");
                              }
                            }
                          }}
                          onChange={(e) => {
                            setSectionName(e.target.value);
                          }}
                          autoFocus
                        />
                      </div>
                      <div
                        className="navtab"
                        onClick={() => {
                          setSectionName("");
                          setStateVal({
                            ...stateVal,
                            isEditTab: false,
                          });
                        }}
                      >
                        <img alt="" src="/images/actioncenter/cross.svg" />
                      </div>
                    </div>
                  )}

                  {!stateVal.isEditTab && (
                    <div
                      className="navtab mt-1 d-flex justify-content-center align-items-center"
                      onClick={() =>
                     {
                       if (allowDrilldownActionCentre?.Option === false) {
                         setShowUpgradeToPremiumModal(true);
                         return;
                       }

                       setStateVal({
                         ...stateVal,
                         isEditTab: true /**true isedit */,
                       });
                     }
                      }
                    >
                      <img alt="" src="/images/actioncenter/plusgrey.svg" />{" "}
                      {sectionList.length === 0 && (
                        <span
                          className="ml-2"
                          style={{ color: "#616778", fontWeight: "500" }}
                        >
                          Add section
                        </span>
                      )}
                    </div>
                  )}

                  {sectionList?.length > 0&& allowDrilldownActionCentre?.Option===true && (
                    <>
                      <Dropdown
                        id="dropdown-parent"
                        className="table-drop-down edit-section-dropdown position-relative mt-1"
                      >
                        <Dropdown.Toggle
                          className={`section-list-toggle`}
                          onClick={() => {
                            const dragdropdown =
                              document.getElementById("drag-drop-dropdown");
                            dragdropdown.classList.toggle("d-none");
                          }}
                        >
                          <div className="navtab" id="low">
                            <img
                              alt=""
                              src="/images/actioncenter/context.svg"
                              className="image-click"
                            />
                          </div>
                        </Dropdown.Toggle>
                        <div
                          id="drag-drop-dropdown"
                          className={`dropdown_menu_list drop-drag-issue position-absolute d-none    
                          
                         
                          
                          `}
                        >
                          <span className="list_item_header">
                            Edit section:
                          </span>
                          <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable
                              direction="vertical"
                              droppableId="droppableSection"
                            >
                              {(provided, snapshot) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}

                                  // style={getListStyle(snapshot.isDraggingOver)}
                                >
                                  {sectionList &&
                                    sectionList.map((section, i) => (
                                      <Draggable
                                        key={section?.ObjId}
                                        draggableId={section?.ObjId.toString()}
                                        index={i}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            // style={getItemStyle(
                                            //   snapshot.isDragging,
                                            //   provided.draggableProps.style
                                            // )}
                                            className="list_item d-flex justify-content-between  px-2"
                                            key={i}
                                            index={i}
                                          >
                                            {editSectionId === i ? (
                                              <div className="d-inline-flex">
                                                <img
                                                  alt=""
                                                  src="/images/actioncenter/contextmenu.svg"
                                                  className="pr-2"
                                                  style={{ cursor: "grab" }}
                                                />
                                                <div className="navtabinput">
                                                  <input
                                                    type="text"
                                                    value={sectionName}
                                                    onChange={(e) => {
                                                      setSectionName(
                                                        e.target.value
                                                      );
                                                    }}
                                                    onBlur={() => {
                                                      if (sectionName == "") {
                                                        setEditSectionId("");
                                                        return false;
                                                      } else {
                                                        let updatedSection = [
                                                          ...sectionList,
                                                        ];
                                                        let Index =
                                                          updatedSection.findIndex(
                                                            (sec) =>
                                                              sec.ObjId ===
                                                              section?.ObjId
                                                          );
                                                        updatedSection[
                                                          Index
                                                        ].DDLText = sectionName;
                                                        setSectionList(
                                                          updatedSection
                                                        );
                                                        CreateUpdateSection(
                                                          section?.ObjId,
                                                          sectionName,
                                                          Index + 1
                                                        );
                                                        setEditSectionId("");
                                                      }
                                                    }}
                                                    onKeyDown={(e) => {
                                                      if (e.key === "Enter") {
                                                        if (sectionName == "") {
                                                          setEditSectionId("");
                                                          return false;
                                                        } else {
                                                          let updatedSection = [
                                                            ...sectionList,
                                                          ];
                                                          let Index =
                                                            updatedSection.findIndex(
                                                              (sec) =>
                                                                sec.ObjId ===
                                                                section?.ObjId
                                                            );
                                                          updatedSection[
                                                            Index
                                                          ].DDLText =
                                                            sectionName;
                                                          setSectionList(
                                                            updatedSection
                                                          );
                                                          CreateUpdateSection(
                                                            section?.ObjId,
                                                            sectionName,
                                                            Index + 1
                                                          );

                                                          setEditSectionId("");
                                                          // setTimeout(() => {
                                                          //   document
                                                          //     .getElementById(
                                                          //       "low"
                                                          //     )
                                                          //     .click();
                                                          // }, 500);
                                                        }
                                                      }
                                                    }}
                                                    autoFocus
                                                  />
                                                </div>
                                              </div>
                                            ) : (
                                              <>
                                                <div
                                                  className="d-inline-flex w-75"
                                                  onClick={() => {}}
                                                  style={{
                                                    alignItems: "center",
                                                    paddingTop: "3px",
                                                  }}
                                                >
                                                  <img
                                                    alt=""
                                                    src="/images/actioncenter/contextmenu.svg"
                                                    className="pr-2"
                                                    style={{ cursor: "grab" }}
                                                  />
                                                  {TrimString(
                                                    section?.DDLText,
                                                    18
                                                  )}
                                                </div>

                                                <div
                                                  style={{
                                                    float: "right",
                                                  }}
                                                  className="position-relative sideBarDropdown complianceManagerDropdown actioncenterdropdown black-dropdown"
                                                >
                                                  <Dropdown id="parent-2">
                                                    <Dropdown.Toggle
                                                      className="p-0 position-relative section-rename-dropdown-"
                                                      ref={(el) => {
                                                        if (el) {
                                                          el.style.setProperty(
                                                            "padding",
                                                            "4px 2px 13px 7px",
                                                            "important"
                                                          );
                                                        }
                                                      }}
                                                      style={{
                                                        zIndex: 1,
                                                      }}
                                                    >
                                                      <span className="menudropdown">
                                                        <img
                                                          alt=""
                                                          src="/images/actioncenter/context2.svg"
                                                          className="pr-2 float-right mt-2"
                                                        />
                                                      </span>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu
                                                      className="BillingInfo"
                                                      style={{
                                                        height: "150px",
                                                      }}
                                                    >
                                                      <div
                                                        // ref={RenameDiv}
                                                        id="rename-div"
                                                        className="mt-2 child_drop"
                                                        onClick={(e) => {
                                                          e.stopPropagation();

                                                          // e.target
                                                          //   .closest(
                                                          //     ".BillingInfo"
                                                          //   )
                                                          //   .closest(
                                                          //     ".dropdown"
                                                          //   )
                                                          //   .querySelector(
                                                          //     ".dropdown-toggle"
                                                          //   )
                                                          //   .click();
                                                          setEditSectionId(i);
                                                          setSectionName(
                                                            section?.DDLText
                                                          );
                                                        }}
                                                      >
                                                        Rename
                                                      </div>
                                                      <div
                                                        className="mt-2 child_drop"
                                                        onClick={(e) => {
                                                          DeleteSectionFunc(
                                                            "You are about to delete this section.",
                                                            section?.ObjId
                                                          );
                                                          // DeleteSection(
                                                          //   section?.ObjId
                                                          // );
                                                          e.target
                                                            .closest(
                                                              ".BillingInfo"
                                                            )
                                                            .closest(
                                                              ".dropdown"
                                                            )
                                                            .querySelector(
                                                              ".dropdown-toggle"
                                                            )
                                                            .click();
                                                          setTimeout(() => {
                                                            document
                                                              .getElementById(
                                                                "low"
                                                              )
                                                              .click();
                                                          }, 500);
                                                        }}
                                                      >
                                                        Delete
                                                      </div>
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </div>
                      </Dropdown>
                    </>
                  )}
                </div>

                {!sectionTabLoading &&
                  actionCentreData?.NoData === false &&
                  SearchedTaskList > 0 && (
                    <div
                      className={`content_table mt-2
                    
                    
                    ${stateVal.showSidebar ? "  scrolling-div-issue" : ""}
                    
                    
                    
                    `}
                      onScroll={ScrollFunction}
                    >
                      {DataInSpecificSection && (
                        <div
                        style={{
                          pointerEvents:`${allowDrilldownActionCentre?.Option===false ? "none" :"all"}`
                          
                        }}
                          className={`tableheader- ${
                            stateVal.showSidebar
                              ? "tableheader2"
                              : "tableheader"
                          } `}
                        >
                          <div
                            className="table_item pl-5 pointer"
                            onClick={() => {
                              setTabWiseLoader({
                                ...tabWiseLoader,
                                title: true,
                              });

                              setFilterParameter((prevState) => {
                                return {
                                  ...prevState,
                                  TaskSort: "Title",
                                  TaskSortOrder: Number(
                                    !prevState.TaskSortOrder
                                  ),
                                };
                              });

                              setTimeout(() => {
                                window.localStorage.setItem(
                                  "TaskSort",
                                  "Title"
                                );
                                window.localStorage.setItem(
                                  "TaskSortOrder",
                                  Number(!filterParameter.TaskSortOrder)
                                );
                              }, 50);
                            }}
                          >
                            Title
                            {tabWiseLoader.title ? (
                              <Spinner
                                animation="border"
                                role="status"
                                className="spinner_loader"
                              />
                            ) : (
                              filterParameter.TaskSort == "Title" && (
                                <>
                                  {filterParameter.TaskSortOrder ? (
                                    <img
                                      alt=""
                                      src="/images/actioncenter/arrowup.svg"
                                      className="ml-2"
                                      style={{ width: "10px" }}
                                    />
                                  ) : (
                                    <img
                                      alt=""
                                      src="/images/actioncenter/arrowdown.svg"
                                      className="ml-2"
                                    />
                                  )}
                                </>
                              )
                            )}
                          </div>
                          <div
                            className="table_item pointer"
                            onClick={() => {
                              setTabWiseLoader({
                                ...tabWiseLoader,
                                duedate: true,
                              });
                              setFilterParameter((prevState) => {
                                return {
                                  ...prevState,
                                  TaskSort: "Due_Date",
                                  TaskSortOrder: Number(
                                    !prevState.TaskSortOrder
                                  ),
                                };
                              });

                              setTimeout(() => {
                                window.localStorage.setItem(
                                  "TaskSort",
                                  "Due_Date"
                                );
                                window.localStorage.setItem(
                                  "TaskSortOrder",
                                  Number(!filterParameter.TaskSortOrder)
                                );
                              }, 50);
                            }}
                          >
                            Due Date
                            {tabWiseLoader.duedate ? (
                              <Spinner
                                animation="border"
                                role="status"
                                className="spinner_loader"
                              />
                            ) : (
                              filterParameter.TaskSort == "Due_Date" && (
                                <>
                                  {filterParameter.TaskSortOrder ? (
                                    <img
                                      alt=""
                                      src="/images/actioncenter/arrowup.svg"
                                      className="ml-2"
                                      style={{ width: "10px" }}
                                    />
                                  ) : (
                                    <img
                                      alt=""
                                      src="/images/actioncenter/arrowdown.svg"
                                      className="ml-2"
                                    />
                                  )}
                                </>
                              )
                            )}
                          </div>
                          <div
                            className="table_item pointer"
                            onClick={() => {
                              setTabWiseLoader({
                                ...tabWiseLoader,
                                owner: true,
                              });
                              setFilterParameter((prevState) => {
                                return {
                                  ...prevState,
                                  TaskSort: "Owner",
                                  TaskSortOrder: Number(
                                    !prevState.TaskSortOrder
                                  ),
                                };
                              });

                              setTimeout(() => {
                                window.localStorage.setItem(
                                  "TaskSort",
                                  "Owner"
                                );
                                window.localStorage.setItem(
                                  "TaskSortOrder",
                                  Number(!filterParameter.TaskSortOrder)
                                );
                              }, 50);
                            }}
                          >
                            Owner
                            {tabWiseLoader.owner ? (
                              <Spinner
                                animation="border"
                                role="status"
                                className="spinner_loader"
                              />
                            ) : (
                              filterParameter.TaskSort == "Owner" && (
                                <>
                                  {filterParameter.TaskSortOrder ? (
                                    <img
                                      alt=""
                                      src="/images/actioncenter/arrowup.svg"
                                      className="ml-2"
                                      style={{ width: "10px" }}
                                    />
                                  ) : (
                                    <img
                                      alt=""
                                      src="/images/actioncenter/arrowdown.svg"
                                      className="ml-2"
                                    />
                                  )}
                                </>
                              )
                            )}
                          </div>
                          {!stateVal.showSidebar && (
                            <div
                              className="table_item pointer"
                              onClick={() => {
                                setTabWiseLoader({
                                  ...tabWiseLoader,
                                  module: true,
                                });
                                setFilterParameter((prevState) => {
                                  return {
                                    ...prevState,
                                    TaskSort: "Module",
                                    TaskSortOrder: Number(
                                      !prevState.TaskSortOrder
                                    ),
                                  };
                                });

                                setTimeout(() => {
                                  window.localStorage.setItem(
                                    "TaskSort",
                                    "Module"
                                  );
                                  window.localStorage.setItem(
                                    "TaskSortOrder",
                                    Number(!filterParameter.TaskSortOrder)
                                  );
                                }, 50);
                              }}
                            >
                              Module
                              {tabWiseLoader.module ? (
                                <Spinner
                                  animation="border"
                                  role="status"
                                  className="spinner_loader"
                                />
                              ) : (
                                filterParameter.TaskSort == "Module" && (
                                  <>
                                    {filterParameter.TaskSortOrder ? (
                                      <img
                                        alt=""
                                        src="/images/actioncenter/arrowup.svg"
                                        className="ml-2"
                                        style={{ width: "10px" }}
                                      />
                                    ) : (
                                      <img
                                        alt=""
                                        src="/images/actioncenter/arrowdown.svg"
                                        className="ml-2"
                                      />
                                    )}
                                  </>
                                )
                              )}
                            </div>
                          )}
                          <div
                            className="table_item pointer"
                            onClick={() => {
                              setTabWiseLoader({
                                ...tabWiseLoader,
                                status: true,
                              });
                              setFilterParameter((prevState) => {
                                return {
                                  ...prevState,
                                  TaskSort: "Status",
                                  TaskSortOrder: Number(
                                    !prevState.TaskSortOrder
                                  ),
                                };
                              });

                              setTimeout(() => {
                                window.localStorage.setItem(
                                  "TaskSort",
                                  "Status"
                                );
                                window.localStorage.setItem(
                                  "TaskSortOrder",
                                  Number(!filterParameter.TaskSortOrder)
                                );
                              }, 50);
                            }}
                          >
                            Status
                            {tabWiseLoader.status ? (
                              <Spinner
                                animation="border"
                                role="status"
                                className="spinner_loader"
                              />
                            ) : (
                              filterParameter.TaskSort == "Status" && (
                                <>
                                  {filterParameter.TaskSortOrder ? (
                                    <img
                                      alt=""
                                      src="/images/actioncenter/arrowup.svg"
                                      className="ml-2"
                                      style={{ width: "10px" }}
                                    />
                                  ) : (
                                    <img
                                      alt=""
                                      src="/images/actioncenter/arrowdown.svg"
                                      className="ml-2"
                                    />
                                  )}
                                </>
                              )
                            )}
                          </div>
                          {!stateVal.showSidebar && (
                            <div className="table_item" />
                          )}
                        </div>
                      )}
                      {/* All Active tabs data */}

                      <div>
                        {!tasksLoader &&
                          actionCentreData?.ActionTaskList &&
                          [
                            ...new Map(
                              actionCentreData?.ActionTaskList.map((item) => [
                                item["ObjectId"],
                                item,
                              ])
                            ).values(),
                          ]
                            .filter((item) => {
                              if (search !== "") {
                                return item?.Title?.toLowerCase().includes(
                                  search?.toLowerCase()
                                );
                              } else {
                                return true;
                              }
                            })

                            .filter((item) => {
                              if (stateVal.activeTab == "all") {
                                return true;
                              } else {
                                if (
                                  item?.ObjId_Section ===
                                  selectedSectionTab?.ObjId
                                ) {
                                  return true;
                                }
                              }
                            })
                            .map((task, taskIndex) => (
                              <div
                              style={{
                                // pointerEvents:`${allowDrilldownActionCentre?.Option===false ? "none" :"all"}`
                                
                              }}
                              
                                className={`tablerow ${
                                  stateVal.showSidebar ? "tablerow2" : ""
                                }`}
                                onClick={(e) => {
                                  if(allowDrilldownActionCentre?.Option===false){
                                    setShowUpgradeToPremiumModal(true)
                                    return;
                                  }
                                  setCommentsData({});
                                  createCommentRef.current.reset();
                                  e.preventDefault();
                                  setSelectedTask(task);
                                  setSelectedTaskId(task?.ObjectId);
                                  setUpdatedTaskTitle(task?.Title);
                                  setSelectedOwner(
                                    ownerList.filter(
                                      (owner) =>
                                        owner.OwnerUserName ===
                                        task?.OwnerUserName
                                    )[0] || {}
                                  );
                                  setSelectedSection(
                                    sectionList.filter(
                                      (section) =>
                                        section.ObjId === task?.ObjId_Section
                                    )[0] || {}
                                  );
                                  setSelectedModule(
                                    moduleList.filter(
                                      (module) =>
                                        module.DDLText == task?.Module_Text
                                    )[0] || {}
                                  );
                                  setSelectedStatus(
                                    statusList.filter(
                                      (status) =>
                                        status?.DDLText === task?.Status_Text
                                    )[0] || {}
                                  );
                                  setSelectedTaskTitle(task?.Title);
                                  setSelectedTaskDetails(task?.Detail);
                                  try {
                                    taskDetailRef.current.setValue(
                                      JSON.parse(task?.Detail)
                                    );
                                  } catch (err) {
                                    taskDetailRef.current.reset();
                                  }
                                  setSelectedTaskDate("");

                                  setTimeout(() => {
                                    setSelectedTaskDate(
                                      task?.DueDate?.split(" ")[0]
                                    );
                                  }, 1);

                                  setCreateTask(false);
                                  setNewTaskTitle(false);
                                  setStateVal({
                                    ...stateVal,
                                    showSidebar: true,
                                  });
                                }}
                              >
                                <div
                                  className="table_item text-ellipsis"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (stateVal.isArchivedTasks) {
                                      return false;
                                    }

                                    // setStateVal({
                                    //   ...stateVal,
                                    //   showSidebar: true,
                                    // });
                                  }}
                                >
                                  {stateVal.isArchivedTasks ? (
                                    <img
                                      alt=""
                                      src="/images/actioncenter/archived.svg"
                                      height="11"
                                    />
                                  ) : (
                                    <Form.Group className="round-checkbox titleCheckbox">
                                      <Form.Check
                                        type="checkbox"
                                        label=""
                                        name={`checkbox` + taskIndex}
                                        checked={
                                          task?.Status_Text == "Closed" ? 1 : 0
                                        }
                                        onChange={(e) => e.preventDefault()}
                                      />
                                    </Form.Group>
                                  )}

                                  <span
                                    className={`${
                                      stateVal.isArchivedTasks
                                        ? "pl-3"
                                        : "ml-4 pl-2"
                                    }`}
                                  >
                                    {/* {TrimString(task?.Title, 30)} */}
                                    {task?.Title}
                                  </span>
                                </div>
                                <div className="table_item">
                                  {task?.DueDate?.split(" ")[0]}
                                </div>
                                <div className="table_item">
                                  <span className="prfimg">
                                    {task?.OwnerShowInitials === true ? (
                                      <ImageWithInitials
                                        initials={task?.OwnerInitails}
                                        width={20}
                                        background={"purple"}
                                      />
                                    ) : task?.OwnerImage == null ? (
                                      <img
                                        alt=""
                                        src="/images/settings-v2/highground-dp.svg"
                                      />
                                    ) : (
                                      <img
                                        alt=""
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                          objectFit: "contain",
                                          borderRadius: "50%",
                                        }}
                                        src={task?.OwnerImage}
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src =
                                            "/images/settings-v2/highground-dp.svg";
                                        }}
                                      />
                                    )}
                                  </span>

                                  {TrimString(
                                    !stateVal.showSidebar && task?.OwnerName,
                                    11
                                  )}
                                </div>
                                <div
                                  className={`table_item ${
                                    stateVal.showSidebar ? "d-none" : ""
                                  } `}
                                >
                                  <img
                                    alt=""
                                    src={`/images/actioncentrelive/${task?.ModuleLogo}`}
                                    className="mr-2"
                                    style={{
                                      filter: "invert(0.4)",
                                    }}
                                  />
                                  {TrimString(task?.Module_Text, 16)}
                                </div>
                                <div
                                  className={`table_item position-relative  ${
                                    stateVal.showSidebar ? "status-center" : ""
                                  } `}
                                >
                                  <div
                                    className="tableStatus"
                                    style={{
                                      backgroundColor: task?.Status_Colour
                                        ? `${task?.Status_Colour}40`
                                        : "",
                                      color: task?.Status_Colour,
                                    }}
                                  >
                                    <div
                                      className="tablecircle"
                                      style={{
                                        backgroundColor: task?.Status_Colour,
                                      }}
                                    />
                                    {TrimString(task?.Status_Text, 10)}
                                  </div>
                                </div>
                                <div
                                  className={`table_item details ${
                                    stateVal.showSidebar ? "d-none" : ""
                                  } `}
                                  onClick={() =>{

                                    if(allowDrilldownActionCentre?.Option===false){
                                      // setShowUpgradeToPremiumModal(true)
                                      return;
                                    }

                                    setStateVal({
                                      ...stateVal,
                                      showSidebar: true,
                                    })



                                  }
                                  }
                                >
                          { allowDrilldownActionCentre?.Option===true&&       <span className="detailsinner">
                                    Details
                                    <img
                                      alt=""
                                      src="/images/actioncenter/arrowright.svg"
                                      className="ml-2"
                                    />
                                  </span>}
                                </div>
                              </div>
                            ))}

                        {tasksLoader && <TasksLoader />}
                      </div>
                    </div>
                  )}

                {/* tabs data */}
                {actionCentreData.ActionTaskList?.length > 0 &&
                  search !== "" &&
                  SearchedTaskList == 0 && (
                    <div className="zeroSearchResult flex-column ">
                      <div className="f-16">
                        {" "}
                        No task found for the given search
                      </div>
                      <button
                        className="replotposture mt-4"
                        onClick={() => {

                          if(allowDrilldownActionCentre?.Option===false){
                            setShowUpgradeToPremiumModal(true)
                            return;
                          }
                          setSelectedModule({});

                          setSelectedStatus(
                            statusList?.filter(
                              (item) => item.DDLText.toLowerCase() == "new"
                            )[0] || {
                              ObjId: 0,
                              DDLId: 13362,
                              DDLText: "New",
                              DDLSort: 0,
                              DDLValue: 3,
                              DDLColour: "#B6B6BF",
                              DDLImage: "NA",
                            }
                          );
                          if (stateVal.activeTab == "all") {
                            setSelectedSection({});
                            setUpdatedTaskTitle("");
                          } else {
                            setSelectedSection(selectedSectionTab);
                            setIsEditTaskTitle(true);
                            setUpdatedTaskTitle("Title");
                          }

                          setSelectedOwner({});
                          setSelectedTaskDate("");
                          setSelectedTaskDetails("");
                          taskDetailRef.current.reset();
                          setSelectedTaskTitle("Untitled");

                          setSelectedTaskId("");
                          setCreateTask(true);
                          setNewTaskTitle(true);
                          setStateVal({ ...stateVal, showSidebar: true });
                          setSelectedTask({});
                        }}
                      >
                        <img
                          alt=""
                          src="/images/actioncenter/plus.svg"
                          className="pr-1"
                        />
                        Create Task
                      </button>
                    </div>
                  )}

                {fetchingNewTasks && (
                  <div className="d-flex align-items-center">
                    <Spinner
                      size="sm"
                      className="mr8px- ml-2 my-2"
                      animation="border"
                      variant="secondary"
                    />
                    <span className="ml-2" style={{ color: "#9499AA" }}>
                      Loading more tasks...
                    </span>
                  </div>
                )}

                {sectionTabLoading &&
                  (stateVal.showSidebar ? (
                    <SplitSectionTabLoader />
                  ) : (
                    <SectionTabLoader />
                  ))}

                {(actionCentreData?.NoData === true ||
                  !DataInSpecificSection) &&
                  !sectionTabLoading &&
                  search == "" && (
                    <div>
                      {
                        <div className="row mt-5 pt-5">
                          <div className="text-center mx-auto mt-5">
                            <p className="heading3 mb-2">
                              There's no tasks to show yet...
                            </p>
                            <span className="greyText">
                              Create a task in this section to get started
                            </span>
                            <br />
                            <button
                              className="replotposture mt-4"
                              onClick={() => {
                                if(allowDrilldownActionCentre?.Option===false){
                                  setShowUpgradeToPremiumModal(true)
                                  return;
                                }
                                setSelectedModule({});

                                setSelectedStatus(
                                  statusList?.filter(
                                    (item) =>
                                      item.DDLText.toLowerCase() == "new"
                                  )[0] || {
                                    ObjId: 0,
                                    DDLId: 13362,
                                    DDLText: "New",
                                    DDLSort: 0,
                                    DDLValue: 3,
                                    DDLColour: "#B6B6BF",
                                    DDLImage: "NA",
                                  }
                                );
                                if (stateVal.activeTab == "all") {
                                  setSelectedSection({});
                                  setUpdatedTaskTitle("");
                                } else {
                                  setSelectedSection(selectedSectionTab);
                                  setIsEditTaskTitle(true);
                                  setUpdatedTaskTitle("Title");
                                }

                                setSelectedOwner({});
                                setSelectedTaskDate("");
                                setSelectedTaskDetails("");
                                taskDetailRef.current.reset();
                                setSelectedTaskTitle("Untitled");

                                setSelectedTaskId("");
                                setCreateTask(true);
                                setNewTaskTitle(true);
                                setStateVal({ ...stateVal, showSidebar: true });
                                setSelectedTask({});
                              }}
                            >
                              <img
                                alt=""
                                src="/images/actioncenter/plus.svg"
                                className="pr-1"
                              />
                              Create Task
                            </button>
                          </div>
                        </div>
                      }
                      <div className="content_table mt-2">
                        {true &&
                          /*tabNames[stateVal.activeTab]["tabledata"].length > 0*/ false && (
                            <div
                              className={`tableheader ${
                                stateVal.showSidebar ? "d-none-" : ""
                              }`}
                            >
                              <div className="table_item">
                                Title---
                                <img
                                  alt=""
                                  src="/images/actioncenter/arrowdown.svg"
                                  className="ml-2"
                                />
                              </div>
                              <div className="table_item">Due Date</div>
                              <div className="table_item">Owner</div>
                              <div className="table_item">Module</div>
                              <div className="table_item">Status</div>
                              <div className="table_item" />
                            </div>
                          )}

                        {/* Active tabs data */}

                        {tabNames?.map((tab, index) => (
                          <div key={index}>
                            {tab?.tabledata?.map((val, i) => (
                              <div
                                className={`tablerow ${
                                  stateVal.activeTab == index ? "" : "d-none"
                                } ${stateVal.showSidebar ? "tablerow2" : ""}`}
                                key={i}
                              >
                                <div
                                  className="table_item"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (stateVal.isArchivedTasks) {
                                      return false;
                                    }
                                    const oldValues = [...tabnames];
                                    oldValues[stateVal.activeTab]["tabledata"][
                                      i
                                    ]["checkbox"] =
                                      !oldValues[stateVal.activeTab][
                                        "tabledata"
                                      ][i]["checkbox"];
                                    setTabNames(oldValues);
                                  }}
                                >
                                  {stateVal.isArchivedTasks ? (
                                    <img
                                      alt=""
                                      src="/images/actioncenter/archived.svg"
                                      height="11"
                                    />
                                  ) : (
                                    <Form.Group className="round-checkbox titleCheckbox">
                                      <Form.Check
                                        type="checkbox"
                                        label=""
                                        checked={val.checkbox}
                                        onChange={(e) => {
                                          e.preventDefault();
                                        }}
                                      />
                                    </Form.Group>
                                  )}

                                  <span
                                    className={`${
                                      stateVal.isArchivedTasks
                                        ? "pl-3"
                                        : "ml-4 pl-2"
                                    }`}
                                  >
                                    {val.title}
                                  </span>
                                </div>
                                <div className="table_item">{val.date}</div>
                                <div className="table_item">
                                  <span className="prfimg">
                                    <img alt="" src={val.img} />
                                  </span>

                                  {!stateVal.showSidebar && val.owner}
                                </div>
                                <div
                                  className={`table_item  ${
                                    stateVal.showSidebar ? "d-none" : ""
                                  }`}
                                >
                                  <img
                                    alt=""
                                    src="/images/actioncenter/compliancemanager.svg"
                                    className="mr-2"
                                  />
                                  {val.module}
                                </div>
                                <div className="table_item">
                                  <div
                                    className="tableStatus"
                                    style={{
                                      backgroundColor: val.statusbg,
                                      color: val.statusclr,
                                    }}
                                  >
                                    <div
                                      className="tablecircle"
                                      style={{ backgroundColor: val.statusclr }}
                                    />
                                    {val.status}
                                  </div>
                                </div>
                                <div
                                  className={`table_item details  ${
                                    stateVal.showSidebar ? "d-none" : ""
                                  }`}
                                >
                                  <span className="detailsinner">
                                    Details
                                    <img
                                      alt=""
                                      src="/images/actioncenter/arrowright.svg"
                                      className="ml-2"
                                    />
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
              </div>
              {/* Sidebar */}
              <div
                className={`tabSidebar archived_task  ${
                  stateVal.showSidebar
                    ? "move-right scrolling-div-issue"
                    : "d-none "
                }`}
                style={{
                  pointerEvents: `  ${selectedTask?.Archived ? "none" : "all"}`,
                }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  {isEditTaskTitle ? (
                    <input
                      type="text"
                      className="edit-task-title flex-grow-1 mr-5"
                      value={updatedTaskTitle}
                      onChange={(e) => {
                        setUpdatedTaskTitle(e.target.value);
                      }}
                      onBlur={() => {
                        UpdateTask("Title", updatedTaskTitle, "String");
                        setIsEditTaskTitle(false);
                        setNewTaskTitle(false);
                      }}
                      autoFocus
                    />
                  ) : (
                    <p
                      className={`title m-0 text-ellipsis flex-grow-1 mr-5 task-title ${
                        selectedTask?.Archived && "archived-text--"
                      }   `}
                      style={{ color: `${newTaskTitle ? "#9499AA" : ""}` }}
                      onClick={() => {
                        setIsEditTaskTitle(true);
                        setUpdatedTaskTitle(
                          updatedTaskTitle || selectedTaskTitle
                        );
                      }}
                    >
                      {/* {TrimString(
                        `${
                          updatedTaskTitle || selectedTaskTitle || "Task Title"
                        }`,
                        30
                      )} */}

                      {updatedTaskTitle || selectedTaskTitle || "Task Title"}
                    </p>
                  )}
                  <div>
                    <div className="inner_header d-flex align-items-center">
                      <div className="pl-3">
                        {/* <img alt=""
                          onClick={() =>
                            setStateVal({ ...stateVal, isArchivedTasks: false })
                          }
                          src="/images/actioncenter/asanaok.svg"
                          className={`pr-4 crsrpoint ${
                            stateVal.isArchivedTasks ? "d-none" : ""
                          }`}
                        /> */}

                        {selectedTaskId && (
                          <img
                            alt=""
                            onClick={() => {
                              // ArchiveTaskFunc();

                              changeArchiveStatus(0);
                              // let updated_data = { ...actionCentreData };
                              // const Index =
                              //   updated_data.ActionTaskList.findIndex(
                              //     (item) => item.ObjectId == selectedTaskId
                              //   );
                              // updated_data.ActionTaskList[
                              //   Index
                              // ].Archived = true;
                              // setSelectedTask({
                              //   ...selectedTask,
                              //   Archived: true,
                              // });
                              // setActionCentreData(updated_data);
                            }}
                            src="/images/actioncenter/folder.svg"
                            className={`pr-4 crsrpoint ${
                              selectedTask?.Archived ? "d-none" : ""
                            }`}
                          />
                        )}
                        <img
                          alt=""
                          src="/images/actioncenter/delete.svg"
                          className={`pr-3 pointer unarchived ${
                            selectedTaskId ? "" : "d-none"
                          }`}
                          style={{ borderRight: "1px solid #EBEDF1" }}
                          onClick={() => {
                            DeleteTaskFunc(
                              "You are about to delete this task.",
                              selectedTaskId
                            );
                          }}
                        />
                        <img
                          alt=""
                          src="/images/settings-v2/create-task-cross.svg"
                          className="crsrpoint ml-2 unarchived"
                          onClick={() => {
                            setCommentsData({});
                            setSelectedTaskId("");
                            setStateVal({
                              ...stateVal,

                              showSidebar: false,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="mt-4" />

                {/* sidebar tab content */}

                <Row>
                  {
                    //  Archived Task Message
                    true && (
                      <Col
                        xs={12}
                        className={`${
                          !selectedTask?.Archived ? "d-none" : ""
                        } mb-2`}
                      >
                        <div className="archivemsg">
                          <div className="f-500 d-flex align-items-center">
                            <img
                              alt=""
                              className="mr-2"
                              src="/images/actioncenter/archived.svg"
                            />
                            <div className="text-set">
                              This Task is archived
                            </div>
                          </div>
                          <div
                            className="unarchivetxt crsrpoint unarchived f-500 d-flex align-items-center"
                            onClick={() => {
                              changeArchiveStatus(1);
                              // UnArchiveTaskFunc();
                              // setSelectedTask({
                              //   ...selectedTask,
                              //   Archived: false,
                              // });
                            }}
                          >
                            <img
                              alt=""
                              className="mr-2"
                              src="/images/settings-v2/unarchive.svg"
                              height="12"
                            />
                            <div className="text-set">Unarchive</div>
                          </div>
                        </div>
                      </Col>
                    )
                  }

                  <>
                    {gettingComments ? (
                      <></>
                    ) : (
                      // <ContentLoader
                      //   speed={2}
                      //   width={"98%"}
                      //   //   height={"100%"}
                      //   viewBox="29 10 1500 110"
                      //   backgroundColor="#F6F8FB"
                      //   foregroundColor="#ecebeb"
                      // >
                      //   <rect
                      //     x="5%"
                      //     y="10"
                      //     rx="8"
                      //     ry="8"
                      //     width="100%"
                      //     height="80"
                      //   />
                      // </ContentLoader>
                      <>
                        {Object.keys(commentsData).length > 0 &&
                          commentsData?.ShowSyncMessage &&
                          selectedTaskId && (
                            <Col xs={12} className="mb-2">
                              {!syncing && (
                                <>
                                  {commentsData?.SyncHeader_Logo_Left ==
                                  "syncing.svg" ? (
                                    <div
                                      style={{ background: "#F6F8FB" }}
                                      className="p-2 d-flex align-items-center justify-content-start radius-4"
                                    >
                                      <Spinner
                                        size="sm"
                                        className="mr8px ml-2 mr-2"
                                        animation="border"
                                        variant="secondary"
                                      />
                                      <span className="f-500 f-darkgrey ml-3-">
                                        Syncing...
                                      </span>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        background:
                                          commentsData?.SyncHeader_Colour,
                                      }}
                                      className="p-2 d-flex align-items-center justify-content-between radius-4"
                                    >
                                      <div className="d-flex align-items-center">
                                        <img
                                          alt=""
                                          src={`/images/actioncenter/${commentsData?.SyncHeader_Logo_Left}`}
                                        />
                                        <span
                                          className="ml-2"
                                          style={{
                                            color: `${
                                              commentsData?.SyncHeader_Logo_Left ==
                                              "failed.svg"
                                                ? "#F36060"
                                                : "#40D88E"
                                            }`,
                                          }}
                                        >
                                          {
                                            commentsData?.SyncHeader_Message_Left
                                          }
                                        </span>
                                      </div>
                                      <div
                                        className="d-flex align-items-center pointer"
                                        onClick={() => {
                                          SyncTask(selectedTaskId);
                                        }}
                                      >
                                        {commentsData?.SyncHeader_Logo_Right && (
                                          <img
                                            alt=""
                                            src={`/images/actioncenter/${commentsData?.SyncHeader_Logo_Right}`}
                                          />
                                        )}
                                        <span
                                          className="ml-2 mr-2"
                                          style={{ color: "#008FD4" }}
                                        >
                                          {
                                            commentsData?.SyncHeader_Message_Right
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}

                              {syncing && (
                                <div
                                  style={{ background: "#F6F8FB" }}
                                  className="p-2 d-flex align-items-center justify-content-start radius-4"
                                >
                                  <Spinner
                                    size="sm"
                                    className="mr8px ml-2 mr-2"
                                    animation="border"
                                    variant="secondary"
                                  />
                                  <span className="f-500 f-darkgrey">
                                    Syncing...
                                  </span>
                                </div>
                              )}
                            </Col>
                          )}
                      </>
                    )}
                  </>

                  {/* Owner dropdown */}
                  <Col
                    xs={3}
                    className={`d-flex align-items-center mt-2- f-500  ${
                      selectedTask?.Archived && "archived-header"
                    }`}
                  >
                    Task Owner
                  </Col>
                  <Col xs={9}>
                    <Dropdown className="input_custom_dropdown">
                      <Dropdown.Toggle
                        className="p-0 position-relative"
                        style={{ zIndex: 1 }}
                      >
                        {Object.keys(selectedOwner).length > 0 ? (
                          <div className="dropdown_input drop_border">
                            <Badge
                              className="custombadge d-flex align-items-center"
                              style={{
                                opacity: `${selectedTask.Archived ? 0.5 : 1}`,
                              }}
                            >
                              {selectedOwner?.OwnerShowInitals === true ? (
                                <ImageWithInitials
                                  initials={selectedOwner?.OwnerInitials}
                                  width={20}
                                  background={"purple"}
                                />
                              ) : selectedOwner?.OwnerLogo == null ? (
                                <img
                                  alt=""
                                  src="/images/settings-v2/highground-dp.svg"
                                />
                              ) : (
                                <img
                                  alt=""
                                  src={selectedOwner?.OwnerLogo}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src =
                                      "/images/settings-v2/highground-dp.svg";
                                  }}
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    borderRadius: "10px",
                                    objectFit: "contain",
                                  }}
                                />
                              )}
                              <div className="ml-1">
                                {selectedOwner?.OwnerName}
                              </div>
                              <img
                                alt=""
                                src="/images/actioncenter/cross.svg"
                                className="crossimg"
                                onClick={() => setSelectedOwner({})}
                              />
                            </Badge>
                          </div>
                        ) : (
                          <input
                            type="text"
                            className="dropdown_input"
                            value=""
                            placeholder="Select"
                          />
                        )}
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        className="BillingInfo"
                        style={{ height: "150px" }}
                      >
                        {ownerList?.map((owner, i) => (
                          <div
                            className="child_drop d-flex align-items-center"
                            key={i}
                            onClick={(e) => {
                              e.target
                                .closest(".input_custom_dropdown")
                                .click();
                              setSelectedOwner(owner);
                              UpdateTask(
                                "OwnerUserName",
                                owner?.OwnerUserName,
                                "String"
                              );
                            }}
                          >
                            {owner?.OwnerShowInitals === true ? (
                              <ImageWithInitials
                                initials={owner?.OwnerInitials}
                                width={20}
                                background={"purple"}
                              />
                            ) : owner?.OwnerLogo == null ? (
                              <img
                                alt=""
                                src="/images/settings-v2/highground-dp.svg"
                              />
                            ) : (
                              <img
                                alt=""
                                src={owner?.OwnerLogo}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src =
                                    "/images/settings-v2/highground-dp.svg";
                                }}
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "10px",
                                  objectFit: "contain",
                                }}
                              />
                            )}
                            &nbsp;
                            <div>{owner?.OwnerName}</div>
                          </div>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>

                  {/* status */}
                  <Col
                    xs={3}
                    className={`d-flex align-items-center mt-2 f-500  ${
                      selectedTask?.Archived && "archived-header"
                    }`}
                  >
                    Status
                  </Col>
                  <Col xs={9} className="mt-2">
                    <Dropdown className="input_custom_dropdown">
                      <Dropdown.Toggle
                        className="p-0 position-relative"
                        style={{ zIndex: 1 }}
                      >
                        {Object.keys(selectedStatus).length > 0 ? (
                          <div className="dropdown_input drop_border">
                            <div
                              className={`${
                                stateVal.isArchivedTasks ? "archived" : ""
                              }  tableStatus`}
                              style={{
                                backgroundColor: `${
                                  selectedTask.Archived
                                    ? "#616778"
                                    : selectedStatus?.DDLColour
                                }40`,
                                color: `${
                                  selectedTask.Archived
                                    ? "#616778"
                                    : selectedStatus?.DDLColour
                                }`,
                              }}
                            >
                              <div
                                className={`tablecircle ${
                                  stateVal.isArchivedTasks ? "archived" : ""
                                }`}
                                style={{
                                  backgroundColor: `${
                                    selectedTask.Archived
                                      ? "#616778"
                                      : selectedStatus?.DDLColour
                                  }`,
                                }}
                              />
                              {selectedStatus?.DDLText}
                            </div>
                          </div>
                        ) : (
                          <input
                            type="text"
                            className="dropdown_input"
                            value=""
                            placeholder="Select"
                          />
                        )}
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        className="BillingInfo"
                        style={{ height: "150px" }}
                      >
                        <div className="tableStatus greyclr2 pl-3">
                          Select or create status:
                        </div>
                        <div className="tableStatus greyclr2 pl-3 d-block mt-2 crsrpoint">
                          {createStatus ? (
                            <div className="navtabinput">
                              <input
                                type="text"
                                value={newStatus}
                                className="w-100"
                                onBlur={() => {
                                  setCreateStatus(false);
                                  setNewStatus("");
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    if (newStatus == "") return;
                                    let AlreadyExistTask = statusList.some(
                                      (item) =>
                                        item.DDLText.toLowerCase() ==
                                        newStatus.toLowerCase()
                                    );
                                    if (AlreadyExistTask) {
                                      CallToast(
                                        "Status name already exist",
                                        false,
                                        false,
                                        "v2style"
                                      );
                                      return;
                                    }

                                    CreateUpdateStatus(0);
                                    setCreateStatus(false);
                                  }
                                }}
                                onChange={(e) => setNewStatus(e.target.value)}
                                autoFocus
                              />
                            </div>
                          ) : (
                            <div
                              // className="dummy-click"
                              onClick={() => {
                                setCreateStatus(true);
                              }}
                            >
                              <img
                                alt=""
                                style={{ top: "-1px", left: "3px" }}
                                src="/images/actioncenter/plusgrey.svg"
                                height="9"
                                className="mr-2 position-relative"
                              />
                              Create
                            </div>
                          )}
                        </div>
                        {statusList?.length > 0 &&
                          statusList?.map((status, i) => {
                            return (
                              <>
                                {editStatusId == status.ObjId ? (
                                  <div className="navtabinput">
                                    <input
                                      type="text"
                                      className="w-100"
                                      value={statusDropdownValue}
                                      onChange={(e) => {
                                        let updated_status_list = [
                                          ...statusList,
                                        ];

                                        let Index = statusList.findIndex(
                                          (item) => item.ObjId === status.ObjId
                                        );
                                        updated_status_list[Index].DDLText =
                                          e.target.value;
                                        setStatusList(updated_status_list);

                                        setStatusDropdownValue(e.target.value);
                                      }}
                                      onBlur={() => {
                                        if (statusDropdownValue.length == 0)
                                          return false;

                                        // CreateUpdateStatus(status?.ObjId);
                                        setEditStatusId("");
                                      }}
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          if (statusDropdownValue.length == 0)
                                            return false;

                                          // CreateUpdateStatus(status?.ObjId);

                                          postRequest(
                                            authTokens,
                                            "CreateActionCustomStatus",
                                            {
                                              ObjectId: status?.ObjId,
                                              StatusName: statusDropdownValue,
                                              // TextColour: color,
                                              Colour: status?.DDLColour,
                                              accessKey: JSON.parse(
                                                localStorage.getItem(
                                                  "user_MessageObj"
                                                )
                                              ),
                                            }
                                          ).then((response) => {
                                            if (response.Success) {
                                              RefreshSection(selectedStatus);
                                            }
                                          });

                                          setEditStatusId("");
                                        }
                                      }}
                                      autoFocus
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className="child_drop d-flex justify-content-between align-items-center less-padding"
                                    key={i}
                                    onClick={(e) => {
                                      e.target
                                        .closest(".input_custom_dropdown")
                                        .click();
                                      setSelectedStatus(status);

                                      UpdateTask(
                                        "Status_Enum",
                                        status?.ObjId || 0,
                                        "Integer"
                                      );
                                    }}
                                  >
                                    <div>
                                      <div
                                        className="tableStatus"
                                        style={{
                                          backgroundColor: `${status?.DDLColour}40`,
                                          color: status?.DDLColour,
                                        }}
                                      >
                                        <div
                                          className="tablecircle"
                                          style={{
                                            backgroundColor: status?.DDLColour,
                                          }}
                                        />
                                        {status?.DDLText}
                                      </div>
                                    </div>
                                    {/* drop down div for status editing--------------------------------------------------------------------------- */}
                                    <div
                                      className={`color-dropdown hidden-dropdown-    ${
                                        status?.DDLAllowUserEdit ? "" : "d-none"
                                      }  `}
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <Dropdown>
                                        <Dropdown.Toggle>
                                          <span className="hidden-dropdown">
                                            <img
                                              alt=""
                                              src="/images/settings-v2/horizontal-dots.svg"
                                            />
                                          </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <div className="choose-color">
                                            Choose Color
                                          </div>
                                          {Colors.map((item) => {
                                            return (
                                              <div
                                                className="d-flex align-items-center my-1 color-wrapper"
                                                onClick={(e) => {
                                                  // CreateUpdateStatus(
                                                  //   status?.ObjId,
                                                  //   item.color
                                                  // );
                                                  postRequest(
                                                    authTokens,
                                                    "CreateActionCustomStatus",
                                                    {
                                                      ObjectId: status?.ObjId,
                                                      StatusName:
                                                        status?.DDLText,
                                                      // TextColour: color,
                                                      Colour: item.color,
                                                      accessKey: JSON.parse(
                                                        localStorage.getItem(
                                                          "user_MessageObj"
                                                        )
                                                      ),
                                                    }
                                                  ).then((response) => {
                                                    if (response.Success) {
                                                      RefreshSection(
                                                        selectedStatus
                                                      );
                                                    } else {
                                                      CallToast(
                                                        "Something went wrong",
                                                        false,
                                                        false,
                                                        "v2style"
                                                      );
                                                    }
                                                  });

                                                  e.target
                                                    .closest(".color-dropdown")
                                                    .click();
                                                }}
                                              >
                                                <div
                                                  className="color-circle"
                                                  style={{
                                                    background: `${item.color}`,
                                                  }}
                                                ></div>
                                                <div className="d-flex justify-content-center align-items-center color-text">
                                                  {" "}
                                                  {item.name}{" "}
                                                </div>
                                              </div>
                                            );
                                          })}
                                          {/* <hr
                                            className="color-divider"
                                            color="#616778"
                                          /> */}
                                          <div className="rename-status-border"></div>
                                          <div
                                            className="color-text my-2 color-wrapper "
                                            onClick={(e) => {
                                              setEditStatusId(status?.ObjId);
                                              e.target
                                                .closest(".color-dropdown")
                                                .click();

                                              let Index = statusList.findIndex(
                                                (item) =>
                                                  item.ObjId === status.ObjId
                                              );

                                              setStatusDropdownValue(
                                                statusList[Index].DDLText
                                              );
                                            }}
                                          >
                                            <img
                                              alt=""
                                              src="/images/settings-v2/edit-icon.svg"
                                              className="mr-2"
                                              // style={{ filter: "invert(1)" }}
                                            />
                                            Rename
                                          </div>
                                          <div
                                            className="color-text my-2 color-wrapper"
                                            onClick={(e) => {
                                              e.target
                                                .closest(".color-dropdown")
                                                .click();

                                              DeleteStatusFunc(
                                                "You are about to delete this status.",
                                                status.ObjId
                                              );
                                            }}
                                          >
                                            <img
                                              alt=""
                                              src="/images/settings-v2/bin.svg"
                                              className="mr-2 mb-1"
                                              // style={{ filter: "invert(1)" }}
                                            />
                                            Delete
                                          </div>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                )}
                              </>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>

                  {/* Date */}
                  <Col
                    xs={3}
                    className={`d-flex align-items-center mt-2 f-500  ${
                      selectedTask?.Archived && "archived-header"
                    }`}
                  >
                    Due Date
                  </Col>
                  <Col
                    xs={9}
                    className="mt-2 datepicker_cutom"
                    style={{
                      opacity: `${selectedTask.Archived ? 0.5 : 1}`,
                    }}
                  >
                    <div
                      className={`${
                        selectedTask?.Archived ? "archived-date" : "d-none"
                      }`}
                    ></div>
                    {selectedTaskDate && (
                      <DatePicker
                        singleDatePicker={true}
                        startDate={`${moment(
                          selectedTaskDate,
                          "DD/MM/YYYY"
                        ).format("MM/DD/YYYY")}`}
                        // startDate={selectedTaskDate}
                        placeholder={selectedTaskDate}
                        onChange={(e) => {
                          setSelectedTaskDate(e);
                          UpdateTask("DueDate", e, "Date");
                        }}
                        value
                      />
                    )}

                    {selectedTaskDate == "" && (
                      <DatePicker
                        singleDatePicker={true}
                        startDate={moment(new Date()).format("MM/DD/YYYY")}
                        placeholder="Choose due date"
                        onChange={(e) => {
                          setSelectedTaskDate(e);
                        }}
                      />
                    )}
                  </Col>

                  {/* Section */}
                  <Col
                    xs={3}
                    className={`d-flex align-items-center mt-2 f-500  ${
                      selectedTask?.Archived && "archived-header"
                    }`}
                  >
                    Section
                  </Col>
                  <Col xs={9} className="mt-2">
                    {sectionList?.length == 0 && (
                      <div className="f-darkgrey ml-2 py-2">
                        No Sections Created Yet
                      </div>
                    )}

                    {sectionList?.length > 0 && (
                      <Dropdown className="input_custom_dropdown">
                        <Dropdown.Toggle
                          className="p-0 position-relative"
                          style={{ zIndex: 1 }}
                        >
                          {Object.keys(selectedSection).length > 0 ? (
                            <div className="dropdown_input drop_border">
                              <Badge
                                className="custombadge pl-2"
                                style={{
                                  opacity: `${selectedTask.Archived ? 0.5 : 1}`,
                                }}
                              >
                                {selectedSection?.DDLText}
                                <img
                                  alt=""
                                  src="/images/actioncenter/cross.svg"
                                  className="crossimg"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    UpdateTask("ObjId_Section", 0, "Integer");

                                    setSelectedSection({});
                                  }}
                                />
                              </Badge>
                            </div>
                          ) : (
                            <input
                              type="text"
                              className="dropdown_input"
                              value=""
                              placeholder="Add section"
                            />
                          )}
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          className="BillingInfo"
                          style={{ height: "150px" }}
                        >
                          {sectionList?.map((section, i) => (
                            <div
                              className="child_drop"
                              key={i}
                              onClick={(e) => {
                                e.target
                                  .closest(".input_custom_dropdown")
                                  .click();
                                setSelectedSection(section);
                                UpdateTask(
                                  "ObjId_Section",
                                  section?.ObjId || 0,
                                  "Integer"
                                );
                              }}
                            >
                              {section?.DDLText}
                            </div>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </Col>

                  {/* module */}
                  <Col
                    xs={3}
                    className={`d-flex align-items-center mt-2 f-500  ${
                      selectedTask?.Archived && "archived-header"
                    }`}
                  >
                    Module
                  </Col>
                  <Col xs={9} className="mt-2">
                    <Dropdown className="input_custom_dropdown module-dropdown-fix">
                      <Dropdown.Toggle
                        className="p-0 position-relative"
                        style={{ zIndex: 1 }}
                      >
                        {Object.keys(selectedModule).length > 0 ? (
                          <div className="dropdown_input drop_border">
                            <Badge
                              className="custombadge2"
                              ref={(el) => {
                                if (el) {
                                  el.style.setProperty(
                                    "color",
                                    `${
                                      selectedTask.Archived
                                        ? "#9499AA"
                                        : selectedModule?.DDLColour
                                    }`,
                                    "important"
                                  );
                                }
                              }}
                            >
                              <img
                                alt=""
                                src={`/images/actioncentrelive/${selectedModule?.DDLImage}`}
                                style={{
                                  filter: "invert(0.4)",

                                  opacity: `${selectedTask.Archived ? 0.5 : 1}`,
                                }}
                                className="mr-2"
                              />
                              {selectedModule?.DDLText}
                              <img
                                alt=""
                                src="/images/actioncenter/cross.svg"
                                className="d-none" //         "crossimg float-right mt-2"
                                onClick={() => setSelectedModule({})}
                              />
                            </Badge>
                          </div>
                        ) : (
                          <input
                            type="text"
                            className="dropdown_input"
                            value={moduleSearch}
                            placeholder="Choose module"
                            onChange={(e) => {
                              setModuleSearch(e.target.value);
                            }}
                          />
                        )}
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        className="BillingInfo"
                        style={{ height: "150px" }}
                      >
                        {moduleList
                          .filter((el) =>
                            el?.DDLText.toLowerCase().includes(
                              moduleSearch.toLowerCase()
                            )
                          )
                          .map((module, i) => (
                            <div
                              className="child_drop"
                              key={i}
                              ref={(el) => {
                                if (el) {
                                  el.style.setProperty(
                                    "color",
                                    `${module?.DDLColour}`,
                                    "important"
                                  );
                                }
                              }}
                              onClick={(e) => {
                                e.target
                                  .closest(".input_custom_dropdown")
                                  .click();
                                // selectValues(val, "moduledropdown");
                                setSelectedModule(module);
                                UpdateTask(
                                  "Module_Enum",
                                  module?.DDLId || 0,
                                  "Integer"
                                );
                                setModuleSearch("");
                              }}
                            >
                              <img
                                alt=""
                                src={`/images/actioncentrelive/${module?.DDLImage}`}
                                className="mr-2"
                                style={{ filter: "invert(0.4)" }}
                              />
                              &nbsp;
                              {module?.DDLText}
                            </div>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>

                {/* <textarea
                  className={`${
                    selectedTask?.Archived ? "archived" : ""
                  } actiontext`}
                  value={selectedTaskDetails}
                  onBlur={() => {
                    UpdateTask("Detail", selectedTaskDetails, "String");
                  }}
                  onChange={(e) => {
                    setSelectedTaskDetails(e.target.value);
                  }}
                  placeholder="Add description to this task"
                ></textarea> */}
                {/* {console.log(
                  "---",
                  getHTMLfromRawState(selectedTaskDetails, mentionList)
                )} */}
                <div
                  onClick={(e) => {
                    console.log(taskDetailRef.current.editor.focus());
                    setShowTaskDescription(false);
                  }}
                  onBlur={() => {
                    setShowTaskDescription(true);
                    UpdateTask("Detail", selectedTaskDetails, "String");
                  }}
                  id={"customdiveditor"}
                  className="addcommentinput placeholder task_description_box"
                >
                  <div
                    className={`task_description_text  ${
                      showTaskDescription ? "" : "d-none"
                    }`}
                  >
                    {getHTMLfromRawState(selectedTaskDetails, mentionList) ===
                      "<p><br></p>" && (
                      <input
                        placeholder="Add a description"
                        className="border-none bg-transparent"
                        disabled
                      />
                    )}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: getHTMLfromRawState(
                          selectedTaskDetails,
                          mentionList
                        ),
                      }}
                    />
                  </div>
               

                  <div
                    className={`${
                      showTaskDescription ? "d-none" : ""
                    } task-detail-editor`}
                  >
                    <PostEditor
                      className="PostEditor__input"
                      placeholder={"Add a description"}
                      getValue={(e) => {
                        setSelectedTaskDetails(JSON.stringify(e));
                      }}
                      mentions={mentionList}
                      ref={taskDetailRef}
                    />
                  </div>
                </div>

                {createTask && (
                  <div
                    className="d-flex align-items-center justify-content-end mt-2"
                    style={{
                      borderTop: "1px solid #EBEDF1",
                      paddingTop: "0.7rem",
                    }}
                  >
                    <Button
                      className="task-cancel-btn mr-2"
                      onClick={() => {
                        setStateVal({ ...stateVal, showSidebar: false });
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      onClick={CreateTask}
                      disabled={creatingNewTask}
                      style={{ background: "#008fd4", fontWeight: "500" }}
                    >
                      {creatingNewTask ? (
                        <div className="d-flex align-items-center">
                          <Spinner
                            size="sm"
                            className="mr8px ml-2 mr-2"
                            animation="border"
                            variant="light"
                          />
                          <span>Saving...</span>
                        </div>
                      ) : (
                        <span>
                          <img
                            alt=""
                            src="/images/settings-v2/white-tick-save.svg"
                            className="mr-1"
                          />
                          Save
                        </span>
                      )}
                    </Button>
                  </div>
                )}

                <div
                  className={`comments_wrapper  ${
                    createTask ? "d-none" : ""
                  }  `}
                >
                  <div
                    className={`${selectedTask?.Archived ? "d-none" : ""}
                
                
               
                    ${!selectedTask?.AutoGenTask_Bool && "d-none"}
                
                 comment_header`}
                  >
                    <div className="greyText2">
                      <img
                        alt=""
                        src="/images/actioncenter/placeholder.svg"
                        className="mr-2"
                      />
                      {selectedTask?.AutoGenTask_Detail?.AGTaskTitle}
                    </div>
                    <div>
                      <button
                        className="replotposture"
                        onClick={() => {
                          history.push(
                            `${selectedTask?.AutoGenTask_Detail?.AGButtonRoute}`
                          );
                        }}
                      >
                        {selectedTask?.AutoGenTask_Detail?.AGButtonText}
                      </button>
                    </div>
                  </div>
                  <hr />

                  <div
                    className={`${
                      !selectedTask?.Archived ? "d-none" : ""
                    } text-center archivedclr mt-4`}
                  >
                    <img
                      alt=""
                      className="mr-2"
                      src="/images/actioncenter/archived.svg"
                      height="15"
                    />
                    Archived task can't be commented
                  </div>

                  <div
                    className={`${
                      selectedTask?.Archived ? "d-none" : ""
                    } addcomments mr-1`}
                  >
                    <div className="addcommentwrap">
                      <div
                        id={"customdiveditor"}
                        className="addcommentinput placeholder"
                      >
                        <PostEditor
                          className="PostEditor__input"
                          placeholder={"Add a comment"}
                          getValue={(e) => {
                            setNewComment(e);
                          }}
                          mentions={mentionList}
                          ref={createCommentRef}
                        />
                      </div>
                      <span
                        onClick={() => {
                          if (getCommentLength(newComment) === 0) {
                            return;
                          } else {
                            setCreatingNewComment(true);
                            CreateEditComment(0, newComment, selectedTaskId);
                          }
                        }}
                        className={`${
                          getCommentLength(newComment) > 0
                            ? "addlabelbtnblu"
                            : ""
                        } addlabelbtn`}
                      >
                        {creatingNewComment ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="light"
                          />
                        ) : (
                          <img
                            alt=""
                            src={`images/actioncenter/${
                              getCommentLength(newComment) > 0
                                ? "arrowwhite"
                                : "arrowgrey"
                            }.svg`}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                  {/*  Comments section */}

                  {gettingComments ? (
                    // <CommentsLoader />

                    <div className="w-100 d-flex align-items-center justify-content-center pt-4 f-grey">
                      Loading comments...
                    </div>
                  ) : (
                    <div
                      className={`usercomments mt-4 ${
                        createTask ? "d-none" : ""
                      }`}
                    >
                      {commentsData?.ShowSyncMessage &&
                        !selectedTask?.Archived && (
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ color: "#9499AA" }}
                          >
                            <img
                              alt=""
                              style={{ width: "24px", height: "24px" }}
                              src={getLogo(commentsData?.SyncLogo)}
                              className="mr-2"
                            />{" "}
                            {/* Synced with */}
                            <strong>
                              &nbsp;{commentsData?.SyncMessage}&nbsp;
                            </strong>{" "}
                            {/* {moment().format("LLL")} */}
                          </div>
                        )}
                      <ListGroup style={{ minHeight: "153px" }}>
                        {commentsData?.TaskCommentGroupList?.map(
                          (CommentList, i) => (
                            <div key={i}>
                              {CommentList?.TaskComments?.length > 0 && (
                                <>
                                  {" "}
                                  <p className="text-center mb-1">
                                    <span className="daytime">
                                      {CommentList?.GroupTitle_Text}
                                    </span>
                                  </p>
                                  <ListGroup.Item>
                                    {CommentList?.TaskComments.map(
                                      (comment, commentIndex) => {
                                        return (
                                          <>
                                            {comment?.CommentType_Text ==
                                              "IndentedComment" && (
                                              <div
                                                className="d-flex justify-content-center align-items-center my-3"
                                                style={{
                                                  color: "#9499AA",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {comment?.TaskOwnerList[0]
                                                  ?.OwnerShowInitals ===
                                                true ? (
                                                  <ImageWithInitials
                                                    initials={
                                                      comment?.TaskOwnerList[0]
                                                        ?.OwnerInitials
                                                    }
                                                    width={16}
                                                    background={"purple"}
                                                  />
                                                ) : comment?.TaskOwnerList[0]
                                                    ?.OwnerLogo == null ||
                                                  comment?.TaskOwnerList[0]
                                                    ?.OwnerLogo == "No URL" ? (
                                                  <img
                                                    alt=""
                                                    src="/images/settings-v2/highground-dp.svg"
                                                    style={{
                                                      width: "16px",
                                                      height: "16px",
                                                      objectFit: "contain",
                                                      borderRadius: "50%",
                                                    }}
                                                  />
                                                ) : (
                                                  <img
                                                    alt=""
                                                    style={{
                                                      width: "16px",
                                                      height: "16px",
                                                      objectFit: "contain",
                                                      borderRadius: "50%",
                                                    }}
                                                    src={
                                                      comment?.TaskOwnerList[0]
                                                        ?.OwnerLogo
                                                    }
                                                    onError={({
                                                      currentTarget,
                                                    }) => {
                                                      currentTarget.onerror =
                                                        null; // prevents looping
                                                      currentTarget.src =
                                                        "/images/settings-v2/highground-dp.svg";
                                                    }}
                                                  />
                                                )}
                                                <strong>
                                                  &nbsp;&nbsp;
                                                  {TrimString(
                                                    comment?.TaskOwnerList[0]
                                                      ?.OwnerName,
                                                    12
                                                  )}{" "}
                                                  &nbsp;
                                                </strong>

                                                <span>{comment?.Comments}</span>
                                              </div>
                                            )}
                                            {comment?.CommentType_Text ==
                                              "FullComment" && (
                                              <div
                                                className={"greyText2"}
                                                style={{ marginBottom: "21px" }}
                                              >
                                                <div className="d-flex justify-content-between">
                                                  <div>
                                                    {comment?.TaskOwnerList[0]
                                                      ?.OwnerShowInitals ===
                                                    true ? (
                                                      <ImageWithInitials
                                                        initials={
                                                          comment
                                                            ?.TaskOwnerList[0]
                                                            ?.OwnerInitials
                                                        }
                                                        width={24}
                                                        background={"purple"}
                                                      />
                                                    ) : comment
                                                        ?.TaskOwnerList[0]
                                                        ?.OwnerLogo == null ||
                                                      comment?.TaskOwnerList[0]
                                                        ?.OwnerLogo ==
                                                        "No URL" ? (
                                                      <img
                                                        alt=""
                                                        src="/images/settings-v2/highground-dp.svg"
                                                        style={{
                                                          width: "24px",
                                                          height: "24px",
                                                          objectFit: "contain",
                                                          borderRadius: "50%",
                                                        }}
                                                      />
                                                    ) : (
                                                      <img
                                                        alt=""
                                                        style={{
                                                          width: "24px",
                                                          height: "24px",
                                                          objectFit: "contain",
                                                          borderRadius: "50%",
                                                        }}
                                                        src={
                                                          comment
                                                            ?.TaskOwnerList[0]
                                                            ?.OwnerLogo
                                                        }
                                                        onError={({
                                                          currentTarget,
                                                        }) => {
                                                          currentTarget.onerror =
                                                            null; // prevents looping
                                                          currentTarget.src =
                                                            "/images/settings-v2/highground-dp.svg";
                                                        }}
                                                      />
                                                    )}
                                                    <span
                                                      className={`${
                                                        selectedTask?.Archived
                                                          ? "archivedclr"
                                                          : ""
                                                      } user_txt d-inline-block ml-2 font-weight-bold`}
                                                    >
                                                      {
                                                        comment
                                                          ?.TaskOwnerList[0]
                                                          ?.OwnerName
                                                      }
                                                    </span>
                                                    <span className="ml-2 user_txt2  d-inline-block">
                                                      {
                                                        comment?.PeriodSinceCreated
                                                      }
                                                    </span>
                                                  </div>

                                                  <div>
                                                    {/* start */}

                                                    <div
                                                      style={{
                                                        float: "right",
                                                      }}
                                                      className="position-relative sideBarDropdown comment-dropdown complianceManagerDropdown actioncenterdropdown"
                                                    >
                                                      <Dropdown>
                                                        <Dropdown.Toggle
                                                          className="p-0 position-relative section-rename-dropdown"
                                                          ref={(el) => {
                                                            if (el) {
                                                              el.style.setProperty(
                                                                "padding",
                                                                "4px 2px 13px 7px",
                                                                "important"
                                                              );
                                                            }
                                                          }}
                                                          style={{
                                                            zIndex: 1,
                                                          }}
                                                        >
                                                          <span className="menudropdown">
                                                            <img
                                                              alt=""
                                                              src="/images/actioncenter/context2.svg"
                                                              className="pr-2 float-right mt-2"
                                                            />
                                                          </span>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu
                                                          className="BillingInfo pointer"
                                                          style={{
                                                            height: "150px",
                                                          }}
                                                        >
                                                          <div
                                                            className="mt-2 child_drop"
                                                            onClick={(e) => {
                                                              e.target
                                                                .closest(
                                                                  ".BillingInfo"
                                                                )
                                                                .closest(
                                                                  ".dropdown"
                                                                )
                                                                .querySelector(
                                                                  ".dropdown-toggle"
                                                                )
                                                                .click();
                                                              setEditCommentId(
                                                                comment?.ObjId_Comments
                                                              );

                                                              setTimeout(() => {
                                                                editCommentRef.current.setValue(
                                                                  JSON.parse(
                                                                    comment.Comments
                                                                  )
                                                                );
                                                              }, 100);
                                                            }}
                                                          >
                                                            Edit
                                                          </div>
                                                          <div
                                                            className="mt-2 child_drop"
                                                            onClick={(e) => {
                                                              e.target
                                                                .closest(
                                                                  ".BillingInfo"
                                                                )
                                                                .closest(
                                                                  ".dropdown"
                                                                )
                                                                .querySelector(
                                                                  ".dropdown-toggle"
                                                                )
                                                                .click();
                                                              DeleteCommentFunc(
                                                                "You are about to delete this comment.",
                                                                comment?.ObjId_Comments
                                                              );
                                                            }}
                                                          >
                                                            Delete
                                                          </div>
                                                        </Dropdown.Menu>
                                                      </Dropdown>
                                                    </div>

                                                    {/* end */}
                                                  </div>
                                                </div>
                                                {editCommentId ==
                                                comment?.ObjId_Comments ? (
                                                  <div>
                                                    <div className="editcomment-wrapper">
                                                      <PostEditor
                                                        className="PostEditor__input editcomment-area"
                                                        placeholder={
                                                          "Edit comment"
                                                        }
                                                        getValue={(e) => {
                                                          setEditComment(e);
                                                        }}
                                                        mentions={mentionList}
                                                        ref={editCommentRef}
                                                      />
                                                    </div>

                                                    {/* End */}
                                                    <div className="d-flex justify-content-end mb-3 mr-3">
                                                      <Button
                                                        className="task-cancel-btn mr-2"
                                                        onClick={() => {
                                                          editCommentRef.current.reset();
                                                          setEditCommentId("");
                                                        }}
                                                      >
                                                        Cancel
                                                      </Button>
                                                      <Button
                                                        disabled={
                                                          editingComment
                                                        }
                                                        style={{
                                                          background: "#008fd4",
                                                          fontWeight: "500",
                                                        }}
                                                        onClick={() => {
                                                          if (editComment == "")
                                                            return;
                                                          CreateEditComment(
                                                            editCommentId,
                                                            editComment,
                                                            selectedTaskId
                                                          );
                                                          setEditingComment(
                                                            true
                                                          );
                                                        }}
                                                      >
                                                        {editingComment ? (
                                                          <div className="d-flex align-items-center">
                                                            <Spinner
                                                              size="sm"
                                                              className="mr8px ml-2 mr-2"
                                                              animation="border"
                                                              variant="light"
                                                            />
                                                            <span>
                                                              Saving...
                                                            </span>
                                                          </div>
                                                        ) : (
                                                          <span>Save</span>
                                                        )}
                                                      </Button>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <p
                                                    className={`${
                                                      selectedTask?.Archived
                                                        ? "archivedclr"
                                                        : ""
                                                    } user_txt mt-2 ml-4 pl-2 mb-2`}
                                                  >
                                                    <span
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          comment?.usefulComment,
                                                      }}
                                                    />

                                                    {/* <span
                                                      className={` ${
                                                        stateVal.isArchivedTasks
                                                          ? "archivedclr2"
                                                          : ""
                                                      } txt-blue`}
                                                    > */}
                                                    {/* {val.user2 && val.user2} */}
                                                    {/* </span> */}
                                                    {/* {comment.Comments} */}
                                                  </p>
                                                )}
                                              </div>
                                            )}
                                          </>
                                        );
                                      }
                                    )}
                                  </ListGroup.Item>
                                </>
                              )}
                            </div>
                          )
                        )}
                      </ListGroup>
                    </div>
                  )}
                </div>

                {/* sidebar tab content */}
              </div>
            </div>

            {/* <div className="tableloader mt-2">
          <div className="d-flex">
            <div className="loader"></div>
            <div className="ml-2">Loading more task...</div>
          </div>
        </div> */}
          </div>
          <WelcomeModal
            showSkipModal={() => setSkipModal(true)}
            isOpen={showWelcomeModal}
            setIsOpen={() => setShowWelcomeModal(false)}
          />
          <SkipModal show={skipModal} isClose={() => setSkipModal(false)} />
          <ActionCenterModal
            isIntegration={stateVal.isIntegration}
            setIntegration={() =>
              setStateVal({ ...stateVal, isIntegration: true })
            }
            integration={() =>
              setStateVal({ ...stateVal, isIntegration: false })
            }
            isOpen={stateVal.actionCenterModal}
            setIsOpen={() =>
              setStateVal({ ...stateVal, actionCenterModal: false })
            }
            setResetModal={() => setStateVal({ ...stateVal, resetModal: true })}
          />
          <ResetModal
            isOpen={stateVal.resetModal}
            setIsOpen={() => setStateVal({ ...stateVal, resetModal: false })}
          />
          <DeleteTaskModal
            show={showDeleteModal}
            hideModal={hideDeleteModal}
            deleteMessage={deleteMessage}
            deleteTask={() => DeleteTask(deleteID)}
            RefreshTaskListData={RefreshTaskListData}
            setStateVal={setStateVal}
            deleteType={deleteType}
            deleteSection={() => DeleteSection(deleteID)}
            deleteStatus={() => DeleteStatus(deleteID)}
            deleteComment={() => DeleteComment(deleteID)}
            archiveTask={() => changeArchiveStatus(archiveStatus)}
            archiveStatus={archiveStatus}
            selectedTaskId={selectedTaskId}
          />
          <UpgradeToPremiumModal
            show={showUpgradeToPremiumModal}
            hideModal={() => setShowUpgradeToPremiumModal(false)}
            upgradeOptions={allowDrilldownActionCentre?.UpgradeOptions}
          />
        </Col>
      )}
    </>
  );
};

export default ActionCenter;



