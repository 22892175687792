import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Row,
  Col,
  Nav,
  Tab,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getAPIData, postData } from "../../../Utils/commonApi";
import { TrimString } from "../../../Utils/StringTrimmer";
import { viewOnlyAccess } from "../../App/App";
import { useAuth } from "../../context/Auth";
import UpgradeToPremiumModal from "../../Integrationsv2/UpgradeToPremiumModal";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { GreyLoader } from "../../Theme/APILoaders";
import QuestionnaireFilterDropdown from "../filterDropDown/QuestionnaireFilterDropdown";
import QuestionnaireTable from "../SupplyCompany/QuestionnaireTable";
import QuestionnaireBigModal from "./QuestionnaireBigModal";

import "./questionnairetab.scss";
import SendQuestionnaireModal from "./SendQuestionnaireModal";

const QuestionnaireTab = forwardRef(
  (
    {
      setPage,
      questionnaireTabSelectedTab,
      setQuestionnaireTabSelectedTab,
      allowDrillDownQuestionnaire,
      setAllowDrillDownQuestionnaire,
    },
    ref
  ) => {
    const { authTokens } = useAuth();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const defaultSelectedTab = searchParams.get("selectedTab");

    const [dataForTable, setDataForTable] = useState([]);
    const [pageLoading, setPageLoading] = useState(false);
    const [questionsData, setQuestionsData] = useState({});
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const [questionnaireSearchString, setQuestionnaireSearchString] =
      useState("");
    const [isSent, setIsSent] = useState(
      questionnaireTabSelectedTab === "Received" ? false : true
    );
    const [IsArchived, setIsArchived] = useState(false);
    const [upgradeOptions, setUpgradeOptions] = useState({});
    const [screenLoaded, setScreenLoaded] = useState(false);
    const [columnSortingLoading, setColumnSortingLoading] = useState(false);

    const [receivedRedDot, setReceivedRedDot] = useState(false);

    // Send questionnaire modal
    const [showSendQuestionnaireModal, setShowSendQuestionnaireModal] =
      useState(false);
    // for sorting Questionnaire
    // Title
    // Supplier
    // Due_Date
    // LastUpdated
    const [sortItem, setSortItem] = useState({
      type: "Title",
      order: 1,
    });

    const [filterParameters, setFilterParameters] = useState([
      { TaskFilter: "created", FilterText_List: [] },
      { TaskFilter: "Title", FilterText_List: [] },
      { TaskFilter: "Due_Date", FilterText_List: [] },
      { TaskFilter: "LastUpdated", FilterText_List: [] },
      { TaskFilter: "Supplier", FilterText_List: [] },
    ]);

    // useEffect(() => {
    //   if (defaultSelectedTab == "questionnaires") {
    //     setPageLoading(true);
    //     getPageData();
    //   }
    // }, [defaultSelectedTab]);

    async function getPageData() {
      await postData(authTokens, "GetSCMQUestionnaireList", {
        QuestionnaireNumber: 0,
        IsArchived: IsArchived,
        SCMQuestionnaireFilter_List: JSON.stringify(filterParameters),
        SCMQuestionnaireSort: sortItem.type,
        SCMQuestionnaireSortOrder: sortItem.order,
        // accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        IsSent: isSent,
      })
        .then((response) => {
          setColumnSortingLoading(false);
          setReceivedRedDot(response?.IsRecievedRedDot);
          setQuestionsData(response);
          setPageLoading(false);
          setScreenLoaded(true);
          if (response?.mr?.Success) {
            setDataForTable(response?.SCMQuestionnaires_List);
            setAllowDrillDownQuestionnaire(
              response?.mr?.AccessObjList?.filter(
                (item) =>
                  item?.Question === "Supply Chain Monitoring Visibility"
              )[0]
            );
            setUpgradeOptions(
              response?.mr?.AccessObjList?.filter(
                (item) =>
                  item?.Question === "Supply Chain Monitoring Visibility"
              )[0]?.UpgradeOptions
            );
          } else {
            // CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          setPageLoading(false);
          setScreenLoaded(true);
          setColumnSortingLoading(false);
          console.log(err);
          // CallToast("Something went wrong", false, false, "v2style");
        });
    }

    useEffect(() => {
      if (defaultSelectedTab == "questionnaires") {
        // setScreenLoaded(true);
        console.log("check");
        // if (!screenLoaded) return;
        setPageLoading(true);
        postData(authTokens, "GetSCMQUestionnaireList", {
          QuestionnaireNumber: 0,
          IsArchived: IsArchived,
          SCMQuestionnaireFilter_List: JSON.stringify(filterParameters),
          SCMQuestionnaireSort: sortItem.type,
          SCMQuestionnaireSortOrder: sortItem.order,
          // accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
          IsSent: isSent,
        })
          .then((response) => {
            setQuestionsData(response);
            setColumnSortingLoading(false);
            setReceivedRedDot(response?.IsRecievedRedDot);
            setPageLoading(false);
            setScreenLoaded(true);
            if (response?.mr?.Success) {
              setDataForTable(response?.SCMQuestionnaires_List);
              setAllowDrillDownQuestionnaire(
                response?.mr?.AccessObjList?.filter(
                  (item) =>
                    item?.Question === "Supply Chain Monitoring Visibility"
                )[0]
              );
              setUpgradeOptions(
                response?.mr?.AccessObjList?.filter(
                  (item) =>
                    item?.Question === "Supply Chain Monitoring Visibility"
                )[0]?.UpgradeOptions
              );
            } else {
              CallToast("Something went wrong", false, false, "v2style");
            }
          })
          .catch((err) => {
            setColumnSortingLoading(false);
            setPageLoading(false);
            console.log(err);
            CallToast("Something went wrong", false, false, "v2style");
          });
      }
    }, [isSent, IsArchived, sortItem, filterParameters, defaultSelectedTab]);

    // Function  to get Archived Task
    useImperativeHandle(ref, () => ({
      getArchivedTask(status = true) {
        setPageLoading(true);
        setIsArchived(status);
      },
      refreshReceivedsentQuestionnaire() {
        getPageData();
      },
    }));

    // Function to sort Columns in Questionnaire
    // Title
    // Supplier
    // Due_Date
    // LastUpdated
    function SortQuestionaireColumn(type) {
      setColumnSortingLoading(true);
      setSortItem((prevSort) => ({
        type: type,
        order: Number(!prevSort.order),
      }));
    }

    // Archive=0
    // Delete=1

    async function ArchiveOrDeleteQuestions(objectId, status) {
      await getAPIData(`${437}`, authTokens, {
        optionEnum1: objectId,
        optionEnum2: status,
      })
        .then((response) => {
          if (response?.Success) {
            if (status === 0)
              CallToast("Archived Successfully", false, false, "v2style");
            if (status === 1)
              CallToast("Deleted Successfully", false, false, "v2style");
            if (status === 2)
              CallToast("Unarchived Successfully", false, false, "v2style");
            getPageData();
          }
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    }

    return (
      <div className="questionnaireTab-wrapper pt-4">
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey={questionnaireTabSelectedTab}
        >
          <Row className="m-0">
            <Col
              sm={12}
              className="ml-3 mb-2 d-flex justify-content-between align-items-center"
            >
              <div
                className="f-500 ml-3 d-flex align-items-center w-100 justify-content-between"
                style={{
                  pointerEvents: `${pageLoading ? "none" : ""}`,
                  cursor: `${pageLoading ? "wait" : ""}`,
                }}
              >
                <div>
                  <Nav
                    variant="pills"
                    className={`flex-row  ${
                      allowDrillDownQuestionnaire?.Option
                        ? ""
                        : "disable-sent-questionnaire-tab"
                    }`}
                    onSelect={(e) => {
                      setQuestionnaireTabSelectedTab(e);

                      if (
                        e === "Received" &&
                        questionnaireTabSelectedTab !== "Received"
                      ) {
                        setIsSent(false);
                        // setPageLoading(true);
                      }
                      if (
                        e === "Sent" &&
                        questionnaireTabSelectedTab !== "Sent"
                      ) {
                        // setPageLoading(true);
                        setIsSent(true);
                      }
                    }}
                  >
                    <Nav.Item
                      className={
                        receivedRedDot
                          ? "position-relative received-message-dot"
                          : ""
                      }
                    >
                      <Nav.Link eventKey="Received">Received</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Sent">Sent</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="d-flex align-items-center mr-4">
                  <div
                    className="mx-2 d-flex align-items-center"
                    style={{
                      pointerEvents: `${
                        allowDrillDownQuestionnaire?.Option ? "all" : "none"
                      }`,
                    }}
                  >
                    <img
                      alt=""
                      src="/images/attack-surface/search-icon.svg"
                      className="pointer"
                      onClick={() => {}}
                    />
                    <input
                      type="text"
                      placeholder="Search.."
                      value={questionnaireSearchString ?? ""}
                      onChange={(e) => {
                        setQuestionnaireSearchString(e.target.value);
                      }}
                      onBlur={() => {}}
                      className={`border-none bg-tranparent pl-2`}
                    />
                  </div>
                  {questionnaireTabSelectedTab === "Sent" && (
                    <OverlayTrigger
                      placement="left"
                      trigger={
                        viewOnlyAccess("AccessState_SCM")
                          ? ["hover", "focus"]
                          : []
                      }
                      delay={{
                        show: 200,
                        hide: 250,
                      }}
                      overlay={
                        <Tooltip
                          id={`tooltip-1365`}
                          className="custom_tooltip_access"
                        >
                          This feature is managed by your service provider
                        </Tooltip>
                      }
                    >
                      <div>
                        <Button
                          className={`mr-4 ${
                            viewOnlyAccess("AccessState_SCM")
                              ? "btn-disable"
                              : "hg-blue-btn "
                          } `}
                          onClick={() => {
                            if (allowDrillDownQuestionnaire?.Option === false) {
                              setShowUpgradeModal(true);
                              return;
                            }
                            setShowSendQuestionnaireModal(true);
                          }}
                        >
                          {viewOnlyAccess("AccessState_SCM") ? (
                            <img
                              alt=""
                              src="/images/attack-surface/small-lock.svg"
                              className="mr-2"
                            />
                          ) : (
                            <></>
                          )}
                          Send Questionnaire
                        </Button>
                      </div>
                    </OverlayTrigger>
                  )}
                  <div
                    className="f-darkgrey mr-2 questionnaire-filter"
                    onClick={(e) => {
                      if (allowDrillDownQuestionnaire?.Option === false) {
                        setShowUpgradeModal(true);
                        return;
                      }
                    }}
                  >
                    {/* {questionsData?.TotalCount > 0 && !pageLoading && ( */}
                    <QuestionnaireFilterDropdown
                      setFilterParameters={setFilterParameters}
                      allowDrillDownQuestionnaire={allowDrillDownQuestionnaire}
                    >
                      Filter
                    </QuestionnaireFilterDropdown>
                    {/* // )} */}
                  </div>
                  <div>
                    {/* <img alt="" src="/images/attack-surface/search-icon.svg" /> */}
                  </div>
                </div>
              </div>
              {/* Right side items at top */}
              <div className="d-flex align-items-center mr-3"></div>
            </Col>
            <Col sm={12}>
              <Tab.Content>
                {/* Received */}
                <Tab.Pane eventKey="Received" className="pl-4 pr-3">
                  {/*  */}

                  {pageLoading ? (
                    <QuestionnairePageLoader />
                  ) : (
                    <QuestionnaireTable
                      hideHeader
                      isReceived={true}
                      suppliersQuestions
                      restrictOperations
                      setPage={setPage}
                      data={dataForTable.filter((item) =>
                        item?.Title?.toLowerCase()?.includes(
                          questionnaireSearchString?.toLowerCase()
                        )
                      )}
                      SortQuestionaireColumn={SortQuestionaireColumn}
                      columnSortingLoading={columnSortingLoading}
                      sortItem={sortItem}
                      NoQuestionnaireState={NoQuestionnaireStateInReceived}
                    />
                  )}
                </Tab.Pane>

                {/* Sent */}

                <Tab.Pane eventKey="Sent" className="pl-4 pr-3">
                  {pageLoading ? (
                    <QuestionnairePageLoader />
                  ) : (
                    <QuestionnaireTable
                      hideHeader
                      clientsQuestions
                      isReceived={false}
                      setPage={setPage}
                      data={dataForTable.filter((item) =>
                        item?.Title?.toLowerCase()?.includes(
                          questionnaireSearchString?.toLowerCase()
                        )
                      )}
                      SortQuestionaireColumn={SortQuestionaireColumn}
                      columnSortingLoading={columnSortingLoading}
                      sortItem={sortItem}
                      ArchiveOrDeleteQuestions={ArchiveOrDeleteQuestions}
                      IsArchived={IsArchived}
                      NoQuestionnaireState={NoQuestionnaireStateInSent}
                      refreshData={getPageData}
                    />
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
        <SendQuestionnaireModal
          show={showSendQuestionnaireModal}
          hideModal={() => setShowSendQuestionnaireModal(false)}
          // title={templateData?.Title}
          // templateId={templateData?.ObjectId}
          refreshData={getPageData}
        />

        <UpgradeToPremiumModal
          show={showUpgradeModal}
          hideModal={() => setShowUpgradeModal(false)}
          upgradeOptions={upgradeOptions}
        />
      </div>
    );
  }
);

export default QuestionnaireTab;

export const QuestionnairePageLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0%" y="10" rx="4" ry="4" width="100%" height="35" />
      <rect x="0%" y="47" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="129" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="211" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="293" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="375" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="457" rx="4" ry="4" width="100%" height="80" />
    </ContentLoader>
  );
};

export const NoQuestionnaireStateInReceived = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-50 mt-5 mx-auto">
      <img
        alt=""
        src="/images/supply-chain/question-list-icon.svg"
        className="no-supplier-icon mb-3"
      />
      <div className="banner-element-name mb-2">No Questionnaires</div>
      <p className="banner-subtitle text-center" onClick={() => {}}>
        You haven't received any questionniares.
      </p>
    </div>
  );
};

export const NoQuestionnaireStateInSent = ({ refreshData }) => {
  const { authTokens } = useAuth();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const defaultSelectedTab = searchParams.get("selectedTab");
  const [showQuestionnaireModal, setShowQuestionnaireModal] = useState(false);
  const [loadingTemplateList, setLoadingTemplateList] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [editTemplateID, setEditTemplateID] = useState();
  useEffect(() => {
    if (defaultSelectedTab == "questionnaires") {
      setLoadingTemplateList(true);
      getTemplateList();
    }
  }, []);

  async function getTemplateList() {
    await getAPIData(304, authTokens)
      .then((response) => {
        setLoadingTemplateList(false);
        if (response.mr.Success) {
          setTemplateList(response?.SCMAllFormTemplate_List);
        }
      })
      .catch((err) => {
        setLoadingTemplateList(false);
        console.log(err);
      });
  }
  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center w-50 mt-5 mx-auto">
        <img
          alt=""
          src="/images/supply-chain/question-list-icon.svg"
          className="no-supplier-icon mb-3"
        />
        <div className="banner-element-name mb-2">No Questionnaires</div>
        <p className="banner-subtitle text-center" onClick={() => {}}>
          Send your first questionnaire by creating a template or using <br />
          one of our pre-built ones
        </p>

        <OverlayTrigger
          placement="left"
          trigger={viewOnlyAccess("AccessState_SCM") ? ["hover", "focus"] : []}
          delay={{
            show: 200,
            hide: 250,
          }}
          overlay={
            <Tooltip id={`tooltip-1365`} className="custom_tooltip_access">
              This feature is managed by your service provider
            </Tooltip>
          }
        >
          <div
            style={{
              width: "40%",
            }}
          >
            <div
              className="p-3 f-500 bg-grey pointer f-blue radius-8 mt-2"
              style={{
                // width: "40%",
                pointerEvents: viewOnlyAccess("AccessState_SCM")
                  ? "none"
                  : "all",
              }}
              onClick={() => {
                setEditTemplateID();
                setShowQuestionnaireModal(true);
              }}
            >
              {viewOnlyAccess("AccessState_SCM") ? (
                <img
                  alt=""
                  src="/images/attack-surface/small-lock.svg"
                  className="mr-2"
                  style={{
                    filter:
                      "invert(43%) sepia(99%) saturate(2553%) hue-rotate(173deg) brightness(91%) contrast(101%)",
                  }}
                />
              ) : (
                <img
                  alt=""
                  className="mr-2"
                  src="/images/actioncenter/plusgrey.svg"
                  style={{
                    filter:
                      "invert(43%) sepia(99%) saturate(2553%) hue-rotate(173deg) brightness(91%) contrast(101%)",
                  }}
                />
              )}
              Create new questionnaire
            </div>
          </div>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          trigger={viewOnlyAccess("AccessState_SCM") ? ["hover", "focus"] : []}
          delay={{
            show: 200,
            hide: 250,
          }}
          overlay={
            <Tooltip id={`tooltip-1365`} className="custom_tooltip">
              This feature is managed by your service provider
            </Tooltip>
          }
        >
          <div style={{ width: "40%" }}>
            <div
              className=" radius-8 mt-1 bg-grey"
              style={{
                pointerEvents: viewOnlyAccess("AccessState_SCM")
                  ? "none"
                  : "all",
              }}
            >
              {loadingTemplateList && (
                <div className="d-flex align-items-center justify-content-center my-3">
                  <GreyLoader width={17} />
                </div>
              )}
              {templateList?.length > 0 && (
                <>
                  <div className="f-12 f-500 f-darkgrey py-2 pl-3">
                    Create from template
                  </div>

                  {templateList?.slice(0, 3)?.map((template, templateIndex) => {
                    return (
                      <>
                        <div
                          className="pointer d-flex align-items-center f-500 pl-3"
                          onClick={() => {
                            setEditTemplateID(template.ObjectId);
                            setShowQuestionnaireModal(true);
                          }}
                        >
                          <img
                            alt=""
                            className="mr-2"
                            src="/images/supply-chain/template-icon.svg"
                          />
                          {TrimString(template?.Title, 25)}
                        </div>
                        <hr />
                      </>
                    );
                  })}

                  <div
                    className="pointer d-flex f-darkgrey align-items-center f-500 pl-3 pb-3"
                    onClick={() => {
                      setEditTemplateID();
                      setShowQuestionnaireModal(true);
                    }}
                  >
                    <img
                      alt=""
                      className="mr-2"
                      src="/images/attack-surface/grey-goto.svg"
                    />
                    Browse all templates
                  </div>
                </>
              )}
            </div>
          </div>
        </OverlayTrigger>
      </div>
      <QuestionnaireBigModal
        show={showQuestionnaireModal}
        hideModal={() => setShowQuestionnaireModal(false)}
        refreshData={refreshData}
        editTemplateId={editTemplateID}
      />
    </>
  );
};
