import { SEGMENT_DETAIL } from "./constants";

/**
 * Increments in calculator value
 *
 * @param {Object} data
 */

export const setsegment = (data) => {
  return { type: SEGMENT_DETAIL, data };
};
