import React, { useState } from "react";
import ImageWithInitials from "../../../Utils/ImageWithInitials";
import "../supplier-profiler.scss";

const SummaryBlock = ({
  title,
  setShowProviderModal,
}) => {
  const [updateState, setUpdateState] = useState(false);

  return (
    <div
      className="d-flex justify-content-center"
      style={{ width: "1024px", padding: "24px" }}
    >
      <div className="d-flex flex-column justify-content-center align-items-center w-100">
        <div
          className="d-flex flex-row w-100 justify-space-between header mb-2"
          style={{ justifyContent: "space-between" }}
        >
          <div className="title">
            <div className="f-16">{title.Title}</div>
            <div className="f-darkgrey">{title.Description}</div>
          </div>
        </div>

        <div className=" w-100 grid-container">
          {/* <div className="flex-row item-question ">
                              <div  className="f-grey">Summary</div>
                              <div className={`${newFields ? '' : 'f-darkgrey'}`} style={{width: '610px'}}>
                                  <textarea
                                  type="text"
                                  rows={Math.ceil(newFields.length / 70)}
                                  className="edit-task w-100"
                                  value={newFields}
                                  placeholder="Summary"
                                  onChange={(e) => {
                                    setNewFields(e.target.value);
                                  }}
                                />
                              </div> 
                            </div> */}
        </div>
      </div>
    </div>
  );
};

export default SummaryBlock;
