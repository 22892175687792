import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import "./modals.scss";
import moment from "moment";
import ContentLoader from "react-content-loader";
import {
  getAllQuestions,
  getCaptureAutomatically,
  postRequest as SubmitNotificationSettings,
  SubmitQuestionaire,
} from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import Loader from "../../Common/loader";

import { GreyLoader } from "../../Theme/APILoaders";
function QuestionaireModal(props) {
  const {
    showQuestionModal,
    hideQuestionModal,
    loadChart,
    setValues,
    currentPostureStatus,
    refreshScenarioList,
  } = props;
  const { authTokens } = useAuth();
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [refreshingQuestionStatus, setRefreshingQuestionStatus] =
    useState(false);

  const SetQuestions = () => {
    setLoading(true);
    getAllQuestions(authTokens, 212, 0)
      .then((response) => {
        if (response.QuestionnaireSection_List === null) {
          setLoading(false);
          return false;
        }
        setQuestionData(response);
        setLoading(false);
        setPage(page + 1);
        loadChart();
      })
      .catch((err) => {
        console.log("questions-error", err);
        setLoading(false);
      });
  };

  const RefreshQuestionStatus = () => {
    setRefreshingQuestionStatus(true);
    getAllQuestions(authTokens, 212, 0)
      .then((response) => {
        setModalLoading(false);
        setRefreshingQuestionStatus(false);
        if (response.QuestionnaireSection_List === null) {
          return false;
        }
        setQuestionData(response);
        loadChart();
      })
      .catch((err) => {
        console.log("questions-error", err);
      });
  };

  useEffect(() => {
    if (showQuestionModal && currentPostureStatus) {
      setOption("question");
    }
  }, [showQuestionModal]);

  const [questions, setQuestions] = useState();
  const [questionData, setQuestionData] = useState("");
  const [page, setPage] = useState(0);
  const [disabled, setDisabled] = useState(/**!currentPostureStatus*/ false);
  const [completed, setCompleted] = useState(false);
  const [option, setOption] = useState("");
  const [disabledCapture, setDisabledCapture] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);

  const data = [
    {
      header: "Current Posture",
      title: "Plot current security posture",
      subtitle:
        "We can plot your current cyber security posture on the cyber radar chart as a reference point.",
    },
    {
      header: "Quick Questionnaire",
      title: "Answer on the following questions",
      subtitle:
        "Some description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus viverra a elit blandit in sed nisl felis vitae. Tortor sit et egestas sem duis. Sit enim ultric.",
    },
    {
      header: "",
    },
  ];
  const [details, setDetails] = useState(data);

  const QuestionData = {
    ObjectId: 0,
    QuestionnaireTypeEnum: 6,
    QuestionList: JSON.stringify(questions?.Question_List),
    accesskey: JSON.parse(localStorage.getItem("user_MessageObj")),
  };

  const totalSections = questionData?.QuestionnaireSection_List?.length;
  const completedSections = questionData?.QuestionnaireSection_List?.filter(
    (section) => section.ProgressText === "Complete"
  )?.length;

  const circleIndicator = 5 - completedSections;

  const SubmitQuestions = async () => {
    await SubmitQuestionaire(authTokens, "POST", QuestionData)
      .then(async (res) => {
        if (!res.Success) return false;
        setValues.setState({ test: false });

        await getAllQuestions(authTokens, 224, 0).then((postureData) => {
          let Data = [
            postureData?.CurrentPosture?.Identity,
            postureData?.CurrentPosture?.Protect,
            postureData?.CurrentPosture?.Detect,
            postureData?.CurrentPosture?.Respond,
            postureData?.CurrentPosture?.Recover,
          ];

          let dataset_data = [...setValues.state.datasets];
          dataset_data[0].data = Data;
          setValues.setState({ datasets: dataset_data, test: true });
        });
      })
      .catch((err) => console.log(err));
  };

  const CaptureDataFromHighground = () => {
    setLoading(true);
    getCaptureAutomatically(authTokens, 229, 3189312)
      .then((response) => {
        setQuestionData(response);
        setPage(1);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const resetModal = () => {
    setCompleted(false);
    setPage(0);
    setOption("");
    setDisabledCapture(false);
    hideQuestionModal();

    setCurrentPage(0);
  };

  const postDatawithYES = {
    SettingOwner: 0,
    OwnerType: 1,
    NotificationName: "ShowCurrentPosture",
    NotificationValue: 1,
    AccessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
  };
  const postDatawithNO = {
    SettingOwner: 0,
    OwnerType: 1,
    NotificationName: "ShowCurrentPosture",
    NotificationValue: 0,
    AccessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
  };

  useEffect(() => {
    if (showQuestionModal) {
      setDisabled(!currentPostureStatus);
      // setDisabled(false);
      RefreshQuestionStatus();
      setModalLoading(true);
    }
  }, [showQuestionModal]);

  return (
    <Modal
      // show={true}
      show={showQuestionModal}
      dialogClassName="modal-50w"
      className=" questionaireModal integrationModal centerModal dashboardModal upgradePremium"
      aria-labelledby="asset-modal"
      centered
      scrollable
      onHide={() => {}}
    >
      <Modal.Header className="text-center ">
        {page !== 0 && (
          <span className="cursor-pointer" onClick={() => setPage(page - 1)}>
            <img src="/images/leftarrow.png" alt="" />
          </span>
        )}

        <div className="w-100 mb-0 font-bold-14">
          {page < 2 && details && details[page].header}
          {page === 2 &&
            questionData?.QuestionnaireSection_List[currentPage]?.Title}
        </div>

        <span className="cursor-pointer" onClick={resetModal}>
          <img src="/images/icons/close.svg" alt="" />
        </span>
      </Modal.Header>

      <Modal.Body>
        {modalLoading && (
          <div className="questionnarire-loader">
            <Loader />
          </div>
        )}
        <Row className="">
          <Col xs={12} className="mb-3">
            <div className="d-flex justify-content-around align-items-start">
              <div>
                <p className="font-bold-14">{details[page].title}</p>
                <p className="subtitle">{details[page].subtitle}</p>
              </div>
              <div>
                {page === 0 && (
                  <Form.Group controlId={"check"} className="">
                    <Form.Check
                      type="switch"
                      id="switch-ques"
                      className="m-0 scale-switch switch-shadow-none"
                      checked={!disabled}
                      onChange={(e) => {
                        setDisabled(!e.target.checked);
                        if (e.target.checked === true) {
                          SubmitNotificationSettings(
                            authTokens,
                            "SubmitNotificationSettings",
                            postDatawithYES
                          ).then((res) => {
                            if (res.Success) {
                              loadChart();
                            }
                          });
                        }

                        if (e.target.checked === false) {
                          SubmitNotificationSettings(
                            authTokens,
                            "SubmitNotificationSettings",
                            postDatawithNO
                          ).then((res) => {
                            if (res.Success) {
                              loadChart();
                            }
                          });
                        }
                      }}
                    />
                  </Form.Group>
                )}
              </div>
            </div>
          </Col>

          <Col xs={12}>
            {page === 0 && (
              <>
                <Form>
                  <div
                    className="d-flex p-3 pointer"
                    onClick={() => {
                      if (!disabled) {
                        setOption("question");
                        if (!currentPostureStatus) {
                          SetQuestions();
                        }
                      }
                    }}
                  >
                    {/* <Form.Group controlId={"radio"} className=""> */}

                    <Form.Check
                      type="radio"
                      disabled={disabled}
                      className=" scale-radio"
                      id="1"
                      checked={option == "question" ? true : false}
                      name="qwe"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setOption("question");
                          if (!currentPostureStatus) {
                            SetQuestions();
                          }
                        }
                      }}
                    />
                    {/* </Form.Group> */}
                    <div>
                      <p
                        className={`radio-heading ${
                          disabled ? "disabled" : ""
                        }`}
                      >
                        Quick Questionnaire{" "}
                        <span className="position-relatve">
                          {loading && !currentPostureStatus && (
                            <span>
                              <GreyLoader width={14} />
                            </span>
                          )}
                        </span>
                      </p>
                      <p
                        className={`radio-summary subtitle ${
                          disabled ? "disabled" : ""
                        }`}
                      >
                        Answer the questions so we can establish your
                        {option == "question" &&
                          !disabled &&
                          currentPostureStatus &&
                          completedSections < totalSections && <br />}
                        posture
                      </p>
                    </div>

                    {currentPostureStatus && (
                      <>
                        {option == "question" &&
                          !disabled &&
                          (refreshingQuestionStatus ? (
                            <div>
                              <ContentLoader
                                speed={2}
                                width={"100%"}
                                height={"100%"}
                                viewBox="0 0 180 40"
                                backgroundColor="#F6F8FB"
                                foregroundColor="#ecebeb"
                              >
                                <rect
                                  x="28"
                                  y="9"
                                  rx="12"
                                  ry="12"
                                  width="70"
                                  height="24"
                                />
                                <rect
                                  x="109"
                                  y="5"
                                  rx="4"
                                  ry="4"
                                  width="70"
                                  height="30"
                                />
                              </ContentLoader>
                            </div>
                          ) : completedSections < totalSections ? (
                            <span className="d-flex ml-auto align-items-center">
                              <span className="incomplete-status d-flex">
                                <svg
                                  height="16"
                                  width="16"
                                  viewBox="0 0 16 16"
                                  className="circle-indicator mt-1"
                                >
                                  <circle
                                    cx="8"
                                    cy="8"
                                    r="6"
                                    stroke="#f6f6f6"
                                    stroke-width="1.5"
                                    fill="transparent"
                                  />
                                  <circle
                                    cx="8"
                                    cy="8"
                                    r="6"
                                    stroke="#008FD4"
                                    stroke-width="1.5"
                                    fill="transparent"
                                    pathLength={5}
                                    strokeDasharray={5}
                                    strokeDashoffset={circleIndicator}
                                  />
                                </svg>
                                &nbsp;&nbsp;
                                <div className="status">
                                  {completedSections}/{totalSections} Incomplete
                                </div>
                              </span>
                              <span
                                className="change-button"
                                onClick={SetQuestions}
                              >
                                {loading ? (
                                  <>
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    &nbsp;&nbsp;
                                  </>
                                ) : (
                                  ""
                                )}
                                Update
                              </span>
                            </span>
                          ) : (
                            !disabled &&
                            option == "question" && (
                              <span
                                className="change-ques-button"
                                onClick={SetQuestions}
                              >
                                {loading ? (
                                  <div
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ width: "20px", display: "flex" }}
                                  >
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    <span>&nbsp;</span>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div>Change</div>
                              </span>
                            )
                          ))}
                      </>
                    )}
                  </div>
                  <hr style={{ margin: "auto", width: "95%" }} />
                  <div
                    className="d-flex p-3 pointer"
                    style={{
                      pointerEvents: "none",
                    }}
                    onClick={() => {
                      if (!disabled) {
                        setOption("capture");
                      }
                    }}
                  >
                    {/* <Form.Group controlId={"radio"} className=""> */}
                    <Form.Check
                      type="radio"
                      disabled={disabled || disabledCapture || true}
                      id="2"
                      name="qwe"
                      checked={option == "capture" ? true : false}
                      className="scale-radio"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setOption("capture");
                        }
                      }}
                    />
                    {/* </Form.Group> */}
                    <div className="position-relative">
                      <p
                        className={`radio-heading ${
                          disabled || disabledCapture || true ? "disabled" : ""
                        }`}
                      >
                        Capture automatically from HighGround
                      </p>
                      <span
                        className="comingtooltip d-inline-flex align-items-center"
                        style={{
                          position: "absolute",
                          right: "0%",
                          top: "-7%",
                        }}
                      >
                        <img
                          alt=""
                          height="12"
                          src="/images/clock.svg"
                          className="mx-1"
                        />
                        <span className="d-inline-flex align-items-center">
                          Coming
                        </span>
                      </span>
                      <p
                        className={`radio-summary subtitle ${
                          disabled || disabledCapture || true ? "disabled" : ""
                        }`}
                      >
                        Show current pose automatically from the HighGround
                      </p>
                    </div>
                    {option == "capture" && !disabled && (
                      <span
                        className=" capture-automatic"
                        onClick={CaptureDataFromHighground}
                      >
                        {loading ? (
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ width: "20px", display: "flex" }}
                          >
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span>&nbsp;</span>
                          </div>
                        ) : (
                          ""
                        )}
                        <div>Update</div>
                      </span>
                    )}
                  </div>
                </Form>
              </>
            )}

            {page == 1 && (
              <div className="pt-3">
                {[
                  "Identity",
                  "Protection",
                  "Detection",
                  "Incident Response",
                  "Backup & Disaster Recovery",
                ].map((item, index) => {
                  const answeredQuestions =
                    questionData?.QuestionnaireSection_List[
                      index
                    ].Question_List.filter(
                      (ques) => ques.AnswerBool !== null
                    ).length;
                  const totalQuestions =
                    questionData?.QuestionnaireSection_List[index].Question_List
                      .length;

                  return (
                    <div
                      key={index}
                      className="d-flex p-3 ques-type align-items-center"
                      onClick={() => {
                        setCurrentPage(index);
                        setQuestions(
                          questionData?.QuestionnaireSection_List[index]
                        );
                        setDetails((details) => {
                          details[page + 1].header = item;

                          return details;
                        });
                        setPage(page + 1);
                      }}
                    >
                      {true ? (
                        <img
                          alt=""
                          src={`/images/${
                            answeredQuestions == 0
                              ? "plusicon.png"
                              : answeredQuestions < totalQuestions
                              ? "loading.png"
                              : "green-tick.png"
                          }`}
                          className="mr-2"
                          style={{ objectFit: "contain" }}
                        />
                      ) : (
                        <img
                          alt=""
                          src="/images/plusicon.png"
                          className="mr-2"
                          style={{ objectFit: "contain" }}
                        />
                      )}
                      <div
                        className="d-flex flex-column"
                        style={{ fontSize: "16px", fontWeight: "500" }}
                      >
                        <div>{item}</div>
                        {option == "capture" && (
                          <span className="answered-question-detail">
                            (
                            {
                              questionData?.QuestionnaireSection_List[index]
                                ?.PrePopulatedProgressText
                            }
                            )
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            {page == 2 && (
              <div className="pt-2">
                {questions?.Question_List?.map((question, quesNumber) => {
                  if (question?.QuestionTypeText == "YesOrNo") {
                    return (
                      <div key={quesNumber} className="d-flex flex-column pb-5">
                        <p style={{ fontSize: "15px", fontWeight: "500" }}>
                          {quesNumber + 1}. {question?.QuestionText}
                        </p>
                        <div>
                          <Button
                            className={`mr-3 yes-button ${
                              questions.Question_List[quesNumber].AnswerBool
                                ? "selected"
                                : "not-selected"
                            }`}
                            onClick={() => {
                              let Question_List = [...questions.Question_List];
                              Question_List[quesNumber].AnswerBool = true;
                              Question_List[quesNumber].QuestionStatus = 3;

                              setQuestions({ ...questions, Question_List });
                            }}
                          >
                            Yes
                          </Button>

                          <Button
                            className={`mr-3 no-button ${
                              questions.Question_List[quesNumber].AnswerBool ===
                              false
                                ? "selected"
                                : ""
                            }`}
                            onClick={() => {
                              let Question_List = [...questions.Question_List];
                              Question_List[quesNumber].AnswerBool = false;
                              Question_List[quesNumber].QuestionStatus = 3;

                              setQuestions({ ...questions, Question_List });
                            }}
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    );
                  } else {
                    return <></>;
                  }
                })}
              </div>
            )}
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Row className="m-0 ">
          <Col xs={12} className="p-0 d-flex justify-content-end">
            <Button
              className="cancel-button"
              onClick={() => {
                hideQuestionModal();
                resetModal();
              }}
            >
              Close
            </Button>

            {/* Next Button */}

            {page == 2 && currentPostureStatus && (
              <>
                <Button
                  className={`save-button ${
                    currentPage == 4 ? "disabled-btn" : ""
                  }`}
                  onClick={() => {
                    if (currentPage < 4) {
                      if (option == "question") {
                        RefreshQuestionStatus();
                      }
                      SubmitQuestions();
                      setQuestions(
                        questionData?.QuestionnaireSection_List[currentPage + 1]
                      );
                      setCurrentPage(currentPage + 1);
                    } else {
                      return false;
                    }
                  }}
                  disabled={currentPage == 4 ? true : false}
                >
                  Next
                </Button>

                {/* Save & Next */}

                <Button
                  className={`ml-2 save-button ${`'currentPage < 4 ? "disabled-btn" : ""'`}`}
                  onClick={() => {
                    SubmitQuestions();
                    resetModal();
                    // if(option=="question"){
                    //   RefreshQuestionStatus()
                    // }
                  }}
                  // disabled={currentPage < 4 ? true : false}
                >
                  Save & Exit
                </Button>
              </>
            )}

            {page == 2 && !currentPostureStatus && (
              <Button
                className={`ml-2 save-button ${
                  currentPage < 4 ? "disabled-btn=" : ""
                }`}
                onClick={() => {
                  setTimeout(setPage(page - 1), 1500);
                  SubmitQuestions();
                  if (option == "question") {
                    RefreshQuestionStatus();
                  }
                }}
              >
                Save
              </Button>
            )}
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}

export default QuestionaireModal;

const YesNoQuestion = ({ id }) => {
  const [yes, setYes] = useState(true);
  return (
    <div className="d-flex flex-column pb-5">
      <p style={{ fontSize: "15px", fontWeight: "500" }}>
        {id}. Does the business have to meet any Compliance Standards or is
        governed by Industry Bodies? E.G. the FCA.
      </p>
      <div>
        <Button
          className={`mr-3 yes-button ${yes ? "selected" : "not-selected"}`}
          onClick={() => {
            setYes(true);
          }}
        >
          Yes
        </Button>
        <Button
          className={`mr-3 no-button ${yes ? "not-selected" : "selected"}`}
          onClick={() => {
            setYes(false);
          }}
        >
          No
        </Button>
      </div>
    </div>
  );
};

const DateQuestion = ({ id }) => {
  return (
    <div className="d-flex flex-column pb-5">
      <p className="font-500">
        {id}. Does the business have to meet any Compliance Standards or is
        governed by Industry Bodies? E.G. the FCA.
      </p>
      <div>
        <Form.Group>
          <InputGroup className="inptDate date-form">
            <InputGroup.Prepend>
              <InputGroup.Text>Date:</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="date"
              id={"date-question-2"}
              required
              placeholder="sada"
              defaultValue={moment().format("DD MMMM YYYY")}
            />
          </InputGroup>
        </Form.Group>
      </div>
    </div>
  );
};
