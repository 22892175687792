import React, { useState, useEffect,   } from "react";
import "./CyberSavings.css";
import { Col, Row,   } from "react-bootstrap";
import { useDb } from "../context/db";
import CountUp from "react-countup";
import LoadingSpinner from "../Spinner/Spinner";
 

const CyberSavings = (props) => {
  const { dbObj,   } = useDb();
  const [savingNumber, setsavingNumber] = useState(
    props.value ||
      (dbObj.CEOComponents && dbObj.CEOComponents.CyberSavingNumber)
  );
  const [savingDecimal, setsavingDecimal] = useState(" ");
  const [savingNoDecimals, setsavingNoDecimals] = useState(3);
  const [savingSuffix, setsavingSuffix] = useState("");
  const [savingComplete, setsavingComplete] = useState(false);

  useEffect(() => {
    if (savingNumber?.includes("M")) {
      setsavingNumber(
        savingNumber?.replace(",", ".").replace(" ", "").replace("M", "")
      );
      setsavingDecimal(".");
      setsavingNoDecimals(2);
      setsavingSuffix("M");
      setsavingComplete(true);
    } else {
      setsavingNumber(savingNumber?.replace(",", ".").replace(" ", ""));
      setsavingComplete(true);
    }
  }, []);

  
  if (savingComplete) {
    return (
      <Row className="fullHeight cyberSavings">
        {dbObj.SubscriptionDetail && dbObj.SubscriptionDetail.PreDataView ? (
          <>
            <Col md={7} className="my-auto containerPadding">
              <div className="containerPadding">
                <h3 className="cc_text">Cyber Savings</h3>
                <div class="d-flex">
                  <img alt="" src="/images/dashboard/suggestionIcon.svg" />
                  <p className="smallGrey pl-3">Show suggestions</p>
                </div>
              </div>
            </Col>
            <Col xs={5} className="text-center csMain pr-4">
              <div className="csmain_background">
                <div>
                  <img alt="" src="/images/dashboard/tileUpArrow.svg" />
                </div>
                <div
                  className="greyText heading4"
                  style={{ fontSize: "26px", paddingTop: "8px" }}
                >
                  £0
                </div>
                <p className="margin-clear saving-maintext greyText">
                  {savingNumber != 0 ? (
                    <CountUp
                      start={0}
                      end={0}
                      decimal={0}
                      // decimals={savingNoDecimals}
                      prefix="£"
                      suffix={savingSuffix}
                      useEasing={false}
                      duration="2"
                      seperator=""
                    />
                  ) : (
                    ""
                  )}
                  {/* {dbObj.SubscriptionDetail.PreDataView ? ( */}
                  {/* <CountUp
                    start={0}
                    end={0}
                    decimal={savingDecimal}
                    prefix="£"
                    suffix={savingSuffix}
                    useEasing={false}
                    duration="2"
                    seperator=""
                  /> */}
                  {/* ) : (
                    ""
                  )} */}
                </p>

                {/* {dbObj.SubscriptionDetail.PreDataView ? ( */}
                <img
                  alt=""
                  className="logo"
                  src="/images/dashboard/greyLine.svg"
                ></img>
                {/* ) : (
                  <img className="logo" src="/images/cs_line.svg"></img>
                )} */}
                <p className="saving_text">
                  {dbObj && dbObj.CEOComponents
                    ? dbObj.CEOComponents.CyberSavingText
                    : ""}
                </p>
              </div>
            </Col>
          </>
        ) : (
          <>
            <Col md={7} className="my-auto containerPadding">
              <div className="containerPadding">
                <h3 className="cc_text">Cyber Savings</h3>
                {/* {dbObj.SubscriptionDetail.PreDataView ? (
                  <div class="d-flex">
                    <img src="/images/dashboard/suggestionIcon.svg" />
                    <p className="smallGrey pl-3">Show suggestions</p>
                  </div>
                ) : ( */}
                <p className="smallGrey">You have already saved...</p>
                {/* )} */}
              </div>
            </Col>
            <Col xs={5} className="text-center csMain pr-4">
              <div className="csmain_background">
                <img
                  alt=""
                  className="mb-2"
                  src="/images/mdi_arrow_upward.svg"
                />
                {/* {console.log(savingNumber)} */}

                <p className="margin-clear saving-maintext">
                  {savingNumber != 0 ? (
                    <CountUp
                      start={0}
                      end={savingNumber}
                      decimal=","
                      // decimal={savingDecimal}
                      decimals={savingNoDecimals}
                      prefix="£"
                      suffix={savingSuffix}
                      useEasing={false}
                      duration="2"
                      seperator=""
                      onEnd={(e) => {
                        console.log(e);
                      }}
                    />
                  ) : (
                    <span className="smallGrey fonttxt">£0</span>
                  )}
                </p>

                <img alt="" className="logo" src="/images/cs_line.svg"></img>
                <p className="saving_text">
                  {dbObj && dbObj.CEOComponents
                    ? dbObj.CEOComponents.CyberSavingText
                    : ""}
                </p>
              </div>
            </Col>
          </>
        )}
      </Row>
    );
  } else {
    return "";//<LoadingSpinner />;
  }
};
export default CyberSavings;
