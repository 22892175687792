import React, { useEffect, useState } from "react";
import SlidingModal from "../../CustomModal/SlidingModal";
import "./mspIntegrations.scss";
const ImportedClientDetailModal = ({ show, parentID, hideModal, data }) => {
  const [header, setHeader] = useState('')
  useEffect(() => {
    let header_name= data?.[0]?.data?.filter((item)=>item?.sortOrder===0)?.[0]?.value
    setHeader(header_name)
  }, [data ])
  
  
  return (
    <SlidingModal
      className={"importedClientDetailModal"}
      show={show}
      parentID={parentID}
      id="016sqxw"
      width={"28%"}
      onHide={hideModal}
    >
      <SlidingModal.Header className="msp-client-modalheader">
        <div className="w-100 position-relative d-flex align-items-center justify-content-between p-3">
          <div></div>
          <div className="f-14 f-black f-500">{header}</div>

          <img
            alt=""
            className=" pointer"
            src="/images/attack-surface/cross-icon.svg"
            onClick={hideModal}
            style={{
              right: "15px",
              top: "21px",
              width: "14px",
            }}
          />
        </div>
      </SlidingModal.Header>

      <SlidingModal.Body className="">
        <div className="p-3">
          {data?.map((block, blockIndex) => {
            return (
              <>
                {block?.groupName !== "index" && (
                  <div className="f-darkgrey mb-3">{block?.groupName} </div>
                )}

                {block?.data
                  ?.sort((a, b) => a.sortOrder - b.sortOrder)
                  ?.map((row, rowIndex) => {
                    return (
                      <div className="d-flex align-items-start my-2">
                        <div className="w-40 f-grey">{row?.label}:</div>
                        <div className="f-black w-60">{row?.value}</div>
                      </div>
                    );
                  })}

                {blockIndex < data?.length - 1 && <hr />}
              </>
            );
          })}
          {/* Second Section */}
        </div>
      </SlidingModal.Body>
    </SlidingModal>
  );
};

export default ImportedClientDetailModal;
