import React, { useEffect, useState } from "react";
import "./subscriptionRequests.scss";

import moment from "moment";
import axios from "axios";

import ContentLoader from "react-content-loader";
import Loader from "../../Common/loader";
import { useAuth } from "../../context/Auth";
import { useSelector } from "react-redux";

import { getAPIData, postData } from "../../../Utils/commonApi";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { Button } from "react-bootstrap";

import CreateTaskModal from "../../PriceCalculator/modals/CreateTaskModal";
import { HGHighlightText } from "../../App/App";
import { HGPopUp } from "../../AttackSurface/KPIpopup/KPIpopup";
import { CustomDeleteModal } from "../../DeleteModal/DeleteModal";

const SubscriptionRequests = ({ frameworksToShow = null, IsMsp }) => {
  const { authTokens } = useAuth();

  const userDetails = useSelector((state) => state.user?.userDetail);
  const [loading, setLoading] = useState(false);
  const [requestsListLoading, setRequestsListLoading] = useState(false);
  const [showSidePanel, setShowSidePanel] = useState(false);

  const [requests, setRequests] = useState([]);
  const [selectedRequestItem, setSelectedRequestItem] = useState();

  const [objId, setObjId] = useState(0);

  useEffect(() => {
    setLoading(true);
    getRequestsData();
  }, []);
  // 0= All
  // 1= MSP
  // 2= SAAS
  // 3 = Archived
  const [requestsType, setRequestsType] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [pageData, setPageData] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [nextPageLoading, setNextPageLoading] = useState(false);
  const [requestActionModal, setRequestActionModal] = useState({
    show: false,
    linkButtonText: "Delete",
    headerText: "Are you sure?",
    deleteButtonClass:"hg-blue-btn",
    bodyText: "Are you sure you would like to unlink this",
    deleteParams: {},
  });

  async function getRequestsData(kwargs = {}) {
    setRequestsListLoading(true);
    let next_page = kwargs?.pageNo;
    if (kwargs?.pageNo) {
      setPageNo(kwargs?.pageNo);
    } else {
      setPageNo(1);
    }
    await getAPIData("1", authTokens, {
      optionEnum1: kwargs?.requestsType ?? requestsType,
      optionEnum2: kwargs?.pageNo ?? 1,
      optionEnum3: 5,
      optionStrEnum1: kwargs?.searchString ?? searchString,
    })
      .then((response) => {
        setNextPageLoading(false);
        setPageData(response);
        if (response?.mr?.Success) {
          if (next_page) {
            setRequests([...requests, ...response?.requests]);
          } else {
            setRequests(response?.requests);
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setLoading(false);
        setRequestsListLoading(false);
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
        setLoading(false);
      });
  }

  // {
  //   type
  // objId_Request
  // }
  function getRequestType(_type) {
    switch (_type) {
      case "unread":
        return 0;
      case "read":
        return 1;
      case "delete":
        return -1;
      case "archive":
        return 2;

      default:
        break;
    }
  }
  function performAction(kwargs = {}) {
    let showToast = kwargs?.showToast;
    let action =kwargs?.type
  
    return new Promise((resolve, reject) => {
      getAPIData("2", authTokens, {
        optionEnum1: getRequestType(kwargs?.type),
        optionEnum2: kwargs?.objId_Request,
      })
        .then((response) => {
          if(action==='delete' || action ==="archive"){
            setShowSidePanel(false);
            setSelectedRequestItem();
getRequestsData()
          }
          if (showToast) {
            CallToast("Updated successfully", false, false, "v2style");
          }
          resolve(response); // Resolve the promise
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
          setLoading(false);
          reject(err); // Reject the promise
        });
    });
  }
  
  

  async function updateVisibility(_id, _visibility) {
    await postData(authTokens, "UpdateFrameworkVisibility", {
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      optionEnum1: _id,
      visibility: _visibility,
    })
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
  }

  function isAtTop() {
    return;
    if (requests.length === 0) return;
    let all_list = requests.reduce((accumulator, currentValue) => {
      return accumulator.concat(currentValue.FrameworkList);
    }, []);
    let index = all_list.findIndex(
      (item) => item?.FrameworkId === selectedRequestItem?.FrameworkId
    );

    if (index === 0) {
      return true;
    } else {
      return false;
    }
  }
  function isAtBottom() {
    return;
    if (requests.length === 0) return;
    let all_list = requests.reduce((accumulator, currentValue) => {
      return accumulator.concat(currentValue.FrameworkList);
    }, []);
    let index = all_list.findIndex(
      (item) => item?.FrameworkId === selectedRequestItem?.FrameworkId
    );

    if (index === all_list.length - 1) {
      return true;
    } else {
      return false;
    }
  }

  async function moveNext() {
    return;
    if (requests.length === 0) return;
    let all_list = requests?.reduce((accumulator, currentValue) => {
      return accumulator.concat(currentValue?.FrameworkList);
    }, []);
    let index = all_list.findIndex(
      (item) => item?.FrameworkId === selectedRequestItem?.FrameworkId
    );

    if (index > -1 && index < all_list.length - 1) {
      setSelectedRequestItem(all_list[index + 1]);
      setObjId(all_list[index + 1]?.CyberFrameworkAudit?.objId ?? 0);
    }
  }

  async function movePrev() {
    return;
    if (requests.length === 0) return;
    let all_list = requests?.reduce((accumulator, currentValue) => {
      return accumulator.concat(currentValue?.FrameworkList);
    }, []);
    let index = all_list.findIndex(
      (item) => item?.FrameworkId === selectedRequestItem?.FrameworkId
    );

    if (index > 0) {
      setSelectedRequestItem(all_list[index - 1]);
      setObjId(all_list[index - 1]?.CyberFrameworkAudit?.objId ?? 0);
    }
  }

  const [timeoutId, setTimeoutId] = useState();

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchString(value);

    // Clear the previous timeout
    clearTimeout(timeoutId);

    // Set a new timeout to call the API after 1 second
    let _timeoutId = setTimeout(() => {
      getRequestsData({
        searchString: value,
      });
    }, 800);
    setTimeoutId(_timeoutId);
  };

  return (
    <div className="w-100 compliance-section">
      {/* Page Body */}

      {loading ? (
        <div className="w-45 mx-auto mt-4">
          <FrameworksLoader />
        </div>
      ) : (
        <>
          <div
            className={`slidable-body-new2 position-relative ${
              showSidePanel ? "open" : ""
            }`}
          >
            <div
              className={`main-body hide-scrollbar ${
                showSidePanel ? "panel-open" : "panel-closed"
              }`}
            >
              <div className="f-16 f-600 mt-4 mb-2">Requests</div>

              {/* Subscription Requests Filters */}
              <div className="w-100 d-flex align-items-center mt-3">
                <div className="bg-grey radius-4 d-flex align-items-center flex-1 p-2">
                  <img
                    src="/images/attack-surface/search-icon.svg"
                    alt=""
                    className="mr-2"
                  />
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchString}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    className="bg-transparent border-none flex-1"
                  />
                  <img
                    alt=""
                    style={{
                      visibility: searchString !== "" ? "visible" : "hidden",
                    }}
                    src="/images/settings-v2/header-search-cross.svg"
                    className="pointer"
                    onClick={() => {
                      handleInputChange({ target: { value: "" } });
                    }}
                  />
                </div>
                <div className="f-darkgrey mx-1">|</div>
                <Button
                  className={`${
                    requestsType === 0 ? "hg-blue-btn" : "hg-cancel-btn"
                  } mr-1`}
                  onClick={() => {
                    setRequestsType(0);
                    getRequestsData({
                      requestsType: 0,
                    });
                  }}
                >
                  All
                </Button>
                <Button
                  className={`${
                    requestsType === 1 ? "hg-blue-btn" : "hg-cancel-btn"
                  } mr-1`}
                  onClick={() => {
                    setRequestsType(1);
                    getRequestsData({
                      requestsType: 1,
                    });
                  }}
                >
                  MSP’s
                </Button>
                <Button
                  className={`${
                    requestsType === 2 ? "hg-blue-btn" : "hg-cancel-btn"
                  } mr-1`}
                  onClick={() => {
                    setRequestsType(2);
                    getRequestsData({
                      requestsType: 2,
                    });
                  }}
                >
                  SAAS’s
                </Button>
                <div className="f-darkgrey mx-1">|</div>
                <Button
                  className={`${
                    requestsType === 3 ? "hg-blue-btn" : "hg-cancel-btn"
                  } mr-1 d-flex align-items-center`}
                  onClick={() => {
                    setRequestsType(3);
                    getRequestsData({
                      requestsType: 3,
                    });
                  }}
                >
                  <img
                    src="/images/msp/package-icon.svg"
                    width={13}
                    height={13}
                    alt=""
                    className="mx-1"
                  />
                  <div>Archived</div>
                </Button>
                {/* <div className="f-darkgrey mx-1">|</div>
                <Button className="hg-cancel-btn d-flex align-items-center">
                  Filter
                  <img
                    src="/images/actioncenter/plusgrey.svg"
                    alt=""
                    className="mx-1"
                  />
                </Button> */}
              </div>
              <div className="border-parent radius-8 mt-4">
                {requestsListLoading && (
                  <div
                    className="position-absolute w-100 h-100 bg-white"
                    style={{
                      opacity: "0.6",
                      zIndex: "2",
                    }}
                  ></div>
                )}

                {requests?.length===0 &&
                
                
                <div className="f-16 text-center p-5">
                  No Requests !! 
                </div>
                
                }
                {requests?.map((request, requestIndex) => {
                  return (
                    <>
                      <>
                        <div
                          className={`d-flex flex-column border-item pointer bg-hover-row ${
                            selectedRequestItem?.requestDetail
                              ?.objId_Request ===
                            request?.requestDetail?.objId_Request
                              ? "bg-lightblue-imp"
                              : ""
                          }`}
                          onClick={() => {
                            setShowSidePanel(true);
                            setSelectedRequestItem(request);
                            setObjId(
                              request?.companyDetail?.objId_Customer ?? 0
                            );
                            if (request?.requestDetail?.isRead === false) {
                              performAction({
                                type: "read",
                                objId_Request:
                                  request?.requestDetail?.objId_Request,
                              });
                            }
                          }}
                        >
                          <div className=" w-100 pointer px-4  py-3 ">
                            <div className="d-flex align-items-center justify-content-between">
                              <HGPopUp
                                maxWidth={"400px"}
                                width={"400px"}
                                content={
                                  <>
                                    <div className="p-3">
                                      <div className="d-flex align-items-center">
                                        <img
                                          src={
                                            request?.requestBy?.avatar
                                              ? request?.requestBy?.avatar
                                              : "/images/user-logo.svg"
                                          }
                                          alt=""
                                          className="mr-2 rounded-full"
                                          width={24}
                                          height={24}
                                        />
                                        <div>
                                          <div className="f-500 d-flex align-items-center f-black">
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: HGHighlightText(
                                                  request?.requestBy?.fullName,
                                                  searchString
                                                ),
                                              }}
                                            />

                                            {request?.requestDetail?.isRead ===
                                              false && (
                                              <span className="unread-blue-dot ml-2"></span>
                                            )}
                                          </div>
                                          <div className="f-darkgrey f-12">
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: HGHighlightText(
                                                  request?.companyDetail
                                                    ?.companyName,
                                                  searchString
                                                ),
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex">

                                    <Button className="hg-cancel-btn text-center d-flex flex-1 align-items-center justify-content-center mb-2 mx-2"
                                    onClick={(e)=>{
                                      e.stopPropagation()
                                    }}
                                    
                                    >
                                      View {request?.companyDetail?.accountType}
                                      's Account
                                    </Button>
                                      </div>
                                    <div className="border1" />
                                    <div className="p-2 w-100">
                                      <div className="f-12 f-500 f-darkgrey">
                                        Company details
                                      </div>

                                      <div className="d-flex mb-2">
                                        <div className="w-40 f-darkgrey">
                                          Name
                                        </div>
                                        <div className="w-60 d-flex align-items-center f-600">
                                          <img
                                            src={
                                              request?.companyDetail
                                                ?.companyLogo
                                            }
                                            alt=""
                                            className="rounded-full mr-2"
                                            width={18}
                                            height={18}
                                          />
                                          {request?.companyDetail?.companyName}
                                        </div>
                                      </div>
                                      <div className="d-flex mb-2">
                                        <div className="w-40 f-darkgrey">
                                          Type
                                        </div>
                                        <div className="w-60 d-flex align-items-center">
                                        {request?.companyDetail?.accountType}
                                        </div>
                                      </div>
                                      <div className="d-flex mb-2">
                                        <div className="w-40 f-darkgrey">
                                          Primary Email
                                        </div>
                                        <div className="w-60 d-flex align-items-center">
                                        {request?.companyDetail?.primaryEmail}
                                        </div>
                                      </div>
                                      <div className="d-flex mb-2">
                                        <div className="w-40 f-darkgrey">
                                       Address
                                        </div>
                                        <div className="w-60 d-flex align-items-center">
                                        {request?.companyDetail?.address}
                                        </div>
                                      </div>
                                      <div className="d-flex mb-2">
                                        <div className="w-40 f-darkgrey">
                                       Subscription
                                        </div>
                                        <div className="w-60 d-flex align-items-center f-600">
                                        {request?.companyDetail?.subscriptionName}
                                        </div>
                                      </div>
                                      <div className="d-flex mb-2">
                                       
                                        
                                        <div className="w-40 f-darkgrey">
                                        Account Owner
                                        </div>
                                    
                              
                                
                                        <div className="w-60 d-flex align-items-center f-600">
                                          <img
                                            src={
                                              request?.companyDetail
                                                ?.accountOwner?.avatar ?   request?.companyDetail
                                                ?.accountOwner?.avatar   :     "/images/user-logo.svg"
                                            }
                                            alt=""
                                            className="rounded-full mr-2"
                                            width={18}
                                            height={18}
                                          />
                                          {request?.companyDetail?.accountOwner?.fullName}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                }
                              >
                                <div className="d-flex align-items-center">
                                  <img
                                    src={
                                      request?.requestBy?.avatar
                                        ? request?.requestBy?.avatar
                                        : "/images/user-logo.svg"
                                    }
                                    alt=""
                                    className="mr-2 rounded-full"
                                    width={24}
                                    height={24}
                                  />
                                  <div>
                                    <div className="f-500 d-flex align-items-center f-black">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: HGHighlightText(
                                            request?.requestBy?.fullName,
                                            searchString
                                          ),
                                        }}
                                      />

                                      {request?.requestDetail?.isRead ===
                                        false && (
                                        <span className="unread-blue-dot ml-2"></span>
                                      )}
                                    </div>
                                    <div className="f-darkgrey f-12">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: HGHighlightText(
                                            request?.companyDetail?.companyName,
                                            searchString
                                          ),
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </HGPopUp>
                              <div className="f-black f-12">
                                {request?.requestDetail?.requestCreatedOn}
                              </div>
                            </div>
                            {request?.requestDetail?.message ? (
                              <div className="f-12 mt-2">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: HGHighlightText(
                                      request?.requestDetail?.message,
                                      searchString
                                    ),
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="my-2"></div>
                            )}
                            <div className="mt-2 d-flex align-items-center">
                              {request?.tags.map((text) => (
                                <Greychip chipText={text} className={"mr-2"} />
                              ))}
                            </div>
                          </div>
                        </div>
                      </>
                    </>
                  );
                })}
              </div>

              {pageData?.totalRecords > requests?.length && (
                <Button
                  className="hg-cancel-btn mt-2"
                  onClick={() => {
                    setNextPageLoading(true);
                    getRequestsData({
                      pageNo: pageNo + 1,
                    });
                  }}
                >
                  {nextPageLoading ? "Loading.." : "Show more"}
                </Button>
              )}

              <div className="p-4" />
            </div>

            <div
              className={`sidepanel  ${
                showSidePanel ? "panel-open" : "panel-closed"
              } `}
              style={{
                top:
                  IsMsp &&
                  (userDetails?.AccountVerified === false ||
                    userDetails?.ShowConfirmVerified === true)
                    ? "56px"
                    : "56px",
              }}
            >
              <div className="panel-header d-flex align-items-center justify-content-between p-3">
                <div className="d-flex align-items-center">
                  <div
                    className={`mx-2 pointer ${
                      isAtTop() ? "pointer-events-none opacity-40" : ""
                    }`}
                    onClick={() => {
                      movePrev();
                    }}
                  >
                    <img
                      src="/images/msp/secops-chevron-down.svg"
                      className="rotate180"
                      alt=""
                    />
                  </div>
                  <div
                    className={`mx-2 pointer ${
                      isAtBottom() ? "pointer-events-none opacity-40" : ""
                    }`}
                    onClick={() => {
                      moveNext();
                    }}
                  >
                    <img
                      src="/images/msp/secops-chevron-down.svg"
                      className=""
                      alt=""
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center  ">
                  {selectedRequestItem?.requestDetail?.isRead === true && (
                    <div
                      className="mx-2 f-grey f-500 pointer"
                      onClick={() => {
                        performAction({
                          type: "unread",
                          objId_Request:
                            selectedRequestItem?.requestDetail?.objId_Request,
                        });
                        let updated_selectedItem = {...selectedRequestItem}
                        updated_selectedItem.requestDetail.isRead=false
                        setSelectedRequestItem(updated_selectedItem)

                        let updated_list = [...requests]
                        let updated_index = requests.findIndex((item)=>
                      item.requestDetail.objId_Request===selectedRequestItem.requestDetail.objId_Request
                      
                      )
                      if(updated_index>-1){
                        updated_list[updated_index].requestDetail.isRead=false
setRequests(updated_list)
                      }

                      }}
                    >
                      Mark as unread
                    </div>
                  )}

                  <img
                    alt=""
                    src="/images/actioncenter/folder.svg"
                    className="mx-2 pointer"
                    onClick={() => {
                      setRequestActionModal((prev)=>({
                        ...prev,
                        linkButtonText: "Archive",
                        bodyText: "Do you want to archive this request?",
                        show:true,
                        deleteParams:{
                          type: "archive",
                          showToast: true,
                          objId_Request:
                            selectedRequestItem?.requestDetail?.objId_Request,
                        }
                      }))
                      // performAction({
                      //   type: "archive",
                      //   showToast: true,
                      //   objId_Request:
                      //     selectedRequestItem?.requestDetail?.objId_Request,
                      // });
                    }}
                  />

                  <div className="f-darkgrey mx-1">|</div>
                  <div
                    className="mx-2 f-grey f-500 pointer"
                    onClick={() => {
                      setRequestActionModal((prev)=>({
                        ...prev,
                        linkButtonText: "Delete",
                        bodyText: "Do you want to delete this request?",
                        show:true,
                        deleteParams:{
                          type: "delete",
                          objId_Request:
                            selectedRequestItem?.requestDetail?.objId_Request,
                        }
                      }))
                      // performAction({
                      //   type: "delete",
                      //   objId_Request:
                      //     selectedRequestItem?.requestDetail?.objId_Request,
                      // });
                    }}
                  >
                    Delete
                  </div>
                  <div className="f-darkgrey mx-1">|</div>

                  <div
                    className="pl-2   pointer"
                    onClick={() => {
                      setShowSidePanel(false);
                      setSelectedRequestItem();
                    }}
                  >
                    <img
                      width={13}
                      src="/images/attack-surface/cross-icon.svg"
                      alt=""
                      className=" "
                    />
                  </div>
                </div>
              </div>

              <div className="panel-body transparent-scroller">
                <div className="d-flex align-items-center justify-content-between p-3">
                  <div className="d-flex align-items-center">
                    <img
                      src={
                        selectedRequestItem?.requestBy?.avatar
                          ? selectedRequestItem?.requestBy?.requestBy?.avatar
                          : "/images/user-logo.svg"
                      }
                      alt=""
                      className="mr-2 rounded-full"
                      width={24}
                      height={24}
                    />
                    <div>
                      <div className="f-500 d-flex align-items-center">
                        {selectedRequestItem?.requestBy?.fullName}

                        {/* {selectedRequestItem?.requestDetail?.isRead===false  &&  <span className="unread-blue-dot ml-2"></span> } */}
                      </div>
                      <div className="f-darkgrey f-12">
                        {selectedRequestItem?.companyDetail?.companyName}
                      </div>
                    </div>
                  </div>
                  <div className="f-black f-12">
                    {selectedRequestItem?.requestDetail?.requestCreatedOn}
                  </div>
                </div>
                <div className="px-5 w-100">
                  <div className="d-flex align-items-start mb-2">
                    <div className="w-50 f-darkgrey">Tel#</div>
                    <div className="f-black">
                      {selectedRequestItem?.requestDetail?.countryCode}
                      {selectedRequestItem?.requestDetail?.contactNo}
                    </div>
                  </div>
                  <div className="d-flex align-items-start mb-2">
                    <div className="w-50 f-darkgrey">Type</div>
                    <div className="f-black">
                      {selectedRequestItem?.companyDetail?.accountType}
                    </div>
                  </div>
                  <div className="d-flex align-items-start mb-2">
                    <div className="w-50 f-darkgrey">Time</div>
                    <div className="f-black w-50">
                      {selectedRequestItem?.requestDetail?.date},{" "}
                      {selectedRequestItem?.requestDetail?.time}
                      <br />
                      <span>
                        {
                          selectedRequestItem?.requestDetail
                            ?.selectedTimeZoneText
                        }
                      </span>
                    </div>
                  </div>

                  <div className="d-flex align-items-start mb-2">
                    <div className="w-50 f-darkgrey">Quantity of Clients</div>
                    <div className="f-black">
                      {selectedRequestItem?.requestDetail?.clientRange?.Value}
                    </div>
                  </div>
                  <div className="d-flex align-items-start mb-2">
                    <div className="w-50 f-darkgrey">Quantity of Prospects</div>
                    <div className="f-black">
                      {selectedRequestItem?.requestDetail?.prospectRange?.Value}
                    </div>
                  </div>
                </div>

                <div className="my-4 w-100 border1" />
                <div className="p-3">
                  <div>
                    {selectedRequestItem?.requestDetail?.message ? (
                      <div className="">
                        {selectedRequestItem?.requestDetail?.message}
                      </div>
                    ) : (
                      <div className="my-2"></div>
                    )}
                  </div>
                  <div className="mt-2 d-flex align-items-center">
                    {selectedRequestItem?.tags?.map((text) => (
                      <Greychip chipText={text} className={"mr-2"} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <CustomDeleteModal
        show={requestActionModal.show}
        hideModal={() => {
          setRequestActionModal((prev) => ({
            ...prev,
            show: false,
          }));
        }}
        deleteButtonClass={requestActionModal.deleteButtonClass}
        deleteButtonText={requestActionModal.linkButtonText}
        deleteHeaderText={requestActionModal.headerText}
        deleteBodyText={requestActionModal.bodyText}
        deleteFunction={performAction}
        deleteParams={requestActionModal?.deleteParams}
        deleteType={"subscriptionBackendRequestsModal"}
        processingText={requestActionModal?.processingText ?? "Deleting"}
      />
          </div>
        </>
      )}
      <CreateTaskModal />
    </div>
  );
};

export default SubscriptionRequests;

export const previousResponses = [
  {
    type: "Internal Comment",
    answer: "Yes",
    date: "12/12/2020",
    question:
      "Ho umar de hisab naal doona ruthba Thoda nahiyo bahla hi khalaki challda  Akhan ch akheeti koyi sheh boldi Aive nai koyi duniya ton aaki challda ",
  },
  {
    type: "Internal Comment",
    answer: "Yes",
    date: "12/12/2020",
    question:
      "Ho umar de hisab naal doona ruthba Thoda nahiyo bahla hi khalaki challda  Akhan ch akheeti koyi sheh boldi Aive nai koyi duniya ton aaki challda ",
  },
  {
    type: "Internal Comment",
    answer: "Yes",
    date: "12/12/2020",
    question:
      "Ho umar de hisab naal doona ruthba Thoda nahiyo bahla hi khalaki challda  Akhan ch akheeti koyi sheh boldi Aive nai koyi duniya ton aaki challda ",
  },
  {
    type: "Internal Comment",
    answer: "Yes",
    date: "12/12/2020",
    question:
      "Ho umar de hisab naal doona ruthba Thoda nahiyo bahla hi khalaki challda  Akhan ch akheeti koyi sheh boldi Aive nai koyi duniya ton aaki challda ",
  },
  {
    type: "Internal Comment",
    answer: "Yes",
    date: "12/12/2020",
    question:
      "Ho umar de hisab naal doona ruthba Thoda nahiyo bahla hi khalaki challda  Akhan ch akheeti koyi sheh boldi Aive nai koyi duniya ton aaki challda ",
  },
  {
    type: "Internal Comment",
    answer: "Yes",
    date: "12/12/2020",
    question:
      "Ho umar de hisab naal doona ruthba Thoda nahiyo bahla hi khalaki challda  Akhan ch akheeti koyi sheh boldi Aive nai koyi duniya ton aaki challda ",
  },
  {
    type: "Internal Comment",
    answer: "Yes",
    date: "12/12/2020",
    question:
      "Ho umar de hisab naal doona ruthba Thoda nahiyo bahla hi khalaki challda  Akhan ch akheeti koyi sheh boldi Aive nai koyi duniya ton aaki challda ",
  },
];

const FrameworksLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0%" y="70" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="212" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="354" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="496" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="638" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="780" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="922" rx="8" ry="8" width="100%" height="140" />
    </ContentLoader>
  );
};

const Greychip = ({ chipText, className }) => {
  return (
    <div className={`f-12 f-500 f-grey bg-greyish px-2 py-1 ${className}`}>
      {chipText}
    </div>
  );
};
