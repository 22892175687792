import React, { useEffect } from "react";
import "./new-custom.scss";
const ThresholdSlider = ({
  min,
  max,
  step,
  id,
  defaultValue,
  onChange,
  value,
  onMouseUp,
  labelData,
}) => {
  useEffect(() => {
    let value = ((defaultValue - min) / (max - min)) * 100;

    const slider = document.getElementById(id);

    slider.style.background =
      "linear-gradient(to right, #f6f8fb 0%, #f6f8fb " +
      value +
      "%, #fff " +
      value +
      "%, white 100%)";
  }, [defaultValue]);

  function progressColor(rangeValue) {
    let value = ((rangeValue - min) / (max - min)) * 100;

    const slider = document.getElementById(id);

    slider.style.background =
      "linear-gradient(to right, #f6f8fb 0%, #f6f8fb " +
      value +
      "%, #fff " +
      value +
      "%, white 100%)";
  }

  return (
    <div className="threshold-slider">
      <div className="d-flex justify-content-between ml-2 mb-3 mt-2">
        {labelData &&
          labelData.map((label, index) => {
            return (
              <div
                key={index}
                className={`${
                  Math.floor((index / (labelData.length - 1)) * 100) ===
                    Math.floor((value / max) * 100) ||
                  (step === 33 && value > 90 && index === labelData.length - 1)
                    ? "active"
                    : "normal"
                }`}
              >
                {label}{" "}
              </div>
            );
          })}
      </div>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onMouseUp={onMouseUp}
        defaultValue={defaultValue}
        onChange={(e) => {
          progressColor(e.target.value);
          onChange(e);
        }}
        className="slider"
        id={id}
      />
    </div>
  );
};

export default ThresholdSlider;
