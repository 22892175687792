import React from "react";
import "./modals.scss";
// export default CustomToast;

export function CallToast(
  message = "Something went wrong",
  undo = false,
  closeButton = true,
  style = ""
) {
  var oldElement = document.getElementById("custom-toastz");
  if (oldElement) {
    oldElement.remove();
  }
  let htmlMarkup = "";
  if (closeButton) {
    htmlMarkup =
      '<div id="custom-toastz"><div class="toast-message"></div><div><img src="/images/cancelButton.png" /></div></div>';
    if (undo) {
      htmlMarkup =
        '<div id="custom-toastz"><div class="toast-message"></div><div><span class="undo-text"></span><img src="/images/cancelButton.png" /></div></div>';
    }
  } else {
    htmlMarkup =
      '<div id="custom-toastz"><div class="toast-message"></div><div></div></div>';
    if (style == "v2style") {
      htmlMarkup =
        '<div id="custom-toastz"><div class="toast-message"></div><div><span class="v2-undo-text"></span><img src="/images/settings-v2/v2-toast-cross.svg" class="pointer"/></div></div>';
    }
  }

  document.getElementById("root").insertAdjacentHTML("beforeend", htmlMarkup);
  var x = document.getElementById("custom-toastz");
  if (closeButton) {
    var Image = x.getElementsByTagName("img");
    Image[0].classList.add("pointer-cursor");
    Image[0].addEventListener("click", () => {
      let x = document.getElementById("custom-toastz");
      x.classList.remove("showw");
    });
  }

  var messagePart = x.querySelector(".toast-message");
  messagePart.innerHTML = message;
  x.classList.add("showw");
  setTimeout(function () {
    x.classList.remove("showw");
  }, 2700);
}

export function RightSideToast(amount, message) {
  return;
  let htmlMarkup =
    '<div id="right-side-custom-toastz" class=" d-flex flex-column"><div class="toast-message d-flex align-items-center"><img src="/images/settings-v2/gift-icon.svg" class="mr-2" /><div>+&nbsp;£<span class="pound-amt"></span>&nbsp;Congratulations!</div></div><div class="gift-message"></div></div>';

  var oldElement = document.getElementById("right-side-custom-toastz");
  if (oldElement) {
    oldElement.remove();
  }

  document.getElementById("root").insertAdjacentHTML("beforeend", htmlMarkup);

  var x = document.getElementById("right-side-custom-toastz");

  var messagePart = x.querySelector(".pound-amt");
  messagePart.innerText = amount;

  var messagePart = x.querySelector(".gift-message");
  messagePart.innerText = message;

  x.classList.add("showFromRight");
  setTimeout(function () {
    x.classList.remove("showFromRight");
  }, 5100);
}



export function CustomRightSideToast(header, message,callback ,hideClickableMessage = false) {
  
  return;
  let htmlMarkup ="";
  if(hideClickableMessage){
    htmlMarkup = `<div id="right-side-custom-toastz" class=" d-flex flex-column"><div class="toast-message d-flex align-items-center"><img src="/images/settings-v2/gift-icon.svg" class="mr-2" /><div>${header}</div></div><div class="gift-message">${message}</div><div></div></div>`;

  }else{
    htmlMarkup = `<div id="right-side-custom-toastz" class=" d-flex flex-column"><div class="toast-message d-flex align-items-center"><img src="/images/settings-v2/gift-icon.svg" class="mr-2" /><div>${header}</div></div><div class="gift-message">${message}</div><div><span class="f-white f-italic pointer click-event">Click here to spend it.</span></div></div>`;
  }

  var oldElement = document.getElementById("right-side-custom-toastz");
  if (oldElement) {
    oldElement.remove();
  }

  document.getElementById("root").insertAdjacentHTML("beforeend", htmlMarkup);

  var x = document.getElementById("right-side-custom-toastz");

  // var messagePart = x.querySelector(".pound-amt");
  // messagePart.innerText = amount;

  var clickEvent = x.querySelector(".click-event");
  if(clickEvent){
    clickEvent.addEventListener("click", () => {
      if(callback)callback()
       var oldElement = document.getElementById("right-side-custom-toastz");
    if (oldElement) {
      oldElement.remove();
    }
    });


  }
 
  // messagePart.innerText = message;

  x.classList.add("showFromRight");
  setTimeout(function () {
    x.classList.remove("showFromRight");
  }, 5100);
}




export function CustomRightSideToastForSendReport(header, message,clickableMessage,callback) {

  let htmlMarkup =
    `<div id="right-side-custom-toastz" class=" d-flex flex-column"><div class="toast-message d-flex align-items-center"><img src="/images/settings-v2/gift-icon.svg" class="mr-2" /><div>${header}</div></div><div class="gift-message">${message}</div><div><span class="f-white f-italic pointer click-event">${clickableMessage}</span> </div></div>`;

  var oldElement = document.getElementById("right-side-custom-toastz");
  if (oldElement) {
    oldElement.remove();
  }

  document.getElementById("root").insertAdjacentHTML("beforeend", htmlMarkup);

  var x = document.getElementById("right-side-custom-toastz");

  // var messagePart = x.querySelector(".pound-amt");
  // messagePart.innerText = amount;

  var clickEvent = x.querySelector(".click-event");
  clickEvent.addEventListener("click", () => {
    if(callback)callback()
    var oldElement = document.getElementById("right-side-custom-toastz");
    if (oldElement) {
      oldElement.remove();
    }
  });
  // messagePart.innerText = message;

  x.classList.add("showFromRight");
  setTimeout(function () {
    x.classList.remove("showFromRight");
  }, 5100);
}



export function CustomRightSideToastForReport(header, message,callback) {
  let htmlMarkup =
    `<div id="right-side-custom-toastz" class=" forReport d-flex flex-column"><div class="toast-message d-flex align-items-center"><img src="/images/settings-v2/gift-icon.svg" class="mr-2 d-none" /><div>${header}</div></div><div class="gift-message d-none">${message}</div><div><span class="f-white f-italic pointer click-event d-none">Click here to spend it.</span></div></div>`;

  var oldElement = document.getElementById("right-side-custom-toastz");
  if (oldElement) {
    oldElement.remove();
  }

  document.getElementById("root").insertAdjacentHTML("beforeend", htmlMarkup);

  var x = document.getElementById("right-side-custom-toastz");

  // var messagePart = x.querySelector(".pound-amt");
  // messagePart.innerText = amount;

  var clickEvent = x.querySelector(".click-event");
  clickEvent.addEventListener("click", () => {
    if(callback)callback()
     var oldElement = document.getElementById("right-side-custom-toastz");
  if (oldElement) {
    oldElement.remove();
  }
  });
  // messagePart.innerText = message;

  x.classList.add("showFromRight");
  setTimeout(function () {
    x.classList.remove("showFromRight");
  }, 5100);
}