import React from "react";

const MspMappingDropdownSearchInput = ({ placeholder, value, onChange }) => {
  return (
    <div className="bg-grey radius-4 mx-2 w-100 p-2">
      <input
        type="text"
        className="border-none bg-transparent w-100"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default MspMappingDropdownSearchInput;
