import React, { useState, lazy, Suspense } from "react";
import "./supppliersAndComplianceProfiles.scss";
import {
  Col,
  Row,
  Tab,
  Nav,
  Dropdown,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import SupplierDetailsPopover from "./SupplierDetailsPopover";
import CompanySettingsDropdown from "../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
// import AddComplianceProfileModal from "../AddComplianceProfileModal/AddComplianceProfileModal";
import ReassignSupplierModal from "../modals/ReassignSupplierModal";
import { useHistory } from "react-router-dom";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { TrimString } from "../../../Utils/StringTrimmer";
import {
  CallToast,
  CustomRightSideToastForReport,
} from "../../PriceCalculator/modals/CustomToast";
import { GreyLoader } from "../../Theme/APILoaders";
import ImageWithInitials from "../../../Utils/ImageWithInitials";
import SuspendRemoveCompanyModal from "../modals/SuspendRemoveCompany";
import { downloadPDF } from "../SupplyCompany/SupplyCompany";
import ChangeAccessLevelModal from "../RequestsModal/ChangeAccessLevelModal";
import { BlueComingSoonTag } from "../Index";
import ManagePotentialSupplierModal from "./ManagePotentialSupplierModal";
import { UpgradeToPremiumSection } from "../../Integrationsv2/UpgradeToPremiumModal";
import { viewOnlyAccess } from "../../App/App";
import SupplierInfoDropdown from "./SupplierInfoDropdown";
import EditSupplierModal from "../../AttackSurface/Suppliers/EditSupplierModal";
import ManageSupplierModal from "./ManageSupplierModal";
import ProfilingModal from "../../supplierProfiler/ProfilingModal";

const AddComplianceProfileModal = lazy(() =>
  import("../AddComplianceProfileModal/AddComplianceProfileModal")
);

const SuppliersAndComplianceProfilesTabs = (props) => {
  const {
    SortSupplierColumn,
    columnSortingLoading,
    sortItem,
    potentialSupplierList,
    openPotentialSupplierModal,
    activeTab,
    setActiveTab,
    SCMFilterDropdown,
    openRequestsModal,
    unManagedSuppliers,
    openSupplierDetailScreenInRequestsModal,
    totalSupplierCount,
  } = props;
  const history = useHistory();
  const { authTokens } = useAuth();
  const [
    showManagePotentialSupplierModal,
    setShowManagePotentialSupplierModal,
  ] = useState({
    show: false,
    supplier: {},
  });
  const [showEditSupplierModal, setShowEditSupplierModal] = useState({
    show: false,
    supplier: {},
  });

  const [selectedPotentialSupplierData, setSelectedPotentialSupplierData] =
    useState({});
  const [selectedSupplier, setSelectedSupplier] = useState({});

  const [showChangeAccessLevelModal, setShowChangeAccessLevelModal] =
    useState(false);
  const [showAddComplianceModal, setShowAddComplianceModal] = useState(false);
  const [suspendRemoveModal, setSuspendRemoveModal] = useState(false);
  const [suspendRemoveSupplierDetals, setSuspendRemoveSupplierDetals] =
    useState({
      supplierId: "",
      supplierSupplyChainId: "",
      type: "",
      supplierName: "",
      supplierLogo: "",
      IsUnmanagedSupplier: 0
    });

  const [showReassignModal, setShowReassignModal] = useState(false);
  const [complianceProfileSuppliersList, setComplianceProfileSuppliersList] =
    useState([]);
  const [complianceFilter, setComplianceFilter] = useState({
    id: 0,
    category: "Newest first",
  });
  const [complianceProfileId, setComplianceProfileId] = useState(0);
  const [showIntegrationPopup, setShowIntegrationPopup] = useState(true);

  const [showProfilingModal, setShowProfilingModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [titleTemplate, setTitleTemplate] = useState([]);

  async function createDuplicateComplianceProfile(objectID) {
    await getAPIData("308", authTokens, {
      optionEnum1: objectID,
    })
      .then((response) => {
        if (response?.Success) {
          CallToast(
            "Duplicate profile created successfully",
            false,
            false,
            "v2style"
          );
          props.getComplianceProfiles(complianceFilter?.id);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // Function to Change Supplier Status
  //  for Remove => 3
  // For Suspend => 4
  async function updateSupplierStatus(supplierID, status, relationshipId) {
    await getAPIData("345", authTokens, {
      optionEnum1: supplierID,
      optionEnum2: status,
      optionEnum3: relationshipId,
    })
      .then((response) => {
        if (response?.Success) {
          if (props?.refreshData) {
            props.refreshData();
          }

          CallToast("Updated successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  async function printSupplierReport(Id) {
    // CallToast("Report will be downloaded shortly.", false, false, "v2style");
    CustomRightSideToastForReport(
      "Report is being created and will be sent to you by email shortly."
    );
    await getAPIData(`${454}`, authTokens, {
      optionEnum1: Id,
    })
      .then((response) => {
        if (response?.mr?.Success) {
          CallToast(
            "Report has been sent successfully",
            false,
            false,
            "v2style"
          );
          // downloadPDF(response?.FileURL);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function printProfilingReport(id) {
    // CallToast("Report will be downloaded shortly.", false, false, "v2style");
    CustomRightSideToastForReport(
      "Report is being created and will appear soon. It will also be sent to you by email shortly."
    );
    await getAPIData(`${783}`, authTokens, {
      optionEnum1: id,
    })
      .then((response) => {
        if (response?.mr?.Success) {
          CallToast(
            "Report has been sent successfully",
            false,
            false,
            "v2style"
          );
          downloadPDF(response?.FileURL);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="supplierAndComplianceProfile-wrapper">
      <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab}>
        <Row>
          <Col
            sm={12}
            className="ml-3 mb-2 d-flex justify-content-between align-items-center"
          >
            <div className="f-500 ml-3 d-flex align-items-center w-100 justify-content-between">
              <div>
                <Nav
                  variant="pills"
                  className={`flex-row  ${
                    props.showUpgrade ? "disable-suppliers-tab" : ""
                  }`}
                >
                  <Nav.Item>
                    <Nav.Link
                      eventKey="suppliers"
                      onSelect={(e) => {
                        setActiveTab(e);
                      }}
                    >
                      Suppliers
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="complianceProfiles"
                      onSelect={(e) => {
                        setActiveTab(e);
                      }}
                    >
                      Compliance Profiles
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="compliance-filter-dropdown mr-5">
                {activeTab === "complianceProfiles" && (
                  <CompanySettingsDropdown
                    fieldId={"id"}
                    fieldValue={"category"}
                    selected={complianceFilter}
                    placeholder="Filters"
                    //  showid={true}
                    onClick={(id, val) => {
                      setComplianceFilter({ id, category: val });
                      props.getComplianceProfiles(id);
                    }}
                    data={[
                      { id: 0, category: "Newest first" },
                      { id: 1, category: "Older first" },
                      { id: 2, category: "Alphabetical ascending" },
                      { id: 3, category: "Alphabetical descending" },
                      { id: 4, category: "Profile with least suppliers" },
                      { id: 5, category: "Profile with most suppliers" },
                    ]}
                  />
                )}
              </div>
            </div>
            {/* Right side items at top */}
            <div className="d-flex align-items-center mr-3"></div>
          </Col>
          <Col sm={12}>
            <Tab.Content>
              {/* Suppliers */}
              <Tab.Pane eventKey="suppliers" style={{ height: "100vh" }}>
                {(props.supplierList === null ||
                  props.supplierList?.length === 0) &&
                  !props.showUpgrade && (
                    <div className="d-flex flex-column justify-content-center align-items-center w-50 mt-5 mx-auto mb-4">
                      <img
                        alt=""
                        src="/images/supply-chain/no-supplier-icon.svg"
                        className="no-supplier-icon mb-3"
                      />
                      <div className="banner-element-name mb-2">
                        No suppliers
                      </div>
                      <p
                        className="banner-subtitle text-center"
                        onClick={() => {}}
                      >
                        You haven't added any suppliers yet. To add suppliers,
                        send them a <br /> request to access their HighGround
                        data
                      </p>

                      <div
                        className="f-blue pointer f-500"
                        onClick={() => {
                          openRequestsModal();
                        }}
                      >
                        + Add Supplier
                      </div>
                    </div>
                  )}
                {props.showUpgrade && (
                  <div className="d-flex align-items-center justify-content-center w-100">
                    <div className="w-25">
                      <UpgradeToPremiumSection
                        upgradeOptions={props.upgradeOptions}
                      />
                    </div>
                  </div>
                )}

                {props.supplierList?.length > 0 && !props.showUpgrade && (
                  <div className="suppliers-data mx-5">
                    <div
                      className="supplier-list-header mb-2"
                      style={{
                        pointerEvents: `${props.showUpgrade ? "none" : "all"}`,
                      }}
                    >
                      <div
                        className="ml-3 pointer"
                        onClick={() => {
                          SortSupplierColumn("Supplier Name");
                        }}
                      >
                        Supplier Name
                        {sortItem?.type === "Supplier Name" && (
                          <>
                            {columnSortingLoading ? (
                              <GreyLoader width={11} />
                            ) : (
                              <>
                                {sortItem?.order ? (
                                  <img
                                    alt=""
                                    src="/images/actioncenter/arrowdown.svg"
                                    className="ml-2"
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    src="/images/actioncenter/arrowup.svg"
                                    className="ml-2"
                                    style={{ width: "10px" }}
                                  />
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                      <div
                        className="pointer"
                        onClick={() => {
                          SortSupplierColumn("Status");
                        }}
                      >
                        Status
                        {sortItem?.type === "Status" && (
                          <>
                            {columnSortingLoading ? (
                              <GreyLoader width={11} />
                            ) : (
                              <>
                                {sortItem?.order ? (
                                  <img
                                    alt=""
                                    src="/images/actioncenter/arrowdown.svg"
                                    className="ml-2"
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    src="/images/actioncenter/arrowup.svg"
                                    className="ml-2"
                                    style={{ width: "10px" }}
                                  />
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                      <div
                        className="pointer"
                        onClick={() => {
                          SortSupplierColumn("Supplier Risk");
                        }}
                      >
                        Risk Rating
                        {sortItem?.type === "Supplier Risk" && (
                          <>
                            {columnSortingLoading ? (
                              <GreyLoader width={11} />
                            ) : (
                              <>
                                {sortItem?.order ? (
                                  <img
                                    alt=""
                                    src="/images/actioncenter/arrowdown.svg"
                                    className="ml-2"
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    src="/images/actioncenter/arrowup.svg"
                                    className="ml-2"
                                    style={{ width: "10px" }}
                                  />
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                      <div
                        className="pointer"
                        onClick={() => {
                          SortSupplierColumn("CyberScore");
                        }}
                      >
                        Profiling Status
                        {sortItem?.type === "CyberScore" && (
                          <>
                            {columnSortingLoading ? (
                              <GreyLoader width={11} />
                            ) : (
                              <>
                                {sortItem?.order ? (
                                  <img
                                    alt=""
                                    src="/images/actioncenter/arrowdown.svg"
                                    className="ml-2"
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    src="/images/actioncenter/arrowup.svg"
                                    className="ml-2"
                                    style={{ width: "10px" }}
                                  />
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                      <div
                        className="pointer"
                        onClick={() => {
                          SortSupplierColumn("CyberThreat");
                        }}
                      >
                        Profiling Next Due
                        {sortItem?.type === "CyberThreat" && (
                          <>
                            {columnSortingLoading ? (
                              <GreyLoader width={11} />
                            ) : (
                              <>
                                {sortItem?.order ? (
                                  <img
                                    alt=""
                                    src="/images/actioncenter/arrowdown.svg"
                                    className="ml-2"
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    src="/images/actioncenter/arrowup.svg"
                                    className="ml-2"
                                    style={{ width: "10px" }}
                                  />
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                      <div
                        className="pointer"
                        onClick={() => {
                          SortSupplierColumn("CyberCompliance");
                        }}
                      >
                        Managed
                        {sortItem?.type === "CyberCompliance" && (
                          <>
                            {columnSortingLoading ? (
                              <GreyLoader width={11} />
                            ) : (
                              <>
                                {sortItem?.order ? (
                                  <img
                                    alt=""
                                    src="/images/actioncenter/arrowdown.svg"
                                    className="ml-2"
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    src="/images/actioncenter/arrowup.svg"
                                    className="ml-2"
                                    style={{ width: "10px" }}
                                  />
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                      <div></div>
                    </div>
                    {/* table Data */}
                    <div className="table-data-wrapper">
                      <div className="border-parent radius-8">
                        <div className="border-item d-flex align-items-center f-darkgrey pl-4 p-2 ">
                          <img
                            alt=""
                            src="/images/supply-chain/grey-info.svg"
                            className="mr-2"
                          />
                          Suppliers {props.supplierList?.length} of{" "}
                          {totalSupplierCount}
                        </div>

                        {/* Banner */}
                        <div
                          className={`  ${
                            showIntegrationPopup ? "d-flex" : "d-none"
                          } align-items-center justify-content-between bg-grey  border-item radius-4 p-3 pointer  `}
                          onClick={() => {
                            if (potentialSupplierList?.length > 0) {
                              openPotentialSupplierModal();
                            }
                          }}
                        >
                          {potentialSupplierList?.length === 0 ? (
                            <>
                              <div className=" d-flex align-items-center">
                                <img
                                  alt=""
                                  src="/images/supply-chain/blue-plug.svg"
                                  className="mr-2"
                                />
                                <div className="f-black">
                                  Integrate your tools to identify your
                                  suppliers automatically
                                </div>
                              </div>

                              <div className="f-blue d-flex align-items-center">
                                <div
                                  className="pointer"
                                  onClick={() => {
                                    history.push("/integrations");
                                  }}
                                >
                                  Integrations
                                </div>
                                <img
                                  src="/images/supply-chain/blue-redirect.svg"
                                  alt=""
                                  className="mx-2 pointer"
                                  onClick={() => {
                                    history.push("/integrations");
                                  }}
                                />
                                <img
                                  src="/images/small-cross-icon.svg"
                                  alt=""
                                  className="mx-2 pointer"
                                  onClick={() => {
                                    setShowIntegrationPopup(false);
                                  }}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="d-flex align-items-center">
                                <div className={` ${"image-group"}`}>
                                  {potentialSupplierList
                                    ?.slice(0, 3)
                                    ?.map((supplier) => {
                                      return (
                                        <>
                                          {supplier?.PotentialSupplierIcon?.toLowerCase() ===
                                          "noicon" ? (
                                            <span
                                              style={{ marginLeft: "-10px" }}
                                            >
                                              <ImageWithInitials
                                                width={32}
                                                background="purple"
                                                initials={
                                                  supplier?.PotentialSupplierName?.toUpperCase()?.[0] ??
                                                  "X"
                                                }
                                              />
                                            </span>
                                          ) : (
                                            <img
                                              alt=""
                                              src={
                                                supplier?.PotentialSupplierIcon
                                              }
                                              width={32}
                                              style={{
                                                border: "1px solid white",
                                              }}
                                              height={32}
                                              className="rounded-full"
                                              onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src =
                                                  "/images/user-logo.svg";
                                              }}
                                            />
                                          )}
                                        </>
                                      );
                                    })}
                                </div>
                                <div className={`${"ml-2"}`}>
                                  View {potentialSupplierList?.length} potential
                                  suppliers
                                </div>
                              </div>

                              <img
                                alt=""
                                src="/images/chevron-right.svg"
                                className="ml-2 mr-2"
                              />
                            </>
                          )}
                        </div>

                        <div className=""></div>
                      </div>
                      {/* {[
                        ...new Map(

                          props.supplierList
                          
                          // dummy_Data
                          .map((item) => [
                            item["objId_Customer"],
                            item,
                          ])
                        ).values(),
                      ] */}
                      {props.supplierList?.map((supplier, supplierIndex) => {
                        // {props.supplierList?.map((supplier, supplierIndex) => {
                        return (
                          <div
                            className={`table-data-row   ${
                              supplierIndex === props.supplierList.length - 1
                                ? ""
                                : "border-btm-grey"
                            } 
                            
                            ${
                              supplier?.DisableItem
                                ? "disable-supplier-row"
                                : ""
                            }
                            
                            `}
                            onClick={(e) => {
                              e.preventDefault();
                              if (supplier?.IsActive) {
                                history.push(
                                  `/supply-company/${supplier?.objId_Customer}`
                                );
                              } else if (
                                supplier?.RequestStatusText ==
                                "Request sent to supplier"
                              ) {
                                CallToast(
                                  "Invite sent",
                                  false,
                                  false,
                                  "v2style"
                                );
                                if (
                                  supplier?.objId_Customer &&
                                  supplier?.objId_Relationship &&
                                  supplier?.ObjId_SendQuestionnaire
                                )
                                  openSupplierDetailScreenInRequestsModal(
                                    supplier?.objId_Customer,
                                    supplier?.objId_Relationship,
                                    supplier?.ObjId_SendQuestionnaire
                                  );
                              } else if (
                                supplier?.RequestStatusText == "Unmanaged"
                              ) {
                                setSelectedPotentialSupplierData(supplier);
                                // setShowEditSupplierModal({
                                //   show: true,
                                //   supplier: supplier,
                                // });
                                setShowManagePotentialSupplierModal({
                                  show: true,
                                  supplier: supplier,
                                });
                                // setShowManagePotentialSupplierModal(true);
                              } else {
                                openSupplierDetailScreenInRequestsModal(
                                  supplier?.objId_Customer,
                                  supplier?.objId_Relationship,
                                  supplier?.ObjId_SendQuestionnaire
                                );
                              }
                            }}
                          >
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="supplier-popover-parent">
                                  <img
                                    alt=""
                                    width={32}
                                    height={32}
                                    src={supplier.SupplierLogo}
                                    className="mr-2 rounded-full"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src =
                                        "/images/no-image.png";
                                    }}
                                  />
                                  {supplier?.IsActive && (
                                    <SupplierDetailsPopover
                                      Details={supplier?.Details}
                                      ServicesProvided={
                                        supplier?.ServicesProvided
                                      }
                                      SupplierProfileName={
                                        supplier?.SupplierName
                                      }
                                      showResumeBtn={
                                        supplier?.Show_ResumeButton
                                      }
                                      SharingSince={supplier?.SharingSince}
                                      SupplierLogo={supplier?.SupplierLogo}
                                      subtitle={supplier?.SupplierProfileName}
                                      RequestStatusText={
                                        supplier?.RequestStatusText
                                      }
                                      supplierId={supplier?.objId_Customer}
                                    />
                                  )}
                                </div>
                              </div>

                              <div>
                                <p className="m-0 f-14 f-500">
                                  {supplier.SupplierName}
                                </p>
                                <div className="m-0 f-12 f-grey">
                                  {supplier?.ProfilingName ??
                                    "Untitled profile"}
                                </div>
                              </div>
                            </div>
                            {/* <SupplierInfoDropdown
                              supplier={supplier}
                              // onClick={() => {
                              //   if (supplier?.IsActive) {
                              //     history.push(
                              //       `/supply-company/${supplier?.objId_Customer}`
                              //     );
                              //   } else {
                              //     openSupplierDetailScreenInRequestsModal(
                              //       supplier?.objId_Customer,
                              //       supplier?.objId_Relationship,
                              //       supplier?.ObjId_SendQuestionnaire
                              //     );
                              //   }
                              // }}
                            /> */}
                            {supplier.HideContextMenuAndDetails === false ? (
                              <div className="nested-row ">
                                <div className="d-flex align-items-center">
                                  {supplier?.IsChangeRequest && false ? (
                                    <span className="f-darkgrey">
                                      {supplier?.RequestStatusText}
                                    </span>
                                  ) : (
                                    <img
                                      alt=""
                                      width={14}
                                      src={supplier.StatusIconUrl}
                                    />
                                  )}
                                </div>
                                <div className="f-grey- d-flex align-items-center">
                                  <BlueComingSoonTag />
                                </div>
                                <div className="f-grey d-flex align-items-center f-black">
                                  {supplier?.ProfilingStatus_Text}
                                </div>
                                <div className="f-grey d-flex align-items-center f-black">
                                  {supplier?.ProfilingNextDue}
                                </div>
                                <div className="f-grey d-flex align-items-center f-12">
                                  {/* {supplier?.managed && (
                                    <div className="f-black">
                                      <img
                                        src="/images/msp/managed-folder.svg"
                                        alt=""
                                        className="mr-2"
                                      />
                                      Managed
                                    </div>
                                  )} */}
                                  {/* {supplier?.requestDeclined && (
                                    <div className="redText">
                                      <img
                                        src="/images/msp/red-cross-icon.svg"
                                        alt=""
                                        className="mr-2"
                                      />
                                      Request declined
                                    </div>
                                  )} */}
                                  {/* {supplier?.showSendRequest && (
                                    <div className="f-blue">
                                      <img
                                        src="/images/msp/blue-user-icon.svg"
                                        alt=""
                                        className="mr-2"
                                      />
                                      Send Request
                                    </div>
                                  )} */}
                                  <div
                                    className="d-flex "
                                    style={{
                                      color: supplier?.RequestStatusColour,
                                    }}
                                  >
                                    <div>
                                      <img
                                        src={supplier?.RequestStatusIcon}
                                        alt=""
                                        width={14}
                                        className="mr-2"
                                      />
                                    </div>

                                    <div>{supplier?.RequestStatusText}</div>
                                  </div>
                                </div>

                                <div className="d-flex align-items-center justify-content-end mr-4 supplier-list-dropdown">
                                  <div
                                    className={`menu-dropdown  `}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <Dropdown className="device-dropdown">
                                      <Dropdown.Toggle>
                                        <img
                                          alt=""
                                          src="/images/horizontal-dots.svg"
                                        />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <div>
                                          <Row>
                                            {supplier?.ContextMenu?.ContextMenuItems.map(
                                              (menuItem, menuItemIndex) => {
                                                return (
                                                  <OverlayTrigger
                                                    placement="left"
                                                    trigger={
                                                      !menuItem.isEnabled
                                                        ? ["hover", "focus"]
                                                        : []
                                                    }
                                                    delay={{
                                                      show: 200,
                                                      hide: 250,
                                                    }}
                                                    overlay={
                                                      <Tooltip
                                                        id={`tooltip-1365`}
                                                        className="custom_tooltip_access"
                                                      >
                                                        This feature is managed
                                                        by your service provider
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <div>
                                                      <Col
                                                        xs={12}
                                                        className="  pointer "
                                                      >
                                                        <div
                                                          style={{
                                                            pointerEvents:
                                                              !menuItem.isEnabled
                                                                ? "none"
                                                                : "all",
                                                          }}
                                                          className="drop-item"
                                                          onClick={(e) => {
                                                            if (
                                                              menuItem?.newRequestStatus_Id ===
                                                              9
                                                            ) {
                                                              setSuspendRemoveSupplierDetals(
                                                                {
                                                                  supplierId:
                                                                    supplier?.objId_Customer &&
                                                                    supplier?.objId_Customer >
                                                                      0
                                                                      ? supplier?.objId_Customer
                                                                      : supplier?.objId_UnManagedSupplier,
                                                                  supplierSupplyChainId:
                                                                    supplier?.SupplyChainId,
                                                                  type: `${
                                                                    menuItem?.newRequestStatus_Id ===
                                                                    3
                                                                      ? "Remove"
                                                                      : "Suspend"
                                                                  }`,
                                                                  supplierName:
                                                                    supplier?.SupplierName,
                                                                  supplierLogo:
                                                                    supplier?.SupplierLogo,
                                                                  IsUnmanagedSupplier:
                                                                    supplier?.objId_Customer >
                                                                    0
                                                                      ? 0
                                                                      : 1,
                                                                }
                                                              );
                                                              setShowChangeAccessLevelModal(
                                                                true
                                                              );
                                                              return;
                                                            }
                                                            if (
                                                              menuItem?.callPageEnum ===
                                                              454
                                                            ) {
                                                              printSupplierReport(
                                                                supplier?.objId_Customer
                                                              );
                                                              e.target
                                                                .closest(
                                                                  ".device-dropdown"
                                                                )
                                                                .click();
                                                              return;
                                                            }
                                                            if (
                                                              menuItem?.callPageEnum ===
                                                              783
                                                            ) {
                                                              printProfilingReport(
                                                                supplier?.ObjId_SendQuestionnaire
                                                              );
                                                              e.target
                                                                .closest(
                                                                  ".device-dropdown"
                                                                )
                                                                .click();
                                                              return;
                                                            }
                                                            if (
                                                              menuItem?.newRequestStatus_Id ===
                                                                3 ||
                                                              menuItem?.newRequestStatus_Id ===
                                                                5
                                                            ) {
                                                              setSuspendRemoveSupplierDetals(
                                                                {
                                                                  supplierId:
                                                                    supplier?.objId_Customer &&
                                                                    supplier?.objId_Customer >
                                                                      0
                                                                      ? supplier?.objId_Customer
                                                                      : supplier?.objId_UnManagedSupplier,
                                                                  supplierSupplyChainId:
                                                                    supplier?.SupplyChainId,
                                                                  type: `${
                                                                    menuItem?.newRequestStatus_Id ===
                                                                    3
                                                                      ? "Remove"
                                                                      : "Suspend"
                                                                  }`,
                                                                  supplierName:
                                                                    supplier?.SupplierName,
                                                                  supplierLogo:
                                                                    supplier?.SupplierLogo,
                                                                  IsUnmanagedSupplier:
                                                                    supplier?.objId_Customer >
                                                                    0
                                                                      ? 0
                                                                      : 1,
                                                                }
                                                              );
                                                              setSuspendRemoveModal(
                                                                true
                                                              );
                                                              return;
                                                            }

                                                            if (
                                                              menuItem?.newRequestStatus_Id ===
                                                              -99
                                                            ) {
                                                              if (
                                                                menuItem?.menuActionLabel ==
                                                                "Profile Supplier"
                                                              ) {
                                                                setShowProfilingModal(
                                                                  true
                                                                );
                                                                setSelectedSupplier(
                                                                  supplier
                                                                );
                                                              } else {
                                                                setSelectedPotentialSupplierData(
                                                                  supplier
                                                                );

                                                                setShowManagePotentialSupplierModal(
                                                                  {
                                                                    show: true,
                                                                    supplier:
                                                                      supplier,
                                                                  }
                                                                );
                                                              }
                                                              // openSupplierDetailScreenInRequestsModal(
                                                              //   supplier?.objId_Customer,
                                                              //   supplier?.objId_Relationship
                                                              // );

                                                              return;
                                                            }

                                                            updateSupplierStatus(
                                                              supplier?.objId_Customer,
                                                              menuItem?.newRequestStatus_Id,
                                                              supplier?.objId_Relationship
                                                            );
                                                            e.target
                                                              .closest(
                                                                ".device-dropdown"
                                                              )
                                                              .click();
                                                          }}
                                                        >
                                                          {!menuItem.isEnabled ? (
                                                            <img
                                                              alt=""
                                                              src="/images/attack-surface/small-lock.svg"
                                                              className="mr-2"
                                                            />
                                                          ) : (
                                                            <></>
                                                          )}
                                                          {
                                                            menuItem?.menuActionLabel
                                                          }
                                                        </div>
                                                      </Col>
                                                    </div>
                                                  </OverlayTrigger>
                                                );
                                              }
                                            )}
                                          </Row>
                                        </div>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="f-darkgrey d-flex align-items-center justify-content-end">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div
                                    className="d-flex "
                                    style={{
                                      color: supplier?.RequestStatusColour,
                                    }}
                                  >
                                    <div>
                                      <img
                                        src={supplier?.RequestStatusIcon}
                                        alt=""
                                        width={14}
                                        className="mr-2"
                                      />
                                    </div>

                                    <div>{supplier?.RequestStatusText}</div>
                                  </div>
                                  <div className="f-12 f-500 redText mx-5">
                                    Remove
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    {/* bottom notifi */}

                    <div>
                      <Button
                        className={`hg-cancel-btn  mt-2 mb-5    ${
                          props.supplierNextPage ? "" : "d-none"
                        } `}
                        onClick={() => {
                          props.getNextPage();
                        }}
                        disabled={props.nextPageLoading}
                      >
                        {props.nextPageLoading ? (
                          <>
                            <GreyLoader width={13} /> Loading..
                          </>
                        ) : (
                          "Show more"
                        )}
                      </Button>
                    </div>
                  </div>
                )}

                <div className="test" style={{ padding: "110px" }}></div>
              </Tab.Pane>

              {/* Compliance Profiles */}

              <Tab.Pane eventKey="complianceProfiles">
                <div className="profiles-list d-flex flex-wrap mx-5 mt-2">
                  {props.complianceProfilesList &&
                    props.complianceProfilesList.map(
                      (profile, profileIndex) => {
                        return (
                          <div
                            className="compliance-item p-3 d-flex flex-column justify-content-between comProfile mr-2 mt-2"
                            onClick={() => {
                              props.setPage({
                                type: "complianceSupplierList",
                                name: profile?.ComplianceProfileName,
                                data: profile,
                              });
                            }}
                          >
                            <div>
                              <div className="d-flex align-items-center justify-content-between">
                                <span
                                  className="d-inline-flex f-500 f-black mb-2 align-items-center"
                                  title={profile?.ComplianceProfileName}
                                >
                                  <img
                                    alt=""
                                    width={14}
                                    height={14}
                                    src={
                                      profile?.ComplianceProfileStatusIcon ||
                                      "/images/no-image.png"
                                    } //"/images/supply-chain/grey-tick.svg"
                                    className="mr-2 rounded-full"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src =
                                        "/images/no-image.png";
                                    }}
                                  />
                                  {TrimString(
                                    profile?.ComplianceProfileName,
                                    20
                                  )}
                                </span>
                                <span
                                  className="d-inline-flex profile-dropdown"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <Dropdown className="device-dropdown">
                                    <Dropdown.Toggle>
                                      <img
                                        alt=""
                                        src="/images/horizontal-dots.svg"
                                      />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <div>
                                        <Row>
                                          <Col xs={12} className=" pointer">
                                            <div
                                              className="drop-item"
                                              onClick={(e) => {
                                                e.target
                                                  .closest(".device-dropdown")
                                                  .click();
                                                setComplianceProfileId(
                                                  profile?.objId_ComplianceProfile
                                                );
                                                setShowAddComplianceModal(true);
                                                // setShowReassignModal(true);
                                              }}
                                            >
                                              Edit
                                            </div>
                                          </Col>
                                          <Col xs={12} className="  pointer">
                                            <div
                                              className="drop-item"
                                              onClick={(e) => {
                                                e.target
                                                  .closest(".device-dropdown")
                                                  .click();
                                                createDuplicateComplianceProfile(
                                                  profile?.objId_ComplianceProfile
                                                );
                                              }}
                                            >
                                              Duplicate
                                            </div>
                                          </Col>
                                          {profile.SupplierList?.length ===
                                            0 && (
                                            <Col xs={12} className="  pointer">
                                              <div
                                                className="drop-item"
                                                onClick={(e) => {
                                                  e.target
                                                    .closest(".device-dropdown")
                                                    .click();
                                                  setComplianceProfileId(
                                                    profile?.objId_ComplianceProfile
                                                  );
                                                  setComplianceProfileSuppliersList(
                                                    profile?.SupplierList
                                                  );
                                                  setShowReassignModal(true);
                                                }}
                                              >
                                                Delete
                                              </div>
                                            </Col>
                                          )}
                                        </Row>
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </span>
                              </div>

                              <div className="d-flex align-items-center justify-content-between">
                                <span className="d-inline-flex f-grey">
                                  Risky Suppliers
                                </span>
                                <span className="d-inline-flex f-darkgrey">
                                  {profile?.RiskySupplierCount} of{" "}
                                  {profile?.TotalSupplierCount}
                                </span>
                              </div>
                              <div className="d-flex align-items-center justify-content-between">
                                <span className="d-inline-flex f-grey">
                                  Compliant Suppliers
                                </span>
                                <span className="d-inline-flex f-darkgrey">
                                  {profile?.HealthySupplierCount} of{" "}
                                  {profile?.TotalSupplierCount}
                                </span>
                              </div>
                            </div>

                            <div className="d-flex align-items-center justify-content-between">
                              <span className="d-inline-flex">
                                <div className="image-group d-flex">
                                  {profile.SupplierList.slice(0, 3).map(
                                    (supplier, supplierIndex) => {
                                      return (
                                        <img
                                          alt=""
                                          width={24}
                                          height={24}
                                          src={supplier?.SupplierLogo} //"/images/supply-chain/grey-tick.svg"
                                          className="mr-2 rounded-full"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src =
                                              "/images/no-image.png";
                                          }}
                                        />
                                      );
                                    }
                                  )}
                                  {profile.SupplierList?.length > 3 && (
                                    <div
                                      className="bg-grey rounded-full f-black f-12 f-500"
                                      style={{
                                        background: "#d3d8df",
                                        color: "white",
                                        marginLeft: "-10px",
                                        zIndex: 0,
                                        padding: "3px",
                                      }}
                                    >
                                      +{profile.SupplierList?.length - 3}
                                    </div>
                                  )}
                                </div>
                              </span>
                              <span className="d-inline-flex f-darkgrey">
                                {profile?.TotalSupplierCount} suppliers
                              </span>
                            </div>
                          </div>
                        );
                      }
                    )}
                  <OverlayTrigger
                    placement="bottom"
                    trigger={
                      viewOnlyAccess("AccessState_SCM")
                        ? ["hover", "focus"]
                        : []
                    }
                    delay={{ show: 200, hide: 250 }}
                    overlay={
                      <Tooltip
                        id={`tooltip-1365`}
                        className="custom_tooltip_access"
                      >
                        This feature is managed by your service provider
                      </Tooltip>
                    }
                  >
                    <div>
                      <div
                        className="d-flex align-items-center justify-content-center add-profile-div mr-2 mt-2"
                        style={{
                          pointerEvents: viewOnlyAccess("AccessState_SCM")
                            ? "none"
                            : "all",
                        }}
                        onClick={() => {
                          setComplianceProfileId(0);
                          setShowAddComplianceModal(true);
                        }}
                      >
                        <span className="f-darkgrey f-500">
                          {viewOnlyAccess("AccessState_SCM") ? (
                            <img
                              alt=""
                              src="/images/attack-surface/small-lock.svg"
                              className="mr-2"
                            />
                          ) : (
                            <>+</>
                          )}
                          &nbsp;Add compliance profile
                        </span>
                      </div>
                    </div>
                  </OverlayTrigger>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      <Suspense fallback={<div>Loading...</div>}>
        <AddComplianceProfileModal
          show={showAddComplianceModal}
          hideModal={() => setShowAddComplianceModal(false)}
          complianceProfileId={complianceProfileId}
          refreshData={() => {
            props.getComplianceProfiles(complianceFilter?.id);

            SCMFilterDropdown.current.refreshFilters();
          }}
        />
      </Suspense>

      <ReassignSupplierModal
        oldComplianceProfileId={complianceProfileId}
        show={showReassignModal}
        hideModal={() => setShowReassignModal(false)}
        refreshData={() => {
          props.getComplianceProfiles(complianceFilter?.id);

          SCMFilterDropdown.current.refreshFilters();
        }}
        suppliersList={complianceProfileSuppliersList}
      />
      <ChangeAccessLevelModal
        show={showChangeAccessLevelModal}
        hideModal={() => setShowChangeAccessLevelModal(false)}
        supplierId={suspendRemoveSupplierDetals?.supplierId}
        // supplierChainId={suspendRemoveSupplierDetals?.supplierSupplyChainId}
        refreshData={() => props.refreshData()}
      />

      <SuspendRemoveCompanyModal
        show={suspendRemoveModal}
        hideModal={() => setSuspendRemoveModal(false)}
        type={suspendRemoveSupplierDetals?.type}
        supplierName={suspendRemoveSupplierDetals.supplierName}
        supplierLogo={suspendRemoveSupplierDetals?.supplierLogo}
        supplierId={suspendRemoveSupplierDetals?.supplierId}
        supplierChainId={suspendRemoveSupplierDetals?.supplierSupplyChainId}
        IsUnmanagedSupplier={suspendRemoveSupplierDetals?.IsUnmanagedSupplier}
        refreshData={() => props.refreshData()}
      />
      <ManageSupplierModal
        show={showManagePotentialSupplierModal.show}
        hideModal={() => setShowManagePotentialSupplierModal({ show: false })}
        editSupplierData={showManagePotentialSupplierModal.supplier}
        refreshData={() => props.refreshData()}
      />
      <EditSupplierModal
        show={showEditSupplierModal.show}
        hideModal={() => setShowEditSupplierModal({ show: false })}
        refreshData={() => props.refreshData()}
        editSupplierData={showEditSupplierModal.supplier}
      />
      <ProfilingModal
        show={showProfilingModal}
        hideModal={() => setShowProfilingModal(false)}
        setSelectedTemplate={setSelectedTemplate}
        selectedTemplate={selectedTemplate}
        setTitleTemplate={setTitleTemplate}
        selectedSupplier={selectedSupplier}
      />
    </div>
  );
};

export default SuppliersAndComplianceProfilesTabs;
export function handleUnmanagedText(text) {
  if (text === null || text === undefined || text === "") return "";
  if (text?.toLowerCase() === "un managed") {
    return "Unmanaged";
  } else {
    return text;
  }
}

const dummy_Data = [
  {
    objId_Customer: 3963230933,
    objId_Relationship: 4229021,
    SupplierLogo: "https://source.unsplash.com/random/300×300",
    SupplierName: "Mohit Account 2",
    showSendRequest: true,
    SupplierProfileName: "",
    SupplierStatus: 2,
    StatusIconUrl:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/InfoRed.svg",
    SupplierRisk: 0,
    SupplierRiskMax: 100,
    SupplierCyberScore: 18,
    SupplierCyberScoreMax: 100,
    SupplierCyberThreat: 110,
    SupplierCyberThreatMax: 145,
    SupplierCyberCompliance: 0,
    SupplierCyberComplianceMax: 100,
    ProfilingName: null,
    ProfilingStatus_Text: null,
    ProfilingNextDue: null,
    ObjId_SendQuestionnaire: 0,
    RequestStatusText: "Active",
    SharingSince: "May 18, 2024",
    Show_ResumeButton: false,
    IsActive: true,
    IsChangeRequest: false,
    Details: [],
    ServicesProvided: [],
    ContextMenu: {
      requestList_ItemVisible: false,
      requestDetail_MenuVisible: false,
      supplierList_MenuVisible: true,
      supplierComment_Visible: false,
      showResponseComment: false,
      loadDrillDownOnly: false,
      contextMenuLabel: "",
      allowEditAccessAndCompliance: false,
      ContextMenuItems: [
        {
          sortOrder: 1,
          Description: "Client - Print Report",
          IsButton: false,
          menuActionLabel: "Print Supplier Report",
          newRequestStatus_Id: -99,
          newRequestStatus_Text: "No Change",
          callPageEnum: 454,
          optionEnum2: -99,
        },
        {
          sortOrder: 2,
          Description: "Client - Access Level Change Request",
          IsButton: false,
          menuActionLabel: "Change Access Level",
          newRequestStatus_Id: 9,
          newRequestStatus_Text: "Change_Request_By_Client",
          callPageEnum: 346,
          optionEnum2: -99,
        },
        {
          sortOrder: 3,
          Description: "Client - Suspend Supplier",
          IsButton: false,
          menuActionLabel: "Suspend supplier",
          newRequestStatus_Id: 5,
          newRequestStatus_Text: "Suspended_By_Client",
          callPageEnum: 346,
          optionEnum2: -99,
        },
        {
          sortOrder: 4,
          Description: "Client - Remove Supplier",
          IsButton: false,
          menuActionLabel: "Remove supplier",
          newRequestStatus_Id: 3,
          newRequestStatus_Text: "Removed_By_Client",
          callPageEnum: 346,
          optionEnum2: -99,
        },
      ],
      ButtonMenuItems: [],
      ResponseModalText: null,
    },
    SupplyChainId: "221-762-765",
    DisableItem: false,
  },
  {
    objId_Customer: 39630933,
    objId_Relationship: 4229021,
    SupplierLogo: "https://source.unsplash.com/random/300×300",
    SupplierName: "Mohit Account",
    requestDeclined: true,
    SupplierProfileName: "",
    SupplierStatus: 2,
    StatusIconUrl:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/InfoRed.svg",
    SupplierRisk: 0,
    SupplierRiskMax: 100,
    SupplierCyberScore: 18,
    SupplierCyberScoreMax: 100,
    SupplierCyberThreat: 110,
    SupplierCyberThreatMax: 145,
    SupplierCyberCompliance: 0,
    SupplierCyberComplianceMax: 100,
    ProfilingName: null,
    ProfilingStatus_Text: null,
    ProfilingNextDue: null,
    ObjId_SendQuestionnaire: 0,
    RequestStatusText: "Active",
    SharingSince: "May 18, 2024",
    Show_ResumeButton: false,
    IsActive: true,
    IsChangeRequest: false,
    Details: [],
    ServicesProvided: [],
    ContextMenu: {
      requestList_ItemVisible: false,
      requestDetail_MenuVisible: false,
      supplierList_MenuVisible: true,
      supplierComment_Visible: false,
      showResponseComment: false,
      loadDrillDownOnly: false,
      contextMenuLabel: "",
      allowEditAccessAndCompliance: false,
      ContextMenuItems: [
        {
          sortOrder: 1,
          Description: "Client - Print Report",
          IsButton: false,
          menuActionLabel: "Print Supplier Report",
          newRequestStatus_Id: -99,
          newRequestStatus_Text: "No Change",
          callPageEnum: 454,
          optionEnum2: -99,
        },
        {
          sortOrder: 2,
          Description: "Client - Access Level Change Request",
          IsButton: false,
          menuActionLabel: "Change Access Level",
          newRequestStatus_Id: 9,
          newRequestStatus_Text: "Change_Request_By_Client",
          callPageEnum: 346,
          optionEnum2: -99,
        },
        {
          sortOrder: 3,
          Description: "Client - Suspend Supplier",
          IsButton: false,
          menuActionLabel: "Suspend supplier",
          newRequestStatus_Id: 5,
          newRequestStatus_Text: "Suspended_By_Client",
          callPageEnum: 346,
          optionEnum2: -99,
        },
        {
          sortOrder: 4,
          Description: "Client - Remove Supplier",
          IsButton: false,
          menuActionLabel: "Remove supplier",
          newRequestStatus_Id: 3,
          newRequestStatus_Text: "Removed_By_Client",
          callPageEnum: 346,
          optionEnum2: -99,
        },
      ],
      ButtonMenuItems: [],
      ResponseModalText: null,
    },
    SupplyChainId: "221-762-765",
    DisableItem: false,
  },
  {
    objId_Customer: 3963093,
    objId_Relationship: 4229021,
    SupplierLogo: "https://source.unsplash.com/random/300×300",
    SupplierName: "Mohit Test Account",
    managed: true,
    SupplierProfileName: "",
    SupplierStatus: 2,
    StatusIconUrl:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/InfoRed.svg",
    SupplierRisk: 0,
    SupplierRiskMax: 100,
    SupplierCyberScore: 18,
    SupplierCyberScoreMax: 100,
    SupplierCyberThreat: 110,
    SupplierCyberThreatMax: 145,
    SupplierCyberCompliance: 0,
    SupplierCyberComplianceMax: 100,
    ProfilingName: null,
    ProfilingStatus_Text: null,
    ProfilingNextDue: null,
    ObjId_SendQuestionnaire: 0,
    RequestStatusText: "Active",
    SharingSince: "May 18, 2024",
    Show_ResumeButton: false,
    IsActive: true,
    IsChangeRequest: false,
    Details: [],
    ServicesProvided: [],
    ContextMenu: {
      requestList_ItemVisible: false,
      requestDetail_MenuVisible: false,
      supplierList_MenuVisible: true,
      supplierComment_Visible: false,
      showResponseComment: false,
      loadDrillDownOnly: false,
      contextMenuLabel: "",
      allowEditAccessAndCompliance: false,
      ContextMenuItems: [
        {
          sortOrder: 1,
          Description: "Client - Print Report",
          IsButton: false,
          menuActionLabel: "Print Supplier Report",
          newRequestStatus_Id: -99,
          newRequestStatus_Text: "No Change",
          callPageEnum: 454,
          optionEnum2: -99,
        },
        {
          sortOrder: 2,
          Description: "Client - Access Level Change Request",
          IsButton: false,
          menuActionLabel: "Change Access Level",
          newRequestStatus_Id: 9,
          newRequestStatus_Text: "Change_Request_By_Client",
          callPageEnum: 346,
          optionEnum2: -99,
        },
        {
          sortOrder: 3,
          Description: "Client - Suspend Supplier",
          IsButton: false,
          menuActionLabel: "Suspend supplier",
          newRequestStatus_Id: 5,
          newRequestStatus_Text: "Suspended_By_Client",
          callPageEnum: 346,
          optionEnum2: -99,
        },
        {
          sortOrder: 4,
          Description: "Client - Remove Supplier",
          IsButton: false,
          menuActionLabel: "Remove supplier",
          newRequestStatus_Id: 3,
          newRequestStatus_Text: "Removed_By_Client",
          callPageEnum: 346,
          optionEnum2: -99,
        },
      ],
      ButtonMenuItems: [],
      ResponseModalText: null,
    },
    SupplyChainId: "221-762-765",
    DisableItem: false,
  },
  {
    objId_Customer: 3781235,
    objId_Relationship: 3622736,
    SupplierLogo: "https://source.unsplash.com/random/300×300",
    SupplierName: "Music Forever",
    SupplierProfileName: null,
    SupplierStatus: 0,
    StatusIconUrl: null,
    SupplierRisk: -1,
    SupplierRiskMax: 100,
    SupplierCyberScore: -1,
    SupplierCyberScoreMax: 100,
    SupplierCyberThreat: -1,
    SupplierCyberThreatMax: 145,
    SupplierCyberCompliance: -1,
    SupplierCyberComplianceMax: 100,
    ProfilingName: null,
    ProfilingStatus_Text: null,
    ProfilingNextDue: null,
    ObjId_SendQuestionnaire: 0,
    RequestStatusText: "Request not sent to supplier",
    SharingSince: "Mar 22, 2024",
    Show_ResumeButton: false,
    IsActive: false,
    IsChangeRequest: false,
    Details: [],
    ServicesProvided: [],
    ContextMenu: {
      requestList_ItemVisible: true,
      requestDetail_MenuVisible: false,
      supplierList_MenuVisible: true,
      supplierComment_Visible: false,
      showResponseComment: false,
      loadDrillDownOnly: false,
      contextMenuLabel: "Request not sent",
      allowEditAccessAndCompliance: true,
      ContextMenuItems: [
        {
          sortOrder: 1,
          Description: "Client - show Request Details",
          IsButton: false,
          menuActionLabel: "Review / Send",
          newRequestStatus_Id: -99,
          newRequestStatus_Text: "No Change",
          callPageEnum: 346,
          optionEnum2: -99,
        },
        {
          sortOrder: 2,
          Description: "Client - Archive Request",
          IsButton: false,
          menuActionLabel: "Archive",
          newRequestStatus_Id: 10,
          newRequestStatus_Text: "Archived_By_Client",
          callPageEnum: -99,
          optionEnum2: -99,
        },
      ],
      ButtonMenuItems: [],
      ResponseModalText: null,
    },
    SupplyChainId: "217-916-756",
    DisableItem: false,
  },
  {
    objId_Customer: 3324341,
    objId_Relationship: 3622620,
    SupplierLogo: "https://source.unsplash.com/random/300×300",
    SupplierName: "one piece",
    SupplierProfileName: "",
    SupplierStatus: 0,
    StatusIconUrl: null,
    SupplierRisk: -1,
    SupplierRiskMax: 100,
    SupplierCyberScore: -1,
    SupplierCyberScoreMax: 100,
    SupplierCyberThreat: -1,
    SupplierCyberThreatMax: 145,
    SupplierCyberCompliance: -1,
    SupplierCyberComplianceMax: 100,
    ProfilingName: null,
    ProfilingStatus_Text: null,
    ProfilingNextDue: null,
    ObjId_SendQuestionnaire: 0,
    RequestStatusText: "Request sent to supplier",
    SharingSince: "Mar 22, 2024",
    Show_ResumeButton: false,
    IsActive: false,
    IsChangeRequest: false,
    Details: [],
    ServicesProvided: [],
    ContextMenu: {
      requestList_ItemVisible: true,
      requestDetail_MenuVisible: false,
      supplierList_MenuVisible: true,
      supplierComment_Visible: false,
      showResponseComment: false,
      loadDrillDownOnly: false,
      contextMenuLabel: "Request sent",
      allowEditAccessAndCompliance: false,
      ContextMenuItems: [
        {
          sortOrder: 1,
          Description: "Client - Details",
          IsButton: false,
          menuActionLabel: "Details",
          newRequestStatus_Id: -99,
          newRequestStatus_Text: "No Change",
          callPageEnum: 346,
          optionEnum2: -99,
        },
        {
          sortOrder: 2,
          Description: "Client - Cancel Request",
          IsButton: false,
          menuActionLabel: "Cancel Request",
          newRequestStatus_Id: 8,
          newRequestStatus_Text: "Cancelled_By_Client",
          callPageEnum: -99,
          optionEnum2: -99,
        },
        {
          sortOrder: 3,
          Description: "Client -",
          IsButton: false,
          menuActionLabel: "Resend request",
          newRequestStatus_Id: 1,
          newRequestStatus_Text: "Sent",
          callPageEnum: -99,
          optionEnum2: -99,
        },
      ],
      ButtonMenuItems: [],
      ResponseModalText: null,
    },
    SupplyChainId: "438-822-214",
    DisableItem: false,
  },
];
