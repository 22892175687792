import React, { Fragment, useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  Table,
  Button,
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { getAPIData } from "../../../Utils/commonApi";
import { useDb } from "../../context/db";
import { useAuth } from "../../context/Auth";
import ModalDynamic from "../../ModalDynamic/ModalDynamic";
import AddNewUser from "../../AddNewUser/AddNewUser";
import ContentLoader from "react-content-loader";

const UsersAndDevices = (props) => {
  let history = useHistory();

  useEffect(() => {
    getUserDevicesDetails();
    setNewUserModalOpen(false);
  }, []);

  const { authTokens } = useAuth();
  const { dbObj } = useDb();
  const [userDevicesDetails, setUserDevicesDetails] = useState([]);
  const [closeModal, setCloseModal] = useState(false);
  const [newUserModalOpen, setNewUserModalOpen] = useState(false);
  const deviceID = dbObj && dbObj.UserDetail.objId_UserSettings;
  const handleUserProfile = (index) => {
    history.push(`/system_settings/user_profile/${index}`);
  };

  const handleNewUser = () => {
    setNewUserModalOpen(false);
    setInterval(() => {
      setNewUserModalOpen(true);
    }, 100);
  };

  async function getUserDevicesDetails() {
    const result = await getAPIData("101", authTokens);
    setUserDevicesDetails(result);
  }

  return userDevicesDetails.length == 0 ? (
    <ContentLoader
      speed={2}
      width={1500}
      height={700}
      viewBox="0 0 1500 700"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="20" y="5" rx="12" ry="12" width="100%" height="600" />
    </ContentLoader>
  ) : (
    <Fragment>
      <div className="settings_container">
        <Table className="settings_table cybercare-table2" striped borderless>
          <thead>
            <tr>
              <th className="border-bottom">
                <span className="linkGreyText">Name</span>
              </th>
              <th className="border-bottom">
                <span className="linkGreyText">Email</span>
              </th>
              <th className="border-bottom">
                <span className="linkGreyText">Devices</span>
              </th>
              <th className="border-bottom">
                <span className="linkGreyText">Added</span>
              </th>
              <th className="border-bottom">
                <span className="linkGreyText">Status</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {userDevicesDetails.UserAndDevices &&
              userDevicesDetails.UserAndDevices.map((userDetail, index) => (
                <tr
                  key={index}
                  onClick={() => handleUserProfile(index)}
                  className="cursor-pointer"
                >
                  <td className="d-flex">
                    {userDetail?.UserDetails?.showInitials ? (
                      <div className="circle-blue align-self-center">
                        {userDetail.UserDetails.Initials}
                      </div>
                    ) : (
                      <img
                        src={userDetail?.UserDetails?.Avatar}
                        className="circle-small align-self-center"
                      />
                    )}
                    <div className="align-self-center">
                      <h1 className="m-0 text-nowrap">
                        {userDetail?.UserDetails?.FullName}
                      </h1>
                      <span className="greyText">
                        {userDetail?.UserDetails?.Role}
                      </span>
                    </div>
                  </td>
                  <td>
                    <span className="txt-small">
                      {userDetail?.UserDetails?.UserName}
                    </span>
                  </td>
                  <td>
                    <img
                      src="/images/icons/computerIcon.svg"
                      style={{ marginRight: "10px" }}
                    />
                    <span className="txt-small">{userDetail.Device_Count}</span>
                  </td>
                  <td>
                    <span className="txt-small">{userDetail.User_Added}</span>
                  </td>
                  <td className="txt-blue">
                    <span
                      className="txt-small"
                      style={{ color: userDetail.User_Status_Colour }}
                    >
                      {userDetail.User_Status}
                    </span>
                  </td>
                </tr>
              ))}
            {userDevicesDetails == "" &&
              [...Array(5)].map((elementInArray, index) => (
                <tr>
                  <td>
                    <ContentLoader
                      speed={2}
                      width={88}
                      height={25}
                      viewBox="0 0 88 25"
                      // backgroundColor="#f3f3f3"
                      // foregroundColor="#ecebeb"
                      {...props}
                    >
                      <rect x="24" y="3" rx="3" ry="3" width="88" height="6" />
                      <circle cx="11" cy="11" r="11" />
                      <rect x="27" y="17" rx="3" ry="3" width="64" height="4" />
                    </ContentLoader>
                  </td>
                  <td>
                    <ContentLoader
                      speed={2}
                      width={88}
                      height={25}
                      viewBox="0 0 88 25"
                      // backgroundColor="#f3f3f3"
                      // foregroundColor="#ecebeb"
                      {...props}
                    >
                      <rect x="24" y="3" rx="3" ry="3" width="84" height="6" />
                      {/* <circle cx="11" cy="11" r="11" /> */}
                      <rect x="27" y="17" rx="3" ry="3" width="64" height="4" />
                    </ContentLoader>
                  </td>
                  <td>
                    <ContentLoader
                      speed={2}
                      width={88}
                      height={25}
                      viewBox="0 0 88 25"
                      // backgroundColor="#f3f3f3"
                      // foregroundColor="#ecebeb"
                      {...props}
                    >
                      <rect x="24" y="3" rx="3" ry="3" width="88" height="6" />
                      {/* <circle cx="11" cy="11" r="11" /> */}
                      <rect x="27" y="17" rx="3" ry="3" width="64" height="4" />
                    </ContentLoader>
                  </td>
                  <td>
                    <ContentLoader
                      speed={2}
                      width={88}
                      height={25}
                      viewBox="0 0 88 25"
                      // backgroundColor="#f3f3f3"
                      // foregroundColor="#ecebeb"
                      {...props}
                    >
                      <rect x="24" y="3" rx="3" ry="3" width="88" height="6" />
                      {/* <circle cx="11" cy="11" r="11" /> */}
                      <rect x="27" y="17" rx="3" ry="3" width="64" height="4" />
                    </ContentLoader>
                  </td>
                  <td>
                    <ContentLoader
                      speed={2}
                      width={88}
                      height={25}
                      viewBox="0 0 88 25"
                      // backgroundColor="#f3f3f3"
                      // foregroundColor="#ecebeb"
                      {...props}
                    >
                      <rect x="24" y="3" rx="3" ry="3" width="88" height="6" />
                      {/* <circle cx="11" cy="11" r="11" /> */}
                      <rect x="27" y="17" rx="3" ry="3" width="64" height="4" />
                    </ContentLoader>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>

        {userDevicesDetails.ShowAddUserButton ? (
          <span
            onClick={() => handleNewUser()}
            className={
              userDevicesDetails.ShowAddUserButton &&
              userDevicesDetails.EnableAddUserButton
                ? "light-blue-btn"
                : "addUserDisable"
            }
            style={{
              pointerEvents: !userDevicesDetails.EnableAddUserButton && "none",
            }}
          >
            Add New User
          </span>
        ) : (
          ""
        )}
        {userDevicesDetails.ShowUpgradeLink ? (
          <>
            <span
              className={`ml24px cursor-pointer ${
                userDevicesDetails.ShowUpgradeLink ? "upgradeLink" : "greyText"
              }`}
              onClick={() => {
                props.setKey("subscriptions");
              }}
            >
              {userDevicesDetails.UpgradeLinkText}
            </span>

            <span>{userDevicesDetails.UserAddMessage}</span>
          </>
        ) : (
          <span className="greyText ml24px">
            {userDevicesDetails.UserAddMessage}
          </span>
        )}
      </div>
      {newUserModalOpen ? (
        <ModalDynamic
          setShow={true}
          modalHeader={"Add New User"}
          modalData={<AddNewUser />}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default UsersAndDevices;
