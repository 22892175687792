import React, { useState, useEffect } from "react";
// import "./networks.scss";
import "./email.scss";
import "../common.scss";
import CompanySettingsDropdown from "../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import { useAuth } from "../../context/Auth";

import {
  AttackSurfaceGetApi,
  getAPIData,
  SubmitQuestionaire,
} from "../../../Utils/commonApi";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import AssetsListMainPageLoader from "../AttackSurfaceLoaders/AssetsListMainPageLoader";
import { AttackSurfaceResetModal } from "../AttackSurfaceResetModal";
import { useHistory } from "react-router-dom";
import { cleanText } from "../Index";
import { showSubtitleOfAsset } from "../communications/Index";
const Email = (props) => {
  const { goTo, setAssetId } = props;
  const history = useHistory();
  const [questionList, setQuestionList] = useState([]);
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState([]);
  const { authTokens } = useAuth();
  const [questionsHeader, setQuestionsHeader] = useState("General");
  const [startRange] = useState(2229);
  const [endRange] = useState(2232);
  const [questionnaireEnum] = useState(352);
  const [questionTypeEnum] = useState(13);
  const [showResetModal, setShowResetModal] = useState(false);

  useEffect(() => {
    // getPageData();
    setMainPageLoading(true);
    AttackSurfaceGetApi(`AttackSurfaceAPI/LandingPage?`, authTokens, {
      ASEComponentSecEnum:5
    }).then((response) => {
      getPageData();
      if (response?.Success) {
        setData(response?.landingPageResponses);
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }, []);

  function getPageData(success = false) {
    getAPIData(questionnaireEnum, authTokens, { optionEmun1: 0 }).then(
      (response) => {
        setMainPageLoading(false);
        if (response?.mr?.Success) {
          setQuestionsHeader(
            response?.QuestionnaireSection_List?.[0]?.["Title"]
          );
          setOriginalData(
            response?.QuestionnaireSection_List?.[0]?.Question_List
          );
          if (success) {
            let updated_data = [...questionList].map((item, index) => {
              return {
                ...item,
                AnswerId:
                  response?.QuestionnaireSection_List?.[0]?.Question_List[index]
                    .AnswerId,
              };
            });

            setQuestionList(updated_data);
          } else {
            let updated_data = [
              ...response?.QuestionnaireSection_List?.[0]?.Question_List,
            ].map((item) => {
              return {
                ...item,
                selectedDropdownValue: item?.AnswerText
                  ? item?.QuestionDropdownListMembers.map((listItem, index) => {
                      return {
                        id: index + 1,
                        category: listItem,
                      };
                    }).filter((filteritem) => {
                      return filteritem.category == item?.AnswerText;
                    })[0]
                  : null,
                dropDowndata: item?.QuestionDropdownListMembers
                  ? item?.QuestionDropdownListMembers.map((listItem, index) => {
                      return {
                        id: index + 1,
                        category: listItem,
                      };
                    })
                  : [],
                loading: false,
              };
            });

            setQuestionList(updated_data);
          }
        }
      }
    );
  }

  const SubmitQuestions = async (data_to_send) => {
    await SubmitQuestionaire(authTokens, "POST", {
      ObjectId: 0,
      QuestionnaireTypeEnum: questionTypeEnum,
      QuestionList: JSON.stringify(data_to_send),
      accesskey: JSON.parse(localStorage.getItem("user_MessageObj")),
    }).then((response) => {
      if (response?.Success) {
        CallToast("Updated successfully", false, false, "v2style");
        window.localStorage.setItem(
          "user_MessageObj",
          JSON.stringify(response?.Message)
        );
        props.refreshMenuItems();
        // getPageData(response?.Success);
        // refreshMenuItems();
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  };

  return (
    <div className="">
      {mainPageLoading ? (
        <AssetsListMainPageLoader />
      ) : (
        <>
          <div className="w-100 d-flex align-items-center justify-content-center bg-lightblue p-2">
            <div className="d-flex align-items-center justify-content-center">
              <img
                alt=""
                src="/images/attack-surface/plug-icon.svg"
                className="mr-3"
              />
              <div className="d-flex align-items-center justify-content-center">
                Automate your data acquisition by integrating your existing
                security tools
              </div>
              <div
                className="d-flex align-items-center justify-content-center f-blue mx-3 pointer"
                onClick={() => {
                  history.push("/integrations");
                }}
              >
                Connect tools
              </div>
            </div>
          </div>

          {/* Main Components */}

          <div className="attack-networks-wrapper">
            {/* General */}
            <div className="font-weight-bold mt-3 mb-2">
              {/* {questionsHeader} */}
              General
            </div>
            <div className="bg-grey radius-8 border-parent">
              {questionList.map((question, questionIndex) => {
                return (
                  <div
                    className="d-flex flex-column border-item"
                    onClick={() => {
                      // goTo(vulnerbility.name);
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-between w-100  px-4  py-3">
                      <div
                        className="d-flex align-align-items-baseline "
                        style={{ width: "75%" }}
                      >
                        <div>
                          <img
                            alt=""
                            src="/images/attack-surface/yellow-dot.svg"
                            className="mr-2"
                            style={{
                              visibility: `${
                                question?.AnswerId === 0 ? "visible" : "hidden"
                              }`,
                            }}
                          />
                        </div>
                        <p className="f-500 m-0">{question?.QuestionText}</p>
                        {/* <p className="f-12 f-darkgrey"> {service.noemptyStatus} </p> */}
                      </div>
                      <div>
                        <CompanySettingsDropdown
                          fieldId={"id"}
                          fieldValue={"category"}
                          selected={question.selectedDropdownValue}
                          placeholder="Select"
                          //  showid={true}
                          onClick={(id, val) => {
                            //   To send Data to API
                            let data_to_send = [...originalData];
                            data_to_send[
                              questionIndex
                            ].AnswserSelectedDropdownItems = [val];
                            data_to_send[questionIndex].AnswerText = val;
                            data_to_send[questionIndex].QuestionStatus = 3;
                            data_to_send[questionIndex].QuestionStatusText =
                              "Answered";
                            setOriginalData(data_to_send);
                            SubmitQuestions(data_to_send);

                            // To Update the drop down value
                            let updated_data = [...questionList];
                            updated_data[questionIndex].selectedDropdownValue =
                              {
                                id,
                                category: val,
                              };
                            updated_data[questionIndex].AnswerId = 123;
                            setQuestionList(updated_data);
                          }}
                          data={question?.dropDowndata}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            {/* Assets list */}

            <div className="font-weight-bold mt-4 mb-2">
              {props?.activePage} Assets
            </div>
            <div className="bg-grey radius-8 border-parent">
              {/* Networks ASSETS */}
              {data.map((asset, assetIndex) => {
                return (
                  <div
                    className="d-flex flex-column border-item"
                    onClick={() => {
                      setAssetId(asset?.ObjectDefID);
                      goTo(
                        cleanText(asset?.ObjectName, {
                          isLandingSreen: true,
                          activePage: props?.activePage,
                        })
                      );
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-between w-100 pointer px-4  py-3 bg-hover-row">
                      <div
                        className="d-flex flex-column "
                        style={{ width: "75%" }}
                      >
                        <div className="d-flex align-items-baseline ">
                          <img
                            alt=""
                            src="/images/attack-surface/yellow-dot.svg"
                            className="mr-1"
                            style={{
                              visibility: `${
                                asset?.ObjectCount > 0 || asset?.Disabled
                                  ? "hidden"
                                  : "visible"
                              }`,
                            }}
                          />

                          <p className="f-500 m-0 f-black">
                            {cleanText(asset?.ObjectName, {
                              isLandingSreen: true,
                              activePage: props?.activePage,
                            })}
                          </p>
                        </div>
                        <p
                          className="f-12 f-darkgrey "
                          style={{ marginLeft: "0.83rem" }}
                        >
                          {showSubtitleOfAsset(
                            cleanText(asset?.ObjectName),
                            asset?.isService,
                            asset?.ObjectCount,
                            asset?.Disabled,
                            props?.activePage
                          )}
                        </p>
                      </div>
                      <div>
                        <img
                          alt=""
                          src="/images/attack-surface/chevron-right.svg"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* Reset button */}

            <div
              className="f-grey  mt-4 ml-2 pointer"
              onClick={() => {
                setShowResetModal(true);
              }}
            >
              <img
                alt=""
                src="/images/attack-surface/loader-icon.svg"
                className="mr-2"
              />
              Reset answers
            </div>
          </div>
        </>
      )}
      <AttackSurfaceResetModal
        refreshData={getPageData}
        refreshMenuItems={props.refreshMenuItems}
        resetPageEnum={364}
        show={showResetModal}
        hideModal={() => {
          setShowResetModal(false);
        }}
      />
    </div>
  );
};

export default Email;
