import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Dropdown } from "react-bootstrap";
import "./mspclients.scss";
import AttackSurfaceTooltip from "../../AttackSurface/AssetsList/AttackSurfaceTooltip"; 

const MSPCatSubcatSortFilters = (props) => {
  const { children, headersList = _headersList, updatedHeaderList, id ="qwe", NoDataStateText="No categories !" } = props;
  const [filteredMenuItems, setfilteredMenuItems] = useState(false);
  const [shownList, setShownList] = useState([]);
  const [hiddenList, setHiddenList] = useState([]);
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEndShown(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      shownList,
      result.source.index,
      result.destination.index
    );
    setShownList(
      items.map((item, index) => {
        return {
          ...item,
          SortOrder: index + 1,
        };
      })
    );
    updatedHeaderList([
      ...items.map((item, index) => {
        return {
          ...item,
          SortOrder: index + 1,
        };
      }),
      ...hiddenList,
    ]);
  }
  function onDragEndHidden(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      hiddenList,
      result.source.index,
      result.destination.index
    );
    setHiddenList(items);
  }

  useEffect(() => {
    setShownList([...headersList]?.filter((item) => item.Visibility === true));
    setHiddenList(
      [...headersList]?.filter((item) => item.Visibility === false)
    );
  }, [headersList]);

  useEffect(() => {
    let dragdropdown = document.getElementById(`drag-drop-dropdown-${id}`);
    if (!dragdropdown) return;
    dragdropdown.classList.add("d-none");
    function CloseDraggableDropdown(e) {
      const DraggableDropdownParent =
        document.getElementById(`dropdown-parent-${id}`);
      const DraggableDropdown = document.getElementById(`drag-drop-dropdown-${id}`);

      // const Parent2 = document.getElementById("parent-2");

      if (
        e.target !== DraggableDropdownParent &&
        !DraggableDropdownParent?.contains(e.target)
      ) {
        if (e.target.id === "rename-div") {
          return null;
        } else {
          setfilteredMenuItems(false);

          DraggableDropdown && DraggableDropdown.classList.add("d-none");
        }
      }
    }

    document.removeEventListener("click", CloseDraggableDropdown);
    document.addEventListener("click", CloseDraggableDropdown);

    return () => {
      document.removeEventListener("click", CloseDraggableDropdown);
    };
  }, [id,props.loading]);
  return (
    <div className="mx-2 clients-filter-dropdown action_center">
      <>
        <Dropdown
          id={`dropdown-parent-${id}`}
          className="table-drop-down edit-section-dropdown position-relative mt-1 "
        >
          <Dropdown.Toggle
            className={`section-list-toggle`}
            onClick={() => {
              const dragdropdown =
                document.getElementById(`drag-drop-dropdown-${id}`);
              dragdropdown.classList.toggle("d-none");
            }}
          >
            <div className="navtab" id="low">
              {children}
            </div>
          </Dropdown.Toggle>
          <div
            id={`drag-drop-dropdown-${id}`}
            // ${filteredMenuItems ? "w-320" : "w-240"}
            className={`dropdown_menu_list drop-drag-issue position-absolute   p-0  d-none
             
              
              `}

              style={{
                width:filteredMenuItems ? "320px" : "240px"
              }}
          >
            {!filteredMenuItems ? (
              <>
                <>{props?.dropDownUpperUI}</>
                <div className=""  >
                  <div
                    className="d-flex align-items-center f-grey pointer p-3"
                    onClick={(e) => {
                      e.stopPropagation()
                      setTimeout(() => {
                        setfilteredMenuItems(true);
                        
                      }, 10);
                    }}
                    id="rename-div"
                  >
                    <img
                      alt=""
                      src="/images/attack-surface/hidden-icon.svg"
                      className="mr-2"
                    />
                    Customise view
                    <span className="mr-3"></span>
                    <img
                      alt=""
                      src="/images/attack-surface/chevron-right.svg"
                      className="ml-5"
                    />
                  </div>
                  {props.customBodyOnFirstPage ?     <div onClick={()=>{
                          const dragdropdown =
                          document.getElementById(`drag-drop-dropdown-${id}`);
                        dragdropdown.classList.toggle("d-none");
                  }}>{props?.customBodyOnFirstPage} </div>     :  <></>}
                </div>
              </>
            ) : (
              <>
                {/* Shown Items */}
                <div className="m-3"></div>
                <span className="list_item_header">Shown:</span>
                {
                  shownList?.length ===0 && <div className="p-2  w-100 f-grey">
                    {NoDataStateText}
                  </div>
                }
                <DragDropContext  onDragEnd={onDragEndShown}>
                  <Droppable
                    direction="vertical"
                    droppableId="droppableSection"
                  >
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          maxHeight: "300px",
                          overflowY: "auto",
                        }}
                        className="hide-scrollbar"
                        // style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {shownList &&
                          shownList
                            .filter((item) => item.Visibility === true)
                            .map((section, i) => (
                              <Draggable
                                key={section?.FieldKey}
                                draggableId={section?.FieldKey}
                                index={i}
                                 
                                
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="list_item d-flex justify-content-between  px-2 opacity-1-imp "
                                    key={i}
                                    index={i}
                                  >
                                    <>
                                      <div
                                        className="d-inline-flex w-75"
                                        onClick={() => {}}
                                        style={{
                                          alignItems: "center",
                                          paddingTop: "3px",
                                        }}
                                      >
                                        <img
                                          alt=""
                                          src="/images/actioncenter/contextmenu.svg"
                                          className="pr-2"
                                          style={{ cursor: "grab" }}
                                          onClick={(e) => {}}
                                        />
                                        {section?.FieldName}
                                      </div>
                                      <AttackSurfaceTooltip
                                        disabled={(section?.IsEditable===false && section?.IsEditable!==null)?  false  : true  }
                                        bottomLeft={i < 4 ? true : false}
                                        topLeft={i < 4 ? false : true}
                                        content={`Categories and subcategories cannot be hidden when they have a total greater than zero in the last 24 months`}
                                      >
                                        <div
                                         id="rename-div"
                                          style={{
                                            float: "right",
                                          }}
                                          className="position-relative sideBarDropdown complianceManagerDropdown actioncenterdropdown"
                                          onClick={(e) => {
                                            if (section?.IsEditable===false&& section?.IsEditable!==null) return;
                                            e.stopPropagation();
                                            e.preventDefault();
                                            let updated_shown = [...shownList];
                                            let removed_item = {
                                              ...updated_shown.splice(i, 1)[0],
                                            };
                                            removed_item.Visibility = false;
                                            setShownList(
                                              [...updated_shown].map(
                                                (item, index) => {
                                                  return {
                                                    ...item,
                                                    SortOrder: index + 1,
                                                  };
                                                }
                                              )
                                            );
                                            updatedHeaderList([
                                              ...updated_shown.map(
                                                (item, index) => {
                                                  return {
                                                    ...item,
                                                    SortOrder: index + 1,
                                                  };
                                                }
                                              ),
                                              ...hiddenList,
                                              { ...removed_item },
                                            ]);
                                            setHiddenList([
                                              ...hiddenList,
                                              { ...removed_item },
                                            ]);
                                          }}
                                        >
                                          <img
                                            id="rename-div"
                                            alt=""
                                            src="/images/attack-surface/shown-icon.svg"
                                            className="pr-2 float-right mt-2"
                                            style={{
                                              opacity:
                                              (section?.IsEditable===false && section?.IsEditable !==null) ? 0.3 : 1,
                                            }}
                                          />
                                        </div>
                                      </AttackSurfaceTooltip>
                                    </>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                {/* Hidden Items */}
                <div className="m-3"></div>
                <span className="list_item_header mt-3">Hidden:</span>
                {
                  hiddenList?.length ===0 && <div className="p-2  w-100 f-grey">
                    {NoDataStateText}
                  </div>
                }
                <DragDropContext onDragEnd={onDragEndHidden}>
                  <Droppable
                    direction="vertical"
                    droppableId="droppableSection"
                  >
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          maxHeight: "300px",
                          overflowY: "auto",
                        }}
                        className="hide-scrollbar"
                        // style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {hiddenList &&
                          hiddenList
                            .filter((item) => item.Visibility === false)
                            .map((section, i) => (
                              <Draggable
                                key={section?.FieldKey}
                                draggableId={section?.FieldKey}
                                index={i}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="list_item d-flex justify-content-between  px-2"
                                    key={i}
                                    index={i}
                                  >
                                    <>
                                      <div
                                        className="d-inline-flex w-75"
                                        onClick={() => {}}
                                        style={{
                                          alignItems: "center",
                                          paddingTop: "3px",
                                        }}
                                      >
                                        <img
                                          alt=""
                                          src="/images/actioncenter/contextmenu.svg"
                                          className="pr-2"
                                          style={{ cursor: "grab" }}
                                        />
                                        {section.FieldName}
                                      </div>

                                      <div
                                        style={{
                                          float: "right",
                                        }}
                                        className="position-relative sideBarDropdown complianceManagerDropdown actioncenterdropdown"
                                      >
                                        <img
                                          id="rename-div"
                                          alt=""
                                          src="/images/attack-surface/hidden-icon.svg"
                                          className="pr-2 float-right mt-2"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            let updated_hidden = [
                                              ...hiddenList,
                                            ];
                                            let removed_item = {
                                              ...updated_hidden.splice(i, 1)[0],
                                            };
                                            removed_item.Visibility = true;
                                            setHiddenList(updated_hidden);
                                            let updated_shown = [
                                              ...shownList,
                                              { ...removed_item },
                                            ];
                                            updatedHeaderList([
                                              ...updated_shown.map(
                                                (item, index) => {
                                                  return {
                                                    ...item,
                                                    SortOrder: index + 1,
                                                  };
                                                }
                                              ),
                                              ...updated_hidden,
                                            ]);
                                            setShownList(
                                              updated_shown.map(
                                                (item, index) => {
                                                  return {
                                                    ...item,
                                                    SortOrder: index + 1,
                                                  };
                                                }
                                              )
                                            );
                                          }}
                                        />
                                      </div>
                                    </>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </>
            )}
          </div>
        </Dropdown>
      </>
    </div>
  );
};

export default MSPCatSubcatSortFilters;

const _headersList = [
  {
    SortOrder: 1,
    FieldName: "Cyber Security",
    FieldKey: "asset_id",
    Visibility: true,
    Spend: 5,
  },
  {
    SortOrder: 2,
    FieldName: "Telecoms",
    FieldKey: "asset_name",
    Visibility: true,
    Spend: 0,
  },
  {
    SortOrder: 3,
    FieldName: "Cyber Score",
    FieldKey: "hostname",
    Visibility: true,
    Spend: 5,
  },
  {
    SortOrder: 4,
    FieldName: "Hardware",
    FieldKey: "manufacturer",
    Visibility: true,
    Spend: 0,
  },
  {
    SortOrder: 5,
    FieldName: "Software",
    FieldKey: "model",
    Visibility: true,
    Spend: 0,
  },
  {
    SortOrder: 6,
    FieldName: "Back up/Disaster",
    FieldKey: "serial",
    Visibility: false,
    Spend: 0,
  },
  {
    SortOrder: 7,
    FieldName: "MSTest Cat",
    FieldKey: "operating_system",
    Visibility: false,
    Spend: 0,
  },
  {
    SortOrder: 8,
    FieldName: "Goggle workspace",
    FieldKey: "operating_system_feature_build",
    Visibility: false,
    Spend: 0,
  },
  {
    SortOrder: 9,
    FieldName: "Microsoft 365",
    FieldKey: "ip_address",
    Visibility: true,
    Spend: 5,
  },
  {
    SortOrder: 10,
    FieldName: "Managing security",
    FieldKey: "mac_address",
    Visibility: true,
    Spend: 5,
  },
];
