import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useAuth } from "../context/Auth";
import "./drilldownModal.scss";
import { getAPIData } from "../../Utils/commonApi";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import Loader from "../../Components/Common/loader"; //Common/loader";
 

const TechnicalDrillDownCyberComplianceModal = ({
  show,
  hideModal,
  supplierId,
}) => {
  const { authTokens } = useAuth();
  const [modalData, setModalData] = useState({});
  const [loading, setLoading] = useState(false);
 
  function ToggleAccordion(e, work = true) {
    if (!work) return;
    var parent = e.target.closest(".point-row");
    var panel = parent.querySelector(".panel");
    if (panel?.style?.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }

  useEffect(() => {
    if (show) {
      setLoading(true);
      getModalData();
    }
  }, [show]);

  async function getModalData() {
    await getAPIData(`${330}`, authTokens, {
      optionEnum1: supplierId,
    })
      .then((response) => {
        setLoading(false);
        if (response?.mr?.Success) {
          setModalData(response?.data);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  useEffect(() => {
    if (show) {
      if (Object.keys(modalData)?.length > 0) {
        let expandend_rows = document.getElementsByClassName(
          "technicalCyberAutoExpand"
        );
        if (expandend_rows?.length > 0) {
          for (let index = 0; index < expandend_rows.length; index++) {
            expandend_rows[index].click();
          }
        }
      }
    }
  }, [modalData, show]);

  return (
    <Modal
      show={show}
      dialogClassName="right big-modal"
      className=" cybercomplinace-Modal-v2 drilldown-moda=l "
      aria-labelledby="big-modal"
      onHide={() => {
        hideModal();
      }}
      centered
      scrollable
    >
      <Modal.Header className=" d-flex align-items-center justify-content-between">
        <div className=""></div>

        <div className=" mb-0 f-500 f-14 " style={{ fontWeight: "600" }}>
          Technical Drilldown CyberCompliance
        </div>

        <img
          src="/images/supply-chain/small-cross-modal.svg"
          alt=""
          className="pointer"
          onClick={hideModal}
        />
      </Modal.Header>

      <Modal.Body className="p-1">
        {loading ? (
          <div className="d-flex align-items-center justify-content-center w-100 h-100">
            <Loader />
          </div>
        ) : (
          <>
            <div className=" header bmb-3">
              <div>CyberCompliance Component</div>
              <div className="d-flex justify-content-center align-items-center">
                Status
              </div>
              <div className="d-flex justify-content-center align-items-center">
                Impact
              </div>
              <div></div>
            </div>
            <div className="points-wrapper border-parent radius-8">
              {modalData?.sections?.map((compliance, index) => {
                
                return (
                  <div
                    className={`point-row border-item   ${
                      compliance?.IsExpanded ? "technicalCyberAutoExpand" : ""
                    }  `}
                    onClick={ToggleAccordion}
                    style={{
                      pointerEvents:`${compliance?.DrillDownEnable ?  "all" :"none"}`
                    }}
                  >
                    <div className="header pointer  bg-hover-dark-grey">
                      <div className="f-500 d-flex  align-items-center">
                        {compliance?.sectionText}
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <span
                          className="f-8 f-500 f-grey px-2  radius-2"
                          style={{
                            color: compliance?.complianceText?.backColor,
                            backgroundColor:
                              compliance?.complianceText?.foreColor,
                          }}
                        >
                          {compliance?.complianceText?.text}
                        </span>
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center "
                        style={{
                          color: compliance?.scoreRating?.ratingColor,
                        }}
                      >
                        {compliance?.scoreRating?.rating}
                      </div>
                      <div className=" d-flex justify-content-center align-items-center">
                        <img alt="" src="/images/chevron-down.svg" />
                      </div>
                    </div>
                    <div className="panel ">
                      <div className="p-2"></div>
                      <div className="panel-row my-1">
                        <div className="d-flex align-items-center">
                          {/* <img
                        alt=""
                        className="mx-2"
                        src="/images/supply-chain/yes-tic.svg"
                      /> */}
                        </div>
                        <div className="f-12 f-grey f-500">
                          Compliance Profile
                        </div>
                        <div className="f-12 f-grey f-500">Actual</div>
                        <div></div>
                      </div>
                      {compliance?.subSections.map((item, index) => {
                        return (
                          <div className="panel-row my-1">
                            <div className="d-flex align-items-center">
                              <img
                                alt=""
                                className="mx-2"
                                src={item?.complianceIconUrl}
                                width={14}
                                height={14}
                              />
                              {item?.subSectionText}
                              {item?.complianceText?.text && (
                                <span
                                  className="px-1 f-8 ml-2"
                                  style={{
                                    color: item?.complianceText?.foreColor,
                                    background: item?.complianceText?.backColor,
                                  }}
                                >
                                  {item?.complianceText?.text}{" "}
                                </span>
                              )}
                            </div>
                            <div>{item?.complianceProfileValue}</div>
                            <div
                              style={{
                                color: item?.actualValueForeColor,
                              }}
                            >
                              {item?.actualValue}
                            </div>
                            <div></div>
                          </div>
                        );
                      })}
                      <div className="p-2"></div>
                    </div>
                  </div>
                );
              })}
              <div className="border-item p-3 px-5 d-flex align-items-center justify-content-between">
                <div className="f-grey">Summary</div>
                <div
                  className=""
                  style={{
                    marginRight: "10rem",
                    color: `${modalData?.summaryScoreRating?.ratingColor}`,
                  }}
                >
                  {modalData?.overAllScoreRating}&nbsp;
                  {modalData?.summaryScoreRating?.rating}
                </div>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    
    </Modal>
  );
};

export default TechnicalDrillDownCyberComplianceModal;
