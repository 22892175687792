import React from "react";
import "./input.scss";

const InputField = (props) => {
  return (
    <div className={`custom_input-group ${props.readonly ? "read-only" : ""}`}>
      {props.label && <div className="input-label">{props.label}</div>}
      <input
        type={props?.type}
        value={props?.value}
        name={props?.name}
        className={`input-field form-control ${props.textcenter ? 'text-center' : ''}`}
        placeholder={props?.placeholder}
        disabled={props.readonly ? true : false}
        id={props?.id}
        onChange={props.onChange}
      />
      {props?.button && props.children}
    </div>
  );
};

export default InputField;
