import React from "react";
import { Button, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { TrimString } from "../../../Utils/StringTrimmer";
import AddEditNoteModal from "./AddEditNoteModal";
import { useState } from "react";
import { useAuth } from "../../context/Auth";
import { getAPIData } from "../../../Utils/commonApi";
import { useEffect } from "react";
import ContentLoader from "react-content-loader";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { DeleteModal } from "../../DeleteModal/DeleteModal";
import { viewOnlyAccess } from "../../App/App";

const SupplierProfilerNotes = ({ questionId, isTemplate }) => {
  const { authTokens } = useAuth();
  const [loading, setLoading] = useState(false);
  const [notesModalState, setNotesModalState] = useState({
    show: false,
    noteId: 0,
    modalState: "add",
    noteData: {},
  });
  const [notes, setNotes] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState({
    showModal: false,
    deleteSentQuestionnaire: () => {},
  });

  useEffect(() => {
    setLoading(true);
    getNotesList();
  }, []);

  async function getNotesList() {
    await getAPIData(`${759}`, authTokens, {
      optionEnum1: questionId,
    })
      .then((response) => {
        setLoading(false);
        setNotes(response ?? []);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function deleteNote(_deletId) {
    return new Promise((resolve, reject) => {
      getAPIData(`${756}`, authTokens, {
        optionEnum1: _deletId,
      })
        .then((response) => {
          if (response?.Success) {
            resolve(true);
            CallToast("Deleted Successfully", false, false, "v2style");
          } else {
            resolve(false);
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  return (
    <div className="w-100">
      {loading ? (
        <NotesLoader />
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-between">
            <div className="f-16 f-600">Notes</div>
            {!isTemplate && (
              <OverlayTrigger
                placement="left"
                trigger={
                  viewOnlyAccess("AccessState_SCM") ? ["hover", "focus"] : []
                }
                delay={{
                  show: 200,
                  hide: 250,
                }}
                overlay={
                  <Tooltip
                    id={`tooltip-1365`}
                    className="custom_tooltip_access"
                  >
                    This feature is managed by your service provider
                  </Tooltip>
                }
              >
                <div>
                  <Button
                    className={` ${
                      viewOnlyAccess("AccessState_SCM")
                        ? "btn-disable"
                        : "hg-blue-btn"
                    } `}
                    onClick={() => {
                      setNotesModalState({
                        show: true,
                        noteId: 0,
                        modalState: "add",
                        noteData: {},
                      });
                    }}
                  >
                    {viewOnlyAccess("AccessState_SCM") ? (
                      <img
                        alt=""
                        src="/images/attack-surface/small-lock.svg"
                        className="mr-2"
                      />
                    ) : (
                      <img
                        src="/images/white-plus-icon.svg"
                        alt="plus"
                        className="mr-2"
                      />
                    )}
                    Add note
                  </Button>
                </div>
              </OverlayTrigger>
            )}
          </div>
          {notes?.length === 0 ? (
            <div className="w-100 d-flex flex-column align-items-center justify-content-center">
              <img
                src="/images/msp/no-notes-icon.svg"
                alt="notes"
                className="mt-5"
              />
              <div className="f-600 f-grey mt-4 w-fit-content">
                No notes yet
              </div>
              <div className=" f-darkgrey mt-2 w-fit-content">
                You don’t have notes yet. Add your first note
              </div>
            </div>
          ) : (
            <div className="d-flex flex-wrap pt-3">
              {notes?.map((note, index) => {
                return (
                  <div
                    className="radius-8 d-flex flex-column justify-content-between p-3 mr-2 mb-2 bg-hover-grey pointer"
                    style={{
                      border: "1px solid #D4D6DD",
                      width: "32%",
                      height: "250px",
                    }}
                    onClick={() => {
                      setNotesModalState({
                        show: true,
                        noteId: index,
                        modalState: "view",
                        noteData: note,
                      });
                    }}
                  >
                    <div className="d-flex justify-content-between w-100">
                      <div
                        className="mr-3 w-100"
                        style={{
                          // overflow: "hidden",
                          // textOverflow: "ellipsis",
                          // whiteSpace: "nowrap",
                        }}
                      >
                        {TrimString(note?.Notes, 290)}{" "}
                      </div>
                      <div>
                        <Dropdown
                          className="transparennt-wrapper-dropdown"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Dropdown.Toggle className="w-100 d-flex align-items-center justify-content-between">
                            <div className="pb-2">
                              <img
                                src="/images/settings-v2/horizontal-dots.svg"
                                alt="3-dots"
                                className=" "
                              />
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className="p-2 pointer left-dropdown-dir"
                            onClick={(e) => {
                              e.target
                                .closest(".transparennt-wrapper-dropdown")
                                .click();
                            }}
                          >
                            <div
                              className="p-2 f-grey"
                              onClick={() => {
                                setNotesModalState({
                                  show: true,
                                  noteId: index,
                                  modalState: "edit",
                                  noteData: note,
                                });
                              }}
                            >
                              Edit{" "}
                            </div>
                            <div
                              className="redText p-2"
                              onClick={() => {
                                setShowDeleteModal({
                                  showModal: true,
                                  deleteSentQuestionnaire: () =>
                                    deleteNote(note?.ObjectId).then(
                                      (response) => {
                                        if (response) {
                                          getNotesList();
                                        }
                                      }
                                    ),
                                });
                              }}
                            >
                              Delete
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between">
                      <div className="f-12 f-500 f-grey">
                        <img
                          src={note?.AddedByLogo ?? ""}
                          alt="clock"
                          className="mr-2 rounded-full"
                          width={20}
                          height={20}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/images/user-logo.svg";
                          }}
                        />
                        {note?.AddedBy_Text}
                      </div>
                      <div className="f-12 f-darkgrey">{note?.AddedDate}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
      <AddEditNoteModal
        show={notesModalState.show}
        hideModal={() => {
          setNotesModalState({
            show: false,
          });
        }}
        modalState={notesModalState.modalState}
        noteId={notesModalState.noteId}
        refreshData={getNotesList}
        deleteNote={deleteNote}
        noteData={notesModalState.noteData}
        questionId={questionId}
      />
      <DeleteModal
        show={showDeleteModal.showModal}
        hideModal={() =>
          setShowDeleteModal({
            showModal: false,
          })
        }
        deleteMessage="You are about to delete a note."
        deleteType={"deleteSentQuestionnaire"}
        deleteSentQuestionnaire={() => {
          showDeleteModal.deleteSentQuestionnaire();
        }}
      />
    </div>
  );
};

export default SupplierProfilerNotes;

const Notes = [
  {
    note: "Platea mi sit fusce semper mauris. Iaculis ac duis risus ut id. Eu in vitae in neque. Sed egestas hac mus nibh rutrum. Pretium neque hac dolor diam mi nulla. Neque faucibus ultrices cursus nullam sed. Quam lorem cras id nulla. Lorem imperdiet id in vulputate porta enim. Platea mi sit fusce semper mauris. Iaculis ac duis risus ut id. Eu in vitae in neque. Sed egestas hac mus nibh rutrum. Pretium neque hac dolor diam mi nulla. Neque faucibus ultrices cursus nullam sed. Quam lorem cras id nulla. Lorem imperdiet id in vulputate porta enim.",
    date: "1 Jan 2024 12:30",
    user: "Janne Cooper",
  },
  {
    note: "Platea mi sit fusce semper mauris. Iaculis ac duis risus ut id. Eu in vitae in neque. Sed egestas hac mus nibh rutrum. Pretium neque hac dolor diam mi nulla. Neque faucibus ultrices cursus nullam sed. Quam lorem cras id nulla. Lorem imperdiet id in vulputate porta enim. Platea mi sit fusce semper mauris. Iaculis ac duis risus ut id. Eu in vitae in neque. Sed egestas hac mus nibh rutrum. Pretium neque hac dolor diam mi nulla. Neque faucibus ultrices cursus nullam sed. Quam lorem cras id nulla. Lorem imperdiet id in vulputate porta enim.",
    date: "1 Jan 2024 12:30",
    user: "Janne Cooper",
  },
  {
    note: "Platea mi sit fusce semper mauris. Iaculis ac duis risus ut id. Eu in vitae in neque. Sed egestas hac mus nibh rutrum. Pretium neque hac dolor diam mi nulla. Neque faucibus ultrices cursus nullam sed. Quam lorem cras id nulla. Lorem imperdiet id in vulputate porta enim. Platea mi sit fusce semper mauris. Iaculis ac duis risus ut id. Eu in vitae in neque. Sed egestas hac mus nibh rutrum. Pretium neque hac dolor diam mi nulla. Neque faucibus ultrices cursus nullam sed. Quam lorem cras id nulla. Lorem imperdiet id in vulputate porta enim.",
    date: "1 Jan 2024 12:30",
    user: "Janne Cooper",
  },
  {
    note: "Platea mi sit fusce semper mauris. Iaculis ac duis risus ut id. Eu in vitae in neque. Sed egestas hac mus nibh rutrum. Pretium neque hac dolor diam mi nulla. Neque faucibus ultrices cursus nullam sed. Quam lorem cras id nulla. Lorem imperdiet id in vulputate porta enim. Platea mi sit fusce semper mauris. Iaculis ac duis risus ut id. Eu in vitae in neque. Sed egestas hac mus nibh rutrum. Pretium neque hac dolor diam mi nulla. Neque faucibus ultrices cursus nullam sed. Quam lorem cras id nulla. Lorem imperdiet id in vulputate porta enim.",
    date: "1 Jan 2024 12:30",
    user: "Janne Cooper",
  },
  {
    note: "Platea mi sit fusce semper mauris. Iaculis ac duis risus ut id. Eu in vitae in neque. Sed egestas hac mus nibh rutrum. Pretium neque hac dolor diam mi nulla. Neque faucibus ultrices cursus nullam sed. Quam lorem cras id nulla. Lorem imperdiet id in vulputate porta enim. Platea mi sit fusce semper mauris. Iaculis ac duis risus ut id. Eu in vitae in neque. Sed egestas hac mus nibh rutrum. Pretium neque hac dolor diam mi nulla. Neque faucibus ultrices cursus nullam sed. Quam lorem cras id nulla. Lorem imperdiet id in vulputate porta enim.",
    date: "1 Jan 2024 12:30",
    user: "Janne Cooper",
  },
  {
    note: "Platea mi sit fusce semper mauris. Iaculis ac duis risus ut id. Eu in vitae in neque. Sed egestas hac mus nibh rutrum. Pretium neque hac dolor diam mi nulla. Neque faucibus ultrices cursus nullam sed. Quam lorem cras id nulla. Lorem imperdiet id in vulputate porta enim. Platea mi sit fusce semper mauris. Iaculis ac duis risus ut id. Eu in vitae in neque. Sed egestas hac mus nibh rutrum. Pretium neque hac dolor diam mi nulla. Neque faucibus ultrices cursus nullam sed. Quam lorem cras id nulla. Lorem imperdiet id in vulputate porta enim.",
    date: "1 Jan 2024 12:30",
    user: "Janne Cooper",
  },
  {
    note: "Platea mi sit fusce semper mauris. Iaculis ac duis risus ut id. Eu in vitae in neque. Sed egestas hac mus nibh rutrum. Pretium neque hac dolor diam mi nulla. Neque faucibus ultrices cursus nullam sed. Quam lorem cras id nulla. Lorem imperdiet id in vulputate porta enim. Platea mi sit fusce semper mauris. Iaculis ac duis risus ut id. Eu in vitae in neque. Sed egestas hac mus nibh rutrum. Pretium neque hac dolor diam mi nulla. Neque faucibus ultrices cursus nullam sed. Quam lorem cras id nulla. Lorem imperdiet id in vulputate porta enim.",
    date: "1 Jan 2024 12:30",
    user: "Janne Cooper",
  },
  {
    note: "Platea mi sit fusce semper mauris. Iaculis ac duis risus ut id. Eu in vitae in neque. Sed egestas hac mus nibh rutrum. Pretium neque hac dolor diam mi nulla. Neque faucibus ultrices cursus nullam sed. Quam lorem cras id nulla. Lorem imperdiet id in vulputate porta enim. Platea mi sit fusce semper mauris. Iaculis ac duis risus ut id. Eu in vitae in neque. Sed egestas hac mus nibh rutrum. Pretium neque hac dolor diam mi nulla. Neque faucibus ultrices cursus nullam sed. Quam lorem cras id nulla. Lorem imperdiet id in vulputate porta enim.",
    date: "1 Jan 2024 12:30",
    user: "Janne Cooper",
  },
  {
    note: "Platea mi sit fusce semper mauris. Iaculis ac duis risus ut id. Eu in vitae in neque. Sed egestas hac mus nibh rutrum. Pretium neque hac dolor diam mi nulla. Neque faucibus ultrices cursus nullam sed. Quam lorem cras id nulla. Lorem imperdiet id in vulputate porta enim. Platea mi sit fusce semper mauris. Iaculis ac duis risus ut id. Eu in vitae in neque. Sed egestas hac mus nibh rutrum. Pretium neque hac dolor diam mi nulla. Neque faucibus ultrices cursus nullam sed. Quam lorem cras id nulla. Lorem imperdiet id in vulputate porta enim.",
    date: "1 Jan 2024 12:30",
    user: "Janne Cooper",
  },
  {
    note: "Platea mi sit fusce semper mauris. Iaculis ac duis risus ut id. Eu in vitae in neque. Sed egestas hac mus nibh rutrum. Pretium neque hac dolor diam mi nulla. Neque faucibus ultrices cursus nullam sed. Quam lorem cras id nulla. Lorem imperdiet id in vulputate porta enim. Platea mi sit fusce semper mauris. Iaculis ac duis risus ut id. Eu in vitae in neque. Sed egestas hac mus nibh rutrum. Pretium neque hac dolor diam mi nulla. Neque faucibus ultrices cursus nullam sed. Quam lorem cras id nulla. Lorem imperdiet id in vulputate porta enim.",
    date: "1 Jan 2024 12:30",
    user: "Janne Cooper",
  },
  {
    note: "Platea mi sit fusce semper mauris. Iaculis ac duis risus ut id. Eu in vitae in neque. Sed egestas hac mus nibh rutrum. Pretium neque hac dolor diam mi nulla. Neque faucibus ultrices cursus nullam sed. Quam lorem cras id nulla. Lorem imperdiet id in vulputate porta enim. Platea mi sit fusce semper mauris. Iaculis ac duis risus ut id. Eu in vitae in neque. Sed egestas hac mus nibh rutrum. Pretium neque hac dolor diam mi nulla. Neque faucibus ultrices cursus nullam sed. Quam lorem cras id nulla. Lorem imperdiet id in vulputate porta enim.",
    date: "1 Jan 2024 12:30",
    user: "Janne Cooper",
  },
  {
    note: "Platea mi sit fusce semper mauris. Iaculis ac duis risus ut id. Eu in vitae in neque. Sed egestas hac mus nibh rutrum. Pretium neque hac dolor diam mi nulla. Neque faucibus ultrices cursus nullam sed. Quam lorem cras id nulla. Lorem imperdiet id in vulputate porta enim. Platea mi sit fusce semper mauris. Iaculis ac duis risus ut id. Eu in vitae in neque. Sed egestas hac mus nibh rutrum. Pretium neque hac dolor diam mi nulla. Neque faucibus ultrices cursus nullam sed. Quam lorem cras id nulla. Lorem imperdiet id in vulputate porta enim.",
    date: "1 Jan 2024 12:30",
    user: "Janne Cooper",
  },
];

const NotesLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="8" ry="8" width="30%" height="220" />
      <rect x="31%" y="0" rx="8" ry="8" width="30%" height="220" />
      <rect x="62%" y="0" rx="8" ry="8" width="30%" height="220" />

      <rect x="0" y="240" rx="8" ry="8" width="30%" height="220" />
      <rect x="31%" y="240" rx="8" ry="8" width="30%" height="220" />
      <rect x="62%" y="240" rx="8" ry="8" width="30%" height="220" />

      <rect x="0" y="480" rx="8" ry="8" width="30%" height="220" />
      <rect x="31%" y="480" rx="8" ry="8" width="30%" height="220" />
      <rect x="62%" y="480" rx="8" ry="8" width="30%" height="220" />
    </ContentLoader>
  );
};
