import React, { useState, useRef, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./support-ticket-v2.scss";
import { TrackUser } from "../../../Utils/SegmentFunctions";

import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { useSelector } from "react-redux";

const SupplySmallChatModal = ({ show, hideModal }) => {
  const fileUpload = useRef();

  const [message, setMessage] = useState("");
  const [data, setData] = useState(null);

  const userDetails = useSelector((state) => state.user?.userDetail);
  const [messageImage, setMessageImage] = useState("");

  const [messageData, setMessageData] = useState([
    {
      name: "Test 1",
      profileImage:
        "https://image.shutterstock.com/image-photo/pensive-bearded-man-sitting-table-600w-1463229359.jpg",
      post: "Assistant",
      time: "4:50 PM",
      image: "/images/settings-v2/chat-image.svg",
      message:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    },
    {
      name: "Test 2",
      profileImage:
        "https://image.shutterstock.com/image-photo/pensive-bearded-man-sitting-table-600w-1463229359.jpg",
      post: "CEO",
      time: "2:50 PM",
      image: "/images/settings-v2/chat-image.svg",
      message:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    },
  ]);

  //   page types=>
  // chatlist
  // chat
  const [page, setPage] = useState("chatlist");
  //   chat type=>
  //  chats &&& contacts
  const [chatType, setChatType] = useState("chats");

  // useEffect(() => {
  //   if (show) {
  //     let modal_backdrop = document.querySelector(".modal-backdrop");
  //     modal_backdrop.classList.remove("modal-backdrop");
  //     // modal_backdrop.classList.add("transparent-backdrop");
  //     if (modal_backdrop) {
  //       setTimeout(() => {
  //         modal_backdrop.classList.add("transparent-backdrop");
  //       });
  //     }
  //   }
  // }, [show]);

  const chatList =[
    {
      name:"GoPro",
      time:"10:33 am",
      message:"Lorem ipsum dolor sit amet, fringilla.",
      unreadNo:0,
      image:"/images/attack-surface/goPro.svg"

    },
    {
      name:"Microsoft Inc.",
      time:"10:33 am",
      message:"Lorem ipsum dolor sit amet, fringilla consectetur fringilla adipiscing elit. Fringilla.",
      unreadNo:5,
      image:"/images/attack-surface/microsoft.svg"

    },
    {
      name:"M3 Networks ltd.",
      time:"10:33 am",
      message:"Lorem ipsum dolor sit amet, fringilla.",
      unreadNo:0,
      image:"/images/supply-chain/m3-network-logo.svg"

    },
    {
      name:"Starbucks",
      time:"May 20, 2022",
      message:"Lorem ipsum dolor sit amet, fringilla consectetur fringilla adipiscing elit elit. Fringilla.",
      unreadNo:0,
      image:"/images/attack-surface/starBucks.svg"

    },
    {
      name:"Tesla",
      time:"May 20, 2022",
      message:"Lorem ipsum dolor sit amet, fringilla.",
      unreadNo:0,
      image:"https://upload.wikimedia.org/wikipedia/commons/thumb/b/bb/Tesla_T_symbol.svg/482px-Tesla_T_symbol.svg.png"

    },
    {
      name:"BMW",
      time:" ",
      message:"No messages yet",
      unreadNo:0,
      image:"/images/attack-surface/bmw.svg"

    },













  ]
  return (
    <Modal
      show={show}
      dialogClassName={`support-ticket-v2-modal ${
        show ? "right-side-modal" : "rightBackModal"
      }`}
      className={``}
      // backdrop={false}
      onHide={() => {
        setPage("chatlist");
        hideModal();
      }}
      aria-labelledby="support-ticket-v2-modal"
    >
      <Modal.Header>
        {page == "chatlist" && (
          <div
            className="d-flex align-items-center pointer"
            style={{ width: "30%", pointerEvents: "none" }}
          >
            <div
              className={`${
                chatType == "chats" ? "active-chat-tab" : "normal-tab"
              } mr-3`}
              onClick={() => {
                setChatType("chats");
              }}
            >
              Chats
            </div>
            <div
              className={`${
                chatType == "contacts" ? "active-chat-tab" : "normal-tab"
              }`}
              onClick={() => {
                setChatType("contacts");
              }}
            >
              Contacts
            </div>
          </div>
        )}
        {page == "chat" && (
          <div className="d-flex align-items-center ">
            <img
              alt=""
              src="/images/settings-v2/chevron-left.svg"
              className="pointer my-1 "
              onClick={() => setPage("chatlist")}
            />
            <div
              className="d-flex align-items-center position-absolute"
              style={{ left: "36px" }}
            >
              <img
                alt=""
                className=" mr-2"
                src="/images/supply-chain/m3-network-logo.svg"
              />
              <div className="d-flex flex-column">
                <p className="m-0 f-12 f-500 f-black">Microsoft Inc.</p>
                <p className="m-0 f-12 f-blue">Online</p>
              </div>
            </div>
          </div>
        )}

        <img
          alt=""
          src="/images/icons/close.svg"
          className="pointer"
          style={{ width: "11px" }}
          onClick={() => {
            setPage("chatlist");
            hideModal();
          }}
        />
      </Modal.Header>
      <Modal.Body>
        {/* First page- chats or contacts */}
        {page === "chatlist" && false ? (<>
        
        
          
        <img alt="" className="position-absolute" src="/images/attack-surface/chatUI.svg"/>

       
        
        </>
         
        ) : (
          <>
            {chatType == "chats" && (
              <>
                {/* search box */}
                <div className="d-flex align-items-center"  
                style={{pointerEvents:"none"}}
                
                >
                  <img alt="" src="/images/attack-surface/search-icon.svg" />
                  <input
                    type="text"
                    className="chat-search pl-2"
                    placeholder="Search"
                  />
                </div>
                {/* Chats List */}
                <div className="chats-wrapper mt-3"
                
                style={{pointerEvents:"none"}}>
                  {chatList.map((chat, index) => {
                    return (
                      <div
                        className="d-flex pointer chat-list-item radius-4 p-3"
                        onClick={() => {
                          setPage("chat");
                        }}
                        style={{background:`${index===1 ?"#ebecf180":""  }`}}
                      >
                        <img
                          alt=""
                          src={chat?.image}
                          className="mr-2 rounded-full"
                          width={40}
                          height={40}
                        />
                        <div className="w-100">
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <div className="f-500 f-black">
                              {chat?.name}
                            </div>
                            <div className="f-12 f-grey">{chat?.time}</div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div className="f-darkgrey">
                             {chat?.message}
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                              <span
                                className={` ${chat?.unreadNo >0 ? "d-inline-flex"  :"visibility-hidden"}    f-white`}
                                style={{
                                  background: "#008FD4",
                                  padding: "2px 10px",
                                  borderRadius: "9999px",
                                }}
                              >
                                {chat?.unreadNo}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}

            {/* Closed tikets */}

            {chatType == "contacts" && (
              <>
                {/* search box */}
                <div className="d-flex align-items-center">
                  <img alt="" src="/images/attack-surface/search-icon.svg" />
                  <input
                    type="text"
                    className="chat-search pl-2"
                    placeholder="Search"
                  />
                </div>
                {/* Chats List */}
                <div className="chats-wrapper mt-3">
                  {[1, 2, 3, 4, 5].map((chat, index) => {
                    return (
                      <div
                        className="d-flex pointer chat-list-item radius-4 p-3"
                        onClick={() => {
                          setPage("chat");
                        }}
                      >
                        <img
                          alt=""
                          src="/images/supply-chain/m3-network-logo.svg"
                          className="mr-2"
                        />
                        <div className="w-100">
                          <div className="w-100 d-flex  align-items-center">
                            <div className="f-500 f-black">
                              M3 Networks ltd.
                            </div>
                          </div>
                          <div className="d-flex justify-content-between f-darkgrey">
                            Away
                            {/* <div className="f-darkgrey">
                              Lorem ipsum dolor sit amet, fringilla consectetur
                              fringilla adipiscing elit elit. Fringilla.
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                              <span
                                className="d-inline-flex f-white"
                                style={{
                                  background: "#008FD4",
                                  padding: "2px 10px",
                                  borderRadius: "9999px",
                                }}
                              >
                                3
                              </span>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </>
        )}

        {page == "chat" && (
          <>
            {data == null && (
              <div
                className="position-relative"
                style={{
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <div className="d-flex my-2 align-items-center justify-content-center ">
                  <div className="">
                    <img
                      alt=""
                      src="/images/supply-chain/no-message-plane.svg"
                      //   style={{ width: "32px", height: "32px" }}
                    />
                  </div>
                </div>
                <div className=" d-flex justify-content-center w-50 mx-auto text-center mt-2 f-500 f-grey">
                  No messages here yet
                </div>
                <p className=" d-flex justify-content-center w-50 mx-auto text-center mt-1 f-darkgrey">
                  Send first message to start conversation
                </p>
              </div>
            )}

            {data !== null &&
              messageData.map((data) => {
                return <Message data={data} />;
              })}

            {/* message typer */}
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="d-none">
        {page == "openClose" && chatType == "opened" && (
          <div className="start-conversation-wrapper">
            <div className="title">Start another conversation</div>

            <div className="d-flex my-2 " style={{ marginLeft: "12px" }}>
              <div className="mr-2">
                <img
                  alt=""
                  src="https://www.etvbharat.com/_next/image?url=https%3A%2F%2Fetvbharatimages.akamaized.net%2Fetvbharat%2Fprod-images%2F768-512-15585887-thumbnail-3x2-lap.jpg&w=1920&q=75"
                  className="avatar"
                />
                <img
                  alt=""
                  src="https://www.etvbharat.com/_next/image?url=https%3A%2F%2Fetvbharatimages.akamaized.net%2Fetvbharat%2Fprod-images%2F768-512-15585887-thumbnail-3x2-lap.jpg&w=1920&q=75"
                  className="avatar"
                />
                <img
                  alt=""
                  src="https://www.etvbharat.com/_next/image?url=https%3A%2F%2Fetvbharatimages.akamaized.net%2Fetvbharat%2Fprod-images%2F768-512-15585887-thumbnail-3x2-lap.jpg&w=1920&q=75"
                  className="avatar"
                />
              </div>
              <div className="small-subtitle">
                We are here to help you on your journey
              </div>
            </div>

            <Button
              className="w-100 create-ticket-btn"
              onClick={() => setPage("createTicket")}
            >
              Create Ticket
              <img
                alt=""
                src="/images/settings-v2/white-plane.svg"
                className="ml-2"
              />
            </Button>
          </div>
        )}

        {page == "chat" && (
          <div className="message-typer d-flex align-items-center">
            <input
              type="file"
              className="d-none"
              ref={fileUpload}
              onChange={(e) => {
                if (e.target.files[0]) {
                  const reader = new FileReader();
                  reader.readAsDataURL(e.target.files[0]);

                  reader.onload = function () {
                    setMessageImage(reader.result);
                  };
                }
              }}
            />
            <img
              alt=""
              src="/images/settings-v2/attach-file.svg"
              className="mx-2 pointer"
              onClick={() => {
                fileUpload.current.click();
              }}
            />
            <input
              type="text"
              placeholder="Write a message..."
              className="pl-2"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <Button
              onClick={() => {
                if (message == "" && messageImage == "") return false;

                let temp_data = [...messageData];
                temp_data.push({
                  name: userDetails?.FullName,
                  profileImage: userDetails?.Avatar,
                  post: "(You)",
                  message: message,
                  time: new Date().toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  }),
                  image: messageImage,
                });
                setMessageData(temp_data);
                setMessageImage("");
                setMessage("");
                setTimeout(() => {
                  const chatbox = document.querySelector(".modal-body");
                  chatbox.scrollTo({
                    top: chatbox?.scrollHeight,
                    behavior: "smooth",
                  });
                }, 100);
              }}
            >
              <img
                alt=""
                src="/images/settings-v2/white-plane.svg"
                className=""
                style={{ width: "20px" }}
              />
            </Button>
          </div>
        )}

        {page == "ticketData" && chatType == "closed" && (
          <Button disabled={true} className="ticket-closed-btn">
            Ticket Closed!
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default SupplySmallChatModal;

const Message = ({ data }) => {
  return (
    <div className="ticket-message">
      {/* User details */}
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center ">
          <img alt="" src={data.profileImage} className="message-avatar mr-2" />
          <div className="title mr-1">{data?.name}</div>
          <div className="small-subtitle">{data?.post} </div>
        </div>
        <div className="small-subtitle">{data?.time} </div>
      </div>
      {/* User text */}
      <div className="message-text">{data?.message}</div>
      {/* Image wrapper */}
      <div className="message-image-wrapper">
        <div
          className={`message-image-container   ${
            data?.image ? "" : "d-none"
          }     `}
          style={{ width: "fit-content", position: "relative" }}
        >
          <img alt="" src={data?.image} className="message-image" />
          <img
            alt=""
            src="/images/settings-v2/download-cloud.svg"
            className="overlay"
          />
        </div>
      </div>
      {/* Video wrapper */}
      {/* <div className="message-image-wrapper">
        <div
          className="message-image-container"
          style={{ width: "fit-content", position: "relative" }}
        >
          <img alt=""
            src={"/images/settings-v2/chat-video.svg"}
            className="message-image"
          />
          <img alt=""
            src="/images/settings-v2/download-cloud.svg"
            className="overlay"
          />
        </div>
      </div> */}
      {/* File wrapper */}
      {/* <div className="d-flex align-items-center justify-content-start message-file-wrapper">
        <img alt="" src="/images/settings-v2/file-icon.svg" className="mr-3" />
        <div>
          <p className="title m-0">File name.pdf</p>
          <p className="small-subtitle"> 200kb</p>
        </div>
        <img alt="" src="/images/settings-v2/download-cloud.svg" className="overlay" />
      </div> */}
    </div>
  );
};
