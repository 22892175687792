import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  InputGroup,
  Form,
  OverlayTrigger,
  FormControl,
  Tooltip,
} from "react-bootstrap";
import "./index.scss";

const PaymentResponse = (props) => {
  return (
    <div className="w-100 mt-5">
      <div className="d24" />
      <div className="d24" />
      <div className="d24" />

      {/* Error Message */}
      {/* <div className="text-center">
        <img src="/images/rfail.svg" alt="" />
        <p className="txtfail mt-4">
          Oops! Something went <br /> wrong
        </p>
      </div>
      <div className="responseList mt-4">
        <p>
          Please check you have entered the correct information in all fields
          and try again. If you have already checked these and confirmed they
          are correct, please open a support ticket.
        </p>
        <Button className="w-100 blubtn mt-4">Try Again</Button>
        <div className="d24" />
        <div className="d24" />
        <div className="d24" />
        <div className="d24" />
      </div> */}

      {/* Success Message */}

      <div className="text-center">
        <img src="/images/rsuccess.svg" alt="" />
        <p className="txtsucess mt-4">Payment successful</p>
      </div>

      <div className="responseList mt-4">
        <p>
          Thank you for upgrading to a Premium Subscription. You can now enjoy
          access to:
        </p>
        <div className="mt-4">
          <p>
            {" "}
            <img src="/images/rsuccess.svg" alt="" />
            Unlimited Integrations
          </p>
          <p>
            {" "}
            <img src="/images/rsuccess.svg" alt="" />
            Faster Sync
          </p>
          <p>
            {" "}
            <img src="/images/rsuccess.svg" alt="" />
            Up to 3 users
          </p>
          <p>
            {" "}
            <img src="/images/rsuccess.svg" alt="" />
            Scheduled Reports
          </p>
        </div>
        <Button onClick={ () =>  props.setData((prevState) => ({ ...prevState, show: false , isResponse: false })) } className="w-100 blubtn mt-4">Close</Button>
        <div className="d24" />
        <div className="d24" />
        <div className="d24" />
        <div className="d24" />
      </div>

       {/* Success Message */}
    </div>
  );
};

export default PaymentResponse;
