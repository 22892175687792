import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import {
  AddRemoveSecurityPack as connectToolApi,
  getAPIData as getToolsList,
} from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import Loader from "../../../Common/loader";

import "../actionCentreModals.scss";
import { GreyLoader } from "../../../Theme/APILoaders";

const ConnectTool = ({ setModalTab, UpdateTooldata, goToIntegrationPage }) => {
  const { authTokens } = useAuth();
  const [integrationList, setIntegrationList] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [connecting, setConnecting] = useState(false);
  useEffect(() => {
    setLoading(true);
    getToolsList(256, authTokens)
      .then((response) => {
        setLoading(false);
        if (response?.mr?.Success) {
          let updated_data = [...response?.TaskManagerIntergration].map(
            (item) => {
              return {
                ...item,
                loading: false,
              };
            }
          );

          setIntegrationList(updated_data);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }, [authTokens]);

  return (
    <Row className="m-0">
      {loading && (
        <Col md={12} className="loader-custom">
          <Loader />
        </Col>
      )}

      <Col md={12} className={`p-0 mt-1   ${loading ? "d-none" : ""}  `}>
        <div className="mt-3">
          <span className="titletext">Connect Tool</span>
          <p className="titletextgrey mb-4">
            Connect on your task management tool below and follow our
            integration instructions
          </p>

          {integrationList.map((integration, integrationIndex) => {
            return (
              <div
                key={integrationIndex}
                className="d-flex align-items-center justify-content-between mb-3"
              >
                <div className="">
                  <img
                    src={`/images/actioncenter/${integration?.TMIntegartionLogo}`}
                    alt=""
                    width={44}
                  />
                  <span className="titletext ml-2">
                    {integration?.TMIntegartionName}
                  </span>
                </div>

                <div
                  className="d-flex align-items-center crsrpoint"
                  onClick={() => {
                    if (integration?.TMIntegration_Connected) {
                      UpdateTooldata({
                        toolName: integration?.TMIntegartionName,
                        toolId: integration?.TMIntegartion_ObjId,
                        ConnectionStrings: [],
                      });

                      goToIntegrationPage();
                    } else {
                      let updated_data = [...integrationList];
                      updated_data[integrationIndex].loading = true;
                      setIntegrationList(updated_data);

                      connectToolApi(
                        authTokens,
                        65,
                        integration?.TMIntegartion_ObjId,
                        1
                      ).then((response) => {
                        let updated_data = [...integrationList];
                        updated_data[integrationIndex].loading = false;
                        setIntegrationList(updated_data);
                        if (response?.mr?.Success) {
                          UpdateTooldata({
                            toolName: integration?.TMIntegartionName,
                            toolId: integration?.TMIntegartion_ObjId,
                            ConnectionStrings: response?.ConnectionStrings,
                          });
                          setModalTab();
                        } else {
                          CallToast(
                            "Something went wrong",
                            false,
                            false,
                            "v2style"
                          );
                        }
                      });
                    }
                  }}
                >
                  {integration.loading ? (
                    <>
                      <GreyLoader />
                      <span className="text3">Connecting...</span>
                    </>
                  ) : (
                    // Show edit cutton if the tool is already connected
                    <>
                      {integration?.TMIntegration_Connected ? (
                        <Button className="hg-cancel-btn">Edit</Button>
                      ) : (
                        <>
                          <span className="text3">Connect</span>
                          <img
                            src="/images/actioncenter/plusgrey.svg"
                            height="11"
                            className="ml-2"
                            alt=""
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </Col>
    </Row>
  );
};

export default ConnectTool;
