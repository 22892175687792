import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./labelledInputpassword.scss";
function LabelledInputPassword({
  placeholder,
  PrefixComponent,
  SuffixComponent,
  onChange,
  value,
  error,
  disabled,
  onSubmit,
}) {
  const [type, setType] = useState("password");

  const changeType = () => {
    if (disabled) return false;
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  return (
    <div
      className={`labelled-input-password ${
        error ? "error-labelled-input" : ""
      } ${disabled ? "disabled-labelled-input" : ""} `}
    >
      <div className="prefix-component px-1">{PrefixComponent}</div>

      <input
        className="input-field"
        onChange={onChange}
        type={type}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete="new-password"
      />
      <div className="suffix-component">
        {type === "password" && (
          <img
            src="/images/openEye.svg"
            className="mr-1"
            onClick={changeType}
          />
        )}
        {type === "text" && (
          <img
            src="/images/closeEye.svg"
            className="mr-1"
            onClick={changeType}
          />
        )}
      </div>
    </div>
  );
}
export default LabelledInputPassword;

export const PrefixPasswordInput = () => {
  return <img className="pr-2 pl-2" src="/images/lock.svg" />;
  //   return <div>Label:</div>;
};

export const SuffixComponent = () => {
  return <Button>Label</Button>;
};
