import React, { useEffect, useState } from "react";
import AnonymousLogin from "../../../Components/Loginv2/AnonymousLogin";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";

const AnonymousContributorsView = () => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  // const [accessToken, setaccessToken] = useState('');
  const [pageState, setPageState] = useState(0);
  const [objId, setObjId] = useState(0);
  const [message, setMessage] = useState("");
  const [userDetail, setUserDetail] = useState({});
  const [showConfirmationScreen, setShowConfirmationScreen] = useState(false);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const key = urlParams.get("key");
  const showConfirm = urlParams.get("confirm");

  useEffect(() => {
    InformationRequest();
  }, []);

  async function InformationRequest(accessToken) {
    var myHeaders = new Headers();
    if (accessToken) {
      myHeaders.append("Authorization", `Bearer ${accessToken}`);
    }

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    setLoading(true);
    if (showConfirm) {
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/HGIR/InformationRequest?key=${key}&showConfirm=${true}`,
        // `https://hg-dev-ui-ac-api.azurewebsites.net/HGIR/InformationRequest?key=${key}&showConfirm=${true}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.mr?.Success) {
            setObjId(data?.mr.EntityId);
            if (data.isInvalidUrl) {
              setRefresh(false);
              CallToast("Url is invalid", false, false, "v2style");
              return;
            } else if (data.isTokenExpired) {
              RefreshAccessToken();
              return;
            } else {
              setRefresh(false);
              setPageState(
                data.isLinkExpired ? 2 : data.showConfirmationScreen ? 1 : 0
              );
              setUserDetail(data.userDetail);
              setShowConfirmationScreen(data.showConfirmationScreen);
              setMessage(data.message);
            }

            setLoading(false);
          }
        })
        .catch((ex) => {});
    } else {
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/HGIR/InformationRequest?key=${key}`,
        // `https://hg-dev-ui-ac-api.azurewebsites.net/HGIR/InformationRequest?key=${key}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.mr?.Success) {
            if (data.showConfirmationScreen) {
              setObjId(data?.mr.EntityId);
            }
            if (data.isInvalidUrl) {
              setRefresh(false);
              CallToast("Url is invalid", false, false, "v2style");
              return;
            } else if (data.isTokenExpired) {
              RefreshAccessToken();
              return;
            } else {
              setRefresh(false);
              setPageState(
                data.isLinkExpired ? 2 : data.showConfirmationScreen ? 1 : 0
              );
              setUserDetail(data.userDetail);
              setShowConfirmationScreen(data.showConfirmationScreen);
              setMessage(data.message);
            }

            setLoading(false);
          }
        })
        .catch((ex) => {});
    }
  }

  async function RefreshAccessToken() {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/HGIR/RefreshAccessToken?key=${key}`,
      // `https://hg-dev-ui-ac-api.azurewebsites.net/HGIR/RefreshAccessToken?key=${key}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("dddd", data);
        setRefresh(true);
        // setaccessToken(data.Message);
        InformationRequest(data.Message);
      })
      .catch((ex) => {});
  }

  return (
    <>
      {!loading && (
        <AnonymousLogin
          keyVal={key}
          pageState={pageState}
          userDetail={userDetail}
          showConfirmationScreen={showConfirmationScreen}
          message={message}
          objId={objId}
        />
      )}
    </>
  );
  // return (
  //   <>
  //     <Route exact path="/login/anonymous" component={AnonymousLogin} />
  //   </>
  // );
};

export default AnonymousContributorsView;
