import React from "react";
import { Button, Modal } from "react-bootstrap";

export const OpenLinkModal = ({
    show,
    link,
    hideModal
}) => {
    return (
        <Modal
            show={show}

            dialogClassName="delete-user-modal"
            className=" "
            aria-labelledby="delete-user-modal"
            centered
            onHide={hideModal}
        >
            <Modal.Header>
                <div className="d-flex align-items-center justify-content-between w-100">
                    <div></div>
                    <div className="f-500 ml-3 f-17">Open link</div>
                    <img
                        alt=""
                        src="/images/user-detail-close.svg"
                        className="mr-3 pointer"
                        onClick={hideModal}
                    />
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="permission mb-1 mt-3">Do you want to open the link in a new tab or in this tab?</div>
            </Modal.Body>

            <Modal.Footer>
                <div className="d-flex align-items-center justify-content-between">
                    <Button
                        className="hg-blue-btn"
                        onClick={() => {
                            window.open(link, '_blank');
                            hideModal();
                        }}>
                        Open in new tab
                    </Button>
                    <Button
                        className="hg-blue-btn"
                        onClick={() => {
                            window.location.replace(link);
                            hideModal();
                        }}
                    >
                        Stay in this tab
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default OpenLinkModal;
