import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { BrowserRouter as Router, Link } from "react-router-dom";
import "../../main.scss";
import "./CC_Container.css";
class CC_Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: this.props.size || 4,
      label: this.props.label || "",
      label2: this.props.label2 || "",
      icon: this.props.icon || "",
      text: this.props.text || "",
      link: this.props.link || "",
      linkText: this.props.linkText || "",
      type: this.props.type || "",
      fontSize: "",
      height: this.props.height || "",
    };
  }

  componentDidMount() {
    if (this.state.type == "cfo") {
      this.setState({ fontSize: "cfo-font" });
    } else {
      this.setState({ fontSize: "cto-font" });
    }
  }

  render() {
    return (
      <Col lg={this.state.size}>
        <div className="cc_background px-2">
          <div className="text-center pt-4">
            <p className="c_label">{this.state.label}</p>
            <p className="c_label2">{this.state.label2}</p>
          </div>

          <Col xs={12} style={{ padding: "0 0.2em  0.2em" }}>
            <div className="c_border" style={{ height: this.state.height }}>
              <div className="row text-center pt-3">
                {this.state.icon != "" ? (
                  <Col xs={{ span: 1, offset: 4 }} className="pt-2">
                    <img src={this.state.icon} className="c_image"></img>
                  </Col>
                ) : (
                  ""
                )}

                <Col xs={this.state.icon != "" ? 2 : 12}>
                  {/* <p className={"mx-auto " + this.state.fontSize}>
                                        {/* <p className={"mx-auto " + this.state.fontSize}>
                                        {this.state.symbol == "£" ?
                                            <span>£</span>
                                            :
                                            ""
                                        }
                                        <CountUp start={0} end={parseFloat(this.state.text)} separator="" decimals={0} decimal=","
                                            prefix="" useEasing={false} suffix="M" duration="2" />
                                    </p> */}

                  {this.state.text != undefined ? this.state.text : ""}
                  {/* <CountUp start={0} end={dbObj.CFOComponents.ValueBoxes[0].BoxValue} separator="" decimals={0} decimal=","
                                        prefix="" useEasing={false} suffix="M" duration="2" /> */}
                </Col>
              </div>
              <div
                className="text-center mb-2"
                style={{ paddingBottom: "1em" }}
              >
                <img src="/images/cs_line.svg" className="icon"></img>
                <br></br>
                {this.state.link != "" ? (
                  <div className="pb-1">
                    <Link to={this.state.link}>
                      <span className="cc_containerlinktext">
                        {" "}
                        {this.state.linkText}
                      </span>
                    </Link>
                  </div>
                ) : (
                  <span className="cc_containerlinktext">
                    {this.state.linkText}
                  </span>
                )}
              </div>
            </div>
          </Col>
        </div>
      </Col>
    );
  }
}

export default CC_Container;
