import React from "react";
import { Col, Row } from "react-bootstrap";
import Header from "../Header";
import MspAttackSurface from "../MSPv2/MspAttackSurface.js/MspAttackSurface";
import { useSelector } from "react-redux";

const SaasTechnologySpend = () => {
  const isMenuCollapse = useSelector((state) => state?.global?.menuCollapse);
  return (
    <Row className={`trend_data_screen fullHeight m-0 `} fluid>
      <Col
        xs={12}
        className="px-0"
        style={{
          height: "100%",
        }}
      >
        <div
          className="dashboard fullHeight "
          style={{ height: "auto", padding: "0 1em", background: "inherit" }}
        >
          <header
            className="d-flex justify-content-between navbar header-bottom-shadow"
            style={{
              paddingTop: "1em",
              background: "#FFFFFF",
              position: "fixed",
              width: `${isMenuCollapse ? "92.25%" : "83.25%"}`, // "",
              zIndex: 6,
              marginLeft: "-0.9rem",
              paddingLeft: "2rem",
            }}
          >
            <h1 className="main_header ml-4 d-flex">Technology Spend</h1>

            <Header />
          </header>

          <div className="p-4"> </div>
          <MspAttackSurface setClientData={() => {}} isSaas={true} />
          <div className="p-4"> </div>
        </div>
      </Col>
    </Row>
  );
};

export default SaasTechnologySpend;
