import React from "react";
import { Dropdown } from "react-bootstrap";
import { TrimString } from "../../../Utils/StringTrimmer";
import "../supplier-profiler.scss";

const OptionsDropdown = (props) => {
  return (
    <Dropdown className="options_drop-down">
      <Dropdown.Toggle className={`py-4px  ${props.selected ? "selected" : ""}`}>
        {props.children ? (
          props.children
        ) : (
          <div className="selected-option">
            {props?.selected ? (
              props?.showid ? (
                props?.selected
              ) : (
                TrimString(
                  props?.selected?.replaceAll("_", " "),
                  props.trimStringSelected ?? 30
                )
              )
            ) : (
              <span className="drop-placeholder">{props?.placeholder}</span>
            )}
          </div>
        )}
      </Dropdown.Toggle>
      {!props.disabled && (
        <Dropdown.Menu className={`${props.rightAlign ? "right-align"   :"left-align"}`} style={{width: '200px'}}>
          {props?.data?.map((val) => (
            <div
              className={`dropdown_item d-flex
              
              ${!props.selectionArrowAtLeft ? "justify-content-between" : ""}
              
              ${
                props?.selected &&
                props?.selected == val[props.fieldId]
                  ? "selected"
                  : ""
              }`}
              onClick={(e) => {
                console.log(val[props.fieldId], val[props.fieldValue], e,val)
                props.onClick(val[props.fieldId], val[props.fieldValue], e,val);
                {
                  !props.controlClosing &&
                    e.target.closest(".options_drop-down").click();
                }
              }}
              key={val[props.fieldId]}
              data-id={val[props.fieldId]}
              data-value={val[props.fieldValue]}
            >
              {props.selectionArrowAtLeft && (
                <img
                  src={"/images/settings-v2/black-tick.svg"}
                  className="float-left tick"
                  alt=""
                  style={{
                    visibility: `${
                      props.selected &&
                      props.selected == val[props.fieldValue]
                        ? "visible"
                        : "hidden"
                    }`,

                    filter: `${
                      props.greyTextAndArrowTheme
                        ? "invert(46%) sepia(2%) saturate(3030%) hue-rotate(187deg) brightness(84%) contrast(85%)"
                        : ""
                    }`,
                  }}
                />
              )}
              <span
                className={`${props.selectionArrowAtLeft ? "pl-1" : ""}
              
              ${props.greyTextAndArrowTheme ? "f-grey" : ""}
              
              `}
              >
                {" "}
                {TrimString(
                  val[props.fieldValue]?.replaceAll("_", " "),
                  props.trimStringItem ?? 54
                )}
              </span>
              {!props.selectionArrowAtLeft &&
              props.selected &&
              props.selected == val[props.fieldValue] ? (
                <img
                  // src={"/images/settings-v2/black-tick.svg"}
                  src={"/images/msp/dropdown-grey-tick.svg"}
                  className="float-right tick"
                  alt=""
                />
              ) : (
                ""
              )}
            </div>
          ))}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default OptionsDropdown;

