import React, { useEffect, useState } from "react";
import { Button, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getAPIData, postData } from "../../../../../Utils/commonApi";
import { useAuth } from "../../../../context/Auth";
import { servicesIndex } from "..";
import Loader from "../../../../Common/loader";
import { TrimString } from "../../../../../Utils/StringTrimmer";

const LinkToSeriesDropdown = ({
  onSelect,
  selectedList,
  selectedNistPillar,
  selectedBillingPeriod,
  onAddEdit,
  onDelete,
  checkAccess,
  fieldErrors = 0,
  disabling,
  menuOpen,
  serviceId,
  serviceModalData,
}) => {
  const { authTokens } = useAuth();
  // home
  // series
  const [page, setPage] = useState("series");
  const [listData, setListData] = useState([]);
  const [seriesDetail, setSeriesDetail] = useState({});
  const [saving, setSaving] = useState(false);
  const [loadingSeries, setLoadingSeries] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [loadingSeriesList, setLoadingSeriesList] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // console.log(checkAcc);
    // if (checkAccess) {
    //   let temp = checkAccess();
    //   setCheckAcc(temp);
    // }
    // if (fieldErrors > 0) {
    //   setCheckAcc(false);
    // } else {
    //   setCheckAcc(true);
    // }
  }, []);

  function getLinkedSeries(_pillarId) {
    setLoading(true);
    getAPIData(639, authTokens, {
      optionEnum1: _pillarId,
      optionEnum2: selectedBillingPeriod?.DDLId,
    }).then((response) => {
      setLoadingSeriesList(false);
      setLoading(false);
      setListData(response?.SecurityPackLinkSeries_List ?? []);
    });
  }
  async function deleteLinkedSerie(_seriesId) {
    await getAPIData(640, authTokens, {
      optionEnum1: _seriesId,
      optionEnum2: 0,
    }).then((response) => {
      if (response?.Success) {
        getLinkedSeries(selectedNistPillar?.DDLId);
      } else {
      }
    });
  }
  async function getSeriesDetail(serie) {
    setLoadingSeries(true);
    let data = {};
    await getAPIData(672, authTokens, {
      optionEnum1: serie?.objId_SecurityPackLinkSeries,
    })
      .then((response) => {
        setLoadingSeries(false);
        setSeriesDetail(response);
        data = response;
        if (response) {
          if (response?.SecurityPackVM_List?.length < 3) {
            let temp = response?.SecurityPackVM_List;
            temp.push(serviceModalData);
            console.log(response?.SecurityPackVM_List);
            postData(authTokens, "CreateSecurityPackLinkSeries", {
              objId_SecurityPackLinkSeries:
                response?.objId_SecurityPackLinkSeries,
              SeriesName: response?.SeriesName,
              SecurityPackVM_List: JSON.stringify(temp),
              accesskey: JSON.parse(localStorage.getItem("user_MessageObj")),
              NistPillar: selectedNistPillar?.DDLId,
              BillingPeriod_Text: selectedBillingPeriod.BillingPeriod_Text,
            }).then((response) => {
              onSelect(serie, false);
            });
          }
        } else {
        }
      })
      .catch((err) => {
        setLoadingSeries(true);
      });
    return data;
  }

  async function createEditseries() {
    setSaving(true);
    await postData(authTokens, "CreateSecurityPackLinkSeries", {
      objId_SecurityPackLinkSeries: seriesDetail?.objId_SecurityPackLinkSeries,
      SeriesName: seriesDetail?.SeriesName,
      SecurityPackVM_List: JSON.stringify(
        seriesDetail?.SecurityPackVM_List?.filter(
          (item) => item?.Selected === true
        )
      ),
      accesskey: JSON.parse(localStorage.getItem("user_MessageObj")),
      NistPillar: selectedNistPillar?.DDLId,
      BillingPeriod_Text: selectedBillingPeriod.BillingPeriod_Text,
    })
      .then((response) => {
        setSaving(false);
        if (response?.Success) {
          setLoadingSeriesList(true);
          getLinkedSeries(selectedNistPillar?.DDLId);
          setPage("home");
        }
      })
      .catch((err) => {
        setSaving(false);
      });
  }

  async function handleSelectSeries(e, serie) {
    const res = await getSeriesDetail(serie?.objId_SecurityPackLinkSeries);

    if (res) {
      let temp = res?.SecurityPackVM_List;
      temp.push(serviceModalData);
      console.log(res?.SecurityPackVM_List);
      postData(authTokens, "CreateSecurityPackLinkSeries", {
        objId_SecurityPackLinkSeries:
          seriesDetail?.objId_SecurityPackLinkSeries,
        SeriesName: seriesDetail?.SeriesName,
        SecurityPackVM_List: JSON.stringify(temp),
        accesskey: JSON.parse(localStorage.getItem("user_MessageObj")),
        NistPillar: selectedNistPillar?.DDLId,
        BillingPeriod_Text: selectedBillingPeriod.BillingPeriod_Text,
      }).then((response) => {
        onSelect(serie, false);
        // if (isALreadySelected) {
        // } else {
        //   onSelect(serie, true);
        // }
      });
    }
  }

  return (
    <Dropdown
      className="link-to-series-dropdown"
      onToggle={(e) => {
        setPage("home");
        setSearchString("");
        if (menuOpen) {
          setOpen(menuOpen);
        }
        // if (checkAccess) {
        //   let temp = checkAccess();
        //   // setCheckAcc(temp);
        // }
        let tempErr = [];

        if (!selectedNistPillar) {
          tempErr.push({ name: "type", state: true });
        } else {
          tempErr.push({ name: "type", state: false });
        }
        if (!selectedBillingPeriod) {
          tempErr.push({ name: "billing", state: true });
        } else {
          tempErr.push({ name: "billing", state: false });
        }
        if (serviceId === 0) {
          tempErr.push({ name: "serviceId", state: true });
        } else {
          tempErr.push({ name: "serviceId", state: false });
        }
        if (disabling) {
          disabling(tempErr);
        }

        if (e) {
          // if (checkAcc) {
          setLoadingSeriesList(true);
          if (selectedNistPillar?.DDLText !== "") {
            getLinkedSeries(selectedNistPillar?.DDLId);
          }
          // }
        }
      }}
    >
      <Dropdown.Toggle className="w-100 d-flex link-to-series-parent-toggle">
        {selectedList?.length > 0 ? (
          <div
            className=" bg-grey radius-2 f-grey f-500 w-fit-content  d-flex justify-content-center align-items-center p-2"
            onClick={(e) => {
              onAddEdit(selectedList[0]?.objId_SecurityPackLinkSeries);
              e.stopPropagation();
            }}
          >
            <img
              alt=""
              src="/images/msp/msp-link-series-icon.svg"
              className="mr-2"
            />
            {selectedList[0]?.SeriesName}{" "}
            {selectedList?.length - 1 > 0 ? (
              <span className="f-blue"> +{selectedList?.length - 1}</span>
            ) : (
              ""
            )}
            <img
              className="ml-2"
              width={15}
              src={"/images/calculator/edit.svg"}
              alt=""
            />
            {/* <img
              alt=""
              className="pointer ml-2"
              src="/images/supply-chain/chat-box-cross.svg"
              onClick={(e) => {
                e.stopPropagation();
                onSelect();
              }}
            /> */}
          </div>
        ) : (
          <div
            className=" bg-transparent radius-2 f-grey f-500 w-fit-content  d-flex justify-content-center align-items-center p-2"
            onClick={() => {}}
          >
            {/* {checkAcc ? ( */}
            <img
              alt=""
              src="/images/actioncenter/plusgrey.svg"
              className="mr-2"
            />
            {/* // ) : (
            //   <img
            //     alt=""
            //     src="/images/attack-surface/small-lock.svg"
            //     className="ml-2 mr-2"
            //   />
            // )} */}
            Link or Create
          </div>
        )}
        <div
          style={{ height: "34px", width: "100%" }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        ></div>
      </Dropdown.Toggle>

      {open && (
        <Dropdown.Menu
          className="  p-0 link-to-series-parent-dropdown-menu"
          style={{
            width: "432px",
          }}
        >
          {" "}
          {page === "home" && (
            <>
              <div className="d-flex align-items-center m-1 pl-1">
                <img
                  className="mr-1 ml-1"
                  src="/images/attack-surface/search-icon.svg"
                  alt=""
                />
                <input
                  type="text"
                  placeholder="Find series..."
                  className="border-none bg-transparent p-2"
                  value={searchString}
                  onChange={(e) => {
                    setSearchString(e.target.value);
                  }}
                />
              </div>

              <div
                className={`${
                  selectedList?.length > 0 ? "f-grey" : "f-blue pointer"
                } f-500 ml-3 my-2 d-flex align-items-center `}
                onClick={() => {
                  if (selectedList.length === 0) onAddEdit(0, searchString);
                }}
              >
                {selectedList?.length > 0 ? (
                  <img
                    alt=""
                    src="/images/attack-surface/small-lock.svg"
                    className="mr-2"
                  />
                ) : (
                  <img
                    alt=""
                    src="/images/msp/blue-add-icon.svg"
                    className="mr-2"
                  />
                )}

                {searchString ? (
                  <div className="pt-1">Create "{searchString}" series</div>
                ) : (
                  <div className="pt-1">Create series</div>
                )}
              </div>
              {/* <div className="f-12 f-darkgrey mt-1 ml-3 mb-2">Series</div> */}
              <div
                className={` ${
                  listData?.length > 4 ? "transparent-scroller" : ""
                }`}
                style={{
                  maxHeight: ` ${listData?.length > 4 ? "300px" : ""}`,
                  overflowY: ` ${listData?.length > 4 ? "auto" : ""}`,
                }}
              >
                {loadingSeriesList ? (
                  <div
                    className="d-flex align-items-center justify-content-center w-100 "
                    // style={{
                    //   height: "20vh",
                    // }}
                  >
                    <Loader />
                  </div>
                ) : (
                  <>
                    {listData
                      // ?.filter((item) =>
                      //   item?.SeriesName?.toLowerCase().includes(
                      //     searchString?.toLowerCase()
                      //   )
                      // )
                      ?.map((serie, serieIndex) => {
                        let isALreadySelected =
                          selectedList?.findIndex(
                            (item) =>
                              item?.objId_SecurityPackLinkSeries ===
                              serie?.objId_SecurityPackLinkSeries
                          ) > -1;
                        return (
                          <div
                            key={serieIndex}
                            className={`d-flex align-items-center justify-content-between bg-hover-grey p-2 mx-1 mb-1 radius-4 pointer
                    
                    ${isALreadySelected ? "bg-grey" : ""}
                    `}
                            style={{
                              pointerEvents:
                                selectedList.length > 0
                                  ? isALreadySelected
                                    ? "all"
                                    : "none"
                                  : "all",
                            }}
                            onClick={(e) => {
                              getSeriesDetail(serie);
                              // handleSelectSeries(e, serie);
                              e.target
                                .closest(".link-to-series-dropdown")
                                .click();
                            }}
                          >
                            <div>
                              <div className="f-grey f-500">
                                {TrimString(serie?.SeriesName, 40)}
                              </div>
                              <div className="f-darkgrey f-400">
                                {serie?.BillingPeriod_Text ? (
                                  <div className="d-flex">
                                    {serie?.BillingPeriod_Text} •{" "}
                                    <OverlayTrigger
                                      placement="bottom"
                                      trigger={
                                        serie?.TotalServices == 3
                                          ? ["hover", "focus"]
                                          : []
                                      }
                                      delay={{ show: 200, hide: 250 }}
                                      overlay={
                                        <Tooltip
                                          id={`tooltip-1365`}
                                          className="custom_tooltip_access_series-integr"
                                        >
                                          This series already has 3 services
                                        </Tooltip>
                                      }
                                    >
                                      <div className="ml-1">
                                        {serie?.TotalServices} of 3 Services
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                ) : (
                                  <div className="d-flex">
                                    {serie?.BillingPeriod_Text}{" "}
                                    <OverlayTrigger
                                      placement="bottom"
                                      trigger={
                                        serie?.TotalServices == 3
                                          ? ["hover", "focus"]
                                          : []
                                      }
                                      delay={{ show: 200, hide: 250 }}
                                      overlay={
                                        <Tooltip
                                          id={`tooltip-1365`}
                                          className="custom_tooltip_access_series-integr"
                                        >
                                          This series already has 3 services
                                        </Tooltip>
                                      }
                                    >
                                      <div className="ml-1">
                                        {serie?.TotalServices} of 3 Services
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                )}
                              </div>
                            </div>
                            {selectedList.length > 0 ? (
                              isALreadySelected ? (
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <Dropdown className="series-item-dropdown">
                                    <Dropdown.Toggle>
                                      <img
                                        className="mx-2"
                                        src="/images/settings-v2/horizontal-dots.svg"
                                        alt=""
                                      />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="p-1 pointer">
                                      {/* <div
                                        className="p-2 f-grey bg-hover-grey radius-4"
                                        onClick={(e) => {
                                          // if (isALreadySelected) {
                                          //   onSelect(serie, false);
                                          // } else {
                                          onSelect(serie, true);
                                          e.target
                                            .closest(".link-to-series-dropdown")
                                            .click();
                                          // }
                                          // onAddEdit(
                                          //   serie?.objId_SecurityPackLinkSeries
                                          // );
                                          // setPage("series");
                                          // getSeriesDetail(
                                          //   serie?.objId_SecurityPackLinkSeries
                                          // );
                                        }}
                                      >
                                        <img
                                          alt=""
                                          className="mr-2 d-none"
                                          src="/images/msp/grey-pencil.svg"
                                        />
                                        Link to this series
                                      </div> */}
                                      <div
                                        className="p-2 f-grey bg-hover-grey radius-4"
                                        onClick={() => {
                                          // setPage("series");
                                          // getSeriesDetail(
                                          //   serie?.objId_SecurityPackLinkSeries
                                          // );
                                          onAddEdit(
                                            serie?.objId_SecurityPackLinkSeries
                                          );
                                        }}
                                      >
                                        <img
                                          alt=""
                                          className="mr-2  d-none"
                                          src="/images/msp/grey-pencil.svg"
                                        />
                                        Edit
                                      </div>
                                      <div
                                        className={`p-2 ${
                                          serie?.CanDelete
                                            ? "f-danger"
                                            : "f-darkgrey"
                                        }  bg-hover-grey radius-4`}
                                        onClick={(e) => {
                                          // deleteLinkedSerie(
                                          //   serie?.objId_SecurityPackLinkSeries
                                          // );

                                          if (serie?.CanDelete) {
                                            onDelete(
                                              serie?.objId_SecurityPackLinkSeries
                                            );
                                            e.target
                                              .closest(
                                                ".link-to-series-dropdown"
                                              )
                                              .click();
                                          }
                                        }}
                                      >
                                        {!serie?.CanDelete && (
                                          <img
                                            alt=""
                                            className="mr-2 "
                                            src="/images/attack-surface/small-lock.svg"
                                          />
                                        )}
                                        Delete
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              ) : (
                                <></>
                              )
                            ) : (
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <Dropdown className="series-item-dropdown">
                                  <Dropdown.Toggle>
                                    <img
                                      className="mx-2"
                                      src="/images/settings-v2/horizontal-dots.svg"
                                      alt=""
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="p-1 pointer">
                                    {/* <div
                                      className="p-2 f-grey bg-hover-grey radius-4"
                                      onClick={(e) => {
                                        // if (isALreadySelected) {
                                        //   onSelect(serie, false);
                                        // } else {
                                        onSelect(serie, true);
                                        e.target
                                          .closest(".link-to-series-dropdown")
                                          .click();
                                        // }
                                        // onAddEdit(
                                        //   serie?.objId_SecurityPackLinkSeries
                                        // );
                                        // setPage("series");
                                        // getSeriesDetail(
                                        //   serie?.objId_SecurityPackLinkSeries
                                        // );
                                      }}
                                    >
                                      <img
                                        alt=""
                                        className="mr-2 d-none"
                                        src="/images/msp/grey-pencil.svg"
                                      />
                                      Link to this series
                                    </div> */}
                                    <div
                                      className="p-2 f-grey bg-hover-grey radius-4"
                                      onClick={() => {
                                        // setPage("series");
                                        // getSeriesDetail(
                                        //   serie?.objId_SecurityPackLinkSeries
                                        // );
                                        onAddEdit(
                                          serie?.objId_SecurityPackLinkSeries
                                        );
                                      }}
                                    >
                                      <img
                                        alt=""
                                        className="mr-2  d-none"
                                        src="/images/msp/grey-pencil.svg"
                                      />
                                      Edit
                                    </div>
                                    <div
                                      className={`p-2 ${
                                        serie?.CanDelete
                                          ? "f-danger"
                                          : "f-darkgrey"
                                      }  bg-hover-grey radius-4`}
                                      onClick={(e) => {
                                        // deleteLinkedSerie(
                                        //   serie?.objId_SecurityPackLinkSeries
                                        // );

                                        if (serie?.CanDelete) {
                                          onDelete(
                                            serie?.objId_SecurityPackLinkSeries
                                          );
                                          e.target
                                            .closest(".link-to-series-dropdown")
                                            .click();
                                        }
                                      }}
                                    >
                                      {!serie?.CanDelete && (
                                        <img
                                          alt=""
                                          className="mr-2 "
                                          src="/images/attack-surface/small-lock.svg"
                                        />
                                      )}
                                      Delete
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    {searchString
                      ? listData?.filter((item) =>
                          item?.SeriesName?.toLowerCase().includes(
                            searchString?.toLowerCase()
                          )
                        )?.length == 0 && (
                          <div className="d-flex align-items-start f-darkgrey px-2 f-12">
                            No series found
                          </div>
                        )
                      : listData?.length == 0 && (
                          <div className="d-flex align-items-start f-darkgrey px-2 f-12">
                            No series have been added yet
                          </div>
                        )}
                  </>
                )}
              </div>
              <div className="border-bottom  m-2"></div>
              <div className="d-flex align-items-start f-darkgrey px-2">
                <img
                  src="/images/msp/integrations/grey-info.svg"
                  className="mr-2 mt-1"
                  alt=""
                />
                Services must be of the same type and have the same billing
                period to be linked in a series.
              </div>
              <div className="p-1" />
            </>
          )}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default LinkToSeriesDropdown;

export const LinkToServiceDropdown = ({
  onSelect = () => {},
  selectedList,
  onAddEdit = () => {},
  onDelete = () => {},
  refresh = (id) => {},
  selectedBillingPeriod,
  serviceId,
  BillingPeriod,
  NistPillar,
  seriesModalData,
  id,
  title,
  disabling,
  menuOpen,
}) => {
  const { authTokens } = useAuth();
  // home
  // series
  const [page, setPage] = useState("series");

  const [searchString, setSearchString] = useState("");
  const [loadingSeriesList, setLoadingSeriesList] = useState(false);
  const [servicePacks, setServicePacks] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    console.log("check", selectedList, seriesModalData);
    if (BillingPeriod) {
      console.log("dropdown trigger", BillingPeriod);

      getServicePacks(NistPillar.DDLId, BillingPeriod.DDLId);
    }
  }, [BillingPeriod]);

  async function createEditseries(serie) {
    // setSaving(true);
    setLoadingSeriesList(true);
    let listTemp = selectedList;
    listTemp.push(serie);
    await postData(authTokens, "CreateSecurityPackLinkSeries", {
      objId_SecurityPackLinkSeries: id ?? 0,
      SeriesName: title,
      SecurityPackVM_List: JSON.stringify(listTemp) ?? JSON.stringify([]),
      accesskey: JSON.parse(localStorage.getItem("user_MessageObj")),
      NistPillar: NistPillar?.DDLId,
      BillingPeriod_Text: BillingPeriod.DDLText,
    })
      .then((response) => {
        // setSaving(false);
        setLoadingSeriesList(false);
        if (response?.Success) {
          refresh(response.EntityId ? response.EntityId : id);
          // getServicePacks(BillingPeriod?.DDLId);
          // setPage("home");
        }
      })
      .catch((err) => {
        // setSaving(false);
      });
  }

  async function getServicePacks(nisttype, billingPeriod) {
    await getAPIData(673, authTokens, {
      optionEnum1: nisttype,
      optionEnum2: billingPeriod,
    }).then((response) => {
      // if (response?.Success) {
      // getLinkedSeries(selectedNistPillar?.DDLId);
      console.log("dropdown work", response.SecurityPackList);
      setServicePacks(response.SecurityPackList);
      // } else {
      // }
    });
  }

  return (
    <Dropdown
      className="link-to-service-dropdown"
      onToggle={(e) => {
        setPage("home");
        setSearchString("");
        if (menuOpen) {
          setOpen(menuOpen);
        }
        let tempErr = [];

        tempErr.push({ name: "title", state: title ? false : true });

        if (disabling) {
          disabling(tempErr);
        }
        // if (selectedBillingPeriod) {
        // getServicePacks(selectedBillingPeriod);
        // }
      }}
    >
      <Dropdown.Toggle className="w-100 d-flex link-to-series-parent-toggle">
        <div className="d-flex align-items-center f-14 f-500 f-grey">
          <img
            alt=""
            src="/images/actioncenter/plusgrey.svg"
            className="mr-2"
          />{" "}
          Link a service
        </div>
      </Dropdown.Toggle>

      {open && (
        <Dropdown.Menu
          className="  p-0 link-to-series-parent-dropdown-menu"
          style={{
            width: "350px",
          }}
        >
          {page === "home" && (
            <>
              <div className="d-flex align-items-center m-1 pl-1">
                <img
                  className="mr-1 ml-1"
                  src="/images/attack-surface/search-icon.svg"
                  alt=""
                />
                <input
                  type="text"
                  placeholder="Find services..."
                  className="border-none bg-transparent p-2"
                  value={searchString}
                  onChange={(e) => {
                    setSearchString(e.target.value);
                  }}
                />
              </div>

              {/* <div className="f-12 f-darkgrey mt-1 ml-3 mb-2">Series</div> */}
              <div
                className={` ${
                  servicePacks?.length > 4 ? "transparent-scroller" : ""
                }`}
                style={{
                  maxHeight: ` ${servicePacks?.length > 4 ? "300px" : ""}`,
                  overflowY: ` ${servicePacks?.length > 4 ? "auto" : ""}`,
                }}
              >
                {loadingSeriesList ? (
                  <div className="d-flex align-items-center justify-content-center w-100 ">
                    <Loader />
                  </div>
                ) : (
                  <>
                    {servicePacks
                      ?.filter((item) => item?.CanAddService)
                      ?.filter((item) =>
                        item?.Headline?.toLowerCase().includes(
                          searchString?.toLowerCase()
                        )
                      )?.length > 0 && (
                      <div className="f-darkgrey f-500 p-2 mx-1 mb-1">
                        Choose a service
                      </div>
                    )}
                    {servicePacks
                      ?.filter((item) => item?.CanAddService)
                      ?.filter((item) =>
                        item?.Headline?.toLowerCase().includes(
                          searchString?.toLowerCase()
                        )
                      )
                      .map((serie, serieIndex) => {
                        let isALreadySelected =
                          selectedList?.findIndex(
                            (item) =>
                              item?.objId_SecurityPack ===
                              serie?.objId_SecurityPack
                          ) > -1;
                        return (
                          <div
                            key={serieIndex}
                            className={`d-flex align-items-center justify-content-between bg-hover-grey p-2 mx-1 mb-1 radius-4 pointer
                    
                    ${isALreadySelected ? "bg-grey" : ""}
                    `}
                            onClick={(e) => {
                              if (serie?.CanAddService) {
                                if (isALreadySelected) {
                                  onSelect(serie, false);
                                } else {
                                  onSelect(serie, true);

                                  createEditseries(serie);
                                }
                                e.target
                                  .closest(".link-to-service-dropdown")
                                  .click();
                              }
                            }}
                          >
                            <div>
                              <div className="f-grey f-500">
                                {TrimString(serie?.Headline, 40)}
                              </div>
                              <div className="f-darkgrey f-400 d-flex">
                                {!serie?.CanAddService && (
                                  <OverlayTrigger
                                    placement="bottom"
                                    trigger={
                                      !serie?.CanAddService
                                        ? ["hover", "focus"]
                                        : []
                                    }
                                    delay={{ show: 200, hide: 250 }}
                                    overlay={
                                      <Tooltip
                                        id={`tooltip-1365`}
                                        className="custom_tooltip_access_series-integr"
                                      >
                                        This service is already linked to the
                                        series
                                      </Tooltip>
                                    }
                                  >
                                    <div>
                                      <img
                                        className="mr-1 ml-1"
                                        src={
                                          "/images/msp/msp-cert-link-icon.svg"
                                        }
                                        alt=""
                                      />
                                    </div>
                                  </OverlayTrigger>
                                )}
                                {serie?.Sku}{" "}
                                {serie?.Sku && serie?.Sku.trim() !== "" && (
                                  <span className="mx-1">•</span>
                                )}{" "}
                                {serie?.BillingPeriod_Text}{" "}
                                <span className="mx-1">•</span>
                                {serie?.MonthlyPrice_Text}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    {searchString
                      ? servicePacks
                          ?.filter((item) => item?.CanAddService)
                          ?.filter((item) =>
                            item?.Headline?.toLowerCase().includes(
                              searchString?.toLowerCase()
                            )
                          )?.length == 0 && (
                          <>
                            <div className="f-500 f-darkgrey px-2">
                              Available services
                            </div>
                            <div className="d-flex align-items-start f-darkgrey px-2 f-12">
                              No services found
                            </div>
                          </>
                        )
                      : servicePacks?.length == 0 && (
                          <>
                            <div className="f-500 f-darkgrey px-2">
                              No services yet
                            </div>
                            <div className="d-flex align-items-start f-darkgrey px-2 f-12">
                              Create service first to link into series{" "}
                            </div>
                          </>
                        )}
                    {servicePacks?.filter((item) => !item?.CanAddService)
                      ?.length > 0 && (
                      <div className="f-darkgrey f-500 p-2 mt-2 mb-1">
                        Linked services
                      </div>
                    )}

                    {servicePacks
                      ?.filter((item) => !item?.CanAddService)
                      ?.filter((item) =>
                        item?.Headline?.toLowerCase().includes(
                          searchString?.toLowerCase()
                        )
                      )
                      .map((serie, serieIndex) => {
                        let isALreadySelected =
                          selectedList?.findIndex(
                            (item) =>
                              item?.objId_SecurityPack ===
                              serie?.objId_SecurityPack
                          ) > -1;
                        return (
                          <div
                            key={serieIndex}
                            className={`d-flex align-items-center justify-content-between p-2 mx-1 mb-1 radius-4 
                    
                    ${isALreadySelected ? "bg-grey" : ""}
                    `}
                            onClick={(e) => {
                              if (serie?.CanAddService) {
                                if (isALreadySelected) {
                                  onSelect(serie, false);
                                } else {
                                  onSelect(serie, true);

                                  createEditseries(serie);
                                }
                                e.target
                                  .closest(".link-to-service-dropdown")
                                  .click();
                              }
                            }}
                          >
                            <div>
                              <div className="f-darkgrey f-500">
                                {TrimString(serie?.Headline, 40)}
                              </div>
                              <div className="f-darkgrey f-400 d-flex">
                                {!serie?.CanAddService && (
                                  <OverlayTrigger
                                    placement="bottom"
                                    trigger={
                                      !serie?.CanAddService
                                        ? ["hover", "focus"]
                                        : []
                                    }
                                    delay={{ show: 200, hide: 250 }}
                                    overlay={
                                      <Tooltip
                                        id={`tooltip-1365`}
                                        className="custom_tooltip_access_series"
                                      >
                                        This service is already linked to the
                                        series
                                      </Tooltip>
                                    }
                                  >
                                    <div>
                                      <img
                                        className="mr-1 ml-1"
                                        src={
                                          "/images/msp/msp-cert-link-icon.svg"
                                        }
                                        alt=""
                                      />
                                    </div>
                                  </OverlayTrigger>
                                )}
                                {serie?.Sku}{" "}
                                {serie?.Sku && serie?.Sku?.trim() !== "" && (
                                  <span className="mx-1">•</span>
                                )}{" "}
                                {serie?.BillingPeriod_Text}{" "}
                                <span className="mx-1">•</span>
                                {serie?.MonthlyPrice_Text}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    {
                      searchString &&
                        servicePacks
                          ?.filter((item) => !item?.CanAddService)
                          ?.filter((item) =>
                            item?.Headline?.toLowerCase().includes(
                              searchString?.toLowerCase()
                            )
                          )?.length == 0 && (
                          <div className="d-flex align-items-start f-darkgrey px-2 f-12">
                            No services found
                          </div>
                        )
                      // : servicePacks?.length == 0 && (
                      //     <div className="d-flex align-items-start f-darkgrey px-2 f-12">
                      //       No services exist
                      //     </div>
                      //   )
                    }
                  </>
                )}
              </div>
              <div className="border-bottom  m-2"></div>
              <div className="d-flex align-items-start f-darkgrey px-2">
                <img
                  src="/images/msp/integrations/grey-info.svg"
                  className="mr-2 mt-1"
                  alt=""
                />
                Services must be of the same type and have the same billing
                period to be linked in a series.
              </div>
              <div className="p-1" />
            </>
          )}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};
