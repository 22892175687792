import React, { useState } from "react";
import "../supplier-profiler.scss";

const AccreditationBlock = ({ title, setAccreditationModalState }) => {
  //   const [updateState, setUpdateState] = useState(false);

  return (
    <div
      className="d-flex justify-content-center"
      style={{ width: "1024px", padding: "24px" }}
    >
      <div className="d-flex flex-column justify-content-center align-items-center w-100">
        <div
          className="d-flex flex-row w-100 justify-space-between header mb-2"
          style={{ justifyContent: "space-between" }}
        >
          <div className="title">
            <div className="f-16">{title.Title}</div>
            <div className="f-darkgrey">{title.Description}</div>
          </div>
        </div>

        <div className=" w-100 grid-container">
          {/* <div className="flex-row  file-blocks f-darkgrey"  onClick={() => {
            setAccreditationModalState({
            showModal: true,
            selectedId: 0,
            headerName: "Add Accreditation"
            });
            setUpdateState(!updateState);
            }}>
        <img
            src={"/images/supply-chain/darkgrey-add-base-icon.svg"}
            className="mr-2 text-icon "
            alt=""
            />
                <div className="">Add Accreditation</div>
        </div>  */}
          {title.AccreditationVM?.items?.map((f, ind) => {
            return (
              <div className="flex-row  file-blocks">
                <img
                  width={33}
                  src={`/images/msp/${f?.icon}`}
                  alt=""
                  className=" rounded-full"
                />
                <div className="d-flex flex-column">
                  <div className="d-flex flex-row">
                    <div className="mr-2">{f.name}</div>
                    <div style={{ color: f.status?.foreColor }}>
                      {f.status?.text}
                    </div>
                  </div>
                  <div className="d-flex flex-row file-block-info">
                    <div className="f-darkgrey">{f.points[0]}</div>
                    {f.points[1] && <span className="grey-dot" />}
                    <div className="f-darkgrey">{f.points[1]}</div>
                    {f.points[2] && <span className="grey-dot" />}
                    <div className="f-darkgrey">{f.points[2]}</div>
                  </div>
                </div>
                {f.fileUrl ? (
                  <div className=" f-12 f-darkgrey link-file">
                    <img
                      src={`/images/msp/msp-cert-file-icon.svg`}
                      alt=""
                      className="mr-2 "
                    />
                    <div>File</div>
                  </div>
                ) : f.linkUrl ? (
                  <div className=" f-12 f-darkgrey link-file">
                    <img
                      src={`/images/msp/msp-cert-link-icon.svg`}
                      alt=""
                      className="mr-2 "
                    />
                    <div>Link</div>
                  </div>
                ) : (
                  <div className="add-btn f-12 f-grey">
                    <img
                      src={`/images/msp/msp-cert-add-icon.svg`}
                      alt=""
                      className="mr-2 "
                    />
                    <div>Add</div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AccreditationBlock;
