import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Modal, Form, Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAPIData, getRequestOptions } from "../../../../Utils/commonApi";
import ImageWithInitials from "../../../../Utils/ImageWithInitials";
import { useAuth } from "../../../context/Auth";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import "./companySettingsModals.scss";

const SendCopyMSPEmailsModal = ({ show, hideModal, emailsList, refresh }) => {
  const { authTokens } = useAuth();
  const [email, setEmail] = useState("");
  const [emailList, setEmailList] = useState(emailsList);
  const [error, setError] = useState("");
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (show) {
    }
  }, [show]);
  async function CreateUpdateNotificationSettings() {
    // let temp = [...emailsList, ...emailList];
    setSaving(true);
    var message = JSON.parse(localStorage.getItem("user_MessageObj"));
    let data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/CreateUpdateNotificationSettings?accessKey=${message}`,
      // `https://hg-dev-ui-ac-api.azurewebsites.net/CC_API/CreateUpdateNotificationSettings?accessKey=${message}`,
      getRequestOptions(authTokens, "POST", {
        EmailsList: JSON.stringify(emailList),
        IsClientSettingEmails: 1,
      })
    )
      .then((response) => response.json())
      .then((result) => {
        if (result?.Success) {
          hideModal();
          if (refresh) {
            refresh();
          }
          CallToast("Selected successfully", false, false, "v2style");
        } else {
          // setMainPageLoading(false);

          CallToast("Something went wrong", false, false, "v2style");
        }
        setSaving(false);
      });
  }

  return (
    <Modal
      // show={true}
      show={show}
      dialogClassName=" company-settings-sendcopy-modal"
      className="hide-scrollbar "
      aria-labelledby=" company-settings-sendcopy-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Header className="text-center">
        <div className="w-100 mb-0 font-bold-14 f-500">
          Send copy of MSP system emails to{" "}
        </div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img src="/images/icons/close.svg" alt="" className="close-icon" />
        </span>
      </Modal.Header>
      <Modal.Body className="hide-scrollbar block">
        <Row style={{ gap: "16px" }}>
          {/* Company Name update*/}
          <Col xs={12}>
            <div className="mt-3">
              <p className="label-heading m-0 mb-1 f-500 ">Enter emails</p>
              <div
                className={`${email ? "" : ""} w-100`}
                style={{
                  borderRadius: "4px",
                  border: "1px solid var(--grayscale-gray-05, #EBEDF1)",
                  padding: "4px",
                }}
              >
                <div
                  className="d-flex flex-row align-items-center w-100"
                  style={{
                    gap: "4px",
                    flexWrap: "wrap",
                  }}
                >
                  {emailList?.map((op) => {
                    return (
                      <div className="chip-item">
                        {op}
                        <img
                          alt=""
                          src="/images/attack-surface/grey-cross-icon.svg"
                          className={`ml-1 pointer`}
                          onClick={(e) => {
                            e.stopPropagation();
                            let updated_data = [...emailList];
                            let Index = updated_data.findIndex(
                              (item) => item == op
                            );
                            updated_data.splice(Index, 1);
                            setEmailList(updated_data);
                          }}
                        />
                      </div>
                    );
                  })}

                  <input
                    type="text"
                    className="f-500 f-grey d-flex align-items-center p-2 mr-1 mb-1  bg-grey edit-task"
                    value={email}
                    placeholder={"Add Email"}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && email.trim() !== "") {
                        e.preventDefault();
                        setEmail("");
                        let temp = emailList;
                        temp.push(email);
                        setEmailList(temp);
                      }
                    }}
                  />
                </div>
              </div>
              {/* <Form.Group controlId="formBasicInput">
                <Form.Control
                  className={`${error ? "input-error" : ""}`}
                  as="input"
                  type="text"
                  placeholder="Enter company name"
                  value={companyName}
                  onChange={(e) => {
                    setError("");
                    setCompanyName(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group> */}
              {error && <span className="error-text">{error}</span>}
            </div>
          </Col>
          <Col xs={12}>
            <div className="d-flex f-500" style={{ gap: "12px" }}>
              <img
                src="/images/supply-chain/alert-icon.svg"
                alt=""
                className=""
                width={16}
                style={{ alignSelf: "flex-start" }}
              />
              <p>
                All system emails will be redirected to those emails. Its means
                any user wont receive any system email such as integration
                statuses, score health etc.
              </p>
            </div>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-end">
        <div className="d-flex ">
          <Button className="hg-cancel-btn mr-2" onClick={hideModal}>
            Cancel
          </Button>
          <Button
            className="hg-blue-btn"
            onClick={() => {
              CreateUpdateNotificationSettings();
            }}
          >
            {saving ? "Saving.." : "Save"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SendCopyMSPEmailsModal;
