import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { postRequest } from "../../../Utils/commonApi";
import "../index.scss";
import Loader from "../../Common/loader";
import { useAuth } from "../../context/Auth";

// import "./style.scss";

const WelcomeModal = (props) => {
  const { authTokens } = useAuth();
  const { isOpen, setIsOpen } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
    }
  }, [isOpen]);

  const [activeCount, setActiveCount] = useState(1);

  const response = {
    PageContent: [
      {
        PageTitle: "Action Centre",
        TextHeading: "Manage all of your tasks in one place",
        TextHtml:
          "<p>We know you have a lot to do, so we created an Action Centre in HighGround so you can keep all of your cyber security related tasks in a central place.</p><p>Whether they are from your connected tools, aspects of your compliance, or anything else you know you need to do, you can create and manage here in HighGround.</p>",
        PageNo: 1,
        PageCount: 5,
        Image1URL: "onloading1.svg",
        Image2URL: null,
        ButtonText: "Next",
      },
      {
        PageTitle: "Action Centre",
        TextHeading: "Auto generated tasks",
        TextHtml:
          "<p>To make your life easier, and to enable you to turn notification into action, we automatically funnel action required in HighGround into your action centre.</p><p>But wait, it gets better. If we detect that the issue has been resolved, we automatically archive your tasks, keeping your to-do list accurate and every action tracable.</p>",
        PageNo: 2,
        PageCount: 5,
        Image1URL: "onloading2.svg",
        Image2URL: null,
        ButtonText: "Next",
      },
      {
        PageTitle: "Action Centre",
        TextHeading: "Create your own tasks",
        TextHtml:
          "<p>You don't need to be using HighGround for all your security needs to benefit from the action centre. We know you have a lot to deal with, so we have created the ability for you to create your own tasks.</p><p>Finally, a place to store all your security tasks in one central place. Or as we call it, your cyber sh*t list!</p>",
        PageNo: 3,
        PageCount: 4,
        Image1URL: "onloading3.svg",
        Image2URL: null,
        ButtonText: "Next",
      },
      {
        PageTitle: "Action Centre",
        TextHeading: "Sync your tasks with your project management tool",
        TextHtml:
          "<p>Already have a task management tool? Of course you do!</p><p>We knw how difficult it is to manage a zillion tools, and we don't want to add to that headache. So we've made it easy for your to sync HighGround with your existing tools.</p><p>Simply hook up HighGround to your various task management tools and we'll keep your tasks synced (both ways). Awesome, right?</p>",
        PageNo: 4,
        PageCount: 5,
        Image1URL: "onloading4.svg",
        Image2URL: null,
        ButtonText: "Next",
      },
      {
        PageTitle: "Action Centre",
        TextHeading: "Task Notifications",
        TextHtml:
          "<p>As great as it is to keep all of your cyber security tasks in one place, we know it's still difficult to stay on top of it all. So we've hooked up the action centre to our notifications engine to keep you tuned in at all times.</p><p>We can ping you on Email, the HighGround Mobile App, both or none - it's up to you!</p>",
        PageNo: 5,
        PageCount: 5,
        Image1URL: "onloading5.svg",
        Image2URL: null,
        ButtonText: "Let's Go",
      },
    ],
    mr: {
      Success: true,
      ExThrown: false,
      EntityId: 0,
      Message: "Success Message",
      ExMessage: null,
      ExInnerMessage: null,
      SuccessCount: 0,
      PartialCount: 0,
      FaliureCount: 0,
      ObjectList: [],
    },
  };

  const [data, setData] = useState(response.PageContent);
  const Next = () => {
    const incr = activeCount + 1;
    if (incr <= data?.length) {
      setActiveCount(incr);
    }
  };

  const Back = () => {
    const incr = activeCount - 1;
    if (incr >= 1) {
      setActiveCount(incr);
    }
  };
  const isCloseModal = () => {
    setActiveCount(1);
  };

  const SubmitNotificationSettings = (value) => {
    const data = {
      SettingOwner: 0,
      OwnerType: 1,
      NotificationName: "ShowActionCenterModal",
      NotificationValue: value,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    };

    postRequest(authTokens, "SubmitNotificationSettings", data).then(
      (response) => {
        if (response.Success) {
          window.localStorage.setItem(
            "user_MessageObj",
            JSON.stringify(response?.Message)
          );
        }
      }
    );
  };

  return (
    <Modal
      show={isOpen}
      dialogClassName="modal-50w"
      className="integrationModal  centerModal dashboardModal actionCentreWelcomeModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header className="text-center ">
        {/* {activeCount > 1 && (
          <span class="cursor-pointer" onClick={() => Back()}>
            <img src="/images/icons/arrowLeft.svg" alt="" />
          </span>
        )} */}
        <h1 className="w-100 mb-0 mt-0" style={{ fontSize: "16px" }}>
          {data[activeCount - 1]?.PageTitle}
        </h1>
        <span
          className="cursor-pointer"
          onClick={() => {
            props.showSkipModal();
            setIsOpen();
          }}
        >
          <img src="/images/icons/close.svg" alt="" />
        </span>
      </Modal.Header>
      <Modal.Body>
        {loading && (
          <div className="loader-custom">
            <Loader />
          </div>
        )}
        <Row className="m-0">
          <Col md={12} className="p-0">
            {/* <img
              src={`/images/actioncenter/${data[activeCount - 1]?.Image1URL}`}
              alt=""
              style={{ marginTop: "2rem" }}
            /> */}
            <div className="w-100 p-3"></div>
            {activeCount === 1 && (
              <div className="image-loader">
                <img
                  src={`/images/actioncenter/onloading1.svg`}
                  alt=""
                  className="welcome-modal-image"
                />
              </div>
            )}
            {activeCount === 2 && (
              <div className="image-loader">
                <img
                  src={`/images/actioncenter/onloading2.svg`}
                  alt=""
                  className="welcome-modal-image"
                />
              </div>
            )}
            {activeCount === 3 && (
              <div className="image-loader">
                <img
                  src={`/images/actioncenter/onloading3.svg`}
                  alt=""
                  className="welcome-modal-image"
                />
              </div>
            )}
            {activeCount === 4 && (
              <div className="image-loader">
                <img
                  src={`/images/actioncenter/onloading4.svg`}
                  alt=""
                  className="welcome-modal-image"
                />
              </div>
            )}
            {activeCount === 5 && (
              <div className="image-loader">
                <img
                  src={`/images/actioncenter/onloading5.svg`}
                  alt=""
                  className="welcome-modal-image"
                />
              </div>
            )}

            <h1 className="mt-5">{data[activeCount - 1]?.TextHeading}</h1>

            <div
              dangerouslySetInnerHTML={{
                __html: data[activeCount - 1]?.TextHtml,
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className="m-0" style={{ justifyContent: "space-between" }}>
          <Col
            md={4}
            className="crsrpoint p-0 d-flex align-items-center justify-content-left"
          >
            {activeCount > 1 && (
              <span
                style={{ color: "#616778" }}
                onClick={() => {
                  Back();
                }}
              >
                Back
              </span>
            )}
          </Col>
          <Col
            md={4}
            className="p-0 text-center d-flex align-items-center justify-content-center"
          >
            {activeCount}/5
          </Col>
          <Col md={4} className="p-0 text-right">
            <Button
              variant="primary"
              type="submit"
              className="float-right securityBtnmodal"
              onClick={() => {
                if (activeCount == 5) {
                  SubmitNotificationSettings(0);
                  isCloseModal();
                  setIsOpen();
                  return;
                }
                Next();
              }}
            >
              {data[activeCount - 1]?.ButtonText || "Next"}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default WelcomeModal;
