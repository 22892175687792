import React from "react";
import ContentLoader from "react-content-loader";

const CompanyPageLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 30 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="17%" y="80" rx="3" ry="3" width="10%" height="26" />
      <rect x="17%" y="125" rx="40" ry="40" width="80" height="80" />
      <rect x="62%" y="145" rx="3" ry="3" width="9%" height="39" />

      <rect x="74%" y="145" rx="4" ry="4" width="9%" height="39" />
      <rect x="17%" y="230" rx="4" ry="4" width="9%" height="20" />

      <rect x="17%" y="270" rx="4" ry="4" width="66%" height="70" />
      <rect x="17%" y="342" rx="4" ry="4" width="66%" height="80" />
      <rect x="17%" y="424" rx="4" ry="4" width="66%" height="80" />
      <rect x="17%" y="506" rx="4" ry="4" width="66%" height="70" />

      <rect x="17%" y="610" rx="4" ry="4" width="12%" height="20" />
      <rect x="17%" y="645" rx="4" ry="4" width="66%" height="70" />
      <rect x="17%" y="719" rx="4" ry="4" width="66%" height="70" />
    </ContentLoader>
  );
};

export default CompanyPageLoader;
