import React from "react";
import ContentLoader from "react-content-loader";

const AssetsSearchLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 150 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="1%" y="100" rx="6" ry="6" width="98%" height="50" />
      <rect x="1%" y="152" rx="6" ry="6" width="98%" height="70" />
      <rect x="1%" y="224" rx="6" ry="6" width="98%" height="70" />
      <rect x="1%" y="296" rx="6" ry="6" width="98%" height="70" />
      <rect x="1%" y="368" rx="6" ry="6" width="98%" height="70" />
      <rect x="1%" y="440" rx="6" ry="6" width="98%" height="70" />
      <rect x="1%" y="512" rx="6" ry="6" width="98%" height="70" />
      <rect x="1%" y="584" rx="6" ry="6" width="98%" height="70" />
      <rect x="1%" y="591" rx="6" ry="6" width="98%" height="70" />
    </ContentLoader>
  );
};

export default AssetsSearchLoader;
