import React from "react";
import { Spinner } from "react-bootstrap";

export const WhiteLoader = ({ className, width, variant }) => {
  return (
    <Spinner
      animation="border"
      variant={`${variant ? variant : "light"}`}
      size="sm"
      style={{
        width: `${width}px`,
        height: `${width}px`,
      }}
      className={`mx-2 ${className}`}
    />
  );
};

export const GreyLoader = ({ className, width }) => {
  return (
    <Spinner
      animation="border"
      variant="secondary"
      size="sm"
      style={{
        width: `${width}px`,
        height: `${width}px`,
      }}
      className={`mx-2 ${className}`}
    />
  );
};
