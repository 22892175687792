import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import "./mspSubscriptionAccounts.scss";
import MSPClientFilters from "../../MSPv2/clients/MSPClientFilters";
import { GreyLoader } from "../../Theme/APILoaders";
import { HGHighlightText } from "../../App/App";
import { getAPIData, postData } from "../../../Utils/commonApi";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { useAuth } from "../../context/Auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { throttle } from "lodash";
import MembersPageLoader from "../../settingsV2/members/MembersPageLoader";
import { TrimString } from "../../../Utils/StringTrimmer";
const MspArchivedAccounts = () => {
    const SearchInput = useRef();
    const history = useHistory()
    const {authTokens} = useAuth()
    const [searchString, setSearchString] = useState("");
    const [searchInput, setSearchInput] = useState(false);
    const [timeoutId, setTimeoutId] = useState();
    const [headersList, setHeadersList] = useState([])
  const [ClientNumber, setClientNumber] = useState(0);
const [loading, setLoading] = useState(false)
const [isArchived, setIsArchived] = useState(true)
    const [fetchingNewClients, setFetchingNewClients] = useState(false);
    const [listDataLoading, setListDataLoading] = useState(false);

    const [mspFilters, setmspFilters] = useState(
      `[{"AccountFilter_Enum":1,"FilterText_List":[""]}]`
    );
    const [pageData, setPageData] = useState({})
    const [bannerData, setBannerData] = useState({})
    const [sortData, setSortData] = useState({
        sortItem: "Client_Name",
        sortOrder: 0,
        sorting: false,
      });
      function sortRecords(_fieldKey) {
        let _sortOrder = 0;
        if (sortData.sortItem === _fieldKey) {
          _sortOrder = sortData.sortOrder === 0 ? 1 : 0;
        } else {
          _sortOrder = 0;
        }
    
        setSortData({
          sortItem: _fieldKey,
          sortOrder: _sortOrder,
          sorting: true,
        });
    
        getPageData({
          ClientNumber: 0,
          sortItem: _fieldKey,
          sortOrder: _sortOrder,
        }).then(() => {
          setSortData({
            sortItem: _fieldKey,
            sortOrder: _sortOrder,
            sorting: false,
          });
        });
      }
      const handleInputChange = (event) => {
        const { value } = event.target;
        setSearchString(value);
        // sessionStorage.setItem("mspClientSearchString", value);
        let updated_filter = JSON.parse(mspFilters);
        updated_filter[0].FilterText_List[0] = value;
        setmspFilters(JSON.stringify(updated_filter));
    
        // Clear the previous timeout
        clearTimeout(timeoutId);
    
        // Set a new timeout to call the API after 1 second
        let _timeoutId = setTimeout(() => {
          // setListDataLoading(true);
          getPageData({
            ClientNumber: 0,
            AccountFilter_List: JSON.stringify(updated_filter),
          }).then(() => {
            // setListDataLoading(false);
          });
        }, 800);
        setTimeoutId(_timeoutId);
      };






  useEffect(() => {
    setLoading(true)
    // getBannerData()
    getColumnheaderList()
  }, [ ])
  
  
  function getBannerData(){
    return new Promise((resolve, reject) => {
      getAPIData("3", authTokens, {
        optionEnum1:1
      })
        .then((response) => {
        if(response?.mr?.Success){
          setBannerData(response)
        }
          
          resolve(response); // Resolve the promise
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
           
          reject(err); // Reject the promise
        });
    });
  }
  
  


  function getColumnheaderList(kwargs) {
    setListDataLoading(true)
    getAPIData(4, authTokens,{
      optionEnum1:1,
      optionEnum2:kwargs?.archived ?? Number(isArchived)
    }).then((response) => {
      if (response?.mr?.Success) {
        setHeadersList(response?.AccountSortVisibility_List);
 
    
          getPageData({
            AccountSortVisibility_List: JSON.stringify(
              response?.AccountSortVisibility_List,
             
            ), 
            isArchived : Boolean(kwargs?.archived ?? Number(isArchived)),
            ClientNumber:0
          });
         
      } else {
      }
    });
  }


   function getPageData(kwargs) {
    setListDataLoading(true)
    if(typeof kwargs?.isArchived ==="boolean"){
    setIsArchived(kwargs?.isArchived)
    }

     
    return new Promise((resolve, reject) => {
      postData(authTokens, "Accounts", {
        AccountType:1,
 
        ObjectId: 0,
        pageSize:20,
        ClientNumber: kwargs?.ClientNumber ?? ClientNumber,
        isArchived:kwargs?.isArchived ?? isArchived,
        AccountSortOrder: kwargs?.sortOrder ?? sortData.sortOrder,
        AccountSort: kwargs?.sortItem ?? sortData.sortItem,
        AccountFilter_List: kwargs?.AccountFilter_List ?? mspFilters,
        AccountSortVisibility_List:
          kwargs?.AccountSortVisibility_List ?? JSON.stringify(headersList),
        // accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      })
        .then((response) => {
          setLoading(false);
    setListDataLoading(false)

          if (response?.mr?.Success) {
          
     
            setClientNumber(response?.ClientNumber);
            if (kwargs?.ClientNumber === 0 || ClientNumber === 0) {
              setPageData(response);
            } else {
              let new_output = response;

              let old_client_data = [...pageData.AccountRowData_List];
              old_client_data.push(...new_output.AccountRowData_List);

              let updated_data = {
                ...new_output,
                AccountRowData_List: old_client_data,
              };

              setPageData(updated_data);
            }
            resolve(response);
          } else {
            reject("false");
          }
        })
        .catch((err) => {
          console.log(err);
          reject("false");
        });
    });
  }



  const throttledFetchData = throttle(getMoreClients, 1000);
  async function getMoreClients(e) {
    try {
      if (
        e.target.clientHeight + e.target.scrollTop + 20 >=
        e.target.scrollHeight
      ) {
        if (ClientNumber === 0) return;

        let element = document.getElementById("mainBody");
        element.removeEventListener("scroll", throttledFetchData);

        setFetchingNewClients(true);

        getPageData({
          // financePageNumber:financePageNumber+1,
          // nextPage:true
          
          ClientNumber:ClientNumber+1

        }).then(() => {
          setFetchingNewClients(false);
        });

        console.log("You've reached the end of the screen!");
      }
    } catch (error) {
      console.log("errror aa gya", error);
    }
  }

  useEffect(() => {
    var element = document.getElementById("mainBody");
    element.removeEventListener("scroll", throttledFetchData);
    element.addEventListener("scroll", throttledFetchData);

    return () => {
      element.removeEventListener("scroll", throttledFetchData);
    };
  }, [pageData, fetchingNewClients]);






  return (
    <div className="msp-subscription-accounts px-4">
        
{ loading?  <MembersPageLoader />  :     <>
      <div className="p-5" />
    {false &&  <Row className="m-0 radius-8 bg-grey">
        <Col className="border-right p-0 d-flex flex-column" xs={6}>
          <div className="d-flex w-100 flex-1">
            <div className="flex-1 border-right border-bottom p-3">
              <div className="f-500 f-grey">MSP’s Accounts</div>
              <div className="f-18 f-500 f-black mt-2 mb-3">{bannerData?.stats?.OverAll} </div>
            </div>
            <div className="flex-1 p-3 border-bottom">
              <div className="f-500 f-grey">Trials expired</div>
              <div className="f-18 f-500 f-black mt-2 mb-3">{bannerData?.stats?.TrialExpired}</div>
            </div>
          </div>
          <div className="d-flex w-100 flex-1">
            <div className="flex-1 border-right  p-3">
              <div className="f-500 f-grey">On Trial Period</div>
              <div className="f-18 f-500 f-black mt-2 mb-3">{bannerData?.stats?.OnTrial}</div>
            </div>
            <div className="flex-1 p-3">
              <div className="f-500 f-grey">On Paid Suscription</div>
              <div className="f-18 f-500 f-black mt-2 mb-3">{bannerData?.stats?.Paid}</div>
            </div>
          </div>
        </Col>
        <Col className="p-3 subscription-usage-wrapper" xs={6}>
          <div className="f-grey f-500 mb-3">Subscriptions Usage</div>
          {
          bannerData?.plans?.sort((a,b)=> a.Order- b.Order )?.map((item) => {
            const maxValue = bannerData?.plans?.length >0 ? Math.max(...bannerData?.plans?.map(item => item.Value)) : 0
            return (
              <div className="w-100">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center f-grey">
                    <img
                      src="/images/msp/dollar-icon.svg"
                      alt=""
                      className="mr-2"
                      width={13}
                      height={13}
                    />
                    {item.Label}
                  </div>
                  <div className="f-darkgrey">{item?.Value} </div>
                </div>
                <div className="w-100">
                  <progress value={item.Value} max={maxValue} />
                </div>
              </div>
            );
          })}
        </Col>
      </Row>}
{/* <div  className="p-2"/> */}
<div className="d-flex align-items-center justify-content-between clients-header">
                <div className="f-16 f-600">
                  
                  {pageData?.TotalClients} MSP’s Archived Accounts
                </div>
                {/* Filter section */}
                <div className="d-flex align-items-center ">
                {/* <div
                    className="pointer mr-2 f-grey f-500"
                    onClick={() => {
                    // getColumnheaderList({archived: Number(!isArchived)})
                    history.push("/subscription-portal/msp-archived-accounts")
                    }}
                  >
                    <img
                      src="/images/actioncenter/folder.svg"
                      alt=""
                      className="mr-2"
                    />
                    Archived
                  </div> */}
                 
                  <div className="mx-2 d-flex align-items-center">
                    <img
                      alt=""
                      src="/images/attack-surface/search-icon.svg"
                      className="pointer"
                      onClick={() => {
                        setSearchInput(true);
                        SearchInput.current.focus();
                      }}
                    />
                    <input
                      type="text"
                      placeholder="Search.."
                      ref={SearchInput}
                      value={searchString}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                      onBlur={() => {}}
                      className={`${
                        searchInput
                          ? "animated-search-input-open"
                          : "animated-search-input-close"
                      } pl-2`}
                      autoFocus
                    />
                    <img
                      alt=""
                      style={{
                        visibility: searchString !== "" ? "visible" : "hidden",
                      }}
                      src="/images/settings-v2/header-search-cross.svg"
                      className="pointer"
                      onClick={() => {
                        handleInputChange({ target: { value: "" } });
                      }}
                    />
                  </div>
                  <div className="mx-1 f-grey f-500">Filter</div>
                 
                  <div className="mx-2">
                    <Button
                      className="hg-blue-btn py-1 px-2"
                      onClick={() => {
                      
                      }}
                    >
                      Add
                    </Button>
                  </div>
                  <div className="d-flex align-items-center mx-2">
                    <MSPClientFilters
                      headersList={headersList}
                      updatedHeaderList={(e) => {
                        getPageData({
                          AccountSortVisibility_List: JSON.stringify(e),
                          ClientNumber: 0,
                        });
                        setHeadersList(e);
                        console.log(e, "updated");
                      }}
                    >
                      <img
                        alt=""
                        className="pointer"
                        src="/images/settings-v2/horizontal-dots.svg"
                      />
                    </MSPClientFilters>
                  </div>
                </div>
              </div>

  {pageData?.AccountRowData_List?.length===0?
  
  
  <div className="d-flex flex-column justify-content-center mx-auto w-fit-content mt-5">
                <img
                  src="/images/msp/file-icon.svg"
                  alt=""
                  className="mx-auto"
                  width={40}
                  height={40}
                />

                <div className="f-600 f-grey mt-4 w-100 text-center">
                No MSP’s Accounts yet
                </div>
                <div className=" f-darkgrey mt-2  w-fit-content">
                There are no MSP’s Accounts yet
                </div>
{/* 
                <div
                  className="d-flex align-items-center f-blue pointer mt-3  w-fit-content"
                  onClick={() => {
                    setAccreditationId(0);
                    setSelectedAccreditationItem(null);
                    setCertificateName('');
                    setAccreditationDetails({});
                    setUploadedFiles([]);
                    setInputValue('');
                    setShowSidePanel(true);
                    setAccreditationLoading(true)
                    getAccreditationDetails(0)
                  }}
                >
                  <img
                    src="/images/msp/blue-add-icon.svg"
                    alt=""
                    className="mr-2"
                  />
                  Add Accreditation
                </div> */}
              </div>
  
  :<div className="mt-3 transparent-scroller"     style={
                      {
                        overflowX:"auto"
                      }
                    }>
                  {listDataLoading && (
                    <div
                      className="position-absolute w-100 h-100 bg-white"
                      style={{
                        opacity: "0.6",
                        zIndex: "2",
                      }}
                    ></div>
                  )}
                  <div
                    className="bg-grey radius-8 border-parent "
                    style={
                      {
                         width: "max-content",
                      }
                    }
                  >
                    {/* Header */}
                    <div className="border-item d-flex align-items-center justify-content-between p-2 ">
                      {pageData?.AccountSortVisibility_List?.sort(
                        (a, b) => a.SortOrder - b.SortOrder
                      ).map((headerItem, headerItemIndex) => {
                          return (
                            <>
                              {headerItem?.Visibility === true && (
                                <>
                                  {headerItem?.FieldKey === "Client_Name" && (
                                    <div
                                      className={`f-12 f-grey d-flex align-items-center pointer  justify-content-start   ${
                                        headerItemIndex === 0 ? "pl-5" : "pl-4"
                                      } --`}
                                      style={{ width: "16rem" }}
                                      onClick={() => {
                                        sortRecords(headerItem?.FieldKey);
                                      }}
                                    >
                                      Client Name
                                      {sortData.sortItem === "Client_Name" && (
                                        <>
                                          {sortData?.sorting ? (
                                            <GreyLoader width={10} />
                                          ) : (
                                            <img
                                              alt=""
                                              src="/images/actioncenter/arrowdown.svg"
                                              className={`ml-2 ${
                                                sortData.sortOrder === 0
                                                  ? "rotate180"
                                                  : ""
                                              }`}
                                            />
                                          )}
                                        </>
                                      )}
                                    </div>
                                  )}

                                  {headerItem?.FieldKey !== "Client_Name" && (
                                    <div
                                      className={`f-12 f-grey d-flex align-items-center pointer  justify-content-start     ${
                                        headerItemIndex === 0 && "pl-5"
                                      } `}
                                      onClick={() => {
                                        sortRecords(headerItem?.FieldKey);
                                         
                                      }}
                                    style={{
                                        width:"16rem"
                                    }}
                                    >
                                      {headerItem?.FieldName}
                                      {sortData.sortItem ===
                                        headerItem?.FieldKey && (
                                        <>
                                          {sortData?.sorting ? (
                                            <GreyLoader width={10} />
                                          ) : (
                                            <img
                                              alt=""
                                              src="/images/actioncenter/arrowdown.svg"
                                              className={`ml-2 ${
                                                sortData.sortOrder === 0
                                                  ? "rotate180"
                                                  : ""
                                              }`}
                                            />
                                          )}
                                        </>
                                      )}
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          );
                        })}

                     
                    </div>

                    {/* TABLE BODY */}
                    {pageData?.AccountRowData_List?.map(
                      (tableBody, index) => {
                        return (
                          <div
                            className="border-item d-flex align-items-center justify-content-between p-2  parent-visible bg-hover-dark-grey pointer"
                            onClick={() => {
                         history.push(`/subscription-portal/msp-accounts/${tableBody?.objId_Customer}`)
                        //  history.push(`/subscription-portal/msp-accounts/${tableBody?.objId_SO}`)
                            }}
                          >
                           


                           {pageData?.AccountSortVisibility_List?.sort(
                        (a, b) => a.SortOrder - b.SortOrder
                      ).map((sortItem, sortItemIndex) => {
                          return (
                            <>
                              {sortItem?.Visibility === true && (
                                <>
                                 {
                      tableBody?.AccountColumnData_List?.map((tableBodyItem, tableBodyItemIndex) => {
                              return (
                                <>
                           {tableBodyItem?.FieldName=== sortItem?.FieldName &&     <>
                                  {tableBodyItem?.FieldName ===
                                    "Company Name" && (
                                    <div
                                      className=" d-flex align-items-center  justify-content-start pl-2  py-2"
                                      style={{ width: "16rem" }}
                                    >
                                      <img
                                        alt=""
                                        src={
                                          tableBodyItem?.Icon ??
                                          "/images/user-logo.svg"
                                        }
                                        className="ml-1 mr-2 rounded-full"
                                        width={32}
                                        height={32}
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src =
                                            "/images/user-logo.svg";
                                        }}
                                      />
                                      <div className="pr-3">
                                        <p className="f-500 f-black m-0">
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: HGHighlightText(
                                                tableBodyItem?.FieldValue1,
                                                searchString
                                              ),
                                            }}
                                          />
                                        </p>
                                        <p className="f-12 f-darkgrey">
                                         {tableBodyItem?.FieldValue3}
                                        </p>
                                      </div>
                                    </div>
                                  )}

                                  {tableBodyItem?.FieldName ===
                                    "Location" && (
                                    <div
                                      className={`f-12 f-black f-500 d-flex  justify-content-start   ${
                                        tableBodyItemIndex === 0 && "pl-5"
                                      }`}
                                      style={{ width: "16rem" }}
                                    >
                                     {tableBodyItem?.FieldValue1}
                                    </div>
                                  )}
                                  {tableBodyItem?.FieldName ===
                                    "Account Owner" && (
                                      <div
                                      className=" d-flex align-items-center  justify-content-start pl-2  py-2"
                                      style={{ width: "16rem" }}
                                    >
                                      <img
                                        alt=""
                                        src={
                                          tableBodyItem?.Icon ??
                                          "/images/user-logo.svg"
                                        }
                                        className="ml-1 mr-2 rounded-full"
                                        width={32}
                                        height={32}
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src =
                                            "/images/user-logo.svg";
                                        }}
                                      />
                                      <div className="pr-3">
                                        <p className="f-500 f-black m-0">
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: HGHighlightText(
                                                tableBodyItem?.FieldValue1,
                                                searchString
                                              ),
                                            }}
                                          />
                                        </p>
                                        <p className="f-12 f-darkgrey">
                                        {TrimString(tableBodyItem?.FieldValue3,15)}
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                  {tableBodyItem?.FieldName ===
                                    "Signed up" && (
                                    <div
                                      className={`f-12  d-flex  justify-content-start  ${
                                        tableBodyItemIndex === 0 && "pl-5"
                                      }`}
                                      style={{ width: "16rem" ,
                                      color:tableBodyItem?.FieldValue1Color

                                      }}
                                    >
                                     {tableBodyItem?.FieldValue1}
                                    </div>
                                  )}
                                  {tableBodyItem?.FieldName ===
                                    "Last Log in" && (
                                    <div
                                      className={`f-12 f-black d-flex  justify-content-start  ${
                                        tableBodyItemIndex === 0 && "pl-5"
                                      }`}
                                      style={{ width: "16rem" }}
                                    >
                                     12/01/24 17:00
                                    </div>
                                  )}
                                  {tableBodyItem?.FieldName ===
                                    "Subscription" && (
                                    <div
                                      className={`f-12  d-flex  justify-content-start  ${
                                        tableBodyItemIndex === 0 && "pl-5"
                                      }`}
                                      style={{ width: "16rem" }}
                                    >
                                     
                                     <div>
                                      <div><span
                                      style={{
                                        color:tableBodyItem?.FieldValue1Color
                                      }}
                                      > {tableBodyItem?.FieldValue1}</span> <span  className="ml-2" style={{
                                        color:tableBodyItem?.FieldValue2Color
                                      }}> {tableBodyItem?.FieldValue2}</span></div>
                                      <div><span  style={{
                                        color:tableBodyItem?.FieldValue3Color
                                      }}> {tableBodyItem?.FieldValue3}</span></div>
                                     </div>
                                    </div>
                                  )}
                                  {tableBodyItem?.FieldName ===
                                    "Trial Expiry" && (
                                    <div
                                      className={`f-12 d-flex  justify-content-start  ${
                                        tableBodyItemIndex === 0 && "pl-5"
                                      }`}
                                      style={{ width: "16rem", color:tableBodyItem?.FieldValue1Color }}
                                    >
                                   {tableBodyItem?.FieldValue1}
                                    </div>
                                  )}
                                  {tableBodyItem?.FieldName ===
                                    "Qty Members" && (
                                    <div
                                      className={`f-12 f-black d-flex  justify-content-start  ${
                                        tableBodyItemIndex === 0 && "pl-5"
                                      }`}
                                      style={{ width: "16rem" }}
                                    >
                                        {tableBodyItem?.FieldValue1}
                                    </div>
                                  )}
                                  {tableBodyItem?.FieldName ===
                                    "Qty Prospect Licenses" && (
                                    <div
                                      className={`f-12 f-black d-flex  justify-content-start  ${
                                        tableBodyItemIndex === 0 && "pl-5"
                                      }`}
                                      style={{ width: "16rem" }}
                                    >
                                        {tableBodyItem?.FieldValue1}
                                    </div>
                                  )}
                                  {tableBodyItem?.FieldName ===
                                    "Qty Premium Licenses" && (
                                    <div
                                      className={`f-12 f-black d-flex  justify-content-start  ${
                                        tableBodyItemIndex === 0 && "pl-5"
                                      }`}
                                      style={{ width: "16rem" }}
                                    >
                                        {tableBodyItem?.FieldValue1}
                                    </div>
                                  )}
                                  {tableBodyItem?.FieldName ===
                                    "Promo" && (
                                    <div
                                      className={`f-12 f-black d-flex  justify-content-start  ${
                                        tableBodyItemIndex === 0 && "pl-5"
                                      }`}
                                      style={{ width: "16rem" }}
                                    >
                                    {tableBodyItem?.FieldValue1}
                                    </div>
                                  )}
                                  {tableBodyItem?.FieldName ===
                                    "Qty Integrations" && (
                                    <div
                                      className={`f-12 f-black d-flex  justify-content-start  ${
                                        tableBodyItemIndex === 0 && "pl-5"
                                      }`}
                                      style={{ width: "16rem" }}
                                    >
                                                                          {tableBodyItem?.FieldValue1}

                                    </div>
                                  )}
                                  {tableBodyItem?.FieldName ===
                                    "XXXXXX" && (
                                    <div
                                      className={`f-12 f-black d-flex  justify-content-start  ${
                                        tableBodyItemIndex === 0 && "pl-5"
                                      }`}
                                      style={{ width: "16rem" }}
                                    >
                                     Switzerland
                                    </div>
                                  )}
                                  {tableBodyItem?.FieldName ===
                                    "XXXXXX" && (
                                    <div
                                      className={`f-12 f-black d-flex  justify-content-start  ${
                                        tableBodyItemIndex === 0 && "pl-5"
                                      }`}
                                      style={{ width: "16rem" }}
                                    >
                                     Switzerland
                                    </div>
                                  )}
                                  {tableBodyItem?.FieldName ===
                                    "XXXXXX" && (
                                    <div
                                      className={`f-12 f-black d-flex  justify-content-start  ${
                                        tableBodyItemIndex === 0 && "pl-5"
                                      }`}
                                      style={{ width: "16rem" }}
                                    >
                                     Switzerland
                                    </div>
                                  )}
                                  {tableBodyItem?.FieldName ===
                                    "Qty Products" && (
                                    <div
                                      className={`f-12 f-black d-flex  justify-content-start  ${
                                        tableBodyItemIndex === 0 && "pl-5"
                                      }`}
                                      style={{ width: "16rem" }}
                                    >
                                     {tableBodyItem?.FieldValue1}
                                    </div>
                                  )}
                                  {tableBodyItem?.FieldName ===
                                    "Location" && (
                                    <div
                                      className={`f-12 f-black d-flex  justify-content-start  ${
                                        tableBodyItemIndex === 0 && "pl-5"
                                      }`}
                                      style={{ width: "16rem" }}
                                    >
                                     Switzerland
                                    </div>
                                  )}
                                  {tableBodyItem?.FieldName ===
                                    "Qty Services" && (
                                    <div
                                      className={`f-12 f-black d-flex  justify-content-start  ${
                                        tableBodyItemIndex === 0 && "pl-5"
                                      }`}
                                      style={{ width: "16rem" }}
                                    >
                                     {tableBodyItem?.FieldValue1}
                                    </div>
                                  )}
                                  {tableBodyItem?.FieldName ===
                                    "Qty Packages" && (
                                    <div
                                      className={`f-12 f-black d-flex  justify-content-start  ${
                                        tableBodyItemIndex === 0 && "pl-5"
                                      }`}
                                      style={{ width: "16rem" }}
                                    >
                                    {tableBodyItem?.FieldValue1}
                                    </div>
                                  )}
                                </>}
                                </>
                              );
                            })}

                          
                                </>
                              )}
                            </>
                          );
                        })}


















                         

                   
                          </div>
                        );
                      }
                    )}
                  </div>
                  {fetchingNewClients && (
                    <div className="d-flex align-items-center f-darkgrey ml-3">
                      <GreyLoader width={12} />
                      Loading...
                    </div>
                  )}
                </div>}
</>}
    </div>
  );
};

export default MspArchivedAccounts;
