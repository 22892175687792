import React, { useState } from "react";
import { Row, Col, Dropdown, Button } from "react-bootstrap";
import "./dropdown.scss";
import HighgroundDatePicker from "../../test/TestComponent";
import OptionsDropdown from "../../supplierProfiler/ProfilerDropdowns/OptionsDropdown";

const ProfilingFilterDropdown = (props) => {
  const {
    activeFilter,
    setFilterParameters,
    allowDrillDownQuestionnaire,
    riskRatings,
  } = props;

  let dummyFilterList = [
    { TaskFilter: "created", FilterText_List: [] },
    { TaskFilter: "Title", FilterText_List: [] },
    { TaskFilter: "Re-Profiling Due", FilterText_List: [] },
    { TaskFilter: "Last Profiled", FilterText_List: [] },
    { TaskFilter: "Supplier", FilterText_List: [] },
    { TaskFilter: "Rating", FilterText_List: [] },
  ];

  const [filterList, setFilterList] = useState([...dummyFilterList]);

  function ResetFilters() {
    setFilterList(dummyFilterList);

    setFilterParameters(dummyFilterList);
  }

  return (
    <div className="dropdownfilter supply-chain-filter">
      <Dropdown
        className="input_custom_dropdown"
        // show={show}
        onToggle={(e) => {
          console.log(e);
          if (e) {
          }
        }}
      >
        <Dropdown.Toggle
          className={`p-0 position-relative filter_toggle ${
            activeFilter ? "active-dropdown-toggle" : ""
          }`}
          style={{ zIndex: 1 }}
          //   onClick={toggleFilter}
        >
          {props.children}
        </Dropdown.Toggle>

        {allowDrillDownQuestionnaire?.Option && (
          <Dropdown.Menu className="Billing Info" style={{ height: "150px" }}>
            <Row>
              <Col
                xs={6}
                className="mt-2 mb-2 pr-0 d-flex align-items-center greyText"
              >
                Filters
              </Col>
              <Col xs={6} className="mt-2 mb-2 d-flex text-right">
                <span
                  className="w-100 txt-blue pointer"
                  onClick={() => {
                    ResetFilters();
                  }}
                >
                  Reset
                </span>
              </Col>
              {/* Fot Title */}

              {/* Fot Supplier/Client */}
              <>
                <Col xs={6} className=" pr-0 d-flex align-items-center my-2">
                  Supplier
                </Col>
                <Col xs={6} className="pl-0 d-flex align-items-center">
                  <input
                    placeholder="Type"
                    type={`text`}
                    className="border-none pl-2"
                    value={filterList[4].FilterText_List?.[0] ?? ""}
                    onChange={(e) => {
                      let updated_data = [...filterList];
                      updated_data[4].FilterText_List[0] = e.target.value;
                      setFilterList(updated_data);
                    }}
                  />
                </Col>
              </>

              {/* For Due Date */}
              <>
                <Col xs={6} className=" pr-0 d-flex align-items-center my-2">
                  Re-Profiling Due
                </Col>
                <Col
                  xs={6}
                  className="pl-0 d-flex align-items-center filter-date-picker"
                >
                  <HighgroundDatePicker
                    selected={filterList[2].FilterText_List?.[0] ?? ""}
                    icon={"/images/attack-surface/date-icon.svg"}
                    onChange={(val) => {
                      let updated_data = [...filterList];
                      updated_data[2].FilterText_List[0] = val;
                      setFilterList(updated_data);
                    }}
                    id={
                      "DUe_date76"
                        ?.replaceAll(" ", "")
                        ?.replaceAll("/", "")
                        ?.replaceAll("-", "")
                        ?.replaceAll("_", "")
                        ?.replaceAll("(", "")
                        ?.replaceAll(")", "")
                        ?.replaceAll(".", "")
                        ?.replaceAll(",", "")
                      // +
                      // Date.now()
                    }
                  />
                </Col>
              </>
              {/* For Last edited */}
              <>
                <Col xs={6} className=" pr-0 d-flex align-items-center my-2">
                  Last Profiled
                </Col>
                <Col
                  xs={6}
                  className="pl-0 d-flex align-items-center filter-date-picker"
                >
                  <HighgroundDatePicker
                    selected={filterList[3].FilterText_List?.[0] ?? ""}
                    icon={"/images/attack-surface/date-icon.svg"}
                    onChange={(val) => {
                      let updated_data = [...filterList];
                      updated_data[3].FilterText_List[0] = val;
                      setFilterList(updated_data);
                    }}
                    id={
                      "DUe_datscsce76"
                        ?.replaceAll(" ", "")
                        ?.replaceAll("/", "")
                        ?.replaceAll("-", "")
                        ?.replaceAll("_", "")
                        ?.replaceAll("(", "")
                        ?.replaceAll(")", "")
                        ?.replaceAll(".", "")
                        ?.replaceAll(",", "")
                      // +
                      // Date.now()
                    }
                  />
                </Col>
              </>
              {/* For Rating */}
              <>
                <Col xs={6} className=" pr-0 d-flex align-items-center my-2">
                  Rating
                </Col>
                <Col xs={6} className="pl-0 d-flex align-items-center">
                  <OptionsDropdown
                    fieldId={"id"}
                    fieldValue={"levelName"}
                    selected={filterList[5].FilterText_List?.[0] ?? ""}
                    placeholder="Choose"
                    onClick={(id, val) => {
                      let updated_data = [...filterList];
                      updated_data[5].FilterText_List[0] = val;
                      setFilterList(updated_data);
                    }}
                    data={riskRatings}
                  />
                  {/* <input
                    placeholder="Type"
                    type={`text`}
                    className="border-none pl-2"
                    value={filterList[5].FilterText_List?.[0] ?? ""}
                    onChange={(e) => {
                      let updated_data = [...filterList];
                      updated_data[5].FilterText_List[0] = e.target.value;
                      setFilterList(updated_data);
                    }}
                  /> */}
                </Col>
              </>

              {/*wwwwwwwwwwwwwwwwww wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww */}
              {/* wwwwwwwwwwwwwwwwwwwwwwwww wwwwwwwwwwwwwwwwwwwwwwwwwwwww*/}
              {/* wwwwwwwwwwwwwwwwwwwwwwwwww wwwwwwwwwwwwwwwwwwwwwwwwwwwwww*/}
              {/* wwwwwwwwwwwwwwwwwwwwwww wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww*/}
              {/* wwwwwwwwwwwwwwwwwwwwwwwwwwwwwww wwwwwwwwwwwwwwwwwwwwwwwwwwwww*/}
              {/* wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww */}

              <hr color="#EBEDF1" className="mb-3 " />
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-between "
              >
                <div
                  className="pointer"
                  onClick={() => {
                    ResetFilters();
                  }}
                >
                  <img alt="" src="/images/settings-v2/refresh-filter.svg" />
                  <span
                    className="ml-1"
                    style={{ color: "#BBBEC9", padding: "3px" }}
                  >
                    Reset
                  </span>
                </div>
                <div>
                  <Button
                    className="cancel-btnn"
                    onClick={(e) => {
                      e.target.closest(".input_custom_dropdown").click();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="apply-btn"
                    onClick={(e) => {
                      setFilterParameters(filterList);
                      e.target.closest(".input_custom_dropdown").click();
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </Col>
            </Row>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </div>
  );
};

export default ProfilingFilterDropdown;
