import React, { useEffect, useState } from "react";
import SlidingModal from "../../CustomModal/SlidingModal";
import { Button, Dropdown } from "react-bootstrap";
import HighgroundDatePicker from "../../test/TestComponent";
import { handleDateForInvoice } from "../../MSPv2/MspAttackSurface.js/technologySpendModal/TechnologySpendModal";
import { StatusChip } from "../finance/SubscriptionPortalFinance";

const AddEditSubscriptionBillingModal = ({ show, hideModal, _modalData,createEditInvoice ,refreshData}) => {
  const [saving, setSaving] = useState(false)
  const [modalData, setModalData] = useState({})
  useEffect(() => {
    setModalData(_modalData)
  }, [_modalData])
  
  return (
    <SlidingModal
      show={show}
      parentID={"mainBody"}
      id="sddssqxw"
      width={"31%"}
      onHide={hideModal}
    >
      <SlidingModal.Header className="  d-flex p-3 align-items-center justify-content-between border-bottom">
        <div></div>

        <div className="f-500 f-black">  Billing</div>

        <div className="cursor-pointer" onClick={hideModal}>
          <img src="/images/attack-surface/cross-icon.svg" alt="" />
        </div>
      </SlidingModal.Header>
      <SlidingModal.Body className="p-3">
        <div className="f-12 f-500 f-darkgrey mb-2">Details</div>
        <div className="w-100  d-flex align-items-center f-black mb-2">
          <div className="w-50">Date</div>
          <div className="w-50 msp-date-picker">
            {/* <HighgroundDatePicker
              disableFutureDates
              isError={false}
              selected={handleDateForInvoice("")}
              icon={"/images/attack-surface/date-icon.svg"}
              onChange={(val) => {}}
              id={"dvdsgwdgdj"}
            /> */}
            {modalData.Date}
          </div>
        </div>
        <div className="w-100  d-flex align-items-center f-black mb-2">
          <div className="w-50">Status</div>
          <div className="w-50 msp-date-picker">
          <Dropdown
                                  className={`transparennt-wrapper-dropdown `}
                                  onClick={(e) => {
                                    // e.stopPropagation();
                                  }}
                                >
                                  <Dropdown.Toggle>
                                    {modalData?.Status?.filter(
                                      (item) => item.isSelected
                                    ).map((status, statusIndex) => {
                                      return (
                                        <div className="mb-2">
                                          <StatusChip
                                            text={status?.StatusText}
                                            color={status?.StatusColor}
                                          />
                                        </div>
                                      );
                                    })}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="p-3  pointer">
                                    <div className="f-12 f-darkgrey mb-2">
                                      Status
                                    </div>
                                    {modalData?.Status?.map(
                                      (status, statusIndex) => {
                                        return (
                                          <div className="mb-2"
                                          onClick={(e)=>{
                                            e.target.closest(".transparennt-wrapper-dropdown").click()

                                            let updated_data = {
                                              ...modalData
                                            };
                                            
                                            // Update isSelected property of all elements in the Status array
                                            updated_data.Status = updated_data.Status.map(element => {
                                              return { ...element, isSelected: false }; // Set isSelected to false for all elements
                                            });
                                            
                                            // Set isSelected to true for the element at statusIndex
                                            updated_data.Status[statusIndex].isSelected = true;
                                            
                                            setModalData(updated_data);
                                            
                                          }}
                                          
                                          >
                                            <StatusChip
                                              text={status?.StatusText}
                                              color={status?.StatusColor}
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
          </div>
        </div>
        <div className="f-12 f-500 f-darkgrey mb-2">Account</div>
        <div className="w-100 py-2 d-flex align-items-center f-black">
                              <div className="w-50 f-500">Account</div>
                              <div className="w-50 d-flex align-items-center f-500">
                                 <img src={modalData?.AccountIcon ??   "/images/user-logo.svg"} alt="" width={16} height={16} className="mr-2 rounded-full"/>             {modalData.AccountName}

                              </div>
                            </div>
        <div className="w-100 py-2 d-flex align-items-center f-black">
                              <div className="w-50 f-500">Type</div>
                              <div className="w-50">
                                {modalData.AccountType}
                                {/* <div
                                  className={` ${
                                    false
                                      ? "input-error"
                                      : ""
                                  }   d-flex align-items-center radius-4 highlight-input  p-2 flex-grow-1 mr-1`}
                                >
                                  <input
                                    type="text"
                                    className={` ${""} bg-transparent flex-grow-1 border-none f-500`}
                                    placeholder={
                                    "Type"
                                    }
                                    value={"MSP"}
                                    onChange={(e) => {
                                     
                                    }}
                                  />
                                </div> */}
                              </div>
                            </div>
        <div className="w-100 py-2 d-flex align-items-center f-black">
                              <div className="w-50 f-500">Primary email</div>
                              <div className="w-50">
                                {modalData?.PrimaryEmail}
                                {/* <div
                                  className={` ${
                                    false
                                      ? "input-error"
                                      : ""
                                  }   d-flex align-items-center radius-4 highlight-input  p-2 flex-grow-1 mr-1`}
                                >
                                  <input
                                    type="text"
                                    className={` ${""} bg-transparent flex-grow-1 border-none f-500`}
                                    placeholder={
                                    "Type"
                                    }
                                    // value={invoicePageData?.ContactEmail}
                                    onChange={(e) => {
                                     
                                    }}
                                  />
                                </div> */}
                              </div>
                            </div>

                            <div  className="w-100 my-3" style={{borderTop:"1px solid #EBEDF1"}} />
    
                            <div className="f-12 f-500 f-darkgrey mb-2">Items</div>
{
  modalData?.InvoiceItems?.map((invoiceItem,invoiceItemIndex)=>{
    return( <div className="d-flex align-items-center justify-content-between  f-black mb-2">
    <div>
        <div className="f-500">{invoiceItem?.ItemName} </div>
        <div className="f-12 f-darkgrey">{invoiceItem?.ItemDescription} </div>
    </div>
    <div>{invoiceItem?.CurrencyCode} {invoiceItem?.ItemPrice}</div>

</div>

    )
  })
}

                           
                          





                            <div className="f-12 f-500 f-darkgrey mb-3">Total</div>

                            <div className="d-flex align-items-center justify-content-between f-600 f-black">
                                <div>Total</div>
                                <div>{modalData?.CurrencyCode}  {modalData?.InvoiceItems?.reduce((total, item) => total + item.Price, 0)}</div>

                            </div>

    
    
    
      </SlidingModal.Body>
      <SlidingModal.Footer className="p-3 position-sticky w-100 border-top">
        <div className="d-flex align-items-center justify-content-end">
          <Button className="hg-cancel-btn mr-2">Cancel</Button>
          <Button className="hg-blue-btn"
          disabled={saving}
          onClick={()=>{
            setSaving(true)
            createEditInvoice(modalData).then(()=>{
              refreshData()
              hideModal()
              setSaving(false)
            }).catch(()=>{
              setSaving(false)

            })
          }}
          >{saving?   "Saving.."  :"Save"}</Button>
        </div>
      </SlidingModal.Footer>
    </SlidingModal>
  );
};

export default AddEditSubscriptionBillingModal;
