import React, { useEffect, useState } from "react";
import { getAPIData } from "../../../../Utils/commonApi";
import ImageWithInitials from "../../../../Utils/ImageWithInitials";
import { useAuth } from "../../../context/Auth";
import { DeleteModal } from "../../../DeleteModal/DeleteModal";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import { GreyLoader } from "../../../Theme/APILoaders";
import CreateTaskModal from "../../../PriceCalculator/modals/CreateTaskModal";
import { useDispatch } from "react-redux";
import { setactioncentredata } from "../../../../redux/actionCentre/actions";
import { SecurityAssetsLoader } from "../operationalSecurity/OperationalSecurityAssetsScreen";

const AuditSummary = ({ auditId, setAuditUpdateData = () => { }, auditUpdateData }) => {
  const { authTokens } = useAuth();
  const dispatch = useDispatch();

  const [auditList, setAuditList] = useState([]);
  const [auditDetails, setAuditDetails] = useState([]);
  const [selectedAuditId, setSelectedAuditId] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [loading, setLoading] = useState(false);


  // FAKE DATA ------------------------------------------


  // const FakeActionTaskList = [
  //   {

  //     ObjectId: 4087513,
  //     Title: "Untitled",
  //     Detail: "{\"blocks\":[{\"key\":\"4pou3\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRa",
  //     DueDate: "14/09/2023 00:00",
  //     OwnerName: "Matthew Cruger",
  //     OwnerImage: "No URL",
  //     OwnerInitails: "MC",
  //     OwnerShowInitials: true,
  //     OwnerUserName: "HG_Test_MatthewCruger@mailinator.com",
  //     Module_Text: null,
  //     ModuleLogo: null,

  //     Status_Text: "Not Started",
  //     Status_Enum: 4059402,

  //     Status_Colour: "#4573D2",
  //     ObjId_Section: 0,
  //     Section_Name: null,
  //     Archived: false,
  //     AutoGenTask_Bool: false,
  //     AutoGenTask_Detail: null
  //   }
  // ]

  // const FakeAccreditationVM = {
  //   items: [
  //     {
  //       icon: "secops_file_greentick.svg",
  //       objId_Accreditation: 5234148,
  //       name: "ISO 27001",
  //       status: null,
  //       points: [
  //         "September, 2024",
  //         "UK",
  //         "All"
  //       ],
  //       fileUrl: "https://hguksouthinbound reports.blob.core.windows",
  //       linkUrl: "",
  //       showOnlyAddButton: false,
  //       UpdatedStatus: "New"
  //     },
  //     {
  //       icon: "secops_file_greentick.svg",
  //       objId_Accreditation: 5234149,
  //       name: "Test",
  //       status: null,
  //       points: [
  //         "April, 2024",
  //         "UK",
  //         "All"
  //       ],
  //       fileUrl: "https://hguksouthinbound reports.blob.core.windows",
  //       linkUrl: "ggggggg",
  //       showOnlyAddButton: false,
  //       UpdatedStatus: "Updated"
  //     }
  //   ],
  //   mr: null
  // }

  // const FakeTrainingCert_UserListVM = {
  //   items: [
  //     {
  //       certificateText: "1 certificate",
  //       position: null,
  //       certificates: [
  //         {
  //           objId_Certification: 5234149,
  //           certificationName: "(ISC) 2 CAP",
  //           status: null,
  //           showInitials: false,
  //           initials: "IC",
  //           icon: "ISC2_CAP.webp",
  //           statusIcon: ''
  //         }
  //       ],
  //       UpdatedStatus: "New",
  //       userId: "91a63b51-0985-4a3b-97f2-b380fe133266",
  //       fullName: "Matthew Cruger (You)",
  //       showInitials: true
  //     }
  //   ]
  // }

  const FakeMSPOperationalSecurity = {
    MainSectionText: null,
    MSPSecopsAuditSection_List: [
      {
        SectionText: "Training",
        MSPSecopsAuditQuestion_List: [
          {
            QuestionText: "Are all of your employees trained on basic",
            OldStatus: "No",
            NewStatus: "Yes",
            mx: null
          },
          {
            QuestionText: "Are all of your engineers trained and certi",
            OldStatus: "Unanswered",
            NewStatus: "Yes",
            mr: null
          },
          {
            QuestionText: "Are all of your employees trained on basic",
            OldStatus: "Unanswered",
            NewStatus: "No",
            mx: null
          },
          {
            QuestionText: "Are all of your engineers trained and certi",
            OldStatus: "Unanswered",
            NewStatus: "In Progress",
            mr: null
          },
          {
            QuestionText: "Are all of your employees trained on basic",
            OldStatus: "In Progress",
            NewStatus: "Yes",
            mx: null
          },
          {
            QuestionText: "Are all of your engineers trained and certi",
            OldStatus: "Unanswered",
            NewStatus: "Yes",
            mr: null
          }
        ]
      },
      {
        SectionText: "Test",
        MSPSecopsAuditQuestion_List: [
        ]
      }
    ]
  }


  // FAKE DATA ------------------------------------------


  useEffect(() => {
    setLoading(true)
    if (!auditList?.length)
      getAuditList()
  }, [auditList])


  async function getAuditList() {
    await getAPIData("659", authTokens)
      .then((response) => {
        setAuditList(response?.MSPSecOpsAudit_List);
        setSelectedAuditId(response?.MSPSecOpsAudit_List[0]?.ObjectId);
        if (response?.MSPSecOpsAudit_List[0]?.ObjectId) {
          getAuditDetails(response?.MSPSecOpsAudit_List[0]?.ObjectId);
        }
      })
      .catch((error) => {
        console.log(error);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  async function getAuditDetails(id) {
    setLoading(true);
    await getAPIData("660", authTokens, { optionEnum1: Number(localStorage.getItem("auditId")) })
      .then((response) => {
        setAuditDetails(response);
        setLoading(false);
        setAuditUpdateData({
          auditSummary: response?.AuditSummary,
          startDate: response?.StartDate,
          completionDate: response?.CompletionDate,
          nextAuditDate: response?.NextAuditDate
        })
      })
      .catch((error) => {
        console.log(error);
        CallToast("Something went wrong", false, false, "v2style");
        setLoading(false);
      });
  }
  async function deleteAudit(objectId) {
    await getAPIData(`${784}`, authTokens, {
      optionEnum1: objectId,
      optionEnum2: 0,
    })
      .then((response) => {
        if (response?.Success) {
          CallToast("Deleted Successfully", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };

    return date.toLocaleDateString('en-US', options);
  }

  return (
    <div className="w-100">
      {
        loading ?
          <div className="w-45 mx-auto">
            <SecurityAssetsLoader />
          </div> :
          <div
            className="d-flex justify-content-center"
          >
            <div
              className="p-0 hide-scrollbar"
              style={{ width: "1200px", overflowY: "unset" }}
            >
              {
                <div
                  className="supplier-profiler profilingModal d-flex"
                  id="modal-profiling"
                >
                  <div className="w-100">

                    <div className="d-flex justify-content-center ">

                      <div
                        className={`${"w-100"} d-flex flex-column align-items-center`}
                      >
                        {loading ? (
                          <span className="d-inline-flex align-items-center justify-content-center h-100 w-100">
                            <GreyLoader width={30} />
                            Loading...
                          </span>
                        ) : (
                          <>
                            <div
                              className="d-flex flex-column"
                              style={{
                                width: "640px",
                                gap: "12px",
                                marginTop: '24px',
                                marginBottom: '24px',
                                borderBottom: `1px solid #EBEDF1`,
                              }}
                            >
                              <div className="f-16 f-600">Summary</div>

                              <div
                                className="w-100 d-flex flex-column"
                                style={{ gap: "8px" }}
                              >
                                <div
                                  className="f-500 f-darkgrey"
                                  style={{
                                    marginTop: '4px',
                                  }}
                                >Details</div>
                                <div
                                  className="f-grey"
                                  style={{
                                    gap: "4px",
                                    display: "grid",
                                    gridTemplateColumns: "20% 80%",
                                    alignItems: "center",
                                  }}
                                >
                                  <div>Performed by</div>
                                  <div
                                    className="d-flex"
                                  >
                                    <img
                                      alt=""
                                      width={24}
                                      height={24}
                                      src={auditDetails?.PerformedByLogo}
                                      className=" rounded-full bg-darkgrey mr-2"
                                    />
                                    <div className="w-100">
                                      {auditDetails?.PerformedBy_Text}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="f-grey"
                                  style={{
                                    gap: "4px",
                                    display: "grid",
                                    gridTemplateColumns: "20% 60%",
                                    alignItems: "center",
                                  }}
                                >
                                  <div>Start Date</div>
                                  <div className="w-55  ">
                                    <div
                                      className="d-flex align-items-center hide-focus-outline"
                                      tabIndex={0}
                                    >
                                      <img
                                        alt=""
                                        className="mr-1"
                                        src="/images/attack-surface/date-icon.svg"
                                      />

                                      <input
                                        disabled
                                        tabIndex={-1}
                                        type="text"
                                        placeholder={`Choose date`}
                                        className="border-none pl-2 bg-transparent-imp w-100"
                                        value={formatDate(auditDetails?.StartDate) ?? ""}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="f-grey"
                                  style={{
                                    gap: "4px",
                                    display: "grid",
                                    gridTemplateColumns: "20% 60%",
                                    alignItems: "center",
                                  }}
                                >
                                  <div>Completion Date</div>
                                  <div className="w-55  ">
                                    <div
                                      className="d-flex align-items-center hide-focus-outline"
                                      tabIndex={0}
                                    >
                                      <img
                                        alt=""
                                        className="mr-1"
                                        src="/images/attack-surface/date-icon.svg"
                                      />

                                      <input
                                        disabled
                                        tabIndex={-1}
                                        type="text"
                                        placeholder={`Choose date`}
                                        className="border-none pl-2 bg-transparent-imp w-100"
                                        value={formatDate(auditDetails?.CompletionDate) ?? ""}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex align-items-center w-100">
                                  <div
                                    className="f-grey"
                                    style={{
                                      gap: "4px",
                                      display: "grid",
                                      gridTemplateColumns: "48% 68%",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div>Next Audit</div>
                                    <div className="w-55  ">
                                      <div
                                        className="d-flex align-items-center hide-focus-outline"
                                        tabIndex={0}
                                      >
                                        <img
                                          alt=""
                                          className="mr-1"
                                          src="/images/attack-surface/date-icon.svg"
                                        />

                                        <input
                                          disabled
                                          tabIndex={-1}
                                          type="text"
                                          placeholder={`Choose date`}
                                          className="border-none pl-2 bg-transparent-imp w-100"
                                          value={formatDate(auditDetails?.NextAuditDate) ?? ""}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="f-500 f-12 pointer"
                                    style={{
                                      color: "#616778"
                                    }}
                                    onClick={() => {
                                      dispatch(
                                        setactioncentredata({
                                          refreshCreateTaskModalModule: true,
                                        })
                                      );
                                      const drag = document.getElementById("create-task-modal");
                                      drag.style.display = "block";
                                    }}
                                  >
                                    <img
                                      width={16}
                                      alt=""
                                      src="/images/msp/secops-create-task-icon.svg"
                                      className="pr-1"
                                    />
                                    Create Task
                                  </div>
                                </div>

                              </div>

                              <div className="w-100 d-flex flex-column">
                                <div
                                  className="f-500 f-darkgrey"
                                  style={{
                                    marginTop: '6px'
                                  }}
                                >
                                  Audit Summary
                                </div>
                                <textarea
                                  placeholder="Type your message here..."
                                  className="w-100 p-2 border-grey radius-4 mt-2"
                                  rows={6}
                                  value={auditDetails?.AuditSummary}
                                  onChange={(e) => {
                                    setAuditDetails({
                                      ...auditDetails,
                                      AuditSummary: e.target.value,
                                    });
                                    let updatedData = auditUpdateData;
                                    updatedData = { ...updatedData, auditSummary: e.target.value }
                                    setAuditUpdateData(updatedData)

                                  }}
                                ></textarea>
                              </div>


                              {
                                auditDetails?.ActionTaskList?.length !== 0 ?
                                  // FakeActionTaskList ?
                                  <div className="w-100 d-flex flex-column">
                                    <div
                                      className="f-500 f-darkgrey"
                                      style={{
                                        marginTop: '6px'
                                      }}
                                    >
                                      Actions
                                    </div>
                                    <div
                                      className="bg-grey"
                                      style={{
                                        borderRadius: "8px",
                                        marginTop: "12px",
                                        marginBottom: '24px'
                                      }}
                                    >

                                      {/* {FakeActionTaskList?.map( */}
                                      {auditDetails?.ActionTaskList?.map(
                                        (action, index) => {
                                          return (
                                            <div
                                              className={`d-flex flex-row `}
                                              style={{
                                                justifyContent: "space-between",
                                                padding: "13px 16px 13px 16px",
                                                borderBottom:
                                                  index ===
                                                    auditDetails?.ActionTaskList?.length - 2
                                                    ? "1px solid #ebedf1"
                                                    : "none",
                                              }}
                                            >
                                              <div className="d-flex">
                                                <img
                                                  alt=""
                                                  width={16}
                                                  src="/images/empty-circle.svg"
                                                  className=" rounded-full  mr-2"
                                                />
                                                <div>{action.Title}</div>
                                              </div>
                                              <div className="d-flex f-grey f-12">
                                                <div>{action?.DueDate}</div>
                                                <div className="ml-2">
                                                  {action?.OwnerInitails ? (
                                                    <ImageWithInitials
                                                      initials={action?.OwnerInitails}
                                                      width={16}
                                                      background={"purple"}
                                                    />
                                                  ) : (
                                                    <img
                                                      alt=""
                                                      src={action?.OwnerImage}
                                                      style={{
                                                        width: "20px",
                                                        height: "20px",
                                                        borderRadius: "10px",
                                                        objectFit: "contain",
                                                      }}
                                                    />
                                                  )}
                                                </div>

                                                <div
                                                  className={`ml-2 px-2 rounded-full d-flex align-items-center f-12 f-500 pointer `}
                                                  style={{
                                                    color: action.Status_Colour,
                                                    backgroundColor: "#EDEDED",
                                                    display: "inline-flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      width: "8px",
                                                      height: "8px",
                                                      borderRadius: "50%",
                                                      backgroundColor: action.Status_Colour,
                                                      marginRight: "8px",
                                                    }}
                                                  />
                                                  {action.Status_Text}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                  :
                                  <div className="d-flex justify-content-center align-items-lg-center py-4 rounded-sm"
                                    style={{
                                      backgroundColor: '#F6F8FB',
                                    }}
                                  >
                                    Actions have not been changed or added
                                  </div>
                              }

                            </div>

                            <div
                              className="d-flex flex-column"
                              style={{
                                width: "640px",
                                marginBottom: '24px',
                              }}
                            >
                              <div className="f-16 f-600">Audit results</div>

                              {/* Accreditation Block */}

                              <>
                                <div
                                  style={{
                                    marginTop: '24px',

                                  }}
                                >
                                  <div className="f-14 f-600"
                                    style={{
                                      marginBottom: '8px',
                                    }}
                                  >
                                    Accreditations
                                  </div>
                                </div>
                                {
                                  auditDetails?.AccreditationVM?.items?.length !== 0 ?
                                    // FakeAccreditationVM?.items?.length !== 0 ?
                                    <div style={{ backgroundColor: '#F6F8FB', borderRadius: '8px' }}>
                                      {
                                        auditDetails?.AccreditationVM?.items?.map((accreditationItem, index) => {
                                          // FakeAccreditationVM?.items?.map((accreditationItem, index) => {
                                          return (
                                            <div
                                              className={`border-item d-flex align-items-center justify-content-between p-3`}
                                              style={{
                                                borderBottom: `${index < auditDetails?.AccreditationVM?.items?.length - 1 ? "1px solid #EBEDF1" : 'none'}`
                                                // borderBottom: `${index < FakeAccreditationVM?.items?.length - 1 ? "1px solid #EBEDF1" : 'none'}`
                                              }}
                                            >
                                              <div className="d-flex align-items-center">
                                                <img
                                                  width={33}
                                                  src={`/images/msp/${accreditationItem?.icon}`}
                                                  alt=""
                                                  className="mr-3 rounded-full"
                                                />
                                                <div>
                                                  <div>
                                                    <span className="f-500 f-black">
                                                      {accreditationItem?.name}
                                                    </span>
                                                    &nbsp; &nbsp;
                                                    {accreditationItem?.status && (
                                                      <span
                                                        style={{
                                                          color: accreditationItem?.status?.foreColor,
                                                        }}
                                                      >
                                                        {accreditationItem?.status?.text}
                                                      </span>
                                                    )}
                                                  </div>
                                                  <div className="f-darkgrey">
                                                    {accreditationItem?.points?.map((item, idx) => (
                                                      <span>
                                                        {idx > 0 ? " • " : ""} {item}
                                                      </span>
                                                    ))}{" "}
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="d-flex">
                                                <div className="d-flex align-items-center">
                                                  {accreditationItem?.linkUrl && (
                                                    <div className="d-flex align-items-center f-12 f-darkgrey mr-2">
                                                      <img
                                                        src={"/images/msp/msp-cert-link-icon.svg"}
                                                        alt=""
                                                        className="mr-2  "
                                                      />
                                                      Link
                                                    </div>
                                                  )}
                                                  {accreditationItem?.fileUrl && (
                                                    <div className="d-flex align-items-center f-12 f-darkgrey mr-2">
                                                      <img
                                                        src={"/images/msp/msp-cert-file-icon.svg"}
                                                        alt=""
                                                        className="mr-2  "
                                                      />
                                                      File
                                                    </div>
                                                  )}
                                                </div>

                                                <div
                                                  className={`ml-2 px-2 rounded-full d-flex align-items-center f-12 f-500 pointer `}
                                                  style={{
                                                    color: `${accreditationItem?.UpdatedStatus == 'New' ? '#008FD4' : '#616778'}`,
                                                    backgroundColor: `${accreditationItem?.UpdatedStatus == 'New' ? '#D6EDF8' : '#EBEDF1'}`,
                                                    display: "inline-flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  {accreditationItem?.UpdatedStatus}
                                                </div>
                                              </div>

                                            </div>
                                          )
                                        })
                                      }
                                    </div> : <div className="d-flex justify-content-center align-items-lg-center py-4 rounded-sm"
                                      style={{
                                        backgroundColor: '#F6F8FB',
                                      }}
                                    >
                                      Accreditations have not been changed or added
                                    </div>
                                }
                              </>

                              {/* Certifications Block */}

                              <>
                                <div className="f-14 f-600"
                                  style={{
                                    marginTop: '24px',
                                    marginBottom: '8px'
                                  }}
                                >
                                  Training Certifications
                                </div>
                                {
                                  auditDetails?.TrainingCert_UserListVM?.items?.length !== 0 ?
                                    // FakeTrainingCert_UserListVM?.items?.length !== 0 ? 
                                    <div style={{ backgroundColor: '#F6F8FB', borderRadius: '8px' }}>
                                      {
                                        auditDetails?.TrainingCert_UserListVM?.items?.map((certificateItem, index) => {
                                          // FakeTrainingCert_UserListVM?.items?.map((certificateItem, index) => {
                                          return (
                                            <div
                                              className={`border-item d-flex align-items-center justify-content-between p-3`}
                                              style={{
                                                borderBottom: `${index < auditDetails?.TrainingCert_UserListVM?.items?.length - 1 ? "1px solid #EBEDF1" : 'none'}`
                                                // borderBottom: `${index < FakeTrainingCert_UserListVM?.items?.length - 1 ? "1px solid #EBEDF1" : 'none'}`
                                              }}
                                            >
                                              <div className="d-flex align-items-center">
                                                {certificateItem?.showInitials ? <div className="mr-2">
                                                  <ImageWithInitials
                                                    initials={certificateItem?.initials}
                                                    width={32}
                                                    background={"purple"}
                                                  />
                                                </div>
                                                  : (
                                                    <img
                                                      src={certificateItem?.avatar}
                                                      alt=""
                                                      width={32}
                                                      className="mr-2 rounded-full"
                                                    />
                                                  )}
                                                <div>
                                                  <div>
                                                    <span className="f-500 f-black">
                                                      {certificateItem?.fullName}
                                                    </span>
                                                    &nbsp; &nbsp;
                                                  </div>
                                                  <div className="f-darkgrey">
                                                    {certificateItem?.certificateText}{" "}
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="d-flex">

                                                {certificateItem?.certificates?.length > 0 ? (
                                                  <div className="secops_training_certificate_overlap_parent mr-3">
                                                    {certificateItem?.certificates
                                                      ?.slice(0, 8)
                                                      .map((certificate, certificateIndex) => {
                                                        return (
                                                          <>
                                                            {certificate?.showInitials ? (
                                                              <div className="rounded-full w-fit-content h-fit-content position-relative overlap_child">
                                                                <ImageWithInitials
                                                                  initials={certificate?.initials}
                                                                  width={25}
                                                                  background={"purple"}
                                                                />
                                                                {certificate?.statusIcon && (
                                                                  <img
                                                                    width={13}
                                                                    src={
                                                                      "/images/msp/" +
                                                                      certificate?.statusIcon
                                                                    }
                                                                    className="rounded-full overlapping_icon"
                                                                    alt=""
                                                                  />
                                                                )}
                                                              </div>
                                                            ) : (
                                                              <div className="rounded-full w-fit-content h-fit-content position-relative overlap_child">
                                                                <img
                                                                  width={25}
                                                                  src={`/images/msp/framework-certificates/${certificate?.icon}`}
                                                                  className="rounded-full"
                                                                  alt=""
                                                                  onError={({ currentTarget }) => {
                                                                    currentTarget.onerror = null; // prevents looping
                                                                    currentTarget.src =
                                                                      "/images/msp/default-cert-icon.svg";
                                                                  }}
                                                                />
                                                                {certificate?.statusIcon && (
                                                                  <img
                                                                    width={13}
                                                                    src={
                                                                      "/images/msp/" +
                                                                      certificate?.statusIcon
                                                                    }
                                                                    className="rounded-full overlapping_icon"
                                                                    alt=""
                                                                  />
                                                                )}
                                                              </div>
                                                            )}
                                                          </>
                                                        );
                                                      })}
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}

                                                <div
                                                  className={`ml-2 px-2 rounded-full d-flex align-items-center f-12 f-500 pointer `}
                                                  style={{
                                                    color: `${certificateItem?.UpdatedStatus == 'New' ? '#008FD4' : '#616778'}`,
                                                    backgroundColor: `${certificateItem?.UpdatedStatus == 'New' ? '#D6EDF8' : '#EBEDF1'}`,
                                                    display: "inline-flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  {certificateItem?.UpdatedStatus}
                                                </div>
                                              </div>

                                            </div>
                                          )
                                        })
                                      }
                                    </div> : <div className="d-flex justify-content-center align-items-lg-center py-4 rounded-sm"
                                      style={{
                                        backgroundColor: '#F6F8FB',
                                      }}
                                    >
                                      Certifications have not been changed or added
                                    </div>
                                }
                              </>

                              {/* Frameworks Block */}

                              <>
                                <div className="f-14 f-600"
                                  style={{
                                    marginTop: '24px',
                                    marginBottom: '8px'
                                  }}
                                >
                                  Frameworks
                                </div>

                                {/* Security Frameworks Block */}
                                <>
                                  <div className="f-14 f-500"
                                    style={{
                                      marginBottom: '8px'
                                    }}
                                  >
                                    {auditDetails?.SecurityFrameworks?.FrameworkGroupList[0]?.Name} Frameworks
                                    {/* Security Frameworks */}
                                  </div>

                                  {
                                    auditDetails?.SecurityFrameworks?.FrameworkGroupList[0]?.FrameworkList?.length !== 0 ?
                                      // FakeTrainingCert_UserListVM?.items?.length !== 0 ? 
                                      <div style={{ backgroundColor: '#F6F8FB', borderRadius: '8px' }}>
                                        {
                                          auditDetails?.SecurityFrameworks?.FrameworkGroupList[0]?.FrameworkList?.map((frameworkItem, index) => {
                                            // FakeTrainingCert_UserListVM?.items?.map((certificateItem, index) => {
                                            return (
                                              <div
                                                className={`border-item d-flex align-items-center justify-content-between p-3`}
                                                style={{
                                                  borderBottom: `${index < auditDetails?.TrainingCert_UserListVM?.items?.length - 1 ? "1px solid #EBEDF1" : 'none'}`
                                                  // borderBottom: `${index < FakeTrainingCert_UserListVM?.items?.length - 1 ? "1px solid #EBEDF1" : 'none'}`
                                                }}
                                              >
                                                <div className="d-flex align-items-center">
                                                  <div>
                                                    <div>
                                                      <span className="f-500 f-black">
                                                        {frameworkItem?.Abbreviation}
                                                      </span>
                                                      &nbsp; &nbsp;
                                                    </div>
                                                    <div className="f-darkgrey">
                                                      {frameworkItem?.Name}{" "}
                                                    </div>
                                                  </div>
                                                </div>

                                                {!frameworkItem?.CyberFrameworkAudit && (
                                                  <div className="d-flex align-items-center">
                                                    <>
                                                      <div
                                                        className="mr-4 p-2 f-grey f-12 f-500"
                                                      >
                                                        <img
                                                          src="/images/attack-surface/shown-icon.svg"
                                                          alt=""
                                                          className="pointer mr-2"
                                                        />{" "}
                                                        <span className="pt-3">Show</span>
                                                      </div>
                                                      <img
                                                        alt=""
                                                        src="/images/actioncenter/plusgrey.svg"
                                                      />
                                                    </>
                                                  </div>
                                                )}

                                                <div className="d-flex">
                                                  {frameworkItem?.CyberFrameworkAudit && (
                                                    <div className="d-flex align-items-center">
                                                      <div className="f-12 f-darkgrey mr-3 w-max-content">
                                                        <div>
                                                          <>
                                                            Last audit:{" "}
                                                            {frameworkItem?.CyberFrameworkAudit
                                                              ?.LastAudit !== "NA" ? frameworkItem?.CyberFrameworkAudit
                                                              ?.LastAudit
                                                              : "Not Selected"}
                                                          </>
                                                          {/* )} */}
                                                        </div>
                                                        <div>
                                                          <>
                                                            Next audit:{" "}
                                                            {frameworkItem?.CyberFrameworkAudit
                                                              ?.NextAudit !== 'NA'
                                                              ? frameworkItem?.CyberFrameworkAudit
                                                                ?.NextAudit
                                                              : "Not Selected"}
                                                          </>
                                                          {/* )} */}
                                                        </div>
                                                      </div>
                                                      <img
                                                        src="/images/attack-surface/chevron-right.svg"
                                                        alt=""
                                                        className=""
                                                      />
                                                    </div>
                                                  )}

                                                  <div
                                                    className={`ml-2 px-2 rounded-full d-flex align-items-center f-12 f-500 pointer `}
                                                    style={{
                                                      color: `${frameworkItem?.UpdatedStatus == 'New' ? '#008FD4' : '#616778'}`,
                                                      backgroundColor: `${frameworkItem?.UpdatedStatus == 'New' ? '#D6EDF8' : '#EBEDF1'}`,
                                                      display: "inline-flex",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    {frameworkItem?.UpdatedStatus}
                                                  </div>
                                                </div>

                                              </div>
                                            )
                                          })
                                        }
                                      </div> : <div className="d-flex justify-content-center align-items-lg-center py-4 rounded-sm"
                                        style={{
                                          backgroundColor: '#F6F8FB',
                                        }}
                                      >
                                        Security Frameworks have not been changed or added
                                      </div>
                                  }
                                </>

                                {/* Data Privacy Frameworks Block */}
                                <>
                                  <div className="f-14 f-500"
                                    style={{
                                      marginTop: '8px',
                                      marginBottom: '8px'
                                    }}
                                  >
                                    {auditDetails?.DataPrivacyFrameworks?.FrameworkGroupList[0]?.Name} Frameworks
                                  </div>

                                  {
                                    auditDetails?.DataPrivacyFrameworks?.FrameworkGroupList[0]?.FrameworkList?.length !== 0 ?
                                      // FakeTrainingCert_UserListVM?.items?.length !== 0 ? 
                                      <div style={{ backgroundColor: '#F6F8FB', borderRadius: '8px' }}>
                                        {
                                          auditDetails?.DataPrivacyFrameworks?.FrameworkGroupList[0]?.FrameworkList?.map((frameworkItem, index) => {
                                            // FakeTrainingCert_UserListVM?.items?.map((certificateItem, index) => {
                                              return (
                                                <div
                                                  className={`border-item d-flex align-items-center justify-content-between p-3`}
                                                  style={{
                                                    borderBottom: `${index < auditDetails?.TrainingCert_UserListVM?.items?.length - 1 ? "1px solid #EBEDF1" : 'none'}`
                                                    // borderBottom: `${index < FakeTrainingCert_UserListVM?.items?.length - 1 ? "1px solid #EBEDF1" : 'none'}`
                                                  }}
                                                >
                                                  <div className="d-flex align-items-center">
                                                    <div>
                                                      <div>
                                                        <span className="f-500 f-black">
                                                          {frameworkItem?.Abbreviation}
                                                        </span>
                                                        &nbsp; &nbsp;
                                                      </div>
                                                      <div className="f-darkgrey">
                                                        {frameworkItem?.Name}{" "}
                                                      </div>
                                                    </div>
                                                  </div>
  
                                                  {!frameworkItem?.CyberFrameworkAudit && (
                                                    <div className="d-flex align-items-center">
                                                      <>
                                                        <div
                                                          className="mr-4 p-2 f-grey f-12 f-500"
                                                        >
                                                          <img
                                                            src="/images/attack-surface/shown-icon.svg"
                                                            alt=""
                                                            className="pointer mr-2"
                                                          />{" "}
                                                          <span className="pt-3">Show</span>
                                                        </div>
                                                        <img
                                                          alt=""
                                                          src="/images/actioncenter/plusgrey.svg"
                                                        />
                                                      </>
                                                    </div>
                                                  )}
  
                                                  <div className="d-flex">
                                                    {frameworkItem?.CyberFrameworkAudit && (
                                                      <div className="d-flex align-items-center">
                                                        <div className="f-12 f-darkgrey mr-3 w-max-content">
                                                          <div>
                                                            <>
                                                              Last audit:{" "}
                                                              {frameworkItem?.CyberFrameworkAudit
                                                                ?.LastAudit !== "NA" ? frameworkItem?.CyberFrameworkAudit
                                                                ?.LastAudit
                                                                : "Not Selected"}
                                                            </>
                                                            {/* )} */}
                                                          </div>
                                                          <div>
                                                            <>
                                                              Next audit:{" "}
                                                              {frameworkItem?.CyberFrameworkAudit
                                                                ?.NextAudit !== 'NA'
                                                                ? frameworkItem?.CyberFrameworkAudit
                                                                  ?.NextAudit
                                                                : "Not Selected"}
                                                            </>
                                                            {/* )} */}
                                                          </div>
                                                        </div>
                                                        <img
                                                          src="/images/attack-surface/chevron-right.svg"
                                                          alt=""
                                                          className=""
                                                        />
                                                      </div>
                                                    )}
  
                                                    <div
                                                      className={`ml-2 px-2 rounded-full d-flex align-items-center f-12 f-500 pointer `}
                                                      style={{
                                                        color: `${frameworkItem?.UpdatedStatus == 'New' ? '#008FD4' : '#616778'}`,
                                                        backgroundColor: `${frameworkItem?.UpdatedStatus == 'New' ? '#D6EDF8' : '#EBEDF1'}`,
                                                        display: "inline-flex",
                                                        alignItems: "center",
                                                      }}
                                                    >
                                                      {frameworkItem?.UpdatedStatus}
                                                    </div>
                                                  </div>
  
                                                </div>
                                              )
                                            // return (
                                            //   <div
                                            //     className={`border-item d-flex align-items-center justify-content-between p-3`}
                                            //     style={{
                                            //       borderBottom: `${index < auditDetails?.TrainingCert_UserListVM?.items?.length - 1 ? "1px solid #EBEDF1" : 'none'}`
                                            //       // borderBottom: `${index < FakeTrainingCert_UserListVM?.items?.length - 1 ? "1px solid #EBEDF1" : 'none'}`
                                            //     }}
                                            //   >
                                            //     <div className="d-flex align-items-center">
                                            //       <img
                                            //         width={33}
                                            //         src={`/images/msp/${certificateItem?.icon}`}
                                            //         alt=""
                                            //         className="mr-3 rounded-full"
                                            //       />
                                            //       <div>
                                            //         <div>
                                            //           <span className="f-500 f-black">
                                            //             {certificateItem?.fullName}
                                            //           </span>
                                            //           &nbsp; &nbsp;
                                            //         </div>
                                            //         <div className="f-darkgrey">
                                            //           {certificateItem?.certificateText}{" "}
                                            //         </div>
                                            //       </div>
                                            //     </div>

                                            //     <div className="d-flex">
                                            //       <div className="d-flex align-items-center">
                                            //         {certificateItem?.linkUrl && (
                                            //           <div className="d-flex align-items-center f-12 f-darkgrey mr-2">
                                            //             <img
                                            //               src={"/images/msp/msp-cert-link-icon.svg"}
                                            //               alt=""
                                            //               className="mr-2  "
                                            //             />
                                            //             Link
                                            //           </div>
                                            //         )}
                                            //         {certificateItem?.fileUrl && (
                                            //           <div className="d-flex align-items-center f-12 f-darkgrey mr-2">
                                            //             <img
                                            //               src={"/images/msp/msp-cert-file-icon.svg"}
                                            //               alt=""
                                            //               className="mr-2  "
                                            //             />
                                            //             File
                                            //           </div>
                                            //         )}
                                            //       </div>

                                            //       <div
                                            //         className={`ml-2 px-2 rounded-full d-flex align-items-center f-12 f-500 pointer `}
                                            //         style={{
                                            //           color: `${certificateItem?.UpdatedStatus == 'New' ? '#008FD4' : '#616778'}`,
                                            //           backgroundColor: `${certificateItem?.UpdatedStatus == 'New' ? '#D6EDF8' : '#EBEDF1'}`,
                                            //           display: "inline-flex",
                                            //           alignItems: "center",
                                            //         }}
                                            //       >
                                            //         {certificateItem?.UpdatedStatus}
                                            //       </div>
                                            //     </div>

                                            //   </div>
                                            // )
                                          })
                                        }
                                      </div> : <div className="d-flex justify-content-center align-items-lg-center py-4 rounded-sm"
                                        style={{
                                          backgroundColor: '#F6F8FB',
                                        }}
                                      >
                                        Security Frameworks have not been changed or added
                                      </div>
                                  }
                                </>
                              </>

                              {/* MSP Operational Security Block */}

                              <>
                                <div className="f-14 f-600"
                                  style={{
                                    marginTop: '24px',
                                  }}
                                >
                                  MSP Operational Security
                                </div>

                                {
                                  auditDetails?.MSPOperationalSecurity?.MSPSecopsAuditSection_List?.length !== 0 ?

                                    // FakeTrainingCert_UserListVM?.items?.length !== 0 ? 
                                    <div >
                                      {
                                        auditDetails?.MSPOperationalSecurity?.MSPSecopsAuditSection_List?.map((questionBlock, index) => {
                                          // FakeTrainingCert_UserListVM?.items?.map((certificateItem, index) => {
                                          return (
                                            <>
                                              {
                                                questionBlock?.MSPSecopsAuditQuestion_List?.length !== 0 ? <>
                                                  <div className="f-14 f-500"
                                                    style={{
                                                      marginTop: "8px",
                                                      marginBottom: '8px'
                                                    }}
                                                  >
                                                    {questionBlock?.SectionText}
                                                  </div>
                                                  <div style={{ backgroundColor: '#F6F8FB', borderRadius: '8px' }}>
                                                    {
                                                      questionBlock?.MSPSecopsAuditQuestion_List?.map((question, index) => {
                                                        return (
                                                          <div
                                                            className={`border-item d-flex align-items-center justify-content-between p-3`}
                                                            style={{
                                                              borderBottom: `${index < questionBlock?.MSPSecopsAuditQuestion_List?.length - 1 ? "1px solid #EBEDF1" : 'none'}`
                                                            }}
                                                          >
                                                            <div className="d-flex align-items-center">
                                                              <div>
                                                                <div>
                                                                  <span className="f-400 f-14 f-black">
                                                                    {question?.QuestionText}
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </div>

                                                            <div className="d-flex">
                                                              <div className="f-400 f-14"
                                                                style={{
                                                                  color: "#9499AA"
                                                                }}
                                                              >
                                                                {question?.OldStatus}
                                                              </div>
                                                              <img
                                                                alt=""
                                                                src="/images/actioncenter/arrowdown.svg"
                                                                className="mx-2"
                                                                style={{
                                                                  transform: "rotate(270deg)"
                                                                }}
                                                              />
                                                              <div className="f-400 f-14"
                                                                style={{
                                                                  color: `${question?.NewStatus == 'No' ? "#F36060" : question?.NewStatus == "In Progress" ? "#008FD4" : "#9499AA"}`
                                                                }}
                                                              >
                                                                {question?.NewStatus}
                                                              </div>
                                                            </div>

                                                          </div>
                                                        )
                                                      })
                                                    }
                                                  </div>
                                                </> : <></>
                                              }
                                            </>
                                          )
                                        })
                                      }
                                    </div> : <div className="d-flex justify-content-center align-items-lg-center py-4 rounded-sm"
                                      style={{
                                        backgroundColor: '#F6F8FB',
                                      }}
                                    >
                                      Security Frameworks have not been changed or added
                                    </div>
                                }


                                {/* {
                                  FakeMSPOperationalSecurity?.MSPSecopsAuditSection_List?.map((questionBlock) => {
                                    return (
                                      <>
                                        <div className="f-14 f-500"
                                          style={{
                                            marginTop: "8px",
                                            marginBottom: '8px'
                                          }}
                                        >
                                          {questionBlock?.SectionText}
                                        </div>
                                        {
                                          questionBlock?.MSPSecopsAuditQuestion_List?.length !== 0 ?
                                            <div style={{ backgroundColor: '#F6F8FB', borderRadius: '8px' }}>
                                              {
                                                questionBlock?.MSPSecopsAuditQuestion_List.map((question, index) => {
                                                  return (
                                                    <div
                                                      className={`border-item d-flex align-items-center justify-content-between p-3`}
                                                      style={{
                                                        borderBottom: `${index < questionBlock?.MSPSecopsAuditQuestion_List?.length - 1 ? "1px solid #EBEDF1" : 'none'}`
                                                      }}
                                                    >
                                                      <div className="d-flex align-items-center">
                                                        <div>
                                                          <div>
                                                            <span className="f-400 f-14 f-black">
                                                              {question?.QuestionText}
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="d-flex">
                                                        <div className="f-400 f-14"
                                                          style={{
                                                            color: "#9499AA"
                                                          }}
                                                        >
                                                          {question?.OldStatus}
                                                        </div>
                                                        <img
                                                          alt=""
                                                          src="/images/actioncenter/arrowdown.svg"
                                                          className="mx-2"
                                                          style={{
                                                            transform: "rotate(270deg)"
                                                          }}
                                                        />
                                                        <div className="f-400 f-14"
                                                          style={{
                                                            color: `${question?.NewStatus == 'No' ? "#F36060" : question?.NewStatus == "In Progress" ? "#008FD4" : "#9499AA"}`
                                                          }}
                                                        >
                                                          {question?.NewStatus}
                                                        </div>
                                                      </div>

                                                    </div>
                                                  )
                                                })
                                              }
                                            </div> : <div className="d-flex justify-content-center align-items-lg-center py-4 rounded-sm"
                                              style={{
                                                backgroundColor: '#F6F8FB',
                                              }}
                                            >
                                              {questionBlock?.SectionText} have not been changed or added
                                            </div>
                                        }
                                      </>
                                    )
                                  })
                                } */}
                              </>


                              {/* MSP Security Processes & Procedures Block */}

                              <>
                                <div className="f-14 f-600"
                                  style={{
                                    marginTop: '24px',
                                  }}
                                >
                                  MSP Security Processes & Procedures
                                </div>

                                {
                                  auditDetails?.MSPSecurityProcesses_List?.length !== 0 ?

                                    // FakeTrainingCert_UserListVM?.items?.length !== 0 ? 
                                    <div >
                                      {
                                        auditDetails?.MSPSecurityProcesses_List?.map((questionBlock, index) => {
                                          // FakeTrainingCert_UserListVM?.items?.map((certificateItem, index) => {
                                          return (
                                            <>
                                              {
                                                questionBlock?.MSPSecopsAuditSection_List?.length !== 0 ? <>
                                                  {/* <div className="f-14 f-500"
                                                    style={{
                                                      marginTop: "8px",
                                                      marginBottom: '8px'
                                                    }}
                                                  >
                                                    {questionBlock?.MainSectionText}
                                                  </div> */}
                                                  <div>
                                                    {
                                                      questionBlock?.MSPSecopsAuditSection_List?.map((questions, indexBlock) => {
                                                        return (
                                                          <>
                                                            {
                                                              questions?.MSPSecopsAuditQuestion_List?.length !== 0 ? <>
                                                                {/* <div className="f-14 f-500"
                                                              style={{
                                                                marginTop: "8px",
                                                                marginBottom: '8px'
                                                              }}
                                                            >
                                                              {questions?.SectionText}
                                                            </div> */}
                                                                <div >
                                                                  {
                                                                    questions?.MSPSecopsAuditQuestion_List?.map((question, index) => {
                                                                      return (
                                                                        <>
                                                                          {indexBlock == 0 &&
                                                                            <div className="f-14 f-500"
                                                                              style={{
                                                                                marginTop: "8px",
                                                                                marginBottom: '8px'
                                                                              }}
                                                                            >
                                                                              {questionBlock?.MainSectionText}
                                                                            </div>
                                                                          }
                                                                          {index == 0 &&
                                                                            <div className="f-14 f-400"
                                                                              style={{
                                                                                marginTop: "8px",
                                                                                marginBottom: '8px'
                                                                              }}
                                                                            >
                                                                              {questions?.SectionText}
                                                                            </div>
                                                                          }
                                                                          <div
                                                                            className={`border-item d-flex align-items-center justify-content-between p-3`}
                                                                            style={{
                                                                              backgroundColor: '#F6F8FB', borderRadius: '8px',
                                                                              borderBottom: `${index < questions?.MSPSecopsAuditQuestion_List?.length - 1 ? "1px solid #EBEDF1" : 'none'}`
                                                                            }}
                                                                          >
                                                                            <div className="d-flex align-items-center">
                                                                              <div>
                                                                                <div>
                                                                                  <span className="f-400 f-14 f-black">
                                                                                    {question?.QuestionText}
                                                                                  </span>
                                                                                </div>
                                                                              </div>
                                                                            </div>

                                                                            <div className="d-flex">
                                                                              <div className="f-400 f-14"
                                                                                style={{
                                                                                  color: "#9499AA"
                                                                                }}
                                                                              >
                                                                                {question?.OldStatus}
                                                                              </div>
                                                                              <img
                                                                                alt=""
                                                                                src="/images/actioncenter/arrowdown.svg"
                                                                                className="mx-2"
                                                                                style={{
                                                                                  transform: "rotate(270deg)"
                                                                                }}
                                                                              />
                                                                              <div className="f-400 f-14"
                                                                                style={{
                                                                                  color: `${question?.NewStatus == 'No' ? "#F36060" : question?.NewStatus == "In Progress" ? "#008FD4" : "#9499AA"}`
                                                                                }}
                                                                              >
                                                                                {question?.NewStatus}
                                                                              </div>
                                                                            </div>

                                                                          </div>
                                                                        </>
                                                                      )
                                                                    })
                                                                  }
                                                                </div>
                                                              </> : <></>
                                                            }
                                                          </>
                                                        )
                                                      })
                                                    }
                                                  </div>
                                                </> : <></>
                                              }
                                            </>
                                          )
                                        })
                                      }
                                    </div> : <div className="d-flex justify-content-center align-items-lg-center py-4 rounded-sm"
                                      style={{
                                        backgroundColor: '#F6F8FB',
                                      }}
                                    >
                                      Security Frameworks have not been changed or added
                                    </div>
                                }


                                {/* {
                                  FakeMSPOperationalSecurity?.MSPSecopsAuditSection_List?.map((questionBlock) => {
                                    return (
                                      <>
                                        <div className="f-14 f-500"
                                          style={{
                                            marginTop: "8px",
                                            marginBottom: '8px'
                                          }}
                                        >
                                          {questionBlock?.SectionText}
                                        </div>
                                        {
                                          questionBlock?.MSPSecopsAuditQuestion_List?.length !== 0 ?
                                            <div style={{ backgroundColor: '#F6F8FB', borderRadius: '8px' }}>
                                              {
                                                questionBlock?.MSPSecopsAuditQuestion_List.map((question, index) => {
                                                  return (
                                                    <div
                                                      className={`border-item d-flex align-items-center justify-content-between p-3`}
                                                      style={{
                                                        borderBottom: `${index < questionBlock?.MSPSecopsAuditQuestion_List?.length - 1 ? "1px solid #EBEDF1" : 'none'}`
                                                      }}
                                                    >
                                                      <div className="d-flex align-items-center">
                                                        <div>
                                                          <div>
                                                            <span className="f-400 f-14 f-black">
                                                              {question?.QuestionText}
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="d-flex">
                                                        <div className="f-400 f-14"
                                                          style={{
                                                            color: "#9499AA"
                                                          }}
                                                        >
                                                          {question?.OldStatus}
                                                        </div>
                                                        <img
                                                          alt=""
                                                          src="/images/actioncenter/arrowdown.svg"
                                                          className="mx-2"
                                                          style={{
                                                            transform: "rotate(270deg)"
                                                          }}
                                                        />
                                                        <div className="f-400 f-14"
                                                          style={{
                                                            color: `${question?.NewStatus == 'No' ? "#F36060" : question?.NewStatus == "In Progress" ? "#008FD4" : "#9499AA"}`
                                                          }}
                                                        >
                                                          {question?.NewStatus}
                                                        </div>
                                                      </div>

                                                    </div>
                                                  )
                                                })
                                              }
                                            </div> : <div className="d-flex justify-content-center align-items-lg-center py-4 rounded-sm"
                                              style={{
                                                backgroundColor: '#F6F8FB',
                                              }}
                                            >
                                              {questionBlock?.SectionText} have not been changed or added
                                            </div>
                                        }
                                      </>
                                    )
                                  })
                                } */}
                              </>

                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <DeleteModal
                    show={showDeleteModal}
                    hideModal={() => setShowDeleteModal(false)}
                    deleteMessage={"You are about to delete an audit."}
                    deleteType={"deleteSentQuestionnaire"}
                    deleteSentQuestionnaire={() => {
                      deleteAudit(selectedAuditId);
                    }}
                  />
                  <CreateTaskModal
                    isAudit={true}
                  />
                </div>
              }
            </div>
          </div>
      }
    </div>
  );
};

export default AuditSummary;
