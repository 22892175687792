import React from 'react'
import { WhiteLoader } from '../Theme/APILoaders';
import { Button, Col, Dropdown, Row } from 'react-bootstrap';
import PostEditor from '../ActionCenter/PostEditor';
import { getHTMLfromRawState } from '../ActionCenter/PostEditor/postEditor';
import { TrimString } from '../../Utils/StringTrimmer';
import { useState } from 'react';
import { useRef } from 'react';

const AssessmentChatDropDown = ({question,children}) => {
    const userCommentRef= useRef()
    const [renameTemplate, setRenameTemplate] = useState(false);
    const [mentionList, setMentionList] = useState([
      {
      "name": "Raman",
      "OwnerLogo": "No URL",
      "OwnerInitials": "RA",
      "OwnerShowInitals": true,
      "OwnerUserName": "Raman@techformation.co.in"
  },
      {
      "name": "GUPTA",
      "OwnerLogo": "No URL",
      "OwnerInitials": "GU",
      "OwnerShowInitals": true,
      "OwnerUserName": "Raman@techformation.co.in"
  },
      {
      "name": "Raman Gupta",
      "OwnerLogo": "No URL",
      "OwnerInitials": "ZA",
      "OwnerShowInitals": true,
      "OwnerUserName": "Raman@techformation.co.in"
  },




])
    const [saving, setSaving] = useState(false);
    const [sending, setSending] = useState(true);
    const [message, setMessage] = useState("");
    const [editMessageData, setEditMessageData] = useState("");
  return (
    <div className="question-chat-dropdown mx-2">
    <Dropdown
      className="chat-dropdown"
      onToggle={(e) => {
        setSending(false);
        setMessage("");
        setEditMessageData("");
        // console.log(e)
        if(e){
          setTimeout(() => {
            userCommentRef.current.reset()
            
          }, 200);
        }
      }}
    >
      <Dropdown.Toggle
        className="p-0 position-relative"
        style={{ zIndex: 1 }}
      >
        <div
          onMouseEnter={(event) => {
            event.target.click();
            event.stopPropagation();
          }}
          className={`d-flex align-items-center f-12 f-500 f-grey  ${
            question?.ResponseComment_List
              ?.length > 0
              ? ""
              : "hover-on-show-child"
          } `}
        >
          {/* {question?.ResponseComment_List
            ?.length > 0 ? (
            <img
              alt=""
              className="mr-1"
              src="/images/supply-chain/chat-icon.svg"
            />
          ) : (
            <img
              alt=""
              className="mr-1"
              src="/images/attack-surface/no-comments-icon.svg"
            />
          )}
          {question?.ResponseComment_List
            ?.length || ""} */}
            {children}
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div>
          {/* Header */}
          <div className="chat-box-head px-2 pb-2 border-bottom">
            <div className="d-flex align-items-center justify-content-between">
              <div className="f-12 f-darkgrey">
                Question ID:{" "}
                {question?.QuestionId}
              </div>
              <div>
                <img
                  alt=""
                  className="pointer"
                  src="/images/supply-chain/chat-box-cross.svg"
                  onClick={(e) => {
                    e.target
                      .closest(".chat-dropdown")
                      .click();
                  }}
                />
              </div>
            </div>
          </div>
          {/* Body */}
          <div className="py-3 hide-scrollba r chat-bod y">
            {
              // question?.SCMFormComment_List

              question?.ResponseComment_List?.map(
                (comment, commentIndex) => {
                  return (
                    <div className=" d-flex align-items-start justify-content-between mx-3 mb-3">
                      <div className="d-flex">
                        <img
                          alt=""
                          className="rounded-full mr-2"
                          width={24}
                          height={24}
                          src={comment?.ImageURL}
                          onError={({
                            currentTarget,
                          }) => {
                            currentTarget.onerror =
                              null; // prevents looping
                            currentTarget.src =
                              "/images/settings-v2/highground-dp.svg";
                          }}
                        />

                        <div>
                          <p className="m-0 f-500 f-black">
                            {TrimString(
                              comment?.FullName,
                              22
                            )}
                          </p>
                          {/* <p className=" f-black">
                            {comment?.Message}
                          </p> */}
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                getHTMLfromRawState(
                                  comment?.Message,
                                  mentionList
                                ),
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          className={`template-item-dropdown  ${
                            comment?.CanUpdateDelete
                              ? ""
                              : "d-none"
                          }  `}
                        >
                          <Dropdown
                            onToggle={() => {}}
                            className={`  device-dropdown ${
                              renameTemplate
                                ? "white-side-dropdown"
                                : "black-dropdown"
                            }`}
                          >
                            <Dropdown.Toggle>
                              <img
                                alt=""
                                src="/images/horizontal-dots.svg"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <div>
                                <Row>
                                  <Col
                                    xs={12}
                                    className=" pointer "
                                  >
                                    <div
                                      className="drop-item"
                                      onClick={(
                                        e
                                      ) => {
                                        setEditMessageData(
                                          {
                                            username:
                                              comment?.FullName,
                                            messageId:
                                              comment?.ObjectId,
                                          }
                                        );
                                        // setMessage(
                                        //   comment?.Message
                                        // );
                                        userCommentRef.current.setValue(
                                          JSON.parse(
                                            comment?.Message
                                          )
                                        );
                                        e.target
                                          .closest(
                                            ".device-dropdown"
                                          )
                                          .click();
                                      }}
                                    >
                                      Edit
                                    </div>
                                  </Col>

                                  <Col
                                    xs={12}
                                    className=" pointer "
                                  >
                                    <div
                                      className="drop-item"
                                      onClick={(
                                        e
                                      ) => {
                                        // `deleteComment(comment?.ObjectId);`
                                        e.target
                                          .closest(
                                            ".device-dropdown"
                                          )
                                          .click();
                                      }}
                                    >
                                      Delete
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  );
                }
              )
            }
          </div>
          {/* footer */}
          <div>
            {editMessageData ? (
              <div
                className="d-flex align-items-center justify-content-between pt-1 px-2"
                style={{
                  borderTop: "1px solid #ebecf1",
                }}
              >
                <div className="f-black f-500">
                  {editMessageData?.username}
                </div>
                <img
                  alt=""
                  className="pointer"
                  src="/images/attack-surface/small-cross.svg"
                  onClick={() => {
                    setEditMessageData("");
                    setMessage("");
                    userCommentRef.current.reset();
                    
                  }}
                />
              </div>
            ) : null}

            <div className="message-typer d-flex align-items-center mx-1 mt-2">
              <div
                className="w-100"
                style={{
                  paddingLeft: "3px",
                }}
              >
                <PostEditor
                  className="PostEditor__input"
                  placeholder={"Add a comment"}
                  getValue={(e) => {
                    setMessage(JSON.stringify(e));
                  }}
                  mentions={mentionList}
                  ref={userCommentRef}
                />
              </div>

               
            

              <Button
                className=""
                onClick={() => {

                  if(JSON.parse(message)?.blocks?.[0]?.text==="" || !JSON.parse(message)?.blocks?.[0]?.text)return;
                 
                  if (
                    message !== "" &&
                    !editMessageData?.messageId
                  ) {
                    // sendMessage(
                    //   message,
                    //   question?.QuestionId
                    // );
                  }
                  if (
                    message !== "" &&
                    editMessageData?.messageId
                  ) {
                    // sendMessage(
                    //   message,
                    //   question?.QuestionId,
                    //   editMessageData?.messageId
                    // );
                  }
                }}
              >
                {sending ? (
                  <WhiteLoader width={11} />
                ) : (
                  <img
                    alt=""
                    width={12}
                    height={12}
                    src="/images/settings-v2/white-plane.svg"
                    className=""
                    style={{ width: "20px" }}
                  />
                )}
              </Button>
            </div>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  </div>
  )
}

export default AssessmentChatDropDown