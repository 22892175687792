import React, { useState, useEffect, Fragment } from "react";
import { Row, Col, Dropdown, Badge, Button } from "react-bootstrap";
import ImageWithInitials from "../../Utils/ImageWithInitials";
import DatePicker from "../Theme/datepicker";
import moment from "moment";
import "./dropdown.scss";
import { useSelector } from "react-redux";

const DropDownFilter = (props) => {
  const {
    activeFilter,

    data,
    filterTask,
    setFilterTask,
    show,
    toggleFilter,

    setFilterParameter,

    selectSectionTab,
  } = props;

  const [allData, setAllData] = useState("");
  useEffect(() => {
    setAllData(data);
  }, [data]);

  const initialFilterState = [
    { TaskFilter: "Title", FilterText_List: [] },
    { TaskFilter: "Status", FilterText_List: [] },
    { TaskFilter: "Due_Date", FilterText_List: [] },
    { TaskFilter: "Section", FilterText_List: [] },
    { TaskFilter: "Module", FilterText_List: [] },
    { TaskFilter: "Task_Owner", FilterText_List: [] },
  ];

  const [filterRequestData, setfilterRequestData] =
    useState(initialFilterState);

  const loginedUser = useSelector((state) => state.user?.userDetail?.UserName);

  useEffect(() => {
    let filterData = window.localStorage.getItem("TaskFilter_List");
    let user = window.localStorage.getItem("user-email-filter");

    if (filterData && user == loginedUser) {
      let filters = JSON.parse(filterData);
      setfilterRequestData(filters);
      let StatusIndex = filters.findIndex(
        (item) => item.TaskFilter == "Status"
      );
      let ModuleIndex = filters.findIndex(
        (item) => item.TaskFilter == "Module"
      );
      let DueDateIndex = filters.findIndex(
        (item) => item.TaskFilter == "Due_Date"
      );
      let SectionIndex = filters.findIndex(
        (item) => item.TaskFilter == "Section"
      );
      let OwnerIndex = filters.findIndex(
        (item) => item.TaskFilter == "Task_Owner"
      );

      if (filters[OwnerIndex]?.FilterText_List?.length > 0) {
        let datad = allData?.TaskOwnerList?.filter((item) => {
          return item.OwnerUserName == filters[OwnerIndex]?.FilterText_List[0];
        })[0];

        if (typeof datad == "object") {
          setFilterTask((filterTask) => {
            return {
              ...filterTask,
              selectedOwner: allData?.TaskOwnerList?.filter(
                (item) =>
                  item.OwnerUserName == filters[OwnerIndex]?.FilterText_List[0]
              )[0],
            };
          });
        }
      }

      if (filters[SectionIndex]?.FilterText_List?.length > 0) {
        let datad = allData?.SectionList?.filter((item) => {
          return item.DDLText == filters[SectionIndex]?.FilterText_List[0];
        })[0];

        if (typeof datad == "object") {
          setFilterTask((filterTask) => {
            return {
              ...filterTask,
              selectedSection: datad,
            };
          });
        }
      }

      if (filters[ModuleIndex]?.FilterText_List?.length > 0) {
        let datad = allData?.ModuleList?.filter((item) => {
          return item.DDLText == filters[ModuleIndex]?.FilterText_List[0];
        })[0];

        if (typeof datad == "object") {
          setFilterTask((filterTask) => {
            return {
              ...filterTask,
              selectedModule: datad,
            };
          });
        }
      }
      if (filters[StatusIndex]?.FilterText_List?.length > 0) {
        let datad = allData?.StatusList?.filter((item) => {
          return item.DDLText == filters[StatusIndex]?.FilterText_List[0];
        })[0];

        if (typeof datad == "object") {
          setFilterTask((filterTask) => {
            return {
              ...filterTask,
              selectedStatus: datad,
            };
          });
        }
      }
      if (filters[DueDateIndex]?.FilterText_List?.length > 0) {
        let datad = filters[DueDateIndex]?.FilterText_List[0];

        if (datad.length > 5) {
          setFilterTask((filterTask) => {
            return {
              ...filterTask,
              selectedDate: datad,
            };
          });
        }
      }
    }
  }, [allData]);

  return (
    <div className="dropdownfilter">
      <Dropdown className="input_custom_dropdown" show={show}>
        <Dropdown.Toggle
          className={`p-0 position-relative filter_toggle ${
            activeFilter ? "active-dropdown-toggle" : ""
          }`}
          style={{ zIndex: 1 }}
          onClick={toggleFilter}
        >
          {props.children}
        </Dropdown.Toggle>

        <Dropdown.Menu className="BillingInfo" style={{ height: "150px" }}>
          <Row>
            <Col
              xs={6}
              className="mt-2 mb-2 pr-0 d-flex align-items-center greyText"
            >
              Filters
            </Col>
            <Col xs={6} className="mt-2 mb-2 d-flex text-right">
              <span
                className="w-100 txt-blue"
                onClick={() => {
                  setFilterTask({
                    selectedOwner: {},
                    selectedStatus: {},
                    selectedDate: "",
                    selectedSection: {},
                    selectedModule: {},
                  });

                  if (filterRequestData[3].FilterText_List.length > 0) {
                    props.selectAllTab();
                  }

                  setfilterRequestData(initialFilterState);
                  setFilterParameter((prevState) => {
                    return {
                      ...prevState,
                      TaskFilter_List: JSON.stringify(initialFilterState),
                    };
                  });
                  window.localStorage.removeItem("TaskFilter_List");
                }}
              >
                Reset
              </span>
            </Col>
            {/* Owner dropdown */}
            <Col xs={4} className=" pr-0 d-flex align-items-center">
              Task Owner
            </Col>
            <Col xs={8} className="pl-0">
              <Dropdown className="input_custom_dropdown">
                <Dropdown.Toggle
                  className="p-0 position-relative"
                  style={{ zIndex: 1 }}
                >
                  {Object.keys(filterTask?.selectedOwner).length > 0 ? (
                    <div className="dropdown_input drop_border">
                      <Badge className="custombadge d-flex align-items-center">
                        {filterTask?.selectedOwner?.OwnerShowInitals ===
                        true ? (
                          <ImageWithInitials
                            initials={filterTask?.selectedOwner?.OwnerInitials}
                            width={20}
                            background={"purple"}
                          />
                        ) : (
                          <img
                            alt=""
                            src={filterTask?.selectedOwner?.OwnerLogo}
                            style={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "10px",
                              objectFit: "contain",
                            }}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src =
                                "/images/settings-v2/highground-dp.svg";
                            }}
                          />
                        )}
                        <div className="ml-1">
                          {filterTask?.selectedOwner?.OwnerName}
                        </div>
                        <img
                          alt=""
                          src="/images/actioncenter/cross.svg"
                          className="crossimg d-none"
                          onClick={() => {
                            setFilterTask({ ...filterTask, selectedOwner: {} });
                            // setSelectedOwner({})}
                          }}
                        />
                      </Badge>
                    </div>
                  ) : (
                    <input
                      type="text"
                      className="dropdown_input"
                      value=""
                      placeholder="Select"
                    />
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className="dropfilterchild"
                  style={{ height: "150px" }}
                >
                  {allData?.TaskOwnerList?.map((owner, i) => (
                    <div
                      className="child_drop d-flex align-items-center"
                      key={i}
                      onClick={(e) => {
                        e.target.closest(".input_custom_dropdown").click();
                        setFilterTask({ ...filterTask, selectedOwner: owner });

                        let updated_data = [...filterRequestData];
                        let Index = updated_data.findIndex(
                          (item) => item?.TaskFilter == "Task_Owner"
                        );
                        updated_data[Index].FilterText_List[0] =
                          owner.OwnerUserName;

                        setfilterRequestData(updated_data);
                      }}
                    >
                      {owner?.OwnerShowInitals === true ? (
                        <ImageWithInitials
                          initials={owner?.OwnerInitials}
                          width={20}
                          background={"purple"}
                        />
                      ) : (
                        <img
                          alt=""
                          src={owner?.OwnerLogo}
                          style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "10px",
                            objectFit: "contain",
                          }}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src =
                              "/images/settings-v2/highground-dp.svg";
                          }}
                        />
                      )}
                      &nbsp;
                      <div>{owner?.OwnerName}</div>
                    </div>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>

            {/* status */}
            <Col xs={4} className="d-flex align-items-center">
              Status
            </Col>
            <Col xs={8} className="mt-2 pl-0">
              <Dropdown className="input_custom_dropdown">
                <Dropdown.Toggle
                  className="p-0 position-relative"
                  style={{ zIndex: 1 }}
                >
                  {Object.keys(filterTask?.selectedStatus).length > 0 ? (
                    <div className="dropdown_input drop_border">
                      <div
                        className={`${true}  tableStatus`}
                        style={{
                          backgroundColor: `${filterTask?.selectedStatus?.DDLColour}40`,
                          color: `${filterTask?.selectedStatus?.DDLColour}`,
                        }}
                      >
                        <div
                          className={`tablecircle ${true}`}
                          style={{
                            backgroundColor: `${filterTask?.selectedStatus?.DDLColour}`,
                          }}
                        />
                        {filterTask?.selectedStatus?.DDLText}
                      </div>
                    </div>
                  ) : (
                    <input
                      type="text"
                      className="dropdown_input"
                      value=""
                      placeholder="Select"
                    />
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className="dropfilterchild"
                  style={{ height: "150px" }}
                >
                  {allData?.StatusList?.length > 0 &&
                    allData?.StatusList?.map((status, i) => {
                      return (
                        <>
                          <div
                            className="child_drop d-flex justify-content-between align-items-center less-padding"
                            key={i}
                            onClick={(e) => {
                              e.target
                                .closest(".input_custom_dropdown")
                                .click();
                              setFilterTask({
                                ...filterTask,
                                selectedStatus: status,
                              });
                              let updated_data = [...filterRequestData];
                              let Index = updated_data.findIndex(
                                (item) => item?.TaskFilter == "Status"
                              );
                              updated_data[Index].FilterText_List[0] =
                                status.DDLText;

                              setfilterRequestData(updated_data);
                            }}
                          >
                            <div>
                              <div
                                className="tableStatus"
                                style={{
                                  backgroundColor: `${status?.DDLColour}40`,
                                  color: status?.DDLColour,
                                }}
                              >
                                <div
                                  className="tablecircle"
                                  style={{
                                    backgroundColor: status?.DDLColour,
                                  }}
                                />
                                {status?.DDLText}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>

            {/* date */}
            <Col xs={4} className="d-flex align-items-center">
              Due Date
            </Col>
            <Col xs={8} className="mt-2 pl-0 datepicker_cutom">
              {filterTask.selectedDate == "" && (
                <DatePicker
                  singleDatePicker={true}
                  startDate={moment(new Date()).format("MM/DD/YYYY")}
                  placeholder="Choose due date"
                  onChange={(e) => {
                    setFilterTask({
                      ...filterTask,
                      selectedDate: e.toString(),
                    });

                    let updated_data = [...filterRequestData];
                    let Index = updated_data.findIndex(
                      (item) => item?.TaskFilter == "Due_Date"
                    );
                    updated_data[Index].FilterText_List[0] = e.toString();

                    setfilterRequestData(updated_data);
                  }}
                />
              )}

              {filterTask.selectedDate && (
                <DatePicker
                  singleDatePicker={true}
                  startDate={`${moment(
                    filterTask.selectedDate,
                    "DD/MM/YYYY"
                  ).format("MM/DD/YYYY")}`}
                  // startDate={selectedTaskDate}
                  placeholder={filterTask.selectedDate}
                  onChange={(e) => {
                    setFilterTask({
                      ...filterTask,
                      selectedDate: e.toString(),
                    });

                    let updated_data = [...filterRequestData];
                    let Index = updated_data.findIndex(
                      (item) => item?.TaskFilter == "Due_Date"
                    );
                    updated_data[Index].FilterText_List[0] = e.toString();

                    setfilterRequestData(updated_data);
                  }}
                  value
                />
              )}

              {/* 
              <DatePicker
                singleDatePicker={true}
                startDate={moment(new Date()).format("MM/DD/YYYY")}
                onChange={(e) => {
                  setFilterTask({ ...filterTask, selectedDate: e.toString() });

                  let updated_data = [...filterRequestData];
                  let Index = updated_data.findIndex(
                    (item) => item?.TaskFilter == "Due_Date"
                  );
                  updated_data[Index].FilterText_List[0] = e.toString();

                  setfilterRequestData(updated_data);
                }}
                placeholder="Choose due date"
              /> */}
            </Col>

            {/* Section */}
            <Col xs={4} className="d-flex align-items-center ">
              Section
            </Col>
            <Col xs={8} className="mt-2 pl-0">
              <Dropdown className="input_custom_dropdown">
                <Dropdown.Toggle
                  className="p-0 position-relative"
                  style={{ zIndex: 1 }}
                >
                  {Object.keys(filterTask?.selectedSection).length > 0 ? (
                    <div className="dropdown_input drop_border">
                      <Badge className="custombadge pl-2">
                        {filterTask?.selectedSection?.DDLText}
                        <img
                          alt=""
                          src="/images/actioncenter/cross.svg"
                          className="crossimg d-none"
                          onClick={() => {
                            setFilterTask({
                              ...filterTask,
                              selectedSection: {},
                            });
                          }}
                        />
                      </Badge>
                    </div>
                  ) : (
                    <input
                      type="text"
                      className="dropdown_input"
                      value=""
                      placeholder="Select"
                    />
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className="dropfilterchild"
                  style={{ height: "150px" }}
                >
                  {allData?.SectionList?.map((section, i) => (
                    <div
                      className="child_drop"
                      key={i}
                      onClick={(e) => {
                        e.target.closest(".input_custom_dropdown").click();
                        setFilterTask({
                          ...filterTask,
                          selectedSection: section,
                        });

                        let updated_data = [...filterRequestData];
                        let Index = updated_data.findIndex(
                          (item) => item?.TaskFilter == "Section"
                        );
                        updated_data[Index].FilterText_List[0] =
                          section.DDLText;

                        setfilterRequestData(updated_data);
                      }}
                    >
                      {section?.DDLText}
                    </div>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>

            {/* module */}
            <Col xs={4} className="d-flex align-items-center">
              Module
            </Col>
            <Col xs={8} className="mt-2 pl-0">
              <Dropdown className="input_custom_dropdown">
                <Dropdown.Toggle
                  className="p-0 position-relative"
                  style={{ zIndex: 1 }}
                >
                  {Object.keys(filterTask?.selectedModule).length > 0 ? (
                    <div className="dropdown_input drop_border">
                      <Badge
                        className="custombadge2"
                        ref={(el) => {
                          if (el) {
                            el.style.setProperty(
                              "color",
                              `${filterTask?.selectedModule?.DDLColour}`,
                              "important"
                            );
                          }
                        }}
                      >
                        <img
                          alt=""
                          src={`/images/actioncentrelive/${filterTask?.selectedModule?.DDLImage}`}
                          style={{ filter: "invert(0.4)" }}
                          className="mr-2"
                        />
                        {filterTask?.selectedModule?.DDLText}
                        <img
                          alt=""
                          src="/images/actioncenter/cross.svg"
                          className="crossimg float-right mt-2 d-none"
                          onClick={() =>
                            setFilterTask({ ...filterTask, selectedModule: {} })
                          }
                        />
                      </Badge>
                    </div>
                  ) : (
                    <input
                      type="text"
                      className="dropdown_input"
                      placeholder="Select"
                    />
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className="dropfilterchild"
                  style={{ height: "150px" }}
                >
                  {allData?.ModuleList?.map((module, i) => (
                    <div
                      className="child_drop"
                      key={i}
                      ref={(el) => {
                        if (el) {
                          el.style.setProperty(
                            "color",
                            `${module?.DDLColour}`,
                            "important"
                          );
                        }
                      }}
                      onClick={(e) => {
                        e.target.closest(".input_custom_dropdown").click();
                        setFilterTask({
                          ...filterTask,
                          selectedModule: module,
                        });

                        let updated_data = [...filterRequestData];
                        let Index = updated_data.findIndex(
                          (item) => item?.TaskFilter == "Module"
                        );
                        updated_data[Index].FilterText_List[0] = module.DDLText;

                        setfilterRequestData(updated_data);
                      }}
                    >
                      <img
                        alt=""
                        src={`/images/actioncentrelive/${module?.DDLImage}`}
                        className="mr-2"
                        style={{ filter: "invert(0.4)" }}
                      />
                      &nbsp;
                      {module?.DDLText}
                    </div>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <hr color="#EBEDF1" className="mb-3" />
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-between"
            >
              <div
                className="pointer"
                onClick={() => {
                  setFilterTask({
                    selectedOwner: {},
                    selectedStatus: {},
                    selectedDate: "",
                    selectedSection: {},
                    selectedModule: {},
                  });
                  if (filterRequestData[3].FilterText_List.length > 0) {
                    props.selectAllTab();
                  }
                  setfilterRequestData(initialFilterState);
                  setFilterParameter((prevState) => {
                    return {
                      ...prevState,
                      TaskFilter_List: JSON.stringify(initialFilterState),
                    };
                  });
                  window.localStorage.removeItem("TaskFilter_List");
                }}
              >
                <img src="/images/settings-v2/refresh-filter.svg" />
                <span
                  className="ml-1"
                  style={{ color: "#BBBEC9", padding: "3px" }}
                >
                  Reset
                </span>
              </div>
              <div>
                <Button className="cancel-btnn" onClick={toggleFilter}>
                  Cancel
                </Button>
                <Button
                  className="apply-btn"
                  onClick={(e) => {
                    if (filterRequestData[3].FilterText_List.length > 0) {
                      let section = allData?.SectionList?.filter((item) => {
                        return (
                          item.DDLText ==
                          filterRequestData[3].FilterText_List[0]
                        );
                      })[0];

                      if (typeof section == "object") {
                        selectSectionTab(section);
                      }
                    }
                    setFilterParameter((prevState) => {
                      return {
                        ...prevState,
                        TaskFilter_List: JSON.stringify(filterRequestData),
                      };
                    });

                    setTimeout(() => {
                      window.localStorage.setItem(
                        "TaskFilter_List",
                        JSON.stringify(filterRequestData)
                      );

                      window.localStorage.setItem(
                        "user-email-filter",
                        loginedUser
                      );
                    }, 100);

                    // console.log(filterRequestData);

                    toggleFilter(e);
                  }}
                >
                  Apply
                </Button>
              </div>
            </Col>
          </Row>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default DropDownFilter;
