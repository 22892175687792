import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import ActionCenter from "../../ActionCenter/Action_center_modals/ActionCentreSettings";
import ResetModal from "../../ActionCenter/Action_center_modals/ResetModal";
import "./action-center.scss";
import { viewOnlyAccess } from "../../App/App";

const ActionCentreV2 = () => {
  const [actionCenterModal, setActionCenterModal] = useState(false);
  const [type, setType] = useState("");
  const [showResetModal, setShowResetModal] = useState(false);
  const IsMspClientView = window.location.pathname.split("/")?.[1] ==="clientmspsettingsv2"
  return (
    <div
      className="action-centre-wrapper"
      style={{
        pointerEvents: viewOnlyAccess("AccessState_ActionCentre_Settings")
          ? "none"
          : "all",
      }}
    >
      <div className="heading mb-4">Action Centre Settings</div>

      <Row className="option-wrapper">
        <Col
          xs={12}
          className="d-flex justify-content-between align-items-center p-3"
        >
          <div>
            <p className="title m-0">Integrations</p>
            <p className="subtitle">
              Sync your HighGround tasks with current task/project management
              tool for easier management
            </p>
          </div>
          <div>
            <Button
              className="connect-btn"
              onClick={() => {
                setType("integration");
                setActionCenterModal(true);
              }}
            >
              <img src="/images/plus-icon.svg" alt="" /> Connect
            </Button>
          </div>
        </Col>
        <hr className="m-0" />
        <Col
          xs={12}
          className="d-flex justify-content-between align-items-center p-3 pointer"
          onClick={() => {
            setType("taskcreation");
            setActionCenterModal(true);
          }}
        >
          <div>
            <p className="title m-0">Task Creation</p>
            <p className="subtitle">
              Configure how tasks are automatically created in your action
              centre
            </p>
          </div>
          <div>
            <img src="/images/chevron-right.svg" alt="" />
          </div>
        </Col>
        {/* Notifications */}

        {!IsMspClientView && (
          <>
            <hr className="m-0" />
            <Col
              xs={12}
              className="d-flex justify-content-between align-items-center p-3 pointer"
              onClick={() => {
                setType("notifications");
                setActionCenterModal(true);
              }}
            >
              <div>
                <p className="title m-0">Notifications</p>
                <p className="subtitle">
                  Configure how you are notified about tasks created in your
                  action centre
                </p>
              </div>
              <div>
                <img alt="" src="/images/chevron-right.svg" />
              </div>
            </Col>
            {/* Reset Settings */}
            <hr className="m-0" />
            <Col
              xs={12}
              className="d-flex justify-content-between align-items-center p-3 pointer"
              onClick={() => {
                setShowResetModal(true);
              }}
            >
              <div>
                <p className="title m-0">Reset settings</p>
                <p className="subtitle">Restore settings to their defaults</p>
              </div>
              <div>
                <img src="/images/chevron-right.svg" alt="" />
              </div>
            </Col>
          </>
        )}
      </Row>
      <ActionCenter
        isOpen={actionCenterModal}
        setIsOpen={setActionCenterModal}
        settingsV2={type}
      />
      <ResetModal
        isOpen={showResetModal}
        setIsOpen={() => setShowResetModal(false)}
      />
    </div>
  );
};

export default ActionCentreV2;
