import React, { useEffect, useState } from "react";
import { Button, Dropdown, Nav, Tab } from "react-bootstrap";
import { HGHighlightText } from "../../App/App";
import "./mspSubscriptionAccounts.scss";
import MspAccountOverview from "./MspAccountOverview";
import MspAccountLogs from "./MspAccountLogs";
import MspAccountSubscriptions from "./MspAccountSubscriptions";
import MspAccountAbout from "./MspAccountAbout";
import TrialPeriodModal from "../modals/TrialPeriodModal";
import ConvertSubscriptionModal from "../modals/ConvertSubscriptionModal";
import SubscriptionSuspendModal from "../modals/SubscriptionSuspendModal";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import MspSupportAccess from "./MspSupportAccess";
const MspSubscriptionAccountDetails = () => {
  const [defaultSelectedTab, setDefaultSelectedTab] = useState("Overview");
const {accountId}= useParams()
const history = useHistory()
const [showTrialPeriodModal, setShowTrialPeriodModal] = useState(false)
const [showConvertSubscriptionModal, setShowConvertSubscriptionModal] = useState(false)
const [showSubscriptionSuspendModal, setShowSubscriptionSuspendModal] = useState(false)
const {authTokens} = useAuth()
const [bannerData, setBannerData] = useState({})
useEffect(() => {
  // getBannerData()
}, [ ])


function getBannerData(){
  return new Promise((resolve, reject) => {
    getAPIData("3", authTokens, {
      optionEnum1:1
    })
      .then((response) => {
      
        
        resolve(response); // Resolve the promise
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
         
        reject(err); // Reject the promise
      });
  });
}


  return (
    <div className="px-3 msp-subscription-details">
      <div className="p-3" />
      <div className="d-flex align-items-center justify-content-between border-bottom pb-2 bg-white pt-3"
      style={{
     position:"sticky",
     top:"54px"
      }}
      
      >
        <div className="d-flex align-items-center">
        <div className="d-flex align-items-center pointer"
        
        onClick={()=>{
        history.goBack()
        }}
        >
          <img
            src="/images/attack-surface/back-icon.svg"
            alt=""
            className="mr-2"
          />
          <span className="f-grey">Back</span>
        </div>
          <span className="f-600 f-grey pl-3">MSP’s Accounts</span>
          <span className="f-600">&nbsp; &nbsp;/&nbsp;&nbsp;</span>
          <span className="f-600 f-black">M3Networks ltd.</span>
        </div>
        <div className="d-flex align-items-center">
          <Dropdown className={`transparennt-wrapper-dropdown `}>
            <Dropdown.Toggle>
              <Button className="hg-cancel-btn  mr-2">
                <img
                  src="/images/settings-v2/settingwheel.svg"
                  alt=""
                  className="mr-2"
                />
                Manage account
              </Button>
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-2  pointer"
            
            style={{
              width:"200px"
            }}
            >
              <div className="f-12 f-darkgrey mb-2">Subscription</div>
              <div className="d-flex mb-2"
              onClick={()=>{
                setShowTrialPeriodModal(true)
              }
              }
              
              >
                <img src="" alt="" className="mr-2" />
                <div>
                  <div className="f-14 f-grey">Extend trial</div>
                  <div className="f-12 f-darkgrey">
                    Extend free trial period
                  </div>
                </div>
              </div>
              <div className="d-flex mb-2"
              
              onClick={()=>{
                setShowConvertSubscriptionModal(true)
              }
              }
              
              >
                <img src="" alt="" className="mr-2" />
                <div>
                  <div className="f-14 f-grey">Extend trial</div>
                  <div className="f-12 f-darkgrey">
                    Extend free trial period
                  </div>
                </div>
              </div>
              <div className="f-12 f-darkgrey mb-2">Account</div>
              <div className="d-flex mb-2"  onClick={()=>{
                setShowSubscriptionSuspendModal(true)
              }
              }>
                <img src="" alt="" className="mr-2" />
                <div>
                  <div className="f-14 f-grey">Suspend Account</div>
                  <div className="f-12 f-darkgrey">
                    Temporarily restrict login
                  </div>
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {/*User Details */}
      <div className="d-flex align-items-center justify-content-between px-5">
        <div>
          <div className=" d-flex align-items-center  justify-content-start pl-2  py-2 mt-3">
            <img
              alt=""
              src={"/images/user-logo.svg"}
              className="ml-1 mr-3 rounded-full"
              width={64}
              height={64}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/user-logo.svg";
              }}
            />
            <div className="">
              <div className="f-600 f-18 f-black m-0">MohitXero</div>
              {/* <div className="f-12 f-darkgrey">Last Login: 12/01/24 17:00</div> */}
              <div className="f-12 f-darkgrey">Active</div>
            </div>
          </div>
        </div>

        <div>
          <div className="f-darkgrey">Supply Chain ID</div>
          <div className="f-500 f-black">182-773-628</div>
        </div>
      </div>
      <div className="mt-4 px-5">
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey={defaultSelectedTab}
        >
          <div>
            <div
              sm={12}
              className="tab-header d-flex justify-content-between align-items-center ml- p-0"
            >
              <div className={`${true}`}>
                <Nav
                  variant="pills"
                  className="flex-row"
                  onSelect={(e) => setDefaultSelectedTab(e)}
                >
                  <Nav.Item>
                    <Nav.Link eventKey="Overview">Overview</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Subscription">Subscription</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="SupportAccess">Support Access</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="Logs">Logs</Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link eventKey="About">About</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="Requests">Requests</Nav.Link>
                  </Nav.Item> */}
                </Nav>
              </div>
            </div>
            <div sm={12} className="ml-1 p-0">
              <Tab.Content>
                <Tab.Pane eventKey="Overview">
                  <MspAccountOverview accountId={accountId} />
                </Tab.Pane>
                <Tab.Pane eventKey="Subscription">
                  <MspAccountSubscriptions accountId={accountId}/>
                </Tab.Pane>
                <Tab.Pane eventKey="SupportAccess">
                  <MspSupportAccess accountId={accountId}/>
                </Tab.Pane>
                <Tab.Pane eventKey="Logs">
                  <MspAccountLogs accountId={accountId}/>
                </Tab.Pane>
                <Tab.Pane eventKey="About">
                  <MspAccountAbout accountId={accountId}/>
                </Tab.Pane>
                <Tab.Pane eventKey="Requests"></Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>




      <TrialPeriodModal
      show={showTrialPeriodModal}
      hideModal={()=>{
        setShowTrialPeriodModal(false)
      }}
      />
      <ConvertSubscriptionModal
      show={showConvertSubscriptionModal}
      hideModal={()=>{
        setShowConvertSubscriptionModal(false)
      }}
      />
      <SubscriptionSuspendModal
      show={showSubscriptionSuspendModal}
      hideModal={()=>{
        setShowSubscriptionSuspendModal(false)
      }}
      
      />
    </div>
  );
};

export default MspSubscriptionAccountDetails;
