import React, { useState } from "react";
import { Row, Col, Modal, Button, Spinner } from "react-bootstrap";
import CompanySettingsDropdown from "../companySettings/companysettingsdrodown/CompanySettingsDropdown";
import "./members.scss";

const InvitationModal = ({ show, hideModal }) => {
  const [post, setPost] = useState({
    id: 12,
    category: "CEO",
  });
  const [inviteEmail, setInviteEmail] = useState("");
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  return (
    <Modal
      // show={true}
      show={show}
      dialogClassName="invitation-modal"
      className=" "
      aria-labelledby="invitation-modal"
      centered
      onHide={hideModal}
      //   scrollable
    >
      <Modal.Header className="text-center">
        <div className="w-100 mb-0 font-bold-14" style={{ fontWeight: "600" }}>
          Invite Member
        </div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img src="/images/icons/close.svg" alt="" className="close-icon" />
        </span>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col xs={12}>
            <div className="item-wrapper">
              <div className="label-input mt-3">Invite link</div>
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center field-wrapper">
                  <div className="link-text">
                    https://highground.io/jnas77ashsf9
                  </div>
                  <CompanySettingsDropdown
                    fieldId={"id"}
                    fieldValue={"category"}
                    selected={post}
                    placeholder="Choose..."
                    //  showid={true}
                    onClick={(id, val) => {
                      setPost({ id, category: val });
                    }}
                    data={[
                      { id: 12, category: "CEO" },
                      { id: 112, category: "CTO" },
                      { id: 113, category: "CFO" },
                    ]}
                  />
                </div>
                <Button className="d-flex align-items-center copy-link-btn">
                  <img
                    src="/images/settings-v2/copy-link.svg"
                    className="mr-2"
                  />
                  <div>Copy link</div>
                </Button>
              </div>
            </div>
          </Col>

          <Col xs={12}>
            <div className="email-item-wrapper mt-2 mb-3">
              <div className="label-input mt-3">Invite by email</div>
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center email-field-wrapper ">
                  <input
                    type="email"
                    className="email-input"
                    placeholder="Enter Email"
                    value={inviteEmail}
                    onChange={(e) => setInviteEmail(e.target.value)}
                  />
                  <CompanySettingsDropdown
                    fieldId={"id"}
                    fieldValue={"category"}
                    selected={post}
                    placeholder="Choose..."
                    //  showid={true}
                    onClick={(id, val) => {
                      setPost({ id, category: val });
                    }}
                    data={[
                      { id: 12, category: "CEO" },
                      { id: 112, category: "CTO" },
                      { id: 113, category: "CFO" },
                    ]}
                  />
                </div>
                <Button
                  className="d-flex align-items-center copy-link-btn"
                  disabled={sending || sent}
                  onClick={() => {
                    setSending(true);
                    setTimeout(() => {
                      setSending(false);
                      setSent(true);
                      setInviteEmail("");
                    }, 1500);
                    setTimeout(() => setSent(false), 2500);
                  }}
                >
                  {!sending && !sent && <div>Send Invite</div>}
                  {sending && (
                    <div>
                      <span>
                        <Spinner
                          size="sm"
                          className="mr8px"
                          animation="border"
                        />{" "}
                        Sending...
                      </span>
                    </div>
                  )}
                  {sent && <div>Sent!</div>}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default InvitationModal;
