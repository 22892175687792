import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Button, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import Confetti from "react-dom-confetti";
import { getAPIData } from "../../Utils/commonApi";
import { TrimString } from "../../Utils/StringTrimmer";
import { useAuth } from "../context/Auth";
import { CallToast } from "../PriceCalculator/modals/CustomToast";

import ConnectIntegrationModal from "../MSPv2/integrations/ConnectIntegrationModal";
import { useHistory } from "react-router-dom";
import { TrackUser } from "../../Utils/SegmentFunctions";
import UpgradeToPremiumModal from "./UpgradeToPremiumModal";
import MspConnectIntegrationModal from "../MSPv2/integrations/mspIntegrationdetailScreen/MspConnectIntegrationModal";
import { viewOnlyAccess } from "../App/App";

const AppMarketPlace = forwardRef(
  (
    {
      /**Props will be Here */ searchString,
      sortAsc,
      showRecommendedList,
      showComingSoonlist,
      IsMsp,
    },
    ref
  ) => {
    const { authTokens } = useAuth();
    const [loading, setLoading] = useState(false);
    const [upgradeOptions, setUpgradeOptions] = useState({});
    const [showConnectModal, setShowConnectModal] = useState(false);
    const [showMspConnectModal, setShowMspConnectModal] = useState(false);
    const history = useHistory();
    const [selectedIntegrationId, setSelectedIntegrationId] = useState("");
    const [pageData, setPageData] = useState(false);
    const [recommendedList, setRecommendedList] = useState([]);
    const [allTools, setAllTools] = useState([]);
    const [itemsVisible, setItemsVisible] = useState(1000);
    const [comingSoonTools, setComingSoonTools] = useState([]);
    const [initialTagsLength, setInitialTagsLength] = useState("empty");
    const [sections, setSections] = useState([]);
    const [selectedSection, setSelectedSection] = useState("All");
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);

    const [filters, setFilters] = useState({
      ObjectId: 0,
      IntegrationFilter_List: [
        {
          IntegrationFilter_Enum: 1,
          FilterText_List: null, // [""],
        },
        {
          IntegrationFilter_Enum: 2,
          FilterText_List: null, // [""],
        },
      ],
      TaskSort: 0,
      TaskSortOrder: 0,
    });
    async function showConnectToolModal(integrationId) {
      await getAPIData(`${502}`, authTokens)
        .then((response) => {
          if (response?.SuccessCount === 1) {
            setSelectedIntegrationId(integrationId);
            setShowConnectModal(true);
          } else if (
            response?.SuccessCount === 0 ||
            response?.SuccessCount === -1
          ) {
            setUpgradeOptions(
              response?.AccessObjList?.filter(
                (item) => item?.Question === "Integrations Visibility"
              )?.[0]?.UpgradeOptions ?? {}
            );
            setShowUpgradeModal(true);
          } else {
            CallToast(response?.Message, false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    }

    async function showConnectModalWithoutSubscription(_integrationId) {
      setSelectedIntegrationId(_integrationId);
      setShowConnectModal(true);
    }

    async function showMspConnectIntegrationModal(_integrationId) {
      setSelectedIntegrationId(_integrationId);
      setShowMspConnectModal(true);
    }
    useEffect(() => {
      setLoading(true);
      getPageData(filters);
      setSelectedSearchTab();
    }, []);

    async function getPageData(_filters) {
      await getAPIData("458", authTokens, {
        optionStrEnum1: JSON.stringify(_filters),
      })
        .then((response) => {
          setLoading(false);
          if (response?.mr?.Success) {
            setPageData(response);
            // Extracting Tags from the Integration List
            if (initialTagsLength === "empty") {
              let all_tags = [];
              for (
                let integrationIndex = 0;
                integrationIndex < response?.IntegrationList?.length;
                integrationIndex++
              ) {
                if (
                  response?.IntegrationList[integrationIndex].Tags_List
                    ?.length > 0
                ) {
                  for (
                    let tagIndex = 0;
                    tagIndex <
                    response?.IntegrationList[integrationIndex].Tags_List
                      ?.length;
                    tagIndex++
                  ) {
                    if (
                      all_tags.indexOf(
                        response?.IntegrationList[integrationIndex].Tags_List[
                          tagIndex
                        ]
                      ) === -1
                    ) {
                      all_tags.push(
                        response?.IntegrationList[integrationIndex].Tags_List[
                          tagIndex
                        ]
                      );
                    }
                  }
                }
              }

              setSections(["All", ...all_tags]);
              setInitialTagsLength("not-empty");
            }

            // Setting Integration lists
            let whole_list = response?.IntegrationList?.filter(
              (item) => item?.IntegrationId > 0
            );
            setRecommendedList(
              whole_list?.filter(
                (item) => item?.IntegrationMarketplaceEnum === 0
              )
            );
            setAllTools(
              whole_list?.filter(
                (item) => item?.IntegrationMarketplaceEnum === 1
              )
            );
            setComingSoonTools(
              whole_list?.filter(
                (item) => item?.IntegrationMarketplaceEnum === 2
              )
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }

    const [initialLoad, setInitialLoad] = useState(false);
    useEffect(() => {
      if (!initialLoad) {
        setInitialLoad(true);
        return;
      }

      getPageData(filters);
    }, [filters]);

    async function voteIntegration(integrationId) {
      await getAPIData("65", authTokens, {
        OptionEnum1: integrationId,
        OptionEnum2: 4,
      })
        .then((response) => {
          if (response?.Success) {
            getPageData(filters);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    useImperativeHandle(ref, () => ({
      searchIntegration(text) {
        let updated_data = { ...filters };

        updated_data.IntegrationFilter_List[1].FilterText_List = [text];

        setFilters(updated_data);
      },
    }));

    function filterIntegrationByTags(integration_list) {
      return integration_list
        .sort((a, b) => {
          if (sortAsc === true) {
            if (a.IntegrationName < b.IntegrationName) {
              return -1;
            }
            if (a.IntegrationName > b.IntegrationName) {
              return 1;
            }
            return 0;
          }
          if (sortAsc === false) {
            if (a.IntegrationName > b.IntegrationName) {
              return -1;
            }
            if (a.IntegrationName < b.IntegrationName) {
              return 1;
            }
            return 0;
          }

          return 0;
        })
        .filter((item) =>
          item?.IntegrationName?.toLowerCase()?.includes(
            searchString?.toLowerCase()
          )
        )
        .filter((integration) => {
          if (selectedSection === "All") {
            return true;
          } else {
            if (integration.Tags_List?.includes(selectedSection)) {
              return true;
            } else {
              return false;
            }
          }
        });
    }

    // To show selected Search Tab

    function setSelectedSearchTab() {
      const selectedSearchTab = new URLSearchParams(window.location.search).get(
        "selectedSearchTab"
      );
      if (selectedSearchTab) {
        setSelectedSection(selectedSearchTab);

        if (IsMsp) {
          return;
        }

        history.push({ search: "" });
      }
    }
    return (
      <>
        {loading ? (
          <div className="pt-5 pl-4">
            <AppMarketPlaceLoader />
          </div>
        ) : (
          <div className="appMarketPlace pl-4 pt-3">
            {/* All tabs for Fitering */}
            <div className="d-flex flex-wrap p-3">
              {sections?.length > 0 &&
                [
                  sections[0],
                  ...sections
                    .slice(1)
                    .sort((a, b) =>
                      a
                        .trim()
                        .toLowerCase()
                        .substring(0, 2)
                        .localeCompare(b.trim().toLowerCase().substring(0, 2))
                    ),
                ]
                  ?.slice(0, itemsVisible)
                  ?.map((section, sectionIndex) => {
                    return (
                      <div
                        className={`p-2 radius-4 mr-2 pointer mb-2 ${
                          selectedSection === section
                            ? "hg-blue-btn"
                            : "hg-cancel-btn"
                        }`}
                        onClick={() => {
                          setSelectedSection(section);
                          // let updated_data = { ...filters };
                          // if (section === "All") {
                          //   updated_data.IntegrationFilter_List[0].FilterText_List = [
                          //     "",
                          //   ];
                          // } else {
                          //   updated_data.IntegrationFilter_List[0].FilterText_List = [
                          //     section,
                          //   ];
                          // }
                          // setFilters(updated_data);
                        }}
                      >
                        {section}
                      </div>
                    );
                  })}
              <div
                className="pointer f-blue d-flex align-items-center"
                onClick={() => {
                  if (itemsVisible > 7) {
                    setItemsVisible(7);
                  } else {
                    setItemsVisible(700);
                  }
                }}
              >
                {itemsVisible === 7 ? "Show more" : "Show less"}
              </div>
            </div>

            {showRecommendedList && (
              <>
                <div className="d-flex align-items-start mb-3">
                  <img
                    src="/images/attack-surface/thunder-icon.svg"
                    alt=""
                    className="mr-2 mt-2"
                  />
                  <div>
                    <p className="f-16 f-600 mb-0">Recommended tools</p>
                    <div className="f-darkgrey">
                      ...based on what you have defined in the Attack Surface
                    </div>
                  </div>
                </div>
                {/* Recommended Tools List */}
                <div className="d-flex flex-wrap">
                  {filterIntegrationByTags(recommendedList)?.map(
                    (integration, integrationIndex) => {
                      return (
                        <IntegrationCard
                          integration={integration}
                          isRecommended={true}
                          refreshData={getPageData}
                          showConnectToolModal={showConnectToolModal}
                          IsMsp={IsMsp}
                          showConnectModalWithoutSubscription={
                            showConnectModalWithoutSubscription
                          }
                          showMspConnectIntegrationModal={
                            showMspConnectIntegrationModal
                          }
                        />
                      );
                    }
                  )}
                </div>
              </>
            )}

            {/* All tools */}
            <div className="f-16 f-600 my-3">All Tools</div>
            {/* All tools list */}
            <div className="d-flex flex-wrap">
              {filterIntegrationByTags(allTools)?.map(
                (integration, integrationIndex) => {
                  return (
                    <IntegrationCard
                      integration={integration}
                      isAllTool={true}
                      refreshData={getPageData}
                      showConnectToolModal={showConnectToolModal}
                      IsMsp={IsMsp}
                      showConnectModalWithoutSubscription={
                        showConnectModalWithoutSubscription
                      }
                      showMspConnectIntegrationModal={
                        showMspConnectIntegrationModal
                      }
                    />
                  );
                }
              )}
            </div>

            {/* Coming Soon Tools */}
            {/* Header */}

            {showComingSoonlist && (
              <>
                <div className="d-flex algn-items-center justify-content-between">
                  <div className="d-flex align-items-start mb-3">
                    <img
                      src="/images/attack-surface/grey-thumbs-up.svg"
                      alt=""
                      className="mr-2 mt-2"
                    />
                    <div>
                      <p className="f-16 f-600 mb-0">Coming Soon...</p>
                      <div className="f-darkgrey">
                        Vote for your favourite tools
                      </div>
                    </div>
                  </div>

                  {/* Right part fro sorting */}
                  <div></div>
                </div>

                {/* Voting Tools List */}
                <div className="d-flex flex-wrap">
                  {filterIntegrationByTags(comingSoonTools)?.map(
                    (integration, integrationIndex) => {
                      return (
                        <IntegrationCard
                          integration={integration}
                          isVotingCard={true}
                          refreshData={getPageData}
                          voteIntegration={voteIntegration}
                          IsMsp={IsMsp}
                          showConnectModalWithoutSubscription={
                            showConnectModalWithoutSubscription
                          }
                          showMspConnectIntegrationModal={
                            showMspConnectIntegrationModal
                          }
                        />
                      );
                    }
                  )}
                </div>
              </>
            )}
          </div>
        )}
        <ConnectIntegrationModal
          show={showConnectModal}
          hideModal={() => setShowConnectModal(false)}
          integrationId={selectedIntegrationId}
          refreshData={getPageData}
        />
        <MspConnectIntegrationModal
          show={showMspConnectModal}
          hideModal={() => setShowMspConnectModal(false)}
          integrationId={selectedIntegrationId}
          refreshData={getPageData}
        />

        <UpgradeToPremiumModal
          show={showUpgradeModal}
          hideModal={() => setShowUpgradeModal(false)}
          upgradeOptions={upgradeOptions}
        />
      </>
    );
  }
);

export default AppMarketPlace;

export const IntegrationCard = ({
  integration,
  isVotingCard,
  isRecommended,
  isAllTool,
  refreshData,
  voteIntegration,
  showConnectToolModal,
  showConnectModalWithoutSubscription,
  showMspConnectIntegrationModal,
  IsMsp,
}) => {
  const config = {
    angle: 90,
    spread: "360",
    startVelocity: 50,
    elementCount: 70,
    dragFriction: 0.3,
    duration: 4000,
    stagger: 1,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };
  const [isExploding, setIsExploding] = useState(false);

  const history = useHistory();
  return (
    <div
      className="bg-grey bg-hover-dark-grey radius-8 d-flex align-items-center flex-column mr-3 mb-3 p-3 pointer"
      style={{
        width: "360px",
      }}
      onClick={() => {
        if (!isVotingCard) {
          if (IsMsp) {
            history.push(
              `/mspv2/integrationDetails/${integration?.IntegrationId}`
            );
          } else {
            history.push(`/integrations/${integration?.IntegrationId}`);
          }
          TrackUser("Tool description clicked", {
            type: integration?.IntegrationName,
          });
        }
      }}
    >
      {/* first row */}
      <div
        className="d-flex align-items-center justify-content-between w-100 mb-2"
        style={{
          height: "auto",
          width: "100%",
        }}
      >
        <div
          className="f-500 f-black d-flex align-items-center text-ellipsis"
           
        >
          <img
            src={`/images/attack-surface/integrations/${integration?.IntegrationId}.webp`}
            alt=""
            className="mr-2 radius-4"
            width={34}
            height={34}
          />
          <div className="text-ellipsis">{TrimString(integration?.IntegrationName, 50)}</div>
        </div>
        <div className="radius-2 d-flex align-items-center justify-content-end       ">
          {/* Is Recomended Card */}
          {isRecommended && (
            <>
              {integration?.ButtonFunction !== 2 && (
                <OverlayTrigger
                  placement="bottom"
                  trigger={
                    viewOnlyAccess("AccessState_Integrations")
                      ? ["hover", "focus"]
                      : []
                  }
                  delay={{ show: 200, hide: 250 }}
                  overlay={
                    <Tooltip id={`tooltip-1365`}>
                      This feature is managed by your service provider
                    </Tooltip>
                  }
                >
                  <div>
                    <Button
                      className={` ${
                        viewOnlyAccess("AccessState_Integrations")
                          ? "btn-disable"
                          : "hg-blue-btn"
                      } d-flex align-items-center`}
                      onClick={(e) => {
                        e.stopPropagation(integration?.IntegrationId);
                        if (
                          IsMsp &&
                          integration?.TechnologyTitle?.toLowerCase() === "psa"
                        ) {
                          showMspConnectIntegrationModal(
                            integration?.IntegrationId
                          );
                        } else if (
                          IsMsp &&
                          integration?.TechnologyTitle?.toLowerCase() !== "psa"
                        ) {
                          showConnectModalWithoutSubscription(
                            integration?.IntegrationId
                          );
                        } else {
                          showConnectToolModal(integration?.IntegrationId);
                        }
                        TrackUser("Clicked Integrate Tool");
                      }}
                    >
                      {viewOnlyAccess("AccessState_Integrations") ? (
                        <img
                          alt=""
                          src="/images/attack-surface/small-lock.svg"
                          className="mr-2"
                        />
                      ) : (
                        <img
                          src="/images/attack-surface/white-plus-icon.svg"
                          alt=""
                          className=" mr-2"
                        />
                      )}
                      Connect
                    </Button>
                  </div>
                </OverlayTrigger>
              )}
              {integration?.ButtonFunction === 2 && (
                 <div
                 className="radius-2 d-flex align-items-center justify-content-end p-1 px-2 f-12 f-500 expand-on-hover"
                 style={{
                   color: `${integration?.IntegrationStateColour}`,
                 }}
               >
                 <img
                   alt=""
                   className="mr-2"
                   width={14}
                   height={14}
                   src={
                     integration?.IntegrationStateIconURL
                   }
                 />
                 <div
                   style={{
                     height: "20px",
                     whiteSpace:"nowrap"
                   }}
                 >
                   {integration?.IntegrationState}
                 </div>
               </div>
              )}
            </>
          )}
          <Confetti active={isExploding} config={config} />

          {/* Is All tools  Card */}
          {isAllTool && (
            <>
              {integration?.ButtonFunction !== 2 && (
                <OverlayTrigger
                  placement="bottom"
                  trigger={
                    viewOnlyAccess("AccessState_Integrations")
                      ? ["hover", "focus"]
                      : []
                  }
                  delay={{ show: 200, hide: 250 }}
                  overlay={
                    <Tooltip id={`tooltip-1365`}>
                      This feature is managed by your service provider
                    </Tooltip>
                  }
                >
                  <div>
                    <Button
                      className={` ${
                        viewOnlyAccess("AccessState_Integrations")
                          ? "btn-disable"
                          : "hg-tranparent-grey-btn"
                      } d-flex align-items-center`}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (
                          IsMsp &&
                          integration?.TechnologyTitle?.toLowerCase() === "psa"
                        ) {
                          showMspConnectIntegrationModal(
                            integration?.IntegrationId
                          );
                        } else if (
                          IsMsp &&
                          integration?.TechnologyTitle?.toLowerCase() !== "psa"
                        ) {
                          showConnectModalWithoutSubscription(
                            integration?.IntegrationId
                          );
                        } else {
                          showConnectToolModal(integration?.IntegrationId);
                        }
                        TrackUser("Clicked Integrate Tool");
                      }}
                    >
                      {viewOnlyAccess("AccessState_Integrations") ? (
                        <img
                          alt=""
                          src="/images/attack-surface/small-lock.svg"
                          className="mr-2"
                        />
                      ) : (
                        <img
                          src="/images/actioncenter/plusgrey.svg"
                          alt=""
                          className=" mr-2"
                        />
                      )}
                      Connect
                    </Button>
                  </div>
                </OverlayTrigger>
              )}

              {integration?.ButtonFunction === 2 && (
                <div
                className="radius-2 d-flex align-items-center justify-content-end p-1 px-2 f-12 f-500 expand-on-hover"
                style={{
                  color: `${integration?.IntegrationStateColour}`,
                }}
              >
                <img
                  alt=""
                  className="mr-2"
                  width={14}
                  height={14}
                  src={
                    integration?.IntegrationStateIconURL
                  }
                />
                <div
                  style={{
                    height: "20px",
                    whiteSpace:"nowrap"
                  }}
                >
                  {integration?.IntegrationState}
                </div>
              </div>
              )}
            </>
          )}

          {/* If isVoting Card */}
          {isVotingCard && (
            <>
              {integration?.IsUpVoted ? (
                <div className="f-green">
                  <img
                    alt=""
                    src="/images/attack-surface/voted-tick.svg"
                    className="mr-1"
                  />
                  Voted
                </div>
              ) : (
                <Button
                  className="voting-btn d-flex align-items-center"
                  onClick={() => {
                    voteIntegration(integration?.IntegrationId);
                    setIsExploding(true);
                    TrackUser("Integration Upvote clicked", {
                      type: integration?.IntegrationName,
                    });
                    setTimeout(() => {
                      setIsExploding(false);
                    }, 2500);
                  }}
                >
                  <img
                    src="/images/attack-surface/white-thumbs-up.svg"
                    alt=""
                    className=" mr-2"
                  />
                  Vote
                </Button>
              )}
            </>
          )}
        </div>
      </div>
      {/* second row */}
      {isRecommended && (
        <>
          <div className="w-100 d-flex flex-column justify-content-between h-100">
            <div className="f-grey " title={integration?.IntegrationSummary}>
              {TrimString(integration?.IntegrationSummary, 125)}
            </div>
            <div className="d-flex align-items-end justify-content-between">
              <div className="d-flex align-items-center w-100 mt-2">
                <MultiTagsUI tagList={integration?.Tags_List} />
              </div>

              <div className="d-flex align-items-center justify-content-end w-100">
                <img
                  src="/images/attack-surface/thunder-icon.svg"
                  alt=""
                  className=""
                />
              </div>
            </div>
          </div>
        </>
      )}
      {isAllTool && (
        <>
          <div className="w-100 d-flex flex-column justify-content-between h-100">
            <div className="f-grey " title={integration?.IntegrationSummary}>
              {TrimString(integration?.IntegrationSummary, 125)}
            </div>
            <div className="d-flex align-items-center w-100 mt-2">
              <MultiTagsUI tagList={integration?.Tags_List} />
            </div>
          </div>
        </>
      )}

      {isVotingCard && (
        <>
          <div className="w-100 d-flex align-items-center justify-content-start">
            <div className="d-flex align-items-center w-100 mt-2">
              <MultiTagsUI tagList={integration?.Tags_List} />
            </div>
          </div>

          <div className="d-flex align-items-center w-100 mb-2">
            {integration?.type?.map((item) => {
              return (
                <div
                  className="radius-2 p-1 px-2 f-12 f-500 mr-2"
                  style={{
                    background: `${item.color}30`,
                    color: `${item.color}`,
                  }}
                >
                  {item.name === "Default" && (
                    <img
                      alt=""
                      className="mr-2"
                      src="/images/msp/integrations/default-integration-icon.svg"
                    />
                  )}
                  {item.name}
                </div>
              );
            })}
          </div>
          {/* Third row */}
          <div className="w-100 d-flex flex-column mt-1">
            <div className="d-flex align-items-center justify-content-between mb-1">
              <div className="f-grey">Added</div>
              <div className="f-grey">{integration?.UpVoteDate ?? "N/A"}</div>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="f-grey">Votes</div>
              <div className="f-grey"> {integration?.UpVoteCount} </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const AppMarketPlaceLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="8" ry="8" width="30%" height="220" />
      <rect x="31%" y="0" rx="8" ry="8" width="30%" height="220" />
      <rect x="62%" y="0" rx="8" ry="8" width="30%" height="220" />

      <rect x="0" y="240" rx="8" ry="8" width="30%" height="220" />
      <rect x="31%" y="240" rx="8" ry="8" width="30%" height="220" />
      <rect x="62%" y="240" rx="8" ry="8" width="30%" height="220" />

      <rect x="0" y="480" rx="8" ry="8" width="30%" height="220" />
      <rect x="31%" y="480" rx="8" ry="8" width="30%" height="220" />
      <rect x="62%" y="480" rx="8" ry="8" width="30%" height="220" />
    </ContentLoader>
  );
};

export const MultiTagsUI = ({ tagList }) => {
  return (
    <div className="d-flex align-items-center">
      {tagList?.slice(0, 2).map((tagName) => (
        <SingleTagUI itemName={tagName} />
      ))}
      {/* if tags length more than 2 */}

      {tagList?.length > 2 && (
        <div>
          <Dropdown
            className="interationv2_tagDropdown"
            onClick={(e) => e.stopPropagation()}
          >
            <Dropdown.Toggle className="">
              <div
                className="radius-2 p-1 px-2 f-12 f-500 mr-2"
                style={{
                  background: `${"#616778"}30`,
                  color: `${"#616778"}`,
                  // whiteSpace: "nowrap",
                  // overflow: "hidden",
                  // textOverflow: "ellipsis",
                }}
              >
                +{tagList?.length - 2}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="d-flex flex-column px-2">
              {tagList?.slice(2, 1000)?.map((tagName) => (
                <div className="mb-2">
                  <SingleTagUI itemName={tagName} />
                </div>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export const SingleTagUI = ({ itemName }) => {
  return (
    <div
      className="radius-2 p-1 px-2 f-12 f-500 mr-2 w-max-content"
      style={{
        background: `${"#616778"}30`,
        color: `${"#616778"}`,
        // whiteSpace: "nowrap",
        // overflow: "hidden",
        // textOverflow: "ellipsis",
      }}
    >
      {itemName}
    </div>
  );
};
