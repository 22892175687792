import React from "react";
import { Dropdown, Row, Col } from "react-bootstrap";
import "./supplyComplianceManager.scss";

const ComplianceManagerCard = (props) => {
  const {
    mainIcon,
    title,
    date,
    statusIcon,
    status,
    downloadURL,
    statusColor,
  } = props;

  return (
    <div className="complianceManagerCard p-3">
      <div className="d-flex flex-column align-items-center">
        <img
          alt=""
          width={48}
          height={48}
          className="mb-3 mt-4"
          src={mainIcon}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = "/images/no-image.png";
          }}
        />
        <div className="f-16 f-500 f-black mb-2">{title}</div>
        <div className="d-flex align-items-center">
          <img
            alt=""
            className="mr-2"
            src="/images/supply-chain/comp-cloud.svg"
          />
          <div className="f-darkgrey f-500  d-flex align-items-center">
            {date}
          </div>
        </div>
      </div>
  {downloadURL &&    <hr style={{ borderTop: "1px solid #EBEDF1" }} />}
      <div className="d-flex align-items-center justify-content-between mb-2 mt-4">
        <div className="d-flex align-items-center">
     { statusIcon&&    <img
            alt=""
            width={14}
            height={14}
            className="mr-2"
            src={statusIcon}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "/images/no-image.png";
            }}
          />}
          <div
            className="d-flex align-items-center"
            style={{
              color: statusColor,
            }}
          >
            {status}{" "}
          </div>
        </div>
        <div className="menu-dropdown">
 {  downloadURL &&       <Dropdown className="device-dropdown">
            <Dropdown.Toggle>
              <img alt="" src="/images/horizontal-dots.svg" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div>
                <Row>
                  <Col xs={12} className=" pointer ">
                    <a
                      href={downloadURL}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <div
                        className="drop-item d-flex"
                        onClick={(e) => {
                          e.target.closest(".device-dropdown").click();
                        }}
                      >
                        <img
                          alt=""
                          className="mr-2 invert-filter"
                          src="/images/complianceManager/downloadDownIcon.svg"
                        />
                  {   TitleWiseDownloadText[title] ??   'Download certificate'}
                      </div>
                    </a>
                  </Col>
                  <Col xs={12} className=" d-none pointer ">
                    <div
                      className="drop-item pl-3 f-danger"
                      onClick={(e) => {
                        e.target.closest(".device-dropdown").click();
                      }}
                    >
                      <img
                        alt=""
                        className="mr-2 "
                        src="/images/complianceManager/deleteIcon.svg"
                      />
                      Delete
                    </div>
                  </Col>
                </Row>
              </div>
            </Dropdown.Menu>
          </Dropdown>}
        </div>
      </div>
    </div>
  );
};

export default ComplianceManagerCard;


const TitleWiseDownloadText = {
  "Cyber Essentials Certificate": "Download certificate",
  "Incident Response Vision & Mission": "Download IR Vision & Mission",
  "Incident Response Plan": "Download IR Plan",
  "Incident Response Procedure": "Download IR Procedure",
  "IT BCDR Plan": "Download BCDR Plan",
};