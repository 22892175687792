import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { TrimString } from "../../../Utils/StringTrimmer";

// import "../../../../MSPSettingsv2/mspMembersAccess/mspMembersAccess.scss";
// import "../../../Components/MSPSettingsv2/mspMembersAccess/MspMembersAccess.scss";
// import "../Components/MSPSettingsv2/mspMembersAccess/mspMembersAccess.scss";
// import '../../../MSPSettingsv2/mspMembersAccess/mspMembersAccess.scss';
const MultiSelectDropdown = (props) => {
  const { locked, disabled, placeholder, placeholderIcon, inputDisable } =
    props;
  const {
    row,
    currentWord,
    isTemplate,
    setCurrentWord,
    block,
    setData_to_send,
    setSaveTemplateStatus,
    AssessmentBlock,
    setAssessmentBlock,
    setProfilingBlocks,
    isContributorView,
    isSent,
  } = props;
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [errorInput, setErrorInput] = useState(false);

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    setSelectedOptions(
      props.selected === null || props.selected === "" ? [] : props.selected
    );
    if (typeof props.selected === "object" && props.selected?.length === 0) {
      setInputValue("");
    }
  }, [props.selected]);
  return (
    <Dropdown
      className="members-multiselect-dropdown"
      disabled={locked}
      style={{
        pointerEvents: `${locked || disabled ? "none" : "all"}`,
      }}
    >
      <Dropdown.Toggle
        className={`${errorInput ? "input-error" : ""}  `}
        style={{ backgroundColor: "transparent !important" }}
      >
        <div
          className="d-flex"
          style={{
            gap: "4px",
            flexWrap: "wrap",
          }}
        >
          <div
            className="d-flex flex-wrap"
            style={{
              gap: "4px",
              flexWrap: "wrap",
            }}
          >
            {selectedOptions &&
              selectedOptions?.map((selected, selectedIndex) => {
                return (
                  <div
                    key={selectedIndex + selected?.Text}
                    className="d-flex align-items-center chip-item"
                    title={selected?.Text}
                  >
                    <span className="  d-inline-flex f-black">
                      {selected?.Text}
                    </span>

                    <img
                      alt=""
                      src="/images/attack-surface/grey-cross-icon.svg"
                      className={`ml-1 pointer ${locked ? "d-none" : ""}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        let updated_data = [...selectedOptions];
                        let Index = updated_data.findIndex(
                          (item) => item?.Text === selected?.Text
                        );
                        updated_data.splice(Index, 1);
                        setSelectedOptions(updated_data);

                        let selectedArray = [];
                        updated_data.map((el) => {
                          selectedArray.push(el.Text);
                        });

                        if (!inputDisable) {
                          e.preventDefault();
                          setCurrentWord({
                            word: "",
                            id: 0,
                          });
                          let newField = block.Question_List?.map((item) => {
                            if (item === row) {
                              if (
                                !item.AnswerArrayText ||
                                item.AnswerArrayText?.length < 0
                              ) {
                                item.AnswerArrayText = [];
                              }
                              item.AnswerArrayText = selectedArray;
                              item.QuestionStatusText = "Answered";
                              item.QuestionStatus = 3;
                              item.QuestionTypeEnum = 8;
                              setData_to_send([item]);
                              setSaveTemplateStatus(true);
                            }
                            return item;
                          });
                          console.log(newField);
                          let temp2 = AssessmentBlock.map((q) => {
                            if (q === block) {
                              q.Question_List = newField;
                            }
                            return q;
                          });
                          setAssessmentBlock(temp2);
                          console.log('assesm block 8 -------', AssessmentBlock, temp2);

                          setProfilingBlocks(AssessmentBlock);
                        }
                      }}
                    />
                  </div>
                );
              })}
          </div>
          <div className="d-flex flex-row align-items-center">
            {placeholderIcon}
            <input
              tabIndex={-1}
              placeholder={placeholder ? placeholder : "Add"}
              type="text"
              className={`border-none w-100 h-100 ${locked ? "d-none-" : ""} `}
              style={{
                pointerEvents: `${locked ? "none" : "all"}`,
              }}
              disabled={isContributorView || !isSent ? true : false}
              // value={inputValue}
              value={
                !inputDisable
                  ? row.QuestionText === currentWord.id
                    ? currentWord.word
                    : ""
                  : ""
              }
              onChange={(e) => {
                if (!isContributorView || isSent) {
                  if (e.target.value.trim() === "") {
                    setErrorInput(true);
                  }
                  if (!inputDisable) {
                    if (!isTemplate) {
                      setCurrentWord({
                        word: e.target.value,
                        id: row.QuestionText,
                      });
                    }
                  }
                }
              }}
              onBlur={() => {
                setErrorInput(false);
                if (currentWord.word?.trim() === "") {
                  setCurrentWord({
                    word: "",
                    id: row.QuestionText,
                  });
                }
              }}
              onKeyDown={(e) => {
                if (!inputDisable) {
                  if (e.key === "Enter" && currentWord.word.trim() !== "") {
                    e.preventDefault();
                    setCurrentWord({
                      word: "",
                      id: 0,
                    });
                    let newField = block.Question_List?.map((item) => {
                      if (item === row) {
                        console.log(currentWord);
                        if (
                          !item.AnswerArrayText ||
                          item.AnswerArrayText?.length < 0
                        ) {
                          item.AnswerArrayText = [];
                        }
                        item.AnswerArrayText.push(currentWord.word.trim());
                        item.QuestionStatusText = "Answered";
                        item.QuestionStatus = 3;
                        item.QuestionTypeEnum = 8;
                        setData_to_send([item]);
                        setSaveTemplateStatus(true);
                      }
                      return item;
                    });
                    console.log(newField);
                    let temp2 = AssessmentBlock.map((q) => {
                      if (q === block) {
                        q.Question_List = newField;
                      }
                      return q;
                    });
                    setAssessmentBlock(temp2);
                    console.log('assesm block 9 -------', AssessmentBlock, temp2);

                    setProfilingBlocks(AssessmentBlock);
                  }
                }
              }}
              // onChange={(e) => {
              //   if (!inputDisable) {
              //     setInputValue(e.target.value);
              //     e.stopPropagation();
              //   }
              // }}
              // onKeyDown={(e) => {
              //   if (!inputDisable) {
              //     if (e.key === "Enter" && inputValue.trim() !== "") {
              //       e.preventDefault();
              //       let selected_list = [...selectedOptions];
              //       selected_list.push({
              //         userName: inputValue,
              //         objId_PSAMember: 0,
              //         objId_PSAContact: 0,
              //       });
              //       setSelectedOptions(selected_list);
              //       props.onChange(selected_list);
              //       setInputValue("");
              //     }
              //     e.stopPropagation();
              //   }
              // }}
            />
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="hide-scrollbar"
        style={{ maxHeight: "300px", overflow: "auto" }}
      >
        <div className="px-2">
          {data
            ?.filter((filterItem) => {
              return filterItem?.Text?.toLowerCase().includes(
                currentWord?.word?.toLowerCase()
              );
            })
            ?.map((item, itemIndex) => {
              return (
                <div
                key={itemIndex + item?.Text}
                  className={`${
                    selectedOptions?.filter((Item) => Item?.Text === item.Text)
                      ?.length > 0
                      ? "f-black"
                      : "f-grey"
                  } 
                        
                        ${item.Text === "" ? "d-none" : "d-flex"}
                        
                        f-500 f-15 pointer py-1 ml-2  align-items-center justify-content-between`}
                  onClick={(e) => {
                    if (
                      selectedOptions?.filter(
                        (Item) => Item?.Text === item.Text
                      )?.length === 0
                    ) {
                      let selected_list = [...selectedOptions];
                      selected_list.push(item);
                      setSelectedOptions(selected_list);
                      // props.onChange(selected_list);
                      let selectedArray = [];
                      selected_list.map((el) => {
                        selectedArray.push(el.Text);
                      });

                      if (!inputDisable) {
                        e.preventDefault();
                        setCurrentWord({
                          word: "",
                          id: 0,
                        });
                        let newField = block.Question_List?.map((item) => {
                          if (item === row) {
                            if (
                              !item.AnswerArrayText ||
                              item.AnswerArrayText?.length < 0
                            ) {
                              item.AnswerArrayText = [];
                            }
                            item.AnswerArrayText = selectedArray;
                            item.QuestionStatusText = "Answered";
                            item.QuestionStatus = 3;
                            item.QuestionTypeEnum = 8;
                            setData_to_send([item]);
                            setSaveTemplateStatus(true);
                          }
                          return item;
                        });
                        console.log(newField);
                        let temp2 = AssessmentBlock.map((q) => {
                          if (q === block) {
                            q.Question_List = newField;
                          }
                          return q;
                        });
                        setAssessmentBlock(temp2);
                        console.log('assesm block 10 -------', AssessmentBlock, temp2);

                        setProfilingBlocks(AssessmentBlock);
                      }
                    } else {
                    }
                  }}
                >
                  <div className="ml-2">
                    <div className="f-500 f-grey">{item.Text}</div>
                    {/* <div className="f-12 f-darkgrey">{TrimString(item.userName,30)}</div> */}
                  </div>
                  {selectedOptions?.filter((Item) => Item?.Text === item.Text)
                    ?.length > 0 && (
                    <img
                      alt=""
                      width={11}
                      className="mb-1 mr-2"
                      src="/images/settings-v2/black-tick.svg"
                    />
                  )}
                </div>
              );
            })}
          {data?.length == 0 && (
            <div className="f-darkgrey">No options yet</div>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MultiSelectDropdown;
