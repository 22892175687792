import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// import AllIntegrations from "./AllIntegrations";
// import ConnectedIntegrations from "./ConnectedIntegrations";
import MapClients from "../MapClients";
// import MapIntegrations from "./MapIntegrations";
import "../mspIntegrations.scss";
import MapContacts from "../MapContacts";
import MapProducts from "../MapProducts";
import { Button } from "react-bootstrap";
import MapMembers from "../MapMembers";
import MapSummary from "../MapSummary";
import { getAPIData, postData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import capitalize from "../../../../Utils/capitalizeWord";

const MSPMappings = () => {
  const history = useHistory();
  const { authTokens } = useAuth();
  const Type = new URLSearchParams(window.location.search).get("type");
  const [headers, setHeaders] = useState([]);
  const IntegrationId = JSON.parse(
    localStorage.getItem("importedIntegrtionId")
  );
  const [processing, setProcessing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selectedHeaderTab, setSelectedHeaderTab] = useState(0);

  // clients,---0
  // contacts,----1
  // categories,----2
  // products,------3
  // members,------4

  useEffect(() => {
    let importListData = localStorage.getItem("importListData");
    if (importListData) {
      setHeaders(JSON.parse(importListData));
    }
  }, []);

  // SETUP FOR CLIENTS PART+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const [clientPageData, setClientPageData] = useState({});
  const [clientsMappingData, setClientsMappingData] = useState({});

  /**
   *
   * @params
   * kwargs:{
   * NextPageNo:1,
   * searchString:"",
   * hideIgnored: 0 or 1
   * }
   */

  function getClientsListData(kwargs) {
    return new Promise((resolve, reject) => {
      getAPIData(`${701}`, authTokens, {
        optionEnum1: 0,
        optionEnum2:
          kwargs?.NextPageNo !== null && kwargs?.NextPageNo !== undefined
            ? kwargs?.NextPageNo
            : clientPageData?.NextPageNo !== null &&
              clientPageData?.NextPageNo !== undefined
            ? clientPageData?.NextPageNo
            : 1,

        optionEnum3: 30,
        optionEnum4: IntegrationId,
        optionEnum5: kwargs?.hideIgnored ?? 0,
        optionStrEnum1: kwargs?.searchString ?? "",
        optionStrEnum2:
          kwargs?.filters ??
          (window.localStorage.getItem("clientFilterGroup")
            ? JSON.parse(window.localStorage.getItem("clientFilterGroup"))
            : ""),
        optionStrEnum3:
          kwargs?.sortOrder ??
          (window.localStorage.getItem("clientSortOrder")
            ? JSON.parse(window.localStorage.getItem("clientSortOrder"))
            : 0),
      })
        .then((response) => {
          if (response?.Success) {
            try {
              if (
                Object.keys(clientPageData).length === 0 ||
                kwargs?.NextPageNo === 1
              ) {
                let new_output = generateOutputForTable(response);
                setClientPageData({
                  ...new_output,
                  ImportClientList: (new_output?.ImportClientList || "")
                    .split(",")
                    .filter(Boolean)
                    .map((word) => word.trim()),
                });
              } else {
                let new_output = generateOutputForTable(response);

                let old_client_data = [...clientPageData.ObjectList];
                old_client_data.push(...new_output.ObjectList);

                let updated_data = {
                  ...new_output,
                  ObjectList: old_client_data,
                };

                setClientPageData({
                  ...updated_data,
                  ObjectList: updated_data?.ObjectList,
                  ImportClientList: (updated_data?.ImportClientList || "")
                    .split(",")
                    .filter(Boolean)
                    .map((word) => word.trim()),
                });
              }

              resolve(response);
            } catch (err) {
              console.log("getclientsdata", err);
            }
          } else {
            reject(false);
          }
        })
        .catch(() => {
          reject(false);
        });
    });
  }
  // Clients Mapping List
  /**
   *
   * @params
   * kwargs:{
   * NextPageNo:1,
   * searchString
   * }
   */

  function getClientsMappingListData(kwargs) {
    return new Promise((resolve, reject) => {
      getAPIData(`${702}`, authTokens, {
        optionEnum1: 0,
        optionEnum2:
          kwargs?.NextPageNo !== null && kwargs?.NextPageNo !== undefined
            ? kwargs?.NextPageNo
            : clientsMappingData?.NextPageNo !== null &&
              clientsMappingData?.NextPageNo !== undefined
            ? clientsMappingData?.NextPageNo
            : 1,

        optionEnum3: kwargs?.count ?? 12,
        optionEnum4: kwargs?.skip ?? 0,
        optionStrEnum1: kwargs?.searchString ?? "",
      })
        .then((response) => {
          if (response?.Success) {
            if (
              Object.keys(clientsMappingData).length === 0 ||
              kwargs?.NextPageNo === 1
            ) {
              setClientsMappingData(response);
            } else {
              let new_output = { ...response };

              let old_client_mapping_data = [...clientsMappingData.ObjectList];
              old_client_mapping_data.push(...new_output.ObjectList);

              let updated_data = {
                ...new_output,
                ObjectList: old_client_mapping_data,
              };

              setClientsMappingData(updated_data);
            }

            resolve(response);
          } else {
            reject(true);
          }
        })
        .catch(() => {
          reject(true);
        });
    });
  }

  async function updateClientData(_data) {
    await postData(authTokens, "UpdatePSAObjects", {
      optionEnum1: 0,
      objectList: JSON.stringify(_data),
      integrationId: IntegrationId,
    }).then((response) => {
      if (response) {
      }
    });
  }

  //  SETUP CLIENTS PART END++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  // SETUP CONTACTS PART START++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const [contactPageData, setContactPageData] = useState({});

  /**
   *
   * @params
   * kwargs:{
   * NextPageNo:1,
   * searchString:"",
   * hideIgnored: 0 or 1
   * }
   */
  function getContactsListData(kwargs) {
    return new Promise((resolve, reject) => {
      getAPIData(`${701}`, authTokens, {
        optionEnum1: 1,
        optionEnum2:
          kwargs?.NextPageNo !== null && kwargs?.NextPageNo !== undefined
            ? kwargs?.NextPageNo
            : contactPageData?.NextPageNo !== null &&
              contactPageData?.NextPageNo !== undefined
            ? contactPageData?.NextPageNo
            : 1,
        optionEnum3: 10000,
        optionEnum4: IntegrationId,
        optionEnum5: kwargs?.hideIgnored ?? 0,
        optionStrEnum1: kwargs?.searchString ?? "",
      })
        .then((response) => {
          if (response?.Success) {
            try {
              if (
                Object.keys(contactPageData).length === 0 ||
                kwargs?.NextPageNo === 1
              ) {
                let new_output = generateOutputForTable(response);
                setContactPageData({
                  ...new_output,
                  ObjectList: new_output?.ObjectList,

                  // ?.sort((a, b) => {
                  //   if (
                  //     a.state === "Not_Selected" &&
                  //     b.state !== "Not_Selected"
                  //   ) {
                  //     return -1; // a should come before b
                  //   } else if (
                  //     a.state !== "Not_Selected" &&
                  //     b.state === "Not_Selected"
                  //   ) {
                  //     return 1; // b should come before a
                  //   } else if (
                  //     a.state === "Not_Selected" &&
                  //     b.state === "Not_Selected"
                  //   ) {
                  //     return a.name.localeCompare(b.name); // sort alphabetically for state "Not_Selected"
                  //   } else {
                  //     return a.name.localeCompare(b.name); // sort alphabetically for other states
                  //   }
                  // }),
                });
              } else {
                let new_output = generateOutputForTable(response);

                let old_client_data = [...contactPageData.ObjectList];
                old_client_data.push(...new_output.ObjectList);

                let updated_data = {
                  ...new_output,
                  ObjectList: old_client_data,
                };

                setContactPageData({
                  ...updated_data,
                  ObjectList: updated_data?.ObjectList,

                  // ?.sort((a, b) => {
                  //   if (
                  //     a.state === "Not_Selected" &&
                  //     b.state !== "Not_Selected"
                  //   ) {
                  //     return -1; // a should come before b
                  //   } else if (
                  //     a.state !== "Not_Selected" &&
                  //     b.state === "Not_Selected"
                  //   ) {
                  //     return 1; // b should come before a
                  //   } else if (
                  //     a.state === "Not_Selected" &&
                  //     b.state === "Not_Selected"
                  //   ) {
                  //     return a.name.localeCompare(b.name); // sort alphabetically for state "Not_Selected"
                  //   } else {
                  //     return a.name.localeCompare(b.name); // sort alphabetically for other states
                  //   }
                  // }),
                });
              }

              resolve(response);
            } catch (err) {
              console.log("getclientsdata", err);
            }
          } else {
            reject(false);
          }
        })
        .catch(() => {
          reject(false);
        });
    });
  }

  async function updateContactData(_data) {
    await postData(authTokens, "UpdatePSAObjects", {
      optionEnum1: 1,
      objectList: JSON.stringify(_data),
      integrationId: IntegrationId,
    }).then((response) => {
      if (response) {
      }
    });
  }
  // SETUP CONTACTS PART END++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  // SETUP FOR MEMBERS PART START++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const [memberPageData, setMemberPageData] = useState({});
  const [membersMappingData, setMembersMappingData] = useState({});

  /**
   *
   * @params
   * kwargs:{
   * NextPageNo:1,
   * searchString:"",
   * hideIgnored: 0 or 1
   * }
   */
  function getMembersListData(kwargs) {
    return new Promise((resolve, reject) => {
      getAPIData(`${701}`, authTokens, {
        optionEnum1: 4,
        optionEnum2:
          kwargs?.NextPageNo !== null && kwargs?.NextPageNo !== undefined
            ? kwargs?.NextPageNo
            : memberPageData?.NextPageNo !== null &&
              memberPageData?.NextPageNo !== undefined
            ? memberPageData?.NextPageNo
            : 1,
        optionEnum3: 10000,
        optionEnum4: IntegrationId,
        optionEnum5: kwargs?.hideIgnored ?? 0,
        optionStrEnum1: kwargs?.searchString ?? "",
      })
        .then((response) => {
          if (response?.Success) {
            try {
              if (
                Object.keys(memberPageData).length === 0 ||
                kwargs?.NextPageNo === 1
              ) {
                let new_output = generateOutputForTable(response);
                setMemberPageData({
                  ...new_output,
                  ObjectList: new_output?.ObjectList,

                  // ?.sort((a, b) => {
                  //   if (
                  //     a.state === "Not_Selected" &&
                  //     b.state !== "Not_Selected"
                  //   ) {
                  //     return -1; // a should come before b
                  //   } else if (
                  //     a.state !== "Not_Selected" &&
                  //     b.state === "Not_Selected"
                  //   ) {
                  //     return 1; // b should come before a
                  //   } else if (
                  //     a.state === "Not_Selected" &&
                  //     b.state === "Not_Selected"
                  //   ) {
                  //     return a.name.localeCompare(b.name); // sort alphabetically for state "Not_Selected"
                  //   } else {
                  //     return a.name.localeCompare(b.name); // sort alphabetically for other states
                  //   }
                  // }),
                });
              } else {
                let new_output = generateOutputForTable(response);

                let old_client_data = [...memberPageData.ObjectList];
                old_client_data.push(...new_output.ObjectList);

                let updated_data = {
                  ...new_output,
                  ObjectList: old_client_data,
                };

                setMemberPageData({
                  ...updated_data,
                  ObjectList: updated_data?.ObjectList,

                  // ?.sort((a, b) => {
                  //   if (
                  //     a.state === "Not_Selected" &&
                  //     b.state !== "Not_Selected"
                  //   ) {
                  //     return -1; // a should come before b
                  //   } else if (
                  //     a.state !== "Not_Selected" &&
                  //     b.state === "Not_Selected"
                  //   ) {
                  //     return 1; // b should come before a
                  //   } else if (
                  //     a.state === "Not_Selected" &&
                  //     b.state === "Not_Selected"
                  //   ) {
                  //     return a.name.localeCompare(b.name); // sort alphabetically for state "Not_Selected"
                  //   } else {
                  //     return a.name.localeCompare(b.name); // sort alphabetically for other states
                  //   }
                  // }),
                });
              }

              resolve(response);
            } catch (err) {
              console.log("getmeebrsdata", err);
            }
          } else {
            reject(false);
          }
        })
        .catch(() => {
          reject(false);
        });
    });
  }
  //Clients Mapping List
  /**
   *
   * @params
   * kwargs:{
   * NextPageNo:1,
   * searchString
   * }
   */
  function getMembersMappingListData(kwargs) {
    return new Promise((resolve, reject) => {
      getAPIData(`${702}`, authTokens, {
        optionEnum1: 4,
        optionEnum2:
          kwargs?.NextPageNo !== null && kwargs?.NextPageNo !== undefined
            ? kwargs?.NextPageNo
            : membersMappingData?.NextPageNo !== null &&
              membersMappingData?.NextPageNo !== undefined
            ? membersMappingData?.NextPageNo
            : 1,
        optionEnum3: kwargs?.count ?? 7,
        optionEnum4: kwargs?.skip ?? 0,
        optionStrEnum1: kwargs?.searchString ?? "",
      })
        .then((response) => {
          if (response?.Success) {
            if (
              Object.keys(membersMappingData).length === 0 ||
              kwargs?.NextPageNo === 1
            ) {
              setMembersMappingData(response);
            } else {
              let new_output = { ...response };

              let old_members_mapping_data = [...membersMappingData.ObjectList];
              old_members_mapping_data.push(...new_output.ObjectList);

              let updated_data = {
                ...new_output,
                ObjectList: old_members_mapping_data,
              };

              setMembersMappingData(updated_data);
            }
            resolve(response);
          } else {
            reject(true);
          }
        })
        .catch(() => {
          reject(true);
        });
    });
  }

  async function updateMemberData(_data) {
    await postData(authTokens, "UpdatePSAObjects", {
      optionEnum1: 4,
      objectList: JSON.stringify(_data),
      integrationId: IntegrationId,
    }).then((response) => {
      if (response) {
      }
    });
  }

  // SETUP FOR MEMBERS PART END++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  // SETUP FOR PRODUCTS PART START++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const [productCategoryPageData, setProductCategoryPageData] = useState({});
  const [productCategoryMappingData, setProductCategoryMappingData] = useState(
    {}
  );

  /**
   *
   * @params
   * kwargs:{
   * NextPageNo:1,
   * searchString:"",
   * itemType: 2(categories) or 3(products),
   * hideIgnored: 0 or 1
   * }
   */
  function getproductCategoryListData(kwargs) {
    return new Promise((resolve, reject) => {
      getAPIData(`${701}`, authTokens, {
        optionEnum1: kwargs.itemType,
        optionEnum2:
          kwargs?.NextPageNo !== null && kwargs?.NextPageNo !== undefined
            ? kwargs?.NextPageNo
            : productCategoryPageData?.NextPageNo !== null &&
              productCategoryPageData?.NextPageNo !== undefined
            ? productCategoryPageData?.NextPageNo
            : 1,
        optionEnum3: 30,
        optionEnum4: IntegrationId,
        optionEnum5: kwargs?.hideIgnored ?? 0,
        optionStrEnum1: kwargs?.searchString ?? "",
      })
        .then((response) => {
          if (response?.Success) {
            try {
              if (
                Object.keys(productCategoryPageData).length === 0 ||
                kwargs?.NextPageNo === 1
              ) {
                let new_output = generateOutputForTable(response);
                setProductCategoryPageData({
                  ...new_output,
                  ObjectList: new_output?.ObjectList,

                  // ?.sort((a, b) => {
                  //   if (
                  //     a.state === "Not_Selected" &&
                  //     b.state !== "Not_Selected"
                  //   ) {
                  //     return -1; // a should come before b
                  //   } else if (
                  //     a.state !== "Not_Selected" &&
                  //     b.state === "Not_Selected"
                  //   ) {
                  //     return 1; // b should come before a
                  //   } else if (
                  //     a.state === "Not_Selected" &&
                  //     b.state === "Not_Selected"
                  //   ) {
                  //     return a.name.localeCompare(b.name); // sort alphabetically for state "Not_Selected"
                  //   } else {
                  //     return a.name.localeCompare(b.name); // sort alphabetically for other states
                  //   }
                  // }),
                });
              } else {
                let new_output = generateOutputForTable(response);

                let old_client_data = [...productCategoryPageData.ObjectList];
                old_client_data.push(...new_output.ObjectList);

                let updated_data = {
                  ...new_output,
                  ObjectList: old_client_data,
                };

                setProductCategoryPageData({
                  ...updated_data,
                  ObjectList: updated_data?.ObjectList,

                  // ?.sort((a, b) => {
                  //   if (
                  //     a.state === "Not_Selected" &&
                  //     b.state !== "Not_Selected"
                  //   ) {
                  //     return -1; // a should come before b
                  //   } else if (
                  //     a.state !== "Not_Selected" &&
                  //     b.state === "Not_Selected"
                  //   ) {
                  //     return 1; // b should come before a
                  //   } else if (
                  //     a.state === "Not_Selected" &&
                  //     b.state === "Not_Selected"
                  //   ) {
                  //     return a.name.localeCompare(b.name); // sort alphabetically for state "Not_Selected"
                  //   } else {
                  //     return a.name.localeCompare(b.name); // sort alphabetically for other states
                  //   }
                  // }),
                });
              }

              resolve(response);
            } catch (err) {
              console.log("getproduct categorydata", err);
            }
          } else {
            reject(false);
          }
        })
        .catch(() => {
          reject(false);
        });
    });
  }

  /**
   *
   * @params
   * kwargs:{
   * NextPageNo:1,
   * searchString:"",
   * itemType: 2(categories) or 3(products),
   * hideign
   * }
   */

  function getProductsCategoryMappingListData(kwargs) {
    return new Promise((resolve, reject) => {
      getAPIData(`${702}`, authTokens, {
        optionEnum1: kwargs.itemType,

        optionEnum2:
          kwargs?.NextPageNo !== null && kwargs?.NextPageNo !== undefined
            ? kwargs?.NextPageNo
            : productCategoryMappingData?.NextPageNo !== null &&
              productCategoryMappingData?.NextPageNo !== undefined
            ? productCategoryMappingData?.NextPageNo
            : 1,
        optionEnum3: kwargs?.count ?? 11,
        optionStrEnum1: kwargs?.searchString ?? "",
      })
        .then((response) => {
          if (response?.Success) {
            if (
              Object.keys(productCategoryMappingData).length === 0 ||
              kwargs?.NextPageNo === 1
            ) {
              setProductCategoryMappingData(response);
            } else {
              let new_output = { ...response };

              let old_members_mapping_data = [
                ...productCategoryMappingData.ObjectList,
              ];
              old_members_mapping_data.push(...new_output.ObjectList);

              let updated_data = {
                ...new_output,
                ObjectList: old_members_mapping_data,
              };

              setProductCategoryMappingData(updated_data);
            }
            resolve(response);
          } else {
            reject(true);
          }
        })
        .catch(() => {
          reject(true);
        });
    });
  }

  async function updateProductData(_data, _obj = {}) {
    return new Promise((resolve, reject) => {
      postData(authTokens, "UpdatePSAObjects", {
        optionEnum1: 3,
        objectList: JSON.stringify(_data),
        integrationId: IntegrationId,
        ..._obj,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          console.log(err, "error in updateCategoryData");
          reject(false);
        });
    });
  }

  async function updateCategoryData(_data, _obj = {}) {
    return new Promise((resolve, reject) => {
      postData(authTokens, "UpdatePSAObjects", {
        optionEnum1: 2,
        objectList: JSON.stringify(_data),
        integrationId: IntegrationId,
        ..._obj,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          console.log(err, "error in updateCategoryData");
          reject(false);
        });
    });
  }

  // SETUP FOR PRODUCTS PART END++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  /**
   * OptionEnums for Config
        SO_automatically_import_new_clients,        //0
        SO_import_contacts,                        //1
        SO_automatically_import_new_contacts,      //2
        SO_import_by_products,                     //3
        SO_automatically_import_new_categories,    //4
        SO_automatically_import_new_porducts,     //5
        SO_import_members,                        //6
        SO_automatically_import_new_members,     //7
        SO_import_process_status                //8
   *    SO_confiuredImportInProccess,           //9
        SO_ClientGroupList_for_autoImport      //10
   * @param {number} _ConfigEnum 
   * @param {0,1} _ConfigValue 
   */
  async function updateImportStatus(_ConfigEnum, _ConfigValue, _obj = {}) {
    return new Promise((resolve, reject) => {
      postData(authTokens, "PSAGlobalConfig", {
        ConfigEnum: _ConfigEnum,
        ConfigValue: _ConfigValue,
        IntegrationId: IntegrationId,
        ..._obj,
      })
        .then((response) => {
          if (response?.Success) {
            resolve(response);
          } else {
            reject(false);
          }
        })
        .catch((err) => {
          console.log(err, "error in PSAGlobalConfig");
          reject(false);
        });
    });
  }

  async function startMappingInBackground() {
    return new Promise((resolve, reject) => {
      getAPIData("70300", authTokens, {
        OptionEnum2: IntegrationId,
      }).then((response) => {
        if (response?.Success) {
          resolve(response);
        } else {
          reject(false);
        }
      });
    });
  }

  // kwargs={
  //   PSAObjectEnum,
  //   StateEnum,
  //   FilterList,
  //   SearchStr,
  //   IntegrationId
  // }

  // PSAObjectEnum
  // clients,          0
  // contacts,      1
  // categories,   2
  // products,      3
  // members      4,

  // StateEnum
  // Ignored,                //0
  // Mapped,                 //1
  // Imported,               //2
  // Auto_Imported,          //3
  // Provisional_Import,     //4
  // Provisional_Map,        //5
  // Deleted,                //6
  // Not_Selected            //7

  function applyToAllFunc(kwargs) {
    return new Promise((resolve, reject) => {
      postData(authTokens, "ApplyActionToAll", {
        PSAObjectEnum: kwargs?.PSAObjectEnum ?? 0,
        StateEnum: kwargs?.StateEnum ?? 0,
        FilterList: JSON.stringify(kwargs?.FilterList ?? []),
        SearchStr: kwargs?.SearchStr ?? "",
        integrationId: IntegrationId,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          console.log(err, "error in ApplyActionToAll");
          reject(false);
        });
    });
  }

  const mappingState = localStorage.getItem("mappingState");

  return (
    <div className="integration-header">
      {mappingState === "view" ? (
        <div
          className="w-100 bg-black d-flex align-items-center justify-content-between"
          style={{
            position: "sticky",
            top: "56px",
            height: "51px",
            borderTop: "1px solid rgba(255, 255, 255, 0.08)",
            zIndex: 3,
          }}
        >
          <div
            className="d-flex align-items-center f-white f-500 ml-3 w-15 pointer"
            onClick={() => {
              history.push("/mspv2/integrations?type=connectedIntegrations");
            }}
          >
            <img
              alt=""
              src="/images/msp/msp-white-cross.svg"
              className="mr-3 pointer"
            />
            Mapping data
          </div>
          {/* Mapping Tabs List */}
          <div className="d-flex align-items-center justify-content-center w-70">
            {headers?.slice(0, -1)?.map((headerItem, headerItemIndex) => {
              return (
                <>
                  <div
                    className={`mx-2 p-2 menuItem radius-2 pointer   ${
                      Type ===
                      screenMappingEnums[headerItem?.IntegrationRequestEnum]
                        ? "selectedMenuItem"
                        : ""
                    }   `}
                    onClick={() => {
                      setSelectedHeaderTab(headerItemIndex);
                      history.push(
                        `/mspv2/mappings?type=${
                          screenMappingEnums[headerItem?.IntegrationRequestEnum]
                        }`
                      );
                    }}
                  >
                    {/* <span
                    style={{ background: "#ffffff16" }}
                    className="mr-2 f-12 f-500 p-2 rounded-full"
                  >
                    {headerItemIndex + 1}
                  </span> */}
                    {capitalize(
                      headerItem?.IntegrationRequestTitle?.toLowerCase()
                    ) === "Members" &&
                    Number(parseIntegrationName("importedIntegrtionId")) ===
                      3906771
                      ? "Agents"
                      : capitalize(
                          headerItem?.IntegrationRequestTitle?.toLowerCase()
                        )}
                  </div>
                  {headerItemIndex < headers?.length - 1 && (
                    <img
                      alt=""
                      className="mx-1"
                      src="/images/msp/msp-greater-sign.svg"
                      style={{
                        visibility: "hidden",
                      }}
                    />
                  )}
                </>
              );
            })}
          </div>

          <div className="mr-3 d-flex justify-content-end w-15">
            {headers?.slice(0, -1)?.length - 1 !== selectedHeaderTab && (
              <Button
                className="hg-blue-btn   mr-2"
                onClick={() => {
                  // history.push(
                  //   `/mspv2/integrations?type=connectedIntegrations&openConnectModal=${IntegrationId}`
                  // )
                  setSaving(true);
                  startMappingInBackground()
                    .then(() => {
                      setSaving(false);
                      history.push(
                        `/mspv2/integrations?type=connectedIntegrations&openConnectModal=${IntegrationId}`
                      );
                    })
                    .catch(() => {});
                }}
              >
                {saving
                  ? "Please wait.."
                  : headers?.slice(0, -1)?.length - 1 === selectedHeaderTab
                  ? "Sync"
                  : "Sync"}
              </Button>
            )}
            <Button
              disabled={processing}
              className="hg-blue-btn"
              onClick={() => {
                if (headers?.slice(0, -1)?.length - 1 === selectedHeaderTab) {
                  // history.push(
                  //   `/mspv2/integrations?type=connectedIntegrations&openConnectModal=${IntegrationId}`
                  // );
                  setProcessing(true);
                  startMappingInBackground()
                    .then(() => {
                      setProcessing(false);
                      history.push(
                        `/mspv2/integrations?type=connectedIntegrations&openConnectModal=${IntegrationId}`
                      );
                    })
                    .catch(() => {});
                  return;
                } else {
                  setSelectedHeaderTab(selectedHeaderTab + 1);
                  history.push(
                    `/mspv2/mappings?type=${
                      screenMappingEnums[
                        headers[selectedHeaderTab + 1]?.IntegrationRequestEnum
                      ]
                    }`
                  );
                }
              }}
            >
              {processing
                ? "Please wait.."
                : headers?.slice(0, -1)?.length - 1 === selectedHeaderTab
                ? "Sync"
                : "Continue"}
            </Button>
          </div>
        </div>
      ) : (
        <div
          className="w-100 bg-black d-flex align-items-center justify-content-between"
          style={{
            position: "sticky",
            top: "56px",
            height: "51px",
            borderTop: "1px solid rgba(255, 255, 255, 0.08)",
            zIndex: 3,
          }}
        >
          <div
            className="d-flex align-items-center f-white f-500 ml-3 pointer"
            onClick={() => {
              history.push("/mspv2/integrations?type=connectedIntegrations");
            }}
          >
            <img
              alt=""
              src="/images/msp/msp-white-cross.svg"
              className="mr-3 pointer"
            />
            Mapping data
          </div>
          {/* Mapping Tabs List */}
          <div className="d-flex align-items-center">
            {headers?.map((headerItem, headerItemIndex) => {
              return (
                <>
                  <div
                    className={`mx-2 p-2 menuItem radius-2 pointer   ${
                      Type ===
                      screenMappingEnums[headerItem?.IntegrationRequestEnum]
                        ? "selectedMenuItem"
                        : ""
                    }   `}
                    onClick={() => {
                      setSelectedHeaderTab(headerItemIndex);
                      history.push(
                        `/mspv2/mappings?type=${
                          screenMappingEnums[headerItem?.IntegrationRequestEnum]
                        }`
                      );
                    }}
                  >
                    <span
                      style={{ background: "#ffffff16" }}
                      className="mr-2 f-12 f-500 p-2 rounded-full"
                    >
                      {headerItemIndex + 1}
                    </span>
                    Import {headerItem?.IntegrationRequestTitle?.toLowerCase()}
                  </div>
                  {headerItemIndex < headers?.length - 1 && (
                    <img
                      alt=""
                      className="mx-1"
                      src="/images/msp/msp-greater-sign.svg"
                    />
                  )}
                </>
              );
            })}
          </div>

          <div className="mr-3">
            {Type !== "mapSummary" && (
              <Button
                className="hg-blue-btn mr-2"
                onClick={() => {
                  setSaving(true);
                  updateImportStatus(8, 1)
                    .then(() => {
                      setSaving(false);
                      history.push(
                        `/mspv2/integrations?type=connectedIntegrations&openConnectModal=${IntegrationId}`
                      );
                    })
                    .catch(() => {});
                }}
              >
                {saving ? "Please wait..." : "Sync"}
              </Button>
            )}
            <Button
              disabled={processing}
              className="hg-blue-btn"
              onClick={() => {
                if (Type === "mapSummary") {
                  setProcessing(true);
                  updateImportStatus(8, 1)
                    .then(() => {
                      setProcessing(false);
                      history.push(
                        `/mspv2/integrations?type=connectedIntegrations&openConnectModal=${IntegrationId}`
                      );
                    })
                    .catch(() => {});
                  return;
                } else {
                  setSelectedHeaderTab(selectedHeaderTab + 1);
                  history.push(
                    `/mspv2/mappings?type=${
                      screenMappingEnums[
                        headers[selectedHeaderTab + 1]?.IntegrationRequestEnum
                      ]
                    }`
                  );
                }
              }}
            >
              {processing
                ? "Please wait.."
                : Type === "mapSummary"
                ? "Import"
                : "Continue"}
            </Button>
          </div>
        </div>
      )}

      {Type === "mapClients" && (
        <MapClients
          getClientsListData={getClientsListData}
          clientPageData={clientPageData}
          setClientPageData={setClientPageData}
          clientsMappingData={clientsMappingData}
          getClientsMappingListData={getClientsMappingListData}
          updateClientData={updateClientData}
          updateImportStatus={updateImportStatus}
          applyToAllFunc={applyToAllFunc}
        />
      )}
      {Type === "mapContacts" && (
        <MapContacts
          getContactsListData={getContactsListData}
          contactPageData={contactPageData}
          setContactPageData={setContactPageData}
          updateContactData={updateContactData}
          updateImportStatus={updateImportStatus}
        />
      )}
      {Type === "mapProducts" && (
        <MapProducts
          productCategoryPageData={productCategoryPageData}
          setProductCategoryPageData={setProductCategoryPageData}
          getproductCategoryListData={getproductCategoryListData}
          productCategoryMappingData={productCategoryMappingData}
          setProductCategoryMappingData={setProductCategoryMappingData}
          getProductsCategoryMappingListData={
            getProductsCategoryMappingListData
          }
          updateProductData={updateProductData}
          updateCategoryData={updateCategoryData}
          updateImportStatus={updateImportStatus}
        />
      )}
      {Type === "mapMembers" && (
        <MapMembers
          memberPageData={memberPageData}
          setMemberPageData={setMemberPageData}
          getMembersListData={getMembersListData}
          membersMappingData={membersMappingData}
          getMembersMappingListData={getMembersMappingListData}
          updateMemberData={updateMemberData}
          updateImportStatus={updateImportStatus}
        />
      )}
      {Type === "mapSummary" && (
        <MapSummary
          // FOR CLIENTS
          getClientsListData={getClientsListData}
          clientPageData={clientPageData}
          setClientPageData={setClientPageData}
          clientsMappingData={clientsMappingData}
          getClientsMappingListData={getClientsMappingListData}
          updateClientData={updateClientData}
          updateImportStatus={updateImportStatus}
          // FOR CONTACTS
          getContactsListData={getContactsListData}
          contactPageData={contactPageData}
          setContactPageData={setContactPageData}
          updateContactData={updateContactData}
          // FOR PRODUCTS AND CATEGORIES
          productCategoryPageData={productCategoryPageData}
          setProductCategoryPageData={setProductCategoryPageData}
          getproductCategoryListData={getproductCategoryListData}
          productCategoryMappingData={productCategoryMappingData}
          setProductCategoryMappingData={setProductCategoryMappingData}
          getProductsCategoryMappingListData={
            getProductsCategoryMappingListData
          }
          updateProductData={updateProductData}
          updateCategoryData={updateCategoryData}
          // FOR MEMBERS
          memberPageData={memberPageData}
          setMemberPageData={setMemberPageData}
          getMembersListData={getMembersListData}
          membersMappingData={membersMappingData}
          getMembersMappingListData={getMembersMappingListData}
          updateMemberData={updateMemberData}
        />
      )}
    </div>
  );
};

export default MSPMappings;

function makeGroup(list, key) {
  let resultArr = [];
  let types = [];
  for (let i = 0; i < list.length; i++) {
    if (types.indexOf(list[i][key]) > -1) {
      let indexPosition = types.indexOf(list[i][key]);
      resultArr[indexPosition]["data"].push(list[i]);
      resultArr[indexPosition]["groupName"] = list[i][key];
    } else {
      types.push(list[i][key]);
      let indexPosition = types.indexOf(list[i][key]);
      if (typeof resultArr[indexPosition] === "object") {
        resultArr[indexPosition]["data"].push(list[i]);
        resultArr[indexPosition]["groupName"] = list[i][key];
      } else {
        resultArr[indexPosition] = {};
        resultArr[indexPosition]["data"] = [];
        resultArr[indexPosition]["data"].push(list[i]);
        resultArr[indexPosition]["groupName"] = list[i][key];
      }
    }
  }

  return resultArr;
}

export function generateOutputForTable(oldData) {
  let newData = { ...oldData };

  for (
    let objectlistIndex = 0;
    objectlistIndex < newData?.ObjectList?.length;
    objectlistIndex++
  ) {
    let detailsArray = [];

    for (
      let detailsArrayIndex = 0;
      detailsArrayIndex < newData?.ObjectList[objectlistIndex]?.details?.length;
      detailsArrayIndex++
    ) {
      let detailArrayElement =
        newData?.ObjectList[objectlistIndex]?.details[detailsArrayIndex];

      if (detailArrayElement["group"] === "index") {
        if (
          newData?.ObjConfigList[
            `${detailArrayElement["label"]}_visibility`
          ] === "true"
        ) {
          detailArrayElement.sortOrder = Number(
            newData?.ObjConfigList[`${detailArrayElement["label"]}_sortOrder`]
          );
          detailsArray.push(detailArrayElement);
        }
      } else {
        detailsArray.push(detailArrayElement);
      }
    }

    newData.ObjectList[objectlistIndex].details = makeGroup(
      detailsArray.sort((a, b) => a.groupSortOrder - b.groupSortOrder),
      "group"
    );
  }

  return newData;
}

export const screenMappingEnums = {
  0: "mapClients",
  1: "mapContacts",
  2: "mapProducts",
  3: "mapProducts",
  4: "mapMembers",
  99: "mapSummary",
};

export function parseIntegrationName(_keyName) {
  try {
    return JSON.parse(localStorage.getItem(_keyName));
  } catch (e) {
    return "";
  }
}
