import React, { Component, Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Alert,
  InputGroup,
} from "react-bootstrap";
import { BrowserRouter, Link, Redirect, Router } from "react-router-dom";
import "../Login/Login.css";
import { AlertDialog } from "../Alert/Alert";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";

function ResetPasswordForm() {
  let history = useHistory();
  let params = new URLSearchParams(document.location.search.substring(1));
  let ids = params.get("id");
  let tokens = params.get("token"); // is the string "Jonathan"

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formSubmit, setFormSubmit] = useState(false);
  const [uid, setUid] = useState(ids || "");
  const [token, setToken] = useState(tokens || "");
  const [error, setError] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const strength = [
    {
      name: "Use A-Z uppercase, a-z lowercase, 0-9 number, symbols, 8-digits",
      maxValue: 0,
      color: "greyText",
      barColor: "bar-not-active",
    },
    {
      name: "Use A-Z uppercase, symbols, 0-9 number, 8-digits",
      maxValue: 1,
      color: "redText",
      barColor: "bar-filled-red",
    },
    {
      name: "Use symbols, 0-9 number, 8-digits",
      maxValue: 2,
      color: "redText",
      barColor: "bar-filled-red",
    },
    {
      name: "Use 0-9 number, 8-digits",
      maxValue: 3,
      color: "orangeText",
      barColor: "bar-filled-orange",
    },
    {
      name: "Use 8-digits",
      maxValue: 4,
      color: "orangeText",
      barColor: "bar-filled-orange",
    },
    {
      name: "Your password is strong",
      maxValue: 5,
      color: "greenText",
      barColor: "bar-filled",
    },
  ];

  const passwordChecker = (password) => {
    var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    // console.log(strongRegex.test(this.state.password))
    if (strongRegex.test(password)) {
      console.log("got here");
      return true;
    } else {
      console.log(password, "got here 2");

      return false;
    }
  };

  const handlePassword = (pwd) => {
    setPassword(pwd);
    var variations = {
      length: /^.{8,}$/.test(pwd),
      digits: /\d/.test(pwd),
      lower: /[a-z]/.test(pwd),
      upper: /[A-Z]/.test(pwd),
      nonWords: /\W/.test(pwd),
    };
    var strengthCount = 0;

    for (var check in variations) {
      strengthCount += variations[check] == true ? 1 : 0;
    }
    setPasswordStrength(strengthCount);
  };

  async function sendPasswordReset(event) {
    event.preventDefault();

    if (!password) {
      setPasswordError("Please enter Password");
      return false;
    }
    if (!confirmPassword) {
      setConfirmPasswordError("Please enter Confirm Password");
      return false;
    }
    if (password && passwordStrength < 5) {
      setPasswordError(
        "Password must contain 8 characters or more and must include lowercase, uppercase, numeric and special characters"
      );
      return false;
    }
    if (password && confirmPassword && password != confirmPassword) {
      setConfirmPasswordError(
        "Passwords don't match. Please make sure passwords are matching!"
      );
      return false;
    }

    setFormSubmit(true);
    // const { is_error } = handleValidations(true);
    // if (is_error) return;
    var urlencoded = new URLSearchParams();
    urlencoded.append("password", password);
    urlencoded.append("uid", uid);
    urlencoded.append("token", token);

    var requestOptions = {
      method: "POST",
      body: urlencoded,
      redirect: "follow",
    };

    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/ObjectraAPI/SubmitNewPassword`,
      // `https://hg-dev-ui-ac-api.azurewebsites.net/ObjectraAPI/SubmitNewPassword`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.Success === true) {
          setError({
            type: "success",
            heading: "Changing Password Successful",
            body: "You have succesfully reset your password! You will be redirected to the login screen!",
          });
          setTimeout(() => {
            history.push("/login");
          }, 3000);
        } else {
          // this.setState({
          //   error: {
          //     type: "danger",
          //     heading: "Changing Password Failed",
          //     body: "Unable to Reset Password",
          //   },
          // });
        }
        if (data.Success === false) {
          setError({
            type: "danger",
            heading: "Changing Password Failed",
            body: "Unable to Reset Password",
          });
          setTimeout(() => {
            setError(false);
          }, 2000);
        }
      })
      .catch((error) => {
        setError({
          type: "danger",
          heading: "Changing Password Failed",
          body: "Unable to Reset Password",
        });
        setTimeout(() => {
          setError(false);
        }, 2000);
      });
  }

  /**
   * Handle validations
   */
  const handleValidations = (submit) => {
    let error_structure = {
      ePassword: false,
      eConfirmPassword: false,
    };
    if (submit == 0) return error_structure;
    if (!password) {
      error_structure.ePassword = "Please enter Password";
    }
    if (!confirmPassword) {
      error_structure.eConfirmPassword = "Please enter Confirm Password";
    }
    if (password && passwordStrength < 5) {
      error_structure.ePassword =
        "Password must contain 8 characters or more and must include lowercase, uppercase, numeric and special characters";
    }
    if (password && confirmPassword && password != confirmPassword) {
      error_structure.eConfirmPassword =
        "Passwords don't match. Please make sure passwords are matching!";
    }
    const { ePassword, eConfirmPassword } = error_structure;

    if (ePassword || eConfirmPassword) {
      error_structure.is_error = true;
    }
    return error_structure;
  };

  const { ePassword, eConfirmPassword } = handleValidations(formSubmit);

  return (
    <div className="loginBg">
     
      <Row className="innerLoginWrapper">
        <Col md={{ span: 4, offset: 1 }}>
          <div className="wrapperContainer paddingTopWrap">
            <Row className="align-items-center" style={{ paddingTop: "40px" }}>
              <Col md={12} className="text-center">
                <div>
                  <img alt="" src="/images/logo-large.svg" className="icon"></img>
                  {/* <p style={{ paddingTop: "4em" }} className="pt-3 makingcyber">Making Cyber Simple</p> */}
                </div>
              </Col>
            </Row>
            <Container className="fullHeight">
              <Row className="mt-5">
                <Col md={12} className="text-center pt-5 pb-4">
                  <div>
                    <h3 className="lgn_HeaderText2">Reset Password</h3>
                    <p
                      style={{
                        color: "#9499AA",
                        fontFamily: "Archivo",
                        fontStyle: "normal",
                      }}
                    >
                      Create a new strong password
                    </p>
                  </div>
                </Col>
              </Row>

              <Row className="align-items-center">
                <Col md={10} className="mx-auto">
                  <Form
                    className="userLoginForm"
                    autoComplete="nope"
                    onSubmit={(e) => sendPasswordReset(e)}
                  >
                    <div className="label-sign-up">New password</div>
                    <Form.Group
                      controlId="loginEmail"
                      className={`mt- ${ePassword && "border-danger-"}`}
                    >
                      <InputGroup className="mr-sm-2">
                        {/* <InputGroup.Prepend>
                          <InputGroup.Text>Password: </InputGroup.Text>
                        </InputGroup.Prepend> */}
                        <Form.Control
                          className="inputControl"
                          size="lg"
                          placeholder="Password"
                          type={passwordShow ? "text" : "password"}
                          onChange={(event) => {
                            handlePassword(event.target.value);

                            setPasswordError("");
                          }}
                          value={password}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text
                            className="passwordIcon"
                            onClick={() => {
                              setPasswordShow(!passwordShow);
                            }}
                          >
                            {passwordShow ? <BsEyeSlashFill /> : <BsEyeFill />}
                          </InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </Form.Group>

                    {passwordError && (
                      <span className="error-text">{passwordError}</span>
                    )}
                    <Fragment>
                      <div className="d-flex justify-content-between mt8px mb8px">
                        {[1, 2, 3, 4, 5].map((bar) => (
                          <div
                            className={
                              bar <= passwordStrength
                                ? strength.find(
                                    (elmt) => elmt.maxValue >= passwordStrength
                                  ).barColor
                                : "bar-not-active"
                            }
                          ></div>
                        ))}
                      </div>
                      <div
                        className={
                          strength.find(
                            (elmt) => elmt.maxValue >= passwordStrength
                          ).color
                        }
                      >
                        {
                          strength.find(
                            (elmt) => elmt.maxValue >= passwordStrength
                          ).name
                        }
                      </div>
                    </Fragment>

                    <div className="label-sign-up">Repeat password</div>
                    <Form.Group
                      controlId="loginEmail"
                      className={`mt- ${eConfirmPassword && "border-danger"}`}
                    >
                      <InputGroup className="mr-sm-2">
                        <Form.Control
                          className="inputControl"
                          size="lg"
                          placeholder="Repeat Password"
                          type={confirmPasswordShow ? "text" : "password"}
                          onChange={(event) => {
                            setConfirmPassword(event.target.value);

                            setConfirmPasswordError("");
                          }}
                          value={confirmPassword}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text
                            className="passwordIcon"
                            onClick={() =>
                              setConfirmPasswordShow(!confirmPasswordShow)
                            }
                          >
                            {confirmPasswordShow ? (
                              <BsEyeSlashFill />
                            ) : (
                              <BsEyeFill />
                            )}
                          </InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </Form.Group>
                    {confirmPasswordError && (
                      <span className="error-text">{confirmPasswordError}</span>
                    )}

                    <div className="mt-4">
                      <Button
                        size="lg"
                        variant="blue"
                        className="form-control btnControl"
                        type="submit"
                      >
                        Set new password
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>

              <Row className="paddingTop mb-5">
                <Col md={12} className="pt- text-center">
                  
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
        <Col md={7} className="imageLoginWrapper">
          <img alt="" src="/images/login.svg" width="100%" />
        </Col>
      </Row>
    </div>
  );
}
export default ResetPasswordForm;
