import React from "react";
import "./button.scss";

interface Props {
  onClick: () => void;
  children?: React.ReactNode;
  disabled: boolean;
  className: string;
  image: string;
  icon: boolean;
  hoverImage: string;
}

const Button: React.FC<Props> = ({
  onClick,
  children,
  disabled,
  className,
  image,
  icon,
  hoverImage,
}) => {
  return (
    <button
      onClick={onClick}
      className={`${className ? className : ""} ${disabled ? "disabled" : ""}`}
      disabled={disabled ? disabled : false}
      onMouseOut={(e) =>
        icon ? (e.currentTarget.querySelector("img").src = image) : ""
      }
      onMouseOver={(e) =>
        icon
          ? (e.currentTarget.querySelector("img").src = disabled
              ? image
              : hoverImage)
          : ""
      }
    >
      {children}
      {icon && <img src={image} />}
    </button>
  );
};

export default Button;
