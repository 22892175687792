import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  ContributorGetAPIData,
  ContributorPostData,
  getAPIData,
  postData,
} from "../../../Utils/commonApi";
import ImageWithInitials from "../../../Utils/ImageWithInitials";
import { TrimString } from "../../../Utils/StringTrimmer";
import PostEditor, {
  getHTMLfromRawState,
} from "../../ActionCenter/PostEditor/postEditor";
import { useAuth } from "../../context/Auth";
import { DeleteModal } from "../../DeleteModal/DeleteModal";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { WhiteLoader } from "../../Theme/APILoaders";
import "../supplier-profiler.scss";

const ChatDropdown = ({
  question,
  refreshData,
  questionaireId,
  blockId,
  AllProfilingObj,
  mentionList,
  isContributorView,
}) => {
  const userCommentRef = useRef();
  const [saving, setSaving] = useState(false);
  const [sending, setSending] = useState(true);
  const [message, setMessage] = useState("");
  const [editMessageData, setEditMessageData] = useState("");
  const [ResponseComment_List, setResponseComment_List] = useState([]);
  const [data, setData] = useState({});
  const { authTokens } = useAuth();
  const { state } = useLocation();
  const accessToken = state?.accessToken;
  const accessKey = state?.accessKey;
  // const [mentionList, setMentionList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState({
    showModal: false,
    deleteMessage: "You are about to delete.",
    deleteSentQuestionnaire: () => {},
  });

  useEffect(() => {
    // getAllMentionsList();
    // console.log(question.ResponseComment_List);
  }, []);

  async function getComment(commentId) {
    if (isContributorView) {
      await ContributorGetAPIData(778, authTokens, {
        optionEnum1: questionaireId,
        optionEnum2: commentId,
      })
        .then((response) => {
          if (response?.mr?.Success) {
            setResponseComment_List(response?.ResponseComment_List);
            // updateChat();
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    } else {
      await getAPIData(778, authTokens, {
        optionEnum1: questionaireId,
        optionEnum2: commentId,
      })
        .then((response) => {
          if (response?.mr?.Success) {
            // updateChat();
            setResponseComment_List(response?.ResponseComment_List);
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    }
  }

  async function updateChat() {
    if (isContributorView) {
      // await ContributorGetAPIData(754, authTokens, {
      //   optionEnum1: 0,
      //   optionEnum2: 0,
      //   optionEnum3: questionaireId,
      // })
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${accessToken}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/HGIR/InformationRequestDetail?key=${accessKey}`,
        // `https://hg-dev-ui-ac-api.azurewebsites.net/HGIR/InformationRequestDetail?key=${accessKey}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          if (response?.mr?.Success) {
            // refreshData();
            let old_questions_data = [
              ...AllProfilingObj?.AssesmentVm?.QuestionnaireSection_List,
            ];
            let new_questions_data = [
              ...response?.AssesmentVm?.QuestionnaireSection_List,
            ];
            for (let i = 0; i < new_questions_data?.length; i++) {
              for (
                let j = 0;
                j < new_questions_data[i]?.Question_List.length;
                j++
              ) {
                if (
                  new_questions_data[i]?.Question_List[j]
                    .ResponseComment_List !== null
                ) {
                  old_questions_data[i].Question_List[j].ResponseComment_List =
                    [
                      ...new_questions_data[i]?.Question_List[j]
                        .ResponseComment_List,
                    ];
                } else {
                  old_questions_data[i].Question_List[j].ResponseComment_List =
                    [];
                }
              }
            }
            // setQuestionsData(old_questions_data);
            console.log(old_questions_data);
            let currentBlock = old_questions_data.find((old) => {
              return old.ObjectId == blockId;
            });

            let currentQuestion = currentBlock.Question_List.find((list) => {
              return list.QuestionId == question.QuestionId;
            });

            setData(currentQuestion.ResponseComment_List[0]);
            if (refreshData) {
              refreshData(new_questions_data);
            }
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
          setSending(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await getAPIData(754, authTokens, {
        optionEnum1: 0,
        optionEnum2: 0,
        optionEnum3: questionaireId,
      })
        .then((response) => {
          if (response?.mr?.Success) {
            // refreshData();
            let old_questions_data = [
              ...AllProfilingObj?.AssesmentVm?.QuestionnaireSection_List,
            ];
            let new_questions_data = [
              ...response?.AssesmentVm?.QuestionnaireSection_List,
            ];
            for (let i = 0; i < new_questions_data?.length; i++) {
              for (
                let j = 0;
                j < new_questions_data[i]?.Question_List.length;
                j++
              ) {
                if (
                  new_questions_data[i]?.Question_List[j]
                    .ResponseComment_List !== null
                ) {
                  old_questions_data[i].Question_List[j].ResponseComment_List =
                    [
                      ...new_questions_data[i]?.Question_List[j]
                        .ResponseComment_List,
                    ];
                } else {
                  old_questions_data[i].Question_List[j].ResponseComment_List =
                    [];
                }
              }
            }
            // setQuestionsData(old_questions_data);
            console.log(old_questions_data);
            let currentBlock = old_questions_data.find((old) => {
              return old.ObjectId == blockId;
            });

            let currentQuestion = currentBlock.Question_List.find((list) => {
              return list.QuestionId == question.QuestionId;
            });

            setData(currentQuestion.ResponseComment_List[0]);
            if (refreshData) {
              refreshData(new_questions_data);
            }
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
          setSending(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  async function sendMessage(message, questionId, commentId = 0) {
    setSending(true);
    if (isContributorView) {
      await ContributorPostData(
        authTokens,
        "CreateQuestionnaireResponseComment",
        {
          ObjId_Supplier: 0,
          Message: message,
          QuestionId: questionId,
          ObjectId: commentId,
          key: JSON.parse(localStorage.getItem("user_MessageObj")),
          FormTemplateId: 0,
          SendQuestionnaireId: questionaireId,
        }
      )
        .then((response) => {
          if (response?.Success) {
            getComment(question?.QuestionId);
            updateChat();
            // setEditMessageData("");
            // setSending(false);
            setMessage("");
            userCommentRef.current.reset();
            // CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          setSending(false);
          CallToast("Something went wrong", false, false, "v2style");
        });
    } else {
      await postData(authTokens, "CreateQuestionnaireResponseComment", {
        ObjId_Supplier: 0,
        Message: message,
        QuestionId: questionId,
        ObjectId: commentId,
        accesskey: JSON.parse(localStorage.getItem("user_MessageObj")),
        FormTemplateId: 0,
        SendQuestionnaireId: questionaireId,
      })
        .then((response) => {
          if (response?.Success) {
            updateChat();
            // getComment(question?.QuestionId);
            setEditMessageData("");
            // setSending(false);
            setMessage("");
            userCommentRef.current.reset();
            // CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          setSending(false);
          CallToast("Something went wrong", false, false, "v2style");
        });
    }
  }

  // Function to delete message

  async function deleteComment(commentId) {
    if (isContributorView) {
      await ContributorGetAPIData(432, authTokens, {
        optionEnum1: commentId,
        optionEnum2: questionaireId,
      })
        .then((response) => {
          if (response?.Success) {
            updateChat();
            // getComment(question?.QuestionId);
            CallToast("Comment deleted successfully", false, false, "v2style");
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    } else {
      await getAPIData(432, authTokens, {
        optionEnum1: commentId,
        optionEnum2: questionaireId,
      })
        .then((response) => {
          if (response?.Success) {
            updateChat();
            // getComment(question?.QuestionId);
            CallToast("Comment deleted successfully", false, false, "v2style");
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    }
  }

  async function archiveComments(chatId) {
    if (isContributorView) {
      await ContributorGetAPIData(770, authTokens, {
        optionEnum1: questionaireId,
        optionEnum2: chatId,
      })
        .then((response) => {
          if (response?.Success) {
            updateChat();
            // getComment(question?.QuestionId);
            CallToast("Chat resolved successfully", false, false, "v2style");
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    } else {
      await getAPIData(770, authTokens, {
        optionEnum1: questionaireId,
        optionEnum2: chatId,
      })
        .then((response) => {
          if (response?.Success) {
            updateChat();
            // getComment(question?.QuestionId);
            CallToast("Chat resolved successfully", false, false, "v2style");
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    }
  }

  return (
    <Dropdown
      className="chat-dropdown"
      onToggle={(e) => {
        setSending(false);
        setMessage("");
        setEditMessageData("");
        if (e) {
          setTimeout(() => {
            userCommentRef.current.reset();
          }, 200);
        }
      }}
    >
      <Dropdown.Toggle
        className="p-0 position-relative"
        // style={{ zIndex: 1 }}
      >
        <div
          onMouseEnter={(event) => {
            // event.target.click();
            // getComment(question?.QuestionId);
          }}
          className={`d-flex align-items-center f-12 f-500 f-grey  ${
            question?.ResponseComment_List?.length > 0
              ? ""
              : "hover-on-show-child"
          } `}
        >
          {/* <img
            alt=""
            className="mr-1"
            src="/images/supply-chain/chat-icon.svg"
          /> */}
          {question?.ResponseComment_List?.length > 0 ? (
            <img
              alt=""
              className="mr-1"
              src="/images/supply-chain/chat-icon.svg"
            />
          ) : (
            <img
              alt=""
              className="mr-1"
              src="/images/attack-surface/no-comments-icon.svg"
            />
          )}
          {question?.ResponseComment_List?.length || ""}
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="custom-chat"
        style={{ zIndex: 1, inset: "0px 0px auto auto !important" }}
      >
        <div>
          {/* Header */}
          <div className="chat-box-head px-2 pb-2 border-bottom">
            <div className="d-flex align-items-center justify-content-between">
              <div className="f-12 f-darkgrey">
                Question ID: {question?.QuestionId}
              </div>
              <div>
                <img
                  alt=""
                  className="pointer mr-2"
                  src="/images/supply-chain/circle-tick.svg"
                  onClick={(e) => {
                    archiveComments(question?.QuestionId);
                  }}
                />
                <img
                  alt=""
                  className="pointer"
                  src="/images/supply-chain/chat-box-cross.svg"
                  onClick={(e) => {
                    e.target.closest(".chat-dropdown").click();
                  }}
                />
              </div>
            </div>
          </div>
          {/* Body */}
          <div
            className="py-3 hide-scrollbar chat-body"
            style={{ overflowY: "scroll", maxHeight: "600px" }}
          >
            {
              // question?.SCMFormComment_List

              question?.ResponseComment_List?.map((comment, commentIndex) => {
                // ResponseComment_List?.map((comment, commentIndex) => {
                return (
                  <div className=" d-flex align-items-start justify-content-between mx-3 mb-3 mt-2">
                    <div className="d-flex">
                      {comment?.ImageURL ? (
                        <img
                          alt=""
                          className="rounded-full mr-2"
                          width={24}
                          height={24}
                          src={comment?.ImageURL}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src =
                              "/images/settings-v2/highground-dp.svg";
                          }}
                        />
                      ) : (
                        <div className="mr-2">
                          {" "}
                          <ImageWithInitials
                            initials={comment?.FullName[0]}
                            width={25}
                            background={"purple"}
                          />
                        </div>
                      )}

                      <div>
                        <p className="m-0 f-500 f-black">
                          {TrimString(comment?.FullName, 22)}
                        </p>
                        {/* <p className=" f-black">{comment?.Message}</p> */}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: getHTMLfromRawState(
                              comment?.Message,
                              mentionList
                            ),
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <div
                        className={`template-item-dropdown  ${
                          comment?.CanUpdateDelete ? "" : "d-none"
                        }  `}
                      >
                        <Dropdown
                          onToggle={() => {}}
                          className={`  device-dropdown white-side-dropdown`}
                        >
                          <Dropdown.Toggle>
                            <img alt="" src="/images/horizontal-dots.svg" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu style={{ width: "100px" }}>
                            <div>
                              <Row>
                                <Col xs={12} className=" pointer ">
                                  <div
                                    className="drop-item"
                                    onClick={(e) => {
                                      setEditMessageData({
                                        username: comment?.FullName,
                                        messageId: comment?.ObjectId,
                                      });
                                      setMessage(comment?.Message);
                                      userCommentRef.current.setValue(
                                        JSON.parse(comment?.Message)
                                      );
                                      e.target
                                        .closest(".device-dropdown")
                                        .click();
                                    }}
                                  >
                                    Edit
                                  </div>
                                </Col>

                                <Col xs={12} className=" pointer ">
                                  <div
                                    className="drop-item"
                                    onClick={(e) => {
                                      setShowDeleteModal({
                                        showModal: true,
                                        deleteSentQuestionnaire: () =>
                                          deleteComment(comment?.ObjectId),
                                      });

                                      // deleteComment(comment?.ObjectId);
                                      e.target
                                        .closest(".device-dropdown")
                                        .click();
                                    }}
                                  >
                                    Delete
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
          {/* footer */}
          <div>
            {editMessageData ? (
              <div
                className="d-flex align-items-center justify-content-between pt-1 px-2"
                style={{
                  borderTop: "1px solid #ebecf1",
                }}
              >
                <div className="f-black f-500">
                  {editMessageData?.username}
                  {/* username */}
                </div>
                <img
                  alt=""
                  className="pointer"
                  src="/images/attack-surface/small-cross.svg"
                  onClick={() => {
                    setEditMessageData("");
                    setMessage("");
                    userCommentRef.current.reset();
                  }}
                />
              </div>
            ) : null}

            <div
              className="message-typer d-flex align-items-center mx-1 mt-2"
              style={{ marginTop: "30px!important", position: "relative" }}
            >
              <div
                className="w-100 bg-grey d-flex flex-row"
                style={{
                  paddingLeft: "3px",
                  borderRadius: "4px",
                }}
              >
                <div className="w-90 cursor-default message-test">
                  <PostEditor
                    className="PostEditor__input"
                    placeholder={"Reply to thread"}
                    getValue={(e) => {
                      setMessage(JSON.stringify(e));
                    }}
                    mentions={mentionList}
                    ref={userCommentRef}
                  />
                </div>
                <Button
                  className=""
                  onClick={() => {
                    if (
                      JSON.parse(message)?.blocks?.[0]?.text.trim() === "" ||
                      !JSON.parse(message)?.blocks?.[0]?.text
                    )
                      return;

                    if (message !== "" && !editMessageData?.messageId) {
                      sendMessage(message, question?.QuestionId);
                    }
                    if (message !== "" && editMessageData?.messageId) {
                      sendMessage(
                        message,
                        question?.QuestionId,
                        editMessageData?.messageId
                      );
                    }
                  }}
                >
                  {sending ? (
                    <WhiteLoader width={11} />
                  ) : (
                    <img
                      alt=""
                      width={12}
                      height={12}
                      src="/images/settings-v2/white-plane.svg"
                      className=""
                      style={{ width: "20px" }}
                    />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Dropdown.Menu>
      <DeleteModal
        show={showDeleteModal.showModal}
        hideModal={() =>
          setShowDeleteModal({
            showModal: false,
          })
        }
        deleteMessage={"You are about to delete a comment."}
        deleteType={"deleteSentQuestionnaire"}
        deleteSentQuestionnaire={() => {
          showDeleteModal.deleteSentQuestionnaire();
        }}
      />
    </Dropdown>
  );
};

export default ChatDropdown;
