import React from "react";
import "./attackSurfaceTooltip.scss";

const AttackSurfaceTooltip = ({
  children,
  content,
  top,
  className,
  disabled,
  topLeft,
}) => {
  return (
    <div
      className={`   ${
        top
          ? "hg-tooltip-top"
          : topLeft
          ? "hg-tooltip-topLeft"
          : "hg-tooltip-bottom"
      }  ${className}  `}
    >
      {children}

      <span className={`tooltiptext  ${disabled ? "d-none" : ""}    `}>
        {content}
      </span>
    </div>
  );
};

export default AttackSurfaceTooltip;
