import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  InputGroup,
  Form,
  OverlayTrigger,
  FormControl,
  Tooltip,
} from "react-bootstrap";
import "./index.scss";
import Loader from "../../../Common/loader";
import { useAuth } from "../../../context/Auth";
import PaymentResponse from "../../../Common/PaymentResponseBilling";

const BillingInfo = (props) => {
  const [saveMethod, setSaveMethod] = useState(false);
  const { data, isLoading, subId, setIsLoading } = props;
  const [isSelectedMethod, setIsSelectedMethod] = useState("");
  const [applyCredit, setApplyCredit] = useState(true);
  const { authTokens, setAuthTokens } = useAuth();
  const [isResponse, setIsResponse] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [billingResponse, setBillingResponse] = useState();
  const [checkYear, setcheckYear] = useState(false);
  const rowElement = useRef(null);
  const cardDetails = {
    cname: "",
    caddress: "",
    ccountry: "GB",
    creg: "",
    cvat: "",
    cardnumber: "",
    monthyear: "",
    cvc: "",
    cardname: "",
  };
  const [error, setErrors] = useState({
    cname: "Required field",
    caddress: "Required field",
    creg: "Required field",
    cvat: "Required field",
    cardnumber: "Required field",
    monthyear: "Required field",
    cvc: "Required field",
    cardname: "Required field",
  });
  const [billingDetails, setBillingDetails] = useState(cardDetails);

  async function changeBillingInfo(paymentmethodId) {
    setIsLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/ChangeBillingInfo?SubscriptionId=${subId}&PaymentMethodId=${paymentmethodId}`,
      // `https://hg-dev-ui-ac-api.azurewebsites.net/CC_API/ChangeBillingInfo?SubscriptionId=${subId}&PaymentMethodId=${paymentmethodId}`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authTokens}`,
        },
      }
    ).then((response) => response.json());
    if (response.AccessKey !== undefined) {
      localStorage.setItem(
        "user_MessageObj",
        JSON.stringify(response.AccessKey)
      );
    }
    setIsResponse(true);
    setIsLoading(false);
    setBillingResponse(response);
  }

  const renderTooltip = (prop) => (
    <Tooltip className="custom_tooltip proService_tooltip">
      <p className="redText">{prop}</p>
    </Tooltip>
  );

  async function changeBillingInfoWithCard(subscritionId) {
    setIsLoading(true);
    const month = billingDetails.monthyear.split("/");
    const data = {
      CardName: billingDetails.cardname,
      CardNumber: billingDetails.cardnumber,
      ExpMonth: month[0],
      ExpYear: month[1],
      Cvc: billingDetails.cvc,
      CompanyName: billingDetails.cname,
      CompanyAddress: billingDetails.caddress,
      Country: billingDetails.ccountry,
      CompanyRegistration: billingDetails.creg,
      VAT: billingDetails.cvat,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/ChangeBillingInfo?SubscriptionId=${subId}`,
      // `https://hg-dev-ui-ac-api.azurewebsites.net/CC_API/ChangeBillingInfo?SubscriptionId=${subId}`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authTokens}`,
        },
        body: JSON.stringify(data),
      }
    ).then((response) => response.json());
    setIsResponse(true);
    setBillingResponse(response);
    setIsLoading(false);
  }

  const submit = async () => {
    rowElement.current.scrollIntoView();

    if (isSelectedMethod.StripeId) {
      changeBillingInfo(isSelectedMethod.StripeId);
    } else {
      const billingErr = handleError(billingDetails);
      if (billingErr == 0) {
        changeBillingInfoWithCard();
      }
    }
  };

  const maskDate = (value) => {
    let v = value.replace(/(\w)\//g, function (str, match) {
      return match[0];
    });
    if (v.length >= 3) {
      return `${v.slice(0, 2)}/${v.slice(2)}`;
    }
    return value;
  };

  const handleError = (fields) => {
    setIsSubmit(true);
    let err = 0;
    for (var key in fields) {
      if (fields.hasOwnProperty(key)) {
        if (fields[key] == "") {
          err = err + 1;
        }
        if (key == "cardnumber") {
          if (fields[key].length != 16) {
            err = err + 1;
          }
        }
        if (key == "cvc") {
          if (fields[key].length != 3) {
            err = err + 1;
          }
        }

        if (key == "monthyear") {
          if (fields[key] != "") {
            const mnth = fields[key].split("/");
            var strDate = new Date();
            var shortYear = strDate.getFullYear();
            var twoDigitYear = shortYear.toString().substr(-2);
            console.log(mnth[mnth.length - 1] < twoDigitYear);
            if (mnth[mnth.length - 1] < twoDigitYear) {
              err = err + 1;
              setcheckYear(true);
            } else {
              setcheckYear(false);
            }
          }
        }
      }
    }

    if (!err) {
      setShowErr(true);
      setIsSubmit(false);
    } else {
      setShowErr(false);
    }
    return err;
  };

  return (
    <Modal
      show={props.isOpen}
      dialogClassName="modal-50w"
      className="integrationModal centerModal dashboardModal upgradePremium"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      {isLoading && <Loader />}
      <Modal.Header>
        <h1 className="w-100 mb-0 mt-0 text-center">Change Payment Card</h1>
        <span className="cursor-pointer">
          <img
            src="/images/icons/close.svg"
            onClick={() => {
              props.setIsOpen(false);
              setIsResponse(false);
              setIsSelectedMethod("");
              setShowErr(false);
              setIsSubmit(false);
              setBillingDetails(cardDetails);
            }}
            alt=""
          />
        </span>
      </Modal.Header>
      <Modal.Body>
        <Row className="m-0">
          {isResponse ? (
            <PaymentResponse
              data={billingResponse}
              closeModal={() => props.setIsOpen(false)}
              isClose={() => {
                setIsResponse(false);
                setBillingResponse("");
                setIsSelectedMethod("");
                setShowErr(false);
                setIsSubmit(false);
                setBillingDetails(cardDetails);
              }}
            />
          ) : (
            <>
              <Col md={12} className="p-0 billingInfo" ref={rowElement}>
                <div className="bill_wrapper">
                  <div className="d24" />
                  <p className="m-0">Payment Method</p>
                  <div className="d24" />
                  <div className="payment_method_wrapper">
                    <div
                      onClick={() => {
                        setIsSelectedMethod("");
                        props.setSelectedPaymentId();
                      }}
                      className={`crsrpoint greyBorderCont mr8px position-relative flex-fill w30 ${
                        !isSelectedMethod &&
                        !props.selectedPaymentId &&
                        "brdBlu"
                      } `}
                    >
                      <Form.Group
                        controlId={"check"}
                        className="round-checkbox blue-checkbox"
                      >
                        <Form.Check
                          type="radio"
                          name="stripeId"
                          label=""
                          value={isSelectedMethod ? true : false}
                          checked={!isSelectedMethod ? true : false}
                        />
                      </Form.Group>
                      <div className="center-container text-center">
                        <div className="heading3">
                          <img
                            src="/images/subscription/addcard.svg"
                            className=""
                            alt=""
                          />
                        </div>
                        <div className="heading3 txt-blue txtcard">
                          Add Card
                        </div>
                      </div>
                    </div>

                    {data &&
                      data.PaymentMethod_List &&
                      data.PaymentMethod_List.map((val, i) => (
                        <div
                          key={i}
                          onClick={() => {
                            setIsSelectedMethod(val);
                            props.setSelectedPaymentId(false);
                          }}
                          className={`mb-3 crsrpoint greyBorderCont mr8px position-relative  flex-fill w30 
                          ${
                            // val.Id == isSelectedMethod.Id && "brdBlu"
                            val.StripeId == props.selectedPaymentId
                              ? "brdBlu"
                              : val.Id == isSelectedMethod.Id
                              ? "brdBlu"
                              : ""
                          }
                          `}
                        >
                          <Form.Group
                            controlId={`chec2${i}`}
                            className="round-checkbox blue-checkbox"
                          >
                            <Form.Check
                              name="stripeId"
                              type="radio"
                              value={
                                val.Id == isSelectedMethod.Id
                                  ? true
                                  : val.StripeId == props.selectedPaymentId
                                  ? true
                                  : false
                              }
                              label=""
                              checked={
                                val.StripeId == props.selectedPaymentId
                                  ? true
                                  : val.Id == isSelectedMethod.Id
                                  ? true
                                  : false
                              }
                            />
                          </Form.Group>
                          <div className="center-container text-center">
                            <img
                              src={`/images/${val.Icon}`}
                              width="30"
                              alt=""
                            />
                            <div className="heading3  pb-1">
                              <div className="heading3 txtcard textUpper">
                                {val.CardType}
                              </div>
                            </div>
                            <span className="gryClr">{val.CardNumber}</span>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="d24" />

                  {!isSelectedMethod.StripeId && (
                    <Fragment>
                      <div
                        className={`${props.selectedPaymentId ? "d-none" : ""}`}
                      >
                        <hr />
                        <div className="d24" />
                        <p className="m-0">Billing Address</p>
                        {/* <CardDetails /> */}
                        <div className="d24" />
                        <Form.Group>
                          <InputGroup className="grey-input">
                            <FormControl
                              name="name"
                              value={billingDetails.cname}
                              onChange={(e) =>
                                setBillingDetails({
                                  ...billingDetails,
                                  cname: e.target.value,
                                })
                              }
                              className={`${
                                isSubmit &&
                                !showErr &&
                                !billingDetails.cname &&
                                "bgred"
                              }`}
                              placeholder="Company name"
                            />
                            {isSubmit && !showErr && !billingDetails.cname && (
                              <OverlayTrigger
                                placement="bottom"
                                overlay={renderTooltip(error.cname)}
                              >
                                <InputGroup.Text
                                  style={{ height: "32px" }}
                                  className="bgred"
                                >
                                  <img src="/images/card_error.svg" />
                                </InputGroup.Text>
                              </OverlayTrigger>
                            )}
                          </InputGroup>
                        </Form.Group>
                        <Form.Group>
                          <InputGroup className="grey-input mt-10px">
                            <Form.Control
                              name="name"
                              value={billingDetails.caddress}
                              onChange={(e) =>
                                setBillingDetails({
                                  ...billingDetails,
                                  caddress: e.target.value,
                                })
                              }
                              className={`${
                                isSubmit &&
                                !showErr &&
                                !billingDetails.caddress &&
                                "bgred"
                              }`}
                              placeholder="Company address"
                            />
                            {isSubmit &&
                              !showErr &&
                              !billingDetails.caddress && (
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={renderTooltip(error.caddress)}
                                >
                                  <InputGroup.Text
                                    style={{ height: "32px" }}
                                    className="bgred"
                                  >
                                    <img src="/images/card_error.svg" />
                                  </InputGroup.Text>
                                </OverlayTrigger>
                              )}
                          </InputGroup>
                        </Form.Group>
                        {data && data.CountryList && (
                          <Form.Group>
                            <InputGroup className="mt-10px grey-select customerStyle">
                              <Form.Control
                                style={{ height: "32px" }}
                                as="select"
                                className={"pt-0 pb-0"}
                                defaultValue={"GB"}
                                onChange={(e) =>
                                  setBillingDetails({
                                    ...billingDetails,
                                    ccountry: e.target.value,
                                  })
                                }
                              >
                                {data.CountryList.map((val, i) => (
                                  <option key={i} value={val.code}>
                                    {val.country}
                                  </option>
                                ))}
                              </Form.Control>
                            </InputGroup>
                          </Form.Group>
                        )}
                        <Form.Group>
                          <InputGroup className="grey-input mt-10px">
                            <FormControl
                              name="name"
                              value={billingDetails.creg}
                              onChange={(e) =>
                                setBillingDetails({
                                  ...billingDetails,
                                  creg: e.target.value,
                                })
                              }
                              className={`${
                                isSubmit &&
                                !showErr &&
                                !billingDetails.creg &&
                                "bgred"
                              }`}
                              placeholder="Company registration"
                            />
                            {isSubmit && !showErr && !billingDetails.creg && (
                              <OverlayTrigger
                                placement="bottom"
                                overlay={renderTooltip(error.creg)}
                              >
                                <InputGroup.Text
                                  style={{ height: "32px" }}
                                  className="bgred"
                                >
                                  <img src="/images/card_error.svg" />
                                </InputGroup.Text>
                              </OverlayTrigger>
                            )}
                          </InputGroup>
                        </Form.Group>
                        <Form.Group>
                          <InputGroup className="grey-input mt-10px">
                            <FormControl
                              name="name"
                              value={billingDetails.cvat}
                              onChange={(e) =>
                                setBillingDetails({
                                  ...billingDetails,
                                  cvat: e.target.value,
                                })
                              }
                              className={`${
                                isSubmit &&
                                !showErr &&
                                !billingDetails.cvat &&
                                "bgred"
                              }`}
                              placeholder="VAT"
                            />
                            {isSubmit && !showErr && !billingDetails.cvat && (
                              <OverlayTrigger
                                placement="bottom"
                                overlay={renderTooltip(error.cvat)}
                              >
                                <InputGroup.Text
                                  style={{ height: "32px" }}
                                  className="bgred"
                                >
                                  <img src="/images/card_error.svg" />
                                </InputGroup.Text>
                              </OverlayTrigger>
                            )}
                          </InputGroup>
                        </Form.Group>
                        <div className="d24" />
                        <hr />
                        <div className="d24" />
                        <p className="m-0">Payment Information</p>
                        <div className="d24" />
                        <Form.Group className="card_number_wrapper">
                          <InputGroup className="grey-input cardnumber">
                            <FormControl
                              name="name"
                              value={billingDetails.cardnumber}
                              type="number"
                              onChange={(e) => {
                                if (e.target.value.length == 17) return false;
                                setBillingDetails({
                                  ...billingDetails,
                                  cardnumber: e.target.value,
                                });
                              }}
                              className={`${
                                isSubmit &&
                                !showErr &&
                                (!billingDetails.cardnumber ||
                                  billingDetails.cardnumber.length != 16)
                                  ? "carderr1"
                                  : "cardicon"
                              }`}
                              placeholder="Card number"
                            />
                            {isSubmit &&
                              !showErr &&
                              (!billingDetails.cardnumber ||
                                billingDetails.cardnumber.length != 16) && (
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={renderTooltip(
                                    !billingDetails.cardnumber
                                      ? "Required Field"
                                      : billingDetails.cardnumber.length != 16
                                      ? "Invalid Card Number"
                                      : ""
                                  )}
                                >
                                  <InputGroup.Text className="bgred toltip2">
                                    <img src="/images/card_error.svg" />
                                  </InputGroup.Text>
                                </OverlayTrigger>
                              )}
                          </InputGroup>
                          <InputGroup className="grey-input datemonth">
                            <FormControl
                              name="name"
                              maxLength="5"
                              value={billingDetails.monthyear}
                              onChange={(e) => {
                                setBillingDetails({
                                  ...billingDetails,
                                  monthyear: maskDate(e.target.value),
                                });
                                setcheckYear(false);
                              }}
                              className={`${
                                isSubmit &&
                                !showErr &&
                                (!billingDetails.monthyear ||
                                  checkYear ||
                                  billingDetails.monthyear.length != 5)
                                  ? "carderr1"
                                  : "cardicon2"
                              }`}
                              placeholder="MM/YY"
                            />
                            {isSubmit &&
                              !showErr &&
                              (!billingDetails.monthyear ||
                                checkYear ||
                                billingDetails.monthyear.length != 5) && (
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={renderTooltip(
                                    !billingDetails.monthyear
                                      ? "Required Field"
                                      : checkYear
                                      ? "Invalid Date"
                                      : billingDetails.monthyear.length != 5
                                      ? "Invalid Date"
                                      : ""
                                  )}
                                >
                                  <InputGroup.Text className="bgred toltip2">
                                    <img src="/images/card_error.svg" />
                                  </InputGroup.Text>
                                </OverlayTrigger>
                              )}
                          </InputGroup>
                          <InputGroup className="grey-input csvnumber">
                            <FormControl
                              name="name"
                              value={billingDetails.cvc}
                              onChange={(e) =>
                                setBillingDetails({
                                  ...billingDetails,
                                  cvc: e.target.value,
                                })
                              }
                              className={`${
                                isSubmit &&
                                !showErr &&
                                (!billingDetails.cvc ||
                                  billingDetails.cvc.length != 3) &&
                                "carderr1"
                              }`}
                              placeholder="CVC"
                            />
                            {isSubmit &&
                              !showErr &&
                              (!billingDetails.cvc ||
                                billingDetails.cvc.length != 3) && (
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={renderTooltip(
                                    !billingDetails.cvc
                                      ? "Required Field"
                                      : billingDetails.cvc.length != 3
                                      ? "Invalid CVC"
                                      : ""
                                  )}
                                >
                                  <InputGroup.Text className="bgred toltip2">
                                    <img src="/images/card_error.svg" />
                                  </InputGroup.Text>
                                </OverlayTrigger>
                              )}
                          </InputGroup>
                        </Form.Group>
                        <Form.Group>
                          <InputGroup className="grey-input">
                            <FormControl
                              name="name"
                              value={billingDetails.cardname}
                              onChange={(e) =>
                                setBillingDetails({
                                  ...billingDetails,
                                  cardname: e.target.value,
                                })
                              }
                              className={`${
                                isSubmit &&
                                !showErr &&
                                !billingDetails.cardname &&
                                "bgred"
                              }`}
                              placeholder="Name on card"
                            />
                            {isSubmit &&
                              !showErr &&
                              !billingDetails.cardname && (
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={renderTooltip(error.cardname)}
                                >
                                  <InputGroup.Text
                                    style={{ height: "32px" }}
                                    className="bgred"
                                  >
                                    <img src="/images/card_error.svg" />
                                  </InputGroup.Text>
                                </OverlayTrigger>
                              )}
                          </InputGroup>
                        </Form.Group>

                        <div className="d24" />
                        <hr />
                      </div>
                    </Fragment>
                  )}

                  <div className="d24" />
                  <div className="mt40" />
                </div>
              </Col>
              <Button
                onClick={() => submit()}
                className="w-100 blubtn"
                style={{ height: "47px" }}
              >
                Save
              </Button>
              <div className="d24" />
            </>
          )}
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default BillingInfo;
