import React, { useEffect, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { getPageDataApi } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { createStaticRanges } from "react-date-range";
import Loader from "../../Common/loader";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  addYears,
  startOfQuarter,
  endOfQuarter,
  subQuarters,
  subMonths,
  subYears,
} from "date-fns";
 
import { convertToDDMMYYYY, getDateDifferenceCategory } from "../../MSPv2/MspAttackSurface.js/MspAttackSurface";
const MspAccountOverview = ({accountId}) => {
  const { authTokens } = useAuth();
  const [activityLogsData, setActivityLogsData] = useState({});
  const [overviewData, setOverviewData] = useState({});
const [activityGraphLoading, setActivityGraphLoading] = useState(false)
  useEffect(() => {
    getActivityLogs();
    getUserOverview();
  }, []);

  function getActivityLogs(kwargs = {}) {
    setActivityGraphLoading(true)
    return new Promise((resolve, reject) => {
      getPageDataApi("GetLoginActivityDetails", authTokens, {
        FromDate:convertToDDMMYYYY( kwargs.startDate ?? dateSelectionValue.startDate),
        ToDate: convertToDDMMYYYY(kwargs.endDate ?? dateSelectionValue.endDate ),
        objId_Customer:accountId,
        optionEnum98:accountId
      })
        .then((response) => {
          setActivityGraphLoading(false)
          resolve(response);
          setActivityLogsData(response?.detail);
          let updated_data ={...data}
          updated_data.labels= response?.detail?.points?.map((item)=>item?.Label)
          updated_data.datasets[0].data= response?.detail?.points?.map((item)=>item?.Value)
          updated_data.datasets[0].pointRadius= response?.detail?.points?.map((item)=>item?.Value >0 ?  1 : 0)
        setData(updated_data)
        })
        .catch((err) => {
          reject("Something went wrong");
        });
    });
  }
  function getUserOverview(kwargs = {}) {
    return new Promise((resolve, reject) => {
      getPageDataApi("GetMspUserOverview", authTokens, {   objId_Customer:accountId,
        optionEnum98:accountId})
        .then((response) => {
          resolve(response);
          setOverviewData(response?.detail);
        })
        .catch((err) => {
          reject("Something went wrong");
        });
    });
  }



const [data, setData] = useState({
  labels: ["Jan", "Feb", "March", "April", "May", "June", "July"],
  datasets: [
    {
      data: [15, 40, 5, 45, 70, 50, 20],
      label: "Logins",
      borderColor: "#008fd4",
      backgroundColor: "#008fd409",
      fill: true,
      pointRadius: 1,
      borderWidth: 2,
    },
  ],
})

   
  function average(ctx) {
    const values = ctx.chart.data.datasets[0].data;
    return values.reduce((a, b) => a + b, 0) / values.length;
  }
  const _annotation = {
    type: "line",
    borderColor: "black",
    borderDash: [6, 6],
    borderDashOffset: 0,
    borderWidth: 3,
    label: {
      enabled: true,
      content: (ctx) => "Average: " + average(ctx).toFixed(2),
      position: "end",
    },
    scaleID: "y",
    value: (ctx) => {
      console.log(ctx);
      return average(ctx);
    },
  };
  const options = {
    legend: {
      display: false,
    },
    annotation: {
      annotations: [
        {
          type: "line",
          borderColor: "red",
          borderDash: [1, 1],
          borderDashOffset: 0,
          borderWidth: 1,
          label: {
            enabled: true,
            content: (ctx) => "Average: " + average(ctx).toFixed(2),
            // position: "end",
          },
          scaleID: "x",
          value: (ctx) => {
            console.log(ctx);
            return average(ctx);
          },
        },
      ],
    },
    datalabels: {
      display: false,
    },
    style: {
      strokewidth: 1,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
    title: {
      display: false,
      //   text: "World population per region (in millions)",
    },

    // tooltips: {
    //   callbacks: {
    //     label: function (tooltipItem) {
    //       return "xcc";
    //     },
    //   },
    // },
    plugins: {
      legend: {
        display: false,
      },

      autocolors: true,
      annotation: {
        annotations: {
          _annotation,
          // line1: {
          //   type: "line",
          //   yMin: 60,
          //   yMax: 60,
          //   borderColor: "rgb(255, 99, 132)",
          //   borderWidth: 2,
          // },
        },
      },
      //   zoom: {
      //     zoom: {
      //       wheel: {
      //         enabled: true,
      //       },
      //       pinch: {
      //         enabled: true,
      //       },
      //       mode: "y",
      //     },
      //   },
      datalabels: {
        backgroundColor: "transparent",
        // backgroundColor: function (context) {
        //   return context.dataset.backgroundColor;
        // },
        // borderRadius: 4,
        color: "transparent",
        // font: {
        //   weight: "bold",
        // },
        // formatter: Math.round,
        // padding: 6,
      },
    },
  };







  // DATE Filter SETUP
  const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
    startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
    startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date()),
    startOflastYear: startOfYear(addYears(new Date(), -1)),
    endOflastYear: endOfYear(addYears(new Date(), -1)),
    startOfLastQuarter: startOfQuarter(subQuarters(new Date(), 1)),
    endOfLastQuarter: endOfQuarter(subQuarters(new Date(), 1)),
    startOfLastSixMonth: startOfMonth(subMonths(new Date(), 6)),
    startOfLastTwelveMonth: startOfMonth(subMonths(new Date(), 12)),
    startOfCurrentYear: startOfYear(new Date()),
    endOfPreviousYear: endOfYear(subYears(new Date(), 1)),
  };

  const sideBarOptions = () => {
    const customDateObjects = [
      {
        label: "Current Year",
        range: () => ({
          startDate: defineds.startOfCurrentYear,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Previous Year",
        range: () => ({
          startDate: defineds.startOflastYear,
          endDate: defineds.endOfPreviousYear,
        }),
      },
      {
        label: "Last 12 Months",
        range: () => ({
          startDate: defineds.startOfLastTwelveMonth,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Last 6 Months",
        range: () => ({
          startDate: defineds.startOfLastSixMonth,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Last 90 Days",
        range: () => ({
          startDate: defineds.startOfLastNintyDay,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Last 30 Days",
        range: () => ({
          startDate: defineds.startOfLastThirtyDay,
          endDate: defineds.endOfToday,
        }),
      },
    ];

    return customDateObjects;
  };

  const sideBar = sideBarOptions();

  const staticRanges = [...createStaticRanges(sideBar)];
  const [dateSelectionValue, setDateSelectionValue] = useState({
    startDate: defineds.startOfLastTwelveMonth,
    endDate: defineds.endOfToday,
    key: "selection",
  })
  const selectedDateFilter = useMemo((state) => {
    let dashboard_date = {
      ...dateSelectionValue,
    };

    dashboard_date.startDate = dashboard_date.startDate
      ? new Date(dashboard_date.startDate)
      : null;

    dashboard_date.endDate = dashboard_date.endDate
      ? new Date(dashboard_date.endDate)
      : null;
    dashboard_date.key = "selection";
    return dashboard_date;
  },[dateSelectionValue]);
  
  const dateFilterValue = getDateDifferenceCategory(
    selectedDateFilter?.startDate,
    selectedDateFilter?.endDate
  );
  return (
    <div className="mx-auto w-70 my-4">
      <div className="f-16 f-600 mb-3">Activity</div>
      <div className="bg-grey radius-8">
        <div className="d-flex border-bottom">
          <div className="p-3 flex-1 border-right">
            <div className="f-grey f-500">Avg Daily Login</div>
            <div className="f-black f-18 f-500 my-2">
              {activityLogsData?.DailyAverage}
            </div>
          </div>
          <div className="p-3 flex-1 border-right">
            <div className="f-grey f-500">Avg Weekly Login</div>
            <div className="f-black f-18 f-500 my-2">
              {activityLogsData?.WeeklyAverage}
            </div>
          </div>
          <div className="p-3 flex-1  ">
            <div className="f-grey f-500">Avg Monthly Login</div>
            <div className="f-black f-18 f-500 my-2">
              {activityLogsData?.MonthlyAverage}
            </div>
          </div>
        </div>
        <div className=" zoom-independent">
          <div className="p-3">
            <div className="d-flex al;ign-items-center justify-content-between">
              <div className="f-500 f-grey">Logins</div>
              <div>
                  <Dropdown
                    className="date-filter-dropdown m-1 dashboardDateFilter"
                    alignRight
                    onToggle={(e) => {
                      // setToggleDateFilterDropdown(e);
                    }}
                    // id={`${activeFilter?.filterType}_filterX`}
                  >
                    <Dropdown.Toggle className="p-0">
                      <div className="d-flex align-items-center p-2 bg-grey f-grey f-500 radius-4">
                        {dateFilterValue}
                        <img
                          src="/images/big-chevron-down.svg"
                          alt=""
                          className={`ml-2 ${
                            "toggleDateFilterDropdown" ? "" : "rotate180"
                          }`}
                        />
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="p-0"
                      style={{
                        zIndex: 4,
                      }}
                    >
                      <DateRangePicker
                        direction="vertical"
                        ranges={[selectedDateFilter ?? {}]}
                        staticRanges={staticRanges}
                        onChange={(ranges) => {
                          // dispatch(
                          //   mspClientDashboardDatePickerState(ranges.selection)
                          // );
                          setDateSelectionValue(ranges.selection)
                          if (
                            ranges.selection.startDate ===
                            ranges.selection.endDate
                          )
                            return;
                          getActivityLogs(ranges.selection)
                          
                          document
                            .querySelector(".dashboardDateFilter")
                            .click();
                        }}
                      />
                      <div
                        className="pl-4 f-12 pointer border-bottom pt-0"
                        onClick={() => {
                          // dispatch(
                          //   mspClientDashboardDatePickerState({
                          //     startDate: defineds.startOfLastTwelveMonth,
                          //     endDate: defineds.endOfToday,
                          //     key: "selection",
                          //   })
                          // );
                          // getTechnologySpendData({
                          //   startDate: defineds.startOfLastTwelveMonth,
                          //   endDate: defineds.endOfToday,
                          // });
                        }}
                        style={{
                          padding: "0.7rem",
                        }}
                      >
                        Clear
                      </div>
                     
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
            </div>
            <div className="f-18 f-500 f-black mt-1">
              {activityLogsData?.TotalLogins}
            </div>
          </div>
          <div>

{
  activityGraphLoading?

<>
<div
            className="d-flex align-items-center justify-content-center w-100 p-5"
            style={{
            
            }}
          >
            <Loader />
          </div>


</>


  :
  
  <Line redraw={false} data={data} options={options} height={70} />


}

            {/* <canvas id="line-chart" width="150" height="70"></canvas> */}
          </div>
        </div>
      </div>

      <div className="f-16 f-600 mb-3 mt-4">Members, Clients & Prospects</div>
      <div className="d-flex bg-grey">
        <div className="p-3 flex-1 border-right">
          <div className="f-grey f-500">Members</div>
          <div className="f-black f-18 f-500 my-2">
            {overviewData?.ClientProspect_Details?.totalMembers}
          </div>
        </div>
        <div className="p-3 flex-1 border-right">
          <div className="f-grey f-500">Clients</div>
          <div className="f-black f-18 f-500 my-2">
            {overviewData?.ClientProspect_Details?.totalClients}
          </div>
        </div>
        <div className="p-3 flex-1  ">
          <div className="f-grey f-500">Prospects</div>
          <div className="f-black f-18 f-500 my-2">
            {overviewData?.ClientProspect_Details?.totalProspects}
          </div>
        </div>
      </div>
      <div className="f-16 f-600 mb-3 mt-4">Integrations</div>
      <div className="d-flex bg-grey">
        <div className="p-3 flex-1 border-right">
          <div className="f-grey f-500">Integrations</div>
          <div className="f-black f-18 f-500 my-2">
            {overviewData?.Integrations_Details?.ConnectedIntegrations}
          </div>
        </div>
        <div className="p-3 flex-1 border-right">
          <div className="f-grey f-500">PSA Connected</div>
          <div className="f-black f-14 f-500 my-2">
            {overviewData?.Integrations_Details?.PSA_Connected?.length > 0 ? (
              <>
                {overviewData?.Integrations_Details?.PSA_Connected?.map(
                  (integration) => {
                    return (
                      <div className="d-flex align-items-center">
                        <img
                          src="/images/user-logo.svg"
                          width={16}
                          height={16}
                          alt=""
                          className="mr-2 radius-4"
                        />
                        ConnectWise Manage
                      </div>
                    );
                  }
                )}
              </>
            ) : (
              <div className="f-black f-18 f-500 my-2">
0            </div>
            )}
          </div>
        </div>
        <div className="p-3 flex-1  ">
          <div className="f-grey f-500">RMM Connected</div>
          <div className="f-black f-14 f-500 my-2">
            {overviewData?.Integrations_Details?.RMM_Connected?.length > 0 ? (
              <>
                {overviewData?.Integrations_Details?.RMM_Connected?.map(
                  (integration) => {
                    return (
                      <div className="d-flex align-items-center">
                        <img
                          src="/images/user-logo.svg"
                          width={16}
                          height={16}
                          alt=""
                          className="mr-2 radius-4"
                        />
                        ConnectWise Manage
                      </div>
                    );
                  }
                )}
              </>
            ) : (
              <div className="f-black f-18 f-500 my-2">
0            </div>
            )}
          </div>
        </div>
      </div>
      <div className="f-16 f-600 mb-3 mt-4">Sell</div>
      <div className="d-flex bg-grey">
        <div className="p-3 flex-1 border-right">
          <div className="f-grey f-500">Products</div>
          <div className="f-black f-18 f-500 my-2">
          {overviewData?.Sell_Details?.Products}
            
          </div>
        </div>
        <div className="p-3 flex-1 border-right">
          <div className="f-grey f-500">Services</div>
          <div className="f-black f-18 f-500 my-2">
          {overviewData?.Sell_Details?.Services}
            
          </div>
        </div>
        <div className="p-3 flex-1  ">
          <div className="f-grey f-500">Packages</div>
          <div className="f-black f-18 f-500 my-2">
          {overviewData?.Sell_Details?.Packages}
            
          </div>
        </div>
      </div>
      <div className="f-16 f-600 mb-3 mt-4">SecOps</div>
      <div className="d-flex bg-grey">
        <div className="p-3 flex-1 border-right">
          <div className="f-grey f-500">Accreditations</div>
          <div className="f-black f-18 f-500 my-2">
          {overviewData?.SecOps_Details?.Accreditations}
      
      
          
          </div>
        </div>
        <div className="p-3 flex-1 border-right">
          <div className="f-grey f-500">Frameworks</div>
          <div className="f-black f-18 f-500 my-2">
          {overviewData?.SecOps_Details?.Frameworks}
            
          </div>
        </div>
        <div className="p-3 flex-1  ">
          <div className="f-grey f-500">
            Employees with Training Certifications
          </div>
          <div className="f-black f-18 f-500 my-2">
          {overviewData?.SecOps_Details?.Employees_with_Training_Certifications}
            
          </div>
        </div>
      </div>

      <div className="d-flex bg-grey radius-8 mt-3">
        <div className="border-right flex-1 p-2 px-3">
          <div className="f-16 f-500 my-2">
            {overviewData?.SecOps_Details?.LeftSection?.Title}
          </div>
          {
         overviewData?.SecOps_Details?.LeftSection?.sectionDetails?.map((item)=>{

          return(
            <div className="d-flex align-items-center justify-content-between mb-1">
            <div className="f-12 f-grey">
              {item?.SectionTitle}
            </div>
            <div className="f-12">{item?.SectionPercentage}%</div>
          </div>
          )
         })   
          }

        
        
         
           
        </div>
        <div className="  flex-1 p-2 px-3">
          <div className="f-16 f-500 my-2">            {overviewData?.SecOps_Details?.RightSection?.Title}
</div>
       
       
         
{
         overviewData?.SecOps_Details?.RightSection?.sectionDetails?.map((item)=>{

          return(
            <div className="d-flex align-items-center justify-content-between mb-1">
            <div className="f-12 f-grey">
              {item?.SectionTitle}
            </div>
            <div className="f-12">{item?.SectionPercentage}%</div>
          </div>
          )
         })   
          }
        
        
        
        
          
          
        
        
          
        </div>
      </div>

      <div className="bg-grey radius-8 border-parent mt-3">
        <div className="f-16 f-500 mx-2 mb-2 pt-3">Actions</div>
        <div className="border-item d-flex align-items-center justify-content-between p-2">
          <div className="f-grey">Audit - Last Performed</div>
          <div>{
          
          overviewData?.Actions_Details?.AuditLastPerformed

          
          }</div>
        </div>
        <div className="border-item d-flex align-items-center justify-content-between p-2">
          <div className="f-grey">Last Printed Export Statement</div>
          <div>
            {
          overviewData?.Actions_Details?.LastPrintedExportStatement ?? "NA"

            }
          </div>
        </div>
        <div className="border-item d-flex align-items-center justify-content-between p-2">
          <div className="f-grey">Last Printed GAP Analysis Report</div>
          <div>
          {
          overviewData?.Actions_Details?.LastPrintedGAPAnalyticsReport ?? "NA"

            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default MspAccountOverview;
