import React from "react";
import { useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import "./similar-search.scss"
const SimilarSearchDropDown = ({ children,onClickExpand=()=>{} }) => {
  const [selectedFilter, setSelectedFilter] = useState("");
  const [searchString, setSearchString] = useState("");
  const [filters] = useState([
    " All ",
    "ISO 27001:2013",
    "ISO 27001:2014",
    "ISO 27001:2015",
    "ISO 27001:2016",
    "ISO 27001:2017",
    "ISO 27001:2018",
    "ISO 27001:2019",
    "ISO 27001:2012",
    "ISO 27001:2008",
    "ISO 27001:2009",
  ]);
  const handleTextHighlight = (searchText) => {
    const elements = document.querySelectorAll(".highlightable-dropdown"); 
    elements.forEach((element) => {
      const text = element.innerText;
      const highlightedText = text.replace(
        new RegExp(searchText, "gi"),
        (match) => `<span class="highlight">${match}</span>`
      );
      element.innerHTML = highlightedText;
    });
  };
  return (
    <Dropdown className="transparennt-wrapper-dropdown">
      <Dropdown.Toggle className="w-100 d-flex align-items-center justify-content-between">
        <div className="">{children}</div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="p-0 f-grey pointer"
        style={{
          minWidth: "450px",
          minHeight: "350px",
          maxWidth: "500px",
          maxHeight: "500px",
        }}
      >
        <div className="d-flex align-items-center px-2 border-bottom">
          <img
            src="/images/attack-surface/search-icon.svg"
            alt="assessment"
            className="mr-2"
          />

          <input
            type="text"
            placeholder="Find similar answers..."
            className="w-100 border-none bg-transparent"
            value={searchString}
            onChange={(e) => {
              setSearchString(e.target.value);
              handleTextHighlight(e.target.value);
            }}
          />
          <div>
            <Dropdown className="transparennt-wrapper-dropdown" alignRight>
              <Dropdown.Toggle className="w-100 d-flex align-items-center justify-content-between">
                <div className="p-2">
                  <img
                    src="/images/actioncenter/filter.svg"
                    alt="filter"
                    className=""
                    width={14}
                    height={14}
                  />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-2 f-grey pointer">
                <div className="f-12 f-darkgrey">Search</div>

                <div className="my-2">Similar answers</div>

                <div className="mb-2">All answers</div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div
            style={{
              height: "100%",
              borderRight: "1px solid #EBECF1",
            }}
          />
          <img
            src="/images/attack-surface/expand-icon.svg"
            alt="assessment"
            className="mr-2"
            onClick={onClickExpand}
          />
          <img
            src="/images/attack-surface/cross-icon.svg"
            alt="assessment"
            className="mr-2"
          />
        </div>

        {!"NO-Search-Results-UI" && (
          <div className="d-flex flex-column align-items-center text-center w-50 mt-5 mx-auto">
            <div className="f-500 f-grey w-fit-content">No results</div>
            <div className="f-darkgrey  w-fit-content">
              No match for “Tool kjdsndlnsldnsld”.
              <br />
              Please try another search
            </div>
            <div className="f-500 f-blue  w-fit-content">Clear search</div>
          </div>
        )}
        {/* Filtrs */}
        <div className="d-flex align-items-center hide-scrollbar overflow-x-auto my-2">
          {filters.map((item, itemIndex) => {
            return (
              <>
                <div
                  className="px-2 p-1 mr-2 pointer f-12 f-500 w-max-content"
                  style={{
                    color: `${selectedFilter === item ? "#008FD4" : "#616778"}`,
                    border: `${
                      selectedFilter === item
                        ? "1px solid #008FD4"
                        : "1px solid #D4D6DD"
                    }`,
                    whiteSpace: "nowrap",
                    borderRadius: "50px",
                  }}
                  onClick={() => {
                    setSelectedFilter(item);
                  }}
                >
                  {item}
                </div>
              </>
            );
          })}
        </div>

        <div
          className="hide-scrollbar "
          style={{
            maxHeight: "400px",
            overflowY: "auto",
          }}
        >
          <div className="w-100 h-100 ">
            <div className="bg-grey radius-8 border-parent m-3">
              {[1, 2, 3, 4, 5, 6].map((item, itemIndex) => {
                return (
                  <>
                    <div className="border-item p-3 parent-visible bg-hover-light-grey">
                      <div className="f-black highlightable-dropdown">
                        How formal are the guidelines to include security
                        requirements in the design of systems?
                      </div>

                      <div className="f-grey highlightable-dropdown">
                        Massa ultricies odio aliquam nibh vel quam cursus
                        aliquam. Non posuere auctor dignissim suspendisse in
                        suspendi...
                      </div>

                      <div className=" d-flex align-items-center justify-content-between">
                        <div className="d-flex flex-wrap">
                          {["ISO 27002:2013", "A.14.1.1"].map(
                            (item, itemIndex) => {
                              return (
                                <>
                                  <div
                                    className="px-2 p-1 mr-2 pointer f-12 f-500 w-max-content highlightable-dropdown"
                                    style={{
                                      color: `${
                                        selectedFilter === 65162
                                          ? "#008FD4"
                                          : "#616778"
                                      }`,
                                      border: `${
                                        selectedFilter === 65489189
                                          ? "1px solid #008FD4"
                                          : "1px solid #D4D6DD"
                                      }`,
                                      whiteSpace: "nowrap",
                                      borderRadius: "50px",
                                    }}
                                    onClick={() => {
                                      setSelectedFilter(item);
                                    }}
                                  >
                                    {item}
                                  </div>
                                </>
                              );
                            }
                          )}
                        </div>
                        <div>
                          <Button className="hg-blue-btn radius-2 child-visible">
                            Apply
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          <div className="f-grey mx-auto w-fit-content py-3">
            Looking for more results? Try to{" "}
            <span className="f-blue f-500">search all answers</span>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SimilarSearchDropDown;
