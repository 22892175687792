import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { AttackSurfacePostApi } from "../../../Utils/commonApi";
import { TrimString } from "../../../Utils/StringTrimmer";
import { useAuth } from "../../context/Auth";
import AttackSurfaceTooltip from "../AssetsList/AttackSurfaceTooltip";
import SmallWorkstationModal from "../AssetsList/WorkStations/SmallWorkstationModal";
import { RenderImagesGroup } from "../AssetsList/WorkStations/WorkstationModal";
import { cleanText } from "../Index";
import "./attackComponents.scss";
import { changeVaribleToName } from "./AttackLocationDropdown";

const MultiSelectDropdown = (props) => {
  const { locked, assetKey, assetId } = props;
  const [data, setData] = useState({ 1: [] });
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showSmallModal, setShowSmallModal] = useState(false);
  const [newAssetId, setNewAssetId] = useState("");

  const [assetsKey, setAssetsKey] = useState("");

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, []);

  useEffect(() => {
    if (props.data !== null && Object.keys(props.data)?.[0] !== "") {
      setItemKey(Object.keys(props.data)?.[0]);
    }
  }, [props.data]);

  useEffect(() => {
    setSelectedOptions(props.selected ?? []);
  }, [props.selected]);

  const [itemKey, setItemKey] = useState();
  function addValueToDropdownList(value, key = itemKey) {
    let updated_data = { ...data };
    updated_data[key].push({
      ...value,
    });
    setData(updated_data);
    // createNewOption(props.objectID, inputValue);

    // ////////ADD THE ITEM TO THE SELECTED VALUE

    let selected_list = [...selectedOptions];
    selected_list.push(value);
    setSelectedOptions(selected_list);
    props.onChange(selected_list);

    setInputValue("");
  }

  return (
    <Dropdown className="attack-multi-select-dropdown" disabled={locked}>
      <Dropdown.Toggle className={`${props.isError ? "input-error" : ""}`}>
        <div className="d-flex flex-wrap">
          {selectedOptions &&
            selectedOptions?.map((selected, selectedIndex) => {
              return (
                <div className="f-12 f-500 f-grey d-flex align-items-center p-2 mr-1 mb-1  bg-grey">
                  {locked && (
                    <span className="d-inline-flex">
                      <AttackSurfaceTooltip
                        className={""}
                        disabled
                        content={
                          <span>
                            Auto-generated cannot be edited
                            <RenderImagesGroup sourceName={"webroot"} />
                          </span>
                        }
                      >
                        <img
                          alt=""
                          src="/images/attack-surface/small-lock.svg"
                          className="mr-1"
                        />
                      </AttackSurfaceTooltip>
                    </span>
                  )}

                  <span className="d-inline-flex">
                    {selected?.AttributeText}
                  </span>

                  <img
                    alt=""
                    src="/images/attack-surface/grey-cross-icon.svg"
                    className={`ml-1 pointer ${locked ? "d-none" : ""}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      let updated_data = [...selectedOptions];
                      let Index = updated_data.findIndex(
                        (item) => item?.AttributeId === selected?.AttributeId
                      );
                      updated_data.splice(Index, 1);
                      setSelectedOptions(updated_data);
                      props.onChange(updated_data);
                    }}
                  />
                </div>
              );
            })}
          <input
            tabIndex={-1}
            placeholder="Add"
            type="text"
            className={`border-none w-100 h-100 ${locked ? "d-none" : ""} `}
            value={inputValue}
            onChange={(e) => {
              // setInputValue(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                // if (inputValue !== "") {
                //   addValueToDropdownList(inputValue);
                //   createNewValue(inputValue);
                // }
              }
            }}
          />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="hide-scrollbar"
        style={{ maxHeight: "300px", overflow: "auto" }}
      >
        <div className="px-2">
          {data &&
            Object.keys(data)?.map((key, keyIndex) => {
              return (
                <>
                  <div className="f-12 f-500 f-darkgrey">
                    {TrimString(
                      cleanText(
                        changeVaribleToName(key?.replaceAll(/\d+/g, ""))
                      ),
                      30
                    )}
                  </div>

                  {data[key].map((item, itemIndex) => {
                    return (
                      <div
                        className={`${
                          selectedOptions?.filter(
                            (Item) => Item?.AttributeId === item.AttributeId
                          )?.length > 0
                            ? "f-black"
                            : "f-grey"
                        } 
                        
                        ${item.AttributeText === "" ? "d-none" : "d-flex"}
                        
                        f-500 f-15 pointer py-1 ml-2  align-items-center justify-content-between`}
                        onClick={(e) => {
                          if (
                            selectedOptions?.filter(
                              (Item) => Item?.AttributeId === item.AttributeId
                            )?.length === 0
                          ) {
                            let selected_list = [...selectedOptions];
                            selected_list.push(item);
                            setSelectedOptions(selected_list);
                            props.onChange(selected_list);
                          } else {
                            let selected_list = [...selectedOptions];
                            let Index = selected_list.findIndex(
                              (Item) => Item?.AttributeId === item?.AttributeId
                            );
                            if (Index === -1) {
                              selected_list.push(item);
                              setSelectedOptions(selected_list);
                              props.onChange(selected_list);
                            }
                          }
                        }}
                      >
                        <span className="d-inline-flex align-items-center">
                          {item.AttributeText}
                        </span>
                        {selectedOptions?.filter(
                          (Item) => Item?.AttributeId === item.AttributeId
                        )?.length > 0 && (
                          <img
                            alt=""
                            width={11}
                            className="mb-1 mr-2"
                            src="/images/settings-v2/black-tick.svg"
                          />
                        )}
                      </div>
                    );
                  })}

                  {props.refId &&
                    props.refId?.length > 0 &&
                    props.refId[keyIndex]?.split("-")[0] > 0 && (
                      <div
                        className={`dropdown_item f-blue f-500 f-15 pointer py-1 ml-2`}
                        onClick={(e) => {
                          if (inputValue !== "" || true) {
                            // setData([...data, inputValue]);
                            // setInputValue(inputValue);
                            setItemKey(key);
                            if (props.nestedModal) {
                              if (props.retainPreviousStateFunc) {
                                props.retainPreviousStateFunc();
                              }
                              if (props.onAddClick) {
                                if (
                                  props.refId &&
                                  props.refId?.length > 0 &&
                                  props.refId[keyIndex]?.split("-")[0] > 0
                                ) {
                                  props.onAddClick(
                                    props.refId[keyIndex]?.split("-")[0],
                                    props.refId[keyIndex]?.split("-")?.[2]
                                  );
                                }
                              }
                            } else {
                              if (
                                props.refId &&
                                props.refId?.length > 0 &&
                                props.refId[keyIndex]?.split("-")[0] > 0
                              ) {
                                setNewAssetId(
                                  props.refId[keyIndex]?.split("-")[0]
                                );
                                setAssetsKey(
                                  props.refId[keyIndex]?.split("-")?.[2]
                                );
                                setShowSmallModal(true);
                              }
                            }
                          }
                        }}
                      >
                        Add{" "}
                        {TrimString(
                          cleanText(
                            changeVaribleToName(
                              props.refId[keyIndex]?.split("-")[1]
                            )
                          ),
                          100
                        )}
                      </div>
                    )}
                </>
              );
            })}
        </div>
      </Dropdown.Menu>
      <SmallWorkstationModal
        firstAssetId={newAssetId}
        hideModal={() => setShowSmallModal(false)}
        show={showSmallModal}
        onFinish={addValueToDropdownList}
        refreshMenuItems={props.refreshMenuItems}
        assetkey={assetsKey}
      />
      {/* {console.log("props.refreshMenuItems", props.refreshMenuItems)} */}
    </Dropdown>
  );
};

export default MultiSelectDropdown;

// const MultiSelectDropdown = (props) => {
//   const { authTokens } = useAuth();
//   const { locked, assetKey, assetId } = props;
//   const [data, setData] = useState({ 1: [] });
//   const [inputValue, setInputValue] = useState("");
//   const [selectedOptions, setSelectedOptions] = useState("");
//   const [showSmallModal, setShowSmallModal] = useState(false);
//   const [newAssetId, setNewAssetId] = useState("");
//   useEffect(() => {
//     if (props.data) {
//       setData(props.data);
//     }
//   }, []);

//   useEffect(() => {
//     if (props.data !== null && Object.keys(props.data)?.[0] !== "") {
//       setItemKey(Object.keys(props.data)?.[0]);
//     }
//   }, [props.data]);

//   useEffect(() => {
//     setSelectedOptions(props.selected ?? "");
//   }, [props.selected]);

//   const [itemKey, setItemKey] = useState();
//   function addValueToDropdownList(value, key = itemKey) {
//     let updated_data = { ...data };
//     updated_data[key].push({
//       AttributeId: 0,
//       AttributeText: value,
//     });
//     setData(updated_data);
//     // createNewOption(props.objectID, inputValue);

//     // ////////ADD THE ITEM TO THE SELECTED VALUE
//     if (selectedOptions === "") {
//       setSelectedOptions(value);
//       props.onChange(value);
//     } else {
//       let selected_list = [...selectedOptions.split(",")];
//       selected_list.push(value);
//       setSelectedOptions(selected_list.join(","));
//       props.onChange(selected_list.join(","));
//     }
//     setInputValue("");
//   }

//   //  function to create new record by typing into Input and Pressing enter

//   async function createNewValue(val) {
//     await AttackSurfacePostApi(
//       authTokens,
//       `AttackSurfaceAPI/ExtendDDLList?ObjDefId=${assetId}&AttrKey=${assetKey}&AttrValue=${val}`,
//       {
//         // ObjDefId: assetId,
//         // AttrKey: assetKey,
//         // AttrValue: val,
//       }
//     );
//   }

//   return (
//     <Dropdown className="attack-multi-select-dropdown" disabled={locked}>
//       <Dropdown.Toggle className={``}>
//         <div className="d-flex flex-wrap">
//           {selectedOptions &&
//             selectedOptions?.split(",").map((selected, selectedIndex) => {
//               return (
//                 <div className="f-12 f-500 f-grey d-flex align-items-center p-2 mr-1 mb-1  bg-grey">
//                   {locked && (
//                     <span className="d-inline-flex">
//                       <AttackSurfaceTooltip
//                         className={""}
//                         disabled
//                         content={
//                           <span>
//                             Auto-generated cannot be edited
//                             <RenderImagesGroup sourceName={"webroot"} />
//                           </span>
//                         }
//                       >
//                         <img
//                           alt=""
//                           src="/images/attack-surface/small-lock.svg"
//                           className="mr-1"
//                         />
//                       </AttackSurfaceTooltip>
//                     </span>
//                   )}

//                   <span className="d-inline-flex">{selected}</span>

//                   <img
//                     alt=""
//                     src="/images/attack-surface/grey-cross-icon.svg"
//                     className={`ml-1 pointer ${locked ? "d-none" : ""}`}
//                     onClick={(e) => {
//                       e.stopPropagation();
//                       let updated_data = [...selectedOptions.split(",")];
//                       updated_data.splice(selectedIndex, 1);
//                       setSelectedOptions(updated_data.join(","));
//                       props.onChange(updated_data.join(","));
//                     }}
//                   />
//                 </div>
//               );
//             })}
//           <input
//             tabIndex={-1}
//             placeholder="Add"
//             type="text"
//             className={`border-none w-100 h-100 ${locked ? "d-none" : ""} `}
//             value={inputValue}
//             onChange={(e) => {
//               setInputValue(e.target.value);
//             }}
//             onKeyDown={(e) => {
//               if (e.key === "Enter") {
//                 // if (inputValue !== "") {
//                 //   addValueToDropdownList(inputValue);
//                 //   createNewValue(inputValue);
//                 // }
//               }
//             }}
//           />
//         </div>
//       </Dropdown.Toggle>
//       <Dropdown.Menu
//         className="hide-scrollbar"
//         style={{ maxHeight: "300px", overflow: "auto" }}
//       >
//         <div className="px-2">
//           {data &&
//             Object.keys(data)?.map((key, keyIndex) => {
//               return (
//                 <>
//                   <div className="f-12 f-500 f-darkgrey">
//                     {TrimString(changeVaribleToName(key), 30)}
//                   </div>

//                   {data[key].map((item, itemIndex) => {
//                     return (
//                       <div
//                         className={`${
//                           selectedOptions
//                             ?.split(",")
//                             .filter((Item) => Item === item.AttributeText)
//                             ?.length > 0
//                             ? "f-black"
//                             : "f-grey"
//                         } f-500 f-15 pointer py-1 ml-2 d-flex align-items-center justify-content-between`}
//                         onClick={(e) => {
//                           if (
//                             selectedOptions
//                               .split(",")
//                               .filter((Item) => Item === item.AttributeText)
//                               ?.length === 0
//                           ) {
//                             if (selectedOptions === "") {
//                               setSelectedOptions(item.AttributeText);
//                               props.onChange(item.AttributeText);
//                             } else {
//                               let selected_list = [
//                                 ...selectedOptions.split(","),
//                               ];
//                               selected_list.push(item.AttributeText);
//                               setSelectedOptions(selected_list.join(","));
//                               props.onChange(selected_list.join(","));
//                             }
//                           }
//                         }}
//                       >
//                         <span className="d-inline-flex align-items-center">
//                           {item.AttributeText}
//                         </span>
//                         {selectedOptions
//                           .split(",")
//                           .filter((Item) => Item === item.AttributeText)
//                           ?.length > 0 && (
//                           <img
//                             alt=""
//                             width={11}
//                             className="mb-1 mr-2"
//                             src="/images/settings-v2/black-tick.svg"
//                           />
//                         )}
//                       </div>
//                     );
//                   })}

//                   {props.refId && props.refId > 0 && (
//                     <div
//                       className={`dropdown_item f-blue f-500 f-15 pointer py-1 ml-2`}
//                       onClick={(e) => {
//                         if (inputValue !== "" || true) {
//                           // setData([...data, inputValue]);
//                           // setInputValue(inputValue);
//                           setItemKey(key);
//                           if (props.nestedModal) {
//                             if (props.onAddClick) {
//                               if (props.refId) {
//                                 props.onAddClick(props.refId);
//                               }
//                             }
//                           } else {
//                             if (props.refId && props.refId > 0) {
//                               setNewAssetId(props.refId);
//                               setShowSmallModal(true);
//                             }
//                           }
//                         }
//                       }}
//                     >
//                       Add {TrimString(changeVaribleToName(key), 100)}
//                     </div>
//                   )}
//                 </>
//               );
//             })}
//         </div>
//       </Dropdown.Menu>
//       <SmallWorkstationModal
//         firstAssetId={newAssetId}
//         hideModal={() => setShowSmallModal(false)}
//         show={showSmallModal}
//         onFinish={addValueToDropdownList}
//       />
//     </Dropdown>
//   );
// };

// export default MultiSelectDropdown;
