import React, { useState } from "react";
import "./supppliersAndComplianceProfiles.scss";
import { Button } from "react-bootstrap";
import CompanyDetailsModal from "../modals/CompanyDetailsModal";
import SupplySmallChatModal from "../../settingsV2/supportTicketV2/SupplySmallChatModal";
const SupplierDetailsPopover = ({
  Details = [],
  ServicesProvided = [],
  SupplierProfileName,
  showResumeBtn,
  SharingSince,
  SupplierLogo,
  RequestStatusText,
  subtitle,
  supplierId,
}) => {
  const [companyDetailsModal, setCompanyDetailsModal] = useState(false);
  const [showSupplySmallChatModal, setSupplySmallChatModal] = useState(false);
  return (
    <div
      className="supplier-popover position-absolute"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <header className="d-flex align-items-center p-3">
        <img
          src={SupplierLogo}
          alt=""
          width={32}
          height={32}
          className="mr-2 rounded-full"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = "/images/no-image.png";
          }}
        />
        <div>
          <p className="f-500 f-black m-0">{SupplierProfileName}</p>
          <p className="f-grey f-12">
            {RequestStatusText ? RequestStatusText : subtitle}
          </p>
        </div>
      </header>
      <div className="d-flex align-items-center justify-content-around mt-2">
        <div
          className="f-500 f-darkgrey"
          onClick={() => {
            setSupplySmallChatModal(true);
          }}
        >
          <img
            alt=""
            src="/images/supply-chain/grey-plane.svg"
            className="mr-2"
          />
          Message
        </div>
        {!showResumeBtn ? (
          <div
            className="f-500 f-darkgrey"
            onClick={() => {
              setCompanyDetailsModal(true);
            }}
          >
            <img
              alt=""
              src="/images/supply-chain/dark-info-icon.svg"
              className="mr-2"
            />{" "}
            Details
          </div>
        ) : (
          <Button className="resume-btn">
            <img
              alt=""
              src="/images/supply-chain/resume-icon.svg"
              className="mr-2"
            />
            Resume
          </Button>
        )}
      </div>
      <hr color="#EBEDF1" />
      <div className="details px-2 mb-3">
 { Details?.length > 0 &&      <>
        <span className="m-0 f-12 f-darkgrey f-500 ml-2">Details</span>
        <div className="d-flex flex-wrap">
          {Details?.map((detail, index) => {
            return <div className="detail-item f-500 mx-1 mt-2"> {detail}</div>;
          })}
        </div>
        </>}
      </div>
{ ServicesProvided?.length >0 &&     <div className="services px-2 mb-2">
        <span className="m-0 f-12 f-darkgrey f-500 ml-2 mt-2">
          Services provided
        </span>
        <div className="d-flex flex-wrap">
          {ServicesProvided?.map((service, index) => {
            return (
              <div className="service-item f-500 mx-1 mt-2"> {service}</div>
            );
          })}
        </div>
      </div>}
      <div className="f-grey f-12 f-500 p-3 pb-1">
        {SupplierProfileName} has been sharing their HighGround data with you
        since&nbsp;
        <strong>{SharingSince}</strong>
      </div>

      <CompanyDetailsModal
        show={companyDetailsModal}
        hideModal={() => setCompanyDetailsModal(false)}
        supplierId={supplierId}
      />

      <SupplySmallChatModal
        show={showSupplySmallChatModal}
        hideModal={() => setSupplySmallChatModal(false)}
      />
    </div>
  );
};

export default SupplierDetailsPopover;
