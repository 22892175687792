import React, { useState, useEffect, Fragment } from "react";
import "./CyberScoreDetails.css";
import { Col, Row, Container, Modal, Button } from "react-bootstrap";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import CollapsibleModalContainer from "../CollapsabileModalContainer/CollapsabileModalContainer";
import { useDb } from "../context/db";
import $ from "jquery";
import LoadingSpinner from "../Spinner/Spinner";
import { useAuth } from "../context/Auth";
import { getAPIData } from "../../Utils/commonApi";

const CyberScoreDetails = (props) => { 
  const [scoreDetails, setScoreDetails] = useState(props.details || 0);
 
 
  const { authTokens, setAuthTokens } = useAuth();

  async function callScoreAPI(num) {
    let data = await getAPIData(num, authTokens,{optionEnum1:props.clientId ?? 0});
    if (data.authToken !== undefined) {
      setAuthTokens(data.authToken);
      setScoreDetails();
      data = await getAPIData(num, data.authToken);
    } else {
      setScoreDetails(data);
    }
  }

  useEffect(() => {
    callScoreAPI(3);
  }, []);

  return (
    <Container>
      {scoreDetails != 0 ? (
        <Row>
          <Col md={12} className="p-0">
            <div
              className="csd_background fullHeight"
              style={{ paddingBottom: "15px" }}
            >
              <Row className="p24px">
                <Col md={{ span: 5, offset: 1 }}>
                  <p className="smallGrey">Technology</p>
                </Col>
                <Col md={{ span: 4, offset: 1 }}>
                  <p className="smallGrey">Impact on CyberScore:</p>
                </Col>
              </Row>

              {scoreDetails.PageGroups.map((item) => {
                const content = () => {
                  return (
                    <div className="whiteBG">
                      <ul className="expList pt-2 pb-2">
                        {/* <li key={item}><span className="pl-3"><img src={item.Icon}></img></span><span className="pl-2 scoreText">{item.Header}</span><span className="pl-1 scoreGrey">{item.Count}</span><span className="right-pr" onClick={() => handleClick(item.Header)}><AiOutlineDown /></span> */}
                        <ul className="detailList">
                          {item.DetailList.map((itemDetail, index) => {
                            console.log(index);
                            console.log("item-" + item.DetailList.length);
                            let indexCount = index + 1;

                            return (
                              <Fragment>
                                <li>
                                  <span>{itemDetail.Descripion}</span>
                                  <span
                                    style={{ color: itemDetail.Colour }}
                                    className="float-right"
                                  >
                                    {itemDetail.Value}
                                  </span>
                                </li>
                                {indexCount < item.DetailList.length ? (
                                  <hr className="mx-auto"></hr>
                                ) : (
                                  <div className="pb-2"></div>
                                )}
                              </Fragment>
                            );
                          })}
                        </ul>
                        {/* </li> */}
                      </ul>
                    </div>
                  );
                };
                return (
                  <Col md={12} className="pb-0">
                    <CollapsibleModalContainer
                      icon={item.Icon}
                      content={content}
                      collapsed={item.Count > 0 ? false : true}
                      title={item.Header}
                      displayCount={item.Count}
                    />
                  </Col>
                );
              })}
            </div>
          </Col>
        </Row>
      ) : (
        <div style={{ height: "100%" }}>
          <LoadingSpinner variant={"primary"} />
        </div>
      )}
    </Container>
  );
};
export default CyberScoreDetails;
