import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { TrimString } from "../../../../Utils/StringTrimmer";

import "../../../MSPSettingsv2/mspMembersAccess/mspMembersAccess.scss";

const MultiSelectDropdown = (props) => {
  const { locked, disabled, placeholder, placeholderIcon } = props;
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);


  useEffect(() => {
    setSelectedOptions(
      props.selected === null || props.selected === "" ? [] : props.selected
    );
    if (typeof props.selected === "object" && props.selected?.length === 0) {
      setInputValue("");
    }
  }, [props.selected]);
console.log(selectedOptions)
  return (
    <Dropdown
      className="members-multiselect-dropdown"
      disabled={locked}
      style={{
        pointerEvents: `${locked || disabled ? "none" : "all"}`,
      }}

    >
      <Dropdown.Toggle className={`${props.isError ? "input-error" : ""}  `}>
        <div className="d-flex flex-column">
        <div className="d-flex flex-wrap">

          {selectedOptions &&
            selectedOptions?.map((selected, selectedIndex) => {
              return (
                <div
                  className="f-12 f-500 f-grey d-flex align-items-center p-2 mr-1 mb-1  bg-grey"
                  title={selected?.fullName}
                >
                  <span className="d-inline-flex">
                    {selected?.fullName ? TrimString(selected?.fullName, 20) : TrimString(selected?.userName, 20)}
                  </span>

                  <img
                    alt=""
                    src="/images/attack-surface/grey-cross-icon.svg"
                    className={`ml-1 pointer ${locked ? "d-none" : ""}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      let updated_data = [...selectedOptions];
                      let Index = updated_data.findIndex(
                        (item) =>
                          item?.userName === selected?.userName
                      );
                      updated_data.splice(Index, 1);
                      setSelectedOptions(updated_data);
                      props.onChange(updated_data);
                    }}
                  />
                </div>
              );
            })} 
          </div>
          <div className="d-flex flex-row align-items-center">
            {placeholderIcon}
          <input
            tabIndex={-1}
            placeholder={placeholder ? placeholder: "Add"}
            type="text"
            className={`border-none w-100 h-100 ${locked ? "d-none-" : ""} `}
            style={{
              pointerEvents: `${locked ? "none" : "all"}`,
            }}
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
              e.stopPropagation();
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && inputValue.trim() !== "") {
                e.preventDefault();
                let selected_list = [...selectedOptions];
                selected_list.push({userName: inputValue, objId_PSAMember: 0, objId_PSAContact: 0});
                setSelectedOptions(selected_list);
                props.onChange(selected_list);
                setInputValue("")
              }
              e.stopPropagation();
            }}
          />
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="hide-scrollbar"
        style={{ maxHeight: "300px", overflow: "auto" }}
      >
        <div className="px-2">
          { 
            data
              ?.filter((filterItem) => {
                return filterItem?.fullName
                  ?.toLowerCase()
                  .includes(inputValue?.toLowerCase()) || filterItem?.userName
                  ?.toLowerCase()
                  .includes(inputValue?.toLowerCase());
              })
              ?.map((item, itemIndex) => {
                return (
                  <div
                    className={`${
                      selectedOptions?.filter(
                        (Item) => Item?.userName === item.userName
                      )?.length > 0
                        ? "f-black"
                        : "f-grey"
                    } 
                        
                        ${item.fullName === "" ? "d-none" : "d-flex"}
                        
                        f-500 f-15 pointer py-1 ml-2  align-items-center justify-content-between`}
                    onClick={(e) => {
                      if (
                        selectedOptions?.filter(
                          (Item) =>
                            Item?.userName === item.userName
                        )?.length === 0
                      ) {
                        let selected_list = [...selectedOptions];
                        selected_list.push(item);
                        setSelectedOptions(selected_list);
                        props.onChange(selected_list);
                      } else {
                      
                      }
                    }}
                  >
                     <div className="ml-2">
                  <div className="f-500 f-grey">{item.fullName}</div>
                  <div className="f-12 f-darkgrey">{TrimString(item.userName,30)}</div>
                </div>
                    {selectedOptions?.filter(
                      (Item) => Item?.userName === item.userName
                    )?.length > 0 && (
                      <img
                        alt=""
                        width={11}
                        className="mb-1 mr-2"
                        src="/images/settings-v2/black-tick.svg"
                      />
                    )}
                  </div>
                );
              })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MultiSelectDropdown;
