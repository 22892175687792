import React, { useState, useEffect } from "react";

import { Button, Modal, Form, Row, Col } from "react-bootstrap";

import "../Integrations/Integrations.css";
import { useAuth } from "../context/Auth";
// import { CallToast } from "./CustomToast";
import { postRequest } from "../../Utils/commonApi";

const SkipModal = (props) => {
  const { authTokens } = useAuth();

  const handleClose = () => {
    setTimeout(() => {
      props.isClose();
    }, 300);
  };

  const SubmitNotificationSettings = (value) => {
    const data = {
      SettingOwner: 0,
      OwnerType: 1,
      NotificationName: "ShowActionCenterModal",
      NotificationValue: value,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    };

    postRequest(authTokens, "SubmitNotificationSettings", data).then(
      (response) => {
        if (response.Success) {
          window.localStorage.setItem(
            "user_MessageObj",
            JSON.stringify(response?.Message)
          );
        }
      }
    );
  };
  return (
    <Modal
      show={props.show}
      size="lg"
      dialogClassName="modal-50w"
      className="integrationModal centerModal dashboardModal cancelModalWidth"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => handleClose()}
    >
      <Modal.Body className="modalContent">
        <h1>Want to skip this just now?</h1>{" "}
        <div
          className="d-flex align-self-center mt16px"
          style={{ justifyContent: "center" }}
        >
          <Form.Group
            controlId="checkEleven"
            className="square-checkbox grey-checkboxs"
          >
            <Form.Check
              type="checkbox"
              id="switch5"
              //   checked={showUnchecked || showChecked}
              label=""
              style={{ marginTop: "12px", width: "20px", height: "19px" }}
              onClick={() => {
                SubmitNotificationSettings(0);
                handleClose();
              }}
            />
          </Form.Group>
          <span className="mt-3" style={{ paddingLeft: "5px" }}>
            Don't show this page again
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Row className="m-0" style={{ justifyContent: "space-between" }}>
          <Col md={4} className="p-0">
            <p className="cursor-pointer pt-3" onClick={() => handleClose()}>
              Cancel
            </p>
          </Col>

          <Col md={4} className="p-0">
            <Button
              style={{ padding: "9px 23px" }}
              variant="primary"
              type="submit"
              className="float-right"
              onClick={() => {
                SubmitNotificationSettings(1);

                handleClose();
              }}
            >
              Skip
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default SkipModal;
