import React, { useEffect } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import "../supplier-profiler.scss";
import HighgroundDatePicker from "../../test/TestComponent";
import RevokeDropdown from "./RevokeDropdown";
import ImageWithInitials from "../../../Utils/ImageWithInitials";
import { useState } from "react";
import {
  ContributorGetAPIData,
  ContributorPostData,
  getAPIData,
  postData,
} from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";

const AskForHelpDropdown = ({
  obj,
  contributors,
  status,
  questionId,
  hideModal,
  questionList,
  refreshData,
  sectionType_List,
  isContributorView,
  notQuestionBlock,
}) => {
  const { authTokens } = useAuth();
  const [inputValue, setInputValue] = useState("");
  const [saving, setSaving] = useState(false);
  const [contributorsTemp, setContributorsTemp] = useState([]);
  const [askData, setAskData] = useState({
    NameOrEmail: "",
    QuestionId_List: questionList,
    SectionType_List: sectionType_List,
    AllowContributors: true,
    DueDate: "",
    Message: "",
  });
  const [currentContributor, setCurrentContributor] = useState({});
  useEffect(() => {
    getAllContributors();
  }, []);
  useEffect(() => {
    setAskData({
      ...askData,
      QuestionId_List: questionList,
    });
  }, [questionList]);

  async function getContributorEmail(contributorId) {
    await getAPIData(768, authTokens, {
      optionEnum1: contributorId,
    })
      .then((response) => {
        if (response?.mr?.Success) {
          setCurrentContributor(response.Message);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async function getAllContributors() {
    if (isContributorView) {
      await ContributorGetAPIData(769, authTokens)
        .then((response) => {
          if (response?.mr?.Success) {
            setContributorsTemp(response.ProfilingContributor_List);
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await getAPIData(769, authTokens)
        .then((response) => {
          if (response?.mr?.Success) {
            setContributorsTemp(response.ProfilingContributor_List);
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  async function handleAsk() {
    setSaving(true);
    if (isContributorView) {
      if (notQuestionBlock) {
        await ContributorPostData(authTokens, "CreateSCMProfilingAsk", {
          // accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
          SectionType_List: JSON.stringify(askData?.SectionType_List),
          NameOrEmail: currentContributor.Email,
          AllowContributors: askData?.AllowContributors,
          DueDate: askData?.DueDate,
          Message: askData?.Message,

          ObjId_SendQuestionnaire: questionId,
        })
          .then((response) => {
            if (response?.Success) {
              CallToast("Saved successfully", false, false, "v2style");
              hideModal();
              // if(refreshData){
              //   refreshData()
              // }
            } else {
              CallToast("Something went wrong", false, false, "v2style");
            }
            setSaving(false);
          })
          .catch((err) => {
            console.log(err);
            CallToast("Something went wrong", false, false, "v2style");
            setSaving(false);
          });
      } else {
        await ContributorPostData(authTokens, "CreateSCMProfilingAsk", {
          // accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
          QuestionId_List: JSON.stringify(askData?.QuestionId_List),
          SectionType_List: JSON.stringify(askData?.SectionType_List),
          NameOrEmail: currentContributor.Email,
          AllowContributors: askData?.AllowContributors,
          DueDate: askData?.DueDate,
          Message: askData?.Message,

          ObjId_SendQuestionnaire: questionId,
        })
          .then((response) => {
            if (response?.Success) {
              CallToast("Saved successfully", false, false, "v2style");
              hideModal();
              // if(refreshData){
              //   refreshData()
              // }
            } else {
              CallToast("Something went wrong", false, false, "v2style");
            }
            setSaving(false);
          })
          .catch((err) => {
            console.log(err);
            CallToast("Something went wrong", false, false, "v2style");
            setSaving(false);
          });
      }
    } else {
      if (notQuestionBlock) {
        await postData(authTokens, "CreateSCMProfilingAsk", {
          // accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
          SectionType_List: JSON.stringify(askData?.SectionType_List),
          NameOrEmail: currentContributor.Email,
          AllowContributors: askData?.AllowContributors,
          DueDate: askData?.DueDate,
          Message: askData?.Message,

          ObjId_SendQuestionnaire: questionId,
        })
          .then((response) => {
            if (response?.Success) {
              CallToast("Saved successfully", false, false, "v2style");
              hideModal();
              // if(refreshData){
              //   refreshData()
              // }
            } else {
              CallToast("Something went wrong", false, false, "v2style");
            }
            setSaving(false);
          })
          .catch((err) => {
            console.log(err);
            CallToast("Something went wrong", false, false, "v2style");
            setSaving(false);
          });
      } else {
        await postData(authTokens, "CreateSCMProfilingAsk", {
          // accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
          QuestionId_List: JSON.stringify(askData?.QuestionId_List),
          SectionType_List: JSON.stringify(askData?.SectionType_List),
          NameOrEmail: currentContributor.Email,
          AllowContributors: askData?.AllowContributors,
          DueDate: askData?.DueDate,
          Message: askData?.Message,

          ObjId_SendQuestionnaire: questionId,
        })
          .then((response) => {
            if (response?.Success) {
              CallToast("Saved successfully", false, false, "v2style");
              hideModal();
              // if(refreshData){
              //   refreshData()
              // }
            } else {
              CallToast("Something went wrong", false, false, "v2style");
            }
            setSaving(false);
          })
          .catch((err) => {
            console.log(err);
            CallToast("Something went wrong", false, false, "v2style");
            setSaving(false);
          });
      }
    }
  }

  function checkValidation() {
    console.log(askData);
    let errors = 0;
    let updated_data = { ...askData };
    try {
      if (!askData.SectionType_List && askData?.QuestionId_List?.length == 0) {
        errors = errors + 1;

        updated_data.QuestionId_ListError = true;
      }
      if (!currentContributor?.Email?.trim()) {
        errors = errors + 1;

        updated_data.NameError = true;
      }
      if (!askData.DueDate.trim()) {
        errors = errors + 1;

        updated_data.DueDateError = true;
      }
      if (!askData.Message.trim()) {
        errors = errors + 1;

        updated_data.MessageError = true;
      }

      setAskData(updated_data);
    } catch (err) {
      console.log(err, "errrrr");
    }

    return errors;
  }

  return (
    <Dropdown
      className="hg-white-dropdown user-detail-dropdown supplier-menu"
      style={{ inset: "auto -24px auto auto !important" }}
      onToggle={() => {}}
    >
      <Dropdown.Toggle
        className="d-flex custom-dropdown-toggle f-gray-imp"
        style={{ padding: 0 }}
      >
        {status == "ask" ? (
          <Button className="hg-blue-btn">Ask</Button>
        ) : (
          <div
            className=" open-block d-flex flex-row "
            style={{ padding: "2px", fontWeight: "500" }}
          >
            <img
              src={"/images/supply-chain/add-user-icon.svg"}
              className="mr-2 ml-2"
              alt=""
            />

            <div> Ask for help</div>
          </div>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu
        className=" hide-scrollbar"
        style={{
          width: " 400px",
          border: "1px solid #EBEDF1",
          borderRadius: "8px",
          height: "422px",
          // overflowY: "scroll",
        }}
      >
        <div
          className="main-block d-flex flex-column"
          style={{
            // borderBottom: "1px solid #EBEDF1",
            padding: "8px 12px",
            gap: "4px",
          }}
        >
          <div className="d-flex flex-row" style={{ gap: "4px" }}>
            <div
              className="w-100 f-grey d-flex highlight-input-with-grey-border"
              style={{ borderRadius: "4px", border: "1px solid #EBEDF1" }}
            >
              <input
                type="text"
                className={`${
                  askData.NameError ? "input-error" : ""
                } bg-transparent w-100 border-none pl-2`}
                placeholder="Enter name or email"
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                  setCurrentContributor({ Email: e.target.value });
                  setAskData({
                    ...askData,
                    NameError: false,
                  });
                }}
              />
            </div>
            <Button
              className={`${
                // disableAsk
                false ? "hg-cancel-btn" : "hg-blue-btn"
              } `}
              onClick={async () => {
                let errors = await checkValidation();

                if (errors > 0) {
                  let error_element =
                    document.getElementsByClassName(`input-error`);
                  if (error_element?.length > 0) {
                    error_element[0].scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                      inline: "nearest",
                    });
                  }
                  if (
                    !askData.SectionType_List &&
                    askData.QuestionId_List.length == 0
                  ) {
                    CallToast(
                      "Choose at least one question to ask.",
                      false,
                      false,
                      "v2style"
                    );
                  } else {
                    CallToast(
                      "Fields can not be empty.",
                      false,
                      false,
                      "v2style"
                    );
                  }

                  return;
                } else {
                  handleAsk();
                }
              }}
            >
              {saving ? "Asking..." : "Ask"}
            </Button>
          </div>
          <textarea
            type="text"
            rows={5}
            className={`${
              askData.MessageError ? "input-error" : ""
            } edit-task w-100 f-grey`}
            style={{
              borderRadius: "4px",
              border: "1px solid #EBEDF1",
              padding: "8px",
            }}
            value={askData?.Message}
            placeholder={"Message"}
            onChange={(e) => {
              setAskData({
                ...askData,
                Message: e.target.value,
                MessageError: false,
              });
            }}
          />
          <div
            className=" f-grey "
            style={{ display: "grid", gridTemplateColumns: "65% 35%" }}
          >
            <div>Due Date</div>
            <HighgroundDatePicker
              selected={askData?.DueDate}
              disablePreviousDates={true}
              placeholder={"Add Due date"}
              icon={"/images/attack-surface/date-icon.svg"}
              onChange={(e) => {
                setAskData({
                  ...askData,
                  DueDate: e,
                  DueDateError: false,
                });
              }}
              id={"bhfgvwmv"}
              isError={askData.DueDateError}
            />
          </div>
          <div
            className=" f-grey "
            style={{ display: "grid", gridTemplateColumns: "93% 7%" }}
          >
            <div>Allow contributors to ask for help</div>
            <Form.Check
              type="switch"
              id="switch"
              checked={askData?.AllowContributors}
              className="m-0"
              onClick={(e) => {
                // handleChange();
                setAskData(prevAskData => ({
                  ...prevAskData,
                  AllowContributors: !prevAskData.AllowContributors,
                  SectionType_List: prevAskData.SectionType_List.map(section => ({
                    ...section,
                    allowContributors: !prevAskData.AllowContributors,
                  })),
                }));
              }}
              onChange={(e) => {
                // if (e.target.checked) {
                //   TrackUser("Notification suppression enabled");
                // }
              }}
            />
          </div>
          {contributorsTemp && (
            <div
              className="dropdown-contributors w-100 hide-scrollbar"
              style={{ padding: 0, overflowY: "scroll", height: "200px" }}
            >
              <div className="f-darkgrey">Recent contributors</div>
              <div className="w-100">
                {contributorsTemp
                  ?.filter((filterItem) => {
                    return (
                      filterItem?.Name?.toLowerCase().includes(
                        inputValue?.toLowerCase()
                      ) ||
                      filterItem?.Email?.toLowerCase().includes(
                        inputValue?.toLowerCase()
                      )
                    );
                  })
                  ?.map((r) => {
                    return (
                      <div
                        className="item"
                        onClick={() => {
                          setCurrentContributor(r);
                          setInputValue(r.Name);
                          // setAskData({
                          //   ...askData,
                          //   NameOrEmail: r?.Email, //must be email
                          // });
                        }}
                      >
                        <div
                          className="d-flex"
                          style={{ gap: "8px", alignItems: "center" }}
                        >
                          {/* {r?.showInitials ? (
                            <div className="rounded-full w-fit-content h-fit-content position-relative ">
                              <ImageWithInitials
                                initials={r?.initials}
                                width={25}
                                background={"purple"}
                              />
                              {r?.statusIcon && (
                                <img
                                  width={13}
                                  src={"/images/msp/" + r?.statusIcon}
                                  className="rounded-full overlapping_icon"
                                  alt=""
                                />
                              )}
                            </div>
                          ) : (
                            <div className="rounded-full w-fit-content h-fit-content position-relative ">
                              <img
                                width={25}
                                src={`${r?.ContributorLogo}`}
                                className="rounded-full"
                                alt=""
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src =
                                    "/images/msp/default-cert-icon.svg";
                                }}
                              />
                              {r?.statusIcon && (
                                <img
                                  width={13}
                                  src={"/images/msp/" + r?.statusIcon}
                                  className="rounded-full overlapping_icon"
                                  alt=""
                                />
                              )}
                            </div>
                          )} */}
                          <div className="d-flex flex-column">
                            {r.Name}
                            <div className="f-darkgrey">{r.Email}</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
        {/* <div
          className="d-flex flex-row f-grey f-12 justify-content-between"
          style={{ padding: "4px 10px 4px 16px" }}
        >
          <div>https//:high.io/dnfjns3492m</div>
          <div className="d-flex" onClick={() => {
              navigator.clipboard.writeText('https//:high.io/dnfjns3492m');
              CallToast("Text Copied !!", false, false, "v2style");
            }}>
            <img
              src={`/images/msp/msp-cert-link-icon.svg`}
              alt=""
              className="mr-2 "
            />
            <div>Copy link</div>
          </div>
        </div> */}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AskForHelpDropdown;
