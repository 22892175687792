import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Row,
  Form,
  Col,
  InputGroup,
  Spinner,
} from "react-bootstrap";

import { Radar } from "react-chartjs-2";

import "./style.scss";
import { postRequest as PrintShareModal } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "./CustomToast";
import { TrackUser } from "../../../Utils/SegmentFunctions";

const PrintSendModal = (props) => {
  const { authTokens } = useAuth();
  const { scenarioData, setValues } = props;
  const [email, setEmail] = useState("");

  const [processing, setProcessing] = useState(false);
  const [selectedScenario, setSelectedScenario] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [state, setState] = useState(1);
  const [scenarioList, setScenarioList] = useState([]);

  const isCloseModal = (onceCreated = false) => {
    if (onceCreated == false && props.modalState == "Print") {
      TrackUser(
        "Price calculator print scenario clicked off before completing"
      );
    }
    if (onceCreated == false && props.modalState == "Share") {
      TrackUser("Price calculator share scenario clicked off");
    }

    setValues.setState({ autoSelected: undefined });
    setSelectedScenario([]);
    props.isClose();
    setSubmit(false);
    setEmail("");
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },

    tooltips: {
      enabled: false,
    },
    scale: {
      pointLabels: {
        fontSize: 16,
        callback: function (val, i) {
          // const dat = [35, 35, 35, 35, 15];
          // const vv = val + ` - ${dat[i]}`;
          return "";
        },
      },
      ticks: {
        beginAtZero: true,
        stepSize: 5,
        max: 35,
        min: 0,
        display: false,
        backdropColor: "rgba(0, 0, 0, 0)",
        // callback: function () {
        //   return "";
        // },
      },
    },
    elements: {
      point: {
        radius: 0.2,
        hoverRadius: 0,
      },
    },
  };

  useEffect(() => {
    if (setValues.state.autoSelected) {
      let test_data = [...scenarioData?.ScenarioHheaderList];
      let Index = test_data.findIndex(
        (scenario) =>
          scenario?.objId_Scenario ===
          setValues.state.autoSelected?.objId_Scenario
      );

      test_data.splice(Index, 1);
      test_data.unshift(setValues.state.autoSelected);
      setScenarioList(test_data);
      setSelectedScenario([...selectedScenario, setValues.state.autoSelected]);
    } else {
      setScenarioList(scenarioData?.ScenarioHheaderList);
    }
  }, [props.isOpen]);

  const PrintShareData = (type) => {
    let scenarioIds = [];

    if (type === "Email") {
      const re =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (!email.match(re)) {
        CallToast("Please enter valid Email", false);
        return false;
      }
    }

    for (let i = 0; i < selectedScenario?.length; i++) {
      scenarioIds.push(selectedScenario[i].objId_Scenario.toString().trim());
    }

    if (scenarioIds?.length === 0) {
      CallToast("Please select any scenario", false);
      return false;
    }

    if (type === "Email") {
      setState("sending");
    }
    setProcessing(true);
    const RequestedData = {
      InputType: "PricingScenario",
      InputValue: JSON.stringify(scenarioIds),
      InputTarget: type,
      TargetAddress: email ? email : "null",
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    };

    PrintShareModal(authTokens, "PrintAndShareModal", RequestedData)
      .then((res) => {
        setProcessing(false);
        if (type === "Email" && res.Success === true) {
          // setSubmit(true);
          TrackUser("Price calculator share scenario sent");
          CallToast(`Pricing was sent to "${email}" `);
          setState(1);

          setTimeout(() => isCloseModal(true), 10);
          return false;
        }
        if (type === "Print" && res.Success === true) {
          // setSubmit(true);
          TrackUser("Price calculator printed scenario.");
          download(res?.Message);

          // CallToast(`Pricing was sent to "${email}" `);
          setState(1);

          setTimeout(() => isCloseModal(true), 10);
          return false;
        }

        setTimeout(() => isCloseModal(true), 10);
      })
      .catch((err) => {
        setState(1);
        setProcessing(false);
        CallToast("Something went wrong", false);
      });
  };

  // Function to download file
  async function download(_url) {
    try {
      const response = await fetch(_url);
      const data = await response.blob();
      const a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(data);
      a.href = url;

      a.download = "file.pdf";
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Modal
      show={props.isOpen}
      // show={true}
      dialogClassName="modal-50w"
      className="integrationModal  centerModal printsendmodal printModalHeader"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        isCloseModal();
        setSelectedScenario([]);
      }}
      scrollable
    >
      <Modal.Header className="">
        <div className="d-flex justify-content-end w-100">
          <img
            src="/images/icons/close.svg"
            alt=""
            className="pointer"
            onClick={() => {
              isCloseModal();
              setSelectedScenario([]);
            }}
          />
        </div>
      </Modal.Header>
      <Modal.Body style={{ overflowY: "auto", overflowX: "hidden" }}>
        <Row className="m-0" style={{ height: "100%" }}>
          <Col md={12} className="p-0">
            <div className={`${submit ? "d-none" : ""}`}>
              <p className="mt-4 mb-2 clrgry">{props.modalState} scenarios:</p>
              {scenarioList?.length > 0 &&
                scenarioList?.map((val, i) => {
                  let radarData = {
                    labels: [
                      "Identity",
                      "Protect",
                      "Detect",
                      "Respond",
                      "Recover",
                    ],
                    datasets: [
                      {
                        data: [
                          val?.InspectScore,
                          val?.ProtectScore,
                          val?.DetectScore,
                          val?.RespondScore,
                          val?.RecoverScore,
                        ],
                        datalabels: {
                          display: false,
                        },
                        backgroundColor: `${props.colors[i % 10]}50`,
                        borderColor: `${props.colors[i % 10]}`,
                        pointBackgroundColor: "rgba(61, 66, 222,1)",
                        borderWidth: 1,
                        pointLabelFontSize: 0,
                        scaleShowLabels: false,
                        label: "Current Posture",
                        // pointRadius: 0
                      },
                    ],
                  };

                  const checkedStatus = selectedScenario.findIndex(
                    (scenario) => scenario.objId_Scenario === val.objId_Scenario
                  );

                  return (
                    <div key={i} className="print_container position-relative">
                      <div className="pl-3 pr-3 pt-3 pb-3 checkboxdiv">
                        <div className="d-flex align-items-center">
                          <Form.Group
                            controlId={"check" + i}
                            className="round-checkbox blue-checkbox checkboxstyle"
                            style={{ marginBottom: "1rem" }}
                          >
                            <Form.Check
                              type="checkbox"
                              name={`StripeId${i}`}
                              label=""
                              checked={checkedStatus == -1 ? 0 : 1}
                              onChange={(e) => {
                                console.log();
                                const Index = selectedScenario.findIndex(
                                  (scenario) =>
                                    scenario?.objId_Scenario ==
                                    val?.objId_Scenario
                                );
                                if (Index < 0) {
                                  setSelectedScenario([
                                    ...selectedScenario,
                                    val,
                                  ]);
                                } else {
                                  let test_array = [...selectedScenario];
                                  test_array.splice(Index, 1);
                                  setSelectedScenario(test_array);
                                }
                              }}

                              // defaultChecked={elmt.StripeId}
                            />
                          </Form.Group>
                          <div className="ml-4">
                            <div className="fnt500">
                              <p className="mb-1 d-flex align-items-center">
                                <span
                                  className="circleDiv d-inline-block mr-1"
                                  style={{
                                    background: `${
                                      props.colors[i % 10]
                                    }` /*`${val?.clr}`*/,
                                  }}
                                />
                                {val.Name}
                              </p>
                              <p className="txt-blue m-0">
                                £
                                {val.BillingPeriod
                                  ? val?.AnnualPrice
                                  : val?.MonthlyPrice}
                                {val?.BillingPeriod ? "/year" : "/mo"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="position-relative">
                        <div className="print_radar">
                          <Radar
                            redraw={true}
                            data={radarData}
                            options={options}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>

            {/* success */}
            <div className={`printSuccessWrapper ${!submit ? "d-none" : ""}`}>
              <img alt="" src="/images/calculator/prints.svg" />
              <p className="font-18 mt-2 mb-2">Sent!</p>
              <p className="font-14 mt-0 w-50 text-center">
                Pricing was sent to <b>{email}</b>. Please check your inbox!
              </p>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className="m-0" style={{ justifyContent: "space-between" }}>
          <Col md={12} className="p-0 text-right">
            {/* props.modalState == "Share" */}
            {props.modalState == "Share" && !submit && (
              <div className="sharebtnwrpper">
                <Form.Group className="sharemodalinpt">
                  <InputGroup className="grey-select ">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="greyText inptLbl">
                        Mail to:
                      </InputGroup.Text>
                    </InputGroup.Prepend>

                    <Form.Control
                      as="input"
                      value={email}
                      placeholder="example@mail.com"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
                {state == 1 && (
                  <button
                    type="button"
                    class="btn-primary sharebtn fntarchivo btn btn-primary"
                    onClick={() => {
                      // setSendData(true);
                      // setTarget("Email");
                      PrintShareData("Email");
                    }}
                  >
                    Send
                  </button>
                )}

                {state == "sending" && (
                  <button
                    type="button"
                    class="btn-primary sharebtn fntarchivo btn greybtnn"
                    disabled={true}
                  >
                    <Spinner
                      animation="border"
                      variant="info"
                      size="sm"
                      className="mr8px"
                    />
                    Sending..
                  </button>
                )}

                {/* {state == 3 && (
                  <button
                    type="button"
                    class="btn-primary sharebtn fntarchivo btn success-btn"
                    // onClick={() => isCloseModal()}
                  >
                    Sent !
                  </button>
                )} */}
              </div>
            )}
            {props.modalState == "Print" && (
              <>
                <Button
                  type="submit"
                  style={{ height: "47px", padding: "13px 23px" }}
                  className="float-right bluebtnn"
                  onClick={() => {
                    PrintShareData("Print");

                    // setSubmit(true);
                    // isCloseModal();
                  }}
                >
                  {processing ? (
                    "Please wait.."
                  ) : (
                    <>
                      <img
                        alt=""
                        src={`/images/calculator/${props.modalState}_w.svg`}
                        className="mr-2"
                      />
                      {props.modalState}
                    </>
                  )}
                </Button>
                <Button
                  type="submit"
                  style={{ height: "47px", padding: "13px 23px" }}
                  className="float-right mr-2 greybtnn"
                  onClick={() => isCloseModal()}
                >
                  Cancel
                </Button>
              </>
            )}
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default PrintSendModal;
