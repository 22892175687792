import React from "react";
import ContentLoader from "react-content-loader";

const BillingPageLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="17%" y="30" rx="3" ry="3" width="10%" height="26" />
      <rect x="17%" y="80" rx="3" ry="3" width="10%" height="26" />
      <rect x="64%" y="80" rx="3" ry="3" width="18%" height="35" />

      <rect x="17%" y="120" rx="4" ry="4" width="65%" height="70" />
      <rect x="17%" y="195" rx="4" ry="4" width="65%" height="70" />
      <rect x="17%" y="270" rx="4" ry="4" width="65%" height="70" />

      <rect x="17%" y="345" rx="4" ry="4" width="65%" height="70" />
      <rect x="17%" y="420" rx="4" ry="4" width="65%" height="70" />
      <rect x="17%" y="495" rx="4" ry="4" width="65%" height="70" />
      <rect x="17%" y="570" rx="4" ry="4" width="65%" height="60" />
      <rect x="17%" y="635" rx="4" ry="4" width="65%" height="60" />
      <rect x="17%" y="700" rx="4" ry="4" width="65%" height="60" />
      <rect x="17%" y="765" rx="4" ry="4" width="65%" height="60" />
      <rect x="17%" y="830" rx="4" ry="4" width="65%" height="60" />
    </ContentLoader>
  );
};

export default BillingPageLoader;
