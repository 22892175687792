import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import MSPClientFilters from "../../MSPv2/clients/MSPClientFilters";
import "./subscriptionFinance.scss";
import AddEditSubscriptionBillingModal from "../modals/AddEditSubscriptionBillingModal";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { getPageDataApi, postData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { getListViewFilters } from "../../MSPv2/MspAttackSurface.js/MspAttackSurface";
import MembersPageLoader from "../../settingsV2/members/MembersPageLoader";
import SubscriptionPortalFinanceListingFilters from "../../MSPv2/MspAttackSurface.js/SubscriptionPortalFinanceListingFilters";
import { GreyLoader } from "../../Theme/APILoaders";
import { HGHighlightText } from "../../App/App";
import { CustomDeleteModal } from "../../DeleteModal/DeleteModal";
import { throttle } from "lodash";
const SubscriptionPortalFinance = () => {
  const SearchInput = useRef();
  const [pageLoading, setPageLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [searchInput, setSearchInput] = useState(false);
  const [timeoutId, setTimeoutId] = useState();
  const [headersList, setHeadersList] = useState([]);
  const [fetchingNewInvoices, setFetchingNewInvoices] = useState(false);
  const [InvoiceData, setInvoiceData] = useState({});
  const [showSubscriptionBillingModal, setShowSubscriptionBillingModal] =
    useState(false);
  const [deleteInvoiceModal, setDeleteInvoiceModal] = useState({
    show: false,
    deleteType: "deleteCyberInsurancePolicy",
    deleteId: "",
  });
  const [pageData, setPageData] = useState({});
  const [sortData, setSortData] = useState({
    sortItem: "Client_Name",
    sortOrder: 0,
    sorting: false,
  });
  function sortRecords(_fieldKey) {
    let _sortOrder = 0;
    if (sortData.sortItem === _fieldKey) {
      _sortOrder = sortData.sortOrder === 0 ? 1 : 0;
    } else {
      _sortOrder = 0;
    }

    setSortData({
      sortItem: _fieldKey,
      sortOrder: _sortOrder,
      sorting: true,
    });

    // getPageData({
    //   ClientNumber: 0,
    //   sortItem: _fieldKey,
    //   sortOrder: _sortOrder,
    // }).then(() => {
    //   setSortData({
    //     sortItem: _fieldKey,
    //     sortOrder: _sortOrder,
    //     sorting: false,
    //   });
    // });
  }
  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchString(value);

    // Clear the previous timeout
    clearTimeout(timeoutId);

    // Set a new timeout to call the API after 1 second
    let _timeoutId = setTimeout(() => {
      getPageData({
        searchString: value,
      });
    }, 800);
    setTimeoutId(_timeoutId);
  };
  const { authTokens } = useAuth();

  // {"SearchCriterias":[{"criteriaEnum":1,"SearchParams":["12/30/2023 8:33:07 PM","3/30/2024 8:33:07 PM"]},{"criteriaEnum":2,"SearchParams":["14262","16253"]},{"criteriaEnum":0,"SearchParams":["32655858","65965863"]},{"criteriaEnum":4,"SearchParams":["0","100"]}],}
  // public enum SearchCriteriaEnum
  // {
  //     Supplier,         //........0
  //     InvoiceDate,      //........1
  //     Categories,       //........2
  //     SubCategories,    //........3
  //     Amount            //........4
  // }
  const [updatingTableData, setUpdatingTableData] = useState(false);
  const [financePageNumber, setfinancePageNumber] = useState(1);
  const [financeFilters, setfinanceFilters] = useState([]);
  const [financeData, setfinanceData] = useState({});
  const [financeNextPageLoading, setfinanceNextPageLoading] = useState(false);
  const [financeInvoiceData, setfinanceInvoiceData] = useState();
  function getPageData(kwargs = {}) {
    setUpdatingTableData(true);
    setfinancePageNumber(kwargs?.financePageNumber ?? 1);
    let nextPageLoading = kwargs?.nextPage;
    let filters = getListViewFilters(kwargs);
    setfinanceFilters(filters);
    return new Promise((resolve, reject) => {
      getPageDataApi("GetSubscriptionListing", authTokens, {
        Filters: kwargs?.filters
          ? JSON.stringify({ SearchCriterias: filters })
          : JSON.stringify({ SearchCriterias: financeFilters }),
        searchString: kwargs?.searchString ?? searchString,
        sortColumn: kwargs?.sortColumn ?? "",
        sortOrder: kwargs?.sortOrder ?? 0,
        pageNo: kwargs?.financePageNumber ?? 1,
        pageSize: 20,
      })
        .then((response) => {
          setPageLoading(false);
          setUpdatingTableData(false);
          try {
            if (response?.mr?.Success) {
              if(nextPageLoading){
                setPageData({
                  ...response?.detail,
                  SubscriptionInvoices: [...pageData?.SubscriptionInvoices,...response?.detail?.SubscriptionInvoices]   ,
                  menuSorting: response?.detail?.Visibility?.map((item) => ({
                    ...item,
                    Visibility: item?.ColumnVisibility,
                    SortOrder: item?.ColumnSortOrder,
                    FieldKey: item?.ColumnName?.replaceAll(" ", ""),
                    FieldName: item?.ColumnName,
                  })),
                });

              }else{
                setPageData({
                  ...response?.detail,
                  menuSorting: response?.detail?.Visibility?.map((item) => ({
                    ...item,
                    Visibility: item?.ColumnVisibility,
                    SortOrder: item?.ColumnSortOrder,
                    FieldKey: item?.ColumnName?.replaceAll(" ", ""),
                    FieldName: item?.ColumnName,
                  })),
                });

              }
              resolve("Success");
            } else {
              CallToast("Something went wrong", false, false, "v2style");
            }
          } catch (e) {
            console.log(e, "errrro");
          }
        })
        .catch((err) => {
          reject("Something went wrong");
        });
    });
  }

  function UpdateSubscriptionViewSorting(e) {
    setUpdatingTableData(true);
    // getPageDataApi("UpdateSortingVisibility", authTokens, {
    postData(authTokens, "UpdateSortingVisibility", {
      SortingJson: JSON.stringify(e),
      CustomerId: pageData?.clientId,
      SortSettingsArea: 3,
    }).then(() => {
      getPageData();
    });
  }

  useEffect(() => {
    setPageLoading(true);
    getPageData();
  }, []);

  const [financeActiveFilters, setFinanceActiveFilters] = useState([]);

  const [financeFilterOptions] = useState([
    {
      filterName: "Amount",
      filterItemIcon: "/images/msp/msp-subcategory-filter-icon.svg",
      filterData: {
        filterType: "amount",
        fromAmount: "",
        toAmount: "",
      },
    },
    {
      filterName: "Invoice Date",
      filterItemIcon: "/images/msp/msp-date-picker-icon.svg",
      filterData: {
        filterType: "date",
        selectedDateRange: {
          startDate: null, // new Date(),
          endDate: null, //new Date(),
          key: "selection",
        },
      },
    },
    // {
    //   filterName: "Supplier",
    //   filterItemIcon: "/images/msp/msp-amount-filter-icon.svg",
    //   filterData: {
    //     filterType: "supplier",
    //     filterList: [],
    //   },
    // },

    // {
    //   filterName: "Expires",
    //   filterItemIcon: "/images/msp/msp-date-picker-icon.svg",
    //   filterData: {
    //     filterType: "expires",
    //     selectedDateRange: {
    //       startDate: null, // new Date(),
    //       endDate: null, //new Date(),
    //       key: "selection",
    //     },
    //   },
    // },
    // {
    //   filterName: "Status",
    //   filterItemIcon: "/images/msp/msp-amount-filter-icon.svg",
    //   filterData: {
    //     filterType: "status",
    //     filterList: [],
    //   },
    // },
  ]);

  function createEditInvoice(_invoice) {
    return new Promise((resolve, reject) => {
      postData(authTokens, "CreateUpdateSubscriptionInvoice", {
        invoice: JSON.stringify(_invoice),
      })
        .then((response) => {
          CallToast("Updated successfully", false, false, "v2style");
          
          resolve(response);
        })
        .catch((err) => {
          reject("Something went wrong");
        });
      });
  }
  function deleteInvoice(_invoiceId) {
    return new Promise((resolve, reject) => {
      getPageDataApi("ArchiveSubscriptionInvoice", authTokens, {
        objId_invoice: _invoiceId,
      })
        .then((response) => {
          CallToast("Deleted successfully", false, false, "v2style");
          resolve(response);
          getPageData();
        })
        .catch((err) => {
          reject("Something went wrong");
        });
    });
  }


  const throttledFetchData = throttle(getMoreInvoices, 1000);
  async function getMoreInvoices(e) {
    try {
      if (
        e.target.clientHeight + e.target.scrollTop + 20 >=
        e.target.scrollHeight
      ) {
        let element = document.getElementById("mainBody");
        element.removeEventListener("scroll", throttledFetchData);

        setFetchingNewInvoices(true);

        getPageData({
          financePageNumber:financePageNumber+1,
          nextPage:true
        }).then(() => {
          setFetchingNewInvoices(false);
        });

        console.log("You've reached the end of the screen!");
      }
    } catch (error) {
      console.log("errror aa gya", error);
    }
  }

  useEffect(() => {
    var element = document.getElementById("mainBody");
    element.removeEventListener("scroll", throttledFetchData);
    element.addEventListener("scroll", throttledFetchData);

    return () => {
      element.removeEventListener("scroll", throttledFetchData);
    };
  }, [pageData, fetchingNewInvoices]);
  return (
    <>
      {pageLoading ? (
        <MembersPageLoader />
      ) : (
        <>
          <div className="px-4 subscription-finance"
          onScroll={()=>{
            console.log("Ddd")
          }}
          
          >
            <div className="p-4" />
            <div className="d-flex bg-grey radius-8">
              <div className="flex-1 p-3 border-right">
                <div className="f-500 f-grey mb-2">Payment Due</div>
                <div className="f-500 f-18 f-black">{pageData?.TotalDue} </div>
              </div>
              <div className="flex-1 p-3">
                <div className="f-500 f-grey mb-2">Paid</div>
                <div className="f-500 f-18 f-black">{pageData?.TotalPaid} </div>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-between my-3">
              <div className="f-16 f-600">Invoices</div>
              {/* Filter section */}
              <div className="d-flex align-items-center ">
                <div className="mx-2 d-flex align-items-center">
                  <img
                    alt=""
                    src="/images/attack-surface/search-icon.svg"
                    className="pointer"
                    onClick={() => {
                      setSearchInput(true);
                      SearchInput.current.focus();
                    }}
                  />
                  <input
                    type="text"
                    placeholder="Search.."
                    ref={SearchInput}
                    value={searchString}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    onBlur={() => {}}
                    className={`${
                      searchInput
                        ? "animated-search-input-open"
                        : "animated-search-input-close"
                    } pl-2`}
                    autoFocus
                  />
                  <img
                    alt=""
                    style={{
                      visibility: searchString !== "" ? "visible" : "hidden",
                    }}
                    src="/images/settings-v2/header-search-cross.svg"
                    className="pointer"
                    onClick={() => {
                      handleInputChange({ target: { value: "" } });
                    }}
                  />
                </div>
                <div className="mr-2">
                  <Dropdown className="transparennt-wrapper-dropdown ">
                    <Dropdown.Toggle className="p-0">
                      <div className="f-grey f-500">Filter</div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="p-1"
                      style={{
                        width: "200px",
                      }}
                    >
                      {financeFilterOptions
                        // ?.filter((item) => hideFilters.indexOf(item?.filterName) === -1)
                        .map((filterItem, filterItemIndex) => {
                          return (
                            <div
                              className="d-flex align-items-center  justify-content-between f-grey f-500 p-2 pointer radius-2 bg-hover-grey"
                              onClick={(e) => {
                                let updated_active_filters = [
                                  ...financeActiveFilters,
                                ];
                                let indexOfFilter =
                                  updated_active_filters.findIndex(
                                    (item) =>
                                      item?.filterType ===
                                      filterItem?.filterData?.filterType
                                  );
                                if (indexOfFilter === -1) {
                                  setFinanceActiveFilters([
                                    ...updated_active_filters,
                                    { ...filterItem?.filterData },
                                  ]);
                                }
                                try {
                                  setTimeout(() => {
                                    let filter_Component =
                                      document.querySelector(".filter-box-X");
                                    if (filter_Component) {
                                      let dropdown =
                                        filter_Component.querySelector(
                                          `#${filterItem?.filterData?.filterType}_filterX`
                                        );

                                      if (dropdown) {
                                        dropdown
                                          .querySelector(".dropdown-toggle")
                                          .click();
                                      }
                                    }
                                  }, 100);
                                } catch (err) {}

                                e.target
                                  .closest(".transparennt-wrapper-dropdown")
                                  .click();
                              }}
                            >
                              <div>{filterItem.filterName}</div>
                              <img
                                src="/images/attack-surface/chevron-right.svg"
                                alt=""
                                className=""
                              />
                            </div>
                          );
                        })}
                      {/* {filterOptions.map((filterItem, filterItemIndx) => {
                      return (
                        <div className="d-flex align-items-center  justify-content-between f-grey f-500 p-2 pointer radius-2 bg-hover-grey">
                          <div>{filterItem.filterName}</div>
                          <img
                            src="/images/attack-surface/chevron-right.svg"
                            alt=""
                            className=""
                          />
                        </div>
                      );
                    })} */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="mx-2">
                  <Button className="hg-blue-btn py-1 px-2" onClick={() => {}}>
                    Add
                  </Button>
                </div>
                <div className="d-flex align-items-center mx-2">
                  <MSPClientFilters
                    // headersList={headersList}
                    headersList={pageData?.menuSorting ?? []}
                    updatedHeaderList={(e) => {
                      // getPageData({
                      //   MSPClientSortVisibility_List: JSON.stringify(e),
                      //   ClientNumber: 0,
                      // });
                      let updated_data = e.map((item)=>({
                        ...item,
                        ColumnSortOrder:item.SortOrder,
                        ColumnVisibility:item.Visibility
                      }))
                      UpdateSubscriptionViewSorting(updated_data);
                      setHeadersList(updated_data);
                      console.log(updated_data, "updated");
                    }}
                  >
                    <img
                      alt=""
                      className="pointer"
                      src="/images/settings-v2/horizontal-dots.svg"
                    />
                  </MSPClientFilters>
                </div>
              </div>
            </div>

            <SubscriptionPortalFinanceListingFilters
              activeFilters={financeActiveFilters}
              setActiveFilters={setFinanceActiveFilters}
              financeData={pageData}
              onApply={(filters) => {
                getPageData({
                  filters: filters,
                });
              }}
            />

            {/* Table */}

            <div className="bg-grey radius-8 border-parent position-relative">
            {
            updatingTableData && <div className="position-absolute" style={{
              top:"0px",
              left:"0px",
              bottom:"0px",
              right:"0px",
              opacity:0.5,
              background:"#fff",
              zIndex:30
            }}></div>
          }
              <div className="border-item p-2 f-12 f-500 f-grey invoice-finance-table">
                {pageData?.Visibility?.filter(
                  (item) => item.ColumnVisibility === true
                )
                  ?.sort((a, b) => a.ColumnSortOrder - b.ColumnSortOrder)
                  ?.map((header, headerIndex) => {
                    return (
                      <div
                        className={` ${
                          headerIndex === 0
                            ? "pl-3"
                            : header?.ColumnName === "ColumnName"
                            ? "pl-2"
                            : ""
                        }  pointer`}
                        onClick={() => {
                          let updated_list = { ...pageData };
                          let index = pageData?.Visibility?.findIndex(
                            (obj) => obj.ColumnName === header?.ColumnName
                          );

                          updated_list.Visibility = pageData?.Visibility?.map(
                            (obj, i) => {
                              if (i === index) {
                                return { ...obj, sorting: true };
                              }
                              return obj;
                            }
                          );
                          setPageData(updated_list);
                          getPageData({
                            sortOrder: Number(!pageData?.sortBy?.sortOrder),
                            sortColumn: header?.ColumnName,
                            stopLoader: true,
                          });
                        }}
                      >
                        {header?.ColumnName}{" "}
                        {header?.sorting ? (
                          <GreyLoader width={10} />
                        ) : (
                          <>
                            {pageData?.sortBy?.sortColumn ===
                            header?.ColumnName ? (
                              <img
                                alt=""
                                src="/images/actioncenter/arrowdown.svg"
                                className={`ml-2 ${
                                  pageData?.sortBy?.sortOrder === 0
                                    ? "rotate180"
                                    : ""
                                }`}
                              />
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </div>
                    );
                  })}
                {/* <div className="pl-3">Date</div>
                <div className="pl-2">Account</div>
                <div>Details</div>
                <div>Status</div>
                <div>Amount</div> */}
                <div></div>
              </div>
              {pageData?.SubscriptionInvoices?.map((invoice, invoiceIndex) => {
                return (
                  <div
                    className="invoice-finance-table p-3 border-item bg-hover-dark-grey pointer"
                    onClick={() => {
                      setInvoiceData(invoice);
                      setShowSubscriptionBillingModal(true);
                    }}
                  >
                    {pageData?.Visibility?.filter(
                      (item) => item.ColumnVisibility === true
                    )
                      ?.sort((a, b) => a.ColumnSortOrder - b.ColumnSortOrder)
                      ?.map((header, headerIndex) => {
                        return (
                          <>
                            {header.ColumnName === "Date" && (
                              <div>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: HGHighlightText(
                                      invoice?.Date,
                                      searchString
                                    ),
                                  }}
                                />
                              </div>
                            )}
                            {header.ColumnName === "Account" && (
                              <div>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={
                                      invoice?.AccountIcon ??
                                      "/images/user-logo.svg"
                                    }
                                    width={24}
                                    height={24}
                                    alt=""
                                    className="mr-2 rounded-full"
                                  />
                                  <div>
                                    <div>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: HGHighlightText(
                                            invoice?.AccountName,
                                            searchString
                                          ),
                                        }}
                                      />
                                    </div>
                                    <div className="f-12 f-darkgrey">
                                      {invoice?.AccountType}{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {header.ColumnName === "Details" && (
                              <div className="f-grey f-500">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: HGHighlightText(
                                      invoice?.Details,
                                      searchString
                                    ),
                                  }}
                                />
                              </div>
                            )}

                            {header.ColumnName === "Status" && (
                              <div>
                                <Dropdown
                                  className={`transparennt-wrapper-dropdown `}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <Dropdown.Toggle>
                                    {invoice?.Status?.filter(
                                      (item) => item.isSelected
                                    ).map((status, statusIndex) => {
                                      return (
                                        <div className="mb-2">
                                          <StatusChip
                                            text={status?.StatusText}
                                            color={status?.StatusColor}
                                          />
                                        </div>
                                      );
                                    })}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="p-3  pointer">
                                    <div className="f-12 f-darkgrey mb-2">
                                      Status
                                    </div>
                                    {invoice?.Status?.map(
                                      (status, statusIndex) => {
                                        return (
                                          <div
                                            className="mb-2"
                                            onClick={(e) => {
                                              e.target
                                                .closest(
                                                  ".transparennt-wrapper-dropdown"
                                                )
                                                .click();
                                              let updated_data = {
                                                ...pageData,
                                              };

                                              // Update isSelected property of all elements in the Status array
                                              updated_data.SubscriptionInvoices[
                                                invoiceIndex
                                              ].Status =
                                                updated_data.SubscriptionInvoices[
                                                  invoiceIndex
                                                ].Status.map((element) => {
                                                  return {
                                                    ...element,
                                                    isSelected: false,
                                                  }; // Set isSelected to false for all elements
                                                });

                                              // Set isSelected to true for the element at statusIndex
                                              updated_data.SubscriptionInvoices[
                                                invoiceIndex
                                              ].Status[
                                                statusIndex
                                              ].isSelected = true;

                                              setPageData(updated_data);
                                              createEditInvoice(
                                                updated_data
                                                  .SubscriptionInvoices[
                                                  invoiceIndex
                                                ]
                                              );
                                            }}
                                          >
                                            <StatusChip
                                              text={status?.StatusText}
                                              color={status?.StatusColor}
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            )}

                            {header.ColumnName === "Amount" && (
                              <div className="f-grey f-500">
                                {invoice?.CurrencyCode}
                                {invoice?.Amount}
                              </div>
                            )}
                          </>
                        );
                      })}

                    <div
                      className="d-flex align-items-center justify-content-end pr-5"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Dropdown className={`transparennt-wrapper-dropdown `}>
                        <Dropdown.Toggle>
                          <img
                            alt=""
                            className="pointer"
                            src="/images/settings-v2/horizontal-dots.svg"
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className="p-3  pointer"
                          onClick={(e) => {
                           
                setDeleteInvoiceModal((prev) => ({ ...prev, show: true,deleteId:invoice.objId_invoice }));

                          }}
                        >
                          Delete
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                );
              })}
            </div>
            {fetchingNewInvoices && (
                    <div className="d-flex align-items-center f-darkgrey ml-3">
                      <GreyLoader width={12} />
                      Loading...
                    </div>
                  )}
            <AddEditSubscriptionBillingModal
              _modalData={InvoiceData}
              createEditInvoice={createEditInvoice}
              show={showSubscriptionBillingModal}
              hideModal={() => {
                setShowSubscriptionBillingModal(false);
              }}
              refreshData={() => {
                getPageData();
              }}
            />

            <CustomDeleteModal
              show={deleteInvoiceModal.show}
              deleteHeaderText={"Delete Confirmation"}
              deleteBodyText={"This invoice will be deleted."}
              hideModal={() => {
                setDeleteInvoiceModal((prev) => ({ ...prev, show: false }));
              }}
              deleteType={deleteInvoiceModal.deleteType}
              deleteFunction={deleteInvoice}
              deleteParams={{ deleteId: deleteInvoiceModal.deleteId }}
              callBack={() => {
                getPageData();
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default SubscriptionPortalFinance;

export const StatusChip = ({ text, color }) => {
  return (
    <span
      className="f-12 f-500 f-white"
      style={{
        padding: "2px 6px",
        borderRadius: "30px",
        background: color ?? "#FFAC2C",
      }}
    >
      {text}
    </span>
  );
};
