import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import moment from "moment";
import ImageWithInitials from "../../Utils/ImageWithInitials";
import "../supplierProfiler/supplier-profiler.scss";
import "../MSPv2/clients/mspclients.scss";
import { Button, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import "../supplyChainMonitoring/QuestionnaireTab/questionnairetab.scss";
import RevokeContributorsDropdown from "./ProfilerDropdowns/RevokeContributorsDropdown";
// import AddEditAccreditationModal from "../MSPv2/secOps/accreditations/AddEditAccreditationModal";
// import AddEditCertificateModal from "../MSPv2/secOps/trainingCertificates/AddEditCertificateModal";
import AddFrameworkModal from "./AddFrameworkModal";
import AddProviderModal from "./AddProviderModal";
import EmptyModal from "./EmptyModal";
import QuestionBlock from "./AssessmentBlocks/QuestionBlock";
import AccreditationBlock from "./AssessmentBlocks/AccreditationsBlock";
import CertificationBlock from "./AssessmentBlocks/CertificationBlock";
import SecurityFrameworksBlock from "./AssessmentBlocks/SecurityFrameworks";
import ProviderBlock from "./AssessmentBlocks/ProvidersBlock";
import SummaryBlock from "./AssessmentBlocks/SummaryBlock";
import {
  SubmitQuestionaire,
  getAPIData,
  postData,
  ContributorSubmitQuestionaire,
  ContributorPostData,
  ContributorGetAPIData,
} from "../../Utils/commonApi";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import { useAuth } from "../context/Auth";
import { GreyLoader } from "../Theme/APILoaders";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AssessmentRow from "./AssessmentRow";
import EditEmployeeModal from "./EditEmployeeModal";
import AddQuestionRow from "./AddQuestionRow";
import { DeleteModal } from "../DeleteModal/DeleteModal";
import { viewOnlyAccess } from "../App/App";

const AddEditCertificateModal = lazy(() =>
  import("../MSPv2/secOps/trainingCertificates/AddEditCertificateModal")
);
const AddEditAccreditationModal = lazy(() =>
  import("../MSPv2/secOps/accreditations/AddEditAccreditationModal")
);

const summary =
  "Purus mattis volutpat integer amet non in phasellus eleifend adipiscing. Donec praesent porta tortor sit. In sit mauris aliquam a hendrerit mi scelerisque. Etiam et nunc ultricies feugiat arcu nec quisque cras. Amet id maecenas nisl habitant tortor. Pharetra varius gravida tincidunt eu quis at ligula. Est massa eget facilisi semper erat. Quis faucibus purus nec pulvinar duis odio nibh. Nec sit convallis vulputate leo sed a vitae vulputate molestie. Quis ultrices congue euismod nulla faucibus urna arcu tortor turpis. Ornare malesuada lacus velit congue mi. Id ullamcorper consequat a odio urna nunc viverra. Purus sodales pharetra pellentesque ultricies ridiculus nulla tortor nibh eget. Proin facilisis sed imperdiet leo. Nunc sed morbi imperdiet ultrices at amet.";

const Assessment = ({
  rawBlocks,
  blockItems,
  setProfilingBlocks,
  refreshData,
  loading,
  questionId,
  setSaving,
  AllProfilingObj,
  isTemplate,
  isTemplateEdit,
  updateRawData,
  isContributorView,
  isSent,
  mentionList,
}) => {
  const { authTokens } = useAuth();
  const addQuestionRef = useRef(null);
  const [AssessmentBlock, setAssessmentBlock] = useState([]);
  const [newSummary, setNewSummary] = useState(summary);
  const [currentWord, setCurrentWord] = useState({ word: "", id: 0 });
  const [renameTitle, setRenameTitle] = useState("");
  const [addQuestion, setAddQuestion] = useState({});
  const [toolData, setToolData] = useState([]);
  const [accreditationModalState, setAccreditationModalState] = useState({
    headerName: "Add Accreditation",
    showModal: false,
    accreditationId: 0,
  });
  const [showCertificateModal, setShowCertificateModal] = useState({
    showModal: false,
    employeeData: { position: "", memberName: "", userId: 0 },
    headerName: "Add Certificate",
    certificateId: 0,
  });
  const [showEmployeeModal, setShowEmployeeModal] = useState({
    showModal: false,
    employeeData: { position: "", name: "", userId: 0 },
    headerName: "Edit Employee",
  });
  const [showFrameworkModal, setShowFrameworkModal] = useState({
    headerName: "Add Framework",
    status: "Cyber Security",
    showModal: false,
    selectedId: 0,
    frameworkItem: {},
    AddOrEdit: "Add",
  });
  const [showProviderModal, setShowProviderModal] = useState({
    headerName: "Add Managed Service Providers",
    showModal: false,
    providerId: 0,
  });
  const [showSummary, setShowSummary] = useState(false);
  const [selectedAll, setSelectAll] = useState(false);
  const [cancelAll, setCancelAll] = useState(false);
  const [statusModal, setStatusModal] = useState("open");
  const [showBlockModal, setShowBlockModal] = useState({
    showModal: false,
    header: "Details",
    headerIcon: "",
    content: <></>,
    contributors: [],
    AllowAskForHelp: true,
    notQuestionBlock: false,
  });
  const [showDeleteModal, setShowDeleteModal] = useState({
    showModal: false,
    deleteMessage: "You are about to delete.",
    deleteSentQuestionnaire: () => {},
  });

  const [showBlocks, setShowBlocks] = useState([]);
  const [data_to_send, setData_to_send] = useState([]);
  const [saveTemplateStatus, setSaveTemplateStatus] = useState(false);
  const [updateShowBlocks, setUpdateShowBlocks] = useState(true);
  const [questionList, setQuestionList] = useState([]);
  const [sectionType_List, setSectionType_List] = useState([]);
  const [newSectionTitle, setNewSectionTitle] = useState("");
  const [creatingNewSection, setCreatingNewSection] = useState(false);
  const [newQuestionType, setnewQuestionType] = useState("Text");
  const [editMode, setEditMode] = useState("");
  const [dataForAddQuestion, setDataForAddQuestion] = useState([
    { id: 0, Text: "Option1" },
    { id: 1, Text: "Option2" },
    { id: 2, Text: "Option3" },
  ]); //SCMFormDropdownItems_List
  const [errorInput, setErrorInput] = useState(false);
  const containerRef = useRef();

  const emptyNewSection = {
    ObjectId: 0,
    Title: "",
    Description: "",
    LogoUrl: "",
    SortOrder: 1,
    EnumerateQuestions: true,
    Progress: 3,
    ProgressText: "InComplete",
    PrePopulatedProgressText: null,
    SectionTypeEnum: -1,
    SectionTypeText: "Questionnaire",
    Question_List: [
      // {
      //   QuestionId: 0,
      //   QuestionAllowEdit: true,
      //   QuestionOrder: 1,
      //   QuestionScore: 0,
      //   QuestionDescription: null,
      //   QuestionDropdownListMembers: null,
      //   QuestionSectionNo: 1,
      //   QuestionStatus: 5,
      //   QuestionStatusText: "LoadedForEdit",
      //   QuestionText: "Test Question ",
      //   QuestionTypeEnum: 0,
      //   QuestionTypeText: "Text",
      //   QuestionCheckBoxLabel: "na",
      //   QuestionUserList: null,
      //   AnswerId: 0,
      //   AnswerBool: null,
      //   AnswerDate: "2024-08-16T11:12:20.9243133+05:30",
      //   AnswerText: "",
      //   AnswserSelectedDropdownItems: null,
      //   AnswerSelectedQuestionDDLObjId: 0,
      //   AnswerSelectedUserNames: null,
      //   DateTimeAnswer: null,
      //   Label: null,
      //   QuestionParentId: 0,
      //   ChildQuestions: null,
      //   ResponseComment_List: null,
      //   NISTAssetClassification_List: null,
      //   NoteAnswerText: null,
      //   Explanation: null,
      //   Statement: null,
      //   AnswerArrayText: null,
      // },
    ],
  };
  const EmptyQuestion = {
    QuestionId: 0,
    QuestionAllowEdit: true,
    QuestionOrder: 1,
    QuestionScore: 0,
    QuestionDescription: null,
    QuestionDropdownListMembers: null,
    QuestionSectionNo: 1,
    QuestionStatus: 5,
    QuestionStatusText: "LoadedForEdit",
    QuestionText: "Test Question ",
    QuestionTypeEnum: 0,
    QuestionTypeText: "Text",
    QuestionCheckBoxLabel: "na",
    QuestionUserList: null,
    AnswerId: 0,
    AnswerBool: null,
    AnswerDate: "2024-08-16T11:12:20.9243133+05:30",
    AnswerText: "",
    AnswserSelectedDropdownItems: null,
    AnswerSelectedQuestionDDLObjId: 0,
    AnswerSelectedUserNames: null,
    DateTimeAnswer: null,
    Label: null,
    QuestionParentId: 0,
    ChildQuestions: null,
    ResponseComment_List: null,
    NISTAssetClassification_List: null,
    NoteAnswerText: null,
    Explanation: null,
    Statement: null,
    AnswerArrayText: null,
  };
  const [isDragging, setIsDragging] = useState(false);
  const handleDrag = () => {
    if (!isDragging) {
      setIsDragging(true);
      handleScroll();
    }
  };

  const handleDragStop = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      // Do one thing
    } else {
      // Do a different thing
    }
  }, [isDragging]);

  // console.log("assessment main QUESTION LIST ", questionList);
  useEffect(() => {
    function FilterDropDownCloser(e) {
      const dropdownElement = document.querySelector(".add-question-row");
      const buttonElements = document.querySelectorAll(".add-quest-btn");

      // Check if the dropdownElement exists and if the click target is outside the dropdown
      const isOutsideDropdown =
        dropdownElement && !dropdownElement.contains(e.target);

      // Check if any of the button elements contain the click target
      const isInsideButton = [...buttonElements].some((button) =>
        button.contains(e.target)
      );

      const isCrossButtonClicked = e.target.classList.contains("addBtnRow");

      // If both conditions are met, close the dropdown
      if (isOutsideDropdown && !isInsideButton && !isCrossButtonClicked) {
        setAddQuestion({});
        setDataForAddQuestion([
          { id: 0, Text: "Option1" },
          { id: 1, Text: "Option2" },
          { id: 2, Text: "Option3" },
        ]);
        setnewQuestionType({});
      }
    }

    document.addEventListener("click", FilterDropDownCloser);

    return () => {
      document.removeEventListener("click", FilterDropDownCloser);
    };
  }, []);
  // useEffect(() => {
  //   if (blockItems) {
  //     let newObj = blockItems?.map((b) => {
  //       getBlockContributors(b.ObjectId, blockItems);
  //     });
  //   }
  // }, [blockItems]);
  const [blockItemsFilled, setBlockItemsFilled] = useState(false);
  const [revokedChange, setRevokedChange] = useState(false);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (blockItems && blockItems?.length > 0) {
  //       for (const b of blockItems) {
  //         await getBlockContributors(b.ObjectId, blockItems);
  //       }
  //     }
  //   };

  //   if (!blockItemsFilled && blockItems && blockItems.length > 0) {
  //     fetchData();
  //     setBlockItemsFilled(true);
  //   }
  // }, [blockItems, blockItemsFilled, revokedChange]);
  useEffect(() => {
    console.log("ISTEMPLATE", isTemplate);
    // getProfilingTemplatesWithAnswers();
    if (statusModal != "open") {
      askOnclick();
    }
    if (blockItems && blockItems?.length > 0) {
      setAssessmentBlock(blockItems); //.AssesmentVm?.QuestionnaireSection_List);
      console.log("assesm block 1 -------", AssessmentBlock, blockItems);
      let temp = [];
      let newObj = blockItems?.map((b) => {
        // getBlockContributors(b.ObjectId, blockItems);
        temp.push({ ObjectId: b.ObjectId, show: false });
      });
      if (updateShowBlocks) {
        setShowBlocks(temp);
      }
    }
    if (data_to_send && saveTemplateStatus) {
      saveTemplate();
    }
  }, [
    statusModal,
    selectedAll,
    blockItems,
    updateShowBlocks,
    data_to_send,
    saveTemplateStatus,
    revokedChange,
  ]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEndShown(result) {
    console.log(result);
    if (!result.destination) {
      return;
    }
    console.log(result.source.index);
    const updatedAssessmentBlocks = AssessmentBlock.map((ab) => {
      const currBlock = ab.Question_List.find((quest) => {
        return quest.QuestionId == result.draggableId;
      });
      if (currBlock) {
        const updatedQuestionList = reorder(
          ab.Question_List,
          result.source.index,
          result.destination.index
        );

        const updatedQuestionListWithSortOrder = updatedQuestionList.map(
          (item, index) => ({
            ...item,
            SortOrder: index + 1,
          })
        );

        return {
          ...ab,
          Question_List: updatedQuestionListWithSortOrder,
        };
      }
      return ab;
    });
    console.log(result.draggableId, updatedAssessmentBlocks);
    setAssessmentBlock(updatedAssessmentBlocks);
    console.log(
      "assesm block 2 -------",
      AssessmentBlock,
      updatedAssessmentBlocks
    );

    handleScroll();
  }
  const handleScroll = () => {
    console.log(containerRef);
    containerRef.current.scrollBy(0, 1); // Измените значение на -1 для прокрутки вверх
  };

  function onDragEndShownSelectorOptions(result) {
    if (!result.destination) {
      return;
    }
    // console.log(result.source.index);
    const items = reorder(
      dataForAddQuestion,
      result.source.index,
      result.destination.index
    );
    setDataForAddQuestion(
      items.map((item, index) => {
        return {
          ...item,
          SortOrder: index + 1,
        };
      })
    );
  }

  function askOnclick(block = {}) {
    console.log(block);
    if (Object.keys(block).length === 0) {
      block = AssessmentBlock.find((i) => i.Title === statusModal);
    }
    if (block) {
      let content = <></>;
      // setStatusModal('ask');
      console.log(statusModal);
      if (
        block.Question_List?.length > 0 &&
        block.Title !== "Data storage and processing"
      ) {
        content = (
          <QuestionBlock
            title={block}
            status={statusModal}
            selectedAll={selectedAll}
            setSelectAll={setSelectAll}
            setCancelAll={setCancelAll}
            cancelAll={cancelAll}
            questionList={questionList}
            addQuestionList={setQuestionList}
          />
        );
      } else if (block.Title === "Data storage and processing") {
        content = (
          <QuestionBlock
            title={block}
            status={statusModal}
            selectedAll={selectedAll}
            setSelectAll={setSelectAll}
            setCancelAll={setCancelAll}
            cancelAll={cancelAll}
            questionList={questionList}
            addQuestionList={setQuestionList}
          />
        );
      } else if (block.Title === "Accreditation") {
        content = (
          <AccreditationBlock
            title={block}
            setAccreditationModalState={setAccreditationModalState}
          />
        );
      } else if (block.Title === "Training Certification") {
        content = (
          <CertificationBlock
            title={block}
            setShowCertificateModal={setShowCertificateModal}
          />
        );
      } else if (block.SectionTypeText === "Security Framework") {
        content = (
          <SecurityFrameworksBlock
            title={block}
            mode={"Security"}
            setShowFrameworkModal={setShowFrameworkModal}
          />
        );
      } else if (block.SectionTypeText === "Data Privacy Framework") {
        content = (
          <SecurityFrameworksBlock
            title={block}
            mode={"Data Privacy"}
            setShowFrameworkModal={setShowFrameworkModal}
          />
        );
      } else if (block.Title === "Managed Service Providers") {
        content = (
          <ProviderBlock
            title={block}
            setShowProviderModal={setShowProviderModal}
          />
        );
      } else if (block.Title === "Summary") {
        content = (
          <SummaryBlock title={block} setShowProviderModal={setShowSummary} />
        );
      }
      setShowBlockModal({
        showModal: true,
        header: block.Title,
        headerIcon: block.LogoUrl,
        content: content,
        contributors:
          block.ActivityTrackingContributorVM?.ActivityTrackingContributor_List,
        AllowAskForHelp: block.allowContributors,
        notQuestionBlock:
          block.Question_List?.length > 0 &&
          block.Title !== "Data storage and processing"
            ? false
            : true,
      });
    }
  }

  async function getBlockContributors(sectionId, block) {
    setSaving(true);
    if (isContributorView) {
      await ContributorGetAPIData(781, authTokens, {
        optionEnum1: questionId,
        optionEnum2: sectionId,
      }).then((response) => {
        if (response) {
          setSaving(false);

          let newField = block.map((item) => {
            if (
              item.ObjectId === sectionId &&
              response?.ActivityTrackingContributor_List?.length > 0
            ) {
              item.ActivityTrackingContributorVM = {};
              item.ActivityTrackingContributorVM.ActivityTrackingContributor_List =
                response.ActivityTrackingContributor_List || [];
            }
            return item;
          });

          setAssessmentBlock(newField);
          console.log("assesm block 3 -------", AssessmentBlock, newField);

          setProfilingBlocks(newField);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
          setSaving(false);
        }
      });
    } else {
      await getAPIData(781, authTokens, {
        optionEnum1: questionId,
        optionEnum2: sectionId,
      }).then((response) => {
        if (response) {
          setSaving(false);

          let newField = block.map((item) => {
            if (
              item.ObjectId === sectionId &&
              response?.ActivityTrackingContributor_List?.length > 0
            ) {
              item.ActivityTrackingContributorVM = {};
              item.ActivityTrackingContributorVM.ActivityTrackingContributor_List =
                response.ActivityTrackingContributor_List || [];
            }
            return item;
          });

          setAssessmentBlock(newField);
          console.log("assesm block 3 -------", AssessmentBlock, newField);

          setProfilingBlocks(newField);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
          setSaving(false);
        }
      });
    }
  }
  async function saveTemplate() {
    setSaving(true);
    if (isContributorView) {
      await ContributorSubmitQuestionaire(authTokens, "POST", {
        ObjectId: 0,
        QuestionnaireTypeEnum: 28,
        QuestionList: JSON.stringify(data_to_send),
        key: JSON.parse(localStorage.getItem("user_MessageObj")),
        objId_SendFormQuestionnaire: questionId,
      }).then((response) => {
        setSaveTemplateStatus(false);
        if (updateRawData) {
          updateRawData();
        }
        if (response?.Success) {
          // CallToast("Updated successfully", false, false, "v2style");
          // refreshData();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setSaving(false);
      });
    } else {
      await SubmitQuestionaire(authTokens, "POST", {
        ObjectId: 0,
        QuestionnaireTypeEnum: 28,
        QuestionList: JSON.stringify(data_to_send),
        accesskey: JSON.parse(localStorage.getItem("user_MessageObj")),
        objId_SendFormQuestionnaire: questionId,
      }).then((response) => {
        setSaveTemplateStatus(false);
        if (updateRawData) {
          updateRawData();
        }
        if (response?.Success) {
          // CallToast("Updated successfully", false, false, "v2style");
          // refreshData();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setSaving(false);
      });
    }
  }

  async function createUpdateSection(section, dataForCreation, addNew = false) {
    let newSection = section ? section : emptyNewSection;
    if (dataForCreation) {
      newSection.Title = dataForCreation.SectionTypeText?.trim();
      newSection.SectionTypeEnum = dataForCreation?.SectionTypeEnum;
      newSection.SectionTypeText = dataForCreation?.SectionTypeText;
    } else if (newSectionTitle) {
      newSection.Title = newSectionTitle;
    }

    console.log("newSection", newSection);
    setSaving(true);
    if (isContributorView) {
      await ContributorPostData(authTokens, "CreateSCMProfilingSection", {
        QuestionnaireSectionVM: JSON.stringify(newSection),
        objId_SendFormQuestionnaire: questionId,
      }).then((response) => {
        if (response?.Success) {
          CallToast("Updated successfully", false, false, "v2style");
          if (addNew) refreshData();
          setNewSectionTitle("");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setSaving(false);
      });
    } else {
      await postData(authTokens, "CreateSCMProfilingSection", {
        QuestionnaireSectionVM: JSON.stringify(newSection),
        objId_SendFormQuestionnaire: questionId,
      }).then((response) => {
        if (response?.Success) {
          CallToast("Updated successfully", false, false, "v2style");
          if (addNew) refreshData();
          setNewSectionTitle("");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setSaving(false);
      });
    }
  }
  async function deleteSection(sectionId, block) {
    setSaving(true);
    await getAPIData(764, authTokens, {
      optionEnum1: sectionId,
      optionEnum2: questionId,
    }).then((response) => {
      if (response?.Success) {
        setSaving(false);
        CallToast("Deleted successfully", false, false, "v2style");
        let tempArr = [];
        AssessmentBlock.map((i) => {
          if (block.ObjectId === i.ObjectId) {
            console.log("deleted ", block.Title);
          } else {
            tempArr.push(i);
          }
        });
        setProfilingBlocks(tempArr);
      } else {
        CallToast("Something went wrong", false, false, "v2style");
        setSaving(false);
      }
    });
  }
  function downloadFile(url, filename) {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  async function deleteAccreditation(_accreditationId) {
    setSaving(true);
    await postData(authTokens, "ArchiveOrDeleteAccreditation", {
      objId_Accreditation: _accreditationId,
      objId_SendFormQuestionnaire: questionId,
    })
      .then((response) => {
        if (response?.Success) {
          CallToast(
            "Accreditation deleted successfully",
            true,
            false,
            "v2style"
          );
          refreshData();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  async function deleteCertification(certificationId, userId) {
    setSaving(true);
    await postData(authTokens, "Certification/ArchiveOrDelete", {
      objId_Certification: certificationId,
      objId_SendFormQuestionnaire: questionId,
      userId: userId,
    })
      .then((response) => {
        if (response?.Success) {
          CallToast(
            "Certification deleted successfully",
            true,
            false,
            "v2style"
          );
          refreshData();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }
  async function deleteProvider(providerId) {
    setSaving(true);
    await getAPIData("766", authTokens, {
      optionEnum1: providerId ?? 0,
      optionEnum2: 0,
      // optionEnum3: questionId,
    })
      .then((response) => {
        if (response?.Success) {
          CallToast("Provider deleted successfully", true, false, "v2style");
          refreshData();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }
  async function deleteFramework(objId, frameworkId) {
    setSaving(true);
    await getAPIData("713", authTokens, {
      optionEnum1: objId ?? 0,
      optionEnum2: frameworkId,
      optionEnum3: questionId,
    })
      .then((response) => {
        if (response?.Success) {
          CallToast("Framework deleted successfully", true, false, "v2style");
          refreshData();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }
  async function deleteEmployee(userId) {
    setSaving(true);
    await postData(authTokens, "Certification/DeleteEmployee", {
      objId_SendFormQuestionnaire: questionId,
      userId: userId,
    })
      .then((response) => {
        if (response?.Success) {
          CallToast("Employee deleted successfully", true, false, "v2style");
          refreshData();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  async function saveNewQuestion(oldSection) {
    let newSection = { ...oldSection };
    let newQuestion = EmptyQuestion;
    newQuestion.QuestionText = addQuestion.QuestionText;
    newQuestion.QuestionTypeEnum = newQuestionType.QuestionTypeEnum;
    newQuestion.QuestionTypeText = newQuestionType.QuestionTypeText;
    newQuestion.QuestionDropdownListMembers =
      newQuestionType.QuestionDropdownListMembers;

    newSection.Question_List.push(newQuestion);
    console.log("newSection", newSection);
    setSaving(true);

    await postData(authTokens, "CreateSCMProfilingSection", {
      QuestionnaireSectionVM: JSON.stringify(newSection),
      objId_SendFormQuestionnaire: questionId,
    }).then((response) => {
      if (response?.Success) {
        CallToast("Updated successfully", false, false, "v2style");
        setDataForAddQuestion([
          { id: 0, Text: "Option1" },
          { id: 1, Text: "Option2" },
          { id: 2, Text: "Option3" },
        ]);
        setnewQuestionType({});
        refreshData();
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
      setSaving(false);
    });
  }

  async function deleteQuestion(deleteQuestion) {
    setSaving(true);
    await getAPIData("664", authTokens, {
      optionEnum1: deleteQuestion ?? 0,
      optionEnum2: questionId,
      optionEnum3: 0,
    })
      .then((response) => {
        if (response?.Success) {
          CallToast("Question deleted successfully", true, false, "v2style");
          refreshData();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }
  useEffect(() => {
    const listItems = document.querySelectorAll(".supplier-assessment ul li");
    if (listItems.length > 0) {
      listItems[listItems.length - 1].classList.add("last-item");
    }
  }, []);
  return (
    <div className="supplier-assessment w-100 info-line" ref={containerRef}>
      {loading ? (
        // <div className="loader d-flex align-items-center justify-content-center h-100 w-70">
        //   <Loader />
        // </div>
        <span className="d-inline-flex align-items-center justify-content-center h-100 w-100">
          <GreyLoader width={30} />
          Loading...
        </span>
      ) : (
        <ul>
          {showBlocks &&
            AssessmentBlock?.map((block, i) => {
              return (
                <li className={`d-flex flex-row ${block.ObjectId} `}>
                  <span className="timeline-circle">
                    <div className=" bg-darkgrey rounded-full img-block">
                      <img
                        src={`${block.LogoUrl}`}
                        alt=""
                        className=" rounded-full bg-darkgrey"
                        style={{ padding: "4px" }}
                      />
                    </div>
                  </span>
                  <div className="block w-100">
                    <div
                      className="d-flex flex-row w-100 justify-space-between header mb-2 mb-2"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className="title w-70 ">
                        <input
                          className="w-100 focused-input fontBold f-black f-16"
                          value={block.Title}
                          disabled={renameTitle === block.Title ? false : true}
                          onBlur={(e) => {
                            updateRawData().then((temp) => {
                              // console.log(temp[7])
                              let currBlock = temp?.find(
                                (obj) => obj.ObjectId == block.ObjectId
                              );

                              if (block.Title == currBlock.Title) {
                                console.log("check 1");
                                setRenameTitle("none");
                              } else {
                                console.log("check 2");
                                createUpdateSection(block);
                                setProfilingBlocks(AssessmentBlock);
                                setRenameTitle("none");
                              }
                              // createUpdateSection(block);
                              // setProfilingBlocks(AssessmentBlock);
                              // setRenameTitle("none");
                            });
                          }}
                          onChange={(e) => {
                            setRenameTitle(e.target.value);
                            let newField = AssessmentBlock.map((item) => {
                              if (item === block) {
                                item.Title = e.target.value;
                              }
                              return item;
                            });

                            setAssessmentBlock(newField);
                            console.log(
                              "assesm block 4 -------",
                              AssessmentBlock,
                              newField
                            );

                            setProfilingBlocks(AssessmentBlock);
                          }}
                          style={
                            renameTitle === block.Title
                              ? {
                                  border: "2px solid #99D2EE",
                                  padding: "4px",
                                  borderRadius: "4px",
                                  backgroundColor: "#fff",
                                }
                              : { border: "none", backgroundColor: "#fff" }
                          }
                        />
                        {/* {block.Title} */}
                        <div className="f-darkgrey">{block.Description}</div>
                      </div>

                      {!isTemplate ||
                      isTemplate === undefined ||
                      isTemplateEdit ? (
                        <div className="secops_training_certificate_overlap_parent mr-3 f-grey">
                          {!isContributorView && isSent && (
                            <OverlayTrigger
                              placement="bottom"
                              trigger={
                                viewOnlyAccess("AccessState_SCM")
                                  ? ["hover", "focus"]
                                  : []
                              }
                              delay={{
                                show: 200,
                                hide: 250,
                              }}
                              overlay={
                                <Tooltip
                                  id={`tooltip-1365`}
                                  className="custom_tooltip_access_assessmentmenu"
                                >
                                  This feature is managed by your service
                                  provider
                                </Tooltip>
                              }
                            >
                              <div>
                                <div
                                  style={{
                                    pointerEvents: viewOnlyAccess(
                                      "AccessState_SCM"
                                    )
                                      ? "none"
                                      : "all",
                                    margin: "0 -17px",
                                  }}
                                >
                                  <Dropdown
                                    className="hg-white-dropdown user-detail-dropdown supplier-menu"
                                    onToggle={() => {}}
                                  >
                                    <Dropdown.Toggle className=" d-flex custom-dropdown-toggle f-gray-imp">
                                      <img
                                        src={
                                          "/images/supply-chain/base-more-dots.svg"
                                        }
                                        className="mr-2 ml-2"
                                        alt=""
                                      />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{ width: "200px" }}>
                                      {block.SectionTypeText ==
                                        "Questionnaire" &&
                                        block.Title !==
                                          "Data storage and processing" && (
                                          <div
                                            className="p-2 pl-3 my-1 pointer f-darkgrey"
                                            onClick={(e) => {
                                              setRenameTitle(block.Title);
                                              // setShowAccessLevelModal(true);
                                              e.target
                                                .closest(".hg-white-dropdown")
                                                .click();
                                            }}
                                          >
                                            <div>Rename</div>
                                          </div>
                                        )}
                                      {/* {(block.Question_List?.length > 0 || */}
                                      {block.SectionTypeText ==
                                        "Questionnaire" &&
                                        block.Title !==
                                          "Data storage and processing" && (
                                          <div
                                            className="p-2 pl-3 my-1 pointer f-darkgrey add-quest-btn"
                                            onClick={(e) => {
                                              setAddQuestion({
                                                title: block.Title,
                                                obj: {},
                                              });

                                              setShowBlocks(
                                                (prevShowBlocks) => {
                                                  return prevShowBlocks.map(
                                                    (t) => {
                                                      if (
                                                        t.ObjectId ===
                                                        block.ObjectId
                                                      ) {
                                                        return {
                                                          ...t,
                                                          show: true,
                                                        };
                                                      }
                                                      return t;
                                                    }
                                                  );
                                                }
                                              );
                                              e.target
                                                .closest(".hg-white-dropdown")
                                                .click();
                                            }}
                                          >
                                            <div>Add question</div>
                                          </div>
                                        )}
                                      {/* {(block.Question_List?.length > 0 || block.Title == 'Data storage and processing') && (
                            <div
                              className="p-2 pl-3 my-1 pointer f-darkgrey"
                              onClick={(e) => {
                                setAddQuestion({title:block.Title, obj: {}});
                                // setRenameTitle(block.Title);
                                // setShowAccessLevelModal(true);
                                // e.target.closest(".hg-white-dropdown").click();
                              }}
                            >
                              <div>Add question</div>
                            </div>
                          )} */}

                                      <div
                                        className="p-2 pl-3 my-1 pointer f-danger"
                                        onClick={(e) => {
                                          setShowDeleteModal({
                                            showModal: true,
                                            deleteMessage:
                                              "You are about to delete a section.",
                                            deleteSentQuestionnaire: () =>
                                              deleteSection(
                                                block.ObjectId,
                                                block
                                              ),
                                          });
                                          // deleteSection(block.ObjectId);
                                          // let tempArr = [];
                                          // AssessmentBlock.map((i) => {
                                          //   if (block.ObjectId === i.ObjectId) {
                                          //     console.log("deleted ", block.Title);
                                          //   } else {
                                          //     tempArr.push(i);
                                          //   }
                                          // });
                                          // setProfilingBlocks(tempArr);

                                          // handleDeleteUser(memb.objId_UserSetting);
                                          e.target
                                            .closest(".hg-white-dropdown")
                                            .click();
                                        }}
                                      >
                                        Delete
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            </OverlayTrigger>
                          )}

                          {!isTemplateEdit && (
                            <>
                              <div style={{ margin: "0 5px" }}>
                                <RevokeContributorsDropdown
                                  questionnaireId={questionId}
                                  revokers={
                                    block.ActivityTrackingContributorVM
                                      ?.ActivityTrackingContributor_List
                                  }
                                  questionId={0}
                                  countFlag={false}
                                  refreshData={() => {
                                    setRevokedChange(true);
                                    refreshData();
                                  }}
                                  isContributorView={isContributorView}
                                />
                              </div>
                              {block?.allowContributors && (
                                <OverlayTrigger
                                  placement="bottom"
                                  trigger={
                                    viewOnlyAccess("AccessState_SCM")
                                      ? ["hover", "focus"]
                                      : []
                                  }
                                  delay={{
                                    show: 200,
                                    hide: 250,
                                  }}
                                  overlay={
                                    <Tooltip
                                      id={`tooltip-1365`}
                                      className="custom_tooltip_access_assessmentmenu"
                                    >
                                      This feature is managed by your service
                                      provider
                                    </Tooltip>
                                  }
                                >
                                  <div>
                                    {
                                      // (block.Question_List.length > 0 ||
                                      //   block.SectionTypeText == null ||
                                      //   block.Title ===
                                      //     "Data storage and processing")
                                      true && (
                                        <div
                                          className=" open-block flex-row"
                                          style={{
                                            pointerEvents: viewOnlyAccess(
                                              "AccessState_SCM"
                                            )
                                              ? "none"
                                              : "all",
                                            zIndex: 0,
                                          }}
                                          onClick={() => {
                                            // setStatusModal(block.Title);
                                            // askOnclick(block);

                                            console.log(showBlockModal);
                                            let content = <></>;
                                            setStatusModal("open");
                                            if (
                                              (block.Question_List.length > 0 ||
                                                block.SectionTypeText ==
                                                  null) &&
                                              block.Title !==
                                                "Data storage and processing"
                                            ) {
                                              setStatusModal(block.Title);
                                              setSectionType_List([
                                                {
                                                  ObjectId: block.ObjectId,
                                                  sectionTypeEnum: 8,
                                                  allowContributors:
                                                    block?.allowContributors ??
                                                    true,
                                                },
                                              ]);
                                            } else if (
                                              block.Title ===
                                              "Data storage and processing"
                                            ) {
                                              setStatusModal(block.Title);
                                              setSectionType_List([
                                                {
                                                  ObjectId: block.ObjectId,
                                                  sectionTypeEnum: 10,
                                                  allowContributors:
                                                    block?.allowContributors ??
                                                    true,
                                                },
                                              ]);
                                            } else if (
                                              block.Title === "Accreditation"
                                            ) {
                                              let temp =
                                                block?.AccreditationVM?.items?.map(
                                                  (obj) =>
                                                    obj.objId_Accreditation
                                                );
                                              // setQuestionList(temp);
                                              setSectionType_List([
                                                {
                                                  ObjectId: block.ObjectId,
                                                  sectionTypeEnum: 0,
                                                  allowContributors:
                                                    block?.allowContributors ??
                                                    true,
                                                },
                                              ]);
                                              content = (
                                                <AccreditationBlock
                                                  title={block}
                                                  setAccreditationModalState={
                                                    setAccreditationModalState
                                                  }
                                                />
                                              );
                                            } else if (
                                              block.Title ===
                                              "Training Certification"
                                            ) {
                                              let temp =
                                                block?.TrainingCert_UserListVM?.items?.reduce(
                                                  (accumulator, obj) => {
                                                    let list =
                                                      obj.certificates.map(
                                                        (item) =>
                                                          item.objId_Certification
                                                      );
                                                    return accumulator.concat(
                                                      list
                                                    );
                                                  },
                                                  []
                                                );
                                              setSectionType_List([
                                                {
                                                  ObjectId: block.ObjectId,
                                                  sectionTypeEnum: 1,
                                                  allowContributors:
                                                    block?.allowContributors ??
                                                    true,
                                                },
                                              ]);
                                              // setQuestionList(temp);
                                              content = (
                                                <CertificationBlock
                                                  title={block}
                                                  setShowCertificateModal={
                                                    setShowCertificateModal
                                                  }
                                                />
                                              );
                                            } else if (
                                              block.SectionTypeText ===
                                              "Security Framework"
                                            ) {
                                              let temp =
                                                block?.SecurityFrameworks?.FrameworkList?.map(
                                                  (obj) =>
                                                    obj.CyberFrameworkAudit
                                                      .objId
                                                );
                                              setSectionType_List([
                                                {
                                                  ObjectId: block.ObjectId,
                                                  sectionTypeEnum: 2,
                                                  allowContributors:
                                                    block?.allowContributors ??
                                                    true,
                                                },
                                              ]);
                                              // setQuestionList(temp);
                                              content = (
                                                <SecurityFrameworksBlock
                                                  title={block}
                                                  mode={"Security"}
                                                  setShowFrameworkModal={
                                                    setShowFrameworkModal
                                                  }
                                                />
                                              );
                                            } else if (
                                              block.SectionTypeText ===
                                              "Data Privacy Framework"
                                            ) {
                                              let temp =
                                                block?.DataPrivacyFrameworks?.FrameworkList?.map(
                                                  (obj) =>
                                                    obj.CyberFrameworkAudit
                                                      .objId
                                                );
                                              setSectionType_List([
                                                {
                                                  ObjectId: block.ObjectId,
                                                  sectionTypeEnum: 3,
                                                  allowContributors:
                                                    block?.allowContributors ??
                                                    true,
                                                },
                                              ]);
                                              // setQuestionList(temp);
                                              content = (
                                                <SecurityFrameworksBlock
                                                  title={block}
                                                  mode={"Data Privacy"}
                                                  setShowFrameworkModal={
                                                    setShowFrameworkModal
                                                  }
                                                />
                                              );
                                            } else if (
                                              block.Title ===
                                              "Managed Service Providers"
                                            ) {
                                              let temp =
                                                block?.ManagedServiceProvider_List?.map(
                                                  (obj) => obj.ObjectId
                                                );
                                              setSectionType_List([
                                                {
                                                  ObjectId: block.ObjectId,
                                                  sectionTypeEnum: 4,
                                                  allowContributors:
                                                    block?.allowContributors ??
                                                    true,
                                                },
                                              ]);
                                              // setQuestionList(temp);
                                              content = (
                                                <ProviderBlock
                                                  title={block}
                                                  setShowProviderModal={
                                                    setShowProviderModal
                                                  }
                                                />
                                              );
                                            } else if (
                                              block.Title === "Summary"
                                            ) {
                                              content = (
                                                <SummaryBlock
                                                  title={block}
                                                  setShowProviderModal={
                                                    setShowSummary
                                                  }
                                                />
                                              );
                                            }
                                            setShowBlockModal({
                                              showModal: true,
                                              header: block.Title,
                                              headerIcon: block.LogoUrl,
                                              content: content,
                                              contributors:
                                                block
                                                  .ActivityTrackingContributorVM
                                                  ?.ActivityTrackingContributor_List,
                                              AllowAskForHelp:
                                                block.allowContributors,
                                              notQuestionBlock:
                                                block.Question_List?.length >
                                                  0 &&
                                                block.Title !==
                                                  "Data storage and processing"
                                                  ? false
                                                  : true,
                                            });
                                          }}
                                        >
                                          {viewOnlyAccess("AccessState_SCM") ? (
                                            <img
                                              alt=""
                                              src="/images/attack-surface/small-lock.svg"
                                              className="ml-2 mr-2"
                                            />
                                          ) : (
                                            <img
                                              src={
                                                "/images/supply-chain/add-user-icon.svg"
                                              }
                                              className="mr-2 ml-2"
                                              alt=""
                                            />
                                          )}

                                          <div> Ask for help</div>
                                        </div>
                                      )
                                    }
                                  </div>
                                </OverlayTrigger>
                              )}

                              {viewOnlyAccess("AccessState_SCM") ||
                              !block?.allowContributors ? (
                                <></>
                              ) : (
                                <div
                                  className=" open-block flex-row"
                                  style={{ zIndex: 0 }}
                                  onClick={() => {
                                    console.log(showBlockModal);
                                    let content = <></>;
                                    setStatusModal("open");
                                    if (
                                      (block.Question_List.length > 0 ||
                                        block.SectionTypeText == null) &&
                                      block.Title !==
                                        "Data storage and processing"
                                    ) {
                                      let temp = block?.Question_List?.map(
                                        (obj) => obj.QuestionId
                                      );
                                      setQuestionList(temp);
                                      content = (
                                        <QuestionBlock
                                          title={block}
                                          status={statusModal}
                                        />
                                      );
                                    } else if (
                                      block.Title ===
                                      "Data storage and processing"
                                    ) {
                                      let temp = block?.Question_List?.map(
                                        (obj) => obj.QuestionId
                                      );
                                      setQuestionList(temp);
                                      content = (
                                        <QuestionBlock
                                          title={block}
                                          status={statusModal}
                                        />
                                      );
                                    } else if (
                                      block.Title === "Accreditation"
                                    ) {
                                      let temp =
                                        block?.AccreditationVM?.items?.map(
                                          (obj) => obj.objId_Accreditation
                                        );
                                      // setQuestionList(temp);
                                      setSectionType_List([
                                        {
                                          ObjectId: block.ObjectId,
                                          sectionTypeEnum: 0,
                                          allowContributors:
                                            block?.allowContributors ?? true,
                                        },
                                      ]);
                                      content = (
                                        <AccreditationBlock
                                          title={block}
                                          setAccreditationModalState={
                                            setAccreditationModalState
                                          }
                                        />
                                      );
                                    } else if (
                                      block.Title === "Training Certification"
                                    ) {
                                      let temp =
                                        block?.TrainingCert_UserListVM?.items?.reduce(
                                          (accumulator, obj) => {
                                            let list = obj.certificates.map(
                                              (item) => item.objId_Certification
                                            );
                                            return accumulator.concat(list);
                                          },
                                          []
                                        );
                                      setSectionType_List([
                                        {
                                          ObjectId: block.ObjectId,
                                          sectionTypeEnum: 1,
                                          allowContributors:
                                            block?.allowContributors ?? true,
                                        },
                                      ]);
                                      // setQuestionList(temp);
                                      content = (
                                        <CertificationBlock
                                          title={block}
                                          setShowCertificateModal={
                                            setShowCertificateModal
                                          }
                                        />
                                      );
                                    } else if (
                                      block.SectionTypeText ===
                                      "Security Framework"
                                    ) {
                                      let temp =
                                        block?.SecurityFrameworks?.FrameworkList?.map(
                                          (obj) => obj.CyberFrameworkAudit.objId
                                        );
                                      setSectionType_List([
                                        {
                                          ObjectId: block.ObjectId,
                                          sectionTypeEnum: 2,
                                          allowContributors:
                                            block?.allowContributors ?? true,
                                        },
                                      ]);
                                      // setQuestionList(temp);
                                      content = (
                                        <SecurityFrameworksBlock
                                          title={block}
                                          mode={"Security"}
                                          setShowFrameworkModal={
                                            setShowFrameworkModal
                                          }
                                        />
                                      );
                                    } else if (
                                      block.SectionTypeText ===
                                      "Data Privacy Framework"
                                    ) {
                                      let temp =
                                        block?.DataPrivacyFrameworks?.FrameworkList?.map(
                                          (obj) => obj.CyberFrameworkAudit.objId
                                        );
                                      setSectionType_List([
                                        {
                                          ObjectId: block.ObjectId,
                                          sectionTypeEnum: 3,
                                          allowContributors:
                                            block?.allowContributors ?? true,
                                        },
                                      ]);
                                      // setQuestionList(temp);
                                      content = (
                                        <SecurityFrameworksBlock
                                          title={block}
                                          mode={"Data Privacy"}
                                          setShowFrameworkModal={
                                            setShowFrameworkModal
                                          }
                                        />
                                      );
                                    } else if (
                                      block.Title ===
                                      "Managed Service Providers"
                                    ) {
                                      let temp =
                                        block?.ManagedServiceProvider_List?.map(
                                          (obj) => obj.ObjectId
                                        );
                                      setSectionType_List([
                                        {
                                          ObjectId: block.ObjectId,
                                          sectionTypeEnum: 4,
                                          allowContributors:
                                            block?.allowContributors ?? true,
                                        },
                                      ]);
                                      // setQuestionList(temp);
                                      content = (
                                        <ProviderBlock
                                          title={block}
                                          setShowProviderModal={
                                            setShowProviderModal
                                          }
                                        />
                                      );
                                    } else if (block.Title === "Summary") {
                                      content = (
                                        <SummaryBlock
                                          title={block}
                                          setShowProviderModal={setShowSummary}
                                        />
                                      );
                                    }
                                    setShowBlockModal({
                                      showModal: true,
                                      header: block.Title,
                                      headerIcon: block.LogoUrl,
                                      content: content,
                                      contributors:
                                        block.ActivityTrackingContributorVM
                                          ?.ActivityTrackingContributor_List,
                                      AllowAskForHelp: block.allowContributors,
                                      notQuestionBlock:
                                        block.Question_List?.length > 0 &&
                                        block.Title !==
                                          "Data storage and processing"
                                          ? false
                                          : true,
                                    });
                                  }}
                                >
                                  <img
                                    src={
                                      "/images/attack-surface/expand-icon.svg"
                                    }
                                    className="mr-2 ml-2"
                                    alt=""
                                  />
                                  <div>Open</div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    {/* {(block.Question_List.length > 0 || */}
                    {block.SectionTypeText == "Questionnaire" &&
                      block.Title !== "Data storage and processing" && (
                        <>
                          <div className=" w-100 line-div">
                            {/* <DragDropContext
                              onDragEnd={onDragEndShown}
                              onDragUpdate={handleScroll}
                            >
                              <Droppable
                                direction="vertical"
                                droppableId="droppableSection"
                              >
                                {(provided, snapshot) => ( */}
                            <div
                            // {...provided.droppableProps}
                            // ref={provided.innerRef}
                            >
                              {/* <div class=""> */}
                              <ul>
                                {showBlocks &&
                                  block.Question_List.map((f, ind) => {
                                    let data = [];
                                    let obj =
                                      f.QuestionDropdownListMembers?.map(
                                        (dat, index) => {
                                          data.push({
                                            id: index,
                                            value: dat,
                                          });
                                        }
                                      );
                                    if (
                                      !Object.values(showBlocks).find(
                                        (b) => b.ObjectId === block.ObjectId
                                      )?.show &&
                                      ind > 4
                                    ) {
                                      return;
                                    } else {
                                      return (
                                        // <Draggable
                                        //   key={f?.QuestionId}
                                        //   draggableId={f?.QuestionId.toString()}
                                        //   index={ind}
                                        //   // isDragDisabled
                                        // >
                                        //   {(provided, snapshot) => (
                                        <li>
                                          <div
                                            // ref={provided.innerRef}
                                            // {...provided.draggableProps}
                                            // {...provided.dragHandleProps}
                                            // {...(viewOnlyAccess(
                                            //   "AccessState_SCM"
                                            // )
                                            //   ? {}
                                            //   : provided.draggableProps)}
                                            // {...(viewOnlyAccess(
                                            //   "AccessState_SCM"
                                            // )
                                            //   ? {}
                                            //   : provided.dragHandleProps)}
                                            className=" d-flex flex-row thread"
                                            style={{
                                              background: "#fff",
                                            }}
                                            key={ind}
                                            index={ind}
                                          >
                                            <div
                                              className="d-flex flex-row w-100 dragndrop-item"
                                              onClick={() => {}}
                                            >
                                              {/* {viewOnlyAccess(
                                                          "AccessState_SCM"
                                                        ) ? (
                                                          <></>
                                                        ) : (
                                                          <>
                                                            {!isContributorView &&
                                                              isSent &&
                                                              (!isTemplate ||
                                                                isTemplate ===
                                                                  undefined ||
                                                                isTemplateEdit) && (
                                                                  
                                                                <img
                                                                  alt=""
                                                                  src="/images/supply-chain/drag-n-drop.svg"
                                                                  className="hover-visible"
                                                                  style={{
                                                                    marginLeft:
                                                                      "-16px",
                                                                  }}
                                                                />
                                                              )}
                                                          </>
                                                        )} */}

                                              <AssessmentRow
                                                row={f}
                                                block={block}
                                                AssessmentBlock={
                                                  AssessmentBlock
                                                }
                                                setAssessmentBlock={
                                                  setAssessmentBlock
                                                }
                                                setProfilingBlocks={
                                                  setProfilingBlocks
                                                }
                                                setSaveTemplateStatus={
                                                  setSaveTemplateStatus
                                                }
                                                currentWord={currentWord}
                                                setCurrentWord={setCurrentWord}
                                                refreshData={refreshData}
                                                data={data}
                                                setData_to_send={
                                                  setData_to_send
                                                }
                                                questionId={questionId}
                                                AllProfilingObj={
                                                  AllProfilingObj
                                                }
                                                deleteQuestion={deleteQuestion}
                                                editMode={editMode}
                                                setEditMode={setEditMode}
                                                createUpdateSection={
                                                  createUpdateSection
                                                }
                                                isTemplate={isTemplate}
                                                isTemplateEdit={isTemplateEdit}
                                                rawBlocks={rawBlocks}
                                                updateRawData={updateRawData}
                                                setShowDeleteModal={
                                                  setShowDeleteModal
                                                }
                                                isContributorView={
                                                  isContributorView
                                                }
                                                isSent={isSent}
                                                mentionList={mentionList}
                                              />
                                            </div>
                                          </div>
                                        </li>
                                        //   )}
                                        // </Draggable>
                                      );
                                    }
                                  })}
                              </ul>
                              {/* </div> */}
                              {/* {provided.placeholder} */}
                            </div>
                            {/* )} */}
                            {/* </Droppable>
                            </DragDropContext> */}
                            {addQuestion.title === block.Title && (
                              <>
                                <AddQuestionRow
                                  dataForAddQuestion={dataForAddQuestion}
                                  setDataForAddQuestion={setDataForAddQuestion}
                                  addQuestionRef={addQuestionRef}
                                  addQuestion={addQuestion}
                                  setAddQuestion={setAddQuestion}
                                  newQuestionType={newQuestionType}
                                  setnewQuestionType={setnewQuestionType}
                                  saveNewQuestion={saveNewQuestion}
                                  block={block}
                                />
                              </>
                            )}
                          </div>

                          {!isContributorView && isSent && (
                            <div className="flex-row item-question w-100 line-div cursor-pointer add-quest-btn">
                              <div
                                className="f-grey d-flex"
                                style={{
                                  // pointerEvents: viewOnlyAccess(
                                  //   "AccessState_SCM"
                                  // )
                                  //   ? "none"
                                  //   : "all",
                                  gap: "4px",
                                  zIndex: 1,
                                }}
                                onClick={() => {
                                  if (viewOnlyAccess("AccessState_SCM")) {
                                    return;
                                  } else {
                                    if (
                                      !isTemplate ||
                                      isTemplate === undefined ||
                                      isTemplateEdit
                                    ) {
                                      console.log("adddddd");
                                      setAddQuestion({
                                        title: block.Title,
                                        obj: {},
                                      });

                                      setShowBlocks((prevShowBlocks) => {
                                        return prevShowBlocks.map((t) => {
                                          if (t.ObjectId === block.ObjectId) {
                                            return { ...t, show: true };
                                          }
                                          return t;
                                        });
                                      });
                                    }
                                  }
                                }}
                              >
                                {viewOnlyAccess("AccessState_SCM") ? (
                                  <img
                                    alt=""
                                    src="/images/attack-surface/small-lock.svg"
                                    className="mr-2 ml-1"
                                  />
                                ) : (
                                  <img
                                    src={
                                      "/images/supply-chain/add-base-icon.svg"
                                    }
                                    className="mr-2"
                                    alt=""
                                  />
                                )}
                                <OverlayTrigger
                                  placement="bottom"
                                  trigger={
                                    viewOnlyAccess("AccessState_SCM")
                                      ? ["hover", "focus"]
                                      : []
                                  }
                                  delay={{
                                    show: 200,
                                    hide: 250,
                                  }}
                                  overlay={
                                    <Tooltip
                                      id={`tooltip-1365`}
                                      className="custom_tooltip_access_assessmentmenu"
                                    >
                                      This feature is managed by your service
                                      provider
                                    </Tooltip>
                                  }
                                >
                                  <div>Add question</div>
                                </OverlayTrigger>
                              </div>
                            </div>
                          )}

                          {block.Question_List.length > 5 && (
                            <div className="flex-row item-question w-100 line-div ">
                              {!Object.values(showBlocks)?.find(
                                (b) => b.ObjectId === block.ObjectId
                              )?.show ? (
                                <div
                                  className="f-grey d-flex"
                                  style={{ gap: "4px" }}
                                  onClick={() => {
                                    setUpdateShowBlocks(false);
                                    setShowBlocks((prevShowBlocks) => {
                                      return prevShowBlocks.map((t) => {
                                        if (t.ObjectId === block.ObjectId) {
                                          return { ...t, show: true };
                                        }
                                        return t;
                                      });
                                    });
                                  }}
                                >
                                  <img
                                    src={
                                      "/images/supply-chain/show-more-icon.svg"
                                    }
                                    className="mr-2"
                                    alt=""
                                  />
                                  <div>Show more</div>
                                </div>
                              ) : (
                                <div
                                  className="f-grey d-flex"
                                  style={{ gap: "4px" }}
                                  onClick={() => {
                                    setUpdateShowBlocks(false);
                                    setShowBlocks((prevShowBlocks) => {
                                      return prevShowBlocks.map((t) => {
                                        if (t.ObjectId === block.ObjectId) {
                                          return { ...t, show: false };
                                        }
                                        return t;
                                      });
                                    });
                                  }}
                                >
                                  <img
                                    src={
                                      "/images/supply-chain/show-less-icon.svg"
                                    }
                                    className="mr-2"
                                    alt=""
                                  />
                                  <div>Show less</div>
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    {block.Title === "Data storage and processing" && (
                      <>
                        <div className=" w-100 line-div">
                          {showBlocks &&
                            block.Question_List?.map((f, ind) => {
                              let data = [];
                              let obj = f.QuestionDropdownListMembers?.map(
                                (dat, index) => {
                                  data.push({ id: index, value: dat });
                                }
                              );
                              if (
                                !Object.values(showBlocks).find(
                                  (b) => b.ObjectId === block.ObjectId
                                )?.show &&
                                ind > 4
                              ) {
                                return;
                              } else {
                                return (
                                  <div
                                    key={ind + f?.QuestionId}
                                    className="thread dragndrop-item"
                                    style={{
                                      background: "#fff",
                                    }}
                                  >
                                    <AssessmentRow
                                      row={f}
                                      block={block}
                                      AssessmentBlock={AssessmentBlock}
                                      setAssessmentBlock={setAssessmentBlock}
                                      setProfilingBlocks={setProfilingBlocks}
                                      setSaveTemplateStatus={
                                        setSaveTemplateStatus
                                      }
                                      currentWord={currentWord}
                                      setCurrentWord={setCurrentWord}
                                      refreshData={refreshData}
                                      data={data}
                                      setData_to_send={setData_to_send}
                                      questionId={questionId}
                                      AllProfilingObj={AllProfilingObj}
                                      createUpdateSection={createUpdateSection}
                                      isTemplate={isTemplate}
                                      isContributorView={isContributorView}
                                      isSent={isSent}
                                      isTemplateEdit={isTemplateEdit}
                                      mentionList={mentionList}
                                      rawBlocks={rawBlocks}
                                      updateRawData={updateRawData}
                                    />
                                  </div>
                                );
                              }
                            })}
                        </div>
                      </>
                    )}
                    {block.Title === "Accreditation" && (
                      <>
                        <div className=" w-100 grid-container line-div">
                          <OverlayTrigger
                            placement="bottom"
                            trigger={
                              viewOnlyAccess("AccessState_SCM")
                                ? ["hover", "focus"]
                                : []
                            }
                            delay={{
                              show: 200,
                              hide: 250,
                            }}
                            overlay={
                              <Tooltip
                                id={`tooltip-1365`}
                                className="custom_tooltip_access"
                              >
                                This feature is managed by your service provider
                              </Tooltip>
                            }
                          >
                            <div className="">
                              <div
                                className="flex-row  file-blocks f-darkgrey cursor-pointer"
                                style={{
                                  pointerEvents: viewOnlyAccess(
                                    "AccessState_SCM"
                                  )
                                    ? "none"
                                    : "all",
                                }}
                                onClick={() => {
                                  if (!isTemplate || isTemplate === undefined) {
                                    setAccreditationModalState({
                                      showModal: true,
                                      selectedId: 0,
                                      headerName: "Add Accreditation",
                                    });
                                  }
                                }}
                              >
                                {viewOnlyAccess("AccessState_SCM") ? (
                                  <img
                                    alt=""
                                    src="/images/attack-surface/small-lock.svg"
                                    className="mr-2 text-icon"
                                  />
                                ) : (
                                  <img
                                    src={
                                      "/images/supply-chain/darkgrey-add-base-icon.svg"
                                    }
                                    className="mr-2 text-icon "
                                    alt=""
                                  />
                                )}

                                <div className="">Add Accreditation</div>
                              </div>
                            </div>
                          </OverlayTrigger>
                          {block.AccreditationVM?.items?.map((f, ind) => {
                            let data = [];
                            if (
                              !Object.values(showBlocks).find(
                                (b) => b.ObjectId === block.ObjectId
                              )?.show &&
                              ind > 4
                            ) {
                              return;
                            } else {
                              return (
                                <div
                                  key={ind + f?.objId_Accreditation}
                                  className="flex-row  file-blocks cursor-pointer"
                                  style={{
                                    pointerEvents: viewOnlyAccess(
                                      "AccessState_SCM"
                                    )
                                      ? "none"
                                      : "all",
                                  }}
                                  onClick={(e) => {
                                    setAccreditationModalState({
                                      showModal: true,
                                      accreditationId: f.objId_Accreditation,
                                      headerName: "Edit Accreditation",
                                    });
                                  }}
                                >
                                  <img
                                    width={33}
                                    src={`/images/msp/${f?.icon}`}
                                    alt=""
                                    className=" rounded-full"
                                  />
                                  <div className="d-flex flex-column">
                                    <div className="d-flex flex-row">
                                      <div className="mr-2">{f.name}</div>
                                      <div
                                        style={{ color: f.status?.foreColor }}
                                      >
                                        {f.status?.text}
                                      </div>
                                    </div>
                                    <div className="d-flex flex-row file-block-info">
                                      <div className="f-darkgrey  text-ellipsis">
                                        {f.points[0]}
                                      </div>
                                      {f.points[1] && (
                                        <span className="grey-dot" />
                                      )}
                                      <div className="f-darkgrey text-ellipsis">
                                        {f.points[1]}
                                      </div>
                                      {f.points[2] && (
                                        <span className="grey-dot" />
                                      )}
                                      <div className="f-darkgrey text-ellipsis">
                                        {f.points[2]}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex mr-2">
                                    {f.fileUrl ? (
                                      <div className=" f-12 f-darkgrey link-file">
                                        <img
                                          src={`/images/msp/msp-cert-file-icon.svg`}
                                          alt=""
                                          className="mr-2 "
                                        />
                                        <div>File</div>
                                      </div>
                                    ) : f.linkUrl ? (
                                      <div className=" f-12 f-darkgrey link-file">
                                        <img
                                          src={`/images/msp/msp-cert-link-icon.svg`}
                                          alt=""
                                          className="mr-2 "
                                        />
                                        <div>Link</div>
                                      </div>
                                    ) : (
                                      <div className="add-btn f-12 f-grey">
                                        <img
                                          src={`/images/msp/msp-cert-add-icon.svg`}
                                          alt=""
                                          className="mr-2 "
                                        />
                                        <div>Add</div>
                                      </div>
                                    )}

                                    <div className="secops_training_certificate_overlap_parent mr-3 f-grey ml-1 dots-hover">
                                      <div style={{ margin: "0 -17px" }}>
                                        <Dropdown
                                          className="hg-white-dropdown user-detail-dropdown supplier-menu"
                                          onToggle={(isOpen, event) => {
                                            if (isOpen) {
                                              event.stopPropagation(); // Prevent the event from propagating
                                            }
                                          }}
                                        >
                                          <Dropdown.Toggle className=" d-flex custom-dropdown-toggle f-gray-imp">
                                            <img
                                              src={
                                                "/images/supply-chain/base-more-dots.svg"
                                              }
                                              className="mr-2 ml-2"
                                              alt=""
                                            />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            {f.fileUrls?.length > 0 ? (
                                              <div
                                                className="p-2 pl-3 my-1 pointer f-darkgrey"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  downloadFile(
                                                    f.fileUrl,
                                                    f.name
                                                  );
                                                }}
                                              >
                                                <div>Download</div>
                                              </div>
                                            ) : f.linkUrl ? (
                                              <div
                                                className="p-2 pl-3 my-1 pointer f-darkgrey"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  window.open(
                                                    f.linkUrl,
                                                    "_blank"
                                                  );
                                                }}
                                              >
                                                <div>Open link</div>
                                              </div>
                                            ) : (
                                              <div
                                                className="p-2 pl-3 my-1 pointer f-darkgrey"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setAccreditationModalState({
                                                    showModal: true,
                                                    accreditationId:
                                                      f.objId_Accreditation,
                                                    headerName:
                                                      "Edit Accreditation",
                                                  });
                                                }}
                                              >
                                                <div>Add</div>
                                              </div>
                                            )}

                                            <div
                                              className="p-2 pl-3 my-1 pointer f-darkgrey"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setAccreditationModalState({
                                                  showModal: true,
                                                  accreditationId:
                                                    f.objId_Accreditation,
                                                  headerName:
                                                    "Edit Accreditation",
                                                });
                                              }}
                                            >
                                              Edit
                                            </div>
                                            <div
                                              className="p-2 pl-3 my-1 pointer f-danger"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setShowDeleteModal({
                                                  showModal: true,
                                                  deleteMessage:
                                                    "You are about to delete an accreditation.",
                                                  deleteSentQuestionnaire: () =>
                                                    deleteAccreditation(
                                                      f.objId_Accreditation
                                                    ),
                                                });
                                              }}
                                            >
                                              Delete
                                            </div>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </div>
                        {block.AccreditationVM?.items?.length > 5 && (
                          <div className="flex-row item-question w-100 line-div ">
                            {!Object.values(showBlocks)?.find(
                              (b) => b.ObjectId === block.ObjectId
                            )?.show ? (
                              <div
                                className="f-grey d-flex"
                                style={{ gap: "4px" }}
                                onClick={() => {
                                  setUpdateShowBlocks(false);
                                  setShowBlocks((prevShowBlocks) => {
                                    return prevShowBlocks.map((t) => {
                                      if (t.ObjectId === block.ObjectId) {
                                        return { ...t, show: true };
                                      }
                                      return t;
                                    });
                                  });
                                }}
                              >
                                <img
                                  src={
                                    "/images/supply-chain/show-more-icon.svg"
                                  }
                                  className="mr-2"
                                  alt=""
                                />
                                <div>Show more</div>
                              </div>
                            ) : (
                              <div
                                className="f-grey d-flex"
                                style={{ gap: "4px" }}
                                onClick={() => {
                                  setUpdateShowBlocks(false);
                                  setShowBlocks((prevShowBlocks) => {
                                    return prevShowBlocks.map((t) => {
                                      if (t.ObjectId === block.ObjectId) {
                                        return { ...t, show: false };
                                      }
                                      return t;
                                    });
                                  });
                                }}
                              >
                                <img
                                  src={
                                    "/images/supply-chain/show-less-icon.svg"
                                  }
                                  className="mr-2"
                                  alt=""
                                />
                                <div>Show less</div>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                    {block.Title === "Training Certification" && (
                      <>
                        <div className=" w-100 line-div grid-container">
                          <OverlayTrigger
                            placement="bottom"
                            trigger={
                              viewOnlyAccess("AccessState_SCM")
                                ? ["hover", "focus"]
                                : []
                            }
                            delay={{
                              show: 200,
                              hide: 250,
                            }}
                            overlay={
                              <Tooltip
                                id={`tooltip-1365`}
                                className="custom_tooltip_access"
                              >
                                This feature is managed by your service provider
                              </Tooltip>
                            }
                          >
                            <div>
                              <div
                                className="flex-row add-cert-block f-darkgrey cursor-pointer"
                                style={{
                                  pointerEvents: viewOnlyAccess(
                                    "AccessState_SCM"
                                  )
                                    ? "none"
                                    : "all",
                                  alignItems: "center !important",
                                }}
                                onClick={() => {
                                  if (!isTemplate || isTemplate === undefined) {
                                    setShowCertificateModal({
                                      showModal: true,
                                      headerName: "Add Training Certificate",
                                      certificateId: 0,
                                      employeeData: {
                                        position: "",
                                        memberName: "",
                                        userId: 0,
                                      },
                                    });
                                  }
                                }}
                              >
                                {viewOnlyAccess("AccessState_SCM") ? (
                                  <img
                                    alt=""
                                    src="/images/attack-surface/small-lock.svg"
                                    className="mr-2 text-icon "
                                  />
                                ) : (
                                  <img
                                    src={
                                      "/images/supply-chain/darkgrey-add-base-icon.svg"
                                    }
                                    className="mr-2 text-icon "
                                    alt=""
                                  />
                                )}

                                <div className="">
                                  Add Training Certification
                                </div>
                              </div>
                            </div>
                          </OverlayTrigger>
                          {block.TrainingCert_UserListVM?.items?.map(
                            (f, ind) => {
                              let data = [];
                              if (
                                !Object.values(showBlocks).find(
                                  (b) => b.ObjectId === block.ObjectId
                                )?.show &&
                                ind > 4
                              ) {
                                return;
                              } else {
                                return (
                                  <div
                                    key={ind + f?.ObjectId}
                                    className="certificate-block"
                                  >
                                    <div className="certificate-title w-100">
                                      <div
                                        className="d-flex flex-row"
                                        style={{ gap: "8px" }}
                                      >
                                        <ImageWithInitials
                                          initials={f.initials}
                                          width={40}
                                          background={"purple"}
                                        />
                                        <div className="d-flex flex-column">
                                          <div>{f?.fullName}</div>
                                          <div className="f-darkgrey f-12">
                                            {f?.position}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="secops_training_certificate_overlap_parent mr-3 f-grey ml-1 dots-hover">
                                        <div style={{ margin: "0 -17px" }}>
                                          <Dropdown
                                            className="hg-white-dropdown user-detail-dropdown supplier-menu"
                                            onToggle={(isOpen, event) => {
                                              if (isOpen) {
                                                event.stopPropagation(); // Prevent the event from propagating
                                              }
                                            }}
                                          >
                                            <Dropdown.Toggle className=" d-flex custom-dropdown-toggle f-gray-imp">
                                              <img
                                                src={
                                                  "/images/supply-chain/base-more-dots.svg"
                                                }
                                                className="mr-2 ml-2"
                                                alt=""
                                              />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <div
                                                className="p-2 pl-3 my-1 pointer f-darkgrey"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setShowEmployeeModal({
                                                    headerName: "Edit Employee",
                                                    showModal: true,
                                                    employeeData: {
                                                      position: f.position,
                                                      memberName: f.fullName,
                                                      userId: f.userId,
                                                    },
                                                  });
                                                }}
                                              >
                                                Edit Employee
                                              </div>
                                              <div
                                                className="p-2 pl-3 my-1 pointer f-danger"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setShowDeleteModal({
                                                    showModal: true,
                                                    deleteMessage:
                                                      "You are about to delete an employee.",
                                                    deleteSentQuestionnaire:
                                                      () =>
                                                        deleteEmployee(
                                                          f.userId
                                                        ),
                                                  });
                                                }}
                                              >
                                                Delete
                                              </div>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </div>
                                    {f.certificates?.length === 0 && (
                                      <div className="f-darkgrey f-12">
                                        No Training Certification yet
                                      </div>
                                    )}
                                    <div className="d-flex flex-column w-100">
                                      {f.certificates?.map((cert) => {
                                        return (
                                          <div
                                            key={ind + cert.objId_Certification}
                                            className="flex-row cert-item cursor-pointer"
                                            onClick={(e) => {
                                              setShowCertificateModal({
                                                headerName:
                                                  "Edit Training Certificate",
                                                showModal: true,
                                                employeeData: {
                                                  position: f.position,
                                                  memberName: f.fullName,
                                                  userId: f.userId,
                                                },
                                                certificateId:
                                                  cert.objId_Certification,
                                              });
                                            }}
                                          >
                                            {cert.icon ? (
                                              <img
                                                width={33}
                                                src={`/images/msp/framework-certificates/${cert.icon}`}
                                                className="mr-2 rounded-full "
                                                alt=""
                                              />
                                            ) : (
                                              <ImageWithInitials
                                                initials={cert?.initials}
                                                width={30}
                                                background={"purple"}
                                              />
                                            )}

                                            <div className="fontBold ml-2">
                                              {cert.certificationName}
                                            </div>
                                            <div
                                              style={{
                                                color: cert.status?.foreColor,
                                              }}
                                            >
                                              {cert.status?.text}
                                            </div>
                                            <div className="secops_training_certificate_overlap_parent mr-3 f-grey ml-1 dots-hover">
                                              <div
                                                style={{ margin: "0 -17px" }}
                                              >
                                                <Dropdown
                                                  className="hg-white-dropdown user-detail-dropdown supplier-menu"
                                                  onToggle={(isOpen, event) => {
                                                    if (isOpen) {
                                                      event.stopPropagation(); // Prevent the event from propagating
                                                    }
                                                  }}
                                                >
                                                  <Dropdown.Toggle className=" d-flex custom-dropdown-toggle f-gray-imp">
                                                    <img
                                                      src={
                                                        "/images/supply-chain/base-more-dots.svg"
                                                      }
                                                      className="mr-2 ml-2"
                                                      alt=""
                                                    />
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu>
                                                    <div
                                                      className="p-2 pl-3 my-1 pointer f-darkgrey"
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        setShowCertificateModal(
                                                          {
                                                            headerName:
                                                              "Edit Training Certificate",
                                                            showModal: true,
                                                            employeeData: {
                                                              position:
                                                                f.position,
                                                              memberName:
                                                                f.fullName,
                                                              userId: f.userId,
                                                            },
                                                            certificateId:
                                                              cert.objId_Certification,
                                                          }
                                                        );
                                                      }}
                                                    >
                                                      Edit
                                                    </div>
                                                    <div
                                                      className="p-2 pl-3 my-1 pointer f-danger"
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        setShowDeleteModal({
                                                          showModal: true,
                                                          deleteMessage:
                                                            "You are about to delete a training certificate.",
                                                          deleteSentQuestionnaire:
                                                            () =>
                                                              deleteCertification(
                                                                cert.objId_Certification,
                                                                f.userId
                                                              ),
                                                        });
                                                      }}
                                                    >
                                                      Delete
                                                    </div>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                      <OverlayTrigger
                                        placement="bottom"
                                        trigger={
                                          viewOnlyAccess("AccessState_SCM")
                                            ? ["hover", "focus"]
                                            : []
                                        }
                                        delay={{
                                          show: 200,
                                          hide: 250,
                                        }}
                                        overlay={
                                          <Tooltip
                                            id={`tooltip-1365`}
                                            className="custom_tooltip_access"
                                          >
                                            This feature is managed by your
                                            service provider
                                          </Tooltip>
                                        }
                                      >
                                        <div>
                                          <div
                                            className="flex-row cert-item-add"
                                            style={{
                                              pointerEvents: viewOnlyAccess(
                                                "AccessState_SCM"
                                              )
                                                ? "none"
                                                : "all",
                                              marginLeft: "5px",
                                            }}
                                            onClick={() => {
                                              setShowCertificateModal({
                                                showModal: true,
                                                headerName:
                                                  "Add Training Certificate",
                                                certificateId: 0,
                                                employeeData: {
                                                  position: f.position,
                                                  memberName: f.fullName,
                                                  userId: f.userId,
                                                },
                                              });
                                            }}
                                          >
                                            {viewOnlyAccess(
                                              "AccessState_SCM"
                                            ) ? (
                                              <img
                                                alt=""
                                                src="/images/attack-surface/small-lock.svg"
                                                className="mr-2  "
                                              />
                                            ) : (
                                              <img
                                                src={
                                                  "/images/supply-chain/add-base-icon.svg"
                                                }
                                                className=""
                                                alt=""
                                              />
                                            )}

                                            <div className="f-grey">
                                              Add Training Certification
                                            </div>
                                          </div>
                                        </div>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          )}
                        </div>
                        {block.TrainingCert_UserListVM?.items?.length > 5 && (
                          <div className="flex-row item-question w-100 line-div ">
                            {!Object.values(showBlocks)?.find(
                              (b) => b.ObjectId === block.ObjectId
                            )?.show ? (
                              <div
                                className="f-grey d-flex"
                                style={{ gap: "4px" }}
                                onClick={() => {
                                  setUpdateShowBlocks(false);
                                  setShowBlocks((prevShowBlocks) => {
                                    return prevShowBlocks.map((t) => {
                                      if (t.ObjectId === block.ObjectId) {
                                        return { ...t, show: true };
                                      }
                                      return t;
                                    });
                                  });
                                }}
                              >
                                <img
                                  src={
                                    "/images/supply-chain/show-more-icon.svg"
                                  }
                                  className="mr-2"
                                  alt=""
                                />
                                <div>Show more</div>
                              </div>
                            ) : (
                              <div
                                className="f-grey d-flex"
                                style={{ gap: "4px" }}
                                onClick={() => {
                                  setUpdateShowBlocks(false);
                                  setShowBlocks((prevShowBlocks) => {
                                    return prevShowBlocks.map((t) => {
                                      if (t.ObjectId === block.ObjectId) {
                                        return { ...t, show: false };
                                      }
                                      return t;
                                    });
                                  });
                                }}
                              >
                                <img
                                  src={
                                    "/images/supply-chain/show-less-icon.svg"
                                  }
                                  className="mr-2"
                                  alt=""
                                />
                                <div>Show less</div>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                    {block.SectionTypeText === "Security Framework" && (
                      <>
                        <div className=" w-100  line-div grid-container">
                          <OverlayTrigger
                            placement="bottom"
                            trigger={
                              viewOnlyAccess("AccessState_SCM")
                                ? ["hover", "focus"]
                                : []
                            }
                            delay={{
                              show: 200,
                              hide: 250,
                            }}
                            overlay={
                              <Tooltip
                                id={`tooltip-1365`}
                                className="custom_tooltip_access"
                              >
                                This feature is managed by your service provider
                              </Tooltip>
                            }
                          >
                            <div>
                              <div
                                className="flex-row p-6px file-blocks f-darkgrey cursor-pointer"
                                style={{
                                  pointerEvents: viewOnlyAccess(
                                    "AccessState_SCM"
                                  )
                                    ? "none"
                                    : "all",
                                }}
                                onClick={() => {
                                  if (!isTemplate || isTemplate === undefined) {
                                    setShowFrameworkModal({
                                      headerName: "Add Framework",
                                      status: "Cyber Security",
                                      showModal: true,
                                      selectedId: 0,
                                      AddOrEdit: "Add",
                                    });
                                  }
                                }}
                              >
                                {viewOnlyAccess("AccessState_SCM") ? (
                                  <img
                                    alt=""
                                    src="/images/attack-surface/small-lock.svg"
                                    className="mr-2 text-icon "
                                  />
                                ) : (
                                  <img
                                    src={
                                      "/images/supply-chain/darkgrey-add-base-icon.svg"
                                    }
                                    className="mr-2 text-icon "
                                    alt=""
                                  />
                                )}
                                <div className="">Add Framework</div>
                              </div>
                            </div>
                          </OverlayTrigger>
                          {block?.SecurityFrameworks?.FrameworkList.map(
                            (f, ind) => {
                              if (
                                !Object.values(showBlocks).find(
                                  (b) => b.ObjectId === block.ObjectId
                                )?.show &&
                                ind > 4
                              ) {
                                return;
                              } else {
                                return (
                                  <div
                                    className="flex-row p-6px framework-block cursor-pointer"
                                    onClick={(e) => {
                                      setShowFrameworkModal({
                                        headerName: "Edit Framework",
                                        status: "Cyber Security",
                                        showModal: true,
                                        selectedId: f.FrameworkId,
                                        frameworkItem: f,
                                        AddOrEdit: "Edit",
                                      });
                                    }}
                                  >
                                    <div className="d-flex flex-column">
                                      <div className="f-black fontBold">
                                        {f.Abbreviation}
                                      </div>
                                      <div className="f-darkgrey text-ellipsis">
                                        {f.Name}
                                      </div>
                                    </div>
                                    <div className="d-flex flex-column f-darkgrey">
                                      <div className="">
                                        Last Audit:{" "}
                                        {f.CyberFrameworkAudit?.LastAudit?.split(
                                          " "
                                        )?.[0]
                                          ? moment(
                                              f?.CyberFrameworkAudit?.LastAudit?.split(
                                                " "
                                              )?.[0],
                                              "DD/MM/YYYY"
                                            ).format("DD MMM YYYY")
                                          : "NA"}
                                      </div>
                                      <div className="">
                                        Next Audit:{" "}
                                        {f.CyberFrameworkAudit?.NextAudit
                                          ? moment(
                                              f?.CyberFrameworkAudit?.NextAudit?.split(
                                                " "
                                              )?.[0],
                                              "DD/MM/YYYY"
                                            ).format("DD MMM YYYY")
                                          : "NA"}
                                      </div>
                                    </div>
                                    <div className="secops_training_certificate_overlap_parent mr-3 f-grey ml-1 dots-hover">
                                      <div style={{ margin: "0 -17px" }}>
                                        <Dropdown
                                          className="hg-white-dropdown user-detail-dropdown supplier-menu"
                                          onToggle={(isOpen, event) => {
                                            if (isOpen) {
                                              event.stopPropagation(); // Prevent the event from propagating
                                            }
                                          }}
                                        >
                                          <Dropdown.Toggle className=" d-flex custom-dropdown-toggle f-gray-imp">
                                            <img
                                              src={
                                                "/images/supply-chain/base-more-dots.svg"
                                              }
                                              className="mr-2 ml-2"
                                              alt=""
                                            />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <div
                                              className="p-2 pl-3 my-1 pointer f-darkgrey"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setShowFrameworkModal({
                                                  headerName: "Edit Framework",
                                                  status: "Cyber Security",
                                                  showModal: true,
                                                  selectedId: f.FrameworkId,
                                                  frameworkItem: f,
                                                  AddOrEdit: "Edit",
                                                });
                                              }}
                                            >
                                              Edit
                                            </div>
                                            <div
                                              className="p-2 pl-3 my-1 pointer f-danger"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setShowDeleteModal({
                                                  showModal: true,
                                                  deleteMessage:
                                                    "You are about to delete a security framework.",
                                                  deleteSentQuestionnaire: () =>
                                                    deleteFramework(
                                                      f.CyberFrameworkAudit
                                                        .objId,
                                                      f.FrameworkId
                                                    ),
                                                });
                                              }}
                                            >
                                              Delete
                                            </div>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          )}
                        </div>
                        {block.SecurityFrameworks?.FrameworkList?.length >
                          5 && (
                          <div className="flex-row item-question w-100 line-div ">
                            {!Object.values(showBlocks)?.find(
                              (b) => b.ObjectId === block.ObjectId
                            )?.show ? (
                              <div
                                className="f-grey d-flex"
                                style={{ gap: "4px" }}
                                onClick={() => {
                                  setUpdateShowBlocks(false);
                                  setShowBlocks((prevShowBlocks) => {
                                    return prevShowBlocks.map((t) => {
                                      if (t.ObjectId === block.ObjectId) {
                                        return { ...t, show: true };
                                      }
                                      return t;
                                    });
                                  });
                                }}
                              >
                                <img
                                  src={
                                    "/images/supply-chain/show-more-icon.svg"
                                  }
                                  className="mr-2"
                                  alt=""
                                />
                                <div>Show more</div>
                              </div>
                            ) : (
                              <div
                                className="f-grey d-flex"
                                style={{ gap: "4px" }}
                                onClick={() => {
                                  setUpdateShowBlocks(false);
                                  setShowBlocks((prevShowBlocks) => {
                                    return prevShowBlocks.map((t) => {
                                      if (t.ObjectId === block.ObjectId) {
                                        return { ...t, show: false };
                                      }
                                      return t;
                                    });
                                  });
                                }}
                              >
                                <img
                                  src={
                                    "/images/supply-chain/show-less-icon.svg"
                                  }
                                  className="mr-2"
                                  alt=""
                                />
                                <div>Show less</div>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                    {block.SectionTypeText === "Data Privacy Framework" && (
                      <>
                        <div className=" w-100  line-div grid-container">
                          <OverlayTrigger
                            placement="bottom"
                            trigger={
                              viewOnlyAccess("AccessState_SCM")
                                ? ["hover", "focus"]
                                : []
                            }
                            delay={{
                              show: 200,
                              hide: 250,
                            }}
                            overlay={
                              <Tooltip
                                id={`tooltip-1365`}
                                className="custom_tooltip_access"
                              >
                                This feature is managed by your service provider
                              </Tooltip>
                            }
                          >
                            <div>
                              <div
                                className="flex-row p-6px file-blocks f-darkgrey cursor-pointer"
                                style={{
                                  pointerEvents: viewOnlyAccess(
                                    "AccessState_SCM"
                                  )
                                    ? "none"
                                    : "all",
                                }}
                                onClick={() => {
                                  if (!isTemplate || isTemplate === undefined) {
                                    setShowFrameworkModal({
                                      headerName: "Add Framework",
                                      status: "Data Privacy",
                                      showModal: true,
                                      selectedId: 0,
                                      AddOrEdit: "Add",
                                    });
                                  }
                                }}
                              >
                                {viewOnlyAccess("AccessState_SCM") ? (
                                  <img
                                    alt=""
                                    src="/images/attack-surface/small-lock.svg"
                                    className="mr-2 text-icon"
                                  />
                                ) : (
                                  <img
                                    src={
                                      "/images/supply-chain/darkgrey-add-base-icon.svg"
                                    }
                                    className="mr-2 text-icon "
                                    alt=""
                                  />
                                )}
                                <div className="">Add Framework</div>
                              </div>
                            </div>
                          </OverlayTrigger>
                          {block.DataPrivacyFrameworks?.FrameworkList.map(
                            (f, ind) => {
                              if (
                                !Object.values(showBlocks).find(
                                  (b) => b.ObjectId === block.ObjectId
                                )?.show &&
                                ind > 4
                              ) {
                                return;
                              } else {
                                return (
                                  <div
                                    className="flex-row p-6px framework-block cursor-pointer"
                                    onClick={(e) => {
                                      setShowFrameworkModal({
                                        headerName: "Edit Framework",
                                        status: "Data Privacy",
                                        showModal: true,
                                        selectedId: f.FrameworkId,
                                        frameworkItem: f,
                                        AddOrEdit: "Edit",
                                      });
                                    }}
                                  >
                                    <div className="d-flex flex-column">
                                      <div className="f-black fontBold">
                                        {f.Abbreviation}
                                      </div>
                                      <div className="f-darkgrey text-ellipsis">
                                        {f.Name}
                                      </div>
                                    </div>
                                    <div className="d-flex flex-column f-darkgrey">
                                      <div className="">
                                        Last Audit:{" "}
                                        {f.CyberFrameworkAudit?.LastAudit?.split(
                                          " "
                                        )?.[0]
                                          ? moment(
                                              f?.CyberFrameworkAudit?.LastAudit?.split(
                                                " "
                                              )?.[0],
                                              "DD/MM/YYYY"
                                            ).format("DD MMM YYYY")
                                          : "NA"}
                                      </div>
                                      <div className="">
                                        Next Audit:{" "}
                                        {f.CyberFrameworkAudit?.NextAudit
                                          ? moment(
                                              f?.CyberFrameworkAudit?.NextAudit?.split(
                                                " "
                                              )?.[0],
                                              "DD/MM/YYYY"
                                            ).format("DD MMM YYYY")
                                          : "NA"}
                                      </div>
                                    </div>
                                    <div className="secops_training_certificate_overlap_parent mr-3 f-grey ml-1 dots-hover">
                                      <div style={{ margin: "0 -17px" }}>
                                        <Dropdown
                                          className="hg-white-dropdown user-detail-dropdown supplier-menu"
                                          onToggle={(isOpen, event) => {
                                            if (isOpen) {
                                              event.stopPropagation(); // Prevent the event from propagating
                                            }
                                          }}
                                        >
                                          <Dropdown.Toggle className=" d-flex custom-dropdown-toggle f-gray-imp">
                                            <img
                                              src={
                                                "/images/supply-chain/base-more-dots.svg"
                                              }
                                              className="mr-2 ml-2"
                                              alt=""
                                            />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <div
                                              className="p-2 pl-3 my-1 pointer f-darkgrey"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setShowFrameworkModal({
                                                  headerName: "Edit Framework",
                                                  status: "Data Privacy",
                                                  showModal: true,
                                                  selectedId: f.FrameworkId,
                                                  frameworkItem: f,
                                                  AddOrEdit: "Edit",
                                                });
                                              }}
                                            >
                                              Edit
                                            </div>
                                            <div
                                              className="p-2 pl-3 my-1 pointer f-danger"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setShowDeleteModal({
                                                  showModal: true,
                                                  deleteMessage:
                                                    "You are about to delete a data privacy framework.",
                                                  deleteSentQuestionnaire: () =>
                                                    deleteFramework(
                                                      f.CyberFrameworkAudit
                                                        .objId,
                                                      f.FrameworkId
                                                    ),
                                                });
                                              }}
                                            >
                                              Delete
                                            </div>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          )}
                        </div>
                        {block.DataPrivacyFrameworks?.FrameworkList?.length >
                          5 && (
                          <div className="flex-row item-question w-100 line-div ">
                            {!Object.values(showBlocks)?.find(
                              (b) => b.ObjectId === block.ObjectId
                            )?.show ? (
                              <div
                                className="f-grey d-flex"
                                style={{ gap: "4px" }}
                                onClick={() => {
                                  setUpdateShowBlocks(false);
                                  setShowBlocks((prevShowBlocks) => {
                                    return prevShowBlocks.map((t) => {
                                      if (t.ObjectId === block.ObjectId) {
                                        return { ...t, show: true };
                                      }
                                      return t;
                                    });
                                  });
                                }}
                              >
                                <img
                                  src={
                                    "/images/supply-chain/show-more-icon.svg"
                                  }
                                  className="mr-2"
                                  alt=""
                                />
                                <div>Show more</div>
                              </div>
                            ) : (
                              <div
                                className="f-grey d-flex"
                                style={{ gap: "4px" }}
                                onClick={() => {
                                  setUpdateShowBlocks(false);
                                  setShowBlocks((prevShowBlocks) => {
                                    return prevShowBlocks.map((t) => {
                                      if (t.ObjectId === block.ObjectId) {
                                        return { ...t, show: false };
                                      }
                                      return t;
                                    });
                                  });
                                }}
                              >
                                <img
                                  src={
                                    "/images/supply-chain/show-less-icon.svg"
                                  }
                                  className="mr-2"
                                  alt=""
                                />
                                <div>Show less</div>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                    {block.Title === "Managed Service Providers" && (
                      <>
                        <div className=" w-100 line-div grid-container">
                          <OverlayTrigger
                            placement="bottom"
                            trigger={
                              viewOnlyAccess("AccessState_SCM")
                                ? ["hover", "focus"]
                                : []
                            }
                            delay={{
                              show: 200,
                              hide: 250,
                            }}
                            overlay={
                              <Tooltip
                                id={`tooltip-1365`}
                                className="custom_tooltip_access"
                              >
                                This feature is managed by your service provider
                              </Tooltip>
                            }
                          >
                            <div>
                              <div
                                className="flex-row file-blocks f-darkgrey cursor-pointer"
                                style={{
                                  pointerEvents: viewOnlyAccess(
                                    "AccessState_SCM"
                                  )
                                    ? "none"
                                    : "all",
                                }}
                                onClick={() => {
                                  if (!isTemplate || isTemplate === undefined) {
                                    setShowProviderModal({
                                      headerName:
                                        "Add Managed Service Providers",
                                      showModal: true,
                                      providerId: 0,
                                    });
                                  }
                                }}
                              >
                                {viewOnlyAccess("AccessState_SCM") ? (
                                  <img
                                    alt=""
                                    src="/images/attack-surface/small-lock.svg"
                                    className="mr-2 text-icon"
                                  />
                                ) : (
                                  <img
                                    src={
                                      "/images/supply-chain/darkgrey-add-base-icon.svg"
                                    }
                                    className="mr-2 text-icon "
                                    alt=""
                                  />
                                )}
                                <div className="">Add Provider</div>
                              </div>
                            </div>
                          </OverlayTrigger>
                          {block.ManagedServiceProvider_List?.map((f, ind) => {
                            if (
                              !Object.values(showBlocks).find(
                                (b) => b.ObjectId === block.ObjectId
                              )?.show &&
                              ind > 4
                            ) {
                              return;
                            } else {
                              return (
                                <div
                                  className="flex-row  provider-block cursor-pointer"
                                  onClick={(e) => {
                                    setShowProviderModal({
                                      headerName:
                                        "Edit Managed Service Providers",
                                      showModal: true,
                                      providerId: f.ObjectId,
                                    });
                                  }}
                                >
                                  <div
                                    className="d-flex flex-row"
                                    style={{ gap: " 8px", flex: " 1 0 0" }}
                                  >
                                    <ImageWithInitials
                                      initials={f.ManagedProviderName[0]}
                                      width={40}
                                      background={"purple"}
                                    />

                                    <div className="d-flex flex-column">
                                      <div className="">
                                        {f.ManagedProviderName}
                                      </div>
                                      <div className="d-flex flex-row file-block-info f-darkgrey">
                                        <div className="text-ellipsis">
                                          {f.RegisteredAddress}
                                        </div>
                                        {f.CompanyName && (
                                          <span className="grey-dot" />
                                        )}
                                        <div className="text-ellipsis">
                                          {f.CompanyName}
                                        </div>
                                        {f.Website && (
                                          <span className="grey-dot" />
                                        )}
                                        <div className="text-ellipsis">
                                          {f.Website}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="secops_training_certificate_overlap_parent mr-3 f-grey ml-1 dots-hover">
                                    <div style={{ margin: "0 -17px" }}>
                                      <Dropdown
                                        className="hg-white-dropdown user-detail-dropdown supplier-menu"
                                        onToggle={(isOpen, event) => {
                                          if (isOpen) {
                                            event.stopPropagation(); // Prevent the event from propagating
                                          }
                                        }}
                                      >
                                        <Dropdown.Toggle className=" d-flex custom-dropdown-toggle f-gray-imp">
                                          <img
                                            src={
                                              "/images/supply-chain/base-more-dots.svg"
                                            }
                                            className="mr-2 ml-2"
                                            alt=""
                                          />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <div
                                            className="p-2 pl-3 my-1 pointer f-darkgrey"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setShowProviderModal({
                                                headerName:
                                                  "Edit Managed Service Providers",
                                                showModal: true,
                                                providerId: f.ObjectId,
                                              });
                                            }}
                                          >
                                            Edit
                                          </div>
                                          <div
                                            className="p-2 pl-3 my-1 pointer f-danger"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setShowDeleteModal({
                                                showModal: true,
                                                deleteMessage:
                                                  "You are about to delete a provider.",
                                                deleteSentQuestionnaire: () =>
                                                  deleteProvider(f.ObjectId),
                                              });
                                            }}
                                          >
                                            Delete
                                          </div>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                  {/* <div className="flex-row item-question w-100 line-div ">
                                  {!Object.values(showBlocks)?.find(
                                    (b) => b.ObjectId === block.ObjectId
                                  )?.show ? (
                                    <div
                                      className="f-grey d-flex"
                                      style={{ gap: "4px" }}
                                      onClick={() => {
                                        setUpdateShowBlocks(false);
                                        setShowBlocks((prevShowBlocks) => {
                                          return prevShowBlocks.map((t) => {
                                            if (t.ObjectId === block.ObjectId) {
                                              return { ...t, show: true };
                                            }
                                            return t;
                                          });
                                        });
                                      }}
                                    >
                                      <img
                                        src={
                                          "/images/supply-chain/show-more-icon.svg"
                                        }
                                        className="mr-2"
                                        alt=""
                                      />
                                      <div>Show more</div>
                                    </div>
                                  ) : (
                                    <div
                                      className="f-grey d-flex"
                                      style={{ gap: "4px" }}
                                      onClick={() => {
                                        setUpdateShowBlocks(false);
                                        setShowBlocks((prevShowBlocks) => {
                                          return prevShowBlocks.map((t) => {
                                            if (t.ObjectId === block.ObjectId) {
                                              return { ...t, show: false };
                                            }
                                            return t;
                                          });
                                        });
                                      }}
                                    >
                                      <img
                                        src={
                                          "/images/supply-chain/show-less-icon.svg"
                                        }
                                        className="mr-2"
                                        alt=""
                                      />
                                      <div>Show less</div>
                                    </div>
                                  )}
                                </div> */}
                                </div>
                              );
                            }
                          })}
                        </div>
                        {block.ManagedServiceProvider_List?.length > 5 && (
                          <div className="flex-row item-question w-100 line-div ">
                            {!Object.values(showBlocks)?.find(
                              (b) => b.ObjectId === block.ObjectId
                            )?.show ? (
                              <div
                                className="f-grey d-flex"
                                style={{ gap: "4px" }}
                                onClick={() => {
                                  setUpdateShowBlocks(false);
                                  setShowBlocks((prevShowBlocks) => {
                                    return prevShowBlocks.map((t) => {
                                      if (t.ObjectId === block.ObjectId) {
                                        return { ...t, show: true };
                                      }
                                      return t;
                                    });
                                  });
                                }}
                              >
                                <img
                                  src={
                                    "/images/supply-chain/show-more-icon.svg"
                                  }
                                  className="mr-2"
                                  alt=""
                                />
                                <div>Show more</div>
                              </div>
                            ) : (
                              <div
                                className="f-grey d-flex"
                                style={{ gap: "4px" }}
                                onClick={() => {
                                  setUpdateShowBlocks(false);
                                  setShowBlocks((prevShowBlocks) => {
                                    return prevShowBlocks.map((t) => {
                                      if (t.ObjectId === block.ObjectId) {
                                        return { ...t, show: false };
                                      }
                                      return t;
                                    });
                                  });
                                }}
                              >
                                <img
                                  src={
                                    "/images/supply-chain/show-less-icon.svg"
                                  }
                                  className="mr-2"
                                  alt=""
                                />
                                <div>Show less</div>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                    {block.Title === "Summary" && (
                      <div className=" w-100 line-div">
                        <div className="flex-row item-question ">
                          <div className="f-grey">Summary</div>
                          <div
                            className={`summary ${
                              newSummary ? "" : "f-darkgrey"
                            }`}
                          >
                            <textarea
                              type="text"
                              rows={Math.ceil(newSummary.length / 70)}
                              className="edit-task w-100"
                              value={newSummary}
                              placeholder="Summary"
                              onChange={(e) => {
                                setNewSummary(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </li>
              );
            })}
          {!isContributorView && isSent && (
            <>
              {AssessmentBlock && (
                <li
                  className="d-flex flex-row"
                  style={{
                    pointerEvents:
                      isTemplate && !isTemplateEdit ? "none" : "all",
                  }}
                >
                  <span className="timeline-circle">
                    <div className=" bg-darkgrey rounded-full img-block">
                      {viewOnlyAccess("AccessState_SCM") ? (
                        <img
                          alt=""
                          src="/images/attack-surface/small-lock.svg"
                          className="ml-2 mt-1"
                        />
                      ) : (
                        <img
                          src={`/images/supply-chain/darkgrey-add-base-icon.svg`}
                          alt=""
                          className=" rounded-full bg-darkgrey"
                          style={{ marginTop: "2px", paddingLeft: "2px" }}
                        />
                      )}
                    </div>
                  </span>
                  <OverlayTrigger
                    placement="bottom"
                    trigger={
                      viewOnlyAccess("AccessState_SCM")
                        ? ["hover", "focus"]
                        : []
                    }
                    delay={{
                      show: 200,
                      hide: 250,
                    }}
                    overlay={
                      <Tooltip
                        id={`tooltip-1365`}
                        className="custom_tooltip_access"
                      >
                        This feature is managed by your service provider
                      </Tooltip>
                    }
                  >
                    <div>
                      <div
                        className="block w-100 pointer add-section"
                        style={{
                          pointerEvents: viewOnlyAccess("AccessState_SCM")
                            ? "none"
                            : "all",
                        }}
                      >
                        <div className="d-flex flex-row">
                          <div className=" f-darkgrey">
                            {creatingNewSection ? (
                              <input
                                className={`${
                                  errorInput ? "input-error" : ""
                                } w-100 edit-task`}
                                value={newSectionTitle}
                                placeholder={"Add section"}
                                onBlur={(e) => {
                                  if (!newSectionTitle) {
                                    setCreatingNewSection(false);
                                  } else if (newSectionTitle?.trim() !== "") {
                                    setErrorInput(false);
                                    createUpdateSection(
                                      emptyNewSection,
                                      null,
                                      true
                                    );
                                    setCreatingNewSection(false);
                                  } else {
                                    setErrorInput(true);
                                    // setCreatingNewSection(false);
                                  }
                                }}
                                onChange={(e) => {
                                  setNewSectionTitle(e.target.value);
                                }}
                                style={{
                                  borderRadius: "4px",
                                  border:
                                    "2px solid var(--primary-primary-10, #99D2EE)",
                                  background: "#F6F8FB",
                                }}
                              />
                            ) : (
                              <Dropdown
                                className="hg-white-dropdown  user-detail-dropdown supplier-menu inset-addsection"
                                onToggle={() => {}}
                              >
                                <Dropdown.Toggle className=" d-flex custom-dropdown-toggle f-gray-imp">
                                  <div>Add section</div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  className=""
                                  style={{ width: "240px" }}
                                >
                                  {AssessmentBlock.length > 0 &&
                                    AllProfilingObj.QuestionSectionType_List?.map(
                                      (item) => {
                                        let exist = AssessmentBlock.find(
                                          (i) => {
                                            // console.log(i.Title, item.DDLText);
                                            return (
                                              i.SectionTypeEnum ==
                                                item.DDLSort &&
                                              item.DDLSort !== 8
                                            );
                                          }
                                        );
                                        if (!exist) {
                                          return (
                                            <div
                                              className="d-flex"
                                              style={{ padding: " 4px 12px" }}
                                              onClick={() => {
                                                if (
                                                  item.DDLText ==
                                                  "Questionnaire"
                                                ) {
                                                  setCreatingNewSection(true);
                                                } else {
                                                  createUpdateSection(
                                                    emptyNewSection,
                                                    {
                                                      SectionTypeEnum:
                                                        item.DDLSort,
                                                      SectionTypeText:
                                                        item.DDLText,
                                                    },
                                                    true
                                                  );
                                                }
                                              }}
                                            >
                                              <img
                                                src={`/images/supply-chain/${item.iconlist}.svg`}
                                                alt=""
                                                className="mr-2"
                                                // style={{ padding: "4px" }}
                                              />
                                              <div>{item.DDLText}</div>
                                            </div>
                                          );
                                        }
                                      }
                                    )}
                                </Dropdown.Menu>
                              </Dropdown>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </OverlayTrigger>
                </li>
              )}
            </>
          )}
          {isContributorView && AssessmentBlock?.length == 0 && (
            <div className="p-4 f-darkgrey">Nothing sent for this tab</div>
          )}
        </ul>
      )}
      <DeleteModal
        show={showDeleteModal.showModal}
        hideModal={() =>
          setShowDeleteModal({
            showModal: false,
          })
        }
        deleteMessage={showDeleteModal.deleteMessage}
        deleteType={"deleteSentQuestionnaire"}
        deleteSentQuestionnaire={() => {
          showDeleteModal.deleteSentQuestionnaire();
        }}
      />

      <Suspense fallback={<div>Loading...</div>}>
        <AddEditAccreditationModal
          show={accreditationModalState.showModal}
          hideModal={() => {
            setAccreditationModalState({
              showModal: false,
            });
          }}
          refreshData={() => {
            refreshData();
          }}
          accreditationId={accreditationModalState.accreditationId}
          headerName={accreditationModalState.headerName}
          questionId={questionId}
          isContributorView={isContributorView}
        />
        <AddEditCertificateModal
          headerName={showCertificateModal.headerName}
          show={showCertificateModal.showModal}
          categoryId={null}
          hideModal={() => {
            // setCategoryIdType(null);
            setShowCertificateModal({
              showModal: false,
            });
          }}
          status={"Assessment"}
          employeeData={showCertificateModal.employeeData}
          selectedUserId={showCertificateModal.employeeData?.userId}
          selectedCertificateId={showCertificateModal.certificateId}
          questionId={questionId}
          refreshData={() => {
            refreshData();
          }}
          isContributorView={isContributorView}
        />
      </Suspense>
      <EditEmployeeModal
        headerName={showEmployeeModal.headerName}
        show={showEmployeeModal.showModal}
        categoryId={null}
        hideModal={() => {
          setShowEmployeeModal({
            showModal: false,
          });
        }}
        employee={showEmployeeModal.employeeData}
        questionId={questionId}
        refreshData={() => {
          refreshData();
        }}
        isContributorView={isContributorView}
      />
      <AddFrameworkModal
        show={showFrameworkModal.showModal}
        hideModal={() => {
          setShowFrameworkModal({ showModal: false });
        }}
        refreshData={() => {
          refreshData();
        }}
        frameworkId={showFrameworkModal.selectedId}
        questionId={questionId}
        headerName={showFrameworkModal.headerName}
        status={showFrameworkModal.status}
        frameworkItem={showFrameworkModal.frameworkItem}
        AddOrEdit={showFrameworkModal.AddOrEdit}
        isContributorView={isContributorView}
      />
      <AddProviderModal
        show={showProviderModal.showModal}
        hideModal={() => {
          setShowProviderModal({
            showModal: false,
          });
        }}
        refreshData={() => {
          refreshData();
        }}
        providerId={showProviderModal.providerId}
        questionId={questionId}
        headerName={showProviderModal.headerName}
        isContributorView={isContributorView}
      />
      <EmptyModal
        show={showBlockModal.showModal}
        hideModal={() => {
          setStatusModal("open");
          setShowBlockModal({
            showModal: false,
          });
        }}
        refreshData={() => {
          refreshData();
        }}
        // refreshData={() => {
        // }}
        questionId={questionId}
        questionList={questionList}
        sectionType_List={sectionType_List}
        addQuestionList={setQuestionList}
        header={showBlockModal.header}
        headerIcon={showBlockModal.headerIcon}
        content={showBlockModal.content}
        contributors={showBlockModal.contributors}
        status={statusModal}
        notQuestionBlock={showBlockModal.notQuestionBlock}
        setStatusModal={setStatusModal}
        setSelectAll={setSelectAll}
        setCancelAll={setCancelAll}
        isContributorView={isContributorView}
        AllowAskForHelp={showBlockModal.AllowAskForHelp}
      />
    </div>
  );
};

export default Assessment;
