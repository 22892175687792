import React, { useState, useEffect, lazy } from "react";
import "./attackSurface.scss";
import { Row, Col, Button } from "react-bootstrap";
import Header from "../Header";
import CDSA from "./CDSA";
import VulnerbilityManagement from "./CDSA/VulnerbilityManagement";
import IncidentResponse from "./CDSA/IncidentResponse";
import AttackSimulation from "./CDSA/AttackSimulation";
import SecurityOperations from "./CDSA/SecurityOperations";

import AssetsList from "./AssetsList/Index";
import Email from "./Email/Index";
import DirectoryServices from "./DirectoryServices/Index";
import Networks from "./Networks/Index";
import FailSharing from "./FailSharing/Index";

import Overview from "./Overview/Index";
import { useAuth } from "../context/Auth";
import { getAPIData } from "../../Utils/commonApi";
import AttackSurfaceMainPageLoader from "./AttackSurfaceLoaders/AttackSurfaceMainPageLoader";
import AssetsData from "./AssetsList/WorkStations/AssetsData";
import { useSelector } from "react-redux";
import WaitingScreen from "./WaitingScreen";
import CloudServices from "./cloudServices/Index";
import Communications from "./communications/Index";
import DataProtection from "./dataProtection/Index";
import Organisation from "./organisation/Index";
import CorporateSocial from "./corporateSocial/Index";
import Authentications from "./authentications/Index";
import WebServices from "./webServices/Index";
import Suppliers from "./Suppliers/Index";
import { useHistory } from "react-router-dom";
import { BasicSubscriptionDropDown } from "../Integrationsv2/UpgradeToPremiumModal";
import { HGPopUp } from "./KPIpopup/KPIpopup";
 
// const Suppliers = lazy(() => import("./Suppliers/Index"));
const AttackSurface = () => {
  const { authTokens } = useAuth();
  const [menuItems, setMenuItems] = useState([]);
  const history = useHistory();
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const isMenuCollapse = useSelector((state) => state?.global?.menuCollapse);
  const [allowDrillDownAttackSurface, setAllowDrillDownAttackSurface] =
    useState({});
    const [overviewPageData, setOverviewPageData] = useState({})
  // Assets list
  const [assetModifierId, setAssetModifierId] = useState(2139);
  const [firstLoad, setfirstLoad] = useState(false)
  useEffect(() => {
    setMainPageLoading(true);
    getOverviewPageData().then((overviewData) => {
      setOverviewPageData(overviewData);
      getMenuItems();
    });
  }, []);

  function getOverviewPageData() {
    return new Promise((resolve, reject) => {
      getAPIData(271, authTokens)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  

  function getMenuItems() {
    getAPIData(264, authTokens).then((response) => {
      setMainPageLoading(false);
      if (response?.mr?.Success) {
        if (!firstLoad) {
          
          let first_live_elem= response?.AttackSurfaceComponent_List?.find((item)=> item.DevelpomentStage==="Live")
          setBreadCrumb([first_live_elem?.Title ?? "CDSA"])
          setfirstLoad(true)
        }



        setMenuItems( response?.AttackSurfaceComponent_List);

        // Attack  Surface Visibility Setup according to Subscription Setup
        let user_data = localStorage.getItem("object_Data");
        let allowDrillDownAttackSurface = response?.mr?.AccessObjList?.filter(
          (item) => item?.Question === "Attack Surface Visibility"
        )?.[0]?.Option;
        if (user_data !== null && !allowDrillDownAttackSurface) {
          let _data = JSON.parse(user_data);
          history.push(`/${_data?.UserDetail?.Role?.toLowerCase()}/dashboard`);
        }

        // Attack Surface Insights visibility Setup
        setAllowDrillDownAttackSurface(
          response?.mr?.AccessObjList?.filter(
            (item) => item?.Question === "Attack Surface Limit"
          )?.[0]
        );
      }
    });
  }
  function refreshMenuItems() {
    getAPIData(375, authTokens)
      .then((response) => {
        getMenuItems();
      })
      .catch((err) => {
        getMenuItems();
      });
  }

  const [breadCrumb, setBreadCrumb] = useState([""]);
  const activePage = breadCrumb[breadCrumb.length - 1];
  function goTo(pageName) {
    if (pageName == null || pageName === "") {
      console.log("go to page is Null");
      return;
    }

    let updated_data = [...breadCrumb];
    updated_data.push(pageName);
    setBreadCrumb(updated_data);
  }

  return (
    <>
      {mainPageLoading ? (
        <div className="pl-3">
          <AttackSurfaceMainPageLoader />
        </div>
      ) : (
        <Row className="attack_surface p-0 m-0" style={{ height: "100%" }}>
          <Col xs={2} className="left-sidebar px-2 pt-3 bg-white">
            <div
              style={{
                position: "fixed",
                width: `${isMenuCollapse ? "14%" : "13%"}`,
              }}
            >
              <p className="f-500 f-darkgrey pl-2 mt-3 mb-4">Components</p>
              <div className="d-flex flex-column">
                {menuItems.map((item, menuIndex) => {
                  return (
                    <div
                      className={` align-items-center justify-content-between px-2 component py-2 mt-1
                      ${
                        item?.DevelpomentStage?.toLowerCase() === "hide"
                          ? "d-none"
                          : "d-flex"
                      }
                      
                      ${
                        breadCrumb[0] == item?.Title ? "selected-component" : ""
                      }   `}
                      onClick={() => {
                        setBreadCrumb([item?.Title]);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <div>
                          {item?.Title?.toLowerCase() === "overview" && (
                            <img
                              alt=""
                              src="/images/attack-surface/overview-icon.svg"
                              className="ml-1"
                            />
                          )}

                          {item?.Title?.toLowerCase() !== "overview" &&
                            item?.DevelpomentStage?.toLowerCase() ===
                              "coming soon" && (
                              <img
                                alt=""
                                src="/images/attack-surface/grey-thunder-icon.svg"
                                className="ml-1"
                                style={{ width: "14px", marginRight: "3px" }}
                              />
                            )}

                          {item?.Title?.toLowerCase() !== "overview" &&
                            item?.DevelpomentStage?.toLowerCase() === "live" &&
                            item?.CompletionScore === 0 && (
                              <img
                                alt=""
                                src="/images/attack-surface/thunder-icon.svg"
                                className="ml-1"
                                style={{ width: "14px", marginRight: "3px" }}
                              />
                            )}

                          {item?.Title?.toLowerCase() !== "overview" &&
                            item?.DevelpomentStage?.toLowerCase() === "live" &&
                            item?.CompletionScore > 0 &&
                            item?.CompletionScore < 100 && (
                              <Status value={100 - item?.CompletionScore} />
                            )}

                          {item?.Title?.toLowerCase() !== "overview" &&
                            item?.DevelpomentStage?.toLowerCase() === "live" &&
                            item?.CompletionScore === 100 && (
                              <img
                                alt=""
                                src="/images/attack-surface/menu-completed.svg"
                                className="ml-1"
                                style={{ width: "14px", marginRight: "3px" }}
                              />
                            )}
                        </div>

                        <div className="ml-2 d-flex align-items-center justify-content-center">
                          {item?.Title}
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        {item?.DevelpomentStage?.toLowerCase() === "live" && (
                          <>{item?.CompletionScore}%</>
                        )}
                        {item?.DevelpomentStage?.toLowerCase() ===
                          "coming soon" && (
                          <span
                            className="attack-menu-tag"
                            style={{
                              width: `${
                                item?.Title.toLowerCase() ==
                                "directory services"
                                  ? "51px"
                                  : "51px"
                              }`,
                            }}
                          >
                            {item?.Title.toLowerCase() == "directory services"
                              ? "COMING"
                              : "COMING"}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>
          <Col
            xs={10}
            className="p-0 position-relative "
            id="assets-list-full-screen-modal-parent"
          >
            <div
              className="dashboard fullHeight action_center px-0 hide-scrollbar"
              id="scrollable"
            >
              <div
                style={{
                  position: "fixed",
                  width: `${isMenuCollapse ? "76.9%" : "69.5%"}`,
                  background: "#fff",
                  zIndex: 6,
                }}
              >
                <header
                  className="d-flex justify-content-between navbar sticky-top pb-0"
                  style={{ paddingTop: "1em", background: "#FFFFFF" }}
                >
                  <h1>Attack Surface</h1>
                  <Header />
                </header>

                <div className="mini-header d-flex align-items-center justify-content-between">
                  <div>
                    {breadCrumb.length == 1 && (
                      <div
                        className="ml-5"
                        style={{ fontSize: "16px", fontWeight: 600 }}
                      >
                        {/* {breadCrumb[0]} */}
                      </div>
                    )}
                    {breadCrumb.length > 1 && (
                      <div className="d-flex align-items-center">
                        <div
                          className="f-grey d-flex align-items-center pointer ml-3"
                          onClick={() => {
                            // if (breadCrumb[0] === "CDSA") {
                            refreshMenuItems();
                            // }
                            let updated_data = [...breadCrumb];
                            updated_data.pop();
                            setBreadCrumb(updated_data);
                          }}
                        >
                          <img
                            alt=""
                            src="/images/attack-surface/back-icon.svg"
                            className="mr-2"
                          />
                          Back
                        </div>
                        <div className="d-flex align-items-center ml-4">
                          {breadCrumb.map((item, itemIndex) => {
                            return (
                              <div className="bread-parent d-flex align-items-center">
                                <div className="bread-child">{item} </div>
                                {breadCrumb.length - 1 !== itemIndex && (
                                  <div className="f-grey mx-3">/</div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mr-3">
                    <BasicSubscriptionDropDown
                      disabled={
                        allowDrillDownAttackSurface?.OtherValue === "None"
                          ? false
                          : true
                      }
                      upgradeOptions={
                        allowDrillDownAttackSurface?.UpgradeOptions
                      }
                    >
                      <HGPopUp
                        maxWidth={"300px"}
                        content={
                          <div className="p-0 d-flex flex-column">
                            <img
                              src="/images/insights-coming-soon-image.svg"
                              alt=""
                              className=""
                            />
                            <img
                              src="/images/welcome-coming-soon-icon.svg"
                              alt=""
                              width={100}
                              className="m-2"
                            />
                            <div className="f-14 f-600 f-black ml-2">
                              Security Insights are coming soon!
                            </div>
                            <div className="f-14 f-grey m-2" style={{textWrap:"pretty"}}>
                              Don’t fall foul to simple oversights and
                              misconfigurations that attackers find and use to
                              take you down! Soon you’ll be able to build, share
                              and action quick, simple and effective security
                              insights to keep you safe.
                            </div>
                          </div>
                        }
                      >
                        <Button
                          className="insights-btn py-1 px-2 mr- 3 mb-1 mt-3"
                          style={{
                            visibility: `${
                              breadCrumb[0] == "Overview" ? "hidden" : "visible"
                            }`,
                            // cursor: "not-allowed",
                          }}
                        >
                          <img
                            alt=""
                            src="/images/attack-surface/thunder-icon.svg"
                            className="mr-2"
                            style={{
                              filter: `${
                                allowDrillDownAttackSurface?.OtherValue ===
                                "None"
                                  ? "invert(39%) sepia(14%) saturate(509%) hue-rotate(187deg) brightness(96%) contrast(20%)"
                                  : ""
                              }`,
                            }}
                          />
                          Insights
                        </Button>
                      </HGPopUp>
                    </BasicSubscriptionDropDown>
                  </div>
                </div>
              </div>
              <div
                className="attack_surface_body h- 1 00"
                style={{ paddingTop: "7.5rem" }}
              >
                {/* Overview */}
                {activePage == "Overview" && (
                  // <WaitingScreen />
                  <Overview progressData={menuItems} overviewPageData={overviewPageData}/>
                )}
                {/* CDSA CDSA CDSA CDSA */}
                {activePage == "CDSA" && (
                  <CDSA goTo={goTo} refreshMenuItems={refreshMenuItems} />
                )}
                {activePage == "Vulnerability Management" && (
                  <VulnerbilityManagement refreshMenuItems={refreshMenuItems} />
                )}
                {activePage == "Incident Response" && (
                  <IncidentResponse refreshMenuItems={refreshMenuItems} />
                )}
                {activePage == "Attack Simulation" && (
                  <AttackSimulation refreshMenuItems={refreshMenuItems} />
                )}
                {activePage == "Security Operations" && (
                  <SecurityOperations refreshMenuItems={refreshMenuItems} />
                )}

                {activePage == "Assets List" && (
                  <AssetsList
                    goTo={goTo}
                    setAssetId={setAssetModifierId}
                    refreshMenuItems={refreshMenuItems}
                  />
                )}
                {breadCrumb[0] == "Assets List" && breadCrumb.length > 1 && (
                  <AssetsData
                    typeName={breadCrumb[1]}
                    assetModifierId={assetModifierId}
                    refreshMenuItems={refreshMenuItems}
                  />
                )}
                {/* Networks */}
                {activePage == "Networks" && (
                  <Networks
                    goTo={goTo}
                    setAssetId={setAssetModifierId}
                    activePage={activePage}
                    refreshMenuItems={refreshMenuItems}
                  />
                )}
                {breadCrumb[0] == "Networks" && breadCrumb.length > 1 && (
                  <AssetsData
                    typeName={breadCrumb[1]}
                    assetModifierId={assetModifierId}
                    refreshMenuItems={refreshMenuItems}
                  />
                )}

                {/* Directory Services */}
                {activePage == "Directory Services" && (
                  <DirectoryServices
                    goTo={goTo}
                    setAssetId={setAssetModifierId}
                    activePage={activePage}
                    refreshMenuItems={refreshMenuItems}
                  />
                )}
                {breadCrumb[0] == "Directory Services" &&
                  breadCrumb.length > 1 && (
                    <AssetsData
                      typeName={breadCrumb[1]}
                      assetModifierId={assetModifierId}
                      refreshMenuItems={refreshMenuItems}
                    />
                  )}
                {/* <DirectoryServices /> */}
                {/* Email */}
                {activePage == "Email" && (
                  <Email
                    goTo={goTo}
                    setAssetId={setAssetModifierId}
                    activePage={activePage}
                    refreshMenuItems={refreshMenuItems}
                  />
                )}
                {breadCrumb[0] == "Email" && breadCrumb.length > 1 && (
                  <AssetsData
                    typeName={breadCrumb[1]}
                    assetModifierId={assetModifierId}
                    refreshMenuItems={refreshMenuItems}
                  />
                )}
                {/* <Email /> */}
                {/* Fail Sharing */}
                {activePage == "File Sharing" && (
                  <FailSharing
                    goTo={goTo}
                    setAssetId={setAssetModifierId}
                    activePage={activePage}
                    refreshMenuItems={refreshMenuItems}
                  />
                )}
                {breadCrumb[0] == "File Sharing" && breadCrumb.length > 1 && (
                  <AssetsData
                    typeName={breadCrumb[1]}
                    assetModifierId={assetModifierId}
                    refreshMenuItems={refreshMenuItems}
                  />
                )}
                {/* <FailSharing /> */}
                {/* Cloud Services */}
                {activePage == "Cloud Services" && (
                  <CloudServices
                    goTo={goTo}
                    setAssetId={setAssetModifierId}
                    activePage={activePage}
                    refreshMenuItems={refreshMenuItems}
                  />
                )}
                {breadCrumb[0] == "Cloud Services" && breadCrumb.length > 1 && (
                  <AssetsData
                    typeName={breadCrumb[1]}
                    assetModifierId={assetModifierId}
                    refreshMenuItems={refreshMenuItems}
                  />
                )}

                {/* Communications */}
                {activePage == "Communications" && (
                  <Communications
                    goTo={goTo}
                    setAssetId={setAssetModifierId}
                    activePage={activePage}
                    refreshMenuItems={refreshMenuItems}
                  />
                )}
                {breadCrumb[0] == "Communications" && breadCrumb.length > 1 && (
                  <AssetsData
                    typeName={breadCrumb[1]}
                    assetModifierId={assetModifierId}
                    refreshMenuItems={refreshMenuItems}
                  />
                )}

                {/* Data Protection */}
                {activePage == "Data Protection" && (
                  <DataProtection
                    goTo={goTo}
                    setAssetId={setAssetModifierId}
                    activePage={activePage}
                    refreshMenuItems={refreshMenuItems}
                  />
                )}
                {breadCrumb[0] == "Data Protection" &&
                  breadCrumb.length > 1 && (
                    <AssetsData
                      typeName={breadCrumb[1]}
                      assetModifierId={assetModifierId}
                      refreshMenuItems={refreshMenuItems}
                    />
                  )}

                {/* Organisation */}
                {activePage == "Organisation" && (
                  <Organisation
                    goTo={goTo}
                    setAssetId={setAssetModifierId}
                    activePage={activePage}
                    refreshMenuItems={refreshMenuItems}
                  />
                )}
                {breadCrumb[0] == "Organisation" && breadCrumb.length > 1 && (
                  <AssetsData
                    typeName={breadCrumb[1]}
                    assetModifierId={assetModifierId}
                    refreshMenuItems={refreshMenuItems}
                  />
                )}

                {/* Corporate Social */}
                {activePage == "Corporate Social" && (
                  <CorporateSocial
                    goTo={goTo}
                    setAssetId={setAssetModifierId}
                    activePage={activePage}
                    refreshMenuItems={refreshMenuItems}
                  />
                )}
                {breadCrumb[0] == "Corporate Social" &&
                  breadCrumb.length > 1 && (
                    <AssetsData
                      typeName={breadCrumb[1]}
                      assetModifierId={assetModifierId}
                      refreshMenuItems={refreshMenuItems}
                    />
                  )}

                {/*Authentications */}
                {activePage == "Authentication" && (
                  <Authentications
                    goTo={goTo}
                    setAssetId={setAssetModifierId}
                    activePage={activePage}
                    refreshMenuItems={refreshMenuItems}
                  />
                )}
                {breadCrumb[0] == "Authentication" && breadCrumb.length > 1 && (
                  <AssetsData
                    typeName={breadCrumb[1]}
                    assetModifierId={assetModifierId}
                    refreshMenuItems={refreshMenuItems}
                  />
                )}

                {/*Web Services */}
                {activePage == "Web Services" && (
                  <WebServices
                    goTo={goTo}
                    setAssetId={setAssetModifierId}
                    activePage={activePage}
                    refreshMenuItems={refreshMenuItems}
                  />
                )}
                {breadCrumb[0] == "Web Services" && breadCrumb.length > 1 && (
                  <AssetsData
                    typeName={breadCrumb[1]}
                    assetModifierId={assetModifierId}
                    refreshMenuItems={refreshMenuItems}
                  />
                )}

                {/*Suppliers */}
                {activePage === "Suppliers" && (
                  <Suppliers
                    goTo={goTo}
                    setAssetId={setAssetModifierId}
                    activePage={activePage}
                    refreshMenuItems={refreshMenuItems}
                  />
                )}
                {breadCrumb[0] === "Suppliers" && breadCrumb.length > 1 && (
                  <AssetsData
                    typeName={breadCrumb[1]}
                    assetModifierId={assetModifierId}
                    refreshMenuItems={refreshMenuItems}
                  />
                )}

                {/* Waiting Page IMPLEMENTATION */}

                {/* {(activePage == "Cloud Services" ||
                  activePage == "Communications" ||
                  activePage == "Data Protection" ||
                  activePage == "Organisation" ||
                  activePage == "Corporate Social" ||
                  activePage == "Security policy") && <WaitingScreen />} */}

                <div
                  style={{
                    margin: "20px",
                  }}
                ></div>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default AttackSurface;

export function cleanText(_text, condtions = {}) {
  let text;

  if (condtions?.isLandingSreen) {
    text = _text.includes("Other")
      ? `${_text} ${condtions.activePage} assets`
      : _text;
  } else {
    text = _text;
  }

  if (text === null || text === undefined) return "";

  let clean_text = text
    ?.trim()

    ?.replaceAll("_", " ")

    // ?.replaceAll(".", "")
    ?.replaceAll(",", "")
    ?.replaceAll("plus", "+")
    ?.replaceAll("fwdslash", "/")
    ?.replaceAll("ampersand", "&")
    ?.replaceAll("openbracet", "(")
    ?.replaceAll("closebracet", ")");

  return clean_text;
}

export const Status = ({ value }) => {
  return (
    <svg
      height="21"
      width="21"
      viewBox="0 0 18 18"
      className="circle-indicator"
    >
      <circle
        cx="9"
        cy="9"
        r="6"
        stroke="#d4d6dd"
        stroke-width="1.5"
        fill="transparent"
      />
      <circle
        cx="9"
        cy="9"
        r="6"
        stroke="#008FD4"
        stroke-width="1.5"
        fill="transparent"
        pathLength={100}
        strokeDasharray={100}
        strokeDashoffset={value}
      />
    </svg>
  );
};
