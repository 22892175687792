import React from "react";
import ContentLoader from "react-content-loader";

const ActionCenterHomePageLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="20" y="10" rx="5" ry="5" width="10%" height="30" />
      <rect x="1200" y="30" rx="5" ry="5" width="20%" height="40" />
      <rect x="1000" y="90" rx="5" ry="5" width="38%" height="40" />
      <rect x="5" y="144" rx="0" ry="0" width="100%" height="1" />
      <rect x="20" y="160" rx="5" ry="5" width="50%" height="40" />

      <rect x="20" y="210" rx="3" ry="3" width="98%" height="36" />

      <rect x="20" y="257" rx="4" ry="4" width="98%" height="60" />
      <rect x="20" y="320" rx="4" ry="4" width="98%" height="60" />
      <rect x="20" y="383" rx="4" ry="4" width="98%" height="60" />
      <rect x="20" y="446" rx="4" ry="4" width="98%" height="60" />

      <rect x="20" y="509" rx="4" ry="4" width="98%" height="60" />
      <rect x="20" y="572" rx="4" ry="4" width="98%" height="60" />
      <rect x="20" y="635" rx="4" ry="4" width="98%" height="60" />
      <rect x="20" y="698" rx="4" ry="4" width="98%" height="60" />
      <rect x="20" y="761" rx="4" ry="4" width="98%" height="60" />
      <rect x="20" y="824" rx="4" ry="4" width="98%" height="60" />
      <rect x="20" y="887" rx="4" ry="4" width="98%" height="60" />
      <rect x="20" y="950" rx="4" ry="4" width="98%" height="60" />
      <rect x="20" y="1013" rx="4" ry="4" width="98%" height="60" />
      <rect x="20" y="1076" rx="4" ry="4" width="98%" height="60" />
      <rect x="20" y="1139" rx="4" ry="4" width="98%" height="60" />
      <rect x="20" y="1202" rx="4" ry="4" width="98%" height="60" />
      <rect x="20" y="1265" rx="4" ry="4" width="98%" height="60" />
      <rect x="20" y="1328" rx="4" ry="4" width="98%" height="60" />
      <rect x="20" y="1391" rx="4" ry="4" width="98%" height="60" />
    </ContentLoader>
  );
};

export default ActionCenterHomePageLoader;
