export function getRequestOptions(token, method = "GET", bodyContents = "") {
  var myHeaders = new Headers();

  if (token) myHeaders.append("Authorization", `Bearer ${token}`);

  console.log(bodyContents);
  if (bodyContents != "") {
    //create FormData Object to hold the body's contents
    var urlencoded = new FormData();
    for (const key in bodyContents) urlencoded.append(key, bodyContents[key]);

    console.log(urlencoded);
    var requestOptions = {
      method: `${method}`,
      headers: myHeaders,
      body: urlencoded,
    };
  } else {
    var requestOptions = {
      method: `${method}`,
      headers: myHeaders,
    };
  }

  return requestOptions;
}

export async function setOneSignalPlayer(authToken, playerId) {
  console.log(authToken);
  console.log(playerId);

  // if messageEncrypt does exist in localStorage then call function
  const data = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/CC_API/SetupOneSignalPlayerId`,
    // `https://hg-dev-ui-ac-api.azurewebsites.net/CC_API/SetupOneSignalPlayerId`,
    getRequestOptions(authToken, "POST", { PlayerId: playerId })
  ).then((response) => response.json());

  return data;
}

export async function removeOneSignalPlayer(authToken, playerId) {
  console.log(authToken);
  console.log(playerId);
  // if messageEncrypt does exist in localStorage then call function
  const data = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/CC_API/RemoveOnePlayerSignalId`,
    // `https://hg-dev-ui-ac-api.azurewebsites.net/CC_API/RemoveOnePlayerSignalId`,
    getRequestOptions(authToken, "POST", { PlayerId: playerId })
  ).then((response) => response.json());

  return data;
}
