import React, { useRef, useState } from "react";
import "./supplier-profiler.scss";
import { useEffect } from "react";
import OptionsDropdown from "./ProfilerDropdowns/OptionsDropdown";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button } from "react-bootstrap";
import MultiSelectDropdown from "./ProfilerDropdowns/MultiSelectDropdown";

const AddQuestionRow = ({
  block,
  dataForAddQuestion,
  setDataForAddQuestion,
  saveNewQuestion,
  addQuestionRef,
  addQuestion,
  setAddQuestion,
  newQuestionType,
  setnewQuestionType,
}) => {
  const [addMode, setAddMode] = useState(false);
  const [errorInput, setErrorInput] = useState(false);
  const [addedOption, setAddedOption] = useState("");
  useEffect(() => {
    setAddedOption("");
    setAddMode(false);
  }, []);
  useEffect(() => {
    // if (addedOption !== "") {
    //   let tempdata = dataForAddQuestion;
    //   tempdata.push({
    //     id:
    //       dataForAddQuestion.length > 0
    //         ? dataForAddQuestion[dataForAddQuestion.length - 1].id + 1
    //         : 0,
    //     Text: addedOption,
    //   });
    //   setDataForAddQuestion(tempdata);
    //   setAddedOption("");
    //   setAddMode(false);
    // }
    console.log(dataForAddQuestion);
  }, [addMode]);

  function moveCaretAtEnd(e) {
    var temp_value = e.target.value;
    e.target.value = "";
    e.target.value = temp_value;
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  function onDragEndShownSelectorOptions(result) {
    if (!result.destination) {
      return;
    }
    // console.log(result.source.index);
    const items = reorder(
      dataForAddQuestion,
      result.source.index,
      result.destination.index
    );
    setDataForAddQuestion(
      items.map((item, index) => {
        return {
          ...item,
          SortOrder: index + 1,
        };
      })
    );
  }
  return (
    <div
      ref={addQuestionRef}
      className="flex-row item-question add-question-row"
      style={{ zIndex: 2, alignItems: "start" }}
    >
      <div className="f-grey d-flex">
        <input
          type="text"
          className={`edit-task   ${errorInput ? "input-error" : ""}`}
          value={addQuestion?.QuestionText}
          placeholder={"Question"}
          onChange={(e) => {
            setErrorInput(false);
            let newAdd = { ...addQuestion };
            newAdd.QuestionText = e.target.value;
            setAddQuestion(newAdd);
            // setCurrentWord(e.target.value);
          }}
          style={{
            borderRadius: "4px",
            border: "2px solid var(--primary-primary-10, #99D2EE)",
            background: "#F6F8FB",
          }}
        />
      </div>
      <div
      // className={`f-darkgrey w-100`}
      >
        {newQuestionType.QuestionTypeText === "Single select" && (
          <div className="d-flex flex-column">
            <div>
              <OptionsDropdown
                fieldId={"id"}
                fieldValue={"value"}
                // selected={row.AnswerText}
                placeholder="Choose"
                onClick={(id, val) => {
                  // if (!isTemplate) {
                  //   let newField =
                  //     block.Question_List?.map((item) => {
                  //       if (item === row) {
                  //         item.AnswerText = val;
                  //         item.QuestionStatusText =
                  //           "Answered";
                  //         item.QuestionStatus = 3;
                  //         setData_to_send([item]);
                  //         setSaveTemplateStatus(true);
                  //       }
                  //       return item;
                  //     });
                  //   let temp2 = AssessmentBlock.map(
                  //     (q) => {
                  //       if (q === block) {
                  //         q.Question_List = newField;
                  //       }
                  //       return q;
                  //     }
                  //   );
                  //   setAssessmentBlock(temp2);
                  //   setProfilingBlocks(AssessmentBlock);
                  // }
                  // console.log(val);
                }}
                data={dataForAddQuestion}
              />
            </div>

            <div className="d-flex flex-column pt-2 w-100">
              <div className={`f-darkgrey f-12 pb-1`}>Options</div>
              {/* <DragDropContext onDragEnd={onDragEndShownSelectorOptions}>
                <Droppable direction="vertical" droppableId="droppableSection">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}> */}
                      <ul style={{ marginLeft: "-28px" }}>
                        {dataForAddQuestion &&
                          dataForAddQuestion.map((it, i) => (
                            // <Draggable
                            //   key={it?.Text}
                            //   draggableId={it?.id.toString()}
                            //   index={i}
                            //   // isDragDisabled
                            // >
                            //   {(provided, snapshot) => (
                                <li>
                                  <div
                                    // ref={provided.innerRef}
                                    // {...provided.draggableProps}
                                    // {...provided.dragHandleProps}
                                    className="  flex-row selector-list-item align-items-center"
                                    key={i}
                                    index={i}
                                    style={{
                                      display: "grid",
                                      gridTemplateColumns: "10% 80% 10%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginRight: "-16px",
                                      }}
                                    >
                                      {/* <img
                                        alt=""
                                        src="/images/supply-chain/drag-n-drop.svg"
                                        className="addBtnRow hover-visible"
                                      /> */}
                                    </div>

                                    <div
                                      className={`f-darkgrey d-flex align-items-center`}
                                    >
                                      <textarea
                                        autoFocus
                                        onFocus={moveCaretAtEnd}
                                        type="text"
                                        rows={1}
                                        className="edit-task w-100"
                                        value={it.Text}
                                        placeholder={"Type"}
                                        onChange={(e) => {
                                          let newList = [];
                                          const updatedData =
                                            dataForAddQuestion.map(
                                              (dataItem) => {
                                                if (dataItem.id === it.id) {
                                                  return {
                                                    ...dataItem,
                                                    Text: e.target.value,
                                                  };
                                                }
                                                return dataItem;
                                              }
                                            );
                                          updatedData.map((el) => {
                                            newList.push(el.Text);
                                          });

                                          setnewQuestionType({
                                            ...newQuestionType,
                                            QuestionDropdownListMembers:
                                              newList,
                                          });
                                          setDataForAddQuestion(updatedData);
                                        }}
                                      />
                                    </div>
                                    <div
                                      className="addBtnRow"
                                      style={{
                                        marginLeft: "-16px",
                                        display: "block",
                                      }}
                                    >
                                      <img
                                        width={9}
                                        className="hover-visible addBtnRow"
                                        src="/images/attack-surface/cross-icon.svg"
                                        alt=""
                                        onClick={(e) => {
                                          const updatedData =
                                            dataForAddQuestion.filter(
                                              (item) => item.id !== it.id
                                            );
                                          let newList = [];
                                          updatedData.map((el) => {
                                            newList.push(el.Text);
                                          });
                                          setnewQuestionType({
                                            ...newQuestionType,
                                            QuestionDropdownListMembers:
                                              newList,
                                          });
                                          setDataForAddQuestion(updatedData);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </li>
                            //   )}
                            // </Draggable>
                          ))}
                      </ul>
                      {/* {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext> */}

              {addMode ? (
                <div className="f-grey d-flex">
                  <textarea
                    type="text"
                    className={`edit-task edit-title`}
                    rows={addedOption ? Math.ceil(addedOption.length / 70) : 1}
                    style={{ width: "200px" }}
                    value={addedOption}
                    onChange={(e) => {
                      setAddedOption(e.target.value);
                    }}
                  />
                  <div
                    style={{
                      padding: "1px",
                      borderRadius: "2px",
                      background: " var(--primary-primary-50, #008FD4)",
                      alignSelf: "flex-start",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      let tempdata = dataForAddQuestion;
                      tempdata.push({
                        id:
                          dataForAddQuestion.length > 0
                            ? dataForAddQuestion[dataForAddQuestion.length - 1]
                                .id + 1
                            : 0,
                        Text: addedOption,
                      });
                      let newList = [];
                      tempdata.map((el) => {
                        newList.push(el.Text);
                      });

                      setnewQuestionType({
                        ...newQuestionType,
                        QuestionDropdownListMembers: newList,
                      });
                      setDataForAddQuestion(tempdata);
                      console.log("test adding", dataForAddQuestion, tempdata);

                      // setDataForAddQuestion(updatedData);
                      setAddedOption("");
                      setAddMode(false);
                    }}
                  >
                    <img
                      className="addBtnRow"
                      alt=""
                      src="/images/settings-v2/white-tick-save.svg"
                      style={{ padding: "2px" }}
                    />
                  </div>
                  <div
                    style={{
                      padding: "6px",
                      marginRight: "10px",
                      cursor: "pointer",
                      display: "flex",
                    }}
                    onClick={(e) => {
                      setAddedOption("");
                      setAddMode(false);
                    }}
                  >
                    <img
                      className="addBtnRow"
                      width={10}
                      src="/images/attack-surface/cross-icon.svg"
                      alt=""
                      // onClick={(e) => {
                      //   setAddedOption("");
                      //   setAddMode(false);
                      // }}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className={`f-darkgrey pt-1 cursor-pointer`}
                  style={{ width: "200px" }}
                  onClick={() => {
                    setAddMode(true);
                  }}
                >
                  Add option
                </div>
              )}
              {/* <div className={`f-darkgrey pt-1`}>Add option</div> */}
            </div>
          </div>
        )}
        {newQuestionType.QuestionTypeText === "Multiple select" && (
          <div className={`f-darkgrey w-100`}>
            <div
              className="d-flex flex-row align-items-center w-100"
              style={{
                gap: "4px",
                flexWrap: "wrap",
              }}
            >
              {/* <input
                type="text"
                className="edit-task"
                value={"Add"}
                placeholder={"Add"}
              /> */}
              <MultiSelectDropdown
                placeholder={"Add"}
                data={dataForAddQuestion}
                inputDisable={true}
               
                // selected={selectedMembersList}
                // onChange={(val) => {
                //   const mergedArray = [...selectedMembersList];

                //   for (let obj of val) {
                //     const existingObj = mergedArray.find(
                //       (item) => item.objId_PSAMember === obj.objId_PSAMember
                //     );
                //     if (!existingObj) {
                //       mergedArray.push(obj);
                //     }
                //   }
                //   setSelectedMembersList(val);
                // }}
              />
            </div>

            <div className="d-flex flex-column pt-2 w-100">
              <div className={`f-darkgrey f-12 pb-1`}>Options</div>
              {/* <DragDropContext onDragEnd={onDragEndShownSelectorOptions}>
                <Droppable direction="vertical" droppableId="droppableSection">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}> */}
                      <ul style={{ marginLeft: "-28px" }}>
                        {dataForAddQuestion &&
                          dataForAddQuestion.map((it, i) => (
                            // <Draggable
                            //   key={it?.Text}
                            //   draggableId={it?.id.toString()}
                            //   index={i}
                            //   // isDragDisabled
                            // >
                            //   {(provided, snapshot) => (
                                <li>
                                  <div
                                    // ref={provided.innerRef}
                                    // {...provided.draggableProps}
                                    // {...provided.dragHandleProps}
                                    className="  flex-row selector-list-item align-items-center"
                                    key={i}
                                    index={i}
                                    style={{
                                      display: "grid",
                                      gridTemplateColumns: "10% 80% 10%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginRight: "-16px",
                                      }}
                                    >
                                      {/* <img
                                        alt=""
                                        src="/images/supply-chain/drag-n-drop.svg"
                                        className=" hover-visible addBtnRow"
                                      /> */}
                                    </div>

                                    <div
                                      className={`f-darkgrey d-flex align-items-center`}
                                    >
                                      <textarea
                                        autoFocus
                                        onFocus={moveCaretAtEnd}
                                        type="text"
                                        rows={1}
                                        className="edit-task w-100"
                                        value={it.Text}
                                        placeholder={"Type"}
                                        onChange={(e) => {
                                          let newList = [];
                                          const updatedData =
                                            dataForAddQuestion.map(
                                              (dataItem) => {
                                                if (dataItem.id === it.id) {
                                                  return {
                                                    ...dataItem,
                                                    Text: e.target.value,
                                                  };
                                                }
                                                return dataItem;
                                              }
                                            );
                                          updatedData.map((el) => {
                                            newList.push(el.Text);
                                          });

                                          setnewQuestionType({
                                            ...newQuestionType,
                                            QuestionDropdownListMembers:
                                              newList,
                                          });

                                          setDataForAddQuestion(updatedData);
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        marginLeft: "-16px",
                                      }}
                                    >
                                      <img
                                        width={9}
                                        className="hover-visible addBtnRow"
                                        src="/images/attack-surface/cross-icon.svg"
                                        alt=""
                                        onClick={(e) => {
                                          const updatedData =
                                            dataForAddQuestion.filter(
                                              (item) => item.id !== it.id
                                            );
                                          let newList = [];
                                          updatedData.map((el) => {
                                            newList.push(el.Text);
                                          });
                                          setnewQuestionType({
                                            ...newQuestionType,
                                            QuestionDropdownListMembers:
                                              newList,
                                          });
                                          setDataForAddQuestion(updatedData);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </li>
                            //   )}
                            // </Draggable>
                          ))}
                      </ul>
                      {/* {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext> */}

              {addMode ? (
                <div className="f-grey d-flex">
                  <textarea
                    type="text"
                    className={`edit-task edit-title`}
                    rows={addedOption ? Math.ceil(addedOption.length / 70) : 1}
                    style={{ width: "200px" }}
                    value={addedOption}
                    onChange={(e) => {
                      setAddedOption(e.target.value);
                    }}
                  />
                  <div
                    style={{
                      padding: "1px",
                      borderRadius: "2px",
                      background: " var(--primary-primary-50, #008FD4)",
                      alignSelf: "flex-start",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      let tempdata = dataForAddQuestion;
                      tempdata.push({
                        id:
                          dataForAddQuestion.length > 0
                            ? dataForAddQuestion[dataForAddQuestion.length - 1]
                                .id + 1
                            : 0,
                        Text: addedOption,
                      });
                      let newList = [];
                      tempdata.map((el) => {
                        newList.push(el.Text);
                      });

                      setnewQuestionType({
                        ...newQuestionType,
                        QuestionDropdownListMembers: newList,
                      });

                      setDataForAddQuestion(tempdata);
                      console.log("test adding", dataForAddQuestion, tempdata);
                      setAddedOption("");
                      setAddMode(false);
                    }}
                  >
                    <img
                      className="addBtnRow"
                      alt=""
                      src="/images/settings-v2/white-tick-save.svg"
                      style={{ padding: "2px" }}
                    />
                  </div>
                  <div
                    style={{
                      padding: "6px",
                      marginRight: "10px",
                      cursor: "pointer",
                      display: "flex",
                    }}
                  >
                    <img
                      className="addBtnRow"
                      width={10}
                      src="/images/attack-surface/cross-icon.svg"
                      alt=""
                      onClick={(e) => {
                        setAddedOption("");
                        setAddMode(false);
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className={`f-darkgrey pt-1 cursor-pointer`}
                  style={{ width: "200px" }}
                  onClick={() => {
                    setAddMode(true);
                  }}
                >
                  Add option
                </div>
              )}
              {/* <div className={`f-darkgrey pt-1`}>Add option</div> */}
            </div>
          </div>
        )}
        {newQuestionType.QuestionTypeText === "Yes/No answer" && (
          <OptionsDropdown
            fieldId={"id"}
            fieldValue={"value"}
            // selected={row.AnswerText}
            placeholder="Choose"
            onClick={(id, val) => {
              // if (!isTemplate) {
              //   let newField =
              //     block.Question_List?.map((item) => {
              //       if (item === row) {
              //         item.AnswerText = val;
              //         item.QuestionStatusText =
              //           "Answered";
              //         item.QuestionStatus = 3;
              //         setData_to_send([item]);
              //         setSaveTemplateStatus(true);
              //       }
              //       return item;
              //     });
              //   let temp2 = AssessmentBlock.map(
              //     (q) => {
              //       if (q === block) {
              //         q.Question_List = newField;
              //       }
              //       return q;
              //     }
              //   );
              //   setAssessmentBlock(temp2);
              //   setProfilingBlocks(AssessmentBlock);
              // }
              // console.log(val);
            }}
            data={[
              { id: 0, value: "Yes" },
              { id: 1, value: "No" },
            ]}
          />
          // <div className="d-flex flex-column">
          //   <div className={`f-darkgrey `}>
          //     <input
          //       type="text"
          //       className="edit-task"
          //       value={"Yes"}
          //       disabled
          //       placeholder={"Type"}
          //     />
          //   </div>
          //   <div className={`f-darkgrey`}>
          //     <input
          //       type="text"
          //       className="edit-task "
          //       value={"No"}
          //       disabled
          //       placeholder={"Type"}
          //     />
          //   </div>
          // </div>
        )}
        {newQuestionType.QuestionTypeText === "Text input" && (
          <div className={`f-darkgrey w-100`}>
            <textarea
              type="text"
              rows={1}
              className="edit-task w-100"
              value={"Type"}
              disabled
              placeholder={"Type"}
            />
          </div>
        )}
      </div>
      <div
        className="message-block"
        // style={{ alignItems: "center" }}
      >
        <div
          className=""
          style={{
            padding: "4px",
            marginRight: "12px",
          }}
        >
          <OptionsDropdown
            fieldId={"id"}
            fieldValue={"value"}
            selected={
              newQuestionType?.QuestionTypeText
              // ? newQuestionType?.QuestionTypeText : "Choose"
            }
            placeholder="Choose"
            onClick={(id, val) => {
              setnewQuestionType({
                QuestionTypeText: val,
                QuestionTypeEnum: id,
              });
              setDataForAddQuestion([
                { id: 0, Text: "Option1" },
                { id: 1, Text: "Option2" },
                { id: 2, Text: "Option3" },
              ]);
            }}
            data={
              [
                {
                  id: 8,
                  value: "Multiple select",
                },
                {
                  id: 3,
                  value: "Single select",
                },
                {
                  id: 2,
                  value: "Yes/No answer",
                },
                { id: 0, value: "Text input" },
              ]
              //   [
              //   { id: 0, value: "Text" },
              //   { id: 2, value: "YesOrNo" },
              //   { id: 3, value: "DropdownList" },
              //   { id: 8, value: "MultiSelectDropdown" },
              // ]
            }
          />
        </div>
        <div
          style={{
            padding: "6px",
            marginRight: "10px",
          }}
        >
          <img
            width={14}
            src="/images/attack-surface/cross-icon.svg"
            alt=""
            onClick={(e) => {
              // let newAdd = addQuestion;
              // newAdd.obj.QuestionText =
              //   e.target.value;
              // setAddQuestion(newAdd);
              setDataForAddQuestion([
                { id: 0, Text: "Option1" },
                { id: 1, Text: "Option2" },
                { id: 2, Text: "Option3" },
              ]);
              setAddQuestion({});
              setnewQuestionType({});
            }}
          />
        </div>

        <Button
          style={{
            padding: "4px",
            borderRadius: "2px",
            background: " var(--primary-primary-50, #008FD4)",
            alignSelf: "flex-start",
          }}
          onClick={(e) => {
            if (addQuestion.QuestionText.trim() == "") {
              setErrorInput(true);
              let newAdd = { ...addQuestion };
              newAdd.QuestionText = "";
              setAddQuestion(newAdd);
            } else {
              setAddQuestion({});
              console.log("test adding1", dataForAddQuestion);
              saveNewQuestion(block);
            }
          }}
        >
          <img
            alt=""
            src="/images/settings-v2/white-tick-save.svg"
            style={{ padding: "2px" }}
          />
        </Button>
      </div>
    </div>
  );
};

export default AddQuestionRow;
