import React from "react";
import { Form } from "react-bootstrap";
import "./button.scss";

const SwitchButton = (props) => {
  return (
    <div className={`d-flex mt40px  ${props.labeltwo ? 'custom_switch_multi_toggle_button' : 'custom_switch_toggle_button'}`}>
      {props.labeltwo ? <p className="textBlack pr-2">{props.labeltwo}</p> : ""}
      <Form.Check
        type="switch"
        id={props?.id}
        name="subscription_plan"
        className="m-0 toogle-switch"
        value={props.value}
        onChange={(e) => props.onChange(!props.value)}
      />
      {props.label ? <p className="textBlack pr-2">{props.label}</p> : ""}
    </div>
  );
};

export default SwitchButton;
