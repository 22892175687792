import React, { useEffect, useState } from "react";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";

import "./supplycompany.scss";

const SupplyTechnicalDrilldown = ({ accessLevel, supplierId }) => {
  const { authTokens } = useAuth();
  const [drillDownList, setDrillDownList] = useState(dummyData);

  useEffect(() => {
    if (accessLevel > 0) {
      getPageData();
    }
  }, [accessLevel]);

  async function getPageData() {
    await getAPIData(`${415}`, authTokens, {
      optionEnum1: supplierId,
      optionEnum2: accessLevel,
    }).then((response) => {
      if (response?.mr?.Success) {
         setDrillDownList(response?.TDBoxList);
      }else{

        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }
  return (
    <div className="d-flex flex-wrap supply-integrations-wrapper p-3">
      <div className="m-0 w-100  d-flex flex-wrap">
        {[...drillDownList].map((drilldown, drildownIndex) => {
          return (
            <div
              xs={6}
              className=" hg-border radius-8 p-2 d-flex justify-content-between align-items-center pointe r "
              style={{
                height: "86px",
                width: "49%",
                marginRight: "1%",
                marginBottom: "1%",
              }}
            >
              <div className="d-flex flex-column ml-3 justify-content-around py-3 h-100">
                <div className="f-16 f-500">{drilldown?.TBoxTitle}</div>
                {/* <div className="d-flex align-items-center">
                  <img
                    alt=""
                    className="mr-2"
                    src="/images/supply-chain/connectwise-logo.svg"
                  />
                  <span className="f-black f-12 d-inline-flex">
                    Connectwise Automate
                  </span>
                </div> */}
              </div>
              <div className="d-flex h-100">
                {/* <div className=" d-flex align-items-end">
                  <div className="d-flex align-items-center pb-3 mr-4">
                    <span className="f-green">Synced</span>
                    <img
                      alt=""
                      src="/images/supply-chain/green-info.svg"
                      className="ml-2"
                    />
                  </div>
                </div> */}
                <div
                  className="bg-grey px-2 radius-4 d-flex align-items-center justify-content-center"
                  style={{
                    width: "164px",
                  }}
                >
                  <div className="d-flex align-items-center">
             { drilldown?.TStatus===0   &&      <img
                      alt=""
                      src={drilldown?.TBoxIcon}
                      className="mr-2"
                      width={15}
                      height={15}
                    />}
                    <div className="f-black f-14 f-500 text-center">
                      {  drilldown?.TStatus!==3   &&   drilldown?.TBoxMessage}
                      {drilldown?.TStatus===3 &&
                      <div>

Go to integrations <img alt="" className="ml-1" src='/images/chevron-right.svg'/>


                      </div>

                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SupplyTechnicalDrilldown;

const dummyData = [
  {
    TBoxTitle: "Patch Management",
    TBoxMessage: "No Alert - being returned by scoring",
    TBoxIcon:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/MoreInfo.svg",
    TboxSortOrder: 0,
    TCategory: 0,
    TStatus: 1,
    TStatus_Text: "Issues",
  },
  {
    TBoxTitle: "Endpoint Protection",
    TBoxMessage: "No Alert - being returned by scoring",
    TBoxIcon:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/MoreInfo.svg",
    TboxSortOrder: 1,
    TCategory: 0,
    TStatus: 1,
    TStatus_Text: "Issues",
  },
  {
    TBoxTitle: "Email Filtering",
    TBoxMessage: "No Alert - being returned by scoring",
    TBoxIcon:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/MoreInfo.svg",
    TboxSortOrder: 2,
    TCategory: 0,
    TStatus: 1,
    TStatus_Text: "Issues",
  },
  {
    TBoxTitle: "SIEM/IDS",
    TBoxMessage: "No Alert - being returned by scoring",
    TBoxIcon:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/MoreInfo.svg",
    TboxSortOrder: 3,
    TCategory: 1,
    TStatus: 1,
    TStatus_Text: "Issues",
  },
  {
    TBoxTitle: "Web Filtering",
    TBoxMessage: "No Alert - being returned by scoring",
    TBoxIcon:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/MoreInfo.svg",
    TboxSortOrder: 4,
    TCategory: 0,
    TStatus: 1,
    TStatus_Text: "Issues",
  },
  {
    TBoxTitle: "Dark Web Monitoring",
    TBoxMessage: "No Alert - being returned by scoring",
    TBoxIcon:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/MoreInfo.svg",
    TboxSortOrder: 5,
    TCategory: 1,
    TStatus: 1,
    TStatus_Text: "Issues",
  },
  {
    TBoxTitle: "Phishing Training",
    TBoxMessage: "No Alert - being returned by scoring",
    TBoxIcon:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/MoreInfo.svg",
    TboxSortOrder: 6,
    TCategory: 1,
    TStatus: 1,
    TStatus_Text: "Issues",
  },
  {
    TBoxTitle: "Incident Response",
    TBoxMessage: "No Alert - being returned by scoring",
    TBoxIcon:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/MoreInfo.svg",
    TboxSortOrder: 9,
    TCategory: 2,
    TStatus: 1,
    TStatus_Text: "Issues",
  },
];
