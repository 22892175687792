import React from "react";
import ContentLoader from "react-content-loader";

const TasksLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="63" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="126" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="189" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="252" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="315" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="378" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="441" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="504" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="567" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="630" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="693" rx="4" ry="4" width="98%" height="60" />

      <rect x="0" y="756" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="819" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="882" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="945" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="1008" rx="4" ry="4" width="98%" height="60" />
    </ContentLoader>
  );
};

export default TasksLoader;
