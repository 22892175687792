import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/Auth";
import {
  Button,
  Modal,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import "react-lazy-load-image-component/src/effects/blur.css";
import { getAPIData, postData } from "../../../Utils/commonApi";
import { Form, InputGroup, FormControl, Spinner } from "react-bootstrap";
 
import "../Dashboard.scss";

import "react-toastify/dist/ReactToastify.css";

import { TrackUser } from "../../../Utils/SegmentFunctions";

const DashboardModal = (props) => {
  const { authTokens } = useAuth();
  const [dropdownList, setDropdownList] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const [formState, setFormState] = useState(0);
  const [industry, setindustry] = useState("");
  const [qtyOfUsers, setQtyOfUsers] = useState("");
   
  const [integrationsInfo, setIntegrationsInfo] = useState([]);
  const [inputs, setInputs] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isRequestSend, setIsRequestSend] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(false);
  const [integrationsIcon, setIntegrationsIcons] = useState([]);
  const [filter, setFilter] = useState("");
   const [defaultDropdown, setDefaultDropdown] = useState([]);

  const message = "All fields required";

  useEffect(() => {
    getDropdownList();
    getIntegrationList();
    getIntegrationIcons();
  }, []);

  async function getDropdownList() {
    const dropdownLists = await getAPIData("141", authTokens, {
      // OptionEnum1: "",
      // OptionEnum2: "",
    });
    setDropdownList(dropdownLists.DDLMembers);
    setDefaultDropdown(dropdownLists);
  }
  async function getIntegrationList() {
    const IntegrationLists = await getAPIData("145", authTokens);
    setIntegrationsInfo(IntegrationLists);
    setInputs({ TechnologyEnum: IntegrationLists.TechnologyEnum });
  }

  async function getIntegrationIcons() {
    const Integration = await getAPIData("143", authTokens);
    // setTimeout(() => {
    //   setIntegrationsIcons(Integration.IntegrationList);
    // }, 8000);
    setIntegrationsIcons(Integration.IntegrationList);
  }

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleCloaseModal = () => {
    // setdbObject({ ...dbObj, PostLoadAction: 0 });
    setShowModal(false);
  };

  async function onChangeIndustry(event) {
    setindustry(event.target.value);
    const result = await getAPIData("142", authTokens, {
      OptionEnum1: event.target.value,
      OptionEnum2: event.target.name,
    });
  }

  async function onChangeQtyOfUsers(event) {
    setQtyOfUsers(event.target.value);
    const result = await getAPIData("142", authTokens, {
      OptionEnum1: event.target.value,
      OptionEnum2: event.target.name,
    });
  }

  const renderTooltip = (message) => (
    <Tooltip className="custom_tooltip">
      <div className="custom_tooltip_wrapper">
        <div className="tooltip_header border-bottom-0">
          <span className="tooltip_subtitle" style={{ color: "#F36060" }}>
            {message}
          </span>
        </div>
      </div>
    </Tooltip>
  );
  async function addIntegrationHandler() {
    setIsSubmit(true);
    const { is_error } = handleValidations(true);
    if (is_error) return;
    setIsRequestSend(true);

    const response = await postData(authTokens, "SuggestIntegration", inputs);
    if (response.ExThrown == false) {
      setSubmitResponse(response.Message);
      setFormState(formState + 1);
      setIsSubmit(false);
    }
    setIsRequestSend(false);
  }
  const handleValidations = (submit) => {
    const {
      IntegrationName,
      IntegrationURL,
      TechnologyEnum,
      IntegrationComment,
    } = inputs;
    let error_structure = {
      eIntegrationName: false,
      eIntegrationURL: false,
      eTechnologyEnum: false,
      // eIntegrationComment: false,
    };
    if (!submit) return error_structure;
    if (!IntegrationName) {
      error_structure.eIntegrationName = true;
    }
    if (!IntegrationURL) {
      error_structure.eIntegrationURL = true;
    }
    if (TechnologyEnum <= -1) {
      error_structure.eTechnologyEnum = true;
    }
    // if (!IntegrationComment) {
    //   error_structure.eIntegrationComment = true;
    // }
    const {
      eIntegrationName,
      eIntegrationURL,
      eTechnologyEnum,
      // eIntegrationComment,
    } = error_structure;

    if (
      eIntegrationName ||
      eIntegrationURL ||
      eTechnologyEnum
      // eIntegrationComment
    ) {
      error_structure.is_error = true;
    }
    return error_structure;
  };

  const {
    eIntegrationName,
    eTechnologyEnum,
    eIntegrationURL,
    // eIntegrationComment,
  } = handleValidations(isSubmit);

  async function onCheckHandler(id) {
    // event.currentTarget.childNodes[1].classList.toggle("d-none");
    // event.currentTarget.childNodes[2].classList.toggle("activeList");

    integrationsIcon.map((data) => {
      if (data.IntegrationId == id) {
        if (!data.checked) {
          data.checked = true;
        } else {
          data.checked = false;
        }
      }
    });
    setIntegrationsIcons([...integrationsIcon]);
    const response = await getAPIData("144", authTokens, {
      OptionEnum1: id,
    });
  }

  const onHandler = () => {
    // window.open("https://highgroundio.slack.com/", "_blank"); //to open new page
    TrackUser("Join Slack community clicked");
    window.open(
      "https://join.slack.com/t/highgroundio/shared_invite/zt-19tfj8ujn-_rHexr3mFsH~aI~q7Y47Sg",
      "_blank"
    ); //to open Slack community page
  };

  async function securityToolsHandler() {
    const response = await getAPIData("148", authTokens);
    if (response.Success == true) {
      window.localStorage.setItem(
        "user_MessageObj",
        JSON.stringify(response.Message)
      );
    }
    setFormState(4);
  }
  function HideComponent() {
    return (
      <div className="d-none">
        {integrationsIcon &&
          integrationsIcon.map((integration, index) => (
            <img
              key={index}
              alt=""
              width="20"
              height="20"
              src={`/images/TechnologyType/${integration.IntegrationFileName}.svg`}
              className=""
            />
          ))}
      </div>
    );
  }

  return (
    <Modal
      show={showModal}
      dialogClassName="modal-50w"
      className="integrationModal centerModal dashboardModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header className="text-center ">
        {formState == 2 || (formState == 3 && formState !== 0) ? (
          <span
            className="cursor-pointer"
            onClick={() => setFormState(formState - 1)}
          >
            <img src="/images/icons/arrowLeft.svg" alt="" />
          </span>
        ) : (
          ""
        )}
        <h1 className="w-100 mb-0 mt-0">
          {formState == 1
            ? "Security Tools"
            : formState == 2 || formState == 3
            ? "Suggest an Integration"
            : formState == 4
            ? "Join our Community"
            : "Company Details"}
        </h1>
        {/* <span className="cursor-pointer" onClick={() => setShowModal(false)}>
          <img src="/images/icons/close.svg" alt="" />
        </span> */}
      </Modal.Header>
      <Modal.Body>
        {formState == 1 && integrationsIcon ? (
          <div>
            <h1 className="mt-3">What Tools do you use?</h1>
            <div className="integrationToolSearch">
              <InputGroup
                className="mt-16px grey-input mb8px"
                // style={{ width: "400px" }}
              >
                <InputGroup.Prepend>
                  <InputGroup.Text className="greyText">
                    <img alt=""
                      src="/images/mag-icon.svg"
                      style={{ display: "inline-block", paddingRight: "1em" }}
                    />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder="Search..."
                  type="text"
                  name="IntegrationName"
                  onChange={(e) => setFilter(e.target.value)}
                />
              </InputGroup>
              <Row className="mt-4 mb-4">
                <Col md={2}>
                  <div
                    className="addIntegration activeIntegration"
                    onClick={() => setFormState(2)}
                  >
                    <div className="suggest  pr-2 pl-2">
                      <span className="">+</span>
                    </div>
                    <p style={{ textAlign: "center" }}>Suggest Integration</p>
                  </div>
                </Col>

                {integrationsIcon &&
                  integrationsIcon

                    .filter((integration) => {
                      return (
                        integration.IntegrationAvailability == 0 &&
                        integration.IntegrationName.toLowerCase().includes(
                          filter.toLowerCase()
                        )
                      );
                    })
                    .map((integration, index) => (
                      <Col md={2} className="mb-4 pr-2 pl-2 cursor-pointer">
                        {integration.IntegrationFileName && (
                          <div
                            className={`integrationTools ${
                              integration.checked == true
                                ? "activeIntegration"
                                : ""
                            }`}
                            onClick={() =>
                              onCheckHandler(integration.IntegrationId)
                            }
                          >
                            <img
                              alt=""
                              width="100%"
                              src={`/images/TechnologyType/${integration.IntegrationFileName}.svg`}
                              className="mainImg modalIcons"
                            />
                            {/* <LazyLoadImage
                              // alt={image.alt}
                              effect="blur"
                              style={{ width: "82px", height: "84px" }}
                              className="mainImg modalIcons"
                              src={`/images/TechnologyType/${integration.IntegrationFileName}.svg`}
                            /> */}

                            <div
                              className={`overlayImage position-relative ${
                                integration.checked ? "d-block" : "d-none"
                              }`}
                            >
                              <div className="overlay"></div>
                              <img
                                className="activeIcon"
                                src="/images/TechnologyType/tickIcon.svg"
                              />
                            </div>
                            <p
                              className=""
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {integration.IntegrationName}
                            </p>
                          </div>
                        )}
                      </Col>
                    ))}
              </Row>
            </div>
          </div>
        ) : formState == 2 ? (
          <div className="suggestIntegrationForm">
            <img className="mainImg" src="" alt="" />
            <h1>Can't find the tool you are using?</h1>
            <span className="linkGreyText">
              If you can't find the tools you are using then you can use this
              page to suggest one, or visit our Integrations page to Upvote our
              Upcoming Integrations
            </span>
            <Form>
              <InputGroup
                className="mt-16px grey-input mb8px"
                style={{ width: "400px" }}
              >
                <InputGroup.Prepend>
                  <InputGroup.Text className="greyText">
                    Tool Name:
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  type="text"
                  name="IntegrationName"
                  onChange={(e) => handleInputChange(e)}
                />
              </InputGroup>

              <InputGroup
                className="mt-16px grey-input mb8px"
                style={{ width: "400px" }}
              >
                <InputGroup.Prepend>
                  <InputGroup.Text className="greyText">
                    Website:
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  type="text"
                  name="IntegrationURL"
                  onChange={(e) => handleInputChange(e)}
                />
              </InputGroup>
              <InputGroup
                className="mt-16px grey-select"
                style={{ width: "400px" }}
              >
                <InputGroup.Prepend>
                  <InputGroup.Text className="greyText">
                    Type of Tool:
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  as="select"
                  name="TechnologyEnum"
                  // defaultValue={integrationsInfo.TechnologyEnum}
                  // value={integrationsInfo.TechnologyEnum}
                  onChange={(e) => handleInputChange(e)}
                >
                  {integrationsInfo &&
                    integrationsInfo.DDLValues &&
                    integrationsInfo.DDLValues.map((option) => (
                      <option value={option.Value}>{option.Text}</option>
                    ))}
                </Form.Control>
              </InputGroup>
              {(eIntegrationName || eIntegrationURL) && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 200, hide: 250 }}
                    overlay={renderTooltip(message)}
                  >
                    <span>
                      <img src="/images/icons/error.svg" /> Attempt failed
                    </span>
                  </OverlayTrigger>
                </Form.Control.Feedback>
              )}
              {/* <InputGroup>
              <Form.Group className="mt-16px">
                <Form.Control
                  name="IntegrationComment"
                  placeholder="Comment"
                  as="textarea"
                  rows={5}
                  onChange={(e) => handleInputChange(e)}
                  className={"border-danger"}
                />
              </Form.Group>
            </InputGroup> */}
            </Form>
          </div>
        ) : formState == 3 ? (
          <div className="thanksModal">
            {" "}
            <img src="/images/icons/checkIcon.svg" />
            <h1>Thanks</h1>
            <span className="">
              Thank you for your suggestion, it will help us to continue
              building integrations our customers want.
            </span>
            <div
              className="light-blue-btn mt-2"
              style={{ margin: "0 29px" }}
              onClick={() => setFormState(2)}
            >
              Suggest another integration
              <img
                src="https://cybercareimages.blob.core.windows.net/icons/PlusSign.svg"
                style={{ paddingLeft: "10px" }}
              />
            </div>
          </div>
        ) : formState == 4 ? (
          <div className="communityForm">
            {" "}
            <img src="/images/slackLogo.svg" />
            <h1 className="mt-5">Join our slack Community</h1>
            <span className="linkGreyText">
              Discuss cyber security and IT challenges with your peers in our
              role-specific slack channels, get answers to your questions,
              suggest product improvements and much more by joining our slack
              community.
            </span>
            <div
              className="mt-4 slackButton"
              // style={{ margin: "0 29px" }}
              onClick={() => {
                onHandler();
              }}
            >
              Join our Slack Community
            </div>
          </div>
        ) : (
          <>
            <div className="companyDetailsForm">
              <img className="mainImg" src="" alt="" />
              <h1>Tell us more about your company</h1>
              <span className="linkGreyText">
                So that we can make data and functionality in CyberCare Vision
                more suitable to your business, please provide us with some
                additional details.
              </span>

              <InputGroup
                className="mt-16px grey-select customerStyle"
                style={{ width: "400px" }}
              >
                <InputGroup.Prepend>
                  <InputGroup.Text className="greyText">
                    Industry:
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  as="select"
                  value={
                    industry ||
                    (defaultDropdown && defaultDropdown.DDL1_CurrentValue)
                  }
                  name={
                    dropdownList && dropdownList[0] && dropdownList[0].DDLIdent
                  }
                  onChange={(e) => onChangeIndustry(e)}
                >
                  {/* <option hidden>Please Select</option> */}
                  {dropdownList &&
                    dropdownList[0] &&
                    dropdownList[0].DDLMemberList.map((list) => (
                      <option title={list.DDLText} value={list.DDLId}>
                        {list.DDLText.slice(0, 50) +
                          (list.DDLText.length > 50 ? "..." : "")}
                      </option>
                    ))}
                </Form.Control>
              </InputGroup>
              <InputGroup
                className="mt-16px grey-select"
                style={{ width: "400px" }}
              >
                <InputGroup.Prepend>
                  <InputGroup.Text className="greyText">
                    Qty of Users:
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  as="select"
                  name={
                    dropdownList && dropdownList[1] && dropdownList[1].DDLIdent
                  }
                  value={
                    qtyOfUsers ||
                    (defaultDropdown && defaultDropdown.DDL2_CurrentValue)
                  }
                  onChange={(e) => onChangeQtyOfUsers(e)}
                >
                  {/* <option style={{ fontFamily: "Archivo" }} hidden>
                  Please Select
                </option> */}

                  {dropdownList &&
                    dropdownList[1] &&
                    dropdownList[1].DDLMemberList.map((list) => (
                      <option value={list.DDLId}>{list.DDLText}</option>
                    ))}
                </Form.Control>
              </InputGroup>
            </div>
            {<HideComponent />}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Row className="m-0" style={{ justifyContent: "space-between" }}>
          <Col md={4} className="p-0">
            {(formState == 1 || formState == 4) && (
              <p
                className="cursor-pointer pt-3"
                onClick={() => {
                  formState == 1 ? setFormState(0) : setFormState(1);
                }}
              >
                Back
              </p>
            )}
            {formState == 2 && (
              <p
                className="cursor-pointer pt-3"
                onClick={() => setFormState(1)}
              >
                Cancel
              </p>
            )}
          </Col>
          {formState == 0 || formState == 1 || formState == 4 ? (
            <Col md={4} className="p-0">
              <p className="text-center pt-3">
                {((formState == 0 && "1") ||
                  (formState == 1 && "2") ||
                  (formState == 4 && "3")) +
                  "/" +
                  3}
              </p>
            </Col>
          ) : (
            ""
          )}

          <Col md={4} className="p-0">
            {formState == 2 ? (
              <Button
                variant="primary"
                type="submit"
                className="float-right"
                onClick={() => {
                  addIntegrationHandler();
                }}
              >
                {isRequestSend ? (
                  <span>
                    <Spinner animation="border" variant="light" size="sm" />{" "}
                    Submitting
                  </span>
                ) : (
                  "Suggest tool"
                )}
              </Button>
            ) : formState == 3 ? (
              <Button
                variant="primary"
                className="float-right"
                onClick={() => {
                  setFormState(1);
                }}
              >
                Close
              </Button>
            ) : formState == 0 ? (
              <Button
                variant="primary"
                type="submit"
                className={`float-right ${
                  (industry == "" || qtyOfUsers == "") && !defaultDropdown
                    ? "disableNextButton"
                    : ""
                }`}
                onClick={() => {
                  setFormState(formState + 1);
                }}
              >
                Next
              </Button>
            ) : formState == 4 ? (
              <Button
                variant="primary"
                type="submit"
                className="float-right"
                onClick={() => {
                  handleCloaseModal();
                }}
              >
                Lets Go!
              </Button>
            ) : (
              <Button
                variant="primary"
                type="submit"
                className="float-right"
                onClick={() => {
                  securityToolsHandler();
                }}
              >
                Next
              </Button>
            )}
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default DashboardModal;
