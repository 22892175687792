import React, { useRef, useState } from "react";
import "./supplier-profiler.scss";
import { useEffect } from "react";
import OptionsDropdown from "./ProfilerDropdowns/OptionsDropdown";
import ChatDropdown from "./ProfilerDropdowns/ChatDropdown";
import RevokeContributorsDropdown from "./ProfilerDropdowns/RevokeContributorsDropdown";
import { Dropdown } from "react-bootstrap";
import MultiSelectDropdown from "./ProfilerDropdowns/MultiSelectDropdown";
import { viewOnlyAccess } from "../App/App";
import { ContributorGetAPIData, getAPIData } from "../../Utils/commonApi";
import { useAuth } from "../context/Auth";

const AssessmentRow = ({
  row,
  block,
  AssessmentBlock,
  setAssessmentBlock,
  setProfilingBlocks,
  setSaveTemplateStatus,
  questionId,
  refreshData,
  setData_to_send,
  data,
  currentWord,
  setCurrentWord,
  AllProfilingObj,
  deleteQuestion,
  editMode,
  setEditMode,
  createUpdateSection,
  isTemplate,
  isTemplateEdit,
  rawBlocks,
  setShowDeleteModal,
  isContributorView,
  isSent,
  mentionList,
  updateRawData,
}) => {
  const { authTokens } = useAuth();
  const [currentRawBlock, setCurrentRawBlock] = useState({});
  const [errorInput, setErrorInput] = useState(false);
  const textareaRef = useRef();
  useEffect(() => {
    if (rawBlocks) {
      let currBlock = rawBlocks?.find((obj) => obj.ObjectId == block.ObjectId);
      // console.log(rawBlocks, currBlock, "check curr blick");
      // updateRawData().then((temp) => {
      //   // console.log(temp[7])
      //   let currBlock = temp?.find(
      //     (obj) => obj.ObjectId == block.ObjectId
      //   );
      setCurrentRawBlock(currBlock);
      // });
    }
  }, [rawBlocks]);

  useEffect(() => {
    if (row.AnswerText) {
      if (row.AnswerText.trim() === "") {
        setErrorInput(true);
      }
    }
  }, [row.AnswerText]);

  // useEffect(() => {
  //   getQuestionContributors();
  // }, [row]);
  function autoExpand(e) {
    e.style.height = "auto"; // Reset the height to auto to calculate the new height
    e.style.height = e.scrollHeight + "px"; // Set the height to the scrollHeight
  }
  async function getQuestionContributors() {
    if (isContributorView) {
      await ContributorGetAPIData(780, authTokens, {
        optionEnum1: questionId,
        optionEnum2: row.ObjectId,
      }).then((response) => {
        if (response) {
          let newField = block.Question_List?.map((item) => {
            if (item === row) {
              item.ActivityTrackingContributorVM = {};
              item.ActivityTrackingContributorVM.ActivityTrackingContributor_List =
                response.ActivityTrackingContributor_List;
            }
            return item;
          });
          let temp2 = AssessmentBlock.map((q) => {
            if (q === block) {
              q.Question_List = newField;
            }
            return q;
          });
          setAssessmentBlock(temp2);
          console.log("assesm block 5 -------", AssessmentBlock, temp2);

          setProfilingBlocks(AssessmentBlock);
          // setAllProfilingObj((prevState) => ({
          //   ...prevState,
          //   ActivityTrackingContributorVM: {
          //     ...prevState.ActivityTrackingContributorVM,
          //     ActivityTrackingContributor_List:
          //       response.ActivityTrackingContributor_List,
          //   },
          // }));
        } else {
        }
      });
    } else {
      await getAPIData(780, authTokens, {
        optionEnum1: questionId,
        optionEnum2: row.ObjectId,
      }).then((response) => {
        if (response) {
          let newField = block.Question_List?.map((item) => {
            if (item === row) {
              item.ActivityTrackingContributorVM = {};
              item.ActivityTrackingContributorVM.ActivityTrackingContributor_List =
                response.ActivityTrackingContributor_List;
            }
            return item;
          });
          let temp2 = AssessmentBlock.map((q) => {
            if (q === block) {
              q.Question_List = newField;
            }
            return q;
          });
          // setAllProfilingObj((prevState) => ({
          //   ...prevState,
          //   ActivityTrackingContributorVM: {
          //     ...prevState.ActivityTrackingContributorVM,
          //     ActivityTrackingContributor_List:
          //       response.ActivityTrackingContributor_List,
          //   },
          // }));
        } else {
        }
      });
    }
  }
  return (
    <div className="flex-row item-question w-100">
      {/* <div
        className={` ${editMode == row.QuestionId ? "edit-title" : ""} f-grey`}
      >
        {row.QuestionText}
      </div> */}
      {setEditMode ? (
        <div
          className="f-grey d-flex"
          style={{
            pointerEvents: viewOnlyAccess("AccessState_SCM") ? "none" : "all",
          }}
        >
          <textarea
            type="text"
            className={` edit-task ${
              editMode == row.QuestionId ? "edit-title" : ""
            }`}
            disabled={editMode == row.QuestionId ? false : true}
            rows={
              row.QuestionText ? Math.ceil(row.QuestionText.length / 70) : 1
            }
            style={{ width: "200px" }}
            value={row?.QuestionText}
            placeholder={"Question"}
            onBlur={(e) => {
              if (editMode == row.QuestionId) {
                updateRawData().then((temp) => {
                  // console.log(temp[7])
                  let currBlock = temp?.find(
                    (obj) => obj.ObjectId == block.ObjectId
                  );
                  setCurrentRawBlock(currBlock);

                  let newField = currBlock.Question_List?.map((item) => {
                    if (item.QuestionId === row.QuestionId) {
                      if (
                        (item.QuestionText == null &&
                          row.QuestionText?.trim() == "") ||
                        (item.QuestionText !== null &&
                          item.QuestionText.trim() == row.QuestionText?.trim())
                      ) {
                        console.log("check 1");
                      } else {
                        console.log("check 2");
                        item.QuestionText = row.QuestionText;
                        item.QuestionStatusText = "Answered";
                        item.QuestionStatus = 3;
                        setData_to_send([item]);
                        setSaveTemplateStatus(true);
                        createUpdateSection(block);
                      }

                      // item.QuestionText = e.target.value;
                      // item.QuestionStatusText = "Answered";
                      // item.QuestionStatus = 3;
                      // setData_to_send([item]);
                      // setSaveTemplateStatus(true);
                      // createUpdateSection(block);
                    }
                    return item;
                  });
                  setEditMode("");
                });
              }
            }}
            onChange={(e) => {
              if (editMode == row.QuestionId) {
                let newField = block.Question_List?.map((item) => {
                  if (item === row) {
                    item.QuestionText = e.target.value;
                  }
                  return item;
                });
                let temp2 = AssessmentBlock.map((q) => {
                  if (q === block) {
                    q.Question_List = newField;
                  }
                  return q;
                });
                setAssessmentBlock(temp2);
                console.log("assesm block 6 -------", AssessmentBlock, temp2);

                setProfilingBlocks(AssessmentBlock);
              }
            }}
          />
        </div>
      ) : (
        <div
          className={`f-grey`}
          style={{
            width: "200px",
            pointerEvents: viewOnlyAccess("AccessState_SCM") ? "none" : "all",
          }}
        >
          <textarea
            type="text"
            disabled={editMode == row.QuestionId ?? false}
            className={`edit-task`}
            rows={
              row.QuestionText ? Math.ceil(row.QuestionText.length / 25) : 1
            }
            // style={{ width: "200px" }}
            value={row?.QuestionText}
            placeholder={"Question"}
          />
          {/* {row.QuestionText} */}
        </div>
      )}

      {row.QuestionTypeText === "DropdownList" && (
        <div
          className="pl-2"
          style={{
            pointerEvents: viewOnlyAccess("AccessState_SCM") ? "none" : "all",
          }}
        >
          <OptionsDropdown
            fieldId={"id"}
            fieldValue={"value"}
            selected={row.AnswerText}
            placeholder="Choose"
            onClick={(id, val) => {
              console.log(val);
              if (!isTemplate) {
                let newField = block.Question_List?.map((item) => {
                  if (item === row) {
                    item.AnswerText = val;
                    item.QuestionStatusText = "Answered";
                    item.QuestionStatus = 3;
                    setData_to_send([item]);
                    setSaveTemplateStatus(true);
                  }
                  return item;
                });
                let temp2 = AssessmentBlock.map((q) => {
                  if (q === block) {
                    q.Question_List = newField;
                  }
                  return q;
                });
                setAssessmentBlock(temp2);
                console.log("assesm block 7 -------", AssessmentBlock, temp2);

                setProfilingBlocks(AssessmentBlock);
              }
            }}
            data={data}
          />
        </div>
      )}
      {row.QuestionTypeText === "MultiSelectDropdown" && (
        <div
          className={`${row.AnswerText ? "" : "f-darkgrey"} w-60`}
          style={{
            pointerEvents: viewOnlyAccess("AccessState_SCM") ? "none" : "all",
          }}
        >
          <div
            className="d-flex flex-row align-items-center w-100"
            style={{
              gap: "4px",
              flexWrap: "wrap",
            }}
          >
            {/* {row.AnswerArrayText?.map((op) => {
              return <div className="chip-item">{op}</div>;
            })} */}

            <MultiSelectDropdown
              // locked={viewOnlyAccess("AccessState_SCM") ? "true" : "false"}
              locked={isTemplate}
              placeholder={"Add"}
              data={() => {
                let newdata = [];
                let updated = data?.map((op, i) => {
                  newdata.push({ id: i, Text: op.value });
                });

                return newdata;
              }}
              inputDisable={false}
              selected={() => {
                let newdata = [];
                let updated = row.AnswerArrayText?.map((op, i) => {
                  newdata.push({ id: i, Text: op });
                });

                return newdata;
              }}
              row={row}
              currentWord={currentWord}
              isTemplate={isTemplate}
              setCurrentWord={setCurrentWord}
              block={block}
              setData_to_send={setData_to_send}
              setSaveTemplateStatus={setSaveTemplateStatus}
              AssessmentBlock={AssessmentBlock}
              setAssessmentBlock={setAssessmentBlock}
              setProfilingBlocks={setProfilingBlocks}
              isContributorView={isContributorView}
              isSent={isSent}
            />

            {/* <input
              type="text"
              className="edit-task"
              value={
                row.QuestionText === currentWord.id ? currentWord.word : ""
              }
              placeholder={"Add"}
              onChange={(e) => {
                if (!isTemplate) {
                  setCurrentWord({
                    word: e.target.value,
                    id: row.QuestionText,
                  });
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && currentWord.word.trim() !== "") {
                  e.preventDefault();
                  setCurrentWord({
                    word: "",
                    id: 0,
                  });
                  let newField = block.Question_List?.map((item) => {
                    if (item === row) {
                      console.log(currentWord);
                      if (
                        !item.AnswerArrayText ||
                        item.AnswerArrayText?.length < 0
                      ) {
                        item.AnswerArrayText = [];
                      }
                      item.AnswerArrayText.push(currentWord.word.trim());
                      item.QuestionStatusText = "Answered";
                      item.QuestionStatus = 3;
                      item.QuestionTypeEnum = 18;
                      setData_to_send([item]);
                      setSaveTemplateStatus(true);
                    }
                    return item;
                  });
                  console.log(newField);
                  let temp2 = AssessmentBlock.map((q) => {
                    if (q === block) {
                      q.Question_List = newField;
                    }
                    return q;
                  });
                  setAssessmentBlock(temp2);
                  setProfilingBlocks(AssessmentBlock);
                }
              }}
            /> */}
          </div>
        </div>
      )}
      {row.QuestionTypeText === "YesOrNo" && (
        <div
          className="pl-2"
          style={{
            pointerEvents: viewOnlyAccess("AccessState_SCM") ? "none" : "all",
          }}
        >
          <OptionsDropdown
            fieldId={"id"}
            fieldValue={"value"}
            selected={row.AnswerText}
            placeholder="Choose"
            onClick={(id, val) => {
              if (!isTemplate) {
                let newField = block.Question_List?.map((item) => {
                  if (item === row) {
                    item.AnswerText = val;
                    item.QuestionStatusText = "Answered";
                    item.QuestionStatus = 3;
                    setData_to_send([item]);
                    setSaveTemplateStatus(true);
                  }
                  return item;
                });
                let temp2 = AssessmentBlock.map((q) => {
                  if (q === block) {
                    q.Question_List = newField;
                  }
                  return q;
                });
                setAssessmentBlock(temp2);
                console.log("assesm block 11 -------", AssessmentBlock, temp2);

                setProfilingBlocks(AssessmentBlock);
              }
              console.log(val);
            }}
            data={[
              { id: 0, value: "Yes" },
              { id: 1, value: "No" },
            ]}
          />
        </div>
      )}
      {row.QuestionTypeText === "Text" && (
        <div
          className={`${row.AnswerText ? "" : "f-darkgrey"} ${
            errorInput ? "input-error" : ""
          } w-60   text-input`}
          style={{
            pointerEvents: viewOnlyAccess("AccessState_SCM") ? "none" : "all",
          }}
        >
          <textarea
            ref={textareaRef}
            type="text"
            rows={row.AnswerText ? Math.ceil(row.AnswerText.length / 45) : 1}
            // rows={1}
            className={`pl-2 edit-task w-100 hide-scrollbar `}
            value={row.AnswerText || ""}
            placeholder={"Type"}
            disabled={isTemplate ? true : false}
            onBlur={(e) => {
              setErrorInput(false);
              if (row.AnswerText?.trim() === "") {
                let newField = block.Question_List?.map((item) => {
                  if (item === row) {
                    item.AnswerText = row.AnswerText?.trim();
                  }
                  return item;
                });
                let temp2 = AssessmentBlock.map((q) => {
                  if (q === block) {
                    q.Question_List = newField;
                  }
                  return q;
                });
                setAssessmentBlock(temp2);
                console.log("assesm block 12 -------", AssessmentBlock, temp2);

                // setProfilingBlocks(temp2);
              }
              if (!isTemplate) {
                updateRawData().then((temp) => {
                  // console.log(temp[7])
                  let currBlock = temp?.find(
                    (obj) => obj.ObjectId == block.ObjectId
                  );
                  setCurrentRawBlock(currBlock);

                  let newField = currBlock?.Question_List?.map((item) => {
                    if (item.QuestionId === row.QuestionId) {
                      if (
                        (item.AnswerText == null &&
                          row.AnswerText?.trim() == "") ||
                        (item.AnswerText !== null &&
                          item.AnswerText.trim() == row.AnswerText?.trim())
                      ) {
                        console.log("check 1");
                      } else {
                        console.log("check 2");
                        item.AnswerText = row.AnswerText;
                        item.QuestionStatusText = "Answered";
                        item.QuestionStatus = 3;
                        setData_to_send([item]);
                        setSaveTemplateStatus(true);
                      }
                    }
                  });
                });
              }
            }}
            onChange={(e) => {
              const textarea = textareaRef.current;
              textarea.style.height = "auto"; // Reset the height to auto
              textarea.style.height = textarea.scrollHeight + "px"; // Set the height to the scrollHeight
              if (!isTemplate) {
                let newField = block.Question_List?.map((item) => {
                  if (item === row) {
                    item.AnswerText = e.target.value;
                  }
                  return item;
                });
                let temp2 = AssessmentBlock.map((q) => {
                  if (q === block) {
                    q.Question_List = newField;
                  }
                  return q;
                });
                setAssessmentBlock(temp2);
                console.log("assesm block 13 -------", AssessmentBlock, temp2);

                // setProfilingBlocks(temp2);
              }
            }}
          />
        </div>
      )}
      <div className="message-block">
        {!isTemplate || isTemplateEdit ? (
          <>
            {viewOnlyAccess("AccessState_SCM") ? (
              <></>
            ) : (
              <>
                {!isContributorView && isSent && deleteQuestion && (
                  <div
                    className="hover-visible"
                    style={{
                      alignSelf: "center",
                      pointerEvents: viewOnlyAccess("AccessState_SCM")
                        ? "none"
                        : "all",
                    }}
                  >
                    <Dropdown
                      className="hg-white-dropdown user-detail-dropdown supplier-menu"
                      onToggle={() => {}}
                    >
                      <Dropdown.Toggle className=" d-flex custom-dropdown-toggle f-gray-imp p-0">
                        <img
                          src={"/images/supply-chain/base-more-dots.svg"}
                          // className="mr-2 ml-2"
                          alt=""
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div
                          className="p-2 pl-3 my-1 pointer f-darkgrey"
                          onClick={(e) => {
                            setEditMode(row.QuestionId);
                            // setRenameTitle(block.Title);
                            // setShowAccessLevelModal(true);
                            // e.target.closest(".hg-white-dropdown").click();
                          }}
                        >
                          <div>Edit</div>
                        </div>

                        <div
                          className="p-2 pl-3 my-1 pointer f-danger"
                          onClick={(e) => {
                            setShowDeleteModal({
                              showModal: true,
                              deleteMessage:
                                "You are about to delete a question.",
                              deleteSentQuestionnaire: () =>
                                deleteQuestion(row.QuestionId),
                            });
                            // deleteQuestion(row.QuestionId);
                            // deleteSection(block.ObjectId);
                            // let tempArr = [];
                            // AssessmentBlock.map((i) => {
                            //   if (block.ObjectId === i.ObjectId) {
                            //     console.log("deleted ", block.Title);
                            //   } else {
                            //     tempArr.push(i);
                            //   }
                            // });
                            // setProfilingBlocks(tempArr);
                            // handleDeleteUser(memb.objId_UserSetting);
                            // e.target.closest(".hg-white-dropdown").click();
                          }}
                        >
                          Delete
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
              </>
            )}

            {!isTemplateEdit && (
              <>
                {viewOnlyAccess("AccessState_SCM") ? (
                  <></>
                ) : (
                  <>
                    {row.ResponseComment_List?.length > 0 ? (
                      <div
                        className="question-chat-dropdown mx-2"
                        style={{
                          pointerEvents: viewOnlyAccess("AccessState_SCM")
                            ? "none"
                            : "all",
                        }}
                      >
                        <ChatDropdown
                          question={row}
                          refreshData={(updateBlock) => {
                            setAssessmentBlock(updateBlock);
                            setProfilingBlocks(updateBlock);
                          }}
                          questionaireId={questionId}
                          AllProfilingObj={AllProfilingObj}
                          blockId={block.ObjectId}
                          mentionList={mentionList}
                          isContributorView={isContributorView}
                          // sectionList={block.Question_List}
                        />
                      </div>
                    ) : (
                      <div
                        className="question-chat-dropdown mx-2 hover-visible"
                        style={{
                          pointerEvents: viewOnlyAccess("AccessState_SCM")
                            ? "none"
                            : "all",
                        }}
                      >
                        <ChatDropdown
                          question={row}
                          refreshData={(updateBlock) => {
                            setAssessmentBlock(updateBlock);
                            setProfilingBlocks(updateBlock);
                          }}
                          questionaireId={questionId}
                          AllProfilingObj={AllProfilingObj}
                          blockId={block.ObjectId}
                          mentionList={mentionList}
                          isContributorView={isContributorView}
                          // sectionList={block.Question_List}
                        />{" "}
                      </div>
                    )}
                  </>
                )}

                {row.ActivityTrackingContributorVM
                  ?.ActivityTrackingContributor_List?.length > 0 && (
                  <div className="rounded-full w-fit-content h-fit-content position-relative overlap_child">
                    <RevokeContributorsDropdown
                      // getQuestionContributors={getQuestionContributors}
                      questionnaireId={questionId}
                      questionId={row.QuestionId}
                      lastEdited={{
                        LastEditedContributorLogo:
                          row?.ActivityTrackingContributorVM
                            ?.LastEditedContributorLogo,
                        LastEditedContributorName:
                          row?.ActivityTrackingContributorVM
                            ?.LastEditedContributorName,
                      }}
                      revokers={
                        row?.ActivityTrackingContributorVM
                          ?.ActivityTrackingContributor_List
                      }
                      countFlag={true}
                      refreshData={refreshData}
                      logo={true}
                    />
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default AssessmentRow;
