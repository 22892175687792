import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Form, Modal, Nav, Tab } from "react-bootstrap";
import { getAPIData } from "../../Utils/commonApi";
import { useAuth } from "../context/Auth";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import { Loader } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";



const ImportSuppliersModal = ({
    show,
    hideModal,
    complianceProfileId,
}) => {

    const { authTokens } = useAuth();
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    const [cyberScoreData, setCyberScoreData] = useState({
        // ObjectId: 0,
        // Section: 0,
        // SectionHasValue: true,
        // SectionValue: 0,
        // ScoreAttestation: 3,
        // SelectionOption1: null,
        // SelectionOption2: null,
        // SelectionOption3: null,
        // SelectedOption1: null,
        // SelectedOption2: null,
        // SelectedOption3: null,
    });
    const [cyberThreatLevelData, setCyberThreatLevelData] = useState({
        // ObjectId: 0,
        // Section: 1,
        // SectionHasValue: true,
        // SectionValue: 0,
        // ScoreAttestation: 3,
        // SelectionOption1: null,
        // SelectionOption2: null,
        // SelectionOption3: null,
        // SelectedOption1: null,
        // SelectedOption2: null,
        // SelectedOption3: null,
    });
    const [cyberComplianceData, setCyberComplianceData] = useState({
        // ObjectId: 0,
        // Section: 2,
        // SectionHasValue: true,
        // SectionValue: 0,
        // ScoreAttestation: 3,
        // SelectionOption1: null,
        // SelectionOption2: null,
        // SelectionOption3: null,
        // SelectedOption1: null,
        // SelectedOption2: null,
        // SelectedOption3: null,
    });
    const [riskyUsersData, setRiskyUsersData] = useState({
        // ObjectId: 0,
        // Section: 3,
        // SectionHasValue: true,
        // SectionValue: 0,
        // ScoreAttestation: 3,
        // SelectionOption1: null,
        // SelectionOption2: null,
        // SelectionOption3: null,
        // SelectedOption1: null,
        // SelectedOption2: null,
        // SelectedOption3: null,
    });
    const [activeThreatsData, setActiveThreatsData] = useState({
        // ObjectId: 0,
        // Section: 4,
        // SectionHasValue: true,
        // SectionValue: 0,
        // ScoreAttestation: 3,
        // SelectionOption1: null,
        // SelectionOption2: null,
        // SelectionOption3: null,
        // SelectedOption1: null,
        // SelectedOption2: null,
        // SelectedOption3: null,
    });
    const [activeRisksData, setActiveRisksData] = useState({
        // ObjectId: 0,
        // Section: 5,
        // SectionHasValue: true,
        // SectionValue: 0,
        // ScoreAttestation: 3,
        // SelectionOption1: null,
        // SelectionOption2: null,
        // SelectionOption3: null,
        // SelectedOption1: null,
        // SelectedOption2: null,
        // SelectedOption3: null,
    });
    const [cyberInvestmentData, setCyberInvestmentData] = useState({
        // ObjectId: 0,
        // Section: 6,
        // SectionHasValue: true,
        // SectionValue: 0,
        // ScoreAttestation: 3,
        // SelectionOption1: null,
        // SelectionOption2: null,
        // SelectionOption3: null,
        // SelectedOption1: null,
        // SelectedOption2: null,
        // SelectedOption3: null,
    });
    const [cyberEssentialsData, setCyberEssentialsData] = useState({
        // ObjectId: 0,
        // Section: 7,
        // SectionHasValue: true,
        // SectionValue: 0,
        // ScoreAttestation: 0,
        // SelectionOption1: [
        //   {
        //     Id: 3347862,
        //     value: "Plus",
        //     SectionDDLName: "CyberEssentialsCertificationLevel",
        //     SectionDDLType: "DropDown",
        //     SortOrder: 1,
        //   },
        // ],
        // SelectionOption2: [
        //   {
        //     Id: 3347863,
        //     value: "Up to date",
        //     SectionDDLName: "CyberEssentialsCertificationStatus",
        //     SectionDDLType: "DropDown",
        //     SortOrder: 1,
        //   },
        // ],
        // SelectionOption3: [
        //   {
        //     Id: 3347864,
        //     value: "Quarterly",
        //     SectionDDLName: "CyberEssentialsAuditFrequency",
        //     SectionDDLType: "DropDown",
        //     SortOrder: 1,
        //   },
        // ],
        // SelectedOption1: null,
        // SelectedOption2: null,
        // SelectedOption3: null,
    });
    const [incidenceResponsePlanData, setIncidenceResponsePlanData] = useState({
        // ObjectId: 0,
        // Section: 8,
        // SectionHasValue: false,
        // SectionValue: 0,
        // ScoreAttestation: 0,
        // SelectionOption1: [
        //   {
        //     Id: 3348560,
        //     value: "Incident Response Vision & Mission",
        //     SectionDDLName: "IncidentResponsePlanDocs",
        //     SectionDDLType: "CheckBox",
        //     SortOrder: 1,
        //   },
        //   {
        //     Id: 3348561,
        //     value: "Internet Acceptable Use Policy",
        //     SectionDDLName: "IncidentResponsePlanDocs",
        //     SectionDDLType: "CheckBox",
        //     SortOrder: 2,
        //   },
        // ],
        // SelectionOption2: [
        //   {
        //     Id: 3347868,
        //     value: "Monthly",
        //     SectionDDLName: "IncidentResponseReview",
        //     SectionDDLType: "DropDown",
        //     SortOrder: 1,
        //   },
        // ],
        // SelectionOption3: [],
        // SelectedOption1: [3348560, 3348561],
        // SelectedOption2: [3347868],
        // SelectedOption3: null,
    });

    const [infosecPoliciesData, setInfosecPoliciesData] = useState({
        // ObjectId: 0,
        // Section: 9,
        // SectionHasValue: false,
        // SectionValue: 0,
        // ScoreAttestation: 0,
        // SelectionOption1: [
        //   {
        //     Id: 3347871,
        //     value: "IT Acceptable Use Policy",
        //     SectionDDLName: "RecommendedPolicies",
        //     SectionDDLType: "CheckBox",
        //     SortOrder: 1,
        //   },
        //   {
        //     Id: 3347872,
        //     value: "Remote Access Policy",
        //     SectionDDLName: "RecommendedPolicies",
        //     SectionDDLType: "CheckBox",
        //     SortOrder: 2,
        //   },
        //   {
        //     Id: 3347873,
        //     value: "Remote Working Policies",
        //     SectionDDLName: "RecommendedPolicies",
        //     SectionDDLType: "CheckBox",
        //     SortOrder: 3,
        //   },
        //   {
        //     Id: 3347874,
        //     value: "Mobile Device Policy",
        //     SectionDDLName: "RecommendedPolicies",
        //     SectionDDLType: "CheckBox",
        //     SortOrder: 4,
        //   },
        //   {
        //     Id: 3347875,
        //     value: "Internet Accepatable Use",
        //     SectionDDLName: "RecommendedPolicies",
        //     SectionDDLType: "CheckBox",
        //     SortOrder: 5,
        //   },
        // ],
        // SelectionOption2: [
        //   {
        //     Id: 3347876,
        //     value: "Password Policy",
        //     SectionDDLName: "CyberEssentialsPolicies",
        //     SectionDDLType: "CheckBox",
        //     SortOrder: 1,
        //   },
        //   {
        //     Id: 3347877,
        //     value: "Work From Home Policy",
        //     SectionDDLName: "CyberEssentialsPolicies",
        //     SectionDDLType: "CheckBox",
        //     SortOrder: 2,
        //   },
        //   {
        //     Id: 3347878,
        //     value: "Mobile Device Register",
        //     SectionDDLName: "CyberEssentialsPolicies",
        //     SectionDDLType: "CheckBox",
        //     SortOrder: 3,
        //   },
        //   {
        //     Id: 3347879,
        //     value: "User Account Creation",
        //     SectionDDLName: "CyberEssentialsPolicies",
        //     SectionDDLType: "CheckBox",
        //     SortOrder: 4,
        //   },
        //   {
        //     Id: 3347880,
        //     value: "Firewall Documentation",
        //     SectionDDLName: "CyberEssentialsPolicies",
        //     SectionDDLType: "CheckBox",
        //     SortOrder: 5,
        //   },
        //   {
        //     Id: 3347881,
        //     value: "Mobile Device Policy",
        //     SectionDDLName: "CyberEssentialsPolicies",
        //     SectionDDLType: "CheckBox",
        //     SortOrder: 6,
        //   },
        // ],
        // SelectionOption3: [],
        // SelectedOption1: null,
        // SelectedOption2: null,
        // SelectedOption3: null,
    });

    const [riskAssessmentsData, setRiskAssessmentsData] = useState({
        // ObjectId: 0,
        // Section: 10,
        // SectionHasValue: false,
        // SectionValue: 0,
        // ScoreAttestation: 0,
        // SelectionOption1: [
        //   {
        //     Id: 3347882,
        //     value: "Monthly",
        //     SectionDDLName: "RiskAssessmentsFrequency",
        //     SectionDDLType: "DropDown",
        //     SortOrder: 1,
        //   },
        // ],
        // SelectionOption2: [],
        // SelectionOption3: [],
        // SelectedOption1: null,
        // SelectedOption2: null,
        // SelectedOption3: null,
    });
    const [bcdrPlanData, setBcdrPlanData] = useState({
        // ObjectId: 0,
        // Section: 11,
        // SectionHasValue: false,
        // SectionValue: 0,
        // ScoreAttestation: 0,
        // SelectionOption1: [
        //   {
        //     Id: 3347883,
        //     value: "Up to date",
        //     SectionDDLName: "ITBCDRPlan",
        //     SectionDDLType: "DropDown",
        //     SortOrder: 1,
        //   },
        // ],
        // SelectionOption2: [
        //   {
        //     Id: 3347884,
        //     value: "Monthly",
        //     SectionDDLName: "TestRecoveryFrequency",
        //     SectionDDLType: "DropDown",
        //     SortOrder: 2,
        //   },
        // ],
        // SelectionOption3: [],
        // SelectedOption1: null,
        // SelectedOption2: null,
        // SelectedOption3: null,
    });
    const [annualCyberReviewsData, setAnnualCyberReviewsData] = useState({
        // ObjectId: 0,
        // Section: 12,
        // SectionHasValue: false,
        // SectionValue: 0,
        // ScoreAttestation: 0,
        // SelectionOption1: [
        //   {
        //     Id: 3347885,
        //     value: "Annual",
        //     SectionDDLName: "AnnualCyberReviewsFrequency",
        //     SectionDDLType: "DropDown",
        //     SortOrder: 1,
        //   },
        // ],
        // SelectionOption2: [],
        // SelectionOption3: [],
        // SelectedOption1: null,
        // SelectedOption2: null,
        // SelectedOption3: null,
    });
    const [attackSurfaceData, setAttackSurfaceData] = useState({});

    const [sendNotificationStatus, setSendNotificationStatus] = useState(false);
    const [objectId, setObjectId] = useState(0);
    const [complianceProfileName, setComplianceProfileName] = useState("");

    useEffect(() => {
        if (show) {
            setSaving(false);
            setObjectId(complianceProfileId ?? 0);
            setComplianceProfileName("");
            setSendNotificationStatus(false);
            // setPage("homepage");
            if (complianceProfileId > 0) {
                getComplianceProfileData();
            }
            console.log(complianceProfileId)
        }
    }, [show, complianceProfileId]);

    function fillData(sectionId, data) {
        switch (sectionId) {
            case 0:
                setCyberScoreData(data);
                break;
            case 1:
                setCyberThreatLevelData(data);
                break;
            case 2:
                setCyberComplianceData(data);
                break;
            case 3:
                setRiskyUsersData(data);
                break;
            case 4:
                setActiveThreatsData(data);
                break;
            case 5:
                setActiveRisksData(data);
                break;
            case 6:
                setCyberInvestmentData(data);
                break;
            case 7:
                setCyberEssentialsData(data);
                break;
            case 8:
                setIncidenceResponsePlanData(data);
                break;
            case 9:
                setInfosecPoliciesData(data);
                break;
            case 10:
                setRiskAssessmentsData(data);
                break;
            case 11:
                setBcdrPlanData(data);
                break;
            case 12:
                setAnnualCyberReviewsData(data);
                break;
            case 13:
                setAttackSurfaceData(data);
                break;

            default:
                console.log(`Section ID doesnt exist`);
        }
    }

    const allSectionData = [


        cyberScoreData,
        cyberThreatLevelData,
        cyberComplianceData,
        riskyUsersData,
        activeThreatsData,
        activeRisksData,
        cyberInvestmentData,
        cyberEssentialsData,
        incidenceResponsePlanData,
        infosecPoliciesData,
        riskAssessmentsData,
        bcdrPlanData,
        annualCyberReviewsData,
        attackSurfaceData,
    ];

    // Function to get compliance Profile Data
    async function getComplianceProfileData() {
        setLoading(true);
        await getAPIData("306", authTokens, {
            optionEnum1: complianceProfileId,
        })
            .then((response) => {
                setLoading(false);
                if (response?.mr?.Success) {
                    setComplianceProfileName(response?.ProfileName);
                    setSendNotificationStatus(response?.SendNotifications);
                    if (response?.ProfileSection?.length > 0) {
                        for (let i = 0; i < response?.ProfileSection?.length; i++) {
                            fillData(
                                response?.ProfileSection[i].Section,
                                response?.ProfileSection[i]
                            );
                        }
                    }
                } else {
                    CallToast("Something went wrong", false, false, "v2style");
                }
            })
            .catch((err) => {
                setLoading(false);
                CallToast("Something went wrong", false, false, "v2style");
            });
    }


    return (
        <Modal
            show={show}
            dialogClassName="secops-cert-modal "
            className="   "
            aria-labelledby=" "
            onHide={hideModal}
            centered
            scrollable
        >
            <Modal.Header className="text-center">
                <div
                    className="w-100 mb-0 font-bold-14 title"
                    style={{ fontWeight: "600" }}
                >
                    Important Suppliers
                </div>

                <span
                    className="cursor-pointer"
                    onClick={() => {
                        hideModal();
                        setComplianceProfileName("");
                    }}
                >
                    <img width={15} src="/images/attack-surface/cross-icon.svg" alt="" />
                </span>
            </Modal.Header>
            <Modal.Body className=" transparent-scroller important-supplier d-flex flex-column" style={{ gap: '16px', padding: '12pxs' }}>
                {loading ? (
                    <div className="loader d-flex align-items-center justify-content-center h-100 w-100">
                        <Loader />
                    </div>
                ) : (
                    <>
                        <div className="d-flex item" >
                            <div className="title f-black f-16" style={{ width: '65%', fontWeight: 600 }}>Important Suppliers</div>
                        </div>
                        <div>
                            <div className="d-flex item" style={{ alignItems: ' center' }}>
                                <div className="title f-black f-16" style={{ width: '65%', fontWeight: 600 }}>Thresholds</div>
                            </div>
                            <div className="d-flex item" style={{ alignItems: ' center' }}>
                                <div className="title f-black" style={{ width: '65%', fontWeight: 500 }}>CyberScore</div>
                                <div className="value d-flex flex-column" style={{ width: '35%', alignItems: ' flex-end' }}>
                                    <div className="f-grey">Min {cyberScoreData?.SectionValue}</div>
                                    <div className="f-12 f-darkgrey"> {cyberScoreData?.ScoreAttestation}</div>
                                    {/* <div className="f-12 f-darkgrey">Verified</div> */}
                                </div>
                            </div>
                            <div className="d-flex item" style={{ alignItems: ' center' }}>
                                <div className="title f-black" style={{ width: '65%', fontWeight: 500 }}>CyberThreat Level</div>
                                <div className="value d-flex flex-column" style={{ width: '35%', alignItems: ' flex-end' }}>
                                    <div className="f-grey">Max {cyberThreatLevelData?.SectionValue}</div>
                                    <div className="f-12 f-darkgrey">{cyberThreatLevelData?.ScoreAttestation}</div>
                                </div>
                            </div>
                            <div className="d-flex item" style={{ alignItems: ' center' }}>
                                <div className="title f-black" style={{ width: '65%', fontWeight: 500 }}>CyberCompliance</div>
                                <div className="value d-flex flex-column" style={{ width: '35%', alignItems: ' flex-end' }}>
                                    <div className="f-grey">Min {cyberComplianceData?.SectionValue}</div>
                                    <div className="f-12 f-darkgrey">{cyberComplianceData?.ScoreAttestation}</div>
                                </div>
                            </div>
                            <div className="d-flex item" style={{ alignItems: ' center' }}>
                                <div className="title f-black" style={{ width: '65%', fontWeight: 500 }}>Risky Users</div>
                                <div className="value d-flex flex-column" style={{ width: '35%', alignItems: ' flex-end' }}>
                                    <div className="f-grey"> {riskyUsersData?.SectionValue}% </div>
                                    <div className="f-12 f-darkgrey">{riskyUsersData?.ScoreAttestation}</div>
                                </div>
                            </div>
                            <div className="d-flex item" style={{ alignItems: ' center' }}>
                                <div className="title f-black" style={{ width: '65%', fontWeight: 500 }}>Active Threats</div>
                                <div className="value d-flex flex-column" style={{ width: '35%', alignItems: ' flex-end' }}>
                                    <div className="f-grey">Max {activeThreatsData?.SectionValue}</div>
                                    <div className="f-12 f-darkgrey">{activeThreatsData?.ScoreAttestation}</div>
                                </div>
                            </div>
                            <div className="d-flex item" style={{ alignItems: ' center' }}>
                                <div className="title f-black" style={{ width: '65%', fontWeight: 500 }}>Active Risks</div>
                                <div className="value d-flex flex-column" style={{ width: '35%', alignItems: ' flex-end' }}>
                                    <div className="f-grey">Max {activeRisksData?.SectionValue}</div>
                                    <div className="f-12 f-darkgrey">{activeRisksData?.ScoreAttestation}</div>
                                </div>
                            </div>
                            <div className="d-flex item" style={{ alignItems: ' center' }}>
                                <div className="title f-black" style={{ width: '65%', fontWeight: 500 }}>CyberInvestment</div>
                                <div className="value d-flex flex-column" style={{ width: '35%', alignItems: ' flex-end' }}>
                                    <div className="f-grey">Min {cyberInvestmentData?.SectionValue}</div>
                                    {/* <div className="f-12 f-darkgrey">{cyberInvestmentData?.SelectionSummary}</div> */}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="d-flex item" >
                                <div className="title f-black f-16" style={{ width: '65%', fontWeight: 600 }}>Required Health & Audit Frequency</div>
                            </div>
                            <div className="d-flex flex-column item">
                                <div className="title f-black" style={{ width: '65%', fontWeight: 600, paddingBottom: '8px' }}>Cyber Essentials</div>
                                <div className="value d-flex">
                                    <div className="f-black" style={{ width: '65%', fontWeight: 500 }}>Certification Level</div>
                                    <div className="f-darkgrey d-flex" style={{ width: '35%', justifyContent: ' flex-end' }}>{cyberEssentialsData?.SelectedOption1?.[0]}</div>
                                </div>
                                <div className="value d-flex">
                                    <div className="f-black" style={{ width: '65%', fontWeight: 500 }}>Incident Response Plan</div>
                                    <div className="f-darkgrey d-flex" style={{ width: '35%', justifyContent: ' flex-end' }}>{cyberEssentialsData?.SelectedOption2?.[0]}</div>
                                </div>
                                <div className="value d-flex">
                                    <div className="f-black" style={{ width: '65%', fontWeight: 500 }}>Audit Frequency</div>
                                    <div className="f-darkgrey d-flex" style={{ width: '35%', justifyContent: ' flex-end' }}>{cyberEssentialsData?.SelectedOption3?.[0]}</div>
                                </div>
                            </div>
                            <div className="d-flex flex-column item">
                                <div className="title f-black" style={{ width: '65%', fontWeight: 600, paddingBottom: '8px' }}>InfoSec Policies</div>
                                <div className="d-flex ">
                                    <div className="w-50">
                                        <div className="f-darkgrey d-flex f-12 ">Recommended Policies</div>
                                        {infosecPoliciesData?.SelectionOption1?.map(item => {
                                            return (<div>{item?.SectionDDLName}</div>)
                                        })}
                                    </div>
                                    <div className="w-50">
                                        <div className="f-darkgrey d-flex f-12">Cyber Essentials Policies</div>
                                        {infosecPoliciesData?.SelectionOption2?.map(item => {
                                            return (<div>{item?.SectionDDLName}  </div>)
                                        })}
                                    </div>
                                </div>

                                {/* <div className="value d-flex">
                                    <div className="f-darkgrey d-flex" style={{ width: '65%' }}>{infosecPoliciesData?.SelectionSummary}</div>
                                </div> */}

                            </div>
                            <div className="d-flex flex-column item">
                                <div className="title f-black" style={{ width: '65%', fontWeight: 600, paddingBottom: '8px' }}>Risk Assessments</div>
                                <div className="value d-flex">
                                    <div className="f-darkgrey d-flex" style={{ width: '65%' }}>{riskAssessmentsData?.SelectedOption1?.[0]}</div>
                                </div>

                            </div>
                            <div className="d-flex flex-column item" >
                                <div className="title f-black" style={{ width: '65%', fontWeight: 600, paddingBottom: '8px' }}>BCDR Plan</div>
                                <div className="value d-flex">
                                    <div className="f-black" style={{ width: '65%', fontWeight: 500 }}>IT BCDR Plan</div>
                                    <div className="f-darkgrey d-flex" style={{ width: '35%', justifyContent: ' flex-end' }}>{bcdrPlanData?.SelectedOption1?.[0]}</div>
                                </div>
                                <div className="value d-flex">
                                    <div className="f-black" style={{ width: '65%', fontWeight: 500 }}>Test Recovery Frequency</div>
                                    <div className="f-darkgrey d-flex" style={{ width: '35%', justifyContent: ' flex-end' }}>{bcdrPlanData?.SelectedOption2?.[0]}</div>
                                </div>
                            </div>
                            <div className="d-flex flex-column item" >
                                <div className="title f-black" style={{ width: '65%', fontWeight: 600, paddingBottom: '8px' }}>Annual Cyber Reviews</div>
                                <div className="value d-flex">
                                    <div className="f-black" style={{ width: '65%', fontWeight: 500 }}>Risk Assessments frequency</div>
                                    <div className="f-darkgrey d-flex" style={{ width: '35%', justifyContent: ' flex-end' }}>{annualCyberReviewsData?.SelectedOption1?.[0]}</div>
                                </div>
                            </div>
                            <div className="d-flex flex-column item">
                                <div className="title f-black" style={{ width: '65%', fontWeight: 600, paddingBottom: '8px' }}>Attack Surface</div>
                                <div className="value d-flex">
                                    <div className="f-black" style={{ width: '65%', fontWeight: 500 }}>Max active security insights</div>
                                    <div className="f-darkgrey d-flex" style={{ width: '35%', justifyContent: ' flex-end' }}>{attackSurfaceData?.SelectedOption1?.[0]}</div>
                                </div>
                                <div className=" f-black" style={{ width: '65%', fontWeight: 500, paddingBottom: '8px' }}>CSDA</div>
                                <div className="d-flex ">
                                    <div className="w-50">
                                        <div className="f-darkgrey d-flex f-12 ">Tools</div>
                                        {attackSurfaceData?.SelectionOption2?.map(item => {
                                            return (<div>{item?.value}</div>)
                                        })}
                                    </div>
                                    <div className="w-50">
                                        <div className="f-darkgrey d-flex f-12">Services</div>
                                        {attackSurfaceData?.SelectionOption3?.map(item => {
                                            return (<div>{item?.value}</div>)
                                        })}
                                    </div>
                                </div>
                                <div className="w-50">
                                    <div className="f-darkgrey d-flex f-12">Capabilities</div>
                                    {attackSurfaceData?.SelectionOption4?.map(item => {
                                        return (<div>{item?.value}</div>)
                                    })}
                                </div>
                                <div className="value d-flex">
                                    <div className="f-black" style={{ width: '65%', fontWeight: 500 }}>Networks</div>
                                    <div className="w-50">
                                        {attackSurfaceData?.SelectionOption18?.map(item => {
                                            return (<div>{item?.value}</div>)
                                        })}
                                    </div>
                                </div>
                                <div className="w-50">
                                    <div className="f-darkgrey d-flex f-12">Networks Assets</div>
                                    {attackSurfaceData?.SelectionOption6?.map(item => {
                                        return (<div>{item?.value}</div>)
                                    })}
                                </div>
                            </div>

                        </div>
                    </>
                )
                }
            </Modal.Body>
        </Modal>
    );
};

export default ImportSuppliersModal;
