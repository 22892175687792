import React, { useEffect, useState, lazy, Suspense } from "react";
import "../secOps.scss";

import CreateTaskModal from "../../../PriceCalculator/modals/CreateTaskModal";
import { setactioncentredata } from "../../../../redux/actionCentre/actions";
import { useDispatch } from "react-redux";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import Loader from "../../../Common/loader";
// import AddEditCertificateModal from "./AddEditCertificateModal";
import AddMemberModal from "./AddMemberModal";
import ContentLoader from "react-content-loader";
import { getAPIData, postData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import ImageWithInitials from "../../../../Utils/ImageWithInitials";

import { WrapperDropdwon } from "../securityProcesses/SecurityProcesses";
import AttackSurfaceTooltip from "../../../AttackSurface/AssetsList/AttackSurfaceTooltip";
import { NO_ACCESS_TEXT, accessStateValue } from "../../../App/App";
import AddMemberModalNew from "./AddMemberModalNew";
import { DeleteModal } from "../../../DeleteModal/DeleteModal";
import OpenLinkModal from "../accreditations/OpenLinkModal";

const AddEditCertificateModal = lazy(() => import("./AddEditCertificateModal"));

const TrainingCertificates = ({setSideBarOpen = () => {}, setShowSidePanelPassed = () => {}, isAudit = false}) => {
  const { authTokens } = useAuth();
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [loading, setLoading] = useState(false);

  const [categoryIdType, setCategoryIdType] = useState(null)

  const [showMemberModal, setShowMemberModal] = useState(false);
  const [selectedUserItem, setSelectedUserItem] = useState();
  const [userDetailsLoading, setUserDetailsLoading] = useState(false);
  const [showCertificateModal, setShowCertificateModal] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [selectedCertificateData, setSelectedCertificateData] = useState({});
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    if (showSidePanel === false) {
        setSideBarOpen(true);
    } else {
      setSideBarOpen(false);
    }
    setShowSidePanelPassed(showSidePanel);
}, [showSidePanel]);


  useEffect(() => {
    setLoading(true);
    getPageDataList();
  }, []);

  useEffect(() => {
    if (selectedUserItem?.userId) {
      setUserDetailsLoading(true);
      getSelectedUserDeatils(selectedUserItem?.userId);
    }
  }, [selectedUserItem]);

  const BannerItems = [
    {
      heading: "In Progress",
      message: pageData?.inProgressCount ?? 0,
      icon: "/images/msp/secops_inprogress.svg",
      optionEnum: 0,
    },
    {
      heading: "Expiring",
      message: pageData?.expiringCount ?? 0,
      icon: "/images/msp/secops_expiring.svg",
      optionEnum: 1,
    },
    {
      heading: "Expired",
      message: pageData?.expiredCount ?? 0,
      icon: "/images/msp/secops_expired.svg",
      optionEnum: 2,
    },
  ];

  async function getPageDataList() {
    await getAPIData(/*"805"*/"587", authTokens/*, { optionEnum1: 0 }*/)
      .then((response) => {
        if (response?.mr?.Success) {
          setPageData(response);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
        setLoading(false);
      });
  }
  async function getSelectedUserDeatils(_userId) {
    await getAPIData("806", authTokens, { optionStrEnum1: _userId })
      .then((response) => {
        if (response?.mr?.Success) {
          setUserDetails(response);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setUserDetailsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
        setUserDetailsLoading(false);
      });
  }






  function isAtTop() {
    if (pageData?.items?.length === 0 || !pageData?.items) return;
    let all_list = pageData?.items?.reduce((accumulator, currentValue) => {
      return accumulator.concat(currentValue);
    }, []);
    let index = all_list.findIndex(
      (item) => item?.userId === selectedUserItem?.userId
    );

    if (index === 0) {
      return true;
    } else {
      return false;
    }
  }
  function isAtBottom() {
    if (pageData?.items?.length === 0 || !pageData?.items) return;
    let all_list = pageData?.items?.reduce((accumulator, currentValue) => {
      return accumulator.concat(currentValue);
    }, []);
    let index = all_list.findIndex(
      (item) => item?.userId === selectedUserItem?.userId
    );

    if (index === all_list.length - 1) {
      return true;
    } else {
      return false;
    }

  }


  async function moveNext() {
    if (pageData?.items?.length === 0 || !pageData?.items) return;
    let all_list = pageData?.items?.reduce((accumulator, currentValue) => {
      return accumulator.concat(currentValue);
    }, []);
    let index = all_list.findIndex(
      (item) => item?.userId === selectedUserItem?.userId
    );

    if (index > -1 && index < all_list.length - 1) {
      setSelectedUserItem(all_list[index + 1]);
    }
  }


  async function movePrev() {
    if (pageData?.items?.length === 0 || !pageData?.items) return;
    let all_list = pageData?.items?.reduce((accumulator, currentValue) => {
      return accumulator.concat(currentValue);
    }, []);
    let index = all_list.findIndex(
      (item) => item?.userId === selectedUserItem?.userId
    );

    if (index > 0) {
      setSelectedUserItem(all_list[index - 1]);
    }

  }

  async function deleteEmployee(objId_EmployeeCertification, userId) {
    // setSaving(true);
    await postData(authTokens, "Certification/ArchiveOrDeleteEmployee", {
      objId_EmployeeCertification: objId_EmployeeCertification,
      userId: userId,
      action: 1
    })
      .then((response) => {
        if (response?.Success) {
          getPageDataList();
          CallToast(
            "Employee deleted successfully",
            true,
            false,
            "v2style"
            );
          setShowSidePanel(false);
          // refreshData();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }













  const [showDeleteModal, setShowDeleteModal] = useState({
    showModal: false,
    deleteMessage: "You are about to delete.",
    deleteSentQuestionnaire: () => { },
  });
  const [showOpenLinkModal, setShowOpenLinkModal] = useState(false);
  const [selectedLink, setSelectedLink] = useState('');

  return (
    <div className="w-100">
      {/*SecOps Side Bar  */}

      {/* Page Body */}
      {loading ? (
        <div className="w-45 mx-auto mt-4">
          <TrainingCertificatesLoader />
        </div>
      ) : (
        <div className="slidable-body position-relative">
          <div
            className={`main-body hide-scrollbar ${showSidePanel ? "panel-open" : "panel-closed"
              }`}
          >
            <div className="p-3" />

            <div className="d-flex  banner">
              {BannerItems?.map((item, bannerItemIndex) => {
                return (
                  <div
                    className={`d-flex justify-content-between align-items-start banner-element pointer ${bannerItemIndex === BannerItems.length - 1
                      ? ""
                      : "banner-element-border"
                      }`}
                    onClick={() => {
                      if (item.message === 0) return;
                      setCategoryIdType(item.optionEnum);
                      setShowCertificateModal(true);
                    }}
                  >
                    {bannerItemIndex >= 0 && (
                      <>
                        <div>
                          <p className="banner-element-name mb-2">
                            {item.heading}
                          </p>
                          <p className="banner-element-value">{item.message}</p>
                        </div>
                        <div className="">
                          <img alt="" src={item.icon} />
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="d-flex align-items-center justify-content-between">
              <div>
                <div className="f-16">Training Certifications</div>
                <div className="f-12 f-darkgrey">
                  Define what security training certifications your team hold or
                  are working towards
                </div>
              </div>
              <AttackSurfaceTooltip
                disabled={accessStateValue("AccessState_SecOps") !== 1}
                bottomLeft={true}
                topLeft={false}
                content={<span>{NO_ACCESS_TEXT}</span>}
              >
                <Button
                  className={`hg-blue-btn
                   ${accessStateValue("AccessState_SecOps") === 1
                      ? " btn-disable"
                      : ""
                    }
                   
                   `}
                  onClick={() => {
                    setShowMemberModal(true);
                  }}
                >
                  {accessStateValue("AccessState_SecOps") === 1 ? (
                    <img
                      alt=""
                      src="/images/attack-surface/small-lock.svg"
                      className="mr-2"
                    />
                  ) : (
                    <img
                      src="/images/white-plus-icon.svg"
                      alt=""
                      className="mr-2"
                    />
                  )}
                  Add Employee
                </Button>
              </AttackSurfaceTooltip>
            </div>

            <div className="border-parent mt-4 radius-8">
              {pageData?.items?.map((item, index) => {
                return (
                  <div
                    className={`
                    ${selectedUserItem?.userId === item?.userId
                        ? "bg-lightblue-imp"
                        : ""
                      }
                    
                    border-item d-flex align-items-center justify-content-between p-3 pointer bg-hover-row`}
                    onClick={() => {
                      setShowSidePanel(true);
                      setSelectedUserItem(item);
                    }}
                  >
                    <div className="d-flex align-items-center">
                      {item?.showInitials ? (
                        <ImageWithInitials
                          initials={item?.initials}
                          width={33}
                          background={"purple"}
                        />
                      ) : (
                        <img
                          width={33}
                          src={item?.avatar}
                          alt=""
                          className="rounded-full"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/images/user-logo.svg";
                          }}
                        />
                      )}
                      <div className="ml-3">
                        <div className="f-500">{item?.fullName} </div>
                        <div className="f-grey">{item?.certificateText} </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center">
                      {item?.certificates?.length > 0 ? (
                        <div className="secops_training_certificate_overlap_parent mr-3">
                          {item?.certificates
                            ?.slice(0, 8)
                            .map((certificate, certificateIndex) => {
                              return (
                                <>
                                  {certificate?.showInitials ? (
                                    <div className="rounded-full w-fit-content h-fit-content position-relative overlap_child">
                                      <ImageWithInitials
                                        initials={certificate?.initials}
                                        width={25}
                                        background={"purple"}
                                      />
                                      {certificate?.statusIcon && (
                                        <img
                                          width={13}
                                          src={
                                            "/images/msp/" +
                                            certificate?.statusIcon
                                          }
                                          className="rounded-full overlapping_icon"
                                          alt=""
                                        />
                                      )}
                                    </div>
                                  ) : (
                                    <div className="rounded-full w-fit-content h-fit-content position-relative overlap_child">
                                      <img
                                        width={25}
                                        src={`/images/msp/framework-certificates/${certificate?.icon}`}
                                        className="rounded-full"
                                        alt=""
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src =
                                            "/images/msp/default-cert-icon.svg";
                                        }}
                                      />
                                      {certificate?.statusIcon && (
                                        <img
                                          width={13}
                                          src={
                                            "/images/msp/" +
                                            certificate?.statusIcon
                                          }
                                          className="rounded-full overlapping_icon"
                                          alt=""
                                        />
                                      )}
                                    </div>
                                  )}
                                </>
                              );
                            })}
                        </div>
                      ) : (
                        <div className="f-grey f-500 mr-3">Add certificate</div>
                      )}

                      <img
                        src="/images/chevron-right.svg"
                        className=""
                        alt=""
                      />
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="p-4" />
          </div>

          <div
            className={`sidepanel  ${showSidePanel ? "panel-open" : "panel-closed"
              } `}
          >
            <div className="panel-header d-flex align-items-center justify-content-between p-3">
              <div className="d-flex align-items-center">
                <div
                  className={`mx-2 pointer ${isAtTop() ? "pointer-events-none opacity-40" : ""
                    }`}
                  onClick={() => {
                    movePrev();
                  }}
                >
                  <img
                    src="/images/msp/secops-chevron-down.svg"
                    className="rotate180"
                    alt=""
                  />
                </div>
                <div
                  className={`mx-2 pointer ${isAtBottom() ? "pointer-events-none opacity-40" : ""
                    }`}
                  onClick={() => {
                    moveNext();
                  }}
                >
                  <img
                    src="/images/msp/secops-chevron-down.svg"
                    className=""
                    alt=""
                  />
                </div>
              </div>
              <div className="d-flex align-items-center">
                <WrapperDropdwon>
                  <div className="f-grey f-500 mr-4">
                    <img
                      src="/images/msp/secops-info-icon.svg"
                      alt=""
                      className="mr-2"
                    />
                    More info
                  </div>
                </WrapperDropdwon>
                <div
                  className="f-grey f-500 mr-2 pointer"
                  onClick={() => {
                    dispatch(
                      setactioncentredata({
                        refreshCreateTaskModalModule: true,
                      })
                    );
                    const drag = document.getElementById("create-task-modal");
                    // drag.style.transform = "translate(0px, 0px) ";
                    drag.style.display = "block";
                  }}
                >
                  <img
                    src="/images/msp/secops-create-task-icon.svg"
                    alt=""
                    className="mr-3"
                  />
                  Create task
                </div>
                <div
                  className="pl-2 border-left pointer"
                  onClick={() => {
                    setShowSidePanel(false);
                    setSelectedUserItem();
                  }}
                >
                  <img
                    width={13}
                    src="/images/attack-surface/cross-icon.svg"
                    alt=""
                    className=" "
                  />
                </div>
              </div>
            </div>

            {userDetailsLoading ? (
              <div className="d-flex align-items-center justify-content-center w-100 h-100">
                <Loader />
              </div>
            ) : (
              <div className="panel-body transparent-scroller">
                <div className="px-3 mt-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center f-500">
                      {userDetails?.userDetail?.showInitials ? (
                        <ImageWithInitials
                          initials={userDetails?.userDetail?.initials}
                          width={33}
                          background={"purple"}
                        />
                      ) : (
                        <img
                          width={33}
                          src={userDetails?.userDetail?.avatar}
                          alt=""
                          className="rounded-full"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/images/user-logo.svg";
                          }}
                        />
                      )}
                      <div className="d-flex align-items-center ml-3">
                        {userDetails?.userDetail?.fullName}
                      </div>
                    </div>

                    <div className="d-flex align-items-center">
                      <AttackSurfaceTooltip
                        disabled={
                          accessStateValue("AccessState_SecOps") !== 1
                        }
                        bottomLeft={true}
                        topLeft={false}
                        content={<span>{NO_ACCESS_TEXT}</span>}
                      >
                        <Button
                          className={`hg-blue-btn
                      ${accessStateValue("AccessState_SecOps") === 1
                              ? " btn-disable"
                              : ""
                            }
                      
                      `}
                          onClick={() => {
                            setShowCertificateModal(true);
                            setSelectedCertificateData();
                          }}
                        >


                          {accessStateValue("AccessState_SecOps") ===
                            1 ? (
                            <img
                              alt=""
                              src="/images/attack-surface/small-lock.svg"
                              className="mr-2"
                            />
                          ) : (
                            <img
                              src="/images/white-plus-icon.svg"
                              alt=""
                              className="mr-2"
                            />
                          )}
                          Add Certificate
                        </Button>
                      </AttackSurfaceTooltip>
                      <div
                        className="menu-dropdown-light mt-2"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Dropdown className="device-dropdown">
                          <Dropdown.Toggle>
                            <img
                              alt=""
                              src="/images/horizontal-dots.svg"
                              className="hover-on-show-child"
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <div>
                              <Row>
                                <Col
                                  xs={12}
                                  className={`pointer`}
                                >
                                  <div
                                    className="d-flex align-items-center justify-content-center drop-item"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setShowDeleteModal({
                                        showModal: true,
                                        deleteMessage:
                                          "Are you sure you would like to delete this member from Training certification list? All uploaded certifications will be deleted",
                                        deleteSentQuestionnaire:
                                          () => deleteEmployee(selectedUserItem?.objId_EmployeeCertification, selectedUserItem?.userId),
                                      });
                                      //   if (filterType === 0) {
                                      //     setDeleteModalConfig((prev) => ({
                                      //       ...prev,
                                      //       show: true,
                                      //       confirmQuestion: `Delete Package`,
                                      //       deleteMessage: `Are you sure you want to delete this Package? `,
                                      //       deleteType: "mspSell",
                                      //       pageEnum: 621,
                                      //       deleteId: data?.ObjectId,
                                      //       deleteFunction: deleteData,
                                      //     }));
                                      //   } else if (filterType === 1) {
                                      //     setDeleteModalConfig((prev) => ({
                                      //       ...prev,
                                      //       show: true,
                                      //       confirmQuestion: `Delete Service`,
                                      //       deleteMessage: `Are you sure you want to delete this Service? `,
                                      //       deleteType: "mspSell",
                                      //       pageEnum: 662,
                                      //       deleteId: data?.ObjectId,
                                      //       deleteFunction: deleteData,
                                      //     }));
                                      //   } else {
                                      //     setDeleteModalConfig((prev) => ({
                                      //       ...prev,
                                      //       show: true,
                                      //       confirmQuestion: `Delete Product`,
                                      //       deleteMessage: `Are you sure you want to delete this Product? `,
                                      //       deleteType: "mspSell",
                                      //       pageEnum: 663,
                                      //       deleteId: data?.ObjectId,
                                      //       deleteFunction: deleteData,
                                      //     }));
                                      //   }
                                      //   e.target
                                      //     .closest(".device-dropdown")
                                      //     .click();
                                    }}
                                  >
                                    Delete
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>


                  </div>

                  <div className="border-parent radius-8 mt-4">
                    {userDetails?.items?.map((item, index) => {
                      return (
                        <div
                          className="border-item bg-hover-row d-flex align-items-center justify-content-between p-3 pointer"
                        onClick={() => {
                          setSelectedCertificateData(item);
                          setShowCertificateModal(true);
                        }}
                        >
                          <div
                            className="d-flex align-items-center overlap_parent w-100"
                            // onClick={() => {
                            //   setSelectedCertificateData(item);
                            //   setShowCertificateModal(true);
                            // }}
                          >
                            {item?.certificateIcon?.showInitials ? (
                              <div className="rounded-full w-fit-content h-fit-content position-relative overlap_child">
                                <ImageWithInitials
                                  initials={item?.certificateIcon?.initials}
                                  width={33}
                                  background={"purple"}
                                />
                                {item?.certificateIcon?.statusIcon && (
                                  <img
                                    width={13}
                                    src={
                                      "/images/msp/" +
                                      item?.certificateIcon?.statusIcon
                                    }
                                    className="rounded-full overlapping_icon"
                                    alt=""
                                  />
                                )}
                              </div>
                            ) : (
                              <div className="rounded-full w-fit-content h-fit-content position-relative overlap_child">
                                <img
                                  width={33}
                                  src={`/images/msp/framework-certificates/${item?.certificateIcon?.icon}`}
                                  className="rounded-full"
                                  alt=""
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src =
                                      "/images/msp/default-cert-icon.svg";
                                  }}
                                />
                                {item?.certificateIcon?.statusIcon && (
                                  <img
                                    width={13}
                                    src={
                                      "/images/msp/" +
                                      item?.certificateIcon?.statusIcon
                                    }
                                    className="rounded-full overlapping_icon"
                                    alt=""
                                  />
                                )}
                              </div>
                            )}
                            <div className="ml-3">
                              <div className="f-500">
                                {item?.name}
                                &nbsp; &nbsp;
                                {item?.status && (
                                  <span
                                    style={{
                                      color: item?.status?.foreColor,
                                    }}
                                  >
                                    {item?.status?.text}
                                  </span>
                                )}
                              </div>
                              <div className="f-darkgrey">
                                {item?.points?.map((point, idx) => (
                                  <span>
                                    {idx > 0 ? " • " : ""} {point}
                                  </span>
                                ))}{" "}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            {item?.linkUrl && (
                              <div
                                className="d-flex align-items-center f-12 f-darkgrey mr-2"
                                // onClick={() => {
                                //   setSelectedLink(item?.linkUrl)
                                //   setShowOpenLinkModal(true);
                                // }}
                              >
                                <img
                                  src={"/images/msp/msp-cert-link-icon.svg"}
                                  alt=""
                                  className="mr-2  "
                                />
                                Link
                              </div>
                            )}
                            {item?.file && (
                              <div className="d-flex align-items-center f-12 f-darkgrey mr-2">
                                <img
                                  src={"/images/msp/msp-cert-file-icon.svg"}
                                  alt=""
                                  className="mr-2  "
                                />
                                File
                              </div>
                            )}
                            {/* {item?.showOnlyAddButton && (
                              <div className="d-flex align-items-center f-12 f-darkgrey border1 px-2 radius-2 mr-2">
                                <img
                                  src={"/images/msp/msp-cert-add-icon.svg"}
                                  alt=""
                                  className="mr-2  "
                                />
                                Add
                              </div>
                            )} */}
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {userDetails?.items?.length === 0 && (
                    <div className="d-flex flex-column align-items-center justify-content-center mt-5 w-100">
                      <img
                        alt=""
                        src="/images/msp/default-cert-icon.svg"
                        className="mb-4"
                      />
                      <div className="f-600 f-grey mt-2 w-fit-content">
                        No certificates yet
                      </div>
                      {accessStateValue("AccessState_SecOps") !== 1 && <>
                        <div className="f-darkgrey  w-fit-content">
                          Add certificates for employee
                        </div>
                        <div
                          className="d-flex align-items-center  w-fit-content f-blue f-500 mt-3 pointer"
                          onClick={() => {
                            setShowCertificateModal(true);
                            setSelectedCertificateData();
                          }}
                        >
                          <img
                            alt=""
                            className="mr-2"
                            src="/images/msp/blue-add-icon.svg"
                          />
                          Add Certificate
                        </div>
                      </>}
                    </div>
                  )}
                  <div className="p-5" />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <OpenLinkModal
        show={showOpenLinkModal}
        link={selectedLink}
        hideModal={() => {
          setShowOpenLinkModal(false);
          setSelectedLink('')
        }}
      />
      <CreateTaskModal />
      <Suspense fallback={<div>Loading...</div>}>
        <AddEditCertificateModal
          isAudit={isAudit}
          show={showCertificateModal}
          categoryId={categoryIdType}
          hideModal={() => {
            setCategoryIdType(null);
            setShowCertificateModal(false);
          }}
          selectedUserId={/*userDetails?.userDetail?.userId*/selectedUserItem?.userId}
          objId_EmployeeCertification={selectedUserItem?.objId_EmployeeCertification}
          selectedCertificateId={
            selectedCertificateData?.objId_Certification ?? 0
          }
          refreshData={() => {
            getPageDataList();
            if (selectedUserItem?.userId) {
              setUserDetailsLoading(true);
              getSelectedUserDeatils(selectedUserItem?.userId);
            }
          }}
        />
      </Suspense>
      <DeleteModal
        show={showDeleteModal.showModal}
        hideModal={() => {
          setShowDeleteModal({
            showModal: false,
          })
        }}
        deleteMessage={showDeleteModal.deleteMessage}
        deleteType={"deleteSentQuestionnaire"}
        deleteSentQuestionnaire={() => {
          showDeleteModal.deleteSentQuestionnaire();
        }}
        confirmQuestion={`Delete ${selectedUserItem?.fullName}`}
      />
      <AddMemberModalNew
        show={showMemberModal}
        hideModal={() => {
          setShowMemberModal(false);
        }}
        refreshData={() => {
          getPageDataList();
        }}
        hideCreateOption
      />
    </div>
  );
};

export default TrainingCertificates;


const TrainingCertificatesLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0%" y="70" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="212" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="354" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="496" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="638" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="780" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="922" rx="8" ry="8" width="100%" height="140" />
    </ContentLoader>
  );
};
