import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "./notes.scss";
import { postData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";

const AddEditNoteModal = ({
  show,
  hideModal,
  noteId,
  modalState = "add",
  refreshData = () => {},
  noteData,
  deleteNote,
  questionId,
}) => {
  // add
  // edit
  // view
  const [modalData, setModalData] = useState({
    note: "",
  });
  useEffect(() => {
    if (show) {
      setModalData({
        ObjectId: noteData?.ObjectId,
        AddedById: noteData?.AddedById,
        AddedBy_Text: noteData?.AddedBy_Text,
        AddedByLogo: noteData?.AddedByLogo,
        AddedDate: noteData?.AddedDate,
        Notes: noteData?.Notes ?? "",
      });
    }
  }, [show, noteData]);

  const [saving, setSaving] = useState(false);
  const { authTokens } = useAuth();

  async function saveNote() {
    setSaving(true);
    await postData(authTokens, "CreateSupplierProfilingNote", {
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      ObjectId: modalState === "add" ? 0 : modalData?.ObjectId,
      Notes: modalData?.Notes,
      objId_SendFormQuestionnaire: questionId,
    })
      .then((response) => {
        if (response?.Success) {
          hideModal();
          refreshData();
          CallToast("Updated Successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setSaving(false);
      })
      .catch((err) => {
        setSaving(false);
      });
  }

  return (
    <Modal
      show={show}
      dialogClassName="supplier-profiler-note-modal"
      className=""
      aria-labelledby=" "
      centered
      scrollable
      onHide={() => {
        hideModal();
      }}
    >
      <Modal.Header>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="f-16 f-500">
            {modalState !== "add" ? "Note" : "Add note"}
          </div>
          <div>
            {modalState === "add" && (
              <Button
                className="hg-cancel-btn mr-2 radius-2"
                onClick={hideModal}
              >
                Cancel
              </Button>
            )}
            {modalState === "edit" && (
              <img
                src="/images/actioncenter/delete.svg"
                alt=""
                className="mr-3 pointer"
                onClick={() => {
                  deleteNote(modalData?.ObjectId).then((response) => {
                    if (response) {
                      hideModal();
                      refreshData();
                    } else {
                    }
                  });
                }}
              />
            )}
            {modalState !== "view" && (
              <Button
                disabled={
                  saving ||
                  modalData?.Notes?.length === 0 ||
                  modalData?.Notes?.trim() == ""
                }
                className="hg-blue-btn radius-2 mr-2"
                onClick={() => {
                  saveNote();
                }}
              >
                {saving ? "Saving.." : "Save"}
              </Button>
            )}

            <img
              alt=""
              src="/images/user-detail-close.svg"
              className=" pointer ml-2 mr-3"
              onClick={hideModal}
            />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="hide-scrollbar p-0 position-relative overflow-hidden d-flex">
        <div
          className="d-flex flex-column p-3"
          style={{
            flex: "1 0",
            // pointerEvents: `${modalState === "view" ? "none" : ""}`,
          }}
        >
          <textarea
            className="border-none br-transaprent w-100 h-100 transparent-scroller"
            placeholder="Type your note here...."
            value={modalData.Notes ?? ""}
            onChange={(e) => {
              if (modalState === "view") {
                return;
              }
              setModalData({ ...modalData, Notes: e.target.value });
            }}
            style={{
              resize: "none",
              caretColor: `${modalState === "view" ? "transparent" : ""}`,
            }}
          />

          {modalState !== "add" && (
            <div className="d-flex justify-content-between">
              <div className="f-12 f-500 f-grey">
                <img
                  src={modalData?.AddedByLogo ?? ""}
                  alt="clock"
                  className="mr-2 rounded-full"
                  width={20}
                  height={20}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/images/user-logo.svg";
                  }}
                />
                {modalData?.AddedBy_Text}
              </div>
              <div className="f-12 f-darkgrey">{modalData?.AddedDate}</div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddEditNoteModal;
