import React, {useState} from 'react';
import './CyberCareContainer.css';
import {Col} from 'react-bootstrap';

const CyberCareContainer = (props) => {

    const [title, setTitle] = useState(props.title || false);
    const [content, setContent] = useState(props.content || false);
    const [containerHeight, setContainerHeight] = useState(props.height || false);
    const [marginTop, setMarginTop] = useState(props.marginTop || false);
    return (
        <Col xs={12} className="cyberCareContainer" style={containerHeight != false ? {height:containerHeight, marginTop: marginTop ? marginTop : "inherit"} : {height:"auto", marginTop: marginTop ? marginTop : "inherit"}}>
            {title? <h2 style={{height:"5%"}} className="title">{title}</h2> : ""}
            {content ? content : ""}
        </Col>
    );
}

export default CyberCareContainer;