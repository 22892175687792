import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { getAPIData, postData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import ContentLoader from "react-content-loader";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";

const SupplyComplianceManager = ({
  goTo,
  complianceAccessLevel,
  supplierId,
  accessCE,
  accessIRP,
  accessISP,
  accessRA,
  accesssACR,
  accessBCDR,
  setComplianceSectionPageEnumData,
}) => {
  const { authTokens } = useAuth();
  const [boxList, setBoxList] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    if (
      complianceAccessLevel !== null &&
      accessCE !== null &&
      accessIRP !== null &&
      accessISP !== null &&
      accessRA !== null &&
      accesssACR !== null &&
      accessBCDR !== null
    ) {
      getPageData(
        supplierId,
        accessCE,
        accessIRP,
        accessISP,
        accessRA,
        accesssACR,
        accessBCDR
      );
    }
  }, [
    complianceAccessLevel,
    supplierId,
    accessCE,
    accessIRP,
    accessISP,
    accessRA,
    accesssACR,
    accessBCDR,
  ]);

  async function getPageData(
    supplierId,
    accessCE,
    accessIRP,
    accessISP,
    accessRA,
    accesssACR,
    accessBCDR
  ) {
    await postData(authTokens, "GetComplianceSCM", {
      ObjId_Supplier: supplierId,
      Access_CE: accessCE,
      Access_IRP: accessIRP,
      Access_ISP: accessISP,
      Access_RA: accessRA,
      Access_ACR: accesssACR,
      Access_BCDR: accessBCDR,
    })
      .then((response) => {
        setLoading(false);
        if (response?.mr?.Success ) {
          setBoxList(response?.ComplanceBoxList);
        } else {
            CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }

  return (
    <div className="p-3 d-flex flex-wrap mt-3">
      {loading ? (
        <ComplianceSectionLoader />
      ) : (
        <>
          {boxList?.map((item, itemIndex) => {
            return (
              <div
                className=""
                style={{
                  width: "29.5%",
                  marginRight: "20px",
                  marginLeft: "20px",
                  marginBottom: "10px",

                  pointerEvents: `${
                    item.AllowDrillThrough === false ? "none" : ""
                  }`,
                }}
                onClick={() => {
                  setComplianceSectionPageEnumData({
                    pageEnum: item?.SCMDrillDownPageEnum,
                    supplierId: supplierId,
                    accessCE: accessCE,
                    accessIRP: accessIRP,
                    accessISP: accessISP,
                    accessRA: accessRA,
                    accesssACR: accesssACR,
                    accessBCDR: accessBCDR,
                  });
                  goTo(item?.CBoxTitle);
                }}
              >
                <ComplianceBox
                  mainText={item.CBoxTitle}
                  icon={item.CBoxIcon}
                  packageText={item.CBoxPackageText}
                  packageColor={item.CBoxPackageColor}
                  packageIcon={item.CBoxPackageIcon}
                  bText={item.CBoxMessage}
                  bTextBolor={item.CBoxMessageColour}
                  pText={item.CBoxDateLabel}
                  linkRoute={"/Compliance_Manager/" + item.BoxLink}
                  status={item.CBoxAuditStatusText}
                  statusColor={item.CBoxAuditStatusColour}
                  bgColor={item.CBoxBackgroundColor}
                  dateText={item.CBoxDateTxt}
                  showUnderScore={item.showUnderScoreAndShadow}
                  accessLevel={item.AccessLevel}
                  AllowDrillThrough={item.AllowDrillThrough}
                />
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default SupplyComplianceManager;

const ComplianceBox = (props) => {
  const [text] = useState(props.mainText || "");
  const [icon] = useState(props.icon || "");
  const [bodyText] = useState(props.bText || "");
  const [percentText] = useState(props.pText || "");
  //   const [linkText] = useState(props.linkText || "");
  // const [linkRoute] = useState(props.linkRoute || "");
  const [statusText] = useState(props.status || "");
  const [statusTextColor] = useState(props.statusColor || "");
  const [packageText] = useState(props.packageText || "");
  const [packageColor] = useState(props.packageColor || "");
  const [packageIcons] = useState(props.packageIcon || "");
  const [backgroundColor] = useState(props.bgColor || "");
  const [isUnderScore] = useState(props.showUnderScore || "");
  const [date] = useState(props.dateText || "");
  const ALIGN = props.align || "right";
  const [bodyTextColor] = useState(props.bTextBolor || "");
  //   const UNDERLINE = props.underline || false;

  const TextColumn = (
    <Col
      xs={12}
      md={6}
      lg={6}
      className="mx-auto my-auto"
      style={{ paddingLeft: "2em" }}
    >
      <div>
        <div className="d-flex">
          {packageIcons !== "" && (
            <img
              alt=""
              width={16}
              height={16}
              src={packageIcons}
              style={{ paddingRight: "5px" }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/no-image.png";
              }}
            />
          )}
          <p
            className="mb-0"
            style={{ color: packageColor ? packageColor : "" }}
          >
            {packageText}
          </p>
        </div>
        <div className="heading3 mt-0" style={{ textAlign: "left" }}>
          {text}
        </div>
        {/* {linkText ?
          <p className="link_textCol"><Link to={linkRoute}>{linkText} {ALIGN ? ">" : ""}</Link></p>
  
          :
  
          ""} */}
      </div>
    </Col>
  );
  const DataColumn = (
    <Col md={6} style={{ padding: "8px" }}>
      <div className="mx-auto my-auto" style={{ height: "100%" }}>
        <Col
          md={12}
          className="webfiltering_background"
          style={{
            backgroundColor: backgroundColor
              ? backgroundColor.replace(";", "")
              : "",
            padding: percentText !== "" ? "5px 0" : "1em 0",
          }}
        >
          {props.accessLevel === 0 ? (
            <img alt="" src="/images/attack-surface/small-lock.svg" />
          ) : (
            <div className="w-100">
              <span style={{ paddingBottom: "0.7em" }}>
                 
                <img
                  alt=""
                  width={18}
                  height={18}
                  src={icon}
                  style={{
                    borderRadius: "50%",
                    padding: "0px",
                    background: isUnderScore ? statusTextColor + 20 : "",
                  }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/images/no-image.png";
                  }}
                />
              </span>
              <span
                style={{
                  paddingBottom: "0.5em",
                  paddingRight: "8px",
                }}
              >
                <h3
                  className="drilldownSideText"
                  style={{
                    color: bodyTextColor ? bodyTextColor : "black",
                  }}
                >
                  {bodyText}
                </h3>
              </span>
              <span style={{ paddingBottom: "0.5em" }}>
                {" "}
                {isUnderScore ? (
                  <img
                    alt=""
                    src="/images/complianceManager/sepratorIcom.svg"
                  ></img>
                ) : (
                  ""
                )}
              </span>
              <span>
                {" "}
                <h3
                  className="percentText"
                  style={{
                    color: statusTextColor ? statusTextColor : "",
                    width: "90%",
                    padding: 0,
                    textAlign: "center",
                  }}
                >
                  {statusText}
                </h3>
              </span>

              <span>
                <h3
                  title={percentText}
                  className="percentText  text-center truncateText pt-2"
                  style={{ width: "90%", padding: 0, textAlign: "center" }}
                >
                  {percentText}
                </h3>
              </span>

              <span>
                <h3
                  className="percentText text-center"
                  title={date}
                  style={{ width: "90%", padding: 0, textAlign: "center" }}
                >
                  {window.innerWidth > 1680
                    ? date.slice(0, 90) + (date.length > 90 ? "..." : "")
                    : date.slice(0, 70) + (date.length > 70 ? "..." : "")}
                </h3>
              </span>
            </div>
          )}
        </Col>
      </div>
    </Col>
  );
  return (
    <Link /*to={linkRoute}*/>
      <Row className="webFilteringBox">
        {ALIGN === "left" ? (
          <React.Fragment>
            {DataColumn}
            {TextColumn}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {TextColumn}
            {DataColumn}
          </React.Fragment>
        )}
      </Row>
    </Link>
  );
};

const ComplianceSectionLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 2000"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0%" y="5" rx="16" ry="16" width="31%" height="225" />
      <rect x="32%" y="5" rx="16" ry="16" width="31%" height="225" />
      <rect x="64%" y="5" rx="16" ry="16" width="31%" height="225" />

      <rect x="0%" y="245" rx="16" ry="16" width="31%" height="225" />
      <rect x="32%" y="245" rx="16" ry="16" width="31%" height="225" />
      <rect x="64%" y="245" rx="16" ry="16" width="31%" height="225" />
    </ContentLoader>
  );
};


export const ComplianceBoxPageEnum={
  "Cyber Essentials":400,
  "Incident Response Plan":401,
  "Information Security Policies":404,
  "Risk Assessment":402,
  "Annual Cyber Review":403,
  "BCDR Plan":405
}