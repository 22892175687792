import React, { useEffect, useRef, useState } from "react";
import "./risk-matrix.scss";
import {
  Button,
  Dropdown,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { WhiteLoader } from "../../Theme/APILoaders";
import { TrimString } from "../../../Utils/StringTrimmer";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DeleteModal } from "../../DeleteModal/DeleteModal";
import { DeleteModalWithBackdrop } from "../../supplyChainMonitoring/QuestionnaireTab/QuestionnaireBigModal";
import { useAuth } from "../../context/Auth";
import { getAPIData, postData } from "../../../Utils/commonApi";
import Loader from "../../Common/loader";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import AttackSurfaceTooltip from "../../AttackSurface/AssetsList/AttackSurfaceTooltip";
import { viewOnlyAccess } from "../../App/App";
const RiskMatrix = ({ show = true, hideModal }) => {
  const { authTokens } = useAuth();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [data, setData] = useState(_data);
  const [colourList, setColourList] = useState([]);
  const [riskLevelList, setRiskLevelList] = useState([]);
  const [deleteQuery, setDeleteQuery] = useState({
    isColumn: false,
    index: 0,
  });

  useEffect(() => {
    if (show) {
      setLoading(true);
      setShowSidebar(false);
      Promise.all([getModalData(), getRiskLevelData()])
        .then((response) => {
          setLoading(false);
        })
        .catch((error) => {});
    }
  }, [show]);

  function getModalData() {
    return new Promise(function (resolve, reject) {
      getAPIData("715", authTokens)
        .then((response) => {
          if (response?.mr?.Success) {
            setData(
              response?.riskMatrix ?? {
                riskColumns: [],
                riskRows: [],
              }
            );
            resolve("success");
          } else {
            reject("error");
          }
        })
        .catch((error) => {
          reject("error");
        });
    });
  }
  async function getRiskLevelData() {
    return new Promise(function (resolve, reject) {
      getAPIData("716", authTokens)
        .then((response) => {
          if (response?.mr?.Success) {
            setColourList(response?.riskLevelColorSelection ?? []);
            setRiskLevelList(
              response?.riskLevelSelection?.riskLevel_List ?? []
            );
            resolve("success");
          } else {
            reject("error");
          }
        })
        .catch((error) => {
          reject("error");
        });
    });
  }

  async function saveModalData() {
    setSaving(true);
    await postData(authTokens, "CreateUpdateRiskMatrix", {
      riskMatrix: JSON.stringify(data),
    })
      .then((response) => {
        setSaving(false);
        if (response?.Success) {
          hideModal();
          CallToast("Updated successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        setSaving(false);
      });
  }

  /**
   * {
   * optionEnum :   {
        Create,//........0
        Update,//........1
        Delete,//........2
        Sort //........3
    }
   * riskLevelList:[]
    *riskLevel:{}
*
   * }
*
   *  
   */
  function createEditDeleteRiskLevel(kwargs) {
    return new Promise(function (resolve, reject) {
      postData(authTokens, "CreateUpdateRiskLevelSelection", {
        optionEnum: kwargs?.optionEnum,
        riskLevelList: JSON.stringify(kwargs?.riskLevelList ?? []),
        riskLevel: JSON.stringify(kwargs?.riskLevel ?? {}),
      })
        .then((response) => {
          if (response?.mr?.Success) {
            setRiskLevelList(
              response?.riskLevelSelection?.riskLevel_List ?? []
            );
            resolve("success");
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch(() => {
          reject("error");
        });
    });
  }

  const [showSidebar, setShowSidebar] = useState(false);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      riskLevelList,
      result.source.index,
      result.destination.index
    );
    let sortedSectionList = items.map((item, index) => ({
      ...item,
      sortOrder: index,
    }));

    setRiskLevelList(sortedSectionList);
    createEditDeleteRiskLevel({
      optionEnum: 3,
      riskLevelList: sortedSectionList,
    });
  }

  async function checkError() {
    let updated_data = { ...data };
    let errors = 0;
    // check error in grid
    for (let i = 0; i < updated_data.riskRows.length; i++) {
      for (let j = 0; j < updated_data.riskRows[i].levels.length; j++) {
        if (!updated_data.riskRows[i].levels[j].riskLevel) {
          updated_data.riskRows[i].levels[j].isError = true;
          errors++;
        }
      }
    }
    //  Check error in Row Header
    for (let i = 0; i < updated_data.riskRows.length; i++) {
      if (!updated_data.riskRows[i].rowName) {
        updated_data.riskRows[i].isError = true;
        errors++;
      }
    }

    // Check error in Column Header
    for (let i = 0; i < updated_data.riskColumns.length; i++) {
      if (!updated_data.riskColumns[i].colName) {
        updated_data.riskColumns[i].isError = true;
        errors++;
      }
    }

    setData(updated_data);
    return errors;
  }

  async function addNewRow() {
    let updated_data = { ...data };

    let num_levels = updated_data.riskColumns.map((item, itemIndex) => ({
      colNo: itemIndex + 1,
      riskLevel: null,
    }));
    updated_data.riskRows = [
      ...updated_data.riskRows,
      {
        rowOrder: updated_data.riskRows.length + 1,
        rowName: "New Row",
        levels: num_levels,
      },
    ];
    setData(updated_data);
  }

  async function deleteRow(row_index) {
    let updated_data = { ...data };
    updated_data.riskRows.splice(row_index, 1);
    setData(updated_data);
  }

  async function addColumn() {
    let updated_data = { ...data };

    updated_data.riskColumns = [
      ...updated_data.riskColumns,
      {
        colOrder: updated_data.riskColumns.length + 1,
        colName: "New Col",
      },
    ];

    for (let i = 0; i < updated_data.riskRows.length; i++) {
      updated_data.riskRows[i].levels = [
        ...updated_data.riskRows[i].levels,
        {
          colNo: updated_data.riskColumns.length + 1,
          riskLevel: null,
        },
      ];
    }

    setData(updated_data);
  }

  async function deleteColumn(col_index) {
    let updated_data = { ...data };
    updated_data.riskColumns.splice(col_index, 1);

    for (let i = 0; i < updated_data.riskRows.length; i++) {
      updated_data.riskRows[i].levels.splice(col_index, 1);
    }
    setData(updated_data);
  }

  const [prevColumnCount, setPrevColumnCount] = useState(0);
  useEffect(() => {
    setPrevColumnCount(data.riskColumns.length);
  }, []);

  useEffect(() => {
    if (prevColumnCount < data.riskColumns.length) {
      var div = document.getElementById("risk-matrix-table");
      if (div) {
        div.scrollTo({
          left: div.scrollWidth - div.clientWidth,
          behavior: "smooth",
        });
      }
    }
    setPrevColumnCount(data.riskColumns.length);
  }, [data.riskColumns.length]);

  function isAlreadySelected(_id) {
    // Write code using array.some() to check if the element is already present in the array or not
    // If the element is present in the array, return true, else return false

    return data?.riskRows?.some((parentRow, parentIndex) => {
      return parentRow?.levels?.some((childRowEl, childIndex) => {
        if (childRowEl?.riskLevel?.id === _id) {
          return true;
        } else {
          return false;
        }
      });
    });
  }
  return (
    <Modal
      show={show}
      dialogClassName="risk-matrix-modal"
      className=""
      aria-labelledby=" "
      centered
      scrollable
      onHide={() => {
        hideModal();
      }}
    >
      <Modal.Header>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="f-16 f-500">Risk Matrix</div>
          <div>
            {viewOnlyAccess("AccessState_SCM") ? (
              <></>
            ) : (
              <>
                <Button
                  className="hg-cancel-btn mr-2 radius-2"
                  onClick={hideModal}
                >
                  Cancel
                </Button>
                <Button
                  className="hg-blue-btn radius-2 mr-2"
                  onClick={async () => {
                    let errors = await checkError();
                    if (errors === 0) {
                      saveModalData();
                    } else {
                      let error_elements =
                        document.getElementsByClassName(`input-error`);
                      if (error_elements.length > 0) {
                        // error_elements[0].scrollIntoView({
                        //   behavior: "smooth",
                        //   block: "center",
                        //   inline: "nearest",
                        // });
                      }
                    }
                  }}
                >
                  {saving ? "Saving.." : "Save"}
                </Button>
              </>
            )}

            <img
              alt=""
              src="/images/user-detail-close.svg"
              className=" pointer"
              onClick={hideModal}
            />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="hide-scrollbar p-0 position-relative overflow-hidden">
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center w-100 h-100"
            // style={{ height: "89vh" }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <div className="w-100 position-relative p-3 overflow-y-auto hide-scrollbar">
              <div className="d-flex align-items-center justify-content-between px-3 py-3">
                <div className="f-16 f-500" onClick={() => {}}>
                  Risk Matrix
                </div>
                <OverlayTrigger
                  placement="left"
                  trigger={
                    viewOnlyAccess("AccessState_SCM") ? ["hover", "focus"] : []
                  }
                  delay={{
                    show: 200,
                    hide: 250,
                  }}
                  overlay={
                    <Tooltip
                      id={`tooltip-1365`}
                      className="custom_tooltip_access"
                    >
                      This feature is managed by your service provider
                    </Tooltip>
                  }
                >
                  <div>
                    <Button
                      className={`radius-2 mr-2 ${
                        viewOnlyAccess("AccessState_SCM")
                          ? "btn-disable"
                          : "hg-cancel-btn "
                      } `}
                      onClick={() => {
                        setShowSidebar(true);
                      }}
                    >
                      {viewOnlyAccess("AccessState_SCM") ? (
                        <img
                          alt=""
                          src="/images/attack-surface/small-lock.svg"
                          className="mr-2"
                        />
                      ) : (
                        <img
                          src="/images/settings-v2/settingwheel.svg"
                          alt=""
                          className="mr-2"
                        />
                      )}
                      Edit Risk levels
                    </Button>
                  </div>
                </OverlayTrigger>
              </div>
              {/* Table */}
              <div className="d-flex align-items-start">
                <div
                  className="d-flex align-items-center justify-content-center f-12 f-500 f-darkgrey my-auto"
                  style={{
                    transform: "rotate(-90deg)",
                    width: "20px",
                  }}
                >
                  Likelihood
                </div>
                <div className="border-righ t w-165">
                  <div style={{ color: "transparent" }}>xxx</div>
                  <div className="d-flex align-items-center p-4 f-grey f-500 h-4 f-white"></div>
                  {data.riskRows.map((rowHeader, rowHeaderIndex) => {
                    return (
                      <>
                        {/* {rowHeader.isEdit ? (
                          <div
                            className="d-flex align-items-center justify-content-between highlight-input border-bottom cp-25 m- 1  h-4"
                            onBlur={() => {
                              let updated_data = { ...data };
                              updated_data.riskRows[
                                rowHeaderIndex
                              ].isEdit = false;
                              setData(updated_data);
                            }}
                          >
                            <input
                              type="text"
                              className="w-100 border-none bg-transparent"
                              value={rowHeader.rowName}
                              onChange={(e) => {
                                let updated_data = { ...data };
                                updated_data.riskRows[rowHeaderIndex].rowName =
                                  e.target.value;
                                updated_data.riskRows[
                                  rowHeaderIndex
                                ].isError = false;

                                setData(updated_data);
                              }}
                              autoFocus
                            />

                            <div className=" ">
                              <img
                                src="/images/user-detail-close.svg"
                                className="pointer"
                                alt=""
                                onClick={(e) => {
                                  // e.stopPropagation();
                                  // deleteRow(rowHeaderIndex);
                                  setShowDeleteModal(true);
                                  setDeleteQuery({
                                    isColumn: false,
                                    index: rowHeaderIndex,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        ) : ( */}

                        <>
                          <div
                            className={`
                              
                              w-100 
                              d-flex align-items-center justify-content-center border-bottom      h-4 parent-visible
                              ${
                                rowHeader.isError ? "input-error radius-4" : ""
                              }`}
                            onClick={() => {
                              return;
                            }}
                          >
                            <div className="d-flex align-items-center w-100 pl-2 justify-content-between f-black">
                              <div>{TrimString(rowHeader?.rowName, 16)}</div>

                              <Dropdown className="transparennt-wrapper-dropdown">
                                <Dropdown.Toggle className="w-100">
                                  <div className="child-visible">
                                    <img
                                      src="/images/settings-v2/horizontal-dots.svg"
                                      className="mr-2"
                                      alt=""
                                      onClick={(e) => {
                                        // e.stopPropagation();
                                        // // deleteColumn(columnHeaderIndex);
                                        // setShowDeleteModal(true);
                                        // setDeleteQuery({
                                        //   isColumn: true,
                                        //   index: rowHeaderIndex,
                                        // });
                                      }}
                                    />
                                  </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  className="p-0"
                                  style={{
                                    width: `${
                                      rowHeader.isEdit ? "300px" : "auto"
                                    }`,
                                    minWidth: "80px",
                                  }}
                                >
                                  {rowHeader.isEdit ? (
                                    <>
                                      <div className="p-2">
                                        <div className="f-12 f-500 f-darkgrey">
                                          Likelihood
                                        </div>
                                        <div className="d-flex align-items-center search-supplier-input highlight-input-with-grey-border radius-4 mb-1 p-2">
                                          <input
                                            type="text"
                                            className=" w-100 border-none bg-transparent"
                                            placeholder="Type"
                                            value={rowHeader?.rowName}
                                            onChange={(e) => {
                                              let updated_data = {
                                                ...data,
                                              };
                                              updated_data.riskRows[
                                                rowHeaderIndex
                                              ].rowName = e.target.value;
                                              updated_data.riskRows[
                                                rowHeaderIndex
                                              ].isError = false;
                                              setData(updated_data);
                                            }}
                                            autoFocus
                                          />
                                        </div>

                                        <div className="d-flex align-items-center search-supplier-input highlight-input-with-grey-border mt- radius-4">
                                          <textarea
                                            type="text"
                                            rows={2}
                                            className=" w-100 border-none bg-transparent p-2"
                                            placeholder="Type"
                                            value={
                                              rowHeader?.levelToolTip ?? ""
                                            }
                                            onChange={(e) => {
                                              let updated_data = {
                                                ...data,
                                              };
                                              updated_data.riskRows[
                                                rowHeaderIndex
                                              ].levelToolTip = e.target.value;

                                              setData(updated_data);
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <hr className="mb-0 mt-2" />

                                      <div className="d-flex align-items-center justify-content-between p-2">
                                        <div
                                          className="d-flex align-items-center f-500 f-grey pointer"
                                          onClick={() => {
                                            setShowDeleteModal(true);
                                            setDeleteQuery({
                                              isRow: true,
                                              index: rowHeaderIndex,
                                            });
                                          }}
                                        >
                                          <img
                                            src="/images/actioncenter/delete.svg"
                                            alt=""
                                            className="mr-2"
                                          />
                                          Delete
                                        </div>
                                        <div className="d-flex align-items-center">
                                          <Button
                                            className="hg-cancel-btn mr-2 radius-2"
                                            onClick={() => {
                                              setShowDeleteModal(true);
                                              setDeleteQuery({
                                                isRow: true,
                                                index: rowHeaderIndex,
                                              });
                                            }}
                                          >
                                            Delete{" "}
                                          </Button>
                                          <Button
                                            className="hg-blue-btn radius-2"
                                            disabled={rowHeader.rowName === ""}
                                            onClick={(e) => {
                                              e.target
                                                .closest(
                                                  ".transparennt-wrapper-dropdown"
                                                )
                                                .click();
                                            }}
                                          >
                                            Save
                                          </Button>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="mx-2 my-2 pointer">
                                      <div
                                        className="f-grey f-500 mb-2"
                                        onClick={() => {
                                          let updated_data = { ...data };
                                          updated_data.riskRows[
                                            rowHeaderIndex
                                          ].isEdit = true;
                                          setData(updated_data);
                                        }}
                                      >
                                        Edit
                                      </div>
                                      <div
                                        className="f-danger f-500"
                                        onClick={() => {
                                          setShowDeleteModal(true);
                                          setDeleteQuery({
                                            isRow: true,
                                            index: rowHeaderIndex,
                                          });
                                        }}
                                      >
                                        Delete
                                      </div>
                                    </div>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>

                          {/* <div
                            className="d-flex align-items-center justify-content-center border-bottom      h-4 parent-visible"
                            onClick={() => {
                              let updated_data = { ...data };
                              updated_data.riskRows[
                                rowHeaderIndex
                              ].isEdit = true;
                              setData(updated_data);
                            }}
                          >
                            <div
                              className={`d-flex align-items-center justify-content-between p-3 w-100 ${
                                rowHeader.isError ? "input-error radius-4" : ""
                              }`}
                            >
                              <div>{rowHeader.rowName}</div>
                              <div className="child-visible">
                                <img
                                  src="/images/user-detail-close.svg"
                                  className=" pointer"
                                  alt=""
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowDeleteModal(true);
                                    setDeleteQuery({
                                      isColumn: false,
                                      index: rowHeaderIndex,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div> */}
                        </>
                      </>
                    );
                  })}

                  <OverlayTrigger
                    placement="bottom"
                    trigger={
                      viewOnlyAccess("AccessState_SCM")
                        ? ["hover", "focus"]
                        : []
                    }
                    delay={{
                      show: 200,
                      hide: 250,
                    }}
                    overlay={
                      <Tooltip
                        id={`tooltip-1365`}
                        className="custom_tooltip_access_matrix"
                      >
                        This feature is managed by your service provider
                      </Tooltip>
                    }
                  >
                    <div>
                      <div
                        className="d-flex align-items-center cp-25 f-grey f-500 pointer   "
                        style={{
                          pointerEvents: viewOnlyAccess("AccessState_SCM")
                            ? "none"
                            : "all",
                        }}
                        onClick={() => {
                          addNewRow();
                        }}
                      >
                        {viewOnlyAccess("AccessState_SCM") ? (
                          <img
                            alt=""
                            src="/images/attack-surface/small-lock.svg"
                            className="mr-2"
                          />
                        ) : (
                          <img
                            src="/images/msp/grey-plus-icon.svg"
                            alt=""
                            className="mr-2"
                          />
                        )}
                        Add
                      </div>
                    </div>
                  </OverlayTrigger>
                </div>
                {/* ---------------Table------------- */}
                <div
                  className={`transparent-scrolle r hide-scrollbar  ${
                    data.riskColumns.length > 3 ? " overflow-x-auto  " : ""
                  } `}
                  id="risk-matrix-table"
                  style={{
                    width: "auto",
                    maxWidth: "72%",
                  }}
                >
                  <div className="d-flex flex-column w-max-content position-relative">
                    <div className="d-flex align-items-center justify-content-center f-12 f-500 f-darkgrey">
                      Impact
                    </div>
                    {/* header */}
                    <div className="d-flex align-items-center h-4">
                      {data.riskColumns.map(
                        (columnHeader, columnHeaderIndex) => {
                          return (
                            <>
                              {/* {columnHeader.isEdit ? (
                                <div className="d-flex align-items-center highlight-input justify-content-between border-right cp-25 w-165 m-1  ">
                                  <input
                                    type="text"
                                    className="w-100 border-none bg-transparent"
                                    value={columnHeader.colName}
                                    onChange={(e) => {
                                      let updated_data = { ...data };
                                      updated_data.riskColumns[
                                        columnHeaderIndex
                                      ].colName = e.target.value;
                                      updated_data.riskColumns[
                                        columnHeaderIndex
                                      ].isError = false;
                                      setData(updated_data);
                                    }}
                                    onBlur={() => {
                                      let updated_data = { ...data };
                                      updated_data.riskColumns[
                                        columnHeaderIndex
                                      ].isEdit = false;
                                      setData(updated_data);
                                    }}
                                    autoFocus
                                  />
                                  <div className=" ">
                                    <img
                                      src="/images/user-detail-close.svg"
                                      className="pointer"
                                      alt=""
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setShowDeleteModal(true);
                                        setDeleteQuery({
                                          isColumn: true,
                                          index: columnHeaderIndex,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : ( */}
                              <div
                                className={`d-flex align-items-center justify-content-center  py-3 w-165 m-1 pointer parent-visible  ${
                                  columnHeader.isError
                                    ? "input-error radius-4"
                                    : ""
                                }`}
                                onClick={() => {
                                  return;
                                }}
                              >
                                <div className="d-flex align-items-center w-100 pl-2 justify-content-between border-right f-black">
                                  <div>
                                    {TrimString(columnHeader.colName, 16)}
                                  </div>

                                  <Dropdown
                                    className="transparennt-wrapper-dropdown"
                                    onToggle={() => {
                                      let updated_data = { ...data };
                                      updated_data.riskColumns[
                                        columnHeaderIndex
                                      ].isEdit = false;
                                      setData(updated_data);
                                    }}
                                  >
                                    <Dropdown.Toggle>
                                      <div className="child-visible">
                                        <img
                                          src="/images/settings-v2/horizontal-dots.svg"
                                          className="mr-2"
                                          alt=""
                                          onClick={(e) => {}}
                                        />
                                      </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                      className="p-0"
                                      style={{
                                        width: `${
                                          columnHeader.isEdit ? "300px" : "auto"
                                        }`,
                                        minWidth: "80px",
                                      }}
                                    >
                                      {columnHeader.isEdit ? (
                                        <>
                                          <div className="p-2">
                                            <div className="f-12 f-500 f-darkgrey">
                                              Impact
                                            </div>
                                            <div className="d-flex align-items-center search-supplier-input highlight-input-with-grey-border radius-4 mb-1 p-2">
                                              <input
                                                type="text"
                                                className=" w-100 border-none bg-transparent"
                                                placeholder="Type"
                                                value={columnHeader?.colName}
                                                onChange={(e) => {
                                                  let updated_data = {
                                                    ...data,
                                                  };
                                                  updated_data.riskColumns[
                                                    columnHeaderIndex
                                                  ].colName = e.target.value;
                                                  updated_data.riskColumns[
                                                    columnHeaderIndex
                                                  ].isError = false;
                                                  setData(updated_data);
                                                }}
                                                autoFocus
                                              />
                                            </div>

                                            <div className="d-flex align-items-center search-supplier-input highlight-input-with-grey-border mt- radius-4">
                                              <textarea
                                                type="text"
                                                rows={2}
                                                className=" w-100 border-none bg-transparent p-2"
                                                placeholder="Type"
                                                value={
                                                  columnHeader?.levelToolTip ??
                                                  ""
                                                }
                                                onChange={(e) => {
                                                  let updated_data = {
                                                    ...data,
                                                  };
                                                  updated_data.riskColumns[
                                                    columnHeaderIndex
                                                  ].levelToolTip =
                                                    e.target.value;

                                                  setData(updated_data);
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <hr className="mb-0 mt-2" />

                                          <div className="d-flex align-items-center justify-content-between p-2">
                                            <div
                                              className="d-flex align-items-center f-500 f-grey pointer"
                                              onClick={() => {
                                                setShowDeleteModal(true);
                                                setDeleteQuery({
                                                  isColumn: true,
                                                  index: columnHeaderIndex,
                                                });
                                              }}
                                            >
                                              <img
                                                src="/images/actioncenter/delete.svg"
                                                alt=""
                                                className="mr-2"
                                              />
                                              Delete
                                            </div>
                                            <div className="d-flex align-items-center">
                                              <Button
                                                className="hg-cancel-btn mr-2 radius-2"
                                                onClick={() => {
                                                  setShowDeleteModal(true);
                                                  setDeleteQuery({
                                                    isColumn: true,
                                                    index: columnHeaderIndex,
                                                  });
                                                }}
                                              >
                                                Delete{" "}
                                              </Button>
                                              <Button
                                                className="hg-blue-btn radius-2"
                                                disabled={
                                                  columnHeader.colName === ""
                                                }
                                                onClick={(e) => {
                                                  e.target
                                                    .closest(
                                                      ".transparennt-wrapper-dropdown"
                                                    )
                                                    .click();
                                                }}
                                              >
                                                Save
                                              </Button>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <div className="mx-2 my-2 pointer">
                                          <div
                                            className="f-grey f-500 mb-2"
                                            onClick={() => {
                                              let updated_data = { ...data };
                                              updated_data.riskColumns[
                                                columnHeaderIndex
                                              ].isEdit = true;
                                              setData(updated_data);
                                            }}
                                          >
                                            Edit
                                          </div>
                                          <div
                                            className="f-danger f-500"
                                            onClick={() => {
                                              setShowDeleteModal(true);
                                              setDeleteQuery({
                                                isColumn: true,
                                                index: columnHeaderIndex,
                                              });
                                            }}
                                          >
                                            Delete
                                          </div>
                                        </div>
                                      )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                              {/* // )} */}
                            </>
                          );
                        }
                      )}
                    </div>
                    {/* Body */}

                    {data.riskRows.map((rowHeader, rowHeaderIndex) => {
                      return (
                        <div className="d-flex align-items-center h-4 border-bottom-transparent">
                          {rowHeader.levels.map(
                            (columnHeader, columnHeaderIndex) => {
                              return (
                                <>
                                  <div className="w-165 m-1">
                                    <RiskLevelDropdown
                                      riskLevelList={riskLevelList}
                                      isError={columnHeader?.isError}
                                      selected={columnHeader.riskLevel}
                                      onChange={(val) => {
                                        let updated_data = { ...data };
                                        updated_data.riskRows[
                                          rowHeaderIndex
                                        ].levels[columnHeaderIndex].riskLevel =
                                          val;
                                        updated_data.riskRows[
                                          rowHeaderIndex
                                        ].levels[
                                          columnHeaderIndex
                                        ].isError = false;
                                        setData(updated_data);
                                      }}
                                      onClickEdit={() => {
                                        setShowSidebar(true);
                                      }}
                                    />
                                  </div>
                                </>
                              );
                            }
                          )}
                        </div>
                      );
                    })}
                  </div>

                  <div className="p-5" />
                </div>
                {/* Add for column */}
                <OverlayTrigger
                  placement="bottom"
                  trigger={
                    viewOnlyAccess("AccessState_SCM") ? ["hover", "focus"] : []
                  }
                  delay={{
                    show: 200,
                    hide: 250,
                  }}
                  overlay={
                    <Tooltip
                      id={`tooltip-1365`}
                      className="custom_tooltip_access"
                    >
                      This feature is managed by your service provider
                    </Tooltip>
                  }
                >
                  <div>
                    <div
                      className="d-flex align-items-center cp-25 f-grey f-500 h-4 pointer mt-4"
                      style={{
                        pointerEvents: viewOnlyAccess("AccessState_SCM")
                          ? "none"
                          : "all",
                      }}
                      onClick={() => {
                        addColumn();
                      }}
                    >
                      {viewOnlyAccess("AccessState_SCM") ? (
                        <img
                          alt=""
                          src="/images/attack-surface/small-lock.svg"
                          className="mr-2"
                        />
                      ) : (
                        <img
                          src="/images/msp/grey-plus-icon.svg"
                          alt=""
                          className="mr-2"
                        />
                      )}
                      Add
                    </div>
                  </div>
                </OverlayTrigger>
              </div>
            </div>

            {/* SidePanel */}
            <div
              className={`position-absolute right-0 bg-white bottom-0 border-left top-0 risk-matrix-sidebar ${
                showSidebar ? "show" : "hide"
              }`}
            >
              <div className="d-flex align-items-center justify-content-between border-bottom p-3">
                <div className="f-500 f-black">
                  <img
                    src="/images/settings-v2/settingwheel.svg"
                    alt=""
                    className="mr-2"
                    style={{}}
                  />
                  Risk Levels
                </div>
                <div>
                  <img
                    alt=""
                    src="/images/user-detail-close.svg"
                    className=" pointer"
                    onClick={() => {
                      setShowSidebar(false);
                    }}
                  />
                </div>
              </div>
              {/* Add Risk Drodown option */}

              {/* List of all levels */}

              <div
                className="w-100   hide-scrollbar overflow-y-auto p-3 zoom-independent-"
                style={{
                  height: "calc(100% - 49px)",
                }}
              >
                <div className="px-3 pb-3">
                  <AddEditRiskLevelDropdown
                    isAlreadySelected={isAlreadySelected}
                    createEditDeleteRiskLevel={createEditDeleteRiskLevel}
                    setDeleteQuery={setDeleteQuery}
                    hideDeleteOption={true}
                    colourList={colourList}
                    selectedRiskLevel={{
                      id: 0,
                      sortOrder: 0,
                      levelName: "",
                      levelColour: "",
                      levelToolTip: "",
                      new: true,
                    }}
                  >
                    <div className="f-12 f-500 f-darkgrey">Risk Levels</div>
                    <div>
                      <img
                        src="/images/msp/grey-plus-icon.svg"
                        alt=""
                        className="mr-2"
                      />
                    </div>
                  </AddEditRiskLevelDropdown>
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable
                    direction="vertical"
                    droppableId="droppableSection"
                  >
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}

                        // style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {riskLevelList &&
                          riskLevelList?.map((section, i) => (
                            <Draggable
                              key={section?.sortOrder.toString()}
                              draggableId={section?.sortOrder.toString()}
                              index={i}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  // style={getItemStyle(
                                  //   snapshot.isDragging,
                                  //   provided.draggableProps.style
                                  // )}
                                  className="list_item d-flex justify-content-between  p-2 parent-visible radius-4 bg-hover-grey"
                                  key={i}
                                  index={i}
                                >
                                  <div className="d-flex align-items-center">
                                    <img
                                      src="/images/actioncenter/contextmenu.svg"
                                      alt=""
                                      className="mr-2"
                                    />
                                    <div
                                      className="mr-2"
                                      style={{
                                        width: "10px",
                                        height: "10px",
                                        borderRadius: "50%",
                                        backgroundColor: section.levelColour,
                                      }}
                                    />
                                    {section.levelName}
                                  </div>
                                  <div
                                    className="d-flex align-items-center justify-content-center pointer"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <AddEditRiskLevelDropdown
                                      isAlreadySelected={isAlreadySelected}
                                      createEditDeleteRiskLevel={
                                        createEditDeleteRiskLevel
                                      }
                                      colourList={colourList}
                                      onDeleteItem={(_riskLevel) => {
                                        setDeleteQuery({
                                          isColumn: null,
                                          riskLevel: _riskLevel,
                                        });
                                        setShowDeleteModal(true);
                                      }}
                                      selectedRiskLevel={section}
                                    >
                                      <img
                                        src="/images/msp/dark-pencil.svg"
                                        alt=""
                                        className="mr-2 child-visible"
                                      />
                                    </AddEditRiskLevelDropdown>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      {/* <Modal.Footer className="p-2">
        <div className="d-flex align-items-center justify-content-end">
          <Button className="hg-cancel-btn mr-2" onClick={hideModal}>
            Cancel
          </Button>
          <Button className="hg-blue-btn">
            {" "}
            {saving ? (
              <>
                <WhiteLoader width={13} />
                Saving..
              </>
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </Modal.Footer> */}
      <DeleteModalWithBackdrop
        modalHeader={"Delete Confirmation"}
        deleteLoading={deleteQuery?.loading}
        show={showDeleteModal}
        hideModal={() => setShowDeleteModal(false)}
        onDelete={() => {
          if (deleteQuery.isColumn !== null) {
            if (deleteQuery.isColumn) {
              deleteColumn(deleteQuery.index);
            } else {
              deleteRow(deleteQuery.index);
            }

            setShowDeleteModal(false);
          } else {
            setDeleteQuery({
              ...deleteQuery,
              loading: true,
            });
            createEditDeleteRiskLevel({
              optionEnum: 2,
              riskLevel: deleteQuery?.riskLevel,
            })
              .then(() => {
                setDeleteQuery({
                  ...deleteQuery,
                  loading: false,
                });
                CallToast("Updated successfully", false, false, "v2style");
                setShowDeleteModal(false);
              })
              .catch(() => {
                setDeleteQuery({
                  ...deleteQuery,
                  loading: false,
                });
                CallToast("Something went wrong", false, false, "v2style");
              });
          }
        }}
        // deleteMessage={deleteMessage}
        //  deleteType={"deleteQuestionnaireTemplate"}
      />
    </Modal>
  );
};

export default RiskMatrix;

const RiskLevelDropdown = ({
  selected,
  onChange,
  isError,
  onClickEdit = () => {},
  riskLevelList,
}) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  function getSelectedById(_id) {
    return riskLevelList.find((item) => item.id === _id);
  }
  return (
    <Dropdown
      className="transparennt-wrapper-dropdown"
      onToggle={(e) => {
        setIsDropDownOpen(e);
      }}
    >
      <Dropdown.Toggle className="w-100">
        {selected ? (
          <div
            className="d-flex align-items-center justify-content-center f-500 f-black radius-4 py-3"
            style={{
              backgroundColor: getSelectedById(selected.id)?.levelColour,
            }}
          >
            {TrimString(getSelectedById(selected.id)?.levelName, 15)}{" "}
            <img
              src="/images/msp/black-chevron-down.svg"
              alt=""
              className={`ml-2 ${isDropDownOpen ? "rotate180" : ""} `}
            />
          </div>
        ) : (
          <div
            className={`d-flex align-items-center justify-content-center f-14 f-500 f-grey bg-grey ${
              isError ? "input-error" : ""
            }  radius-4 py-3`}
          >
            Set risk{" "}
            <img
              src="/images/msp/black-chevron-down.svg"
              alt=""
              className={`ml-2 ${isDropDownOpen ? "rotate180" : ""} `}
            />
          </div>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="p-0"
        style={{
          width: "215px",
        }}
      >
        <div
          className="transparent-scrolle r hide-scrollbar "
          style={{
            maxHeight: "170px",
            overflowY: "auto",
          }}
        >
          {riskLevelList?.map((item, itemIndex) => {
            return (
              <div
                className="d-flex align-items-center justify-content-between pl-3 my-2 pointer f-grey f-500"
                onClick={(e) => {
                  onChange(item);
                  e.target.closest(".transparennt-wrapper-dropdown").click();
                }}
              >
                <div className="d-flex align-items-center">
                  <div
                    className="mr-2"
                    style={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      backgroundColor: item.levelColour,
                    }}
                  />{" "}
                  {item.levelName}
                </div>
                <div>
                  {selected?.id === item.id && (
                    <img
                      src="/images/msp/dropdown-grey-tick.svg"
                      alt=""
                      className="mr-2"
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div
          className="w-100 my-2"
          style={{
            borderBottom: "1px solid #EBEDF1",
          }}
        />

        <div
          className="d-flex align-items-center f-grey f-12 f-500 w-100 pointer px-3 mb-3"
          onClick={(e) => {
            onClickEdit();
            e.target.closest(".transparennt-wrapper-dropdown").click();
          }}
        >
          <img
            src="/images/settings-v2/settingwheel.svg"
            alt=""
            className="mr-2"
          />
          Edit Risk levels
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const AddEditRiskLevelDropdown = ({
  createEditDeleteRiskLevel,
  children,
  hideDeleteOption,
  onDeleteItem = () => {},
  colourList = [],
  selectedRiskLevel,
  isAlreadySelected = () => {},
}) => {
  const [riskLevel, setRiskLevel] = useState(selectedRiskLevel);
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    setRiskLevel(selectedRiskLevel);
  }, [selectedRiskLevel]);

  return (
    <Dropdown
      className="transparennt-wrapper-dropdown"
      onToggle={(e) => {
        if (e) {
          setRiskLevel(selectedRiskLevel);
        }
      }}
    >
      <Dropdown.Toggle className="w-100 d-flex align-items-center justify-content-between">
        {children}
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 risk-edit-drop">
        <div className="highlight-input-with-grey-border p-2 radius-4 m-1">
          <input
            type="text"
            className="border-none bg-transparent"
            placeholder="Risk Level Name"
            value={riskLevel?.levelName}
            onChange={(e) => {
              setRiskLevel({
                ...riskLevel,
                levelName: e.target.value,
              });
            }}
            autoFocus
          />
        </div>

        {/* isAlreadySelected */}
        {!hideDeleteOption && (
          <AttackSurfaceTooltip
            disabled={!isAlreadySelected(selectedRiskLevel?.id)}
            className={"w-100"}
            //  bottomLeft={true}
            content={"Value cannot be deleted as it is already in use."}
            toolTipStyle={{
              top: "110%",
              left: "16%",
            }}
          >
            <div
              className="d-flex align-items-center f-grey w-100 p-2 mb-2"
              onClick={(e) => {
                if (isAlreadySelected(selectedRiskLevel?.id)) return;
                onDeleteItem(selectedRiskLevel);
                e.target.closest(".transparennt-wrapper-dropdown").click();
              }}
              style={{
                cursor: isAlreadySelected(selectedRiskLevel?.id)
                  ? "not-allowed"
                  : "pointer",
              }}
            >
              <img
                alt=""
                className="mr-2"
                src="/images/actioncenter/delete.svg"
              />
              Delete
            </div>
          </AttackSurfaceTooltip>
        )}
        <div
          className="w-90 mx-auto "
          style={{
            borderBottom: "1px solid #EBEDF1",
          }}
        />

        <div
          className="transparent-scroller overflow-y-auto"
          style={{
            maxHeight: "300px",
          }}
        >
          {colourList?.map((item, itemIndex) => {
            return (
              <div
                className="d-flex align-items-center justify-content-between pl-3 my-2 pointer f-grey f-500"
                onClick={(e) => {
                  setRiskLevel({
                    ...riskLevel,
                    levelColour: item.colourCode,
                  });
                  // e.target.closest(".transparennt-wrapper-dropdown").click();
                }}
              >
                <div className="d-flex align-items-center">
                  <div
                    className="mr-2"
                    style={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      backgroundColor: item.colourCode,
                    }}
                  />{" "}
                  {item.colorName}
                </div>

                <div>
                  {item?.colourCode === riskLevel?.levelColour && (
                    <img
                      src="/images/msp/dropdown-grey-tick.svg"
                      alt=""
                      className="mr-2"
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>

        <div
          className="w-100 "
          style={{
            borderBottom: "1px solid #EBEDF1",
          }}
        />
        <div className="p-2">
          <Button
            className="hg-blue-btn radius-2 w-100"
            disabled={
              !riskLevel?.levelName || !riskLevel?.levelColour || saving
            }
            onClick={(e) => {
              let event = e.target;
              setSaving(true);
              createEditDeleteRiskLevel({
                optionEnum: riskLevel?.new ? 0 : 1,
                riskLevel,
              })
                .then(() => {
                  setSaving(false);
                  CallToast("Updated successfully", false, false, "v2style");
                  event.closest(".transparennt-wrapper-dropdown").click();
                })
                .catch(() => {
                  setSaving(false);
                  CallToast("Something went wrong", false, false, "v2style");
                });
            }}
          >
            {saving ? "Saving.." : "Save"}
          </Button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const _data = {
  riskColumns: [
    {
      colOrder: 1,
      colName: "Negligible",
    },
    {
      colOrder: 2,
      colName: "Marginal",
    },
    {
      colOrder: 3,
      colName: "Moderate",
    },
    {
      colOrder: 4,
      colName: "Critical",
    },
    {
      colOrder: 5,
      colName: "Catastrophic",
    },
  ],
  riskRows: [
    {
      rowOrder: 1,
      rowName: "Almost Certain",
      levels: [
        {
          colNo: 1,
          riskLevel: {
            id: 0,
            sortOrder: 0,
            levelName: "Extreme Risk",
            levelColour: "#F36060",
          },
        },
        {
          colNo: 2,
          riskLevel: {
            id: 1,
            sortOrder: 1,
            levelName: "High Risk",
            levelColour: "#FFAC2C",
          },
        },
        {
          colNo: 3,
          riskLevel: {
            id: 2,
            sortOrder: 2,
            levelName: "Moderate Risk",
            levelColour: "#FFDEAB",
          },
        },
        {
          colNo: 4,
          riskLevel: {
            id: 3,
            sortOrder: 3,
            levelName: "Minimum Risk",
            levelColour: "#40D88E",
          },
        },
        {
          colNo: 5,
          riskLevel: {
            id: 4,
            sortOrder: 4,
            levelName: "Low Risk",
            levelColour: "#E0F9ED",
          },
        },
      ],
    },
    {
      rowOrder: 2,
      rowName: "Likely",
      levels: [
        {
          colNo: 1,
          riskLevel: {
            id: 0,
            sortOrder: 0,
            levelName: "Extreme Risk",
            levelColour: "#F36060",
          },
        },
        {
          colNo: 2,
          riskLevel: {
            id: 1,
            sortOrder: 1,
            levelName: "High Risk",
            levelColour: "#FFAC2C",
          },
        },
        {
          colNo: 3,
          riskLevel: {
            id: 2,
            sortOrder: 2,
            levelName: "Moderate Risk",
            levelColour: "#FFDEAB",
          },
        },
        {
          colNo: 4,
          riskLevel: {
            id: 3,
            sortOrder: 3,
            levelName: "Minimum Risk",
            levelColour: "#40D88E",
          },
        },
        {
          colNo: 5,
          riskLevel: {
            id: 4,
            sortOrder: 4,
            levelName: "Low Risk",
            levelColour: "#E0F9ED",
          },
        },
      ],
    },
    {
      rowOrder: 3,
      rowName: "Possible",
      levels: [
        {
          colNo: 1,
          riskLevel: {
            id: 0,
            sortOrder: 0,
            levelName: "Extreme Risk",
            levelColour: "#F36060",
          },
        },
        {
          colNo: 2,
          riskLevel: {
            id: 1,
            sortOrder: 1,
            levelName: "High Risk",
            levelColour: "#FFAC2C",
          },
        },
        {
          colNo: 3,
          riskLevel: null,

          // {
          //     "id": 2,
          //     "sortOrder": 2,
          //     "levelName": "Moderate Risk",
          //     "levelColour": "#FFDEAB"
          // }
        },
        {
          colNo: 4,
          riskLevel: {
            id: 3,
            sortOrder: 3,
            levelName: "Minimum Risk",
            levelColour: "#40D88E",
          },
        },
        {
          colNo: 5,
          riskLevel: {
            id: 4,
            sortOrder: 4,
            levelName: "Low Risk",
            levelColour: "#E0F9ED",
          },
        },
      ],
    },
    {
      rowOrder: 4,
      rowName: "Unlikely",
      levels: [
        {
          colNo: 1,
          riskLevel: {
            id: 0,
            sortOrder: 0,
            levelName: "Extreme Risk",
            levelColour: "#F36060",
          },
        },
        {
          colNo: 2,
          riskLevel: {
            id: 1,
            sortOrder: 1,
            levelName: "High Risk",
            levelColour: "#FFAC2C",
          },
        },
        {
          colNo: 3,
          riskLevel: {
            id: 2,
            sortOrder: 2,
            levelName: "Moderate Risk",
            levelColour: "#FFDEAB",
          },
        },
        {
          colNo: 4,
          riskLevel: {
            id: 3,
            sortOrder: 3,
            levelName: "Minimum Risk",
            levelColour: "#40D88E",
          },
        },
        {
          colNo: 5,
          riskLevel: {
            id: 4,
            sortOrder: 4,
            levelName: "Low Risk",
            levelColour: "#E0F9ED",
          },
        },
      ],
    },
    {
      rowOrder: 5,
      rowName: "Rare",
      levels: [
        {
          colNo: 1,
          riskLevel: {
            id: 0,
            sortOrder: 0,
            levelName: "Extreme Risk",
            levelColour: "#F36060",
          },
        },
        {
          colNo: 2,
          riskLevel: {
            id: 1,
            sortOrder: 1,
            levelName: "High Risk",
            levelColour: "#FFAC2C",
          },
        },
        {
          colNo: 3,
          riskLevel: {
            id: 2,
            sortOrder: 2,
            levelName: "Moderate Risk",
            levelColour: "#FFDEAB",
          },
        },
        {
          colNo: 4,
          riskLevel: {
            id: 3,
            sortOrder: 3,
            levelName: "Minimum Risk",
            levelColour: "#40D88E",
          },
        },
        {
          colNo: 5,
          riskLevel: {
            id: 4,
            sortOrder: 4,
            levelName: "Low Risk",
            levelColour: "#E0F9ED",
          },
        },
      ],
    },
  ],
};
