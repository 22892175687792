import { TrimString } from "./StringTrimmer";

export function validateEmail(input) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(input);
}

export function validateNumber(value) {
 
  let Z = value ? Number(value.replace(/[^\d.]/g, '')) : "";
  if (Number.isInteger(Z)) {
    return Z; // Return the integer if Z is an integer
  } else if (typeof Z === 'number' && !Number.isInteger(Z)) {
    const decimals = Z.toString().split('.')[1];
    if (decimals && decimals.length > 2) {
      return Math.floor(Z * 100) / 100; // Return the float if it has more than 2 decimals
    }else{
      return Z
    }
  }
  return '';
}

export function capitalizeEveryWord(sentence) {
  return sentence
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

export function renderSelectedItemsInDropdown(
  _selectedItemsArray = [],
  limit = 2,
  charLimit
) {
  if (!_selectedItemsArray || _selectedItemsArray?.length === 0) return "";

  if (_selectedItemsArray?.length > 0) {
    return `${TrimString(
      _selectedItemsArray.slice(0, limit).join(","),
      charLimit
    )} ${
      _selectedItemsArray.length > limit
        ? `+${_selectedItemsArray.length - limit}`
        : ""
    }`;
  }
}

export function fixLinkText(linkText) {
  // Regular expression to check if the linkText is a valid URL
  const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

  if (urlRegex.test(linkText)) {
    // If it's a valid URL, return the linkText as is
    return linkText;
  } else {
    // If not a valid URL, add "http://" prefix and wrap it in an anchor tag
    const fixedLink = `http://${linkText}`;
    return fixedLink;
  }
}

export function removeWhiteSpace(_value) {
  if (_value?.trim().length === 0) {
    return "";
  } else {
    return _value;
  }
}

export function addVariableIntoAPIForMSPClient(additionalParameters) {
  let urlPath = window.location.pathname.split("/");

  let test = ["", "mspv2", "clients", "clientsView", "646030", "dashboard"];
  if (typeof additionalParameters === "object" && urlPath[1] === "mspv2") {
    // Conditions for all tabs
    if (urlPath[5] === "attack-surface") {
      additionalParameters.optionEnum98 = urlPath[4];
      additionalParameters.optionEnum99 = 4;
    }
    if (urlPath[5] === "trend_data") {
      additionalParameters.optionEnum98 = urlPath[4];
      additionalParameters.optionEnum99 = 7;
    }
    if (urlPath[5] === "technical_drilldown") {
      additionalParameters.optionEnum98 = urlPath[4];
      additionalParameters.optionEnum99 = 8;
    }
    if (urlPath[5] === "Compliance_Manager") {
      additionalParameters.optionEnum98 = urlPath[4];
      additionalParameters.optionEnum99 = 9;
    }
    if (urlPath[5] === "integrations") {
      additionalParameters.optionEnum98 = urlPath[4];
      additionalParameters.optionEnum99 = 10;
    }
    if (urlPath[5] === "supply") {
      additionalParameters.optionEnum98 = urlPath[4];
      additionalParameters.optionEnum99 = 11;
      
    }
    if (urlPath[5] === "pro_services") {
      additionalParameters.optionEnum98 = urlPath[4];
      additionalParameters.optionEnum99 = 12;
    }
  } else if (
    typeof additionalParameters === "object" &&
    urlPath[1] === "clientmspsettingsv2"
  ) {
    if (urlPath[3] === "company-settings") {
      additionalParameters.optionEnum98 = urlPath[2];
      additionalParameters.optionEnum99 = 14;
    }
    if (urlPath[3] === "client-access-levels") {
      additionalParameters.optionEnum98 = urlPath[2];
      additionalParameters.optionEnum99 = 6;
    }

    if (urlPath[3] === "reset_settings") {
      additionalParameters.optionEnum98 = urlPath[2];
      additionalParameters.optionEnum99 = 14;
    }
    if (urlPath[3] === "notifications") {
      const userId = new URLSearchParams(window.location.search).get("userId");
      if (userId) {
        additionalParameters.optionStrEnum1 = userId;
      }
       

      additionalParameters.optionEnum98 = urlPath[2];
      additionalParameters.optionEnum99 = 15;
    }
    if (urlPath[3] === "c-suite") {
      const userId = new URLSearchParams(window.location.search).get("userId");
      if (userId) {
        additionalParameters.optionStrEnum1 = userId;
      }

      additionalParameters.optionEnum98 = urlPath[2];
      additionalParameters.optionEnum99 = 15;
    }
    if (urlPath[3] === "action_centre") {
      additionalParameters.optionEnum98 = urlPath[2];
      additionalParameters.optionEnum99 = 16;
    }
  }
  return additionalParameters;
}

export const TechnologySpendColorList = [
  { ColorName: "Dark Purple", ColorCode: "#393079" },
  { ColorName: "Indigo", ColorCode: "#3E3586" },
  { ColorName: "Royal", ColorCode: "#42368F" },
  { ColorName: "Blue Purple", ColorCode: "#4A3DA1" },
  { ColorName: "Purple", ColorCode: "#5244B3" },
  { ColorName: "Lapis Lustre", ColorCode: "#6357BB" },
  { ColorName: "Lavender", ColorCode: "#7569C2" },
  { ColorName: "Sapphire", ColorCode: "#867CCA" },
  { ColorName: "Light Purple", ColorCode: "#9087CE" },
  { ColorName: "Lavender", ColorCode: "#BAB4E1" },
  { ColorName: "Dark Violet", ColorCode: "#2B2F94" },
  { ColorName: "Lilac", ColorCode: "#2F33A5" },
  { ColorName: "Royal Azure", ColorCode: "#3135B2" },
  { ColorName: "Ethereal Blue", ColorCode: "#373BC8" },
  { ColorName: "Violet", ColorCode: "#3D42DE" },
  { ColorName: "Twilight Hue", ColorCode: "#5055E1" },
  { ColorName: "Blueberry", ColorCode: "#6468E5" },
  { ColorName: "Periwinkle", ColorCode: "#777BE8" },
  { ColorName: "Light Violet", ColorCode: "#8386EA" },
  { ColorName: "Bloom", ColorCode: "#B1B3F2" },
  { ColorName: "Dark Blue", ColorCode: "#04608E" },
  { ColorName: "Electric Blue", ColorCode: "#036B9D" },
  { ColorName: "Deep Sky", ColorCode: "#0072AA" },
  { ColorName: "Sky Blue", ColorCode: "#0081BF" },
  { ColorName: "Blue", ColorCode: "#008FD4" },
  { ColorName: "Cerulean", ColorCode: "#1A9AD8" },
  { ColorName: "Light Cerulean", ColorCode: "#33A5DD" },
  { ColorName: "Baby Blue", ColorCode: "#4DB1E1" },
  { ColorName: "Light Blue", ColorCode: "#5CB7E3" },
  { ColorName: "Cornflower", ColorCode: "#99D2EE" },
  { ColorName: "Dark Green", ColorCode: "#2D8F61" },
  { ColorName: "Emerald Mist", ColorCode: "#319F6B" },
  { ColorName: "Meadow", ColorCode: "#33AD72" },
  { ColorName: "Lime", ColorCode: "#3AC280" },
  { ColorName: "Green", ColorCode: "#40D88E" },
  { ColorName: "Spring", ColorCode: "#53DC99" },
  { ColorName: "Leaf", ColorCode: "#66E0A5" },
  { ColorName: "Pistachio", ColorCode: "#79E4B0" },
  { ColorName: "Light Green", ColorCode: "#85E6B7" },
  { ColorName: "Mint", ColorCode: "#B3EFD2" },
  { ColorName: "Dark Yellow", ColorCode: "#A77322" },
  { ColorName: "Rust", ColorCode: "#BB7F24" },
  { ColorName: "Gold Nugget", ColorCode: "#CC8A23" },
  { ColorName: "Sunflower", ColorCode: "#E69B28" },
  { ColorName: "Yellow", ColorCode: "#FFAC2C" },
  { ColorName: "Sunbeam", ColorCode: "#FFB441" },
  { ColorName: "Peach", ColorCode: "#FFBD56" },
  { ColorName: "Navajo", ColorCode: "#FFC56B" },
  { ColorName: "Light Yellow", ColorCode: "#FFCA78" },
  { ColorName: "Ivory", ColorCode: "#FFDEAB" },
  { ColorName: "Dark Orange", ColorCode: "#934732" },
  { ColorName: "Burnt", ColorCode: "#A44E36" },
  { ColorName: "Rustic", ColorCode: "#B25336" },
  { ColorName: "Burnt Sienna", ColorCode: "#C95E3D" },
  { ColorName: "Orange", ColorCode: "#DF6844" },
  { ColorName: "Coral", ColorCode: "#E27757" },
  { ColorName: "Light Coral", ColorCode: "#E9957C" },
  { ColorName: "Peachy Pink", ColorCode: "#ECA48F" },
  { ColorName: "Light Orange", ColorCode: "#EB9E87" },
  { ColorName: "Nectar", ColorCode: "#F2C3B4" },
  { ColorName: "Dark Pink", ColorCode: "#923A69" },
  { ColorName: "Raspberry", ColorCode: "#AA437A" },
  { ColorName: "Fuchsia", ColorCode: "#C24D8C" },
  { ColorName: "Magenta", ColorCode: "#DB569E" },
  { ColorName: "Pink", ColorCode: "#F360AF" },
  { ColorName: "Orchid", ColorCode: "#F470B7" },
  { ColorName: "Light Orchid", ColorCode: "#F580BF" },
  { ColorName: "Pastel", ColorCode: "#F790C7" },
  { ColorName: "Light Pink", ColorCode: "#F8A0CF" },
  { ColorName: "Baby Pink", ColorCode: "#F9B0D7" },
  { ColorName: "Dark Red", ColorCode: "#A04244" },
  { ColorName: "Carmine", ColorCode: "#B2494A" },
  { ColorName: "Rust Red", ColorCode: "#C24D4D" },
  { ColorName: "Coral Red", ColorCode: "#DB5656" },
  { ColorName: "Red", ColorCode: "#F36060" },
  { ColorName: "Coral", ColorCode: "#F47070" },
  { ColorName: "Coral Blush", ColorCode: "#F58080" },
  { ColorName: "Pale Coral", ColorCode: "#F79090" },
  { ColorName: "Light Red", ColorCode: "#F79999" },
  { ColorName: "Misty", ColorCode: "#FABFBF" },
  { ColorName: "Dark Brown", ColorCode: "#582B1E" },
  { ColorName: "Rich Brown", ColorCode: "#673223" },
  { ColorName: "Earthy Brown", ColorCode: "#763928" },
  { ColorName: "Copper", ColorCode: "#84402D" },
  { ColorName: "Brown", ColorCode: "#934732" },
  { ColorName: "Sandy Brown", ColorCode: "#9E5947" },
  { ColorName: "Cinnamon", ColorCode: "#A96C5B" },
  { ColorName: "Tan", ColorCode: "#B37E70" },
  { ColorName: "Light Brown", ColorCode: "#BE9184" },
  { ColorName: "Beige", ColorCode: "#C9A399" },
  { ColorName: "Black", ColorCode: "#0C0D11" },
  { ColorName: "Graphite", ColorCode: "#616778" },
  { ColorName: "Steel", ColorCode: "#767A88" },
  { ColorName: "Slate", ColorCode: "#858A99" },
  { ColorName: "Grey", ColorCode: "#9499AA" },
  { ColorName: "Smoke", ColorCode: "#9FA3B3" },
  { ColorName: "Ash Grey", ColorCode: "#A9ADBB" },
  { ColorName: "Comet", ColorCode: "#B4B8C4" },
  { ColorName: "Light Grey", ColorCode: "#BBBEC9" },
  { ColorName: "Silver", ColorCode: "#D4D6DD" },
];


export function isEmptyComment(_postEditorComment) {
  try{
    if(typeof _postEditorComment === "string" && _postEditorComment.trim().length === 0){
      return true;
    }
  let commentData =_postEditorComment
    if(typeof _postEditorComment === "string"){
      commentData = JSON.parse(_postEditorComment);
    }
    const textAvailable = commentData?.blocks?.some(
      (item) => item?.text?.trim()?.length > 0
    );
    
    return !textAvailable;
  }catch(err){
    console.log(err)
    return true
  }
 
}

// kwargs:{
//   searchText,
//   wrapperClass,
//   highlightClass
// }
export function highlightText(kwargs) {
 
  if (!kwargs.searchText || kwargs.searchText?.length === 0) {
    return
    const elements = document.querySelectorAll(`.${kwargs.wrapperClass}`);
    elements.forEach((element) => {

      // element.innerText = element.innerText
      element.innerText = element.textContent
   
    });
    return;
  }

  const elements = document.querySelectorAll(`.${kwargs.wrapperClass}`);
  elements.forEach((element) => {
    const text = element.innerText;
     
    const highlightedText = text.replace(
      new RegExp(kwargs.searchText, "gi"),
      (match) => `<span class=${kwargs.highlightClass}>${match}</span>`
    );
    element.innerHTML = highlightedText;
  });
}


export const all_integration_data = {
  606974: "WebTitan",
  607325: "NinjaRMM",
  615767: "DarkWebID",
  616180: "Perch",
  640081: "KnowBe4",
  640082: "PenTest Tools",
  640083: "ESET",
  678831: "Vipre",
  2388722: "Cyber Compliance (for linking scoring rules only)",
  2890712: "SentinelOne",
  2893653: "Microsoft Defender for Office 365",
  2895436: "Microsoft Defender for Endpoint",
  2896291: "Mimecast",
  2896461: "Sophos",
  2896540: "WEBROOT",
  2896789: "SpamTitan",
  2896790: "Trend Micro",
  2896791: "N-ABLE",
  2896792: "Cisco Meraki (Systems Manager)",
  2896793: "Cisco Umbrella",
  2896794: "DNS Filter",
  2896795: "Splunk",
  2896796: "Rapid7",
  2896797: "Log Rhythm",
  2896798: "Alienvault USM",
  3061365: "Connectwise Automate",
  3077816: "Connect Wise",
  3189661: "KaseyaVSA ",
  3199126: "Jira",
  3200904: "ManageEngine",
  3202540: "Jamf",
  3228870: "Jasdeep SO Integration",
  3234994: "Trello",
  3278516: "Censornet (Web Security)",
  3278977: "Jira",
  3279795: "Censornet (Email Security)",
  3284592: "Microsoft Azure",
  3284593: "Asana",
  3286964: "Jumpcloud",
  3330358: "AutoTask",
  3330359: "Barracuda",
  3330360: "Crowdstrike",
  3330361: "DattoRMM",
  3330362: "Google Workspace",
  3330363: "KaseyaBMS",
  3330364: "M3MIROC",
  3330365: "Microsoft Azure",
  3330366: "Perimeter81",
  3330367: "Qualys",
  3330368: "Skurio",
  3330369: "Team Viewer",
  3330370: "Tenable",
  3334093: "Cisco Meraki Appliance",
  3417718: "Xero",
  3448056: "Quick Books",
  3450675: "Sage One",
  3544486: "Sage200",
  3716058: "HighGround Demo",
  3718128: "Censornet",
  3906771: "HaloPSA",
  3930640: "ConnectWise Manage",
  3950003: "Syncro",
};

export function addEventOnScroll(
  e,
  parentDropDownClass = ".device-dropdown",
  classToToggle = "faded-dropdown-bottom"
) {
  let target = e.target;
  setTimeout(() => {
    const menu = target
      .closest(parentDropDownClass)
      .querySelector(".fade_on_scroll");

    if (!menu) return;
    let blurred_div = menu.querySelector(".black-blur-div");
    if (
      blurred_div.scrollHeight > 225 &&
      Number(
        Math.floor(Number(blurred_div.scrollTop)) -
          (Number(blurred_div.scrollHeight) - Number(blurred_div.offsetHeight))
      ) < -4
    ) {
      blurred_div.classList.add(classToToggle);
    }
    blurred_div.addEventListener("scroll", (obj) => {
      if (
        Number(
          Math.floor(Number(obj.target.scrollTop)) -
            (Number(obj.target.scrollHeight) - Number(obj.target.offsetHeight))
        ) >= -4 &&
        Number(
          Math.floor(Number(obj.target.scrollTop)) -
            (Number(obj.target.scrollHeight) - Number(obj.target.offsetHeight))
        ) < 4
      ) {
        blurred_div.classList.remove(classToToggle);
      } else {
        if (true) {
          blurred_div.classList.add(classToToggle);
        }
      }
    });
  }, 10);
}

export function addEventOnScrollMC(e,parentDropDownClass=".device-dropdown",classToToggle="faded-dropdown-bottom") {
  let target = e.target;
  setTimeout(() => {
    const menu = target
      .closest(parentDropDownClass)
      .querySelector(".fade_on_scroll");
       
    if (!menu) return;
    let blurred_div = menu.querySelector(".black-blur-div")
    if(blurred_div.scrollHeight >225
      
      
      && (Number(
        Math.floor(Number(blurred_div.scrollTop)) -
          (Number(blurred_div.scrollHeight) -
            Number(blurred_div.offsetHeight))
      ) < -4)
      
      ){
      blurred_div.classList.add(classToToggle)
    }
    blurred_div.addEventListener("scroll", (obj) => {
      
      if (
        Number(
          Math.floor(Number(obj.target.scrollTop)) -
            (Number(obj.target.scrollHeight) -
              Number(obj.target.offsetHeight))
        ) >= -4 &&
        Number(
          Math.floor(Number(obj.target.scrollTop)) -
            (Number(obj.target.scrollHeight) -
              Number(obj.target.offsetHeight))
        ) < 4
      ) {
        blurred_div.classList.remove(classToToggle);
      } else {
        if (true) {
           
          blurred_div.classList.add(classToToggle);
        }
      }
    });
  }, 10);
}

export function convertDateFormat(dateString) {
  const parts = dateString?.split("/");
  if (parts?.length > 2) {
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];

    // Rearrange the parts in the desired format
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  } else {
    return "";
  }
}