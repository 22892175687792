import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import "./Dashboard.scss";
import { connect } from "react-redux";
import { Col, Row, Container, Dropdown } from "react-bootstrap";
import CC from "../CyberCompliance/CyberCompliance";
import CS from "../CyberSavings/CyberSavings";
// import CScore from "../CyberScore/CyberScore";
import CScore from "../CyberScore/CyberScore";
import ScoreDetails from "../ScoreDetails/ScoreDetails";
import CC_CONTAINER from "../CC_Container/CC_Container";

import "../../main.scss";
import { useDb } from "../context/db";
import { useAuth } from "../context/Auth";
import SideBar from "../SideBar/SideBar";
import ModalDynamic from "../ModalDynamic/ModalDynamic";
import { FiChevronRight } from "react-icons/fi";
// import InvestmentDetails from "../InvestmentDetails/InvestmentDetails";
import SavingsDetails from "../CyberSavingsDetails/CyberSavingsDetails";
import CyberROIDetails from "../CyberROIDetails/CyberROIDetails";
import CyberScoreDetails from "../CyberScoreDetails/CyberScoreDetails";
import RiskyUsers from "../RiskyUsers/RiskyUsers";
import ActiveRisks from "../ActiveRisks/ActiveRisks";
import ActiveThreats from "../ActiveThreats/ActiveThreats";
import IncidentResponseForm from "../IncidentResponseForm/IncidentResponseForm";

import { getAPIData } from "../../Utils/commonApi";

import CollapsabileTable from "../CollapsabileTable/CollapsabileTable";
import DashboardModal from "../Dashboard/DashboardModal";
import CountUp from "react-countup";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import IntegrationsModal from "../Integrations/IntegrationsModal";
import * as animationData from "../Spinner/loader.json";
import ContentLoader from "react-content-loader";

import { createStructuredSelector } from "reselect";
import { setuser } from "../../redux/userState/actions";
import { getUser } from "../../redux/userState/selectors";
import { useDispatch, useSelector } from "react-redux";
import { ResendEmail } from "../../Utils/VerifyEmail";
// dstart
import TechnicalDrillDownCyberScoreModal from "../AttackSurface/TechnicalDrillDownCyberScoreModal";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import UpgradeToPremiumModal from "../Integrationsv2/UpgradeToPremiumModal";
import { NoAccessUI } from "../App/App";
import CyberGroupModal from "../AttackSurface/CyberGroupModal";

// Replaced 'dbObj' with 'dashBoardPageData'

const Dashboard = (props) => {
  const location = useLocation();
  const { state: redirectInformation } = location;
  const [switchDashboardDropdownIcon, setSwitchDashboardDropdownIcon] =
    useState(false);
  const [blockDashboard, setBlockDashboard] = useState(false);
  const history = useHistory();
  const [upgradeOptions, setUpgradeOptions] = useState({});
  function redirectUser() {
    // if (redirectInformation?.redirectUser === true) {
    //   setTimeout(() => {
    //     if (
    //       redirectInformation?.redirectUrl !== "/login" &&
    //       redirectInformation?.redirectUrl !== "/session_expiry" &&
    //       redirectInformation?.redirectUrl !== "/logout"
    //     ) {
    //       history.replace(redirectInformation?.redirectUrl);
    //     }
    //   }, 3000);
    // }
  }

  const cyberScoreValue = useSelector(
    (state) => state.user?.userDetail?.cyberScoreValue
  );
  const [pageType] = useState(props.match.params.pageType || "");
  const [htmlOutput, setHtmlOutput] = useState();
  const rowElement = useRef(null);
  const { dbObj, setdbObject } = useDb();
  const { authTokens, setAuthTokens } = useAuth();
  const dispatch = useDispatch();
  const [dashBoardPageData, setDashBoardPageData] = useState();
  const [largeModal, setlargeModal] = useState(false);
  const [investmentModal, setinvestmentModal] = useState(false);
  const [scoreModal] = useState(false);
  const [savingsModal, setSavingsModal] = useState(false);
  const [roiModal, setRoiModal] = useState(false);
  const [riskyUsersModal, setRiskyUsersModal] = useState(false);
  const [activeThreatsModal, setActiveThreatsModal] = useState(false);
  const [activeRisksModal, setActiveRisksModal] = useState(false);
  // const { messageEncrypt } = useAuth();
  const [scoreThreat, setScoreThreat] = useState(0);
  const [loading, setLoading] = useState(true);
  // const [count, setCount] = useState(0);
  const [anim, setAnim] = useState(true);
  const [animTwo] = useState(false);
  const [complianceHover, setComplianceHover] = useState(false);

  const [reportCounter, setReportCounter] = useState(0);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [allowDrillDownSecurityIncident, setAllowDrillDownSecurityIncident] =
    useState(true);
  const [showUpgradeToPremiumModal, setShowUpgradeToPremiumModal] =
    useState(false);

  // const [loading] = useState(localStorage.getItem("dasboardLoader"));
  const [
    showTechnicalDrillDowncyberScoreModal,
    setShowTechnicalDrillDowncyberScoreModal,
  ] = useState(false);
  const [showTechnicalDrillDownCyberComplianceModal, setShowTechnicalDrillDownCyberComplianceModal] = useState(false)
  const CURRENT_URL = useLocation().pathname;
  const url = new URLSearchParams(props.location.search);
  const modalUrl = url.get("modal");

  const URLpath = window.location.pathname.split("/");
 
  useEffect(() => {
    if (props.location.state) {
    }
    if (modalUrl == "CyberInvestmentDetails") {
      setinvestmentModal(false);
      setInterval(() => {
        setinvestmentModal(true);
      }, 100);
    }
  }, [props.location.state, modalUrl]);

  useEffect(() => {
    if (reportCounter > 1) {
      setReportModalOpen(false);
      setInterval(() => {
        setReportModalOpen(true);
      }, 100);
    }
  }, [reportCounter]);

  async function makeAPICall(num ,load=true) {
    if(load){
      setLoading(true);

    }
    await getAPIData(num, authTokens, {}).then((data) => {
      if (data?.authToken !== undefined) {
        setAuthTokens(data.authToken);
        setdbObject();
      } else {
        if (
          data?.mr?.Success === false ||
          !data ||
          data === null ||
          data === undefined
        ) {
          CallToast("Something went wrong", false, false, "v2style");
          return;
        }
        setdbObject(data);
        setDashBoardPageData(data);
        localStorage.setItem("object_Data", JSON.stringify(data));

        dispatch(
          setuser({
            ...data?.UserDetail,
            CommonComponents: data?.CommonComponents,
            ScoreAttestation_Icon:
              data?.CommonComponents?.ScoreAttestation_Icon,

            cyberScoreValue: data?.CommonComponents?.CyberScoreNumber,
            cyberThreatValue: data?.CommonComponents?.CyberThreatNumber,
            AccessObjList: data?.mr?.AccessObjList,
          })
        );
        setAllowDrillDownSecurityIncident(
          data?.mr?.AccessObjList?.filter(
            (item) =>
              item?.Question === "Security Incident Reporting Visibility"
          )?.[0]
        );

        if (
          data?.accessData?.Override === true &&
          data?.accessData?.AccessState_Dashboard === 0
        ) {
          setBlockDashboard(true);
        } else {
          setBlockDashboard(false);
        }

        
        setLoading(false);
        //
        redirectUser();
      }
      // setCount(count + 1);
    });
  }

  function refreshDashboard() {
    setTimeout(async () => {
      let data = await getAPIData(2, authTokens);

      setdbObject(data);

      dispatch(setuser(data?.UserDetail));
    }, 200);
  }

  function toggleInvestmentModalDisplay() {
    setinvestmentModal(false);
    setInterval(() => {
      setinvestmentModal(true);
    }, 100);
  }

  function toggleSavingsModalDisplay() {
    setSavingsModal(false);
    setInterval(() => {
      setSavingsModal(true);
    }, 100);
  }

  function toggleROIModalDisplay() {
    setRoiModal(false);
    setInterval(() => {
      setRoiModal(true);
    }, 100);
  }

  function toggleRiskyUsersModal() {
    setRiskyUsersModal(false);
    setInterval(() => {
      setRiskyUsersModal(true);
    }, 100);
  }

  function setReportState(event) {
    event.preventDefault();
    setReportModalOpen(true);
  }

  function toggleActiveThreatsModal() {
    setActiveThreatsModal(false);
    setInterval(() => {
      setActiveThreatsModal(true);
    }, 100);
  }

  function toggleActiveRisksModal() {
    setActiveRisksModal(false);
    setInterval(() => {
      setActiveRisksModal(true);
    }, 100);
  }



  useEffect(() => { 
    if (pageType === "ceo") {
      makeAPICall(0);
    } else if (pageType === "cto") {
      makeAPICall(1);
    } else if (pageType === "cfo") {
      makeAPICall(2);
    } else if (pageType.toLowerCase() === "other") {
      makeAPICall(0);
      console.log("api - CFO");
    } else if (CURRENT_URL.includes("create_support_ticket")) {
      makeAPICall(120);
    }
  }, []);


  function refreshData(){
    if (pageType === "ceo") {
      makeAPICall(0);
    } else if (pageType === "cto") {
      makeAPICall(1);
    } else if (pageType === "cfo") {
      makeAPICall(2);
    } else if (pageType.toLowerCase() === "other") {
      makeAPICall(0); 
    }  
  }

  const handleComplete = () => {
    try {
      document.getElementsByClassName("sideNav")[0].classList.remove("d-none");
      document.getElementById("mainBody").classList.add("col-sm-10");
      setAnim(false);
      // localStorage.setItem("dasboardLoader", null);
    } catch (err) {
      console.log("error in handleComplete function", err);
    }
  };

  useEffect(() => {
    if (!loading) {
      let demo = 0;
      const interval = setInterval(function () {
        demo = demo + 1;
        if (rowElement.current) {
          rowElement.current.style.width = `${demo}%`;
        } else {
          clearInterval(interval);
        }
        if (demo >= 95) {
          clearInterval(interval);
        }
      }, 40);
    }
  }, [loading]);

  useEffect(() => {
    if (!loading && dashBoardPageData && anim) {
      setTimeout(() => {
        handleComplete();
      }, 2000);
    }
    if (loading) {
      try {
        document
          .getElementsByClassName("sideNav")[0]
          .classList.remove("d-none");
        document.getElementById("mainBody").classList.add("col-sm-10");
      } catch (err) {
        console.log("error in useEffect", err);
      }
    }
  }, [loading, dashBoardPageData, anim]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const page = query.get("route");
    const tab = query.get("tab");
    if (tab == "subscriptions") {
      localStorage.setItem("redirectTab", true);
      setTimeout(() => {
        history.push("/system_settings");
      }, 2500);
    } else if (page == "system_settings") {
      setTimeout(() => {
        history.push("/system_settings");
      }, 2500);
    } else if (page == "pro_services") {
      setTimeout(() => {
        history.push("/pro_services");
      }, 2500);
    } else if (page == "Cyber_Essential_Status") {
      setTimeout(() => {
        history.push("/Compliance_Manager/Cyber_Essential_Status");
      }, 2500);
    } else if (page == "IR_Plan_Status") {
      setTimeout(() => {
        history.push("/Compliance_Manager/IR_Plan_Status");
      }, 2500);
    } else if (page == "Infosec_Policies") {
      setTimeout(() => {
        history.push("/Compliance_Manager/Infosec_Policies");
      }, 2500);
    } else if (page == "Risk_Assessment") {
      setTimeout(() => {
        history.push("/Compliance_Manager/Risk_Assessment");
      }, 2500);
    } else if (page == "Annual_Cyber_Review") {
      setTimeout(() => {
        history.push("/Compliance_Manager/Annual_Cyber_Review");
      }, 2500);
    } else if (page == "Test_Recovery") {
      setTimeout(() => {
        history.push("/Compliance_Manager/Test_Recovery");
      }, 2500);
    } else if (page == "integrations") {
      setTimeout(() => {
        history.push("/integrations");
      }, 2500);
    }





 
  }, [
    dbObj, 
    loading,
  ]);

  let investmentNumber =
  dashBoardPageData?.CFOComponents?.ValueBoxes[0]?.BoxValue || "";
let savingNumber =
  dashBoardPageData?.CFOComponents?.ValueBoxes[1]?.BoxValue || "";
let investmentDecimal = " ";
let savingsDecimal = " ";
let investmentDecimalNo = 3;
let savingsDecimalNo = 3;
let investmentDecimalSuffix = "";
let savingsDecimalSuffix = "";

if (investmentNumber.includes("M")) {
  investmentNumber = investmentNumber
    .replace(",", ".")
    .replace(" ", "")
    .replace("M", "");
  investmentDecimal = ".";
  investmentDecimalNo = 2;
  investmentDecimalSuffix = "M";
} else {
  investmentNumber = investmentNumber
    .replace(",", ".")
    .replace(" ", "");
}

if (savingNumber.includes("M")) {
  savingNumber =
    dashBoardPageData?.CFOComponents?.ValueBoxes[1]?.BoxValue?.replace(
      ",",
      "."
    )
      .replace(" ", "")
      .replace("M", "");
  savingsDecimal = ".";
  savingsDecimalNo = 2;
  savingsDecimalSuffix = "M";
} else {
  savingNumber = savingNumber.replace(",", ".").replace(" ", "");
}

  if ((animTwo || anim) && localStorage.getItem("dasboardLoader") === "true") {
    return (
      <div className="center-container-loader">
        <div className="center-container">
        <Lottie                
            loop={false}
            play={true}
            animationData={animationData.default}
            rendererSettings={{
              preserveAspectRatio: "xMidYMid slice",
                filterSize: {
                  width: "100%",
                  height: "100%",
                  x: "-50%",
                  y: "-50%",
                },
            }}                               
            onComplete={() => {
              history.push(
                `/technology-spend`
              );
              localStorage.setItem("dasboardLoader", null);
            }}
            style={{ width: 320, height: 231, margin: "0 auto" }}
            width={320}
            height={231}
          ></Lottie>
          <div className="outsideLoader">
            <div className="custom_loader">
              <div className="custom_bar" ref={rowElement}></div>
            </div>
          </div>
          <p className="smallGrey custom_text">
            It is taking longer than usual to load this page.
            <br /> Please wait...
          </p>
        </div>
      </div>
    );
  } else if ((loading || !dbObj) && loading) {
    return (
      <ContentLoader
        speed={2}
        height={"100%"}
        width={"100%"}
        viewBox="0 -80 1500 1000"
        backgroundColor="#F6F8FB"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect
          x="45"
          y="49"
          rx="15"
          ry="15"
          height={200}
          width={300}
          style={{ height: "200", width: "33%" }}
        />
        <rect
          x="580"
          y="49"
          rx="15"
          ry="15"
          height={200}
          width={300}
          style={{ height: "200", width: "33%" }}
        />
        <rect
          x="45"
          y="320"
          rx="15"
          ry="15"
          height={350}
          width={600}
          style={{ height: "350", width: "69%" }}
        />
        <rect
          x="1150"
          y="49"
          rx="15"
          ry="15"
          height={500}
          width={300}
          style={{ height: "50%", width: "30%" }}
        />
        <rect
          x="1150"
          y="650"
          rx="15"
          ry="15"
          height={220}
          width={300}
          style={{ height: "220", width: "30%" }}
        />
        <rect
          x="45"
          y="820"
          rx="15"
          ry="15"
          height={50}
          width={690}
          style={{ height: "50", width: "69%" }}
        />
      </ContentLoader>
    );
  } else {
    return (
      <>
        {blockDashboard ? (
          <NoAccessUI />
        ) : (
          <Row className="fullHeight">
            {largeModal ? (
              <ModalDynamic
                setShow={true}
                modalSize="lg"
                modalHeader={largeModal ? largeModal : ""}
                modalData={
                  <CollapsabileTable pageType={largeModal ? largeModal : ""} />
                }
              />
            ) : (
              ""
            )}

            {investmentModal == true ? (
              <div>
                <ModalDynamic
                  setShow={investmentModal}
                  modalSize="lg"
                  modalHeader={"Cyber Investment Details"}
                  investmentDetailsForm={true}
                  showButton={true}
                  buttonText={"+ Add Investment"}
                  onClose={refreshDashboard}
                />
              </div>
            ) : (
              ""
            )}

            {scoreModal ? (
              <ModalDynamic
                setShow={true}
                modalHeader={"Cyber Score Details"}
                modalData={<CyberScoreDetails />}
              />
            ) : (
              ""
            )}
            {savingsModal ? (
              <ModalDynamic
                setShow={true}
                modalSize="lg"
                modalHeader={"Cyber Savings Details"}
                modalData={<SavingsDetails />}
              />
            ) : (
              ""
            )}
            {roiModal ? (
              <ModalDynamic
                setShow={true}
                modalHeader={"Cyber ROI Details"}
                modalData={<CyberROIDetails />}
              />
            ) : (
              ""
            )}

            {riskyUsersModal ? (
              <ModalDynamic
                setShow={true}
                modalSize="lg"
                modalHeader={"Risky Users"}
                modalData={<RiskyUsers />}
              />
            ) : (
              ""
            )}

            {activeThreatsModal ? (
              <ModalDynamic
                setShow={true}
                modalSize="lg"
                modalHeader={"Active Threats"}
                modalData={<ActiveThreats />}
              />
            ) : (
              ""
            )}

            {activeRisksModal ? (
              <ModalDynamic
                setShow={true}
                modalSize="lg"
                modalHeader={"Active Risks"}
                modalData={<ActiveRisks />}
              />
            ) : (
              ""
            )}

            {/* <Col xs={12}>
          {accountVerified == false && verifyBanner && verifyEmailBanner && (
            <div
              className="d-flex justify-content-between verify-banner"
              style={{ background: `${userDetails?.VerifyLinkColour}` }}
            >
              <div></div>
              <div className="d-flex align-items-center">
                <img
                  src="/images/settings-v2/msg-gray.svg"
                  className="mr-2"
                  alt=""
                />
                <div className="d-flex align-items-center">
                  Verify your account within {userDetails?.DaysLeftToVerify}{" "}
                  days
                  <span
                    className="link ml-2"
                    onClick={() => ResendEmail(userEmail)}
                  >
                    Resend Link
                  </span>
                </div>
              </div>
              <div></div>
            </div>
          )}
        </Col> */}
            <Col xs={12} sm={9} className="px-0 test">
              <div
                className="dashboard fullHeight"
                style={{ overflow: "hidden" }}
              >
                <header
                  className="d-flex justify-content-between align-items-center"
                  style={{ paddingTop: "1em" }}
                >
                  <div className="d-flex align-items-center">
                    {/* <h1 className="dashHeader">
                  {dbObj !== 0
                    ? dashBoardPageData?.CommonComponents?.Title
                    : ""}
                </h1> */}
                    <Dropdown className="transparennt-wrapper-dropdown ml-4">
                      <Dropdown.Toggle className="p-0">
                        <div className="d-flex align-items-center f-12 f-500 f-grey radius-4 border1 px-2 p-1">
                          <img
                            src="/images/msp/common-dashboard-icon.svg"
                            alt=""
                            className="mr-2"
                          />
                          {URLpath[1].toUpperCase()}&nbsp;Dashboard &nbsp;
                          <img
                            src="/images/big-chevron-down.svg"
                            alt=""
                            className={`${
                              switchDashboardDropdownIcon ? "rotate180" : ""
                            }`}
                          />
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="p-2"
                        style={{
                          width: "200px",
                        }}
                      >
                        {["ceo", "cto", "cfo"].map((item, index) => {
                          return (
                            <div
                              className="d-flex align-items-center mb-2 f-500 f-grey pointer"
                              onClick={(e) => {
                                history.push(`/${item}/dashboard`);
                                window.location.reload();
                                e.target
                                  .closest(".transparennt-wrapper-dropdown")
                                  .click();
                              }}
                            >
                              <img
                                src="/images/msp/dropdown-grey-tick.svg"
                                alt=""
                                className="mr-2"
                                style={{
                                  visibility:
                                    URLpath[1].toLowerCase() === item
                                      ? "visible"
                                      : "hidden",
                                }}
                              />
                              {item.toUpperCase()}&nbsp;Dashboard
                            </div>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>

                    <p style={{ paddingTop: "1px" }}>
                      Hello{" "}
                      {dbObj !== 0
                        ? dashBoardPageData?.UserDetail?.FirstName
                        : ""}
                      , welcome{" "}
                      {dashBoardPageData?.UserDetail?.FirstLogon
                        ? "to HighGround!"
                        : "back!"}
                    </p>
                  </div>

                  <div className="securityIncidentBtn">
                    {/* <a
                      onClick={(event) => {
                        if (allowDrillDownSecurityIncident?.Option === true) {
                          setReportCounter(reportCounter + 1);
                          setReportState(event);
                        } else {
                          setUpgradeOptions(
                            allowDrillDownSecurityIncident?.UpgradeOptions
                          );
                          setShowUpgradeToPremiumModal(true);
                        }
                      }}
                    >
                      <img src="/images/securityIncidentIcon.svg" alt=""></img>
                    </a> */}
                  </div>
                </header>
                {/* {htmlOutput} */}
                {/* CEO DASHBOARD */}
                {pageType === "ceo" && dashBoardPageData && (
                  <Container className="dash_container">
                    <br />
                    <br />
                    <br />
                    <Row style={{ height: "171px" }} className="m-0 scoreTile">
                      {dashBoardPageData?.SubscriptionDetail &&
                      dashBoardPageData?.SubscriptionDetail.PreDataView ? (
                        <Col
                          className="p-0 test-col parentHover"
                          md={6}
                          onMouseEnter={(e) => {
                            setComplianceHover(true);
                          }}
                          onMouseLeave={() => {
                            setComplianceHover(false);
                          }}
                        >
                          <Link exact className={" visibleDiv  mr15"}>
                            <div className="cc_background  fullHeight">
                              <Row>
                                <Col
                                  md={7}
                                  className="my-auto containerPadding"
                                >
                                  <div className="containerPadding">
                                    <h3 className="cc_text">
                                      {dashBoardPageData?.CEOComponents &&
                                        dashBoardPageData?.CEOComponents
                                          .ValueBoxes[0].BoxTitle}
                                    </h3>
                                    <div className="d-flex">
                                      <img
                                        src="/images/dashboard/suggestionIcon.svg"
                                        alt=""
                                      />
                                      <p className="smallGrey pl-3">
                                        {dashBoardPageData?.CEOComponents &&
                                          dashBoardPageData?.CEOComponents
                                            .ValueBoxes[0].BoxSubTitle}
                                      </p>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={5} className="containerPadding">
                                  <CC ccNumber={-1} />
                                </Col>
                              </Row>
                            </div>
                          </Link>
                          <div className={" visibleOnHoverDiv  mr15 "}>
                            <div
                              className="cc_background  fullHeight"
                              style={{ height: "168px", padding: "25px 10px" }}
                            >
                              <div className="containerPadding">
                                <h3 className="cc_text preData">
                                  {dashBoardPageData?.CEOComponents &&
                                    dashBoardPageData?.CEOComponents
                                      .ValueBoxes[0].MoreInfoText}
                                </h3>
                                <p className="blueText">
                                  <a
                                    href={`/${
                                      dashBoardPageData?.CEOComponents &&
                                      dashBoardPageData?.CEOComponents
                                        .ValueBoxes[0].MoreInfoRoute ==
                                        "Compliance_Governance"
                                        ? "Compliance_Manager"
                                        : ""
                                    }`}
                                  >
                                    {dashBoardPageData?.CEOComponents &&
                                      dashBoardPageData?.CEOComponents
                                        .ValueBoxes[0].MoreInfoRouteText}
                                  </a>
                                  {dashBoardPageData?.CEOComponents &&
                                  dashBoardPageData?.CEOComponents
                                    .ValueBoxes[0] ? (
                                    <FiChevronRight />
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      ) : (
                        <Col md={6}>
                          {/* <Link
                            onClick={() =>
                              localStorage.setItem(
                                "currentUrl",
                                JSON.stringify({
                                  url: "/Compliance_Manager",
                                  name: "Compliance Manager",
                                })
                              )
                            }
                            exact
                            to={"/Compliance_Manager"}
                            className=""
                          > */}
                            <div className="cc_background  fullHeight pointer"
                            onClick={()=>{
                              setShowTechnicalDrillDownCyberComplianceModal(true)
                            }}
                            
                            
                            >
                              <Row>
                                <Col
                                  md={7}
                                  className="my-auto containerPadding"
                                >
                                  <div className="containerPadding">
                                    <h3 className="cc_text">CyberCompliance</h3>

                                    <p className="smallGrey whitepre-">
                                      {/* Your cyber compliance is{" "} */}
                                      {dashBoardPageData &&
                                      dashBoardPageData?.CEOComponents
                                        ?.CyberComplianceText
                                        ? dashBoardPageData?.CEOComponents
                                            ?.CyberComplianceText
                                        : "NA"}
                                      !
                                    </p>
                                  </div>
                                </Col>
                                <Col md={5} className="containerPadding">
                                  <CC
                                    ccNumber={
                                      dashBoardPageData
                                        ? dashBoardPageData?.CEOComponents
                                            .CyberComplianceNumber
                                        : 0
                                    }
                                  />
                                </Col>
                              </Row>
                            </div>
                          {/* </Link> */}
                        </Col>
                      )}
                      {/* predata  */}
                      {dashBoardPageData?.SubscriptionDetail &&
                      dashBoardPageData?.SubscriptionDetail.PreDataView ? (
                        <Col
                          md={6}
                          className="p-0 parentHover"
                          onMouseEnter={(e) => {
                            // setSavingHover(true);
                          }}
                          onMouseLeave={() => {
                            // setSavingHover(false);
                          }}
                        >
                          <div
                            className={`cc_background fullHeight  ${"  visibleDiv  ml15"}`}
                          >
                            <Link>
                              <CS />
                            </Link>
                          </div>
                          <div className={"visibleOnHoverDiv ml15"}>
                            <div
                              className="cc_background  fullHeight"
                              style={{ height: "168px", padding: "25px 10px" }}
                            >
                              <div className="containerPadding">
                                <h3 className="cc_text preData">
                                  {dashBoardPageData?.CEOComponents &&
                                    dashBoardPageData?.CEOComponents
                                      .ValueBoxes[1].MoreInfoText}
                                </h3>
                                <p className="blueText">
                                  <a
                                    href={`/${
                                      dashBoardPageData?.CEOComponents &&
                                      dashBoardPageData?.CEOComponents
                                        .ValueBoxes[1].MoreInfoRoute
                                    }`}
                                  >
                                    {dashBoardPageData?.CEOComponents &&
                                      dashBoardPageData?.CEOComponents
                                        .ValueBoxes[1].MoreInfoRouteText}
                                  </a>
                                  {dashBoardPageData?.CEOComponents &&
                                  dashBoardPageData?.CEOComponents.ValueBoxes[1]
                                    .MoreInfoRoute ? (
                                    <FiChevronRight />
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      ) : (
                        <Col md={6}>
                          <div className="cc_background fullHeight">
                            <Link onClick={toggleSavingsModalDisplay}>
                              <CS />
                            </Link>
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Row className="row_split"></Row>
                    <CyberScoreComponent
                      scorevalue={cyberScoreValue}
                      loading={loading}
                      setShowTechnicalDrillDowncyberScoreModal={
                        setShowTechnicalDrillDowncyberScoreModal
                      }
                      ScoreAttestation={
                        dashBoardPageData?.CommonComponents?.ScoreAttestation
                      }
                    />
                    {dashBoardPageData?.PostLoadAction == 3 ? (
                      <DashboardModal />
                    ) : (
                      ""
                    )}
                    {dashBoardPageData?.PostLoadAction == 2 ? (
                      <IntegrationsModal />
                    ) : (
                      ""
                    )}
                  </Container>
                )}

 {/* CTO DASHBOARD */}
 {pageType === "cto" && dashBoardPageData &&
 
 <Container className="dash_container">
              <br />
              <br />
              <br />
              <Row>
                {dashBoardPageData?.SubscriptionDetail &&
                dashBoardPageData?.SubscriptionDetail.PreDataView ? (
                  <Col
                    xs={12}
                    sm={4}
                    className="p-0 parentHover"
                    onMouseOver={() => {
                      // setThreathover(true)
                    }}
                    onMouseLeave={() => {
                      // setThreathover(false)
                    }}
                  >
                    <Link className={"visibleDiv"}>
                      <CC_CONTAINER
                        size={12}
                        type="cto"
                        link={"/ceo/dashboard"}
                        text={
                          <span className="mx-auto cto-font greyText">
                            <CountUp
                              start={0}
                              end={0}
                              separator=""
                              decimals={0}
                              decimal=","
                              useEasing={false}
                              duration="2"
                            />
                          </span>
                        }
                        label={
                          dashBoardPageData?.CTOComponents &&
                          dashBoardPageData?.CTOComponents.ValueBoxes[0]
                            .BoxTitle
                        }
                        label2={
                          <div className="d-flex justify-content-center">
                            <img
                              alt=""
                              src={
                                dashBoardPageData?.CTOComponents &&
                                dashBoardPageData?.CTOComponents.ValueBoxes[0]
                                  .MoreInfoIcon
                              }
                            />
                            <p className="smallGrey pl-3">
                              {dashBoardPageData?.CTOComponents &&
                                dashBoardPageData?.CTOComponents.ValueBoxes[0]
                                  .BoxSubTitle}
                            </p>
                          </div>
                        }
                        icon={
                          dashBoardPageData?.CTOComponents &&
                          dashBoardPageData?.CTOComponents.ValueBoxes[0].BoxIcon
                        }
                        linkText={
                          dashBoardPageData?.CTOComponents &&
                          dashBoardPageData?.CTOComponents.ValueBoxes[0]
                            .BoxFooter
                        }
                      />
                    </Link>

                    <div className={"visibleOnHoverDiv"}>
                      <Col lg={12}>
                        <div
                          className="cc_background  fullHeight"
                          style={{ height: "183px", padding: "25px 10px" }}
                        >
                          <div className="containerPadding">
                            <h3 className="cc_text preData">
                              {dashBoardPageData?.CTOComponents &&
                                dashBoardPageData?.CTOComponents.ValueBoxes[0]
                                  .MoreInfoText}
                            </h3>
                            <p className="blueText">
                              <a
                                href={`/${
                                  dashBoardPageData?.CTOComponents &&
                                  dashBoardPageData?.CTOComponents.ValueBoxes[0]
                                    .MoreInfoRoute
                                }`}
                              >
                                {dashBoardPageData?.CTOComponents &&
                                  dashBoardPageData?.CTOComponents.ValueBoxes[0]
                                    .MoreInfoRouteText}
                              </a>
                              {dashBoardPageData?.CTOComponents.ValueBoxes[0]
                                .MoreInfoRoute ? (
                                <FiChevronRight />
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </Col>
                ) : (
                  <Col xs={12} sm={4} className="p-0">
                    <Link onClick={toggleActiveThreatsModal}>
                      <CC_CONTAINER
                        size={12}
                        type="cto"
                        link={"/ceo/dashboard"}
                        text={
                          <span className="mx-auto cto-font">
                            <CountUp
                              start={0}
                              end={
                                dashBoardPageData?.CTOComponents
                                  ?.ValueBoxes?.[0]?.BoxValue || 0
                              }
                              separator=""
                              decimals={0}
                              decimal=","
                              useEasing={false}
                              duration="2"
                            />
                          </span>
                        }
                        label={
                          dashBoardPageData != 0
                            ? dashBoardPageData?.CTOComponents.ValueBoxes[0]
                                .BoxTitle
                            : 0
                        }
                        label2={
                          dashBoardPageData != 0
                            ? dashBoardPageData?.CTOComponents.ValueBoxes[0]
                                .BoxSubTitle
                            : 0
                        }
                        linkText={
                          dashBoardPageData != 0
                            ? dashBoardPageData?.CTOComponents.ValueBoxes[0]
                                .BoxFooter
                            : 0
                        }
                        icon={
                          dashBoardPageData != 0
                            ? dashBoardPageData?.CTOComponents.ValueBoxes[0]
                                .BoxIcon
                            : 0
                        }
                        // linkText={
                        //   dashBoardPageData?.SubscriptionDetail.PreDataView
                        //     ? dashBoardPageData?.CTOComponents.ValueBoxes[0].BoxFooter
                        //     : ""
                        // }
                      />
                    </Link>
                  </Col>
                )}

                {dashBoardPageData?.SubscriptionDetail &&
                dashBoardPageData?.SubscriptionDetail.PreDataView ? (
                  <Col
                    xs={12}
                    sm={4}
                    className="p-0 parentHover"
                    onMouseOver={() => {
                      //  setRisksHover(true)
                    }}
                    onMouseLeave={() => {
                      //  setRisksHover(false)
                    }}
                  >
                    <Link className={"visibleDiv"}>
                      <CC_CONTAINER
                        type="cto"
                        size={12}
                        text={
                          <span className="mx-auto cto-font greyText">
                            <CountUp
                              start={0}
                              end={0}
                              separator=""
                              decimals={0}
                              decimal=","
                              useEasing={false}
                              duration="2"
                            />
                          </span>
                        }
                        label={
                          dashBoardPageData?.CTOComponents &&
                          dashBoardPageData?.CTOComponents.ValueBoxes[1]
                            .BoxTitle
                        }
                        label2={
                          <div className="d-flex justify-content-center">
                            <img
                              alt=""
                              src={
                                dashBoardPageData?.CTOComponents.ValueBoxes[1]
                                  .MoreInfoIcon
                              }
                            />
                            <p className="smallGrey pl-3">
                              {" "}
                              {dashBoardPageData?.CTOComponents &&
                                dashBoardPageData?.CTOComponents.ValueBoxes[1]
                                  .BoxSubTitle}
                            </p>
                          </div>
                        }
                        icon={
                          dashBoardPageData != 0
                            ? dashBoardPageData?.CTOComponents.ValueBoxes[1]
                                .BoxIcon
                            : 0
                        }
                        linkText={
                          dashBoardPageData?.CTOComponents &&
                          dashBoardPageData?.CTOComponents.ValueBoxes[1]
                            .BoxFooter
                        }
                      />
                    </Link>

                    <div className={"visibleOnHoverDiv"}>
                      <Col lg={12}>
                        <div
                          className="cc_background  fullHeight"
                          style={{ height: "183px", padding: "25px 10px" }}
                        >
                          <div className="containerPadding">
                            <h3 className="cc_text preData">
                              {
                                dashBoardPageData?.CTOComponents.ValueBoxes[1]
                                  .MoreInfoText
                              }
                            </h3>
                            <p className="blueText">
                              <a
                                href={`/${
                                  dashBoardPageData?.CTOComponents &&
                                  dashBoardPageData?.CTOComponents.ValueBoxes[1]
                                    .MoreInfoRoute
                                }`}
                              >
                                {dashBoardPageData?.CTOComponents &&
                                  dashBoardPageData?.CTOComponents.ValueBoxes[1]
                                    .MoreInfoRouteText}
                              </a>
                              {dashBoardPageData?.CTOComponents.ValueBoxes[1]
                                .MoreInfoRoute ? (
                                <FiChevronRight />
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </Col>
                ) : (
                  <Col xs={12} sm={4} className="p-0">
                    <Link onClick={toggleActiveRisksModal} className="">
                      <CC_CONTAINER
                        type="cto"
                        size={12}
                        text={
                          <span className="mx-auto cto-font">
                            <CountUp
                              start={0}
                              end={
                                dashBoardPageData?.CTOComponents.ValueBoxes[1]
                                  .BoxValue || 0
                              }
                              separator=""
                              decimals={0}
                              decimal=","
                              useEasing={false}
                              duration="2"
                            />
                          </span>
                        }
                        label={
                          dashBoardPageData != 0
                            ? dashBoardPageData?.CTOComponents.ValueBoxes[1]
                                .BoxTitle
                            : 0
                        }
                        label2={
                          dashBoardPageData != 0
                            ? dashBoardPageData?.CTOComponents.ValueBoxes[1]
                                .BoxSubTitle
                            : 0
                        }
                        linkText={
                          dashBoardPageData != 0
                            ? dashBoardPageData?.CTOComponents.ValueBoxes[1]
                                .BoxFooter
                            : 0
                        }
                        icon={
                          dashBoardPageData != 0
                            ? dashBoardPageData?.CTOComponents.ValueBoxes[1]
                                .BoxIcon
                            : 0
                        }
                        // linkText={dashBoardPageData?.CTOComponents.ValueBoxes[1].BoxFooter}
                      />
                    </Link>
                  </Col>
                )}

                {dashBoardPageData?.SubscriptionDetail &&
                dashBoardPageData?.SubscriptionDetail.PreDataView ? (
                  <Col
                    xs={12}
                    sm={4}
                    className="p-0 parentHover"
                    onMouseOver={() => {
                      // setUsersHover(true)
                    }}
                    onMouseLeave={() => {
                      // setUsersHover(false)
                    }}
                  >
                    <Link className={"visibleDiv"}>
                      <CC_CONTAINER
                        type="cto"
                        size={12}
                        text={
                          <span className="mx-auto cto-font greyText">
                            <CountUp
                              start={0}
                              end={0}
                              separator=""
                              decimals={0}
                              decimal=","
                              useEasing={false}
                              duration="2"
                            />
                          </span>
                        }
                        label={
                          dashBoardPageData?.CTOComponents.ValueBoxes[2]
                            .BoxTitle
                        }
                        label2={
                          <div className="d-flex justify-content-center">
                            <img
                              alt=""
                              src={
                                dashBoardPageData?.CTOComponents.ValueBoxes[2]
                                  .MoreInfoIcon
                              }
                            />
                            <p className="smallGrey pl-3">
                              {dashBoardPageData?.CTOComponents &&
                                dashBoardPageData?.CTOComponents.ValueBoxes[2]
                                  .BoxSubTitle}
                            </p>
                          </div>
                        }
                        icon={
                          dashBoardPageData?.CTOComponents.ValueBoxes[2].BoxIcon
                        }
                        linkText={
                          dashBoardPageData?.CTOComponents &&
                          dashBoardPageData?.CTOComponents.ValueBoxes[2]
                            .BoxFooter
                        }
                      />
                    </Link>
                    <div className={"visibleOnHoverDiv"}>
                      <Col lg={12}>
                        <div
                          className="cc_background  fullHeight"
                          style={{ height: "183px", padding: "25px 10px" }}
                        >
                          <div className="containerPadding">
                            <h3 className="cc_text preData">
                              {
                                dashBoardPageData?.CTOComponents.ValueBoxes[2]
                                  .MoreInfoText
                              }
                            </h3>
                            <p className="blueText">
                              <a
                                href={`/${
                                  dashBoardPageData?.CTOComponents &&
                                  dashBoardPageData?.CTOComponents.ValueBoxes[2]
                                    .MoreInfoRoute
                                }`}
                              >
                                {dashBoardPageData?.CTOComponents &&
                                  dashBoardPageData?.CTOComponents.ValueBoxes[2]
                                    .MoreInfoRouteText}
                              </a>
                              {dashBoardPageData?.CTOComponents.ValueBoxes[2]
                                .MoreInfoRoute ? (
                                <FiChevronRight />
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </Col>
                ) : (
                  <Col xs={12} sm={4} className="p-0">
                    <Link onClick={toggleRiskyUsersModal} className="">
                      <CC_CONTAINER
                        type="cto"
                        size={12}
                        text={
                          <span className="mx-auto cto-font">
                            <CountUp
                              start={0}
                              end={
                                dashBoardPageData?.CTOComponents.ValueBoxes[2]
                                  .BoxValue || 0
                              }
                              separator=""
                              decimals={0}
                              decimal=","
                              useEasing={false}
                              duration="2"
                            />
                          </span>
                        }
                        label={
                          dashBoardPageData != 0
                            ? dashBoardPageData?.CTOComponents.ValueBoxes[2]
                                .BoxTitle
                            : 0
                        }
                        label2={
                          dashBoardPageData != 0
                            ? dashBoardPageData?.CTOComponents.ValueBoxes[2]
                                .BoxSubTitle
                            : 0
                        }
                        linkText={
                          dashBoardPageData != 0
                            ? dashBoardPageData?.CTOComponents.ValueBoxes[2]
                                .BoxFooter
                            : 0
                        }
                        icon={
                          dashBoardPageData != 0
                            ? dashBoardPageData?.CTOComponents.ValueBoxes[2]
                                .BoxIcon
                            : 0
                        }
                      />
                    </Link>
                  </Col>
                )}

                {/* <CTO text="25" label="Active Threats" label2="You have active threats!" linkText="View active threats"/>
                            <CTO text="25" label="Active Threats" label2="You have active threats!" linkText="View active threats"/>
                             */}
              </Row>
              <Row className="pb-4"></Row>
              <CyberScoreComponent scorevalue={cyberScoreValue}    loading={loading} setShowTechnicalDrillDowncyberScoreModal={setShowTechnicalDrillDowncyberScoreModal}
               ScoreAttestation={dashBoardPageData?.CommonComponents?.ScoreAttestation}/>
              {dashBoardPageData?.PostLoadAction == 3 ? <DashboardModal /> : ""}
              {dashBoardPageData?.PostLoadAction == 2 ? (
                <IntegrationsModal />
              ) : (
                ""
              )}
            </Container>
 
 
 
 }


  {/* CFO Dashboard */}

  {
                  pageType==="cfo" && dashBoardPageData &&
                  
                  <Container className="dash_container">
                  <br />
                  <br />
                  <br />
                  {dashBoardPageData?.CFOComponents !== null &&
                  dashBoardPageData?.CFOComponents.hasOwnProperty("ValueBoxes") ? (
                    <Row>
                      {dashBoardPageData?.SubscriptionDetail &&
                      dashBoardPageData?.SubscriptionDetail.PreDataView ? (
                        <Col
                          xs={12}
                          sm={4}
                          className="p-0 parentHover"
                          onMouseOver={() => {
                            //  setInvestmentHover(true)
                          }}
                          onMouseLeave={() => {
                            //  setInvestmentHover(false)
                          }}
                        >
                          <Link className={"visibleDiv"}>
                            <CC_CONTAINER
                              size={12}
                              type="cfo"
                              text={
                                <span className="mx-auto cfo-font greyText">
                                  <CountUp
                                    start={0}
                                    end={0}
                                    decimals={0}
                                    useEasing={false}
                                    duration="2"
                                  />
                                </span>
                              }
                              label={
                                dashBoardPageData?.CFOComponents.ValueBoxes[0]
                                  .BoxTitle
                              }
                              label2={
                                <div className="d-flex justify-content-center">
                                  <img
                                    alt=""
                                    src={
                                      dashBoardPageData?.CFOComponents &&
                                      dashBoardPageData?.CFOComponents.ValueBoxes[0]
                                        .MoreInfoIcon
                                    }
                                  />
                                  <p className="smallGrey pl-3">
                                    {dashBoardPageData?.CFOComponents &&
                                      dashBoardPageData?.CFOComponents.ValueBoxes[0]
                                        .BoxSubTitle}
                                  </p>
                                </div>
                              }
                              icon={
                                dashBoardPageData?.CFOComponents &&
                                dashBoardPageData?.CFOComponents.ValueBoxes[0]
                                  .BoxIcon
                              }
                              linkText={
                                dashBoardPageData?.CFOComponents &&
                                dashBoardPageData?.CFOComponents.ValueBoxes[0]
                                  .BoxFooter
                              }
                            />
                          </Link>
                          <div className={"visibleOnHoverDiv"}>
                            <div
                              className="cc_background  fullHeight"
                              style={{ height: "192px", padding: "25px 10px" }}
                            >
                              <div className="containerPadding">
                                <h3 className="cc_text preData">
                                  {
                                    dashBoardPageData?.CFOComponents.ValueBoxes[0]
                                      .MoreInfoText
                                  }
                                </h3>
                                <p className="blueText">
                                  <a
                                    href={`/${
                                      dashBoardPageData?.CFOComponents &&
                                      dashBoardPageData?.CFOComponents.ValueBoxes[0]
                                        .MoreInfoRoute
                                    }?modal=${
                                      dashBoardPageData?.CFOComponents &&
                                      dashBoardPageData?.CFOComponents.ValueBoxes[0]
                                        .MoreInfoModal
                                    }`}
                                  >
                                    {dashBoardPageData?.CFOComponents &&
                                      dashBoardPageData?.CFOComponents.ValueBoxes[0]
                                        .MoreInfoRouteText}
                                  </a>
                                  {dashBoardPageData?.CFOComponents.ValueBoxes[0]
                                    .MoreInfoRouteText ? (
                                    <FiChevronRight />
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      ) : (
                        <Col xs={12} sm={4} className="p-0">
                          <Link onClick={toggleInvestmentModalDisplay} className="">
                            <CC_CONTAINER
                              size={12}
                              type="cfo"
                              text={
                                <span className="mx-auto cfo-font">
                                  <CountUp
                                    start={0}
                                    end={investmentNumber || 0}
                                    separator=""
                                    decimals={investmentDecimalNo}
                                    decimal={investmentDecimal}
                                    prefix="£"
                                    suffix={investmentDecimalSuffix}
                                    useEasing={false}
                                    duration="2"
                                  />
                                </span>
                              }
                              label={
                                dashBoardPageData?.CFOComponents.ValueBoxes[0]
                                  .BoxTitle
                              }
                              label2={
                                dashBoardPageData?.CFOComponents.ValueBoxes[0]
                                  .BoxSubTitle
                              }
                              linkText={
                                dashBoardPageData?.CFOComponents.ValueBoxes[0]
                                  .BoxFooter
                              }
                            />
                          </Link>
                        </Col>
                      )}
                      {dashBoardPageData?.SubscriptionDetail &&
                      dashBoardPageData?.SubscriptionDetail.PreDataView ? (
                        <Col
                          xs={12}
                          sm={4}
                          className="p-0 parentHover"
                          onMouseOver={() => {
                            //  setCyberSavingHover(true)
                          }}
                          onMouseLeave={() => {
                            //  setCyberSavingHover(false)
                          }}
                        >
                          <Link className={"visibleDiv"}>
                            <CC_CONTAINER
                              size={12}
                              type="cfo"
                              text={
                                <span className="mx-auto cfo-font greyText">
                                  <CountUp
                                    start={0}
                                    end={0}
                                    decimals={0}
                                    useEasing={false}
                                    duration="2"
                                  />
                                </span>
                              }
                              label={
                                dashBoardPageData?.CFOComponents.ValueBoxes[1]
                                  .BoxTitle
                              }
                              label2={
                                <div className="d-flex justify-content-center">
                                  <img
                                    alt=""
                                    src={
                                      dashBoardPageData?.CFOComponents &&
                                      dashBoardPageData?.CFOComponents.ValueBoxes[1]
                                        .MoreInfoIcon
                                    }
                                  />
                                  <p className="smallGrey pl-3">
                                    {dashBoardPageData?.CFOComponents &&
                                      dashBoardPageData?.CFOComponents.ValueBoxes[1]
                                        .BoxSubTitle}
                                  </p>
                                </div>
                              }
                              icon={
                                dashBoardPageData?.CFOComponents &&
                                dashBoardPageData?.CFOComponents.ValueBoxes[1]
                                  .BoxIcon
                              }
                              linkText={
                                dashBoardPageData?.CFOComponents &&
                                dashBoardPageData?.CFOComponents.ValueBoxes[1]
                                  .BoxFooter
                              }
                            />
                          </Link>
                          <div className={"visibleOnHoverDiv"}>
                            <div
                              className="cc_background  fullHeight"
                              style={{ height: "192px", padding: "25px 10px" }}
                            >
                              <div className="containerPadding">
                                <h3 className="cc_text preData">
                                  {
                                    dashBoardPageData?.CFOComponents.ValueBoxes[1]
                                      .MoreInfoText
                                  }
                                </h3>
                                <p className="blueText">
                                  <a
                                    href={`/${
                                      dashBoardPageData?.CFOComponents &&
                                      dashBoardPageData?.CFOComponents.ValueBoxes[1]
                                        .MoreInfoRoute
                                    }`}
                                  >
                                    {dashBoardPageData?.CFOComponents &&
                                      dashBoardPageData?.CFOComponents.ValueBoxes[1]
                                        .MoreInfoRouteText}
                                  </a>
                                  {dashBoardPageData?.CFOComponents.ValueBoxes[1]
                                    .MoreInfoRoute ? (
                                    <FiChevronRight />
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      ) : (
                        <Col xs={12} sm={4} className="p-0">
                          <Link onClick={toggleSavingsModalDisplay} className="">
                            <CC_CONTAINER
                              size={12}
                              type="cfo"
                              text={
                                <span className="mx-auto cfo-font">
                                  {
                                    <CountUp
                                      start={0}
                                      end={savingNumber || 0}
                                      separator=""
                                      decimals={savingsDecimalNo}
                                      decimal={savingsDecimal}
                                      prefix="£"
                                      suffix={savingsDecimalSuffix}
                                      useEasing={false}
                                      duration="2"
                                    />
                                  }
                                </span>
                              }
                              label={
                                dashBoardPageData?.CFOComponents.ValueBoxes[1]
                                  .BoxTitle
                              }
                              label2={
                                dashBoardPageData?.CFOComponents.ValueBoxes[1]
                                  .BoxSubTitle
                              }
                              linkText={
                                dashBoardPageData?.CFOComponents.ValueBoxes[1]
                                  .BoxFooter
                              }
                            />
                          </Link>
                        </Col>
                      )}
    
                      {dashBoardPageData?.SubscriptionDetail &&
                      dashBoardPageData?.SubscriptionDetail?.PreDataView ? (
                        <Col
                          xs={12}
                          sm={4}
                          className="p-0 parentHover"
                          onMouseOver={() => {
                            // setRoiHover(true)
                          }}
                          onMouseLeave={() => {
                            // setRoiHover(false)
                          }}
                        >
                          <Link className={"visibleDiv"}>
                            <CC_CONTAINER
                              size={12}
                              type="cfo"
                              text={
                                <span className="mx-auto cfo-font greyText">
                                  <CountUp
                                    start={0}
                                    end={0}
                                    decimals={0}
                                    useEasing={false}
                                    suffix="%"
                                    duration="2"
                                  />
                                </span>
                              }
                              label={
                                dashBoardPageData?.CFOComponents.ValueBoxes[2]
                                  .BoxTitle
                              }
                              label2={
                                <div className="d-flex justify-content-center">
                                  <img
                                    alt=""
                                    src={
                                      dashBoardPageData?.CFOComponents &&
                                      dashBoardPageData?.CFOComponents
                                        ?.ValueBoxes?.[2]?.MoreInfoIcon
                                    }
                                  />
                                  <p className="smallGrey pl-3">
                                    {dashBoardPageData?.CFOComponents &&
                                      dashBoardPageData?.CFOComponents
                                        ?.ValueBoxes?.[2]?.BoxSubTitle}
                                  </p>
                                </div>
                              }
                              icon={
                                dashBoardPageData?.CFOComponents &&
                                dashBoardPageData?.CFOComponents?.ValueBoxes?.[2]
                                  ?.BoxIcon
                              }
                              linkText={
                                dashBoardPageData?.CFOComponents &&
                                dashBoardPageData?.CFOComponents?.ValueBoxes[2]
                                  ?.BoxFooter
                              }
                            />
                          </Link>
                          <div className={"visibleOnHoverDiv"}>
                            <div
                              className="cc_background  fullHeight"
                              style={{ height: "192px", padding: "25px 10px" }}
                            >
                              <div className="containerPadding">
                                <h3 className="cc_text preData">
                                  {
                                    dashBoardPageData?.CFOComponents
                                      ?.ValueBoxes?.[2]?.MoreInfoText
                                  }
                                </h3>
                                <p className="blueText">
                                  <a
                                    href={`/${
                                      dashBoardPageData?.CFOComponents &&
                                      dashBoardPageData?.CFOComponents
                                        ?.ValueBoxes?.[2]?.MoreInfoRoute
                                    }`}
                                  >
                                    {dashBoardPageData?.CFOComponents &&
                                      dashBoardPageData?.CFOComponents
                                        ?.ValueBoxes?.[2]?.MoreInfoRouteText}
                                  </a>
                                  {dashBoardPageData?.CFOComponents?.ValueBoxes?.[2]
                                    ?.MoreInfoRoute ? (
                                    <FiChevronRight />
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      ) : (
                        <Col xs={12} sm={4} className="p-0">
                          <Link onClick={toggleROIModalDisplay} className="">
                            <CC_CONTAINER
                              size={12}
                              type="cfo"
                              text={
                                <span className="mx-auto cfo-font ">
                                  <CountUp
                                    start={0}
                                    end={
                                      dashBoardPageData?.CFOComponents?.ValueBoxes?.[2]?.BoxValue?.replace(
                                        ",",
                                        "."
                                      ) || 0
                                    }
                                    decimal=","
                                    decimals={0}
                                    suffix="%"
                                    useEasing={false}
                                    duration="2"
                                    seperator=","
                                  />
                                </span>
                              }
                              label={
                                dashBoardPageData?.CFOComponents?.ValueBoxes?.[2]
                                  ?.BoxTitle
                              }
                              label2={
                                dashBoardPageData?.CFOComponents?.ValueBoxes?.[2]
                                  ?.BoxSubTitle
                              }
                              linkText={
                                dashBoardPageData?.CFOComponents?.ValueBoxes?.[2]
                                  ?.BoxFooter
                              }
                            />
                          </Link>
                        </Col>
                      )}
                    </Row>
                  ) : (
                    ""
                  )}
                  <Row className="pb-4"></Row>
                  <CyberScoreComponent scorevalue={cyberScoreValue}    ScoreAttestation={dashBoardPageData?.CommonComponents?.ScoreAttestation}
                  
                  
                  loading={loading} setShowTechnicalDrillDowncyberScoreModal={setShowTechnicalDrillDowncyberScoreModal}
                  
                  />
                  {dashBoardPageData?.PostLoadAction == 3 ? <DashboardModal /> : ""}
                  {dashBoardPageData?.PostLoadAction == 2 ? (
                    <IntegrationsModal />
                  ) : (
                    ""
                  )}
                </Container>
                  
                  
                  
                  
                  
                  }








                <ModalDynamic showModal={false} header={"Cyber ROI Details"} />
                <ModalDynamic
                  showModal={false}
                  header={"Cyber ROI Details"}
                  modalSize="lg"
                />
                {reportModalOpen ? (
                  <ModalDynamic
                    setShow={true}
                    modalData={<IncidentResponseForm />}
                    modalHeader={"Report an Incident"}
                  />
                ) : (
                  ""
                )}
              </div>
            </Col>
            {dbObj !== 0 ? (
              <Col xs={12} sm={3} className="sideBar fullHeight">
                <SideBar
                  refreshData={refreshData}
                  threatNum={
                    dbObj !== 0
                      ? dashBoardPageData?.CommonComponents?.CyberThreatNumber
                      : 0
                  }
                />
              </Col>
            ) : (
              ""
            )}
            {/* <CreateTaskModal /> */}
            {/* <TechnicalDrillDownCyberScoreModal
              show={showTechnicalDrillDowncyberScoreModal}
              hideModal={() => setShowTechnicalDrillDowncyberScoreModal(false)}
            /> */}
            <CyberGroupModal
              isSaas={true}
              show={showTechnicalDrillDowncyberScoreModal || showTechnicalDrillDownCyberComplianceModal}
              hideModal={() => {
                setShowTechnicalDrillDowncyberScoreModal(false);
                setShowTechnicalDrillDownCyberComplianceModal(false)
                if (pageType === "ceo") {
                  makeAPICall(0, false);
                } else if (pageType === "cto") {
                  makeAPICall(1, false);
                } else if (pageType === "cfo") {
                  makeAPICall(2, false);
                } else if (pageType.toLowerCase() === "other") {
                  makeAPICall(0, false);
                }
              }}
              clientId={0}
              parentID={"mainBody"}
              defaultTab={showTechnicalDrillDownCyberComplianceModal? "cyberCompliance"   :"cyberScore"}
            />
            <UpgradeToPremiumModal
              show={showUpgradeToPremiumModal}
              hideModal={() => setShowUpgradeToPremiumModal(false)}
              upgradeOptions={upgradeOptions}
            />
          </Row>
        )}
      </>
    );
  }
};

const mapStateToProps = createStructuredSelector({
  user: getUser(),
});

const compose = connect(mapStateToProps, { setuser });

export default compose(Dashboard);


const CyberScoreComponent = ({ scorevalue ,ScoreAttestation,loading,setShowTechnicalDrillDowncyberScoreModal}) => {
  return (
    <React.Fragment>
      <Row className="mt-5">
        <Col md={{ span: 4, offset: 0 }}>
          <p className="cyberscore_text">Cyber Score</p>
        </Col>
      </Row>
      <Row>
        <Col sm={12} style={{ height: "270px" }}>
          <Link
            onClick={() => {
              // setlargeModal(false);
              setShowTechnicalDrillDowncyberScoreModal(true);
              // setTimeout(
              //   () => setlargeModal("Technical Drilldown Cyber Score"),
              //   500
              // );
              // setShowTechnicalDrillDowncyberScoreModal(true);
            }}
            // onClick={toggleScoreModalDisplay}
          >
            {!loading && (
              <CScore
                id="dashboard-cyberscore"
                style={{ height: "240px" }}
                Width="240"
                Height="240"
                scoreNumber={scorevalue}
                ScoreAttestation={ScoreAttestation}
              />
            )}
          </Link>
        </Col>
        <Col md={12} className="text-center" style={{ marginTop: "54px" }}>
          <p
            className="greyLinkText"
            onClick={() => {
              // setlargeModal(false);
              // setTimeout(
              //   () => setlargeModal("Technical Drilldown Cyber Score"),
              //   10
              // );
              setShowTechnicalDrillDowncyberScoreModal(true);
            }}
          >
            Score Details <FiChevronRight />
          </p>
        </Col>
      </Row>

      {/* <Row className="score_container">
        <Col xs={12}>
          <ScoreDetails />
        </Col>
      </Row> */}
    </React.Fragment>
  );
};