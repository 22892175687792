import React from "react";
import AttackSurfaceTooltip from "../../AttackSurface/AssetsList/AttackSurfaceTooltip";
import "./supplyComplianceManager.scss";

const CyberEssentialTable = ({ goTo, data, accessLevel }) => {
  return (
    <div className="cyber-essential-table hg-border radius-16 p-3 w-100">
      <div className="grid-parent pb-1 pt-3 px-2">
        <div className="f-12 f-500 f-darkgrey pl-3">Date</div>
        <div className="f-12 f-500 f-darkgrey">Cyber Essentials Type</div>
        <div className="f-12 f-500 f-darkgrey">Audit Frequency</div>
        <div className="f-12 f-500 f-darkgrey">Audit Type</div>
        <div className="f-12 f-500 f-darkgrey">Results</div>
        <div className="f-12 f-500 f-darkgrey">Auditor</div>
        <div></div>
      </div>
      <hr style={{ borderTop: "1px solid #EBEDF1" }} />
      {[...data].map((item, index) => {
        return (
          <div
            className="grid-parent grey-background p-4 pointer"
            onClick={() => {
              if (!item?.AllowDrillDown) return;
              goTo(item?.Col1, "CMQuestion", {
                pageEnum: 462,//406,
                accessLevel: accessLevel,
                entityId: item?.EntityId,
              });
            }}
          >
            <div>{item?.Col1} </div>
            <div>{item?.Col2}</div>
            <div>{item?.Col3}</div>
            <div>{item?.Col4}</div>
            <div>{item?.Col5}</div>
            <div className="d-flex">
              <div className="f-black f-500">{item?.Col6}</div>
            </div>
            <div className="d-flex align-items-center justify-content-end">
              {item?.AllowDrillDown ? (
                <img
                  alt=""
                  className="mr-3 pointer"
                  src="/images/horizontal-dots.svg"
                />
              ) : (
                <AttackSurfaceTooltip
                  topLeft
                  content={
                    <span>
                      You dont have access to this data. To view more details,
                      update your access for this Supplier
                    </span>
                  }
                >
                  <img
                    alt=""
                    className="mr-3 pointer"
                    src="/images/supply-chain/integrations-lock.svg"
                  />
                </AttackSurfaceTooltip>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CyberEssentialTable;

// Incident Repsponse Table

export const IncidentResponseTable = ({ goTo, data, accessLevel }) => {
  return (
    <div className="incident-reponse-table hg-border radius-16 p-3 w-100">
      <div className="grid-parent pb-1 pt-3 px-2">
        <div className="f-12 f-500 f-darkgrey pl-3">Review Date</div>
        <div className="f-12 f-500 f-darkgrey">Review Frequency</div>
        <div className="f-12 f-500 f-darkgrey">Review Type</div>
        <div className="f-12 f-500 f-darkgrey">Results</div>
        <div className="f-12 f-500 f-darkgrey">Auditor</div>

        <div></div>
      </div>
      <hr style={{ borderTop: "1px solid #EBEDF1" }} />
      {[...data].map((item, index) => {
        return (
          <div
            className="grid-parent grey-background p-4 pointer"
            onClick={() => {
              if (!item?.AllowDrillDown) return;
              goTo(item?.Col1, "CMQuestion", {
                pageEnum: 463,//407
                accessLevel: accessLevel,
                entityId: item?.EntityId,
              });
            }}
          >
            <div>{item?.Col1} </div>
            <div>{item?.Col2}</div>
            <div>{item?.Col3}</div>
            <div>{item?.Col4}</div>
            {/* <div>{item?.Col5}</div> */}
            <div className="d-flex">
              <div className="f-black f-500">{item?.Col5}</div>
            </div>
            <div className="d-flex align-items-center justify-content-end">
              {item?.AllowDrillDown ? (
                <img
                  alt=""
                  className="mr-3 pointer"
                  src="/images/horizontal-dots.svg"
                />
              ) : (
                <AttackSurfaceTooltip
                  topLeft
                  content={
                    <span>
                      You dont have access to this data. To view more details,
                      update your access for this Supplier
                    </span>
                  }
                >
                  <img
                    alt=""
                    className="mr-3 pointer"
                    src="/images/supply-chain/integrations-lock.svg"
                  />
                </AttackSurfaceTooltip>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const BCDRPlanTable = ({ goTo, data, accessLevel }) => {
  return (
    <div className="bcdr-plan-table hg-border radius-16 p-3 w-100">
      <div className="grid-parent pb-1 pt-3 px-2">
        <div className="f-12 f-500 f-darkgrey pl-3">Date of Test Recovery</div>
        <div className="f-12 f-500 f-darkgrey">Next Scheduled Test</div>
        <div className="f-12 f-500 f-darkgrey">Type</div>
        <div className="f-12 f-500 f-darkgrey">Engineer</div>
        <div className="f-12 f-500 f-darkgrey">Result</div>

        <div></div>
      </div>
      <hr style={{ borderTop: "1px solid #EBEDF1" }} />
      {[...data].map((item, index) => {
        return (
          <div
            className="grid-parent grey-background p-4 pointer"
            onClick={() => {
              if (!item?.AllowDrillDown) return;
              goTo(item?.Col1, "CMQuestion", {
                pageEnum:466,// 410,
                accessLevel: accessLevel,
                entityId: item?.EntityId,
              });
            }}
          >
            <div>{item?.Col1} </div>
            <div>{item?.Col2}</div>
            <div>{item?.Col3}</div>
            <div className="d-flex">
              <div className="f-black f-500">{item?.Col4}</div>
            </div>
            <div>{item?.Col5}</div>
            {/* <div>{item?.Col5}</div> */}

            <div className="d-flex align-items-center justify-content-end">
              {item?.AllowDrillDown ? (
                <img
                  alt=""
                  className="mr-3 pointer"
                  src="/images/horizontal-dots.svg"
                />
              ) : (
                <AttackSurfaceTooltip
                  topLeft
                  content={
                    <span>
                      You dont have access to this data. To view more details,
                      update your access for this Supplier
                    </span>
                  }
                >
                  <img
                    alt=""
                    className="mr-3 pointer"
                    src="/images/supply-chain/integrations-lock.svg"
                  />
                </AttackSurfaceTooltip>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
