import React, { Fragment, useState, useEffect } from "react";
import $ from "jquery";
import {
  Table,
  Button,
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
  Modal,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import { getAPIData, getRequestOptions } from "../../../Utils/commonApi";
import ModalDynamic from "../../ModalDynamic/ModalDynamic";
import { useAuth } from "../../context/Auth";
import { Link, useHistory } from "react-router-dom";
import { FiCheck, FiX } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgressBar from "../../CircularProgressBar";
import "../Settings.css";
import ContentLoader from "react-content-loader";
import { TrackUser } from "../../../Utils/SegmentFunctions";

const Rewards = () => {
  const { authTokens } = useAuth();
  const [subscriptionPlans, setSubscriptionPlans] = useState(0);
  const [isPlanMonthly, setIsPlanMonthly] = useState(false);
  const [isIntegrationMonthly, setIsIntegrationMonthly] = useState(false);
  const [showRulesModal, setShowRulesModal] = useState(false);
  const [referralDetail, setReferralDetail] = useState([]);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [copy, isCopy] = useState(false);
  const [bars, setBars] = useState();
  const [showToast, setShowToast] = useState(false);
  const [sent, isSent] = useState(false);
  const [sendInvite, isSendInvite] = useState(true);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isProServiceOpen, setIsProServiceOpen] = useState(false);
  const [isActiveInt, setisActiveInt] = useState(false);
  const [isActivePro, setisActivePro] = useState(false);
  const history = useHistory();
  useEffect(() => {
    // getSubscriptionPlans();
    getReferralDetail();
    if (email) {
      isSent(false);
      isSendInvite(true);
    }
  }, [email]);

  async function getSubscriptionPlans() {
    const result = await getAPIData("90", authTokens);
    setSubscriptionPlans(result);
  }
  async function getReferralDetail() {
    const result = await getAPIData("130", authTokens);

    const claimedPro = result?.ProServices_List?.filter((val) => val.Complete);

    const unclaimedPro = result?.ProServices_List?.filter(
      (val) => !val.Complete
    );

    const claimed = result?.Referral_List?.filter(
      (val) => val.Referral_Complete
    );
    const unclaimed = result?.Referral_List?.filter(
      (val) => !val.Referral_Complete
    );

    result.Referral_List = claimed?.concat(unclaimed);
    result.ProServices_List = claimedPro?.concat(unclaimedPro);

    setReferralDetail(result);
    setBars(result?.Referral_TotalIntegrations);
  }
  async function handleSendInvite() {
    if (handleValidations()) {
    } else {
      setError(false);
      isSendInvite(false);
      setIsSendingEmail(true);
      const data = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/CC_API/SendReferral`,
        // `https://hg-dev-ui-ac-api.azurewebsites.net/CC_API/SendReferral`,
        getRequestOptions(authTokens, "POST", {
          EmailAddress: email,
          AccessKey: localStorage.getItem("user_MessageObj")
            ? JSON.parse(localStorage.getItem("user_MessageObj"))
            : "null",
        })
      ).then((response) => response.json());
      if (data.mr.Success == true) {
        window.localStorage.setItem(
            "user_MessageObj",
            JSON.stringify(data?.mr?.Message)
          );
        TrackUser("Invite sent");
        setIsSendingEmail(false);
        isSent(true);
        setEmail("");
        setShowToast(true);

        // setReferralDetail(response);
        toast.dark(
          <span>
            <FiCheck style={{ marginRight: "10px" }} />
            Rewards updated successfully
            <span className="float-right">
              <CircularProgressBar durationInSec="30" />
            </span>
          </span>
        );
      } else {
        setEmail("");
        setIsSendingEmail(false);
        isSendInvite(true);
        toast.dark(
          <span>
            <FiX style={{ marginRight: "10px", color: "#f36060" }} />
            <span style={{ width: "500px", color: "#f36060" }}>
              Unable to update
            </span>
            <span className="float-right">
              <CircularProgressBar durationInSec="30" />
            </span>
          </span>
        );
      }
      // })
      // .catch((err) => {
      //   toast.dark(
      //     <span>
      //       <FiX style={{ marginRight: "10px", color: "#f36060" }} />
      //       <span style={{ width: "500px", color: "#f36060" }}>
      //         Unable to update
      //       </span>
      //       <span className="float-right">
      //         <CircularProgressBar durationInSec="30" />
      //       </span>
      //     </span>
      //   );
      // });
    }
  }

  const getRenderedItems = () => {
    if (isOpen) {
      return referralDetail && referralDetail.Referral_List;
    }
    return (
      referralDetail &&
      referralDetail.Referral_List &&
      referralDetail.Referral_List.slice(0, 10)
    );
  };
  const getProServiceRenderedItems = () => {
    if (isProServiceOpen) {
      return referralDetail && referralDetail.ProServices_List;
    }
    return (
      referralDetail &&
      referralDetail.ProServices_List &&
      referralDetail.ProServices_List.slice(0, 10)
    );
  };

  const copyTextHandler = (link) => {
    navigator.clipboard.writeText(link);
    isCopy(true);
  };

  async function handeleResendReferral(email, referralId, index, referral) {
    referralDetail.Referral_List.map((data) => {
      if (referral.Referral_Id == referralId) {
        referral.sending = true;
      }
    });
    setReferralDetail({
      ...referralDetail,
      referralDetail: referralDetail,
    });
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/ResendReferral`,
      // `https://hg-dev-ui-ac-api.azurewebsites.net/CC_API/ResendReferral`,
      getRequestOptions(authTokens, "POST", {
        EmailAddress: email,
        AccessKey: localStorage.getItem("user_MessageObj")
          ? JSON.parse(localStorage.getItem("user_MessageObj"))
          : "null",
      })
    ).then((response) => response.json());
    setShowToast(true);
    if (data.Success == true) {
      referralDetail.Referral_List.map((data) => {
        if (data.Referral_Id == referralId) {
          data.sent = true;
        }
      });
      setReferralDetail({
        ...referralDetail,
        referralDetail: referralDetail,
      });
      toast.dark(
        <span>
          <FiCheck style={{ marginRight: "10px" }} />
          Rewards updated successfully
          <span className="float-right">
            <CircularProgressBar durationInSec="30" />
          </span>
        </span>
      );
    } else {
      toast.dark(
        <span>
          <FiX style={{ marginRight: "10px", color: "#f36060" }} />
          <span style={{ width: "500px", color: "#f36060" }}>
            Unable to update
          </span>
          <span className="float-right">
            <CircularProgressBar durationInSec="30" />
          </span>
        </span>
      );
    }
  }

  async function handeleCancelReferral(referralId, index, referral) {
    referralDetail.Referral_List.map((data) => {
      if (referral.Referral_Id == referralId) {
        referral.cancelling = true;
      }
    });
    setReferralDetail({
      ...referralDetail,
      referralDetail: referralDetail,
    });
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/CancelReferral`,
      // `https://hg-dev-ui-ac-api.azurewebsites.net/CC_API/CancelReferral`,
      getRequestOptions(authTokens, "POST", {
        ReferralId: referralId,
        AccessKey: localStorage.getItem("user_MessageObj")
          ? JSON.parse(localStorage.getItem("user_MessageObj"))
          : "null",
      })
    ).then((response) => response.json());
    if (data.Success == true) {
      const updatedReferral = referralDetail.Referral_List.splice(index, 1);
      getReferralDetail();
      setShowToast(true);
      toast.dark(
        <span>
          <FiCheck style={{ marginRight: "10px" }} />
          Rewards updated successfully
          <span className="float-right">
            <CircularProgressBar durationInSec="30" />
          </span>
        </span>
      );
    } else {
      toast.dark(
        <span>
          <FiX style={{ marginRight: "10px", color: "#f36060" }} />
          <span style={{ width: "500px", color: "#f36060" }}>
            Unable to update
          </span>
          <span className="float-right">
            <CircularProgressBar durationInSec="30" />
          </span>
        </span>
      );
    }
    // })
    // .catch((err) => {
    //   toast.dark(
    //     <span>
    //       <FiX style={{ marginRight: "10px", color: "#f36060" }} />
    //       <span style={{ width: "500px", color: "#f36060" }}>
    //         Unable to update
    //       </span>
    //       <span className="float-right">
    //         <CircularProgressBar durationInSec="30" />
    //       </span>
    //     </span>
    //   );
    // });
  }
  const changePlanType = (e) => {
    if (e.target.name == "integration_pack") {
      setIsIntegrationMonthly(!isIntegrationMonthly);
    } else {
      setIsPlanMonthly(!isPlanMonthly);
    }
  };
  const handleValidations = () => {
    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (email == "") {
      setError("Email field is required");
      return true;
    } else if (email && !re.test(email)) {
      setError("Invalid email address format");
      return true;
    } else {
      setError("Failed");
    }
    return false;
  };
  const Editions = {
    0: "Freemium",
    1: "Premium",
    2: "Enterprise",
  };

  const renderTooltip = (message) => (
    <Tooltip className="custom_tooltip">
      <div className="custom_tooltip_wrapper">
        <div className="tooltip_header border-bottom-0">
          <span className="tooltip_subtitle" style={{ color: "#F36060" }}>
            {message}
          </span>
        </div>
      </div>
    </Tooltip>
  );

  const referralData = () => {
    return (
      <div className="ml24px mr24px">
        <span className="main-bold-heading">How it Works</span>
        <p className="textBlack mt16px">
          HighGround users are rewarded with Free Integrations and Professional
          Services Credit for referring colleagues, peers or any individual who
          will use the product within their business to improve their cyber
          security compliance, visibility or access services available for
          purchase through HighGround. Users should not refer to friends, family
          or any individual who has no intention of using or reasonable use for
          the product.
        </p>
        <p className="textBlack mt16px" style={{ marginBottom: "40px" }}>
          Users are rewarded with a Free Integration upon account signup,
          whether directly or via referral. The first 2 referrals are rewarded
          with Free Integrations. Subsequent referrals are each rewarded with £5
          Professional Services credit, up to a maximum of £500 for the lifetime
          of the account. In both scenarios, the user must complete signup for a
          Freemium account using the link in the referral email for the referrer
          to redeem the reward. Rewards are redeemed automatically and should be
          available immediately after the user completes signup, however this
          can take up to 24 hours. If you do not have your reward within 24
          hours, please contact support from within your portal.
        </p>
        <span className="main-bold-heading">Restrictions</span>
        <p className="textBlack mt16px">
          Users cannot invite users using free or anonymous email services. Any
          user suspected to be using a free or throwaway email address which is
          not banned during registration, but which is later identified as being
          so, will have the reward associated with this referral revoked, and
          may have their account suspended indefinitely and without notice. Any
          credit consumed to this point for services purchased directly or
          indirectly via HighGround will also be rescinded, and users will be
          liable to repay any credit used up to that point.{" "}
        </p>
        <Button
          className="w-100 submit-btn"
          onClick={() => $(".fade.bigModal").click()}
          style={{ marginTop: "40px" }}
        >
          Okay
        </Button>
      </div>
    );
  };

  const handleChecked = (id) => {};
  const message = error ? error : "";
  return referralDetail.length == 0 ? (
    <Fragment>
      <ContentLoader
        style={{ marginTop: "-80px" }}
        speed={2}
        width={1560}
        height={1000}
        viewBox="30 -80 1500 1000"
        backgroundColor="#f1f2f7e8"
        foregroundColor="#ebecf1"
      >
        <rect
          x="0"
          y="70"
          rx="2"
          ry="2"
          height={300}
          width={1500}
          style={{ height: "8", width: "22.5%" }}
        />
        <rect
          x="0"
          y="85"
          rx="2"
          ry="2"
          height={300}
          width={1500}
          style={{ height: "8", width: "22.5%" }}
        />
        <rect
          x="0"
          y="100"
          rx="2"
          ry="2"
          height={300}
          width={1500}
          style={{ height: "8", width: "22.5%" }}
        />
        <rect
          x="0"
          y="115"
          rx="2"
          ry="2"
          height={300}
          width={1500}
          style={{ height: "8", width: "22.5%" }}
        />
        <rect
          x="0"
          y="130"
          rx="2"
          ry="2"
          height={300}
          width={1500}
          style={{ height: "8", width: "22.5%" }}
        />
        <rect
          x="355"
          y="20"
          rx="15"
          ry="15"
          height={300}
          width={1500}
          style={{ height: "150", width: "45%" }}
        />
        <rect
          x="1050"
          y="20"
          rx="15"
          ry="15"
          height={300}
          width={1500}
          style={{ height: "150", width: "30%" }}
        />
        <rect
          x="0"
          y="200"
          rx="15"
          ry="15"
          height={300}
          width={1500}
          style={{ height: "300", width: "100%" }}
        />
        <rect
          x="0"
          y="515"
          rx="15"
          ry="15"
          height={300}
          width={1500}
          style={{ height: "300", width: "100%" }}
        />
      </ContentLoader>
    </Fragment>
  ) : (
    <Fragment>
      {showToast && (
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
        />
      )}
      <div className="settings_container">
        <Table
          className="settings_table cybercare-table"
          style={{ marginBottom: "40px" }}
        >
          <thead>
            <tr>
              <th
                className="border-0 pl-0 align-top"
                style={{ width: "350px" }}
              >
                <h1 className="mt24px">Credit balance</h1>
                <span className="linkGreyText">
                  Invite your peers to HighGround to earn up to 3 free
                  integrations, then £5 credit per referral thereafter which can
                  be used towards professional services, up to a maximum of
                  £500.
                </span>
              </th>
              <th className="p24px">
                <h1 className="dashHeader">Free Integrations</h1>
                <span className="main-heading">
                  {referralDetail.Referral_TotalIntegrations}
                </span>
                <span> of {referralDetail.Referral_IntegrationMaxAmount}</span>

                {bars == 3 && (
                  <div
                    className="d-flex justify-content-between"
                    style={{ marginTop: "4px" }}
                  >
                    {referralDetail &&
                      Array(bars)
                        .fill(1)
                        .map((v, i) => (
                          <div className="w-100  mr-1" key={i}>
                            <div
                              className={`mb-2 ${i != 0 ? "invisible" : ""}`}
                              style={{ marginTop: "15px" }}
                            >
                              <img src="/images/icons/checkMark.svg" alt="" />
                              <span
                                className="greenText"
                                style={{ paddingLeft: "6px" }}
                              >
                                Connected
                              </span>
                            </div>
                            <div className="bar-filled"></div>
                          </div>
                        ))}
                  </div>
                )}

                {bars == 2 && (
                  <div
                    className="d-flex justify-content-between"
                    style={{ marginTop: "4px" }}
                  >
                    {referralDetail &&
                      Array(bars)
                        .fill(1)
                        .map((v, i) => (
                          <div className="w-100  mr-1" key={i}>
                            <div
                              className={`mb-2 ${i != 0 ? "invisible" : ""}`}
                              style={{ marginTop: "15px" }}
                            >
                              <img src="/images/icons/checkMark.svg" alt="" />
                              <span
                                className="greenText"
                                style={{ paddingLeft: "6px" }}
                              >
                                Connected
                              </span>
                            </div>
                            <div className="bar-filled"></div>
                          </div>
                        ))}

                    <div className="w-100  mr-1">
                      <div
                        className="mb-2 invisible"
                        style={{ marginTop: "15px" }}
                      >
                        <img src="/images/icons/checkMark.svg" alt="" />
                        <span
                          className="greenText"
                          style={{ paddingLeft: "6px" }}
                        >
                          Connected
                        </span>
                      </div>
                      <div className="bar-active"></div>
                    </div>
                  </div>
                )}

                {bars == 1 && (
                  <div
                    className="d-flex justify-content-between"
                    style={{ marginTop: "4px" }}
                  >
                    {referralDetail &&
                      Array(bars)
                        .fill(1)
                        .map((v, i) => (
                          <div className="w-100  mr-1">
                            <div
                              className={`mb-2 ${i != 0 ? "invisible" : ""}`}
                              style={{ marginTop: "15px" }}
                            >
                              <img src="/images/icons/checkMark.svg" alt="" />
                              <span
                                className="greenText"
                                style={{ paddingLeft: "6px" }}
                              >
                                Connected
                              </span>
                            </div>
                            <div className="bar-filled"></div>
                          </div>
                        ))}

                    <div className="w-100  mr-1">
                      <div
                        className="mb-2 invisible"
                        style={{ marginTop: "15px" }}
                      >
                        <img src="/images/icons/checkMark.svg" alt="" />
                        <span
                          className="greenText"
                          style={{ paddingLeft: "6px" }}
                        >
                          Connected
                        </span>
                      </div>
                      <div className="bar-active"></div>
                    </div>
                    <div className="w-100  mr-1">
                      <div
                        className="mb-2 invisible"
                        style={{ marginTop: "15px" }}
                      >
                        <img src="/images/icons/checkMark.svg" alt="" />
                        <span
                          className="greenText"
                          style={{ paddingLeft: "6px" }}
                        >
                          Connected
                        </span>
                      </div>
                      <div className="bar-not-active"></div>
                    </div>
                  </div>
                )}

                {(bars == 0 || typeof bars === "undefined") && (
                  <div
                    className="d-flex justify-content-between"
                    style={{ marginTop: "4px" }}
                  >
                    <div className="w-100  mr-1">
                      <div
                        className="mb-2 invisible"
                        style={{ marginTop: "15px" }}
                      >
                        <img src="/images/icons/checkMark.svg" alt="" />
                        <span
                          className="greenText"
                          style={{ paddingLeft: "6px" }}
                        >
                          Connected
                        </span>
                      </div>
                      <div className="bar-active"></div>
                    </div>
                    <div className="w-100  mr-1">
                      <div
                        className="mb-2 invisible"
                        style={{ marginTop: "15px" }}
                      >
                        <img src="/images/icons/checkMark.svg" alt="" />
                        <span
                          className="greenText"
                          style={{ paddingLeft: "6px" }}
                        >
                          Connected
                        </span>
                      </div>
                      <div className="bar-not-active"></div>
                    </div>
                    <div className="w-100  mr-1">
                      <div
                        className="mb-2 invisible"
                        style={{ marginTop: "15px" }}
                      >
                        <img src="/images/icons/checkMark.svg" alt="" />
                        <span
                          className="greenText"
                          style={{ paddingLeft: "6px" }}
                        >
                          Connected
                        </span>
                      </div>
                      <div className="bar-not-active"></div>
                    </div>
                  </div>
                )}

                <Button
                  className="btn-blue btn-block mt16px"
                  onClick={() => {
                    TrackUser("Integrated all 3 'free tools'");
                    setTimeout(() => history.push("/integrations"), 200);
                  }}
                >
                  Connect Integration
                </Button>
              </th>
              <th className="p24px">
                <h1 className="dashHeader">Pro Services Credit</h1>
                <span className="main-heading txt-blue">
                  £{referralDetail.ProServices_TotalCredit}
                </span>
                <p className="txt-blue" style={{ height: "45px" }}></p>
                <Link to="/pro_services">
                  <Button className="btn-blue btn-block">Pro Services</Button>
                </Link>
              </th>
            </tr>
          </thead>
        </Table>
        <Row>
          <Col md={12}>
            <h1>Earn Integrations and Pro Services Credit</h1>
            <span className="linkGreyText">
              Invite your peers to HighGround to earn up to 3 free integrations,
              then £5 credit per referral thereafter which can be used towards
              professional services, up to a maximum of £500.
            </span>
            <div
              className={copy ? "grey-input" : ""}
              style={{ marginTop: "32px", marginBottom: "32px" }}
            >
              <span className="txt-blue cursor-pointer">
                <a href={referralDetail.Referral_Link} target="_blank">
                  {referralDetail.Referral_Link}
                </a>
              </span>
              <span
                className="input-btn"
                style={{ marginLeft: "17px" }}
                onClick={() => {
                  copyTextHandler(referralDetail.Referral_Link);
                }}
              >
                {copy ? "copied" : <img src="/images/icons/copyIcon.svg" />}
              </span>
            </div>
            <InputGroup className="grey-input mb8px" style={{ width: "400px" }}>
              <InputGroup.Prepend>
                <InputGroup.Text className="greyText">Email:</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="text"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <InputGroup.Append>
                <InputGroup.Text className="passwordIcon p8px cursor-pointer">
                  <span
                    className={`input-btn ${sent ? " greenText" : ""}`}
                    onClick={() => handleSendInvite()}
                  >
                    {isSendingEmail ? (
                      <span>
                        <Spinner
                          animation="border"
                          variant="info"
                          size="sm"
                          className="mr8px"
                        />
                        Sending
                      </span>
                    ) : sent ? (
                      "Sent!"
                    ) : sendInvite ? (
                      "Send Invite"
                    ) : (
                      ""
                    )}
                  </span>
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            {error && (
              <Form.Control.Feedback type="invalid" className="d-block">
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 200, hide: 250 }}
                  overlay={renderTooltip(message)}
                >
                  <span>
                    <img src="/images/icons/error.svg" /> Send Invite Failed.
                  </span>
                </OverlayTrigger>
              </Form.Control.Feedback>
            )}
            <span className="greyText smallTxt">
              Note: You can refer to a corporate email address only - generic
              email domains (such as gmail.com, outlook.com) are not permitted.
              Read our{" "}
              <span
                className="txt-blue cursor-pointer"
                onClick={() => {
                  setShowRulesModal(false);
                  setInterval(() => {
                    setShowRulesModal(true);
                  }, 100);
                }}
              >
                'referral program rules'
              </span>{" "}
              for more info.
            </span>
            <hr />
            {getRenderedItems() &&
              getRenderedItems().map((referral, index) => (
                <>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      {referral.Referrer_Avatar ? (
                        <img
                          className="circle-small align-self-center"
                          src={
                            referral.Referrer_Avatar && referral.Referrer_Avatar
                          }
                        />
                      ) : (
                        <span className="circle-blue align-self-center">
                          {referral && referral.Referrer_Initials}
                        </span>
                      )}
                      <div>
                        <h1 className="m-0">{referral.Referral_Line1_Black}</h1>
                        <span className="greyText">
                          {/* {referral.Referral_Complete
                            ? `${
                                referral.Referral_Complete
                                  ? referral.Referrer_Email
                                  : referral.Referrer_Name
                              }, `
                            : ""} */}
                          {referral.Referral_Line2_Grey &&
                            `${referral.Referral_Line2_Grey}, `}
                          <a
                            href="#"
                            className={`${
                              referral.Referral_Complete
                                ? "green_text"
                                : "greyText"
                            }  `}
                          >
                            {referral.Referral_Status}
                          </a>
                        </span>
                      </div>
                    </div>
                    {referral.Referral_Complete ? (
                      ""
                    ) : (
                      <div className="d-flex">
                        <div
                          className="align-self-center"
                          style={{ marginRight: "165px" }}
                        >
                          {referral.sent ? (
                            ""
                          ) : (
                            <img
                              src="images/icons/resendIcon.svg"
                              style={{ marginRight: "7px" }}
                            />
                          )}
                          <span
                            style={{ cursor: "pointer" }}
                            className={`txt-blue mr16px ${
                              referral.sent ? "txt-green" : ""
                            }`}
                            onClick={(index) =>
                              handeleResendReferral(
                                referral.Referrer_Email,
                                referral.Referral_Id,
                                index,
                                referral
                              )
                            }
                          >
                            {referral.sent
                              ? "Sent!"
                              : referral.sending
                              ? "Resending…"
                              : "Resend"}
                          </span>
                          <span
                            className="greyText"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handeleCancelReferral(
                                referral.Referral_Id,
                                index,
                                referral
                              )
                            }
                          >
                            {referral.cancelling ? "Cancelling…" : "Cancel"}{" "}
                          </span>
                        </div>
                        <div className="d-flex align-self-center">
                          <span
                            className="greyText"
                            style={{ paddingRight: "10px" }}
                          >
                            £5
                          </span>
                          <Form.Group
                            controlId="checkEleven"
                            className="round-checkbox"
                          >
                            <Form.Check
                              type="checkbox"
                              label=""
                              checked={false}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    )}
                    {referral.Referral_RewardType == "TBC" ||
                    referral.Referral_RewardType == "Integration" ? (
                      ""
                    ) : (
                      <div className="d-flex align-self-center">
                        <span
                          className="greyText"
                          style={{ paddingRight: "10px" }}
                        >
                          £{referral.Referral_AwardAmount}
                        </span>
                        <Form.Group
                          controlId="checkEleven"
                          className="round-checkbox"
                        >
                          <Form.Check
                            type="checkbox"
                            label=""
                            checked={referral.Referral_Complete ? true : false}
                          />
                        </Form.Group>
                      </div>
                    )}
                    {referral.Referral_RewardType == "TBC" ||
                    referral.Referral_RewardType == "Credit" ? (
                      ""
                    ) : (
                      <div className="d-flex align-self-center">
                        <span
                          className="textBlackBold"
                          style={{ paddingRight: "10px" }}
                        >
                          {referral.Referral_IntegrationNumber}/3 Integration
                        </span>
                        <Form.Group
                          controlId="checkNine"
                          className="round-checkbox"
                        >
                          <Form.Check
                            type="checkbox"
                            id={"activeChecked" + index}
                            checked={referral.Referral_Complete ? true : false}
                            onClick={(e) =>
                              handleChecked(index, e.target.checked)
                            }
                            label=""
                          />
                        </Form.Group>
                      </div>
                    )}
                  </div>
                  <hr />
                </>
              ))}
            {/* {referralDetail.Referral_List &&
              referralDetail.Referral_List.map((referral, index) => (
                <>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <span className="circle-blue align-self-center">GY</span>
                      <div>
                        <h1 className="m-0">Georgia Young</h1>
                        <span className="greyText">
                          georgia.young@example.com,{" "}
                          <a href="" className="greenText">
                            Registered
                          </a>
                        </span>
                      </div>
                    </div>
                    {referral.Referral_RewardType == "TBC" ||
                    referral.Referral_RewardType == "Credit" ? (
                      ""
                    ) : (
                      <div className="d-flex align-self-center">
                        <span
                          className="textBlackBold"
                          style={{ paddingRight: "10px" }}
                        >
                          3/3 Integration
                        </span>
                        <Form.Group
                          controlId="checkNine"
                          className="round-checkbox"
                          checked
                        >
                          <Form.Check type="checkbox" defaultChecked label="" />
                        </Form.Group>
                      </div>
                    )}
                  </div>
                  <hr />
                </>
              ))} */}
            <Button
              className="btn-grey textBlackBold"
              onClick={() => setIsOpen(!isOpen)}
            >
              {" "}
              {isOpen ? " Show less" : " Show All"}
            </Button>
            <span className="greyText float-right">
              Earned £{referralDetail.Referral_TotalEarnedAmount}
            </span>
          </Col>
        </Row>
      </div>
      <div
        className="settings_container"
        style={{ marginTop: "20px", marginBottom: "20px" }}
      >
        <Row>
          <Col md={12}>
            <h1>Other Claimed Pro Services Credits</h1>
            <hr />
            {getProServiceRenderedItems() &&
              getProServiceRenderedItems().map((proservice, index) => (
                <>
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="greyText">
                        {proservice.Status}{" "}
                        <img
                          src="/images/tick.svg"
                          alt=""
                          style={{ marginLeft: "2px", marginTop: "-4px" }}
                        />
                      </div>
                      <span className="textBlackBold">{proservice.Name}</span>
                    </div>
                    <div className="d-flex align-self-center">
                      <span
                        className="textBlackBold"
                        style={{ paddingRight: "10px" }}
                      >
                        £{proservice.AwardAmount}
                      </span>
                      <Form.Group
                        controlId="checkOne"
                        className="round-checkbox"
                      >
                        <Form.Check
                          type="checkbox"
                          label=""
                          checked={proservice.Complete ? true : false}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <hr />
                </>
              ))}

            {referralDetail.ProServices_List &&
            referralDetail.ProServices_List.length > 0 ? (
              <Button
                className="btn-grey textBlackBold"
                onClick={() => setIsProServiceOpen(!isProServiceOpen)}
              >
                {isProServiceOpen ? " Show less" : " Show All"}
              </Button>
            ) : (
              ""
            )}
            <span className="greyText float-right">
              Earned £{referralDetail.ProServices_TotalEarnedAmount}
            </span>
          </Col>
        </Row>
      </div>
      {showRulesModal && (
        <ModalDynamic
          setShow={true}
          modalHeader={"Referral Programme Rules"}
          modalData={referralData}
        />
      )}
    </Fragment>
  );
};

export default Rewards;
