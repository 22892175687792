import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import "./technologyFilters.scss";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { createStaticRanges } from "react-date-range";
import moment from "moment";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  addYears,
  startOfQuarter,
  endOfQuarter,
  subQuarters,
  subMonths,
  subYears
} from "date-fns";

const TechnologySpendFilter = ({
  activeFilters,
  setActiveFilters,
  invoiceFilterSearch,
  setInvoiceFilterSearch,
  applyingFilters,
  onClickAdd = () => {},
  onApply = () => {},
  onReset = () => {},
  hideFilters = [],
  subCategoryList = [],
}) => {
  // const [activeFilters, setActiveFilters] = useState([]);
  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
  const [isSubcategoryDropdownOpen, setIsSubcategoryDropdownOpen] = useState(false);
  // const options = document.querySelector(".rdrDefinedRangesWrapper .rdrStaticRanges");
  const options = document.querySelector(".rdrDateRangePickerWrapper .rdrDefinedRangesWrapper")
 
  const newItem = document.createElement('p');
  newItem.innerHTML = 'Options';
  options && options.prepend(newItem);

  const [filterOptions] = useState([
    {
      filterName: "Date",
      filterItemIcon: "/images/msp/msp-date-picker-icon.svg",
      filterData: {
        filterType: "date",
        selectedDateRange: {
          startDate: null, // new Date(),
          endDate: null, //new Date(),
          key: "selection",
        },
      },
    },
    {
      filterName: "Amount",
      filterItemIcon: "/images/msp/msp-amount-filter-icon.svg",
      filterData: {
        filterType: "amount",
        filterAmount: "",
      },
    },
    {
      filterName: "Subcategory",
      filterItemIcon: "/images/msp/msp-subcategory-filter-icon.svg",
      filterData: {
        filterType: "subcategory",
        filterList: [],
      },
    },
  ]);

  const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
    startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
    startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date()),
    startOflastYear: startOfYear(addYears(new Date(), -1)),
    endOflastYear: endOfYear(addYears(new Date(), -1)),
    startOfLastQuarter: startOfQuarter(subQuarters(new Date(), 1)),
    endOfLastQuarter: endOfQuarter(subQuarters(new Date(), 1)),
    startOfLastSixMonth: startOfMonth(subMonths(new Date(), 6)),
    startOfLastTwelveMonth: startOfMonth(subMonths(new Date(), 12)),
    startOfCurrentYear: startOfYear(new Date()),
    endOfPreviousYear: endOfYear(subYears(new Date(), 1)),



  };

  
  const sideBarOptions = () => {
    const customDateObjects = [
      {
        label: "Current Year",
        range: () => ({
          startDate: defineds.startOfCurrentYear,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Previous Year",
        range: () => ({
          startDate: defineds.startOflastYear,
          endDate: defineds.endOfPreviousYear,
        }),
      },
      {
        label: "Last 12 Months",
        range: () => ({
          startDate: defineds.startOfLastTwelveMonth,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Last 6 Months",
        range: () => ({
          startDate: defineds.startOfLastSixMonth,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Last 90 Days",
        range: () => ({
          startDate: defineds.startOfLastNintyDay,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Last 30 Days",
        range: () => ({
          startDate: defineds.startOfLastThirtyDay,
          endDate: defineds.endOfToday,
        }),
      },
    ];
  
    return customDateObjects;
  };

  
  const sideBar = sideBarOptions();
  
  const staticRanges = [
    // ...defaultStaticRanges,
    ...createStaticRanges(sideBar),
  ];
  // useEffect(() => {
  //   if (isDateDropdownOpen === false){
  //     const updatedFilters = activeFilters.filter(filter => filter.filterType !== 'date' || filter.selectedDateRange.startDate !== null);
  //     setActiveFilters(updatedFilters);
  //   }
  // }, [isDateDropdownOpen])
  
  
  // useEffect(() => {
  //   if (isSubcategoryDropdownOpen === false){
  //     const updatedFilters = activeFilters.filter(filter => filter.filterType !== 'subcategory' || filter.filterList.length !== 0);
  //     setActiveFilters(updatedFilters);
  //   }
  // }, [isSubcategoryDropdownOpen])

  return (
    <div className="w-100 filter-box-X">
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center p-2 bg-grey radius-4 highlight-input flex-grow-1">
          <img
            alt=""
            src="/images/attack-surface/search-icon.svg"
            className="mr-2"
          />{" "}
          <input
            type="text"
            className="border-none bg-transparent flex-grow-1"
            placeholder="Search"
            value={invoiceFilterSearch}
            onChange={(e) => {
              setInvoiceFilterSearch(e.target.value);
            }}
          />
        </div>
        <div>
          <Dropdown className="spend-filter-dropdown bg-grey mx-1 radius-4">
            <Dropdown.Toggle>
              <div className="d-flex align-items-center">
                <img
                  alt=""
                  src="/images/msp/msp-filter-cup-icon.svg"
                  className="mr-2"
                />
                <div className="f-500 f-grey">Filters</div>
                <img
                  alt=""
                  src="/images/big-chevron-down.svg"
                  className="ml-2"
                />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-1">
              {filterOptions
                ?.filter((item) => hideFilters.indexOf(item?.filterName) === -1)
                .map((filterItem, filterItemIndex) => {
                  return (
                    <div
                      className="d-flex align-items-center justify-content-between p-2 bg-hover-grey pointer"
                      onClick={(e) => {
                        let updated_active_filters = [...activeFilters];
                        let indexOfFilter = updated_active_filters.findIndex(
                          (item) =>
                            item?.filterType ===
                            filterItem?.filterData?.filterType
                        );
                        if (indexOfFilter === -1) {
                          setActiveFilters([
                            ...updated_active_filters,
                            { ...filterItem?.filterData },
                          ]);
                        }
                        try {
                          setTimeout(() => {
                            let filter_Component =
                              document.querySelector(".filter-box-X");
                            if (filter_Component) {
                              let dropdown = filter_Component.querySelector(
                                `#${filterItem?.filterData?.filterType}_filterX`
                              );

                              if (dropdown) {
                                dropdown
                                  .querySelector(".dropdown-toggle")
                                  .click();
                              }
                            }
                          }, 100);
                        } catch (err) {}

                        e.target.closest(".spend-filter-dropdown").click();
                      }}
                    >
                      <div className="f-500 f-grey">
                        <img
                          src={filterItem.filterItemIcon}
                          alt=""
                          className="mr-2"
                        />
                        {filterItem.filterName}
                      </div>
                      <div></div>
                    </div>
                  );
                })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div>
          <div
            className="p-2 d-flex align-items-center bg-grey f-500 f-grey pointer"
            onClick={onClickAdd}
          >
            <img
              alt=""
              className="mr-2"
              src="/images/actioncenter/plusgrey.svg"
            />
            Add
          </div>
        </div>
      </div>

      {/* Filters Body */}

      {activeFilters?.length > 0 && (
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex flex-wrap">
            {activeFilters?.map((activeFilter, activeFiterIndex) => {
              let selectedFilterAmount = activeFilter?.filterAmount;

              let selectedFilterSubcategories = activeFilter?.filterList;

              let selectedDateRange = activeFilter?.selectedDateRange ?? {};
              let dateRangeSelected =
                (Object.keys(selectedDateRange).length > 0 &&
                  selectedDateRange?.startDate &&
                  selectedDateRange?.endDate) ??
                false;

              return (
                <div className="">
                  {activeFilter?.filterType === "amount" && (
                    <>
                      <Dropdown
                        className="amount-filter-dropdown m-1"
                        id={`${activeFilter?.filterType}_filterX`}
                      >
                        <Dropdown.Toggle className="p-0">
                          <div
                            className={`d-flex align-items-center px-2 py-1 rounded-full ${
                              selectedFilterAmount ? "bg-blue" : "bg-grey"
                            }`}
                          >
                            <div
                              className={`${
                                selectedFilterAmount ? "f-white" : "f-grey"
                              } f-500`}
                            >
                              Amount
                            </div>
                            {selectedFilterAmount && (
                              <div>: £ {selectedFilterAmount}</div>
                            )}
                            <img
                              src="/images/attack-surface/cross-icon.svg"
                              alt=""
                              className={`ml-2 pointer   ${
                                selectedFilterAmount ? "white-filter" : ""
                              }`}
                              onClick={() => {
                                let updated_active_filters = [...activeFilters];
                                // if (updated_active_filters?.length === 1) {
                                //   onReset();
                                // }
                                updated_active_filters.splice(
                                  activeFiterIndex,
                                  1
                                );
                                onApply(updated_active_filters)
                                setActiveFilters(updated_active_filters);
                              }}
                            />
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="py-0 px-2">
                          <div className="f-12 f-500 f-darkgrey my-2 ml-2">
                            Amount
                          </div>
                          <div className="p-1 bg-grey radius-4 highlight-input-with-grey-border d-flex align-items-center">
                            <div className="f-darkgrey f-500 flex-grow-1 mt-1px">£&nbsp;</div>
                            <input
                              type="number"
                              className="border-none bg-transparent w-100 flex-grow-1"
                              placeholder="Type"
                              value={activeFilter?.filterAmount ?? ''}
                              onChange={(e) => {
                                let updated_active_filters = [...activeFilters];
                                updated_active_filters[
                                  activeFiterIndex
                                ].filterAmount = e.target.value;
                                setActiveFilters(updated_active_filters);
                              }}
                              onBlur={(e) => {
                                 
                                if (e.target.value === '') {
                                 
                                  let updated_active_filters = [...activeFilters];
                                  updated_active_filters.splice(
                                    activeFiterIndex,
                                    1
                                  );
                                  onApply(updated_active_filters);
                                  setActiveFilters(updated_active_filters);
                                } else {
                                  let updated_active_filters = [...activeFilters];
                                  updated_active_filters[
                                    activeFiterIndex
                                  ].filterAmount = e.target.value;
                                  onApply(updated_active_filters);
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  document.getElementById(`${activeFilter?.filterType}_filterX`).click();
                                }
                              }}
                              autoFocus
                            />
                           <Button className="btn-blue-imp px-2 p-0">
                          <img
                              alt=""
                              width={11}
                              className=" pointer"
                              src="/images/settings-v2/yes-tick-task.svg"
                              onClick={() => {
                                document.getElementById(`${activeFilter?.filterType}_filterX`).click();
                              }}
                            />

                          </Button>
                          </div>
                          
                          <div className="p-2" />
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  )}
                  {activeFilter?.filterType === "subcategory" && (
                    <>
                      <Dropdown
                        className="subcategory-filter-dropdown m-1"
                        id={`${activeFilter?.filterType}_filterX`}
                        show={isSubcategoryDropdownOpen}
                        onToggle={(isOpen) => setIsSubcategoryDropdownOpen(isOpen)}
                      >
                        <Dropdown.Toggle className="p-0">
                          <div
                            className={`d-flex align-items-center px-2 py-1 rounded-full ${
                              selectedFilterSubcategories?.length > 0
                                ? "bg-blue"
                                : "bg-grey"
                            }`}
                          >
                            <div
                              className={`${
                                selectedFilterSubcategories?.length > 0
                                  ? "f-white"
                                  : "f-grey"
                              } f-500`}
                            >
                              Subcategory
                            </div>
                            {selectedFilterSubcategories?.length > 0 && (
                              <div className="f-500">
                                : {selectedFilterSubcategories[0].CategoryName}{" "}
                                {selectedFilterSubcategories?.length > 1
                                  ? `+${
                                      selectedFilterSubcategories?.length - 1
                                    }`
                                  : ""}
                              </div>
                            )}
                            <img
                              src="/images/attack-surface/cross-icon.svg"
                              alt=""
                              className={`ml-2 pointer   ${
                                selectedFilterSubcategories?.length > 0
                                  ? "white-filter"
                                  : ""
                              }`}
                              onClick={() => {
                                let updated_active_filters = [...activeFilters];
                                // if (updated_active_filters?.length === 1) {
                                //   onReset();
                                // }
                                updated_active_filters.splice(
                                  activeFiterIndex,
                                  1
                                );
                                onApply(updated_active_filters)
                                setActiveFilters(updated_active_filters);
                                setTimeout(() => {
                                  setIsSubcategoryDropdownOpen(false);
                                }, 100); 
                              }}                              
                            />
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="py-0 px-2">
                          <div className="f-12 f-500 f-darkgrey mt-2 ml-2">
                            Choose Subcategories
                          </div>
                          <div className="p-1 ">
                            {subCategoryList?.map(
                              (subCategory, subCategoryIndex) => {
                                let subCategoryExistIndex =
                                  activeFilter?.filterList?.findIndex(
                                    (item) =>
                                      item?.objId_Category ===
                                      subCategory?.objId_Category
                                  );
                                return (
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <Form.Group
                                        controlId={`card ${subCategoryIndex}`}
                                        className={`round-checkbox blue-checkbox checkboxstyle mb-3 ${"radio-shadow"}`}
                                      >
                                        <Form.Check
                                          type={"checkbox"}
                                          checked={
                                            subCategoryExistIndex > -1
                                              ? true
                                              : false
                                          }
                                          name={
                                            `check ` + subCategoryIndex + ""
                                          }
                                          label=""
                                          disabled={false}
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let updated_active_filters = [
                                              ...activeFilters,
                                            ];
                                            if (subCategoryExistIndex > -1) {
                                              updated_active_filters[
                                                activeFiterIndex
                                              ].filterList.splice(
                                                subCategoryExistIndex,
                                                1
                                              );
                                            } else {
                                              updated_active_filters[
                                                activeFiterIndex
                                              ].filterList = [
                                                ...updated_active_filters[
                                                  activeFiterIndex
                                                ].filterList,
                                                subCategory,
                                              ];
                                            }
                                            onApply(updated_active_filters)
                                            setActiveFilters(
                                              updated_active_filters
                                            );
                                          }}
                                        />
                                      </Form.Group>
                                    </div>

                                    <div className="f-500 pl-3 pt-2">
                                      {subCategory?.CategoryName}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div className="p-2" />
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  )}

                  {activeFilter?.filterType === "date" && (
                    <>
                      <Dropdown
                        className="date-filter-dropdown m-1 technologySpendModelDatePicker"
                        id={`${activeFilter?.filterType}_filterX`}
                        show={isDateDropdownOpen}
                        onToggle={(isOpen) => setIsDateDropdownOpen(isOpen)}
                      >
                        <Dropdown.Toggle className="p-0">
                          <div
                            className={`d-flex align-items-center px-2 py-1 rounded-full ${
                              dateRangeSelected ? "bg-blue" : "bg-grey" 
                            }`}
                          >
                            <div
                              className={`${
                                dateRangeSelected ? "f-white" : "f-grey"
                              } f-500`}
                            >
                              Date
                            </div>
                            {dateRangeSelected && (
                              <div className="f-500">
                                :{" "}
                                {selectedDateRange?.startDate
                                  ? moment(selectedDateRange?.startDate).format(
                                      "MMM D, YYYY"
                                    )
                                  : ""}
                                {selectedDateRange?.endDate
                                  ? ` - ${moment(
                                      selectedDateRange?.endDate
                                    ).format("MMM D, YYYY")}`
                                  : ""}
                              </div>
                            )}

                            <img
                              src="/images/attack-surface/cross-icon.svg"
                              alt=""
                              className={`ml-2 pointer   ${
                                dateRangeSelected ? "white-filter" : ""
                              }`}
                              onClick={() => {
                                let updated_active_filters = [...activeFilters];
                                if (updated_active_filters?.length === 1) {
                                  onReset();
                                }
                                updated_active_filters.splice(
                                  activeFiterIndex,
                                  1
                                  );
                                  onApply(updated_active_filters)
                                  setActiveFilters(updated_active_filters);
                                  setTimeout(() => {
                                    setIsDateDropdownOpen(false);
                                  }, 100); 
                              }}
                            />
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="p-0">
                          <DateRangePicker
                            editableDateInputs={true}
                            startDatePlaceholder="From"
                            endDatePlaceholder="To"
                            ranges={[activeFilter?.selectedDateRange]}
                            staticRanges={staticRanges}
                            onChange={(ranges) => {
                              
                              //
                              let updated_active_filters = [...activeFilters];
                              updated_active_filters[
                                activeFiterIndex
                              ].selectedDateRange = ranges.selection;
                              onApply(updated_active_filters)
                              setActiveFilters(updated_active_filters);
                              // document.querySelector('.technologySpendModelDatePicker').click()
                            }}
                          />
                          <div
                            className="f-12 pointer clearBlockHeight "
                            onClick={() => {
                              let updated_active_filters = [...activeFilters];
                              updated_active_filters[
                                activeFiterIndex
                              ].selectedDateRange = {
                                startDate: null, // new Date(),
                                endDate: null, // new Date(),
                                key: "selection",
                              };
                              onApply(updated_active_filters)
                              setActiveFilters(updated_active_filters);
                            }}
                            style={{
                              padding: "0.7rem",
                            }}
                          >
                            Clear
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  )}
                </div>
              );
            })}
          </div>

          <div className="d-flex align-items-center ml-2"
          style={{
            visibility:"hidden"
          }}
          >
            <div
              className="f-blue f-12 f-500 mr-2 pointer"
              disabled={applyingFilters}
              onClick={onApply}
            >
              {applyingFilters ? "Applying.." : "Apply"}
            </div>
            <div
              className="f-grey f-12 f-500 pointer"
              onClick={() => {
                setActiveFilters([]);
                onReset();
              }}
            >
              Reset
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TechnologySpendFilter;
