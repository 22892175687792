import { createSelector } from "reselect";

/**
 * Select the portion of the root reducer
 */
const userReducer = () => (state) => {
  return state.user;
};

export const getUser = () =>
  createSelector(userReducer(), (state) => {
    return state.userDetail;
  });
