import React, { useState, useEffect } from "react";
import {
  Form,
  Modal,
  Dropdown,
  Row,
  Col,
  Button,
  Spinner,
} from "react-bootstrap";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import "./billing-modals.scss";
import Loader from "../../Common/loader";
import CompanySettingsDropdown from "../companySettings/companysettingsdrodown/CompanySettingsDropdown";
const CountryList = [
  {
    country: "Argentina",
    code: "AR",
  },
  {
    country: "Australia",
    code: "AU",
  },
  {
    country: "Austria",
    code: "AT",
  },
  {
    country: "Belgium",
    code: "BE",
  },
  {
    country: "Bolivia",
    code: "BO",
  },
  {
    country: "Brazil",
    code: "BR",
  },
  {
    country: "Bulgaria",
    code: "BG",
  },
  {
    country: "Canada",
    code: "CA",
  },
  {
    country: "Chile",
    code: "CL",
  },
  {
    country: "Colombia",
    code: "CO",
  },
  {
    country: "Costa Rica",
    code: "CR",
  },
  {
    country: "Croatia",
    code: "HR",
  },
  {
    country: "Cyprus",
    code: "CY",
  },
  {
    country: "Czech Republic",
    code: "CZ",
  },
  {
    country: "Denmark",
    code: "DK",
  },
  {
    country: "Dominican Republic",
    code: "DO",
  },
  {
    country: "Egypt",
    code: "EG",
  },
  {
    country: "Estonia",
    code: "EE",
  },
  {
    country: "Finland",
    code: "FI",
  },
  {
    country: "France",
    code: "FR",
  },
  {
    country: "Germany",
    code: "DE",
  },
  {
    country: "Greece",
    code: "GR",
  },
  {
    country: "Hong Kong SAR China",
    code: "HK",
  },
  {
    country: "Hungary",
    code: "HU",
  },
  {
    country: "Iceland",
    code: "IS",
  },
  {
    country: "India",
    code: "IN",
  },
  {
    country: "Indonesia",
    code: "ID",
  },
  {
    country: "Ireland",
    code: "IE",
  },
  {
    country: "Israel",
    code: "IL",
  },
  {
    country: "Italy",
    code: "IT",
  },
  {
    country: "Japan",
    code: "JP",
  },
  {
    country: "Latvia",
    code: "LV",
  },
  {
    country: "Liechtenstein",
    code: "LI",
  },
  {
    country: "Lithuania",
    code: "LT",
  },
  {
    country: "Luxembourg",
    code: "LU",
  },
  {
    country: "Malta",
    code: "MT",
  },
  {
    country: "Mexico ",
    code: "MX",
  },
  {
    country: "Netherlands",
    code: "NL",
  },
  {
    country: "New Zealand",
    code: "NZ",
  },
  {
    country: "Norway",
    code: "NO",
  },
  {
    country: "Paraguay",
    code: "PY",
  },
  {
    country: "Peru",
    code: "PE",
  },
  {
    country: "Poland",
    code: "PL",
  },
  {
    country: "Portugal",
    code: "PT",
  },
  {
    country: "Romania",
    code: "RO",
  },
  {
    country: "Singapore",
    code: "SG",
  },
  {
    country: "Slovakia",
    code: "SK",
  },
  {
    country: "Slovenia",
    code: "SI",
  },
  {
    country: "Spain",
    code: "ES",
  },
  {
    country: "Sweden",
    code: "SE",
  },
  {
    country: "Switzerland",
    code: "CH",
  },
  {
    country: "Thailand",
    code: "TH",
  },
  {
    country: "Trinidad & Tobago",
    code: "TT",
  },
  {
    country: "United Arab Emirates",
    code: "AE",
  },
  {
    country: "United Kingdom",
    code: "GB",
  },
  {
    country: "United States",
    code: "US",
  },
  {
    country: "Uruguay",
    code: "UY",
  },
];
const ChangePaymentModal = ({
  show,
  hideModal,
  changeSubcriptionId,
  subId,
  subscriptionData,
}) => {
  const [billingintervaloption, setbillingintervaloption] = useState(
    "Monthly - £395/month"
  );
  const { authTokens } = useAuth();
  const [page, setPage] = useState("initial");
  const [cardNumber, setCardNumber] = useState("");
  const [monthYear, setMonthYear] = useState("");
  const [CVV, setCVV] = useState("");
  const [cardName, setCardName] = useState("");
  const [country, setCountry] = useState({
    code: "GB",
    country: "United Kingdom",
  });
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [registration, setRegistration] = useState("");
  const [vat, setVat] = useState("");
  const [responsePage, setResponsePage] = useState({});
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState({});
  const [cardType, setCardType] = useState("");
  const [changing, setChanging] = useState(false);
  const [formErrors, setFormErrors] = useState({
    cardNumber: "",
    cardMonth: "",
    cardCVV: "",
    cardName: "",
    companyName: "",
    companyAddress: "",
    companyRegistration: "",
    companyVat: "",
  });

  function clearModal() {
    setFormErrors({
      cardNumber: "",
      cardMonth: "",
      cardCVV: "",
      cardName: "",
      companyName: "",
      companyAddress: "",
      companyRegistration: "",
      companyVat: "",
    });
    setVat("");
    setRegistration("");
    setAddress("");
    setCompanyName("");

    setCardName("");
    setMonthYear("");
    setCVV("");
    setCardNumber("");
  }
  useEffect(() => {
    clearModal();
    setCardType("");
  }, [show]);

  const maskDate = (value) => {
    let v = value.replace(/(\w)\//g, function (str, match) {
      return match[0];
    });
    if (v.length >= 3) {
      return `${v.slice(0, 2)}/${v.slice(2)}`;
    }
    return value;
  };
  useEffect(() => {
    if (show) {
      setLoading(true);
      getAPIData("200", authTokens, {
        optionEnum1: changeSubcriptionId,
      }).then((response) => {
        setLoading(false);
        if (response?.mr?.Success) {
          setModalData(response);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      });
    }
  }, [show]);
  const cardDetails = {
    cardname: cardName,
    cardnumber: cardNumber,
    monthyear: monthYear,
    cvc: CVV,
    cname: companyName,
    caddress: address,
    ccountry: country.code,
    creg: registration,
    cvat: vat,
  };

  async function AddNewCardAndChange() {
    if (cardNumber.length < 16) {
      setFormErrors({
        ...formErrors,
        cardNumber: "*Please enter valid card number",
      });
      return;
    }

    if (monthYear.length < 5) {
      setFormErrors({
        ...formErrors,
        cardMonth: "*Please enter valid card expiry",
      });
      return;
    }

    if (monthYear.length > 0) {
      const currentMonth = Number(new Date().getMonth() + 1);
      const last2 = new Date().getFullYear().toString().slice(-2);
      const currentYear = Number(last2);
      const inputMonth = Number(monthYear.split("/")[0]);
      const inputYear = Number(monthYear.split("/")[1]);

      if (
        (inputMonth < currentMonth && inputYear <= currentYear) ||
        inputYear < currentYear
      ) {
        setFormErrors({
          ...formErrors,
          cardMonth: "*Please enter valid card expiry",
        });
        return;
      }
    }

    if (CVV.length < 3) {
      setFormErrors({ ...formErrors, cardCVV: "*Please enter valid CVC" });
      return;
    }

    if (cardName.length == "") {
      setFormErrors({
        ...formErrors,
        cardName: "*Please enter card holder name",
      });

      return;
    }

    if (companyName.length == 0) {
      setFormErrors({
        ...formErrors,
        companyName: "*Please enter company name",
      });
      return;
    }
    if (address.length == 0) {
      setFormErrors({
        ...formErrors,
        companyAddress: "*Please enter company address",
      });
      return;
    }
    // if (country.length == 0) {
    //   CallToast("Please enter country name", false, false, "v2style");
    //   return;
    // }
    if (registration.length == 0) {
      setFormErrors({
        ...formErrors,
        companyRegistration: "*Please enter registration number",
      });
      return;
    }

    if (vat.length == 0) {
      setFormErrors({ ...formErrors, companyVat: "*Please enter vat number" });
      return;
    }
    setChanging(true);
    const month = cardDetails.monthyear.split("/");
    const data = {
      CardName: cardDetails.cardname,
      CardNumber: cardDetails.cardnumber,
      ExpMonth: month[0],
      ExpYear: month[1],
      Cvc: cardDetails.cvc,
      CompanyName: cardDetails.cname,
      CompanyAddress: cardDetails.caddress,
      Country: cardDetails.ccountry,
      CompanyRegistration: cardDetails.creg,
      VAT: cardDetails.cvat,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/ChangeBillingInfo?SubscriptionId=${subId}`,
      // `https://hg-dev-ui-ac-api.azurewebsites.net/CC_API/ChangeBillingInfo?SubscriptionId=${subId}`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authTokens}`,
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setChanging(false);
        if (response.Success) {
          CallToast(
            "Payment method changed successfully",
            false,
            false,
            "v2style"
          );
          hideModal();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      });
    // setIsResponse(true);
    // setBillingResponse(response);
    // setIsLoading(false);
  }

  async function changeCard(paymentmethodId) {
    // setIsLoading(true);
    setChanging(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/ChangeBillingInfo?SubscriptionId=${subId}&PaymentMethodId=${paymentmethodId}`,
      // `https://hg-dev-ui-ac-api.azurewebsites.net/CC_API/ChangeBillingInfo?SubscriptionId=${subId}&PaymentMethodId=${paymentmethodId}`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authTokens}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setChanging(false);
        if (response.AccessKey !== undefined) {
          localStorage.setItem(
            "user_MessageObj",
            JSON.stringify(response.AccessKey)
          );
        }

        if (response.Success) {
          CallToast(
            "Payment method changed successfully",
            false,
            false,
            "v2style"
          );
          hideModal();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      });

    // setIsResponse(true);
    // setIsLoading(false);
    // setBillingResponse(response);
  }

  return (
    <Modal
      // show={true}
      show={show}
      dialogClassName="billing-payment-modal"
      className=""
      aria-labelledby="billing-payment-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Header className="d-flex justify-content-between align-items-center ">
        <div className="d-flex align-items-center">
          <img src="/images/settings-v2/logo.svg" className="mr-3" />
          <div className="header-name">Change Payment method</div>
        </div>

        <img
          src="/images/icons/close.svg"
          alt=""
          className="close-icon pointer"
          onClick={hideModal}
        />
      </Modal.Header>
      <Modal.Body>
        {/* Payment method New/Edit */}

        {/* {page == "initial" && (
          <>
            <div className="title mt-3 mb-1">Payment Method</div>
            <div className="payment-method d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <img
                  src="/images/settings-v2/master-card.svg"
                  className="mr-2"
                />
                <div>
                  <p className="title m-0 ">Master Card *4392</p>
                  <p className="subtitle m-0">Jane Cooper, Cyber Care lt...</p>
                </div>
              </div>
              <img
                src="/images/complianceManager/downArrow.svg"
                onClick={() => setPage("all-list")}
                className="pointer"
              />
            </div>
          </>
        )} */}

        {loading && (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              position: "absolute",
              top: "40%",
              left: 0,
              right: 0,
              margin: "auto",
            }}
          >
            <Loader />
          </div>
        )}
        {!loading && (
          <div>
            {page == "initial" && (
              <>
                <div className="d-flex justify-content-between align-items-start amount-details">
                  <div className="d-flex align-items-center">
                    {subscriptionData?.Icon && (
                      <img
                        alt=""
                        src={`/images/ProServices/${subscriptionData?.Icon}`}
                        className="mr-2 "
                        style={{
                          width: "30px",
                          objectFit: "contain",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                    <div>
                      <p className="title mb-1">{subscriptionData?.Name} </p>
                      <p className="subtitle">{subscriptionData?.Cost}</p>
                    </div>
                  </div>
                  <div className="title d-flex justify-content-center align-items-start">
                    {subscriptionData?.Cost?.split("/")[0]}
                  </div>
                </div>

                <hr />
                <div className="title mt-3 mb-1">Payment Method</div>
                <div className="payment-method-list">
                  <Row>
                    {modalData?.PaymentMethod_List?.map(
                      (paymentmethod, Index) => {
                        return (
                          <>
                            <Col xs={12}>
                              <div className=" d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                  <div className="d-flex justify-content-center align-items-center checkbox-wrapper">
                                    <Form.Group
                                      controlId={"card" + Index}
                                      className={`round-checkbox blue-checkbox `}
                                    >
                                      <Form.Check
                                        type={"radio"}
                                        checked={
                                          paymentmethod?.StripeId ==
                                          modalData?.SelectedPaymentId
                                        }
                                        name={"check"}
                                        label=""
                                        onChange={() => {
                                          let updated_data = { ...modalData };
                                          updated_data.SelectedPaymentId =
                                            paymentmethod?.StripeId;
                                          setModalData(updated_data);
                                          setCardType("old");
                                        }}
                                      />
                                    </Form.Group>
                                  </div>
                                  <div>
                                    <p className="title m-0 ">
                                      {paymentmethod?.CardType}&nbsp;
                                      {paymentmethod?.CardNumber}
                                    </p>
                                    <p className="subtitle m-0">
                                      {modalData?.UserDetails?.FullName} ,
                                      {modalData?.UserDetails?.OrgName}
                                    </p>
                                  </div>
                                </div>
                                <div className="option-dropdown-method">
                                  <Dropdown className="option-dropdown d-none">
                                    <Dropdown.Toggle>
                                      <img
                                        src="/images/horizontal-dots.svg"
                                        className="pointer d-none"
                                      />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <div>
                                        <Row>
                                          <Col xs={12}>
                                            <div
                                              className="notification-btn"
                                              onClick={() => {
                                                // setPage("add-payment")
                                              }}
                                            >
                                              Edit Payment Method
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            </Col>
                            {true && <hr />}
                          </>
                        );
                      }
                    )}

                    <Col xs={12}>
                      <div className="d-flex align-items-center">
                        <div className="d-flex  align-items-center checkbox-wrapper">
                          <Form.Group
                            controlId={"check2"}
                            className={`round-checkbox blue-checkbox `}
                          >
                            <Form.Check
                              type={"radio"}
                              // checked={1}
                              name={"check"}
                              label=""
                              checked={cardType == "new"}
                              onChange={() => {
                                // setPage("add-payment");
                                let updated_data = { ...modalData };
                                updated_data.SelectedPaymentId = "dvfsd";
                                setModalData(updated_data);
                                setCardType("new");
                              }}
                            />
                          </Form.Group>
                        </div>
                        <div className="title">Add payment method</div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            )}

            {cardType == "new" && (
              <>
                <hr />
                <div className="title">Payment Information</div>

                <div className="card-details d-flex align-items-center w-100">
                  <img src="/images/settings-v2/card.svg" />
                  <input
                    className="card-number "
                    type="number"
                    placeholder="Card Number"
                    value={cardNumber}
                    onChange={(e) => {
                      if (e.target.value.length == 17) return false;
                      setFormErrors({
                        ...formErrors,
                        cardNumber: "",
                      });
                      setCardNumber(e.target.value);
                    }}
                  />
                  <input
                    className="card-month "
                    value={monthYear}
                    onChange={(e) => {
                      if (e.target.value.length == 6) return false;
                      e.target.value = e.target.value
                        .replace(
                          /[^0-9]/g,
                          "" // To allow only numbers
                        )
                        .replace(
                          /^([2-9])$/g,
                          "0$1" // To handle 3 > 03
                        )
                        .replace(
                          /^(1{1})([3-9]{1})$/g,
                          "0$1/$2" // 13 > 01/3
                        )
                        .replace(
                          /^0{1,}/g,
                          "0" // To handle 00 > 0
                        )
                        .replace(
                          /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
                          "$1/$2" // To handle 113 > 11/3
                        );
                      setFormErrors({
                        ...formErrors,
                        cardMonth: "",
                      });
                      setMonthYear(maskDate(e.target.value));
                    }}
                    type="text"
                    placeholder="MM/YY"
                    autoComplete="new-password"
                  />
                  <input
                    className="card-cvv "
                    value={CVV}
                    onChange={(e) => {
                      if (e.target.value.length == 4) return false;
                      setFormErrors({
                        ...formErrors,
                        cardCVV: "",
                      });
                      setCVV(e.target.value);
                    }}
                    type="password"
                    placeholder="CVV"
                    autoComplete="new-password"
                  />
                </div>
                <div className="f-12 f-danger">{formErrors.cardNumber} </div>
                <div className="f-12 f-danger">{formErrors.cardMonth} </div>
                <div className="f-12 f-danger">{formErrors.cardCVV} </div>
                <div className="card-details my-1">
                  <input
                    type="text"
                    className="w-100"
                    placeholder="Name on card"
                    value={cardName}
                    onChange={(e) => {
                      setFormErrors({
                        ...formErrors,
                        cardName: "",
                      });
                      setCardName(e.target.value);
                    }}
                  />
                </div>
                <div className="f-12 f-danger">{formErrors.cardName} </div>
                <div className="title mt-3">Billing Information</div>
                <div className="card-details my-1">
                  <input
                    type="text"
                    className="w-100"
                    placeholder="Company name"
                    value={companyName}
                    onChange={(e) => {
                      setFormErrors({
                        ...formErrors,
                        companyName: "",
                      });
                      setCompanyName(e.target.value);
                    }}
                  />
                </div>
                <div className="f-12 f-danger">{formErrors.companyName} </div>
                {/* <Form.Group>
                  <InputGroup className="mt-10px grey-select customerStyle border-4">
                    <Form.Control
                      style={{ height: "40px" }}
                      as="select"
                      className={"pt-0 pb-0 black-clr"}
                      defaultValue={"GB"}
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                    >
                      {modalData?.CountryList.map((country) => {
                        return (
                          <option value={country?.code}>
                            {country?.country}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </InputGroup>
                </Form.Group> */}
                <div className="country-list-dropdown">
                  <CompanySettingsDropdown
                    fieldId={"code"}
                    fieldValue={"country"}
                    selected={country}
                    placeholder="Choose..."
                    //  showid={true}
                    onClick={(id, val) => {
                      setCountry({ code: id, country: val });
                    }}
                    data={CountryList}
                  />
                </div>
                <div className="card-details my-1">
                  <input
                    type="text"
                    className="w-100"
                    placeholder="Company address"
                    value={address}
                    onChange={(e) => {
                      setFormErrors({
                        ...formErrors,
                        companyAddress: "",
                      });
                      setAddress(e.target.value);
                    }}
                  />
                </div>
                <div className="f-12 f-danger">
                  {formErrors.companyAddress}{" "}
                </div>
                <div className="card-details my-1">
                  <input
                    type="text"
                    className="w-100"
                    placeholder="Company registration"
                    value={registration}
                    onChange={(e) => {
                      setFormErrors({
                        ...formErrors,
                        companyRegistration: "",
                      });

                      setRegistration(e.target.value);
                    }}
                  />
                </div>
                <div className="f-12 f-danger">
                  {formErrors.companyRegistration}{" "}
                </div>
                <div className="card-details my-1">
                  <input
                    type="text"
                    className="w-100"
                    placeholder="VAT"
                    value={vat}
                    onChange={(e) => {
                      setFormErrors({
                        ...formErrors,
                        companyVat: "",
                      });
                      setVat(e.target.value);
                    }}
                  />
                </div>
                <div className="f-12 f-danger">{formErrors.companyVat} </div>
              </>
            )}
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          disabled={changing || loading}
          className="payment-btn"
          onClick={() => {
            if (cardType == "new") {
              AddNewCardAndChange();
            } else if (cardType == "old") {
              changeCard(modalData?.SelectedPaymentId);
            } else {
              CallToast("Please select a new card", false, false, "v2style");
            }
          }}
        >
          {changing ? (
            <div>
              <Spinner
                size="sm"
                animation="border"
                role="status"
                className="mr-2"
                variant="light"
              />
              &nbsp;Updating...
            </div>
          ) : (
            <div>Update</div>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePaymentModal;
