import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import "./modals-styles.scss"
const SubscriptionSuspendModal = ({show,hideModal}) => {
  return (
    <Modal
    show={show}
    dialogClassName=""
    className="modal-height-auto modal-radius-8 "
    aria-labelledby=" "
    centered
    scrollable
    onHide={hideModal}
  >
<Modal.Body className='p-3'>
    <div className='f-16 f-500 mb-3'>Are you sure you want to suspend this account?</div>
    <div className='f-14 f-500 mb-3'>Users will be unable to login to their account</div>
    <div className='f-14 f-darkgrey mb-2'>Reason</div>
    <textarea
    rows={8}
    className='w-100 p-2 border-grey radius-4'
    placeholder='Reason'
    
    />
</Modal.Body>

<Modal.Footer className='p-2 d-flex align-items-center justify-content-end border-none'>
    <Button className='hg-cancel-btn mr-2'>Cancel</Button>
    <Button className='hg-red-btn'>Suspend</Button>

</Modal.Footer>

  </Modal>
  )
}

export default SubscriptionSuspendModal