import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Row,
  Col,
  Nav,
  Tab,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { getAPIData, postData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";

import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { GreyLoader } from "../../Theme/APILoaders";

import moment from "moment";

import "./profilingtab.scss";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import ProfilingFilterDropdown from "./ProfilingFilterDropdown";
import { DeleteModal } from "../../DeleteModal/DeleteModal";
import { viewOnlyAccess } from "../../App/App";

const ProfilingTab = forwardRef(
  (
    {
      setPage,
      profilingTabSelectedTab = "Received",
      setProfilingTabSelectedTab,
      allowDrillDownQuestionnaire,
      setAllowDrillDownQuestionnaire,
      showUploadFileModal,
    },
    ref
  ) => {
    const { authTokens } = useAuth();
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const defaultSelectedTab = searchParams.get("selectedTab");

    const [pageLoading, setPageLoading] = useState(false);
    const [dataForTable, setDataForTable] = useState([]);
    const [riskRatings, setRiskratings] = useState([]);

    const [questionnaireSearchString, setQuestionnaireSearchString] =
      useState("");
    const [isSent, setIsSent] = useState(
      profilingTabSelectedTab === "Received" ? false : true
    );

    const [QuestionnaireNumber, setQuestionnaireNumber] = useState(0);

    const [receivedRedDot, setReceivedRedDot] = useState(false);
    const [columnSortingLoading, setColumnSortingLoading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteSentQuestionnaireId, setDeleteSentQuestionnaireId] =
      useState("");
    const [fetchingNewData, setFetchingNewData] = useState(false);

    // for sorting Questionnaire
    // Title
    // Supplier
    // Due_Date
    // LastUpdated
    const [sortItem, setSortItem] = useState({
      type: "Title",
      order: 1,
    });

    const [filterParameters, setFilterParameters] = useState([
      { TaskFilter: "created", FilterText_List: [] },
      { TaskFilter: "Title", FilterText_List: [] },
      { TaskFilter: "Re-Profiling Due", FilterText_List: [] },
      { TaskFilter: "Last Profiled", FilterText_List: [] },
      { TaskFilter: "Supplier", FilterText_List: [] },
      { TaskFilter: "Rating", FilterText_List: [] },
    ]);

    // useEffect(() => {
    //   if (defaultSelectedTab == "profiling") {
    //     console.log('check')
    //     setPageLoading(true);
    //     getPageData();
    //   }
    // }, [defaultSelectedTab]);

    // useEffect(() => {
    //   if (defaultSelectedTab == "profiling") {
    //     console.log('check')
    //     if (!showUploadFileModal) {
    //       setPageLoading(true);
    //       getPageData();
    //     }
    //   }
    // }, [showUploadFileModal, defaultSelectedTab]);

    async function getMoreData(e) {
      try {
        if (
          e.target.clientHeight + e.target.scrollTop + 20 >=
          e.target.scrollHeight
        ) {
          console.log("QuestionnaireNumber", QuestionnaireNumber);
          if (QuestionnaireNumber === 0) return;

          let element = document.getElementById("mainBody");
          element.removeEventListener("scroll", getMoreData);

          setFetchingNewData(true);

          getPageData().then(() => {
            setFetchingNewData(false);
          });

          console.log("You've reached the end of the screen!");
        }
      } catch (error) {
        console.log("errror aa gya", error);
      }
    }

    useEffect(() => {
      var element = document.getElementById("mainBody");
      element.removeEventListener("scroll", getMoreData);
      element.addEventListener("scroll", getMoreData);

      return () => {
        element.removeEventListener("scroll", getMoreData);
      };
    }, [dataForTable, setFetchingNewData]);

    useEffect(() => {
      if (defaultSelectedTab == "profiling") {
        if (!showUploadFileModal) {
          
          setPageLoading(true);
          postData(authTokens, "GetSCMQuestionnaireList", {
            QuestionnaireNumber: QuestionnaireNumber,
            IsArchived: false,
            SCMQuestionnaireFilter_List: JSON.stringify(filterParameters),
            SCMQuestionnaireSort: sortItem.type,
            SCMQuestionnaireSortOrder: sortItem.order,
            // accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
            IsSent: isSent,
            IsSCMProfiling: true,
          })
            .then((response) => {
              setColumnSortingLoading(false);
              setReceivedRedDot(response?.IsRecievedRedDot);
              setPageLoading(false);
              if (response?.mr?.Success) {
                setQuestionnaireNumber(response?.QuestionnaireNumber);
                setRiskratings(response?.RiskLevel_List);
                if (
                  response?.QuestionnaireNumber === 0 ||
                  QuestionnaireNumber === 0
                ) {
                  setDataForTable(response?.SCMQuestionnaires_List);
                } else {
                  let new_output = response.SCMQuestionnaires_List;

                  let old_client_data = [...dataForTable];
                  old_client_data.push(...new_output);

                  setDataForTable(old_client_data);
                }
              } else {
                CallToast("Something went wrong", false, false, "v2style");
              }
            })
            .catch((err) => {
              setColumnSortingLoading(false);
              setPageLoading(false);
              console.log(err);
              CallToast("Something went wrong", false, false, "v2style");
            });
        }
      }
    }, [isSent, sortItem, filterParameters, defaultSelectedTab]);

    // Function  to get Archived Task
    useImperativeHandle(ref, () => ({}));
    async function getPageData() {
      await postData(authTokens, "GetSCMQuestionnaireList", {
        QuestionnaireNumber: QuestionnaireNumber,
        IsArchived: false,
        SCMQuestionnaireFilter_List: JSON.stringify(filterParameters),
        SCMQuestionnaireSort: sortItem.type,
        SCMQuestionnaireSortOrder: sortItem.order,
        // accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        IsSent: isSent,
        IsSCMProfiling: true,
      })
        .then((response) => {
          setColumnSortingLoading(false);
          setReceivedRedDot(response?.IsRecievedRedDot);
          setRiskratings(response?.RiskLevel_List);
          setQuestionnaireNumber(response?.QuestionnaireNumber);
          // setQuestionsData(response);
          // setScreenLoaded(true);
           
          if (response?.mr?.Success) {
            if (response?.QuestionnaireNumber === 0) {
              setDataForTable(response?.SCMQuestionnaires_List);
            } else {
              let new_output = response.SCMQuestionnaires_List;

              let old_client_data = [...dataForTable];
              old_client_data.push(...new_output);

              setDataForTable(old_client_data);
            }

            setAllowDrillDownQuestionnaire(
              response?.mr?.AccessObjList?.filter(
                (item) =>
                  item?.Question === "Supply Chain Monitoring Visibility"
              )[0]
            );
            setPageLoading(false);
          } else {
            // CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          setColumnSortingLoading(false);
          setPageLoading(false);
          console.log(err);
          // CallToast("Something went wrong", false, false, "v2style");
        });
    }
    async function sendReminder(questionnaireId, supplierId) {
      getAPIData(820, authTokens, {
        optionEnum1: questionnaireId,
        optionEnum2: supplierId,
      })
        .then((response) => {
          if (response?.Success) {
            CallToast("Reminder sent successfully", false, false, "v2style");
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    }

    async function ArchiveOrDeleteQuestions(objectId) {
      await getAPIData(`${772}`, authTokens, {
        optionEnum1: objectId,
        optionEnum2: 0,
      })
        .then((response) => {
          if (response?.Success) {
            CallToast("Archived Successfully", false, false, "v2style");
            getPageData();
          }
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    }

    function SortQuestionaireColumn(type) {
      setColumnSortingLoading(true);
      setSortItem((prevSort) => ({
        type: type,
        order: Number(!prevSort.order),
      }));
    }

    return (
      <div className="questionnaireTab-wrapper pt-4">
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey={profilingTabSelectedTab}
        >
          <Row className="m-0">
            <Col
              sm={12}
              className="ml-3 mb-2 d-flex justify-content-between align-items-center"
            >
              <div className="f-500 ml-3 d-flex align-items-center w-100 justify-content-between">
                <div>
                  <Nav
                    variant="pills"
                    className={`flex-row  ${
                      allowDrillDownQuestionnaire?.Option ? "" : " "
                    }`}
                    onSelect={(e) => {
                      setProfilingTabSelectedTab(e);

                      if (
                        e === "Received" &&
                        profilingTabSelectedTab !== "Received"
                      ) {
                        setIsSent(false);
                        // setPageLoading(true);
                      }
                      if (e === "Sent" && profilingTabSelectedTab !== "Sent") {
                        // setPageLoading(true);
                        setIsSent(true);
                      }
                    }}
                  >
                    <Nav.Item
                      className={
                        receivedRedDot
                          ? "position-relative received-message-dot"
                          : ""
                      }
                    >
                      <Nav.Link eventKey="Received">Received</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Sent">Sent</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="d-flex align-items-center mr-4">
                  <div
                    className="mx-2 d-flex align-items-center"
                    style={{
                      pointerEvents: `${
                        allowDrillDownQuestionnaire?.Option ? "all" : "none"
                      }`,
                    }}
                  >
                    <img
                      alt=""
                      src="/images/attack-surface/search-icon.svg"
                      className="pointer"
                      onClick={() => {}}
                    />
                    <input
                      type="text"
                      placeholder="Search.."
                      value={questionnaireSearchString ?? ""}
                      onChange={(e) => {
                        setQuestionnaireSearchString(e.target.value);
                        let updated_data = [...filterParameters];
                        updated_data[1].FilterText_List[0] = e.target.value;
                        setFilterParameters(updated_data);
                      }}
                      onBlur={() => {}}
                      className={`border-none bg-tranparent pl-2`}
                    />
                  </div>

                  <div
                    className="f-darkgrey mr-2 questionnaire-filter"
                    onClick={(e) => {}}
                  >
                    {/* {questionsData?.TotalCount > 0 && !pageLoading && ( */}
                    {/* Filter */}
                    <ProfilingFilterDropdown
                      setFilterParameters={setFilterParameters}
                      allowDrillDownQuestionnaire={allowDrillDownQuestionnaire}
                      riskRatings={riskRatings}
                    >
                      Filter
                    </ProfilingFilterDropdown>
                    {/* // )} */}
                  </div>
                  <div>
                    {/* <img alt="" src="/images/attack-surface/search-icon.svg" /> */}
                  </div>
                </div>
              </div>
              {/* Right side items at top */}
              <div className="d-flex align-items-center mr-3"></div>
            </Col>
            <Col sm={12}>
              <Tab.Content>
                {/* Received */}
                <Tab.Pane eventKey="Received" className="pl-4 pr-3 pb-4">
                  {/*  */}
                  {pageLoading ? (
                    <QuestionnairePageLoader />
                  ) : (
                    <>
                      {dataForTable?.length == 0 ? (
                        <NoProfilingStateInReceived />
                      ) : (
                        <div className="bg-grey radius-8 border-parent profiler-table">
                          {false && <QuestionnairePageLoader />}
                          <div className="profiler-table-grid p-2 border-item">
                            {/* <div className="pl-4">Title</div> */}
                            <div
                              className="ml-3"
                              onClick={() => {
                                SortQuestionaireColumn("Title");
                              }}
                            >
                              Title
                              {sortItem?.type === "Title" && (
                                <>
                                  {columnSortingLoading ? (
                                    <GreyLoader width={11} />
                                  ) : (
                                    <>
                                      {sortItem?.order ? (
                                        <img
                                          alt=""
                                          src="/images/actioncenter/arrowdown.svg"
                                          className="ml-2"
                                        />
                                      ) : (
                                        <img
                                          alt=""
                                          src="/images/actioncenter/arrowup.svg"
                                          className="ml-2"
                                          style={{ width: "10px" }}
                                        />
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                            <div
                              onClick={() => {
                                SortQuestionaireColumn("Supplier");
                              }}
                            >
                              Supplier
                              {sortItem?.type === "Supplier" && (
                                <>
                                  {columnSortingLoading ? (
                                    <GreyLoader width={11} />
                                  ) : (
                                    <>
                                      {sortItem?.order ? (
                                        <img
                                          alt=""
                                          src="/images/actioncenter/arrowdown.svg"
                                          className="ml-2"
                                        />
                                      ) : (
                                        <img
                                          alt=""
                                          src="/images/actioncenter/arrowup.svg"
                                          className="ml-2"
                                          style={{ width: "10px" }}
                                        />
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                            <div
                              onClick={() => {
                                SortQuestionaireColumn("Rating");
                              }}
                            >
                              Rating
                              {sortItem?.type === "Rating" && (
                                <>
                                  {columnSortingLoading ? (
                                    <GreyLoader width={11} />
                                  ) : (
                                    <>
                                      {sortItem?.order ? (
                                        <img
                                          alt=""
                                          src="/images/actioncenter/arrowdown.svg"
                                          className="ml-2"
                                        />
                                      ) : (
                                        <img
                                          alt=""
                                          src="/images/actioncenter/arrowup.svg"
                                          className="ml-2"
                                          style={{ width: "10px" }}
                                        />
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                            <div
                              onClick={() => {
                                SortQuestionaireColumn("Last Profiled");
                              }}
                            >
                              Last Profiled
                              {sortItem?.type === "Last Profiled" && (
                                <>
                                  {columnSortingLoading ? (
                                    <GreyLoader width={11} />
                                  ) : (
                                    <>
                                      {sortItem?.order ? (
                                        <img
                                          alt=""
                                          src="/images/actioncenter/arrowdown.svg"
                                          className="ml-2"
                                        />
                                      ) : (
                                        <img
                                          alt=""
                                          src="/images/actioncenter/arrowup.svg"
                                          className="ml-2"
                                          style={{ width: "10px" }}
                                        />
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                            <div
                              onClick={() => {
                                SortQuestionaireColumn("Re-Profiling Due");
                              }}
                            >
                              Re-Profiling Due
                              {sortItem?.type === "Re-Profiling Due" && (
                                <>
                                  {columnSortingLoading ? (
                                    <GreyLoader width={11} />
                                  ) : (
                                    <>
                                      {sortItem?.order ? (
                                        <img
                                          alt=""
                                          src="/images/actioncenter/arrowdown.svg"
                                          className="ml-2"
                                        />
                                      ) : (
                                        <img
                                          alt=""
                                          src="/images/actioncenter/arrowup.svg"
                                          className="ml-2"
                                          style={{ width: "10px" }}
                                        />
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                            <div></div>
                          </div>

                          {dataForTable
                            ?.filter((item) =>
                              item?.Title?.toLowerCase()?.includes(
                                questionnaireSearchString?.toLowerCase()
                              )
                            )
                            ?.map((question, questionIndex) => {
                              return (
                                <div
                                  className="profiler-table-grid p-2 border-item bg-hover-dark-grey pointer hover-on-show-parent"
                                  onClick={(e) => {
                                    history.push(
                                      "/supplier-profiler/profiling",
                                      {
                                        backUrl: `${
                                          window.location.pathname
                                        }?${new URLSearchParams(
                                          window.location.search
                                        ).toString()}`,
                                        isContributorView: false,
                                        isSent: isSent,
                                        isTemplate: false,
                                        questionId: question?.ObjectId,
                                      }
                                    );
                                  }}
                                >
                                  {/* Title */}
                                  <div className="d-flex align-items-center p-2">
                                    <div className="supplier-popover-parent">
                                      <img
                                        alt=""
                                        src={
                                          "/images/supply-chain/question-list-icon.svg"
                                        }
                                        className="mr-2"
                                      />
                                    </div>
                                    <div>
                                      <p className="m-0 f-14 f-500">
                                        {question?.Title}
                                      </p>
                                      <div className="d-flex align-items-center">
                                        <div
                                          className="flex align-items-center"
                                          style={{ width: "182px" }}
                                        >
                                          <progress
                                            min="0"
                                            value={
                                              question?.QuestionnairesPercentage
                                            }
                                            max="100"
                                          />
                                        </div>
                                        <div className="f-500 f-darkgrey ml-2">
                                          {question?.QuestionnairesPercentage}%
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Supplier */}
                                  <div className="f-grey d-flex align-items-center f-black f-500">
                                    <img
                                      alt=""
                                      src={
                                        question?.Supplier?.SupplierLogo ??
                                        "/images/settings-v2/highground-dp.svg"
                                      }
                                      width={24}
                                      height={24}
                                      className="mr-2 rounded-full"
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src =
                                          "/images/settings-v2/highground-dp.svg";
                                      }}
                                    />
                                    {question?.Supplier?.SupplierName}{" "}
                                  </div>

                                  {/* Ratings */}

                                  <div className="d-flex align-items-center">
                                    <div
                                      className={`d-inline-flex align-items-center justify-content-center rounded-full w-fit-content f-12 f-500 }`}
                                      style={{
                                        background: "#EBEDF1",
                                        color: "#616778",
                                        padding: "0px 6px",
                                      }}
                                    >
                                      <div
                                        className="mr-1"
                                        style={{
                                          background: question?.RatingColour,
                                          width: "9px",
                                          height: "9px",
                                          borderRadius: "50%",
                                        }}
                                      />{" "}
                                      {question?.Rating}
                                      {/* High rated */}
                                    </div>
                                  </div>

                                  {/* Last profiler */}
                                  <div className="f-grey d-flex align-items-center f-black f-500">
                                    {moment(question?.LastUpdated).format(
                                      "DD/MM/YYYY HH:mm"
                                    )}
                                  </div>

                                  {/* Re Profiling Due */}
                                  <div className="f-grey d-flex align-items-center f-black f-500">
                                    {moment(question?.Duedate).format(
                                      "DD/MM/YYYY HH:mm"
                                    )}
                                  </div>
                                  <div className="d-flex align-items-center justify-content-center">
                                    <div
                                      className={`menu-dropdown w-fit-content  hover-on-show-child`}
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <Dropdown className="device-dropdown">
                                        <Dropdown.Toggle>
                                          <img
                                            alt=""
                                            src="/images/horizontal-dots.svg"
                                          />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <div>
                                            <Row>
                                              {/* <Col
                                                xs={12}
                                                className=" pointer "
                                              >
                                                <div
                                                  className="drop-item"
                                                  onClick={(e) => {
                                                    sendReminder(
                                                      question?.ObjectId,
                                                      question?.Supplier
                                                        ?.objId_Customer
                                                    );
                                                    e.target
                                                      .closest(
                                                        ".device-dropdown"
                                                      )
                                                      .click();
                                                  }}
                                                >
                                                  Send reminder
                                                </div>
                                              </Col> */}
                                              <Col
                                                xs={12}
                                                className="  pointer "
                                              >
                                                <div
                                                  className="drop-item"
                                                  onClick={(e) => {
                                                    history.push(
                                                      "/supplier-profiler/profiling",
                                                      {
                                                        backUrl: `${
                                                          window.location
                                                            .pathname
                                                        }?${new URLSearchParams(
                                                          window.location.search
                                                        ).toString()}`,
                                                        isContributorView: false,
                                                        isSent: isSent,
                                                        isTemplate: false,
                                                        questionId:
                                                          question?.ObjectId,
                                                      }
                                                    );
                                                    e.target
                                                      .closest(
                                                        ".device-dropdown"
                                                      )
                                                      .click();
                                                    // setShowQuestionnaireModal(true);
                                                  }}
                                                >
                                                  View {/* Edit */}
                                                </div>
                                              </Col>

                                              <Col
                                                xs={12}
                                                className=" pointer "
                                              >
                                                <div
                                                  className="drop-item"
                                                  onClick={(e) => {
                                                    setDeleteSentQuestionnaireId(
                                                      question?.ObjectId
                                                    );
                                                    setShowDeleteModal(true);

                                                    // ArchiveOrDeleteQuestions(
                                                    //   question?.ObjectId
                                                    // );
                                                    e.target
                                                      .closest(
                                                        ".device-dropdown"
                                                      )
                                                      .click();
                                                  }}
                                                >
                                                  Delete
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          {fetchingNewData && (
                            <div className="d-flex align-items-center f-darkgrey">
                              <GreyLoader width={12} />
                              Loading...
                            </div>
                          )}
                        </div>
                      )}{" "}
                    </>
                  )}
                </Tab.Pane>

                {/* Sent */}

                <Tab.Pane eventKey="Sent" className="pl-4 pr-3 pb-4">
                  {pageLoading ? (
                    <QuestionnairePageLoader />
                  ) : (
                    <>
                      {dataForTable?.length == 0 ? (
                        <NoProfilingStateInSent />
                      ) : (
                        <div className="bg-grey radius-8 border-parent profiler-table">
                          {false && <QuestionnairePageLoader />}
                          <div className="profiler-table-grid p-2 border-item">
                            {/* <div className="pl-4">Title</div> */}
                            <div
                              className="ml-3"
                              onClick={() => {
                                SortQuestionaireColumn("Title");
                              }}
                            >
                              Title
                              {sortItem?.type === "Title" && (
                                <>
                                  {columnSortingLoading ? (
                                    <GreyLoader width={11} />
                                  ) : (
                                    <>
                                      {sortItem?.order ? (
                                        <img
                                          alt=""
                                          src="/images/actioncenter/arrowdown.svg"
                                          className="ml-2"
                                        />
                                      ) : (
                                        <img
                                          alt=""
                                          src="/images/actioncenter/arrowup.svg"
                                          className="ml-2"
                                          style={{ width: "10px" }}
                                        />
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                            <div
                              onClick={() => {
                                SortQuestionaireColumn("Supplier");
                              }}
                            >
                              Supplier
                              {sortItem?.type === "Supplier" && (
                                <>
                                  {columnSortingLoading ? (
                                    <GreyLoader width={11} />
                                  ) : (
                                    <>
                                      {sortItem?.order ? (
                                        <img
                                          alt=""
                                          src="/images/actioncenter/arrowdown.svg"
                                          className="ml-2"
                                        />
                                      ) : (
                                        <img
                                          alt=""
                                          src="/images/actioncenter/arrowup.svg"
                                          className="ml-2"
                                          style={{ width: "10px" }}
                                        />
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                            <div
                              onClick={() => {
                                SortQuestionaireColumn("Rating");
                              }}
                            >
                              Rating
                              {sortItem?.type === "Rating" && (
                                <>
                                  {columnSortingLoading ? (
                                    <GreyLoader width={11} />
                                  ) : (
                                    <>
                                      {sortItem?.order ? (
                                        <img
                                          alt=""
                                          src="/images/actioncenter/arrowdown.svg"
                                          className="ml-2"
                                        />
                                      ) : (
                                        <img
                                          alt=""
                                          src="/images/actioncenter/arrowup.svg"
                                          className="ml-2"
                                          style={{ width: "10px" }}
                                        />
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                            <div
                              onClick={() => {
                                SortQuestionaireColumn("Last Profiled");
                              }}
                            >
                              Last Profiled
                              {sortItem?.type === "Last Profiled" && (
                                <>
                                  {columnSortingLoading ? (
                                    <GreyLoader width={11} />
                                  ) : (
                                    <>
                                      {sortItem?.order ? (
                                        <img
                                          alt=""
                                          src="/images/actioncenter/arrowdown.svg"
                                          className="ml-2"
                                        />
                                      ) : (
                                        <img
                                          alt=""
                                          src="/images/actioncenter/arrowup.svg"
                                          className="ml-2"
                                          style={{ width: "10px" }}
                                        />
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                            <div
                              onClick={() => {
                                SortQuestionaireColumn("Re-Profiling Due");
                              }}
                            >
                              Re-Profiling Due
                              {sortItem?.type === "Re-Profiling Due" && (
                                <>
                                  {columnSortingLoading ? (
                                    <GreyLoader width={11} />
                                  ) : (
                                    <>
                                      {sortItem?.order ? (
                                        <img
                                          alt=""
                                          src="/images/actioncenter/arrowdown.svg"
                                          className="ml-2"
                                        />
                                      ) : (
                                        <img
                                          alt=""
                                          src="/images/actioncenter/arrowup.svg"
                                          className="ml-2"
                                          style={{ width: "10px" }}
                                        />
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                            <div></div>
                          </div>

                          {dataForTable
                            ?.filter((item) =>
                              item?.Title?.toLowerCase()?.includes(
                                questionnaireSearchString?.toLowerCase()
                              )
                            )
                            ?.map((question, questionIndex) => {
                              return (
                                <div
                                  className="profiler-table-grid p-2 border-item bg-hover-dark-grey pointer hover-on-show-parent"
                                  onClick={() => {
                                    history.push(
                                      "/supplier-profiler/profiling",
                                      {
                                        backUrl: `${
                                          window.location.pathname
                                        }?${new URLSearchParams(
                                          window.location.search
                                        ).toString()}`,
                                        isContributorView: false,
                                        isSent: isSent,
                                        isTemplate: false,
                                        questionId: question?.ObjectId,
                                      }
                                    );
                                  }}
                                >
                                  {/* Title */}
                                  <div className="d-flex align-items-center p-2">
                                    <div className="supplier-popover-parent">
                                      <img
                                        alt=""
                                        src={
                                          "/images/supply-chain/question-list-icon.svg"
                                        }
                                        className="mr-2"
                                      />
                                    </div>
                                    <div>
                                      <p className="m-0 f-14 f-500">
                                        {question?.Title}
                                      </p>
                                      <div className="d-flex align-items-center">
                                        <div
                                          className="flex align-items-center"
                                          style={{ width: "182px" }}
                                        >
                                          <progress
                                            min="0"
                                            value={
                                              question?.QuestionnairesPercentage
                                            }
                                            max="100"
                                          />
                                        </div>
                                        <div className="f-500 f-darkgrey ml-2">
                                          {question?.QuestionnairesPercentage}%
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Supplier */}
                                  <div className="f-grey d-flex align-items-center f-black f-500">
                                    <img
                                      alt=""
                                      src={
                                        question?.Supplier?.SupplierLogo ??
                                        "/images/settings-v2/highground-dp.svg"
                                      }
                                      width={24}
                                      height={24}
                                      className="mr-2 rounded-full"
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src =
                                          "/images/settings-v2/highground-dp.svg";
                                      }}
                                    />
                                    {question?.Supplier?.SupplierName}{" "}
                                  </div>

                                  {/* Ratings */}

                                  <div className="d-flex align-items-center">
                                    <div
                                      className={`d-inline-flex align-items-center justify-content-center rounded-full w-fit-content f-12 f-500 }`}
                                      style={{
                                        background: "#EBEDF1",
                                        color: "#616778",
                                        padding: "0px 6px",
                                      }}
                                    >
                                      <div
                                        className="mr-1"
                                        style={{
                                          background: question?.RatingColour,
                                          width: "9px",
                                          height: "9px",
                                          borderRadius: "50%",
                                        }}
                                      />{" "}
                                      {question?.Rating}
                                      {/* High rated */}
                                    </div>
                                  </div>

                                  {/* Last profiler */}
                                  <div className="f-grey d-flex align-items-center f-black f-500">
                                    {moment(question?.LastUpdated).format(
                                      "DD/MM/YYYY HH:mm"
                                    )}
                                  </div>

                                  {/* Re Profiling Due */}
                                  <div className="f-grey d-flex align-items-center f-black f-500">
                                    {moment(question?.Duedate).format(
                                      "DD/MM/YYYY HH:mm"
                                    )}
                                  </div>
                                  <div className="d-flex align-items-center justify-content-center">
                                    <div
                                      className={`menu-dropdown w-fit-content  hover-on-show-child`}
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <Dropdown className="device-dropdown">
                                        <Dropdown.Toggle>
                                          <img
                                            alt=""
                                            src="/images/horizontal-dots.svg"
                                          />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <div>
                                            <Row>
                                              <Col
                                                xs={12}
                                                className=" pointer "
                                              >
                                                <OverlayTrigger
                                                  placement="left"
                                                  trigger={
                                                    viewOnlyAccess(
                                                      "AccessState_SCM"
                                                    )
                                                      ? ["hover", "focus"]
                                                      : []
                                                  }
                                                  delay={{
                                                    show: 200,
                                                    hide: 250,
                                                  }}
                                                  overlay={
                                                    <Tooltip
                                                      id={`tooltip-1365`}
                                                      className="custom_tooltip_access"
                                                    >
                                                      This feature is managed by
                                                      your service provider
                                                    </Tooltip>
                                                  }
                                                >
                                                  <div>
                                                    <div
                                                      className="drop-item"
                                                      style={{
                                                        pointerEvents:
                                                          viewOnlyAccess(
                                                            "AccessState_SCM"
                                                          )
                                                            ? "none"
                                                            : "all",
                                                      }}
                                                      onClick={(e) => {
                                                        sendReminder(
                                                          question?.ObjectId,
                                                          question?.Supplier
                                                            ?.objId_Customer
                                                        );
                                                        e.target
                                                          .closest(
                                                            ".device-dropdown"
                                                          )
                                                          .click();
                                                      }}
                                                    >
                                                      Send reminder
                                                      {viewOnlyAccess(
                                                        "AccessState_SCM"
                                                      ) ? (
                                                        <img
                                                          alt=""
                                                          src="/images/attack-surface/small-lock.svg"
                                                          className="ml-2"
                                                        />
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </div>
                                                  </div>
                                                </OverlayTrigger>
                                              </Col>
                                              <Col
                                                xs={12}
                                                className="  pointer "
                                              >
                                                <div
                                                  className="drop-item"
                                                  onClick={(e) => {
                                                    history.push(
                                                      "/supplier-profiler/profiling",
                                                      {
                                                        backUrl: `${
                                                          window.location
                                                            .pathname
                                                        }?${new URLSearchParams(
                                                          window.location.search
                                                        ).toString()}`,
                                                        isContributorView: false,
                                                        isSent: isSent,
                                                        isTemplate: false,
                                                        questionId:
                                                          question?.ObjectId,
                                                      }
                                                    );
                                                    e.target
                                                      .closest(
                                                        ".device-dropdown"
                                                      )
                                                      .click();
                                                    // setShowQuestionnaireModal(true);
                                                  }}
                                                >
                                                  View {/* Edit */}
                                                </div>
                                              </Col>

                                              <Col
                                                xs={12}
                                                className=" pointer "
                                              >
                                                <OverlayTrigger
                                                  placement="left"
                                                  trigger={
                                                    viewOnlyAccess(
                                                      "AccessState_SCM"
                                                    )
                                                      ? ["hover", "focus"]
                                                      : []
                                                  }
                                                  delay={{
                                                    show: 200,
                                                    hide: 250,
                                                  }}
                                                  overlay={
                                                    <Tooltip
                                                      id={`tooltip-1365`}
                                                      className="custom_tooltip_access"
                                                    >
                                                      This feature is managed by
                                                      your service provider
                                                    </Tooltip>
                                                  }
                                                >
                                                  <div>
                                                    <div
                                                      className="drop-item"
                                                      style={{
                                                        pointerEvents:
                                                          viewOnlyAccess(
                                                            "AccessState_SCM"
                                                          )
                                                            ? "none"
                                                            : "all",
                                                      }}
                                                      onClick={(e) => {
                                                        setDeleteSentQuestionnaireId(
                                                          question?.ObjectId
                                                        );
                                                        setShowDeleteModal(
                                                          true
                                                        );

                                                        // ArchiveOrDeleteQuestions(
                                                        //   question?.ObjectId
                                                        // );
                                                        e.target
                                                          .closest(
                                                            ".device-dropdown"
                                                          )
                                                          .click();
                                                      }}
                                                    >
                                                      Delete
                                                      {viewOnlyAccess(
                                                        "AccessState_SCM"
                                                      ) ? (
                                                        <img
                                                          alt=""
                                                          src="/images/attack-surface/small-lock.svg"
                                                          className="ml-2"
                                                        />
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </div>
                                                  </div>
                                                </OverlayTrigger>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          {fetchingNewData && (
                            <div className="d-flex align-items-center f-darkgrey">
                              <GreyLoader width={12} />
                              Loading...
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                  {/* <ProfilerTable /> */}
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
        <DeleteModal
          show={showDeleteModal}
          hideModal={() => setShowDeleteModal(false)}
          deleteMessage={"You are about to delete a profiling."}
          deleteType={"deleteSentQuestionnaire"}
          deleteSentQuestionnaire={() => {
            ArchiveOrDeleteQuestions(deleteSentQuestionnaireId);
          }}
        />
      </div>
    );
  }
);

export default ProfilingTab;

export const QuestionnairePageLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0%" y="10" rx="4" ry="4" width="100%" height="35" />
      <rect x="0%" y="47" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="129" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="211" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="293" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="375" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="457" rx="4" ry="4" width="100%" height="80" />
    </ContentLoader>
  );
};

export const NoProfilingStateInReceived = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-50 mt-5 mx-auto">
      <img
        alt=""
        src="/images/supply-chain/question-list-icon.svg"
        className="no-supplier-icon mb-3"
      />
      <div className="banner-element-name mb-2">No Profilings</div>
      <p className="banner-subtitle text-center" onClick={() => {}}>
        You haven't received any profilings.
      </p>
    </div>
  );
};
export const NoProfilingStateInSent = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-50 mt-5 mx-auto">
      <img
        alt=""
        src="/images/supply-chain/question-list-icon.svg"
        className="no-supplier-icon mb-3"
      />
      <div className="banner-element-name mb-2">No Profilings</div>
      <p className="banner-subtitle text-center" onClick={() => {}}>
        You haven't sent any profilings.
      </p>
    </div>
  );
};
