import React from "react";
import ContentLoader from "react-content-loader";

const ComingSoonLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 2000"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="26%" y="100" rx="8" ry="8" width="44%" height="215" />
      <rect x="26%" y="350" rx="8" ry="8" width="44%" height="600" />
      <rect x="26%" y="970" rx="8" ry="8" width="21.5%" height="600" />
      <rect x="49%" y="970" rx="8" ry="8" width="21.5%" height="600" />
    </ContentLoader>
  );
};

export default ComingSoonLoader;
