import React, { memo, useMemo } from "react";
import { Radar } from "react-chartjs-2";

const MspRadar = ({ data, options }) => {
  console.log("Radar rendered");

  const updatedData = useMemo(() => data, [data]);
  const updatedOptions = useMemo(() => options, [options]);
  return (
    <div
      className="zoom-independent position-relative"
      style={{
        width: "375px",
        height: "375px",
        marginTop: "60px",
        marginBottom: "40px",
        // marginLeft: "75px",
      }}
    >
      <Radar
        id="actial-radar"
        redraw={true}
        height={200}
        width={200}
        style={{ margin: "auto" }}
        data={updatedData}
        options={updatedOptions}
      />
      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          top: "-24px",
          left: "187px",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Identify
        </div>

        <div className="w-fit-content h-fit-content f-blue">
          {updatedData.datasets[0].data ? updatedData.datasets[0].data[0] : 0}
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "-50px",
          top: "calc(150px - 3rem)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Protect
        </div>

        <div className="w-fit-content h-fit-content f-blue">
          {updatedData.datasets[0].data ? updatedData.datasets[0].data[1] : 0}
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "calc(58px - 3rem)",
          bottom: "calc(53px - 3rem)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Detect
        </div>

        <div className="w-fit-content h-fit-content f-blue">
          {updatedData.datasets[0].data ? updatedData.datasets[0].data[2] : 0}
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "calc(356px - 3rem)",
          bottom: "calc(53px - 3rem)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Respond
        </div>

        <div className="w-fit-content h-fit-content f-blue">
          {updatedData.datasets[0].data ? updatedData.datasets[0].data[3] : 0}
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          left: "calc(-13px - 3rem)",
          top: "calc(150px - 3rem)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Recover
        </div>

        <div className="w-fit-content h-fit-content f-blue">
          {updatedData.datasets[0].data ? updatedData.datasets[0].data[4] : 0}
        </div>
      </div>
    </div>
  );
};

export default memo(MspRadar);

export const MspSinglePointRadar = ({ data, options }) => {
  const updatedData = useMemo(() => data, [data]);
  const updatedOptions = useMemo(() => options, [options]);

  return (
    <div
      className="w-100 h-100 position-relative zoom-independent"
      style={{ height: "fit-content" }}
    >
      <Radar
        id="actial-radar"
        redraw={true}
        height={200}
        width={200}
        style={{ margin: "auto" }}
        data={updatedData}
        options={updatedOptions}
      />
      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          top: "0",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div
          className={`w-fit-content h-fit-content ${
            updatedData.activeLabels[0] ? "f-black f-500" : "f-12 f-darkgrey"
          }`}
        >
          Identify
        </div>
        {updatedData.activeLabels[0] && (
          <div className="w-fit-content h-fit-content f-blue">
            {updatedData.datasets[0].data[0]}
          </div>
        )}
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "-2rem",
          top: "calc(45% - 3rem)",
        }}
      >
        <div
          className={`w-fit-content h-fit-content ${
            updatedData.activeLabels[1] ? "f-black f-500" : "f-12 f-darkgrey"
          }`}
        >
          Protect
        </div>
        {updatedData.activeLabels[1] && (
          <div className="w-fit-content h-fit-content f-blue">
            {updatedData.datasets[0].data[1]}
          </div>
        )}
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "calc(23% - 3rem)",
          bottom: "calc(10% - 3rem)",
        }}
      >
        <div
          className={`w-fit-content h-fit-content ${
            updatedData.activeLabels[2] ? "f-black f-500" : "f-12 f-darkgrey"
          }`}
        >
          Detect
        </div>
        {updatedData.activeLabels[2] && (
          <div className="w-fit-content h-fit-content f-blue">
            {updatedData.datasets[0].data[2]}
          </div>
        )}
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "calc(88% - 3rem)",
          bottom: "calc(10% - 3rem)",
        }}
      >
        <div
          className={`w-fit-content h-fit-content ${
            updatedData.activeLabels[3] ? "f-black f-500" : "f-12 f-darkgrey"
          }`}
        >
          Respond
        </div>
        {updatedData.activeLabels[3] && (
          <div className="w-fit-content h-fit-content f-blue">
            {updatedData.datasets[0].data[3]}
          </div>
        )}
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          left: "calc(1% - 3rem)",
          top: "calc(44% - 3rem)",
        }}
      >
        <div
          className={`w-fit-content h-fit-content ${
            updatedData.activeLabels[4] ? "f-black f-500" : "f-12 f-darkgrey"
          }`}
        >
          Recover
        </div>
        {updatedData.activeLabels[4] && (
          <div className="w-fit-content h-fit-content f-blue">
            {updatedData.datasets[0].data[4]}
          </div>
        )}
      </div>
    </div>
  );
};
