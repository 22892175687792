import React, { useState, useEffect, Fragment } from "react";
import { useDb } from "../../context/db";
import Dropzone from "react-dropzone";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useAuth } from "../../context/Auth";
import { postData } from "../../../Utils/commonApi";
import { Button, Modal, Spinner } from "react-bootstrap";
import SuccessDialog from "../../Spinner/SuccessDialog";
import FailDialog from "../../Spinner/FailDialog";
import { TrackUser } from "../../../Utils/SegmentFunctions";
import "./uploadModal.scss";
import { setuser } from "../../../redux/userState/actions";
import { useDispatch } from "react-redux";

const ImageUploadModal = (props) => {
  const { authTokens } = useAuth();
  const dispatch = useDispatch();
  const { dbObj, setdbObject } = useDb();
  const { showModal, hideModal } = props;
  const [slideHideClass, setSlideHideClass] = useState("");
  const [originalImgUrl, setOriginalImgUrl] = useState(false);
  const [crop, setCrop] = useState({ aspect: 16 / 16, width: 400 });
  const [cropImg, setCropImg] = useState(false);
  const [croppedImageUrl, setCroppedImageUrl] = useState(false);
  const [base64Img, setBase64Img] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [response, setResponse] = useState(false);

  useEffect(() => {
    if (cropImg) {
      makeClientCrop(crop);
    }
  }, [cropImg]);

  const onImageLoaded = (image) => {
    setCropImg(image);
    makeClientCrop(crop);
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    setCrop(crop);
  };

  async function makeClientCrop(crop) {
    if (cropImg && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        cropImg,
        crop,
        "newFile.jpeg"
      );
      setCroppedImageUrl(croppedImageUrl);
    }
  }

  function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      var dataURL = canvas.toDataURL("image/png");
      dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
      setBase64Img(dataURL);
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        const fileUrl = window.URL.createObjectURL(blob);
        resolve(fileUrl);
      }, "image/jpeg");
    });
  }

  const closeModal = () => {
    setSlideHideClass("slideHide");
    setTimeout(() => {
      setSlideHideClass("");
      hideModal();
      setOriginalImgUrl(false);
      setResponse(false);
    }, 300);
  };

  async function handleSave() {
    setIsSave(true);
    const response = await postData(authTokens, "UploadFile", {
      optionEnum1: props.uploadType,
      File: base64Img,
    });
    setResponse(response);
    setIsSave(false);
    if (response.mr.Success == true) {
      window.localStorage.setItem(
        "user_MessageObj",
        JSON.stringify(response?.mr?.Message)
      );
      if (props.uploadType == 0) {
        TrackUser("Profile photo uploaded");
        setdbObject({
          ...dbObj,
          UserDetail: { ...dbObj.UserDetail, Avatar: base64Img },
        });
        dispatch(setuser({ Avatar: base64Img }));
      } else {
        TrackUser("Company logo added");
        setdbObject({ ...dbObj, OrgLogo: base64Img });
      }
      setTimeout(() => {
        closeModal();
      }, 2000);
    }
    setTimeout(() => {
      setResponse(false);
    }, 2000);
  }

  return (
    <Fragment>
      <Modal
        show={showModal}
        dialogClassName="modal-50w uploadmodalresolvezoomissue"
        className={` suggestIntegrationModal integrationModal rightModal modal-medium right imageuploadModal ${slideHideClass}`}
        aria-labelledby="contained-modal-title-vcenter"
        onHide={closeModal}
      >
        <Modal.Header>
          <span className="cursor-pointer" onClick={closeModal}>
            <img src="/images/icons/close.svg" alt="" />
          </span>
          <h1 className="w-100 mb-0 mt-0 ml-4">
            {props.heading || "Upload Your Photo"}
          </h1>
        </Modal.Header>
        <Modal.Body className="container-grey p-0">
          {response && response.mr.Success == true && (
            <SuccessDialog text="Image uploaded successfully" />
          )}
          {response && response.mr.Success == false && (
            <FailDialog text="Unable to save" />
          )}
          {originalImgUrl ? (
            <Fragment>
              <div className="heading3" style={{ padding: "40px" }}>
                Crop your Image
              </div>
              <div
                className="d-flex justify-content-center ml40px mr40px"
                style={{ border: "1px solid #F6F8FB", marginBottom: "110px" }}
              >
                <ReactCrop
                  src={originalImgUrl}
                  crop={crop}
                  ruleOfThirds
                  onImageLoaded={onImageLoaded}
                  onComplete={onCropComplete}
                  onChange={onCropChange}
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #F6F8FB",
                    display: "block",
                  }}
                  className={
                    props.uploadType == 0 ? "circle-crop" : "square-crop"
                  }
                />
              </div>
              <div
                className="w-100 position-absolute"
                style={{
                  bottom: 0,
                  background: "#F6F8FB",
                  marginTop: "40px",
                }}
              >
                <Button
                  className="w-100 submit-btn mt16px"
                  onClick={handleSave}
                >
                  {isSave ? (
                    <span>
                      <Spinner animation="border" variant="light" size="sm" />{" "}
                      Save
                    </span>
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </Fragment>
          ) : (
            <div className="inner-container">
              <div className="center-container text-center">
                <Dropzone
                  onDrop={(file) =>
                    setOriginalImgUrl(URL.createObjectURL(file[0]))
                  }
                  accept="image/*"
                  minSize={1024}
                  maxSize={3072000}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps({
                        className: "bg_btn_wht cursor-pointer",
                      })}
                      variant="light"
                    >
                      <input {...getInputProps()} />
                      <img
                        src="/images/icons/fileIcon.svg"
                        alt=""
                        className="pr-1"
                      />
                      <div
                        className="linkGreyText"
                        style={{ marginTop: "30px" }}
                      >
                        .jpg, .png, .gif
                      </div>
                      <span className="textBlackSmall mt8px">
                        Drag and drop your image here or{" "}
                        <span className="txt-blue">choose a local file</span>
                      </span>
                    </div>
                  )}
                </Dropzone>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ImageUploadModal;
