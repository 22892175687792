import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./mspCalculatorDropdownAndModals.scss";
import MspRadar, { MspSinglePointRadar } from "../MspRadar";
import {
  SubmitQuestionaire,
  getAPIData,
  postRequest,
  getPageDataApi,
} from "../../../../../Utils/commonApi";
import { useAuth } from "../../../../context/Auth";
import Loader from "../../../../Common/loader";
import { GreyLoader, WhiteLoader } from "../../../../Theme/APILoaders";
import { CallToast } from "../../../../PriceCalculator/modals/CustomToast";
import { servicesIndex, services_enums, services_titles } from "..";
import CompanySettingsDropdown from "../../../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import ExploreMappingModal from "../../../../SecurityAssessments/ExploreMappingModal";
import { HGHighlightText } from "../../../../App/App";
const MspScoreModal = ({
  show,
  hideModal,
  serviceId,
  selectedNistPiller,
  hideServiceModal,
  updateRadarChart,
  HideMspSecopsLink,
  packageId,
  isForPackageDetail,
  showExploreMappingModal,
  existServices, //array
  setShowExploreMappingModal = () => {},
}) => {
  const { authTokens } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [perform, setPerform] = useState(false);
  const [selectedNistPillerUpdated, setSelectedNistPillerUpdated] =
    useState(selectedNistPiller);
  const [progress, setProgress] = useState(0);
  const [TotalPercentageAnwered, setTotalPercentageAnwered] = useState(0);
  // const [showExploreMappingModal, setShowExploreMappingModal] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [searchInput, setSearchInput] = useState(false);
  const SearchInput = useRef();

  useEffect(() => {
    setSelectedNistPillerUpdated(selectedNistPiller);
  }, [selectedNistPiller]);

  const [showSecOpsBanner, setShowSecOpsBanner] = useState(!HideMspSecopsLink);
  let radarValue = questionList?.reduce(function (accumulator, currentValue) {
    if (currentValue.AnswerBool === true) {
      return accumulator + currentValue.QuestionScore;
    }
    return accumulator;
  }, 0);

  const data = useMemo(() => {
    let activeLabels = [false, false, false, false, false];
    let total_sum = 0;
    let updated_labels = ["", "", "", "", ""];
    let updated_point_radius = [0, 0, 0, 0, 0];
    // let updated_data = [0, 0, 0, 0, 0];
    let updated_data = [30, 30, 30, 30, 30];
    if (questionList?.length > 0) {
      total_sum = questionList?.reduce(function (accumulator, currentValue) {
        if (currentValue.AnswerBool === true) {
          return accumulator + currentValue.QuestionScore;
        }
        return accumulator;
      }, 0);
      let activeIndex = servicesIndex[selectedNistPillerUpdated];
      // updated_labels[activeIndex] = `${selectedNistPiller} - ${total_sum}`;
      updated_data[activeIndex] = isForPackageDetail
        ? TotalPercentageAnwered
        : total_sum;
      activeLabels[activeIndex] = true;
      updated_point_radius[activeIndex] = 3;
    }

    return {
      labels: updated_labels,
      activeLabels: activeLabels,

      datasets: [
        {
          data: updated_data,
          pointRadius: updated_point_radius,
          datalabels: {
            display: false,
          },
          backgroundColor: `${"#008fd4"}30`,
          borderColor: `${"#008fd4"}`, // "rgba(0, 143, 212, 1)",
          pointBackgroundColor: "#008fd4",
          borderWidth: 2,
          pointLabelFontSize: 0,
          scaleShowLabels: false,
          label: selectedNistPiller ?? "Current posture",
        },
      ],
    };
  }, [questionList, selectedNistPiller, TotalPercentageAnwered]);

  const _options = useMemo(() => {
    return {
      animation: {
        duration: 1, // 4000, // Set the duration of the transition in milliseconds
      },
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },

      tooltips: {
        enabled: true,
      },
      scale: {
        display: true,
        pointLabels: {
          fontSize: 16,
          fontFamily: "Archivo, sans-serif",
          fontColor: "#101010",
        },
        ticks: {
          beginAtZero: false,
          stepSize: 10,
          max: 40,
          min: 0,
          display: false,
          backdropColor: "rgba(0, 0, 0, 0)",
        },
      },
      elements: {
        point: {
          radius: 2,
          hoverRadius: 4,
        },
      },
    };
    // return opts;
  }, []);

  async function getModalData() {
    isForPackageDetail && setQuestionList([]);
    isForPackageDetail && setProgress(0);
    await getAPIData(
      isForPackageDetail ? `${679}` : `${634}`,
      authTokens,
      packageId
        ? {
            optionEnum1: 0,
            optionEnum2: 0,
            optionEnum3: 0,
            optionEnum4: 0,
            optionEnum5: packageId,
            optionStrEnum1: selectedNistPillerUpdated,
          }
        : {
            optionEnum1: 0,
            optionEnum2: 0,
            optionEnum3: 0,
            optionEnum4: 0,
            optionEnum5: serviceId,
            optionStrEnum1: selectedNistPiller,
          }
    )
      .then((response) => {
        if (response?.mr?.Success) {
          let question_data = isForPackageDetail
            ? response?.QuestionnaireSection_List?.filter(
                (item) => item?.Title === selectedNistPillerUpdated
              )[0]
            : response?.QuestionnaireSection_List?.filter(
                (item) => item?.Title === selectedNistPiller
              )[0];
          if (question_data?.Question_List?.length > 0) {
            setQuestionList(question_data?.Question_List);
            setProgress(question_data?.Progress);
          }
          setTotalPercentageAnwered(response?.TotalPercentageAnwered);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  async function updateBackEndData() {
    return new Promise((resolve, reject) => {
      getAPIData(`${666}`, authTokens, {
        optionEnum1: serviceId,
        optionEnum2: radarValue,
        optionStrEnum1: selectedNistPiller,
      })
        .then((response) => {
          if (response?.Success) {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((err) => {
          console.log(err);

          reject(false);
        });
    });
  }

  async function saveData() {
    await SubmitQuestionaire(authTokens, "CM_SubmitQuestionnaire", {
      ObjectId: 0,
      QuestionnaireTypeEnum: 25,
      QuestionList: JSON.stringify(questionList),
      ObjId_Supplier: 0,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      objId_SendFormQuestionnaire: 0,
      objId_Service: serviceId,
    })
      .then((response) => {
        if (response?.Success) {
          updateBackEndData()
            .then(() => {
              setSaving(false);
              hideModal();
              CallToast("Updated successfully", true, false, "v2style");
              updateRadarChart();
            })
            .catch(() => {
              setSaving(false);
              CallToast("Something went wrong", false, false, "v2style");
            });
        } else {
          CallToast("Something went wrong", false, false, "v2style");
          setSaving(false);
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }
  async function hideBanner() {
    postRequest(authTokens, "SubmitNotificationSettings", {
      SettingOwner: 0,
      OwnerType: 0,
      NotificationName: "SO_HideMSPSecopsLink",
      NotificationValue: 1,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((response) => {
        if (response.Success) {
          CallToast("Updated successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  useEffect(() => {
    if (show) {
      setLoading(true);
      getModalData();
      setShowSecOpsBanner(!HideMspSecopsLink);
    }
  }, [show, serviceId, HideMspSecopsLink, selectedNistPillerUpdated]);

  return (
    <Modal
      show={show}
      dialogClassName={`msp-score-modal ${
        isForPackageDetail && "z-Index-1040"
      }`}
      className={`msp-score-modal ${isForPackageDetail && "z-Index-1040"}`}
      aria-labelledby="msp-score-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Header className="d-flex align-items-center justify-content-between p-2">
        {/* {page !== "edit" && (
          <img
            alt=""
            src="/images/supply-chain/chevron-left.svg"
            className="mt-2 pointer"
            onClick={() => {
              setPage("edit");
            }}
          />
        )} */}

        <div className="w-100 mb-0 f-600 f-black ml-3">
          {isForPackageDetail
            ? "Package alignment to NIST CSF"
            : "Align Service to the NIST CSF"}
        </div>

        <div className="d-flex align-items-center">
          {!isForPackageDetail ? (
            <>
              <Button className="hg-cancel-btn mr-2" onClick={hideModal}>
                Cancel
              </Button>
              <Button
                className="hg-blue-btn mr-2"
                disabled={saving}
                onClick={() => {
                  setSaving(true);
                  saveData();
                }}
              >
                <div className="d-flex align-items-center">
                  {saving ? (
                    <>
                      <WhiteLoader width={13} /> Saving..
                    </>
                  ) : (
                    "Save"
                  )}
                </div>
              </Button>
            </>
          ) : (
            <>
              <div className="d-flex pointer">
                <img
                  src="/images/security-assessment/explore-mapping-icon.svg"
                  alt=""
                  className="mr-2"
                  style={{
                    maxWidth: "25px",
                    maxHeight: "25px",
                  }}
                />
                <div
                  className="f-black f-500 mr-4"
                  style={{
                    width: "max-content",
                  }}
                  onClick={() => {
                    setShowExploreMappingModal(true);
                  }}
                >
                  Explore mapping
                </div>
              </div>
            </>
          )}

          <span
            className="cursor-pointer d-inline-flex mx-2"
            onClick={hideModal}
          >
            <img
              alt=""
              width={11}
              height={11}
              src="/images/icons/close.svg"
              className="close-icon"
            />
          </span>
        </div>
      </Modal.Header>
      <Modal.Body className="hide-scrollbar px-3 py-0">
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center w-100 "
            style={{
              height: "85vh",
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <div className="d-flex w-100 position-relative h-100">
              <div
                className="w-70 position-absolute top-0 bottom-0 hide-scrollbar pl-3 pr-5"
                style={{ gap: "24px" }}
              >
                {/* Banner */}

                {showSecOpsBanner && (
                  <div className="radius-8 bg-grey d-flex align-items-center justify-content-between p-3 mt-4">
                    <div className="d-flex align-items-center">
                      <img
                        alt=""
                        src="/images/msp/score-info-icon.svg"
                        className="mr-2"
                      />
                      Don't forget to check out the MSP SecOps section to
                      benchmark your security operations against best practice.
                    </div>
                    <div className="d-flex align-items-center">
                      <div
                        className="f-blue f-500 w-max-content pointer"
                        onClick={() => {
                          hideServiceModal();
                          hideBanner();
                          setTimeout(() => {
                            history.push("/mspv2/secops");
                          }, 500);
                        }}
                      >
                        Go to MSP SecOps
                      </div>
                      <img
                        alt=""
                        className="ml-3 pointer"
                        src="/images/attack-surface/cross-icon.svg"
                        onClick={() => {
                          setShowSecOpsBanner(false);
                        }}
                      />
                    </div>
                  </div>
                )}

                {isForPackageDetail && (
                  <div className="d-flex mb-4 mt-2">
                    {Object.values(services_enums).map((service) => (
                      <div
                        key={service}
                        className={`
                          px-2 py-2 
                          ${
                            services_titles[service.replace(" ", "_")] ===
                            selectedNistPillerUpdated
                              ? " curs-default txt-blue brd-bottom-blue"
                              : " pointer greyText2 "
                          }
                        `}
                        onClick={() => {
                          setSelectedNistPillerUpdated(
                            services_titles[service.replace(" ", "_")]
                          );
                        }}
                      >
                        {service}
                      </div>
                    ))}
                  </div>
                )}

                <div className="d-flex align-items-center p-3">
                  <img
                    alt=""
                    src="/images/attack-surface/search-icon.svg"
                    className="pointer"
                    width={16}
                    onClick={() => {
                      setSearchInput(true);
                      SearchInput.current.focus();
                    }}
                  />
                  <input
                    type="text"
                    placeholder="Find controls.."
                    ref={SearchInput}
                    value={searchString}
                    onBlur={() => {
                      // setSearchInput(false);
                    }}
                    onChange={(e) => {
                      setSearchString(e.target.value);
                    }}
                    className={`${"search-input"} w-100 pl-2`}
                    // autoFocus
                  />
                  <img
                    alt=""
                    className="pointer"
                    onClick={() => {
                      setSearchString("");
                    }}
                    src="/images/msp/clear-search-icon.svg"
                    style={{
                      visibility: `${searchString ? "visible" : "hidden"}`,
                    }}
                  />
                </div>
                {/* -- */}
                <div className="radius-8 bg-grey border-parent ">
                  {!isForPackageDetail && (
                    <div
                      className="f-12 f-500 f-darkgrey mb-3 radius-8 bg-grey"
                      style={{ padding: "8px 16px 4px 16px" }}
                    >
                      Does this service...
                    </div>
                  )}

                  {questionList
                    ?.filter((item) =>
                      item?.QuestionText?.toLowerCase().includes(
                        searchString?.toLowerCase()
                      )
                    )
                    ?.map((question, questionIndex) => {
                      return (
                        <>
                          {isForPackageDetail ? (
                            <>
                              {question?.AnswerId !== 0 &&
                                question?.AnswerBool === true && (
                                  <>
                                    {question?.InheritedText && (
                                      <div className="table-title radius-4 f-500">
                                        <div className="img">
                                          <img
                                            alt=""
                                            className=" "
                                            src="/images/msp/arrow-enter.svg"
                                          />
                                        </div>

                                        <div className="title f-darkgrey">
                                          Inherited from `
                                          {question?.InheritedText}`{" "}
                                        </div>
                                      </div>
                                    )}
                                    <div
                                      className="border-item "
                                      style={{ padding: "12px 16px" }}
                                    >
                                      <div className="d-flex align-items-start justify-content-between">
                                        <div>
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: HGHighlightText(
                                                question.QuestionText,
                                                searchString
                                              ),
                                            }}
                                          />

                                          <div className="d-flex flex-wrap mt-2">
                                            {question?.NISTAssetClassification_List?.map(
                                              (chip, chipIndex) => {
                                                return (
                                                  <div className="position-relative tooltip-parent">
                                                    <QuestionChip
                                                      iconUrl={
                                                        chip.IsFramework
                                                          ? "/images/msp/msp-framework-icon.svg"
                                                          : null
                                                      }
                                                      title={chip?.Name}
                                                      className={"mr-2"}
                                                      IsBlue={
                                                        chip?.Name?.toLowerCase() ===
                                                        "best practice"
                                                      }
                                                    />
                                                    {!chip.IsFramework && (
                                                      <PackageToolTip
                                                        content={chip?.Detail}
                                                      />
                                                    )}
                                                  </div>
                                                );
                                              }
                                            )}
                                            <div className="position-relative tooltip-parent">
                                              <FrameworksDropdown
                                                question={question}
                                              />
                                            </div>
                                            {/* {question?.InheritedText && (
                                          <div className="position-relative ">
                                            <QuestionChip
                                              iconUrl={
                                                "/images/msp/arrow-enter.svg"
                                              }
                                              title={question?.InheritedText}
                                              className={"mr-2"}
                                            />
                                          </div>
                                        )} */}
                                          </div>
                                        </div>
                                        <div className="w-fit-content dropdown-grey-theme score-question-dropdown ml-3">
                                          {question.IsDisabled ? (
                                            <OverlayTrigger
                                              trigger={
                                                !question.IsDisabled
                                                  ? []
                                                  : ["hover", "focus"]
                                              }
                                              placement={"bottom"}
                                              overlay={
                                                <Tooltip id={`tooltip-1365`}>
                                                  Answer has been inherited from
                                                  a parent service in this
                                                  series and cannot be changed.
                                                </Tooltip>
                                              }
                                            >
                                              <div className="d-flex w-fit-content dropdown-grey-theme score-question-dropdown ml-3">
                                                <div className="f-darkgrey">
                                                  Yes
                                                </div>
                                                <img
                                                  alt=""
                                                  src="/images/msp/msp-cert-link-icon.svg"
                                                  className="ml-2"
                                                />
                                              </div>
                                            </OverlayTrigger>
                                          ) : (
                                            <div>
                                              {question.AnswerBool === true
                                                ? "Yes"
                                                : question.AnswerBool ===
                                                    false && "No"}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                            </>
                          ) : (
                            <>
                              {question?.InheritedText && (
                                <div className="table-title radius-4 f-500">
                                  <div className="img">
                                    <img
                                      alt=""
                                      className=" "
                                      src="/images/msp/arrow-enter.svg"
                                    />
                                  </div>

                                  <div className="title f-darkgrey">
                                    Inherited from `{question?.InheritedText}`{" "}
                                  </div>
                                </div>
                              )}
                              <div
                                className="border-item "
                                style={{ padding: "12px 16px" }}
                              >
                                <div className="d-flex align-items-start justify-content-between">
                                  <div>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: HGHighlightText(
                                          question.QuestionText,
                                          searchString
                                        ),
                                      }}
                                    />

                                    <div className="d-flex flex-wrap mt-2">
                                      {question?.NISTAssetClassification_List?.map(
                                        (chip, chipIndex) => {
                                          return (
                                            <div className="position-relative tooltip-parent">
                                              <QuestionChip
                                                iconUrl={
                                                  chip.IsFramework
                                                    ? "/images/msp/msp-framework-icon.svg"
                                                    : null
                                                }
                                                title={chip?.Name}
                                                className={"mr-2"}
                                                IsBlue={
                                                  chip?.Name?.toLowerCase() ===
                                                  "best practice"
                                                }
                                              />
                                              {!chip.IsFramework && (
                                                <PackageToolTip
                                                  content={chip?.Detail}
                                                />
                                              )}
                                            </div>
                                          );
                                        }
                                      )}
                                      {/* <div className="position-relative tooltip-parent">
                                        <QuestionChip
                                          iconUrl={
                                            "/images/msp/msp-framework-icon.svg"
                                          }
                                          title={"Frameworks"}
                                          className={"mr-2"}
                                        />

                                        <FrameworksToolTip />
                                      </div> */}
                                      <div className="position-relative tooltip-parent">
                                        <FrameworksDropdown
                                          question={question}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w-fit-content dropdown-grey-theme score-question-dropdown ml-3">
                                    {question.IsDisabled ? (
                                      <OverlayTrigger
                                        trigger={
                                          !question.IsDisabled
                                            ? []
                                            : ["hover", "focus"]
                                        }
                                        placement={"bottom"}
                                        overlay={
                                          <Tooltip id={`tooltip-1365`}>
                                            Answer has been inherited from a
                                            parent service in this series and
                                            cannot be changed.
                                          </Tooltip>
                                        }
                                      >
                                        <div className="d-flex w-fit-content dropdown-grey-theme score-question-dropdown ml-3">
                                          <div className="f-darkgrey">Yes</div>
                                          <img
                                            alt=""
                                            src="/images/msp/msp-cert-link-icon.svg"
                                            className="ml-2"
                                          />
                                        </div>
                                      </OverlayTrigger>
                                    ) : (
                                      <>
                                        <CompanySettingsDropdown
                                          fieldId={"id"}
                                          fieldValue={"cat"}
                                          selected={
                                            question.AnswerBool === true
                                              ? { id: 1, cat: "Yes" }
                                              : question.AnswerBool === false
                                              ? { id: 0, cat: "No" }
                                              : question.AnswerBool === null
                                              ? null
                                              : null
                                          }
                                          placeholder="Select"
                                          //  showid={true}
                                          onClick={(id, val) => {
                                            let updated_data = [
                                              ...questionList,
                                            ];
                                            updated_data[
                                              questionIndex
                                            ].AnswerBool = Boolean(id);
                                            updated_data[
                                              questionIndex
                                            ].QuestionStatus = 3;
                                            setQuestionList(updated_data);
                                          }}
                                          data={[
                                            { id: 1, cat: "Yes" },
                                            { id: 0, cat: "No" },
                                          ]}
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>

                                {/* Chips */}
                              </div>
                            </>
                          )}
                        </>
                      );
                    })}
                  {searchString &&
                    // existServices[selectedNistPillerUpdated] !== 0 &&
                    questionList
                      ?.filter((item) => item?.AnswerBool)
                      ?.filter((item) =>
                        item?.QuestionText?.toLowerCase().includes(
                          searchString?.toLowerCase()
                        )
                      )?.length == 0 && (
                      <div className="d-flex justify-content-center align-items-center p-4">
                        <div>No questions found</div>
                      </div>
                    )}

                  {isForPackageDetail &&
                    !searchString &&
                    existServices[servicesIndex[selectedNistPillerUpdated]] !==
                      0 &&
                    questionList?.filter((item) => item?.AnswerBool)?.length ==
                      0 && (
                      <div className="d-flex justify-content-center align-items-center p-4 mb-2">
                        <div>No questions</div>
                      </div>
                    )}

                  {isForPackageDetail &&
                    !searchString &&
                    existServices[servicesIndex[selectedNistPillerUpdated]] ===
                      0 && (
                      <div className="d-flex justify-content-center align-items-center p-4">
                        <div>
                          No services of this type have been added to this
                          package
                        </div>
                      </div>
                    )}
                </div>

                <div className="p-5"></div>
              </div>

              {/* Radar Chart */}
              <div className="w-30 p-5 d-flex flex-column position-absolute right-0">
                <div className="h-fit-content">
                  <MspSinglePointRadar data={data} options={_options} />
                </div>

                {false && (
                  <div className="mt-5 d-none">
                    <div className="d-flex align-items-center justify-content-between radius-8 bg-grey p-2">
                      <div className="">
                        <div className="f-black f-500">SpecOps Identify</div>
                        <div className="f-darkgrey f-12">
                          Get additional points from SpecOps Identify
                        </div>
                      </div>

                      <div>
                        {perform ? (
                          <div
                            className="d-flex align-items-center pointer"
                            onClick={() => {
                              setPerform(!perform);
                            }}
                          >
                            <div className="f-blue mr-2">+8</div>
                            <div>
                              <img
                                src="/images/chevron-right.svg"
                                alt=""
                                className="mr-2"
                              />
                            </div>
                          </div>
                        ) : (
                          <Button
                            className="hg-blue-btn"
                            onClick={() => {
                              setPerform(!perform);
                            }}
                          >
                            Perform
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </Modal.Body>

      <Modal.Footer className="p-2 d-fle x d-none align-items-center justify-content-between">
        <div></div>
        <div className="d-flex justify-content-end">
          <Button className="  hg-cancel-btn mr-2" onClick={hideModal}>
            Cancel
          </Button>

          <Button
            className="hg-blue-btn"
            disabled={saving}
            onClick={() => {
              setSaving(true);
              saveData();
            }}
          >
            <div className="d-flex align-items-center">
              {saving ? (
                <>
                  <WhiteLoader width={13} /> Saving..
                </>
              ) : (
                "Save"
              )}
            </div>
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default MspScoreModal;

export const QuestionChip = ({
  iconUrl,
  title,
  className,
  IsBlue,
  clickFunc,
}) => {
  return (
    <div
      className={`px-2 rounded-full d-flex align-items-center ${
        IsBlue ? "f-blue bg-lightblue" : "border1 f-grey"
      }  f-12 f-500 pointer   ${className}`}
      onClick={() => {
        if (clickFunc) {
          clickFunc();
        }
      }}
    >
      {iconUrl && <img alt="" src={iconUrl} className="mr-2" />}
      {title}
    </div>
  );
};

export const PackageToolTip = ({ content }) => {
  let [beforeColon, afterColon] = ["", ""];
  if (content) {
    [beforeColon, afterColon] = content?.split(":");
  }

  return (
    <>
      {content ? (
        <div
          className="position-absolute tooltip-item radius-4 border1 bg-white p-2"
          style={{
            maxWidth: "220px",
            width: "220px",
            boxShadow: "0px 12px 24px rgba(16, 16, 16, 0.08)",
            zIndex: 2,
            left: "100%",
            transform: "translate(0%, -40%)",
          }}
        >
          <span style={{ fontWeight: "bold" }}>{beforeColon}</span>:{afterColon}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export const QuestionChipFramework = ({
  iconUrl,
  title,
  className,
  IsBlue,
  clickFunc,
  beforeColon,
  afterColon,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onMouseEnter={(event) => {
        // event.target.click();
        setIsHovered(true);
      }}
      onMouseLeave={(event) => {
        setIsHovered(false);
        // event.target.click();
      }}
      className={`px-2 rounded-full d-flex align-items-center ${
        IsBlue ? "f-blue bg-lightblue" : "border1 f-grey"
      }  f-12 f-500 pointer   ${className}`}
      onClick={() => {
        if (clickFunc) {
          clickFunc();
        }
      }}
    >
      {iconUrl && <img alt="" src={iconUrl} className="mr-2" />}
      {title}
      <>
        {isHovered ? (
          <div
            className="position-absolute tooltip-item radius-4 border1 bg-white p-2"
            style={{
              maxWidth: "220px",
              width: "220px",
              boxShadow: "0px 12px 24px rgba(16, 16, 16, 0.08)",
              zIndex: 2,
              left: "100%",
              transform: "translate(0%, -40%)",
              whiteSpace: "normal",
            }}
          >
            <span style={{ fontWeight: "bold" }}>{beforeColon}</span>:
            {afterColon}
          </div>
        ) : (
          <></>
        )}
      </>
    </div>
  );
};

export const frameData = [
  {
    title: "CIS CSC v8",
    items: ["CSC1", "CSC2"],
  },
  {
    title: "CIS CSC v8",
    items: ["CSC1", "CSC2", "CSC1", "CSC2"],
  },
  {
    title: "CIS CSC v8",
    items: ["CSC1", "CSC2", "CSC1", "CSC2"],
  },
  {
    title: "CIS CSC v8",
    items: ["CSC1", "CSC2"],
  },
  {
    title: "CIS CSC v8",
    items: ["CSC1", "CSC2", "CSC1", "CSC2"],
  },
];

export const FrameworksDropdown = ({ question }) => {
  const [fetchingHistory, setFetchingHistory] = useState({});
  const { authTokens } = useAuth();
  const [isHovered, setIsHovered] = useState(false);
  const [frameData, setFrameData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("");

  async function loadFrameData(ids, activeControl) {
    setIsHovered(true);

    return getPageDataApi(`SecurityFramework/GetSimilarControls`, authTokens, {
      controls: ids ?? "",
      activeControl: activeControl ?? "",
    })
      .then((response) => {
        setFrameData(response?.detail);
        setSelectedTab(response?.detail[0]?.controlID);

        setFetchingHistory(false);
      })
      .catch((err) => {
        console.log(err);
        setFetchingHistory(false);
      });
  }
  return (
    <div>
      <div
        className={`dropdown-history  px-2 rounded-full d-flex align-items-center   border1 f-grey f-12 f-500 pointer`}
        onMouseEnter={(event) => {
          setFetchingHistory(true);
          const namesArray = question?.NISTAssetClassification_List.map(
            (obj) => obj.Name
          );
          const namesString = namesArray.join(",");

          loadFrameData(namesString);

          // event.target.click();
        }}
        onMouseLeave={(event) => {
          setIsHovered(false);
          // event.target.click();
        }}
      >
        <img src="/images/msp/msp-framework-icon.svg" className="mr-2" />
        Frameworks
        <div
          className="dropdown-revoke history"
          style={{
            width: " 280px",
            display: isHovered ? "flex" : "none",
            // display: "flex",
          }}
        >
          <div className="d-flex">
            <div className="f-darkgrey f-12" style={{ padding: "4px 12px" }}>
              NIST CSF Control Mappings
            </div>
          </div>
          {fetchingHistory ? (
            <span className="d-inline-flex align-items-center justify-content-center h-100 w-100">
              <GreyLoader width={10} />
              Loading...
            </span>
          ) : (
            <div
              className="d-flex flex-column w-100"
              style={{ padding: "4px 12px" }}
            >
              {frameData?.length > 0 ? (
                <>
                  <div className="d-flex flex-wrap  w-100">
                    {frameData.map((data, dataIndex) => {
                      return (
                        <div className="mb-2">
                          <div className="f-12 f-darkgrey mb-2 position-relative tooltip-parent">
                            <QuestionChip
                              title={data.controlID}
                              className="mr-2 mb-2"
                              IsBlue={data.controlID == selectedTab}
                              clickFunc={() => {
                                setSelectedTab(data.controlID);
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    className=" border-bottom mb-2"
                    style={{
                      marginLeft: "-12px",
                      marginRight: "12px",
                      width: "110%",
                    }}
                  ></div>
                  {frameData
                    ?.filter((item) => item.controlID == selectedTab)
                    ?.map((data, dataIndex) => {
                      return (
                        <div className="mb-2">
                          <div className="d-flex flex-column">
                            {data?.list.map((chip, chipIndex) => {
                              return (
                                <>
                                  <div className="f-12 f-darkgrey mb-2 ">
                                    {chip?.frameworkName}
                                  </div>
                                  <div className="d-flex flex-wrap">
                                    {" "}
                                    {chip?.sections?.map((sec, secIndex) => {
                                      return sec?.controls?.map(
                                        (contr, contrIndex) => {
                                          return (
                                            <div
                                              key={contrIndex + contr.controlID}
                                              className="position-relative tooltip-parent"
                                            >
                                              <QuestionChipFramework
                                                title={contr.controlID}
                                                className="mr-2 mb-2"
                                                beforeColon={contr?.controlID}
                                                afterColon={
                                                  contr?.controlDescription
                                                }
                                              />
                                            </div>
                                          );
                                        }
                                      );
                                    })}
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                </>
              ) : (
                <>
                  {frameData?.length == 0 && (
                    <div className="d-flex f-darkgrey">No mappings found</div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const FrameworksToolTip = ({ idArray, activeControlId }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [frameData, setFrameData] = useState([]);
  const accessKey = localStorage.getItem("user_MessageObj")
    ? JSON.parse(localStorage.getItem("user_MessageObj"))
    : "null";

  // Функция для загрузки данных frameData
  const loadFrameData = async () => {
    // Ваш код для загрузки данных frameData, например, fetch или axios запрос
    // После успешной загрузки данных, установите данные в состояние
    // setFrameData(data);
    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/SecurityFramework/GetSimilarOptions?accessKey=${accessKey}&controls=${idArray}&activeControl=${activeControlId}`
    )
      .then((response) => response.json())
      .then(async (data) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  // Обработчики событий для показа и скрытия тултипа
  const handleMouseEnter = () => {
    setIsVisible(true);
    loadFrameData();
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };
  return (
    <div
      className="position-absolute tooltip-item radius-4 border1 bg-white p-2 px-3"
      style={{
        maxWidth: "220px",
        width: "220px",
        boxShadow: "0px 12px 24px rgba(16, 16, 16, 0.08)",
        zIndex: 2,
        left: "100%",
        transform: "translate(0%, -40%)",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {frameData.map((data, dataIndex) => {
        return (
          <div className="mb-2">
            <div className="f-12 f-darkgrey mb-2">{data?.title} </div>
            <div className="d-flex flex-wrap">
              {data?.items.map((chip, chipIndex) => {
                return (
                  <QuestionChip title={data.title} className="mr-2 mb-2" />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
