import React from "react";
import { Dropdown } from "react-bootstrap";
import "./mspCalculatorDropdownAndModals.scss";
const MspServiceModalToolTip = ({ children ,data}) => {
   
  return (
    <div
      className="msp-service-tool-tip-content radius-8 "
      style={{
        maxWidth: "230px",
        border: "1px solid #EBEDF1",
        overflow: "hidden",
      }}
    >
      <img
        src={`/images/msp/${(data?.Image ?? "")
          .replace(".", "")
          .replace("jpg", "")}.svg`}
        alt=""
        className=""
        style={{
          objectFit: "cover",
        }}
      />
      <div className="bg-white p-3">
        <div className=" f-600 f-black mb-2">{data?.Name} </div>
        <div className="f-grey ">{data?.Description}</div>
      </div>
    </div>
  );
};

export default MspServiceModalToolTip;
