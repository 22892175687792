import React, { useState,   } from "react";
import "./DrillDownBoxes.css";
import { Col, Row,   } from "react-bootstrap";
import "../../main.scss";
import { Link, useHistory } from "react-router-dom";

const  DrillDownBoxes = (props) => {
  const [mainText] = useState(props.mainText || "");
  const [img] = useState(props.img || "");
  const [imgSideText] = useState(props.imgSideText || "");
  const [size] = useState(props.size || 6);

  const history= useHistory()

  return props.isPreData !== 3 ? (
    <Row className="drilldownBox">
      <Col md={8} className="my-auto">
        <div>
          <h1 className="drilldownText">{mainText}</h1>
        </div>
      </Col>

      <Col md={{ span: 4 }} style={{ paddingRight: "0.5em" }}>
        <div
          className="mx-auto my-auto"
          style={{ height: "100%", padding: "0.5em 0" }}
        >
          <Col md={12} className="drilldownBox_Background">
            <span>
              <img alt="" src={img} style={{ display: "inline-block" }}></img>
              <h3
                className="drilldownSideText"
                style={{ display: "inline-block" }}
              >
                {imgSideText}
              </h3>
            </span>
          </Col>
        </div>
      </Col>
    </Row>
  ) : (
    <Row className="drilldownBox">
      <Col md={8} className="">
        <Link
          to={`/technical_drilldown/preData/${mainText.replaceAll(/ /g, "_")}`}
        >
          <span>
            <img
              alt=""
              className="mr8px"
              src="/images/technicalDrillPre.svg"
              style={{ display: "inline-block" }}
            ></img>
            <h3
              className="drilldownText"
              style={{ display: "inline-block", marginTop: "18px" }}
            >
              {mainText}
            </h3>
          </span>
          <p className="linkGreyText mt8px">{imgSideText}</p>
        </Link>
      </Col>
      <Col md={4}>
        <div className="" style={{ padding: "30px 13px", textAlign: "right" }}>
          {/* <a href="/integrations"> */}
            <p className=" txt-blue" style={{ cursor: "pointer" }}
            
            onClick={()=>{
              history.push(`/integrations?selectedSearchTab=${mainText}`)
            }}
            
            >
              Go to Integrations
              <img
                alt=""
                src="/images/complianceManager/rightArrowIcon.svg"
                className="ml-3"
              />
            </p>
          {/* </a> */}
        </div>
      </Col>
    </Row>
  );
};

export default DrillDownBoxes;
