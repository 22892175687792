import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import "./clientComplianceDropdown.scss";
import "../../../../test/test.scss";
import moment from "moment";
import { handleDateForInvoice } from "../../../MspAttackSurface.js/technologySpendModal/TechnologySpendModal";
const CyberComplianceDateDropdown = (props) => {
  // home, calender
  const [page, setPage] = useState("home");

  useEffect(() => {
    setPage(props.screenType ?? "home");
  }, [props.screenType]);

  // Calender Setup
  const [selectedDate, setSelectedDate] = useState(
    moment().format("MM/DD/YYYY")
  );
  const [firstLoad, setFirstLoad] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (!firstLoad) return;
    setFirstLoad(true);
    drawCalender();
  }, [selectedDate]);
  useEffect(() => {
    if (props.selected) {
      setInputValue(props.selected);
    }
    if (props.selected?.split("/").length > 2) {
      setSelectedDate(changeDateFormat(props.selected));
    }
    if (props.selected === "") {
      setSelectedDate(moment().format("MM/DD/YYYY"));
    }
  }, [props.selected]);

  function changeDateFormat(format) {
    return `${addLeadingZero(format.split("/")[1])}/${format.split("/")[0]}/${
      format.split("/")[2]
    }`;
  }


  function getValuefromLiTag() {
    let wrapper = document.getElementById(`hg-date-wrapper${props.id}`);

    wrapper.addEventListener("click", (e) => {
      const months_list = {
        January: "01",
        February: "02",
        March: "03",
        April: "04",
        May: "05",
        June: "06",
        July: "07",
        August: "08",
        September: "09",
        October: "10",
        November: "11",
        December: "12",
      };

      let currentDateData = document.querySelector(`.current-date.${props.id}`);

      if (currentDateData) {
        let month = currentDateData.innerText.split(" ")[0];
        let selected_month = months_list[month];
        let year = currentDateData.innerText.split(" ")[1];

        if (e.target.id === "date-value") {
          setSelectedDate(`${selected_month}/${e.target.value}/${year}`);
          setInputValue(
            changeDateFormat(`${selected_month}/${e.target.value}/${year}`)
          );
          document.querySelector(".cyberessential-audit-dropdown").click()
          props.onChange(
            changeDateFormat(`${selected_month}/${e.target.value}/${year}`),"date"
          );
          // document.getElementById(props.id).click();
          setPage("home");
        }
      }
    });
  }

  function drawCalender() {
    const daysTag = document.querySelector(`.days.${props.id}`);
    var currentDate = document.querySelector(`.current-date.${props.id}`);
    var prevNextIcon = document.querySelectorAll(".icons span");

    // getting new date, current year and month
    let date = new Date(selectedDate);
    let currYear = date.getFullYear();
    let currMonth = date.getMonth();

    // storing full name of all months in array
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const renderCalendar = () => {
      try{
        let firstDayofMonth = new Date(currYear, currMonth, 1).getDay(); // getting first day of month
        let lastDateofMonth = new Date(currYear, currMonth + 1, 0).getDate(); // getting last date of month
        let lastDayofMonth = new Date(
          currYear,
          currMonth,
          lastDateofMonth
        ).getDay(); // getting last day of month
        let lastDateofLastMonth = new Date(currYear, currMonth, 0).getDate(); // getting last date of previous month
        let liTag = "";
  
        for (let i = firstDayofMonth; i > 0; i--) {
          // creating li of previous month last days
          liTag += `<li class="inactive">${lastDateofLastMonth - i + 1}</li>`;
        }
  
        for (let i = 1; i <= lastDateofMonth; i++) {
          // creating li of all days of current month
          // adding active className to li if the current day, month, and year matched
          let isToday =
            i === date.getDate() &&
            currMonth === new Date(selectedDate).getMonth() &&
            currYear === new Date(selectedDate).getFullYear()
              ? "active"
              : "";
          liTag += `<li class="${isToday}" id="date-value" value=${i}>${i}</li>`;
        }
  
        for (let i = lastDayofMonth; i < 6; i++) {
          // creating li of next month first days
          liTag += `<li class="inactive">${i - lastDayofMonth + 1}</li>`;
        }
  
        currentDate.innerText = `${months[currMonth]} ${currYear}`; // passing current mon and yr as currentDate text
  
        daysTag.innerHTML = liTag;
      }catch(e){

      }
   
    };
    renderCalendar();

    prevNextIcon.forEach((icon) => {
      // getting prev and next icons
      icon.addEventListener("click", () => {
        // adding click event on both icons
        // if clicked icon is previous icon then decrement current month by 1 else increment it by 1
        currMonth = icon.id === "prev" ? currMonth - 1 : currMonth + 1;

        if (currMonth < 0 || currMonth > 11) {
          // if current month is less than 0 or greater than 11
          // creating a new date of current year & month and pass it as date value
          date = new Date(currYear, currMonth);
          currYear = date.getFullYear(); // updating current year with new date year
          currMonth = date.getMonth(); // updating current month with new date month
        } else {
          date = new Date(); // pass the current date as date value
        }
        renderCalendar(); // calling renderCalendar function
      });
    });
  }

  return (
    <Dropdown
      className="audit-compliance-dropdown highground-datpicker cyberessential-audit-dropdown"
      style={{
        pointerEvents:props.disabled?  "none" :"all"
      }}
      onToggle={(e) => {
        if (e) {
          setPage( props.screenType ?? "home");
          setFirstLoad(true);
          setTimeout(() => {
            drawCalender();
            getValuefromLiTag();
          }, 10);
        } else {
        }
      }}
    >
      <Dropdown.Toggle className="parent-dropdown-toggle pr-2">
        <div className={`${ (props.question.AnswerDate || props.question.AnswerText)   ? " f-black f-500"   :  "f-darkgrey"} d-flex align-items-center`}>

 
          {props.question.AnswerDate
            ? moment(handleDateForInvoice(props.question.AnswerDate),"DD/MM/YYYY").format(
                "LL"
              )
            : props.question.AnswerText
            ? props.question.AnswerText
            : `${props.placeholder ?? "Choose"}`}
        { !props.disabled && <img src="/images/big-chevron-down.svg" alt="" className="ml-2" />}{" "}
        </div>
      </Dropdown.Toggle>

   {!props.disabled &&   <Dropdown.Menu className="parent-dropdown-menu">
        {page === "home" && (
          <div className="p-3 pointer">
            {props.question.dropDowndata?.map((item, itemIndex) => {
              return (
                <div
                  className="d-flex align-items-center"
                  onClick={(e) => {
                    props.onChange(item.category, "text");
                    e.target.closest(".highground-datpicker").click()
                  }}
                >
                  <img
                    className="mr-2"
                    src="/images/msp/grey-tick.svg"
                    alt=""
                    style={{
                      visibility: `${
                        props.question.AnswerText === item.category
                          ? "visible"
                          : "hidden"
                      }`,
                    }}
                  />
                  <div className="f-grey">{item.category}</div>
                </div>
              );
            })}
            <hr />
            <div
              className="d-flex align-items-center justify-content-between"
              onClick={() => {
                setPage("calender");
              }}
            >
              <div className="d-flex align-items-center">
                <img
                  className="mr-2"
                  src="/images/msp/grey-tick.svg"
                  alt=""
                  style={{
                    visibility: `${
                      props.question.AnswerDate ? "visible" : "hidden"
                    }`,
                  }}
                />
                <div className="f-grey">Last Audit</div>
               
                <div className="f-darkgrey f-12 px-2">
                  {props.question.AnswerDate &&
                    moment(
                      handleDateForInvoice(props.question.AnswerDate),"DD/MM/YYYY"
                    ).format("LL")}
                </div>
              </div>
              <div className="d-flex align-items-center">
                <img
                  className=""
                  alt=""
                  src="/images/attack-surface/chevron-right.svg"
                />
              </div>
            </div>
          </div>
        )}

        {/* calender */}

        <div
          className={`wrapper ${page === "calender" ? "" : "d-none"}`}
          id={`hg-date-wrapper${props.id}`} 
        >
          <header className="d-flex align-items-center justify-content-between">
            <span className={`current-date ${props.id}`}></span>
            <div className="icons">
              <span
                id="prev"
                className="material-symbols-rounded d-flex align-items-center justify-content-center"
              >
                <img alt="" src="/images/chevron-left-cal.svg" />
              </span>
              <span
                id="next"
                className="material-symbols-rounded d-flex align-items-center justify-content-center"
              >
                <img alt="" src="/images/chevron-right-cal.svg" />
              </span>
            </div>
          </header>
          <div className="calendar">
            <ul className="weeks">
              <li>Su</li>
              <li>Mo</li>
              <li>Tu</li>
              <li>We</li>
              <li>Th</li>
              <li>Fr</li>
              <li>Sa</li>
            </ul>
            <ul className={`days ${props.id}`}></ul>
          </div>
          <hr className="m-0 hg-border" />
          <div
            className="f-grey pl-4 p-3  pointer w-fit-content"
            onClick={() => {
              setSelectedDate(moment().format("MM/DD/YYYY"));
              props.onChange("");
            }}
          >
            Clear
          </div>
        </div>
      </Dropdown.Menu>}
    </Dropdown>
  );
};

export default CyberComplianceDateDropdown;

export function getDateFormatForMoment(_data) {
  return _data
  // const inputDate = '12/04/2024';
  const parts = _data?.split('/');

  if(parts?.length >2){
    const year = parseInt(parts[2], 10);
    const month = parseInt(parts[1], 10) - 1;
    const day = parseInt(parts[0], 10);
    const date = new Date(year, month, day);
    const formattedDate = date.toISOString();
  
    return formattedDate
  }else{

    return _data
  }
 
}


export function addLeadingZero(number) {
  // Convert the input to a string
  var numString = number.toString();

  // Check if the string length is 1 (single-digit number)
  if (numString.length === 1) {
      // Add a leading zero
      return '0' + numString;
  }

  // If the input is already a two-digit number, return it as is
  return numString;
}