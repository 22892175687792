import React, {useState, useEffect} from 'react';
import { Form, Button} from "react-bootstrap";
import { PostInvestment } from '../../../Utils/commonApi';
import { useAuth } from '../../context/Auth';
import LoadingSpinner from '../../Spinner/Spinner';
import SuccessDialog from '../../Spinner/SuccessDialog';
import './AddInvestmentForm.scss';

const AddInvestmentForm = (props) => {

    //Parameters
    const objectId = props.objectId;
    const objectData = props.objectData;
    const onClickCallback = props.onClickCallback;
    const onClickLoadingStatusCallback = props.additionalHook;
    const ddlValues = props.dropdownLists;

    //Context(s)
    const {authTokens} = useAuth();

    //Values for dropdowns
    const investmentBillingType = SortDDLOrder(0);
    const investmentType = SortDDLOrder(1);
    const investmentTerm = SortDDLOrder(2);

    //Hashmaps containg ddlValues DDLText - Obj - {DDLText: DDLId}
    const investmentBillingHashMap = GenerateDDLTermHashMap(0);
    const investmentTypeHashMap = GenerateDDLTermHashMap(1);
    const investmentTermHashMap = GenerateDDLTermHashMap(2);

    //States for form values
    const [formPurchaseItem, setFormPurchaseItem] = useState();
    const [formVendor, setFormVendor] = useState();
    const [formDescription, setFormDescription] = useState();
    const [formType, setFormType] = useState(investmentType[0].DDLText);
    const [formBillingType, setFormBillingType] = useState(investmentBillingType[0].DDLText);
    const [formTerm, setFormTerm] = useState(investmentTerm[0].DDLText);
    const [formInvestmentValue, setFormInvestmentValue] = useState();
    const [formLoading, setFormLoading] = useState(0); //Three states: 0 = Default; 1 = Loading; 2 = Completed Loading;

    function setFormBillingTypeWithConditions(value){
        if(value === "Adhoc") setFormTerm("NA");
        setFormBillingType(value);
    }

    function SortDDLOrder(column){
        let newOrder = [];
        for (let i = 0; i < ddlValues[column].list.length; i++)
        {
            const element = ddlValues[column].list[i];
            if(element.DDLSort > newOrder.length)
                newOrder.push(element)
            else
                newOrder.splice(element.DDLSort-1, 0, element)
        }
        return newOrder;

    }
    function GenerateDDLTermHashMap(column)
    {
        var hashmap = {};
        ddlValues[column].list.map((element) => hashmap[element.DDLText] = element.DDLId)
        return hashmap;
    }
    async function SubmitForm(){
        if(!formInvestmentValue) return undefined;
        const formBody = {
            ObjectId: objectId,
            Purchase: formPurchaseItem,
            Vendor: formVendor,
            Description: formDescription,
            SavingTypeId: investmentTypeHashMap[formType],
            BillingTypeId: investmentBillingHashMap[formBillingType],
            TermId: investmentTermHashMap[formTerm],
            Investment: parseFloat(formInvestmentValue.replace(",", "").replace("£", ""))
             
        }
        //start spinner and grey out background
        setFormLoading(1)
        const result = await PostInvestment(authTokens, formBody, objectId, "insert");
        setTimeout(() => setFormLoading(2), 500);
        await onClickCallback();
        // setTimeout(() => setFormLoading(0), 700+200);
    }


    useEffect(() => {
        if(formLoading == 2 || formLoading == 0)
            onClickLoadingStatusCallback(formLoading);
    }, [formLoading]);
    useEffect(() => {
        if(objectData !== undefined)
        {
            setFormPurchaseItem(objectData[0].text)
            setFormVendor(objectData[1].text)
            setFormDescription(objectData[2].text)
            setFormType(objectData[3].text)
            setFormBillingType(objectData[4].text)
            setFormTerm(objectData[5].text)
            setFormInvestmentValue(objectData[6].text)
        }
    }, []);
    return (
        <div style={{ position: "relative" }}>
            {
                formLoading === 1 ?
                    <div style={{position: "absolute", width: "100%"}}>
                        <LoadingSpinner type="bootstrap" background={true} backgroundText={`${objectData ? "Updating..." : "Adding..."}`}/>
                    </div>
                :
                formLoading === 2 ?
                        <div style={{ position: "absolute", width: "100%" }}>
                            <SuccessDialog text={`Investment was ${objectData ? "updated" : "added"} successfully`} />
                        </div>
                :
                    ""
            }
            <Form className={`incidentResponseForm InvestmentForm ${formLoading == 0 ? "" : "blurBackground"}`}>
                <div className="flexFormInline top">
                    <Form.Group controlId="formPurchaseItem">
                        <Form.Label>Purchase Item</Form.Label>
                        <Form.Control type="text" defaultValue={formPurchaseItem} onChange={(value) => setFormPurchaseItem(value.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="formVendor">
                        <Form.Label>Vendor</Form.Label>
                        <Form.Control type="text" defaultValue={formVendor} onChange={(value) => setFormVendor(value.target.value)}/>
                    </Form.Group>
                    <Form.Group controlId="formDescription">
                        <Form.Label>Description</Form.Label>
                        <Form.Control type="text" defaultValue={formDescription} onChange={(value) => setFormDescription(value.target.value)}/>
                    </Form.Group>
                </div>
                <div className="flexFormInline bottom">
                    <Form.Group controlId="formType">
                        <Form.Label>Type</Form.Label>
                        <Form.Control as="select" className="transParentInput" value={formType} onChange={(value) => setFormType(value.target.value)}>
                            {investmentType.map((element, index) => <option key={index}>{element.DDLText}</option>)}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formBillingType">
                        <Form.Label>Billing Type</Form.Label>
                        <Form.Control as="select" className="transParentInput" value={formBillingType} onChange={(value) => setFormBillingTypeWithConditions(value.target.value)}>
                            {investmentBillingType.map((element, index) => <option value={element.DDLText} key={index}>{element.DDLText}</option>)}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formTerm">
                        <Form.Label>Term</Form.Label>
                        <Form.Control as="select" value={formTerm} onChange={(value) => setFormTerm(value.target.value)} className={`transParentInput ${formBillingType == "Adhoc" ? "disabled" : ""}`} disabled={formBillingType == "Adhoc"}>
                            {investmentTerm.map((element, index) => <option key={index}>{element.DDLText}</option>)}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formValue">
                        <Form.Label>Investment Value</Form.Label>
                        <Form.Control type="text" placeholder="£" defaultValue={formInvestmentValue} onChange={(value) => setFormInvestmentValue(value.target.value)}/>
                    </Form.Group>
                    <div style={{ display: "flex", alignSelf: "flex-end" }}>
                        <Button variant="primary" onClick={SubmitForm}>{objectData ? "Update" : "Add"}</Button>
                    </div>
                </div>

            </Form>
        </div>
    );
}

export default AddInvestmentForm;