import React, { useEffect, useState } from "react";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import "./supplyComplianceManager.scss";
import { TrimString } from "../../../Utils/StringTrimmer";
import ContentLoader from "react-content-loader";
import AttackSurfaceTooltip from "../../AttackSurface/AssetsList/AttackSurfaceTooltip";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { Button } from "react-bootstrap";

const SupplyRiskAssessment = ({ complianceSectionPageEnumData, goTo }) => {
  const { authTokens } = useAuth();
  const [pageData, setPageData] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getPageData(complianceSectionPageEnumData);
  }, [complianceSectionPageEnumData]);

  const pageEnumMapping = {
    400: "accessCE",
    401: "accessIRP",
    402: "accessRA",
    403: "accesssACR",
    404: "accessISP",
    405: "accessBCDR",
  };

  async function getPageData(complianceSectionPageEnumData) {
    await getAPIData(`${complianceSectionPageEnumData?.pageEnum}`, authTokens, {
      optionEnum1: 0,
      optionEnum2: complianceSectionPageEnumData?.supplierId,
      optionEnum3:
        complianceSectionPageEnumData?.[
          pageEnumMapping[complianceSectionPageEnumData?.pageEnum]
        ],
    })
      .then((response) => {
        setLoading(false);
        if (response?.mr?.Success) {
          setPageData(response);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }
  return (
    <div className="risk-assessment-wrapper w-100 pt-3">
      {loading ? (
        <ScreenLoader />
      ) : (
        <>
          {pageData?.DataList?.map((item, index) => {
            return (
              <div
                className="px-5 my-2 pointer"
                onClick={() => {
                  if (!item?.AllowDrillDown) return;
                  goTo(item?.Col1, "CMQuestion", {
                    pageEnum: 464, // 408,
                    accessLevel:
                      complianceSectionPageEnumData?.[
                        pageEnumMapping[complianceSectionPageEnumData?.pageEnum]
                      ],
                    entityId: item?.EntityId,
                  });
                }}
              >
                <div className="radius-16 hg-border w-100 p-4 grid-parent">
                  <div className="d-flex align-items-center">
                    <img
                      alt=""
                      className=""
                      src="/images/supply-chain/assessment-file-icon.svg"
                    />
                  </div>
                  <div>
                    <p className="f-12 f-500 f-darkgrey m-0">
                      Risk Assessment Date
                    </p>
                    <p className="f-black">{TrimString(item?.Col1, 150)} </p>
                  </div>
                  <div>
                    <p className="f-12 f-500 f-darkgrey m-0">Type</p>
                    <p className="f-black">{item?.Col2}</p>
                  </div>
                  <div>
                    <p className="f-12 f-500 f-darkgrey m-0">Expiry Date</p>
                    <p className="f-black">{item?.Col3}</p>
                  </div>
                  <div>
                    <p className="f-12 f-500 f-darkgrey m-0">Status</p>
                    <p className="f-green">{item?.Col4}</p>
                  </div>
                  <div className="d-flex align-items-center justify-content-end">
                    {item?.AllowDrillDown ? (
                      <img
                        alt=""
                        className="mr-3 pointer"
                        src="/images/horizontal-dots.svg"
                      />
                    ) : (
                      <AttackSurfaceTooltip
                        // topLeft
                        bottomLeft
                        content={
                          <span>
                            You dont have access to this data. To view more
                            details, update your access for this Supplier
                          </span>
                        }
                      >
                        <img
                          alt=""
                          className="mr-3 pointer"
                          src="/images/supply-chain/integrations-lock.svg"
                        />
                      </AttackSurfaceTooltip>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          {/* No Data State */}

          {(pageData?.DataList?.length === 0 || !pageData?.DataList) && (
            <div className="d-flex flex-column align-items-center justify-content-center mt-5">
              <div className="f-15 f-500 f-black">
                No Risk Assessments to show...
              </div>
              <div className="f-grey my-3">
                Perform your first Risk Assessment to get started
              </div>
              <div>
                <Button className="hg-cancel-btn">
                  Perform Risk Assessment
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SupplyRiskAssessment;

const ScreenLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 2000"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="5%" y="15" rx="16" ry="16" width="90%" height="100" />
      <rect x="5%" y="120" rx="16" ry="16" width="90%" height="100" />
      <rect x="5%" y="225" rx="16" ry="16" width="90%" height="100" />
      <rect x="5%" y="330" rx="16" ry="16" width="90%" height="100" />
      <rect x="5%" y="435" rx="16" ry="16" width="90%" height="100" />
    </ContentLoader>
  );
};
