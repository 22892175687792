import { ACTION_CENTRE } from "./constants";

/**
 * Increments in calculator value
 *
 * @param {Object} data
 */

export const setactioncentredata = (data) => {
  return { type: ACTION_CENTRE, data };
};
