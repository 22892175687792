import React from 'react';
import {FiCheck} from 'react-icons/fi';
import './Spinner.css';


const SuccessDialog = (props) => {
    const text = props.text;
    const containerWidth = props.ContWidth ? props.ContWidth : "100%"

    return (
        <div className="spinnerContainer" style={{width : containerWidth}}>
            <div className="fullHeight flexCenterAlign">
                <div className="spinnerBackground" style={{ background: "#40D88E"}}>
                    <FiCheck color="white" size={80}/>            
                    <p style={{color: "white", fontSize: "12px"}}>{text}</p>
                </div>
            </div>
        </div>
    );
}

export default SuccessDialog;