import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Tooltip, OverlayTrigger, Modal } from "react-bootstrap";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { Link } from "react-router-dom";
import ConnectToolModal from "../ConnectToolModal";
import "./integrationBlock.css";

const IntegrationBlock = (props) => {
  const { data, handleClick } = props;
  const { authTokens } = useAuth();
  const [integration, setIntegration] = useState(0);
  const [connectToolDetail, setConnectToolDetail] = useState(false);
  const [connectModalShow, setConnectModalShow] = useState(false);
  const [alertShow, setAlertShow] = useState({});

  async function callSettingsAPI(integrationId) {
    const result = await getAPIData("62", authTokens, {
      OptionEnum1: integrationId,
    });
    setIntegration(result);
  }

  async function getIntegrationStateDetail(id, button_function) {
    if (button_function != 0) {
      if (button_function == 7) {
        handleClick();
        return;
      }
      const response = await getAPIData("65", authTokens, {
        OptionEnum1: id,
        OptionEnum2: button_function,
      });
      if (button_function == 1 || button_function == 2) {
        setConnectToolDetail(response);
        setConnectModalShow(true);
      }
    }
  }

  const renderTooltip = (props) => (
    <Tooltip className="custom_tooltip" {...props}>
      <div className="custom_tooltip_wrapper">
        <div className="tooltip_header">
          <img alt="" className="mr-2" src={integration.TitleIconURL} />
          <span style={{ color: integration.TitleColour }}>
            {integration.Title}
          </span>
          <span
            className="tooltip_subtitle"
            style={{ color: integration.MessageColour }}
          >
            {integration.Message}
          </span>
        </div>
        <p className="m-0 mt-3 mb-1">Last Synced: {integration.LastSync}</p>
        <p className="m-0">Next Synced: {integration.NextSync}</p>
      </div>
    </Tooltip>
  );

  return (
    <Fragment>
      <Modal
        show={alertShow.Sucess == false}
        className="alert-modal alert-success right-alert"
        centered
      >
        <Modal.Body className="text-center">
          <img src="/images/icons/bigTickIcon.svg" alt="" />
          <p>{alertShow.Message}</p>
        </Modal.Body>
      </Modal>
      <Col xs={12}>
        <Row className="webFilteringBox integrationBox">
          <Col
            xs={12}
            md={8}
            lg={8}
            className="mx-auto my-auto"
            onClick={() => handleClick()}
          >
            <div>
              <h1 className="emailMainText">{data.TechnologyTitle}</h1>
              <div className="d-flex justify-content-between">
                <p className="link_textCol mb-0 ">
                  <Link
                    to={"#"}
                    className="d-flex justify-content-between align-items-center"
                  >
                    {data.IntegrationProgressIconURL && (
                      <img
                        alt=""
                        className="mr-3 icon-img"
                        height="22"
                        // src={`/images/TechnologyType/${data.IntegrationFileName}.svg`}
                        src={`/images/attack-surface/integrations/${data?.IntegrationId}.webp`}
                      />
                    )}

                    <span
                      className={`integrationsmalltxt ${
                        data.IntegrationProgressIconURL && "clrblack"
                      }`}
                    >
                      {data.IntegrationName}
                    </span>
                  </Link>
                </p>
                <p className="link_textCol mb-0">
                  <Link
                    onMouseEnter={() => callSettingsAPI(data.IntegrationId)}
                    to={"#"}
                  >
                    <OverlayTrigger
                      placement="bottom"
                      // show={showTooltip}
                      delay={{ show: 200, hide: 250 }}
                      overlay={renderTooltip}
                    >
                      <span className="d-flex justify-content-between align-items-center text-right">
                        <span
                          className="integrationsmalltxtstate"
                          style={{ color: data.IntegrationProgressColour }}
                        >
                          {data.IntegrationProgress}
                        </span>
                        <img
                          alt=""
                          className="ml-2"
                          src={data.IntegrationProgressIconURL}
                        />
                      </span>
                    </OverlayTrigger>
                  </Link>
                </p>
              </div>
            </div>
          </Col>
          <Col md={4} className="p-0">
            <div className="mx-auto my-auto" style={{ height: "100%" }}>
              <Col md={12} className="webfiltering_background">
                <div
                  className="d-flex w-100 h-100 justify-content-center"
                  onClick={() =>
                    getIntegrationStateDetail(
                      data.IntegrationId,
                      data.ButtonFunction
                    )
                  }
                >
                  <span style={{ paddingBottom: "0.5em" }} className="circle">
                    <img
                      alt=""
                      src={data.IntegrationStateIconURL}
                      style={{
                        background: data.IntegrationProgressColour + 20,
                      }}
                    />
                  </span>
                  <span style={{ paddingBottom: "0.5em" }}>
                    <h3
                      className="drilldownSideText"
                      style={{ color: data.IntegrationStateColour }}
                    >
                      {data.IntegrationState}
                    </h3>
                  </span>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
      </Col>
      {connectToolDetail && (
        <ConnectToolModal
          data={connectToolDetail}
          show={connectModalShow}
          onHide={() => setConnectModalShow(false)}
          handleIntegration={() => handleClick()}
        />
      )}
    </Fragment>
  );
};

export default IntegrationBlock;
