import React, { useState, useEffect, ReactDOM } from "react";
import { Button, InputGroup, Form, Alert, Spinner } from "react-bootstrap";
import { generateGravatar, getRequestOptions } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import "./ReusableForm.css";
import { BsCheckBox } from "react-icons/bs";
import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import LoadingSpinner from "../../Spinner/Spinner";
import history from "../../../history";

/*
    DOCUMENTATION
    ----------------------------------------------------------------
    The ReusableForm Component receives three parameters; Titles, formInputs and apiOptions - to create dynamically create forms.

    The contents of each parameter are:

    Titles:
    ----------------------------------------------------------
    mainTitle (Title of Modal)
    subTitle (Title of Form)
    buttonTitle (Title of Submit Button)
    ----------------------------------------------------------
    
    FormInputs:
    ----------------------------------------------------------
    - name (Parameter Name)
    - type (Dropdown, textarea, text, number etc)
    - values (Accepts an array of objects (Displayed below) - which allows for the text and colour of the item to be defined)
    ----------------------------------------------------------

    ApiOptions:
    ----------------------------------------------------------
    - method (API Method)
    - parameters (Array of each parameter)
    ----------------------------------------------------------
    */

/*
    INPUT DATA (PROPS) TEMPATE
    ----------------------------------------------------------------
    var Title = {
        mainTitle: "TESTING",
        subTitle: "TESTING2",
        buttonTitle: "TESTING3"
    }
    var Inputs = {
        0: {
            name: "field1",
            type: "dropdown",
            label: "testing",
            values: [
                [{text: "option 1", color: "green"}],
                [{text: "option 2", color: "red"}],
                [{text: "option 2", color: "blue"}],
            ]
            
        }
    }
    var apiOptions = {
        method: "postCurrentStatus"
        parameters: ["field1", "field2", "field3", "field4"]
}
*/

const ReusableForm = (props) => {
  console.log("REUSABLE FORM IS BEING CALLED", props);
  const [titles, setTitles] = useState(props.Titles || "");
  const [formInputs, setFormInputs] = useState(props.formInputs || "");
  const [apiOptions, setApiOptions] = useState(props.apiOptions || "");
  const [formValuesState, setFormValuesState] = useState([]);
  const [file, setFile] = useState(false);
  const [userDetailGravatars, setUserDetailGravatars] = useState(null);
  const { authTokens } = useAuth();
  const [alertDialog, setAlertDialog] = useState(false);
  const [formLoading, setformLoading] = useState(false);
  const onSubmit = props.onSubmit || undefined;
  const populated = props.populated;
  let containsFile = false;
  Object.keys(formInputs).map((element) => {
    if (formInputs[element].type == "file") containsFile = true;
  });

  useEffect(() => {
    //update states with inital value which will be displayed
    let newFormValuesState = formValuesState;
    Object.keys(formInputs).map((element, index) => {
      if (formInputs[element].values) {
        // newFormValuesState[element] = {}
        if (formInputs[element].type != "userSelect")
          newFormValuesState[[formInputs[element].name]] =
            formInputs[element].values[0][0].text;
        else {
          newFormValuesState[[formInputs[element].name]] =
            formInputs[element].values[0].FullName;
          generateGravatars(formInputs[element].values);
        }
      } else {
        newFormValuesState[[formInputs[element].name]] = populated
          ? formInputs[element].value
          : "";
      }
    });
    console.log(newFormValuesState);
    setFormValuesState(newFormValuesState);
  }, []);

  async function generateGravatars(userDetails) {
    let tempGravObj = {};
    for (let i = 0; i < Object.keys(userDetails).length; i++) {
      let element = userDetails[Object.keys(userDetails)[i]];
      if (!tempGravObj[element.FullName]) {
        console.log("element.Avatar.", element.Avatar);
        if (element.Avatar.indexOf("DefaultAvatar.png") >= 0) {
          tempGravObj[element.FullName] = "/images/logo/Favicon-m3.png";
        } else {
          const avatarExists = await generateGravatar(element.Avatar);
          if (avatarExists) tempGravObj[element.FullName] = element.Avatar;
          else tempGravObj[element.FullName] = "/images/logo/Favicon-m3.png";
        }
      }
    }
    setUserDetailGravatars(tempGravObj);
  }
  async function submitForm(event) {
    event.preventDefault();
    if (containsFile && !file) {
      setAlertDialog(
        <Alert variant={"danger"} onClose={() => setAlertDialog()} dismissible>
          Please upload a file
        </Alert>
      );
      return false;
    }
    setformLoading(true);
    console.log(formValuesState, "FVS");
    const parametersObject = {};
    apiOptions.parameters.map((element) => {
      if (element == "file") {
        return (parametersObject[element] = file);
      } else {
        return (parametersObject[element] = formValuesState[element]);
      }
    }); //Dynamically create parameters object
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/${apiOptions.method}`,
      // `https://hg-dev-ui-ac-api.azurewebsites.net/CC_API/${apiOptions.method}`,
      getRequestOptions(authTokens, "POST", parametersObject)
    ).then((response) => response.json());

    if (data.Message == "Authorization has been denied for this request.")
      window.location.href = "/logout";
    // history.push("/logout");

    setAlertDialog(
      <Alert
        variant={data.Success ? "success" : "danger"}
        onClose={() => setAlertDialog()}
        dismissible
      >
        {data.Message}
      </Alert>
    );

    if (data.Success) {
      Object.keys(formInputs).map((element) => {
        formInputs[element].value = "";
      });
      var tempFormInputs = Object.assign({}, formInputs);
      setFormInputs(tempFormInputs);
    }

    if (onSubmit) onSubmit();
    setformLoading(false);
  }

  function onChange(event, userDropdown = false, id = false, element) {
    let existingState = { ...formValuesState };
    let oldFormInputs = { ...formInputs };
    if (userDropdown) {
      existingState[[id]] = event;
    } else {
      existingState[[event.target.id]] = event.target.value;
      oldFormInputs[element].value = event.target.value;
    }
    setFormInputs(oldFormInputs);
    setFormValuesState(existingState);
  }

  function getMultiSelectDropdownValue(event) {
    const dropdownArray =
      event.target.parentNode.parentNode.getElementsByTagName("a");
    let selectedValue = event.target.getElementsByTagName("span")[0] || false; //Determines if an item has been added or deleted (FALSE EQUALS DELETED)

    if (!selectedValue) selectedValue = event.target.innerText;

    //Get existing values
    let stateVariableValue = [];
    for (let index = 0; index < dropdownArray.length; index++)
      stateVariableValue.push(dropdownArray[index].getAttribute("value"));

    if (selectedValue)
      stateVariableValue.push(
        typeof selectedValue == "object"
          ? selectedValue.textContent
          : selectedValue
      );
    else {
      const targetPosition = stateVariableValue.indexOf(
        event.target.parentNode.getAttribute("value")
      );
      stateVariableValue.splice(targetPosition, 1);
    }

    return stateVariableValue;
  }

  useEffect(() => {
    console.log("form inputs", formInputs);
  }, [formInputs]);

  // console.log("Form Values: " + formValuesState);

  return (
    <React.Fragment>
      {alertDialog}

      <div className="grayBackground roundedCorners modalContainer">
        <div>
          <h2 style={{ fontSize: "14px", paddingBottom: "1em" }}>
            {titles != "" ? titles.subTitle : ""}
          </h2>
          <Form
            id="myForm"
            className="incidentResponseForm reusableModalForm"
            onSubmit={(event) => submitForm(event)}
          >
            {formInputs != ""
              ? Object.keys(formInputs).map((element, index) => {
                  if (formInputs[element].type == "file") {
                    if (file) {
                      return (
                        <div className="fileUploadBox">
                          <input
                            type="file"
                            name="file"
                            id="file"
                            onChange={(event) => setFile(event.target.files[0])}
                          />
                          <BsCheckBox
                            style={{ fontSize: "4em", paddingBottom: ".2em" }}
                          />
                          <p>{file.name} has been selected</p>
                          <p>{formInputs[element].label}</p>
                          <p>
                            Drag and drop your file here or{" "}
                            <span style={{ color: "blue" }}>
                              choose a local file to overwrite the existing file
                            </span>
                          </p>
                        </div>
                      );
                    } else {
                      return (
                        <div className="fileUploadBox">
                          <input
                            type="file"
                            name="file"
                            id="file"
                            onChange={(event) => setFile(event.target.files[0])}
                          />
                          <img src="/images/folderImage.svg" alt="folder" />
                          <p>{formInputs[element].label}</p>
                          <p>
                            Drag and drop your file here or{" "}
                            <span style={{ color: "blue" }}>
                              choose a local file
                            </span>
                          </p>
                        </div>
                      );
                    }
                  } else if (formInputs[element].type == "select") {
                    return (
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            {formInputs[element].label}
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          id={formInputs[element].name}
                          as={formInputs[element].type}
                          onChange={(event) =>
                            onChange(
                              event,
                              false,
                              formInputs[element].name,
                              element
                            )
                          }
                          value={formInputs[element].value}
                          required
                        >
                          {formInputs[element].values.map(
                            (innerElement, innerIndex) => (
                              <option
                                style={
                                  innerElement[0].color
                                    ? { color: innerElement[0].color }
                                    : { color: "inherit" }
                                }
                              >
                                {innerElement[0].initials
                                  ? innerElement[0].text
                                  : innerElement[0].text}
                              </option>
                            )
                          )}
                        </Form.Control>
                      </InputGroup>
                    );
                  } else if (formInputs[element].type == "textarea") {
                    return (
                      <Form.Group>
                        <Form.Control
                          id={formInputs[element].name}
                          as="textarea"
                          rows={formInputs[element].rows || 7}
                          placeholder={formInputs[element].label}
                          value={formInputs[element].value}
                          onChange={(event) =>
                            onChange(
                              event,
                              false,
                              formInputs[element].name,
                              element
                            )
                          }
                          required
                        />
                      </Form.Group>
                    );
                  } else if (formInputs[element].type == "text") {
                    return (
                      <Form.Group>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              {formInputs[element].label}
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            id={formInputs[element].name}
                            required
                            onChange={(event) =>
                              onChange(
                                event,
                                false,
                                formInputs[element].name,
                                element
                              )
                            }
                            value={formInputs[element].value}
                          />
                        </InputGroup>
                      </Form.Group>
                    );
                  } else if (formInputs[element].type == "date") {
                    return (
                      <Form.Group>
                        <InputGroup className="inptDate">
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              {formInputs[element].label}
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            type="date"
                            id={formInputs[element].name}
                            required
                            value={formInputs[element].value}
                            onChange={(event) =>
                              onChange(
                                event,
                                false,
                                formInputs[element].name,
                                element
                              )
                            }
                          />
                        </InputGroup>
                      </Form.Group>
                    );
                  } else if (formInputs[element].type == "userSelect") {
                    if (!userDetailGravatars) {
                      return <LoadingSpinner />;
                    } else {
                      let dropdownOptions = formInputs[element].values.map(
                        (innerElement, index) => {
                          return {
                            key: index,
                            text: innerElement.FullName,
                            value: innerElement.FullName,
                            image: {
                              avatar: true,
                              src: userDetailGravatars[innerElement.FullName],
                            },
                          };
                        }
                      );
                      return (
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              {formInputs[element].label}
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            id={formInputs[element].name}
                            as={formInputs[element].type}
                            required
                          >
                            <Dropdown
                              fluid
                              selection
                              options={dropdownOptions}
                              onChange={(event) =>
                                onChange(
                                  getMultiSelectDropdownValue(event),
                                  "userSelect",
                                  formInputs[element].name
                                )
                              }
                              defaultValue={dropdownOptions[0].text}
                            />
                          </Form.Control>
                        </InputGroup>
                      );
                    }
                  }
                })
              : ""}

            <Button variant="primary" type="submit">
              {formLoading ? (
                <Spinner animation="border" />
              ) : titles != "" ? (
                titles.buttonTitle
              ) : (
                ""
              )}
            </Button>
          </Form>
          {/* Form End */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReusableForm;
