import React, { useState, useEffect } from "react";
import { Modal, Button, Col, Row, Dropdown } from "react-bootstrap";
import { useAuth } from "../../context/Auth";
import CompanySettingsDropdown from "../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import "./requestsModal.scss";
import { getAPIData, postData } from "../../../Utils/commonApi";
import AttackSurfaceTooltip from "../../AttackSurface/AssetsList/AttackSurfaceTooltip";
import Loader from "../../Common/loader";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { WhiteLoader } from "../../Theme/APILoaders";
import SupplyPopUp from "../SupplyPopUp/SupplyPopUp";
import { TrimString } from "../../../Utils/StringTrimmer";
import { addEventOnScroll } from "../../../Utils/CommonFunctions";

const ChangeAccessLevelModal = ({
  show,
  hideModal,
  supplierChainId,
  supplierId,
  refreshData,
}) => {
  const { authTokens } = useAuth();

  const [loading, setLoading] = useState(false);
  const [supplyChainId, setSupplyChainId] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [supplierLogo, setSupplierLogo] = useState("");
  // Access Level data
  const [accessLevelData, setAccessLevelData] = useState(dummyData);
  // makeGroup(dummyData.data, "Section")

  let customAccess = {
    IsDefault: true,
    Name: "Custom access",
    ObjectId: 0,
  };

  const [accessLevelList, setAccessLevelList] = useState([]);
  const [selectedAccessLevel, setSelectedAccessLevel] = useState(customAccess);

  // Status to handle client details screen UI
  const [showFooter, setShowFooter] = useState(false);

  const [processing, setProcessing] = useState(false);

  // Page status
  const [state, setState] = useState({
    newRequest: false,
    resendRequest: false,
    cancelRequest: false,
    viewRequest: false,
    dropdown: false,
    isClient: false,
  });
  const [page, setPage] = useState("client");
  function ResetState() {
    setShowFooter(false);

    setState({
      newRequest: false,
      resendRequest: false,
      cancelRequest: false,
      viewRequest: false,
      dropdown: false,
      isClient: false,
    });
  }
  useEffect(() => {
    if (show) {
      setLoading(true);
      getAccessData();

      getAccessLevels();
      setPage("client");
      ResetState();

      setProcessing(false);

      // getAccessLevels();
    }
  }, [show]);

  // Function to get Access Level Data:
  async function getAccessLevels() {
    await getAPIData(`${309}`, authTokens, {})
      .then((response) => {
        setLoading(false);
        if (response?.length > 0) {
          setAccessLevelList(response);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  async function getAccessData() {
    await getAPIData(`${346}`, authTokens, {
      OptionEnum1: supplierId,
      optionEnum2: 1,
    })
      .then((response) => {
        setLoading(false);
        if (response?.mr?.Success) {
          setSupplierLogo(response?.Obj_SupplierLogo);
          setSupplierName(response?.Obj_SupplierName);
          setSupplyChainId(response?.SupplyChainId ?? supplierChainId);
          if (
            response?.AccessLevelVM &&
            response?.AccessLevelVM?.AccessLevelSelection_List?.length > 0
          ) {
            setAccessLevelData(
              makeGroup(
                response?.AccessLevelVM?.AccessLevelSelection_List || [],
                "Section"
              )
            );
            if (
              response?.AccessLevelVM?.Name &&
              response?.AccessLevelVM?.ObjectId > 0
            ) {
              setSelectedAccessLevel({
                ObjectId: response?.AccessLevelVM?.ObjectId,
                Name: response?.AccessLevelVM?.Name,
              });
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  // Function to get Access Details
  async function getAccessDetails(accessLevelId) {
    await getAPIData(`${344}`, authTokens, {
      optionEnum1: supplierId,
      optionEnum2: accessLevelId,
    }).then((response) => {
      if (response?.mr?.Success) {
        if (response?.AccessLevelSelection_List) {
          setAccessLevelData(
            makeGroup(response?.AccessLevelSelection_List || [], "Section")
          );
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      }
    });
  }

  async function sendRequest() {
    setProcessing(true);
    let _accessLevelList = [];
    for (let i = 0; i < accessLevelData.length; i++) {
      _accessLevelList.push(...accessLevelData[i].data);
    }
    await postData(authTokens, "SendAccessChangeRequestToSupplier", {
      ObjectId: 0,
      ObjId_AccessLevel: selectedAccessLevel?.ObjectId,
      ObjId_Supplier: supplierId,
      AccessLevelSelection_List: JSON.stringify(_accessLevelList),
    })
      .then((response) => {
        setProcessing(false);
        if (response?.Success) {
          CallToast("Request sent successfully", false, false, "v2style");

          hideModal();
          if (refreshData) {
            refreshData();
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
          setProcessing(false);
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
        setProcessing(false);
      });
  }


   

  return (
    <Modal
      show={show}
      dialogClassName=" supplychain-requestsmodal"
      className=" "
      aria-labelledby=" supplychain-requestsmodal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Header className="text-center">
        <div className="w-100 mb-0 font-bold-14">
          {page === "client" &&
            supplierName &&
            `Update request for ${supplierName}`}
        </div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img alt="" src="/images/icons/close.svg" className="close-icon" />
        </span>
      </Modal.Header>
      <Modal.Body>
        {/* HOME PAGE  PAGE-1 */}
        {loading ? (
          <div className="d-flex align-items-center justify-content-center w-100 h-100">
            <Loader />
          </div>
        ) : (
          <>
            {/* CLIENT REQUEST PAGE-- 2 */}
            {page === "client" && (
              <div className="client-request-data">
                {/* Client name and logo wrapper */}
                <div className="d-flex align-items-center justify-content-between p-2">
                  <div className="d-flex align-items-center">
                    <img
                      alt=""
                      src={supplierLogo}
                      width={32}
                      height={32}
                      className="mr-2 rounded-full"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/images/no-image.png";
                      }}
                    />
                    <div>
                      <p className="m-0 f-500 f-black">{supplierName}</p>
                      <p className="m-0 f-14 f-grey">
                        Supply Chain ID:{supplyChainId}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className=" requestlist-dropdown"></div>
                  </div>
                </div>
                {/* Client Status with you */}
                <p className="f-500 p-2 pb-0">
                  Updated request for access to supplier{" "}
                  <strong>{supplierName}</strong>
                </p>

                {/* Complaince Profile dropdowns */}
                {true && (
                  <>
                    {/* Access Level dropdown */}
                    <div className="d-flex align-items-center justify-content-between  mx-2 mb-2">
                      <div className="d-flex align-items-center">
                        <div className="f-500 mr-2">Access Level</div>
                      </div>

                      <div className="compliance-filter-dropdow n user-detail-dropdown">
                        <Dropdown className="device-dropdown" onClick={addEventOnScroll}>
                          <Dropdown.Toggle>
                            <div className="d-flex align-items-center">
                              {selectedAccessLevel ? (
                                <div className="f-black f-500 mr-2">
                                  {TrimString(selectedAccessLevel?.Name,33)}
                                </div>
                              ) : (
                                <div className="f-grey mr-2">Choose...</div>
                              )}
                              <img
                                alt=""
                                className=""
                                src="/images/big-chevron-down.svg"
                              />
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className="hg-bg-white hide-scrollbar fade_on_scroll "
                            style={{
                              maxHeight: "225px",
                              // overflowY: "scroll",
                              overflow: "hidden",
                            }}
                          >
                            <div
                              className="hide-scrollbar position-relative black-blur-div"
                              style={{
                                maxHeight: "216px",
                                overflowY: "scroll",
                                overflowX: "hidden",
                              }}
                            >
                              <Row>
                                {accessLevelList?.map(
                                  (accessLevel, acessLevelIndex) => {
                                    return (
                                      <Col xs={12}>
                                        <div
                                          style={{ marginTop: "3px" }}
                                          className={`notification-btn bg-hover-grey-light ${
                                            accessLevel?.ObjectId ===
                                            selectedAccessLevel?.ObjectId
                                              ? "bg-grey-light"
                                              : ""
                                          }`}
                                          onClick={(e) => {
                                            setSelectedAccessLevel(accessLevel);
                                            getAccessDetails(
                                              accessLevel?.ObjectId
                                            );
                                            e.target
                                              .closest(".device-dropdown")
                                              .click();
                                          }}
                                        >
                                          {accessLevel?.Name}
                                        </div>
                                      </Col>
                                    );
                                  }
                                )}

                                <hr
                                  className=" line-divider-light "
                                  style={{
                                    width: "80%",
                                    margin: "auto",
                                    /* margin-left: auto; */
                                    marginTop: "12px",
                                    marginBottom: "8px",
                                  }}
                                />
                                <Col xs={12}>
                                  <div
                                    style={{
                                      marginBottom: "8px",
                                    }}
                                    className={`notification-btn bg-hover-grey-light ${
                                      selectedAccessLevel?.Name ===
                                      "Custom access"
                                        ? "bg-grey-light"
                                        : ""
                                    }`}
                                    onClick={(e) => {
                                      setSelectedAccessLevel(customAccess);
                                      e.target
                                        .closest(".device-dropdown")
                                        .click();
                                    }}
                                  >
                                    Custom access
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </>
                )}

                <hr color="#EBEDF1" />
                {/* General Permissions */}
                {accessLevelData?.map((data, dataIndex) => {
                  return (
                    <div className="general-permissions">
                      <div className="f-12 f-grey my-2 ml-2">
                        {data?.Section}
                      </div>

                      {data?.data?.map((row, rowIndex) => {
                        return (
                          <div className="d-flex align-items-center justify-content-between my-1 mx-2">
                            <div className="d-flex align-items-center">
                              <div className="f-500 mr-2">
                                {row?.SubSection}
                              </div>
                              <span className="pointer">
                                <SupplyPopUp
                                  header={row?.SubSection}
                                  dataList={row?.Permission_List?.map(
                                    (permission) => {
                                      return {
                                        title: permission?.Permission,
                                        subtitle: permission?.Rights,
                                      };
                                    }
                                  )}
                                >
                                  <img
                                    alt=""
                                    src="/images/supply-chain/grey-info.svg"
                                  />
                                </SupplyPopUp>
                              </span>
                            </div>

                            <div className={`compliance-filter-dropdown `}>
                              <CompanySettingsDropdown
                                fieldId={"ObjectId"}
                                fieldValue={"Permission"}
                                selected={row?.PermissionSelected}
                                placeholder="Choose..."
                                //  showid={true}
                                onClick={(id, val) => {
                                  let updated_data = [...accessLevelData];
                                  let selected_value = updated_data[dataIndex][
                                    "data"
                                  ][rowIndex].Permission_List.filter(
                                    (item) => item.ObjectId === id
                                  )[0];
                                  updated_data[dataIndex]["data"][
                                    rowIndex
                                  ].PermissionSelected = selected_value;
                                  setAccessLevelData(updated_data);
                                  setSelectedAccessLevel({ObjectId:"123",Name:"Custom Access Level"});
                                }}
                                data={row?.Permission_List}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </Modal.Body>

      <Modal.Footer className="p-2 d-flex align-items-center justify-content-end">
        <div className="d-flex justify-content-end">
          <Button className="hg-cancel-btn mr-2" onClick={hideModal}>
            Cancel
          </Button>

          <Button
            className="hg-blue-btn"
            onClick={() => {
              sendRequest();
            }}
          >
            <div className="d-flex align-items-center">
              {processing ? (
                <>
                  <WhiteLoader width={12} /> Requesting...
                </>
              ) : (
                "Request"
              )}
            </div>
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeAccessLevelModal;

function makeGroup(list, key) {
  if (list?.length === 0) return;
  console.log(list);
  let resultArr = [];
  let types = [];
  for (let i = 0; i < list.length; i++) {
    if (types.indexOf(list[i][key]) > -1) {
      let indexPosition = types.indexOf(list[i][key]);
      resultArr[indexPosition]["data"].push(list[i]);
      resultArr[indexPosition][key] = list[i][key];
    } else {
      types.push(list[i][key]);
      let indexPosition = types.indexOf(list[i][key]);
      if (typeof resultArr[indexPosition] === "object") {
        resultArr[indexPosition]["data"].push(list[i]);
        resultArr[indexPosition][key] = list[i][key];
      } else {
        resultArr[indexPosition] = {};
        resultArr[indexPosition]["data"] = [];
        resultArr[indexPosition]["data"].push(list[i]);
        resultArr[indexPosition][key] = list[i][key];
      }
    }
  }

  return resultArr;
}

const dummyData = [
  {
    data: [
      {
        Section: "General Permissions",
        SubSection: "Primary KPIs",
        Permission_List: [
          {
            ObjectId: 3346236,
            Level: 0,
            Permission: "No Access",
            Rights: "No Access",
          },
          {
            ObjectId: 3346237,
            Level: 1,
            Permission: "View KPI",
            Rights: "View KPI Value Only",
          },
          {
            ObjectId: 3346238,
            Level: 2,
            Permission: "View Drilldown Summary",
            Rights: "View KPI and Drilldown Summary",
          },
          {
            ObjectId: 3346239,
            Level: 3,
            Permission: "View Detailed Drilldown",
            Rights: "View KPI, Drilldown Summary and Drilldown Details",
          },
        ],
        PermissionSelected: null,
        mr: null,
      },
      {
        Section: "General Permissions",
        SubSection: "Role Based Cyber Data\t",
        Permission_List: [
          {
            ObjectId: 3346240,
            Level: 0,
            Permission: "No Access",
            Rights: "No Access",
          },
          {
            ObjectId: 3346241,
            Level: 1,
            Permission: "View KPI",
            Rights: "View KPI Value Only",
          },
          {
            ObjectId: 3346242,
            Level: 2,
            Permission: "View Drilldown Summary",
            Rights: "View KPI and Drilldown Summary",
          },
          {
            ObjectId: 3346244,
            Level: 1,
            Permission: "View Trend Data",
            Rights: "View Trend Data",
          },
        ],
        PermissionSelected: null,
        mr: null,
      },
      {
        Section: "General Permissions",
        SubSection: "Cyber Trend Data",
        Permission_List: [
          {
            ObjectId: 3346243,
            Level: 0,
            Permission: "No Access",
            Rights: "No Access",
          },
        ],
        PermissionSelected: null,
        mr: null,
      },
      {
        Section: "General Permissions",
        SubSection: "Technical Drilldown",
        Permission_List: [
          {
            ObjectId: 3346245,
            Level: 0,
            Permission: "No Access",
            Rights: "No Access",
          },
          {
            ObjectId: 3346246,
            Level: 1,
            Permission: "View Health",
            Rights: "View health of the data for every technology",
          },
          {
            ObjectId: 3346247,
            Level: 2,
            Permission: "View Tools",
            Rights:
              "View health of the data for every technology and details of the connected tool",
          },
        ],
        PermissionSelected: null,
        mr: null,
      },
      {
        Section: "General Permissions",
        SubSection: "Integrations",
        Permission_List: [
          {
            ObjectId: 3346248,
            Level: 0,
            Permission: "No Access",
            Rights: "No Access",
          },
          {
            ObjectId: 3346249,
            Level: 1,
            Permission: "View Health",
            Rights:
              "View health of every integration, inc. if a tool has been connected or not",
          },
          {
            ObjectId: 3346250,
            Level: 2,
            Permission: "View Tools",
            Rights:
              "View health of every integration, inc. if a tool has been connected or not, tool name, last synch date/time and sync health",
          },
        ],
        PermissionSelected: null,
        mr: null,
      },
    ],
    Section: "General Permissions",
  },
  {
    data: [
      {
        Section: "Compliance Manager",
        SubSection: "Cyber Essentials",
        Permission_List: [
          {
            ObjectId: 3346251,
            Level: 0,
            Permission: "No Access",
            Rights: "No Access",
          },
          {
            ObjectId: 3346252,
            Level: 1,
            Permission: "View Status",
            Rights: "View overall status of your Cyber Essentials",
          },
          {
            ObjectId: 3346253,
            Level: 2,
            Permission: "View Certificate",
            Rights:
              "View overall status of your Cyber Essentials and view/download a copy of your Certificate",
          },
          {
            ObjectId: 3346254,
            Level: 3,
            Permission: "View Audit Summary",
            Rights:
              "View overall status of your Cyber Essentials,  view/download a copy of your Certificate and view your summary details for your Cyber Essential Audits",
          },
          {
            ObjectId: 3346255,
            Level: 4,
            Permission: "View Audit Details",
            Rights:
              "View overall status of your Cyber Essentials, view/download a copy of your Certificate and view your full Cyber Essentials Audits",
          },
        ],
        PermissionSelected: null,
        mr: null,
      },
      {
        Section: "Compliance Manager",
        SubSection: "Incident Response",
        Permission_List: [
          {
            ObjectId: 3346256,
            Level: 0,
            Permission: "No Access",
            Rights: "No Access",
          },
          {
            ObjectId: 3346257,
            Level: 1,
            Permission: "View Status",
            Rights: "View overall status of your Incident Response",
          },
          {
            ObjectId: 3346258,
            Level: 2,
            Permission: "View IR Documents",
            Rights:
              "View overall status of your Incident Response and view/download a copy of your IR Documents",
          },
          {
            ObjectId: 3346259,
            Level: 3,
            Permission: "View Review Summary",
            Rights:
              "View overall status of your Incident Response, view/download a copy of your IR Documents and view summary details of your IR Reviews",
          },
          {
            ObjectId: 3346260,
            Level: 4,
            Permission: "View Review Details",
            Rights:
              "View overall status of your Incident Response, view/download a copy of your IR Documents and view your full IR Reviews",
          },
        ],
        PermissionSelected: null,
        mr: null,
      },
      {
        Section: "Compliance Manager",
        SubSection: "Information Security Policies",
        Permission_List: [
          {
            ObjectId: 3346261,
            Level: 0,
            Permission: "No Access",
            Rights: "No Access",
          },
          {
            ObjectId: 3346262,
            Level: 1,
            Permission: "View Status",
            Rights: "View overall status of your Information Security Policies",
          },
          {
            ObjectId: 3346263,
            Level: 2,
            Permission: "View Recommended + Cyber Essentials Policies Summary",
            Rights:
              "View overall status of your Information Security Policies and summary details of your Recommended and Cyber Essentials Policies",
          },
          {
            ObjectId: 3346264,
            Level: 3,
            Permission: "View Recommended + Cyber Essentials Policies",
            Rights:
              "View overall status of your Information Security Policies and view/download a copy of your Recommended and Cyber Essentials Policies",
          },
          {
            ObjectId: 3346265,
            Level: 4,
            Permission: "View All Policies Summary",
            Rights:
              "View overall status of your Information Security Policies and summary details of your Recommended, Cyber Essentials and 'My Policies' Policies",
          },
          {
            ObjectId: 3346266,
            Level: 5,
            Permission: "View All Policies",
            Rights:
              "View overall status of your Information Security Policies and view/download a copy of your Recommended, Cyber Essentials and 'My Policies' Policies",
          },
          {
            ObjectId: 3346267,
            Level: 0,
            Permission: "0",
            Rights: "0",
          },
        ],
        PermissionSelected: null,
        mr: null,
      },
      {
        Section: "Compliance Manager",
        SubSection: "Risk Assessments",
        Permission_List: [
          {
            ObjectId: 3346268,
            Level: 0,
            Permission: "No Access",
            Rights: "No Access",
          },
          {
            ObjectId: 3346269,
            Level: 1,
            Permission: "View Status",
            Rights: "View overall status of your Risk Assessments",
          },
          {
            ObjectId: 3346270,
            Level: 2,
            Permission: "View Assessment Summary",
            Rights:
              "View overall status of your Risk Assessments and summary details of your Risk Assessments",
          },
          {
            ObjectId: 3346271,
            Level: 3,
            Permission: "View Assessment Details",
            Rights:
              "View overall status of your Risk Assessments and your full Risk Assessments",
          },
        ],
        PermissionSelected: null,
        mr: null,
      },
      {
        Section: "Compliance Manager",
        SubSection: "Annual Cyber Reviews",
        Permission_List: [
          {
            ObjectId: 3346272,
            Level: 0,
            Permission: "No Access",
            Rights: "No Access",
          },
          {
            ObjectId: 3346273,
            Level: 1,
            Permission: "View Status",
            Rights: "View overall status of your Annual Cyber Reviews",
          },
          {
            ObjectId: 3346274,
            Level: 2,
            Permission: "View Assessment Summary",
            Rights:
              "View overall status of your Annual Cyber Reviews and summary details of your Annual Cyber Reviews",
          },
          {
            ObjectId: 3346275,
            Level: 3,
            Permission: "View Assessment Details",
            Rights:
              "View overall status of your Annual Cyber Reviewss and your full Annual Cyber Reviews",
          },
        ],
        PermissionSelected: null,
        mr: null,
      },
      {
        Section: "Compliance Manager",
        SubSection: "BCDR",
        Permission_List: [
          {
            ObjectId: 3346276,
            Level: 0,
            Permission: "No Access",
            Rights: "No Access",
          },
          {
            ObjectId: 3346277,
            Level: 1,
            Permission: "View Status",
            Rights: "View overall status of your IT BCDR Planning",
          },
          {
            ObjectId: 3346278,
            Level: 2,
            Permission: "View BCDR Plan Documents",
            Rights:
              "View overall status of your IT BCDR Planning and view/download a copy of your IT BCDR Plan. Note: your SOPs are not visible to the client",
          },
          {
            ObjectId: 3346279,
            Level: 3,
            Permission: "View BCDR Test Summary",
            Rights:
              "View overall status of your IT BCDR Planning, view/download a copy of your IT BCDR Plan and view summary details of your IT BCDR Tests",
          },
          {
            ObjectId: 3346280,
            Level: 4,
            Permission: "View BCDR Test Details",
            Rights:
              "View overall status of your IT BCDR Planning, view/download a copy of your IT BCDR Plan and view your full IT BCDR Tests",
          },
        ],
        PermissionSelected: null,
        mr: null,
      },
    ],
    Section: "Compliance Manager",
  },
  {
    data: [
      {
        Section: "Attack Surface",
        SubSection: null,
        Permission_List: [
          {
            ObjectId: 3346281,
            Level: 0,
            Permission: "No Access",
            Rights: "No Access",
          },
        ],
        PermissionSelected: null,
        mr: null,
      },
    ],
    Section: "Attack Surface",
  },
];
