import React, { useEffect, useState } from "react";
import { SecOpsCircularProgress } from "../SecOps";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getAPIData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import ContentLoader from "react-content-loader";

const OperationalSecurity = () => {
  const history = useHistory();
  const { authTokens } = useAuth();
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    setLoading(true);
    getPageData();
  }, []);

  async function getPageData() {
    await getAPIData("652", authTokens, { optionEnum1: 0 })
      .then((response) => {
        if (response?.mr?.Success) {
          setPageData(response);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        CallToast("Something went wrong", false, false, "v2style");
        setLoading(false);
      });
  }
  return (
    <>
      {loading ? (
        <div className="w-50 mx-auto">
          <OperationalSecurityLoader />
        </div>
      ) : (
        <div className="w-50 mx-auto">
          {/* MSP OPerational Security Block */}
          <div className="f-14 f-600 f-black mt-4 mb-2">
            MSP Operational Security
          </div>
          <div className="border-parent radius-8">
            {pageData?.SCMAllFormTemplate_List?.map((item, index) => {
              return (
                <div
                  className="d-flex flex-column border-item pointer"
                  onClick={() => {
                    history.push(
                      `/mspv2/secops/operational-security/security-assets?selectedSideBarTab=${item?.ObjectId}`
                    );
                  }}
                >
                  <div className="d-flex align-items-center justify-content-between w-100 pointer px-4  py-3 bg-hover-row">
                    <div
                      className="d-flex flex-column "
                      style={{ width: "75%" }}
                    >
                      <div className="d-flex align-items-baseline ">
                        <img
                          alt=""
                          src="/images/attack-surface/yellow-dot.svg"
                          className="mr-1"
                          style={{
                            visibility: `${
                              item?.ShowOrangeDot ? "visible" : "hidden"
                            }`,
                          }}
                        />
                        <p className="f-500 m-0 f-black">{item?.Title}</p>
                      </div>
                      <p className="f-12 f-darkgrey"
                      
                      style={{
                        marginLeft:"0.8rem"
                      }}
                      >
                      {item?.Description}
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="mr-5">
                        <SecOpsCircularProgress progress={Math.min(100,Number(item?.Percentage))} />
                      </div>
                      <img
                        alt=""
                        src="/images/attack-surface/chevron-right.svg"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="p-5" />
        </div>
      )}
    </>
  );
};

export default OperationalSecurity;

 


const OperationalSecurityLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0%" y="70" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="212" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="354" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="496" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="638" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="780" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="922" rx="8" ry="8" width="100%" height="140" />
    </ContentLoader>
  );
};