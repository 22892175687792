import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./companySettingsModals.scss";

const ChangeAddressModal = ({ show, hideModal, handleOnChange }) => {
  const companyDetails = useSelector((state) => state?.company?.companyDetail);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [county, setCounty] = useState("");
  const [postcode, setPostcode] = useState("");
  const [country, setCountry] = useState("");

  useEffect(() => {
    if (show) {
      setAddress1(companyDetails?.Address1 || "");
      setAddress2(companyDetails?.Address2 || "");
      setAddress3(companyDetails?.Address3 || "");
      setCounty(companyDetails?.County || "");
      setPostcode(companyDetails?.Postcode || "");
      setCountry(companyDetails?.Country);
    }
  }, [show]);

  return (
    <Modal
      // show={true}
      show={show}
      dialogClassName=" company-settings-address-modal"
      className=" "
      aria-labelledby=" company-settings-address-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Header className="text-center">
        <div className="w-100 mb-0 font-bold-14">Change Company Address</div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img src="/images/icons/close.svg" alt="" className="close-icon" />
        </span>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {/* <Col xs={12}>
            <div>
              <p className="label-heading m-0 mb-1">Country</p>
              <Form.Group controlId="formBasicSelect">
                <Form.Control
                  as="select"
                  value={country}
                  onChange={(e) => {
                    handleOnChange(e.target.value, "SOcountry");
                    setCountry(e.target.value);
                  }}
                >
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="Italy">Italy</option>
                  <option value="France">France</option>
                </Form.Control>
              </Form.Group>
            </div>
          </Col> */}
          {/* Address 1 */}
          <Col xs={12}>
            <div className="mt-3">
              <p className="label-heading m-0 mb-1">Address 1</p>
              <Form.Group controlId="formBasicInput">
                <Form.Control
                  as="input"
                  type="text"
                  placeholder="Enter address here"
                  value={address1}
                  onBlur={() => {
                    handleOnChange(address1, "SOAdd1");
                  }}
                  onChange={(e) => {
                    setAddress1(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
            </div>
          </Col>
          {/* Address 2 */}
          <Col xs={12}>
            <div className="mt-3">
              <p className="label-heading m-0 mb-1">Address 2</p>
              <Form.Group controlId="formBasicInput">
                <Form.Control
                  as="input"
                  type="text"
                  placeholder="Enter address here (Optional)"
                  value={address2}
                  onBlur={() => {
                    handleOnChange(address2, "SOAdd2");
                  }}
                  onChange={(e) => {
                    setAddress2(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
            </div>
          </Col>
          {/* Address 3 */}
          <Col xs={12}>
            <div className="mt-3">
              <p className="label-heading m-0 mb-1">Address 3</p>
              <Form.Group controlId="formBasicInput">
                <Form.Control
                  as="input"
                  placeholder="Enter address here (Optional)"
                  type="text"
                  value={address3}
                  onBlur={() => {
                    handleOnChange(address3, "SOAdd3");
                  }}
                  onChange={(e) => {
                    setAddress3(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
            </div>
          </Col>
          {/* County */}
          <Col xs={12}>
            <div className="mt-3">
              <p className="label-heading m-0 mb-1">County</p>
              <Form.Group controlId="formBasicInput">
                <Form.Control
                  as="input"
                  type="text"
                  placeholder="County"
                  value={county}
                  onBlur={() => {
                    handleOnChange(county, "SOcounty");
                  }}
                  onChange={(e) => {
                    setCounty(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
            </div>
          </Col>
          {/* Postcode */}
          <Col xs={12}>
            <div className="mt-3">
              <p className="label-heading m-0 mb-3">Postcode</p>
              <Form.Group controlId="formBasicInput">
                <Form.Control
                  as="input"
                  placeholder="Postcode"
                  type="text"
                  value={postcode}
                  onBlur={() => {
                    handleOnChange(postcode, "SOpostcode");
                  }}
                  onChange={(e) => {
                    setPostcode(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
            </div>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button className="cancel-btn" onClick={hideModal}>
            Cancel
          </Button>
          <Button className="save-btn" onClick={hideModal}>
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeAddressModal;
