import React, { useEffect, useState } from "react";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";

import "./supplycompany.scss";

const SupplyIntegrations = ({ accessLevel, supplierId }) => {
  const { authTokens } = useAuth();
  const [integrationsList, setIntegrationsList] = useState(dummyData);

  useEffect(() => {
    if (accessLevel > 0) {
      getPageData();
    }
  }, [accessLevel]);

  async function getPageData() {
    await getAPIData(`${426}`, authTokens, {
      optionEnum1: 0,
      optionEnum2: 0,
      optionEnum3: supplierId,
      optionEnum4: accessLevel,
    }).then((response) => {
      if (response?.mr?.Success) {
        setIntegrationsList(response?.IntegrationList);
      }
    });
  }

  return (
    <div className="d-flex flex-wrap supply-integrations-wrapper p-3">
      <div className="m-0 w-100  d-flex flex-wrap">
        {[...integrationsList].map((integration, integrationIndex) => {
          return (
            <div
              xs={6}
              className={` ${
                integration?.IntegrationId > 0 ? "hg-border" : ""
              } radius-8 p-2 d-flex justify-content-between align-items-center pointer `}
              style={{
                height: "102px",
                width: "49%",
                marginRight: "1%",
                marginBottom: "1%",
                border: `${
                  integration?.IntegrationId === 0 ? "1px dashed #EBEDF1" : ""
                }`,
              }}
            >
              <div className="d-flex flex-column ml-3 justify-content-between py-3 h-100">
                <div className="f-16 f-500">
                  {integration?.TechnologyTitle}{" "}
                </div>
                <div className="d-flex align-items-center">
                  {integration?.IntegrationId === 0 ? (
                    <span className="f-black f-12 d-inline-flex">
                      {integration?.IntegrationName}
                    </span>
                  ) : (
                    <>
                      <img
                        alt=""
                        className="mr-2 radius-2"
                        width={16}
                        height={16}
                        src={`/images/TechnologyType/${integration?.IntegrationFileName}.svg`}
                      />
                      <span className="f-black f-12 d-inline-flex">
                        {integration?.IntegrationName}
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className="d-flex h-100">
                <div className=" d-flex align-items-end">
                  {integration?.IntegrationId > 0 && (
                    <div className="d-flex align-items-center pb-3 mr-4">
                      <span
                        style={{
                          color: integration?.IntegrationProgressColour,
                        }}
                      >
                        {integration?.IntegrationProgress}
                      </span>
                      <img
                        alt=""
                        width={14}
                        height={14}
                        src={integration?.IntegrationProgressIconURL}
                        className="ml-2"
                      />
                    </div>
                  )}
                </div>
                <div
                  className="bg-grey radius-4 d-flex align-items-center justify-content-center p-2"
                  style={{
                    width: "164px",
                  }}
                >
                  {integration?.IntegrationId === 0 && false ? (
                    <div className="d-flex align-items-center">
                      <img
                        alt=""
                        src="/images/supply-chain/supply-integration-not-connected.svg"
                        className="mr-2"
                      />
                      <div className="f-darkgrey f-14 f-500">Not connected</div>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <img
                        alt=""
                        width={15}
                        height={15}
                        src={integration?.IntegrationStateIconURL}
                        className="mr-2"
                      />
                      <div
                        className="f-black f-14 f-500"
                        style={{
                          color: integration?.IntegrationStateColour,
                        }}
                      >
                        {integration?.IntegrationState}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SupplyIntegrations;

const dummyData = [
  {
    SortOrder: 0,
    IntegrationId: 0,
    TechnologyTitle: "Patch Management",
    IntegrationName: "Get started by conecting your Patch Management Tool",
    IntegrationURL: null,
    Integration_KB_URL: null,
    IntegrationFileName: "",
    IntegrationProgress: "",
    IntegrationProgressColour: "#4573D2",
    IntegrationProgressIconURL: "",
    IntegrationState: "Select Tool",
    IntegrationStateColour: "#4573D2",
    IntegrationStateIconURL:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/PlusSign.svg",
    LinkedToOrg: false,
    IntegrationAvailability: 0,
    ButtonFunction: 7,
  },
  {
    SortOrder: 1,
    IntegrationId: 0,
    TechnologyTitle: "Endpoint Protection",
    IntegrationName: "Get started by conecting your Endpoint Protection Tool",
    IntegrationURL: null,
    Integration_KB_URL: null,
    IntegrationFileName: "",
    IntegrationProgress: "",
    IntegrationProgressColour: "#4573D2",
    IntegrationProgressIconURL: "",
    IntegrationState: "Select Tool",
    IntegrationStateColour: "#4573D2",
    IntegrationStateIconURL:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/PlusSign.svg",
    LinkedToOrg: false,
    IntegrationAvailability: 0,
    ButtonFunction: 7,
  },
  {
    SortOrder: 2,
    IntegrationId: 0,
    TechnologyTitle: "Email Filtering",
    IntegrationName: "Get started by conecting your Email Filtering Tool",
    IntegrationURL: null,
    Integration_KB_URL: null,
    IntegrationFileName: "",
    IntegrationProgress: "",
    IntegrationProgressColour: "#4573D2",
    IntegrationProgressIconURL: "",
    IntegrationState: "Select Tool",
    IntegrationStateColour: "#4573D2",
    IntegrationStateIconURL:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/PlusSign.svg",
    LinkedToOrg: false,
    IntegrationAvailability: 0,
    ButtonFunction: 7,
  },
  {
    SortOrder: 3,
    IntegrationId: 0,
    TechnologyTitle: "SIEM IDS",
    IntegrationName: "Get started by conecting your SIEM IDS Tool",
    IntegrationURL: null,
    Integration_KB_URL: null,
    IntegrationFileName: "",
    IntegrationProgress: "",
    IntegrationProgressColour: "#4573D2",
    IntegrationProgressIconURL: "",
    IntegrationState: "Select Tool",
    IntegrationStateColour: "#4573D2",
    IntegrationStateIconURL:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/PlusSign.svg",
    LinkedToOrg: false,
    IntegrationAvailability: 0,
    ButtonFunction: 7,
  },
  {
    SortOrder: 4,
    IntegrationId: 0,
    TechnologyTitle: "Web Filtering",
    IntegrationName: "Get started by conecting your Web Filtering Tool",
    IntegrationURL: null,
    Integration_KB_URL: null,
    IntegrationFileName: "",
    IntegrationProgress: "",
    IntegrationProgressColour: "#4573D2",
    IntegrationProgressIconURL: "",
    IntegrationState: "Select Tool",
    IntegrationStateColour: "#4573D2",
    IntegrationStateIconURL:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/PlusSign.svg",
    LinkedToOrg: false,
    IntegrationAvailability: 0,
    ButtonFunction: 7,
  },
  {
    SortOrder: 5,
    IntegrationId: 0,
    TechnologyTitle: "Dark Web Monitoring",
    IntegrationName: "Get started by conecting your Dark Web Monitoring Tool",
    IntegrationURL: null,
    Integration_KB_URL: null,
    IntegrationFileName: "",
    IntegrationProgress: "",
    IntegrationProgressColour: "#4573D2",
    IntegrationProgressIconURL: "",
    IntegrationState: "Select Tool",
    IntegrationStateColour: "#4573D2",
    IntegrationStateIconURL:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/PlusSign.svg",
    LinkedToOrg: false,
    IntegrationAvailability: 0,
    ButtonFunction: 7,
  },
  {
    SortOrder: 6,
    IntegrationId: 0,
    TechnologyTitle: "Phishing Training",
    IntegrationName: "Get started by conecting your Phishing Training Tool",
    IntegrationURL: null,
    Integration_KB_URL: null,
    IntegrationFileName: "",
    IntegrationProgress: "",
    IntegrationProgressColour: "#4573D2",
    IntegrationProgressIconURL: "",
    IntegrationState: "Select Tool",
    IntegrationStateColour: "#4573D2",
    IntegrationStateIconURL:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/PlusSign.svg",
    LinkedToOrg: false,
    IntegrationAvailability: 0,
    ButtonFunction: 7,
  },
  {
    SortOrder: 7,
    IntegrationId: 0,
    TechnologyTitle: "Website Scan",
    IntegrationName: "Get started by conecting your Website Scan Tool",
    IntegrationURL: null,
    Integration_KB_URL: null,
    IntegrationFileName: "",
    IntegrationProgress: "",
    IntegrationProgressColour: "#4573D2",
    IntegrationProgressIconURL: "",
    IntegrationState: "Select Tool",
    IntegrationStateColour: "#4573D2",
    IntegrationStateIconURL:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/PlusSign.svg",
    LinkedToOrg: false,
    IntegrationAvailability: 0,
    ButtonFunction: 7,
  },
  {
    SortOrder: 8,
    IntegrationId: 0,
    TechnologyTitle: "External Network Scan",
    IntegrationName: "Get started by conecting your External Network Scan Tool",
    IntegrationURL: null,
    Integration_KB_URL: null,
    IntegrationFileName: "",
    IntegrationProgress: "",
    IntegrationProgressColour: "#4573D2",
    IntegrationProgressIconURL: "",
    IntegrationState: "Select Tool",
    IntegrationStateColour: "#4573D2",
    IntegrationStateIconURL:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/PlusSign.svg",
    LinkedToOrg: false,
    IntegrationAvailability: 0,
    ButtonFunction: 7,
  },
  {
    SortOrder: 9,
    IntegrationId: 0,
    TechnologyTitle: "Incident Response",
    IntegrationName: "Get started by conecting your Incident Response Tool",
    IntegrationURL: null,
    Integration_KB_URL: null,
    IntegrationFileName: "",
    IntegrationProgress: "",
    IntegrationProgressColour: "#4573D2",
    IntegrationProgressIconURL: "",
    IntegrationState: "Select Tool",
    IntegrationStateColour: "#4573D2",
    IntegrationStateIconURL:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/PlusSign.svg",
    LinkedToOrg: false,
    IntegrationAvailability: 0,
    ButtonFunction: 7,
  },
  {
    SortOrder: 18,
    IntegrationId: 0,
    TechnologyTitle: "Task Management",
    IntegrationName: "Get started by conecting your Task Management Tool",
    IntegrationURL: null,
    Integration_KB_URL: null,
    IntegrationFileName: "",
    IntegrationProgress: "",
    IntegrationProgressColour: "#4573D2",
    IntegrationProgressIconURL: "",
    IntegrationState: "Select Tool",
    IntegrationStateColour: "#4573D2",
    IntegrationStateIconURL:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/PlusSign.svg",
    LinkedToOrg: false,
    IntegrationAvailability: 0,
    ButtonFunction: 7,
  },
  {
    SortOrder: 19,
    IntegrationId: 0,
    TechnologyTitle: "Vulnerability Management",
    IntegrationName:
      "Get started by conecting your Vulnerability Management Tool",
    IntegrationURL: null,
    Integration_KB_URL: null,
    IntegrationFileName: "",
    IntegrationProgress: "",
    IntegrationProgressColour: "#4573D2",
    IntegrationProgressIconURL: "",
    IntegrationState: "Select Tool",
    IntegrationStateColour: "#4573D2",
    IntegrationStateIconURL:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/PlusSign.svg",
    LinkedToOrg: false,
    IntegrationAvailability: 0,
    ButtonFunction: 7,
  },
  {
    SortOrder: 23,
    IntegrationId: 0,
    TechnologyTitle: "Other",
    IntegrationName: "Get started by conecting your Other Tool",
    IntegrationURL: null,
    Integration_KB_URL: null,
    IntegrationFileName: "",
    IntegrationProgress: "",
    IntegrationProgressColour: "#4573D2",
    IntegrationProgressIconURL: "",
    IntegrationState: "Select Tool",
    IntegrationStateColour: "#4573D2",
    IntegrationStateIconURL:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/PlusSign.svg",
    LinkedToOrg: false,
    IntegrationAvailability: 0,
    ButtonFunction: 7,
  },
];
