import React, { useState, useEffect, lazy, Suspense } from "react";
import { Modal, Button, Col, Row, Dropdown } from "react-bootstrap";
import { useAuth } from "../../context/Auth";
import CompanySettingsDropdown from "../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import "./requestsModal.scss";
import { getAPIData, postData } from "../../../Utils/commonApi";
import AttackSurfaceTooltip from "../../AttackSurface/AssetsList/AttackSurfaceTooltip";
import Loader from "../../Common/loader";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { GreyLoader, WhiteLoader } from "../../Theme/APILoaders";

import SupplyPopUp from "../SupplyPopUp/SupplyPopUp";
import capitalize from "../../../Utils/capitalizeWord";
// import AddComplianceProfileModal from "../AddComplianceProfileModal/AddComplianceProfileModal";
import AccessLevelModal from "../AccessLevelModal/AccessLevelModal";
import { TrimString } from "../../../Utils/StringTrimmer";
import { addEventOnScroll } from "../../../Utils/CommonFunctions";
const AddComplianceProfileModal = lazy(() => import("../AddComplianceProfileModal/AddComplianceProfileModal"));

const ClientsModal = ({ show, hideModal, refreshData }) => {
  const { authTokens } = useAuth();

  const [supplyChainId, setSupplyChainId] = useState("");
  const [clientRequestCount, setClientRequestCount] = useState(0);

  const [clientRequests, setClientRequests] = useState([]);
  const [showAccessLevelModal, setShowAccessLevelModal] = useState(false);
  const [showAddComplianceModal, setShowAddComplianceModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [relationShipID, setRelationShipID] = useState('')
  // Supplier Details
  const [supplierName, setSupplierName] = useState("");
  const [supplierLogo, setSupplierLogo] = useState("");
  const [supplierSupplyChainId, setSupplierSupplyChainId] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [requestDetails, setRequestDetails] = useState({
    Id: "",
    status: "",
    relationId: "",
  });
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");

  // Access Level data
  const [accessLevelData, setAccessLevelData] = useState([]);

  const [processing, setProcessing] = useState(false);
  const [denying, setDenying] = useState(false);
  const [relIdRequest, setRelIdRequest] = useState("");
  // Context Menu and Button Visbility
  const [contextMenuData, setContextMenuData] = useState({});
  const [complianceProfileList, setComplianceProfileList] = useState([]);
  const [selectedComplianceProfile, setSelectedComplianceProfile] = useState();
  const [accessLevelList, setAccessLevelList] = useState([]);
  const [selectedAccessLevel, setSelectedAccessLevel] = useState();
  const [responseModalText, setResponseModalText] = useState({
    Title: "",
    ResponseGreyText: "",
  });

  const [cancelType, setCancelType] = useState("");

  const [cancelRequestStatus, setCancelRequestStatus] = useState();

  // Page status
  const [state, setState] = useState({
    isSuspendedClient: false,
    isNewClient: false,
  });
  const [page, setPage] = useState("homepage");
  function ResetState() {
    setState({
      isSuspendedClient: false,
      isNewClient: false,
    });
    setMessage("");
  }
  useEffect(() => {
    if (show) {
      setLoading(true);
      getModalData();
      setPage("homepage");
      ResetState();

      setProcessing(false);
      setEmployee({
        id: 0,
        category: "All clients",
      });

      // getAccessLevels();
    }
  }, [show]);

  async function getModalData(clientFilter = 0) {
    await getAPIData("342", authTokens, {
      optionEnum1: clientFilter,
      optionEnum2: 0,
      optionEnum3: 1,
    }).then((response) => {
      setLoading(false);
      if (response?.mr?.Success) {
        setSupplyChainId(response?.SupplyChainId);
        setClientRequestCount(response?.ClientRequestCount);
        setClientRequests(
          response?.ClientRequests?.filter(
            (item) => item?.ContextMenu?.requestList_ItemVisible === true
          ) ?? []
        );
      }
    });
  }

  // Function to get Access Level Data:STEP-4
  async function getAccessLevels() {
    await getAPIData(`${309}`, authTokens, {}).then((response) => {
      if (response?.length > 0) {
        setAccessLevelList(response);
        setLoading(false);
      }
    });
  }
  async function getComplianceProfiles(supplierID) {
    await getAPIData(`${314}`, authTokens, {
      optionEnum1: supplierID,
    })
      .then((response) => {
        if (response?.mr?.Success) {
          setComplianceProfileList(response?.Profiles);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // Pge list === homepage , clientlist , client

  const [employee, setEmployee] = useState({
    id: 0,
    category: "All clients",
  });

  // Function to get Request Details for already existing Supplier:STEP-1
  async function getRequestDetails(Id, status, relation) {
    setLoading(true);
    await getAPIData("346", authTokens, {
      optionEnum1: Id,
      optionEnum2: status,
      optionEnum3: relation,
    }).then((response) => {
      if (response?.mr?.Success) {
        // createSupplierRequest(supplierId);
        setSupplierName(response?.Obj_SupplierName);
        setSupplierLogo(response?.Obj_SupplierLogo);
        setSupplierSupplyChainId(response?.SupplyChainId);
        // setSupplierId(response?.ObjId_Supplier)
        setContextMenuData(response?.ContextMenu);
        setRejectionReason(response?.RequestResponseMessage);
          setResponseModalText(response?.ContextMenu?.ResponseModalText ?? {
            Title: "",
            ResponseGreyText: "",
          });
       
        if (response?.AccessLevelVM) {
          setAccessLevelData(
            makeGroup(
              response?.AccessLevelVM?.AccessLevelSelection_List || [],
              "Section"
            )
          );
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      } else {
        setPage("homepage");
        CallToast(response?.mr?.Message ??  "Something went wrong", false, false, "v2style");
      }
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  }

  // Update status function
  // Not_Sent, //-------0
  //       Sent,//-------1
  //       Denied,//-------2
  //       Removed_By_Client,//-------3
  //       Removed_By_Supplier,//-------4
  //       Suspended_By_Client,//-------5,
  //       Suspended_By_Supplier,//----6
  //       Approved_By_Supplier,//----7
  //       Cancelled_By_Client,//----8
  async function updateStatus(status,relationshipId) {
    await getAPIData("345", authTokens, {
      optionEnum1: supplierId,
      optionEnum2: status,
      optionEnum3:relationshipId
    })
      .then((response) => {
        setProcessing(false);
        if (response?.Success) {
          hideModal();
          CallToast("Updated successfully", false, false, "v2style");
        }
      })
      .catch(() => {
        setProcessing(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // Removed_By_Supplier,//-------4

  // Suspended_By_Supplier,//----6

  async function changeStatus(status) {
    postData(authTokens, "SendResponseToSupplier", {
      RequestStatus: status,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      Response: message,
      ObjId_Supplier: supplierId,
    })
      .then((response) => {
        setProcessing(false);
        setDenying(false);
        if (response?.Success) {
          CallToast("Updated successfully", false, false, "v2style");
          hideModal();
        }
      })
      .catch(() => {
        setProcessing(false);
        setDenying(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }
  // function to Update compliance profile
  async function updateComplianceProfile(complianceId) {
    await getAPIData(`${343}`, authTokens, {
      optionEnum1: supplierId,
      optionEnum2: complianceId,
    }).then((response) => {
      if (response?.Success) {
        // CallToast(
        //   "Compliance profile updated successfully",
        //   false,
        //   false,
        //   "v2style"
        // );
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  // Function to get Access Details
  async function getAccessDetails(accessLevelId) {
    await getAPIData(`${344}`, authTokens, {
      optionEnum1: supplierId,
      optionEnum2: accessLevelId,
    }).then((response) => {
      if (response?.mr?.Success) {
        if (response?.AccessLevelSelection_List) {
          setAccessLevelData(
            makeGroup(response?.AccessLevelSelection_List || [], "Section")
          );
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      }
    });
  }

  async function sendRequest() {
    let _accessLevelList = [];
    for (let i = 0; i < accessLevelData.length; i++) {
      _accessLevelList.push(...accessLevelData[i].data);
    }
    await postData(authTokens, "SendRequestToSupplier", {
      ObjectId: 0,
      ObjId_AccessLevel: selectedAccessLevel?.ObjectId,
      ObjId_Supplier: supplierId,
      AccessLevelSelection_List: JSON.stringify(_accessLevelList),
    })
      .then((response) => {
        setProcessing(false);
        if (response?.Success) {
          CallToast("Request sent successfully", false, false, "v2style");
          // if (refreshData) {
          //   refreshData();
          // }

          hideModal();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
          setProcessing(false);
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
        setProcessing(false);
      });
  }
  function moveTocancelRequestScreen(client, status) {
    setSupplierLogo(client?.Obj_CustomerLogo);
    setSupplierId(client?.ObjId_Customer);
    setSupplierName(client?.Obj_CustomerName);
    setSupplierSupplyChainId(client?.SupplyChainId);
    if (status === 6) {
      setCancelType("suspend");
      setPage("cancelRequest");
      setCancelRequestStatus(6);
    }
    if (status === 4) {
      setCancelType("remove");
      setPage("cancelRequest");
      setCancelRequestStatus(4);
    }
  }
  // Not_Sent, //-------0
  // Sent,//-------1
  // Denied,//-------2
  // Removed_By_Client,//-------3
  // Removed_By_Supplier,//-------4
  // Suspended_By_Client,//-------5,
  // Suspended_By_Supplier,//----6
  // Approved_By_Supplier,//----7
  // Cancelled_By_Client,//----8
  async function cancelRequest() {
    setProcessing(true);
    postData(authTokens, "SendResponseToSupplier", {
      RequestStatus: cancelRequestStatus,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      Response: message,
      ObjId_Supplier: supplierId,
    })
      .then((response) => {
        setProcessing(false);
        if (response?.Success) {
          CallToast("Updated successfully", false, false, "v2style");
          hideModal();
          if (refreshData) refreshData();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }



 
  return (
    <Modal
      show={show}
      dialogClassName=" supplychain-requestsmodal"
      className=" "
      aria-labelledby=" supplychain-requestsmodal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Header className="text-center">
        {page !== "homepage" && (
          <img
            alt=""
            src="/images/supply-chain/chevron-left.svg"
            className="mt-2 pointer"
            onClick={() => {
              setPage("homepage");
              ResetState();
            }}
          />
        )}
        <div className="w-100 mb-0 font-bold-14">
          {page === "homepage" && "Clients"}
          {page === "client" && supplierName}

          {page === "cancelRequest" &&
            supplierName &&
            `${capitalize(cancelType)} ${supplierName}`}
        </div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img alt="" src="/images/icons/close.svg" className="close-icon" />
        </span>
      </Modal.Header>
      <Modal.Body>
        {/* HOME PAGE  PAGE-1 */}
        {loading ? (
          <div className="d-flex align-items-center justify-content-center w-100 h-100">
            <Loader />
          </div>
        ) : (
          <>
            {page === "homepage" && (
              <div className="homepage ">
                {/* Access-data message wrapper */}

                {clientRequestCount === 0 && (
                  <div className="d-flex flex-column justify-content-center align-items-center w-75 mt-5 mx-auto">
                    <img
                      alt=""
                      src="/images/supply-chain/no-supplier-icon.svg"
                      className="no-supplier-icon mb-3"
                    />
                    <div className="f-grey f-500 mb-2">No Clients</div>
                    <p className="f-darkgrey text-center" onClick={() => {}}>
                      You dont have any clients yet. To add your first client
                      share your Supply Chain ID: {supplyChainId} with clients
                    </p>
                  </div>
                )}

                {clientRequestCount > 0 && (
                  <>
                    <div
                      className="access-data-wrapper d-flex align-items-center py-2 pointer"
                      onClick={() => {}}
                    >
                      <div className="image-group">
                        {clientRequests?.slice(0, 3)?.map((client) => {
                          return (
                            <img
                              alt=""
                              src={client?.Obj_CustomerLogo}
                              width={24}
                              style={{ border: "1px solid white" }}
                              height={24}
                              className="rounded-full"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =
                                  "/images/settings-v2/highground-dp.svg";
                              }}
                            />
                          );
                        })}
                      </div>
                      <div>
                        <span className="f-blue f-500 ml-2">
                          {clientRequests?.length} client
                          {clientRequests?.length > 1 && "s"} requested
                        </span>
                        &nbsp;
                        <span className="f-500">
                          access to your HighGround data
                        </span>
                        <img
                          alt=""
                          src="/images/chevron-right.svg"
                          className="ml-2"
                        />
                      </div>
                    </div>
                    {/* Filter for clients */}
                    <div className="sent-requests-header d-flex align-items-center justify-content-between mt-3 mb-2">
                      <div className="f-12 f-grey ml-3">Your clients</div>
                      <div className=" compliance-filter-dropdown">
                        <CompanySettingsDropdown
                          fieldId={"id"}
                          fieldValue={"category"}
                          selected={employee}
                          placeholder="Choose..."
                          //  showid={true}
                          onClick={(id, val) => {
                            setEmployee({ id, category: val });
                            getModalData(id);
                          }}
                          data={[
                            { id: 0, category: "All clients" },
                            { id: 1, category: "Sent" },
                            { id: 2, category: "Denied" },
                            { id: 3, category: "Active" },
                            { id: 4, category: "Suspended" },
                            // { id: 5, category: "Removed" },
                          ]}
                        />
                      </div>
                    </div>
                    {/* New requests list wrapper */}
                    <div className="new-requests-wrapper">
                      {clientRequests?.length === 0 && (
                        <div className="d-flex flex-column justify-content-center align-items-center w-50 mt-5 mx-auto">
                          <img
                            alt=""
                            src="/images/supply-chain/no-supplier-icon.svg"
                            className="no-supplier-icon mb-3"
                          />
                          <div className="banner-element-name mb-2">
                            No Clients
                          </div>
                          <p
                            className="banner-subtitle text-center"
                            onClick={() => {}}
                          >
                            {/* You haven't added any suppliers yet. To add suppliers,
                      send them a <br /> request to access their HighGround data */}
                          </p>
                        </div>
                      )}
                      {clientRequests?.length > 0 &&
                        clientRequests?.map((request) => {
                          return (
                            <div
                              className={`${
                                request?.ContextMenu?.requestList_ItemVisible
                                  ? "d-flex"
                                  : "d-none"
                              } justify-content-between align-items-center  pointer request-item`}
                              onClick={() => {
                                setPage("client");
                                setRelationShipID(request?.relId_Request)
                                getRequestDetails(
                                  request?.ObjId_Customer,
                                  -99,
                                  request?.relId_Request
                                );

                                setSupplierId(request?.ObjId_Customer);
                                setRequestDetails({
                                  Id: request?.ObjId_Customer,
                                  status:
                                   -99,
                                  relationId:
                                    request?.relId_Request,
                                });

                                getAccessLevels();
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt=""
                                  src={request?.Obj_CustomerLogo}
                                  width={32}
                                  height={32}
                                  className="mr-2 rounded-full"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "/images/no-image.png";
                                  }}
                                />
                                <div className="f-500 f-black">
                                  {request?.Obj_CustomerName}
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="f-grey mr-2">
                                  {request?.ContextMenu?.contextMenuLabel}
                                </div>
                                <div
                                  className={"mr-2 requestlist-dropdown"}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <Dropdown
                                    className={`device-dropdown ${
                                      request?.ContextMenu?.ContextMenuItems
                                        ?.length > 0
                                        ? ""
                                        : "d-none"
                                    }`}
                                  >
                                    <Dropdown.Toggle>
                                      <img
                                        alt=""
                                        src="/images/supply-chain/horizontal-dots.svg"
                                      />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <div>
                                        <Row>
                                          {request?.ContextMenu?.ContextMenuItems?.map(
                                            (menuItem, menuItemIndex) => {
                                              return (
                                                <Col
                                                  xs={12}
                                                  className=" pointer"
                                                >
                                                  <div
                                                    className="drop-item"
                                                    onClick={(e) => {
                                                      setRelationShipID(request?.relId_Request)
                                                      setRequestDetails({
                                                        Id: request?.ObjId_Customer,
                                                        status:
                                                          menuItem?.newRequestStatus_Id,
                                                        relationId:
                                                          request?.relId_Request,
                                                      });
                                                      if (
                                                        menuItem?.newRequestStatus_Id ===
                                                          4 ||
                                                        menuItem?.newRequestStatus_Id ===
                                                          6
                                                      ) {
                                                        getRequestDetails(
                                                          request?.ObjId_Customer,
                                                          menuItem?.newRequestStatus_Id,
                                                          request?.relId_Request
                                                        );
                                                        moveTocancelRequestScreen(
                                                          request,
                                                          menuItem?.newRequestStatus_Id
                                                        );
                                                        return;
                                                      }
                                                      getRequestDetails(
                                                        request?.ObjId_Customer,
                                                        menuItem?.newRequestStatus_Id,
                                                        request?.relId_Request
                                                      );
                                                      setSupplierId(
                                                        request?.ObjId_Customer
                                                      );
                                                      getAccessLevels();
                                                      e.target
                                                        .closest(
                                                          ".device-dropdown"
                                                        )
                                                        .click();

                                                      setPage("client");
                                                    }}
                                                  >
                                                    {menuItem?.menuActionLabel}
                                                  </div>
                                                </Col>
                                              );
                                            }
                                          )}
                                        </Row>
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </>
                )}
              </div>
            )}
            {/* CLIENT REQUEST PAGE-- 2 */}
            {page === "client" && (
              <div className="client-request-data">
                {/* verify client alert */}
                <div className="verify-client-alert py-2 px-3 d-none">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="f-500 f-black d-flex align-items-center">
                      <img
                        alt=""
                        src="/images/supply-chain/alert-icon.svg"
                        className="mr-2"
                      />
                      <span className="pt-1">
                        Verify this clients Supply Chain ID: 182-773-628
                      </span>
                    </div>
                    <img
                      alt=""
                      src="/images/supply-chain/tiny-cross.svg"
                      className="pointer"
                    />
                  </div>
                  <p className="f-grey">
                    You should verify with your client outside of HighGround
                    that this is their HIghGround Supply Chain ID and that they
                    submitted this request before approving this.
                  </p>
                </div>
                {/* Client name and logo wrapper */}
                <div className="d-flex align-items-center justify-content-between p-2">
                  <div className="d-flex align-items-center">
                    <img
                      alt=""
                      src={supplierLogo}
                      width={32}
                      height={32}
                      className="mr-2 rounded-full"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/images/no-image.png";
                      }}
                    />
                    <div>
                      <p className="m-0 f-500 f-black">{supplierName}</p>
                      <p className="m-0 f-14 f-grey">
                        Supply Chain ID:{supplierSupplyChainId}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div
                      className={`requestlist-dropdown ${
                        contextMenuData?.ContextMenuItems?.length > 0
                          ? ""
                          : "d-none"
                      }`}
                    >
                      <Dropdown className="device-dropdown">
                        <Dropdown.Toggle>
                          <img
                            alt=""
                            src="/images/supply-chain/horizontal-dots.svg"
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div>
                            <Row>
                              {contextMenuData?.ContextMenuItems?.map(
                                (menuItem, menuItemIndex) => {
                                  return (
                                    <Col xs={12} className="  pointer">
                                      <div
                                        className="drop-item"
                                        onClick={(e) => {
                                          updateStatus(
                                            menuItem?.newRequestStatus_Id,
                                            relationShipID
                                          );
                                          e.target
                                            .closest(".device-dropdown")
                                            .click();
                                        }}
                                      >
                                        {menuItem?.menuActionLabel}
                                      </div>
                                    </Col>
                                  );
                                }
                              )}
                            </Row>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                {/* Client Status with you */}
                <p className="f-500 p-2 d-none">
                  <strong>Nike Inc. </strong> has suspended you from their
                  account and are no longer sharing their HighGround data with
                  you.
                </p>

                {/* Client response */}
                <div className="client-reponse d-none">
                  <p className="f-500 mb-2">Client Response</p>
                  <p>
                    “You want a lot - you get little. We are not ready to
                    provide access to our documents, please change your request”
                  </p>
                </div>
                
                {/* Client response */}
                {rejectionReason && (
                  <div className="client-reponse">
                    <p className="f-500 mb-2"> Response</p>
                    <p>
                      
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `<p class="m-0">“${rejectionReason}”</p>`,
                        }}
                      />
                      
                    </p>
                  </div>
                )}

                {/* Clients Complaine profile and access level option */}
                <div className="client-compliance-access d-none">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="f-500 f-black">Compliance profile</div>
                    <div></div>
                  </div>
                </div>

                {/* Complaince Profile dropdowns */}
                {contextMenuData?.allowEditAccessAndCompliance && (
                  <>
                    <div className="d-flex align-items-center justify-content-between my-1 mx-2 mb-2">
                      <div className="d-flex align-items-center">
                        <div className="f-500 mr-2">Compliance Profile</div>
                      </div>

                      <div className="compliance-filter-dropdow n user-detail-dropdown">
                        <Dropdown className="device-dropdown" onClick={addEventOnScroll}>
                          <Dropdown.Toggle>
                            <div className="d-flex align-items-center">
                              {selectedComplianceProfile ? (
                                <div className="f-black f-500 mr-2">
                                  {selectedComplianceProfile?.ProfileName}
                                </div>
                              ) : (
                                <div className="f-grey mr-2">Choose...</div>
                              )}
                              <img
                                alt=""
                                className=""
                                src="/images/big-chevron-down.svg"
                              />
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                             className="hg-bg-white hide-scrollbar fade_on_scroll"
                             style={{
                              maxHeight: "225px",
                               // overflowY: "scroll",
                               overflow: "hidden",
                             }}
                          >
                            <div
                              className="hide-scrollbar position-relative black-blur-div"
                              style={{
                                maxHeight: "216px",
                                overflowY: "scroll",
                                overflowX: "hidden",
                              }}
                            >
                              <Row>
                                {complianceProfileList?.map(
                                  (profile, profileIndex) => {
                                    return (
                                      <Col xs={12}>
                                        <div
                                          style={{ marginTop: "3px" }}
                                          className={`notification-btn bg-hover-grey-light ${
                                            profile?.ObjectId ===
                                            selectedComplianceProfile?.ObjectId
                                              ? "bg-grey-light"
                                              : ""
                                          }`}
                                          onClick={(e) => {
                                            setSelectedComplianceProfile(
                                              profile
                                            );
                                            updateComplianceProfile(
                                              profile?.ObjectId
                                            );
                                            // getAccessDetails(profile?.ObjectId);
                                            e.target
                                              .closest(".device-dropdown")
                                              .click();
                                          }}
                                        >
                                          {profile?.ProfileName}
                                        </div>
                                      </Col>
                                    );
                                  }
                                )}

                                <hr
                                  className=" line-divider-light "
                                  style={{
                                    width: "80%",
                                    margin: "auto",

                                    marginTop: "12px",
                                    marginBottom: "8px",
                                  }}
                                />
                                <Col xs={12}>
                                  <div
                                    style={{
                                      marginBottom: "8px",
                                    }}
                                    className="notification-btn bg-hover-grey-light"
                                    onClick={(e) => {
                                      setShowAddComplianceModal(true);
                                      e.target
                                        .closest(".device-dropdown")
                                        .click();
                                    }}
                                  >
                                    Add compliance profile
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>

                    {/* Access Level dropdown */}
                    <div className="d-flex align-items-center justify-content-between my-1 mx-2 mb-2">
                      <div className="d-flex align-items-center">
                        <div className="f-500 mr-2">Access Level</div>
                      </div>

                      <div className="compliance-filter-dropdow n user-detail-dropdown">
                        <Dropdown className="device-dropdown"  onClick={addEventOnScroll}>
                          <Dropdown.Toggle>
                            <div className="d-flex align-items-center">
                              {selectedAccessLevel ? (
                                <div className="f-black f-500 mr-2">
                                  {TrimString(selectedAccessLevel?.Name, 35)}
                                </div>
                              ) : (
                                <div className="f-grey mr-2">Choose...</div>
                              )}
                              <img
                                alt=""
                                className=""
                                src="/images/big-chevron-down.svg"
                              />
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                             className="hg-bg-white hide-scrollbar fade_on_scroll "
                             style={{
                               maxHeight: "225px",
                               // overflowY: "scroll",
                               overflow: "hidden",
                             }}
                          >
                             <div
                              className="hide-scrollbar position-relative black-blur-div"
                              style={{
                                maxHeight: "216px",
                                overflowY: "scroll",
                                overflowX: "hidden",
                              }}
                            >
                              <Row>
                                {accessLevelList?.map(
                                  (accessLevel, acessLevelIndex) => {
                                    return (
                                      <Col xs={12}>
                                        <div
                                          style={{ marginTop: "3px" }}
                                          className={`notification-btn bg-hover-grey-light ${
                                            accessLevel?.ObjectId ===
                                            selectedAccessLevel?.ObjectId
                                              ? "bg-grey-light"
                                              : ""
                                          }`}
                                          onClick={(e) => {
                                            setSelectedAccessLevel(accessLevel);
                                            getAccessDetails(
                                              accessLevel?.ObjectId
                                            );
                                            e.target
                                              .closest(".device-dropdown")
                                              .click();
                                          }}
                                        >
                                          {accessLevel?.Name}
                                        </div>
                                      </Col>
                                    );
                                  }
                                )}

                                <hr
                                  className=" line-divider-light "
                                  style={{
                                    width: "80%",
                                    margin: "auto",

                                    marginTop: "12px",
                                    marginBottom: "8px",
                                  }}
                                />
                                <Col xs={12}>
                                  <div
                                    style={{
                                      marginBottom: "8px",
                                    }}
                                    className="notification-btn bg-hover-grey-light"
                                    onClick={(e) => {
                                      e.target
                                        .closest(".device-dropdown")
                                        .click();
                                      setShowAccessLevelModal(true);
                                    }}
                                  >
                                    Add access level
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </>
                )}

                <hr color="#EBEDF1" />
                {/* General Permissions */}
                {accessLevelData?.map((data, dataIndex) => {
                  return (
                    <div className="general-permissions">
                      <div className="f-12 f-grey my-2 ml-2">
                        {data?.Section}
                      </div>

                      {data?.data?.map((row, rowIndex) => {
                        return (
                          <div className="d-flex align-items-center justify-content-between my-1 mx-2">
                            <div className="d-flex align-items-center">
                              <div className="f-500 mr-2">
                                {row?.SubSection}
                              </div>
                              <span className="pointer">
                                <SupplyPopUp
                                  header={row?.SubSection}
                                  dataList={row?.Permission_List?.map(
                                    (permission) => {
                                      return {
                                        title: permission?.Permission,
                                        subtitle: permission?.Rights,
                                      };
                                    }
                                  )}
                                >
                                  <img
                                    alt=""
                                    src="/images/supply-chain/grey-info.svg"
                                  />
                                </SupplyPopUp>
                              </span>
                            </div>

                            <div
                              className={`compliance-filter-dropdown   ${
                                contextMenuData?.allowEditAccessAndCompliance
                                  ? ""
                                  : "disabled-dropdown"
                              } `}
                            >
                              <CompanySettingsDropdown
                                fieldId={"ObjectId"}
                                fieldValue={"Permission"}
                                selected={row?.PermissionSelected}
                                placeholder="Choose..."
                                //  showid={true}
                                onClick={(id, val) => {
                                  let updated_data = [...accessLevelData];
                                  let selected_value = updated_data[dataIndex][
                                    "data"
                                  ][rowIndex].Permission_List.filter(
                                    (item) => item.ObjectId === id
                                  )[0];
                                  updated_data[dataIndex]["data"][
                                    rowIndex
                                  ].PermissionSelected = selected_value;
                                  setAccessLevelData(updated_data);
                                  setSelectedAccessLevel({ObjectId:"123",Name:"Custom Access Level"});
                                }}
                                data={row?.Permission_List}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            )}

            {page === "cancelRequest" && (
              <div>
                <div className="px-2 pt-3">
                  {/* User Details */}
                  <div className="d-flex align-items-center">
                    <img
                      src={supplierLogo}
                      alt=""
                      className="mr-2 rounded-full"
                      width={48}
                      height={48}
                    />
                    <div>
                      <p className="f-16 f-600 m-0 mb-1">{supplierName}</p>
                      <p className="f-14 f-500 f-darkgrey m-0">
                        {supplierSupplyChainId}
                      </p>
                    </div>
                  </div>
                  <p className="f-black f-500 mt-3">
                  <span
                        dangerouslySetInnerHTML={{
                          __html: `<p class="m-0">${responseModalText?.Title}</p>`,
                        }}
                      />
                    {}
                  </p>
                  <div className="f-14 f-500 f-black mt-4">Response</div>
                  <textarea
                    rows={10}
                    className="hg-border radius-4 w-100 mt-2 p-2"
                    placeholder={`${  responseModalText?.ResponseGreyText    ??  "Type your message.."}`}
                    value={message}
                    onChange={(e) => {
                      setMessage(window.sanitizeHGtext(e.target.value));
                    }}
                  ></textarea>
                </div>
              </div>
            )}
          </>
        )}
      </Modal.Body>

      {page === "cancelRequest" && (
        <Modal.Footer className="p-2 d-flex align-items-center justify-content-between">
          <div></div>
          <div className="d-flex justify-content-end">
            <Button className="hg-cancel-btn mr-2" onClick={hideModal}>
              Cancel
            </Button>

            <Button
              className="hg-blue-btn"
              disabled={message === ""}
              onClick={() => {
                cancelRequest();
              }}
            >
              <div className="d-flex align-items-center">
                {processing && <WhiteLoader width={12} />}
                {capitalize(cancelType)}
              </div>
            </Button>
          </div>
        </Modal.Footer>
      )}
      {/* To Approve or Deny Client */}
      {page === "client" && contextMenuData?.ButtonMenuItems?.length > 0 && (
        <Modal.Footer className="p-2 d-flex align-items-center justify-content-between">
          <div></div>
          <div className="d-flex justify-content-end">
            <Button
              className={` ${
                contextMenuData?.ButtonMenuItems?.[0]?.menuActionLabel ===
                "HideButton"
                  ? "d-none"
                  : ""
              }    hg-cancel-btn mr-2`}
              onClick={() => {
                if (
                  contextMenuData?.ButtonMenuItems?.[0]?.newRequestStatus_Id ===
                  2
                ) {
                  // Condition for Denying
                  getRequestDetails(
                    requestDetails?.Id,
                    requestDetails?.status,
                    requestDetails?.relationId
                  );
                  setPage("cancelRequest");
                  setCancelType("deny");
                  setCancelRequestStatus(2);
                  return;
                }
                setDenying(true);
                updateStatus(
                  contextMenuData?.ButtonMenuItems?.[0]?.newRequestStatus_Id,
                  relationShipID
                );
              }}
            >
              {denying && <GreyLoader width={12} />}
              {contextMenuData?.ButtonMenuItems?.[0]?.menuActionLabel}
            </Button>

            <Button
              className="hg-blue-btn"
              disabled={
                contextMenuData?.allowEditAccessAndCompliance &&
                (!selectedComplianceProfile || !selectedAccessLevel?.Name)
                  ? true
                  : false
              }
              onClick={() => {
                setProcessing(true);

                if (contextMenuData?.allowEditAccessAndCompliance) {
                  sendRequest();
                } else {
                  updateStatus(
                    contextMenuData?.ButtonMenuItems?.[1]?.newRequestStatus_Id,
                    relationShipID
                  );
                }
              }}
            >
              <div className="d-flex align-items-center">
                {processing && <WhiteLoader width={12} />}

                {contextMenuData?.ButtonMenuItems?.[1]?.menuActionLabel}
              </div>
            </Button>
          </div>
        </Modal.Footer>
      )}

      <Suspense fallback={<div>Loading...</div>}>
        <AddComplianceProfileModal
          show={showAddComplianceModal}
          complianceProfileId={0}
          hideModal={() => setShowAddComplianceModal(false)}
          refreshData={() => {
            getComplianceProfiles(supplierId);
            if (refreshData) refreshData();
          }}
        />
      </Suspense>

      <AccessLevelModal
        show={showAccessLevelModal}
        hideModal={() => setShowAccessLevelModal(false)}
        refreshData={() => {
          getAccessLevels();
        }}
      />
    </Modal>
  );
};

export default ClientsModal;

function makeGroup(list, key) {
  if (list?.length === 0) return;
  let resultArr = [];
  let types = [];
  for (let i = 0; i < list.length; i++) {
    if (types.indexOf(list[i][key]) > -1) {
      let indexPosition = types.indexOf(list[i][key]);
      resultArr[indexPosition]["data"].push(list[i]);
      resultArr[indexPosition][key] = list[i][key];
    } else {
      types.push(list[i][key]);
      let indexPosition = types.indexOf(list[i][key]);
      if (typeof resultArr[indexPosition] === "object") {
        resultArr[indexPosition]["data"].push(list[i]);
        resultArr[indexPosition][key] = list[i][key];
      } else {
        resultArr[indexPosition] = {};
        resultArr[indexPosition]["data"] = [];
        resultArr[indexPosition]["data"].push(list[i]);
        resultArr[indexPosition][key] = list[i][key];
      }
    }
  }

  return resultArr;
}
