import React, { useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import DatePicker from "../../Theme/datepicker";
import "./questionnairetab.scss";
import moment from "moment";
import DropdownWithMultiSelect, { DropdownWithMultiSelectForSupplier } from "../../Theme/dropdowmulti";
import { getAPIData, postData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { WhiteLoader } from "../../Theme/APILoaders";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import Loader from "../../Common/loader";
import HighgroundDatePicker from "../../test/TestComponent";

const SendQuestionnaireModal = ({ show, hideModal, title, templateId,refreshData ,selectedSupplierId}) => {
  const { authTokens } = useAuth();
  const [selectedTaskDate, setSelectedTaskDate] = useState("");
  const [selectedDropdown, setSelectedDropdown] = useState([]);
  const [sending, setSending] = useState(false);
  const [supplierList, setSupplierList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [templateList, setTemplateList] = useState([]);

  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [templateTitle, setTemplateTitle] = useState("");
  useEffect(() => {
    setTemplateTitle(title);
    setSelectedTemplateId(templateId);
  }, [title, templateId]);
 
  async function getTemplateList() {
    await getAPIData(304, authTokens)
      .then((response) => {
        setLoading(false);
        if (response?.mr?.Success) {
          setTemplateList(response?.SCMAllFormTemplate_List);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getSupplierList() {
    await getAPIData(`${305}`, authTokens, {
      // optionEnum1: order,
    })
      .then((response) => {
        getTemplateList();
        if (response?.mr?.Success) {
          if (response?.Suppliers?.length > 0) {
            setSupplierList(response?.Suppliers);
            if (selectedSupplierId) {
              let filteredSupplier = response?.Suppliers?.filter((item) => {
                return item?.objId_Customer === Number(selectedSupplierId);
              });
              console.log(filteredSupplier);
              if (filteredSupplier?.length > 0) {
                setSelectedDropdown([filteredSupplier[0]]);
              }
            }
          } else {
            setSupplierList([]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }
  useEffect(() => {
    if (show) {
      setLoading(true);
      getSupplierList();
      setSelectedDropdown([]);
      setSelectedTaskDate("");
      setSending(false);
    }else{
      setTemplateTitle()
    }
  }, [show]);

  async function SendQuestionnaire() {
    if (selectedTaskDate === "") {
      CallToast("Kindly select due date", false, false, "v2style");
      return;
    }
    setSending(true);

    let suppliersSelected = selectedDropdown.map((item) => {
      return {
        objId_Customer: item?.objId_Customer,
        SupplierName: item?.SupplierName,
      };
    });
    await postData(authTokens, "SendQuestionnaire", {
      Suppliers: JSON.stringify(suppliersSelected),
      DueDate: selectedTaskDate,
      Title: templateTitle,
      FormTemplateId: selectedTemplateId,
    })
      .then((response) => {
        setSending(false);
        if (response?.Success) {
          hideModal();
          if(refreshData)refreshData()
          CallToast("Questionnaire sent successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        setSending(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  return (
    <Modal
      show={show}
      dialogClassName="modal-50w"
      className="send-questionnaire-modal"
      aria-labelledby="send-questionnaire-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Header className="text-center ">
        <div className="w-100 mb-0 f-500">Send Questionnaire</div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img src="/images/supply-chain/small-cross-modal.svg" alt="" />
        </span>
      </Modal.Header>
      <Modal.Body className="hide-scrollbar">
        {loading ? (
          <div
            className="loader d-flex align-items-center justify-content-center h-100  "
            style={{}}
          >
            <Loader />
          </div>
        ) : (
          <>
            <div className="mx-auto" style={{ width: "95%" }}>
              <div className="d-flex align-items-center">
                <img
                  alt=""
                  className="mr-2"
                  src="/images/supply-chain/questionnaire-title.svg"
                />
                {/* <div className="f-black f-500">{title} </div> */}
                <Dropdown className="select-template-dropdown">
                  <Dropdown.Toggle className={`p-0 pl-1 position-relative   ${""}`}>
                    {templateTitle  ??   <div className="f-grey">Select a template</div>}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {templateList?.map((template, templateIndex) => {
                      return (
                        <div
                          className={`pointer px-2 d-flex align-items-center justify-content-between drop-item
                        `}
                          onClick={(e) => {
                            setTemplateTitle(template?.Title);
                            setSelectedTemplateId(template?.ObjectId);
                            e.target
                              .closest(".select-template-dropdown")
                              .click();
                          }}
                        >
                          <div>{template?.Title}</div>
                          <div>
                            {selectedTemplateId === template?.ObjectId && (
                              <img
                                alt=""
                                width={12}
                                src="/images/settings-v2/black-tick.svg"
                                className="ml-1"
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="d-flex align-items-center mt-2">
                <div className="supplier-date-picker pl-4 bg-transparent"
                style={{width:"64%"}}
                
                >
                <HighgroundDatePicker
                              selected={selectedTaskDate}
                              placeholder={"Choose due date"}
                              // icon={"/images/attack-surface/date-icon.svg"}
                              onChange={(e) => { setSelectedTaskDate(e);}}
                              id={"cbhfgvwmv"}
                              isError={false}
                            />
                 

                 
                </div>
              </div>
              {/* Drop down */}

              <div className="my-2 mb-3  send-questionnaire-multi-dropdown">
                <DropdownWithMultiSelectForSupplier
                  fieldId={"objId_Customer"}
                  fieldValue={"SupplierName"}
                  iconKey={"SupplierLogo"}
                  selected={selectedDropdown}
                  placeholder="Enter supplier.."
                  onClick={(objId) => {
                    let selectedValues = [...selectedDropdown];

                    let newItem = supplierList.filter(
                      (item) => item.objId_Customer === objId
                    )[0];

                    selectedValues.push(newItem);

                    setSelectedDropdown(selectedValues);
                  }}
                  onRemove={(objId) => {
                    let selectedValues = [...selectedDropdown];

                    let newItem = selectedValues.findIndex(
                      (item) => item.objId_Customer === objId
                    );

                    selectedValues.splice(newItem, 1);

                    setSelectedDropdown(selectedValues);
                  }}
                  data={supplierList} //supplierList
                />
              </div>
            </div>

            {/* suppliers List */}
            <div>
              <div className="f-12 f-grey ml-3 mb-1">Suppliers</div>
              {[...supplierList].map((supplier, supplierIndex) => {
                let isSelectedSupplier =
                  selectedDropdown.findIndex(
                    (item) => item?.objId_Customer === supplier?.objId_Customer
                  ) !== -1;
                return (
                  <div
                    className="d-flex align-items-center justify-content-between pointer radius-4 bg-hover-grey p-2 mb-1 px-3 parent-visible"
                    onClick={() => {
                      if (!isSelectedSupplier) {
                        let selected_list = [...selectedDropdown];
                        selected_list.push(supplier);
                        setSelectedDropdown(selected_list);
                      }
                    }}
                  >
                    <div className="f-black f-500 d-flex align-items-center">
                      <img
                        alt=""
                        width={32}
                        height={32}
                        className="mr-2 rounded-full"
                        src={supplier?.SupplierLogo}
                      />
                      {supplier?.SupplierName}
                    </div>
                    <div
                      className={`f-500 f-grey ${
                        isSelectedSupplier ? "" : "child-visible"
                      }`}
                    >
                      {isSelectedSupplier ? "Added" : "Add"}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end align-items-center p-2">
        <Button className="hg-cancel-btn" onClick={hideModal}>
          Cancel
        </Button>
        <Button className="hg-blue-btn" onClick={SendQuestionnaire}
        
        disabled={templateTitle ?   false  : true }
        >
          {sending ? (
            <>
              <WhiteLoader width={12} />
              Sending..{" "}
            </>
          ) : (
            "Send Questionnaire"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SendQuestionnaireModal;
