import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import Header from "../Header";
import ComingSoonLoader from "./ComingSoonLoader";

const WelcomeScreenSupplyChain = () => {
  const [loading, setLoading] = useState(false);
  const isMSP = window.location.pathname.split("/")[1] === 'mspv2';

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);

  const isMenuCollapse = useSelector((state) => state?.global?.menuCollapse);
  const userDetails = useSelector((state) => state.user?.userDetail);
  return (
    <div
      className=""
      style={{
        height: "100%",
      }}
    >
      <Row className="fullHeight">
        <Col xs={12} className="px-0">
          <div className="dashboard fullHeight">
            <header
              className="d-flex justify-content-between navbar sticky-top "
              style={{
                paddingTop: ` ${
                 ( userDetails?.AccountVerified === false || userDetails?.ShowConfirmVerified === true) ? "3em" : "0.8em"
                } `,
                background: "#FFFFFF",
                position: "fixed",
                width: `${isMenuCollapse ? "92.25%" : "83.25%"}`,
                zIndex: isMSP ? 4 : 6,
                marginLeft: "-0.9rem",
                paddingLeft: "2rem",
                borderBottom: `1px solid #EBEDF1`,
                paddingBottom: "8px",
              }}
            >
              <h1>Supply Chain Monitoring</h1>
              <Header />
            </header>
            {loading ? (
              <ComingSoonLoader />
            ) : (
              <div
                className="w-100 d-flex flex-column"
                style={{ paddingTop: "5%" }}
              >
                {/* Top row */}
                <img
                  className="mx-auto"
                  alt=""
                  src="/images/supply-chain/coming-soon-supply.svg"
                  style={{
                    width: "46.5%",
                  }}
                />
                {/* Proactve decision row
                 */}

                <div
                  className="d-flex justify-content-between mx-auto radius-8 mt-4"
                  style={{
                    width: "46.5%",
                    background: "#F6F8FB",
                    padding: "2rem 0rem 2rem 2rem",
                  }}
                >
                  <div style={{ marginRight: "2rem", width: "38%" }}>
                    <p
                      className="mb-2"
                      style={{ fontSize: "18px", fontWeight: 600 }}
                    >
                      Security is Security
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "#616778",
                        lineHeight: "22px",
                        textAlign: "justify",
                      }}
                    >
                      There should be no gap between your physical and Cyber
                      security states. Supply Chain Manager will enable you to
                      stay secure in the internet age and enjoy easier, faster,
                      and safer partnerships with your suppliers and buyers.
                      Don’t be the weak link and don’t be put at risk due to a
                      3rd party’s weakness.
                    </p>
                  </div>
                  <div
                    className="flex align-items-start w-100"
                    style={{
                      marginRight: "3px",
                    }}
                  >
                    <img
                      style={{ objectFit: "contain", width: "inherit" }}
                      className=""
                      alt=""
                      src="/images/supply-chain/pro-active.svg"
                    />
                  </div>
                </div>

                {/* Thirst row */}
                <div
                  className="d-flex justify-content-between mx-auto  mt-4"
                  style={{
                    width: "46.5%",
                    height: "466px",
                  }}
                >
                  <div
                    className="d-flex  flex-column radius-8"
                    style={{
                      width: "49%",
                      height: "100%",
                      background: "#F6F8FB",
                    }}
                  >
                    <div className="d-flex justify-content-center pt-3 position-relative">
                      <img
                        alt=""
                        src="/images/supply-chain/supply-demand.svg"
                      />
                      <div
                        className="position-absolute"
                        style={{
                          width: "100%",
                          height: "24px",
                          background: "#F6F8FB",
                          bottom: "-7px",
                          filter: "blur(8px)",
                        }}
                      ></div>
                    </div>
                    <div className="" style={{ padding: "1rem 2rem" }}>
                      <p
                        className="mb-2"
                        style={{ fontSize: "18px", fontWeight: 600 }}
                      >
                        Supply & Demand for Security Info
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: 400,
                          color: "#616778",
                          lineHeight: "22px",
                          textAlign: "justify",
                        }}
                      >
                        Whether you have been asked to supply your security
                        information to a vendor/partner or whether you are the
                        party requesting security details to fortify your supply
                        chain, manage the sharing of information right here.
                        With send/request functionality you can at the click of
                        a button approve or deny based on best security
                        practices.
                      </p>
                    </div>
                  </div>
                  {/*  */}
                  <div
                    className="d-flex  flex-column radius-8"
                    style={{
                      width: "49%",
                      height: "100%",
                      background: "#F6F8FB",
                    }}
                  >
                    <div className="d-flex justify-content-center pt-3 position-relative">
                      <img alt="" src="/images/supply-chain/chat.svg" />
                      <div
                        className="position-absolute"
                        style={{
                          width: "100%",
                          height: "24px",
                          background: "#F6F8FB",
                          bottom: "-7px",
                          filter: "blur(8px)",
                        }}
                      ></div>
                    </div>
                    <div className="" style={{ padding: "1rem 2rem" }}>
                      <p
                        className="mb-2"
                        style={{ fontSize: "18px", fontWeight: 600 }}
                      >
                        Chat
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: 400,
                          color: "#616778",
                          lineHeight: "22px",
                          textAlign: "justify",
                        }}
                      >
                        It kinda does what it says on the box. Communicate with
                        your partners directly through the app for security
                        concerns. This feature, along with the document
                        commenting in the Vendor Assessment feature, ensures all
                        security conversations are centralised and secure. Say
                        goodbye to WhatsApp and emails for Cyber talk.
                      </p>
                    </div>
                  </div>
                </div>

                {/* fiurth rowwwwwwwww */}

                <div
                  className="d-flex justify-content-between mx-auto  mt-4"
                  style={{
                    width: "46.5%",
                    height: "466px",
                  }}
                >
                  <div
                    className="d-flex  flex-column radius-8"
                    style={{
                      width: "49%",
                      height: "100%",
                      background: "#F6F8FB",
                    }}
                  >
                    <div className="d-flex justify-content-center pt-3 position-relative">
                      <img
                        alt=""
                        src="/images/supply-chain/compliance-profile.svg"
                      />
                      <div
                        className="position-absolute"
                        style={{
                          width: "100%",
                          height: "24px",
                          background: "#F6F8FB",
                          bottom: "-7px",
                          filter: "blur(8px)",
                        }}
                      ></div>
                    </div>
                    <div className="mb-2" style={{ padding: "1rem 2rem" }}>
                      <p
                        className="mb-2"
                        style={{ fontSize: "18px", fontWeight: 600 }}
                      >
                        Compliance profiles
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: 400,
                          color: "#616778",
                          lineHeight: "22px",
                          textAlign: "justify",
                        }}
                      >
                        What does a ‘good’ vendor look like to you and how do
                        you want to monitor them? Right here you get to call the
                        shots and set up the requirements you want to measure in
                        a vendor or supplier. What is the minimum Cyber Score
                        you are prepared to accept? How compliant do you want
                        them to be? Once set, you can monitor here with
                        easy-to-decipher visuals and KPIs.
                      </p>
                    </div>
                  </div>
                  {/*  */}
                  <div
                    className="d-flex  flex-column radius-8"
                    style={{
                      width: "49%",
                      height: "100%",
                      background: "#F6F8FB",
                    }}
                  >
                    <div className="d-flex justify-content-center pt-3 position-relative">
                      <img
                        alt=""
                        src="/images/supply-chain/access-levels.svg"
                      />
                      <div
                        className="position-absolute"
                        style={{
                          width: "100%",
                          height: "24px",
                          background: "#F6F8FB",
                          bottom: "-7px",
                          filter: "blur(8px)",
                        }}
                      ></div>
                    </div>
                    <div className="" style={{ padding: "1rem 2rem" }}>
                      <p
                        className="mb-2"
                        style={{ fontSize: "18px", fontWeight: 600 }}
                      >
                        Access Levels
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: 400,
                          color: "#616778",
                          lineHeight: "22px",
                          textAlign: "justify",
                        }}
                      >
                        You have trusted HighGround.io with a lot of secure and
                        sensitive information and you get to manage who sees
                        what. When sharing your profile with a partner as part
                        of the supply chain analysis, you get to choose exactly
                        what you share and what the 3rd party can see, and, what
                        you keep within your well-built fortress. They may
                        complain or ask for more, so use the chat to discuss.
                      </p>
                    </div>
                  </div>
                </div>

                {/* Last Row */}

                <div
                  className="d-flex-  mx-auto radius-8 mt-4"
                  style={{
                    width: "46.5%",
                    background: "#F6F8FB",
                    padding: "2rem 0rem 2rem 2rem",
                  }}
                >
                  <div
                    className=" float-right ml-3 mb-2"
                    style={{
                      height: "fit-content",
                      marginRight: "3px",
                    }}
                  >
                    <img
                      style={{ objectFit: "contain", width: "inherit" }}
                      className=""
                      alt=""
                      src="/images/supply-chain/questionaire.svg"
                    />
                  </div>
                  {/* <div style={{ marginRight: "2rem", width: "31.56%" }}> */}
                  <p
                    className="mb-2"
                    style={{
                      fontSize: "18px",
                      fontWeight: 600,
                      wordWrap: "break-word",
                    }}
                  >
                    Questionaries & Templates
                  </p>

                  <p
                    className="pr-3"
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      color: "#616778",
                      lineHeight: "22px",
                      textAlign: "justify",
                    }}
                  >
                    Sometimes you can't get away from the bureaucracy and you
                    may have to complete or build your own questionnaires. We've
                    tried to take some of that pain away with pre-built
                    templates and a feature where you can even build your own.
                    Once you are at the collaboration stage with a potential
                    partner HighGround.io allows in-document chatter to aid both
                    parties to skip those pesky back-and-forth emails and amend
                    and agree through the document.
                  </p>
                  {/* </div> */}
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default WelcomeScreenSupplyChain;
