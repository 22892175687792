import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import RevokeContributorsDropdown from "./ProfilerDropdowns/RevokeContributorsDropdown";
import "./supplier-profiler.scss";
import AskForHelpDropdown from "./ProfilerDropdowns/AskForHelpDropdown";
import { useEffect } from "react";

const EmptyModal = ({
  show,
  hideModal,
  header,
  headerIcon,
  content,
  status,
  contributors,
  setSelectAll,
  setCancelAll,
  selectedAll,
  setStatusModal,
  questionList,
  questionId,
  refreshData,
  addQuestionList,
  sectionType_List,
  isContributorView,
  AllowAskForHelp,
  notQuestionBlock,
}) => {
  const [updateState, setUpdateState] = useState(false);
  const [currentSelect, setCurrentSelect] = useState(selectedAll);
  useEffect(() => {
    console.log(status);
  }, [status, selectedAll, currentSelect]);
  // console.log("empty modal QUESTION LIST ", questionList);

  return (
    <Modal
      show={show}
      dialogClassName="empty-modal "
      className=" supplier-assessment d-flex justify-content-center  hide-scrollbar"
      aria-labelledby=" "
      onHide={hideModal}
      centered
      scrollable
      // style={{maxWidth: '1200px'}}
    >
      <Modal.Header
        className="text-center divider  d-flex"
        style={{
          padding: "8px 24px 8px 12px",
          alignItems: "center",
          gap: "40px",
        }}
      >
        <div
          className="w-50 mb-0 font-bold-14 f-black d-flex "
          style={{ fontWeight: "600", alignItems: "center" }}
        >
          <img
            src={`${headerIcon}`}
            alt=""
            // className=" rounded-full bg-darkgrey"
            style={{ padding: "4px" }}
          />
          <div className="f-16">{header}</div>
        </div>

        {status == "open" ? (
          <div
            className="w-50 f-12 title f-grey d-flex flex-row secops_training_certificate_overlap_parent"
            style={{ justifyContent: "flex-end" }}
          >
            {isContributorView ? (
              <>
                {AllowAskForHelp ? (
                  <AskForHelpDropdown
                    contributors={contributors}
                    hideModal={hideModal}
                    questionId={questionId}
                    questionList={questionList}
                    sectionType_List={sectionType_List}
                    refreshData={refreshData}
                    isContributorView={isContributorView}
                    notQuestionBlock={notQuestionBlock}
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <AskForHelpDropdown
                contributors={contributors}
                hideModal={hideModal}
                questionId={questionId}
                questionList={questionList}
                sectionType_List={sectionType_List}
                refreshData={refreshData}
                isContributorView={isContributorView}
                notQuestionBlock={notQuestionBlock}
              />
            )}

            <RevokeContributorsDropdown
              revokers={contributors}
              countFlag={false}
              refreshData={refreshData}
              isContributorView={isContributorView}
            />
            <span
              className="cursor-pointer"
              onClick={() => {
                hideModal();
              }}
            >
              <img
                width={15}
                src="/images/attack-surface/cross-icon.svg"
                alt=""
              />
            </span>
          </div>
        ) : (
          <div
            className="w-50 f-14 title f-grey d-flex flex-row secops_training_certificate_overlap_parent"
            style={{ justifyContent: "flex-end" }}
          >
            <div
              className="d-flex justify-content-center cursor-pointer"
              style={{ padding: "4px", alignItems: "center" }}
              onClick={() => {
                console.log("efcaoc", selectedAll);
                setCurrentSelect(!currentSelect);

                if (currentSelect) {
                  setSelectAll(false);
                  setCancelAll(true);
                  addQuestionList([]);
                } else {
                  setSelectAll(true);
                  setCancelAll(false);
                }

                setUpdateState(!updateState);
                selectedAll = currentSelect;
              }}
            >
              <img
                width={15}
                src="/images/supply-chain/checkbox-checked-icon.svg"
                alt=""
                className="mr-1"
              />
              <div>Select All</div>
            </div>
            {/* <div
              className="cursor-pointer"
              style={{
                borderLeft: "1px solid #EBEDF1",
                padding: "4px",
                paddingLeft: "8px",
              }}
              onClick={() => {
                console.log("check", selectedAll);
                // setStatusModal("open");
                setSelectAll(false);
                setCancelAll(true);
                addQuestionList([]);
                // hideModal();
              }}
            >
              Cancel
            </div> */}

            {isContributorView ? (
              <>
                {AllowAskForHelp ? (
                  <AskForHelpDropdown
                    status={"ask"}
                    contributors={contributors}
                    hideModal={hideModal}
                    questionId={questionId}
                    questionList={questionList}
                    sectionType_List={sectionType_List}
                    refreshData={refreshData}
                    isContributorView={isContributorView}
                    notQuestionBlock={notQuestionBlock}
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <AskForHelpDropdown
                status={"ask"}
                contributors={contributors}
                hideModal={hideModal}
                questionId={questionId}
                questionList={questionList}
                sectionType_List={sectionType_List}
                refreshData={refreshData}
                isContributorView={isContributorView}
                notQuestionBlock={notQuestionBlock}
              />
            )}
            <span
              className="cursor-pointer"
              onClick={() => {
                hideModal();
              }}
            >
              <img
                width={15}
                src="/images/attack-surface/cross-icon.svg"
                alt=""
              />
            </span>
          </div>
        )}
      </Modal.Header>
      <Modal.Body className="p-2 hide-scrollbar">{content}</Modal.Body>
    </Modal>
  );
};

export default EmptyModal;
