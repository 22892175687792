// import { configureStore } from "@reduxjs/toolkit";
// import { combineReducers } from "redux-immutable";

import { combineReducers } from "redux";
import ActionCentreReducer from "./actionCentre/reducer";
import calculatorReducer from "./calculator/reducer";
import CompanyReducer from "./companyState/reducer";
import GlobalReducer from "./highgroundGlobalState/reducer";
import SegmentReducer from "./segmentEventStatus/reducer";
import UserReducer from "./userState/reducer";
import MspClientReducer from "./mspClientStates/reducer";

const reducers = combineReducers({
  calculator: calculatorReducer,
  global: GlobalReducer,
  user: UserReducer,
  company: CompanyReducer,
  segment: SegmentReducer,
  actioncentre: ActionCentreReducer,
  mspclient: MspClientReducer,
});

export default reducers;
