import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

const DropdownV2 = ({
  data,
  selected = {},
  fieldId,
  fieldValue,
  placeholder,
  onClick = () => {},
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  return (
    <Dropdown
      alignRight
      className="transparennt-wrapper-dropdown"
      onToggle={(e) => {
        setIsDropdownOpen(e);
      }}
    >
      <Dropdown.Toggle className="d-flex align-items-center">
        {typeof selected === "object" && Object.keys(selected).length > 0 ? (
          <div className="f-500 f-grey">{selected[fieldValue]}</div>
        ) : (
          <div className="p-2 f-grey">{placeholder}</div>
        )}
        <img
          src="/images/big-chevron-down.svg"
          alt="arrow-down"
          className={`ml-2 ${isDropdownOpen ? "rotate180" : ""}`}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu className="pointer px-2"
      style={{
        minWidth:"200px"
      }}
      >
        {data.map((item, index) => {
          return (
            <>
              <div
                className="d-flex align-items-center mb-2 f-grey"
                onClick={(e) => {
                  onClick(item[fieldId], item[fieldValue]);
                  e.target.closest(".transparennt-wrapper-dropdown").click();
                }}
              >
                <img
                  src="/images/security-assessment/grey-tick-icon.svg"
                  alt=""
                  className="mr-2"
                  style={{
                    visibility: `${
                      selected[fieldId] === item[fieldId] ? "visible" : "hidden"
                    }`,
                  }}
                />
                {item[fieldValue]}
              </div>
            </>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownV2;
