import React from "react";
import ContentLoader from "react-content-loader";

const SectionTabLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="47" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="110" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="173" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="236" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="299" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="362" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="425" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="488" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="551" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="614" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="677" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="740" rx="4" ry="4" width="98%" height="60" />

      <rect x="0" y="803" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="866" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="929" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="992" rx="4" ry="4" width="98%" height="60" />
      <rect x="0" y="1055" rx="4" ry="4" width="98%" height="60" />
    </ContentLoader>
  );
};

export default SectionTabLoader;
