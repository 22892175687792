import React, { useState, useEffect } from "react";
import { Dropdown, Modal, Row, Col, Button } from "react-bootstrap";
import CompanySettingsDropdown, {
  CustomCompanySettingsDropdown,
} from "../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import ReassignSupplierModal from "../modals/ReassignSupplierModal";
import "./accesslevelmodal.scss";
import { getAPIData, postData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import Loader from "../../Common/loader";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { WhiteLoader } from "../../Theme/APILoaders";
import SupplyPopUp from "../SupplyPopUp/SupplyPopUp";
import { TrimString } from "../../../Utils/StringTrimmer";

const AccessLevelModal = ({ show, hideModal, refreshData }) => {
  //////// Pages list---->> homepage,accesslevel
  const [accessLevelList, setAccessLevelList] = useState([]);
  const [accessLevelData, setAccessLevelData] = useState([]);
  const [objectId, setObjectId] = useState(0);
  const [accessLevelName, setAccessLevelName] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState("homepage");
  const [header, setHeader] = useState("Access Levels");
  const [saving, setSaving] = useState(false);
  const [accessNameError, setAccessNameError] = useState("");
  const [isDefault, setIsDefault] = useState(false);
  useEffect(() => {
    if (show) {
      setLoading(true);
      getAllAccessLevel();
      setPage("homepage");
      setHeader("Access Levels");
    }
  }, [show]);

  const { authTokens } = useAuth();

  async function getAllAccessLevel() {
    await getAPIData("309", authTokens, {}).then((response) => {
      setLoading(false);
      if (response?.length > 0) {
        setAccessLevelList(response);
      } else {
        setAccessLevelList([]);
      }
    });
  }

  async function getAccessLevel(objectId) {
    setLoading(true);
    await getAPIData("310", authTokens, {
      optionEnum1: objectId,
    })
      .then((response) => {
        setLoading(false);
        setAccessLevelName(response?.Name);
        if (response?.AccessLevelSelection_List?.length > 0) {
          setAccessLevelData(
            makeGroup(response?.AccessLevelSelection_List, "Section")
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function createAccessLevel() {
    if (objectId === 0 && accessLevelName === "") {
      setAccessNameError("sfcsdfsdfs");
      return;
    }

    let access_level_list = [...accessLevelData];
    for (let i = 0; i < access_level_list.length; i++) {
      for (let j = 0; j < access_level_list[i].data.length; j++) {
        let no_access_item = access_level_list[i].data[
          j
        ].Permission_List.filter((item) => item?.Level === 0)[0];

        if (!access_level_list[i].data[j].PermissionSelected) {
          access_level_list[i].data[j].PermissionSelected = {
            ...no_access_item,
          };
        }
      }
    }

    setSaving(true);
    let _accessLevelList = [];
    for (let i = 0; i < access_level_list.length; i++) {
      _accessLevelList.push(...access_level_list[i].data);
    }

    await postData(authTokens, "CreateAccessLevelWithSelection", {
      ObjectId: objectId,
      Name: accessLevelName,
      Sort: 5,
      AccessLevelSelection_List: JSON.stringify(_accessLevelList),
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    }).then((response) => {
      setSaving(false);
      if (response.Success) {
        if (refreshData) {
          refreshData();
        }
        if (objectId === 0) {
          hideModal();
          CallToast(
            "Access level created successfully",
            false,
            false,
            "v2style"
          );
        } else {
          CallToast(
            "Access level updated successfully",
            false,
            false,
            "v2style"
          );
        }
      }
    });
  }

  async function deleteAccessLevel(objectId) {
    await getAPIData("312", authTokens, {
      optionEnum1: objectId,
      optionEnum2: 0,
    })
      .then((response) => {
        if (response.Success) {
          getAllAccessLevel();
          CallToast(
            "Access level deleted successfully",
            false,
            false,
            "v2style"
          );
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function duplicateAccessLevel(objectId) {
    await getAPIData("313", authTokens, {
      optionEnum1: objectId,
    })
      .then((response) => {
        if (response.Success) {
          getAllAccessLevel();
          CallToast(
            "Access level duplicated successfully",
            false,
            false,
            "v2style"
          );
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const [discardModal, setDiscardModal] = useState(false);
  const [showReassignModal, setShowReassignModal] = useState(false);
  const [newLevel, setNewLevel] = useState(false);

  async function getSelectionsList() {
    setLoading(true);
    await getAPIData("460", authTokens, {})
      .then((response) => {
        setLoading(false);
        if (response?.AccessLevelSelection_List?.length > 0) {
          setAccessLevelData(
            makeGroup(response?.AccessLevelSelection_List, "Section")
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    // testing
  }

  return (
    <Modal
      show={show}
      dialogClassName="access-level-modal"
      className=" "
      aria-labelledby="access-level-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Header className="text-center">
        {page !== "homepage" && (
          <img
            alt=""
            src="/images/supply-chain/chevron-left.svg"
            className="mt-2 pointer"
            onClick={() => {
              getAllAccessLevel();
              setPage("homepage");
              setHeader("Access Levels");
            }}
          />
        )}
        <div className="w-100 mb-0 f-500 f-black">
          {TrimString(header, 40)}{" "}
        </div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img alt="" src="/images/icons/close.svg" className="close-icon" />
        </span>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="d-flex align-items-center justify-content-center w-100 h-100">
            <Loader />
          </div>
        ) : (
          <>
            {" "}
            {page == "homepage" && (
              <div className="homepage">
                <div className="f-12 f-darkgrey ml-3 my-2">Default</div>

                {accessLevelList
                  .filter((item) => item.IsDefault === true)
                  .map((level, levelIndex) => {
                    return (
                      <div
                        className="d-flex align-items-center justify-content-between bg-hover-grey p-2 pl-3 pointer level"
                        onClick={() => {
                          setPage("accesslevel");
                          getAccessLevel(level.ObjectId);
                          setHeader(level.Name);
                          setNewLevel(false);
                          setObjectId(level.ObjectId);
                          setIsDefault(true);
                        }}
                      >
                        <div>
                          <p className="f-500 f-black m-0">{level.Name} </p>
                          <p className="f-12 f-darkgrey m-0">
                            {level.SupplierCount} suppliers
                          </p>
                        </div>
                        <div
                          className="level-dropdown"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Dropdown className="device-dropdown">
                            <Dropdown.Toggle>
                              <img
                                alt=""
                                src="/images/horizontal-dots.svg"
                                className="show-on-hover"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <div>
                                <Row>
                                  <Col xs={12} className=" pointer">
                                    <div
                                      className="drop-item"
                                      onClick={(e) => {
                                        e.target
                                          .closest(".device-dropdown")
                                          .click();
                                        duplicateAccessLevel(level?.ObjectId);
                                      }}
                                    >
                                      Duplicate
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    );
                  })}

                {/* Custom levels wrapper */}

                {accessLevelList.filter((item) => item.IsDefault === false)
                  .length === 0 && (
                  <div className="d-flex flex-column w-50 mt-4 align-items-center justify-content-center mx-auto">
                    <p className="f-500 f-grey mb-0">
                      Create custom access level
                    </p>
                    <p className="f-12 f-darkgrey text-center mb-2">
                      You can add your own access levels and use them for your
                      supplier requests
                    </p>
                    <Button
                      className="hg-cancel-btn ml-3 mt-2 w-fit-content"
                      onClick={() => {
                        setPage("accesslevel");
                        setHeader("Create access level");
                        setNewLevel(true);
                        // setAccessLevelData([...dummyData]);
                        setObjectId(0);
                        setAccessLevelName("");
                        setIsDefault(false);
                        getSelectionsList();
                      }}
                    >
                      <img
                        alt=""
                        className="mr-2"
                        src="/images/actioncenter/plusgrey.svg"
                      />
                      Add access level
                    </Button>
                  </div>
                )}

                <div className="customlevel-wrapper">
                  {accessLevelList.filter((item) => item.IsDefault === false)
                    .length > 0 && (
                    <div className="f-12 f-darkgrey ml-3 my-2">Custom</div>
                  )}

                  {accessLevelList
                    .filter((item) => item.IsDefault === false)
                    .map((level, levelIndex) => {
                      return (
                        <div
                          className="d-flex align-items-center justify-content-between bg-hover-grey p-2 pl-3 pointer level"
                          onClick={() => {
                            setPage("accesslevel");
                            getAccessLevel(level.ObjectId);
                            setHeader(level.Name);
                            setNewLevel(true);
                            setObjectId(level.ObjectId);
                            if (level.SupplierCount > 0) {
                              setIsDefault(true);
                            } else {
                              setIsDefault(false);
                            }
                          }}
                        >
                          <div>
                            <p className="f-500 f-black m-0">
                              {TrimString(level.Name, 40)}
                            </p>
                            <p className="f-12 f-darkgrey m-0">
                              {level?.SupplierCount} suppliers
                            </p>
                          </div>
                          <div
                            className="level-dropdown"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Dropdown className="device-dropdown">
                              <Dropdown.Toggle>
                                <img
                                  alt=""
                                  src="/images/horizontal-dots.svg"
                                  className="show-on-hover"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <div>
                                  <Row>
                                    <Col xs={12} className=" pointer">
                                      <div
                                        className="drop-item"
                                        onClick={(e) => {
                                          e.target
                                            .closest(".device-dropdown")
                                            .click();
                                          duplicateAccessLevel(level?.ObjectId);
                                        }}
                                      >
                                        Duplicate
                                      </div>
                                    </Col>
                                 { level?.SupplierCount===0 &&  <Col xs={12} className=" pointer">
                                      <div
                                        className="drop-item"
                                        onClick={(e) => {
                                          e.target
                                            .closest(".device-dropdown")
                                            .click();
                                          // setShowReassignModal(true);
                                          deleteAccessLevel(level.ObjectId);
                                        }}
                                      >
                                        Delete
                                      </div>
                                    </Col>}
                                  </Row>
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      );
                    })}

                  {accessLevelList.filter((item) => item.IsDefault === false)
                    .length > 0 && (
                    <Button
                      className="hg-cancel-btn ml-3 mt-2"
                      onClick={() => {
                        setPage("accesslevel");
                        setHeader("Create access level");
                        setNewLevel(true);
                        // setAccessLevelData([...dummyData]);
                        setObjectId(0);
                        setAccessLevelName("");
                        setIsDefault(false);
                        getSelectionsList();
                      }}
                    >
                      <img
                        alt=""
                        className="mr-2"
                        src="/images/actioncenter/plusgrey.svg"
                      />
                      Add access level
                    </Button>
                  )}
                </div>
              </div>
            )}
            {page == "accesslevel" && (
              <div className="access-level">
                {newLevel && (
                  <div className="access-name px-2">
                    <div className="f-500 f-black ">Name</div>
                    <input
                      type="text"
                      placeholder="Access level name"
                      className="w-100 border-0 grey-input pl-2 "
                      value={accessLevelName}
                      onChange={(e) => {
                        setAccessLevelName(window.sanitizeHGtext(e.target.value));
                        setAccessNameError("");
                      }}
                    />
                    {accessNameError && (
                      <div className="f-12 f-danger">
                        Please fill a valid access level name{" "}
                      </div>
                    )}
                  </div>
                )}

                {/* General Permissions */}
                {accessLevelData.map((data, dataIndex) => {
                  return (
                    <div className="general-permissions">
                      <div className="f-12 f-grey my-2 ml-2">
                        {data?.Section}
                      </div>
                      {/* KPI list mapping */}

                      {data?.data?.map((row, rowIndex) => {
                        return (
                          <div className="d-flex align-items-center justify-content-between my-1 mx-2">
                            <div className="d-flex align-items-center">
                              <div className="f-500 mr-2">
                                {" "}
                                {row?.SubSection}{" "}
                              </div>
                              <span className="pointer">
                                <SupplyPopUp
                                  header={row?.SubSection}
                                  dataList={row?.Permission_List?.map(
                                    (permission) => {
                                      return {
                                        title: permission?.Permission,
                                        subtitle: permission?.Rights,
                                      };
                                    }
                                  )}
                                >
                                  <img
                                    alt=""
                                    src="/images/supply-chain/grey-info.svg"
                                  />
                                </SupplyPopUp>
                              </span>
                            </div>

                            <div
                              className={`access-level-dropdown
                            
                            ${isDefault ? "disabled-dropdown" : ""}
                            
                            `}
                            >
                              <CustomCompanySettingsDropdown
                                fieldId={"ObjectId"}
                                fieldValue={"Permission"}
                                selected={row?.PermissionSelected}
                                placeholder="Choose..."
                                //  showid={true}
                                onClick={(id, val) => {
                                  let updated_data = [...accessLevelData];
                                  let selected_value = updated_data[dataIndex][
                                    "data"
                                  ][rowIndex].Permission_List.filter(
                                    (item) => item.ObjectId === id
                                  )[0];
                                  updated_data[dataIndex]["data"][
                                    rowIndex
                                  ].PermissionSelected = selected_value;
                                  setAccessLevelData(updated_data);
                                }}
                                data={row?.Permission_List}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            )}
            <DiscardModal
              show={discardModal}
              hideModal={() => setDiscardModal(false)}
            />
            <ReassignSupplierModal
              show={showReassignModal}
              hideModal={() => setShowReassignModal(false)}
            />
          </>
        )}
      </Modal.Body>
      {page === "accesslevel" && !isDefault && (
        <Modal.Footer className="d-flex justify-content-end">
          <Button
            className="hg-cancel-btn"
            onClick={() => setDiscardModal(true)}
          >
            Cancel
          </Button>
          <Button className="hg-blue-btn ml-2" onClick={createAccessLevel}>
            {saving ? (
              <div className="d-flex align-items-center">
                <WhiteLoader width={12} />
                Saving...
              </div>
            ) : (
              "Save"
            )}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default AccessLevelModal;

const DiscardModal = ({ show, hideModal }) => {
  return (
    <Modal
      show={show}
      dialogClassName="discard-modal"
      className=" "
      aria-labelledby="discard-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Body>
        <div className="pt-2 px-2">
          <div className="f-16 f-500">Discard changes?</div>
          <div className="f-black mt-3">
            You have unsaved changes. Do you want to discard them?
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end mt-4">
          <Button className="hg-cancel-btn mr-2" onClick={hideModal}>
            Cancel
          </Button>
          <Button className="hg-blue-btn" onClick={hideModal}>
            Discard changes
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export function makeGroup(list, key) {
  if (list?.length === 0) return;
  let resultArr = [];
  let types = [];
  for (let i = 0; i < list.length; i++) {
    if (types.indexOf(list[i][key]) > -1) {
      let indexPosition = types.indexOf(list[i][key]);
      resultArr[indexPosition]["data"].push(list[i]);
      resultArr[indexPosition]["Section"] = list[i][key];
    } else {
      types.push(list[i][key]);
      let indexPosition = types.indexOf(list[i][key]);
      if (typeof resultArr[indexPosition] === "object") {
        resultArr[indexPosition]["data"].push(list[i]);
        resultArr[indexPosition]["Section"] = list[i][key];
      } else {
        resultArr[indexPosition] = {};
        resultArr[indexPosition]["data"] = [];
        resultArr[indexPosition]["data"].push(list[i]);
        resultArr[indexPosition]["Section"] = list[i][key];
      }
    }
  }

  return resultArr;
}
