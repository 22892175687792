import React from "react";
import { Route, useHistory } from "react-router-dom/cjs/react-router-dom";
import SubscriptionRequests from "./requests/SubscriptionRequests";
import SubscriptionPortalFinance from "./finance/SubscriptionPortalFinance";
import SubscriptionOverview from "./overview/SubscriptionOverview";
import MspSubscriptionAccounts from "./mspAccountsSubscription/MspSubscriptionAccounts";
import MspSubscriptionAccountDetails from "./mspAccountsSubscription/MspSubscriptionAccountDetails";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import ImageWithInitials from "../../Utils/ImageWithInitials";
import { TrimString } from "../../Utils/StringTrimmer";
import { useAuth } from "../context/Auth";
import MspAccountOverview from "./mspAccountsSubscription/MspAccountOverview";
import SaasAccountOverview from "./saasAccountsSubscription/SaasAccountOverview";
import MspArchivedAccounts from "./mspAccountsSubscription/MspArchivedAccounts";
import SaasSubscriptionAccounts from "./saasAccountsSubscription/SaasSubscriptionAccounts";

const SubscriptionBackendHome = () => {
  const userDetails = useSelector((state) => state?.user?.userDetail);
  const history = useHistory();

  const { setAuth, authTokens } = useAuth();
  return (
    <div>
      <div
        className="w-100 bg-white border-bottom d-flex align-items-center justify-content-between"
        style={{
          position: "sticky",
          top: "0px",
          height: "56px",
          borderTop: "1px solid rgba(255, 255, 255, 0.08)",
          zIndex: 5,
        }}
      >
        <div
          className="d-flex align-items-center  f-500 ml-1 pointer"
          onClick={() => {}}
        ></div>
        <div
          className="  d-flex align-items-center pointer mr-3"
          onClick={() => {}}
        >
          <Dropdown className="transparennt-wrapper-dropdown">
            <Dropdown.Toggle>
              {userDetails.Avatar == "No URL" ? (
                <ImageWithInitials
                  width={31}
                  background="purple"
                  initials={userDetails?.Initials}
                />
              ) : (
                <img
                  alt=""
                  src={userDetails?.Avatar}
                  className="rounded-full"
                  style={{
                    width: "31px",
                    height: "31px",
                  }}
                />
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item disabled={true}>
                <div className="d-flex justify-content-between align-items-center ">
                  <div className="d-flex align-items-center">
                    {userDetails.Avatar == "No URL" ? (
                      <ImageWithInitials
                        width={31}
                        background="purple"
                        initials={userDetails?.Initials}
                      />
                    ) : (
                      <img
                        alt=""
                        src={userDetails?.Avatar}
                        className="rounded-full"
                        style={{
                          width: "31px",
                          height: "31px",
                        }}
                      />
                    )}

                    <div className="ml-2">
                      <p className="title m-0">
                        {TrimString(userDetails.FullName, 17)}
                      </p>
                      <p className="subtitle m-0">
                        {TrimString(userDetails.UserName, 17)}
                      </p>
                    </div>
                  </div>
                  <div>
                    {/* <img alt="" src="/images/chevron-right.svg" /> */}
                  </div>
                </div>
              </Dropdown.Item>
              <hr className="m-0" />
              <Dropdown.Item
                type="button py-2 my-1"
                onClick={() => {
                  window.localStorage.clear();
                  history.push({
                    pathname: "/login",
                  });
                  setAuth(false);
                }}
              >
                <img
                  alt=""
                  src="/images/settings-v2/logout.svg"
                  className="mr-2 v2-item-color"
                />
                <span className="f-grey-imp"> Logout</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      
      <Route
        exact
        path="/subscription-portal/msp-accounts"
        component={MspSubscriptionAccounts}
      />
      <Route
        exact
        path="/subscription-portal/msp-archived-accounts"
        component={MspArchivedAccounts}
      />
      <Route
        exact
        path="/subscription-portal/msp-archived-accounts/:accountId"
        component={MspSubscriptionAccountDetails}
      />
      <Route
        exact
        path="/subscription-portal/msp-accounts/:accountId"
        component={MspSubscriptionAccountDetails}
      />
      <Route
        exact
        path="/subscription-portal/saas-accounts"
        component={SaasSubscriptionAccounts}
      />
      <Route
        exact
        path="/subscription-portal/account-detail"
        component={MspSubscriptionAccountDetails}
      />
      <Route
        exact
        path="/subscription-portal/finance"
        component={SubscriptionPortalFinance}
      />
      <Route
        exact
        path="/subscription-portal/overview"
        component={SubscriptionOverview}
      />
      <Route
        exact
        path="/subscription-portal/requests"
        component={SubscriptionRequests}
      />
    </div>
  );
};

export default SubscriptionBackendHome;
