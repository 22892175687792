import React, { useState, useEffect } from "react";

// import { postData, getAPIData } from "../../../Utils/commonApi";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { postRequest as StopWelcomeModalApi } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
// import { useDb } from "../../context/db";
import "../../Integrations/Integrations.css";
import { CallToast } from "./CustomToast";

const SkipWelcomeModal = (props) => {
  const { authTokens } = useAuth();
  //   const { dbObj, setdbObject } = useDb();
  //   const [showChecked, setShowChecked] = useState(false);
  //   const [showUnchecked, setShowUnchecked] = useState(props.isChecked || "");

  //   useEffect(() => {
  //     if (props.isChecked) {
  //       onCheckedHandler();
  //     }
  //   }, [props.isChecked]);

  //   async function onCheckedHandler() {
  //     setShowUnchecked(false);
  //     setShowChecked(!showChecked);
  //     const result = await getAPIData("140", authTokens);
  //     if (result.Success == true) {
  //       localStorage.setItem("user_MessageObj", JSON.stringify(result.Message));
  //     }
  //   }
  const handleClose = () => {
    setTimeout(() => {
      props.isClose();
    }, 300);
  };

  const StopWelcomeModals = () => {
    StopWelcomeModalApi(authTokens, "SubmitNotificationSettings", {
      SettingOwner: 0,
      OwnerType: 1,
      NotificationName: "ShowPricingModal",
      NotificationValue: 0,
      AccessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((res) => {
        if (!res.Success) {
          CallToast("Something went wrong");
        }
      })
      .catch((err) => {
        CallToast("Something went wrong");
      });
  };
  return (
    <Modal
      show={props.isOpen}
      size="lg"
      dialogClassName="modal-50w"
      className="integrationModal centerModal dashboardModal cancelModalWidth"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => handleClose()}
    >
      <Modal.Body className="modalContent">
        <h1>Want to skip this just now?</h1>{" "}
        <div
          className="d-flex align-self-center mt16px"
          style={{ justifyContent: "center" }}
        >
          <Form.Group
            controlId="checkEleven"
            className="square-checkbox grey-checkboxs"
          >
            <Form.Check
              type="checkbox"
              id="switch5"
              //   checked={showUnchecked || showChecked}
              label=""
              style={{ marginTop: "12px", width: "20px", height: "19px" }}
              onClick={() => {
                StopWelcomeModals();
                handleClose();
              }}
            />
          </Form.Group>
          <span className="mt-3" style={{ paddingLeft: "5px" }}>
            Don't show this page again
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Row className="m-0" style={{ justifyContent: "space-between" }}>
          <Col md={4} className="p-0">
            <p className="cursor-pointer pt-3" onClick={() => handleClose()}>
              Cancel
            </p>
          </Col>

          <Col md={4} className="p-0">
            <Button
              style={{ padding: "9px 23px" }}
              variant="primary"
              type="submit"
              className="float-right"
              onClick={() => handleClose()}
            >
              Skip
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default SkipWelcomeModal;
