import React, { useState, useEffect, Fragment } from "react";
import { Redirect, Link } from "react-router-dom";

import { Col, Row, Container, Modal } from "react-bootstrap";
import "../../main.scss";
import "./CyberROIDetails.css";
import CyberCareContainer from "../CyberCareContainer/CyberCareContainer";
import { getAPIData } from "../../Utils/commonApi";
import { useAuth } from "../context/Auth";
import LoadingSpinner from "../Spinner/Spinner";
import ModalDynamicInvestment from "../ModalDynamic/ModalDynamic";
import ModalDynamicSavings from "../ModalDynamic/ModalDynamic";
import InvestmentDetails from "../InvestmentDetails/InvestmentDetails";
import SavingsDetails from "../CyberSavingsDetails/CyberSavingsDetails";
import { FiChevronRight } from "react-icons/fi";
import ContentLoader from "react-content-loader";

const CyberROIDetails = (props) => {
  const { authTokens, setAuthTokens } = useAuth();
  const [data, setData] = useState("");

  const [investmentModal, setinvestmentModal] = useState(false);
  const [savingsModal, setSavingsModal] = useState(false);

  const [roiData, setroiData] = useState("");

  function investmentModalDisplay() {
    setinvestmentModal(false);
    setInterval(() => {
      setinvestmentModal(true);
    }, 100);
  }

  function savingsModalDisplay() {
    setSavingsModal(false);
    setInterval(() => {
      setSavingsModal(true);
    }, 100);
  }

  async function getDataFromAPI() {
    const result = await getAPIData("20", authTokens,{optionEnum1:props.clientId ?? 0});
    if (result.authToken !== undefined) {
      setAuthTokens(result.authToken);
      setData();
      result = await getAPIData("20", result.authToken,{optionEnum1:props.clientId ?? 0});
    } else {
      setData({ result });
    }
  }

  useEffect(() => {
    getDataFromAPI();
  }, []);

  useEffect(() => {
    const cyberROIData = () => {
      if (data != "") {
        console.log(data.result.cyberROI);
        return (
          <Row className="background_container m-0" style={{ height: "168px" }}>
            <Col md={8} className="roi-table">
              <Row>
                <Col xs={4} className="text-center p-0">
                  <div className="roi_headings">Cyber Savings</div>
                  <div className="roi_subtext">£{data.result.savings}</div>
                </Col>
                <Col xs={1} style={{ marginTop: "auto" }}>
                  <h1 className="roi_splitter">-</h1>
                </Col>
                <Col xs={5} className="text-center p-0">
                  <div className="roi_headings">Cyber Investment</div>
                  <div className="roi_subtext">£{data.result.investment}</div>
                </Col>
              </Row>

              <Row>
                <Col xs={9}>
                  <hr className="roi-divider"></hr>
                </Col>
                <Col xs={3} className="p-0">
                  <p
                    className="roi_subtext"
                    style={{ margin: "0", marginTop: "0.3em" }}
                  >
                    X 100%
                  </p>
                </Col>
              </Row>

              <Row>
                <Col
                  xs={12}
                  className="text-center"
                  style={{ paddingRight: "4.5em" }}
                >
                  <div className="roi_headings">Cyber Investment</div>
                  <div className="roi_subtext">£{data.result.investment}</div>
                </Col>
              </Row>
            </Col>

            <Col md={{ span: 4 }} style={{ paddingRight: "0.5em" }}>
              <div
                className="mx-auto my-auto"
                style={{ height: "100%", padding: "0.5em 0" }}
              >
                <Col md={12} className="roi_background">
                  <span>
                    <div className="roi_greytext">Cyber ROI</div>
                    <div
                      className="main-heading"
                      style={{ display: "inline-block" }}
                    >
                      {"= " + data.result.cyberROI + "%"}
                    </div>
                  </span>
                </Col>
              </div>
            </Col>
          </Row>
        );
      }
    };
    setroiData(cyberROIData);
  }, [data]);

  const cyberROIIndex = (
    <div style={{ padding: "40px 24px" }}>
      <div className="p-0" xs={12}>
        <div>{roiData != "" ? roiData : ""}</div>
      </div>
    </div>
  );
  
  return (
    <Fragment>
      {investmentModal ? (
        <ModalDynamicInvestment
          setShow={true}
          modalSize="lg"
          modalHeader={"Cyber Investment Details"}
          modalData={<InvestmentDetails clientId={props.clientId}/>}
        />
      ) : (
        ""
      )}
      {savingsModal ? (
        <ModalDynamicSavings
          setShow={true}
          modalSize="lg"
          modalHeader={"Cyber Savings Details"}
          modalData={<SavingsDetails clientId={props.clientId} />}
        />
      ) : (
        ""
      )}
      {!data == "" && (
        <div className="csd_background fullHeight">
          {cyberROIIndex}

          <Row style={{ padding: "0 9px" }}>
            <Col xs={12}>
              <Col>
                <Link
                  className="cyberoi_links"
                  onClick={investmentModalDisplay}
                >
                  Check your Cyber Investment Details <FiChevronRight />
                </Link>
              </Col>
              <Col className="pt-4 pb-4">
                <Link className="cyberoi_links" onClick={savingsModalDisplay}>
                  Check your Cyber Savings Details <FiChevronRight />
                </Link>
              </Col>
            </Col>
          </Row>
        </div>
      )}

      {data == "" && (
        <div className="csd_background fullHeight">
          <ContentLoader
            speed={2}
            width={500}
            height={350}
            viewBox="0 0 500 350"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="30" y="54" rx="12" ry="12" width="450" height="170" />
            <rect x="30" y="250" rx="0" ry="0" width="301" height="23" />
            <rect x="30" y="300" rx="0" ry="0" width="301" height="23" />
          </ContentLoader>
        </div>
      )}
    </Fragment>
  );
  // } else {
  //   return <LoadingSpinner />;
  // }
};

export default CyberROIDetails;
