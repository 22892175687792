import React, { useState, useEffect } from "react";
import { Button, Dropdown } from "react-bootstrap";
import "./overview.scss";
import "../common.scss";
import { useAuth } from "../../context/Auth";
import { getAPIData } from "../../../Utils/commonApi";
const Overview = ({ progressData }) => {
  // const ProgressStatus = [
  //   { name: "CDSA", value: 50 },
  //   { name: "Assets List", value: 67 },
  //   { name: "Networks", value: 32 },
  //   { name: "Directory Services", value: 100 },
  //   { name: "Email", value: 84 },
  //   { name: "Fail Sharing", value: 18 },
  //   { name: "Cloud services", value: 94 },
  //   { name: "Communications", value: 61 },
  //   { name: "Data Protection", value: 53 },
  //   { name: "Organisation", value: 82 },
  //   { name: "Corporate Social", value: 46 },
  // ];

  function ToggleAccordion(e) {
    var parent = e.target.closest(".insight-drop");

    var panel = parent.querySelector(".panel");

    if (panel?.style?.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }

  const InsightsData = [
    {
      name: "3 users without MFA enabled",
      subtitle: "You have 3 users in Office 365 who do not have MFA enabled",
      list: [
        {
          name: "Brooklyn Simmons",
          subtitle: "MFA disabled",
          image: "/images/attack-surface/bs-initials.svg",
        },
        {
          name: "Esther Howard",
          subtitle: "MFA disabled",
          image: "/images/attack-surface/bs-initials.svg",
        },
        {
          name: "Bessie Cooper",
          subtitle: "MFA disabled",
          image: "/images/attack-surface/bs-initials.svg",
        },
      ],
    },
    {
      name: "Firewall's license has expired on 2 devices",
      subtitle:
        "You're Firewall's license has expired and is no longer performing Intrusion Detection",
      list: [
        {
          name: "HG-AUTOID_SUPER-Firewall-2",
          subtitle: "License expired on 10/07/22",
          image: null,
        },
        {
          name: "HG-AUTOID_SUPER-Firewall-1",
          subtitle: "License expired on 10/07/22",
          image: null,
        },
      ],
    },
    {
      name: "6 devices do not have Endpoint Protection",
      subtitle: "",
      list: [
        {
          name: "Brooklyn Simmons",
          subtitle: "MFA disabled",
          image: "/images/attack-surface/bs-initials.svg",
        },
        {
          name: "Esther Howard",
          subtitle: "MFA disabled",
          image: "/images/attack-surface/bs-initials.svg",
        },
        {
          name: "Bessie Cooper",
          subtitle: "MFA disabled",
          image: "/images/attack-surface/bs-initials.svg",
        },
      ],
    },
    {
      name: "Identified missing Internal network",
      subtitle: "",
      list: [
        {
          name: "Brooklyn Simmons",
          subtitle: "MFA disabled",
          image: "/images/attack-surface/bs-initials.svg",
        },
        {
          name: "Esther Howard",
          subtitle: "MFA disabled",
          image: "/images/attack-surface/bs-initials.svg",
        },
        {
          name: "Bessie Cooper",
          subtitle: "MFA disabled",
          image: "/images/attack-surface/bs-initials.svg",
        },
      ],
    },
    {
      name: "Identified unscanned External WAN IP",
      subtitle: "",
      list: [
        {
          name: "Brooklyn Simmons",
          subtitle: "MFA disabled",
          image: "/images/attack-surface/bs-initials.svg",
        },
        {
          name: "Esther Howard",
          subtitle: "MFA disabled",
          image: "/images/attack-surface/bs-initials.svg",
        },
        {
          name: "Bessie Cooper",
          subtitle: "MFA disabled",
          image: "/images/attack-surface/bs-initials.svg",
        },
      ],
    },
  ];

  return (
    <div className="attack-overview-wrapper w-100 p-3">
      <div className="overview-banner bg-grey radius-8 w-100 d-flex">
        <div
          className="w-50 p-4"
          style={{
            borderRight: "1px solid #EBEDF1",
          }}
        >
          <div className="f-500 f-grey mb-3">Attack surface analyze</div>

          <div className="all-progresses d-flex flex-column">
            {progressData?.slice(1).map((progress, index) => {
              return (
                <div className=" attack-progress mb-2">
                  <div className="f-grey">{progress?.Title} </div>
                  <div className="d-flex align-items-center">
                    <progress
                      min="0"
                      value={progress?.CompletionScore}
                      max="100"
                    />
                  </div>
                  <div className="f-darkgrey d-flex align-items-center justify-content-center">
                    {progress?.CompletionScore}%
                  </div>
                </div>
              );
            })}
          </div>
          <div className="" style={{}}></div>
        </div>
        <div className="w-50  d-flex flex-column">
          {/* <div className="shield-image">
            <div className="f-16 f-600">How could you be hacked?</div>
            <div>
              You can’t protect what you don’t know about. Continuous monitoring
              of your attack surface is essential to preventing cyber-attacks.
            </div>
            <Button className="learn-more-btn">Learn more</Button>
          </div> */}
          <img
            alt=""
            src="/images/attack-surface/overview-banner-image.svg"
            className="w-auto m-3"
          />

          <div className="asset-data d-flex flex-column w-100">
            <div className="w-100 d-flex">
              <div className="w-100 p-3">
                <div className="f-grey f-500 mb-2">Users</div>
                <div className="f-18 f-black f-500">78</div>
              </div>
              <div
                className="w-100 p-3"
                style={{ borderLeft: "1px solid #EBEDF1" }}
              >
                <div className="f-grey f-500 mb-2 ">Locations</div>
                <div className="f-18 f-black f-500">4</div>
              </div>
            </div>

            <div className="w-100 d-flex">
              <div
                className="w-100 p-3"
                style={{
                  borderTop: "1px solid #EBEDF1",
                  borderRight: "1px solid #EBEDF1",
                }}
              >
                <div className="f-grey f-500 mb-2">Assets</div>
                <div className="f-18 f-black f-500">310</div>
              </div>
              <div
                className="w-100 p-3"
                style={{ borderTop: "1px solid #EBEDF1" }}
              >
                <div className="f-grey f-500 mb-2">Cloud Services</div>
                <div className="f-18 f-black f-500">40</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="insights-wrapper w-100">
        <div className="f-600 f-black mt-4">Insights</div>

        <div className="bg-grey radius-8 border-parent">
          <div className="border-item d-flex align-items-center p-2">
            <div className="f-blue f-500 mr-3">
              Active
              <span className="p-1 bg-darkgrey rounded-circle f-12 ml-1">
                8
              </span>
            </div>
            <div className="f-grey f-500 mr-3">
              Suppressed
              <span className="p-1 bg-darkgrey rounded-circle f-12 ml-1">
                6
              </span>
            </div>
            <div className="f-grey f-500 mr-3">Closed</div>
          </div>
          {/* Data */}

          {InsightsData.map((item, index) => {
            return (
              <div
                className="border-item insight-drop"
                onClick={ToggleAccordion}
              >
                <div className="d-flex align-items-center justify-content-between w-100 pointer px-4  py-3 bg-hover-row">
                  <div className="d-flex flex-column " style={{ width: "75%" }}>
                    <div className="d-flex align-items-baseline ">
                      <img
                        alt=""
                        src="/images/attack-surface/blue-dot.svg"
                        className="mr-3"
                      />

                      <p className="f-500 m-0">{item?.name} </p>
                    </div>
                    <p className="f-12 f-darkgrey ml-2">{item?.subtitle}</p>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div
                      className="insights-notification-dropdown"
                      style={{
                        display: `${index == 0 ? "block" : "none"}`,
                      }}
                    >
                      <Dropdown onToggle={() => {}}>
                        <Dropdown.Toggle className="dropdown-bg-transparent">
                          <img
                            alt=""
                            src="/images/attack-surface/notification-icon.svg"
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            className="onactive-bg-transparent"
                            onClick={() => {}}
                          >
                            1 Day
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="onactive-bg-transparent"
                            type="button"
                            onClick={(event) => {}}
                          >
                            2 Days
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="onactive-bg-transparent"
                            type="button"
                            onClick={(event) => {}}
                          >
                            3 Days
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="onactive-bg-transparent"
                            type="button"
                            onClick={(event) => {}}
                          >
                            1 Week
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="onactive-bg-transparent"
                            type="button"
                            onClick={(event) => {}}
                          >
                            2 Weeks
                          </Dropdown.Item>

                          <Dropdown.Item
                            className="onactive-bg-transparent"
                            onClick={() => {}}
                          >
                            1 Month
                          </Dropdown.Item>
                          <hr className="mt-1 m-0 mb-1" />
                          <Dropdown.Item
                            className="f-danger-imp onactive-bg-transparent"
                            onClick={() => {}}
                          >
                            Forever
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <img alt="" src="/images/attack-surface/chevron-down.svg" />
                  </div>
                </div>
                <div className="panel" onClick={(e) => e.stopPropagation()}>
                  {item?.list?.map((listItem, index) => {
                    return (
                      <div className="d-flex align-items-center justify-content-between w-auto pointer p-3 ml-4 mr-3 insights-hover-effect">
                        <div
                          className="d-flex align-items-center"
                          style={{ width: "75%" }}
                        >
                          {listItem?.image && (
                            <img
                              alt=""
                              src={listItem?.image}
                              className="mr-2"
                            />
                          )}
                          <div className="d-flex flex-column ">
                            <p className="f-500 m-0">{listItem?.name}</p>

                            <p className="f-12 f-darkgrey ml-2">
                              {listItem?.subtitle}
                            </p>
                          </div>
                        </div>
                        <div className="" onClick={(e) => e.stopPropagation()}>
                          <img
                            alt=""
                            src="/images/attack-surface/arrow-right.svg"
                            className="arrow"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
          <div className="border-item d-flex align-items-center p-2 f-grey f-12">
            <img
              alt=""
              src="/images/attack-surface/info-icon.svg"
              className="mr-2 ml-3"
            />
            Insights automatically update every 24 hours. The next update will
            be in 4h 20m
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
