import React, { useEffect, useState } from "react";
import { getAPIData } from "../../../Utils/commonApi";
import TrendGraph from "../../ActionCenter/mentiontest";
import { useAuth } from "../../context/Auth";
import CyberCompliance from "../../CyberCompliance/CyberCompliance";
// import CyberScore from "../../CyberScore/CyberScore";
import CyberScore2 from "../../CyberScore/CyberScore2";
import ThreatLevel from "../../ThreatLevel/ThreatLevel";
import "./supplycompany.scss";

const SupplyTrendData = ({ accessLevel, supplierId }) => {
  const { authTokens } = useAuth();

  const [cyberScoreData, setCyberScoreData] = useState({});
  const [cyberComplianceData, setcyberComplianceData] = useState({});
  const [cyberThreatData, setCyberThreatData] = useState({});

  // 0= weekly
  // 1= Monthly
  // 2= Yearly

  useEffect(() => {
    if (accessLevel !== null && accessLevel > 0) {
      getPageData(2, 412);
      getPageData(2, 413);
      getPageData(2, 414);
    }
  }, [accessLevel]);

  // pageEnums used= 412,413,414

  async function getPageData(period, pageEnum) {
    await getAPIData(`${pageEnum}`, authTokens, {
      optionEnum1: period,
      optionEnum2: supplierId,
      optionEnum3: accessLevel,
    }).then((response) => {
      if (response?.mr?.Success) {
        if (pageEnum === 412) setCyberScoreData(response);

        if (pageEnum === 414) setcyberComplianceData(response);

        if (pageEnum === 413) setCyberThreatData(response);
      }
    });
  }

  return (
    <>
      {accessLevel === 0 ? (
        <></>
      ) : (
        <div className="mt-4">
          <div
            className="d-flex align-items-cente justify-content-between hg-border radius-8 p-2 my-2 w-100"
            // style={{ height: "300px" }}
          >
            <div
              className="w-1 0 0 h-100 d-flex flex-column"
              style={{ width: "30%" }}
            >
              <div className="f-16 f-500 ml-3 my-3">
                CyberScore: {cyberScoreData?.CurrentValue}
              </div>
              <div
                className="c-score-supply-company mx-auto"
                style={{
                  width: "74%",
                  // height: "100%",
                  maxWidth: "270px",
                }}
              >
                {/* <CyberScore2
              key={10}
              size="small"
              scoreNumber={cyberScoreData?.CurrentValue}
              dataStroke={15}
              bottomText={false}
              Width="150"
              Height="150"
            /> */}
                <CyberScore2
                  key={0}
                  size="small"
                  scoreNumber={cyberScoreData?.CurrentValue || 0}
                  dataStroke={18}
                  bottomText={false}
                  Width="150"
                  Height="150"
                  hideLabels={false}
                />
              </div>
            </div>

            <div style={{ width: "70%" }}>
              <TrendGraph
                color={
                  cyberScoreData?.BaseColour
                    ? cyberScoreData?.BaseColour
                    : "#FFAC2C"
                }
                graphData={cyberScoreData?.Points}
                pageEnum={412}
                getData={getPageData}
              />
            </div>
          </div>
          <div className="d-flex align-items-cente justify-content-between hg-border radius-8 p-2  my-2  w-100">
            <div className="w-1 00" style={{ width: "30%" }}>
              <div className="f-16 f-500 ml-3 my-3">
                CyberCompliance: {cyberComplianceData?.CurrentValue}%
              </div>
              <div className="w-50 d-flex align-items-center justify-content-start ml-5 mt-5 mx-auto">
                <CyberCompliance
                  wh="160"
                  dataStroke="18"
                  whpx="160px"
                  ccNumber={
                    cyberComplianceData?.CurrentValue
                    // compData.ComplianceScore == 0
                    //   ? "-2"
                    //   : compData.ComplianceScore
                  }
                  fontSize={19}
                />
              </div>
            </div>
            <div style={{ width: "70%" }}>
              <TrendGraph
                color={
                  cyberComplianceData?.BaseColour
                    ? cyberComplianceData?.BaseColour
                    : "#FFAC2C"
                }
                graphData={cyberComplianceData?.Points}
                pageEnum={414}
                getData={getPageData}
              />
            </div>
          </div>
          <div className="d-flex align-items-cente justify-content-between hg-border radius-8 p-2  my-2  w-100">
            <div className="w-1 0 0" style={{ width: "30%" }}>
              <div className="f-16 f-500 my-3 ml-3">
                CyberThreat Level: {cyberThreatData?.CurrentValue}
              </div>
              <div className="w-50 d-flex align-items-center justify-content-start ml-5 mt-5">
                <ThreatLevel
                  padding={false}
                  threatHeight="160px"
                  threatText={50}
                  removeBottom={true}
                  threatNumber={cyberThreatData?.CurrentValue}
                />
              </div>
            </div>
            <div style={{ width: "70%" }}>
              <TrendGraph
                color={
                  cyberThreatData?.BaseColour
                    ? cyberThreatData?.BaseColour
                    : "#F36060"
                }
                graphData={cyberThreatData?.Points}
                pageEnum={413}
                getData={getPageData}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SupplyTrendData;
