import React, { useEffect, useState } from "react";

const CyberComplianceGauge = ({value}) => {

    const [gaugeValue, setGaugeValue] = useState(value)

    useEffect(() => {
        let _value = Math.max(0, Math.min(100, value));
      // calculate the percentage of the value in the range of 0-100
  var percentage = (_value - 0) / (100 - 0);

  // map the percentage to the range of 100-20
  var newValue = (1 - percentage) * (100 - 20) + 20;
  setGaugeValue(Math.floor(newValue))
    }, [value])
    // 100- zero
    // 20- full
    
  return (
    <div className="w-auto position-relative">
      <div
        className="position-absolute d-flex align-items-center justify-content-center gauge-graph-text"
        style={{
          boxShadow: "0 11px 42px rgba(0, 143, 212, 0.3)",
        }}
      >
        {value}%
      </div>
      <div
        className="f-12 f-600 f-black position-absolute "
        style={{
          left: "50%",
          transform: "translateX(-50%)",
          bottom: "-4px",
        }}
      >
        CyberCompliance
      </div>
      <svg width="140" height="140" viewBox="0 0 140 ">
        <circle
          stroke-dasharray="100"
          stroke-dashoffset="20"
          className="circle_animation_unfilled"
          r="50"
          cy="70"
          cx="70"
          strokeWidth="14"
          stroke="#ebecf1"
          fill="none"
          pathLength="100"
          transform="rotate(125, 70, 70)"
        />
        <circle
          stroke-dasharray="100"
          strokeDashoffset={gaugeValue}
          className=""
          r="50"
          cy="70"
          cx="70"
          strokeWidth="14"
          stroke="#008fd4"
          fill="none"
          pathLength="100"
          transform="rotate(125, 70, 70)"
        />
      </svg>
    </div>
  );
};

export default CyberComplianceGauge;


export const CustomCyberComplianceGauge = ({value ,hideComplianceText, hidePercentIcon,width}) => {

  const [gaugeValue, setGaugeValue] = useState(value)

  useEffect(() => {
      let _value = Math.max(0, Math.min(100, value));
    // calculate the percentage of the value in the range of 0-100
var percentage = (_value - 0) / (100 - 0);

// map the percentage to the range of 100-20
var newValue = (1 - percentage) * (100 - 20) + 20;
setGaugeValue(Math.floor(newValue))
  }, [value])
  // 100- zero
  // 20- full
  
return (
  <div className="w-auto position-relative">
    <div
      className="position-absolute d-flex align-items-center justify-content-center gauge-graph-text"
      style={{
        boxShadow: "0 11px 42px rgba(0, 143, 212, 0.3)",
         width: `${(width/2)+5}px`,
         height: `${(width/2)+5}px`,
      }}
    >
      {value}{ !hidePercentIcon && "%"}
    </div>
 {!hideComplianceText &&   <div
      className="f-12 f-600 f-black position-absolute "
      style={{
        left: "50%",
        transform: "translateX(-50%)",
        bottom: "-4px",
      }}
    >
      CyberCompliance
    </div>}
    <svg width={width} height={width} viewBox={`0 0 ${width} `}>
      <circle
        stroke-dasharray="100"
        stroke-dashoffset="20"
        className="circle_animation_unfilled"
        r={(width/2)-20}
        cy={width/2}
        cx={width/2}
        strokeWidth="14"
        stroke="#ebecf1"
        fill="none"
        pathLength="100"
        transform={`rotate(125, ${width/2}, ${width/2})`}
      />
      <circle
        stroke-dasharray="100"
        strokeDashoffset={gaugeValue}
        className=""
        r={(width/2)-20}
        cy={width/2}
        cx={width/2}
        strokeWidth="14"
        stroke="#008fd4"
        fill="none"
        pathLength="100"
        transform={`rotate(125, ${width/2}, ${width/2})`}
      />
    </svg>
  </div>
);
};