import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Row, Col, Dropdown, Badge, Button, Form } from "react-bootstrap";
// import ImageWithInitials from "../../../Utils/ImageWithInitials";
// import DatePicker from "../../Theme/datepicker";
// import moment from "moment";
import "./dropdown.scss";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { addEventOnScroll } from "../../../Utils/CommonFunctions";
import { TrimString } from "../../../Utils/StringTrimmer";

const DropDownFilter = forwardRef((
  {   activeFilter,
    intialFilters,
    supplierFilters,
    setSupplierFilters,
    show,
    toggleFilter,
  children },
  ref
) => {
  
  const { authTokens } = useAuth();

  useEffect(() => {
    getFilterList();
    // setValuesToFilterFromLocalStorage();
  }, []);

  const [inputTypeValues, setInputTypeValues] = useState(InputTypeData);

  // Filters Setup
  const [supplierStatusList, setSupplierStatusList] = useState([]);
  const [selectedSupplierList, setSelectedSupplierList] = useState({});

  const [supplierAnnualSpendList, setSupplierAnnualSpendList] = useState([]);
  const [selectedSupplierAnnualSpend, setSelectedSupplierAnnualSpend] =
    useState({});

  const [supplierImportanceList, setSupplierImportanceList] = useState([]);
  const [selectedSupplierImportance, setSelectedSupplierImportance] = useState(
    {}
  );

  const [supplierIndustryList, setSupplierIndustryList] = useState([]);
  const [selectedSupplierIndustry, setSelectedSupplierIndustry] = useState({});

  const [supplierServicesProvidedList, setSupplierServicesProvidedList] =
    useState([]);
  const [
    selectedSupplierServicesProvided,
    setSelectedSupplierServicesProvided,
  ] = useState({});

  const [supplierTypeList, setSupplierTypeList] = useState([]);
  const [selectedSupplierType, setSelectedSupplierType] = useState({});

  const [complianceProfileList, setComplianceProfileList] = useState([]);
  const [selectedComplianceProfile, setselectedComplianceProfile] = useState(
    []
  );
  async function setValuesToFilterFromLocalStorage() {
    const filtersInlocalStorage =
      window.localStorage.getItem("supplier-filters");
    if (filtersInlocalStorage) {
      let filtersObject = JSON.parse(filtersInlocalStorage);

      // Update Inputs
      let updated_inputs = [...inputTypeValues].map((inputs) => {
        return {
          ...inputs,
          value: filtersObject[inputs.type],
        };
      });
      setInputTypeValues(updated_inputs);

      // Update Supplier Status

      if (filtersObject["SupplierStatus"] !== "") {
        console.log(supplierStatusList);
        let Index = supplierStatusList.findIndex(
          (el) => el.Text === filtersObject["SupplierStatus"]
        );
        if (Index !== -1) {
          setSelectedSupplierList(supplierStatusList[Index]);
        }
      }

      // Update Supplier Annual Spend
      if (filtersObject["SupplierAnnualSpend"] !== "") {
        let Index = supplierAnnualSpendList.findIndex(
          (el) => el.Text === filtersObject["SupplierAnnualSpend"]
        );
        if (Index !== -1) {
          setSelectedSupplierAnnualSpend(supplierAnnualSpendList[Index]);
        }
      }
      // Update Supplier Importance
      if (filtersObject["SupplierImportance"] !== "") {
        let Index = supplierImportanceList.findIndex(
          (el) => el.Text === filtersObject["SupplierImportance"]
        );
        if (Index !== -1) {
          setSelectedSupplierImportance(supplierImportanceList[Index]);
        }
      }
      // Update Supplier Industry
      if (filtersObject["SupplierIndustry"] !== "") {
        let Index = supplierIndustryList.findIndex(
          (el) => el.Text === filtersObject["SupplierIndustry"]
        );
        if (Index !== -1) {
          setSelectedSupplierIndustry(supplierIndustryList[Index]);
        }
      }

      // Supplier Services Provided
      if (filtersObject["SupplierServicesProvided"] !== "") {
        let Index = supplierServicesProvidedList.findIndex(
          (el) => el.Text === filtersObject["SupplierServicesProvided"]
        );

        if (Index !== -1) {
          setSelectedSupplierServicesProvided(
            supplierServicesProvidedList[Index]
          );
        }
      }
      // Update Supplier Type
      if (filtersObject["SupplierType"] !== "") {
        let Index = supplierTypeList.findIndex(
          (el) => el.Text === filtersObject["SupplierType"]
        );

        if (Index !== -1) {
          setSelectedSupplierType(supplierTypeList[Index]);
        }
      }
      // Update Compliance Profile List
      if (filtersObject["ComplianceProfile_List"] !== "") {
        if (filtersObject["ComplianceProfile_List"]?.split(",")?.length > 0) {
          let split_data = filtersObject["ComplianceProfile_List"]?.split(",");
          let updated_data = [...selectedComplianceProfile];
          for (let i = 0; i < split_data?.length; i++) {
            let Index = complianceProfileList.findIndex(
              (el) => el.Text === split_data[i]
            );

            if (Index !== -1) {
              let itemIndex = updated_data.findIndex(
                (el) => el.Text === split_data[i]
              );
              if (itemIndex === -1) {
                updated_data.push(complianceProfileList[Index]);
                console.log("data to profile", updated_data);
              }
            }
          }
          console.log("data outside", updated_data);
          setselectedComplianceProfile(updated_data);
        }
      }
    }
  }
  const valueForInputs = inputTypeValues.map((field) => {
    return { [field["type"]]: field["value"] };
  });
  const filter_request_api = {
    ...valueForInputs[0],
    ...valueForInputs[1],
    ...valueForInputs[2],
    ...valueForInputs[3],
    ...valueForInputs[4],
    SupplierStatus: selectedSupplierList?.Text,

    SupplierAnnualSpend: selectedSupplierAnnualSpend?.Text,
    SupplierImportance: selectedSupplierImportance?.Text,
    SupplierIndustry: selectedSupplierIndustry?.Text,
    SupplierServicesProvided: selectedSupplierServicesProvided?.Text,
    SupplierType: selectedSupplierType?.Text,
    ComplianceProfile_List: [
      ...selectedComplianceProfile?.map((plist) => {
        return plist?.Text;
      }),
    ].join(","),
  };

  async function getFilteredData() {
    setSupplierFilters(JSON.stringify(filter_request_api));
    window.localStorage.setItem(
      "supplier-filters",
      JSON.stringify(filter_request_api)
    );
  }

  async function getFilterList() {
    await getAPIData(`${316}`, authTokens, {}).then((response) => {
      if (response?.mr?.Success) {
        for (let i = 0; i < response?.Filters?.length; i++) {
          setFiltersList(
            response?.Filters?.[i]?.FilterType,
            response?.Filters?.[i]?.Filter_List
          );
        }
      }
    });
  }
  useImperativeHandle(ref, () => ({
    refreshFilters( ) {
      getFilterList()
    },
  }));

  function setFiltersList(type, list) {
    switch (type) {
      case "SupplierAnnualSpend":
        setSupplierAnnualSpendList(list);
        break;
      case "SupplierImportance":
        setSupplierImportanceList(list);
        break;
      case "SupplierIndustry":
        setSupplierIndustryList(list);
        break;
      case "SupplierServicesProvided":
        setSupplierServicesProvidedList(list);
        break;
      case "SupplierType":
        setSupplierTypeList(list);
        break;
      case "SupplierStatus":
        setSupplierStatusList(list);
        break;
      case "ComplianceProfile_List":
        setComplianceProfileList(list);
        break;
      default:
      // code block
    }
  }

  function ResetFilters() {
    setSelectedSupplierType({});
    setselectedComplianceProfile([]);
    setSelectedSupplierServicesProvided({});
    setSelectedSupplierIndustry({});
    setSelectedSupplierImportance({});
    setSelectedSupplierAnnualSpend({});
    setSelectedSupplierList({});
    let updated_data = inputTypeValues.map((values) => {
      return { ...values, value: "" };
    });
    setInputTypeValues(updated_data);

    setSupplierFilters(intialFilters);
    window.localStorage.removeItem("supplier-filters");
  }

  useEffect(() => {
    if (show) {
      setValuesToFilterFromLocalStorage();
    }
  }, [show]);

  return (
    <div className="dropdownfilter supply-chain-filter">
      <Dropdown
        className="input_custom_dropdown"
        show={show}
        onToggle={(e) => {
          console.log(e);
          if (e) {
          }
        }}
      >
        <Dropdown.Toggle
          className={`p-0 position-relative filter_toggle ${
            activeFilter ? "active-dropdown-toggle" : ""
          }`}
          style={{ zIndex: 1 }}
          onClick={toggleFilter}
        >
          {children}
        </Dropdown.Toggle>

        <Dropdown.Menu className="BillingInfo" style={{ height: "150px" }}>
          <Row>
            <Col
              xs={6}
              className="mt-2 mb-2 pr-0 d-flex align-items-center greyText"
            >
              Filters
            </Col>
            <Col xs={6} className="mt-2 mb-2 d-flex text-right">
              <span
                className="w-100 txt-blue pointer"
                onClick={() => {
                  ResetFilters();
                }}
              >
                Reset
              </span>
            </Col>
            {/* Owner dropdown */}
            {inputTypeValues.map((item, itemIndex) => {
              return (
                <>
                  <Col xs={6} className=" pr-0 d-flex align-items-center my-2">
                    {item.name}
                  </Col>
                  <Col xs={6} className="pl-0 d-flex align-items-center">
                    <input
                      placeholder="Type"
                      type={`${item?.inputType}`}
                      className="border-none pl-2"
                      value={item.value}
                      onChange={(e) => {
                        let updated_data = [...inputTypeValues];
                        updated_data[itemIndex].value = e.target.value;
                        setInputTypeValues(updated_data);
                      }}
                    />
                  </Col>
                </>
              );
            })}
            {/* Supplier Status */}
            <Col
              xs={6}
              className="d-flex justify-content-start align-items-center "
            >
              Supplier Status
            </Col>
            <Col xs={6} className="mt-2 pl-0 ">
              <Dropdown className="input_custom_dropdown">
                <Dropdown.Toggle
                  className="p-0 position-relative"
                  style={{ zIndex: 1 }}
                >
                  {Object.keys(selectedSupplierList).length > 0 ? (
                    <div className="dropdown_input drop_border">
                      <Badge className="custombadge pl-2">
                        {selectedSupplierList.Text}
                        <img
                          alt=""
                          src="/images/actioncenter/cross.svg"
                          className="crossimg "
                          id="restrict"
                          onClick={() => {
                            setSelectedSupplierList({});
                          }}
                        />
                      </Badge>
                    </div>
                  ) : (
                    <input
                      type="text"
                      className="dropdown_input"
                      value=""
                      placeholder="Select"
                    />
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className="dropfilterchild"
                  style={{ height: "150px" }}
                >
                  {supplierStatusList?.map((status, i) => (
                    <div
                      className="child_drop"
                      key={i}
                      onClick={(e) => {
                        e.target.closest(".input_custom_dropdown").click();
                        setSelectedSupplierList(status);
                      }}
                    >
                      {status?.Text}
                    </div>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            {/* Supplier Annual Spend */}
            <Col
              xs={6}
              className="d-flex justify-content-start align-items-center "
            >
              Supplier Annual Spend
            </Col>
            <Col xs={6} className="pl-0 d-flex align-items-center my-2">
                    <input
                      placeholder="Type"
                      type={`number`}
                      className="border-none pl-2"
                      value={selectedSupplierAnnualSpend?.Text  ??   ""}
                      onChange={(e) => {
                        setSelectedSupplierAnnualSpend({
                          "Id":0 ,
                          "Text": e.target.value,
                          "SortOrder": 1
                        });
                      }}
                    />
                  </Col>
            <Col xs={6} className="mt-2 pl-0 d-none ">
              <Dropdown className="input_custom_dropdown">
                <Dropdown.Toggle
                  className="p-0 position-relative"
                  style={{ zIndex: 1 }}
                >
                  {Object.keys(selectedSupplierAnnualSpend).length > 0 ? (
                    <div className="dropdown_input drop_border">
                      <Badge className="custombadge pl-2">
                        {selectedSupplierAnnualSpend?.Text}
                        <img
                          alt=""
                          src="/images/actioncenter/cross.svg"
                          className="crossimg "
                          id="restrict"
                          onClick={() => {
                            setSelectedSupplierAnnualSpend({});
                          }}
                        />
                      </Badge>
                    </div>
                  ) : (
                    <input
                      type="text"
                      className="dropdown_input"
                      value=""
                      placeholder="Select"
                    />
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className="dropfilterchild"
                  style={{ height: "150px" }}
                >
                  {supplierAnnualSpendList?.map((annualSpend, i) => (
                    <div
                      className="child_drop"
                      key={i}
                      onClick={(e) => {
                        setSelectedSupplierAnnualSpend(annualSpend);
                        e.target.closest(".input_custom_dropdown").click();
                      }}
                    >
                      {annualSpend?.Text}
                    </div>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            {/* Supplier Importance */}
            <Col
              xs={6}
              className="d-flex justify-content-start align-items-center "
            >
              Supplier Importance
            </Col>
            <Col xs={6} className="mt-2 pl-0 ">
              <Dropdown className="input_custom_dropdown">
                <Dropdown.Toggle
                  className="p-0 position-relative"
                  style={{ zIndex: 1 }}
                >
                  {Object.keys(selectedSupplierImportance).length > 0 ? (
                    <div className="dropdown_input drop_border">
                      <Badge className="custombadge pl-2">
                        {selectedSupplierImportance?.Text}
                        <img
                          alt=""
                          src="/images/actioncenter/cross.svg"
                          className="crossimg "
                          id="restrict"
                          onClick={() => {
                            setSelectedSupplierImportance({});
                          }}
                        />
                      </Badge>
                    </div>
                  ) : (
                    <input
                      type="text"
                      className="dropdown_input"
                      value=""
                      placeholder="Select"
                    />
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className="dropfilterchild"
                  style={{ height: "150px" }}
                >
                  {supplierImportanceList?.map((supplierImportance, i) => (
                    <div
                      className="child_drop"
                      key={i}
                      onClick={(e) => {
                        setSelectedSupplierImportance(supplierImportance);
                        e.target.closest(".input_custom_dropdown").click();
                      }}
                    >
                      {supplierImportance?.Text}
                    </div>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            {/* Supplier Industry */}
            <Col
              xs={6}
              className="d-flex justify-content-start align-items-center "
            >
              Supplier Industry
            </Col>
            <Col xs={6} className="mt-2 pl-0 ">
              <Dropdown className="input_custom_dropdown">
                <Dropdown.Toggle
                  className="p-0 position-relative"
                  style={{ zIndex: 1 }}
                >
                  {Object.keys(selectedSupplierIndustry).length > 0 ? (
                    <div className="dropdown_input drop_border">
                      <Badge className="custombadge pl-2">
                        {TrimString(selectedSupplierIndustry?.Text,15)}
                        <img
                          alt=""
                          src="/images/actioncenter/cross.svg"
                          className="crossimg "
                          id="restrict"
                          onClick={() => {
                            setSelectedSupplierIndustry({});
                          }}
                        />
                      </Badge>
                    </div>
                  ) : (
                    <input
                      type="text"
                      className="dropdown_input"
                      value=""
                      placeholder="Select"
                    />
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className="dropfilterchild"
                  style={{ height: "150px" }}
                >
                  {supplierIndustryList?.map((supplierIndustry, i) => (
                    <div
                      className="child_drop"

                      key={i}
                      onClick={(e) => {
                        setSelectedSupplierIndustry(supplierIndustry);
                        e.target.closest(".input_custom_dropdown").click();
                      }}
                    >
                      {supplierIndustry?.Text}
                    </div>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            {/* Supplier servuces provided */}
            <Col
              xs={6}
              className="d-flex justify-content-start align-items-center "
            >
              Supplier Services Provider
            </Col>
            <Col xs={6} className="mt-2 pl-0 ">
              <Dropdown className="input_custom_dropdown">
                <Dropdown.Toggle
                  className="p-0 position-relative"
                  style={{ zIndex: 1 }}
                >
                  {Object.keys(selectedSupplierServicesProvided).length > 0 ? (
                    <div className="dropdown_input drop_border">
                      <Badge className="custombadge pl-2">
                        {TrimString(selectedSupplierServicesProvided?.Text,15)}
                        <img
                          alt=""
                          src="/images/actioncenter/cross.svg"
                          className="crossimg "
                          id="restrict"
                          onClick={() => {
                            setSelectedSupplierServicesProvided({});
                          }}
                        />
                      </Badge>
                    </div>
                  ) : (
                    <input
                      type="text"
                      className="dropdown_input"
                      value=""
                      placeholder="Select"
                    />
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className="dropfilterchild"
                  style={{ height: "150px" }}
                >
                  {supplierServicesProvidedList?.map((servicesProvided, i) => (
                    <div
                      className="child_drop"
                      key={i}
                      onClick={(e) => {
                        setSelectedSupplierServicesProvided(servicesProvided);
                        e.target.closest(".input_custom_dropdown").click();
                      }}
                    >
                      {servicesProvided?.Text}
                    </div>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            {/* Supplier Type */}
            <Col
              xs={6}
              className="d-flex justify-content-start align-items-center "
            >
              Supplier Type
            </Col>
            <Col xs={6} className="mt-2 pl-0 ">
              <Dropdown className="input_custom_dropdown">
                <Dropdown.Toggle
                  className="p-0 position-relative"
                  style={{ zIndex: 1 }}
                >
                  {Object.keys(selectedSupplierType).length > 0 ? (
                    <div className="dropdown_input drop_border">
                      <Badge className="custombadge pl-2">
                        {TrimString(selectedSupplierType?.Text,15)}
                        <img
                          alt=""
                          src="/images/actioncenter/cross.svg"
                          className="crossimg "
                          id="restrict"
                          onClick={() => {
                            setSelectedSupplierType({});
                          }}
                        />
                      </Badge>
                    </div>
                  ) : (
                    <input
                      type="text"
                      className="dropdown_input"
                      value=""
                      placeholder="Select"
                    />
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className="dropfilterchild"
                  style={{ height: "150px" }}
                >
                  {supplierTypeList?.map((supplierType, i) => (
                    <div
                      className="child_drop"
                      key={i}
                      onClick={(e) => {
                        setSelectedSupplierType(supplierType);
                        e.target.closest(".input_custom_dropdown").click();
                      }}
                    >
                      {supplierType?.Text}
                    </div>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            {/* Compliance Profiles List */}
            <Col
              xs={6}
              className="d-flex justify-content-start align-items-center "
            >
              Compliance Profile
            </Col>
            <Col xs={6} className="mt-2 pl-0 ">
              <Dropdown className="input_custom_dropdown restricted-modal" onClick={(e)=>addEventOnScroll(e,".restricted-modal","white-faded-dropdown-bottom")}>
                <Dropdown.Toggle
                  className="p-0 position-relative"
                  style={{ zIndex: 1 }}
                  
                >
                  {selectedComplianceProfile.length > 0 ? (
                    <>
                      {selectedComplianceProfile?.map(
                        (profile, profileIndex) => {
                          return (
                            <div className="d-flex flex-wrap">
                              <div className="dropdown_input drop_border">
                                <Badge className="custombadge pl-2">
                                  {profile?.Text}
                                  <img
                                    alt=""
                                    src="/images/actioncenter/cross.svg"
                                    className="crossimg "
                                    id="restrict"
                                    onClick={() => {
                                      let updated_data = [
                                        ...selectedComplianceProfile,
                                      ];
                                      updated_data.splice(profileIndex, 1);
                                      setselectedComplianceProfile(
                                        updated_data
                                      );
                                    }}
                                  />
                                </Badge>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </>
                  ) : (
                    <input
                      type="text"
                      className="dropdown_input"
                      value=""
                      placeholder="Select"
                    />
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className="dropfilterchild fade_on_scroll"
                  // style={{ height: "150px" }}
                  style={{
                    maxHeight: "225px",
                    // overflowY: "scroll",
                    overflow: "hidden",
                  }}
                >



<div
                              className="hide-scrollbar position-relative black-blur-div"
                              style={{
                                maxHeight: "216px",
                                overflowY: "scroll",
                                overflowX: "hidden",
                              }}
                            >
                  {complianceProfileList?.map((item, i) => {
                    let Index = selectedComplianceProfile.findIndex(
                      (elm) => elm.Id === item.Id
                    );
                    return (
                      <div
                        className="child_drop"
                        key={i}
                        onClick={(e) => {
                          // e.target.closest(".input_custom_dropdown").click();
                          // if (Index === -1) {
                          //   setselectedComplianceProfile([
                          //     ...selectedComplianceProfile,
                          //     item,
                          //   ]);
                          // }
                          if (Index === -1) {
                            setselectedComplianceProfile([
                              ...selectedComplianceProfile,
                              item,
                            ]);
                          } else {
                            let updated_data = [
                              ...selectedComplianceProfile,
                            ];
                            updated_data.splice(Index, 1);
                            setselectedComplianceProfile(updated_data);
                          }
                        }}
                      >
                        <div>
                          <Form.Group
                            controlId={"check" + i}
                            className="round-checkbox blue-checkbox checkboxstyle mr-2"
                          >
                            <Form.Check
                              type="checkbox"
                              name={"check" + i}
                              label=""
                              checked={Index > -1}
                              onChange={(e) => {
                                if (Index === -1) {
                                  setselectedComplianceProfile([
                                    ...selectedComplianceProfile,
                                    item,
                                  ]);
                                } else {
                                  let updated_data = [
                                    ...selectedComplianceProfile,
                                  ];
                                  updated_data.splice(Index, 1);
                                  setselectedComplianceProfile(updated_data);
                                }
                              }}
                              // defaultChecked={elmt.StripeId}
                            />
                          </Form.Group>
                        </div>

                        <div className="pl-4 ml-2">{item?.Text}</div>
                      </div>
                    );
                  })}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <hr color="#EBEDF1" className="mb-3 " />
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-between "
            >
              <div
                className="pointer"
                onClick={() => {
                  ResetFilters();
                }}
              >
                <img alt="" src="/images/settings-v2/refresh-filter.svg" />
                <span
                  className="ml-1"
                  style={{ color: "#BBBEC9", padding: "3px" }}
                >
                  Reset
                </span>
              </div>
              <div>
                <Button className="cancel-btnn" onClick={toggleFilter}>
                  Cancel
                </Button>
                <Button
                  className="apply-btn"
                  onClick={(e) => {
                    // console.log(filterRequestData);
                    getFilteredData();
                    toggleFilter(e);
                  }}
                >
                  Apply
                </Button>
              </div>
            </Col>
          </Row>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
});

export default DropDownFilter;

const InputTypeData = [
  // {
  //   name: "Supplier Name",
  //   type: "SupplierName",
  //   value: "",
  //   inputType: "text",
  // },
  {
    name: "Supplier Risk",
    type: "SupplierRisk",
    value: null,
    inputType: "number",
  },
  {
    name: "Cyber Score",
    type: "CyberScore",
    value: null,
    inputType: "number",
  },
  {
    name: "Cyber Threat",
    type: "CyberThreat",
    value: null,
    inputType: "number",
  },
  {
    name: "Cyber Compliance",
    type: "CyberCompliance",
    value: null,
    inputType: "number",
  },
];
