import React from "react";
import "./input.scss";

const SearchInput = (props) => {
  return (
    <input
      type={props?.type}
      value={props?.value}
      name={props?.name}
      className={`input-field-search ${props?.className}`}
      placeholder={props?.placeholder}
      id={props?.id}
      onChange={props.onChange}
    />
  );
};

export default SearchInput;
