import React, { useState, useEffect } from "react";
import { Modal, Row } from "react-bootstrap";
import { TrackUser } from "../../../Utils/SegmentFunctions";

const CommonModal = (props) => {
  const { isOpen, isClose, data } = props;
  useEffect(() => {
    if (data?.status == "active") {
      TrackUser("Upgrade Purchased");
    }
  }, [data]);

  const URLpath = window.location.pathname.split("/")[1];
  return (
    <Modal
      show={isOpen}
      dialogClassName="modal-50w"
      className="integrationModal centerModal dashboardModal upgradePremium"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header>
        <h1 className="w-100 mb-0 mt-0">
          <img alt="" class="logo" src="/images/logo/highground/regular.svg" />
        </h1>
        <span className="cursor-pointer">
          <img
            src="/images/icons/close.svg"
            alt=""
            onClick={() => {
              isClose();
              if (!data) {
                {
                  URLpath == "system_settings" &&
                    TrackUser("Subscriptions plan Upgrade button clicked Off");
                }
              }
            }}
          />
        </span>
      </Modal.Header>
      <Modal.Body style={{ overflow: `${props.isLoading ? "hidden" : ""} ` }}>
        <Row className="m-0 h-100">{props.children}</Row>
      </Modal.Body>
    </Modal>
  );
};

export default CommonModal;
