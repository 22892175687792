import React from "react";
import { Row, Col } from "react-bootstrap";

import "../actionCentreModals.scss";

const Integration = ({ setModalTab, isIntegration }) => {
  return (
    <Row className="m-0">
      <Col md={12} className="p-0 mt-1">
        {isIntegration ? (
          <div className="mt-3">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="d-flex">
                <img
                  width={40}
                  height={40}
                  src="/images/actioncenter/Asana.svg"
                  alt=""
                />
                <span className="titletext ml-2">
                  Asana <br />
                  <span className="titletextgrey">Project: Cyber Security</span>
                </span>
              </div>

              <div
                className="d-flex align-items-center crsrpoint"
                onClick={setModalTab}
              ></div>
            </div>
            <button className="replotposture mt-3" onClick={() => {}}>
              <img
                src="/images/actioncenter/plus.svg"
                className="pr-1"
                alt=""
              />
              Connect Tool
            </button>
          </div>
        ) : (
          <div className="text-center mt-5 pt-4">
            <span className="titletext">Connect your task managment tool</span>
            <p className="titletextgrey pl-5 pr-5 mt-2 mx-auto">
              Connect HighGround to your existing task management tool and keep
              all of your tasks in one place
            </p>
            <button className="replotposture mt-3" onClick={setModalTab}>
              <img
                src="/images/actioncenter/plus.svg"
                className="pr-1"
                alt=""
              />
              Connect Tool
            </button>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default Integration;
