import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import "./mspIntegrations.scss";
import { TrimString } from "../../../Utils/StringTrimmer";
import ImportedClientDetailModal from "./ImportedClientDetailModal";
import { GreyLoader } from "../../Theme/APILoaders";
import CompanySettingsDropdown from "../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import MspMappingDropdownSearchInput from "./mspMappingScreens/mspMappingComponents/mspMappingDropdownSearchInput";
import ContentLoader from "react-content-loader";
import DotsLoader from "../../../Utils/dotsLoader";
import { parseIntegrationName } from "./mspMappingScreens/MSPMappings";
import { highlightText } from "../../../Utils/CommonFunctions";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { HGHighlightText } from "../../App/App";
 

  
const MapProducts = ({
  productCategoryPageData,
  setProductCategoryPageData,
  getproductCategoryListData,
  productCategoryMappingData,
  setProductCategoryMappingData,
  getProductsCategoryMappingListData,
  updateCategoryData,
  updateProductData,
  updateImportStatus,
}) => {
  // categories===2
  // products===3
  const [itemType, setItemType] = useState(3);
  const [selectedItemtype, setSelectedItemType] = useState({
    Id: 3,
    cat: "Products",
  });
  const [showMappedConfirmationModal, setShowMappedConfirmationModal] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const [fetchingNewproductCategory, setFetchingNewproductCategory] =
    useState(false);
  const [productCategorySearchString, setProductCategorySearchString] =
    useState("");
  const [workInProgress, setWorkInProgress] = useState(false);
  const [automaticallyImportStatus, setAutomaticallyImportStatus] =
    useState(true);
  function switchtype(_id) {
    setProductCategoryMappingData({});
    setProductCategoryPageData({});
    setLoading(true);
    if (_id === 2) {
      updateImportStatus(3, 0).then(() => {
        setSelectedItemType({ Id: 2, cat: "Categories" });
        setItemType(2);
      });
    }

    if (_id === 3) {
      updateImportStatus(3, 1).then(() => {
        setSelectedItemType({ Id: 3, cat: "Products" });
        setItemType(3);
      });
    }
  }

  useEffect(() => {
    
    if (workInProgress) return;

    setWorkInProgress(true);
    setLoading(true);
    Promise.all([
      getproductCategoryListData({
        NextPageNo: 1,
        searchString: productCategorySearchString,
        itemType: itemType,
      }),

      getProductsCategoryMappingListData({
        NextPageNo: 1,
        searchString: "",
        itemType: itemType,
      }),
    ])
      .then((response) => {
        setLoading(false);
        setAutomaticallyImportStatus(response?.[0]?.AutoImport);
        let workOnProducts = response[0].ImportByProducts;
        if (workOnProducts) {
          // Condition if  to work on products
          setItemType(3);
          setSelectedItemType({ Id: 3, cat: "Products" });
        } else {
          // Condition if  to work on categories
          setItemType(2);
          setSelectedItemType({ Id: 2, cat: "Categories" });
        }
        setTimeout(() => {
          setWorkInProgress(false);
        }, 500);
      })
      .catch((err) => {
        console.log("error here");
        setLoading(false);
      });
    // }
  }, [itemType]);

  async function getMoreProductCategories(e) {
    try {
      if (
        e.target.clientHeight + e.target.scrollTop + 20 >=
        e.target.scrollHeight
      ) {
        if (productCategoryPageData?.NextPage === false) return;
        let element = document.getElementById("mainBody");
        element.removeEventListener("scroll", getMoreProductCategories);

        setFetchingNewproductCategory(true);
        getproductCategoryListData({
          NextPageNo: null,
          searchString: productCategorySearchString,
          itemType: itemType,
        }).then(() => {
          setFetchingNewproductCategory(false);
        });

        console.log("You've reached the end of the screen!");
      }
    } catch (error) {
      console.log("errror aa gya", error);
    }
  }

  useEffect(() => {
    var element = document.getElementById("mainBody");
    element.addEventListener("scroll", getMoreProductCategories);

    return () => {
      element.removeEventListener("scroll", getMoreProductCategories);
    };
  }, [productCategoryPageData]);

  const [timeoutId, setTimeoutId] = useState()
const [productsCategoriesLoading, setProductsCategoriesLoading] = useState(false)
 const handleInputChange = (event) => {
    const { value } = event.target;
    setProductCategorySearchString(value);
                

    // Clear the previous timeout
    clearTimeout(timeoutId);

    // Set a new timeout to call the API after 1 second
    let _timeoutId = setTimeout(() => {
      setProductsCategoriesLoading(true)
      getproductCategoryListData({
                        NextPageNo: 1,
                        searchString: value,
                        itemType: itemType,
                      }).then(()=>{
        setProductsCategoriesLoading(false)
      })
    }, 800);
    setTimeoutId(_timeoutId);
  };




 


 
 

  return (
    <>
      <SwitchingTypeModal
        show={showMappedConfirmationModal}
        hideModal={() => setShowMappedConfirmationModal(false)}
        selectedType={itemType}
        executeChange={() => {
          if (itemType === 2) {
            switchtype(3);
          } else {
            switchtype(2);
          }
        }}
      />
      {loading ? (
        <MapProductCategoryLoader />
      ) : (
        <div
          id="mapped-client-inegtration-modal-parent"
          className="map-clients-screen  "
          style={{
            pointerEvents:productsCategoriesLoading ? "none" : "all"
          }}
        >
          <div className="d-flex align-items-center pt-4">
          <img
              alt=""
              width={42}
              height={42}
              src={`/images/attack-surface/integrations/${parseIntegrationName("importedIntegrtionId")}.webp`}
              className="mr-3 radius-4"
              style={{
                border:"1px solid #ebecf180"
              }}
            />
            <div>
              <p className="f-black f-600 f-15 m-0">Import{" "}{itemType === 2 ? "by category" : "by product"}</p>
              <p className="f-grey f-14">
                Import and map your clients spend by category or by products from{" "}{parseIntegrationName("importedIntegrtionName")}
              </p>
            </div>
          </div>
 
          <div className="mt-4 mb-2 f-500">Import</div>

          <div className="border-parent bg-grey radius-8">
            <div className="d-flex align-items-center justify-content-between  p-3 border-item">
              <div className="f-black f-500">
                Automatically import new{" "}
                {itemType === 2 ? "categories" : "products"}
              </div>
              <div className="">
                <Form.Check
                  style={{
                    scale: "1.5",
                  }}
                  type="switch"
                  id={`switchnitification`}
                  checked={automaticallyImportStatus}
                  className={`m-0`}
                  onClick={() => {}}
                  onChange={(e) => {
                    if (itemType === 2) {
                      updateImportStatus(4, Number(e.target.checked));
                    } else {
                      updateImportStatus(5, Number(e.target.checked));
                    }
                    setAutomaticallyImportStatus(e.target.checked);
                  }}
                />
              </div>
            </div>
            <div className="border-item d-flex align-items-center justify-content-between px-3 py-2">
              <div className="f-500 f-black">Import by</div>

              <div className="msp-cat-dropdown">
                <CompanySettingsDropdown
                  fieldId={"Id"}
                  fieldValue={"cat"}
                  selected={selectedItemtype}
                  placeholder="Choose..."
                  //  showid={true}
                  onClick={(id, val) => {
                    // setImportBy({ Id: id, cat: val });
                    setShowMappedConfirmationModal(true);
                  }}
                  data={[
                    { Id: 2, cat: "Categories" },
                    { Id: 3, cat: "Products" },
                  ]}
                />
              </div>
            </div>
          </div>
          {/* CATEGORIES SECTION */}

          {itemType === 2 && (
            <>
              <div className="d-flex align-items-center justify-content-between pt-5 pb-2">
                <div className="f-black f-16 f-600 ">Categories
                
                
                </div>
                <div className="mx-2 d-flex align-items-center">
                  <input
                    type="text"
                    placeholder="Search.."
                    value={productCategorySearchString}
                    onChange={(e) => {
                      handleInputChange(e)
                      // let value = e.target.value;
                      // setProductCategorySearchString(e.target.value);
                      // getproductCategoryListData({
                      //   NextPageNo: 1,
                      //   searchString: value,
                      //   itemType: itemType,
                      // });
                    }}
                    onBlur={() => {}}
                    className={`border-none bg-tranparent pl-2`}
                  />
                  <img
                    alt=""
                    src="/images/attack-surface/search-icon.svg"
                    className="pointer"
                    onClick={() => {}}
                  />
                </div>
              </div>
              <div className="border-parent bg-grey radius-8">
              {productsCategoriesLoading && (
              <div
                className="position-absolute w-100 h-100 bg-white"
                style={{
                  opacity: "0.6",
                  zIndex: "2",
                }}
              ></div>
            )}
                <MapCategoryBlock
                  productCategoryPageData={productCategoryPageData}
                  setProductCategoryPageData={setProductCategoryPageData}
                  productCategoryMappingData={productCategoryMappingData}
                  getProductsCategoryMappingListData={
                    getProductsCategoryMappingListData
                  }
                  updateCategoryData={updateCategoryData}
                  productCategorySearchString={productCategorySearchString}
                />
              </div>
            </>
          )}

          {/* PRODUCTS SECTION */}
          {itemType === 3 && (
            <>
              <div className="d-flex align-items-center justify-content-between pt-5 pb-2">
                <div className="f-black f-16 f-600 ">Products
                
                
                
                </div>
                <div className="mx-2 d-flex align-items-center">
                  <input
                    type="text"
                    placeholder="Search.."
                    value={productCategorySearchString}
                    onChange={(e) => {
                      handleInputChange(e)
                      // let value = e.target.value;
                      // setProductCategorySearchString(e.target.value);
                      // getproductCategoryListData({
                      //   NextPageNo: 1,
                      //   searchString: value,
                      //   itemType: itemType,
                      // });
                    }}
                    onBlur={() => {}}
                    className={`border-none bg-tranparent pl-2`}
                  />
                  <img
                    alt=""
                    src="/images/attack-surface/search-icon.svg"
                    className="pointer"
                    onClick={() => {}}
                  />
                </div>
              </div>
              <div className="border-parent bg-grey radius-8">
              {productsCategoriesLoading && (
              <div
                className="position-absolute w-100 h-100 bg-white"
                style={{
                  opacity: "0.6",
                  zIndex: "2",
                }}
              ></div>
            )}
                <MapProductBlock
                  productCategoryPageData={productCategoryPageData}
                  setProductCategoryPageData={setProductCategoryPageData}
                  productCategoryMappingData={productCategoryMappingData}
                  getProductsCategoryMappingListData={
                    getProductsCategoryMappingListData
                  }
                  updateProductData={updateProductData}
                  productCategorySearchString={productCategorySearchString}
                />
              </div>
            </>
          )}

          {fetchingNewproductCategory && (
            <div className="d-flex align-items-center f-darkgrey">
              <GreyLoader width={12} />
              Loading...
            </div>
          )}

          <div className="p-3"></div>
          {/* <ImportedClientDetailModal
        show={showMappedClientDet ailModal}
        hideModal={() => setShowMappe dClientDetailModal(false)}
        parentID={"mapped-client-inegtration-modal-parent"}
      /> */}
        </div>
      )}
    </>
  );
};

export default MapProducts;

export const MapCategoryBlock = ({
  productCategoryPageData,
  setProductCategoryPageData,
  productCategoryMappingData,
  getProductsCategoryMappingListData,
  updateCategoryData,
  productCategorySearchString
}) => {
  function injectProductDetailsInParent(selectedId, Objectlist) {

    for (let parentIndex = 0; parentIndex < Objectlist?.length; parentIndex++) {
       if (Objectlist[parentIndex].id === selectedId) {
        return { name: Objectlist[parentIndex].name };
      }
      for (
        let childItem = 0;
        childItem < Objectlist[parentIndex]?.ObjectList?.length;
        childItem++
      ) {
        if (Objectlist[parentIndex].ObjectList[childItem].id === selectedId) {
          return {
            name: Objectlist[parentIndex].name,
            childObjectDetails: {
              name: Objectlist[parentIndex].ObjectList[childItem].name,
            },
          };

          // `${Objectlist[parentIndex].name} > ${Objectlist[parentIndex].ObjectList[childItem].name}`;
        }
      }
    }
  }

 useEffect(() => {
   if(productCategorySearchString?.length >0){
   

    const updatedData = {
      ...productCategoryPageData,
      ObjectList: productCategoryPageData.ObjectList.map(obj => ({
        ...obj,
        show: true,
      })),
    }
     
    setProductCategoryPageData(updatedData);
   }
 }, [productCategoryPageData?.ObjectList?.length])
 


  return (
    <>
      {/* Header */}
      <div className="border-item d-flex align-items-center justify-content-between">
        <div className="f-12 f-500 f-grey py-2 pl-4 pr-5 w-100 d-flex align-items-center justify-content-between">
          <div> {parseIntegrationName("importedIntegrtionName")}</div>
          <div> {productCategoryPageData?.TotalCount}</div>
        </div>
        <div className="f-12 f-500 f-grey py-2 pl-2 w-100">HighGround</div>
      </div>

      {/* First row */}
      {productCategoryPageData?.ObjectList
      
      // ?.filter((item) =>
      //   item?.name
      //     ?.toLowerCase()
      //     .includes(productCategorySearchString?.toLowerCase())
      // )
      
      ?.map((parentCategory, parentCategoryIndex) => {
        return (
          <div className="border-item d-flex flex-column align-items-center justify-content-between point">
            <div className="d-flex w-100 align-items-center">
              <div
                className="d-flex align-items-center justify-content-between w-100 pl-4 py-3 pr-3"
                onClick={() => {}}
              >
                <div className="d-flex align-items-center justify-content-between hover-on-show-parent w-100 position-relative">
                  {parentCategory?.state === "Not_Selected" && (
                    <span className="blue-dot" />
                  )}
                  <div
                    className={`d-flex align-items-center f-500 f-black ${parentCategory?.ObjectList?.length > 0?  "pointer" :""}`}
                    title={parentCategory.name}
                    onClick={(e) => {
                      if (parentCategory?.ObjectList?.length > 0) {
                        // ToggleAccordion(e);
                        let data = { ...productCategoryPageData };
                        data.ObjectList[parentCategoryIndex].show =
                          !data.ObjectList[parentCategoryIndex].show;
                        setProductCategoryPageData(data);
                      }
                    }}
                  >
                    <img
                      src="/images/msp/integrations/chevron-down-msp.svg"
                      alt=""
                      className={`mr-2 pointer  ${
                        parentCategory?.show ? "rotate180" : ""
                      }`}
                      style={{
                        visibility: `${
                          parentCategory?.ObjectList?.length > 0
                            ? "visible"
                            : "hidden"
                        }`,
                      }}
                    />
                    
                    <span dangerouslySetInnerHTML={{ __html: HGHighlightText(TrimString(parentCategory.name, 30), productCategorySearchString) }} />
                     
                    
                       
                    
                  </div>
                </div>
                <div>
                  {parentCategory?.state === "Ignored" && (
                    <img
                      src="/images/msp/integrations/map-client-cancel-icon.svg"
                      alt=""
                      className="mr-2 pointer"
                      onClick={()=>{
                        updateCategoryData([
                          {
                            id: parentCategory?.id,
                            state: "Provisional_Import",
                            ParentObjId: 0,
                          },
                        ]);
                        let product_page_data = { ...productCategoryPageData };
                        let _parentCategoryIndex =
                          product_page_data.ObjectList.findIndex(
                            (item) => item.id === parentCategory.id
                          );
                        product_page_data.ObjectList[_parentCategoryIndex].state =
                          "Provisional_Import";
                        product_page_data.ObjectList[
                          _parentCategoryIndex
                        ].relationalObjectDetails = null;
                        product_page_data.ObjectList[
                          _parentCategoryIndex
                        ].ParentObjId = 0;
                        setProductCategoryPageData(product_page_data);
                      }}
                    />
                  )}
                  {parentCategory?.state === "Not_Selected" && (
                    <img
                      src="/images/msp/choose-action-icon.svg"
                      alt=""
                      className="mr-2 pointer"
                    />
                  )}
                  {parentCategory?.state === "Imported" && (
                    <img
                      // src="/images/msp/right-blue-mark.svg"
                      src="/images/msp/twoway-blue-mark.svg"
                      alt=""
                      className="mr-2 pointer"
                    />
                  )}
                  {parentCategory?.state === "Provisional_Import" && (
                    <img
                      src="/images/msp/right-blue-mark.svg"
                      // src="/images/msp/twoway-blue-mark.svg"
                      alt=""
                      className="mr-2 pointer"
                    />
                  )}
                  {parentCategory?.state === "Auto_Imported" && (
                    <img
                      src="/images/msp/right-blue-mark.svg"
                      alt=""
                      className="mr-2 pointer"
                    />
                  )}
                  {parentCategory?.state === "Mapped" && (
                    <img
                      src="/images/msp/twoway-blue-mark.svg"
                      alt=""
                      className="mr-2 pointer"
                    />
                  )}
                  {parentCategory?.state === "Deleted" && (
                    <img
                      src="/images/msp/red-cross-circle.svg"
                      alt=""
                      className="mr-2 pointer"
                    />
                  )}
                </div>
              </div>
              <div className=" w-100">
                <MapCategoriesDropdown
                  isParent={true}
                  onStateChange={(newState, mappingData) => {
                    // if (newState==="ImportAsSubcategory") {
                    //   return
                    // }
                    if (newState === "ImportAsCategory") {
                      let product_page_data = {
                        ...productCategoryPageData,
                      };
                      let _parentCategoryIndex =
                        product_page_data.ObjectList.findIndex(
                          (item) => item.id === parentCategory.id
                        );
                      product_page_data.ObjectList[_parentCategoryIndex] =
                        mappingData?.data;
                      setProductCategoryPageData(product_page_data);
                      return;
                    }

                    updateCategoryData([
                      {
                        id: parentCategory?.id,
                        state: newState,
                        ParentObjId: mappingData?.id ?? 0,
                      },
                    ]);

                    let product_page_data = { ...productCategoryPageData };
                    let _parentCategoryIndex =
                      product_page_data.ObjectList.findIndex(
                        (item) => item.id === parentCategory.id
                      );
                    product_page_data.ObjectList[_parentCategoryIndex].state =
                      newState;
                    product_page_data.ObjectList[
                      _parentCategoryIndex
                    ].relationalObjectDetails = null;
                    if (newState === "Ignored") {
                      product_page_data.ObjectList[
                        _parentCategoryIndex
                      ].ParentObjId = 0;
                    } else if (newState === "Provisional_Import") {
                      product_page_data.ObjectList[
                        _parentCategoryIndex
                      ].ParentObjId = 0;
                    } else if (newState === "Mapped") {
                      product_page_data.ObjectList[
                        _parentCategoryIndex
                      ].ParentObjId = mappingData.id;

                      product_page_data.ObjectList[
                        _parentCategoryIndex
                      ].relationalObjectDetails = injectProductDetailsInParent(
                        mappingData.id,
                        productCategoryMappingData?.ObjectList
                      );
                    }
                    setProductCategoryPageData(product_page_data);
                  }}
                  categoryDetail={parentCategory}
                  productCategoryMappingData={productCategoryMappingData}
                  getProductsCategoryMappingListData={
                    getProductsCategoryMappingListData
                  }
                  updateCategoryData={updateCategoryData}
                />
              </div>
            </div>

            {/* Panel */}
            <div className="border-parent-top w-100 msp-cat-pane l">
          {parentCategory?.show &&    <>
              {parentCategory?.ObjectList?.map(
                (childCategory, childCategoryIndex) => {
                  return (
                    <div className="d-flex w-100 align-items-center border-item-top">
                      <div
                        className="d-flex align-items-center justify-content-between w-100 pl-4 py-3 pr-3"
                        onClick={() => {}}
                      >
                        <div
                          className={`d-flex align-items-center f-500 f-black pl-4  `}
                        >
                           
                          <span dangerouslySetInnerHTML={{ __html: HGHighlightText(TrimString(childCategory.name, 30), productCategorySearchString) }} />
                    
                          
                        </div>
                        <div>
                          {childCategory?.state === "Ignored" && (
                            <img
                              src="/images/msp/integrations/map-client-cancel-icon.svg"
                              alt=""
                              className="mr-2 pointer"
                              onClick={()=>{
                                updateCategoryData([
                                  {
                                    id: childCategory?.id,
                                    state: "Provisional_Import",
                                    ParentObjId: 0,
                                  },
                                ]);
                                let product_page_data = { ...productCategoryPageData };
                                let _parentCategoryIndex =
                                  product_page_data.ObjectList.findIndex(
                                    (item) => item.id === parentCategory.id
                                  );
                                let _childCategoryIndex =
                                  product_page_data.ObjectList[
                                    _parentCategoryIndex
                                  ].ObjectList.findIndex(
                                    (item) => item.id === childCategory.id
                                  );
                                product_page_data.ObjectList[
                                  _parentCategoryIndex
                                ].ObjectList[_childCategoryIndex].state =
                                  "Provisional_Import";
                                product_page_data.ObjectList[
                                  _parentCategoryIndex
                                ].ObjectList[
                                  _childCategoryIndex
                                ].relationalObjectDetails = null;
                                product_page_data.ObjectList[
                                  _parentCategoryIndex
                                ].ObjectList[
                                  _childCategoryIndex
                                ].ParentObjId = 0;
                                setProductCategoryPageData(product_page_data);
                              }}
                            />
                          )}

                          {childCategory?.state === "Imported" && (
                            <img
                              // src="/images/msp/right-blue-mark.svg"
                              src="/images/msp/twoway-blue-mark.svg"
                              alt=""
                              className="mr-2 pointer"
                            />
                          )}
                          {childCategory?.state === "Provisional_Import" && (
                            <img
                              src="/images/msp/right-blue-mark.svg"
                              // src="/images/msp/twoway-blue-mark.svg"
                              alt=""
                              className="mr-2 pointer"
                            />
                          )}
                          {childCategory?.state === "Auto_Imported" && (
                            <img
                              src="/images/msp/right-blue-mark.svg"
                              alt=""
                              className="mr-2 pointer"
                            />
                          )}
                          {childCategory?.state === "Mapped" && (
                            <img
                              src="/images/msp/twoway-blue-mark.svg"
                              alt=""
                              className="mr-2 pointer"
                            />
                          )}
                          {childCategory?.state === "Deleted" && (
                            <img
                              src="/images/msp/red-cross-circle.svg"
                              alt=""
                              className="mr-2 pointer"
                            />
                          )}
                          {childCategory?.state === "Not_Selected" && (
                            <img
                              src="/images/msp/choose-action-icon.svg"
                              alt=""
                              className="mr-2 pointer"
                            />
                          )}
                        </div>
                      </div>
                      <div className=" w-100">
                        <MapCategoriesDropdown
                        productCategorySearchString={productCategorySearchString}
                          isParent={false}
                          onStateChange={(newState, mappingData) => {
                            // if(newState==="ImportAsCategory"){
                            //   return}
                            if (newState === "ImportAsSubcategory") {
                              let product_page_data = {
                                ...productCategoryPageData,
                              };
                              let _parentCategoryIndex =
                                product_page_data.ObjectList.findIndex(
                                  (item) => item.id === parentCategory.id
                                );
                              let _childCategoryIndex =
                                product_page_data.ObjectList[
                                  _parentCategoryIndex
                                ].ObjectList.findIndex(
                                  (item) => item.id === childCategory.id
                                );
                              product_page_data.ObjectList[
                                _parentCategoryIndex
                              ].ObjectList[_childCategoryIndex] =
                                mappingData?.data;
                              setProductCategoryPageData(product_page_data);

                              return false;
                            }

                            updateCategoryData([
                              {
                                id: childCategory?.id,
                                state: newState,
                                ParentObjId: mappingData?.id ?? 0,
                              },
                            ]);

                            let product_page_data = {
                              ...productCategoryPageData,
                            };
                            let _parentCategoryIndex =
                              product_page_data.ObjectList.findIndex(
                                (item) => item.id === parentCategory.id
                              );

                            let _childCategoryIndex =
                              product_page_data.ObjectList[
                                _parentCategoryIndex
                              ].ObjectList.findIndex(
                                (item) => item.id === childCategory.id
                              );
                            product_page_data.ObjectList[
                              _parentCategoryIndex
                            ].ObjectList[_childCategoryIndex].state = newState;
                            product_page_data.ObjectList[
                              _parentCategoryIndex
                            ].ObjectList[
                              _childCategoryIndex
                            ].relationalObjectDetails = null;

                            if (newState === "Ignored") {
                              product_page_data.ObjectList[
                                _parentCategoryIndex
                              ].ObjectList[_childCategoryIndex].ParentObjId = 0;
                            } else if (newState === "Provisional_Import") {
                              product_page_data.ObjectList[
                                _parentCategoryIndex
                              ].ObjectList[_childCategoryIndex].ParentObjId = 0;
                            } else if (newState === "Mapped") {
                              product_page_data.ObjectList[
                                _parentCategoryIndex
                              ].ObjectList[_childCategoryIndex].ParentObjId =
                                mappingData.id;
                              product_page_data.ObjectList[
                                _parentCategoryIndex
                              ].ObjectList[
                                _childCategoryIndex
                              ].relationalObjectDetails =
                                injectProductDetailsInParent(
                                  mappingData.id,
                                  productCategoryMappingData?.ObjectList
                                );
                            }
                            setProductCategoryPageData(product_page_data);
                          }}
                          categoryDetail={childCategory}
                          productCategoryMappingData={
                            productCategoryMappingData
                          }
                          getProductsCategoryMappingListData={
                            getProductsCategoryMappingListData
                          }
                          updateCategoryData={updateCategoryData}
                        />
                      </div>
                    </div>
                  );
                }
              )}</>}
            </div>
          </div>
        );
      })}
    </>
  );
};
//

export const MapCategoriesDropdown = ({
  onStateChange,
  categoryDetail,
  productCategoryMappingData,
  getProductsCategoryMappingListData,
  updateCategoryData,
  isParent,
  productCategorySearchString
}) => {
  function closeDropdown(e) {
    e.target.closest(".map-clients-dropdown").click();
  }
  const [fetchingMapping, setFetchingMapping] = useState(false);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState();
  const [importAsCategory, setImportAsCategory] = useState(true)
  const [selectedParentCategory, setSelectedParentCategory] = useState()
  const [page, setPage] = useState("home");
  const [importAsing, setImportAsing] = useState(false)

  const [searchParentCategoryString, setSearchParentCategoryString] = useState("")
  function renderProductMappingItem(selectedId, Objectlist) {
    for (let parentIndex = 0; parentIndex < Objectlist?.length; parentIndex++) {
      if (Objectlist[parentIndex].id === selectedId) {
        return Objectlist[parentIndex].name;
      }

      for (
        let childItem = 0;
        childItem < Objectlist?.[parentIndex]?.ObjectList?.length;
        childItem++
      ) {
        if (Objectlist[parentIndex].ObjectList[childItem].id === selectedId) {
          return `${Objectlist[parentIndex].ObjectList[childItem].name}`;
        }
      }
    }
  }



  
   
  useEffect(() => {
    if (page === "importAs") {
      setImportAsCategory(true);
      getProductsCategoryMappingListData({
        NextPageNo: 1,
        searchString: "",
        itemType: 2,
        count:20
      });
    }
  }, [page]);
  

  return (
    <Dropdown
      className="map-clients-dropdown"
      onToggle={(e) => {
        if (e) {
          setPage("home");
          setImportAsCategory(true);
          // getProductsCategoryMappingListData({
          //   NextPageNo: 1,
          //   searchString: "",
          //   itemType: 2,
          // });
        } else {
          setSearch("");
          getProductsCategoryMappingListData({
            NextPageNo: 1,
            searchString: "",
            itemType: 2,
          });
        }
      }}
    >
      <Dropdown.Toggle className={`d-flex align-items-center w-100`}>
        {categoryDetail?.state === "Ignored" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-black d-flex">Ignore</div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}
        {categoryDetail?.state === "Not_Selected" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-darkgrey d-flex">Choose action</div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}
        {categoryDetail?.state === "Imported" && (
          <div className="d-flex w-100 align-items-center justify-content-between" title={categoryDetail?.name}>
            <div
              className={`w-100 bg-grey f-black d-flex  `}
            >
              {TrimString(categoryDetail?.name, 20)}
            </div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}
        {categoryDetail?.state === "Provisional_Import" && (
          <div className="d-flex w-100 align-items-center justify-content-between"   title={categoryDetail?.name} >
            <div
              className={`w-100 bg-grey f-black d-flex `}
            >
              Import "{TrimString(categoryDetail?.name, 20)}"
            </div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}
        {categoryDetail?.state === "Auto_Imported" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-black d-flex">Auto-Imported</div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}
        {categoryDetail?.state === "Deleted" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-black d-flex">Deleted</div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}
        {categoryDetail?.state === "Mapped" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div
              className={`d-flex  align-items-center f-black `}
            >
              { categoryDetail?.relationalObjectDetails
                ? categoryDetail?.relationalObjectDetails?.childObjectDetails
                  ? `${categoryDetail?.relationalObjectDetails?.name} > ${categoryDetail?.relationalObjectDetails?.childObjectDetails?.name}`
                  : `${categoryDetail?.relationalObjectDetails?.name}`
                : renderProductMappingItem(
                    categoryDetail?.ParentObjId,
                    productCategoryMappingData?.ObjectList
                  )}
               
            </div>
            <div>
              <img
                alt=""
                className="mr-2"
                src="/images/msp/integrations/chevron-down-msp.svg"
              />
            </div>
          </div>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu className="no-border-shadow-dropdown">
        {page === "home" && (
          <>
            <div className="d-flex align-items-center mb-2">
              <MspMappingDropdownSearchInput
                placeholder="Find categories or subcategories..."
                value={search}
                onChange={(e) => {
                  let value = e.target.value;
                  getProductsCategoryMappingListData({
                    NextPageNo: 1,
                    searchString: value,
                    itemType: 2,
                    count: 10000,
                  });

                  setSearch(e.target.value);
                }}
              />
            </div>
            <div className="f-12 f-400 f-darkgrey my-1 pl-2">
              Import to category
            </div>
            <div
              className="transparent-scroller  right-list-scroller"
              style={{
                maxHeight: "300px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              {productCategoryMappingData?.ObjectList

                // ?.filter((item) =>
                //   item?.name?.toLowerCase()?.includes(search.toLowerCase())
                // )

                ?.map((parentItem, parentitemIndex) => {
                  return (
                    <>
                      <div
                        className={`d-flex align-items-center pl-3 pr-2 f-grey f-14 f-500  bg-hover-grey-light pointer pt-1 pb-2   `}
                        onClick={(e) => {
                          closeDropdown(e);
                          setSearch("");
                          onStateChange("Mapped", parentItem);
                          setSelected(parentItem);
                        }}
                      >
 

                        <span dangerouslySetInnerHTML={{ __html: HGHighlightText(TrimString(parentItem?.name, 30), search)  }} />

 
                        
                      </div>

                      {parentItem?.ObjectList?.map(
                        (childListItem, childListitemIndex) => {
                          return (
                            <div
                              className="d-flex align-items-center f-grey f-14 f-500 w-100 bg-hover-grey-light pointer pt-1 pb-2"
                              onClick={(e) => {
                                closeDropdown(e);
                                setSearch("");
                                onStateChange("Mapped", childListItem);
                                setSelected(childListItem);
                              }}
                            >
                              <img
                                alt=""
                                className="ml-3 mr-2"
                                src={"/images/msp/enter-icon.svg"}
                              />
                             <span dangerouslySetInnerHTML={{ __html: HGHighlightText(TrimString(childListItem?.name, 30), search) }} />
                              
                            </div>
                          );
                        }
                      )}
                    </>
                  );
                })}
            </div>

            {
              // ?.filter((item) =>
              //   item?.name?.toLowerCase()?.includes(search.toLowerCase())
              // )
              productCategoryMappingData?.ObjectList?.length === 0 && (
                <div className="f-darkgrey d-flex align-items-center justify-content-start p-2 pl-3">
                  No results...
                </div>
              )
            }

            {/* {search !== "" && (
          <div className="f-blue pointer d-flex align-items-center pl-3">
            <img alt="" className="mr-2" src="/images/msp/blue-plus.svg" />
            Add “{TrimString(search, 20)}”
          </div>
        )} */}

            {search !== "" && `clientDetail?.state !== "Imported"` && (
              <div
                className="f-blue pointer d-flex align-items-center pl-3"
                onClick={(e) => {
                  // onStateChange("Provisional_Import", { ImportName: search });
                  // closeDropdown(e);
                  // setSearch("");
                  setPage("importAs");
                }}
              >
                <img alt="" className="mr-2" src="/images/msp/blue-plus.svg" />
                Import as “{TrimString(search, 20)}”
              </div>
            )}

            {productCategoryMappingData?.NextPage === true && (
              <div
                className="d-flex pointer align-items-center pl-3"
                onClick={() => {
                  if (fetchingMapping) return;
                  setFetchingMapping(true);
                  getProductsCategoryMappingListData({
                    NextPageNo: null,
                    searchString: search,
                    itemType: 2,
                    count: 10000,
                    skip: productCategoryMappingData?.ObjectList?.length,
                  })
                    .then(() => {
                      setFetchingMapping(false);
                      const elementToScroll = document.querySelector(
                        ".right-list-scroller"
                      );
                      elementToScroll.scrollTo({
                        top: 10000000,
                        behavior: "smooth", // You can use 'auto' for instant scrolling
                      });
                    })
                    .catch(() => {
                      setFetchingMapping(false);
                    });
                }}
              >
                <DotsLoader animate={fetchingMapping} />
                <div className="f-darkgrey ml-2">Show all</div>
              </div>
            )}
            <hr className="hg-border mx-auto px-3" style={{ width: "80%" }} />
            <div className="mx-2  ">
              <div
                className="f-grey mx-2 pointer d-flex align-items-center bg-hover-grey-light"
                onClick={(e) => {
                  setSelected();
                  closeDropdown(e);
                  setSearch("");
                  onStateChange("Provisional_Import");
                }}
              >
                <img
                  src="/images/attack-surface/right-arrow.svg"
                  alt=""
                  className="mr-2"
                />
                Import "{TrimString(categoryDetail?.name, 20)}"
              </div>
              <div
                className="f-grey mx-2 mt-2 pointer d-flex align-items-center bg-hover-grey-light"
                onClick={(e) => {
                  closeDropdown(e);
                  setSearch("");
                  onStateChange("Ignored");
                }}
              >
                <img
                  src="/images/msp/integrations/cross-icon-msp.svg"
                  alt=""
                  className="mr-2"
                />
                Ignore
              </div>
            </div>
          </>
        )}

        {page === "importAs" && (
          <div className="w-100 p-2 hg-radio">
            <div className="f-12 f-darkgrey mb-2">Import as </div>
            <div
              className="d-flex align-items-center pointer"
              onClick={(e) => {
                setImportAsCategory(true);
              }}
            >
              <div className="mb-4 mr-2">
                <Form.Group
                  controlId={`card-1`}
                  className={`round-checkbox blue-checkbox ${"radio-shadow"}`}
                >
                  <Form.Check
                    type={"radio"}
                    checked={importAsCategory}
                    name={`check ` + 1}
                    label=""
                    disabled={false}
                    onChange={() => {
                      setImportAsCategory(true);
                    }}
                  />
                </Form.Group>
              </div>
              Import as category
            </div>
            <div
              className="d-flex align-items-center mt-2 pointer"
              onClick={(e) => {
                setImportAsCategory(false);
              }}
            >
              <div className="mb-4 mr-2">
                <Form.Group
                  controlId={`card-2`}
                  className={`round-checkbox blue-checkbox ${"radio-shadow"}`}
                >
                  <Form.Check
                    type={"radio"}
                    checked={!importAsCategory}
                    name={`check ` + 1}
                    label=""
                    disabled={false}
                    onChange={() => {
                      setImportAsCategory(false);
                    }}
                    onClick={(e) => {
                      return false;
                    }}
                  />
                </Form.Group>
              </div>
              Import as subcategory
            </div>

            {importAsCategory === false && (
              <>
                <Dropdown className="transparennt-wrapper-dropdown mt-2">
                  <Dropdown.Toggle className="w-100 d-flex align-items-center">
                    <div className="highlight-input-with-grey-border d-flex align-items-center radius-4 bg-grey w-100">
                      <input
                        type="text"
                        className="border-none bg-transparent p-2 flex-1"
                        placeholder="Choose parent category"
                        value={searchParentCategoryString}
                        onChange={(e) => {
                          let value = e.target.value;
                          getProductsCategoryMappingListData({
                            NextPageNo: 1,
                            searchString: value,
                            itemType: 2,
                            count:20
                          });
                          setSearchParentCategoryString(value ?? "");
                        }}
                      />
                      {selectedParentCategory && (
                        <img
                          alt=""
                          src="/images/attack-surface/cross-icon.svg"
                          width={12}
                          className="mr-2 pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedParentCategory();
                            setSearchParentCategoryString("");
                          }}
                        />
                      )}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="p-2 upload-template-dro p f-grey pointer"
                    style={{
                      width: "264px",
                    }}
                    onClick={(e) => {}}
                  >
                    {productCategoryMappingData?.ObjectList?.map(
                      (item, index) => {
                        return (
                          <div
                            className={`d-flex align-items-center pl-3 pr-2 f-grey f-14 f-500  bg-hover-grey-light pointer pt-1 pb-2  `}
                            onClick={(e) => {
                              e.target
                                .closest(".transparennt-wrapper-dropdown")
                                .click();
                              setSelectedParentCategory(item);
                              setSearchParentCategoryString(item?.name);
                            }}
                          >
                            {item?.name}
                          </div>
                        );
                      }
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}

            <div className="f-grey my-2">
              “<span className="f-14 f-500 f-grey">{search}</span>” will be
              imported as a new{" "}
              {importAsCategory ? "category" : "subcategory"}
              {" "}
              {importAsCategory === false && selectedParentCategory && (
                <>
                  of{" "}
                  <span className="f-14 f-500 f-grey">
                    {selectedParentCategory?.name}
                  </span>
                </>
              )}
                
               
            </div>
            <div className="d-flex align-items-center justify-content-end">
              <Button className="mr-2 hg-cancel-btn"
              onClick={(e)=>{
                closeDropdown(e)

              }}
              
              >Cancel</Button>
              <Button
                disabled={importAsCategory === false && !selectedParentCategory}
                className={`${"hg-blue-btn"}`}
                onClick={(e) => {
                  let event = e.target;
                  if(importAsing)return
                  setImportAsing(true)
                  if (importAsCategory) {
                    updateCategoryData(
                      [
                        {
                          id: categoryDetail?.id,
                          state: "Mapped",
                          ImportName: search,
                          ParentObjId: 0,
                        },
                      ],
                      {
                        optionEnum2: 0,
                      }
                    ).then((response) => {
                      setImportAsing(false);
                      if(response?.Success){
                        onStateChange(   isParent? "ImportAsCategory" :"ImportAsSubcategory", {
                          Id: response?.ObjectTemplateList[0]?.id,
                          data: response?.ObjectTemplateList[0],
                        });
                        // closeDropdown(event);
                        event.closest(".map-clients-dropdown").click();
                      }else{
                        CallToast("Something went wrong", false, false, "v2style");
                      }
                    
                    });
                  } else {
                    updateCategoryData(
                      [
                        {
                          id: categoryDetail?.id,
                          state: "Mapped",
                          ImportName: search,
                          ParentObjId: selectedParentCategory?.id,
                        },
                      ],
                      {
                        optionEnum2: 1,
                      }
                    ).then((response) => {
                      setImportAsing(false);
                      if(response?.Success){
                        onStateChange( isParent? "ImportAsCategory" :"ImportAsSubcategory", {
                          Id: response?.ObjectTemplateList[0]?.id,
                          data: response?.ObjectTemplateList[0],
                        });
                         
                        event.closest(".map-clients-dropdown").click();


                      }else{

                        CallToast("Something went wrong", false, false, "v2style");
                      }

                  
                    });
                    
                  }
                 
                }}
              >
                {importAsing?  "Saving.."  :"Save"}
              </Button>
            </div>
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

// Products section components

export const MapProductBlock = ({
  productCategoryPageData,
  setProductCategoryPageData,
  productCategoryMappingData,
  getProductsCategoryMappingListData,
  updateProductData,
  productCategorySearchString,

}) => {
  const [showMappedClientDetailModal, setShowMappedClientDetailModal] =
    useState(false);

  const [productDetails, setProductDetails] = useState([]);

  function injectProductDetails(selectedId, Objectlist) {

    for (let parentIndex = 0; parentIndex < Objectlist?.length; parentIndex++) {
       if (Objectlist[parentIndex].id === selectedId) {
        return { name: Objectlist[parentIndex].name };
      }
      for (
        let childItem = 0;
        childItem < Objectlist[parentIndex]?.ObjectList?.length;
        childItem++
      ) {
        if (Objectlist[parentIndex].ObjectList[childItem].id === selectedId) {
          return {
            name: Objectlist[parentIndex].name,
            childObjectDetails: {
              name: Objectlist[parentIndex].ObjectList[childItem].name,
            },
          };

          // `${Objectlist[parentIndex].name} > ${Objectlist[parentIndex].ObjectList[childItem].name}`;
        }
      }
    }
  }
  return (
    <>
      <ImportedClientDetailModal
        show={showMappedClientDetailModal}
        hideModal={() => setShowMappedClientDetailModal(false)}
        data={productDetails}
        parentID={"mapped-client-inegtration-modal-parent"}
      />
      {/* Header */}
      <div className="border-item d-flex align-items-center justify-content-between">
        <div className="f-12 f-500 f-grey py-2 pl-4 pr-5 w-100 d-flex align-items-center justify-content-between">
          <div> {parseIntegrationName("importedIntegrtionName")}</div>
          <div> {productCategoryPageData?.TotalCount}</div>
        </div>
        <div className="f-12 f-500 f-grey py-2 pl-2 w-100">HighGround</div>
      </div>

      {/* First row */}
      {productCategoryPageData?.ObjectList
      
      // ?.filter((item) =>
      //   item?.name
      //     ?.toLowerCase()
      //     .includes(productCategorySearchString?.toLowerCase())
      // )
      
      ?.map((product, productIndex) => {
        return (
          <div className="border-item d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center justify-content-between w-100 pl-4 py-3 pr-3">
              <div className="d-flex align-items-center justify-content-between hover-on-show-parent w-100 position-relative">
                {product?.state === "Not_Selected" && (
                  <span className="blue-dot" />
                )}
                <div className="d-flex align-items-center justify-content-between f-500 f-black hover-on-show-parent w-100">
                  <div className="d-flex align-items-center"title={product?.name} >
                    <img
                      src="/images/msp/msp-product-icon.svg"
                      alt=""
                      className="mr-2"
                    />
                    <div>
                      <div
                        className={`m-0 f-black f-500  `}
                        title={product?.name}
                      >
 
 
<span dangerouslySetInnerHTML={{ __html: HGHighlightText(TrimString(product?.name, 30), productCategorySearchString) }} />
                                              
                      </div>
                      <div className="m-0 f-12 f-darkgrey">
                        {product?.integrationObjId}
                      </div>
                    </div>
                  </div>
                  <div
                    className="f-12 f-darkgrey hover-on-show-child mr-2 pointer"
                    onClick={() => {
                      setProductDetails(product.details);
                      setShowMappedClientDetailModal(true);
                    }}
                  >
                    Details
                  </div>
                </div>
              </div>
              <div>
                {product?.state === "Ignored" && (
                  <img
                    src="/images/msp/integrations/map-client-cancel-icon.svg"
                    alt=""
                    className="mr-2 pointer"
                    onClick={()=>{
                      updateProductData([
                        {
                          id: product?.id,
                          state: "Provisional_Import",
                          ParentObjId: 0,
                        },
                      ]);
                      let product_page_data = { ...productCategoryPageData };
                      let _productIndex = product_page_data.ObjectList.findIndex(
                        (item) => item.id === product.id
                      );
                      product_page_data.ObjectList[_productIndex].state =
                        "Provisional_Import";
                      product_page_data.ObjectList[
                        _productIndex
                      ].relationalObjectDetails = null;
                      product_page_data.ObjectList[
                        _productIndex
                      ].ParentObjId = 0;
                      setProductCategoryPageData(product_page_data);
                    }}
                  />
                )}
                {product?.state === "Not_Selected" && (
                  <img
                    src="/images/msp/choose-action-icon.svg"
                    alt=""
                    className="mr-2 pointer"
                  />
                )}
                {product?.state === "Imported" && (
                  <img
                    // src="/images/msp/right-blue-mark.svg"
                    src="/images/msp/twoway-blue-mark.svg"
                    alt=""
                    className="mr-2 pointer"
                  />
                )}
                {product?.state === "Provisional_Import" && (
                  <img
                    // src="/images/msp/right-blue-mark.svg"
                    // src="/images/msp/twoway-blue-mark.svg"
                    src="/images/msp/choose-action-icon.svg"
                    alt=""
                    className="mr-2 pointer"
                  />
                )}
                {product?.state === "Auto_Imported" && (
                  <img
                    src="/images/msp/right-blue-mark.svg"
                    alt=""
                    className="mr-2 pointer"
                  />
                )}
                {product?.state === "Mapped" && (
                  <img
                    src="/images/msp/twoway-blue-mark.svg"
                    alt=""
                    className="mr-2 pointer"
                  />
                )}
                {product?.state === "Deleted" && (
                  <img
                    src="/images/msp/red-cross-circle.svg"
                    alt=""
                    className="mr-2 pointer"
                  />
                )}
              </div>
            </div>

            <div className=" w-100">
              <MapProductsDropdown
              updateProductData={updateProductData}
              productCategorySearchString={productCategorySearchString}
                productDetail={product}
                onStateChange={(newState, mappingData) => {

                  if(newState==="ImportAsCategory"){
                    let product_page_data = { ...productCategoryPageData };
                    let _productIndex = product_page_data.ObjectList.findIndex(
                      (item) => item.id === product.id
                    );

                    product_page_data.ObjectList[_productIndex] = mappingData?.data;


                    setProductCategoryPageData(product_page_data);
                 return

                  }







                  updateProductData([
                    {
                      id: product?.id,
                      state: newState,
                      ParentObjId: mappingData?.id ?? 0,
                    },
                  ]);
                  let product_page_data = { ...productCategoryPageData };
                  let _productIndex = product_page_data.ObjectList.findIndex(
                    (item) => item.id === product.id
                  );
                  product_page_data.ObjectList[_productIndex].state = newState;
                  product_page_data.ObjectList[
                    _productIndex
                  ].relationalObjectDetails = null;
                  if (newState === "Ignored") {
                    product_page_data.ObjectList[_productIndex].ParentObjId = 0;
                  } else if (newState === "Provisional_Import") {
                    product_page_data.ObjectList[_productIndex].ParentObjId = 0;
                  } else if (newState === "Mapped") {
                    product_page_data.ObjectList[_productIndex].ParentObjId =
                      mappingData.id;
                    product_page_data.ObjectList[
                      _productIndex
                    ].relationalObjectDetails = injectProductDetails(
                      mappingData.id,
                      productCategoryMappingData.ObjectList
                    );
                  }
                  setProductCategoryPageData(product_page_data);
                }}
                productCategoryMappingData={productCategoryMappingData}
                getProductsCategoryMappingListData={
                  getProductsCategoryMappingListData
                }
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

// Products Dropdown

export const MapProductsDropdown = ({
  productCategoryMappingData,
  onStateChange,
  productDetail,
  getProductsCategoryMappingListData,
  productCategorySearchString,
  updateProductData
}) => {
  function closeDropdown(e) {
    try {
      e.target.closest(".map-clients-dropdown").click();
    } catch (e) {
      console.log(e);
    }
  }
  const [fetchingMapping, setFetchingMapping] = useState(false);

  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState();
  const [importAsCategory, setImportAsCategory] = useState(true)
  const [selectedParentCategory, setSelectedParentCategory] = useState()
  const [page, setPage] = useState("home");
  const [importAsing, setImportAsing] = useState(false)
  const [searchParentCategoryString, setSearchParentCategoryString] = useState("")

  function renderProductMappingItem(selectedId, Objectlist) {
    for (let parentIndex = 0; parentIndex < Objectlist?.length; parentIndex++) {
      if (Objectlist[parentIndex].id === selectedId) {
        return Objectlist[parentIndex].name;
      } 
      for (
        let childItem = 0;
        childItem < Objectlist[parentIndex]?.ObjectList?.length;
        childItem++
      ) {
        if (Objectlist[parentIndex].ObjectList[childItem].id === selectedId) {
          return `${Objectlist[parentIndex].name} > ${Objectlist[parentIndex].ObjectList[childItem].name}`;
        }
      }
    }
  }


   
  useEffect(() => {
    if (page === "importAs") {
      setImportAsCategory(true);
      getProductsCategoryMappingListData({
        NextPageNo: 1,
        searchString: "",
        itemType: 2,
        count:20
      });
    }
  }, [page]);

  return (
    <Dropdown
      className="map-clients-dropdown"
      onToggle={(e) => {
        if (e) {
          setPage("home");
          // getProductsCategoryMappingListData({
          //   NextPageNo: 1,
          //   searchString: "",
          //   itemType: 3,
          // });
        } else {
          setSearch("");
          getProductsCategoryMappingListData({
            NextPageNo: 1,
            searchString: "",
            itemType: 3,
          });
        }
      }}
    >
      <Dropdown.Toggle className={`d-flex align-items-center w-100`}>
        {productDetail?.state === "Ignored" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-black d-flex">Ignore</div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}
        {productDetail?.state === "Not_Selected" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-darkgrey d-flex">Choose action</div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}
        {productDetail?.state === "Imported" && (
          <div className="d-flex w-100 align-items-center justify-content-between" title={productDetail?.name} >
            <div
              className={`w-100 bg-grey f-black d-flex  `}
            >
 
  <span dangerouslySetInnerHTML={{ __html: HGHighlightText(TrimString(productDetail?.name, 30), productCategorySearchString) }} />
            
            </div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}
        {productDetail?.state === "Provisional_Import" && (
          // <div className="d-flex w-100 align-items-center justify-content-between">
          //   <div className="w-100 bg-grey f-black d-flex">
          //     Import "{TrimString(productDetail?.name, 20)}"
          //   </div>
          //   <img
          //     alt=""
          //     className="mr-2"
          //     src="/images/msp/integrations/chevron-down-msp.svg"
          //   />
          // </div>
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-darkgrey d-flex">Choose action</div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}
        {productDetail?.state === "Auto_Imported" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-black d-flex">Auto-Imported</div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}
        {productDetail?.state === "Deleted" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-black d-flex">Deleted</div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}
        {productDetail?.state === "Mapped" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div
              className={`d-flex  align-items-center f-black  `}
            >
              { 
                productDetail?.relationalObjectDetails
                  ? productDetail?.relationalObjectDetails?.childObjectDetails
                    ? `${productDetail?.relationalObjectDetails?.name} > ${productDetail?.relationalObjectDetails?.childObjectDetails?.name}`
                    : `${productDetail?.relationalObjectDetails?.name}`
                  : renderProductMappingItem(
                      productDetail?.ParentObjId,
                      productCategoryMappingData?.ObjectList
                    ) }
            </div>
            <div>
              <img
                alt=""
                className="mr-2"
                src="/images/msp/integrations/chevron-down-msp.svg"
              />
            </div>
          </div>
        )}
        {/* {productDetail?.relationalObjectDetails?.name}
        {productDetail?.relationalObjectDetails?.childObjectDetails?.name} */}
      </Dropdown.Toggle>
      <Dropdown.Menu className="no-border-shadow-dropdown">

{page === "home" &&        <>
        <div className="d-flex align-items-center mb-2">
          <MspMappingDropdownSearchInput
            placeholder="Find categories or subcategories..."
            value={search}
            onChange={(e) => {
              let value = e.target.value;
              getProductsCategoryMappingListData({
                NextPageNo: 1,
                searchString: value,
                itemType: 3,
              }); 
              setSearch(e.target.value);
            }}
          />
        </div>
        <div className="f-12 f-400 f-darkgrey my-1 pl-2">
          Import to category
        </div>
        <div
          className="transparent-scroller  category-right-list-scroller"
          style={{
            maxHeight: "300px",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          {productCategoryMappingData?.ObjectList

            // ?.filter((item) =>
            //   item?.name?.toLowerCase()?.includes(search.toLowerCase())
            // )

            ?.map((parentItem, parentitemIndex) => {
              return (
                <>
                  <div
                    className={`d-flex align-items-center ml-3 mr-2 f-grey f-14 f-500 w-100 bg-hover-grey-light pointer pt-1 pb-2 `}
                    onClick={(e) => {
                      closeDropdown(e);
                      setSearch("");
                      onStateChange("Mapped", parentItem);
                      setSelected(parentItem);
                    }}
                  >
                    
                      <span dangerouslySetInnerHTML={{ __html: HGHighlightText(parentItem?.name, search) }} />
                      
                    
                   
                   
                  </div>

                  {parentItem?.ObjectList?.map(
                    (childListItem, childListitemIndex) => {
                      return (
                        <div
                          className="d-flex align-items-center f-grey f-14 f-500 w-100 bg-hover-grey-light pointer pt-1 pb-2"
                          onClick={(e) => {
                            closeDropdown(e);
                            setSearch("");
                            onStateChange("Mapped", childListItem);
                            setSelected(childListItem);
                          }}
                        >
                          <img
                            alt=""
                            className="ml-3 mr-2"
                            src={"/images/msp/enter-icon.svg"}
                          />
                          
                            
                              
                              <span dangerouslySetInnerHTML={{ __html: HGHighlightText(TrimString(childListItem?.name, 30), search) }} />
                            
                           
                        </div>
                      );
                    }
                  )}
                </>
              );
            })}
        </div>

        {
           

          productCategoryMappingData?.ObjectList?.length === 0 && (
            <div className="f-darkgrey d-flex align-items-center justify-content-start p-2 pl-3">
              No results...
            </div>
          )
        }
         {search !== "" && `clientDetail?.state !== "Imported"` && (
              <div
                className="f-blue pointer d-flex align-items-center pl-3"
                onClick={(e) => {
                 
                  setPage("importAs");
                }}
              >
                <img alt="" className="mr-2" src="/images/msp/blue-plus.svg" />
                Import as “{TrimString(search, 20)}”
              </div>
            )}

       
        {productCategoryMappingData?.NextPage === true && (
          <div
            className="d-flex pointer align-items-center pl-3"
            onClick={() => {
              if (fetchingMapping) return;
              setFetchingMapping(true);
              getProductsCategoryMappingListData({
                NextPageNo: null,
                searchString: search,
                itemType: 3,
                count: 10000,
                skip: productCategoryMappingData?.ObjectList?.length,
              })
                .then(() => {
                  setFetchingMapping(false);
                  const elementToScroll = document.querySelector(
                    ".category-right-list-scroller"
                  );
                  elementToScroll.scrollTo({
                    top: 10000000,
                    behavior: "smooth", // You can use 'auto' for instant scrolling
                  });
                })
                .catch(() => {
                  setFetchingMapping(false);
                });
            }}
          >
            <DotsLoader animate={fetchingMapping} />
            <div className="f-darkgrey ml-2">Show all</div>
          </div>
        )}
        <hr className="hg-border mx-auto px-3" style={{ width: "80%" }} />
        <div className="mx-2  ">
          {/* <div
            className="f-grey mx-2 pointer d-flex align-items-center bg-hover-grey-light"
            onClick={(e) => {
              setSelected();
              closeDropdown(e);
              setSearch("");
              onStateChange("Imported");
            }}
          >
            <img
              src="/images/attack-surface/right-arrow.svg"
              alt=""
              className="mr-2"
            />
            Import "{TrimString(productDetail?.name, 20)}"
          </div> */}

          <div
            className="f-grey mx-2 mt-2 pointer d-flex align-items-center bg-hover-grey-light"
            onClick={(e) => {
              closeDropdown(e);
              setSearch("");
              onStateChange("Ignored");
            }}
          >
            <img
              src="/images/msp/integrations/cross-icon-msp.svg"
              alt=""
              className="mr-2"
            />
            Ignore
          </div>
        </div>
        </>}








        {page === "importAs" && (
          <div className="w-100 p-2 hg-radio">
            <div className="f-12 f-darkgrey mb-2">Import as </div>
            <div
              className="d-flex align-items-center pointer"
              onClick={(e) => {
                setImportAsCategory(true);
              }}
            >
              <div className="mb-4 mr-2">
                <Form.Group
                  controlId={`card-1`}
                  className={`round-checkbox blue-checkbox ${"radio-shadow"}`}
                >
                  <Form.Check
                    type={"radio"}
                    checked={importAsCategory}
                    name={`check ` + 1}
                    label=""
                    disabled={false}
                    onChange={() => {
                      setImportAsCategory(true);
                    }}
                  />
                </Form.Group>
              </div>
              Import as category
            </div>
            <div
              className="d-flex align-items-center mt-2 pointer"
              onClick={(e) => {
                setImportAsCategory(false);
              }}
            >
              <div className="mb-4 mr-2">
                <Form.Group
                  controlId={`card-2`}
                  className={`round-checkbox blue-checkbox ${"radio-shadow"}`}
                >
                  <Form.Check
                    type={"radio"}
                    checked={!importAsCategory}
                    name={`check ` + 1}
                    label=""
                    disabled={false}
                    onChange={() => {
                      setImportAsCategory(false);
                    }}
                    onClick={(e) => {
                      return false;
                    }}
                  />
                </Form.Group>
              </div>
              Import as subcategory
            </div>

            {importAsCategory === false && (
              <>
                <Dropdown className="transparennt-wrapper-dropdown mt-2">
                  <Dropdown.Toggle className="w-100 d-flex align-items-center">
                    <div className="highlight-input-with-grey-border d-flex align-items-center radius-4 bg-grey w-100">
                      <input
                        type="text"
                        className="border-none bg-transparent p-2 flex-1"
                        placeholder="Choose parent category"
                        value={searchParentCategoryString}
                        onChange={(e) => {
                          let value = e.target.value;
                          getProductsCategoryMappingListData({
                            NextPageNo: 1,
                            searchString: value,
                            itemType: 2,
                          });
                          setSearchParentCategoryString(value ?? "");
                        }}
                      />
                      {selectedParentCategory && (
                        <img
                          alt=""
                          src="/images/attack-surface/cross-icon.svg"
                          width={12}
                          className="mr-2 pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedParentCategory();
                            setSearchParentCategoryString("");
                          }}
                        />
                      )}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="p-2 upload-template-dro p f-grey pointer"
                    style={{
                      width: "264px",
                    }}
                    onClick={(e) => {}}
                  >
                    {productCategoryMappingData?.ObjectList?.map(
                      (item, index) => {
                        return (
                          <div
                            className={`d-flex align-items-center pl-3 pr-2 f-grey f-14 f-500  bg-hover-grey-light pointer pt-1 pb-2   `}
                            onClick={(e) => {
                              e.target
                                .closest(".transparennt-wrapper-dropdown")
                                .click();
                              setSelectedParentCategory(item);
                              setSearchParentCategoryString(item?.name);
                            }}
                          >
                            {item?.name}
                          </div>
                        );
                      }
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}

            <div className="f-grey my-2">
              “<span className="f-14 f-500 f-grey">{search}</span>” will be
              imported as a new{" "}
              {importAsCategory ? "category" : "subcategory"}
              {" "}
              {importAsCategory === false && selectedParentCategory && (
                <>
                  of{" "}
                  <span className="f-14 f-500 f-grey">
                    {selectedParentCategory?.name}
                  </span>
                </>
              )}
                
               
            </div>
            <div className="d-flex align-items-center justify-content-end">
              <Button className="mr-2 hg-cancel-btn"
               onClick={(e)=>{
                closeDropdown(e)
                
              }}>Cancel</Button>
              <Button
                disabled={importAsCategory === false && !selectedParentCategory}
                className={`${"hg-blue-btn"}`}
                onClick={(e) => {
                  let event = e.target;
                  if(importAsing)return
                  setImportAsing(true)
                  if (importAsCategory) {
                    updateProductData(
                      [
                        {
                          id: productDetail?.id,
                          state: "Mapped",
                          ImportName: search,
                          ParentObjId: 0,
                        },
                      ],
                      {
                        optionEnum2: 0,
                      }
                    ).then((response) => {
                      setImportAsing(false);
                      if(response?.Success){
                        onStateChange( "ImportAsCategory" , {
                          Id: response?.ObjectTemplateList[0]?.id,
                          data: response?.ObjectTemplateList[0],
                        });
                        // closeDropdown(event);
                        event.closest(".map-clients-dropdown").click();
                      }else{
                        CallToast("Something went wrong", false, false, "v2style");
                      }
                    
                    });
                  } else {
                    updateProductData(
                      [
                        {
                          id: productDetail?.id,
                          state: "Mapped",
                          ImportName: search,
                          ParentObjId: selectedParentCategory?.id,
                        },
                      ],
                      {
                        optionEnum2: 1,
                      }
                    ).then((response) => {
                      setImportAsing(false);
                      if(response?.Success){
                        onStateChange( "ImportAsCategory", {
                          Id: response?.ObjectTemplateList[0]?.id,
                          data: response?.ObjectTemplateList[0],
                        });
                         
                        event.closest(".map-clients-dropdown").click();


                      }else{

                        CallToast("Something went wrong", false, false, "v2style");
                      }

                  
                    });
                    
                  }
                 
                }}
              >
                {importAsing?  "Saving.."  :"Save"}
              </Button>
            </div>
          </div>
        )}


      </Dropdown.Menu>
    </Dropdown>
  );
};

const SwitchingTypeModal = ({
  show,
  hideModal,
  executeChange,
  selectedType,
}) => {
  const bodyMaterial = {
    2: {
      header: "Confirm Switching to Map by Products",
      body: "Are you sure you want to switch to ‘Map by Products’? This action will delete all category mappings that you have made so far.",
      greyBtnValue: "Keep Category Mappings",
      redBtnValue: "Continue",
    },
    3: {
      header: "Confirm Switching to Map by Categories",
      body: "Are you sure you want to switch to ‘Map by Categories? This action will reset all product mappings that you have made so far.",
      greyBtnValue: "Keep Product Mappings",
      redBtnValue: "Continue",
    },
  };
  return (
    <Modal
      show={show}
      dialogClassName="map-confirm-modal"
      className=" "
      aria-labelledby="map-confirm-modal"
      centered
      onHide={hideModal}
    >
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <div className="permission mb-1 mt-3">
              {bodyMaterial[selectedType].header}
            </div>
          </Col>
          <Col xs={12}>
            <div className="d-flex align-items-center my-2 f-500 f-15 justify-content-start">
              {bodyMaterial[selectedType].body}
            </div>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex justify-content-end pb-2">
          <Button className="hg-cancel-btn mr-2" onClick={hideModal}>
            {bodyMaterial[selectedType].greyBtnValue}
          </Button>
          <Button
            className="delete-btn"
            disabled={false}
            onClick={() => {
              executeChange();
              hideModal();
            }}
          >
            {bodyMaterial[selectedType].redBtnValue}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

function ToggleAccordion(e, work = true) {
  if (!work) return;
  var parent = e.target.closest(".point");
  var panel = parent.querySelector(".msp-cat-panel");
  if (panel?.style?.maxHeight) {
    panel.style.maxHeight = null;
    panel.style.overflow = "hidden";
  } else {
    panel.style.maxHeight = panel.scrollHeight + "px";
    panel.style.overflow = "visible";
  }
}

const MapProductCategoryLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="27%" y="20" rx="8" ry="8" width="4.5%" height="60" />
      <rect x="33%" y="22" rx="6" ry="6" width="10%" height="20" />
      <rect x="33%" y="52" rx="6" ry="6" width="25%" height="20" />

      <rect x="27%" y="100" rx="7" ry="7" width="45%" height="60" />

      <rect x="27%" y="200" rx="4" ry="4" width="6%" height="25" />
      <rect x="62%" y="200" rx="4" ry="4" width="10%" height="25" />

      <rect x="27%" y="230" rx="4" ry="4" width="45%" height="30" />

      <rect x="27%" y="262" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="324" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="386" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="448" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="510" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="572" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="634" rx="4" ry="4" width="45%" height="60" />
    </ContentLoader>
  );
};
