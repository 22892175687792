import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import "./billing.scss";
import Loader from "../../Common/loader";
import ContentLoader from "react-content-loader";
const CancelSubscriptionModal = ({ show, hideModal, data, Refreshpage }) => {
  const { authTokens } = useAuth();
  const [modalData, setModalData] = useState({});
  const [loading, setLoading] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  useEffect(() => {
    if (show) {
      // console.log(data);
      setLoading(true);
      getData();
    }
  }, [show]);

  const getData = async () => {
    const response = await getAPIData("206", authTokens, {
      optionEnum1: data?.SubscriptionId,
    });

    if (response.mr.Success == true) {
      setLoading(false);
      setModalData(response);
    }
  };

  const cancelSubscription = async () => {
    setCancelling(true);
    const response = await getAPIData("207", authTokens, {
      optionEnum1: data?.SubscriptionId,
    });

    if (response.Success == true) {
      setCancelling(false);
      hideModal();
      Refreshpage();
      CallToast("Subscription cancelled successfully", false, false, "v2style");
    } else {
      setCancelling(false);
      hideModal();
      CallToast("Something went wrong", false, false, "v2style");
    }
  };

  return (
    <Modal
      // show={true}
      show={show}
      dialogClassName="cancel-subscription-modal"
      className=""
      aria-labelledby="cancel-subscription-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Body className="p-4">
        {loading && (
          <div className="my-4">
            {/* <Loader /> */}
            <ContentLoader
              speed={2}
              width={"100%"}
              height={"100%"}
              viewBox="0 0 1500 390"
              backgroundColor="#F6F8FB"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="6" ry="6" width="60%" height="40" />
              <rect x="0" y="110" rx="8" ry="8" width="98%" height="150" />
              <rect x="0" y="350" rx="6" ry="6" width="80%" height="40" />
              <rect x="0" y="400" rx="6" ry="6" width="30%" height="40" />
            </ContentLoader>
          </div>
        )}
        {!loading && (
          <>
            <div className="heading mb-3">{modalData?.Description}</div>
            <div className="option-wrapper d-flex justify-content-between align-items-start">
              <div className="d-flex align-items-center">
                {modalData?.ProService_Icon && (
                  <img
                    alt=""
                    src={`/images/ProServices/${modalData?.ProService_Icon}`}
                    className=""
                    style={{
                      width: "30px",
                      objectFit: "contain",
                      borderRadius: "50%",
                    }}
                  />
                )}
                <div className="ml-2">
                  <p className="title m-0">{modalData?.ProService_Name} </p>
                  <p className="subtitle m-0">{modalData?.ProService_Price} </p>
                </div>
              </div>
              <div className="title"></div>
            </div>

            <p className="title mt-3">{modalData?.DateMessage}</p>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {loading ? (
          <ContentLoader
            speed={2}
            width={"100%"}
            // height={"100%"}
            viewBox="0 0 1500 100"
            backgroundColor="#F6F8FB"
            foregroundColor="#ecebeb"
          >
            <rect x="62%" y="0" rx="6" ry="6" width="14%" height="80" />
            <rect x="79%" y="0" rx="6" ry="6" width="20%" height="80" />
          </ContentLoader>
        ) : (
          <div className="d-flex justify-content-end">
            <Button className="cancel-btn" onClick={hideModal}>
              Cancel
            </Button>
            <Button
              disabled={cancelling}
              className="downgrade-btn"
              onClick={cancelSubscription}
            >
              {cancelling ? (
                <Spinner
                  size="sm"
                  animation="border"
                  role="status"
                  className="mr-2"
                />
              ) : null}
              Cancel Subscription
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CancelSubscriptionModal;
