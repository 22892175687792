import React, { Fragment } from "react";
import "./sign-up.scss";
import { TrackUser } from "../../Utils/SegmentFunctions";
import {
  Button,
  Col,
  Dropdown,
  Form,
  InputGroup,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { useEffect } from "react";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import { useState } from "react";
import { useAuth } from "../context/Auth";
import { useDb } from "../context/db";
import { useHistory } from "react-router-dom";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import CompanySettingsDropdown from "../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import * as animationData from "../Spinner/Icon-check.json";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { LeftSideWelcomeBannerText } from "./Loginv2";
import { CustomGetApi } from "../../Utils/commonApi";
import * as createdData from "./created.json";
import * as creatingData from "./creating.json";
import CryptoJS from 'crypto-js';

const SignUpv2 = (props) => {
  const history = useHistory();
  const { setUserType, setAuthTokens, setAuth } = useAuth();
  const { setdbObject } = useDb();
  const [passwordShow, setPasswordShow] = useState(false);
  const [redirectingMessage, setRedirectingMessage] = useState(
    "Creating your account, please wait.."
  );
  const [finishSignUpData, setFinishSignUpData] = useState({
    signUpCompleted: false,
    data: null,
    callBackFunc: null,
  });
  const [email, setEmail] = useState("");
  const [otherRole, setOtherRole] = useState("");
  const [otherRoleError, setOtherRoleError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [happyToRecieve, setHappyToReceive] = useState(false);
  const [fullName, setFullName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [role, setRole] = useState("CEO");
  const [formSubmit, setFormSubmit] = useState("submitRole");
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [showAnimation, setShowAnimation] = useState(false);
  const [resendEmail, setResendEmail] = useState(false);
  const [resendEmailState, setResendEmailState] = useState(0);
  const [companyError, setCompanyError] = useState(false);
  const [roleData, setRoleData] = useState();
  //
  const [IsMsp, setIsMsp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [referral, setReferral] = useState(
    props.match.params.referraltoken || ""
  );
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({
    objId: 4097775,
    name: "United Kingdom",
    code: "GB",
  });

  async function getCountryList() {
    await CustomGetApi(`CC_API/GetCountries?IsSimpleSort=0`)
      .then((response) => {
        if (response?.mr?.Success) {
          setCountryList(response?.detail);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    let user_token = localStorage.getItem("tokens");
    let user_data = localStorage.getItem("object_Data");
    let existingAuth = localStorage.getItem("userAuthenticated")
      ? Boolean(JSON.parse(localStorage.getItem("userAuthenticated")))
      : null;
    let userAccesKey = localStorage.getItem("user_MessageObj")
      ? JSON.parse(localStorage.getItem("user_MessageObj"))
      : null;
    if (user_token && user_data && existingAuth && userAccesKey) {
      let _data = JSON.parse(user_data);

      if (_data?.UserDetail?.Role?.toLowerCase() === "msp") {
        // If Role is ""MSP"""
        history.push("/mspv2/clients");
        return;
      }
      history.push(`/${_data?.UserDetail?.Role?.toLowerCase()}/dashboard`);
    } else {
      setdbObject();
      localStorage.setItem("dasboardLoader", true);
      getCountryList();
    }
  }, []);

  let runOnce = 0;

  // Redirect User directly to Login Screen
  function RedirectToDashboard(email, password) {
    if (runOnce > 0) return;
    runOnce = 2;
    setTimeout(() => {
      runOnce = 0;
    }, 3000);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "password");
    urlencoded.append("username", email);
    urlencoded.append("password", password);
    urlencoded.append("ipaddress", "111.111.111.111");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/ObjectraAPI/Token`,
      // `https://hg-dev-ui-ac-api.azurewebsites.net/ObjectraAPI/Token`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          if (
            data.error_description ==
            "The email address provided has not been confirmed."
          ) {
            // setFormState(4);
          }
        } else {
          //Set Token to Local Storage
          setAuthTokens(data.access_token);
          const token = data.access_token;
          localStorage.setItem("tokens", JSON.stringify(data.access_token));
       
          setRedirectingMessage(
            `Redirecting you to your dashboard, please wait . . .`
          );
          var myHeaders2 = new Headers();
          myHeaders2.append("Authorization", `Bearer ${data.access_token}`);

          let requestOptions = {
            method: "GET",
            headers: myHeaders2,
            redirect: "follow",
          };

          fetch(
            `${process.env.REACT_APP_API_BASE_URL}/CC_API/CheckGoogleAuth`, 
            requestOptions
          )
            .then((response) => response.json())
            .then((data) => {
              // test =false
              if (data?.ByPass2FA === true) {
                window.localStorage.setItem(
                  "token_time",
                  JSON.stringify(Math.floor(Date.now() / 60000))
                );
                console.log("enteres by pass mfa");
                let myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);

                let requestOptions = {
                  method: "GET",
                  headers: myHeaders,
                  redirect: "follow",
                };

                fetch(
                  `${process.env.REACT_APP_API_BASE_URL}/CC_API/ConfirmGooglePin?pin=999666`, 
                  requestOptions
                )
                  .then((response) => response.json())
                  .then((data) => {
                    setFinishSignUpData({
                      signUpCompleted: true,
                      data: data,
                      callBackFunc: finishSignUpStep,
                    });
 
                    const secretKey = 'Tb-6uVDaCiUgox2snXRxbhQN6yWFj0Mwq-k4P14j'; // Your secret key
                    // const userIdentifier = res?.UserDetail?.UserName; // The email for your user
                    const userIdentifier = email; // The email for your user
                  
                    // Creating HMAC using SHA256 algorithm
                    const hash = CryptoJS.HmacSHA256(userIdentifier, secretKey).toString(CryptoJS.enc.Hex);
                  
     
                    // Intercomm
                    window.Intercom('boot', {
                      app_id: 'jaa5e0c1', 
                      email:  email,
                      name: fullName ,
                    
                      user_hash:hash
                   });

 
                  })
                  .catch((err) => {
                    setFormSubmit(3);
                    setRedirectingMessage(
                      "Creating your account, please wait.."
                    );
                    setShowAnimation(false);
                    setLoading(false);
                    CallToast(
                      "500 - Internal Server Error",
                      false,
                      false,
                      "v2style"
                    );
                    console.log("error- in api 1", error);
                  });
              }
            })
            .catch((error) => {
              setFormSubmit(3);
              setRedirectingMessage("Creating your account, please wait..");
              setShowAnimation(false);
              setLoading(false);
              CallToast("500 - Internal Server Error", false, false, "v2style");
              console.log("error- in api 2", error);
            });
        }
      })
      .catch((error) => {
        setFormSubmit(3);
        setRedirectingMessage("Creating your account, please wait..");
        setShowAnimation(false);
        setLoading(false);
        CallToast("500 - Internal Server Error", false, false, "v2style");
        console.log("login error", error);
      });
  }

  function finishSignUpStep(data) {
    window.localStorage.setItem("userType", JSON.stringify(data?.EntityId));
    window.localStorage.setItem(
      "user_MessageObj",
      JSON.stringify(data.Message)
    );
    setAuth(true);

    setUserType(data.EntityId);

    /* SET USERS DETAILS AS THEY ARE REQUIRED FOR THE DASHBOARD TO LOAD CORRECTLY */
    console.log("setting access key in sign up");
    // setMessageEncrypt(data.Message);
    let element = document.getElementById("root").firstChild;
    element.classList.add("fadeIn");
    setTimeout(() => {
      element.classList.remove("fadeIn");
    }, 500);

    let userType = {
      111111: "ceo",
      999999: "ceo",
      222222: "cto",
      333333: "cfo",
    };
    setShowAnimation(false);
    if (data.EntityId === 555555) {
      history.push({
        pathname: `/mspv2/clients`,
        state: { isLogin: true },
      });
      return;
    }

    history.push({
      pathname: `/${userType[data.EntityId]}/dashboard`,
      state: { isLogin: true },
    });
  }

  const strength = [
    {
      name: "Use A-Z uppercase, a-z lowercase, 0-9 number, symbols, 8-digits",
      maxValue: 0,
      color: "greyText",
      barColor: "bar-not-active",
    },
    {
      name: "Use A-Z uppercase, symbols, 0-9 number, 8-digits",
      maxValue: 1,
      color: "redText",
      barColor: "bar-filled-red",
    },
    {
      name: "Use symbols, 0-9 number, 8-digits",
      maxValue: 2,
      color: "redText",
      barColor: "bar-filled-red",
    },
    {
      name: "Use 0-9 number, 8-digits",
      maxValue: 3,
      color: "orangeText",
      barColor: "bar-filled-orange",
    },
    {
      name: "Use 8-digits",
      maxValue: 4,
      color: "orangeText",
      barColor: "bar-filled-orange",
    },
    {
      name: "Your password is strong",
      maxValue: 5,
      color: "greenText",
      barColor: "bar-filled",
    },
  ];

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (params?.get("email")) {
      setEmail(params.get("email"));
    }
  }, []);

  function togglePasswordShow() {
    if (passwordShow) return setPasswordShow(false);
    return setPasswordShow(true);
  }

  const renderTooltip = (message) => (
    <Tooltip className="custom_tooltip">
      <div className="custom_tooltip_wrapper">
        <div className="tooltip_header border-bottom-0">
          <span className="tooltip_subtitle" style={{ color: "#F36060" }}>
            {message}
          </span>
        </div>
      </div>
    </Tooltip>
  );

  const handlePassword = (pwd) => {
    setPassword(pwd);
    var variations = {
      length: /^.{8,}$/.test(pwd),
      digits: /\d/.test(pwd),
      lower: /[a-z]/.test(pwd),
      upper: /[A-Z]/.test(pwd),
      nonWords: /\W/.test(pwd),
    };

    var strengthCount = 0;
    for (var check in variations) {
      strengthCount += variations[check] == true ? 1 : 0;
    }
    setPasswordStrength(strengthCount);
  };

  async function handleSubmit(value) {
    if (value == 1) {
      const re =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (!re.test(email)) {
        setEmailError("Please enter valid email");
        return false;
      }

      if (!password || passwordStrength < 5) {
        setPasswordError(
          "Password must contain 8 characters or more and must include lowercase, uppercase, numeric and special characters"
        );
        return;
      }
    }

    if (role == "Other") {
      if (otherRole == "") {
        setOtherRoleError("Please enter a role");
        return false;
      }
    }
    setLoading(true);
    setFormSubmit(value);
    const { is_error } = handleValidations(value);
    if (is_error) {
      setLoading(false);
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var inputs = new URLSearchParams();
    inputs.append("EmailAddress", email);
    inputs.append("ReferralCode", referral);
    inputs.append("Role", role === "Other" ? otherRole : "");
    inputs.append("Country", selectedCountry.objId);

    if (new URLSearchParams(window.location.search).get("psid")) {
      inputs.append(
        "PotentialSupplierId",
        new URLSearchParams(window.location.search).get("psid")
      );
    }
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: inputs,
      redirect: "follow",
    };
    if (value === 1) {
      await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/CC_API/CheckUserExists`,
        // `https://hg-dev-ui-ac-api.azurewebsites.net/CC_API/CheckUserExists`,
        requestOptions
      )
        .then((response) => response.json())
        .then(async (data) => {
          if (data.Success === false) {
            setLoading(false);
            setEmailError(data.Message);
          } else {
            setLoading(false);
            setFormSubmit(value + 1);
            setEmailError(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
    if (formSubmit === 2) {
      inputs.append("Password", password);
      inputs.append("HappyToReceive", happyToRecieve);
      inputs.append("FullName", fullName);
      inputs.append("CompanyName", companyName);
      inputs.append("CompanyRole", role);

      requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: inputs,
        redirect: "follow",
      };

      const data = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/CC_API/UserRegistration`,
        // `https://hg-dev-ui-ac-api.azurewebsites.net/CC_API/UserRegistration`,
        requestOptions
      )
        .then((response) => response.json())
        .then(async (data) => {
          if (data?.ExceptionMessage) {
            setLoading(false);
            setError({
              type: "danger",
              heading: "Unable to continue",
              body: data.Message,
            });
            setTimeout(() => {
              setError(false);
            }, 3000);
            setFormSubmit(2);
          }
          if (data?.Success) {
            // setLoading(false);

            setShowAnimation(true);

            setFormSubmit(value + 2);

            if (referral) {
              TrackUser("Invite Activated", {
                email: email,
                UserFullname: fullName,
              });
            } else {
              TrackUser("User signed up", {
                email: email,
                UserFullname: fullName,
              });
            }
          }
          if (data.Success === false) {
            setLoading(false);
            setShowAnimation(false);
            setFormSubmit(2);
            setCompanyError({
              type: "danger",
              heading: "Unable to continue",
              body: data.Message,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async function handleResendEmail() {
    setResendEmail(true);
    setResendEmailState(resendEmailState + 1);
    if (resendEmailState > 2) {
      var duration = 300;
    } else var duration = 10;
    var handle = setInterval(function () {
      var minutes, seconds;
      minutes = parseInt(duration / 60, 10);
      seconds = parseInt(duration % 60, 10);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      document.querySelector("#time").textContent = minutes + ":" + seconds;
      if (duration > 0) {
        duration--;
      } else {
        clearInterval(handle);
        setResendEmail(false);
      }
    }, 1000);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var inputs = new URLSearchParams();
    inputs.append("EmailAddress", email);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: inputs,
      redirect: "follow",
    };
    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/ResendVerification`,
      // `https://hg-dev-ui-ac-api.azurewebsites.net/CC_API/ResendVerification`,
      requestOptions
    )
      .then((response) => response.json())
      .then(async (data) => {
        if (data.Success == false) {
          setError({
            type: "danger",
            heading: "Unable to send",
            body: data.ExMessage,
          });
          setTimeout(() => {
            setError(false);
          }, 3000);
        } else {
          setError(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /**
   * Handle validations
   */
  const handleValidations = (submit) => {
    let error_structure = {
      eEmail: false,
      ePassword: false,
      eCompanyName: false,
      eFullName: false,
    };
    if (submit == 0) return error_structure;
    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email || !re.test(email)) {
      error_structure.eEmail = true;
      // setEmailError("Kindly enter valid email");
    }
    if (email && !re.test(email)) {
      error_structure.eEmail = "Invalid email address format.";
    }
    if (!password || passwordStrength < 5) {
      // error_structure.ePassword = true;
    }
    if (!companyName && formSubmit == 3) {
      error_structure.eCompanyName = true;
    }
    if (!fullName && formSubmit == 3) {
      error_structure.eFullName = true;
    }
    const { eEmail, ePassword, eCompanyName, eFullName } = error_structure;

    if (eEmail || ePassword || eCompanyName || eFullName) {
      error_structure.is_error = true;
    }
    return error_structure;
  };

  const { eEmail, ePassword, eCompanyName, eFullName } =
    handleValidations(formSubmit);
  const message = eEmail ? eEmail : emailError;

  return (
    <div className="d-flex w-100 h-100 p-4">
      <div className="radius-24 border1 bg-grey w-25 p-4 d-flex flex-column overflow-y-auto hide-scrollbar">
        <img alt="" src="/images/mark-lamb.svg" className="w-60 mx-auto mb-5" />
        <div className="f-16 f-500">{<LeftSideWelcomeBannerText />}</div>
        <br />
        <div>
          <div className="f-black f-16 f-700 highground-royal-font">
            Mark Lamb
          </div>
          <div className="f-darkgrey">Founder</div>
        </div>

        {/* MSP Edition  Tag */}
        {false && (
          <div
            className="bg-black radius-8 d-flex align-items-center justify-content-between mt-auto px-2 py-3 pointer"
            onClick={() => {
              window.open(
                "http://25948034.hs-sites-eu1.com/highground.io-msp-ed-elevate-your-security-sales-empower-clients"
              );
            }}
          >
            <div className="d-flex align-items-center">
              <img src="/images/white-theme-logo.svg" alt="" className="mr-3" />
              <div>
                <div className="f-white f-18 f-500 mb-2">MSP Edition</div>
                <div className="f-white-light">
                  If you are an MSP click here
                </div>
              </div>
            </div>
            <img src="/images/white-right-chevron.svg" alt="" />
          </div>
        )}
      </div>
      {formSubmit === "submitRole" && (
        <div className="d-flex align-items-center w-75 flex-column">
          <div
            className="m-auto d-flex align-items-center w-80"
            style={{
              height: "55%",
              overflow: "hidden",
            }}
          >
            <RoleSelectionCard
              className={"mr-4"}
              description={`I want to get a grip on our overall security posture and help our c-suite understand our risks.`}
              headerImageSource={"/images/logo/highground/regular.svg"}
              headerText={"I'm an Internal IT / Security Pro"}
              onClick={() => {
                setIsMsp(false);
                setRole("ceo");
                setRoleData({ id: 4, category: "SAAS" });
                setFormSubmit(0);
              }}
              submitButtonText={"Create account"}
            />
            <RoleSelectionCard
              className={""}
              description={
                "I want to have better security conversations with our clients, improve our security offerings and reduce our own cyber risk."
              }
              headerImageSource={"/images/msp-logo-icon.svg"}
              headerText={"I'm an MSP"}
              onClick={() => {
                setIsMsp(true);
                setRole("MSP");
                setRoleData({ id: 4, category: "MSP" });
                setFormSubmit(0);
              }}
              submitButtonText={"Create account"}
            />
          </div>

          <div className="f-12 f-500 f-darkgrey mt-auto mx-auto">
            © 2024 HighGround. &nbsp; &nbsp;
            <u
              className="pointer"
              onClick={() => {
                window.open(
                  "https://www.highground.io/privacy_policy/",
                  "_blank"
                );
              }}
            >
              Privacy Policy
            </u>
            &nbsp; &nbsp;
            <u
              className="pointer"
              onClick={() => {
                window.open(
                  "https://www.highground.io/highground-terms-and-conditions/",
                  "_blank"
                );
              }}
            >
              Terms & Conditions
            </u>
          </div>
        </div>
      )}
      {formSubmit !== "submitRole" && (
        <div className="w-25 mx-auto d-flex flex-column">
          {showAnimation ? (
            <div
              className="wrapperContainer paddingTopWrap text-center w-100"
              style={{ minHeight: "75vh" }}
            >
              <Lottie
                loop={false}
                play={true}
                animationData={animationData.default}
                rendererSettings={{
                  preserveAspectRatio: "xMidYMid slice",
                  filterSize: {
                    width: "200%",
                    height: "200%",
                    x: "-50%",
                    y: "-50%",
                  },
                }}
                onComplete={() => {
                  RedirectToDashboard(email, password);
                }}
                width={200}
                height={200}
              ></Lottie>
              <div className="heading4">
                {/* Sending Verification Email... */}
                {redirectingMessage}
              </div>
            </div>
          ) : (
            <div className="m-auto ">
              <div>
                <Row
                  className="align-items-center m-0"
                  style={{ paddingTop: "40px" }}
                >
                  <Col md={10} className="text-center mx-auto">
                    <div>
                      {formSubmit > 1 && (
                        <img
                          alt=""
                          src="/images/settings-v2/login-back.svg"
                          className="float-left cursor-pointer d-none"
                          onClick={() => setFormSubmit(0)}
                        />
                      )}
                      <img
                        alt=""
                        src="/images/logo-large.svg"
                        className="icon"
                      ></img>
                    </div>
                  </Col>
                </Row>
                {formSubmit > 1 ? (
                  formSubmit != 5 ? (
                    <Row
                      className="align-items-center slideLeft m-0"
                      style={{ paddingTop: "36px" }}
                    >
                      <Col md={10} className="text-center pt-2 pb-4 mx-auto">
                        <div>
                          <h3 className="lgn_HeaderText2">Almost there...</h3>
                          <div className="greyText">
                            We just need a few more details so we can setup your
                            account
                          </div>
                        </div>
                      </Col>
                      <Col
                        // md={10}
                        md={12}
                        className="mx-auto text-center"
                        style={{ paddingTop: "1em" }}
                      >
                        <Form
                          className="userLoginForm"
                          id="secondForm"
                          autoComplete="none"
                        >
                          <div className="label-sign-up">Full name</div>
                          <Form.Group
                            controlId="fullName"
                            className={eFullName && "border-danger"}
                            style={{ border: "1px solid #EBEDF1" }}
                          >
                            <InputGroup className="mr-sm-2">
                              <Form.Control
                                className="inputControl"
                                size="lg"
                                name="fullName"
                                placeholder="Enter your full name"
                                onChange={(event) =>
                                  setFullName(
                                    window.sanitizeHGtext(event.target.value)
                                  )
                                }
                                value={fullName}
                              />
                            </InputGroup>
                          </Form.Group>
                          <div className="label-sign-up">Company name</div>
                          <Form.Group
                            controlId="companyName"
                            className={`mt- ${eCompanyName && "border-danger"}`}
                            style={{ border: "1px solid #EBEDF1" }}
                          >
                            <InputGroup className="mr-sm-2">
                              <Form.Control
                                className="inputControl"
                                size="lg"
                                name="company-name"
                                placeholder="Enter Company Name"
                                onChange={(event) =>
                                  setCompanyName(
                                    window.sanitizeHGtext(event.target.value)
                                  )
                                }
                                defaultValue={companyName}
                              />
                            </InputGroup>
                          </Form.Group>
                          {companyError != false && (
                            <span className="error-text">
                              {companyError?.body ||
                                "This company name is not permitted"}
                            </span>
                          )}

                          {companyError != false && false && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="d-block"
                              style={{ textAlign: "left" }}
                            >
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 200, hide: 250 }}
                                overlay={renderTooltip(companyError.body)}
                              >
                                <span>
                                  <img alt="" src="/images/icons/error.svg" />
                                  This company name is not permitted
                                </span>
                              </OverlayTrigger>
                            </Form.Control.Feedback>
                          )}
                          {IsMsp === false && false && (
                            <>
                              <div className="label-sign-up">Role</div>
                              <div className="role-dropdown">
                                <CompanySettingsDropdown
                                  fieldId={"id"}
                                  fieldValue={"category"}
                                  selected={roleData}
                                  placeholder="Choose your role"
                                  //  showid={true}
                                  onClick={(id, val) => {
                                    setRole(val);
                                    setRoleData({ id, category: val });
                                  }}
                                  data={[
                                    { id: 1, category: "CEO" },
                                    { id: 2, category: "CTO" },
                                    { id: 3, category: "CFO" },
                                    // { id: 4, category: "MSP" },
                                    { id: 5, category: "Other" },
                                  ]}
                                />
                              </div>
                            </>
                          )}

                          {role == "Other" && (
                            <>
                              <div className="label-sign-up">
                                Specify your role
                              </div>
                              <Form.Group
                                controlId="companyName"
                                className={`mt- ${
                                  otherRoleError && "border-danger"
                                }`}
                                style={{ border: "1px solid #EBEDF1" }}
                              >
                                <InputGroup className="mr-sm-2">
                                  <Form.Control
                                    className="inputControl"
                                    size="lg"
                                    name="other-role"
                                    placeholder="Enter your role"
                                    onChange={(event) => {
                                      setOtherRole(
                                        window.sanitizeHGtext(
                                          event.target.value
                                        )
                                      );
                                      setOtherRoleError("");
                                    }}
                                    defaultValue={otherRole}
                                  />
                                </InputGroup>
                              </Form.Group>
                              {otherRoleError && (
                                <div className="d-flex justify-content-start">
                                  <span className="error-text">
                                    {otherRoleError}
                                  </span>
                                </div>
                              )}
                            </>
                          )}

                          <div className="label-sign-up">Country</div>

                          <div className="role-dropdown">
                            <CompanySettingsDropdown
                              fieldId={"objId"}
                              fieldValue={"name"}
                              selected={selectedCountry}
                              placeholder="Choose your country"
                              //  showid={true}
                              onClick={(id, val, e, value) => {
                                setSelectedCountry(value);
                              }}
                              data={countryList}
                            />
                          </div>

                          <div className="mt-4">
                            <Button
                              disabled={
                                fullName == "" ||
                                companyName == "" ||
                                roleData == null
                              }
                              size="lg"
                              variant="blue"
                              className={`form-control btnControl ${
                                fullName == "" ||
                                companyName == "" ||
                                roleData == null
                                  ? "button-disabled"
                                  : ""
                              }`}
                              onClick={() => {
                                setFinishSignUpData((prev) => ({
                                  ...prev,
                                  signUpCompleted: false,
                                }));
                                handleSubmit(3);
                              }}
                            >
                              {loading ? (
                                <span>
                                  <Spinner size="sm" animation="border" />{" "}
                                  Please wait..
                                </span>
                              ) : (
                                "Create Account"
                              )}
                            </Button>
                            <div
                              className="d-flex align-items-center justify-content-center f-grey mt-3 pointer"
                              onClick={() => setFormSubmit(0)}
                            >
                              Back
                            </div>
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  ) : (
                    <Redirect
                      to={{
                        pathname: "/login",
                        // search: `?email=${email}`,
                        state: { newEmail: email, newPassword: password },
                      }}
                    />
                  )
                ) : (
                  <Row
                    className="align-items-center"
                    style={{ paddingTop: "36px" }}
                  >
                    <Col md={10} className="text-center pt-2 pb-4 mx-auto">
                      <div>
                        <div className="f-24 f-700 highground-royal-font mb-2">
                          Get started for free
                        </div>
                        <div className="greyText">
                          Signup for a 30 day free trial
                        </div>
                      </div>
                    </Col>
                    <Col md={12} className=" ">
                      <Dropdown className="transparennt-wrapper-dropdown">
                        <Dropdown.Toggle className="p-0 w-100">
                          <div className="d-flex align-items-center justify-content-between bg-grey radius-8 p-3">
                            <div className="d-flex align-items-center">
                              <img
                                src="/images/logo/highground/short.svg"
                                alt=""
                                className="mr-2"
                              />
                              <div className="d-flex align-items-start flex-column">
                                <div className="f-darkgrey d-flex">
                                  Account type:
                                </div>
                                <div className="f-500 f-black">
                                  {roleData?.category === "SAAS"
                                    ? "Internal IT / Security Team"
                                    : "Managed Service Provider"}
                                </div>
                              </div>
                            </div>
                            <div>
                              <img src="/images/big-chevron-down.svg" alt="" />
                            </div>
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="py-0 w-100 f-500">
                          <div
                            className="p-2 hg-hover-grey pointer"
                            onClick={(e) => {
                              e.target
                                .closest(".transparennt-wrapper-dropdown")
                                .click();
                              setIsMsp(false);
                              setRole("ceo");
                              setRoleData({ id: 4, category: "SAAS" });
                            }}
                          >
                            Internal IT / Security Pro
                          </div>

                          <div
                            className="p-2 hg-hover-grey pointer"
                            onClick={(e) => {
                              e.target
                                .closest(".transparennt-wrapper-dropdown")
                                .click();
                              setIsMsp(true);
                              setRole("MSP");
                              setRoleData({ id: 4, category: "MSP" });
                            }}
                          >
                            Managed Service Provider
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>

                    <Col
                      //   md={10}
                      md={12}
                      className="mx-auto"
                      style={{ paddingTop: "1em" }}
                    >
                      <Form className="userLoginForm" autoComplete="nope">
                        <div className="label-sign-up">Email</div>
                        <Form.Group
                          controlId="loginEmail"
                          className={emailError ? "border-danger" : ""}
                          style={{ border: "1px solid #EBEDF1" }}
                        >
                          {" "}
                          <InputGroup className="mr-sm-2">
                            <Form.Control
                              className="inputControl"
                              size="lg"
                              type="email"
                              placeholder="Enter your email"
                              onChange={(event) => {
                                setEmail(
                                  window.sanitizeHGtext(event.target.value)
                                );

                                setEmailError("");
                              }}
                              required
                              value={email}
                              // defaultValue={email}
                            />
                          </InputGroup>
                        </Form.Group>
                        {emailError && (
                          <span className="error-text">{emailError}</span>
                        )}
                        {(emailError ||
                          eEmail == "Invalid email address format.") &&
                          false && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="d-block"
                            >
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 200, hide: 250 }}
                                overlay={renderTooltip(message)}
                              >
                                <span>
                                  <img alt="" src="/images/icons/error.svg" />{" "}
                                  This email is not permitted.
                                </span>
                              </OverlayTrigger>{" "}
                            </Form.Control.Feedback>
                          )}
                        <div className="label-sign-up">Password</div>
                        <Form.Group
                          controlId="loginPassword"
                          className={`mt- ${
                            ePassword && false && "border-danger"
                          }`}
                          style={{ border: "1px solid #EBEDF1" }}
                        >
                          <InputGroup className="mr-sm-2">
                            <Form.Control
                              className="inputControl"
                              size="lg"
                              placeholder="Create password"
                              type={passwordShow ? "text" : "password"}
                              onChange={(event) => {
                                handlePassword(
                                  window.sanitizeHGtext(event.target.value)
                                );
                                setPasswordError("");
                              }}
                              defaultValue={password}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text
                                className="passwordIcon"
                                onClick={togglePasswordShow}
                              >
                                {passwordShow ? (
                                  <BsEyeSlashFill />
                                ) : (
                                  <BsEyeFill />
                                )}
                              </InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </Form.Group>

                        {passwordError && (
                          <span className="error-text">{passwordError}</span>
                        )}

                        {passwordStrength < 5 && ePassword && false && (
                          <span className="error-text">
                            Password must contain 8 characters or more and must
                            include lowercase, uppercase, numeric and special
                            characters
                          </span>
                        )}
                        {passwordStrength < 5 && ePassword && false && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            Password must contain 8 characters or more and must
                            include lowercase, uppercase, numeric and special
                            characters
                          </Form.Control.Feedback>
                        )}
                        <Fragment>
                          <div className="d-flex justify-content-between mt8px mb8px">
                            {[1, 2, 3, 4, 5].map((bar) => (
                              <div
                                className={
                                  bar <= passwordStrength
                                    ? strength.find(
                                        (elmt) =>
                                          elmt.maxValue >= passwordStrength
                                      ).barColor
                                    : "bar-not-active"
                                }
                              ></div>
                            ))}
                          </div>
                          <div
                            className={
                              strength.find(
                                (elmt) => elmt.maxValue >= passwordStrength
                              ).color
                            }
                          >
                            {
                              strength.find(
                                (elmt) => elmt.maxValue >= passwordStrength
                              ).name
                            }
                          </div>
                        </Fragment>
                        <Form.Check
                          type="checkbox"
                          id="checkOne"
                          className="m-0 textBlackBold mt16px mobilePaddingTop"
                          label="I'm happy to receive updates by email"
                          onChange={() => setHappyToReceive(!happyToRecieve)}
                        />
                      </Form>
                      <div className="mt-4">
                        <Button
                          size="lg"
                          variant="blue"
                          disabled={email === "" || password === ""}
                          className={`form-control btnControl     ${
                            email === "" || password === ""
                              ? "button-disabled"
                              : ""
                          } `}
                          onClick={() => handleSubmit(1)}
                        >
                          {loading ? (
                            <span>
                              <Spinner
                                size="sm"
                                className="mr8px"
                                animation="border"
                              />
                              Please wait...
                            </span>
                          ) : (
                            "Continue"
                          )}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                )}

                {/* {formSubmit > 1 && (
                <Row style={{ paddingTop: "70px" }}>
                  <Col md={12} className="text-center">
                    &nbsp; 
                  </Col>
                </Row>
              )} */}
              </div>
              <Row className="">
                <Col md={12} className="pt-2 pb-3 mx-auto ">
                  {!(formSubmit > 1) && (
                    <div className="text-center">
                      <p className="lgnGreyText terms pb-5 pt-2">
                        By creating a HighGround.io account, you are confirming
                        you have read, understood and agree to our &nbsp;
                        <u
                          className="pointer"
                          onClick={() => {
                            window.open(
                              "https://www.highground.io/privacy_policy/",
                              "_blank"
                            );
                          }}
                        >
                          Privacy Policy
                        </u>
                        &nbsp;and&nbsp;
                        <u
                          className="pointer"
                          onClick={() => {
                            window.open(
                              "https://www.highground.io/highground-terms-and-conditions/",
                              "_blank"
                            );
                          }}
                        >
                          Terms & Conditions
                        </u>
                        .
                      </p>
                      <p className="f-black mb-5">
                        Already have an account?{" "}
                        <Link to="/login" className="txt-blue">
                          Log in
                        </Link>
                      </p>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          )}
          {/* </Col> */}

          {/* </Row> */}

          <div className="f-12 f-500 f-darkgrey mt-auto mx-auto">
            © 2024 HighGround. &nbsp; &nbsp;
            <u
              className="pointer"
              onClick={() => {
                window.open(
                  "https://www.highground.io/privacy_policy/",
                  "_blank"
                );
              }}
            >
              Privacy Policy
            </u>
            &nbsp; &nbsp;
            <u
              className="pointer"
              onClick={() => {
                window.open(
                  "https://www.highground.io/highground-terms-and-conditions/",
                  "_blank"
                );
              }}
            >
              Terms & Conditions
            </u>
          </div>
        </div>
      )}

      {formSubmit >= 3 && (loading || showAnimation) && (
        <SaasAnimation
          signUpCompleted={finishSignUpData.signUpCompleted}
          callBack={finishSignUpData.callBackFunc}
          callBackData={finishSignUpData.data}
          userType={IsMsp?  "MSP" :  "SAAS"}
        />
      )}
    </div>
  );
};

export default SignUpv2;

const RoleSelectionCard = ({
  className,
  headerImageSource,
  headerText,
  description,
  submitButtonText,
  onClick,
}) => {
  return (
    <div
      className={`d-flex flex-column align-items-center justify-content-between py-4 px-5 flex-1 h-100 ${className}`}
      style={{
        background: "#F6F8FB",
        border: "1px solid #EBEDF1",
        borderRadius: "24px",
      }}
    >
      <div className="w-100">
        <div className="w-100 d-flex align-items-center justify-content-center">
          <img
            src={headerImageSource}
            alt=""
            className="mx-auto"
            style={{
              marginTop: "5rem",
              marginBottom: "5rem",
            }}
          />
        </div>

        <div
          className="f-24 f-700 w-100 highground-royal-font"
          style={{
            lineHeight: "30px",
          }}
        >
          {headerText}
        </div>
        <div
          className="f-16 f-400 mb-2 w-100"
          style={{
            lineHeight: "28px",
            textWrap: "balance",
          }}
        >
          {description}
        </div>
      </div>

      <div className="w-100">
        <Button className="hg-blue-btn w-100 radius-2" onClick={onClick}>
          {submitButtonText}
        </Button>
      </div>
    </div>
  );
};

const SaasAnimation = ({ signUpCompleted, callBack, callBackData ,userType="SAAS"}) => {
  const [index, setIndex] = useState(0);
  const [completedCount, setCompletedCount] = useState(0)
 

  useEffect(() => {
    const interval = setInterval(() => {
      setCompletedCount((prev)=>prev+1)
      setIndex((prevIndex) => (prevIndex + 1) % SaasUserMessages.length);
    }, 5000); // Change message every 3 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
     if(signUpCompleted){

     }
  }, [signUpCompleted])
  
  const SaasUserMessages = [
    "Creating your cyber KPIs",
    "Customising your Technology Spend dashboard",
    "Setting up your Governance & Resilience centre",
    "Creating your Attack Surface Management module",
    "Setting up your Integrations",
    "Building your Dashboards",
  ];


const welcomeAnimationData = {
  MSP: {
    messages: [
      "Creating your your Security Stack",
      "Building your Security Operations centre",
      "Configuring your Sell module",
      "Setting up your Integrations",
      "Creating your Action Centre",
      "Adding your MSPs free NFR account",
    ],
    bannerIcon: [
      "msp-anim-1.webp",
      "msp-anim-2.webp",
      "msp-anim-3.webp",
      "msp-anim-4.webp",
      "msp-anim-5.webp",
      "msp-anim-6.webp",
    ],
    explanationsheader: [
      "Build your security stack",
      "Improve your security operations",
      "Increase your security sales",
      "Integrations",
      "Action Centre",
      "Free NFR account",
    ],
    explanationsHeaderIcon: [],
    explanations: [
      "Your security stack is a crucial part of your security offering to clients - make sure you have all the bases covered.",
      <>
        Having a good security stack is only half the battle - how you operate is just as important.
        <br />
        <br />
        Manage engineers certifications, your organisations accreditations, alignment to security frameworks and your security processes & procedures from HighGround.
      </>,
      <>
        Selling cyber to your clients is a harder sell than it ought to be.
        <br />
        <br />
        Build security packages your clients can visualize and understand, empowering them to make informed decisions on the security risks they are willing to take.
      </>,
      "Automate data ingestion from your PSA, RMM & Security tools and gain security insights like never before. Because lets face it, you ain’t got time to enter it manually!",
      <>
        So many ideas, so little time to implement them! Taking action is always the hardest part, with a lack of follow-through killing your progress.
        <br />
        <br />
        Create your actions in HighGround and assign them to your team - and yes, you can sync them with your existing task management tools too!
      </>,
      <>
        Why should you have to pay to play when you’re selling HighGround to your clients?
        <br />
        <br />
        MSPs get HighGround free for internal use, because let’s face it, that’s how it should be!
      </>
    ],
    
  },
  SAAS: {
    messages: [
      "Creating your cyber KPIs",
      "Customising your Technology Spend dashboard",
      "Setting up your Governance & Resilience centre",
      "Creating your Attack Surface Management module",
      "Setting up your Integrations",
      "Building your Dashboards",
    ],
    bannerIcon: [
      "saas-anim-1.webp",
      "saas-anim-2.webp",
      "saas-anim-3.webp",
      "saas-anim-4.webp",
      "saas-anim-5.webp",
      "saas-anim-6.webp",
    ],
    explanationsheader: [
      "Cyber KPIs",
      "Technology Spend",
      "Governance & Resilience",
      "Attack surface",
      "Integrations",
      "Dashboard",
    ],
    explanationsHeaderIcon: [
      "/images/navIcons/dashboardIcon.svg",
      "/images/navIcons/technology-spend-icon.svg",
      "/images/navIcons/complianceDashboardIcon.svg",
      "/images/attack-surface/attack_surface_dashboard_icon.svg",
      "/images/navIcons/intergrationsDashboard.svg",
      "/images/navIcons/dashboardIcon.svg",
    ],
    explanations: [
      "Simple KPIs help senior management understand and track your organisations cyber security posture.",
      "Mapping your technology spend helps you see just how little really goes to protecting your organisation.",
      "Ensure your can bounce back from a cyber-attack by being cyber resilient, and your senior management more cyber-aware.",
      "Mapping your attack surface is crucial to understanding, reducing and securing it.",
      "Automate data ingestion from across your security stack and gain security insights like never before.",
      "Consolidate the metrics that matter the most and present them to management with ease.",
    ],
  },
};


  return (
    <div
      className="d-flex w-100 h-100 p-4 bg-white signup-animation"
      style={{
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 20000000,
      }}
    >
      <div className="radius-24 border1 bg-grey w-25 p-4 d-flex flex-column overflow-y-auto hide-scrollbar">
        <img
          alt=""
          src={"/images/"+ welcomeAnimationData[userType].bannerIcon[index]}
          // src={"/saas-anim-1.svg"}
          className="w-100 mx-auto mb- my-5"
          style={{
            borderRadius: '8px',
            border: '1px solid var(--Grayscale-Gray-05, #EBEDF1)',
            boxShadow: '0px 12px 24px 0px rgba(16, 16, 16, 0.08)',
          }}
        />
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="d-flex align-items-center f-grey f-16">
            {" "}
          {welcomeAnimationData[userType].explanationsHeaderIcon[index]&&  <img
              src={welcomeAnimationData[userType].explanationsHeaderIcon[index]}
              width={18}
              height={18}
              alt=""
              className="mr-2"
            />}{" "}
          { welcomeAnimationData[userType].explanationsheader[index]}
          </div>

          <div></div>
        </div>
        <div className="f-16 f-500 position-relative">
        {/* { welcomeAnimationData[userType].explanations[index]} */}

        {welcomeAnimationData[userType].explanations.map((message, i) => (
                    <div
                      key={i}
                      className={`${
                        i === index
                          ? "visible"
                          : ""
                      } position-absolute f-16 f-500 signup-message`}
                      style={{ animationDuration: index === -1 ? "0s" : "2s" }}
                    >
                      {message}
                    </div>
                  ))}
        </div>
        <br />
        <div>
          {/* <div className="f-black f-16 f-700 highground-royal-font">
            Mark Lamb
          </div>
          <div className="f-darkgrey">Founder</div> */}
        </div>
      </div>
      {true && (
        <div className="d-flex align-items-center  w-75 flex-column">
          <div
            className="m-auto d-flex align-items-center justify-content-center flex-column w-80"
            style={{
              // height: "55%",
              overflow: "hidden",
            }}
          >
            { ( completedCount >5 && signUpCompleted) ? (
              <div
                className="radius-8 zoom-independent"
                style={{
                  // boxShadow: "1px 1px 5px 1px rgba(0,0,0,0.1)",
                }}
              >
                <Lottie
                  loop={false}
                  play={true}
                  speed={0.7}
                  animationData={createdData.default}
                  style={{ width: 340, height: 300, margin: "0 auto" }}
                  onComplete={() => {
                    if (callBack) {
                      callBack(callBackData);
                    }
                  }}
                />
              </div>
            ) : (
              <div
                className="radius-8 zoom-independent"
                style={{
                  // boxShadow: "1px 1px 5px 1px rgba(0,0,0,0.1)",
                }}
              >
                <Lottie
                  loop={true}
                  play={true}
                  speed={0.7}
                  animationData={creatingData.default}
                  style={{ width: 340, height: 300, margin: "0 auto" }}
                   
                />
              </div>
            )}
            {( completedCount >5 && signUpCompleted) ? (
              <>
                <div
                  className="d-flex align-items-center justify-content-center w-100 text-center f-500 f-16 signup-message visible"
                  style={{
                    marginTop: "41px",
                  }}
                >
                  Account created!
                </div>

                <div className="f-darkgrey mt-3">
                  Redirecting to your dashboard....
                </div>
              </>
            ) : (
              <>
                <div
                  className="d-flex align-items-center justify-content-center w-100 text-center position-relative"
                  style={{
                    marginTop: "41px",
                  }}
                >
                  {welcomeAnimationData[userType].messages.map((message, i) => (
                    <div
                      key={i}
                      className={`${
                        i === index
                          ? "visible"
                          : ""
                      } position-absolute f-16 f-500 signup-message`}
                      style={{ animationDuration: index === -1 ? "0s" : "2s" }}
                    >
                      {message}
                    </div>
                  ))}
                </div>

                <div className="f-darkgrey mt-3">Please wait ...</div>
              </>
            )}
          </div>

          <div className="f-12 f-500 f-darkgrey mt-auto mx-auto">
            © 2024 HighGround. &nbsp; &nbsp;
            <u
              className="pointer"
              onClick={() => {
                window.open(
                  "https://www.highground.io/privacy_policy/",
                  "_blank"
                );
              }}
            >
              Privacy Policy
            </u>
            &nbsp; &nbsp;
            <u
              className="pointer"
              onClick={() => {
                window.open(
                  "https://www.highground.io/highground-terms-and-conditions/",
                  "_blank"
                );
              }}
            >
              Terms & Conditions
            </u>
          </div>
        </div>
      )}
    </div>
  );
};
