import React, { useEffect, useState } from "react";
import { getPageDataApi, postData } from "../../../Utils/commonApi";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { useAuth } from "../../context/Auth";

const MspAccountAbout = ({accountId}) => {
const {authTokens}= useAuth()
 const [pageData, setPageData] = useState({})
useEffect(() => {
  getPageData()
}, [ ])


function getPageData( ) {
  return new Promise((resolve, reject) => {
    getPageDataApi("GetAboutTabDetails", authTokens, {
      objId_Customer:accountId,
      optionEnum98:accountId
    })
      .then((response) => {
        // CallToast("Deleted successfully", false, false, "v2style");
        setPageData(response?.detail)
        resolve(response);
      
      })
      .catch((err) => {
        reject("Something went wrong");
      });
  });
}

  function createEditInvoice(_invoice) {
    return new Promise((resolve, reject) => {
      postData(authTokens, "CreateUpdateSubscriptionInvoice", {
        invoice: JSON.stringify(_invoice),
      })
        .then((response) => {
          CallToast("Updated successfully", false, false, "v2style");
          
          resolve(response);
        })
        .catch((err) => {
          reject("Something went wrong");
        });
      });
  }



  return (
    <div className="w-70 mx-auto">
      <div className="f-16 f-600 my-3">About</div>
      <div className="my-2 f-500">Address</div>
      <div className="border-parent bg-grey radius-8">
        <div className="d-flex align-item-center justify-content-between border-item p-3">
          <div className="f-500">Address</div>
          <div
            className={`${
              pageData?.AddressDetails?.Address ? "f-grey" : "f-darkgrey"
            }`}
          >
            {pageData?.AddressDetails?.Address
              ? pageData?.AddressDetails?.Address
              : "Empty"}
          </div>
        </div>
        <div className="d-flex align-item-center justify-content-between border-item p-3">
          <div className="f-500">Country</div>
          <div className="f-grey">
            {pageData?.AddressDetails?.Country}
          </div>
        </div>
      </div>
      <div className="mb-2 mt-3 f-500">Localisation</div>
      <div className="border-parent bg-grey radius-8">
        <div className="d-flex align-item-center justify-content-between border-item p-3">
          <div className="f-500">Localisation</div>
          <div className="f-grey">{
          pageData?.LocalisationDetails?.Localisation
          
          }</div>
        </div>
        <div className="d-flex align-item-center justify-content-between border-item p-3">
          <div className="f-500">Time Zone</div>
          <div className="f-grey">
            {pageData?.LocalisationDetails?.TimeZone}

          </div>
        </div>
        <div className="d-flex align-item-center justify-content-between border-item p-3">
          <div className="f-500">Language</div>
          <div className="f-grey">
          {pageData?.LocalisationDetails?.Language}
          </div>
        </div>
        <div className="d-flex align-item-center justify-content-between border-item p-3">
          <div className="f-500">Currency</div>
          <div className="f-grey">
            
          {pageData?.LocalisationDetails?.Currency}
          </div>
        </div>
      </div>
      <div className="mb-2 mt-3 f-500">Contacts</div>
      <div className="border-parent bg-grey radius-8">
        <div className="d-flex align-item-center justify-content-between border-item p-3">
          <div className="f-500">Primary Company Email</div>
          <div className="f-darkgrey">
          {pageData?.Primary_Company_Email}
          </div>
        </div>
      </div>

      <div className="mb-2 mt-3 f-500">Account owner</div>
      <div className="border-parent bg-grey radius-8">
        <div className="d-flex align-item-center justify-content-between border-item p-3">
          <div className="f-500 d-flex align-items-center">
            <img
              src={ pageData?.AccountOwnerDetails?.logo??  "/images/user-logo.svg"}
              width={24}
              height={24}
              alt=""
              className=" rounded-full mr-2"
            />{" "}
                     {pageData?.AccountOwnerDetails?.Name}

          </div>
          <div className="f-grey"> </div>
        </div>
        <div className="d-flex align-item-center justify-content-between border-item p-3">
          <div className="f-500">Email</div>
          <div className="f-grey">
          {pageData?.AccountOwnerDetails?.Email}

          </div>
        </div>
      </div>

      <div className="mb-2 mt-3 f-500">Other</div>
      <div className="border-parent bg-grey radius-8">
        <div className="d-flex align-item-center justify-content-between border-item p-3">
          <div className="f-500">Account created</div>
          <div className="f-grey">          {pageData?.Account_Created}
</div>
        </div>
      </div>
    </div>
  );
};

export default MspAccountAbout;
