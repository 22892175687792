import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "./supplier-profiler.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ProfilingModalContent from "./ProfilingModalContent";

const ProfilingModal = ({
  show,
  hideModal,
  setSelectedTemplate,
  selectedTemplate,
  showSupplierModal,
  setShowSupplierModal,
  setTitleTemplate,
  selectedSupplier
}) => {
  return (
    <Modal
      show={show}
      dialogClassName="empty-modal "
      className=" supplier-assessment d-flex justify-content-center  hide-scrollbar"
      aria-labelledby=" "
      onHide={hideModal}
      centered
      scrollable
      // style={{width: '1200px'}}
    >
      <Modal.Body
        className="p-0 hide-scrollbar"
        style={{ width: "1200px", overflowY: "unset" }}
      >
        {
          <ProfilingModalContent
            hideModal={hideModal}
            isTemplate={true}
            setSelectedTemplate={setSelectedTemplate}
            selectedTemplate={selectedTemplate}
            showSupplierModal={showSupplierModal}
            setShowSupplierModal={setShowSupplierModal}
            setTitleTemplate={setTitleTemplate}
            selectedSupplier={selectedSupplier}
          />
        }
      </Modal.Body>
    </Modal>
  );
};

export default ProfilingModal;
