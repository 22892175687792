import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import "./modals-styles.scss"
const TrialPeriodModal = ({show,hideModal}) => {
  return (
    <Modal
    show={show}
    dialogClassName=""
    className="modal-height-auto modal-radius-8 "
    aria-labelledby="asset-modal"
    centered
    scrollable
    onHide={hideModal}

  >

<Modal.Body className='p-3'>
    <div className='f-16 f-500 mb-3'>Extend Trial Period</div>
    <div className='f-14 f-darkgrey mb-3'>Previous Trials</div>
    <div className='d-flex align-items-center justify-content-between mb-3'>
        <div>
            <span className='f-black mr-3'>1 Jan, 2024 - 14 Jan, 2024</span>
            <span className='f-darkgrey'>Ended</span>
        </div>
        <div>14 of 14 days</div>

    </div>
    <div className='f-14 f-darkgrey mb-2'>Days to extend</div>
    <div className='highlight-input-with-grey-border w-100 radius-4 d-flex align-items-center justify-content-between mb-2'>
<div className='flex-1'>
    <input
    type='text'
    className='w-100 border-none bg-transparent p-2'
    />
</div>
<div className='f-500 f-grey mr-2'>Day(s)</div>
    </div>
    <div className='f-14 f-darkgrey mb-2'>Reason</div>
    <textarea
    rows={8}
    className='w-100 p-2 border-grey radius-4'
    placeholder='Reason'
    
    />
</Modal.Body>

<Modal.Footer className='p-2 d-flex align-items-center justify-content-end border-none'>
    <Button className='hg-cancel-btn mr-2'>Cancel</Button>
    <Button className='hg-blue-btn'>Extend Trial</Button>

</Modal.Footer>















  </Modal>
  )
}

export default TrialPeriodModal