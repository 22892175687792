import React from "react";
import { Dropdown } from "react-bootstrap";
import "./dropdown.scss";

const DropdownField = (props) => {
  return (
    <Dropdown className="custom_drop-down">
      <Dropdown.Toggle className={`${props.selected ? "selected" : ""}`}>
        {props.selected
          ? props.showid
            ? props.selected[props.fieldId]
            : props.selected[props.fieldValue]
          : props.placeholder}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {props.data.map((val) => (
          <div
            className={`dropdown_item ${
              props.selected &&
              props.selected[props.fieldId] == val[props.fieldId]
                ? "selected"
                : ""
            }`}
            onClick={(e) => {
              props.onClick(val[props.fieldId], val[props.fieldValue]);
              e.target.closest(".custom_drop-down").click();
            }}
            key={val[props.fieldId]}
            data-id={val[props.fieldId]}
            data-value={val[props.fieldValue]}
          >
            {val[props.fieldValue]}
            {props.selected &&
            props.selected[props.fieldId] == val[props.fieldId] ? (
              <img
                src={"/images/theme/hovericons/tick.svg"}
                className="float-right tick"
                alt=""
              />
            ) : (
              ""
            )}
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownField;
