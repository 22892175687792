import React from "react";
import { Row, Col } from "react-bootstrap";
import ContentLoader from "react-content-loader";


const ComplianceManagerLoader = (props) => {
  return (
    <Row>            
          <Col xs={12} className="px-0">
            <div className="dashboard fullHeight">              
              <ContentLoader
                speed={2}
                width={"100%"}
                height={"100%"}
                viewBox="0 0 1380 730"
                backgroundColor="#F6F8FB"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="6" y="50" rx="12" ry="12" width="430" height="160" />
                <rect x="470" y="50" rx="12" ry="12" width="430" height="160" />
                <rect x="934" y="50" rx="12" ry="12" width="430" height="168" />

                <rect x="6" y="230" rx="12" ry="12" width="430" height="168" />
                <rect
                  x="470"
                  y="230"
                  rx="12"
                  ry="12"
                  width="430"
                  height="168"
                />
                <rect
                  x="934"
                  y="230"
                  rx="12"
                  ry="12"
                  width="430"
                  height="168"
                />

                <rect x="6" y="410" rx="12" ry="12" width="890" height="310" />
                <rect
                  x="934"
                  y="410"
                  rx="12"
                  ry="12"
                  width="430"
                  height="310"
                />
              </ContentLoader>
            </div>
          </Col>       
    </Row>
  );
};

export default ComplianceManagerLoader;
