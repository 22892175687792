import React, { useState, useEffect, Fragment } from "react";
import {
  Col,
  Row,
  Container,
  Modal,
  Dropdown,
  Table,
  Button,
} from "react-bootstrap";
import $, { data, isFunction } from "jquery";
import "../../main.scss";
import "./TechTable.css";
import { useDb } from "../context/db";
import SideBar from "../SideBar/SideBar";
import DrillDownBox from "../DrillDownBoxes/DrillDownBoxes";
import { useAuth } from "../context/Auth";
import { HiPencil } from "react-icons/hi";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  generateGravatar,
  getRequestOptions,
  PrintDataByEntityId,
} from "../../Utils/commonApi";
import LoadingSpinner from "../Spinner/Spinner";
import ModalDynamic from "../ModalDynamic/ModalDynamic";
import IRNotesChatroom from "../IncidentResponse/IRNotesChatroom/IRNotesChatroom";
import { BsEyeFill } from "react-icons/bs";
import ReusableForm from "../ComplianceGovernance/ReusableForm/ReusableForm";
import TableContentLoader from "../TableContentLoader";

const TechTable = (props) => {
  const { authTokens, setLoadingScreen } = useAuth();
  const [tableData, settableData] = useState();
  const [toggle, setToggle] = useState(props.toggleLargeTable || false);
  const [arrayData, setarrayData] = useState(props.data || "");
  const [height] = useState(props.height || "200px");
  const toggleCap = 4; // The minimum amount of values for a toggle feature to be accessible
  const [backgroundColor, setBackgroundColor] = useState(
    props.bgColor || "inherit"
  );
  const [headerTextColor, setHeaderTextColor] = useState(
    props.hdTextColor || "inherit"
  );
  const displayButtonType = props.buttonType || "cyberCareButton";
  const [onClickReaction, setOnClickReaction] = useState(
    props.onClickReaction || false
  );
  const onClickCallback = props.onClickCallback || undefined;
  const [onClickAction, setonClickAction] = useState(false);
  const [modal, setModal] = useState(false);
  const [gravatarObject, setGravatarObject] = useState(false);
  const genericImage =
    "https://cybercareimages.blob.core.windows.net/icons/DefaultAvatar.png";
  const showMoreInnerHtml =
    'Show more <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg>';
  const showLessInnerHtml =
    'Show less <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"></path></svg>';
  const editIconHover = props.editIconHover;
  const verticalAlign = props.verticalAlign;

  const [rowClicked, setRowClicked] = useState();

  const [toggleShowStatus, setToggleShowStatus] = useState({});
  function toggleTableValues(display, index, columnCount, rowCount) {
    for (let iterator = 0; iterator <= columnCount; iterator++) {
      for (
        let innerIterator = toggleCap;
        innerIterator <= rowCount;
        innerIterator++
      ) {
        let targetId = `td_${index}_${iterator}_${innerIterator}`;
        if (document.getElementById(targetId))
          document.getElementById(targetId).style.display = display
            ? "block"
            : "none";
      }
    }
  }
  function toggleElement(id, index, object) {
    // Number of Columns and Rows
    let columnCount, rowCount;
    columnCount = object.length;
    rowCount = getToggleRowCount(object);

    //alter text
    const currentToggleButtonText = document
      .getElementById(id)
      .innerHTML.substr(0, 9);
    if (currentToggleButtonText == "Show more") {
      document.getElementById(id).innerHTML = showLessInnerHtml; //Alter Text / HTML
      //show specific values
      toggleTableValues(true, index, columnCount, rowCount);
    } else if (currentToggleButtonText == "Show less") {
      document.getElementById(id).innerHTML = showMoreInnerHtml; //Alter Text / HTML
      //hide specific values
      toggleTableValues(false, index, columnCount, rowCount);
    }
  }

  function getToggleRowCount(data) {
    let maxRowCount = 0;
    data.map((element, index) => {
      if (Array.isArray(element.text) && element.text.length >= maxRowCount) {
        maxRowCount = element.text.length;
        console.log("maxRowCount", maxRowCount);
      }
    });
    return maxRowCount; //don't display toggle
  }

  function dataToggleCheck(data) {
    let outcome = false;
    data.map((element, index) => {
      if (Array.isArray(element.text) && element.text.length >= toggleCap) {
        outcome = true; //display toggle
      }
    });
    return outcome; //don't display toggle
  }

  useEffect(() => {
    let array = {};
    arrayData[1].data.map((element, index) => {
      //create an array for toggleShowStatus that is the length of the arrayData being mapped.
      array[index] = false;
    });
    setToggleShowStatus(array);

    if (onClickReaction) setonClickAction(true);

    if (arrayData[1].hasOwnProperty("userDetails")) convertUserDetails();
    // console.log("ONLOAD USEEFFECT - typeof toggleShowStatus", typeof toggleShowStatus, toggleShowStatus)
  }, []);

  async function createClickAction(id = 0, bizObjectId = 0, title = "") {
    setModal(false);
    id = parseInt(id.replace("#", ""));
    // alert("Creating click action")
    console.log("clicked and calling ", id);
    if (onClickReaction.action == "openModal") {
      if (onClickReaction.api == "TestRecovery") {
        const data = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/CC_API/GetPageData?pageEnum=58&optionEnum1=${id}`,
          // `https://hg-dev-ui-ac-api.azurewebsites.net/CC_API/GetPageData?pageEnum=58&optionEnum1=${id}`,
          getRequestOptions(authTokens)
        ).then((response) => response.json());
        //set form data
        const formData = {
          titles: {
            mainTitle: "Record Test Recovery",
            subTitle: "Details",
            buttonTitle: "Record",
          },
          apiOptions: {
            method: "RecordTestRecovery",
            parameters: [
              "DateOfTestRecovery",
              "SelectedEngineer",
              "SelectedResult",
              "Note",
            ],
          },
          values: {
            0: {
              name: "DateOfTestRecovery",
              type: "date",
              label: "Date of Test Recovery:",
              value: data.DateOfTestRecovery,
            },
            1: {
              name: "SelectedEngineer",
              type: "text",
              label: "Engineer:",
              values: "",
              value: data.SelectedEngineer,
            },
            2: {
              name: "SelectedResult",
              type: "select",
              label: "Result:",
              values: [
                [{ text: "Futher Action Required", color: "orange" }],
                [{ text: "Complete", color: "green" }],
                [{ text: "Failed", color: "red" }],
              ],
              value: data.SelectedResult,
            },
            3: {
              name: "Note",
              type: "textarea",
              label: "Your note:",
              rows: 7,
              value: data.Note,
            },
          },
        };

        //open modal
        setModal(
          <ModalDynamic
            setShow={true}
            modalSize="md"
            modalHeader={"Record Test Recovery"}
            onClose={onClickCallback}
            modalData={
              <ReusableForm
                Titles={formData.titles}
                apiOptions={formData.apiOptions}
                formInputs={formData.values}
                populated={true}
              />
            }
          />
        );

        //add entity id to fields
      } else if (onClickReaction.api != undefined) {
        const data = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/CC_API/${
          // `https://hg-dev-ui-ac-api.azurewebsites.net/CC_API/${
            onClickReaction.api
          }?ticketId=${id}&bizObjectId=${bizObjectId}&accessKey=${JSON.parse(
            localStorage.getItem("user_MessageObj")
          )}`,
          getRequestOptions(authTokens)
        ).then((response) => response.json());
        console.log("SETTING THE MODAL");
        setModal(
          <ModalDynamic
            setShow={true}
            modalSize="md"
            modalHeader={"Incident Response Details"}
            modalData={
              <IRNotesChatroom
                currentId={id}
                bizObjectId={bizObjectId}
                title={title}
                allTicketIds={[10, 12, 13, 14, id]}
                notes={data.cwTicketNotes}
              />
            }
          />
        );
      }
    }
  }

  async function printRow(entityId) {
    setLoadingScreen(true);
    const data = await PrintDataByEntityId(entityId, authTokens);
    if (data) {
      var file = new Blob([data], { type: "application/pdf" });
      var fileURL = window.URL.createObjectURL(file);
      window.open(fileURL);
      setLoadingScreen(false);
    }
  }

  async function convertUserDetails() {
    //convert to an object {email: avatar} - prevents the issue of avatars being incorrectly displayed

    if (arrayData[1].userDetails) {
      let gravatarObj = {};
      for (let i = 0; i < arrayData[1].userDetails.length; i++) {
        const element = arrayData[1].userDetails[i];
        if (element && gravatarObj[element.UserName] === undefined) {
          let result = false;
          if (
            element.Avatar !=
            "https://www.gravatar.com/avatar/00000000000000000000000000000000?s=24&d=https://cybercareimages.blob.core.windows.net/icons/DefaultAvatar.png"
          )
            result = await generateGravatar(element.Avatar);
          if (result && element.FirstName !== "NA")
            gravatarObj[element.UserName] = element.Avatar;
          else gravatarObj[element.UserName] = "genericImage";
        }
      }
      console.log("gravatarArray");
      setGravatarObject(gravatarObj);
      return true;
    }
    return false;
  }

  const techtableComponent = () => (
    <div className="table-responsive tech-table" style={{ overflow: "auto" }}>
      <Table striped>
        <thead
          className="tech-header"
          style={{ backgroundColor: backgroundColor }}
        >
          <tr className="table-row">
            {arrayData[0]
              ? arrayData[0].header.map((item, index) => {
                  return (
                    <th
                      className="tech-header"
                      key={index}
                      style={{
                        color: headerTextColor,
                        width: item.width || "initial",
                      }}
                    >
                      {item.text}
                    </th>
                  );
                })
              : ""}
          </tr>
        </thead>

        {console.log(
          arrayData?.[1]?.["data"]?.[0]?.[0]?.["text"] === "No Savings Found",
          "arrayData"
        )}

        {arrayData?.[1]?.["data"]?.[0]?.[0]?.["text"] !==
          "No Savings Found" && (
          <tbody>
            {arrayData[1] && toggleShowStatus[0] != undefined
              ? arrayData[1].data.map((item, index) => {
                  if (item) {
                    return (
                      <React.Fragment>
                        <tr
                          className="colourCoordinated table-row"
                          id={arrayData[1].ids ? arrayData[1].ids[index] : ""}
                          onClick={
                            onClickAction
                              ? () =>
                                  createClickAction(
                                    onClickReaction.api == "TestRecovery"
                                      ? arrayData[1].ids[index]
                                      : item[0].text,
                                    arrayData[1].bizObjects != undefined
                                      ? arrayData[1].bizObjects[index]
                                      : undefined,
                                    item[1].text
                                  )
                              : editIconHover
                              ? () => {
                                  setRowClicked(index);
                                  onClickCallback(index);
                                }
                              : ""
                          }
                        >
                          {item.map((item2, index2) => {
                            if (item2.type == "button") {
                              if (
                                !arrayData[1].ids ||
                                arrayData[1].ids[0] != "-999"
                              ) {
                                return (
                                  <td
                                    className={
                                      item2.text == "N/A"
                                        ? item2.icon
                                          ? "na inline-icon"
                                          : "na"
                                        : item2.icon
                                        ? "inline-icon"
                                        : ""
                                    }
                                    style={{
                                      color: item2.color,
                                      width: item2.width,
                                      verticalAlign: verticalAlign
                                        ? "middle"
                                        : "top",
                                    }}
                                  >
                                    <a
                                      href={`${
                                        arrayData[1].link
                                          ? arrayData[1].link
                                          : arrayData[1].downloadLink
                                          ? arrayData[1].downloadLink[index]
                                          : ""
                                      }${
                                        arrayData[1].ids
                                          ? arrayData[1].ids[index]
                                          : ""
                                      }`}
                                      className={
                                        displayButtonType == "cyberCareButton"
                                          ? "cyberCareButton"
                                          : "inlineButton"
                                      }
                                      onClick={(event) =>
                                        !arrayData[1].link &&
                                        arrayData[1].downloadLink &&
                                        arrayData[1].downloadLink[index] ===
                                          "NA"
                                          ? event.preventDefault()
                                          : ""
                                      }
                                      style={
                                        !arrayData[1].link &&
                                        arrayData[1].downloadLink &&
                                        arrayData[1].downloadLink[index] ===
                                          "NA"
                                          ? {
                                              opacity: 0.3,
                                              cursor: "not-allowed",
                                            }
                                          : { opacity: 1 }
                                      }
                                      id={
                                        arrayData[1].ids
                                          ? arrayData[1].ids[index]
                                          : null
                                      }
                                    >
                                      {item2.text}
                                      {displayButtonType ==
                                      "cyberCareButton" ? (
                                        <img
                                          style={{ paddingLeft: ".4em" }}
                                          src={item2.icon}
                                        />
                                      ) : (
                                        <span style={{ paddingLeft: ".4em" }}>
                                          <BsEyeFill />
                                        </span>
                                      )}
                                    </a>
                                    {arrayData[1].ids ? (
                                      <span
                                        style={{ paddingLeft: "1.5em" }}
                                        onClick={() => {
                                          printRow(arrayData[1].ids[index]);
                                        }}
                                      >
                                        <img src="/images/icons/printer.svg" />
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                );
                              } else return null;
                            } else if (
                              item2.type === "printButton" &&
                              arrayData[1].ids
                            ) {
                              return (
                                <td>
                                  <span
                                    style={{ paddingLeft: "1.5em" }}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      printRow(arrayData[1].ids[index]);
                                    }}
                                  >
                                    <img src="/images/icons/printer.svg" />
                                  </span>
                                </td>
                              );
                            } else {
                              return (
                                <td
                                  className={
                                    item2.text == "N/A"
                                      ? item2.icon
                                        ? "na inline-icon"
                                        : "na"
                                      : item2.icon
                                      ? "inline-icon"
                                      : ""
                                  }
                                  style={{
                                    color: item2.color,
                                    width: item2.width,
                                    verticalAlign: verticalAlign
                                      ? "middle"
                                      : "top",
                                  }}
                                >
                                  {!item2.id ? (
                                    Array.isArray(item2.text) ? (
                                      item2.text.map((element, index3) => (
                                        <p
                                          style={
                                            element.color
                                              ? { color: element.color }
                                              : { color: "inherit" }
                                          }
                                          id={`td_${index}_${index2}_${index3}`}
                                        >
                                          {element.text}
                                        </p>
                                      ))
                                    ) : item2.icon ? (
                                      <span className="inline-icon">
                                        {" "}
                                        <p
                                          style={
                                            item2.color
                                              ? { color: item2.color }
                                              : { color: "inherit" }
                                          }
                                        >
                                          {item2.text}
                                        </p>
                                        <img src={item2.icon}></img>
                                      </span>
                                    ) : //check if initals holds a link or two characters
                                    item2.initials ||
                                      item2.text == "ShowUser" ? (
                                      (item2.initials == "ShowUser" ||
                                        item2.text == "ShowUser") &&
                                      gravatarObject &&
                                      arrayData[1].userDetails ? (
                                        <p className="displayChildrenInline">
                                          <span>
                                            <img
                                              className="techtableImageInline userAvatar"
                                              src={`${
                                                gravatarObject[
                                                  arrayData[1].userDetails[
                                                    index
                                                  ].UserName
                                                ] == "genericImage"
                                                  ? genericImage
                                                  : gravatarObject[
                                                      arrayData[1].userDetails[
                                                        index
                                                      ].UserName
                                                    ]
                                              }`}
                                            ></img>{" "}
                                          </span>{" "}
                                          {
                                            arrayData[1].userDetails[index]
                                              .FullName
                                          }
                                        </p>
                                      ) : (
                                        item2.text
                                      )
                                    ) : (
                                      <div style={{ display: "flex" }}>
                                        <div style={{ width: "83%" }}>
                                          {item2.text}
                                        </div>
                                        {index2 == item.length - 1 &&
                                        editIconHover &&
                                        rowClicked == index ? (
                                          <span style={{ width: "10%" }}>
                                            <HiPencil
                                              color={"black"}
                                              size={20}
                                            />
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </td>
                              );
                            }
                          })}
                        </tr>
                        {/* {arrayData == "" &&
                        (<ContentLoader 
                        speed={2}
                        width={900}
                        height={500}
                        viewBox="0 0 900 500"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                        {...props}
                      >
                        <rect x="48" y="26" rx="3" ry="3" width="52" height="6" /> 
                        <rect x="146" y="26" rx="3" ry="3" width="52" height="6" /> 
                        <rect x="231" y="26" rx="3" ry="3" width="52" height="6" /> 
                        <rect x="322" y="26" rx="3" ry="3" width="52" height="6" /> 
                        <rect x="406" y="26" rx="3" ry="3" width="52" height="6" /> 
                        <rect x="498" y="26" rx="3" ry="3" width="52" height="6" />
                      </ContentLoader>)
                        } */}
                        {toggle && dataToggleCheck(item) ? (
                          <tr>
                            <td
                              style={{
                                padding: "0.1em",
                                backgroundColor: "transparent !important",
                                verticalAlign: verticalAlign ? "middle" : "top",
                              }}
                            >
                              <p
                                id={`td_${index}`}
                                style={{
                                  position: "absolute",
                                  width: "97%",
                                  textAlign: "center",
                                  height: "30px",
                                  padding: "0",
                                  marginTop: "-30px",
                                  color: "#B6B6BF",
                                }}
                                onClick={() => {
                                  toggleElement(`td_${index}`, index, item);
                                }}
                              >
                                {toggleShowStatus[index] != false ? (
                                  <React.Fragment>
                                    Show more <FaChevronDown />
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    Show less <FaChevronUp />
                                  </React.Fragment>
                                )}
                              </p>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    );
                  }
                })
              : ""}
          </tbody>
        )}

        
      </Table>
      {arrayData?.[1]?.["data"]?.[0]?.[0]?.["text"] ===
          "No Savings Found" && (
          <div className="text-center p-3 radius-4 w-100 bg-white">
           
            No Savings Found

           
          </div>
        )}
    </div>
  );

  if (arrayData[1] && arrayData[1].userDetails && gravatarObject) {
    return (
      <React.Fragment>
        {modal}
        {techtableComponent()}
      </React.Fragment>
    );
  } else if (arrayData[1] && !arrayData[1].userDetails) {
    return (
      <React.Fragment>
        {modal}
        {techtableComponent()}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {modal}
        {techtableComponent()}
      </React.Fragment>
    );
  }
};

export default TechTable;
