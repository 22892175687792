import React from "react";
import "./mspAttackSurface.scss";
import { HGHighlightText } from "../../App/App";
import moment from "moment";
const TechSpendSubscriptionView = ({subscriptionViewData ,searchString,onOpenInvoice}) => {
  return (<> 
  {subscriptionViewData?.SubscriptionList?.length >0 &&  <div className="tech-spend-subscription-view ">
      {
      // [
      //   { name: "", status: "Active" },
      //   { name: "", status: "Suspended" },
      //   { name: "", status: "Completed" },
      //   { name: "", status: "Cancelled" },
      //   { name: "", status: "Active" },
      //   { name: "", status: "Suspended" },
      //   { name: "", status: "Suspended" },
      //   { name: "", status: "Active" },
      //   { name: "", status: "Completed" },
      //   { name: "", status: "Completed" },
      //   { name: "", status: "Active" },
      // ]
      
      subscriptionViewData?.SubscriptionList?.map((subscriptionItem, subscriptionIndex) => {
        return (
          <div className="sub-tile p-3 pointer "
          onClick={()=>{
            onOpenInvoice(subscriptionItem)
          }}
          
          >
            <div className="d-flex align-items-center justify-content-between w-100 mb-2">
              <div className="text-ellipsis f-500">
              <span
                              dangerouslySetInnerHTML={{
                                __html: HGHighlightText(
                                  subscriptionItem?.SubscriptionTitle,
                                  searchString
                                ),
                              }}
                            />
               
              </div>
              <div
              style={{
                background:subscriptionItem?.StatusColor
              }}
                className={`status 
                `}
                // ${subscriptionItem.Status.toLowerCase()}
              >
                {subscriptionItem.Status}{" "}
              </div>
            </div>
            {/* {[
              { key: "Amount", value: "$132/month" },
              { key: "Period", value: "9 months" },
              { key: "Invoices", value: "3 of 9" },
              { key: "Supplier", value: "Figma" },
            ].map((item) => {
              return ( */}
                <div className="d-flex align-items-center justify-content-between w-100 mb-1">
                  <div className="text-ellipsis f-grey f-12 f-500">
                  Amount
                  </div>
                  <div className={` f-darkgrey f-12`}>{subscriptionItem?.Amount}  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between w-100 mb-1">
                  <div className="text-ellipsis f-grey f-12 f-500">
                  Expires
                  </div>
                  <div className={` f-darkgrey f-12`}>
                    
                    {moment(`${subscriptionItem?.EndDate?.Month}/${subscriptionItem?.EndDate?.Day}/${subscriptionItem?.EndDate?.Year}`).format(
                      "ll"
                    )}
                     
                     
                     
                     </div>
                </div>
                <div className="d-flex align-items-center justify-content-between w-100 mb-1">
                  <div className="text-ellipsis f-grey f-12 f-500">
                  Invoices
                  </div>
                  <div className={` f-darkgrey f-12`}> {subscriptionItem?.Invoices}</div>
                </div>
                <div className="d-flex align-items-center justify-content-between w-100 mb-1">
                  <div className="text-ellipsis f-grey f-12 f-500">
                  Supplier
                  </div>
                  <div className={` f-darkgrey f-12`}> {subscriptionItem?.Supplier} </div>
                </div>
              {/* );
            })} */}
          </div>
        );
      })}
    </div>}


{subscriptionViewData?.SubscriptionList?.length === 0 && searchString && (
        <div
          className="mx-auto w-50 d-flex align-items-center justify-content-center flex-column"
          style={{
            marginTop: "4rem",
          }}
        >
          <img
            src="/images/attack-surface/search-icon.svg"
            alt=""
            className=""
          />
          <div className="f-500 f-grey mt-3">
            No results for “{searchString}”{" "}
          </div>
          <div className="f-500 f-darkgrey mt-2">
            Try to search another request or rest filters{" "}
          </div>
        </div>
      )}
      {subscriptionViewData?.SubscriptionList?.length === 0 && !searchString && (
        <div
          className="mx-auto w-50 d-flex align-items-center justify-content-center flex-column"
          style={{
            marginTop: "4rem",
          }}
        >
          <div className="f-600 f-18 f-grey mt-3">No Data found !!</div>
          {/* <div className="f-500 f-darkgrey mt-2"> </div> */}
        </div>
      )}
    </>
  );
};

export default TechSpendSubscriptionView;
