import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import "./mentiontest.scss";

const TrendGraph = ({ color, graphData, getData, pageEnum }) => {
  const _data = {
    labels: ["23/11", "12/05", "11/06", "18/05", "80/07", "90/18"],
    datasets: [
      {
        data: [86, 114, 306, 80, 107, 111],
        label: "Africa",
        borderColor: color,
        backgroundColor: ({ chart: { ctx } }) => {
          var gradientFill = ctx.createLinearGradient(0, 0, 0, 200);
          gradientFill.addColorStop(0, "#ffffff00");
          gradientFill.addColorStop(1, `${color}45`);
          return gradientFill;
        },

        fill: "end",

        pointRadius: 0,
        borderWidth: 2,
        // lineWidth: 1,
      },
    ],
  };

  const [data, setData] = useState(_data);

  useEffect(() => {
    if (graphData?.length > 0) {
      let values = [...graphData].map((item) => item?.Value);
      let labels = [...graphData].map((item) => item?.Label);

      let updated_data = { ...data };
      updated_data.labels = labels;
      updated_data.datasets[0].data = values;
      setData(updated_data);
    }
  }, [graphData]);

  // Plugins for Graph
  // yearly monthly weekly
  const [selectedPeriod, setSelectedPeriod] = useState("yearly");
  const bgColorPlugin = {
    id: "custom_canvas_background_color",
    beforeDraw: (chart) => {
      const { ctx } = chart;
      ctx.save();
      ctx.globalCompositeOperation = "destination-over";
      ctx.fillStyle = "#F6F8FB";
      ctx.fillRect(0, 0, chart.width, chart.height);

      ctx.restore();
    },
  };

  const options = {
    bezierCurve: false,
    datalabels: {
      display: false,
    },
    style: {
      strokewidth: 1,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          position: "right",
          gridLines: {
            display: false,
          },
          ticks: {},
        },
      ],
    },
    title: {
      display: false,
    },
    legend: {
      display: false,
    },

    plugins: {
      autocolors: true,

      datalabels: {
        backgroundColor: "transparent",

        color: "transparent",
      },
    },
  };
  return (
    <div
      style={{
        width: "100%",
        height: "250px",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        padding: "20px",
        paddingTop: "30px",
        background: "#F6F8FB",
        borderRadius: "8px",
        // marginTop: "100px",
        // marginLeft: "100px",
        flexDirection: "column",
      }}
    >
      <div className="d-flex align-items-center justify-content-end w-100">
        <Button
          onClick={() => {
            setSelectedPeriod("yearly");

            getData(2, pageEnum);
          }}
          className={`f-12 f-500  ${
            selectedPeriod === "yearly" ? "active-period" : "inactive-period"
          }  `}
        >
          Yearly
        </Button>
        <Button
          onClick={() => {
            setSelectedPeriod("monthly");
            getData(1, pageEnum);
          }}
          className={`f-12 f-500  ${
            selectedPeriod === "monthly" ? "active-period" : "inactive-period"
          }  `}
        >
          Monthly
        </Button>
        <Button
          onClick={() => {
            setSelectedPeriod("weekly");
            getData(0, pageEnum);
          }}
          className={`f-12 f-500  ${
            selectedPeriod === "weekly" ? "active-period" : "inactive-period"
          }  `}
        >
          Weekly
        </Button>
      </div>
      <Line
        redraw={true}
        width={700}
        height={200}
        data={data}
        options={options}
        plugins={bgColorPlugin}
      />
    </div>
  );
};

export default TrendGraph;
