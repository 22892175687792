import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { getAPIData, getRequestOptions } from "../../../Utils/commonApi";
import ImageWithInitials from "../../../Utils/ImageWithInitials";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";

import BigModal from "./BigModal";
import "./rewards.scss";
import RewardsInvitationModal from "./RewardsInvitationModal";
import RewardsPageLoader from "./RewardsPageLoader";
import SendReportModal from "./SendReportModal";

import SharePostModal from "./SharePostModal";
import { viewOnlyAccess } from "../../App/App";
const RewardsV2 = () => {
  const { authTokens } = useAuth();
  const history = useHistory();
  const [bigModal, setBigModal] = useState(false);
  const [sharePost, setSharePost] = useState(false);
  const [rewardsInvitation, setRewardsInvitation] = useState(false);
  const [sendReport, setSendReport] = useState(false);
  const [pageData, setPageData] = useState({});
  const [refferalList, setRefferalList] = useState([]);
  const [refferalShowMore, setRefferalShowMore] = useState(5);
  const [proServicesList, setProServicesList] = useState([]);
  const [proServiceShowMore, setProServiceShowMore] = useState(5);
  const [mainPageLoader, setMainPageLoader] = useState(false);
  const [rewardId, setRewardId] = useState("");
  const [shareModalTitle, setShareModalTitle] = useState("");
  const [postData, setPostData] = useState({});
  const [rewardData, setRewardData] = useState({});

  useEffect(() => {
    setMainPageLoader(true);
    getReferralData();
  }, []);

  const hideRewardsInvitationModal = () => {
    setRewardsInvitation(false);
  };

  const hideSendReportModal = () => {
    setSendReport(false);
  };

  const hideBigModal = () => {
    setBigModal(false);
  };

  const hideSharePostModal = () => {
    setSharePost(false);
  };

  async function getReferralData() {
     await getAPIData("130", authTokens,{optionEnum1:1}).then((response) => {
      setMainPageLoader(false);
      if (response.mr.Success) {
        setPageData(response);
        setRefferalList(response?.Referral_List);
        setProServicesList(response?.ProServices_List);
      }
    });
  }

  async function handeleResendReferral(email, referralId) {
    let updated_refferalList = [...refferalList];
    let Index = updated_refferalList.findIndex(
      (item) => item.Referral_Id == referralId
    );
    updated_refferalList[Index].sending = true;
    setRefferalList(updated_refferalList);

    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/ResendReferral`,
      // `https://hg-dev-ui-ac-api.azurewebsites.net/CC_API/ResendReferral`,
      getRequestOptions(authTokens, "POST", {
        EmailAddress: email,
        AccessKey: localStorage.getItem("user_MessageObj")
          ? JSON.parse(localStorage.getItem("user_MessageObj"))
          : "null",
      })
    )
      .then((response) => response.json())
      .then((response) => {
        let updated_refferalList = [...refferalList];
        let Index = updated_refferalList.findIndex(
          (item) => item.Referral_Id == referralId
        );
        updated_refferalList[Index].sending = false;
        setRefferalList(updated_refferalList);
        if (response.Success) {
          CallToast("Invitation sent successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      });
  }

  async function handeleCancelReferral(referralId) {
    let updated_refferalList = [...refferalList];
    let Index = updated_refferalList.findIndex(
      (item) => item.Referral_Id == referralId
    );
    updated_refferalList[Index].cancelling = true;
    setRefferalList(updated_refferalList);

    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/CancelReferral`,
      // `https://hg-dev-ui-ac-api.azurewebsites.net/CC_API/CancelReferral`,
      getRequestOptions(authTokens, "POST", {
        ReferralId: referralId,
        AccessKey: localStorage.getItem("user_MessageObj")
          ? JSON.parse(localStorage.getItem("user_MessageObj"))
          : "null",
      })
    )
      .then((response) => response.json())
      .then((response) => {
        let updated_refferalList = [...refferalList];
        let Index = updated_refferalList.findIndex(
          (item) => item.Referral_Id == referralId
        );
        updated_refferalList[Index].cancelling = false;
        setRefferalList(updated_refferalList);
        if (response.Success) {
          getReferralData();
          CallToast(
            "Invitation cancelled successfully",
            false,
            false,
            "v2style"
          );
        }
      });
  }
  return (
    <>
      {mainPageLoader && <RewardsPageLoader />}
      <div className={`rewards-wrapper ${mainPageLoader ? "d-none" : ""}  `}
      
      
      style={{
        pointerEvents:viewOnlyAccess("AccessState_Reward_Settings") ? "none"  :"all"
      }}
      
      >
        <div className="header">Rewards</div>

        <Row
          className="m-0 option-wrapper mt-4 mb-2"
          style={{ minHeight: "140px" }}
        >
          {/* <Col
            xs={6}
            className="d-flex flex-column justify-content-between p-3 right-border pointer"
            onClick={() => {
              history.push("/integrations");
              // setSharePost(true)
            }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div className="title">Free Integrations</div>
              <img alt="" src="/images/settings-v2/sideArrow.svg" />
            </div>
            <div className="brownText">
              <span className="bigText">
                {pageData?.Referral_TotalIntegrations}
              </span>
              &nbsp; of {pageData?.Referral_IntegrationMaxAmount}
            </div>
            <div>
              <img alt="" src="/images/settings-v2/connected.svg" />
            </div>
          </Col> */}
          <Col
            xs={12}
            className="d-flex flex-column justify-content-between p-3 pointer "
            onClick={() => {
              history.push("/pro_services");
              // setSendReport(true)
            }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div className="title">Pro Services Credit</div>
              <img alt="" src="/images/settings-v2/sideArrow.svg" />
            </div>
            <div className="blueText">£{pageData?.ProServices_TotalCredit}</div>
            <div></div>
          </Col>
        </Row>
        <div className="d-flex justify-content-between align-items-start mb-3 mt-2">
          <div className="w-75">
            <p className="title m-0">
            Earn Pro Services Credit
            </p>
            <p className="subtitle">
            Invite your peers to HighGround to earn credits that can be used towards the purchase of Pro Services up to £500.
            </p>
          </div>
          <div className="graytext pointer" onClick={() => setBigModal(true)}>
            Referral rules <img alt="" src="/images/info.svg" />
          </div>
        </div>

        <Row className="m-0 option-wrapper">
          <Col
            xs={12}
            className="d-flex justify-content-between align-items-center p-3 pointer"
            onClick={() => setRewardsInvitation(true)}
          >
            <div className="d-flex align-items-center">
              <img
                alt=""
                src="/images/settings-v2/invite-user.svg"
                className="mr-2"
              />
              <div className="title">Invite Members</div>
            </div>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center mr-2 graytext">
                + £5
              </div>
              <img src="/images/chevron-right.svg" alt="" className="mb-1" />
            </div>
          </Col>
          {refferalList.length > 0 && <hr className="m-0" />}

          {refferalList
            .slice(0, refferalShowMore)
            .map((refferal, referIndex) => {
              return (
                <>
                  <Col
                    xs={12}
                    className="d-flex justify-content-between align-items-center p-3 pointer"
                  >
                    <div className="d-flex align-items-center">
                      {refferal?.Referrer_Initials ? (
                        <ImageWithInitials
                          width="30"
                          initials={refferal?.Referrer_Initials}
                          background="purple"
                        />
                      ) : (
                        <img
                          src={refferal?.Referrer_Avatar}
                          alt=""
                          className="rounded-full"
                          width={30}
                          height={30}
                        />
                      )}
                      <div className="ml-2">
                        <p className="title m-0">
                          {refferal?.Referral_Line1_Black}
                        </p>
                        <p className="subtitle">
                          {refferal?.Referral_Line2_Grey}&nbsp;
                          <span
                            style={{
                              color: "#40d88e",
                            }}
                          >
                            {refferal?.Referral_Status}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="d-flex align-items-center">
                      {refferal?.Referral_RewardType.toLowerCase() ==
                      "integration" ? (
                        <div
                          className="d-flex align-items-center mr-2 f-black pointer"
                          onClick={() => {}}
                        >
                          {refferal?.Referral_IntegrationNumber}/3 Integration
                        </div>
                      ) : (
                        <>
                          <div className="mr-4">
                            {/* resend option wiht cancel option for invitation */}
                            {refferal?.Referral_RewardType.toLowerCase() ==
                              "tbc" &&
                              refferal?.Referral_Complete == false && (
                                <div className="d-flex align-items-center">
                                  {refferal.sending ? (
                                    <div className="f-blue">
                                      <Spinner
                                        size="sm"
                                        className="mr-2"
                                        animation="border"
                                        variant="secondary"
                                      />
                                      Resending..
                                    </div>
                                  ) : (
                                    <div
                                      className="f-blue"
                                      onClick={() => {
                                        handeleResendReferral(
                                          refferal?.Referrer_Email,
                                          refferal?.Referral_Id
                                        );
                                      }}
                                    >
                                      Resend
                                    </div>
                                  )}

                                  {refferal.cancelling ? (
                                    <div className="ml-2">Cancelling...</div>
                                  ) : (
                                    <div
                                      className="ml-2"
                                      onClick={() => {
                                        handeleCancelReferral(
                                          refferal?.Referral_Id
                                        );
                                      }}
                                    >
                                      Cancel
                                    </div>
                                  )}
                                </div>
                              )}
                          </div>

                          <div
                            className="d-flex align-items-center mr-2 graytext pointer"
                            onClick={() => {
                              // RightSideToast(
                              //   5,
                              //   "Thanks, You have got reward of £5"
                              // );
                            }}
                          >
                            + £5
                          </div>
                        </>
                      )}
                      <Form.Group className="round-checkbox titleCheckbox mb-2">
                        <Form.Check
                          type="checkbox"
                          label=""
                          name={`checkbox`}
                          checked={Number(refferal?.Referral_Complete)}
                          onChange={(e) => e.preventDefault()}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                  {referIndex !==
                    refferalList.slice(0, refferalShowMore).length - 1 && (
                    <hr className="m-0" />
                  )}
                </>
              );
            })}
        </Row>
        <div className="d-flex align-items-center justify-content-between my-2 mx-2">
          <div>
            {refferalList.length > 0 && (
              <div
                className={`show-more-btn   ${
                  refferalShowMore == 5 ? "arrow-down" : "arrow-up"
                } `}
                onClick={() => {
                  if (refferalShowMore == 5) {
                    setRefferalShowMore(10000);
                  } else {
                    setRefferalShowMore(5);
                  }
                }}
              >
                {refferalShowMore == 5 ? "Show More" : "Show Less"}
              </div>
            )}
          </div>
          <div className="f-12 f-500 f-darkgrey">
            Total earned £{pageData?.Referral_TotalEarnedAmount}
          </div>
        </div>

        <div className="title my-3">Other Rewards</div>

        <Row className="m-0 option-wrapper">
          {proServicesList
            .slice(0, proServiceShowMore)
            .map((proservice, Index) => {
              return (
                <>
                  <Col
                    xs={12}
                    className="d-flex justify-content-between align-items-center p-3 py-4 pointer"
                    onClick={() => {
                      if (
                        proservice?.Status == "Shared" &&
                        proservice?.Complete == false
                      ) {
                        setPostData(proservice);
                        setRewardId(proservice?.RewardId);
                        setSharePost(true);
                      }
                      if (
                        proservice?.Status == "Sent" &&
                        proservice?.Complete == false
                      ) {
                        setSendReport(true);
                        setRewardId(proservice?.RewardId);
                        setShareModalTitle(proservice?.Name);
                        setRewardData(proservice);
                      }
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        alt=""
                        src={`/images/settings-v2/${proservice?.Status?.toLowerCase()}.svg`}
                        className="mr-2"
                      />
                      <div className="title">
                        {proservice?.Status == "Shared"
                          ? `Shared post "${proservice?.Name}"`
                          : proservice?.Name}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center mr-2 graytext">
                        + £{proservice?.AwardAmount}
                      </div>
                      <Form.Group className="round-checkbox titleCheckbox mb-2">
                        <Form.Check
                          type="checkbox"
                          label=""
                          name={`checkbox`}
                          checked={proservice.Complete}
                          onChange={(e) => e.preventDefault()}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                  {Index !==
                    proServicesList.slice(0, proServiceShowMore).length - 1 && (
                    <hr className="m-0" />
                  )}
                </>
              );
            })}
        </Row>
        <div className="d-flex align-items-center justify-content-between my-2 mx-2">
          <div>
            {proServicesList.length > 0 && (
              <div
                className={`show-more-btn   ${
                  proServiceShowMore == 5 ? "arrow-down" : "arrow-up"
                } `}
                onClick={() => {
                  if (proServiceShowMore == 5) {
                    setProServiceShowMore(10000);
                  } else {
                    setProServiceShowMore(5);
                  }
                }}
              >
                {proServiceShowMore == 5 ? "Show More" : "Show Less"}
              </div>
            )}
          </div>
          <div className="f-12 f-500 f-darkgrey">
            Total earned £{pageData?.ProServices_TotalEarnedAmount}
          </div>
        </div>
        <BigModal show={bigModal} hideModal={hideBigModal} />
        <SharePostModal
          show={sharePost}
          hideModal={hideSharePostModal}
          postData={postData}
          rewardId={rewardId}
          refreshData={getReferralData}
        />
        <RewardsInvitationModal
          show={rewardsInvitation}
          hideModal={hideRewardsInvitationModal}
          referralLink={pageData?.Referral_Link}
        />
        <SendReportModal
          show={sendReport}
          hideModal={hideSendReportModal}
          title={shareModalTitle}
          rewardId={rewardId}
          refreshData={getReferralData}
          rewardData={rewardData}
        />
      </div>
    </>
  );
};

export default RewardsV2;
