import React, { useState, useEffect } from "react";
import "./attackSurface.scss";
import { Row, Col, Button } from "react-bootstrap";
import Header from "../Header";
import CDSA from "./CDSA";
import VulnerbilityManagement from "./CDSA/VulnerbilityManagement";
import IncidentResponse from "./CDSA/IncidentResponse";
import AttackSimulation from "./CDSA/AttackSimulation";
import SecurityOperations from "./CDSA/SecurityOperations";

import AssetsList from "./AssetsList/Index";
import Email from "./Email/Index";

import Overview from "./Overview/Index";
import { useAuth } from "../context/Auth";
import { getAPIData } from "../../Utils/commonApi";
import AttackSurfaceMainPageLoader from "./AttackSurfaceLoaders/AttackSurfaceMainPageLoader";
import AssetsData from "./AssetsList/WorkStations/AssetsData";
import { useSelector } from "react-redux";
import { NoAccessContainer } from "../supplyChainMonitoring/SupplyCompany/SupplyCompany";

const AttackSurface = ({
  supplierId,
  accessLevel,
  attackSurfaceAccessLevels,
}) => {
  const { authTokens } = useAuth();
  const [menuItems, setMenuItems] = useState([]);
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const isMenuCollapse = useSelector((state) => state?.global?.menuCollapse);
  // Assets list
  const [assetModifierId, setAssetModifierId] = useState(2139);
  useEffect(() => {
    setMainPageLoading(true);
    refreshMenuItems();
  }, []);

  function refreshMenuItems() {
    getAPIData(441, authTokens, {
      optionEnum2: 2, //accessLevel,
      optionEnum3: supplierId,
    }).then((response) => {
      setMainPageLoading(false);
      if (response?.mr?.Success) {
        setMenuItems(response?.AttackSurfaceComponent_List);
      }
    });
  }

  const [breadCrumb, setBreadCrumb] = useState(["CDSA"]);
  const activePage = breadCrumb[breadCrumb.length - 1];
  function goTo(pageName) {
    if (pageName == null || pageName === "") {
      console.log("go to page is Null");
      return;
    }

    let updated_data = [...breadCrumb];
    updated_data.push(pageName);
    setBreadCrumb(updated_data);
  }

  const Status = ({ value }) => {
    return (
      <svg
        height="21"
        width="21"
        viewBox="0 0 18 18"
        className="circle-indicator"
      >
        <circle
          cx="9"
          cy="9"
          r="6"
          stroke="#d4d6dd"
          stroke-width="1.5"
          fill="transparent"
        />
        <circle
          cx="9"
          cy="9"
          r="6"
          stroke="#008FD4"
          stroke-width="1.5"
          fill="transparent"
          pathLength={100}
          strokeDasharray={100}
          strokeDashoffset={value}
        />
      </svg>
    );
  };
  return (
    <>
      {mainPageLoading ? (
        <AttackSurfaceMainPageLoader />
      ) : (
        <Row className="attack_surface p-0 m-0" style={{ height: "100%" }}>
          <Col xs={2} className="left-sidebar px-2 pt-3 bg-white">
            <div
              style={{
                position: "relative",
                width: `${isMenuCollapse ? "100%" : "100%"}`,
              }}
            >
              <p className="f-500 f-darkgrey pl-2 mt-3 mb-4"> Components</p>
              <div className="d-flex flex-column">
                {menuItems.map((item, menuIndex) => {
                  return (
                    <div
                      className={` align-items-center justify-content-between px-2 component py-2 mt-1
                      ${
                        item?.DevelpomentStage?.toLowerCase() === "hide"
                          ? "d-none"
                          : "d-flex"
                      }
                      
                      ${
                        breadCrumb[0] == item?.Title ? "selected-component" : ""
                      }   `}
                      onClick={() => {
                        setBreadCrumb([item?.Title]);
                      }}
                      style={{
                        pointerEvents: `${
                          attackSurfaceAccessLevels[item?.Title] === 0
                            ? "none"
                            : "all"
                        }`,
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <div>
                          {item?.Title?.toLowerCase() === "overview" && (
                            <img
                              alt=""
                              src="/images/attack-surface/overview-icon.svg"
                              className="ml-1"
                            />
                          )}

                          {/* {item?.Title?.toLowerCase() !== "overview" &&
                            item?.DevelpomentStage?.toLowerCase() ===
                              "coming soon" && (
                              <img
                                alt=""
                                src="/images/attack-surface/grey-thunder-icon.svg"
                                className="ml-1"
                              />
                            )} */}

                          {item?.Title?.toLowerCase() !== "overview" &&
                            attackSurfaceAccessLevels[item?.Title] > 0 &&
                            item?.DevelpomentStage?.toLowerCase() === "live" &&
                            item?.CompletionScore === 0 && (
                              <img
                                alt=""
                                src="/images/attack-surface/thunder-icon.svg"
                                className="ml-1"
                              />
                            )}

                          {item?.Title?.toLowerCase() !== "overview" &&
                            attackSurfaceAccessLevels[item?.Title] > 0 &&
                            item?.DevelpomentStage?.toLowerCase() === "live" &&
                            item?.CompletionScore > 0 &&
                            item?.CompletionScore < 100 && (
                              <Status value={100 - item?.CompletionScore} />
                            )}

                          {item?.Title?.toLowerCase() !== "overview" &&
                            attackSurfaceAccessLevels[item?.Title] > 0 &&
                            item?.DevelpomentStage?.toLowerCase() === "live" &&
                            item?.CompletionScore === 100 && (
                              <img
                                alt=""
                                src="/images/attack-surface/menu-completed.svg"
                                className="ml-1"
                              />
                            )}

                          {attackSurfaceAccessLevels[item?.Title] === 0 && (
                            <img
                              alt=""
                              src="/images/attack-surface/small-lock.svg"
                              className="ml-1"
                            />
                          )}
                        </div>

                        <div
                          className={`ml-2 d-flex align-items-center justify-content-center   ${
                            attackSurfaceAccessLevels[item?.Title] === 0
                              ? "f-grey"
                              : ""
                          } `}
                        >
                          {item?.Title}
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        {item?.DevelpomentStage?.toLowerCase() === "live" &&
                          attackSurfaceAccessLevels[item?.Title] > 0 && (
                            <>{item?.CompletionScore}%</>
                          )}
                        {/* {item?.DevelpomentStage?.toLowerCase() ===
                          "coming soon" && (
                          <span
                            className="attack-menu-tag"
                            style={{
                              width: `${
                                item?.Title.toLowerCase() ==
                                "directory services"
                                  ? "51px"
                                  : "51px"
                              }`,
                            }}
                          >
                            {item?.Title.toLowerCase() == "directory services"
                              ? "COMING"
                              : "COMING"}
                          </span>
                        )} */}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>
          <Col
            xs={10}
            className="p-0 position-relative flex-changes-attack-surface"
            id="assets-list-full-screen-modal-parent"
          >
            <div
              className="dashboard fullHeight action_center px-0 hide-scrollbar"
              id="scrollable"
            >
              <div
                style={{
                  position: "absolute",
                  top: "0px",
                  width: `${isMenuCollapse ? "100%" : "100%"}`,
                  background: "#fff",
                  // zIndex: 6,
                }}
              >
                <header
                  className="d-flex- d-none justify-content-between navbar sticky-top pb-0"
                  style={{ paddingTop: "1em", background: "#FFFFFF" }}
                >
                  <h1>Attack Surface</h1>
                  <Header />
                </header>

                <div className="mini-header d-flex align-items-center justify-content-between">
                  <div>
                    {breadCrumb.length == 1 && (
                      <div
                        className="ml-5"
                        style={{ fontSize: "16px", fontWeight: 600 }}
                      >
                        {/* {breadCrumb[0]} */}
                      </div>
                    )}
                    {breadCrumb.length > 1 && (
                      <div className="d-flex align-items-center">
                        <div
                          className="f-grey d-flex align-items-center pointer ml-3"
                          onClick={() => {
                            let updated_data = [...breadCrumb];
                            updated_data.pop();
                            setBreadCrumb(updated_data);
                          }}
                        >
                          <img
                            alt=""
                            src="/images/attack-surface/back-icon.svg"
                            className="mr-2"
                          />
                          Back
                        </div>
                        <div className="d-flex align-items-center ml-4">
                          {breadCrumb.map((item, itemIndex) => {
                            return (
                              <div className="bread-parent d-flex align-items-center">
                                <div className="bread-child">{item} </div>
                                {breadCrumb.length - 1 !== itemIndex && (
                                  <div className="f-grey mx-3">/</div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      marginRight: `${
                        breadCrumb[0] === commonPage(breadCrumb[0]) &&
                        breadCrumb.length > 1
                          ? "2rem"
                          : ""
                      }`,
                    }}
                  >
                    <Button
                      className="insights-btn py-1 px-2 mr-3 my-2"
                      style={{
                        visibility: `${
                          breadCrumb[0] == "Overview" ? "hidden" : "visible"
                        }`,

                        cursor: "not-allowed",
                      }}
                    >
                      <img
                        alt=""
                        src="/images/attack-surface/thunder-icon.svg"
                        className="mr-2"
                      />
                      Insights
                    </Button>
                  </div>
                </div>
              </div>
              <div
                className="attack_surface_body h-100--"
                style={{ paddingTop: "4.5rem" }}
              >
                {/* Overview */}
                {activePage == "Overview" && (
                  <Overview progressData={menuItems} />
                )}
                {/* CDSA CDSA CDSA CDSA */}
                {activePage == "CDSA" && (
                  <>
                    {attackSurfaceAccessLevels[activePage] === 0 ? (
                      <NoAccessContainer type={"CDSA"} />
                    ) : (
                      <CDSA
                        goTo={goTo}
                        refreshMenuItems={() => {}}
                        supplierId={supplierId}
                        accessLevel={attackSurfaceAccessLevels[activePage]}
                      />
                    )}
                  </>
                )}
                {activePage == "Vulnerability Management" && (
                  <VulnerbilityManagement
                    supplierId={supplierId}
                    accessLevel={attackSurfaceAccessLevels["CDSA"]}
                    refreshMenuItems={() => {}}
                  />
                )}
                {activePage == "Incident Response" && (
                  <IncidentResponse
                    supplierId={supplierId}
                    accessLevel={attackSurfaceAccessLevels["CDSA"]}
                    refreshMenuItems={() => {}}
                  />
                )}
                {activePage == "Attack Simulation" && (
                  <AttackSimulation
                    supplierId={supplierId}
                    accessLevel={attackSurfaceAccessLevels["CDSA"]}
                    refreshMenuItems={() => {}}
                  />
                )}
                {activePage == "Security Operations" && (
                  <SecurityOperations
                    supplierId={supplierId}
                    accessLevel={attackSurfaceAccessLevels["CDSA"]}
                    refreshMenuItems={() => {}}
                  />
                )}

                {/* Assets List--- Assets List---Assets List-----Assets List */}

                {breadCrumb[0] == commonPage(breadCrumb[0]) &&
                  breadCrumb.length > 1 && (
                    <AssetsData
                      typeName={breadCrumb[1]}
                      assetModifierId={assetModifierId}
                      refreshMenuItems={refreshMenuItems}
                      supplierId={supplierId}
                      accessLevel={attackSurfaceAccessLevels[activePage]}
                    />
                  )}

                {/* <DirectoryServices /> */}
                {/* Email */}
                {activePage === commonPage(activePage) && (
                  <>
                    <Email
                      goTo={goTo}
                      setAssetId={setAssetModifierId}
                      activePage={activePage}
                      refreshMenuItems={() => {}}
                      pageParams={pageParams[activePage]}
                      supplierId={supplierId}
                      accessLevel={attackSurfaceAccessLevels[activePage]}
                    />
                  </>
                )}

                {/*Suppliers */}

                {breadCrumb[0] === "Suppliers" && breadCrumb.length > 1 && (
                  <AssetsData
                    typeName={breadCrumb[1]}
                    assetModifierId={assetModifierId}
                    refreshMenuItems={refreshMenuItems}
                  />
                )}

                <div
                  style={{
                    margin: "20px",
                  }}
                ></div>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default AttackSurface;

export function cleanText(text) {
  if (text === null || text === undefined) return "";

  let clean_text = text
    ?.trim()

    ?.replaceAll("_", " ")
    ?.replaceAll(",", "")
    ?.replaceAll("plus", "+")
    ?.replaceAll("fwdslash", "/")
    ?.replaceAll("ampersand", "&")
    ?.replaceAll("openbracet", "(")
    ?.replaceAll("closebracet", ")");

  return clean_text;
}

const pageParams = {
  "Assets List": {
    startRange: 2271,
    endRange: 2282,
    ASEComponentSecEnum:2
  },
  Networks: {
    questionEnum: 442,
    startRange: 2224,
    endRange: 2228,
    ASEComponentSecEnum:3
  },
  "Directory Services": {
    // questionEnum: "",
    startRange: 2218,
    endRange: 2222,
    ASEComponentSecEnum:4
  },
  Email: {
    questionEnum: 443,
    startRange: 2229,
    endRange: 2232,
    ASEComponentSecEnum:5
  },
  "File Sharing": {
    questionEnum: 444,
    startRange: 2233,
    endRange: 2238,
    ASEComponentSecEnum:6
  },
  "Cloud Services": {
    questionEnum: 445,
    startRange: 2240,
    endRange: 2241,
    ASEComponentSecEnum:7
  },
  Communications: {
    questionEnum: 449,
    startRange: 2242,
    endRange: 2245,
    ASEComponentSecEnum:8
  },
  "Data Protection": {
    questionEnum: 446,
    startRange: 2246,
    endRange: 2249,
    ASEComponentSecEnum:9
  },
  Organisation: {
    questionEnum: 447,
    startRange: 2250,
    endRange: 2254,
    ASEComponentSecEnum:10
  },
  "Corporate Social": {
    questionEnum: 448,
    startRange: 2261,
    endRange: 2261,
    ASEComponentSecEnum:11
  },
  Authentication: {
    questionEnum: 450,
    startRange: 2255,
    endRange: 2258,
    ASEComponentSecEnum:12
  },
  "Web Services": {
    questionEnum: 451,
    startRange: 2259,
    endRange: 2260,
    ASEComponentSecEnum:13
  },
  Suppliers: {
    // questionEnum: "",
    startRange: 2295,
    endRange: 2296,
    ASEComponentSecEnum:15
  },
};

function commonPage(activePage) {
  if (
    activePage === "Assets List" ||
    activePage === "Networks" ||
    activePage === "Directory Services" ||
    activePage === "Email" ||
    activePage === "File Sharing" ||
    activePage === "Cloud Services" ||
    activePage === "Communications" ||
    activePage === "Data Protection" ||
    activePage === "Organisation" ||
    activePage === "Corporate Social" ||
    activePage === "Authentication" ||
    activePage === "Web Services"
  ) {
    return activePage;
  } else {
    return "XXXX";
  }
}
