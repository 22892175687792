import React, { memo, useMemo,  } from "react";
import { Radar } from "react-chartjs-2";
const MspClientPriceCalculatorRadarChart = ({
  activeChart = 1,
  _datasets = staticDatasets,
}) => {
  const updatedData = useMemo(() => {
    let updatedDatasets = [..._datasets]?.map((data, dataIndex) => ({
      ...data,
      backgroundColor:
        dataIndex === activeChart
          ? `${data.backgroundColor}30`
          : `${data.backgroundColor}00`,
      pointRadius:
        dataIndex === activeChart ? [2, 2, 2, 2, 2] : [0, 0, 0, 0, 0],

      borderDash: dataIndex === 0 ? [5, 5] : [0, 0],
    }));

    return {
      labels: ["Identity", "Protect", "Detect", "Respond", "Recover"],

      datasets: updatedDatasets,
    };
  }, [_datasets, activeChart]);



  const updatedOptions = useMemo(() => {
    return {
      animation: {
        duration:1,// 4000, // Set the duration of the transition in milliseconds
      },
      responsive: false,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },

      tooltips: {
        enabled: false,
      },
      scale: {
        // display: false,
        pointLabels: {
          display: false,
          fontSize: 16,
          fontFamily: "Archivo, sans-serif",
          fontColor: "#101010",
        },
        ticks: {
          beginAtZero: false,
          stepSize: 5,
          max: 35,
          min: 0,
          display: false,
          backdropColor: "rgba(0, 0, 0, 0)",
        },
      },
      elements: {
        point: {
          radius: 2,
          hoverRadius: 4,
        },
      },
    };
  }, []);
  return (
    <div
      className="zoom-independent position-relative"
      style={{
        width: "375px",
        height: "375px",
        marginTop: "60px",
        marginBottom: "40px",
      }}
    >
      <Radar
        id="actial-radar"
        redraw={true}
        height={375}
        width={375}
        style={{ margin: "auto" }}
        data={updatedData}
        options={updatedOptions}
      />
      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          top: "-24px",
          left: "187px",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Identify
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData.datasets[activeChart].data ? updatedData.datasets[activeChart].data[0] : 0}
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "-50px",
          top: "calc(150px - 3rem)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Protect
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData.datasets[activeChart].data ? updatedData.datasets[activeChart].data[1] : 0}
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "calc(58px - 3rem)",
          bottom: "calc(53px - 3rem)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Detect
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData.datasets[activeChart].data ? updatedData.datasets[activeChart].data[2] : 0}
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "calc(356px - 3rem)",
          bottom: "calc(53px - 3rem)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Respond
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData.datasets[activeChart].data ? updatedData.datasets[activeChart].data[3] : 0}
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          left: "calc(-13px - 3rem)",
          top: "calc(150px - 3rem)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Recover
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData.datasets[activeChart].data ? updatedData.datasets[activeChart].data[4] : 0}
        </div>
      </div>
    </div>
  );
};

export default memo(MspClientPriceCalculatorRadarChart);

const staticDatasets = [
  {
    data: [25, 20, 17, 30, 31],
    pointRadius: [0, 0, 0, 0, 0],
    datalabels: {
      display: false,
    },
    backgroundColor: `${"#5244B3"}`,
    borderColor: `${"#5244B3"}`,
    pointBackgroundColor: "#5244B3",
    borderWidth: 1,
    pointLabelFontSize: 0,
    scaleShowLabels: false,
    label: "Current Posture",
  },
  // {
  //   data: [30, 5, 34, 20, 15],
  //   pointRadius: [0, 0, 0, 0, 0],
  //   datalabels: {
  //     display: false,
  //   },
  //   backgroundColor: `${"#008fd4"}`,
  //   borderColor: `${"#008fd4"}`,
  //   pointBackgroundColor: "#008fd4",
  //   borderWidth: 1,
  //   pointLabelFontSize: 0,
  //   scaleShowLabels: false,
  //   label: "Current Posture",
  // },
];





export const MspTechSpendScreenRadarChart = ({
  activeChart = 0,
  _datasets = staticDatasets,
}) => {
  const updatedData = useMemo(() => {
    let updatedDatasets = [..._datasets]?.map((data, dataIndex) => ({
      ...data,
      backgroundColor:
        dataIndex === activeChart
          ? `${data.backgroundColor}30`
          : `${data.backgroundColor}00`,
      pointRadius:
        dataIndex === activeChart ? [2, 2, 2, 2, 2] : [0, 0, 0, 0, 0],

      borderDash: dataIndex === 0 ? [5, 5] : [0, 0],
    }));

    return {
      labels: ["Identity", "Protect", "Detect", "Respond", "Recover"],

      datasets: updatedDatasets,
    };
  }, [_datasets, activeChart]);



  const updatedOptions = useMemo(() => {
    return {
      animation: {
        duration:1,// 4000, // Set the duration of the transition in milliseconds
      },
      responsive: false,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },

      tooltips: {
        enabled: false,
      },
      scale: {
        // display: false,
        pointLabels: {
          display: false,
          fontSize: 16,
          fontFamily: "Archivo, sans-serif",
          fontColor: "#101010",
        },
        ticks: {
          beginAtZero: false,
          stepSize: 5,
          max: 35,
          min: 0,
          display: false,
          backdropColor: "rgba(0, 0, 0, 0)",
        },
      },
      elements: {
        point: {
          radius: 2,
          hoverRadius: 4,
        },
      },
    };
  }, []);
  return (
    <div
      className="zoom-independent position-relative"
      style={{
        transform:"translate(0px, 0px)"
        // width: "450px",
        // height: "450px",
        // marginTop: "60px",
        // marginBottom: "40px",
      }}
    >
      <Radar
        id="actial-radar"
        redraw={true}
        height={150}
        width={150}
        style={{ margin: "auto" }}
        data={updatedData}
        options={updatedOptions}
      />
      <div
        className="position-fixed w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          top: "-13%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Identify
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData.datasets[activeChart].data[0]}
        </div>
      </div>

      <div
        className="position-fixed w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "-42%",
          top: "29%",
          
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Protect
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData.datasets[activeChart].data[1]}
        </div>
      </div>

      <div
        className="position-fixed w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: '-26%',
          bottom: '-26%',
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Detect
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData.datasets[activeChart].data[2]}
        </div>
      </div>

      <div
        className="position-fixed w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "64%",
          bottom: "-26%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Respond
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData.datasets[activeChart].data[3]}
        </div>
      </div>

      <div
        className="position-fixed w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          left: "-14%",
          top: "28%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Recover
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData.datasets[activeChart].data[4]}
        </div>
      </div>
    </div>
  );
};