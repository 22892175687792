// import { fromJS } from "immutable";
import { SEGMENT_DETAIL } from "./constants";

export const initState = {
  onceTicketCreated: false,
  onceScenarioPrinted: false,
  onceScenarioShared: false,
  insideSupportTicket: false,
  cyberScoreRelatedEventTriggered: false,
  insideShareReport: false,
};

const SegmentReducer = (state = initState, action) => {
  switch (action.type) {
    case SEGMENT_DETAIL:
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
};

export default SegmentReducer;
