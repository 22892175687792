import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import {
  AttackSurfaceGetApi,
  AttackSurfacePostApi,
} from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import { WhiteLoader } from "../../../Theme/APILoaders";
import "./smallWorkstationModal.scss";
import {
  getDataToUpload,
  makeGroup,
  modifyHeaderListToUpload,
  // RenderImagesGroup,
} from "./WorkstationModal";
import "../../common.scss";
import MultiSelectDropdown from "../../AttackComponents/MultiSelectDropdown";
import HighgroundDatePicker from "../../../test/TestComponent";
// import { TrimString } from "../../../../Utils/StringTrimmer";
import AttackLocationDropdown from "../../AttackComponents/AttackLocationDropdown";
import Loader from "../../../Common/loader";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import { cleanText } from "../../Index";
import { isValidEmail } from "../../../../Utils/utilityJsxElements";

const SmallWorkstationModal = ({
  show,
  hideModal,
  firstAssetId,
  assetkey,
  refreshMenuItems,
  onFinish,
  intialValue = "",
}) => {
  const [saving, setSaving] = useState(false);
  const { authTokens } = useAuth();
  const [fieldsData, setFieldsData] = useState([]);
  const [assetIdField, setAssetIdField] = useState({});
  const [assetNameField, setAssetNameField] = useState({});
  const [loading, setLoading] = useState(false);
  const [autoGeneratingId, setAutoGeneratingId] = useState(false);
  const [pageMetaData, setPageMetaData] = useState([]);
  const [headerFieldList, setHeaderFieldList] = useState([]);

  const [keysList, setKeysList] = useState([]);
  function removeLastKey() {
    let updated_data = [...keysList];
    updated_data.pop();
    setKeysList(updated_data);
  }

  // {
  //   name: "Rounter",
  //   assetId: 2229,
  // headerFieldList:[],
  //   fieldsData: [],
  // },

  // LOGIC TO CONTROL OPENED NESTED ASSETS LIST
  // let testStructure = {
  //   elementClass: "",
  //   elementKey: "",
  //   elementValue: "",
  // };
  const [selectedList, setSelectedList] = useState([]);

  function addItemToSelectionList(attributeName, Key) {
    let _initial_state = [...selectedList];
    let new_item = {
      elementClass: superCleanText(attributeName),
      elementKey: Key,
      elementValue: "",
    };
    _initial_state.push(new_item);
    setSelectedList(_initial_state);
  }

  useEffect(() => {
    if (show) {
      setSelectedList([]);
      setKeysList([assetkey]);
      autoFillAssetIdAndgetModalData(firstAssetId, 0, {
        key: assetkey,
        value: intialValue,
      });
      let modal_backdrop = document.querySelector(".modal-backdrop");
      modal_backdrop.classList.remove("modal-backdrop");
      if (modal_backdrop) {
        setTimeout(() => {
          modal_backdrop.classList.add("transparent-backdrop");
        });
      }
    }
  }, [show, firstAssetId]);
  const TypeMapping = {
    "A_Text (Single Line)": 0, // Text input box
    "A_Drop Down List (Single)": 1, // Drop down single
    A_Date: 2, //Date Picker
    "A_Yes or No": 3, //Check Box
    "A_Drop Down List (Multi)": 4,
    "A_Text (Multi Line)": 5,
  };

  // keyPair={key:"Key name",value:"intial Pre filled Value"}
  function getModalData(assetId, page, autoGenerateId = "", keyPair = {}) {
    setLoading(true);
    AttackSurfaceGetApi(
      `AttackSurfaceAPI/GetObjectParameters?`, //?OrgId=237003
      authTokens,
      {
        ObjDefId: assetId,
      }
    )
      .then((response) => {
        if (!response?.FieldList?.length) {
          CallToast("Something went wrong", false, false, "v2style");
          setLoading(false);
          return;
        }
        // let modal_fields = response?.FieldList;
        let modal_fields = [
          ...response?.FieldList?.sort(
            (a, b) =>
              Number(a.ASE_AttrDef_ColumnSort) -
              Number(b.ASE_AttrDef_ColumnSort)
          ),
        ];
        for (let i = 0; i < modal_fields?.length; i++) {
          // Fill intial Name value if user type in Input field and then click on Add

          if (
            modal_fields[i]?.ASE_FieldName === keyPair?.key &&
            modal_fields[i]?.ASE_AttrDef_InputType === "A_Text (Single Line)"
          ) {
            modal_fields[i].ASE_FieldValue = keyPair?.value;
          }

          // Auto fill Asset Id
          if (modal_fields[i]?.ASE_FieldName === "asset_id") {
            modal_fields[i].ASE_FieldValue = autoGenerateId;
          }

          modal_fields[i].error = false;
          if (
            modal_fields[i]?.ASE_AttrDef_InputType === "A_Yes or No" &&
            modal_fields[i]?.ASE_FieldMandatory === 1
          ) {
            modal_fields[i].ASE_FieldValue = false;
          }
        }
        // Set Page name for header
        let updated_header = [...pageMetaData];
        updated_header[page] = {};
        updated_header[page]["name"] = response?.ObjectName;
        updated_header[page]["assetId"] = assetId;
        setPageMetaData(updated_header);

        structureModal(modal_fields);
        setLoading(false);
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }
  // Function to structure modal

  function structureModal(_modal_fields) {
    let modalFields = [..._modal_fields].filter(
      (field) => field.ASE_AttrDef_GroupName !== "Header"
    );
    let headerFields = [..._modal_fields].filter(
      (field) => field.ASE_AttrDef_GroupName === "Header"
    );

    setHeaderFieldList(headerFields);

    let fields_data = makeGroup(modalFields, "ASE_AttrDef_GroupName");
    setFieldsData(fields_data);
    setLoading(false);
  }
  async function uploadData(data) {
    setSaving(true);
    let updated_data = [...data];

    await AttackSurfacePostApi(
      authTokens,
      `AttackSurfaceAPI/UploadASEData?AssetIdentifier=${
        pageMetaData[pageMetaData.length - 1]["assetId"]
      }&BizObjectId=0`,
      updated_data
    )
      .then((response) => {
        if (!response?.Success) {
          CallToast("Something went wrong", false, false, "v2style");

          setSaving(false);
          return;
        }

        if (refreshMenuItems) {
          refreshMenuItems();
        }
        if (pageMetaData.length > 1) {
          goBack(true, {
            AttributeId: response?.EntityId,
            AttributeText: updated_data[0][keysList[pageMetaData.length - 1]],
          });
        } else {
          setSaving(false);
          closeModal();
          // Save the value to the main modal drop list\

          if (typeof updated_data[0][keysList[0]] === "object") {
            if (onFinish) {
              onFinish(updated_data[0][keysList[0]]);
            }
          } else {
            if (onFinish) {
              onFinish({
                AttributeId: response?.EntityId,
                AttributeText: updated_data[0][keysList[0]],
              });
            }
          }
        }
      })
      .catch(() => {
        setSaving(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // Function to go back to previous screen
  async function goBack(save = true, savedValue) {
    setLoading(true);
    removeLastKey();
    let updated_pageMetaData = [...pageMetaData];
    updated_pageMetaData.pop();
    let unpacked_fieldsData = unPackFieldsData(
      updated_pageMetaData[updated_pageMetaData.length - 1].fieldsData
    );

    unpacked_fieldsData.unshift(
      ...updated_pageMetaData[updated_pageMetaData.length - 1].headerFieldList
    );

    // All fields are unpacked from old Asset

    // Now get call and updated data and exchabge fields except values
    AttackSurfaceGetApi(
      `AttackSurfaceAPI/GetObjectParameters?`, //?OrgId=237003
      authTokens,
      {
        ObjDefId: updated_pageMetaData[updated_pageMetaData.length - 1].assetId,
      }
    )
      .then((response) => {
        if (!response?.FieldList?.length) {
          CallToast("Something went wrong", false, false, "v2style");
          setLoading(false);
          setSaving(false);
          return;
        }
        let new_fields = [
          ...response?.FieldList?.sort(
            (a, b) =>
              Number(a.ASE_AttrDef_ColumnSort) -
              Number(b.ASE_AttrDef_ColumnSort)
          ),
        ];
        let old_fields = [
          ...unpacked_fieldsData?.sort(
            (a, b) =>
              Number(a.ASE_AttrDef_ColumnSort) -
              Number(b.ASE_AttrDef_ColumnSort)
          ),
        ];

        let updated_fields = [];
        for (let i = 0; i < new_fields.length; i++) {
          let that_field = old_fields.filter(
            (item) => item.ASE_FieldName === new_fields[i].ASE_FieldName
          )[0];

          if (save && savedValue?.AttributeId > 0) {
            // Logic when value is saved in Next step
            let selection_list = [...selectedList];
            let previous_selection_key =
              selection_list[selection_list?.length - 1]?.elementKey;
            if (that_field["ASE_FieldName"] === previous_selection_key) {
              if (
                that_field["ASE_AttrDef_InputType"] ===
                "A_Drop Down List (Multi)"
              ) {
                if (that_field["ASE_FieldValue"]?.length === 0) {
                  that_field["ASE_FieldValue"] = [savedValue];
                  updated_fields.push({
                    ...new_fields[i],
                    ASE_FieldValue: that_field["ASE_FieldValue"],
                  });
                } else {
                  // If multi select array is filled or not empty

                  let updated_that_field = [...that_field["ASE_FieldValue"]];
                  updated_that_field.push(savedValue);
                  updated_fields.push({
                    ...new_fields[i],
                    ASE_FieldValue: updated_that_field,
                  });
                }
              } else {
                that_field["ASE_FieldValue"] = savedValue;
                updated_fields.push({
                  ...new_fields[i],
                  ASE_FieldValue: that_field["ASE_FieldValue"],
                });
              }
            } else {
              updated_fields.push({
                ...new_fields[i],
                ASE_FieldValue: that_field["ASE_FieldValue"],
              });
            }
          } else {
            // When clicked on Cancel

            removeLastKey();
            updated_fields.push({
              ...new_fields[i],
              ASE_FieldValue: that_field["ASE_FieldValue"],
            });
          }
        }

        structureModal(updated_fields);
        setPageMetaData(updated_pageMetaData);
        setSaving(false);
        setLoading(false);

        // Logic to scroll To previous selected field
        if (save && false) {
        } else {
          //  Scroll to the Previous selected Element
          let selection_list = [...selectedList];
          let Previous_element_class =
            selection_list[selection_list?.length - 1]?.elementClass;
          setTimeout(() => {
            let nested_modal = document.getElementsByClassName(
              "attack-nested-modal"
            )[0];
            let previous_element = nested_modal.getElementsByClassName(
              Previous_element_class
            )?.[0];

            if (previous_element) {
              let dropDown_toggle =
                previous_element.getElementsByClassName("dropdown-toggle")[0];
              if (dropDown_toggle) {
                dropDown_toggle.click();
              }
              previous_element.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest",
              });
            }
            selection_list.pop();
            setSelectedList(selection_list);
          }, 1);
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // Function to Open New Asset while saving the previous one
  async function openNewAsset(newAsseId, keyPair) {
    if (newAsseId === null || newAsseId === "" || newAsseId === "0") {
      return;
    }
    setLoading(true);
    AttackSurfaceGetApi(
      `AttackSurfaceAPI/AutoGenID?`, //?OrgId=237003
      authTokens,
      {
        ObjDefId: newAsseId,
      }
    )
      .then((response) => {
        // saving old 'state
        let old_data = [...pageMetaData];

        old_data[pageMetaData.length - 1].headerFieldList = [
          ...headerFieldList,
        ];
        old_data[pageMetaData.length - 1].fieldsData = [...fieldsData];
        setPageMetaData(old_data);
        getModalData(
          newAsseId,
          pageMetaData.length,
          response?.AutoGenResp,
          keyPair
        );
      })
      .catch(() => {
        // saving old 'state
        let old_data = [...pageMetaData];

        old_data[pageMetaData.length - 1].headerFieldList = [
          ...headerFieldList,
        ];
        old_data[pageMetaData.length - 1].fieldsData = [...fieldsData];
        setPageMetaData(old_data);
        getModalData(newAsseId, pageMetaData.length, "", keyPair);
      });
  }

  function closeModal() {
    hideModal();
    setFieldsData([]);
    setAssetIdField({});
    setAssetNameField({});
    setPageMetaData([]);
    setHeaderFieldList([]);
  }

  // Function to Auto generate the Asset Id

  function autoGenerateId() {
    setAutoGeneratingId(true);
    AttackSurfaceGetApi(
      `AttackSurfaceAPI/AutoGenID?`, //?OrgId=237003
      authTokens,
      {
        ObjDefId: pageMetaData[pageMetaData.length - 1].assetId,
      }
    )
      .then((response) => {
        setAutoGeneratingId(false);
        if (response?.AutoGenResp) {
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  async function checkValidations(
    headerFieldList = [],
    setHeaderFieldList,
    fieldsData = [],
    setFieldsData
  ) {
    let errorCount = 0;

    let updated_headerFieldList = [...headerFieldList];
    for (let i = 0; i < updated_headerFieldList.length; i++) {
      // To check if the Mandatory field in an inout and is empty
      if (
        (updated_headerFieldList[i].ASE_FieldMandatory === 1 &&
          updated_headerFieldList[i].ASE_FieldValue === "") ||
        (updated_headerFieldList[i].ASE_FieldName?.includes("email") &&
          updated_headerFieldList[i].ASE_FieldValue !== "" &&
          !isValidEmail(updated_headerFieldList[i].ASE_FieldValue))
      ) {
        updated_headerFieldList[i].error = true;
        errorCount += 1;
      }
      // To Check  if the mandatory field is an Multi dropdown and is empty
      if (
        updated_headerFieldList[i].ASE_FieldMandatory === 1 &&
        updated_headerFieldList[i].ASE_AttrDef_InputType ===
          "A_Drop Down List (Multi)" &&
        updated_headerFieldList[i].ASE_FieldValue?.length === 0
      ) {
        updated_headerFieldList[i].error = true;
        errorCount += 1;
      }
      // To Check  if the mandatory field is an Single dropdown and is empty
      if (
        updated_headerFieldList[i].ASE_FieldMandatory === 1 &&
        updated_headerFieldList[i].ASE_AttrDef_InputType ===
          "A_Drop Down List (Single)" &&
        Object.keys(updated_headerFieldList[i].ASE_FieldValue).length === 0
      ) {
        updated_headerFieldList[i].error = true;
        errorCount += 1;
      }
    }
    setHeaderFieldList(updated_headerFieldList);

    let updated_fieldsData = [...fieldsData];

    for (let i = 0; i < updated_fieldsData.length; i++) {
      for (let j = 0; j < updated_fieldsData[i].data.length; j++) {
        // To check if the Mandatory field in an inout and is empty
        if (
          (updated_fieldsData[i].data[j].ASE_FieldMandatory === 1 &&
            updated_fieldsData[i].data[j].ASE_FieldValue === "") ||
          (updated_fieldsData[i].data[j].ASE_FieldName?.includes("email") &&
            updated_fieldsData[i].data[j].ASE_FieldValue !== "" &&
            !isValidEmail(updated_fieldsData[i].data[j].ASE_FieldValue))
        ) {
          updated_fieldsData[i].data[j].error = true;
          errorCount += 1;
        }

        // To Check  if the mandatory field is an Multi dropdown and is empty
        if (
          updated_fieldsData[i].data[j].ASE_FieldMandatory === 1 &&
          updated_fieldsData[i].data[j].ASE_AttrDef_InputType ===
            "A_Drop Down List (Multi)" &&
          updated_fieldsData[i].data[j].ASE_FieldValue?.length === 0
        ) {
          updated_fieldsData[i].data[j].error = true;
          errorCount += 1;
        }
        // To Check  if the mandatory field is an Single dropdown and is empty
        if (
          updated_fieldsData[i].data[j].ASE_FieldMandatory === 1 &&
          updated_fieldsData[i].data[j].ASE_AttrDef_InputType ===
            "A_Drop Down List (Single)" &&
          Object.keys(updated_fieldsData[i].data[j].ASE_FieldValue).length === 0
        ) {
          updated_fieldsData[i].data[j].error = true;
          errorCount += 1;
        }
      }
    }
    setFieldsData(updated_fieldsData);

    return errorCount;
  }

  // Function to Autofill Asset Id
  function autoFillAssetIdAndgetModalData(
    assetModifierId,
    page = 0,
    keyPair = {}
  ) {
    setLoading(true);
    AttackSurfaceGetApi(
      `AttackSurfaceAPI/AutoGenID?`, //?OrgId=237003
      authTokens,
      {
        ObjDefId: assetModifierId,
      }
    )
      .then((response) => {
        getModalData(assetModifierId, page, response?.AutoGenResp, keyPair);
      })
      .catch(() => {
        getModalData(assetModifierId, page, "", keyPair);
      });
  }

  // Toggle Secondary field of FieldsData
  function toggleSecondaryField(groupIndex, field_name, visibility) {
    let updated_fieldsData = [...fieldsData];
    for (let i = 0; i < updated_fieldsData[groupIndex]["data"].length; i++) {
      if (
        updated_fieldsData[groupIndex]["data"][i].ASE_AttrDef_PrimaryField ===
          field_name &&
        updated_fieldsData[groupIndex]["data"][i].ASE_AttrDef_IsSecondary ===
          true
      ) {
        updated_fieldsData[groupIndex]["data"][i].ASE_AttrDef_Visibility =
          visibility;
      }
    }
    setFieldsData(updated_fieldsData);
  }



  function ThrowExceptionIfOnSiteWorkIsSelected() {
    let updated_fieldsData = [...fieldsData];
    for (let i = 0; i < updated_fieldsData.length; i++) {
      for (let j = 0; j < updated_fieldsData[i]["data"].length; j++) {
        if (
          updated_fieldsData[i]["data"][j].ASE_FieldName === "work_type" &&
          updated_fieldsData[i]["data"][j].ASE_FieldValue?.filter(
            (item) => item?.AttributeText?.toLowerCase() === "onsite"
          )?.length > 0
        ) {
          throw new Error("Onsite value is selected!");
        }
      }
    }
  }
  function ThrowExceptionIfPermanentHomeIsSelected(row) {
    let updated_fieldsData = [...fieldsData];
    for (let i = 0; i < updated_fieldsData.length; i++) {
      for (let j = 0; j < updated_fieldsData[i]["data"].length; j++) {
        if (
          updated_fieldsData[i]["data"][j].ASE_FieldName === "permanent_work_from_home_position" &&
          updated_fieldsData[i]["data"][j].ASE_FieldValue===true &&
          row?.ASE_FieldName === "work_type"
        ) {
          return true
          
        }
      }
    }
  }

  return (
    <Modal
      show={show}
      dialogClassName="small-workstation-modal common-big-modal"
      className={`attack-nested-modal`}
      aria-labelledby="small-workstation-modal"
      centered
      scrollable
      onHide={closeModal}
    >
      <Modal.Header className="p-0">
        <div className="border-parent w-100">
          {pageMetaData.map((header, headerIndex) => {
            return (
              <div
                className="d-flex align-items-center f-12 f-grey border-item w-100 pl-3"
                style={{
                  height: "2.55rem",
                }}
              >
                <div
                  className="mr-2 rounded-full  f-white bg-grey-66 d-flex align-items-center justify-content-center"
                  style={{
                    fontSize: "0.86rem",
                    height: "0.94rem",
                    width: "0.94rem",
                  }}
                >
                  {headerIndex + 1}{" "}
                </div>
                <div style={{ fontSize: "0.99rem" }}>
                  {" "}
                  Add {header?.name?.replaceAll("_", " ")}
                </div>
              </div>
            );
          })}
        </div>

        {/* <div className="f-12 f-grey">Add Router/Firewall</div> */}
      </Modal.Header>
      <Modal.Body className="hide-scrollbar px-3">
        {loading ? (
          <div className="d-flex align-items-center justify-content-center w-100 h-100">
            <Loader />
          </div>
        ) : (
          <>
            <div className="p-1"></div>

            {/* Header field list */}
            {headerFieldList
              ?.sort(
                (a, b) =>
                  Number(a.ASE_AttrDef_ColumnSort) -
                  Number(b.ASE_AttrDef_ColumnSort)
              )
              ?.map((row, Index) => {
                return (
                  <div
                    className={`d-flex align-items-center justify-content-between mb-1   ${superCleanText(
                      row?.ASE_AttributeName
                    )} `}
                    id={`${row?.error ? `error-elementsmall` : ""}`}
                  >
                    <div
                      className={`f-black w-40 f-500 ${
                        TypeMapping[row["ASE_AttrDef_InputType"]] === 0
                          ? "d-none"
                          : ""
                      }`}
                    >
                      {row?.ASE_AttributeName?.replaceAll("_", " ")?.replaceAll(
                        "plus",
                        "+"
                      )}
                    </div>
                    {/* For Input Field */}
                    {TypeMapping[row["ASE_AttrDef_InputType"]] === 0 && (
                      <>
                        <div
                          className={`asset-name-wrapper w-100 d-flex align-items-center p-2 radius-4 mt-2
              
              ${row?.error ? "input-error" : ""}
              
              `}
                          id={`${row?.error ? `error-elementsmall` : ""}`}
                        >
                          <div className="f-darkgrey  ">
                            {cleanText(row?.ASE_AttributeName)}:
                          </div>
                          <input
                            type="text"
                            placeholder={`Enter ${cleanText(
                              row?.ASE_AttributeName
                            )}`}
                            className="flex-grow-1 pl-3 border-none"
                            value={row?.ASE_FieldValue || ""}
                            onChange={(e) => {
                              let fields_data = [...headerFieldList];
                              fields_data[Index]["ASE_FieldValue"] =
                                e.target.value;
                              fields_data[Index]["error"] = false;
                              setHeaderFieldList(fields_data);
                            }}
                          />
                          {row?.ASE_FieldName === "asset_id" && (
                            <div
                              className={`d-flex align-items-center f-blue pointer
                        
                          `}
                              onClick={() => {
                                setAutoGeneratingId(true);
                                AttackSurfaceGetApi(
                                  `AttackSurfaceAPI/AutoGenID?`, //?OrgId=237003
                                  authTokens,
                                  {
                                    ObjDefId:
                                      pageMetaData[pageMetaData.length - 1]
                                        .assetId,
                                  }
                                )
                                  .then((response) => {
                                    setAutoGeneratingId(false);
                                    if (response?.AutoGenResp) {
                                      let fields_data = [...headerFieldList];
                                      fields_data[Index]["ASE_FieldValue"] =
                                        response?.AutoGenResp;
                                      fields_data[Index]["error"] = false;
                                      setHeaderFieldList(fields_data);
                                    } else {
                                      CallToast(
                                        "Something went wrong",
                                        false,
                                        false,
                                        "v2style"
                                      );
                                    }
                                  })
                                  .catch(() => {
                                    CallToast(
                                      "Something went wrong",
                                      false,
                                      false,
                                      "v2style"
                                    );
                                  });
                              }}
                            >
                              {autoGeneratingId
                                ? "Generating Id.."
                                : "Auto-generate ID"}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    {/* For Drop down Field */}
                    {TypeMapping[row["ASE_AttrDef_InputType"]] === 1 && (
                      <>
                        <div className="w-60 attack-bigmodal-dropdown">
                          <AttackLocationDropdown
                            nestedModal={true}
                            retainPreviousStateFunc={() => {
                              addItemToSelectionList(
                                row?.ASE_AttributeName,
                                row?.ASE_FieldName
                              );
                            }}
                            refId={row?.["ASE_RelObjDefID"]}
                            icon={
                              row?.ASE_AttrDef_Icon
                                ? `/images/attack-surface/${row?.ASE_AttrDef_Icon}.svg`
                                : "/images/attack-surface/attack_surface_dashboard_icon.svg"
                            }
                            onAddClick={(Id, key, initialVal) => {
                              openNewAsset(Id, { key: key, value: initialVal });
                              setKeysList((prevKeys) => [...prevKeys, key]);
                            }}
                            placeholder={row?.ASE_AttributeName}
                            data={row?.AttributeDDLList}
                            selected={row?.ASE_FieldValue}
                            onChange={(val) => {
                              let fields_data = [...headerFieldList];
                              fields_data[Index]["ASE_FieldValue"] = val;
                              fields_data[Index]["error"] = false;
                              setHeaderFieldList(fields_data);
                            }}
                            objectID={row?.ASE_RelObjDefID}
                            isError={row?.error}
                            refreshMenuItems={refreshMenuItems}
                            attrKey={row?.ASE_AttributeName?.replaceAll(
                              "_",
                              " "
                            )}
                            assetId={
                              pageMetaData[pageMetaData.length - 1]["assetId"]
                            }
                            groups={row?.["ASE_AttributeRelatedObj"]}
                          />
                        </div>
                      </>
                    )}

                    {/* For Date picker Field */}

                    {TypeMapping[row["ASE_AttrDef_InputType"]] === 2 && (
                      <>
                        <div className="w-60 attack-bigModal-datepicker">
                          {/* {row?.ASE_FieldValue && ( */}
                          <HighgroundDatePicker
                            selected={row?.ASE_FieldValue}
                            icon={"/images/attack-surface/date-icon.svg"}
                            onChange={(val) => {
                              let fields_data = [...headerFieldList];
                              fields_data[Index]["ASE_FieldValue"] = val;
                              fields_data[Index]["error"] = false;
                              setHeaderFieldList(fields_data);
                            }}
                            id={
                              row?.ASE_AttributeName.trim().replaceAll(
                                " ",
                                ""
                              ) + Index
                            }
                            isError={row?.error}
                          />
                        </div>
                      </>
                    )}

                    {/* For Check Box Field */}

                    {TypeMapping[row["ASE_AttrDef_InputType"]] === 3 && (
                      <div
                        className="w-60 my-2 d-flex"
                        style={{
                          paddingLeft: "2.9rem",
                        }}
                      >
                        <Form.Group
                          controlId={`check${Index}-${true}` + "" + Index}
                          className=""
                        >
                          <Form.Check
                            disabled={false}
                            type="switch"
                            id={"switch-ques-+1" + "" + Index}
                            style={{
                              transform: "scale(1.6)",
                              paddingLeft: "0.75rem",
                            }}
                            className={`m-0 scale-switch switch-shadow-none
                ${row?.error ? "attack-switch-error" : ""}
                `}
                            checked={row?.ASE_FieldValue}
                            label=""
                            // disabled={row?.ASE_FieldVerified}
                            onChange={(e) => {
                              let fields_data = [...headerFieldList];
                              fields_data[Index]["ASE_FieldValue"] =
                                e.target.checked;
                              fields_data[Index]["error"] = false;
                              setHeaderFieldList(fields_data);
                            }}
                          />
                        </Form.Group>
                      </div>
                    )}
                    {TypeMapping[row["ASE_AttrDef_InputType"]] === 4 && (
                      <>
                        <div className="w-60 my-1">
                          <MultiSelectDropdown
                            locked={false}
                            nestedModal={true}
                            retainPreviousStateFunc={() => {
                              addItemToSelectionList(
                                row?.ASE_AttributeName,
                                row?.ASE_FieldName
                              );
                            }}
                            refId={row?.["ASE_RelObjDefID"]}
                            onAddClick={(Id, key, initialVal) => {
                              openNewAsset(Id, { key: key, value: initialVal });
                              setKeysList((prevKeys) => [...prevKeys, key]);
                            }}
                            data={row?.AttributeDDLList}
                            selected={row?.ASE_FieldValue}
                            onChange={(val) => {
                              let fields_data = [...headerFieldList];
                              fields_data[Index]["ASE_FieldValue"] = val;
                              fields_data[Index]["error"] = false;
                              setHeaderFieldList(fields_data);
                            }}
                            assetKey={row?.ASE_AttributeName?.replaceAll(
                              "_",
                              " "
                            )}
                            assetId={
                              pageMetaData[pageMetaData.length - 1]["assetId"]
                            }
                            isError={row?.error}
                            refreshMenuItems={refreshMenuItems}
                            groups={row?.["ASE_AttributeRelatedObj"]}
                          />
                        </div>
                      </>
                    )}
                    {TypeMapping[row["ASE_AttrDef_InputType"]] === 5 && (
                      <>
                        <div className="w-60 my-1">
                          <div
                            className={`attack-bigmodal-textarea-wrapper
               ${row?.error ? "input-error radius-4" : ""} 
              
              `}
                          >
                            <textarea
                              className="border-none radius-4"
                              placeholder="Type"
                              rows={3}
                              id="message"
                              name="message"
                              value={row?.ASE_FieldValue}
                              onChange={(e) => {
                                let fields_data = [...headerFieldList];
                                fields_data[Index]["ASE_FieldValue"] =
                                  e.target.value;
                                fields_data[Index]["error"] = false;
                                setHeaderFieldList(fields_data);
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            {/* 333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333 */}
            {/* All the Groups rendering  */}
            {/* 333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333 */}
            {fieldsData?.length > 0 &&
              fieldsData?.map((group, groupIndex) => {
                return (
                  <>
                    {(headerFieldList?.length > 0 || groupIndex !== 0) && (
                      <hr className="line-grey" />
                    )}
                    <div className="f-12 f-darkgrey mb-2 ">
                      {group?.groupName}
                    </div>
                    {/* Details */}

                    {group.data
                      ?.sort(
                        (a, b) =>
                          Number(a.ASE_AttrDef_ColumnSort) -
                          Number(b.ASE_AttrDef_ColumnSort)
                      )
                      ?.map((row, Index) => {
                        return (
                          <div
                            className={` align-items-center justify-content-between mb-1   ${superCleanText(
                              row?.ASE_AttributeName
                            )}
                            
                            ${
                              row?.ASE_AttrDef_Visibility === false
                                ? "d-none"
                                : "d-flex"
                            }
                            
                            
                            `}
                            id={`${row?.error ? `error-elementsmall` : ""}`}
                          >
                            <div
                              className="f-black w-40 f-500"
                              style={{
                                paddingLeft: `${
                                  row?.ASE_AttrDef_IsSecondary === true
                                    ? "1rem"
                                    : ""
                                }`,
                              }}
                            >
                              {cleanText(row?.ASE_AttributeName)}
                            </div>
                            {/* For Input Field */}
                            {TypeMapping[row["ASE_AttrDef_InputType"]] ===
                              0 && (
                              <>
                                <div className="w-60">
                                  <div
                                    className={`attack-bigmodal-input-wrapper
                                ${row?.error ? "input-error" : ""}
                                `}
                                  >
                                    {ModalItemsData?.[row?.ASE_AttributeName]?.[
                                      "icon"
                                    ] && (
                                      <img
                                        alt=""
                                        className="mr-2"
                                        src={
                                          ModalItemsData?.[
                                            row?.ASE_AttributeName
                                          ]?.["icon"]
                                        }
                                      />
                                    )}
                                    <input
                                      type="text"
                                      placeholder={`${
                                        ModalItemsData?.[
                                          row?.ASE_AttributeName
                                        ]?.["placeholder"] || "Type"
                                      }`}
                                      className="attack-bigmodal-input border-none flex-grow-1"
                                      value={row?.ASE_FieldValue}
                                      onChange={(e) => {
                                        let fields_data = [...fieldsData];
                                        fields_data[groupIndex]["data"][Index][
                                          "ASE_FieldValue"
                                        ] = e.target.value;
                                        fields_data[groupIndex]["data"][Index][
                                          "error"
                                        ] = false;
                                        setFieldsData(fields_data);
                                      }}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                            {/* For Drop down Field */}
                            {TypeMapping[row["ASE_AttrDef_InputType"]] ===
                              1 && (
                              <>
                                <div className="w-60 attack-bigmodal-dropdown">
                                  <AttackLocationDropdown
                                    nestedModal={true}
                                    retainPreviousStateFunc={() => {
                                      addItemToSelectionList(
                                        row?.ASE_AttributeName,
                                        row?.ASE_FieldName
                                      );
                                    }}
                                    refId={row?.["ASE_RelObjDefID"]}
                                    icon={
                                      row?.ASE_AttrDef_Icon
                                        ? `/images/attack-surface/${row?.ASE_AttrDef_Icon}.svg`
                                        : "/images/attack-surface/attack_surface_dashboard_icon.svg"
                                    }
                                    onAddClick={(Id, key, initialVal) => {
                                      openNewAsset(Id, {
                                        key: key,
                                        value: initialVal,
                                      });
                                      setKeysList((prevKeys) => [
                                        ...prevKeys,
                                        key,
                                      ]);
                                    }}
                                    placeholder={row?.ASE_AttributeName}
                                    data={row?.AttributeDDLList}
                                    selected={row?.ASE_FieldValue}
                                    onChange={(val) => {
                                      let fields_data = [...fieldsData];
                                      fields_data[groupIndex]["data"][Index][
                                        "ASE_FieldValue"
                                      ] = val;
                                      fields_data[groupIndex]["data"][Index][
                                        "error"
                                      ] = false;
                                      setFieldsData(fields_data);
                                    }}
                                    objectID={row?.ASE_RelObjDefID}
                                    isError={row?.error}
                                    refreshMenuItems={refreshMenuItems}
                                    attrKey={row?.ASE_AttributeName?.replaceAll(
                                      "_",
                                      " "
                                    )}
                                    assetId={
                                      pageMetaData[pageMetaData.length - 1][
                                        "assetId"
                                      ]
                                    }
                                    groups={row?.["ASE_AttributeRelatedObj"]}
                                  />
                                </div>
                              </>
                            )}

                            {/* For Date picker Field */}

                            {TypeMapping[row["ASE_AttrDef_InputType"]] ===
                              2 && (
                              <>
                                <div className="w-60 attack-bigModal-datepicker">
                                  {/* {row?.ASE_FieldValue && ( */}
                                  <HighgroundDatePicker
                                    selected={row?.ASE_FieldValue}
                                    icon={
                                      "/images/attack-surface/date-icon.svg"
                                    }
                                    onChange={(val) => {
                                      let fields_data = [...fieldsData];
                                      fields_data[groupIndex]["data"][Index][
                                        "ASE_FieldValue"
                                      ] = val;
                                      fields_data[groupIndex]["data"][Index][
                                        "error"
                                      ] = false;
                                      setFieldsData(fields_data);
                                    }}
                                    id={
                                      row?.ASE_AttributeName.trim().replaceAll(
                                        " ",
                                        ""
                                      ) + Index
                                    }
                                    isError={row?.error}
                                  />
                                </div>
                              </>
                            )}

                            {/* For Check Box Field */}

                            {TypeMapping[row["ASE_AttrDef_InputType"]] ===
                              3 && (
                              <div
                                className="w-60 my-2 d-flex"
                                style={{
                                  paddingLeft: "2.9rem",
                                }}
                              >
                                <Form.Group
                                  controlId={
                                    `check${Index}-${groupIndex}` +
                                    groupIndex +
                                    "" +
                                    Index
                                  }
                                  className=""
                                >
                                  <Form.Check
                                    disabled={false}
                                    type="switch"
                                    id={
                                      "switch-ques-+1" + groupIndex + "" + Index
                                    }
                                    style={{
                                      transform: "scale(1.6)",
                                      paddingLeft: "0.75rem",
                                    }}
                                    className={`m-0 scale-switch switch-shadow-none
                                  ${row?.error ? "attack-switch-error" : ""}
                                  `}
                                    checked={row?.ASE_FieldValue}
                                    label=""
                                    // disabled={row?.ASE_FieldVerified}
                                    onChange={(e) => {
                                      try{
                                        ThrowExceptionIfOnSiteWorkIsSelected()

                                        let fields_data = [...fieldsData];
                                        fields_data[groupIndex]["data"][Index][
                                          "ASE_FieldValue"
                                        ] = e.target.checked;
                                        fields_data[groupIndex]["data"][Index][
                                          "error"
                                        ] = false;
                                        setFieldsData(fields_data);
  
                                        // Tocheck if its Primary and then Toggle Secondary field
                                        if (row?.ASE_AttrDef_IsPrimary === true) {
                                          toggleSecondaryField(
                                            groupIndex,
                                            row?.ASE_FieldName,
                                            e.target.checked
                                          );
                                        }





                                      }catch(err){

console.log(err)




                                      }
                                     
                                    }}
                                  />
                                </Form.Group>
                              </div>
                            )}
                            {TypeMapping[row["ASE_AttrDef_InputType"]] ===
                              4 && (
                              <>
                                <div className="w-60 my-1">
                                  <MultiSelectDropdown
                                  disabled={ThrowExceptionIfPermanentHomeIsSelected(row)}
                                    locked={false}
                                    nestedModal={true}
                                    retainPreviousStateFunc={() => {
                                      addItemToSelectionList(
                                        row?.ASE_AttributeName,
                                        row?.ASE_FieldName
                                      );
                                    }}
                                    refId={row?.["ASE_RelObjDefID"]}
                                    onAddClick={(Id, key, initialVal) => {
                                      openNewAsset(Id, {
                                        key: key,
                                        value: initialVal,
                                      });
                                      setKeysList((prevKeys) => [
                                        ...prevKeys,
                                        key,
                                      ]);
                                    }}
                                    data={row?.AttributeDDLList}
                                    selected={row?.ASE_FieldValue}
                                    onChange={(val) => {
                                      let fields_data = [...fieldsData];
                                      fields_data[groupIndex]["data"][Index][
                                        "ASE_FieldValue"
                                      ] = val;
                                      fields_data[groupIndex]["data"][Index][
                                        "error"
                                      ] = false;
                                      setFieldsData(fields_data);
                                    }}
                                    assetKey={row?.ASE_AttributeName?.replaceAll(
                                      "_",
                                      " "
                                    )}
                                    assetId={
                                      pageMetaData[pageMetaData.length - 1][
                                        "assetId"
                                      ]
                                    }
                                    isError={row?.error}
                                    refreshMenuItems={refreshMenuItems}
                                    groups={row?.["ASE_AttributeRelatedObj"]}
                                  />
                                </div>
                              </>
                            )}
                            {TypeMapping[row["ASE_AttrDef_InputType"]] ===
                              5 && (
                              <>
                                <div className="w-60 my-1">
                                  <div
                                    className={`attack-bigmodal-textarea-wrapper
                                 ${row?.error ? "input-error radius-4" : ""} 
                                
                                `}
                                  >
                                    <textarea
                                      className="border-none radius-4"
                                      placeholder="Type"
                                      rows={3}
                                      id="message"
                                      name="message"
                                      value={row?.ASE_FieldValue}
                                      onChange={(e) => {
                                        let fields_data = [...fieldsData];
                                        fields_data[groupIndex]["data"][Index][
                                          "ASE_FieldValue"
                                        ] = e.target.value;
                                        fields_data[groupIndex]["data"][Index][
                                          "error"
                                        ] = false;
                                        setFieldsData(fields_data);
                                      }}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        );
                      })}
                  </>
                );
              })}
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <div className="footer d-flex justify-content-end">
          <Button
            className="hg-cancel-btn f-500"
            onClick={() => {
              if (pageMetaData.length > 1) {
                goBack(false);
              } else {
                setSaving(false);
                closeModal();
              }
            }}
          >
            Cancel
          </Button>
          <Button
            className={`${true} f-500 ml-2`}
            onClick={async () => {
              let errorCount = await checkValidations(
                headerFieldList,
                setHeaderFieldList,
                fieldsData,
                setFieldsData
              );

              if (errorCount > 0) {
                let error_element =
                  document.getElementById(`error-elementsmall`);
                if (error_element) {
                  error_element.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "nearest",
                  });
                }

                return;
              } else {
                let Data = getDataToUpload([...fieldsData]);

                let DataFromHeaders = modifyHeaderListToUpload(headerFieldList);
                let data_to_upload = [{ ...Data[0], ...DataFromHeaders }];

                uploadData(data_to_upload);
              }
            }}
            disabled={loading || saving}
          >
            {saving ? (
              <div className="d-flex align-items-center">
                <WhiteLoader />
                <span className="d-flex align-items-center">Saving...</span>
              </div>
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SmallWorkstationModal;
const ModalItemsData = {
  Hostname: { placeholder: "Type", icon: false },
  Manufacturer: { placeholder: "Type", icon: false },
  Model: { placeholder: "Type", icon: false },
  Serial: { placeholder: "A0BCD1EFG2", icon: false },
  "Operating System": { placeholder: "Type", icon: false },
  "Operating System Feature Build": { placeholder: "v0.00", icon: false },
  "IP Address": { placeholder: "0.0.0.0", icon: false },
  "MAC Address": { placeholder: "00:00:00:00:00:00", icon: false },
  "Managed by": {
    placeholder: "Type",
    icon: "/images/attack-surface/attack-modal-managed-by.svg",
  },
  "Primary User": {
    placeholder: "Select user",
    icon: "/images/attack-surface/attack-modal-user-icon.svg",
  },
  "Secondary User": {
    placeholder: "Select user",
    icon: "/images/attack-surface/attack-modal-user-icon.svg",
  },
  "Endpoint Protection Software": { placeholder: "Type", icon: false },
  "Web Filtering Software": { placeholder: "Type", icon: false },
  "Patch Management": { placeholder: "Type", icon: false },
  "Last Patched Windows Updates": { placeholder: "Type", icon: false },
  "Address Line 1": { placeholder: "Enter Address line 1", icon: false },
  "Address Line 2": { placeholder: "Enter Address line 2", icon: false },
  "Address Line 3": { placeholder: "Enter Address line 3", icon: false },
  City: { placeholder: "Enter city ", icon: false },
  "Postcode/ZIP Code": { placeholder: "Enter zip code", icon: false },
  "State/County": { placeholder: "Enter state/county", icon: false },
  Country: { placeholder: "Enter country", icon: false },
};

export function unPackFieldsData(inputData) {
  let output = [];
  for (let i = 0; i < inputData.length; i++) {
    output.push(...inputData[i].data);
  }

  return output;
}

function superCleanText(_text) {
  if (_text === "" || _text === null || _text === undefined) return "";
  return _text
    ?.toLowerCase()
    ?.replaceAll(" ", "")
    ?.replaceAll("/", "")
    ?.replaceAll("-", "")
    ?.replaceAll("_", "")
    ?.replaceAll("(", "")
    ?.replaceAll(")", "")
    ?.replaceAll(".", "")
    ?.replaceAll(",", "");
}
