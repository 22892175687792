import React from "react";
import { useState } from "react";
import "./insights.scss";
const InsightsSidebar = () => {
  const [selectedHeader, setSelectedHeader] = useState("Collections"); //Stages
  const Collections = [
    {
      folder: "Name of collection 1",
      queryCount: 5,
      folderData: [
        "Devices missing Endpoint Protection",
        "Devices not running Approved Endpoint Protection Software",
        "Device Exists in Endpoint Protection Product but is Missing Patch Management / RMM Product",
        "Device Exists in Endpoint Protection Product but is Missing in Patch Management/RMM and Web Filtering Product",
        "Device missing Endpoint Encryption",
      ],
    },
    {
      folder: "Name of collection 2",
      queryCount: 15,
      folderData: [
        "Devices missing Endpoint Protection",
        "Devices not running Approved Endpoint Protection Software",
        "Device Exists in Endpoint Protection Product but is Missing Patch Management / RMM Product",
        "Device Exists in Endpoint Protection Product but is Missing in Patch Management/RMM and Web Filtering Product",
        "Device missing Endpoint Encryption",
      ],
    },
    {
      folder: "Name of collection 3",
      queryCount: 0,
      folderData: [
        "Devices missing Endpoint Protection",
        "Devices not running Approved Endpoint Protection Software",
        "Device Exists in Endpoint Protection Product but is Missing Patch Management / RMM Product",
        "Device Exists in Endpoint Protection Product but is Missing in Patch Management/RMM and Web Filtering Product",
        "Device missing Endpoint Encryption",
      ],
    },
    {
      folder: "Name of collection 4",
      queryCount: 4,
      folderData: [
        "Devices missing Endpoint Protection",
        "Devices not running Approved Endpoint Protection Software",
        "Device Exists in Endpoint Protection Product but is Missing Patch Management / RMM Product",
        "Device Exists in Endpoint Protection Product but is Missing in Patch Management/RMM and Web Filtering Product",
        "Device missing Endpoint Encryption",
      ],
    },
    {
      folder: "Name of collection 5",
      queryCount: 5,
      folderData: [
        "Devices missing Endpoint Protection",
        "Devices not running Approved Endpoint Protection Software",
        "Device Exists in Endpoint Protection Product but is Missing Patch Management / RMM Product",
        "Device Exists in Endpoint Protection Product but is Missing in Patch Management/RMM and Web Filtering Product",
        "Device missing Endpoint Encryption",
      ],
    },
  ];

  function ToggleCollection(e) {
    // var acc = document.getElementById(e.target.id);
    let parent = e.target.closest(".folder");
    // var panel = e.target.nextElementSibling;
    let panel = parent.querySelector(".collection-panel-sidebar");
    if (panel?.style?.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }

    // Toggle Folder Icon fro Collection
    let folderIcon = parent.querySelector(".folder-icon");
    folderIcon.classList.toggle("open");
    folderIcon.classList.toggle("close");
  }
  return (
    <div
      className="insights_sidebar h-100"
      style={{
        borderRight: "1px solid #EBEDF1",
      }}
    >
      {/*Side bar Header */}
      <div
        className="w-100 d-flex align-items-center justify-content-between p-3"
        style={{
          borderBottom: "1px solid #EBEDF1",
        }}
      >
        <div className="d-flex align-items-center">
          <img
            src="/images/attack-surface/insights-logo.svg"
            alt=""
            className="mr-2"
          />
          <div className="f-16 f-600">ASI Builder</div>
        </div>
        <div>
          <img
            alt=""
            className=""
            src="/images/attack-surface/insights-arrow-left.svg"
          />
        </div>
      </div>
      {/* search Bar */}
      <div className="d-flex align-items-center p-3">
        <div className="radius-4 d-flex align-items-center bg-grey highlight-input p-2">
          <img
            alt=""
            src="/images/attack-surface/search-icon.svg"
            className="mr-1"
          />
          <input
            type="text"
            placeholder="Search.."
            className="border-none bg-transparent"
          />
        </div>
        <div className="radius-4 p-2 bg-grey pointer ml-2">
          <img alt="" src="/images/actioncenter/plusgrey.svg" className="" />
        </div>
      </div>
      {/* Body Header */}
      <div className="d-flex align-items-center justify-content-between px-3 pt-1 pb-3">
        <div className="d-flex align-items-center">
          <div
            className={`mr-3 f-500 pointer ${
              selectedHeader === "Collections" ? "f-blue" : "f-darkgrey"
            } `}
            onClick={() => {
              setSelectedHeader("Collections");
            }}
          >
            Collections
          </div>
          <div
            className={`mr-3 f-500 pointer ${
              selectedHeader === "Stages" ? "f-blue" : "f-darkgrey"
            } `}
            onClick={() => {
              setSelectedHeader("Stages");
            }}
          >
            Stages
          </div>
        </div>
        <div className="d-flex align-items-center f-grey">
          <img
            alt=""
            className="mr-2"
            src="images/attack-surface/insights-filter-icon.svg"
          />
          Filter
        </div>
      </div>

      {/* Folder Data for Collections */}
      {Collections.map((collection, collectionIndex) => {
        return (
          <div className=" px-2 folder" onClick={ToggleCollection}>
            <div className="d-flex align-items-center justify-content-between p-2 folder-hover">
              <div className="d-flex align-items-center folder-name">
                {/* <img
                  alt=""
                  className="mr-2 folder-icon"
                  src="/images/attack-surface/insights-folder-closed.svg"
                /> */}
                <div className="folder-icon close p-2 mr-2"></div>
                {collection.folder}
              </div>
              <div className="d-flex align-items-center f-12 query-text">
                {collection.queryCount} queries
              </div>
            </div>
            <div className="collection-panel-sidebar ml-3">
              {collection.folderData.map((file, fileIndex) => {
                return (
                  <div className="d-flex align-items-center justify-content-between p-2 folder-hover ml-2">
                    <div
                      className="d-flex align-items-center folder-name"
                      style={{
                        width: "90%",
                      }}
                      title={file}
                    >
                      <img
                        alt=""
                        className="mr-2"
                        src="/images/attack-surface/insights-file-icon.svg"
                      />

                      <div
                        className=""
                        style={{
                          flex: "1 1 100%",
                          minWidth: 0,
                        }}
                      >
                        <div
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {file}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center f-12 query-text">
                      v1
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default InsightsSidebar;
