import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { ContributorGetAPIData, getAPIData } from "../../../Utils/commonApi";
import ImageWithInitials from "../../../Utils/ImageWithInitials";
import { viewOnlyAccess } from "../../App/App";
import { useAuth } from "../../context/Auth";
import "../supplier-profiler.scss";
import ContributorInfoDropdown from "./ContributorInfoDropdown";
import RevokeDropdown from "./RevokeDropdown";

const RevokeContributorsDropdown = ({
  revokers,
  countFlag,
  questionId,
  lastEdited,
  questionnaireId,
  refreshData,
  logo,
  isContributorView,
  rowState,
  // getQuestionContributors,
}) => {
  const { authTokens } = useAuth();
  const [lastEditedRow, setLastEdited] = useState([]);
  //   revokers?.filter((contrib) => {
  //     if (questionId !== 0) {
  //       return contrib.QuestionId == questionId;
  //     } else return true;
  //   })
  // );
  async function getQuestionContributors() {
    if (isContributorView) {
      await ContributorGetAPIData(780, authTokens, {
        optionEnum1: questionnaireId,
        optionEnum2: questionId,
      }).then((response) => {
        setCurrentContributors(response?.ActivityTrackingContributor_List);
        setLastEdited({
          LastEditedContributorLogo: response?.LastEditedContributorLogo,
          LastEditedContributorName: response?.LastEditedContributorName,
        });
      });
    } else {
      await getAPIData(780, authTokens, {
        optionEnum1: questionnaireId,
        optionEnum2: questionId,
      }).then((response) => {
        setCurrentContributors(response?.ActivityTrackingContributor_List);
        setLastEdited({
          LastEditedContributorLogo: response?.LastEditedContributorLogo,
          LastEditedContributorName: response?.LastEditedContributorName,
        });
      });
    }
  }
  const [currentContributors, setCurrentContributors] = useState([]);
  useEffect(() => {
    setCurrentContributors(revokers);
  }, [revokers]);
  return (
    <>
      <Dropdown
        className=" user-detail-dropdown supplier-menu "
        style={{ inset: "auto -24px auto auto !important" }}
        onToggle={() => {}}
      >
        <Dropdown.Toggle
          className="custom-dropdown-toggle f-gray-imp "
          style={{ padding: 0 }}
        >
          <div
            className="d-flex flex-row"
            onMouseEnter={(event) => {
              // if (getQuestionContributors) {
              // if (countFlag) {
              //   getQuestionContributors();
              // }
              // }
              // event.target.click();
            }}
          >
            {/* {countFlag && (
              <img
                alt=""
                src="/images/attack-surface/attack-modal-user-icon.svg"
                className="pointer"
              />
            )}

            {countFlag ? (
              <>
                {lastEditedRow && currentContributors?.length > 0 && (
                  <div className="d-flex flex-row">
                    {logo ? (
                      <>
                        <img
                          alt=""
                          src="/images/attack-surface/attack-modal-user-icon.svg"
                          className="pointer"
                        />
                      </>
                    ) : lastEditedRow?.LastEditedContributorLogo ? (
                      <img
                        alt=""
                        width={16}
                        height={16}
                        src={lastEditedRow?.LastEditedContributorLogo}
                        style={{ borderRadius: "9999px" }}
                      />
                    ) : (
                      <ImageWithInitials
                        initials={lastEditedRow?.LastEditedContributorName}
                        width={16}
                        background={"purple"}
                      />
                    )}

                    <div className="ml-2">{currentContributors?.length}</div>
                  </div>
                )}
              </>
            ) */}

            {countFlag ? (
              // <img
              //   alt=""
              //   src="/images/attack-surface/attack-modal-user-icon.svg"
              //   className="pointer"
              // />

              <>
                {lastEdited && currentContributors.length > 0 && (
                  <div className="d-flex flex-row">
                    {logo ? (
                      <>
                        <img
                          alt=""
                          src="/images/attack-surface/attack-modal-user-icon.svg"
                          className="pointer"
                        />
                      </>
                    ) : lastEdited.LastEditedContributorLogo ? (
                      <img
                        alt=""
                        width={16}
                        height={16}
                        src={lastEdited.LastEditedContributorLogo}
                        style={{ borderRadius: "9999px" }}
                      />
                    ) : (
                      <ImageWithInitials
                        initials={lastEdited.LastEditedContributorName}
                        width={16}
                        background={"purple"}
                      />
                    )}

                    <div className="ml-2">{currentContributors.length}</div>
                  </div>
                )}
              </>
            ) : (
              <>
                {currentContributors?.slice(0, 3)?.map((r, ind) => {
                  if (currentContributors.length > 3) {
                    if (ind == 2) {
                      return (
                        <div className="rounded-full plus_count_circle w-fit-content h-fit-content position-relative overlap_child">
                          <div className="rounded-full">
                            + {currentContributors.length - 2}
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="d-flex">
                          {r?.ContributorLogo ? (
                            <div className="rounded-full w-fit-content h-fit-content position-relative overlap_child">
                              <img
                                width={25}
                                src={`${r?.ContributorLogo}`}
                                className="rounded-full"
                                alt=""
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src =
                                    "/images/msp/default-cert-icon.svg";
                                }}
                              />
                            </div>
                          ) : (
                            <div className="rounded-full w-fit-content h-fit-content position-relative overlap_child">
                              <ImageWithInitials
                                initials={r?.ContributorName[0]}
                                width={25}
                                background={"purple"}
                              />
                            </div>
                          )}
                        </div>
                      );
                    }
                  } else {
                    if (ind > 3) {
                      return;
                    } else {
                      return (
                        <div key={ind + r?.ObjectId} className="d-flex">
                          {r?.ContributorLogo ? (
                            <div className="rounded-full w-fit-content h-fit-content position-relative overlap_child">
                              <img
                                width={25}
                                src={`${r?.ContributorLogo}`}
                                className="rounded-full"
                                alt=""
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src =
                                    "/images/msp/default-cert-icon.svg";
                                }}
                              />
                            </div>
                          ) : (
                            <div className="rounded-full w-fit-content h-fit-content position-relative overlap_child">
                              <ImageWithInitials
                                initials={r?.ContributorName[0]}
                                width={25}
                                background={"purple"}
                              />
                            </div>
                          )}
                        </div>
                      );
                    }
                  }
                })}
              </>
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-revoke" style={{ width: " 280px" }}>
          <div className="f-grey">Contributors</div>
          {currentContributors?.map((r) => {
            return (
              <ContributorInfoDropdown
                key={r?.ObjectId}
                obj={r}
                questionId={questionnaireId}
                refreshData={refreshData}
                isContributorView={isContributorView}
              />
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default RevokeContributorsDropdown;
