import React, { useRef } from "react";
import { useState } from "react";

const Oauth2 = () => {
  const [_width, setWidth] = useState(100);
  const [start, setStart] = useState(0);
  const [parentWidth, setParentWidth] = useState(100);

  function dragOver(x, event, lastX) {
    // var currentX = event.clientX - x;
    // var mouseMove = currentX - lastX;
    //Slide Direction
    // if (currentX > lastX) {
    //   carouselSlide.style.transform = "translateX(" + mouseMove + "px)";
    // } else if (currentX < lastX) {
    //   carouselSlide.style.transform = "translateX(" + mouseMove + "px)";
    // }
    // lastX = currentX;
  }

  //How to resize div with ondrag function in javascript?

  return (
    <div>
      <div>
        <div>dsjhvbsdjhbds cdbd dhk</div>
        <div>b cjdbcdskucfsdkfdf dkfds kfsduvdsjvsud</div>
      </div>
      <div
        className="mt-5 ml-2 position-relative"
        style={{
          width: `${_width}px`,
          backgroundColor: "red",
          minWidth: "100px",
          height: "100px",
        }}
      >
        <img
          alt=""
          onDragStart={(e) => {
            setStart(e.clientX);
            setParentWidth(e.target.parentNode.clientWidth);
          }}
          onDrag={(e) => {
            var x = e.clientX;

            if (x > 0 && start > 0) {
              if (x - start > 0) {
                setWidth(parentWidth + x - start - 5);
              }
              if (x - start < 0) {
                setWidth(parentWidth + x - start - 5);
              }
            }
          }}
          className=""
          src="/images/theme/icons/dropdownarrow.svg"
          style={{
            position: "absolute",
            right: "0",
            bottom: "50%",
            cursor: "col-resize",
          }}
        />
      </div>
    </div>
  );
};

export default Oauth2;
