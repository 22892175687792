import React from "react";
import ContentLoader from "react-content-loader";

const UserPageLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="18%" y="80" rx="3" ry="3" width="10%" height="26" />
      <rect x="18%" y="130" rx="40" ry="40" width="80" height="80" />
      <rect x="25%" y="135" rx="3" ry="3" width="18%" height="30" />

      <rect x="25%" y="180" rx="4" ry="4" width="18%" height="25" />

      <rect x="18%" y="230" rx="4" ry="4" width="60%" height="70" />
      <rect x="18%" y="305" rx="4" ry="4" width="60%" height="70" />

      <rect x="18%" y="380" rx="4" ry="4" width="60%" height="70" />
      <rect x="18%" y="455" rx="4" ry="4" width="60%" height="70" />
      <rect x="18%" y="530" rx="4" ry="4" width="60%" height="70" />
      <rect x="18%" y="605" rx="4" ry="4" width="60%" height="70" />
      <rect x="18%" y="680" rx="4" ry="4" width="60%" height="70" />
      <rect x="18%" y="755" rx="4" ry="4" width="60%" height="70" />
      <rect x="18%" y="830" rx="4" ry="4" width="60%" height="70" />
      {/* <rect x="18%" y="830" rx="4" ry="4" width="60%" height="60" /> */}
    </ContentLoader>
  );
};

export default UserPageLoader;
