import React, { useState, useEffect } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { useAuth } from "../../context/Auth";
import "./billing-modals.scss";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import CompanySettingsDropdown from "../companySettings/companysettingsdrodown/CompanySettingsDropdown";
const AddPaymentModal = ({ show, hideModal, Refreshpage }) => {
  const { authTokens } = useAuth();
  const [page, setPage] = useState("initial");
  const [cardNumber, setCardNumber] = useState("");
  const [monthYear, setMonthYear] = useState("");
  const [CVV, setCVV] = useState("");
  const [cardName, setCardName] = useState("");
  const [country, setCountry] = useState({
    code: "GB",
    country: "United Kingdom",
  });
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [registration, setRegistration] = useState("");
  const [vat, setVat] = useState("");
  const [responsePage, setResponsePage] = useState({});
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({
    cardNumber: "",
    cardMonth: "",
    cardCVV: "",
    cardName: "",
    companyName: "",
    companyAddress: "",
    companyRegistration: "",
    companyVat: "",
  });

  function clearModal() {
    setFormErrors({
      cardNumber: "",
      cardMonth: "",
      cardCVV: "",
      cardName: "",
      companyName: "",
      companyAddress: "",
      companyRegistration: "",
      companyVat: "",
    });
    setVat("");
    setRegistration("");
    setAddress("");
    setCompanyName("");

    setCardName("");
    setMonthYear("");
    setCVV("");
    setCardNumber("");
  }
  useEffect(() => {
    if (show) {
      setPage("initial");
      clearModal();
    }
  }, [show]);

  const month = monthYear.split("/");
  const data = {
    CardName: cardName,
    CardNumber: cardNumber,
    ExpMonth: month[0],
    ExpYear: month[1],
    Cvc: CVV,
    CompanyName: companyName,
    CompanyAddress: address,
    Country: country.code,
    CompanyRegistration: registration,
    VAT: vat,
   
  };
  async function AddCardDetails() {
    if (cardNumber.length < 16) {
      setFormErrors({
        ...formErrors,
        cardNumber: "*Please enter valid card number",
      });

      return;
    }

    if (monthYear.length < 5) {
      setFormErrors({
        ...formErrors,
        cardMonth: "*Please enter valid card expiry",
      });

      return;
    }

    if (monthYear.length > 0) {
      const currentMonth = Number(new Date().getMonth() + 1);
      const last2 = new Date().getFullYear().toString().slice(-2);
      const currentYear = Number(last2);
      const inputMonth = Number(monthYear.split("/")[0]);
      const inputYear = Number(monthYear.split("/")[1]);

      if (
        (inputMonth < currentMonth && inputYear <= currentYear) ||
        inputYear < currentYear
      ) {
        setFormErrors({
          ...formErrors,
          cardMonth: "*Please enter valid card expiry",
        });

        return;
      }
    }

    if (CVV.length < 3) {
      setFormErrors({ ...formErrors, cardCVV: "*Please enter valid CVC" });

      return;
    }
    if (cardName.length == "") {
      setFormErrors({
        ...formErrors,
        cardName: "*Please enter card holder name",
      });

      return;
    }

    if (companyName.length == 0) {
      setFormErrors({
        ...formErrors,
        companyName: "*Please enter company name",
      });

      return;
    }
    if (address.length == 0) {
      setFormErrors({
        ...formErrors,
        companyAddress: "*Please enter company address",
      });

      return;
    }
    // if (country.length == 0) {
    //   CallToast("Please enter country name", false, false, "v2style");
    //   return;
    // }
    if (registration.length == 0) {
      setFormErrors({
        ...formErrors,
        companyRegistration: "*Please enter registration number",
      });

      return;
    }

    if (vat.length == 0) {
      setFormErrors({ ...formErrors, companyVat: "*Please enter vat number" });

      return;
    }
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/AddPaymentMethod?accessKey=${JSON.parse(localStorage.getItem('user_MessageObj'))}`,
      // "https://hg-dev-ui-ac-api.azurewebsites.net/CC_API/AddPaymentMethod",
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authTokens}`, // notice the Bearer before your token
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        console.log(res);
        setResponsePage(res);
        setPage("result");
        if (res.mr.Success) {
          Refreshpage();
        }
      });
  }

  const maskDate = (value) => {
    let v = value.replace(/(\w)\//g, function (str, match) {
      return match[0];
    });
    if (v.length >= 3) {
      return `${v.slice(0, 2)}/${v.slice(2)}`;
    }
    return value;
  };

  const CountryList = [
    {
      country: "Argentina",
      code: "AR",
    },
    {
      country: "Australia",
      code: "AU",
    },
    {
      country: "Austria",
      code: "AT",
    },
    {
      country: "Belgium",
      code: "BE",
    },
    {
      country: "Bolivia",
      code: "BO",
    },
    {
      country: "Brazil",
      code: "BR",
    },
    {
      country: "Bulgaria",
      code: "BG",
    },
    {
      country: "Canada",
      code: "CA",
    },
    {
      country: "Chile",
      code: "CL",
    },
    {
      country: "Colombia",
      code: "CO",
    },
    {
      country: "Costa Rica",
      code: "CR",
    },
    {
      country: "Croatia",
      code: "HR",
    },
    {
      country: "Cyprus",
      code: "CY",
    },
    {
      country: "Czech Republic",
      code: "CZ",
    },
    {
      country: "Denmark",
      code: "DK",
    },
    {
      country: "Dominican Republic",
      code: "DO",
    },
    {
      country: "Egypt",
      code: "EG",
    },
    {
      country: "Estonia",
      code: "EE",
    },
    {
      country: "Finland",
      code: "FI",
    },
    {
      country: "France",
      code: "FR",
    },
    {
      country: "Germany",
      code: "DE",
    },
    {
      country: "Greece",
      code: "GR",
    },
    {
      country: "Hong Kong SAR China",
      code: "HK",
    },
    {
      country: "Hungary",
      code: "HU",
    },
    {
      country: "Iceland",
      code: "IS",
    },
    {
      country: "India",
      code: "IN",
    },
    {
      country: "Indonesia",
      code: "ID",
    },
    {
      country: "Ireland",
      code: "IE",
    },
    {
      country: "Israel",
      code: "IL",
    },
    {
      country: "Italy",
      code: "IT",
    },
    {
      country: "Japan",
      code: "JP",
    },
    {
      country: "Latvia",
      code: "LV",
    },
    {
      country: "Liechtenstein",
      code: "LI",
    },
    {
      country: "Lithuania",
      code: "LT",
    },
    {
      country: "Luxembourg",
      code: "LU",
    },
    {
      country: "Malta",
      code: "MT",
    },
    {
      country: "Mexico ",
      code: "MX",
    },
    {
      country: "Netherlands",
      code: "NL",
    },
    {
      country: "New Zealand",
      code: "NZ",
    },
    {
      country: "Norway",
      code: "NO",
    },
    {
      country: "Paraguay",
      code: "PY",
    },
    {
      country: "Peru",
      code: "PE",
    },
    {
      country: "Poland",
      code: "PL",
    },
    {
      country: "Portugal",
      code: "PT",
    },
    {
      country: "Romania",
      code: "RO",
    },
    {
      country: "Singapore",
      code: "SG",
    },
    {
      country: "Slovakia",
      code: "SK",
    },
    {
      country: "Slovenia",
      code: "SI",
    },
    {
      country: "Spain",
      code: "ES",
    },
    {
      country: "Sweden",
      code: "SE",
    },
    {
      country: "Switzerland",
      code: "CH",
    },
    {
      country: "Thailand",
      code: "TH",
    },
    {
      country: "Trinidad & Tobago",
      code: "TT",
    },
    {
      country: "United Arab Emirates",
      code: "AE",
    },
    {
      country: "United Kingdom",
      code: "GB",
    },
    {
      country: "United States",
      code: "US",
    },
    {
      country: "Uruguay",
      code: "UY",
    },
  ];

  return (
    <Modal
      // show={true}
      show={show}
      dialogClassName="billing-payment-modal"
      className=""
      aria-labelledby="billing-payment-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Header className="text-center">
        <div className="w-100 mb-0 font-bold-14" style={{ fontWeight: "600" }}>
          Add Payment Method
        </div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img src="/images/icons/close.svg" alt="" />
        </span>
      </Modal.Header>
      <Modal.Body>
        {page == "initial" && (
          <>
            <div className="title mb-2 mt-2">Payment Information</div>

            <div className="card-details d-flex align-items-center w-100">
              <img alt="" src="/images/settings-v2/card.svg" />
              <input
                className="card-number input-autofill-issue"
                type="number"
                placeholder="Card Number"
                value={cardNumber}
                onChange={(e) => {
                  if (e.target.value.length == 17) return false;
                  setFormErrors({
                    ...formErrors,
                    cardNumber: "",
                  });
                  setCardNumber(e.target.value);
                }}
              />
              <input
                className="card-month input-autofill-issue"
                value={monthYear}
                onChange={(e) => {
                  if (e.target.value.length == 6) return false;
                  e.target.value = e.target.value
                    .replace(
                      /[^0-9]/g,
                      "" // To allow only numbers
                    )
                    .replace(
                      /^([2-9])$/g,
                      "0$1" // To handle 3 > 03
                    )
                    .replace(
                      /^(1{1})([3-9]{1})$/g,
                      "0$1/$2" // 13 > 01/3
                    )
                    .replace(
                      /^0{1,}/g,
                      "0" // To handle 00 > 0
                    )
                    .replace(
                      /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
                      "$1/$2" // To handle 113 > 11/3
                    );
                  setFormErrors({
                    ...formErrors,
                    cardMonth: "",
                  });
                  setMonthYear(maskDate(e.target.value));
                }}
                type="text"
                placeholder="MM/YY"
                autoComplete="new-password"
              />
              <input
                className="card-cvv input-autofill-issue"
                value={CVV}
                onChange={(e) => {
                  setFormErrors({
                    ...formErrors,
                    cardCVV: "",
                  });
                  if (e.target.value.length == 4) return false;
                  setCVV(e.target.value);
                }}
                type="password"
                placeholder="CVV"
                autoComplete="new-password"
              />
            </div>
            <div className="f-12 f-danger">{formErrors.cardNumber} </div>
            <div className="f-12 f-danger">{formErrors.cardMonth} </div>
            <div className="f-12 f-danger">{formErrors.cardCVV} </div>
            <div className="card-details my-1">
              <input
                type="text"
                className="w-100 input-autofill-issue"
                placeholder="Name on card"
                value={cardName}
                onChange={(e) => {
                  setFormErrors({
                    ...formErrors,
                    cardName: "",
                  });
                  setCardName(e.target.value);
                }}
              />
            </div>
            <div className="f-12 f-danger">{formErrors.cardName} </div>
            <div className="title mt-3">Billing Information</div>
            <div className="card-details my-1">
              <input
                type="text"
                className="w-100 input-autofill-issue"
                placeholder="Company name"
                value={companyName}
                onChange={(e) => {
                  setFormErrors({
                    ...formErrors,
                    companyName: "",
                  });
                  setCompanyName(e.target.value);
                }}
              />
            </div>
            <div className="f-12 f-danger">{formErrors.companyName} </div>
            {/* <Form.Group>
              <InputGroup className="mt-10px grey-select customerStyle border-4">
                <Form.Control
                  style={{ height: "40px" }}
                  as="select"
                  className={"pt-0 pb-0 black-clr"}
                  defaultValue={"GB"}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  {CountryList.map((country) => {
                    return (
                      <option value={country?.code}>{country?.country}</option>
                    );
                  })}
                </Form.Control>
              </InputGroup>
            </Form.Group> */}
            <div className="country-list-dropdown">
              <CompanySettingsDropdown
                fieldId={"code"}
                fieldValue={"country"}
                selected={country}
                placeholder="Choose..."
                //  showid={true}
                onClick={(id, val) => {
                  setCountry({ code: id, country: val });
                }}
                data={CountryList}
              />
            </div>
            <div className="card-details my-1">
              <input
                type="text"
                className="w-100 input-autofill-issue"
                placeholder="Company address"
                value={address}
                onChange={(e) => {
                  setFormErrors({
                    ...formErrors,
                    companyAddress: "",
                  });
                  setAddress(e.target.value);
                }}
              />
            </div>
            <div className="f-12 f-danger">{formErrors.companyAddress} </div>
            <div className="card-details my-1">
              <input
                type="text"
                className="w-100 input-autofill-issue"
                placeholder="Company registration"
                value={registration}
                onChange={(e) => {
                  setFormErrors({
                    ...formErrors,
                    companyRegistration: "",
                  });
                  setRegistration(e.target.value);
                }}
              />
            </div>
            <div className="f-12 f-danger">
              {formErrors.companyRegistration}{" "}
            </div>
            <div className="card-details my-1">
              <input
                type="text"
                className="w-100 input-autofill-issue"
                placeholder="VAT"
                value={vat}
                onChange={(e) => {
                  setFormErrors({
                    ...formErrors,
                    companyVat: "",
                  });
                  setVat(e.target.value);
                }}
              />
            </div>
            <div className="f-12 f-danger">{formErrors.companyVat} </div>
          </>
        )}

        {page == "result" && (
          <div className="w-75 mt-5 mx-auto">
            <div className="d24" />
            <div className="d24" />
            <div className="d24" />

            <>
              <div className="text-center">
                {responsePage &&
                  responsePage.ResultIcon &&
                  responsePage.ResultIcon.search("RedCircle") > 0 && (
                    <img src="/images/rfail.svg" alt="" />
                  )}

                {responsePage &&
                  responsePage.ResultIcon &&
                  responsePage.ResultIcon.search("GreenCircle") > 0 && (
                    <img src="/images/rsuccess.svg" alt="" />
                  )}

                {responsePage &&
                  responsePage.status &&
                  responsePage.status == "succeeded" && (
                    <img src="/images/rsuccess.svg" alt="" />
                  )}
                {/* {isError && <img src="/images/rfail.svg" alt="" />} */}

                <p
                  className="txtfail mt-4"
                  style={{
                    color: `${
                      responsePage && responsePage.ResultColour
                        ? responsePage.ResultColour
                        : ""
                    }`,
                  }}
                >
                  {responsePage &&
                    responsePage.ResultText &&
                    responsePage.ResultText}
                </p>
              </div>
              <div className="responseList mt-4">
                <p>
                  {responsePage &&
                    responsePage.DescriptionText &&
                    responsePage.DescriptionText}
                  {responsePage && responsePage.message}
                  {responsePage && responsePage.Message && responsePage.Message}
                </p>
                {/* {responsePage && responsePage.status == "succeeded" && (
                  <p>Thanks for buying {service && service.Title}</p>
                )} */}

                <Button
                  onClick={() => {
                    if (
                      responsePage &&
                      responsePage.ButtonText == "Try Again"
                    ) {
                      setPage("initial");
                    } else {
                      hideModal();
                    }
                  }}
                  className="w-100 blubtn mt-4"
                >
                  {responsePage && responsePage.ButtonText}
                </Button>
                <div className="d24" />
                <div className="d24" />
                <div className="d24" />
                <div className="d24" />
                <div className="mb-5" />
              </div>
            </>
          </div>
        )}
      </Modal.Body>

      {page == "initial" && (
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button className="add-cancel-btn" onClick={hideModal}>
              Cancel
            </Button>
            <Button
              className="add-paymnt-btn"
              disabled={loading}
              onClick={() => {
                if (page == "initial") {
                  AddCardDetails();
                } else {
                  setPage("initial");
                }
              }}
            >
              {loading ? (
                <>
                  <Spinner
                    size="sm"
                    animation="border"
                    role="status"
                    className="mx-2"
                    variant="light"
                  />
                  Adding...
                </>
              ) : (
                <>{page == "initial" ? "Add Payment method" : "Go back"}</>
              )}
            </Button>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default AddPaymentModal;
