import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Button, Spinner, Dropdown } from "react-bootstrap";
import ImageCropperModal from "./personal-profile-modals/ImageCropperModal";
import UserDetailModal from "./personal-profile-modals/UserDetailModal";

import "./personal-profile.scss";
import { useDispatch, useSelector } from "react-redux";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { getAPIData, postData, postRequest } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { setuser } from "../../../redux/userState/actions";
// import CompanySettingsDropdown from "../companySettings/companysettingsdrodown/CompanySettingsDropdown";
import { useDb } from "../../context/db";
import { UpdateRoleModal } from "../companySettings/companySettingsModals/UpdateRoleModal";
import { WhiteLoader } from "../../Theme/APILoaders";
import { useHistory } from "react-router-dom";
import { TrimString } from "../../../Utils/StringTrimmer";

const PersonalProfileV2 = () => {
  const { setdbObject } = useDb();
  const history = useHistory();
  const [showOtherRoleModal, setShowOtherRoleModal] = useState(false);
  const { authTokens, setAuthTokens, setUserType } = useAuth();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.user?.userDetail);

  const [notifSetting, setNotifSetting] = useState(false);
  const [disableResetPassword, setDisableResetPassword] = useState(true);
  const [loading, setloading] = useState(false);
  const [mfaReset, setMfaReset] = useState(false);
  const [DisableMFAReset, setDisableMFAReset] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [userObjId, setUserObjId] = useState("");
  const [role, setRole] = useState();
  const [userData, setUserData] = useState({});
  const [removingPhoto, setRemovingPhoto] = useState(false);
  const [editOtherRole, setEditOtherRole] = useState(false);
  const [otherRole, setOtherRole] = useState("");
  const [otherRoleLoading, setOtherRoleLoading] = useState(false);
  const [deleteCode, setDeleteCode] = useState("");

  useEffect(() => {
    // window.analytics.page("settings V2 profile page", "Profile Page");
    getNotificationSettings();
    getMFAstatus();

    setRole(userDetails?.Role);
  }, []);

  async function getMFAstatus() {
    const response = await getAPIData(102, authTokens);
    if (response?.mr?.Success) {
      setUserData(response);

      dispatch(setuser({ FullName: response?.FullName }));
    }
  }

  async function makeAPICall(num) {
    let data = await getAPIData(num, authTokens, {
      // OptionEnum1: "99",
      // OptionEnum2: "99",
    });
    if (data.authToken !== undefined) {
      setAuthTokens(data.authToken);
      setdbObject();
      data = await getAPIData(num, data.authToken);
    } else {
      setdbObject(data);
      // console.log(data?.UserDetail);
      dispatch(setuser(data?.UserDetail));
    }
  }

  async function getNotificationSettings() {
    const response = await getAPIData(103, authTokens);
    setNotifSetting(response.NotificationSettings);
    setUserObjId(response.objId_UserSetting);
  }

  async function resetData(id) {
    const response = await getAPIData(id, authTokens, {
      optionEnum1: userObjId,
    });

    if (response.Success) {


      if(Number(id) === 107){
        CallToast("Password reset email sent", false, false, "v2style");}
      // CallToast(response?.Message, false, false);
    } else {
      CallToast("Something went wrong", false);
    }
  }

  async function handleSave(notifName, notifValue) {
    notifValue = notifValue.replace(/<\/?[^>]+>/gi, "");
    if (notifName == "Fullname") {
    }
    const inputs = {
      SettingOwner: notifSetting.SettingOwner,
      OwnerType: notifSetting.OwnerType,
      NotificationName: notifName,
      NotificationValue: notifValue,
    };
    if (notifName == "Password_Reset") {
      setloading(true);
    }
    if (notifName == "MFA_Reset") {
      setMfaReset(true);
      setDisableMFAReset(false);
    }

    if (notifName == "Password_Reset") {
      setDisableResetPassword(false);
    }
    if (notifValue !== 1) {
    }
    setShowToast(true);
    await postData(authTokens, "SubmitNotificationSettings", inputs)
      .then((response) => {
        if (response.Success == true || response.FullName == notifValue) {
          dispatch(setuser(response));
          setloading(false);
          setMfaReset(false);
        } else {
          setloading(false);
        }
      })
      .catch(() => {
        setloading(false);
      });
  }

  async function RemovePhoto() {
    setRemovingPhoto(true);
    await getAPIData("258", authTokens).then((response) => {
      setRemovingPhoto(false);
      if (response.Success) {
        window.localStorage.setItem(
          "user_MessageObj",
          JSON.stringify(response?.Message)
        );
        dispatch(setuser({ Avatar: "No URL" }));
        CallToast("Photo removed", false, false, "v2style");
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  const fileUpload = useRef();
  const [detailModal, setdetailModal] = useState(false);
  const [cropperModal, setCropperModal] = useState(false);
  const [type, setType] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [userImage, setUserImage] = useState(
    "https://st4.depositphotos.com/1012074/20946/v/450/depositphotos_209469984-stock-illustration-flat-isolated-vector-illustration-icon.jpg"
  );

  const setImage = (image) => {
    setUserImage(image);
  };

  const showCropperModal = () => {
    setCropperModal(true);
  };

  const hideCropperModal = () => {
    setCropperModal(false);
  };

  const hideDetailModal = () => setdetailModal(false);
  const showAsNameModal = () => {
    setType("name");
    setdetailModal(true);
  };
  const showAsEmailModal = () => {
    setType("email");
    setdetailModal(true);
  };

  function ChangeRole(role, roleType = "", element = "") {
    return new Promise((resolve, reject) => {
      postRequest(authTokens, "UpdateUserRole", {
        Role: role,
        OtherRole: roleType,
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      }).then((response) => {
        setOtherRoleLoading(false);
        if (element) {
          element.click();
        }
        if (response.Success) {
          resolve(response);
          dispatch(setuser({ Role: role }));
          CallToast("Role updated successfully", false, false, "v2style");
          window.localStorage.setItem(
            "userType",
            JSON.stringify(response?.EntityId)
          );
          window.localStorage.setItem(
            "user_MessageObj",
            JSON.stringify(response?.Message)
          );
          setUserType(response?.EntityId);
          if (role == "CEO") {
            makeAPICall(0);
          } else if (role == "CTO") {
            makeAPICall(1);
          } else if (role == "CFO") {
            makeAPICall(2);
          } else {
            makeAPICall(0);
          }
        } else {
          reject(response);
          CallToast("Something went wrong", false, false, "v2style");
        }
      });
    });
  }
  const [deleteAccountError, setDeleteAccountError] = useState("");
  const [deleting, setDeleting] = useState(false);
  const [showDeleteRow, setShowDeleteRow] = useState(false);
  // Function to delte account
  async function DeleteAccount() {
    if (deleteCode === "") {
      setDeleteAccountError("dfdsf");
      return;
    }
    setDeleting(true);
    await getAPIData("1000", authTokens, {
      optionEnum1: userDetails?.objId_UserSettings,
      optionEnum2: deleteCode,
    })
      .then((response) => {
        setDeleting(false);
        if (response?.Success) {
          setDeleteCode("");
          CallToast("Account deleted successfully", false, false, "v2style");
          setTimeout(() => {
            history.push("/logout");
          }, 2000);
        } else {
          setDeleteAccountError("dfdsf");
          CallToast(response?.Message, false, false, "v2style");
        }
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, "v2style");
        setDeleting(false);
      });
  }
  return (
    <div className="personal-profile-settings-v2 ">
      <h3>Profile settings</h3>

      <div className="d-flex justify-content-between align-items-center">
        <img
          className="rounded-circle border-grey camera-icon cursor-pointer"
          src={
            userDetails.Avatar == "No URL"
              ? "/images/settings-v2/profile-thumbnail.svg"
              : userDetails.Avatar
          }
          alt=""
          // onClick={() => setShowUploadModal(true)}
          // width="79"
        />
        <input
          type="file"
          accept="image/*"
          ref={fileUpload}
          className="d-none"
          onChange={(e) => {
            if (e.target.files[0]) {
              const reader = new FileReader();
              reader.readAsDataURL(e.target.files[0]);

              reader.onload = function () {
                setSelectedImage(reader.result);
                showCropperModal();
              };
            }
            // setSelectedImage(e.target.files[0]);
            // showCropperModal();
          }}
        />

        {userDetails.Avatar == "No URL" ? (
          <Button
            className="profile-upload-btn"
            onClick={() => {
              fileUpload.current.click();
            }}
          >
            Upload photo
          </Button>
        ) : (
          <div>
            <Button
              className="change-photo-btn"
              onClick={() => {
                fileUpload.current.click();

                // CallToast("Email has been Updated", false, false);
              }}
            >
              Change photo
            </Button>
            <Button
              className="remove-photo-btn"
              onClick={() => {
                RemovePhoto();
              }}
            >
              {removingPhoto ? (
                <>
                  <Spinner
                    size="sm"
                    className=" mr-2"
                    animation="border"
                    variant="secondary"
                  />
                  Removing...
                </>
              ) : (
                "Remove photo"
              )}
            </Button>
          </div>
        )}
      </div>
      <hr />

      <div className="personal-info">
        <h4>Personal Info</h4>
        <div className="user-details">
          <Row>
            <Col xs={12} onClick={showAsNameModal} className="pointer">
              <div className="name-detail">
                <div className="name-label">Name</div>
                <div>
                  <span className="name">{userDetails?.FullName} </span>
                  <img
                    alt=""
                    src="/images/chevron-right.svg"
                    className="details-arrow"
                  />
                </div>
              </div>
              <hr className="m-0" />
            </Col>

            <Col
              xs={12}
              // onClick={showAsEmailModal}
              className="pointer"
            >
              <div className="name-detail">
                <div className="name-label">Email</div>
                <div>
                  <span className="name mr-2">{userDetails?.UserName} </span>
                  <img
                    alt=""
                    src="/images/chevron-right.svg"
                    className="details-arrow hidden d-none"
                  />
                </div>
              </div>
              <hr className="m-0" />
            </Col>
            <Col xs={12}>
              <div className="name-detail">
                <div className="name-label">Role</div>
                <div className="role-drop">
                  <Dropdown
                    className="hg-white-dropdown"
                    onToggle={() => {
                      setEditOtherRole(false);
                      setOtherRole("");
                      setOtherRoleLoading(false);
                    }}
                  >
                    <Dropdown.Toggle className="custom-dropdown-toggle">
                      {role === "Other"
                        ? TrimString(userDetails?.OtherRoleDesc ?? "Other", 15)
                        : role}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div
                        className="p-2 pl-3 my-1 pointer f-black f-500"
                        onClick={(e) => {
                          ChangeRole("CEO");
                          setRole("CEO");
                          e.target.closest(".hg-white-dropdown").click();
                        }}
                      >
                        CEO
                        {role == "CEO" && (
                          <img
                            alt=""
                            src="/images/settings-v2/black-tick.svg"
                            style={{
                              float: "right",
                              marginRight: "10px",
                              paddingTop: "3px",
                            }}
                          />
                        )}
                      </div>
                      <div
                        className="p-2 pl-3 my-1 pointer f-black f-500"
                        onClick={(e) => {
                          ChangeRole("CFO");
                          setRole("CFO");
                          e.target.closest(".hg-white-dropdown").click();
                        }}
                      >
                        CFO
                        {role == "CFO" && (
                          <img
                            alt=""
                            src="/images/settings-v2/black-tick.svg"
                            style={{
                              float: "right",
                              marginRight: "10px",
                              paddingTop: "3px",
                            }}
                          />
                        )}
                      </div>
                      <div
                        className="p-2 pl-3 my-1 pointer f-black f-500"
                        onClick={(e) => {
                          ChangeRole("CTO");
                          setRole("CTO");
                          e.target.closest(".hg-white-dropdown").click();
                        }}
                      >
                        CTO
                        {role == "CTO" && (
                          <img
                            alt=""
                            src="/images/settings-v2/black-tick.svg"
                            style={{
                              float: "right",
                              marginRight: "10px",
                              paddingTop: "3px",
                            }}
                          />
                        )}
                      </div>
                      <div className="p-2 pl-3 my-1 pointer f-black f-500">
                        {editOtherRole ? (
                          <div className="d-flex algn-items-center">
                            <div className="d-flex align-items-center other-role-editor">
                              <input
                                style={{
                                  maxWidth: "124px",
                                }}
                                value={otherRole}
                                onChange={(e) => setOtherRole(e.target.value)}
                              />
                              <Button
                                className="d-flex align-items-center"
                                onClick={(e) => {
                                  if (otherRole == "") return;
                                  setOtherRoleLoading(true);
                                  var element =
                                    e.target.closest(".hg-white-dropdown");
                                  ChangeRole("Other", otherRole, element);
                                  setRole("Other");
                                }}
                              >
                                {otherRoleLoading && <WhiteLoader width={11} />}
                                <div>Save</div>
                              </Button>
                            </div>
                            <img
                              alt=""
                              src="/images/attack-surface/small-cross.svg"
                              className="mx-1 pointer d-flex align-items-center"
                              onClick={() => {
                                setEditOtherRole(false);
                                setOtherRole("");
                              }}
                            />
                          </div>
                        ) : (
                          <div onClick={() => setEditOtherRole(true)}>
                            Other
                            {userDetails?.OtherRoleDesc !== ""
                              ? ` (${TrimString(
                                  userDetails?.OtherRoleDesc,
                                  6
                                )})`
                              : ""}
                            {role == "Other" && (
                              <img
                                alt=""
                                src="/images/settings-v2/black-tick.svg"
                                style={{
                                  float: "right",
                                  marginRight: "10px",
                                  paddingTop: "3px",
                                }}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* <CompanySettingsDropdown
                    fieldId={"id"}
                    fieldValue={"category"}
                    selected={role}
                    placeholder="Choose..."
                    //  showid={true}
                    onClick={(id, val) => {
                      if (role.category == val) return;
                      if (val == "Other") {
                        setShowOtherRoleModal(true);
                        return;
                      }
                      ChangeRole(val);
                      setRole({ id, category: val });
                    }}
                    data={[
                      { id: 0, category: "CEO" },
                      { id: 1, category: "CTO" },
                      { id: 2, category: "CFO" },
                      { id: 3, category: "Other" },
                    ]}
                  /> */}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="security-wrapper">
        <h4>Security</h4>
        <div className="security-details">
          <Row>
            <Col xs={12}>
              <div className="d-flex justify-content-between align-items-center reset-password-wrapper">
                <div>
                  <div className="textBlackBold">Reset MFA</div>
                  <span className="greyText">
                  Reset MFA for this user
                  </span>
                </div>
                <Button
                  disabled={!userData?.EnableMFAReset}
                  className={`align-self-center ${
                    "personalDetail.EnableMFAReset && DisableMFAReset"
                      ? "light-red-btn"
                      : "btn-disable"
                  }     ${
                    !userData?.EnableMFAReset ? "disabled-btn" : ""
                  }           `}
                  style={{ minWidth: "70px" }}
                  onClick={() => {
                    resetData("108");
                  }}
                >
                  {/* Reset */}
                  {false ? <Spinner size="sm" animation="border" /> : "Reset"}
                </Button>
              </div>
              <hr className="m-0" />
            </Col>
            <Col xs={12}>
              <div className="d-flex justify-content-between align-items-center reset-password-wrapper">
                <div>
                  <div className="textBlackBold">Reset Password</div>
                  <span className="greyText">
                  Reset the password for this user
                  </span>
                </div>
                <Button
                  className={`align-self-center ${
                    "disableResetPassword" ? "light-red-btn" : "btn-disable"
                  }`}
                  style={{ minWidth: "50px" }}
                  onClick={() => {
                    resetData("107");
                  }}
                >
                  {/* {loading ? <Spinner size="sm" animation="border" /> : "Reset"} */}
                  Reset
                  {/* Reset */}
                </Button>
              </div>
              <hr className="m-0" />
            </Col>
            <Col xs={12}>
              <div className="d-flex justify-content-between align-items-center reset-password-wrapper">
                <div>
                  <div className="textBlackBold">Delete my account</div>
                  <span className="greyText">You will need your 2FA code</span>
                </div>
                <Button
                  className={`align-self-center ${
                    "disableResetPassword" ? "light-red-btn" : "btn-disable"
                  }`}
                  style={{ minWidth: "50px" }}
                  onClick={() => {
                    setShowDeleteRow(true);
                  }}
                >
                  Delete
                </Button>
              </div>
              {showDeleteRow && <hr className="m-0" />}
            </Col>
            {/* To enter 2FA code to delete account */}
            <Col
              xs={12}
              className={`code-input ${showDeleteRow ? "open" : ""}`}
            >
              <div className="d-flex justify-content-between align-items-center reset-password-wrapper">
                <div>
                  <div className="textBlackBold">
                    Enter your 2FA code and click confirm
                  </div>
                  <span className="greyText">This can not be undone !</span>
                </div>
                <div className="d-flex align-items-center">
                  <input
                    type="text"
                    value={deleteCode}
                    onChange={(e) => {
                      setDeleteAccountError("");
                      setDeleteCode(e.target.value);
                    }}
                    className={`pl-1 border-none bg-white mr-3 radius-4 ${
                      deleteAccountError ? "input-error" : ""
                    }`}
                    style={{
                      height: "26px",
                    }}
                    autoFocus
                  />
                  <Button
                    className={`d-flex align-items-center ${
                      "disableResetPassword" ? "light-red-btn" : "btn-disable"
                    }`}
                    style={{ minWidth: "50px" }}
                    onClick={() => {
                      DeleteAccount();
                    }}
                  >
                    {deleting ? (
                      <div className="d-flex align-items-center">
                        <WhiteLoader width={12} variant={"danger"} />
                        Confirming deletion..
                      </div>
                    ) : (
                      "Confirm account deletion"
                    )}
                  </Button>
                </div>
              </div>
              {/* <hr className="m-0" /> */}
            </Col>
          </Row>
        </div>
        <div className="p-5"></div>
      </div>
      <UserDetailModal
        type={type}
        show={detailModal}
        hideModal={hideDetailModal}
        handleSave={handleSave}
        refreshData={getMFAstatus}
      />
      <ImageCropperModal
        show={cropperModal}
        hideModal={hideCropperModal}
        profileImage={selectedImage}
        setProfileImage={setImage}
      />
      <UpdateRoleModal
        show={showOtherRoleModal}
        hideModal={() => setShowOtherRoleModal(false)}
        changeRole={ChangeRole}
        setRole={setRole}
      />
    </div>
  );
};

export default PersonalProfileV2;
