import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../context/Auth";

function PrivateRoute({ component: Component, ...rest }) {
    const { auth,authTokens } = useAuth();
    const existingTokens = JSON.parse(localStorage.getItem("tokens"));
// console.log("existingTokens",existingTokens,"existingTokens")
// console.log("AUTHexistingTokens",authTokens,"AUTHexistingTokens")
    return (
      <Route
        {...rest}
        render={(props) =>
         ( existingTokens  ) ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  redirectUser: true,
                  redirectUrl: `${window.location.pathname}${window.location.search}`,
                },
              }}
            />
          )
        }
      />
    );
}

export default PrivateRoute;