import React, { Fragment, useState, useEffect } from "react";
import {
  Table,
  Button,
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
  Modal,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Tab,
  Tabs,
  Card,
  Dropdown,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FiMoreHorizontal } from "react-icons/fi";
import { BiErrorCircle, BiCheck } from "react-icons/bi";
import BillingInfo from "./BillingInfo";
import TicketsView from "../../Support/TicketsView/TicketsView";
import IncidentResponseForm from "../../IncidentResponseForm/IncidentResponseForm";
import ParentChildModal from "../../ModalDynamic/ParentChildModal";
import AddPaymentMethod from "../Subscriptions/AddPaymentMethod";
import RemovePaymentMethod from "../Subscriptions/RemovePaymentMethod";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import CancelSubscription from "./CancelSubscription";
import UpgradePremium from "../Subscriptions/UpgradePremium";
import ToastMessage from "../../ToastMessage";
import ContentLoader from "react-content-loader";
import { FiCheck, FiX } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import CircularProgressBar from "../../CircularProgressBar";

const Billing = (props) => {
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [showAddPaymentModal, setAddPaymentModal] = useState(false);
  const [supportCounter, setSupportCounter] = useState(0);
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [showRemovePaymentModal, setRemovePaymentModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { authTokens, setAuthTokens } = useAuth();
  const [billingData, setBillingdata] = useState([]);
  const [paymentMethodDetail, setPaymentMethodDetail] = useState([]);
  const [showCancelSubscription, setCancelSubscription] = useState(false);
  const [removePaymentData, setRemovePaymentData] = useState({});
  const [subPlan, setSubPlan] = useState({});
  const [integrationPlan, setIntegrationPlan] = useState({});
  const [responseData, setResponseData] = useState({});
  const [removeId, setRemoveId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [paymentCardData, setPaymentCardData] = useState();
  const [isLoadingBilling, setIsLoadingBilling] = useState(true);
  const [billingSubId, setBillingSubId] = useState(false);
  const [cancelSubscrition, setCancelSubscrition] = useState();
  const [selectedPaymentId, setSelectedPaymentId] = useState(false);
  const history = useHistory();
  const [compData, setcompData] = useState();
  const [toastMsg, setToastMsg] = useState({
    show: false,
    msg: "Removed Successfully",
  });
  const [upgradeModalData, setUpgradeModalData] = useState({
    show: false,
    isResponse: false,
  });
  const [title, setTitle] = useState();

  useEffect(() => {
    BillingDetail();
  }, []);

  const paymentCardInfo = async (subscriptionId) => {
    setIsLoadingBilling(true);
    setShowBillingModal(true);
    setBillingSubId(subscriptionId);
    const response = await getAPIData("200", authTokens, {
      optionEnum1: subscriptionId,
    });
    setPaymentCardData(response);
    if (response.mr.Success == true) {
      setIsLoadingBilling(false);
      setSelectedPaymentId(response.SelectedPaymentId);
    }
  };

  useEffect(() => {
    // hang on msg after 5 sec if there is no response from  API
    var time = setInterval(() => {
      if (!compData) {
        setShowToast(true);
        toast.dark(
          <span className="d-flex">
            <FiCheck style={{ marginRight: "5px" }} />
            <div className="text-center">
              Hang on, it's taking longer than usual to process your request,
              please wait...
            </div>
            <span className="float-right">
              <CircularProgressBar durationInSec="30" />
            </span>
          </span>
        );
        return () => setShowToast(false), clearInterval(time);
      }
    }, 9000);
    if (compData) {
      return () => setShowToast(false), clearInterval(time);
    }
    return () => clearInterval(time);
  }, [compData]);

  async function BillingDetail() {
    const response = await getAPIData("192", authTokens, {
      optionEnum1: 0,
    });
    if (response.mr.Success == true) {
      setcompData(true);
      setShowToast(false);
      setResponseData(response);
      setTitle(response?.Title);
      setBillingdata(response.StripeInvoiceList);
      setPaymentMethodDetail(response.PaymentMethodList);
      setSubPlan(response.SubPlan);
      setIntegrationPlan(response.IntegrationPlan);
      setCountryList(
        response.CountryList || [{ country: "United Kingdom", code: "GB" }]
      );
    }
    setIsLoading(false);
  }
  function setSupportState(event) {
    event.preventDefault();
    setSupportModalOpen(true);
  }

  async function CallBillingDetail(val) {
    setIsLoading2(true);
    let req = 0;
    switch (val) {
      case "subscriptions":
        req = 1;
        break;
      case "rewards":
        req = 2;
        break;
      case "billing":
        req = 3;
        break;
      case "notifications":
        req = 4;
        break;
    }
    const response = await getAPIData("192", authTokens, {
      optionEnum1: req,
    });
    if (response.mr.Success == true) {
      setResponseData(response);
      setTitle(response?.Title);
      setBillingdata(response.StripeInvoiceList);
      setPaymentMethodDetail(response.PaymentMethodList);
      setSubPlan(response.SubPlan);
      setIntegrationPlan(response.IntegrationPlan);
      setIsLoading2(false);
    } else {
      setIsLoading2(false);
    }
  }

  async function getCard(id) {
    const getCard = await getAPIData(195, authTokens, {
      optionEnum1: id,
    });
    return getCard;
  }

  const removePaymentMethod = async (id) => {
    setRemoveId(id);
    //  props.setKey("subscriptions");
    setRemovePaymentModal(true);
    const res = await getCard(id);
    setRemovePaymentData(res);
  };

  const cancelSubscription = async (id) => {
    setCancelSubscription(true);
    const response = await getAPIData("206", authTokens, {
      optionEnum1: id,
    });

    if (response.mr.Success == true) {
      setCancelSubscrition(response);
    }
    setIsLoadingBilling(false);
  };

  const cancelSubscriptionApi = async () => {
    setIsLoadingBilling(true);
    const id = cancelSubscrition.SubscriptionId || 0;
    const response = await getAPIData("207", authTokens, {
      optionEnum1: id,
    });

    if (response.Success) {
      toast.dark(
        <span className="d-flex">
          <FiCheck style={{ marginRight: "5px" }} />
          <div className="text-left w-100">Cancel Successfully</div>
          <span className="float-right">
            <CircularProgressBar durationInSec="30" />
          </span>
        </span>
      );
      setShowToast(true);
      setCancelSubscription(false);
    } else {
      toast.dark(
        <span className="d-flex">
          <FiX style={{ marginRight: "5px", color: "#f36060" }} />
          <div className="text-left w-100" style={{ color: "#f36060" }}>
            Something went wrong!
          </div>
          <span className="float-right">
            <CircularProgressBar durationInSec="30" />
          </span>
        </span>
      );
      setShowToast(true);
    }
    setIsLoadingBilling(false);
  };

  const htmlParser = (data) => {
    const content = data.replace("<b>Premium Plan.</b>", "");
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
  };

  const htmlParserPacks = (data) => {
    const content = data.slice(0, -20);
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
  };

  const BillingContextMenu = ({ subscriptionId }) => {
    return (
      <div
        style={{ float: "right" }}
        className="sideBarDropdown complianceManagerDropdown "
      >
        <Dropdown>
          <Dropdown.Toggle className="pr-0">
            <img src="/images/subMenu.svg" />
          </Dropdown.Toggle>

          <Dropdown.Menu className="BillingInfo">
            <span className="greyText pl-3 mt-3">Actions</span>

            <Dropdown.Item
              className="mt-2"
              onClick={() => {
                paymentCardInfo(subscriptionId);
              }}
            >
              <img src="/images/cardIcon.svg" className="pr-2" />
              Change Payment Card
            </Dropdown.Item>

            <hr />
            <Dropdown.Item
              type="button"
              className="redText"
              onClick={() => cancelSubscription(subscriptionId)}
            >
              <img src="/images/cancel.svg" className="pr-2" /> Cancel
              Subscription
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  function Tabdata() {
    if (isLoading2) {
      return (
        <div className="text-center mb-4" style={{ marginTop: "" }}>
          <ContentLoader viewBox="0 0 380 300">
            {/* Only SVG shapes */}
            <rect x="5" y="17" rx="1" ry="1" width="50" height="3" />
            <rect x="5" y="22" rx="1" ry="1" width="360" height="3" />
            <rect x="5" y="40" rx="1" ry="1" width="50" height="3" />
            <rect x="5" y="45" rx="1" ry="1" width="360" height="3" />
            <rect x="5" y="68" rx="1" ry="1" width="360" height="3" />
            <rect x="5" y="63" rx="1" ry="1" width="50" height="3" />
            <rect x="5" y="96" rx="1" ry="1" width="360" height="3" />
            <rect x="5" y="91" rx="1" ry="1" width="50" height="3" />
            <rect x="5" y="119" rx="1" ry="1" width="360" height="3" />
            <rect x="5" y="114" rx="1" ry="1" width="50" height="3" />
            <rect x="5" y="142" rx="1" ry="1" width="360" height="3" />
            <rect x="5" y="137" rx="1" ry="1" width="50" height="3" />
            <rect x="5" y="165" rx="1" ry="1" width="360" height="3" />
            <rect x="5" y="160" rx="1" ry="1" width="50" height="3" />
            <rect x="5" y="192" rx="1" ry="1" width="360" height="3" />
            <rect x="5" y="188" rx="1" ry="1" width="50" height="3" />
            <rect x="5" y="215" rx="1" ry="1" width="50" height="3" />
            <rect x="5" y="220" rx="1" ry="1" width="360" height="3" />
            <rect x="5" y="243" rx="1" ry="1" width="50" height="3" />
            <rect x="5" y="248" rx="1" ry="1" width="360" height="3" />
            <rect x="5" y="243" rx="1" ry="1" width="50" height="3" />
            <rect x="5" y="248" rx="1" ry="1" width="360" height="3" />
            <rect x="5" y="266" rx="1" ry="1" width="50" height="3" />
            <rect x="5" y="271" rx="1" ry="1" width="360" height="3" />
          </ContentLoader>
        </div>
      );
    }

    return (
      <Table
        className="settings_table cybercare-table2  billingTable"
        borderless
      >
        <thead>
          <tr className="billingTab">
            <th>Name</th>
            <th>Status</th>
            <th>Discount</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody className="tableData">
          {billingData &&
            billingData.length > 0 &&
            billingData.map((detail, index) => (
              <tr className="crsrpoint">
                <td>
                  <p className="textGrey mb-0 greyText">{detail.Name}</p>
                  <p>{detail.Date}</p>
                </td>
                <td>
                  <p style={{ color: detail.StatusColor }}>{detail.Status}</p>
                </td>
                <td className="txt-blue">{detail.Discount}</td>
                <td>{detail.Amount}</td>
                <td>
                  {!detail.PrintURL ? (
                    <img
                      src="/images/complianceManager/printIcon.svg"
                      height="37px"
                    />
                  ) : (
                    <a href={`${detail.PrintURL}`} target="_blank">
                      <img
                        src="/images/complianceManager/printIcon.svg"
                        height="37px"
                      />
                    </a>
                  )}
                </td>
              </tr>
            ))}
          {responseData.ExpandButtonText && (
            <Button
              className={`btn-grey textBlackBold mt8px ${
                billingData && billingData.length == 0 ? "d-none" : ""
              }  `}
            >
              {responseData.ExpandButtonText}
            </Button>
          )}
        </tbody>
      </Table>
    );
  }

  return (
    <Fragment>
      <img
        src={`/images/visa.svg`}
        style={{ position: "absolute", opacity: 0 }}
        alt=""
      />
      {showToast && (
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
        />
      )}
      {isLoading ? (
        <ContentLoader
          style={{ marginTop: "-80px" }}
          speed={2}
          width={"100%"}
          height={"100%"}
          viewBox="0 -80 1500 1000"
          backgroundColor="#f1f2f7e8"
          foregroundColor="#ebecf1"
        >
          <rect
            x="15"
            y="0"
            rx="15"
            ry="15"
            height={660}
            width={300}
            style={{ height: "660", width: "65%" }}
          />

          <rect
            x="1020"
            y="0"
            rx="15"
            ry="15"
            height={200}
            width={300}
            style={{ height: "200", width: "30%" }}
          />
          <rect
            x="1020"
            y="230"
            rx="15"
            ry="15"
            height={200}
            width={300}
            style={{ height: "200", width: "30%" }}
          />

          <rect
            x="1020"
            y="460"
            rx="15"
            ry="15"
            height={200}
            width={300}
            style={{ height: "200", width: "30%" }}
          />
        </ContentLoader>
      ) : (
        <Row>
          {toastMsg.show && <ToastMessage message={toastMsg.msg} />}
          <BillingInfo
            isOpen={showBillingModal}
            data={paymentCardData}
            subId={billingSubId}
            isLoading={isLoadingBilling}
            setIsLoading={setIsLoadingBilling}
            setIsOpen={setShowBillingModal}
            selectedPaymentId={selectedPaymentId}
            setSelectedPaymentId={setSelectedPaymentId}
          />
          <AddPaymentMethod
            isEdit={isEdit}
            countryList={countryList}
            billingApi={BillingDetail}
            isOpen={showAddPaymentModal}
            setIsOpen={setAddPaymentModal}
            setToastMsg={setToastMsg}
            toastMsg={toastMsg}
          />
          <RemovePaymentMethod
            cardId={removeId}
            isOpen={showRemovePaymentModal}
            setIsOpen={setRemovePaymentModal}
            data={removePaymentData}
            paymentList={paymentMethodDetail}
            setPaymentList={setPaymentMethodDetail}
            setToastMsg={setToastMsg}
            setToastRemove={props.setToast}
          />
          <CancelSubscription
            isOpen={showCancelSubscription}
            setIsOpen={setCancelSubscription}
            data={cancelSubscrition}
            isLoading={isLoadingBilling}
            cancelSubscription={cancelSubscriptionApi}
          />
          <UpgradePremium
            data={upgradeModalData}
            setData={setUpgradeModalData}
          />
          <Col md={8}>
            <Fragment>
              <div className="settings_container">
                <h3 className="m-0 heading3" style={{ paddingLeft: "17px" }}>
                  {title}
                </h3>
                {billingData && billingData.length == 0 ? (
                  <p className="text-center pb-0  pb-4 pr-4 greyText font16px">
                    <hr className="mb-5 ml-3" />
                    You have no payments yet{" "}
                  </p>
                ) : (
                  <Tabs
                    className="integration_tabs billingTab integration_tabs2"
                    //   activeKey={activeTab}
                    transition={false}
                    style={{ paddingTop: "17px" }}
                    onSelect={(k) => {
                      CallBillingDetail(k);
                    }}
                  >
                    <Tab eventKey="general" title="All">
                      <div className="">
                        <Tabdata />
                      </div>
                    </Tab>
                    <Tab eventKey="subscriptions" title="Subscriptions">
                      <div className="">
                        <Tabdata />
                      </div>
                    </Tab>
                    <Tab eventKey="rewards" title="Integration Packs">
                      <div className="">
                        <Tabdata />
                      </div>
                    </Tab>
                    <Tab eventKey="billing" title="Pro Services">
                      <div className="">
                        <Tabdata />
                      </div>
                    </Tab>
                    <Tab
                      eventKey="notifications"
                      title="Managed Security Packs"
                    >
                      <div className="">
                        <Tabdata />
                      </div>
                    </Tab>
                  </Tabs>
                )}
              </div>
            </Fragment>
          </Col>
          <Col md={4}>
            <Card border="" className="threat_card p24px">
              <Card.Body className="p-0">
                <div className="">
                  <p className="heading4 font600">
                    {subPlan && subPlan.PlanName}
                  </p>
                  <p>
                    {subPlan && htmlParser(subPlan.Description)}

                    {subPlan && subPlan.PlanName == "Freemium Plan" && (
                      <span
                        className="txt-blue crsrpoint"
                        onClick={() => {
                          props.setKey("subscriptions");
                        }}
                      >
                        Premium Plan
                      </span>
                    )}
                  </p>
                  <p>
                    {subPlan && subPlan.StatusIcon && (
                      <img src={`${subPlan.StatusIcon}`} />
                    )}

                    <span
                      className="pl-2"
                      style={{ color: `${subPlan && subPlan.StatusColour}` }}
                    >
                      {" "}
                      {subPlan && subPlan.StatusText}
                    </span>
                  </p>
                  <p>{subPlan && subPlan.RenewText}</p>
                </div>
                <hr></hr>
                <div className="proServiceBtn">
                  {subPlan && subPlan.PlanName == "Freemium Plan" ? (
                    <button
                      type="button"
                      class="btn-blue btn btn-primary"
                      style={{ padding: "8px 16px" }}
                      onClick={() => props.setKey("subscriptions")}
                    >
                      Upgrade
                    </button>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn-blue btn btn-primary"
                        style={{ padding: "8px 16px" }}
                        onClick={() => {
                          window.location.href =
                            "/system_settings?tab=subscriptions";
                        }}
                      >
                        Change Plan
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          paymentCardInfo(subPlan.SubscriptionId);
                        }}
                        className=" btn-blue btn float-right btn-grey2"
                        style={{ padding: "8px 16px" }}
                      >
                        Change Payment Card
                      </button>
                    </>
                  )}
                </div>
              </Card.Body>
            </Card>
            {responseData.ShowIntegrations && (
              <Card border="" className="threat_card p24px mt24px ">
                <Card.Body className="p-0">
                  <div className="">
                    <p className="heading4 font600">
                      {integrationPlan && integrationPlan.PlanName}
                    </p>
                    <p>
                      {integrationPlan &&
                        htmlParserPacks(integrationPlan.Description)}
                      {subPlan && subPlan.PlanName == "Freemium Plan" && (
                        <span
                          className="txt-blue crsrpoint"
                          onClick={() => {
                            props.setKey("subscriptions");
                          }}
                        >
                          Premium Subscription
                        </span>
                      )}
                    </p>
                    <p>
                      {integrationPlan && integrationPlan.StatusIcon && (
                        <img src={`${integrationPlan.StatusIcon}`} />
                      )}

                      <span
                        className="pl-2"
                        style={{
                          color: `${
                            integrationPlan && integrationPlan.StatusColour
                          }`,
                        }}
                      >
                        {" "}
                        {integrationPlan && integrationPlan.StatusText}
                      </span>
                    </p>
                    <p>{integrationPlan && integrationPlan.RenewText}</p>
                  </div>
                  <hr />
                  <div className="proServiceBtn">
                    <button
                      type="button"
                      className="btn-blue btn btn-primary"
                      style={{ padding: "8px 16px" }}
                      onClick={() => {
                        window.location.href =
                          "/system_settings?tab=subscriptions&scroll=true";
                      }}
                    >
                      Change Pack
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        paymentCardInfo(integrationPlan.SubscriptionId);
                      }}
                      className=" btn-blue btn float-right btn-grey2"
                      style={{ padding: "8px 16px" }}
                    >
                      Change Payment Card
                    </button>
                  </div>
                </Card.Body>
              </Card>
            )}

            {responseData && responseData.ProService.length > 0 && (
              <Card border="" className="threat_card p24px mt24px ">
                <Card.Body className="p-0">
                  <div className="">
                    <p className="heading4 font600">
                      {responseData && responseData.ProServiceTitle}
                    </p>
                    <p>{responseData && responseData.ProServiceDescription}</p>
                  </div>
                  {responseData &&
                    responseData.ProService &&
                    responseData.ProService.map((service, index) => (
                      <div
                        className="d-flex justify-content-between mt24px"
                        key={index}
                      >
                        <div className="d-flex">
                          <div>
                            {service.Icon && (
                              <img
                                className="border-grey"
                                width="48"
                                height="48"
                                src={"/images/ProServices/" + service.Icon}
                                alt=""
                              />
                            )}
                          </div>
                          <div className="pl-3">
                            <p className="m-0">{service.Name}</p>
                            <div className="d-flex mt8px">
                              <p className="txt-blue">{service.Cost}</p>
                              <div className="leftGreyBorder"></div>
                              <p className="greenText">
                                <img src={service.StatusIcon} />
                                <span
                                  className="pl-2"
                                  style={{ color: `${service.StatusColour}` }}
                                >
                                  {service.StatusText}{" "}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <BillingContextMenu
                          subscriptionId={service.SubscriptionId}
                        />
                      </div>
                    ))}
                </Card.Body>
              </Card>
            )}

            {paymentMethodDetail && paymentMethodDetail.length > 0 && (
              <Card border="" className="threat_card p24px mb-3 mt24px">
                <Card.Body className="p-0">
                  <div className="">
                    <p className="heading4 font600">Payment Methods</p>
                    {paymentMethodDetail &&
                      paymentMethodDetail.map((res, i) => (
                        <div className="d-flex justify-content-between" key={i}>
                          <div className="d-flex align-items-top" key={i}>
                            <div>
                              {res.Icon && (
                                <img
                                  src={`/images/${res.Icon}`}
                                  alt="Icon"
                                  width={50}
                                  className="billingCardIcon"
                                />
                              )}
                            </div>
                            <p className="pl-2 billingCardIconText">
                              <span className="txt-blue textUpper">
                                {res.CardType}
                              </span>{" "}
                              {res.CardNumber}
                            </p>
                          </div>
                          <div className="d-flex">
                            <p
                              onClick={() => {
                                props.setToast({
                                  show: false,
                                  msg: false,
                                });
                                removePaymentMethod(res.Id);
                              }}
                              className="greyText crsrpoint pb-2 billingCardIconText"
                            >
                              Remove
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                  <hr></hr>
                  <div className="proServiceBtn">
                    <button
                      type="button"
                      onClick={() => {
                        setAddPaymentModal(true);
                      }}
                      className="btn-blue btn btn-primary crsrpoint"
                      style={{ padding: "8px 16px" }}
                    >
                      + Add Payment Method
                    </button>
                  </div>
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
      )}

      {supportModalOpen ? (
        <ParentChildModal
          ParentComponent={<TicketsView />}
          ChildComponent={<IncidentResponseForm type={"support"} />}
          buttonText={"+ Create Ticket"}
          createTicket={2}
          modalHeader={"Support Tickets"}
          childHeader={"Create Ticket"}
          onClose={() => {
            {
              setSupportModalOpen(false);
            }
          }}
          setShow={true}
          showButton={true}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default Billing;
