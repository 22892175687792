import React, { useState, useEffect } from "react";
import { Button, Modal, Row, Col, Tooltip } from "react-bootstrap";
import "./index.scss";

const OrderSummary = (props) => {
  const { setData } = props;
  return (
    <div className="loader_wrapper">
      <div className="loader">
        <div className="text-center">
          <div className="loader_circle" />
          <p className="mt-2">Please wait...</p>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;

export const FullScreenLoader = () => {
  return (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center">

    <div className="loader_wrapper">
      <div className="loader">
        <div className="text-center">
          <div className="loader_circle" />
        </div>
      </div>
    </div>
    </div>
  );
};
