import React, { useState, useEffect } from "react";
import {
  Form,
  Modal,
  Dropdown,
  Row,
  Col,
   
  Button,
  Spinner,
} from "react-bootstrap";
import {
  getAPIData,
   
  
    
  postData,
   
  
    
  postJsonData,
} from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import "./subscriptionmodals.scss";
import Loader from "../../../Common/loader";
import CompanySettingsDropdown from "../../companySettings/companysettingsdrodown/CompanySettingsDropdown";
 

const CountryList = [
  {
    country: "Argentina",
    code: "AR",
  },
  {
    country: "Australia",
    code: "AU",
  },
  {
    country: "Austria",
    code: "AT",
  },
  {
    country: "Belgium",
    code: "BE",
  },
  {
    country: "Bolivia",
    code: "BO",
  },
  {
    country: "Brazil",
    code: "BR",
  },
  {
    country: "Bulgaria",
    code: "BG",
  },
  {
    country: "Canada",
    code: "CA",
  },
  {
    country: "Chile",
    code: "CL",
  },
  {
    country: "Colombia",
    code: "CO",
  },
  {
    country: "Costa Rica",
    code: "CR",
  },
  {
    country: "Croatia",
    code: "HR",
  },
  {
    country: "Cyprus",
    code: "CY",
  },
  {
    country: "Czech Republic",
    code: "CZ",
  },
  {
    country: "Denmark",
    code: "DK",
  },
  {
    country: "Dominican Republic",
    code: "DO",
  },
  {
    country: "Egypt",
    code: "EG",
  },
  {
    country: "Estonia",
    code: "EE",
  },
  {
    country: "Finland",
    code: "FI",
  },
  {
    country: "France",
    code: "FR",
  },
  {
    country: "Germany",
    code: "DE",
  },
  {
    country: "Greece",
    code: "GR",
  },
  {
    country: "Hong Kong SAR China",
    code: "HK",
  },
  {
    country: "Hungary",
    code: "HU",
  },
  {
    country: "Iceland",
    code: "IS",
  },
  {
    country: "India",
    code: "IN",
  },
  {
    country: "Indonesia",
    code: "ID",
  },
  {
    country: "Ireland",
    code: "IE",
  },
  {
    country: "Israel",
    code: "IL",
  },
  {
    country: "Italy",
    code: "IT",
  },
  {
    country: "Japan",
    code: "JP",
  },
  {
    country: "Latvia",
    code: "LV",
  },
  {
    country: "Liechtenstein",
    code: "LI",
  },
  {
    country: "Lithuania",
    code: "LT",
  },
  {
    country: "Luxembourg",
    code: "LU",
  },
  {
    country: "Malta",
    code: "MT",
  },
  {
    country: "Mexico ",
    code: "MX",
  },
  {
    country: "Netherlands",
    code: "NL",
  },
  {
    country: "New Zealand",
    code: "NZ",
  },
  {
    country: "Norway",
    code: "NO",
  },
  {
    country: "Paraguay",
    code: "PY",
  },
  {
    country: "Peru",
    code: "PE",
  },
  {
    country: "Poland",
    code: "PL",
  },
  {
    country: "Portugal",
    code: "PT",
  },
  {
    country: "Romania",
    code: "RO",
  },
  {
    country: "Singapore",
    code: "SG",
  },
  {
    country: "Slovakia",
    code: "SK",
  },
  {
    country: "Slovenia",
    code: "SI",
  },
  {
    country: "Spain",
    code: "ES",
  },
  {
    country: "Sweden",
    code: "SE",
  },
  {
    country: "Switzerland",
    code: "CH",
  },
  {
    country: "Thailand",
    code: "TH",
  },
  {
    country: "Trinidad & Tobago",
    code: "TT",
  },
  {
    country: "United Arab Emirates",
    code: "AE",
  },
  {
    country: "United Kingdom",
    code: "GB",
  },
  {
    country: "United States",
    code: "US",
  },
  {
    country: "Uruguay",
    code: "UY",
  },
];

const PaymentModal = ({
  show,
  hideModal,
  planId,
  Refreshpage,
  isAnnualy,
  typeOfProduct,
  // SubmitPaymentDetails
}) => {
  const { authTokens } = useAuth();

  const [page, setPage] = useState("initial");

  

  const [modalData, setModalData] = useState({});
  const [formType, setFormType] = useState("AnnualForm"); // Annual or Monthly
  const [billingintervaloption, setbillingintervaloption] = useState("");

  // Add new card setup
  const [cardNumber, setCardNumber] = useState("");
  const [monthYear, setMonthYear] = useState("");
  const [CVV, setCVV] = useState("");
  const [cardName, setCardName] = useState("");
  const [country, setCountry] = useState({
    code: "GB",
    country: "United Kingdom",
  });
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [registration, setRegistration] = useState("");
  const [vat, setVat] = useState("");
  const [responsePage, setResponsePage] = useState({});
  const [step, setStep] = useState(1);
  const [addingPayment, setAddingPayment] = useState(false);
  const [processingPayment, setProcessingPayment] = useState(false);

 
  
  const [selectedStripeId, setSelectedStripeId] = useState("");
  const [modalLoading, setModalLoading] = useState(false);
   

  const [formErrors, setFormErrors] = useState({
    cardNumber: "",
    cardMonth: "",
    cardCVV: "",
    cardName: "",
    companyName: "",
    companyAddress: "",
    companyRegistration: "",
    companyVat: "",
  });

  const cardDetails = {
    cardname: cardName,
    cardnumber: cardNumber,
    monthyear: monthYear,
    cvc: CVV,
    cname: companyName,
    caddress: address,
    ccountry: country.code,
    creg: registration,
    cvat: vat,
  };

 
  
  let month = cardDetails.monthyear.split("/");
  let dataToPost = {
    obj_PaymentMethod: {
      CardName: cardDetails.cardname,
      CardNumber: cardDetails.cardnumber,
      ExpMonth: month[0],
      ExpYear: month[1],
      Cvc: cardDetails.cvc,
      CompanyName: cardDetails.cname,
      CompanyAddress: cardDetails.caddress,
      Country: cardDetails.ccountry,
      CompanyRegistration: cardDetails.creg,
      VAT: cardDetails.cvat,
    },
    stripeId_PaymentMethod: selectedStripeId,
    objId_Price: planId,
    isMonthly: formType === "AnnualForm" ? 2 : 1,
    // test: "http://localhost:3000/settings/v2/subscriptions",
    // test: "http://localhost:3000/settings/v2/team",
    // test: "http://localhost:3000/test",
    test: `${window.location.origin}/handlesubscription3ds`,

    // Change value after deploying on Production (Talk to Jasdeep)
  };
 
 
  

 

 

  function clearModal() {
    setFormErrors({
      cardNumber: "",
      cardMonth: "",
      cardCVV: "",
      cardName: "",
      companyName: "",
      companyAddress: "",
      companyRegistration: "",
      companyVat: "",
    });
    setVat("");
    setRegistration("");
    setAddress("");
    setCompanyName("");

    setCardName("");
    setMonthYear("");
    setCVV("");
    setCardNumber("");
  }

  useEffect(() => {
    if (show) {
      clearModal();
      setStep(1);
      setProcessingPayment(false);
      setAddingPayment(false);
      setPage("initial");
      setModalLoading(true);
      // API call
       getAPIData("191", authTokens, {
        OptionEnum1: planId,
      }).then((response) => {
        setModalLoading(false);
        if (response.mr.Success) {
          setModalData(response);
          if (isAnnualy) {
            setFormType("AnnualForm");
            setbillingintervaloption(
              `${response?.BillingIntervalList?.[0].Name} -
            ${response?.BillingIntervalList?.[0].Price}`
            );
          } else {
            setFormType("MonthlyForm");
            setbillingintervaloption(
              `${response?.BillingIntervalList?.[1].Name} -
            ${response?.BillingIntervalList?.[1].Price}`
            );
          }
          if (response?.PaymentMethod_List?.length == 0) {
            setPage("new-payment");
          }
        }
      });
    }else{
       
    }

   
  }, [show]);

  const maskDate = (value) => {
    let v = value.replace(/(\w)\//g, function (str, match) {
      return match[0];
    });
    if (v.length >= 3) {
      return `${v.slice(0, 2)}/${v.slice(2)}`;
    }
    return value;
  };
// SETUP FOR 3DS

function handle3DSuccess(response) {
  if (response?.mr?.Success) {
    if (response?.data?.accessKeys) {
      localStorage.setItem(
        "user_MessageObj",
        JSON.stringify(response?.data?.accessKeys)
      );
    }
    window.removeEventListener("message", redirectedToUrlEvent);
    console.log(response, "ressssssssss");
    setResponsePage(response);
    Refreshpage();
    setStep(2);
    try {
      // window.location.reload(true);
    } catch (err) {
      console.log(err);
    }
  }
}

function redirectedToUrlEvent(event) {
  console.log(event?.source.location.href + event?.source.location.search);
  const urlParams = new URLSearchParams(event?.source.location.search);
  const paymentIntent = urlParams.get("payment_intent");
  const paymentClientSecret = urlParams.get("payment_intent_client_secret");
  if (show && paymentIntent && paymentClientSecret) {
    handleSubmitIntent(paymentIntent, paymentClientSecret);
  }
}
  async function handleSubmitIntent(paymentIntent, paymentClientSecret) {
    return new Promise((resolve, reject) => {
      postData(authTokens, "HandleStripePayment", {
        payment_intent: paymentIntent,
        payment_intent_client_secret: paymentClientSecret,
      })
        .then((response) => {
          if (response?.mr?.Success) {
            if (response?.data?.requires_action) {
              openChildWindow(response?.data?.redirectUrl);
            } else {
              handle3DSuccess(response);
              resolve(response);
            }
          } else {
            reject("error");
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  function openChildWindow(_url) {
    window.addEventListener("message", redirectedToUrlEvent);
    if (show) {
      window.open(_url, "childWindow", "width=600,height=600");
      // window.open(_url, "_blank");
    }

  }

  async function SubmitPaymentDetails(dataToPost) {
    return new Promise((resolve, reject) => {
      postJsonData(authTokens, "SubmitPaymentDetail", dataToPost)
        .then((response) => {
          if (response?.mr?.Success) {
            if (response?.data?.requires_action) {
              openChildWindow(response?.data?.redirectUrl);
            } else {
              handle3DSuccess(response);
              resolve(response);
            }
          } else {
            reject("error");
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }
  return (
    <Modal
      // show={true}
      show={show}
      dialogClassName="payment-modal modal-700h"
      className=""
      aria-labelledby="payment-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Header className="d-flex justify-content-between align-items-center ">
        <div className="d-flex align-items-center">
          <img src="/images/settings-v2/logo.svg" alt="" className="mr-3" />
          <div className="header-name">
            {/* {modalData?.Title}-----  */}
            Payment
          </div>
        </div>

        <img
          src="/images/icons/close.svg"
          alt=""
          className="close-icon pointer"
          onClick={hideModal}
        />
      </Modal.Header>
      <Modal.Body className="">
         
        {modalLoading && (
          <div>
            <Col className="min-400h">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  position: "absolute",
                  top: "40%",
                  left: 0,
                  right: 0,
                  margin: "auto",
                }}
              >
                <Loader />
              </div>
            </Col>
          </div>
        )}

        {step == 1 && !modalLoading && (
          <>
            <div className="d-flex justify-content-between align-items-start amount-details">
              <div className="d-flex align-items-center">
                <img alt=""
                  src="/images/settings-v2/avatar-image.svg"
                  className="mr-2 d-none"
                />
                <div>
                  <p className="title mb-1">
                    {modalData[formType]?.OrderSummaryName}
                  </p>
                  <p className="subtitle">
                    {billingintervaloption.split("-")[1]} · Billed&nbsp;
                    {formType.substring(0, formType.length - 4)}
                  </p>
                </div>
              </div>
              <div className="title d-flex justify-content-center align-items-start">
                £{modalData[formType]?.Total}
              </div>
            </div>

            {/* Billing Intervals */}
            <div className="title mt-3 mb-2">Billing interval</div>

            <Dropdown className="billing-interval-dropdown">
              <Dropdown.Toggle variant="success" id="billing-intervals">
                {billingintervaloption ||
                  `${modalData?.BillingIntervalList?.[0].Name} -
                      ${modalData?.BillingIntervalList?.[0].Price}`}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Row>
                  <Col
                    xs={12}
                    className="mb-2 cursor-pointer"
                    onClick={(e) => {
                      setbillingintervaloption(
                        `${modalData?.BillingIntervalList?.[0].Name} -
                      ${modalData?.BillingIntervalList?.[0].Price}`
                      );
                      setFormType("AnnualForm");
                      e.target.closest(".billing-interval-dropdown").click();
                    }}
                  >
                    {/* Monthly - £395/month */}
                    {modalData?.BillingIntervalList?.[0].Name} -
                    {modalData?.BillingIntervalList?.[0].Price}
                  </Col>
                  <Col
                    xs={12}
                    className=" cursor-pointer"
                    onClick={(e) => {
                      setFormType("MonthlyForm");
                      setbillingintervaloption(
                        `${modalData?.BillingIntervalList?.[1].Name} -
                      ${modalData?.BillingIntervalList?.[1].Price}`
                      );
                      e.target.closest(".billing-interval-dropdown").click();
                    }}
                  >
                    {/* Annual - £4 275/year <span className="percentage">-10%</span> */}
                    {modalData?.BillingIntervalList?.[1].Name} -
                    {modalData?.BillingIntervalList?.[1].Price}
                  </Col>
                </Row>
              </Dropdown.Menu>
            </Dropdown>
            <hr />

            <div id="wrapper" className="w-100">

            </div>

            {/* Payment method New/Edit */}

            {page == "initial" && (
              <>
                <div className="title mt-3 mb-1">Payment Method</div>
                <div
                  className="payment-method d-flex justify-content-between align-items-center pointer"
                  onClick={() => setPage("all-list")}
                >
                  <div className="d-flex align-items-center">
                    <img alt=""
                      src="/images/settings-v2/master-card.svg"
                      className="mr-2"
                    />
                    <div>
                      <p className="title m-0 ">
                     
                        Select a payment method
                      </p>
                    
                    </div>
                  </div>
                  <img alt=""
                    src="/images/complianceManager/downArrow.svg"
                    // onClick={() => setPage("all-list")}
                    className="pointer"
                  />
                </div>
              </>
            )}

            {page == "all-list" && (
              <>
                <div className="payment-method-list">
                  <Row>
                    {modalData?.PaymentMethod_List.map(
                      (paymentMethod, paymentIndex) => {
                        return (
                          <Col xs={12} className="m-0 p-0">
                            <div className=" d-flex justify-content-between align-items-center p-3">
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-center align-items-center checkbox-wrapper">
                                  <Form.Group
                                    controlId={`card ${paymentIndex}  `}
                                    className={`round-checkbox blue-checkbox `}
                                  >
                                    <Form.Check
                                      type={"radio"}
                                      // checked={1}
                                      name={`check `}
                                      label=""
                                      onChange={() => {
                                        setSelectedStripeId(
                                          paymentMethod?.StripeId
                                        );
                                      }}
                                    />
                                  </Form.Group>
                                </div>
                                <div>
                                  <p className="title m-0 ">
                                    {paymentMethod?.CardType} &nbsp;
                                    {paymentMethod?.CardNumber}
                                  </p>
                                  <p className="subtitle m-0">
                                    {/* Jane Cooper, Cyber Care lt... */}
                                  </p>
                                </div>
                              </div>
                              <div className="--------">
                                
                              </div>
                            </div>
                            {<hr className="m-0" />}
                          </Col>
                        );
                      }
                    )}

                    <Col xs={12} className="p-0">
                      <div className="d-flex align-items-center">
                        <div className="d-flex  align-items-center checkbox-wrapper p-3">
                          <Form.Group
                            controlId={"check2"}
                            className={`round-checkbox blue-checkbox `}
                          >
                            <Form.Check
                              type={"radio"}
                              // checked={1}
                              name={"check"}
                              label=""
                              onChange={() => {
                                setSelectedStripeId(
                                  ""
                                );
                                setPage("new-payment");
                              }}
                            />
                          </Form.Group>
                        </div>
                        <div className="title">Add payment method</div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            )}

            {page == "new-payment" && (
              <>
                <div className="title">Payment Method</div>
                <div className="bg-grey radius-4 p-2 d-flex align-items-center mb-3 w-100 mt-1 f-black f-500">
                  <img
                    width={17}
                    height={15}
                    alt=""
                    src="/images/settings-v2/add-payment-icon.svg"
                    className="mr-3 ml-2 my-1"
                  />
                  Add Payment method
                </div>
              </>
            )}

            {(page == "add-payment" || page == "new-payment") && (
              <>
                <div className="title">Payment Information</div>

                <div className="card-details d-flex align-items-center w-100">
                  <img src="/images/settings-v2/card.svg" />
                  <input
                    className="card-number input-autofill-issue"
                    type="number"
                    placeholder="Card Number"
                    value={cardNumber}
                    onChange={(e) => {
                      if (e.target.value.length == 17) return false;
                      setFormErrors({
                        ...formErrors,
                        cardNumber: "",
                      });
                      setCardNumber(e.target.value);
                    }}
                    autoComplete="new-password"
                  />
                  <input
                    className="card-month input-autofill-issue"
                    value={monthYear}
                    onChange={(e) => {
                      if (e.target.value.length == 6) return false;
                      e.target.value = e.target.value
                        .replace(
                          /[^0-9]/g,
                          "" // To allow only numbers
                        )
                        .replace(
                          /^([2-9])$/g,
                          "0$1" // To handle 3 > 03
                        )
                        .replace(
                          /^(1{1})([3-9]{1})$/g,
                          "0$1/$2" // 13 > 01/3
                        )
                        .replace(
                          /^0{1,}/g,
                          "0" // To handle 00 > 0
                        )
                        .replace(
                          /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
                          "$1/$2" // To handle 113 > 11/3
                        );
                      setFormErrors({
                        ...formErrors,
                        cardMonth: "",
                      });
                      setMonthYear(maskDate(e.target.value));
                    }}
                    type="text"
                    placeholder="MM/YY"
                    autoComplete="new-password"
                  />
                  <input
                    className="card-cvv input-autofill-issue"
                    value={CVV}
                    onChange={(e) => {
                      if (e.target.value.length == 4) return false;
                      setFormErrors({
                        ...formErrors,
                        cardCVV: "",
                      });
                      setCVV(e.target.value);
                    }}
                    type="password"
                    placeholder="CVV"
                    autoComplete="new-password"
                  />
                </div>
                <div className="f-12 f-danger">{formErrors.cardNumber} </div>
                <div className="f-12 f-danger">{formErrors.cardMonth} </div>
                <div className="f-12 f-danger">{formErrors.cardCVV} </div>
                <div className="card-details my-1">
                  <input
                    type="text"
                    className="w-100 input-autofill-issue"
                    placeholder="Name on card"
                    value={cardName}
                    onChange={(e) => {
                      setFormErrors({
                        ...formErrors,
                        cardName: "",
                      });
                      setCardName(e.target.value);
                    }}
                  />
                </div>
                <div className="f-12 f-danger">{formErrors.cardName} </div>
                <div className="title mt-3">Billing Information</div>
                <div className="card-details my-1">
                  <input
                    type="text"
                    className="w-100 input-autofill-issue"
                    placeholder="Company name"
                    value={companyName}
                    onChange={(e) => {
                      setFormErrors({
                        ...formErrors,
                        companyName: "",
                      });
                      setCompanyName(e.target.value);
                    }}
                  />
                </div>
                <div className="f-12 f-danger">{formErrors.companyName} </div>
                {/* <Form.Group>
                  <InputGroup className="mt-10px grey-select customerStyle border-4">
                    <Form.Control
                      style={{ height: "40px" }}
                      as="select"
                      className={"pt-0 pb-0 black-clr"}
                      defaultValue={"GB"}
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                    >
                      {modalData?.CountryList.map((country) => {
                        return (
                          <option value={country?.code}>
                            {country?.country}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </InputGroup>
                </Form.Group> */}
                <div className="country-list-dropdown">
                  <CompanySettingsDropdown
                    fieldId={"code"}
                    fieldValue={"country"}
                    selected={country}
                    placeholder="Choose..."
                    //  showid={true}
                    onClick={(id, val) => {
                      setCountry({ code: id, country: val });
                    }}
                    data={CountryList}
                  />
                </div>
                <div className="card-details my-1">
                  <input
                    type="text"
                    className="w-100 input-autofill-issue"
                    placeholder="Company address"
                    value={address}
                    onChange={(e) => {
                      setFormErrors({
                        ...formErrors,
                        companyAddress: "",
                      });
                      setAddress(e.target.value);
                    }}
                  />
                </div>
                <div className="f-12 f-danger">
                  {formErrors.companyAddress}{" "}
                </div>
                <div className="card-details my-1">
                  <input
                    type="text"
                    className="w-100 input-autofill-issue"
                    placeholder="Company registration"
                    value={registration}
                    onChange={(e) => {
                      setFormErrors({
                        ...formErrors,
                        companyRegistration: "",
                      });
                      setRegistration(e.target.value);
                    }}
                  />
                </div>
                <div className="f-12 f-danger">
                  {formErrors.companyRegistration}{" "}
                </div>
                <div className="card-details my-1">
                  <input
                    type="text"
                    className="w-100 input-autofill-issue"
                    placeholder="VAT"
                    value={vat}
                    onChange={(e) => {
                      setFormErrors({
                        ...formErrors,
                        companyVat: "",
                      });
                      setVat(e.target.value);
                    }}
                  />
                </div>
                <div className="f-12 f-danger">{formErrors.companyVat} </div>
              </>
            )}

            {(page == "initial" || page == "all-list" || true) && (
              <>
                <hr />
                {/* Order Summary */}

                <div className="title my-2 mb-3">Order Summary</div>

                <div>
                  <div className="d-flex justify-content-between align-items-center my-1">
                    <div className="subtitle">Subtotal</div>
                    <div className="title">
                      {modalData[formType]?.SubTotal_Text}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center my-1">
                    <div className="subtitle">Tax (if applicable)</div>
                    <div className="title">
                      {modalData[formType]?.VatTotal_Text}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-1 mb-2">
                    <div className="subtitle">Discount</div>
                    <div className="title">-</div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center my-1">
                    <div className="subtitle">Total</div>
                    <div className="title">
                      {modalData[formType]?.Total_Text}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}

        {step == 2 && (
          <div className="w-75 mt-5 mx-auto mb-5">
            <div className="d24" />
            <div className="d24" />
            <div className="d24" />

            <>
              <div className="text-center">
                {responsePage &&
                  responsePage?.data &&
                  responsePage?.data?.icon.search("RedCircle") > 0 && (
                    <img src="/images/rfail.svg" width={40} height={40} alt="" />
                  )}
                  {/* <img src={responsePage?.data?.icon} width={40} height={40} alt="" /> */}
                  {responsePage &&
                  responsePage?.data &&
                  responsePage?.data?.icon.search("GreenCircle") > 0 && (
                    <img src="/images/rsuccess.svg" alt="" />
                  )}
                {/* {responsePage &&
                  responsePage.ResultIcon &&
                  responsePage.ResultIcon.search("GreenCircle") > 0 && (
                    <img src="/images/rsuccess.svg" alt="" />
                  )}

                {responsePage &&
                  responsePage.status &&
                  responsePage.status == "succeeded" && (
                    <img src="/images/rsuccess.svg" alt="" />
                  )} */}
                {/* {isError && <img src="/images/rfail.svg" alt="" />} */}

                <p
                  className="txtfail mt-4"
                  style={{
                    color: `${
                      responsePage && responsePage?.data.messageTitleColor
                        ? responsePage?.data?.messageTitleColor
                        : ""
                    }`,
                  }}
                >
                  {responsePage &&
                    responsePage?.data.messageTitle &&
                    responsePage?.data.messageTitle}
                </p>
              </div>
              <div className="responseList mt-4">
                <p className="text-center">
                  {responsePage &&
                    responsePage?.data.message &&
                    responsePage?.data.message}
                  {/* {responsePage && responsePage.message}
                  {responsePage && responsePage.Message && responsePage.Message} */}
                </p>
                {/* {responsePage && responsePage.status == "succeeded" && (
                  <p>Thanks for buying {service && service.Title}</p>
                )} */}

                <Button
                  onClick={() => {
                    if (
                      responsePage &&
                      responsePage?.data.buttonText == "Try Again"
                    ) {
                      setStep(1);
                      setProcessingPayment(false)
                    } else {
                      Refreshpage();
                      hideModal();
                    }
                  }}
                  className="w-100 blubtn mt-4 mb-5"
                >
                  {responsePage && responsePage?.data.buttonText}
                </Button>
                <div className="d24" />
                <div className="d24" />
                <div className="d24" />
                <div className="d24" />
              </div>
            </>
          </div>
        )}
      </Modal.Body>

      {step == 1 && (
        <Modal.Footer>
          <Button
            className="payment-btn"
            disabled={addingPayment || processingPayment || modalLoading   || ((page == "initial" || page == "all-list") && !selectedStripeId)  }
            onClick={() => {
              if (page == "initial" || page == "all-list") {
                setProcessingPayment(true)
                
                SubmitPaymentDetails(dataToPost).then((response)=>{
                  
                  setProcessingPayment(false)
                }).catch((err)=>{
                  setProcessingPayment(false)
                  console.log(err)
                })
              }
              if (page == "new-payment") {
                if (cardNumber.length < 16) {
                  setFormErrors({
                    ...formErrors,
                    cardNumber: "*Please enter valid card number",
                  });
                  return;
                }

                if (monthYear.length < 5) {
                  setFormErrors({
                    ...formErrors,
                    cardMonth: "*Please enter valid card expiry",
                  });
                  return;
                }

                if (monthYear.length > 0) {
                  const currentMonth = Number(new Date().getMonth() + 1);
                  const last2 = new Date().getFullYear().toString().slice(-2);
                  const currentYear = Number(last2);
                  const inputMonth = Number(monthYear.split("/")[0]);
                  const inputYear = Number(monthYear.split("/")[1]);

                  if (
                    (inputMonth < currentMonth && inputYear <= currentYear) ||
                    inputYear < currentYear
                  ) {
                    setFormErrors({
                      ...formErrors,
                      cardMonth: "*Please enter valid card expiry",
                    });
                    return;
                  }
                }

                if (CVV.length < 3) {
                  setFormErrors({
                    ...formErrors,
                    cardCVV: "*Please enter valid CVC",
                  });
                  return;
                }

                if (cardName.length == "") {
                  setFormErrors({
                    ...formErrors,
                    cardName: "*Please enter card holder name",
                  });

                  return;
                }

                if (companyName.length == 0) {
                  setFormErrors({
                    ...formErrors,
                    companyName: "*Please enter company name",
                  });
                  return;
                }
                if (address.length == 0) {
                  setFormErrors({
                    ...formErrors,
                    companyAddress: "*Please enter company address",
                  });
                  return;
                }

                if (registration.length == 0) {
                  setFormErrors({
                    ...formErrors,
                    companyRegistration: "*Please enter registration number",
                  });
                  return;
                }

                if (vat.length == 0) {
                  setFormErrors({
                    ...formErrors,
                    companyVat: "*Please enter vat number",
                  });
                  return;
                }

                setProcessingPayment(true)
                
                SubmitPaymentDetails(dataToPost).then((response)=>{
                  
                  setProcessingPayment(false)
                }).catch((err)=>{
                  setProcessingPayment(false)
                  console.log(err)
                })
              }
            }}
          >
            <div>
              {(page == "initial" ||
                page == "all-list" ||
                page == "new-payment") &&
                !processingPayment &&
                 (
                  <>
                    <img
                      alt=""
                      src="/images/settings-v2/locker.svg"
                      className="mr-2"
                    />
                    &nbsp;Pay £{modalData[formType]?.Total} GBP now
                  </>
                )}

              {(page == "initial" ||
                page == "all-list" ||
                page == "new-payment") &&
                (processingPayment  ) && (
                  <>
                    <Spinner
                      size="sm"
                      animation="border"
                      role="status"
                      className="mx-2"
                      variant="light"
                    />
                    Processing...
                  </>
                )}
            </div>
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default PaymentModal;
