export const TrackUser = (message, data = {}) => {
  return
  try {
    const UserFullName = window.localStorage.getItem("persist:Cyber Care");

    var userDetails = JSON.parse(UserFullName)?.user
      ? JSON.parse(JSON.parse(UserFullName)?.user)?.userDetail
      : undefined;

    return window.analytics.track(
      message,
      data
        ? {
            username: userDetails?.FullName,
            email: userDetails?.UserName,
            user_role: userDetails?.Role,
            company_info: userDetails?.OrgName,
            avatar: userDetails?.Avatar,

            ...data,
          }
        : {
            username: userDetails?.FullName,
            email: userDetails?.UserName,
            user_role: userDetails?.Role,
            company_info: userDetails?.OrgName,
            avatar: userDetails?.Avatar,
          }
    );
  } catch (err) {
    console.log("track segment error=>", err);
  }
};
