import React, { Fragment, useState, useEffect } from "react";
import { useAuth } from "../../context/Auth";
import { useDb } from "../../context/db";
import {
  Button,
  InputGroup,
  FormControl,
  Spinner,
  Row,
  Col,
} from "react-bootstrap";
import { getAPIData, postData } from "../../../Utils/commonApi";
import ImageUploadModal from "../../SystemSettings/UsersAndDevices/imageUploadModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgressBar from "../../CircularProgressBar";
import { FiCheck, FiX } from "react-icons/fi";
import ContentLoader from "react-content-loader";
import "./personal-profile.scss";
import UserDetailModal from "./personal-profile-modals/UserDetailModal";
import ImageCropperModal from "./personal-profile-modals/ImageCropperModal";
import { useDispatch, useSelector } from "react-redux";
import { setuser } from "../../../redux/userState/actions";

const PersonalProfile = () => {
  const { authTokens } = useAuth();
  const { dbObj, setdbObject } = useDb();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.user?.userDetail);
  const [personalDetail, setPersonalDetail] = useState({});
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [notifSetting, setNotifSetting] = useState(false);
  const [disableResetPassword, setDisableResetPassword] = useState(true);
  const [loading, setloading] = useState(false);
  const [mfaReset, setMfaReset] = useState(false);
  const [DisableMFAReset, setDisableMFAReset] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [userObjId, setUserObjId] = useState("");
  useEffect(() => {
    getProfileData();
    getNotificationSettings();
  }, []);

  async function getProfileData() {
    const response = await getAPIData(102, authTokens);
    setPersonalDetail(response);
  }

  async function resetData(id) {
    const response = await getAPIData(id, authTokens, {
      optionEnum1: userObjId,
    });

    if (response.Success) {
      toast.dark(
        <span>
          <FiCheck style={{ marginRight: "10px" }} />
          {response.Message}
          <span className="float-right">
            <CircularProgressBar durationInSec="30" />
          </span>
        </span>
      );
    } else {
      toast.dark(
        <span>
          <FiX style={{ marginRight: "10px", color: "#f36060" }} />
          <span style={{ width: "500px", color: "#f36060" }}>
            {response.Message}
          </span>
          <span className="float-right">
            <CircularProgressBar durationInSec="30" />
          </span>
        </span>
      );
    }
    setShowToast(true);
  }

  async function getNotificationSettings() {
    const response = await getAPIData(103, authTokens);
    setNotifSetting(response.NotificationSettings);
    setUserObjId(response.objId_UserSetting);
  }

  const handleChange = (inputName, value) => {
    setPersonalDetail({ ...personalDetail, [inputName]: value });
  };

  async function handleSave(notifName, notifValue) {
    notifValue = notifValue.replace(/<\/?[^>]+>/gi, "");
    if (notifName == "Fullname") {
      if (dbObj.UserDetail.FullName == notifValue) {
        return false;
      }
    }
    const inputs = {
      SettingOwner: notifSetting.SettingOwner,
      OwnerType: notifSetting.OwnerType,
      NotificationName: notifName,
      NotificationValue: notifValue,
    };
    if (notifName == "Password_Reset") {
      setloading(true);
    }
    if (notifName == "MFA_Reset") {
      setMfaReset(true);
      setDisableMFAReset(false);
    }

    if (notifName == "Password_Reset") {
      setDisableResetPassword(false);
    }
    if (notifValue !== 1) {
      setdbObject({
        ...dbObj,
        UserDetail: { ...dbObj.UserDetail, FullName: notifValue },
      });

      dispatch(setuser({ FullName: notifValue }));
    }
    setShowToast(true);
    await postData(authTokens, "SubmitNotificationSettings", inputs)
      .then((response) => {
        if (response.Success == true || response.FullName == notifValue) {
          setloading(false);
          setMfaReset(false);
          toast.dark(
            <span>
              <FiCheck style={{ marginRight: "10px" }} />
              Profile updated successfully
              <span className="float-right">
                <CircularProgressBar durationInSec="30" />
              </span>
            </span>
          );
        } else {
          setloading(false);
          toast.dark(
            <span>
              <FiX style={{ marginRight: "10px", color: "#f36060" }} />
              <span style={{ width: "500px", color: "#f36060" }}>
                Unable to update
              </span>
              <span className="float-right">
                <CircularProgressBar durationInSec="30" />
              </span>
            </span>
          );
        }
      })
      .catch(() => {
        setloading(false);
        toast.dark(
          <span>
            <FiX style={{ marginRight: "10px", color: "#f36060" }} />
            <span style={{ width: "500px", color: "#f36060" }}>
              Unable to update
            </span>
            <span className="float-right">
              <CircularProgressBar durationInSec="30" />
            </span>
          </span>
        );
      });
  }

  return Object.keys(personalDetail).length == 0 ? (
    <ContentLoader
      style={{ marginTop: "-80px" }}
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 -80 1500 1000"
      backgroundColor="#f1f2f7e8"
      foregroundColor="#ebecf1"
    >
      <rect
        x="0"
        y=""
        rx="50"
        ry="50"
        height={300}
        width={1500}
        style={{ height: "80", width: "80" }}
      />
      <rect
        x="100"
        y="0"
        rx="2"
        ry="2"
        height={300}
        width={1500}
        style={{ height: "40", width: "120" }}
      />
      <rect
        x="100"
        y="60"
        rx="2"
        ry="2"
        height={300}
        width={1500}
        style={{ height: "5", width: "150" }}
      />
      <rect
        x="100"
        y="70"
        rx="2"
        ry="2"
        height={300}
        width={1500}
        style={{ height: "5", width: "150" }}
      />
      <rect
        x="0"
        y="120"
        rx="15"
        ry="15"
        height={300}
        width={1500}
        style={{ height: "220", width: "100%" }}
      />

      <rect
        x="0"
        y="360"
        rx="15"
        ry="15"
        height={300}
        width={1500}
        style={{ height: "220", width: "100%" }}
      />
    </ContentLoader>
  ) : (
    <Fragment>
      {/* Settings Version 2---start */}

      {/* Settings Version 2--- End */}
      {showToast && false && (
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
        />
      )}
      <div className="d-flex mt40px ">
        <div
          className="profile-container"
          style={{ border: "1px dashed #B6B6BF" }}
        >
          {userDetails?.Avatar !== "No URL" ? (
            <img
              className="rounded-circle border-grey camera-icon cursor-pointer "
              src={userDetails.Avatar}
              alt=""
              onClick={() => setShowUploadModal(true)}
              width="79"
            />
          ) : (
            <img
              className="border-grey camera-icon cursor-pointer "
              width="32"
              src={`/images/icons/cameraIcon.svg`}
              alt=""
              onClick={() => setShowUploadModal(true)}
            />
          )}
        </div>
        <div className="align-self-center ml24px  " style={{ width: "223px" }}>
          <div
            className="light-blue-btn"
            style={{ width: "120px" }}
            onClick={() => setShowUploadModal(true)}
          >
            Upload Photo
          </div>
          <div className="smallGreyText " style={{ marginTop: "15px" }}>
            If you do not upload a profile picture, your initials will be used
            instead.
          </div>
        </div>
      </div>
      <div className="settings_container mt40px ">
        <div className="heading3">Personal Info</div>
        <InputGroup
          className="mt-16px grey-input mb8px"
          style={{ width: "400px" }}
        >
          <InputGroup.Prepend>
            <InputGroup.Text className="greyText">Full Name:</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="text"
            defaultValue={userDetails.FullName}
            onBlur={(e) => handleSave("Fullname", e.target.value)}
          />
        </InputGroup>
        {/* <InputGroup
          className="mt-16px grey-input mb8px"
          style={{ width: "400px" }}
        >
          <InputGroup.Prepend>
            <InputGroup.Text className="greyText">Surname:</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="text"
            defaultValue={personalDetail.SurName}
            onBlur={(e) => handleSave("Surname", e.target.value)}
          />
        </InputGroup> */}
        <div style={{ marginTop: "30px" }}>
          <span className="greyText ml16px">Email:</span> {personalDetail.Email}
        </div>
      </div>
      <div className="settings_container mt16px mb16px ">
        <div className="heading3">Reset</div>
        <div
          className="d-flex mt40px justify-content-between"
          style={{ width: "387px" }}
        >
          <div>
            <div className="textBlackBold">Reset MFA</div>
            <span className="greyText">You can reset MFA for this user</span>
          </div>
          <Button
            className={`align-self-center ${
              personalDetail.EnableMFAReset && DisableMFAReset
                ? "light-red-btn"
                : "btn-disable"
            }`}
            style={{ minWidth: "70px" }}
            onClick={() => resetData("108")}
          >
            {/* Reset */}
            {mfaReset ? <Spinner size="sm" animation="border" /> : "Reset"}
          </Button>
        </div>
        <div
          className="d-flex mt16px justify-content-between"
          style={{ width: "387px" }}
        >
          <div>
            <div className="textBlackBold">Reset Password</div>
            <span className="greyText">
              We will send email to user with reset instruction
            </span>
          </div>
          <Button
            className={`align-self-center ${
              disableResetPassword ? "light-red-btn" : "btn-disable"
            }`}
            style={{ minWidth: "70px" }}
            onClick={() => resetData("107")}
          >
            {loading ? <Spinner size="sm" animation="border" /> : "Reset"}

            {/* Reset */}
          </Button>
        </div>
      </div>
      <ImageUploadModal
        showModal={showUploadModal}
        hideModal={() => setShowUploadModal(false)}
        uploadType={0}
      />
      <UserDetailModal />
      <ImageCropperModal />
    </Fragment>
  );
};

export default PersonalProfile;
