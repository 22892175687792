import {
  MSP_SPEND_MODAL_DATE_PICKER_STATE,
  MSP_DASHBOARD_DATE_PICKER_STATE,
} from "./constants";

/**
 * Increments in calculator value
 *
 * @param {Boolean} data
 */
export const mspClientDashboardDatePickerState = (data) => {
  return { type: MSP_DASHBOARD_DATE_PICKER_STATE, data };
};
export const mspClientSpendModalDatePickerState = (data) => {
  return { type: MSP_SPEND_MODAL_DATE_PICKER_STATE, data };
};
