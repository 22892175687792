import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const MspFrameworks = () => {
    const history = useHistory()
  return (
    <div className="w-50 mx-auto">
        <div className='f-16 f-600 mt-4 mb-2'>Security Frameworks</div>
        <div className='f-12 f-darkgrey'>Define what cybersecurity, information security and data privacy frameworks your organisation is aligned to</div>
      {/*Frameworks Block */}
      <div className="f-14 f-600 f-black mt-4 mb-2">
      Cybersecurity Frameworks
      </div>
      <div className="border-parent radius-8">
        {cyberSecurityFrameworks.map((item, index) => {
          return (
            <div
              className="d-flex flex-column border-item pointer"
              onClick={() => {
                history.push("/mspv2/secops/frameworks/frameworks-assets");
              }}
            >
              <div className="d-flex align-items-center justify-content-between w-100 pointer px-4  py-3 bg-hover-row">
                <div className="d-flex flex-column " style={{ width: "75%" }}>
                  <div className="d-flex align-items-baseline ">
                    {/* <img
                      alt=""
                      src="/images/attack-surface/yellow-dot.svg"
                      className="mr-1"
                      style={{
                        visibility: `${item.visible ? "hidden" : "visible"}`,
                      }}
                    /> */}
                    <p className="f-500 m-0 f-black">
                      {item.name}
                    </p>
                  </div>
                  <p className="f-12 f-darkgrey ">
                    {item?.subtitle}
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <div className="mr-5">
                    {/* <SecOpsCircularProgress progress={70} /> */}
                  </div>
                  <img alt="" src="/images/attack-surface/chevron-right.svg" />
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* Information Security Frameworks */}
      <div className="f-14 f-600 f-black mt-4 mb-2">
      Information Security Frameworks
      </div>
      <div className="border-parent radius-8">
        {informationSecurityFrameworks.map((item, index) => {
          return (
            <div
              className="d-flex flex-column border-item pointer"
              onClick={() => {
                history.push("/mspv2/secops/frameworks/frameworks-assets");
              }}
            >
              <div className="d-flex align-items-center justify-content-between w-100 pointer px-4  py-3 bg-hover-row">
                <div className="d-flex flex-column " style={{ width: "75%" }}>
                  <div className="d-flex align-items-baseline ">
                    {/* <img
                      alt=""
                      src="/images/attack-surface/yellow-dot.svg"
                      className="mr-1"
                      style={{
                        visibility: `${item.visible ? "hidden" : "visible"}`,
                      }}
                    /> */}
                    <p className="f-500 m-0 f-black">
                      {item.name}
                    </p>
                  </div>
                  <p className="f-12 f-darkgrey ">
                    {item?.subtitle}
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <div className="mr-5">
                    {/* <SecOpsCircularProgress progress={70} /> */}
                  </div>
                  <img alt="" src="/images/attack-surface/chevron-right.svg" />
                </div>
              </div>
            </div>
          );
        })}
      </div>



      {/* Data Privacy Frameworks */}
      <div className="f-14 f-600 f-black mt-4 mb-2">
      Data Privacy Frameworks
      </div>
      <div className="border-parent radius-8">
        {dataPrivacyFrameworks.map((item, index) => {
          return (
            <div
              className="d-flex flex-column border-item pointer"
              onClick={() => {
                history.push("/mspv2/secops/frameworks/frameworks-assets");
              }}
            >
              <div className="d-flex align-items-center justify-content-between w-100 pointer px-4  py-3 bg-hover-row">
                <div className="d-flex flex-column " style={{ width: "75%" }}>
                  <div className="d-flex align-items-baseline ">
                    {/* <img
                      alt=""
                      src="/images/attack-surface/yellow-dot.svg"
                      className="mr-1"
                      style={{
                        visibility: `${item.visible ? "hidden" : "visible"}`,
                      }}
                    /> */}
                    <p className="f-500 m-0 f-black">
                      {item.name}
                    </p>
                  </div>
                  <p className="f-12 f-darkgrey ">
                    {item?.subtitle}
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <div className="mr-5">
                    {/* <SecOpsCircularProgress progress={70} /> */}
                  </div>
                  <img alt="" src="/images/attack-surface/chevron-right.svg" />
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="p-5" />
    </div>
  )
}

export default MspFrameworks


const cyberSecurityFrameworks = [
  {
    name: "NIST Cybersecurity Framework",
    subtitle: "Last audit: 1 Jan 2024 • Next audit: 1 Mar 2024",
  },
  {
    name: "ISO 27001 and ISO 27002",
    subtitle: "Last audit: 1 Jan 2024 • Next audit: 1 Mar 2024",
  },
  {
    name: "SOC2",
  },
  {
    name: "NERC-CIP",
  },
  {
    name: "HIPAA",
  },
  {
    name: "GDPR",
  },
];



const informationSecurityFrameworks = [
    {
      name: "NIST Cybersecurity Framework",
    //   subtitle: "Last audit: 1 Jan 2024 • Next audit: 1 Mar 2024",
    },
    {
      name: "ISO 27001 and ISO 27002",
    //   subtitle: "Last audit: 1 Jan 2024 • Next audit: 1 Mar 2024",
    },
    {
      name: "ISO 27001 and ISO 27002",
    },
    {
      name: "ISO 27001 and ISO 27002",
    },
    {
      name: "ISO 27001 and ISO 27002",
    },
    {
      name: "ISO 27001 and ISO 27002",
    },
  ];



const dataPrivacyFrameworks = [
    {
      name: "GDPR",
    
    },
    {
      name: "HIPAA",
    
    },
    {
      name: "CCPA",
    },
    {
      name: "Singapore PDPA",
    },
    {
      name: "FedRamp",
    },
    {
      name: "HITRUST",
    },
  ];


 