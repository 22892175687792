import React from "react";
import { Modal, Button,   } from "react-bootstrap";
 
import "../mspIntegrations.scss";
 
import MspConnectModalLoader from "./MspConnectModalLoader";
import "./mspSpinner.scss";
 

const StaticLoaderModalForImporting = ({
  show,
  hideModal,
  integrationId = 607325,
  isEdit,
  refreshData,
}) => {
 const testing = false
  return (
    <Modal
      show={show}
      dialogClassName="  connect-integration-modal"
      className=""
      aria-labelledby="reset-settings-modal-title-vcenter"
      centered
      scrollable
      onHide={() => {
        hideModal();
      }}
    >
      <Modal.Body className="p-3 hide-scrollbar position-relative">
        {/* Testing page */}

        <>
          <div className="d-flex align-items-center justify-content-center">
            <div
              className={`d-flex align-items-center ${
                testing ? "" : "combined_images"
              }`}
            >
              <img
                width={48}
                height={48}
                alt=""
                className="mt-2 mb-3 border-ebc radius-8 left-image"
                src={`/images/attack-surface/integrations/${integrationId}.webp`}
              />

              <div>
                {testing ? (
                  <>
                    <img
                      alt=""
                      src="/images/msp/grey-refresh-icon.svg"
                      className="rotate-full mx-3"
                    />
                  </>
                ) : (
                  <div>
                    <img
                      alt=""
                      width={17}
                      height={17}
                      src="/images/attack-surface/suggestionSuccess.svg"
                      className="middle-image"
                    />
                  </div>
                )}
              </div>
              <img
                width={48}
                height={48}
                alt=""
                className="mt-2 mb-3 right-image"
                src="/images/attack-surface/integration-hg-logo.svg"
              />
            </div>
          </div>
          <div className="text-center f-16 f-500 f-black mb-2 d-flex align-items-center justify-content-center">
            <div className="f-16 f-500">
              {testing ? "Connection test..." : "Importing data.."}
            </div>

            <div className="f-16 f-darkgrey"></div>
          </div>
          <div className=" mb-2"></div>

          <div className="mx-2">
            {testing ? (
              <div
                className={` d-flex align-items-center  ${"f-black"}  `}
                style={{ height: "25px" }}
              >
                <div className="mr-3">
                  <MspConnectModalLoader />
                </div>
                Connection test...
              </div>
            ) : (
              <div
                className={` d-flex align-items-center  ${"f-black"} `}
                style={{ height: "25px" }}
              >
                <img
                  alt=""
                  src="/images/attack-surface/integration-green-tick.svg"
                  className=""
                  style={{ marginRight: "0.65rem" }}
                />
                Connection tested
              </div>
            )}
          </div>
          <div className="mx-2 d-none">
            {testing === false ? (
              <div
                className={` d-flex align-items-center  ${"f-black"}  `}
                style={{ height: "25px" }}
              >
                <div className="mr-3">
                  <MspConnectModalLoader />
                </div>
                Importing data..
              </div>
            ) : (
              <div
                className={` d-flex align-items-center  ${"f-darkgrey"} `}
                style={{ height: "25px" }}
              >
                <img
                  alt=""
                  src="/images/attack-surface/integration-green-tick.svg"
                  className=""
                  style={{ marginRight: "0.65rem", visibility: "hidden" }}
                />
                Importing data
              </div>
            )}
          </div>
        </>
        {testing ? (
          <div className="f-darkgrey position-absolute bottom-0"
          style={{
            left: '50%',
            width: '90%',
            transform: 'translate(-50%, -10%)',
          }}
          
          >
            Please wait whilst we test your connection.
          </div>
        ) : (
          <div className="f-darkgrey text-center position-absolute bottom-0"
          style={{
            left: '50%',
            width: '90%',
            transform: 'translate(-50%, -10%)',
          }}>
            Your integration is running in the background and may take up to 1hr
            to complete. 
            <br />
            <br />
            
             We will email you when its finished with a link
             We will email you when its finished with a link
            for you to complete the integration configuration.
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <div className="footer d-flex justify-content-end">
          <Button
            className="hg-cancel-btn f-500 w-100"
            onClick={() => {
              hideModal();
            }}
          >
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default StaticLoaderModalForImporting;

 

 
