import React, { useEffect, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getAPIData } from "../../Utils/commonApi";
import { TrackUser } from "../../Utils/SegmentFunctions";
import { viewOnlyAccess } from "../App/App";
import { useAuth } from "../context/Auth";
import Header from "../Header";
import ConnectIntegrationModal from "../MSPv2/integrations/ConnectIntegrationModal";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import SupportTicketV2 from "../settingsV2/supportTicketV2/SupportTicketV2";
import { IntegrationCard } from "./AppMarketPlace";
import "./integrationsv2.scss";
import UpgradeToPremiumModal from "./UpgradeToPremiumModal";

const IntegrationDetailsPage = () => {
  const [loading, setLoading] = useState(false);
  const isMenuCollapse = useSelector((state) => state?.global?.menuCollapse);
  const userDetails = useSelector((state) => state.user?.userDetail);
  const history = useHistory();
  const [supportTicketV2Modal, setSupportTicketV2Modal] = useState(false);
  const { authTokens } = useAuth();
  const [upgradeOptions, setUpgradeOptions] = useState({});
  const [pageData, setPageData] = useState({});
  const [selectedIntegration, setSelectedIntegration] = useState({});
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [selectedIntegrationId, setSelectedIntegrationId] = useState("");
  let { integrationId } = useParams();

  useEffect(() => {
    setLoading(true);
    getPageData();
  }, [integrationId]);

  async function showConnectToolModal(integrationId) {
    await getAPIData(`${502}`, authTokens)
      .then((response) => {
        if (response?.SuccessCount === 1) {
          setSelectedIntegrationId(integrationId);
          setShowConnectModal(true);
        } else if (
          response?.SuccessCount === 0 ||
          response?.SuccessCount === -1
        ) {
          setUpgradeOptions(
            response?.AccessObjList?.filter(
              (item) => item?.Question === "Integrations Visibility"
            )?.[0]?.UpgradeOptions ?? {}
          );

          setShowUpgradeModal(true);
        } else {
          CallToast(response?.Message, false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  async function getPageData() {
    await getAPIData("461", authTokens, {
      optionEnum1: integrationId,
    })
      .then((response) => {
        setLoading(false);
        if (response?.mr?.Success) {
          let selected_integration = [...response?.IntegrationList]
            .filter((item) => item?.IntegrationId > 0)
            .filter((item) => item?.IntegrationId === Number(integrationId))[0];
          setSelectedIntegration(selected_integration);
          let updated_data = {
            ...response,
            IntegrationList: [...response?.IntegrationList]
              .filter((item) => item?.IntegrationId > 0)
              .filter((item) => item?.IntegrationId !== Number(integrationId))
              .filter((item) => item?.IntegrationMarketplaceEnum !== 2),
          };
          setPageData(updated_data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  return (
    <>
      {loading ? (
        <IntegrationDetailsPageLoader />
      ) : (
        <div className="integration-details-page">
          <div>
            <header
              className="d-flex justify-content-between navbar sticky-top pb-0"
              style={{
                paddingTop: ` ${
                  userDetails?.AccountVerified === false ||
                  userDetails?.ShowConfirmVerified === true
                    ? "3em"
                    : "0.8em"
                } `,
                background: "#FFFFFF",
                position: "fixed",
                width: `${isMenuCollapse ? "92.25%" : "83.25%"}`,
                zIndex: 6,

                paddingLeft: "2rem",
                // borderBottom: `1px solid #EBEDF1`,
                paddingBottom: "8px",
              }}
            >
              <h3 className="f-16 f-600">Integrations</h3>

              <Header />
            </header>
          </div>
          <div
            className="d-flex align-items-center"
            style={{
              paddingTop: "4.5rem",
              position: "fixed",
              width: "100%",
              zIndex: 4,
              background: "white",
              paddingLeft: "1rem",
              paddingBottom: "0.5rem",
              borderBottom: "1px solid #ebecf1",
            }}
          >
            <div
              className=" pointer f-grey mr-3"
              onClick={() => {
                history.push("/integrations?selectedTab=appMarketPlace");
              }}
              style={{ top: "15px" }}
            >
              <img
                src="/images/icons/arrowLeft.svg"
                alt=""
                className="mr-2"
                height={10}
              />
              Back
            </div>

            {/* Integration Logo and name */}

            <div className="d-flex align-items-center">
              <img
                alt=""
                className="mr-2 radius-4"
                width={20}
                height={20}
                src={`/images/attack-surface/integrations/${integrationId}.webp`}
              />
              <div className="f-black f-16 f-500">
                {selectedIntegration?.IntegrationName}
              </div>
            </div>
          </div>

          {/* Header */}
          <div style={{ paddingTop: "6rem" }} />

          <div className="bg-grey d-flex align-items-center justify-content-between page-banner position-relative">
            <div className="d-flex ">
              <div>
                <img
                  src={`/images/attack-surface/integrations/${integrationId}.webp`}
                  alt=""
                  className="radius-8 mr-3"
                  width={50}
                  height={50}
                />
              </div>
              <div>
                <div className="f-24 f-600">
                  {" "}
                  {selectedIntegration?.IntegrationName}{" "}
                </div>
                <div className="f-grey f-16 mt-2 w-75">
                  {selectedIntegration?.IntegrationSummary}
                </div>
                <div className="d-flex align-items-center w-100 mt-2">
                  {selectedIntegration?.Tags_List?.map((item) => {
                    return (
                      <div
                        className="radius-2 p-1 px-2 f-12 f-500 mr-2"
                        style={{
                          background: `${"#616778"}30`,
                          color: `${"#616778"}`,
                        }}
                      >
                        {item === "Default" && (
                          <img
                            alt=""
                            className="mr-2"
                            src="/images/msp/integrations/default-integration-icon.svg"
                          />
                        )}
                        {item}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div>
              {selectedIntegration?.ButtonFunction !== 2 && (
                <OverlayTrigger
                  placement="bottom"
                  // defaultShow={true}
                  trigger={
                    viewOnlyAccess("AccessState_Integrations")
                      ? ["hover", "focus"]
                      : []
                  }
                  delay={{ show: 200, hide: 250 }}
                  overlay={
                    <Tooltip className="custom_tooltip_access-integr" id={`tooltip-1365`} >
                      This feature is managed by your service provider
                    </Tooltip>
                  }
                >
                  <div>
                    <Button
                      className={` ${
                        viewOnlyAccess("AccessState_Integrations")
                          ? "btn-disable"
                          : "hg-blue-btn"
                      } d-flex align-items-center`}
                      onClick={() => {
                        showConnectToolModal(integrationId);
                      }}
                    >
                      {viewOnlyAccess("AccessState_Integrations") ? (
                        <img
                          alt=""
                          src="/images/attack-surface/small-lock.svg"
                          className="mr-2"
                        />
                      ) : (
                        <img
                          src="/images/attack-surface/white-plus-icon.svg"
                          alt=""
                          className=" mr-2"
                        />
                      )}
                      Connect
                    </Button>
                  </div>
                </OverlayTrigger>
              )}
              {selectedIntegration?.ButtonFunction === 2 && (
                <div className="f-green">
                  <img
                    alt=""
                    src="/images/attack-surface/voted-tick.svg"
                    className="mr-1"
                  />
                  Connected
                </div>
              )}
            </div>
          </div>

          {/* Details */}
          <div className="d-flex w-100">
            <div className="pt-5 pl-4" style={{ width: "22%" }}>
              <div className="f-600 f-black">Resources</div>
              <div className="f-blue mt-3">
                <a
                  rel="noopener noreferrer"
                  href={selectedIntegration?.IntegrationDeveloperSite}
                  target="_blank"
                >
                  Developer's site
                  <img
                    alt=""
                    className="ml-1"
                    src="/images/msp/integrations/blue-goto.svg"
                  />
                </a>
              </div>

              <div className="my-3 f-black f-600">Help</div>
              <div className="ml-2">
                <div
                  className="f-grey mb-2 pointer"
                  onClick={() => {
                    if (selectedIntegration?.Integration_KB_URL) {
                      window.open(
                        selectedIntegration?.Integration_KB_URL,
                        "_blank"
                      );
                      TrackUser("How to connect tool clicked", {
                        type: selectedIntegration?.IntegrationName,
                      });
                    }
                  }}
                >
                  <img
                    src="/images/settings-v2/knowledgebase.svg"
                    alt=""
                    className="mr-2"
                  />
                  How to connect
                </div>
                <div
                  className="f-grey mb-2 pointer"
                  onClick={() => {
                    // window.open(
                    //   "https://join.slack.com/t/highgroundio/shared_invite/zt-19tfj8ujn-_rHexr3mFsH~aI~q7Y47Sg",
                    //   "_blank"
                    // );
                    window.open(
                      "https://chat.whatsapp.com/C0W3c8N0owfCQeof5auWjG",
                      "_blank"
                    );
                  }}
                >
                  <img
                    src="/images/settings-v2/askcommunity.svg"
                    alt=""
                    className="mr-2"
                  />
                  Join our MSP Community
                </div>
                <div
                  className="f-grey mb-2 pointer"
                  onClick={() => {
                    // setSupportTicketV2Modal(true);
                    window.Intercom('showNewMessage');
                  }}
                >
                  <img
                    src="/images/settings-v2/contactsupport.svg"
                    alt=""
                    className="mr-2"
                  />
                  Contact Support
                </div>
              </div>
            </div>
            <div className="pr-4 pt-3" style={{ width: "78%" }}>
              <div className="f-24 f-600 pt-4">About</div>
              <div className="pt-3" style={{ textAlign: "justify" }}>
                {selectedIntegration?.IntegrationAbout}
              </div>

              {/* Tools list */}
              <div className="f-16 f-600 my-3 pt-4">
                More {selectedIntegration?.TechnologyTitle} tools:
              </div>
              <div className="d-flex flex-wrap">
                {pageData?.IntegrationList?.map(
                  (integration, integrationIndex) => {
                    return (
                      <IntegrationCard
                        integration={integration}
                        isAllTool={true}
                        refreshData={getPageData}
                        showConnectToolModal={showConnectToolModal}
                      />
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <ConnectIntegrationModal
        show={showConnectModal}
        hideModal={() => setShowConnectModal(false)}
        integrationId={selectedIntegrationId}
        refreshData={getPageData}
      />
      <SupportTicketV2
        show={supportTicketV2Modal}
        hideModal={() => setSupportTicketV2Modal(false)}
      />
      <UpgradeToPremiumModal
        show={showUpgradeModal}
        hideModal={() => setShowUpgradeModal(false)}
        upgradeOptions={upgradeOptions}
      />
    </>
  );
};

export default IntegrationDetailsPage;

const IntegrationDetailsPageLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="1%" y="10%" rx="8" ry="8" width="70" height="70" />
      <rect x="7%" y="10%" rx="4" ry="4" width="200" height="35" />
      <rect x="7%" y="15%" rx="4" ry="4" width="500" height="22" />

      <rect x="7%" y="18.5%" rx="4" ry="4" width="190" height="35" />
      <rect x="20%" y="18.5%" rx="4" ry="4" width="100" height="35" />
      <rect x="90%" y="16%" rx="4" ry="4" width="130" height="37" />

      <rect x="0%" y="29%" rx="4" ry="4" width="100%" height="1" />

      <rect x="0.8%" y="35%" rx="4" ry="4" width="150" height="18" />
      <rect x="1.5%" y="40%" rx="4" ry="4" width="180" height="18" />

      <rect x="1.5%" y="48%" rx="4" ry="4" width="180" height="15" />
      <rect x="1.5%" y="51%" rx="4" ry="4" width="180" height="15" />
      <rect x="1.5%" y="54%" rx="4" ry="4" width="180" height="15" />

      <rect x="30%" y="44%" rx="4" ry="4" width="700" height="13" />
      <rect x="30%" y="47%" rx="4" ry="4" width="570" height="13" />
      <rect x="30%" y="50%" rx="4" ry="4" width="700" height="13" />
      <rect x="30%" y="53%" rx="4" ry="4" width="590" height="13" />
      <rect x="30%" y="56%" rx="4" ry="4" width="610" height="13" />
      <rect x="30%" y="59%" rx="4" ry="4" width="690" height="13" />
      <rect x="30%" y="62%" rx="4" ry="4" width="410" height="13" />

      <rect x="30%" y="70%" rx="8" ry="8" width="420" height="190" />
      <rect x="61%" y="70%" rx="8" ry="8" width="420" height="190" />
    </ContentLoader>
  );
};
