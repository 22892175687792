import React, { useEffect, useState, lazy, Suspense } from "react";
import { Modal, Button, Col, Row, Dropdown } from "react-bootstrap";
import { getAPIData, postData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import CompanySettingsDropdown from "../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import { WhiteLoader } from "../../Theme/APILoaders";
import CompanyDetailsDropdown, {
  CompanyServicesProvidedDropdown,
} from "./companyDetailsDropdowns/companyDetailsDropdown";
import "./supply-modals.scss";
import Loader from "../../Common/loader";
import ChangeAccessLevelModal from "../RequestsModal/ChangeAccessLevelModal";
// import AddComplianceProfileModal from "../AddComplianceProfileModal/AddComplianceProfileModal";
import { TrimString } from "../../../Utils/StringTrimmer";
const AddComplianceProfileModal = lazy(() => import("../AddComplianceProfileModal/AddComplianceProfileModal"));

const CompanyDetailsModal = ({ show, hideModal, supplierId,refreshData }) => {
  const { authTokens } = useAuth();
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showChangeAccessLevelModal, setShowChangeAccessLevelModal] =
    useState(false);

  const [supplierDetails, setSupplierDetails] = useState({});
  useEffect(() => {
    if (show) {
      setLoading(true);
      getModalData();
    }
  }, [show]);

  async function getModalData(dataUpdate = false) {
    await getAPIData(`${341}`, authTokens, {
      optionEnum1: supplierId,
    })
      .then((response) => {
        if (response?.mr?.Success) {
          getAccessData();
          setImportanceList(response?.SupplierCategoryImportance_List);
          setIndustryList(response?.SupplierCategoryIndustry_List);

          // Supplier details
          setSupplierDetails(response?.SCMSupplierDetail);

          setAnnualSpendList(response?.SupplierCategorySpend_List);
          setTypeList(response?.SupplierCategoryType_List);
          setServicesProvidedList(response?.SupplierCategoryServices_List);
          setComplianceProfileList(response?.ComplianceProfile_List);
          if (dataUpdate === false) {
            setSelectedType(response?.SupplierCategoryTypeSelected);
            setSelectedIndustry(response?.SupplierCategoryIndustrySelected);
            setSelectedImportance(response?.SupplierCategoryImportanceSelected);
            setSelectedAnnualSpend(response?.SupplierCategorySpendSelected  ?? {"Id":1,"Text":"","SortOrder":1});
            setSelectedComplianceProfile(response?.ComplianceProfileSelected);

            if (response?.SupplierCategoryServicesSelected_List) {
              setSelectedServicesProvided(
                response?.SupplierCategoryServicesSelected_List
              );
            } else {
              setSelectedServicesProvided([]);
            }
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  const [complianceProfile, setComplianceProfile] = useState({
    id: 0,
    category: "Key Suppliers",
  });

  const [typeList, setTypeList] = useState([]);
  const [selectedType, setSelectedType] = useState("");

  const [industryList, setIndustryList] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState("");

  const [importanceList, setImportanceList] = useState([]);
  const [selectedImportance, setSelectedImportance] = useState("");

  const [annualSpendList, setAnnualSpendList] = useState([]);
  const [selectedAnnualSpend, setSelectedAnnualSpend] = useState("");

  const [servicesProvidedList, setServicesProvidedList] = useState([]);
  const [selectedServicesProvided, setSelectedServicesProvided] = useState([]);

  const [complianceProfileList, setComplianceProfileList] = useState([]);
  const [selectedComplianceProfile, setSelectedComplianceProfile] = useState(
    {}
  );
  const [showAddComplianceModal, setShowAddComplianceModal] = useState(false);

  const [accessLevelData, setAccessLevelData] = useState();

  async function createCategory(type, value) {
    let data = {
      SupplierCategoryTypeSelected: null,
      SupplierCategoryIndustrySelected: null,
      SupplierCategoryImportanceSelected: null,
      SupplierCategorySpendSelected: null,
      SupplierCategoryServicesSelected: null,
      SupplierCategoryServices_List: null,
      SupplierCategoryType_List: null,
      SupplierCategoryIndustry_List: null,
      SupplierCategoryImportance_List: null,
      SupplierCategorySpend_List: null,
      // mr: null,
    };
    let updated_data = {
      ...data,
      [type]: value,
    };

    await postData(authTokens, "CreateSupplierCategory", {
      SupplierCategory: JSON.stringify(updated_data),
    }).then((response) => {
      if (response?.mr?.Success) {
        getModalData(true);
      }
    });
  }

  async function updatedSelection() {
    setUpdating(true);
    await postData(authTokens, "UpdateSelectedAdHocValue", {
      objId_Supplier: supplierId,
      ComplianceProfileSelected: JSON.stringify(selectedComplianceProfile),
      SupplierCategoryTypeSelected: JSON.stringify(selectedType),
      SupplierCategoryIndustrySelected: JSON.stringify(selectedIndustry),
      SupplierCategoryImportanceSelected: JSON.stringify(selectedImportance),
      SupplierCategorySpendSelected: JSON.stringify(selectedAnnualSpend),
      SupplierCategoryServicesSelected: JSON.stringify(
        selectedServicesProvided
      ),
    })
      .then((response) => {
        setUpdating(false);
        if (response?.Success) {
          hideModal();
          if(refreshData){
            refreshData()
          }
          CallToast("Updated Successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        setUpdating(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // get access level data
  async function getAccessData() {
    await getAPIData(`${346}`, authTokens, {
      OptionEnum1: supplierId,
      optionEnum2: 1,
    })
      .then((response) => {
        setLoading(false);
        if (response?.mr?.Success) {
          if (
            response?.AccessLevelVM &&
            response?.AccessLevelVM?.AccessLevelSelection_List?.length > 0
          ) {
            setAccessLevelData(
              makeGroup(
                response?.AccessLevelVM?.AccessLevelSelection_List || [],
                "Section"
              )
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }
  return (
    <Modal
      // show={true}
      show={show}
      dialogClassName={`big-modal  ${show ? "right" : "rightBack"} `}
      className={`common-big-modal  company-details-modal`}
      aria-labelledby="big-modal"
      onHide={hideModal}
      centered
      scrollable
    >
      <Modal.Header className="text-center">
        <div
          className="w-100 mb-0 font-bold-14 title"
          style={{ fontWeight: "600" }}
        >
          Details
        </div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img src="/images/attack-surface/cross-icon.svg" alt="" />
        </span>
      </Modal.Header>

      <Modal.Body className="hide-scrollbar position-relative">
        {loading ? (
          <div className="d-flex align-items-center justify-content-center w-100 h-100">
            <Loader />
          </div>
        ) : (
          <div className="px-2 pt-3">
            {/* User Details */}
            <div className="d-flex align-items-center">
              <img
                src={supplierDetails?.Obj_CustomerLogo}
                alt=""
                className="mr-2 rounded-full"
                width={48}
                height={48}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "/images/settings-v2/highground-dp.svg";
                }}
              />
              <div>
                <p className="f-16 f-600 m-0 mb-1">
                  {supplierDetails?.Obj_CustomerName}
                </p>
                <p className="f-14 f-500 f-darkgrey m-0">
                  {supplierDetails?.SupplyChainId}
                </p>
              </div>
            </div>
            <p className="f-black f-500 mt-3">
              {supplierDetails?.Obj_CustomerName} has been sharing their
              HighGround data with you since{" "}
              <strong>{supplierDetails?.AcceptedDate}</strong>
            </p>

            {/* Company Attributes Data */}
            <div className="w-100 mt-3">
              {/* compliance profile */}
              <div className="d-flex w-100 align-items-center justify-content-between mb-2">
                <div className="f-14 f-600">Compliance profile</div>
                <div className="attribute-dropdown">
                  <div className="compliance-filter-dropdow n user-detail-dropdown">
                    <Dropdown className="device-dropdown">
                      <Dropdown.Toggle>
                        <div className="d-flex align-items-center">
                          {selectedComplianceProfile ? (
                            <div className="f-black f-500 mr-2">
                              {TrimString(selectedComplianceProfile?.Text, 22)}
                            </div>
                          ) : (
                            <div className="f-grey mr-2">Choose...</div>
                          )}
                          <img
                            alt=""
                            className=""
                            src="/images/supply-chain/company-chevron-down.svg"
                          />
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="hg-bg-white hide-scrollbar"
                        style={{
                          maxHeight: "225px",
                          overflowY: "scroll",
                          overflowX: "hidden",
                        }}
                      >
                        <div>
                          <Row>
                            {complianceProfileList?.map(
                              (profile, profileIndex) => {
                                return (
                                  <Col xs={12}>
                                    <div
                                      style={{ marginTop: "3px" }}
                                      className={`notification-btn bg-hover-grey-light ${
                                        profile?.Id ===
                                        selectedComplianceProfile?.Id
                                          ? "bg-grey-light"
                                          : ""
                                      }`}
                                      onClick={(e) => {
                                        setSelectedComplianceProfile({
                                          Id: profile.Id,
                                          Text: profile.Text,
                                        });
                                        // updateComplianceProfile(
                                        //   profile?.ObjectId
                                        // );
                                        // getAccessDetails(profile?.ObjectId);
                                        e.target
                                          .closest(".device-dropdown")
                                          .click();
                                      }}
                                    >
                                      {profile?.Text}
                                    </div>
                                  </Col>
                                );
                              }
                            )}

                            <hr
                              className=" line-divider-light "
                              style={{
                                width: "80%",
                                margin: "auto",
                                /* margin-left: auto; */
                                marginTop: "12px",
                                marginBottom: "8px",
                              }}
                            />
                            <Col xs={12}>
                              <div
                                style={{
                                  marginBottom: "8px",
                                }}
                                className="notification-btn bg-hover-grey-light"
                                onClick={(e) => {
                                  setShowAddComplianceModal(true);
                                  e.target.closest(".device-dropdown").click();
                                }}
                              >
                                Add compliance profile
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>

              {/*type */}
              <div className="d-flex w-100 align-items-center justify-content-between mb-2">
                <div className="f-14 f-600">Type</div>
                <div className="attribute-dropdown">
                  <CompanyDetailsDropdown
                    data={[...typeList.map((item, itemIndex) => item.Text)]}
                    selected={selectedType?.Text}
                    onChange={(value) => {
                      let Index = typeList.findIndex(
                        (item) => item.Text === value
                      );
                      if (Index !== -1) {
                        setSelectedType(typeList[Index]);
                      }
                    }}
                    onAdd={(value) => {
                      let data = {
                        Id: 0,
                        Text: value,
                        SortOrder: typeList?.length,
                      };
                      createCategory("SupplierCategoryTypeSelected", data);
                    }}
                  />
                </div>
              </div>

              {/*Industry */}
              <div className="d-flex w-100 align-items-center justify-content-between mb-2">
                <div className="f-14 f-600">Industry</div>
                <div className="attribute-dropdown">
                  <CompanyDetailsDropdown
                    data={[...industryList.map((item, itemIndex) => item.Text)]}
                    selected={selectedIndustry?.Text}
                    onChange={(value) => {
                      let Index = industryList.findIndex(
                        (item) => item.Text === value
                      );
                      if (Index !== -1) {
                        setSelectedIndustry(industryList[Index]);
                      }
                    }}
                    onAdd={(value) => {
                      let data = {
                        Id: 0,
                        Text: value,
                        SortOrder: industryList?.length,
                      };
                      createCategory("SupplierCategoryIndustrySelected", data);
                    }}
                  />
                </div>
              </div>
              {/*Importance */}
              <div className="d-flex w-100 align-items-center justify-content-between mb-2">
                <div className="f-14 f-600">Importance</div>
                <div className="attribute-dropdown">
                  <CompanyDetailsDropdown
                    data={[
                      ...importanceList.map((item, itemIndex) => item.Text),
                    ]}
                    selected={selectedImportance?.Text}
                    onChange={(value) => {
                      let Index = importanceList.findIndex(
                        (item) => item.Text === value
                      );
                      if (Index !== -1) {
                        setSelectedImportance(importanceList[Index]);
                      }
                    }}
                    onAdd={(value) => {
                      let data = {
                        Id: 0,
                        Text: value,
                        SortOrder: importanceList?.length,
                      };
                      createCategory(
                        "SupplierCategoryImportanceSelected",
                        data
                      );
                    }}
                  />
                </div>
              </div>
              {/*Industry */}
              <div className="d-flex w-100 align-items-center justify-content-between mb-2">
                <div className="f-14 f-600">Annual spend</div>
                <div className="attribute-dropdown">

                  <div className="bg-grey radius-4 p-2 highlight-input">
                    <span>£</span><input type="text" className="border-none bg-transparent pl-1" placeholder="Type annual spend" value={selectedAnnualSpend?.Text ?? ""}
                    onChange={(e)=>{
                      setSelectedAnnualSpend({
                        ...selectedAnnualSpend,
                        Text:e.target.value
                      })
                    }}
                    
                    />
                    <span>/year</span>
                  </div>

                  {/* <CompanyDetailsDropdown
                    data={[
                      ...annualSpendList.map((item, itemIndex) => item.Text),
                    ]}
                    selected={selectedAnnualSpend?.Text}
                    onChange={(value) => {
                      let Index = annualSpendList.findIndex(
                        (item) => item.Text === value
                      );
                      if (Index !== -1) {
                        setSelectedAnnualSpend(annualSpendList[Index]);
                      }
                    }}
                    onAdd={(value) => {
                      let data = {
                        Id: 0,
                        Text: value,
                        SortOrder: annualSpendList?.length,
                      };
                      createCategory("SupplierCategorySpendSelected", data);
                    }}
                  /> */}
                </div>
              </div>
              {/* Services provided */}
              <div
                className={`w-100  ${
                  selectedServicesProvided?.length === 0
                    ? "d-flex align-items-center justify-content-between"
                    : ""
                }   `}
              >
                <div className="f-14 f-600 ">Services Provided</div>
                <div className="d-flex flex-wrap">
                  {selectedServicesProvided &&
                    selectedServicesProvided?.map((service, index) => {
                      return (
                        <div className="d-flex align-items-center p-2 bg-grey radius-2 m-1">
                          <div className="f-500 f-grey d-flex align-items-center">
                            {service.Text}
                          </div>
                          <img
                            alt=""
                            className="ml-2 pointer"
                            src="/images/supply-chain/services-cross.svg"
                            onClick={() => {
                              let updated_data = [...selectedServicesProvided];
                              updated_data.splice(index, 1);
                              setSelectedServicesProvided(updated_data);
                            }}
                          />
                        </div>
                      );
                    })}

                  <CompanyServicesProvidedDropdown
                    data={servicesProvidedList}
                    selected={selectedServicesProvided}
                    onChange={(value) => {
                      let Index = selectedServicesProvided?.findIndex(
                        (item) => item.Text === value.Text
                      );

                      if (Index === -1) {
                        setSelectedServicesProvided([
                          ...selectedServicesProvided,
                          value,
                        ]);
                      }
                    }}
                    onAdd={(value) => {
                      let data = {
                        Id: 0,
                        Text: value,
                        SortOrder: servicesProvidedList?.length,
                      };
                      createCategory("SupplierCategoryServicesSelected", data);
                    }}
                  />
                </div>
              </div>

              <hr />

              <div className="w-100 d-flex align-items-center justify-content-between my-2">
                <div className="f-14 f-black f-600">Access level</div>
                <div
                  className="f-blue pointer"
                  onClick={() => {
                    setShowChangeAccessLevelModal(true);
                  }}
                >
                  Change
                </div>
              </div>
              {accessLevelData?.map((access, accessIndex) => {
                return (
                  <>
                    <div className="f-12 f-500 f-darkgrey mt-4 mb-3">
                      {access?.Section}
                    </div>
                    {access?.data?.map((item) => {
                      return (
                        <div className="w-100 d-flex align-items-center justify-content-between mt-2 mb-3">
                          <div className="f-14 f-black f-600">
                            {item?.SubSection}{" "}
                          </div>
                          <div className="f-grey pointer">
                            {item?.PermissionSelected?.Permission}{" "}
                          </div>
                        </div>
                      );
                    })}
                  </>
                );
              })}
            </div>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer className="p-2 position-relative">
        <div
          className="position-absolute"
          style={{
            height: "27px",
            width: "103%",
            background: "linear-gradient(180deg, #ffffff00,#ffffff)",
            top: "-60%",
            left: "-10px",
          }}
        ></div>
        <div className="d-flex align-items-center justify-content-end">
          <Button className="hg-cancel-btn mr-2" onClick={hideModal}>
            Cancel
          </Button>
          <Button className="hg-blue-btn" onClick={updatedSelection}>
            {updating ? (
              <div className="d-flex align-items-center">
                <WhiteLoader width={12} />
                Updating..
              </div>
            ) : (
              "Update"
            )}
          </Button>
        </div>
      </Modal.Footer>
      <ChangeAccessLevelModal
        show={showChangeAccessLevelModal}
        hideModal={() => {
          setShowChangeAccessLevelModal(false);
        }}
        supplierId={supplierId}
        refreshData={() => {
          setLoading(true);
          getModalData();
        }}
      />

      <Suspense fallback={<div>Loading...</div>}>
        <AddComplianceProfileModal
          show={showAddComplianceModal}
          hideModal={() => setShowAddComplianceModal(false)}
          complianceProfileId={0}
          refreshData={() => {
            getModalData(true);
          }}
        />
      </Suspense>
    </Modal>
  );
};

export default CompanyDetailsModal;

function makeGroup(list, key) {
  if (list?.length === 0) return;

  let resultArr = [];
  let types = [];
  for (let i = 0; i < list.length; i++) {
    if (types.indexOf(list[i][key]) > -1) {
      let indexPosition = types.indexOf(list[i][key]);
      resultArr[indexPosition]["data"].push(list[i]);
      resultArr[indexPosition][key] = list[i][key];
    } else {
      types.push(list[i][key]);
      let indexPosition = types.indexOf(list[i][key]);
      if (typeof resultArr[indexPosition] === "object") {
        resultArr[indexPosition]["data"].push(list[i]);
        resultArr[indexPosition][key] = list[i][key];
      } else {
        resultArr[indexPosition] = {};
        resultArr[indexPosition]["data"] = [];
        resultArr[indexPosition]["data"].push(list[i]);
        resultArr[indexPosition][key] = list[i][key];
      }
    }
  }

  return resultArr;
}
