import React, { Fragment, useState, useEffect } from "react";
import { Col } from "react-bootstrap";

import "./MyIntegrationBlock.css";
import Confetti from "react-dom-confetti";
import { TrackUser } from "../../../Utils/SegmentFunctions";

const MyIntegrationBlock = (props) => {
  const { showTitle, detail } = props;
  const [data, setData] = useState(props.data);
  const [isExploding, setIsExploding] = useState(false);

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  const config = {
    angle: 0,
    spread: "360",
    startVelocity: 50,
    elementCount: 70,
    dragFriction: 0.5,
    duration: 3000,
    stagger: 5,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  async function handleDetail(id, button_function) {
    if (button_function != 0) {
      const response = await detail();
     
      if (response && response?.Success == true) {
        setData((data) => ({
          ...data,
          ButtonFunction: 0,
          IntegrationState: response.SuccessCount,
          IntegrationStateIconURL:
            "https://cybercareimages.blob.core.windows.net/icons/SmallArrowUpWhite.svg",
        }));

        setIsExploding(true);
        setTimeout(() => {
          setIsExploding(false);
        }, 3000);
      }
    }
  }

  return (
    <Fragment>
      <Col
        key={data.SortOrder}
        xs={12}
        md={6}
        lg={4}
        className="mb-4"
        sort={data.SortOrder}
      >
        <div
          className="myIntegrationBox d-flex flex-column justify-content-between"
          id={"box" + data.IntegrationId}
        >
          {data.IntegrationFileName && (
            <img
              alt=""
              width="100%"
              src={`/images/attack-surface/integrations/largeIcons/${data?.IntegrationId}.webp`}
              className="mainImg"
            />
          )}
          {/* <div style={{ marginLeft: "150px" }}>
            <Confetti active={isExploding} config={config} />
          </div> */}
          <div className="d-flex justify-content-between p-2 pb-3">
            <div className="align-self-center">
              <span
                className="settings_emailSubHeader"
                style={{ color: data.IntegrationProgressColour }}
              >
                {data.IntegrationProgress}
              </span>
              <h1 className="m-0">{data.IntegrationName}</h1>
              {showTitle && (
                <span className="settings_emailSubHeader">
                  {data.TechnologyTitle}
                </span>
              )}
            </div>
            <span
              className={`d-flex explosion statusBox justify-content-center align-self-center text-right ${
                data.ButtonFunction != 0 && "cursor-pointer"
              }`}
              style={{
                background:
                  data.IntegrationStateColour +
                  (data.ButtonFunction == 0 && !showTitle ? "" : 20),
              }}
              onClick={() => {
                handleDetail(data.IntegrationId, data.ButtonFunction);

                TrackUser(`Tool selected`, { type: data.IntegrationName });
              }}
            >
              <img alt="" src={data.IntegrationStateIconURL} />
              <span
                className="integrationsmalltxtstate"
                style={{
                  color:
                    data.ButtonFunction == 0 && !showTitle
                      ? "white"
                      : data.IntegrationStateColour,
                }}
              >
                {data.IntegrationState}
                <Confetti active={isExploding} config={config} />
              </span>
            </span>
          </div>
        </div>
      </Col>
    </Fragment>
  );
};

export default MyIntegrationBlock;
