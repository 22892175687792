import React, { Fragment, useState, useEffect } from "react";
import {
  Table,
  Button,
  Form,
  Row,
  Col,
  ButtonGroup,
  ToggleButton,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { getAPIData } from "../../../Utils/commonApi";
import ModalDynamic from "../../ModalDynamic/ModalDynamic";
import LoadingSpinner from "../../Spinner/Spinner";
import { useAuth } from "../../context/Auth";
import { useHistory } from "react-router-dom";
import ImageUploadModal from "./imageUploadModal";
import { useDb } from "../../context/db";
import { FaLessThanEqual } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiCheck, FiX } from "react-icons/fi";
import Header from "../../Header";
import CircularProgressBar from "../../CircularProgressBar";
import Notification from "../../Notification/Notification";
import ContentLoader from "react-content-loader";
import { TrackUser } from "../../../Utils/SegmentFunctions";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";

const UserProfile = (props) => {
  let history = useHistory();
  useEffect(() => {
    getUserDevicesDetails();
  }, []);
  const { dbObj } = useDb();
  const { authTokens } = useAuth();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [userProfileDevice, setUserProfileDevice] = useState([]);
  const [loading, setLoading] = useState(false);
  const [radioValue, setRadioValue] = useState(dbObj.UserDetail.Role || "CEO");
  const [mobileListId, setMobileListId] = useState(0);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [getPersonalDevices, setPersonalDevices] = useState([]);
  const [disableUser, setDisableUser] = useState(false);
  const [mfaReset, setMfaReset] = useState(false);
  const [resetPass, setResetPass] = useState(false);
  const [disableResetPass, setDisableResetPass] = useState(false);
  const [disableMfa, setDisableMfa] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [userEnable, setUserEnable] = useState("");
  const [removeDeviceId, setRemoveDeviceId] = useState("");
  const deviceID = dbObj && dbObj.UserDetail.objId_UserSettings;
  const [userDeviceId, setDeviceId] = useState(props.match.params.id || "");
  const [remove, isRemove] = useState(false);
  const [showToast, setShowToast] = useState(false);
  useEffect(() => {
    getdeviceeData();
  }, []);

  async function getdeviceeData() {
    const response = await getAPIData(102, authTokens);
    setPersonalDevices(response);
  }
  const radios = [
    { name: "CEO", value: "CEO" },
    { name: "CTO", value: "CTO" },
    { name: "CFO", value: "CFO" },
  ];

  const referralData = () => {
    return (
      <div className="container-grey p-0">
        <div>
          <div className="mt24px ml24px mr24px mb40px">
            <img src="/images/icons/bell.svg" className="float-left" />
            <span className="textBlack" style={{ marginLeft: "14px" }}>
              Send Notification
            </span>
          </div>
          <div className="ml40px mr40px">
            <Notification
              index={0}
              device={mobileListId}
              role={1}
              type="scoretab"
              company="test"
              value={getPersonalDevices.CyberScore.CyberScoreNumber}
              time="3s ago"
              heading="CyberScore"
              text={`Your CyberScore has decreased to   ${getPersonalDevices.CyberScore.CyberScoreNumber}%`}
              color="#007BD4"
            />
            <Notification
              index={1}
              device={mobileListId}
              role={1}
              type="threattab"
              company="test"
              value={getPersonalDevices.CyberScore.CyberThreatNumber}
              time="3s ago"
              heading="CyberThreat Level"
              text={`Your CyberThreat Level has increased to   ${getPersonalDevices.CyberScore.CyberThreatNumber}%`}
              color="#007BD4"
            />
            <Notification
              index={2}
              device={mobileListId}
              role={1}
              type="compliancetab"
              company="test"
              value={getPersonalDevices.CyberScore.CyberComplianceNumber}
              time="3s ago"
              heading="CyberCompliance"
              text={`Your CyberCompliance has decreased to   ${getPersonalDevices.CyberScore.CyberComplianceNumber}%`}
              color="#007BD4"
            />
          </div>
        </div>
      </div>
    );
  };

  async function getUserDevicesDetails() {
    const result = await getAPIData("101", authTokens);
    setUserProfileDevice(result.UserAndDevices);

    setUserEnable(result.UserAndDevices[userDeviceId].User_Status);
    setToggle(
      result.UserAndDevices[userDeviceId].User_Status == "Disabled"
        ? "Enable"
        : "Disable"
    );
  }

  async function handleResetMfa() {
    setMfaReset(true);
    const response = await getAPIData("108", authTokens, {
      optionEnum1: deviceID,
    });
    if (response.Success == true) {
      setMfaReset(false);
      setDisableMfa(false);
    }
    if (response.Success == false) {
      setMfaReset(false);
    }
  }
  async function handleResetPass() {
    setResetPass(true);
    const response = await getAPIData("107", authTokens, {
      optionEnum1: deviceID,
    });
    if (response.Success == true) {
      setResetPass(false);
      setDisableResetPass(true);
      
        CallToast("Password reset email sent", false, false, "v2style");
    }
    if (response.Success == false) {
      setResetPass(false);
    }
  }

  async function handleDeleteUser() {
    setShowToast(true);
    await getAPIData("109", authTokens, {
      optionEnum1: deviceID,
    })
      .then((response) => {
        if (response.Success == true) {
          toast.dark(
            <span>
              <FiCheck style={{ marginRight: "10px" }} />
              Profile updated successfully
              <span className="float-right">
                <CircularProgressBar durationInSec="30" />
              </span>
            </span>
          );
        } else {
          toast.dark(
            <span>
              <FiX style={{ marginRight: "10px", color: "#f36060" }} />
              <span style={{ width: "500px", color: "#f36060" }}>
                Unable to update
              </span>
              <span className="float-right">
                <CircularProgressBar durationInSec="30" />
              </span>
            </span>
          );
        }
      })
      .catch((err) => {
        toast.dark(
          <span>
            <FiX style={{ marginRight: "10px", color: "#f36060" }} />
            <span style={{ width: "500px", color: "#f36060" }}>
              Unable to update
            </span>
            <span className="float-right">
              <CircularProgressBar durationInSec="30" />
            </span>
          </span>
        );
      });
  }
  async function handleRemove(deviceId, index) {
    userProfileDevice[userDeviceId].MobileList.map((data) => {
      if (data.DeviceId == deviceId) {
        data.removing = true;
      }
    });
    setUserProfileDevice({
      ...userProfileDevice,
      userProfileDevice: userProfileDevice.MobileList,
    });
    setRemoveDeviceId(deviceId);
    const response = await getAPIData("105", authTokens, {
      optionEnum1: deviceId,
    });
    if (response.Success == true) {
      TrackUser("Device deleted");
      getUserDevicesDetails();

      // const updatedDevices = userProfileDevice[userDeviceId].MobileList.splice(
      //   index,
      //   1
      // );
      // if (userProfileDevice[userDeviceId].MobileList < 0) {
      //   setUserProfileDevice({
      //     ...userProfileDevice,
      //     userProfileDevice: userProfileDevice.MobileList,
      //   });
      // }
      setShowToast(true);
      toast.dark(
        <span>
          <FiCheck style={{ marginRight: "10px" }} />
          Profile updated successfully
          <span className="float-right">
            <CircularProgressBar durationInSec="30" />
          </span>
        </span>
      );
    }
  }

  async function handleEnableUser() {
    setLoading(true);
    const response = await getAPIData("106", authTokens, {
      optionEnum1: deviceID,
    });
    if (response.Success == true) {
      const result = await getAPIData("101", authTokens, {
        optionEnum1: deviceID,
      });
      setUserEnable(result.User_Status);
      if (userEnable == "Disabled") {
        setToggle("Disable");
        setUserEnable("Enabled");
      } else {
        setUserEnable("Disabled");
        setToggle("Enable");
      }
      setLoading(false);
      setDisableUser(true);
    }
  }

  const handleNotifModal = (userDeviceId) => {
    setMobileListId(userDeviceId);
    setShowNotificationModal(false);
    setInterval(() => {
      setShowNotificationModal(true);
    }, 100);
  };
  if (userProfileDevice && userProfileDevice[userDeviceId]) {
    return (
      <Fragment>
        {showToast && (
          <ToastContainer
            position="bottom-center"
            autoClose={3000}
            hideProgressBar
          />
        )}
        <Row className="fullHeight">
          <Col xs={12} className="px-0">
            {userProfileDevice[userDeviceId] && (
              <div className="dashboard fullHeight">
                <header
                  className="d-flex justify-content-between navbar sticky-top pb-0"
                  style={{ paddingTop: "1em", background: "#FFFFFF" }}
                >
                  <h1>System Settings</h1>
                  <Header />
                </header>
                <div className="d-flex" style={{ paddingTop: "5%" }}>
                  <span
                    className="cursor-pointer d-flex"
                    onClick={() =>
                      history.push("/system_settings?tab=users-and-devices")
                    }
                  >
                    <img src="/images/arrow-left.svg" className="pr-1" alt="" />
                    <p className="link_textCol">Back</p>
                  </span>
                  <p className="integrationTitle">
                    {" "}
                    {userProfileDevice[userDeviceId].UserDetails &&
                      userProfileDevice[userDeviceId].UserDetails.FullName}
                  </p>
                </div>
                <div className="d-flex mt40px">
                  <div className="profile-container">
                    <img
                      src={
                        (userProfileDevice[userDeviceId].UserDetails &&
                          userProfileDevice[userDeviceId].UserDetails.Avatar) ||
                        ""
                      }
                      className="border-grey camera-icon cursor-pointer"
                      width="32"
                      onClick={() => setShowUploadModal(true)}
                    />
                  </div>
                  <div className="align-self-center ml24px">
                    <div className="main-heading">
                      {userProfileDevice[userDeviceId].UserDetails &&
                        userProfileDevice[userDeviceId].UserDetails.FullName}
                    </div>
                    <span className="txt-blue">
                      {userProfileDevice[userDeviceId].UserDetails &&
                        userEnable}
                    </span>
                  </div>
                  {dbObj &&
                  dbObj.UserDetail.UserName ==
                    userProfileDevice[userDeviceId].UserDetails.UserName ? (
                    ""
                  ) : (
                    <div className="ml-auto align-self-center">
                      <span
                        className="align-self-center  mr16px 
                            light-blue-btn"
                        style={{ padding: "8px 16px" }}
                        onClick={() => handleEnableUser()}
                      >
                        {loading ? (
                          <Spinner size="sm" animation="border" />
                        ) : (
                          toggle
                        )}
                      </span>
                      <Button
                        className="align-self-center btn-red"
                        onClick={() => {
                          TrackUser("Account Removed User");
                          handleDeleteUser();
                        }}
                      >
                        Delete User
                        <img
                          src="/images/icons/deleteIcon.svg"
                          style={{ marginLeft: "10px" }}
                        />
                      </Button>
                    </div>
                  )}
                </div>
                <div className="settings_container mt40px">
                  <div className="heading3">User Settings</div>
                  <div className="textBlackBold mt40px">User Role</div>
                  <ButtonGroup toggle className="mt16px">
                    {radios.map((radio, idx) => (
                      <ToggleButton
                        key={idx}
                        type="radio"
                        variant="secondary"
                        name="radio"
                        value={radio.value}
                        checked={radioValue === radio.value}
                        onChange={(e) => setRadioValue(e.currentTarget.value)}
                      >
                        {radio.name}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                  <div className="heading3 mt40px">Reset</div>
                  <div
                    className="d-flex mt40px justify-content-between"
                    style={{ width: "387px" }}
                  >
                    <div>
                      <div className="textBlackBold">Reset MFA</div>
                      <span className="greyText">
                        Reset the users MFA code on next login
                      </span>
                    </div>
                    <Button
                      className={`align-self-center ${
                        userProfileDevice[userDeviceId].UserDetails &&
                        userProfileDevice[userDeviceId].UserDetails
                          .GoogleAuthActive &&
                        disableMfa
                          ? "light-red-btn"
                          : "btn-disable"
                      }`}
                      style={{ minWidth: "70px" }}
                      onClick={() => handleResetMfa()}
                    >
                      {mfaReset ? (
                        <Spinner size="sm" animation="border" />
                      ) : (
                        "Reset"
                      )}
                    </Button>
                  </div>
                  <div
                    className="d-flex mt40px justify-content-between"
                    style={{ width: "387px" }}
                  >
                    <div>
                      <div className="textBlackBold">Reset Password</div>
                      <span className="greyText">
                        Send an email to the user with password reset
                        instructions
                      </span>
                    </div>
                    <Button
                      className={`align-self-center ${
                        disableResetPass ? "btn-disable" : "light-red-btn"
                      }`}
                      style={{ minWidth: "70px" }}
                      onClick={() => handleResetPass()}
                    >
                      {resetPass ? (
                        <Spinner size="sm" animation="border" />
                      ) : (
                        "Reset"
                      )}
                    </Button>
                  </div>
                </div>
                <div className="settings_container mt16px">
                  <div className="heading3">Devices</div>
                  <Table
                    className="settings_table cybercare-table2"
                    striped
                    borderless
                  >
                    <thead>
                      <tr>
                        <th className="border-bottom">
                          <span className="linkGreyText">Name</span>
                        </th>
                        <th className="border-bottom">
                          <span className="linkGreyText">Date Added</span>
                        </th>
                        <th className="border-bottom">
                          <span className="linkGreyText"></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {userProfileDevice[userDeviceId].MobileList.map(
                        (device, index) => (
                          <tr>
                            <td>
                              <img
                                src="/images/icons/phoneIcon.svg"
                                style={{ marginRight: "10px" }}
                              />

                              <span className="txt-small">{device.Detail}</span>
                            </td>
                            <td>
                              <span className="txt-small">
                                {device.DateAdded}
                              </span>
                            </td>

                            <td
                              className="float-right"
                              style={{ paddingTop: "22px" }}
                            >
                              {device.DeviceId == 0 ? (
                                ""
                              ) : (
                                <>
                                  <span
                                    className="light-blue-btn"
                                    onClick={() =>
                                      handleNotifModal(device.DeviceId)
                                    }
                                  >
                                    Send Notification
                                    <img
                                      src="/images/icons/sendIcon.svg"
                                      style={{ marginLeft: "10px" }}
                                    />
                                  </span>
                                  <span
                                    className={`redText ml56px cursor-pointer ${
                                      device.removing
                                        ? "greyText removingText"
                                        : ""
                                    }`}
                                    onClick={(index) =>
                                      handleRemove(device.DeviceId, index)
                                    }
                                  >
                                    {device.removing ? "Removing..." : "Remove"}
                                  </span>
                                </>
                              )}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            )}

            {showNotificationModal && (
              <ModalDynamic
                setShow={true}
                modalData={referralData}
                modalHeader={"Test Notifications"}
              />
            )}
          </Col>
        </Row>
        <ImageUploadModal
          showModal={showUploadModal}
          hideModal={() => setShowUploadModal(false)}
          uploadType={0}
        />
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Row className="fullHeight">
          <Col xs={12}>
            <ContentLoader
              speed={2}
              height={"100%"}
              width={"100%"}
              viewBox="0 -80 1500 1000"
              backgroundColor="#F6F8FB"
              foregroundColor="#ecebeb"
            >
              <rect x="10" y="120" rx="60" ry="60" width="120" height="120" />
              <rect x="160" y="150" rx="5" ry="5" width="160" height="45" />
              <rect x="1245" y="175" rx="9" ry="9" width="100" height="40" />
              <rect x="1370" y="175" rx="9" ry="9" width="160" height="40" />
              <rect x="20" y="290" rx="30" ry="30" width="1480" height="600" />
              <rect x="20" y="960" rx="20" ry="20" width="1480" height="100" />
            </ContentLoader>
          </Col>
        </Row>
      </Fragment>
    );
  }
};

export default UserProfile;
