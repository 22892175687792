import React from "react";
import { Button } from "react-bootstrap";

const MspAccountLogs = () => {
  return (
    <div className="w-70 mx-auto">
      <div className="f-16 f-600 my-3">Logs and Activity</div>
      <div className="d-flex align-items-center">
        <Button className="hg-blue-btn mr-2">All</Button>
        <Button className="hg-cancel-btn mr-2">Login</Button>
        <Button className="hg-cancel-btn mr-2">Activity</Button>
        <Button className="hg-cancel-btn mr-2">Email</Button>
      </div>

      <div className="f-grey f-12 f-500 mt-2 mb-2">18 May, 2024</div>
{
    [1,2,3,4,5,6,7,8,9,0].map(()=>{
        return(  <div className="d-flex align-items-start mb-2">
        <img
          src="/images/msp/letter-envelope.svg"
          alt=""
          className="mr-2 mt-1"
        />
        <div className="f-grey ml-2 mr-3">19:38</div>

        <div>
          <span className="f-black f-500">
            “Sophos Endpoint Security integration has been disconnected from the
            service”
          </span>{" "}
          <span className="f-darkgrey"> email sent to</span>{" "}
          <span className="f-black f-500">
            @Brooklyn Simmons, @Kathryn Murphy (Owner).
          </span>
        </div>
      </div>

        )
    })
}
    
    </div>
  );
};

export default MspAccountLogs;
