import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Form, Modal, Nav, Tab } from "react-bootstrap";
// import "../secOps.scss";
import "../MSPv2/secOps/secOps.scss";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import { ContributorPostData, postData, postJsonData } from "../../Utils/commonApi";
import { useAuth } from "../context/Auth";
const EditEmployeeModal = ({
  show,
  hideModal,
  refreshData,
  headerName,
  questionId,
  employee,
  isContributorView,
}) => {
  const { authTokens } = useAuth();
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [employeeData, setEmployeeData] = useState({
    userId: "",
    position: [],
    memberName: "",
  });
  async function refreshModalData() {
    setEmployeeData({
      userId: "",
      position: [],
      memberName: "",
    });
  }

  useEffect(() => {
    if (show) {
      setEmployeeData(employee);
    }
  }, [show]);

  async function handleSave() {
    setSaving(true);
    if (isContributorView) {
      await ContributorPostData(authTokens, "Certification/UpdateEmployee", {
        userId: employeeData.userId,
        objId_SendFormQuestionnaire: questionId ?? 0,
        position: employeeData.position,
        memberName: employeeData.memberName,
      })
        .then((response) => {
          if (response?.Success) {
            CallToast("Saved successfully", false, false, "v2style");
            hideModal();
            if (refreshData) {
              refreshData();
            }
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
          setSaving(false);
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
          setSaving(false);
        });
    } else {
      await postData(authTokens, "Certification/UpdateEmployee", {
        userId: employeeData.userId,
        objId_SendFormQuestionnaire: questionId ?? 0,
        position: employeeData.position,
        memberName: employeeData.memberName,
      })
        .then((response) => {
          if (response?.Success) {
            CallToast("Saved successfully", false, false, "v2style");
            hideModal();
            if (refreshData) {
              refreshData();
            }
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
          setSaving(false);
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
          setSaving(false);
        });
    }
  }
  async function deleteEmployee() {
    setSaving(true);
    await postData(authTokens, "Certification/DeleteEmployee", {
      objId_SendFormQuestionnaire: questionId ?? 0,
      userId: employeeData.userId,
    })
      .then((response) => {
        if (response?.Success) {
          CallToast("Employee deleted successfully", true, false, "v2style");
          refreshData();
          hideModal();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  return (
    <Modal
      show={show}
      dialogClassName="secops-cert-modal employeeModal"
      className="   "
      aria-labelledby=" "
      onHide={hideModal}
      centered
      scrollable
    >
      <Modal.Header className="text-center">
        <div
          className="w-100 mb-0 font-bold-14 title"
          style={{ fontWeight: "600" }}
        >
          {headerName}
        </div>

        <span
          className="cursor-pointer"
          onClick={() => {
            hideModal();
          }}
        >
          <img width={15} src="/images/attack-surface/cross-icon.svg" alt="" />
        </span>
      </Modal.Header>
      <Modal.Body
        className=" transparent-scroller d-flex flex-column"
        style={{
          padding: "16px 24px",
          gap: "8px",
          height: "172px",
          width: "480px",
        }}
      >
        {
          // loading ? (
          //   <div className="d-flex align-items-center justify-content-center w-100 h-100">
          //     <Loader />
          //   </div>
          // ) :
          <>
            <div>
              <>
                <div className="d-flex align-items-center my-2 w-100">
                  <div className="w-30 f-500 f-grey">Employee name</div>
                  <div className="w-70 ">
                    <div
                      className={`d-flex align-items-center radius-4 highlight-input py-2 flex-grow-1 mr-1 ${true}`}
                    >
                      <input
                        type="text"
                        className="bg-transparent w-100 border-none pl-2"
                        placeholder="Type"
                        value={employeeData?.memberName ?? ""}
                        onChange={(e) => {
                          setEmployeeData({
                            ...employeeData,
                            memberName: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center my-2 w-100">
                  <div className="w-30 f-500 f-grey">Employee position</div>
                  <div className="w-70 ">
                    <div
                      className={`d-flex align-items-center radius-4 highlight-input py-2 flex-grow-1 mr-1 ${true}`}
                    >
                      <input
                        type="text"
                        className="bg-transparent w-100 border-none pl-2"
                        placeholder="Type"
                        value={employeeData?.position ?? ""}
                        onChange={(e) => {
                          setEmployeeData({
                            ...employeeData,
                            position: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-5" />
              </>
            </div>
          </>
        }
      </Modal.Body>

      <Modal.Footer className="p-2 d-flex align-items-center justify-content-between w-100">
        {/* <div className="d-flex align-items-center justify-content-between"> */}
        <div
          className="d-flex align-items-center f-500 f-grey pointer"
          onClick={() => {
            deleteEmployee();
          }}
        >
          <img alt="" className="mx-2" src="/images/actioncenter/delete.svg" />
          {deleting ? "Deleting..." : "Delete"}
        </div>

        <div className="d-flex justify-content-end">
          <Button className="hg-cancel-btn mr-2" onClick={hideModal}>
            Cancel
          </Button>
          <Button
            disabled={false}
            className="hg-blue-btn"
            onClick={() => {
              handleSave();
            }}
          >
            {saving ? <>Saving..</> : "Save"}
          </Button>
        </div>
        {/* </div> */}
      </Modal.Footer>
    </Modal>
  );
};

export default EditEmployeeModal;
