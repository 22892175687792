import React from "react";
import { Button, Dropdown, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./files.scss";
import { useState } from "react";
import { WhiteLoader } from "../../Theme/APILoaders";
import AddFileModal, { downloadFile } from "./AddFileModal";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { useEffect } from "react";
import ContentLoader from "react-content-loader";
import { TrimString } from "../../../Utils/StringTrimmer";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import moment from "moment";
import { DeleteModal } from "../../DeleteModal/DeleteModal";
import { viewOnlyAccess } from "../../App/App";

const SupplierProfilerFiles = ({ questionId, isTemplate }) => {
  const { authTokens } = useAuth();
  const [editFileModal, setEditFileModal] = useState(false);
  const [openLinkModal, setOpenLinkModal] = useState(false);
  const [addFileModal, setAddFileModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Files, setFiles] = useState([]);
  const [redirectToLink, setRedirectToLink] = useState("");
  const [fileData, setFileData] = useState({
    ObjectId: 0,
    objId_SendFormQuestionnaire: 0,
    IsLink: false,
    FileName: "",
    ActualFileName: "",
    FullFileName: null,
    Description: "Dscriptiokcdscsd",
    FileSize: null,
    FileURLOrLink:
      "https://hguksouthinboundreports.blob.core.windows.net/customer-objid-3548195/supplierprofilingfile322f89c0-dcbe-48e3-bfd7-458d5ec9b4a2-HHHHHHHHHHHHHHHHHHHHHHHH.pdf?sv=2018-03-28&sr=c&sig=5rLasqz9tVk20ExQHO%2BPc9iIbLXQTeu6i0FSMWzQ7QQ%3D&se=2024-08-05T06%3A24%3A01Z&sp=r",
    FileUploadedDate: "04/08/2024",
  });
  const [showDeleteModal, setShowDeleteModal] = useState({
    showModal: false,
    deleteMessage: "You are about to delete a file.",
    deleteSentQuestionnaire: () => {},
  });
  const [sortItem, setSortItem] = useState({
    type: "Title",
    enumNum: 0,
    order: 1,
  });

  useEffect(() => {
    setLoading(true);
    getFilesList(sortItem.enumNum, sortItem.order);
  }, [sortItem]);

  async function getFilesList(sortEnum, sortOrder) {
    await getAPIData(`${758}`, authTokens, {
      optionEnum1: questionId,
      optionEnum2: sortEnum ?? 0,
      optionEnum3: sortOrder ?? 0,
    })
      .then((response) => {
        setLoading(false);
        setFiles(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function deleteFile(_deletId) {
    return new Promise((resolve, reject) => {
      getAPIData(`${755}`, authTokens, {
        optionEnum1: _deletId,
        optionEnum2: questionId,
      })
        .then((response) => {
          if (response?.Success) {
            CallToast("Deleted Successfully", false, false, "v2style");
            getFilesList();
            resolve(true);
          } else {
            resolve(false);
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
  function SortQuestionaireColumn(type, enumNum) {
    // setColumnSortingLoading(true);
    setSortItem((prevSort) => ({
      type: type,
      enumNum: enumNum,
      order: Number(!prevSort.order),
    }));
  }

  return (
    <div className="w-100 supplier-profiler-files-wrapper">
      {loading ? (
        <div className="mt-5">
          <FilesLoader />
        </div>
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-between">
            <div className="f-16 f-600">Files</div>
            {!isTemplate && (
              <OverlayTrigger
                placement="left"
                trigger={
                  viewOnlyAccess("AccessState_SCM") ? ["hover", "focus"] : []
                }
                delay={{
                  show: 200,
                  hide: 250,
                }}
                overlay={
                  <Tooltip
                    id={`tooltip-1365`}
                    className="custom_tooltip_access"
                  >
                    This feature is managed by your service provider
                  </Tooltip>
                }
              >
                <div>
                  <Button
                    className={` ${
                      viewOnlyAccess("AccessState_SCM")
                        ? "btn-disable"
                        : "hg-blue-btn"
                    } `}
                    onClick={() => {
                      setFileData({});

                      setAddFileModal(true);
                    }}
                  >
                    {viewOnlyAccess("AccessState_SCM") ? (
                      <img
                        alt=""
                        src="/images/attack-surface/small-lock.svg"
                        className="mr-2"
                      />
                    ) : (
                      <img
                        src="/images/white-plus-icon.svg"
                        alt="plus"
                        className="mr-2"
                      />
                    )}
                    Add file
                  </Button>
                </div>
              </OverlayTrigger>
            )}
          </div>

          {Files?.length === 0 ? (
            <div className="w-100 d-flex flex-column align-items-center justify-content-center">
              <img
                src="/images/msp/no-notes-icon.svg"
                alt="notes"
                className="mt-5"
              />
              <div className="f-600 f-grey mt-4 w-fit-content">
                No files yet
              </div>
              <div className=" f-darkgrey mt-2 w-fit-content">
                You don’t have files yet. Add your first file
              </div>
            </div>
          ) : (
            <>
              <div className="bg-grey radius-8 border-parent mt-3">
                <div className="border-item files-table-wrapper p-2">
                  <div
                    className="f-12 f-500 f-grey"
                    onClick={() => {
                      SortQuestionaireColumn("File_Name", 0);
                    }}
                  >
                    File name
                    {sortItem.type == "File_Name" ||
                    sortItem.type == "Title" ? (
                      sortItem?.order ? (
                        <img
                          alt=""
                          src="/images/actioncenter/arrowdown.svg"
                          className="ml-2"
                        />
                      ) : (
                        <img
                          alt=""
                          src="/images/actioncenter/arrowup.svg"
                          className="ml-2"
                          style={{ width: "10px" }}
                        />
                      )
                    ) : (
                      <></>
                    )}
                    {/* <img
                      src="/images/actioncenter/arrowdown.svg"
                      alt="sort"
                      className="ml-2"
                    /> */}
                  </div>
                  <div
                    className="f-12 f-500 f-grey"
                    onClick={() => {
                      SortQuestionaireColumn("Uploaded", 1);
                    }}
                  >
                    Uploaded
                    {sortItem.type == "Uploaded" ? (
                      sortItem?.order ? (
                        <img
                          alt=""
                          src="/images/actioncenter/arrowdown.svg"
                          className="ml-2"
                        />
                      ) : (
                        <img
                          alt=""
                          src="/images/actioncenter/arrowup.svg"
                          className="ml-2"
                          style={{ width: "10px" }}
                        />
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                  <div></div>
                </div>
                {Files?.map((item, index) => {
                  return (
                    <div
                      className="border-item files-table-wrapper pointer cp-25 bg-hover-dark-grey hover-on-show-parent"
                      onClick={() => {
                        setFileData(item);
                        setAddFileModal(true);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={
                            item?.IsLink
                              ? "/images/msp/profiler-link-icon.svg"
                              : "/images/msp/profiler-file-icon.svg"
                          }
                          alt="file"
                          className="mr-2"
                        />
                        <div>
                          <div className="f-500 f-black">
                            {item?.FileName || item.ActualFileName}
                          </div>
                          <div className="f-12 f-darkgrey">
                            {TrimString(item.Description, 100)}
                          </div>
                        </div>
                      </div>
                      <div className="f-black d-flex align-items-center">
                        {item?.FileUploadedDate?.split("/")?.length > 2
                          ? moment(item?.FileUploadedDate, "DD/MM/YYYY").format(
                              "MMM DD, YYYY"
                            )
                          : ""}
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <Dropdown
                          className="transparennt-wrapper-dropdown"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Dropdown.Toggle className="w-100 d-flex align-items-center justify-content-between">
                            <div className="p-2 w-fit-content hover-on-show-child">
                              <img
                                src="/images/settings-v2/horizontal-dots.svg"
                                alt="3-dots"
                                className=" "
                              />
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className="p-2 pointer left-dropdown-dir"
                            onClick={(e) => {
                              e.target
                                .closest(".transparennt-wrapper-dropdown")
                                .click();
                            }}
                          >
                            <div
                              className="p-2 f-grey"
                              onClick={() => {
                                setFileData(item);
                                setAddFileModal(true);
                              }}
                            >
                              Edit
                            </div>
                            {item?.IsLink ? (
                              <div
                                className="px-2 py-1 f-grey"
                                onClick={() => {
                                  setRedirectToLink(item?.FileURLOrLink);
                                  setOpenLinkModal(true);
                                }}
                              >
                                Open link
                              </div>
                            ) : (
                              <div
                                className="px-2 py-1 f-grey"
                                onClick={() => {
                                  console.log(
                                    item?.FileURLOrLink,
                                    item.ActualFileName || item?.FileName
                                  );
                                  downloadFile(
                                    item?.FileURLOrLink,
                                    item.ActualFileName || item?.FileName
                                  );
                                }}
                              >
                                Download
                              </div>
                            )}
                            <div
                              className="redText p-2"
                              onClick={() => {
                                setShowDeleteModal({
                                  showModal: true,
                                  deleteMessage:
                                    "You are about to delete a file.",
                                  deleteSentQuestionnaire: () =>
                                    deleteFile(item?.ObjectId),
                                });
                              }}
                            >
                              Delete
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </>
      )}
      <DeleteModal
        show={showDeleteModal.showModal}
        hideModal={() =>
          setShowDeleteModal({
            showModal: false,
          })
        }
        deleteMessage={showDeleteModal.deleteMessage}
        deleteType={"deleteSentQuestionnaire"}
        deleteSentQuestionnaire={() => {
          showDeleteModal.deleteSentQuestionnaire();
        }}
      />
      <OpenLinkModal
        show={openLinkModal}
        hideModal={() => {
          setOpenLinkModal(false);
        }}
        redirectToLink={redirectToLink}
      />
      <AddFileModal
        show={addFileModal}
        hideModal={() => {
          setAddFileModal(false);
        }}
        refreshData={getFilesList}
        selectedFile={fileData}
        questionId={questionId}
      />
    </div>
  );
};

export default SupplierProfilerFiles;

const OpenLinkModal = ({ show, hideModal, redirectToLink }) => {
  return (
    <Modal
      show={show}
      dialogClassName="open-link-modal"
      className=""
      aria-labelledby=" "
      centered
      scrollable
      onHide={() => {
        hideModal();
      }}
    >
      <Modal.Body className="hide-scrollbar p-0 position-relative overflow-hidden d-flex p-3">
        <div>
          <div className="f-16 f-500 mb-3">
            <img
              src=" /images/msp/yellow-exclamation-icon.svg"
              alt="link"
              className="mr-2"
            />
            Proceed with caution
          </div>
          <div>
            You are being redirected to{" "}
            <span
              className="f-blue"
              style={{
                overflowWrap: "anywhere",
              }}
            >
              {TrimString(redirectToLink, 100)}
            </span>
            . This site is not hosted on highground.io and the security of this
            is out of our control.
          </div>
          <div className="f-12 f-darkgrey mt-2">
            For your safety, don't enter any sensitive data including usernames,
            passwords or personally identifiable information from your
            highground.io account on external websites.
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="p-2">
        <div className="d-flex align-items-center justify-content-end">
          <Button className="hg-cancel-btn mr-2" onClick={hideModal}>
            Back
          </Button>
          <Button
            className="hg-blue-btn"
            onClick={() => {
              window.open(redirectToLink, "_blank");
            }}
          >
            Proceed to website
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const FilesLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0%" y="10" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="93" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="176" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="259" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="342" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="425" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="508" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="591" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="674" rx="4" ry="4" width="100%" height="80" />
    </ContentLoader>
  );
};
