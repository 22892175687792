import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import "./mspSubscriptionAccounts.scss"
import { useAuth } from '../../context/Auth';
import { getAPIData } from '../../../Utils/commonApi';
import moment from "moment"
const MspSupportAccess = ({accountId}) => {
  const periods = [
    {
      PeriodId: 21998,
      PeriodDayCount: 1,
      PeriodText: "24 hours",
    },
    {
      PeriodId: 21999,
      PeriodDayCount: 2,
      PeriodText: "2 days",
    },
    {
      PeriodId: 22000,
      PeriodDayCount: 5,
      PeriodText: "5 days",
    },
    {
      PeriodId: 22001,
      PeriodDayCount: 7,
      PeriodText: "7 days",
    },
  ];
  const [selectedPeriod, setSelectedPeriod] = useState();
  const [periodsList, setPeriodsList] = useState(periods);
  const [sessionList, setSessionList] = useState([])
  const { authTokens } = useAuth();
  const [givingAccess, setGivingAccess] = useState(false)
  const [dataLoading, setDataLoading] = useState(false)
  useEffect(() => {
    getModalData()
  }, [ ])
  

  function getModalData() {
    return new Promise((resolve, reject) => {
      getAPIData(`${1100}`, authTokens, {
        optionEnum98:accountId

      })
        .then((response) => {
          resolve(response);
          setPeriodsList(response?.Period_DDL)
          setSessionList(response?.Session_List)
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  function revokeAccess(_id) {
    setDataLoading(true)
    return new Promise((resolve, reject) => {
      getAPIData(`${1101}`, authTokens, {
        optionEnum1:_id,
        optionEnum98:accountId

      })
        .then((response) => {
          resolve(response);
    setDataLoading(false)
    getModalData()
         
        })
        .catch((err) => {
          reject(err);
    setDataLoading(false)

        });
    });
  }
  return (
    <div className="w-70 mx-auto mt-4 support-access-wrapper">
      {dataLoading && (
        <div
          className="position-absolute w-100 h-100 bg-white"
          style={{
            opacity: "0.6",
            zIndex: "2",
          }}
        ></div>
      )}
      <div className="f-16 f-600 mb-3">Temporary Support Access</div>

      <div className="bg-grey d-flex align-items-center justify-content-between p-3">
        <div className="">
          <div>Temporary Support Access</div>
          <div>
            <span className="f-grey">
              20 Jan 2024 13:35 - 21 Jan 2024 13:35
            </span>{" "}
            &nbsp;
            <span className="f-green">Active</span>
          </div>
        </div>
        <div>
          <Button className="hg-blue-btn">
            <div className="d-flex align-items-center">
              <img
                src="/images/msp/white-open-door.svg"
                alt=""
                className="mr-2"
              />
              Take control
            </div>
          </Button>
        </div>
      </div>

    {periodsList?.length >0 &&  <div className="f-500 mb-2 mt-3">History</div>}

      <div className="bg-grey border-parent">
        {periodsList?.map((historyItem, index) => {
          return (
            <div className="border-item support-access-history-table p-3">
              <div>
              {historyItem?.Status === 0
                        ? <span>{moment(historyItem?.StartDate).format("ll")}&nbsp;-&nbsp;</span>
                        : <span>{ moment(historyItem?.StartDate).format("lll")}&nbsp;-&nbsp;{moment(historyItem?.EndDate).format("lll")} </span>  }
             
             <span
                          style={{
                            color: historyItem?.StatusColour,
                          }}
                        >
                          {historyItem?.Status_Text}{" "}
                        </span>
              </div>
              <div
                      className={`f-400 f-14 pointer ${
                        historyItem?.Status === 0
                          ? "f-danger"
                          : ""
                      } mx-2 line-height-22 letter-space-2`}

                      onClick={()=>{
                        revokeAccess(historyItem?.objId)
                      }}
                    >
                      {historyItem?.Status===0 ?  "Revoke Access"   :""}
                    </div>
              <div className="d-flex justify-content-end">
              {historyItem?.SupportPeriodText}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MspSupportAccess