import React, { useEffect, useMemo, useState } from "react";
import SlidingModal from "../../../CustomModal/SlidingModal";
import { Button, Dropdown, Form } from "react-bootstrap";
import "./mspPriceCalculator.scss";

import Loader from "../../../Common/loader";
import { removeWhiteSpace } from "../../../../Utils/CommonFunctions";
import { LinkToServiceDropdown } from "./mspCalculatorDropdownAndModals/LinkToSeriesDropdown";
import { CustomDeleteModal } from "../../../DeleteModal/DeleteModal";
import Lottie from "react-lottie-player";
import * as animationData from "./seriesAnimationData.json";
import { getAPIData, postData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
const MspAddEditSeriesModal = ({
  show,
  hideModal,
  parentID,
  seriesModalData,
  setSeriesModalData,
  refreshData,
  linkCurrSerie,
  // objId_SecurityPackLinkSeries,
}) => {
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);

  const [selectedSeries, setSelectedSeries] = useState([]);
  const [tempSeriesName, setTempSeriesName] = useState("Untitled series");
  const [isNameUnique, setIsNameUnique] = useState(true);
  const [selectedItem, setSelectedItem] = useState();

  const { authTokens } = useAuth();
  const [servicePacksDetails, setServicePacksDetails] = useState([]);
  const [servicePacks, setServicePacks] = useState([]);
  const [currentService, setCurrentService] = useState([]);
  const [
    selectedObjId_SecurityPackLinkSeries,
    setSelectedObjId_SecurityPackLinkSeries,
  ] = useState(0);
  const [unlinkServiceId, setUnlinkServiceId] = useState(0);
  const [servicePageErrors, setServicePageErrors] = useState({});
  const [seriesList, setSeriesList] = useState([]);
  const [baseService, setBaseService] = useState({});
  const [warningModalConfig, setWarningModalConfig] = useState({
    showWarningModal: false,
  });

  async function clearAll() {
    setChanged(false);
    setCurrentService([]);
    setSelectedItem({});
    setServicePageErrors({});
    setServicePacksDetails({});
    setSelectedObjId_SecurityPackLinkSeries(0);
    setTempSeriesName("Untitled series");
  }
  useEffect(() => {
    if (show) {
      clearAll();
      console.log("modal trigger", seriesModalData);
      getServicePacks(
        seriesModalData?.serviceType?.DDLId,
        seriesModalData?.billingPeriod?.DDLId
      );
      getLinkedSeries();
    }
  }, [show]);
  useEffect(() => {
    console.log("modal ", seriesModalData);

    setServicePacksDetails({});
    if (show) {
      if (seriesModalData?.serviceModalState === "add") {
        if (seriesModalData?.SeriesName) {
          setTempSeriesName(seriesModalData?.SeriesName);
        }
        setSelectedObjId_SecurityPackLinkSeries(0);
        setServicePacksDetails({
          BillingPeriod_Text: seriesModalData?.billingPeriod?.DDLText,
          NistPillar_Text: seriesModalData?.serviceType?.DDLText,
          SecurityPackVM_List: [seriesModalData?.serviceModalData] ?? [],
        });
        setCurrentService([seriesModalData?.serviceModalData] ?? []);

        console.log("---------sec pack list", servicePacksDetails);
      } else {
        // setTempSeriesName(seriesModalData?.SeriesName);
        if (seriesModalData?.seriesId) {
          getServicePackDetails(seriesModalData?.seriesId);
        } else if (
          seriesModalData?.selectedList &&
          seriesModalData?.selectedList[0]
        ) {
          getServicePackDetails(
            seriesModalData?.selectedList[0]?.objId_SecurityPackLinkSeries
          );
        } else {
          setServicePacksDetails({
            BillingPeriod_Text: seriesModalData?.billingPeriod?.DDLText,
            NistPillar_Text: seriesModalData?.serviceType?.DDLText,
          });
        }
      }

      setSelectedSeries(seriesModalData?.selectedList);
    }
  }, [show, seriesModalData]);

  function getLinkedSeries() {
    setLoading(true);
    getAPIData(639, authTokens, {
      optionEnum1: seriesModalData?.serviceType?.DDLId,
      optionEnum2: seriesModalData?.billingPeriod?.DDLId,
    }).then((response) => {
      setLoading(false);
      setSeriesList(response?.SecurityPackLinkSeries_List ?? []);
    });
  }

  async function deleteLinkedSerie(_seriesId) {
    await getAPIData(640, authTokens, {
      optionEnum1: _seriesId,
      optionEnum2: 0,
    }).then((response) => {
      if (response.Success) {
        CallToast("Deleted Successfully", false, false, "v2style");
        // setLoading(true);
        // getServiceData(_selectedServiceId);
        seriesModalData.linkCurrSerie(servicePacksDetails);
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
      return true;

      // getLinkedSeries(selectedNistPillar?.DDLId);
    });
  }

  async function getServicePackDetails(objId_SecurityPackLinkSeries) {
    await getAPIData(672, authTokens, {
      optionEnum1: objId_SecurityPackLinkSeries,
    }).then((response) => {
      if (response?.SecurityPackVM_List?.length > 0) {
        setSelectedItem(
          response?.SecurityPackVM_List[
            response?.SecurityPackVM_List?.length - 1
          ]
        );
      }
      // setServicePacksDetails(response);
      // setCurrentService(response?.SecurityPackVM_List);
      setTempSeriesName(response?.SeriesName);
      setSelectedObjId_SecurityPackLinkSeries(
        response.objId_SecurityPackLinkSeries
      );
      if (
        seriesModalData?.serviceModalState !== "add" &&
        response?.SecurityPackVM_List?.length > 0 &&
        seriesModalData?.serviceModalData
      ) {
        // let temp = response?.SecurityPackVM_List;
        // temp.push(seriesModalData?.serviceModalData);
        // setCurrentService(temp);

        let temp = response?.SecurityPackVM_List.slice(); // Make a shallow copy to avoid mutating the original array
        let newServiceModalData = seriesModalData?.serviceModalData;

        if (
          !temp.some(
            (item) =>
              item.objId_SecurityPack === newServiceModalData.objId_SecurityPack
          )
        ) {
          temp.push(newServiceModalData);
          setCurrentService(temp);

          const { SecurityPackVM_List, ...restResponse } = response;

          setServicePacksDetails({
            SecurityPackVM_List: temp,
            ...restResponse,
          });
        } else {
          console.log("Object already exists in the array");
          setServicePacksDetails(response);
          setCurrentService(response?.SecurityPackVM_List);
        }
      } else {
        setServicePacksDetails(response);
        setCurrentService(response?.SecurityPackVM_List);
      }
    });
  }

  async function getServicePacks(nisttype, billingPeriod) {
    await getAPIData(673, authTokens, {
      optionEnum1: nisttype,
      optionEnum2: billingPeriod,
    }).then((response) => {
      setServicePacks(response.SecurityPackList);
      // if (seriesModalData?.serviceId) {
      //   const selectedSeries = response.SecurityPackList.find(
      //     (series) => series?.objId_SecurityPack === seriesModalData?.serviceId
      //   );
      //   setBaseService(selectedSeries);
      //   const t = [];
      //   t.push(baseService);
      //   setServicePacksDetails((prev) => ({
      //     ...prev,
      //     SecurityPackVM_List: t,
      //   }));
      //   setCurrentService(t);
      //   createEditseries();
      // }
    });
  }

  async function unlinkSeries(seriesId, serviceId, closeModal) {
    await getAPIData(671, authTokens, {
      optionEnum1: seriesId,
      optionEnum2: serviceId,
    }).then((response) => {
      if (response?.Success) {
        CallToast("Unlinked Successfully", false, false, "v2style");
        getServicePackDetails(seriesId);
        // hideModal();
        if (seriesModalData?.updateServiceData) {
          seriesModalData.updateServiceData(seriesModalData?.serviceId);
        }
        if (closeModal) {
          hideModal();
        }
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
      return true;
    });
  }
  async function createEditseries(id, serie) {
    await postData(authTokens, "CreateSecurityPackLinkSeries", {
      objId_SecurityPackLinkSeries: id ?? 0,
      SeriesName: tempSeriesName ?? servicePacksDetails?.SeriesName,
      SecurityPackVM_List: JSON.stringify(currentService) ?? JSON.stringify([]),
      accesskey: JSON.parse(localStorage.getItem("user_MessageObj")),
      NistPillar: seriesModalData?.serviceType?.DDLId,
      BillingPeriod_Text: seriesModalData?.billingPeriod.DDLText,
    })
      .then((response) => {
        if (response?.Success) {
          CallToast("Saved Successfully", false, false, "v2style");
          console.log("ceckkkdkdj", servicePacksDetails);
          seriesModalData.linkCurrSerie(servicePacksDetails);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, "v2style");
        // setSaving(false);
      });
  }

  async function CheckValidationForServicePage() {
    let errors = 0;
    if (tempSeriesName === "") {
      // if (headline === "") {
      errors += 1;
      setServicePageErrors((prev) => ({ ...prev, nameError: true }));
    }
    if (
      selectedObjId_SecurityPackLinkSeries === 0 &&
      seriesList?.some(
        (service) => service.SeriesName === tempSeriesName.trim()
      )
    ) {
      errors += 1;
      setServicePageErrors((prev) => ({ ...prev, nameExistError: true }));
    }

    if (currentService?.length == 0) {
      errors += 1;
      setServicePageErrors((prev) => ({
        ...prev,
        linkedListError: true,
      }));
    }

    return errors;
  }
  useEffect(() => {
    if (show) {
      // Check if the default service name already exists
      if (seriesModalData?.seriesId === 0) {
        const defaultServiceName = "Untitled series 1";
        let incrementedName = defaultServiceName;
        let counter = 1;

        while (
          seriesList?.some((service) => service.SeriesName == incrementedName)
        ) {
          counter++;
          incrementedName = `Untitled series ${counter}`;
        }

        // Set the default service name (incremented if necessary)
        setTempSeriesName(incrementedName);
        // setHeadline(incrementedName);
      } else {
      }
    }
  }, [seriesList, seriesModalData, selectedObjId_SecurityPackLinkSeries, show]);

  const handleInputChange = (e) => {
    setChanged(true);
    setServicePageErrors((prev) => ({
      ...prev,
      nameError: false,
      nameExistError: false,
    }));
    const newName = e.target.value;
    setTempSeriesName(newName);

    // Check if the entered name is unique
    setIsNameUnique(
      !seriesList?.some((service) => service.SeriesName === newName.trim())
    );
  };

  const handleInputBlur = () => {
    // Check uniqueness and set the service name in state
    if (tempSeriesName.trim() === "") {
      // Optionally handle the case when the name is empty
      setServicePageErrors((prev) => ({ ...prev, nameError: true }));
    } else if (isNameUnique) {
      // Set the service name in state only if it's unique
      setTempSeriesName((prev) => prev.trim());
    } else {
      // Optionally handle the case when the name is not unique
      setServicePageErrors((prev) => ({ ...prev, nameExistError: true }));
    }
  };

  const [close, setClose] = useState(0);
  useEffect(() => {
    if (!changed) {
      hideModal();
      // if (seriesModalData && seriesModalData.linkCurrSerie) {
      //   seriesModalData.linkCurrSerie(servicePacksDetails);
      // }
      setChanged(false);
    } else {
      setWarningModalConfig((prev) => ({
        ...prev,
        showWarningModal: true,
      }));
    }
  }, [close]);

  function checkClosing() {
    setClose((prevClose) => prevClose + 1);
    console.log(close);
  }

  return (
    <SlidingModal
      show={show}
      parentID={parentID}
      id="serviemmodalx"
      width={"780px"}
      onHide={() => {
        checkClosing();
        // if (!changed) {
        //   clearAll();
        //   refreshData();
        //   hideModal();
        // } else {
        //   setWarningModalConfig((prev) => ({
        //     ...prev,
        //     showWarningModal: true,
        //   }));
        // }
        // clearAll();
        // refreshData();
        // hideModal();
      }}
    >
      <SlidingModal.Header className={`text-center   ${true}`}>
        <div className="d-flex align-items-center justify-content-between p-3 border-bottom bg-grey-imp pointer-events-none">
          <div
            className="w-100 mb-0 font-bold-14 title"
            style={{ fontWeight: "600" }}
          >
            {seriesModalData?.serviceModalState === "add"
              ? "Add Service"
              : "Edit Service"}
          </div>

          <div
            className="cursor-pointer"
            onClick={() => {
              if (!changed) {
                clearAll();
                refreshData();
                hideModal();
                // seriesModalData.linkCurrSerie(servicePacksDetails);
                setChanged(false);
              } else {
                setWarningModalConfig((prev) => ({
                  ...prev,
                  showWarningModal: true,
                }));
              }
            }}
          >
            <img src="/images/attack-surface/cross-icon.svg" alt="" />
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between p-3 border-bottom">
          <div
            className="w-100 mb-0 font-bold-14 title"
            style={{ fontWeight: "600" }}
          >
            {seriesModalData?.seriesId === 0 ? "Add Series" : "Edit Series"}
          </div>

          <div
            className="cursor-pointer"
            onClick={() => {
              if (!changed) {
                clearAll();
                refreshData();
                hideModal();
                // seriesModalData.linkCurrSerie(servicePacksDetails);
                setChanged(false);
              } else {
                setWarningModalConfig((prev) => ({
                  ...prev,
                  showWarningModal: true,
                }));
              }
              // clearAll();
              // refreshData();
              // hideModal();
            }}
          >
            <img src="/images/attack-surface/cross-icon.svg" alt="" />
          </div>
        </div>
      </SlidingModal.Header>
      <SlidingModal.Body
        className={`msp-series-add-edit-modal  h-100  ${true}`}
      >
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center w-100 "
            style={{
              height: "85vh",
            }}
          >
            <Loader />
          </div>
        ) : (
          <div
            className="d-flex align-items-start w-100 "
            style={{ height: "100%" }}
          >
            <div className="w-50 p-3 service-tree-wrapper">
              <div className="mb-2">
                <div
                  className={`highlight-input-with-grey-border w-100  d-flex align-items-center p-2 radius-4 mt-2   ${
                    servicePageErrors?.nameError ||
                    servicePageErrors?.nameExistError
                      ? "input-error"
                      : ""
                  } `}
                >
                  <div className="f-darkgrey">Name:</div>
                  <input
                    tabIndex={0}
                    type="text"
                    placeholder="Type.."
                    className="flex-grow-1 pl-1 pr-2"
                    value={tempSeriesName}
                    onBlur={handleInputBlur}
                    // onBlur={() => {
                    //   setChanged(true);

                    //   setServicePacksDetails((prev) => ({
                    //     ...prev,
                    //     SeriesName: removeWhiteSpace(tempSeriesName),
                    //   }));
                    //   setServicePageErrors((prev) => ({
                    //     ...prev,
                    //     nameError: false,
                    //     nameExistError: false,
                    //   }));
                    // }}
                    onChange={handleInputChange}
                    // onChange={(e) => {
                    //   setChanged(true);
                    //   let value = e.target.value;
                    //   setTempSeriesName(e.target.value);
                    //   setServicePageErrors((prev) => ({
                    //     ...prev,
                    //     nameError: false,
                    //   }));
                    // }}
                  />
                </div>
                {servicePageErrors?.nameError && (
                  <div className="f-danger f-12 p-1">
                    Series name is required
                  </div>
                )}
                {servicePageErrors?.nameExistError && (
                  <div className="f-danger f-12 p-1">
                    Series name already exists
                  </div>
                )}
              </div>

              <div className="f-grey f-500 f-12">Details</div>

              <div className="d-flex align-items-center my-2">
                <div className="w-40">Services type:</div>
                <div className="w-50">
                  {servicePacksDetails.NistPillar_Text}
                </div>
              </div>
              <div className="d-flex align-items-center my-2">
                <div className="w-40">Billing Period:</div>
                <div className="w-50">
                  {servicePacksDetails.BillingPeriod_Text}
                </div>
              </div>

              <div className="d-flex align-items-start f-darkgrey px-2 mt-1">
                <img
                  src="/images/msp/integrations/grey-info.svg"
                  className="mr-2 mt-1"
                  alt=""
                  style={{
                    filter: "brightness(1.6)",
                  }}
                />
                Services must be of the same type and have the same billing
                period to be linked in a series.
              </div>

              <div className="f-12 f-500 f-darkgrey my-1 mt-3">
                Series Hierarchy
              </div>
              <ul class="tree">
                <ul>
                  {/* {[
                    { Headline: "hjagsbcj" },
                    { Headline: "advfadvadg" },
                    { Headline: "adgbadehbad", addNew: true },
                    
                  ] */}
                  {servicePacksDetails?.SecurityPackVM_List?.sort(
                    (a, b) => b.Selected - a.Selected
                  )?.map((item, itemIndex, array) => {
                    const isLastItem = itemIndex === array.length - 1;
                    return (
                      <li
                        key={itemIndex}
                        className={`d-flex align-items-center justify-content-between radius-8 pointer p-2  ${
                          selectedItem?.Headline === item?.Headline
                            ? "bg-grey"
                            : ""
                        }`}
                        onClick={() => {
                          setChanged(true);
                          setSelectedItem(item);
                        }}
                      >
                        <>
                          <span
                            class={`tree_label  pointer  d-flex flex-column f-grey f-500`}
                            onClick={() => {}}
                          >
                            <>
                              <span className="d-flex">{item?.Headline}</span>
                              <span className="d-flex f-darkgrey">
                                {/* {item?.BillingPeriod_Text} */}
                                {item?.BillingPeriod_Selected?.DDLText}
                              </span>
                            </>
                          </span>

                          <span className="d-flex">
                            {isLastItem && currentService && (
                              <img
                                alt=""
                                className="mr-2"
                                src="/images/msp/unlink-icon.svg"
                                onClick={() => {
                                  setChanged(true);
                                  setSeriesModalData((prev) => ({
                                    ...prev,
                                    showUnlinkModal: true,
                                    deleteConfig: {
                                      unlinkName: item?.Headline,
                                      deleteId:
                                        selectedObjId_SecurityPackLinkSeries,
                                      deleteId2: item?.objId_SecurityPack,
                                      closeModal: currentService?.length < 2,
                                    },
                                  }));
                                }}
                              />
                            )}
                          </span>
                        </>
                      </li>
                    );
                  })}
                  {!currentService ||
                    (currentService?.length < 3 && (
                      <li
                        key={"linkservice"}
                        className={`d-flex align-items-center justify-content-between radius-8 pointer p-2 `}
                        onClick={() => {
                          // setSelectedSeries(item);
                        }}
                      >
                        <>
                          <span
                            class={`tree_label  pointer  d-flex flex-column ${"f-darkgrey"}`}
                            onClick={() => {}}
                          >
                            <div className="pt-2">
                              {tempSeriesName && tempSeriesName !== "" ? (
                                <LinkToServiceDropdown
                                  menuOpen={true}
                                  disabling={(error) => {
                                    if (error?.length > 0) {
                                      error.map((er) => {
                                        if (er?.name == "title") {
                                          setServicePageErrors((prev) => ({
                                            ...prev,
                                            nameError: er?.state,
                                          }));
                                        }
                                      });
                                    }
                                  }}
                                  onSelect={(status) => {
                                    setChanged(true);
                                    setSelectedItem(currentService[0]);
                                    setServicePageErrors((prev) => ({
                                      ...prev,
                                      linkedListError: false,
                                    }));
                                  }}
                                  refresh={(id) => {
                                    getServicePackDetails(id).then(() => {
                                      seriesModalData.linkCurrSerie(
                                        servicePacksDetails
                                      );
                                    });
                                  }}
                                  selectedList={currentService}
                                  selectedBillingPeriod={
                                    seriesModalData?.billingPeriod?.DDLId
                                  }
                                  title={tempSeriesName}
                                  BillingPeriod={seriesModalData?.billingPeriod}
                                  NistPillar={seriesModalData?.serviceType}
                                  serviceId={seriesModalData.serviceId}
                                  seriesModalData={seriesModalData}
                                  id={selectedObjId_SecurityPackLinkSeries}
                                />
                              ) : (
                                <LinkToServiceDropdown
                                  menuOpen={false}
                                  disabling={(error) => {
                                    if (error?.length > 0) {
                                      error.map((er) => {
                                        if (er?.name == "title") {
                                          setServicePageErrors((prev) => ({
                                            ...prev,
                                            nameError: er?.state,
                                          }));
                                        }
                                      });
                                    }
                                  }}
                                  onSelect={(status) => {
                                    setChanged(true);
                                    setSelectedItem(currentService[0]);
                                    setServicePageErrors((prev) => ({
                                      ...prev,
                                      linkedListError: false,
                                    }));
                                  }}
                                  refresh={(id) => {
                                    getServicePackDetails(id).then(() => {
                                      seriesModalData.linkCurrSerie(
                                        servicePacksDetails
                                      );
                                    });
                                  }}
                                  selectedList={currentService}
                                  selectedBillingPeriod={
                                    seriesModalData?.billingPeriod?.DDLId
                                  }
                                  title={tempSeriesName}
                                  BillingPeriod={seriesModalData?.billingPeriod}
                                  NistPillar={seriesModalData?.serviceType}
                                  serviceId={seriesModalData.serviceId}
                                  seriesModalData={seriesModalData}
                                  id={selectedObjId_SecurityPackLinkSeries}
                                />
                              )}
                            </div>
                          </span>
                        </>
                      </li>
                    ))}
                </ul>
              </ul>
              {servicePageErrors?.linkedListError && (
                <div className="f-danger f-12 p-1">
                  Series must contain a minimum of 1 linked service
                </div>
              )}
              <div style={{ height: "400px" }}></div>
            </div>

            {/* RIGHT SIDE */}

            <div className="w-50 bg-grey h-100">
              {selectedItem && currentService?.length > 0 ? (
                <div className=" p-3">
                  <div className="f-14 f-500 f-black">Preview</div>

                  {/* <div>
                    <div className="f-14 f-500 f-black mt-2">
                      {selectedItem?.Headline}
                    </div>
                  </div> */}

                  {servicePacksDetails?.SecurityPackVM_List?.map(
                    (serv, servIndex) => {
                      const selectedIndex =
                        servicePacksDetails?.SecurityPackVM_List.indexOf(
                          selectedItem
                        );

                      const isUpToSelected = true; // servIndex < selectedIndex;
                      const isItemSelected =
                        selectedItem?.Headline === serv.Headline;
                      const hasFeatures =
                        serv.SecurityPack_Bullet_List &&
                        serv.SecurityPack_Bullet_List.length > 0;
                      return (
                        <div key={servIndex}>
                          {isUpToSelected && (
                            <>
                              {" "}
                              <div className="f-12 f-500 f-darkgrey mt-2">
                                This service includes these features from '
                                {
                                  servicePacksDetails?.SecurityPackVM_List[
                                    servIndex
                                  ]?.Headline
                                }
                                '
                              </div>
                              {hasFeatures ? (
                                <>
                                  {serv?.SecurityPack_Bullet_List?.map(
                                    (item, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="d-flex align-items-center my-2 f-grey"
                                        >
                                          {isItemSelected ? (
                                            <img
                                              alt=""
                                              className="ml-1 mr-2"
                                              src="/images/msp/green-circular-tick.svg"
                                            />
                                          ) : (
                                            <img
                                              alt=""
                                              className="ml-1 mr-2"
                                              src="/images/msp/grey-circular-tick.svg"
                                            />
                                          )}

                                          {item.Text}
                                        </div>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                <div className="d-flex align-items-center my-2 f-grey">
                                  <img
                                    alt=""
                                    className="ml-1 mr-2"
                                    src="/images/msp/cross-circular-tick.svg"
                                  />{" "}
                                  This service has no features
                                </div>
                              )}
                            </>
                          )}

                          {/* {isItemSelected && (
                            <div className="f-12 f-500 f-darkgrey mt-2">
                              This service adds the following features:
                            </div>
                          )}
                          {isItemSelected && (
                            <>
                              {hasFeatures ? (
                                <>
                                  {selectedItem?.SecurityPack_Bullet_List?.map(
                                    (item, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="d-flex align-items-center my-2 f-grey"
                                        >
                                          <img
                                            alt=""
                                            className="ml-1 mr-2"
                                            src="/images/msp/green-circular-tick.svg"
                                          />
                                          {item.Text}
                                        </div>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                <div className="d-flex align-items-center my-2 f-grey">
                                  <img
                                    alt=""
                                    className="ml-1 mr-2"
                                    src="/images/msp/cross-circular-tick.svg"
                                  />{" "}
                                  This service has no features
                                </div>
                              )}
                            </>
                          )} */}
                        </div>
                      );
                    }
                  )}
                </div>
              ) : (
                <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                  <div
                    className="radius-8 zoom-independent"
                    style={{
                      boxShadow: "2px 2px 13px 1px rgba(0,0,0,0.1)",
                    }}
                  >
                    <Lottie
                      loop={true}
                      play={true}
                      speed={0.7}
                      animationData={animationData.default}
                      style={{ width: 340, height: 300, margin: "0 auto" }}
                    />
                  </div>

                  <div className="text-center d-flex flex-column justify-content-center mt-4">
                    <div className="f-16 f-500">Link into series</div>
                    <div className="f-14 f-darkgrey mt-2">
                      Build up your service offerings by linking similar
                      services together in a service series.
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <CustomDeleteModal
          show={seriesModalData?.showUnlinkModal}
          deleteHeaderIcon={"/images/msp/yellow-exclammation.svg"}
          hideModal={() => {
            setSeriesModalData((prev) => ({
              ...prev,
              showUnlinkModal: false,
            }));
          }}
          deleteButtonClass={"hg-blue-btn"}
          deleteButtonText={"Unlink"}
          deleteHeaderText={`Unlink ${seriesModalData?.deleteConfig?.unlinkName}`}
          deleteBodyText={
            "Unlinking a service from its series will remove all inherited features and service costs."
          }
          deleteFooterText={
            "Are you sure you want to unlink this service from its series?"
          }
          deleteFunction={() => {
            unlinkSeries(
              seriesModalData?.deleteConfig?.deleteId,
              seriesModalData?.deleteConfig?.deleteId2,
              seriesModalData?.deleteConfig?.closeModal
            );
          }}
          deleteParams={seriesModalData?.deleteConfig}
          deleteType={"unlinkMspService"}
        />
        <CustomDeleteModal
          show={seriesModalData?.showDeleteModal}
          deleteHeaderIcon={"/images/msp/danger-exclammation.svg"}
          hideModal={() => {
            setSeriesModalData((prev) => ({
              ...prev,
              showDeleteModal: false,
            }));
          }}
          deleteHeaderText={"Delete series"}
          deleteBodyText={
            "Deleting a series will remove all inherited features and services costs from services linked in a series."
          }
          deleteFooterText={"Are you sure you want to delete this series?"}
          deleteFunction={() => {
            deleteLinkedSerie(seriesModalData?.deleteConfig?.deleteId);
            clearAll();
            hideModal();
            // seriesModalData.linkCurrSerie(servicePacksDetails);
          }}
          deleteParams={seriesModalData?.deleteConfig}
          deleteType={"deleteMspSerie"}
        />
        <CustomDeleteModal
          show={warningModalConfig?.showWarningModal}
          deleteHeaderIcon={"/images/msp/yellow-exclammation.svg"}
          hideModal={() => {
            setWarningModalConfig((prev) => ({
              ...prev,
              showWarningModal: false,
            }));
          }}
          deleteButtonClass={"hg-blue-btn"}
          deleteButtonText={"Close"}
          deleteHeaderText={"Warning"}
          deleteBodyText={"Closing this modal will remove all changes."}
          deleteFooterText={"Are you sure you want to close this modal?"}
          deleteFunction={() => {
            clearAll();
            refreshData();
            hideModal();
            // seriesModalData.linkCurrSerie(servicePacksDetails);
          }}
          deleteParams={{}}
          deleteType={"warningClose"}
        />
      </SlidingModal.Body>
      <SlidingModal.Footer
        className="p-3 position-sticky w-100 border-top z-Index-1000"
        style={{ zIndex: 1000 }}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div>
            {seriesModalData?.seriesId > 0 && (
              <div
                className="d-flex align-items-center f-500 f-grey pointer"
                onClick={() => {
                  setChanged(true);
                  setSeriesModalData((prev) => ({
                    ...prev,
                    showDeleteModal: true,
                    deleteConfig: { deleteId: seriesModalData?.seriesId },
                  }));
                }}
              >
                <img
                  alt=""
                  className="mx-2"
                  src="/images/actioncenter/delete.svg"
                />
                Delete
              </div>
            )}
          </div>

          <div className="d-flex justify-content-end">
            <Button
              className="hg-cancel-btn mr-2"
              onClick={() => {
                if (!changed) {
                  clearAll();
                  refreshData();
                  hideModal();
                  // seriesModalData.linkCurrSerie(servicePacksDetails);
                } else {
                  setWarningModalConfig((prev) => ({
                    ...prev,
                    showWarningModal: true,
                  }));
                }
                // clearAll();
                // refreshData();
                // hideModal();
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={false}
              className="hg-blue-btn"
              onClick={async () => {
                let errors = await CheckValidationForServicePage();
                if (errors > 0) {
                  let _modal = document.querySelector(
                    ".msp-series-add-edit-modal"
                  );
                  let top_error =
                    _modal.getElementsByClassName("input-error")[0];
                  if (top_error) {
                    top_error.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                      inline: "nearest",
                    });
                  }
                  // return false;
                } else {
                  setChanged(false);
                  let id = seriesModalData?.seriesId
                    ? seriesModalData?.seriesId
                    : selectedObjId_SecurityPackLinkSeries;
                  createEditseries(id).then(() => {
                    setChanged(true);

                    // addOrRemove Means boolean to add to list or to remove
                    // let updated_list = [...currentService];

                    // if (selectedSerie) {
                    //   let isLinkedItemIndex = selectedLinkList?.findIndex(
                    //     (item) =>
                    //       item?.objId_SecurityPackLinkSeries ===
                    //       selectedSerie?.objId_SecurityPackLinkSeries
                    //   );
                    //   if (isLinkedItemIndex > -1) {
                    //     updated_list.splice(isLinkedItemIndex, 1);
                    //   } else {
                    //     updated_list.push({ ...selectedSerie });
                    //   }
                    //   setSelectedLinkList(updated_list);
                    // } else {
                    //   setSelectedLinkList([]);
                    //   setLinkedProductsList([]);
                    // }
                  });
                  console.log(seriesModalData);
                  setSeriesModalData((prev) => ({
                    ...prev,
                    SeriesName: removeWhiteSpace(
                      servicePacksDetails?.SeriesName
                    ),
                    selectedList: currentService,
                    serviceModalState: "edit",
                    seriesId: id,
                  }));
                  hideModal();
                  clearAll();
                }
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </SlidingModal.Footer>
    </SlidingModal>
  );
};

export default MspAddEditSeriesModal;
