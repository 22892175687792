import React, { useState, useEffect, useRef } from "react";

import "../../common.scss";
import { Form, Button, Dropdown } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import WorkstationModal, { RenderImagesGroup } from "./WorkstationModal";
import { TrimString } from "../../../../Utils/StringTrimmer";
import KPIpopup from "../../KPIpopup/KPIpopup";
import {
  AttackSurfaceGetApi,
  AttackSurfacePostApi,
} from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";

import AssetsDataPageLoader from "../../AttackSurfaceLoaders/AssetsDataPageLoader";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import { GreyLoader } from "../../../Theme/APILoaders";
import AttackSurfaceTooltip from "../AttackSurfaceTooltip";
import CompanySettingsDropdown from "../../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import AssetsSearchLoader from "../../AttackSurfaceLoaders/AssetsSearchLoader";
import { ToastsV2 } from "../../../settingsV2/ToastsV2";
import { viewOnlyAttackSurface } from "../../../../Utils/AccessLevelFunctions";
// import { Chart } from "chart.js";
// import WorkstationModal from "./WorkStationModal";

const AssetsData = ({ typeName, assetModifierId, refreshMenuItems }) => {
  const SearchInput = useRef();
  const { authTokens } = useAuth();
  const [showWorkstationModal, setShowWorkstationModal] = useState(false);

  const [modalHeader, setModalHeader] = useState("Add Workstation");
  const [searchInput, setSearchInput] = useState(false);
  const [filteredMenuItems, setfilteredMenuItems] = useState(false);
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [headersList, setHeadersList] = useState([]);
  const [modalDataStructure, setModalDataStructure] = useState({});
  const [dataToRender, setDataToRender] = useState([]);
  const [IsService, setIsService] = useState(false);

  const [enabled, setEnabled] = useState(true);

  const [loadingNewPage, setLoadingNewPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [screenLoaded, setScreenLoaded] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [searching, setSearching] = useState(false);
  const [searchColumn, setSearchColumn] = useState({
    FieldKey: "All",
    FieldName: "All",
  });
  const [BizObjectId, setBizObjectId] = useState(0);
  const [bizObjectIdsRowWise, setBizObjectIdsRowWise] = useState([]);
  const [shownList, setShownList] = useState([]);
  const [hiddenList, setHiddenList] = useState([]);

  const [archiveSwitch1, setArchiveSwitch1] = useState(false);
  const [archiveSwitch2, setArchiveSwitch2] = useState(false);
  const [nextPage, setNextPage] = useState(false);

  // Toast
  const [showWaitingToast, setShowWaitingToast] = useState(false);
  // const [toastText, setToastText] = useState("");
  const [archivingDone, setArchivingDone] = useState(false);

  useEffect(() => {
    setMainPageLoading(true);

    setupToChangeEditStructure();
  }, [assetModifierId]);

  function pageData(assetModifierId, FieldList) {
    // Headers list
    AttackSurfaceGetApi(
      `AttackSurfaceAPI/GetObjectFiltersAndSorting?`, //?OrgId=237003
      authTokens,
      {
        AssetIdentifier: assetModifierId,
      }
    ).then((response) => {
      let headerList = [...response?.Result?.visibilityVm];
      setHeadersList(headerList);
      setShownList(headerList.filter((item) => item.Visibility === true));
      setHiddenList(headerList.filter((item) => item.Visibility === false));
      AttackSurfaceGetApi(
        `AttackSurfaceAPI/GetASEData?`, //?OrgId=237003
        authTokens,
        {
          objDefId: assetModifierId,
          pageNumber: 1,
          pageSize: 20,
          OrderBy: "",
          SearchStr: "",
          SearchColumn: "",
          // AccessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        }
      )
        .then((response) => {
          setMainPageLoading(false);
          setScreenLoaded(true);
          setEnabled(!!!response?.Disabled);
          setIsService(response?.isService);
          setArchiveSwitch1(!!response?.Disabled);
          setArchiveSwitch2(!!response?.Disabled);
          setNextPage(response?.NextPage);
          if (response?.GetASEDataResponse?.length > 0) {
            // Add BozObjectIds in a array with same order
            let BizIds = [];
            let updated_tableData = [...response?.GetASEDataResponse];
            for (let i = 0; i < updated_tableData?.length; i++) {
              BizIds.push(updated_tableData[i]["BizObjectId"]);
              delete updated_tableData[i]["BizObjectId"];
            }
            setBizObjectIdsRowWise(BizIds);
            let table_row_data = updated_tableData.map((tableItem) => {
              return Object.values(tableItem);
            });
            for (let i = 0; i < table_row_data?.length; i++) {
              table_row_data[i] = changeDataFormat(
                FieldList,
                table_row_data[i],
                "ASE_FieldName",
                "ASE_FieldName"
              );
            }
            setTableData(table_row_data);
          } else {
            setTableData([]);
          }
        })
        .catch((err) => {
          CallToast("Something went wrong", false, false, "v2style");
        });
    });
  }

  useEffect(() => {
    let dragdropdown = document.getElementById("drag-drop-dropdown");
    if (!dragdropdown) return;
    dragdropdown.classList.add("d-none");
    function CloseDraggableDropdown(e) {
      const DraggableDropdownParent =
        document.getElementById("dropdown-parent");
      const DraggableDropdown = document.getElementById("drag-drop-dropdown");

      // const Parent2 = document.getElementById("parent-2");

      if (
        e.target !== DraggableDropdownParent &&
        !DraggableDropdownParent?.contains(e.target)
      ) {
        if (e.target.id == "rename-div") {
          return null;
        } else {
          setfilteredMenuItems(false);

          DraggableDropdown && DraggableDropdown.classList.add("d-none");
        }
      }
    }

    document.addEventListener("click", CloseDraggableDropdown);

    return () => {
      document.removeEventListener("click", CloseDraggableDropdown);
    };
  }, [mainPageLoading]);

  // Setup Draggable section list
  // const grid = 8;
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEndShown(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      shownList,
      result.source.index,
      result.destination.index
    );
    setShownList(
      items.map((item, index) => {
        return {
          ...item,
          SortOrder: index + 1,
        };
      })
    );
    sortFilterTableData([
      ...items.map((item, index) => {
        return {
          ...item,
          SortOrder: index + 1,
        };
      }),
      ...hiddenList,
    ]);
  }
  function onDragEndHidden(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      hiddenList,
      result.source.index,
      result.destination.index
    );
    setHiddenList(items);
  }

  // Change the Edit data same as Add data to render the modal
  async function setupToChangeEditStructure() {
    await AttackSurfaceGetApi(
      `AttackSurfaceAPI/GetObjectParameters?`, //?OrgId=237003
      authTokens,
      {
        ObjDefId: assetModifierId,
      }
    )
      .then((response) => {
        setModalDataStructure(response?.FieldList);
        pageData(assetModifierId, response?.FieldList);
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  function changeDataFormat(mainData, rowData, rowfield, mainField) {
    //  "mainfield" is key in Main data to match in main row data
    try {
      let resultArr = [];
      for (let i = 0; i < rowData.length; i++) {
        let row_object = mainData.filter(
          (item) => item[mainField] === rowData[i][rowfield]
        )[0];
        let test = { ...row_object };

        resultArr.push({
          ...test,
          ASE_FieldValue: jsonParseData(rowData[i]?.ASE_FieldValue),
          ASE_FieldVerified: rowData[i]?.ASE_FieldVerified,
          ASE_FieldSourceName: rowData[i]?.ASE_FieldSourceName,
          ASE_FieldSource: rowData[i]?.ASE_FieldSource,
        });
      }

      return resultArr;
    } catch (err) {
      console.log("data-mapping-error", err);
    }
  }
  // Function to Filter and sort the Elements
  async function sortFilterTableData(updated_data) {
    await AttackSurfacePostApi(
      authTokens,
      `AttackSurfaceAPI/UpdateASEFilters?AssetIdentifier=${assetModifierId}`,
      updated_data
    )
      .then((response) => {
        // if (response?.Result?.Success) {
        pageData(assetModifierId, modalDataStructure);
        // }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // Functon to Fetch more Rows:

  async function getPaginationData(pageNumber) {
    AttackSurfaceGetApi(
      `AttackSurfaceAPI/GetASEData?`, //?OrgId=237003
      authTokens,
      {
        objDefId: assetModifierId,
        pageNumber: pageNumber,
        pageSize: 20,
        OrderBy: "",
        SearchStr: searchString,
        SearchColumn: searchColumn?.FieldKey,

        // AccessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      }
    )
      .then((response) => {
        setMainPageLoading(false);
        setLoadingNewPage(false);
        setEnabled(!!!response?.Disabled);
        setIsService(response?.isService);
        setArchiveSwitch1(!!response?.Disabled);
        setArchiveSwitch2(!!response?.Disabled);
        setNextPage(response?.NextPage);
        if (response?.GetASEDataResponse?.length > 0) {
          // Add BozObjectIds in a array with same order
          let BizIds = [];
          let updated_tableData = [...response?.GetASEDataResponse];
          for (let i = 0; i < updated_tableData?.length; i++) {
            BizIds.push(updated_tableData[i]["BizObjectId"]);
            delete updated_tableData[i]["BizObjectId"];
          }
          setBizObjectIdsRowWise(BizIds);
          let table_row_data = updated_tableData.map((tableItem) => {
            return Object.values(tableItem);
          });
          for (let i = 0; i < table_row_data?.length; i++) {
            table_row_data[i] = changeDataFormat(
              modalDataStructure,
              table_row_data[i],
              "ASE_FieldName",
              "ASE_FieldName"
            );
          }

          setTableData([...tableData, ...table_row_data]);
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  async function searchAssetsTable(field, search = "") {
    setSearching(true);
    setPageNumber(1);
    AttackSurfaceGetApi(
      `AttackSurfaceAPI/GetASEData?`, //?OrgId=237003
      authTokens,
      {
        objDefId: assetModifierId,
        pageNumber: 1,
        pageSize: 20,
        OrderBy: "",
        SearchStr: search,
        SearchColumn: field,

        // AccessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      }
    )
      .then((response) => {
        // for Archiving Toast

        setMainPageLoading(false);
        setLoadingNewPage(false);
        setEnabled(!!!response?.Disabled);
        setIsService(response?.isService);
        setArchiveSwitch1(!!response?.Disabled);
        setArchiveSwitch2(!!response?.Disabled);
        setNextPage(response?.NextPage);
        setSearching(false);
        if (response?.GetASEDataResponse?.length > 0) {
          // Add BizObjectIds in a array with same order
          let BizIds = [];
          let updated_tableData = [...response?.GetASEDataResponse];
          for (let i = 0; i < updated_tableData?.length; i++) {
            BizIds.push(updated_tableData[i]["BizObjectId"]);
            delete updated_tableData[i]["BizObjectId"];
          }
          setBizObjectIdsRowWise(BizIds);
          let table_row_data = updated_tableData.map((tableItem) => {
            return Object.values(tableItem);
          });
          for (let i = 0; i < table_row_data?.length; i++) {
            table_row_data[i] = changeDataFormat(
              modalDataStructure,
              table_row_data[i],
              "ASE_FieldName",
              "ASE_FieldName"
            );
          }

          setTableData(table_row_data);
        } else {
          setTableData([]);
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // To archive Assets
  function archiveAllObjects(boolValue) {
    // setShowWaitingToast(true);
    setTimeout(() => {
      setSearching(true);
      AttackSurfaceGetApi(
        `AttackSurfaceAPI/ArchiveAllObjectsByDefId?`, //?OrgId=237003
        authTokens,
        {
          ObjDefId: assetModifierId,
          Disabled: boolValue,
        }
      )
        .then((response) => {
          setSearchString("");
          searchAssetsTable("all");
          refreshMenuItems();
          // setArchiveSwitch(!response?.HasVerified);
          // setArchivingDone(true);
          // setTimeout(() => {
          //   setShowWaitingToast(false);
          //   setTimeout(() => {
          //     searchAssetsTable("all");
          //     setArchivingDone(false);
          //   }, 100);
          // }, 1500);
        })
        .catch(() => {
          setSearching(false);
          CallToast("Something went wrong", false, false, "v2style");
        });
    }, 500);
  }

  var parentWidth;
  var startWidth;

  function isEmptyAssetValue(value) {
    if (value === null) {
      return true;
    } else if (value === "") {
      return true;
    } else if (typeof value === "object" && Object.keys(value)?.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      {mainPageLoading ? (
        <AssetsDataPageLoader />
      ) : (
        <div className="main-parent-wrapper h-100" 
        style={{
          pointerEvents: viewOnlyAttackSurface() ? "none" : "all"
        }} 
        >
          {/* Header Top bar for component */}
          <div className="header-wrapper d-flex align-items-center justify-content-between p-4">
            <div className="d-flex align-items-center">
              <div className="f-600">{typeName}</div>
              {!enabled && (
                <div className="header-for-disabled">
                  <img
                    alt=""
                    className="mr-1"
                    src="/images/attack-surface/yello-info-icon.svg"
                  />
                  <span className="f-warning d-inline-flex align-items-center">
                    You don't {IsService ? "use" : "have"}&nbsp;
                    {TrimString(typeName, 25)} anymore
                  </span>
                  <span
                    className="pointer"
                    onClick={() => {
                      archiveAllObjects(false);
                    }}
                  >
                    &nbsp;
                    <u className="f-blue pointer d-inline-flex align-items-center">
                      Re-enable
                    </u>
                  </span>
                </div>
              )}
            </div>

            <div
              className="d-flex align-items-center "
              title={`${enabled ? "" : `Kindly enable ${typeName} first.`}`}
              style={{
                pointerEvents: `${enabled ? "all" : "none"}`,
              }}
            >
              <div className="mx-2 ">Search by column:</div>
              <div>
                <div className="compliance-filter-dropdown mr-5- assets-search-filter-dropdown">
                  <CompanySettingsDropdown
                    fieldId={"FieldKey"}
                    fieldValue={"FieldName"}
                    selected={searchColumn}
                    placeholder="Search Column"
                    //  showid={true}
                    onClick={(id, val) => {
                      setSearchColumn({ FieldKey: id, FieldName: val });
                      if (searchString !== "") {
                        searchAssetsTable(id, searchString);
                      }
                      // setComplianceFilter({ id, category: val });
                      // props.getComplianceProfiles(id);
                    }}
                    data={[
                      {
                        FieldKey: "All",
                        FieldName: "All",
                      },
                      ...shownList,
                    ]}
                  />
                </div>
              </div>
              <div className="mx-2 d-flex align-items-center">
                <img
                  alt=""
                  src="/images/attack-surface/search-icon.svg"
                  className="pointer"
                  onClick={() => {
                    setSearchInput(true);
                    SearchInput.current.focus();
                  }}
                />
                <input
                  type="text"
                  placeholder="Search.."
                  ref={SearchInput}
                  value={searchString}
                  onChange={(e) => {
                    setSearchString(e.target.value);
                    if (searchColumn?.FieldKey) {
                      searchAssetsTable(searchColumn.FieldKey, e.target.value);
                    }
                  }}
                  onBlur={() => {
                    // setSearchInput(false);
                  }}
                  className={`${
                    searchInput
                      ? "animated-search-input-open"
                      : "animated-search-input-close"
                  } pl-2`}
                  autoFocus
                />
              </div>
              <div className="mx-2">
                <>
                  <Dropdown
                    id="dropdown-parent"
                    className="table-drop-down edit-section-dropdown position-relative mt-1"
                  >
                    <Dropdown.Toggle
                      className={`section-list-toggle`}
                      onClick={() => {
                        console.log("toggle clicked");
                        const dragdropdown =
                          document.getElementById("drag-drop-dropdown");
                        dragdropdown.classList.toggle("d-none");
                      }}
                    >
                      <div className="navtab" id="low">
                        <img
                          alt=""
                          src="/images/actioncenter/context.svg"
                          className="image-click"
                        />
                      </div>
                    </Dropdown.Toggle>
                    <div
                      id="drag-drop-dropdown"
                      className={`dropdown_menu_list drop-drag-issue position-absolute     
                         
                         ${filteredMenuItems ? "w-320" : "w-240"}
                          
                          `}
                    >
                      {!filteredMenuItems ? (
                        <div className="" id="rename-div">
                          <div
                            className="d-flex align-items-center f-grey pointer p-3"
                            onClick={() => {
                              setfilteredMenuItems(true);
                            }}
                            id="rename-div"
                          >
                            <img
                              alt=""
                              src="/images/attack-surface/column-icon.svg"
                              className="mr-2"
                            />
                            Columns settings
                            <span className="mr-3"></span>
                            <img
                              alt=""
                              src="/images/attack-surface/chevron-right.svg"
                              className="ml-5"
                            />
                          </div>
                          <hr className="m-0 p-0" />
                          <div className="d-flex align-items-center justify-content-between f-grey p-3">
                            <div style={{ maxWidth: "75%" }}>
                              I don't {IsService ? "use" : "have any"}
                              <br /> {typeName} anymore
                            </div>
                            <div>
                              <Form.Group controlId={"check"} className="">
                                <Form.Check
                                  type="switch"
                                  id="switch-ques1"
                                  style={{ transform: "scale(1.6)" }}
                                  className="m-0 pl-3 scale-switch switch-shadow-none"
                                  checked={archiveSwitch1}
                                  onChange={(e) => {
                                    // if (e.target.checked) {
                                    //   archiveAllObjects();
                                    // }
                                    archiveAllObjects(e.target.checked);
                                    setArchiveSwitch1(e.target.checked);
                                  }}
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          {/* Shown Items */}
                          <div className="m-3"></div>
                          <span className="list_item_header">Shown:</span>
                          <DragDropContext onDragEnd={onDragEndShown}>
                            <Droppable
                              direction="vertical"
                              droppableId="droppableSection"
                            >
                              {(provided, snapshot) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  style={{
                                    maxHeight: "215px",
                                    overflowY: "auto",
                                  }}
                                  className="hide-scrollbar"
                                  // style={getListStyle(snapshot.isDraggingOver)}
                                >
                                  {shownList &&
                                    shownList
                                      .filter(
                                        (item) => item.Visibility === true
                                      )
                                      .map((section, i) => (
                                        <Draggable
                                          key={section?.FieldKey}
                                          draggableId={section?.FieldKey}
                                          index={i}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              className="list_item d-flex justify-content-between  px-2"
                                              key={i}
                                              index={i}
                                            >
                                              <>
                                                <div
                                                  className="d-inline-flex w-75"
                                                  onClick={() => {}}
                                                  style={{
                                                    alignItems: "center",
                                                    paddingTop: "3px",
                                                  }}
                                                >
                                                  <img
                                                    alt=""
                                                    src="/images/actioncenter/contextmenu.svg"
                                                    className="pr-2"
                                                    style={{ cursor: "grab" }}
                                                    onClick={(e) => {}}
                                                  />
                                                  {section?.FieldName}
                                                </div>

                                                <div
                                                  id="rename-div"
                                                  style={{
                                                    float: "right",
                                                  }}
                                                  className="position-relative sideBarDropdown complianceManagerDropdown actioncenterdropdown"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    let updated_shown = [
                                                      ...shownList,
                                                    ];
                                                    let removed_item =
                                                      updated_shown.splice(
                                                        i,
                                                        1
                                                      )[0];
                                                    removed_item.Visibility = false;
                                                    setShownList(
                                                      updated_shown.map(
                                                        (item, index) => {
                                                          return {
                                                            ...item,
                                                            SortOrder:
                                                              index + 1,
                                                          };
                                                        }
                                                      )
                                                    );
                                                    sortFilterTableData([
                                                      ...updated_shown.map(
                                                        (item, index) => {
                                                          return {
                                                            ...item,
                                                            SortOrder:
                                                              index + 1,
                                                          };
                                                        }
                                                      ),
                                                      ...hiddenList,
                                                      removed_item,
                                                    ]);
                                                    setHiddenList([
                                                      ...hiddenList,
                                                      { ...removed_item },
                                                    ]);
                                                  }}
                                                >
                                                  <img
                                                    id="rename-div"
                                                    alt=""
                                                    src="/images/attack-surface/shown-icon.svg"
                                                    className="pr-2 float-right mt-2"
                                                  />
                                                </div>
                                              </>
                                            </div>
                                          )}
                                        </Draggable>
                                      ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>

                          {/* Hidden Items */}
                          <div className="m-3"></div>
                          <span className="list_item_header mt-3">Hidden:</span>
                          <DragDropContext onDragEnd={onDragEndHidden}>
                            <Droppable
                              direction="vertical"
                              droppableId="droppableSection"
                            >
                              {(provided, snapshot) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  style={{
                                    maxHeight: "215px",
                                    overflowY: "auto",
                                  }}
                                  className="hide-scrollbar"
                                  // style={getListStyle(snapshot.isDraggingOver)}
                                >
                                  {hiddenList &&
                                    hiddenList
                                      .filter(
                                        (item) => item.Visibility === false
                                      )
                                      .map((section, i) => (
                                        <Draggable
                                          key={section?.FieldKey}
                                          draggableId={section?.FieldKey}
                                          index={i}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              className="list_item d-flex justify-content-between  px-2"
                                              key={i}
                                              index={i}
                                            >
                                              <>
                                                <div
                                                  className="d-inline-flex w-75"
                                                  onClick={() => {}}
                                                  style={{
                                                    alignItems: "center",
                                                    paddingTop: "3px",
                                                  }}
                                                >
                                                  <img
                                                    alt=""
                                                    src="/images/actioncenter/contextmenu.svg"
                                                    className="pr-2"
                                                    style={{ cursor: "grab" }}
                                                  />
                                                  {section.FieldName}
                                                </div>

                                                <div
                                                  style={{
                                                    float: "right",
                                                  }}
                                                  className="position-relative sideBarDropdown complianceManagerDropdown actioncenterdropdown"
                                                >
                                                  <img
                                                    id="rename-div"
                                                    alt=""
                                                    src="/images/attack-surface/hidden-icon.svg"
                                                    className="pr-2 float-right mt-2"
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      e.preventDefault();
                                                      let updated_hidden = [
                                                        ...hiddenList,
                                                      ];
                                                      let removed_item =
                                                        updated_hidden.splice(
                                                          i,
                                                          1
                                                        )[0];
                                                      removed_item.Visibility = true;
                                                      setHiddenList(
                                                        updated_hidden
                                                      );
                                                      let updated_shown = [
                                                        ...shownList,
                                                        { ...removed_item },
                                                      ];
                                                      sortFilterTableData([
                                                        ...updated_shown.map(
                                                          (item, index) => {
                                                            return {
                                                              ...item,
                                                              SortOrder:
                                                                index + 1,
                                                            };
                                                          }
                                                        ),
                                                        ...updated_hidden,
                                                      ]);
                                                      setShownList(
                                                        updated_shown.map(
                                                          (item, index) => {
                                                            return {
                                                              ...item,
                                                              SortOrder:
                                                                index + 1,
                                                            };
                                                          }
                                                        )
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              </>
                                            </div>
                                          )}
                                        </Draggable>
                                      ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </>
                      )}
                    </div>
                  </Dropdown>
                </>
              </div>
              <div className="mx-2">
                <Button
                  className="hg-blue-btn py-1 px-2"
                  disabled={!enabled}
                  onClick={() => {
                    setModalHeader(`Add ${typeName}`);
                    setShowWorkstationModal(true);
                    setBizObjectId(0);
                  }}
                >
                  Add
                </Button>
              </div>
            </div>
          </div>

          <div
            className="body hide-scrollbar h-100"
            style={{
              overflowX: "scroll",
              overflowY: "hidden",
            }}
          >
            {/* No Data State */}
            {searching ? (
              <AssetsSearchLoader />
            ) : (
              <>
                {tableData?.length == 0 && (
                  <div className="d-flex flex-column align-items-center mt-5">
                    <img
                      alt=""
                      src="/images/attack-surface/nodata-icon.svg"
                      className="my-4"
                    />
                    <div className="f-600 f-grey text-center mb-2">
                      No {typeName}
                    </div>
                    <div className="f-darkgrey text-center mb-3">
                      Add your {typeName} to the list
                    </div>

                    {searchString === "" && (
                      <div className="bg-grey radius-8 d-flex align-items-start justify-content-between  p-3">
                        <div
                          className="d-flex flex-column mr-5"
                          style={{ width: "75%" }}
                        >
                          <div className="d-flex align-items-baseline ">
                            <img
                              alt=""
                              src="/images/attack-surface/yellow-dot.svg"
                              className="mr-1"
                              style={{
                                visibility: `${enabled ? "visible" : "hidden"}`,
                              }}
                            />

                            <p className="f-500 m-0 w-max-content">
                              I don't {IsService ? "use" : "have any"}{" "}
                              {typeName}
                            </p>
                          </div>
                          <p className="f-12 f-darkgrey ml-2 w-max-content">
                            Choose if you don't {IsService ? "use" : "have"}{" "}
                            {typeName}
                          </p>
                        </div>
                        <div>
                          <Form.Group controlId={"check"} className="">
                            <Form.Check
                              type="switch"
                              id="switch-ques"
                              style={{ transform: "scale(1.6)" }}
                              className="m-0 scale-switch switch-shadow-none"
                              checked={archiveSwitch2}
                              onChange={(e) => {
                                // if (e.target.checked) {
                                //   archiveAllObjects();
                                // }
                                archiveAllObjects(e.target.checked);
                                setArchiveSwitch2(e.target.checked);
                              }}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {/* Table Data */}

                {tableData?.length > 0 && (
                  <div
                    className="bg-grey radius-8 border-parent ml-3"
                    style={{
                      width: "max-content",
                    }}
                  >
                    {/*Table Header */}
                    <div className="border-item d-flex align-items-center justify-content-between p-2 table-row-parent">
                      {headersList.map((headerName, headerIndex) => {
                        return (
                          <div
                            className={`f-12 f-grey position-relative   justify-content-start table-row-child-- ${
                              headerName?.Visibility ? "d-flex-" : "d-none"
                            }`}
                            title={headerName?.FieldName}
                            style={{
                              paddingLeft: `${
                                headerIndex === 0 ? "3rem" : "1rem"
                              }`,
                              paddingRight: "0.5rem",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              float: "left",
                              minWidth: "185px",
                              width: `${185}px`,
                            }}
                            id={`attDrag${headerIndex}`}
                          >
                            {/* {TrimString(headerName?.FieldName, 15)} */}
                            {headerName?.FieldName}
                            <img
                              alt=""
                              onDragStart={(e) => {
                                startWidth = e.clientX;
                                parentWidth = e.target.parentNode.clientWidth;
                              }}
                              onDrag={(e) => {
                                var x = e.clientX;

                                if (x > 0 && startWidth > 0) {
                                  const nodeList = document.querySelectorAll(
                                    `#attDrag${headerIndex}`
                                  );
                                  for (let i = 0; i < nodeList.length; i++) {
                                    nodeList[i].style.width = `${
                                      parentWidth + x - startWidth
                                    }px`;
                                  }

                                  return;
                                  // One liner function:
                                  function insertCss(code) {
                                    var style = document.createElement("style");
                                    style.type = "text/css";

                                    if (style.styleSheet) {
                                      // IE
                                      style.styleSheet.cssText = code;
                                    } else {
                                      // Other browsers
                                      style.innerHTML = code;
                                    }

                                    document
                                      .getElementsByTagName("head")[0]
                                      .appendChild(style);
                                  }

                                  // if (headerIndex === 0) {
                                  //   insertCss(
                                  //     `#root #attDrag${headerIndex}{width: ${
                                  //       parentWidth + x - startWidth
                                  //     }px !important}

                                  //    #root span#attDrag0{width: ${
                                  //      parentWidth + x - startWidth - 31
                                  //    }px !important}

                                  //     `
                                  //   );
                                  // } else {
                                  //   insertCss(
                                  //     `#root #attDrag${headerIndex}{width: ${
                                  //       parentWidth + x - startWidth
                                  //     }px !important}

                                  //     `
                                  //   );
                                  // }

                                  // -------
                                }
                              }}
                              onDragEnd={() => {}}
                              className={`${
                                headersList?.length - 1 === headerIndex
                                  ? "d-none"
                                  : ""
                              }`}
                              src="/images/attack-surface/row-drag-icon.svg"
                              style={{
                                position: "absolute",
                                right: "0",
                                bottom: "50%",
                                cursor: "col-resize",
                                filter: "grayscale(0.7)",
                                transform: "rotate(90deg)",
                                height: "2px",
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                    {/* Table Body */}

                    {tableData?.map((data, dataIndex) => {
                      return (
                        <div
                          className={`border-item d-flex align-items-center justify-content-betwe-en p-2 pb-4 table-row-parent bg-hover-row ${
                            enabled ? "" : "disable-attack-row"
                          }`}
                          // id={`attDrag${dataIndex}`}
                          onClick={() => {
                            let data = changeDataFormat(
                              modalDataStructure,
                              tableData[dataIndex],
                              "ASE_FieldName",
                              "ASE_FieldName"
                            );
                            setBizObjectId(bizObjectIdsRowWise[dataIndex]);
                            // setTimeout(() => {
                            setDataToRender(data);
                            setModalHeader(`Edit ${typeName}`);
                            setShowWorkstationModal(true);
                            // }, );
                          }}
                        >
                          {data.map((item, itemIndex) => {
                            const rowVerified = data.some((item) => {
                              if (
                                item?.ASE_FieldVerified === true &&
                                !isEmptyAssetValue(item?.ASE_FieldValue)
                              ) {
                                return true;
                              } else if (
                                item?.ASE_FieldVerified === false &&
                                isEmptyAssetValue(item?.ASE_FieldValue)
                              ) {
                                return false;
                              } else {
                                return false;
                              }
                            });
                            const rowUnVerified = data.every(
                              (item) => item?.ASE_FieldVerified === false
                            );

                            return (
                              <div
                                className="d-flex  justify-content-start table-row-child--"
                                // title={item["ASE_FieldValue"]}
                                // id={`row${dataIndex}  attDrag${itemIndex}`}
                                id={`attDrag${itemIndex}`}
                                style={{
                                  paddingLeft: `${"0.5rem"}`,
                                  paddingRight: "0.5rem",
                                  whiteSpace: "nowrap",
                                  // overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  float: "left",
                                  minWidth: "185px",
                                  width: `${185}px`,
                                }}
                              >
                                <span
                                  className="d-inline-flex align-items-center"
                                  id={`row${dataIndex}`}
                                >
                                  <span className="position-relative">
                                    <KPIpopup data={KPIModalData} />
                                  </span>
                                  {itemIndex === 0 && (
                                    <span
                                      className="d-inline-flex align-items-center pl-1 position-relative"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        let modal =
                                          document.getElementsByClassName(
                                            "kpi-modal"
                                          );
                                        for (let i = 0; i < modal.length; i++) {
                                          modal[i].style.display = "none";
                                        }
                                        const event = e;

                                        const parent = event.target.closest(
                                          `#row${dataIndex}`
                                        );
                                        let modalx =
                                          parent.querySelector(".kpi-modal");
                                        modalx.style.display = "block";
                                        modalx.style.width = "500px";
                                        modalx.style.top = "-3rem";
                                        modalx.style.left = "2rem";
                                      }}
                                    >
                                      <img
                                        alt=""
                                        src={`/images/attack-surface/${
                                          rowVerified
                                            ? "verified.svg"
                                            : rowUnVerified
                                            ? "unverified.svg"
                                            : "part-verified.svg"
                                        }`}
                                        className="mr-2 position-absolute"
                                      />
                                    </span>
                                  )}

                                  {itemIndex > 0 &&
                                    item["ASE_AttrDef_InputType"] !==
                                      "A_Drop Down List (Multi)" && (
                                      <span>
                                        {item["ASE_FieldValue"] &&
                                          item?.ASE_FieldVerified && (
                                            <AttackSurfaceTooltip
                                              className={"ml-2"}
                                              content={
                                                <span>
                                                  Auto-generated cannot be
                                                  edited
                                                  <RenderImagesGroup
                                                    sourceName={
                                                      item?.ASE_FieldSource
                                                    }
                                                  />
                                                </span>
                                              }
                                            >
                                              <img
                                                alt=""
                                                src="/images/attack-surface/small-lock.svg"
                                              />
                                            </AttackSurfaceTooltip>
                                          )}
                                      </span>
                                    )}

                                  <span
                                    className="d-inline-fle x align-items-center "
                                    id={`attDrag${itemIndex}`}
                                    style={{
                                      paddingLeft: `${
                                        itemIndex === 0 ? "2.5rem" : "0.5rem"
                                      }`,
                                      paddingRight: "0.5rem",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      float: "left",
                                      minWidth: `${"185px"}`,
                                      width: `${"185px"}`,
                                    }}
                                  >
                                    {item["ASE_AttrDef_InputType"] ===
                                    "A_Drop Down List (Multi)" ? (
                                      <div className="d-flex flex-wrap">
                                        {item["ASE_FieldValue"] &&
                                          /* JSON.parse*/ item["ASE_FieldValue"]

                                            ?.slice(0, 3)
                                            .map((selected, selectedIndex) => {
                                              return (
                                                <div
                                                  className="f-12 f-500 f-grey d-flex align-items-center p-2 mr-1 mb-1 "
                                                  style={{
                                                    background: "#e4e5ed",
                                                  }}
                                                >
                                                  <span className="d-inline-flex align-items-center">
                                                    {item?.ASE_FieldVerified ===
                                                      true && (
                                                      <AttackSurfaceTooltip
                                                        className={""}
                                                        content={
                                                          <span>
                                                            Auto-generated
                                                            cannot be edited
                                                            <RenderImagesGroup
                                                              sourceName={
                                                                item?.ASE_FieldSource
                                                              }
                                                            />
                                                          </span>
                                                        }
                                                      >
                                                        <img
                                                          alt=""
                                                          src="/images/attack-surface/small-lock.svg"
                                                          className="mr-1"
                                                        />
                                                      </AttackSurfaceTooltip>
                                                    )}
                                                    <span className="d-inline-flex">
                                                      {selected?.AttributeText}
                                                    </span>
                                                  </span>
                                                </div>
                                              );
                                            })}
                                        {item["ASE_FieldValue"] &&
                                          /* JSON.parse*/ item["ASE_FieldValue"]
                                            ?.length > 3 && (
                                            <div
                                              className="f-12 f-500 f-grey d-flex align-items-center p-2 mr-1 mb-1"
                                              style={{
                                                background: "#e4e5ed",
                                              }}
                                            >
                                              +
                                              {
                                                /* JSON.parse*/ item[
                                                  "ASE_FieldValue"
                                                ]?.length - 3
                                              }
                                            </div>
                                          )}
                                      </div>
                                    ) : item["ASE_AttrDef_InputType"] ===
                                      "A_Drop Down List (Single)" ? (
                                      TrimString(
                                        /* JSON.parse*/ item[
                                          "ASE_FieldValue"
                                        ]?.AttributeText?.replaceAll('"', ""),
                                        150
                                      )
                                    ) : (
                                      <>
                                        {}

                                        {TrimString(
                                          JSON.stringify(
                                            item["ASE_FieldValue"]
                                          )?.replaceAll('"', ""),
                                          150
                                        )}
                                      </>
                                    )}
                                  </span>
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                )}

                {tableData?.length > 0 && nextPage && (
                  <Button
                    className={`hg-cancel-btn ml-3 mt-2 mb-5    ${
                      enabled ? "" : "d-none"
                    } `}
                    onClick={() => {
                      setLoadingNewPage(true);
                      getPaginationData(pageNumber + 1);
                      setPageNumber(pageNumber + 1);
                    }}
                  >
                    {loadingNewPage ? (
                      <>
                        <GreyLoader width={13} /> Loading..
                      </>
                    ) : (
                      "Show more"
                    )}
                  </Button>
                )}
              </>
            )}
          </div>

          <WorkstationModal
            show={showWorkstationModal}
            hideModal={() => {
              setShowWorkstationModal(false);

              setDataToRender("");
            }}
            header={modalHeader}
            assetModifierId={assetModifierId}
            refreshData={() => {
              pageData(assetModifierId, modalDataStructure);
              if (refreshMenuItems) {
                refreshMenuItems();
              }
            }}
            dataToRender={dataToRender}
            BizObjectId={BizObjectId}
            refreshMenuItems={refreshMenuItems}
          />
        </div>
      )}

      <ToastsV2
        showToast={showWaitingToast}
        done={archivingDone}
        successText={"Updated successfully"}
      />
    </>
  );
};

export default AssetsData;

export function jsonParseData(value) {
  try {
    return JSON.parse(value);
  } catch (err) {
    return value;
  }
}

const KPIModalData = [
  {
    image: "/images/attack-surface/verified.svg",
    title: "Verified",
    subtitle:
      "Data is verified when it originates from an integration with your security tools. Enhancing this data with manually defined data will not affect this.",
  },

  {
    image: "/images/attack-surface/unverified.svg",
    title: "Unverified",
    subtitle:
      "Data is unverified when it has been created manually by you, and has not been created or affected by an integration with your security tools.",
  },
];
