import React from "react";
import ContentLoader from "react-content-loader";

const RewardsPageLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 160 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="17%" y="210" rx="3" ry="3" width="10%" height="26" />
      <rect x="17%" y="260" rx="6" ry="6" width="66%" height="200" />

      <rect x="17%" y="510" rx="4" ry="4" width="66%" height="70" />
      <rect x="17%" y="585" rx="4" ry="4" width="66%" height="70" />
      <rect x="17%" y="660" rx="4" ry="4" width="66%" height="70" />

      <rect x="17%" y="735" rx="4" ry="4" width="66%" height="70" />
      <rect x="17%" y="810" rx="4" ry="4" width="66%" height="70" />

      {/* <rect x="18%" y="698" rx="4" ry="4" width="60%" height="60" /> */}
      {/* <rect x="18%" y="761" rx="4" ry="4" width="60%" height="60" /> */}
      {/* <rect x="18%" y="824" rx="4" ry="4" width="60%" height="60" />  */}
    </ContentLoader>
  );
};

export default RewardsPageLoader;
