import React from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./integrationsv2.scss";







 


const UpgradeToPremiumModal = ({ 
  show,
  hideModal,
 upgradeOptions
  
  }) => {
  const history = useHistory();
  return (
    <Modal
      show={show}
      dialogClassName="connect-integration-modal upgrade-to-premium-modal"
      className=""
      aria-labelledby="reset-settings-modal-title-vcenter"
      centered
      scrollable
      onHide={() => {
        hideModal();
      }}
    >
      <Modal.Body className="p-3 hide-scrollbar d-flex flex-column align-items-center">
        <img
          alt=""
          width={48}
          height={48}
          className="mt-4"
          src={upgradeOptions?.LogoImageUrl}
        />

     { upgradeOptions?.Title &&  <div className="f-18 f-600 text-center mt-3 mb-2">{upgradeOptions?.Title}</div>}
     { upgradeOptions?.SubTitle &&  <div className="f-16 f-500 text-center mt-3">{upgradeOptions?.SubTitle}</div>}
    { upgradeOptions?.FeatureHeader &&   <div className="w-100 f-500 f-black mt-4 mb-2">{upgradeOptions?.FeatureHeader}</div>}
        {upgradeOptions?.Features?.map((optionName, index) => {
          return (
            <div className="w-100 d-flex align-items-start f-black ml-2 mt-2">
              <img
                alt=""
                width={14}
                height={14}
                className=" mr-2 mt-1"
                src={optionName?.IconUrl}
              />
              {optionName?.Feature}
            </div>
          );
        })}
         { upgradeOptions?.FeatureFooter &&   <div className="w-100 f-500 f-black mt-3 mb-2">{upgradeOptions?.FeatureFooter}</div>}
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column">
      {upgradeOptions?.SubmitButton && (
            <Button
              className={`${upgradeOptions?.SubmitButton?.ButtonClass} d-flex align-items-center justify-content-center w-100 mb-2 mt-3`}
              onClick={() => {
                history.push(upgradeOptions?.SubmitButton?.ButtonUrl);
              }}
            >
              <img
                alt=""
                className="mr-2"
                src={upgradeOptions?.SubmitButton?.ButtonImage}
              />
              {upgradeOptions?.SubmitButton?.ButtonText}
            </Button>
          )}

          {upgradeOptions?.CancelButton && (
            <Button
              className={`${upgradeOptions?.CancelButton?.ButtonClass} w-100`}
              onClick={(e) => {
                hideModal()
                // e.target.closest(".upgrade-to-premium-dropdown").click();
              }}
            >
              {upgradeOptions?.CancelButton?.ButtonText}
            </Button>
          )}
      </Modal.Footer>
    </Modal>
  );
};

export default UpgradeToPremiumModal;


export const BasicChip = ({className}) => {
  return (
    <div
      className={`d-flex f-500 align-items-center justify-content-center px-2 py-1 radius-2 f-white f-8 f-600 ${className}`}
      style={{
        background: "#9499AA",
      }}
    >
      BASIC
    </div>
  );
};

export const BasicSubscriptionDropDown = ({
  disabled= false,
  children,
  upgradeOptions
}) => {
  const history = useHistory();
  return (
    <Dropdown className="upgrade-to-premium-dropdown">
      <Dropdown.Toggle>{children}</Dropdown.Toggle>
      { !disabled && (
        <Dropdown.Menu className="d-flex align-items-center flex-column px-3">
          <img
            alt=""
            width={48}
            height={48}
            className="mt-4"
            src={upgradeOptions?.LogoImageUrl}
          />
          {upgradeOptions?.Title && (
            <div className="mt-3 f-14 f-500 text-center mb-2">
              {upgradeOptions?.Title}
            </div>
          )}
          {upgradeOptions?.FeatureHeader && (
            <div className="mt-2 f-14 f-500 text-center mb-2">
              {upgradeOptions?.FeatureHeader}
            </div>
          )}

          {upgradeOptions?.Features.map((optionName) => {
            return (
              <div className="w-100 d-flex align-items-center f-black ml-2 mt-1">
                <img
                  alt=""
                  width={13}
                  height={13}
                  className=" mr-2"
                  src={optionName?.IconUrl}
                />
                {optionName?.Feature}
              </div>
            );
          })}
              {upgradeOptions?.FeatureFooter && (
            <div className="mt-2 f-14 f-500 text-center mb-2">
              {upgradeOptions?.FeatureFooter}
            </div>
          )}

          {upgradeOptions?.SubmitButton && (
            <Button
              className={`${upgradeOptions?.SubmitButton?.ButtonClass} d-flex align-items-center justify-content-center w-100 mb-2 mt-4`}
              onClick={() => {
                history.push(upgradeOptions?.SubmitButton?.ButtonUrl);
              }}
            >
              <img
                alt=""
                className="mr-2"
                src={upgradeOptions?.SubmitButton?.ButtonImage}
              />
              {upgradeOptions?.SubmitButton?.ButtonText}
            </Button>
          )}
          {upgradeOptions?.CancelButton && (
            <Button
              className={`${upgradeOptions?.CancelButton?.ButtonClass} w-100`}
              onClick={(e) => {
                e.target.closest(".upgrade-to-premium-dropdown").click();
              }}
            >
              {upgradeOptions?.CancelButton?.ButtonText}
            </Button>
          )}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};


export const UpgradeToPremiumSection = ({ upgradeOptions }) => {
  const history = useHistory();

  return (
    <div className="p-3 hide-scrollbar d-flex flex-column align-items-center ">
      <img
        alt=""
        width={48}
        height={48}
        className="mt-4"
        src={upgradeOptions?.LogoImageUrl}
      />

      {upgradeOptions?.Title && (
        <div className="f-18 f-600 text-center mt-3 mb-3">
          {upgradeOptions?.Title}
        </div>
      )}
      {upgradeOptions?.FeatureHeader && (
        <div className="w-100 f-500 f-black mt-4 mb-2">
          {upgradeOptions?.FeatureHeader}
        </div>
      )}
      {upgradeOptions?.Features?.map((optionName, index) => {
        return (
          <div className="w-100 d-flex align-items-start f-black ml-2 mt-2">
            <img
              alt=""
              width={14}
              height={14}
              className=" mr-2 mt-1"
              src={optionName?.IconUrl}
            />
            {optionName?.Feature}
          </div>
        );
      })}
      {upgradeOptions?.FeatureFooter && (
        <div className="w-100 f-500 f-black mt-3 mb-2">
          {upgradeOptions?.FeatureFooter}
        </div>
      )}

      {upgradeOptions?.SubmitButton && (
        <Button
          className={`${upgradeOptions?.SubmitButton?.ButtonClass} d-flex align-items-center justify-content-center w-100 mb-2 mt-4`}
          onClick={() => {
            history.push(upgradeOptions?.SubmitButton?.ButtonUrl);
          }}
        >
          <img
            alt=""
            className="mr-2"
            src={upgradeOptions?.SubmitButton?.ButtonImage}
          />
          {upgradeOptions?.SubmitButton?.ButtonText}
        </Button>
      )}

      {upgradeOptions?.CancelButton && (
        <Button
          className={`${upgradeOptions?.CancelButton?.ButtonClass} w-100`}
          onClick={(e) => {
            // hideModal()
            // e.target.closest(".upgrade-to-premium-dropdown").click();
          }}
        >
          {upgradeOptions?.CancelButton?.ButtonText}
        </Button>
      )}
    </div>
  );
};