import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  InputGroup,
  Form,
  OverlayTrigger,
  FormControl,
  Tooltip,
} from "react-bootstrap";
import "./index.scss";
import Loader from "./loader";
import { getAPIData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";

const AddCardForm = (props) => {
  const [saveMethod, setSaveMethod] = useState(false);
  const [isSelectedMethod, setIsSelectedMethod] = useState("");
  const [applyCredit, setApplyCredit] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [showErr, setShowErr] = useState(false);

  const [billingDetails, setBillingDetails] = useState({
    cname: "",
    caddress: "",
    ccountry: "",
    creg: "",
    cvat: "",
  });
  const [cardDetails, setcardDetails] = useState({
    cardnumber: "",
    monthyear: "",
    cvc: "",
    cardname: "",
  });
  const { authTokens } = useAuth();
  const [upgradePlans, setUpgradePlans] = useState(props.upgradePlans);
  const [planName, setPlanName] = useState(false);
  const { data, stripeApi } = props;
  const { price } = data;
  const rowElement = useRef(null);

  const submit = (paymentMethodId) => {
    rowElement.current.scrollIntoView();
    const billingErr = handleError(billingDetails);
    const cardErr = handleError(cardDetails);

    if (isSelectedMethod) {
      if (!billingErr) {
        stripeApi.confirm_payment(data.PaymentIntentId, paymentMethodId);
      }
    } else {
      if (!billingErr && !cardErr) {
        stripeApi.addCard(billingDetails, cardDetails, data.PaymentIntentId);
      }
    }
    // const err = handleError(billingDetails);

    // if (!err) {
    //   setData((prevState) => ({ ...prevState, isLoading: true }));
    //   setTimeout(() => {
    //     setData((prevState) => ({
    //       ...prevState,
    //       isLoading: false,
    //       isResponse: true,
    //     }));
    //   }, 3000);
    // }
  };

  const handleError = (fields) => {
    setIsSubmit(true);
    let err = 0;
    for (var key in fields) {
      if (fields.hasOwnProperty(key)) {
        if (fields[key] == "") {
          err = err + 1;
        }
      }
    }

    if (!err) {
      setShowErr(true);
      setIsSubmit(false);
    } else {
      setShowErr(false);
    }
    return err;
  };

  const handleChange = (value) => {
    var detail = value.replace(/\-[^.]+$/, "");
    if (detail == "Monthly ") {
      setPlanName(true);
    } else {
      setPlanName(false);
    }
  };

  return (
    <>
      {props.data.isLoading && <Loader />}
      <Col md={7} className="p-0 annual_wrapper" ref={rowElement}>
        <div className="mt40" />

        <h1> {upgradePlans?.Title}</h1>
        <div className="list_wrapper">
          <p className="listtxt">For power users who want to do even more.</p>
          {upgradePlans &&
            upgradePlans.BulletPoints &&
            upgradePlans.BulletPoints.map((data, index) => (
              <p className="listtxt d-flex align-items-center flex-row">
                {" "}
                <img
                  src="/images/prem_check.svg"
                  className="mr-2"
                  alt=""
                />{" "}
                {data}
              </p>
            ))}

          <hr />
        </div>

        <div className="bill_wrapper">
          <div className="d24" />
          <p>Billing Interval</p>
          <InputGroup className={`grey-select`}>
            <Form.Control
              as="select"
              name=""
              className="bgNone pt-1 pb-1 h32"
              onChange={(e) => handleChange(e.target.value)}
            >
              {upgradePlans &&
                upgradePlans.BillingIntervalList &&
                upgradePlans.BillingIntervalList.map((element) => (
                  <option>{element.Name + " - " + element.Price}</option>
                ))}
              {/* <option>Annual - £4 275/year -10%</option>
              <option>Annual - £4 275/year -10%</option> */}
            </Form.Control>
          </InputGroup>
          <div className="d24" />
          <hr />
          <div className="d24" />
          <p className="m-0">Payment Method</p>
          <div className="d24" />
          <div className="payment_method_wrapper flex-wrap">
            <div
              className={`mb-3 crsrpoint greyBorderCont mr8px position-relative flex-fill w30 ${
                !isSelectedMethod && "brdBlu"
              } `}
            >
              <Form.Group
                controlId={"check"}
                className="round-checkbox blue-checkbox"
              >
                <Form.Check
                  type="radio"
                  name="stripeId"
                  label=""
                  value={isSelectedMethod ? true : false}
                  onClick={() => setIsSelectedMethod(false)}
                  defaultChecked={true}
                />
              </Form.Group>
              <div className="center-container text-center">
                <div className="heading3">
                  <img
                    src="/images/subscription/addcard.svg"
                    className=""
                    alt=""
                  />
                </div>
                <div className="heading3 txt-blue txtcard">Add Card</div>
              </div>
            </div>
            {upgradePlans &&
              upgradePlans.PaymentMethod_List &&
              upgradePlans.PaymentMethod_List.map((data, i) => (
                <div
                  key={i}
                  className={`mb-3 crsrpoint greyBorderCont mr8px position-relative  flex-fill w30 ${
                    data.Id == isSelectedMethod.Id && "brdBlu"
                  }`}
                >
                  <Form.Group
                    controlId={`chec${i}`}
                    className="round-checkbox blue-checkbox"
                  >
                    <Form.Check
                      type="radio"
                      name="stripeId"
                      onClick={() => setIsSelectedMethod(data)}
                      value={data.Id == isSelectedMethod.Id ? true : false}
                      label=""
                      defaultChecked={""}
                    />
                  </Form.Group>
                  <div className="center-container text-center">
                    <div className="heading3">
                      {" "}
                      <img src={data.Icon} className="" alt="" />
                    </div>
                    <div className="heading3  txtcard">{data.CardType}</div>
                    <p className="greyText">{data.CardNumber}</p>
                  </div>
                </div>
              ))}
          </div>
          <div className="d24" />

          {/* payment method div */}
          {!isSelectedMethod && (
            <div>
              <hr />
              <div className="d24" />
              <p className="m-0">Billing Address</p>

              <div className="d24" />
              <Form.Group>
                <InputGroup className="grey-input">
                  <FormControl
                    name="name"
                    value={billingDetails.cname}
                    onChange={(e) =>
                      setBillingDetails({
                        ...billingDetails,
                        cname: e.target.value,
                      })
                    }
                    className={`${
                      isSubmit && !showErr && !billingDetails.cname && "carderr"
                    }`}
                    placeholder="Company name"
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <InputGroup className="grey-input mt-10px">
                  <FormControl
                    name="name"
                    value={billingDetails.caddress}
                    onChange={(e) =>
                      setBillingDetails({
                        ...billingDetails,
                        caddress: e.target.value,
                      })
                    }
                    className={`${
                      isSubmit &&
                      !showErr &&
                      !billingDetails.caddress &&
                      "carderr"
                    }`}
                    placeholder="Company address"
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <InputGroup className="grey-input mt-10px">
                  <FormControl
                    name="name"
                    value={billingDetails.ccountry}
                    onChange={(e) =>
                      setBillingDetails({
                        ...billingDetails,
                        ccountry: e.target.value,
                      })
                    }
                    className={`${
                      isSubmit &&
                      !showErr &&
                      !billingDetails.ccountry &&
                      "carderr"
                    }`}
                    placeholder="Country"
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <InputGroup className="grey-input mt-10px">
                  <FormControl
                    name="name"
                    value={billingDetails.creg}
                    onChange={(e) =>
                      setBillingDetails({
                        ...billingDetails,
                        creg: e.target.value,
                      })
                    }
                    className={`${
                      isSubmit && !showErr && !billingDetails.creg && "carderr"
                    }`}
                    placeholder="Company registration"
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <InputGroup className="grey-input mt-10px">
                  <FormControl
                    name="name"
                    value={billingDetails.cvat}
                    onChange={(e) =>
                      setBillingDetails({
                        ...billingDetails,
                        cvat: e.target.value,
                      })
                    }
                    className={`${
                      isSubmit && !showErr && !billingDetails.cvat && "carderr"
                    }`}
                    placeholder="VAT"
                  />
                </InputGroup>
              </Form.Group>

              <div className="d24" />
              <hr />
              <div className="d24" />
              <p className="m-0">Payment Information</p>
              <div className="d24" />
              <Form.Group className="card_number_wrapper">
                <InputGroup className="grey-input cardnumber">
                  <FormControl
                    name="name"
                    value={billingDetails.cardnumber}
                    type="number"
                    onChange={(e) =>
                      setBillingDetails({
                        ...billingDetails,
                        cardnumber: e.target.value,
                      })
                    }
                    className={`${
                      isSubmit && !showErr && !billingDetails.cardnumber
                        ? "carderr1"
                        : "cardicon"
                    }`}
                    placeholder="Card number"
                  />
                </InputGroup>
                <InputGroup className="grey-input datemonth">
                  <FormControl
                    name="name"
                    value={billingDetails.monthyear}
                    onChange={(e) =>
                      setBillingDetails({
                        ...billingDetails,
                        monthyear: e.target.value,
                      })
                    }
                    className={`${
                      isSubmit && !showErr && !billingDetails.monthyear
                        ? "carderr1"
                        : "cardicon2"
                    }`}
                    placeholder="MM/YY"
                  />
                </InputGroup>
                <InputGroup className="grey-input csvnumber">
                  <FormControl
                    name="name"
                    value={billingDetails.cvc}
                    onChange={(e) =>
                      setBillingDetails({
                        ...billingDetails,
                        cvc: e.target.value,
                      })
                    }
                    className={`${
                      isSubmit && !showErr && !billingDetails.cvc && "carderr1"
                    }`}
                    placeholder="CVC"
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <InputGroup className="grey-input">
                  <FormControl
                    name="name"
                    value={billingDetails.cardname}
                    onChange={(e) =>
                      setBillingDetails({
                        ...billingDetails,
                        cardname: e.target.value,
                      })
                    }
                    className={`${
                      isSubmit &&
                      !showErr &&
                      !billingDetails.cardname &&
                      "carderr"
                    }`}
                    placeholder="Name on card"
                  />
                </InputGroup>
              </Form.Group>

              <div className="d24" />
              <hr />
              <div className="d24" />
              <div className="d-flex flex-direction-row justify-content-start">
                <Form.Check
                  type="switch"
                  id={"swi"}
                  checked={saveMethod}
                  className="m-0"
                  onChange={() => setSaveMethod(!saveMethod)}
                />
                <p>Save this Payment Method</p>
              </div>
              <div className="mt40" />
            </div>
          )}
        </div>
      </Col>
      <Col md={5} className="pr-0">
        <div className="mt40" />
        <h1>Order Summary</h1>
        <div className="order_summary">
          <div className="order_r">
            <div>
              {planName
                ? upgradePlans &&
                  upgradePlans.MonthlyForm &&
                  upgradePlans.MonthlyForm.OrderSummaryName
                : upgradePlans &&
                  upgradePlans.AnnualForm &&
                  upgradePlans.AnnualForm.OrderSummaryName}
            </div>
            <div>£4,275</div>
          </div>

          <div className="gtxt">
            {props.data.package && props.data.package
              ? `${props.data.package.MonthlyCost}`
              : "£4 275/year"}{" "}
            Billed Yearly{" "}
          </div>
        </div>
        <div className="d24" />
        <hr />
        <div className="mt16" />
        <div className="order_calc">
          <div className="order_r">
            <div className="greytxt">Subtotal</div>
            <div>
              {planName
                ? upgradePlans &&
                  upgradePlans.MonthlyForm &&
                  upgradePlans.MonthlyForm.SubTotal
                : upgradePlans &&
                  upgradePlans.AnnualForm &&
                  upgradePlans.AnnualForm.SubTotal}
            </div>
          </div>
          <div className="order_r">
            <div className="greytxt">Tax</div>
            <div>
              {planName
                ? upgradePlans &&
                  upgradePlans.MonthlyForm &&
                  upgradePlans.MonthlyForm.Tax
                : upgradePlans &&
                  upgradePlans.AnnualForm &&
                  upgradePlans.AnnualForm.Tax}
            </div>
          </div>
          <div className="order_r">
            <div className="greytxt">Discount</div>
            <div className="txt-blue">
              {planName
                ? upgradePlans &&
                  upgradePlans.MonthlyForm &&
                  upgradePlans.MonthlyForm.Discount
                : upgradePlans &&
                  upgradePlans.AnnualForm &&
                  upgradePlans.AnnualForm.Discount}
            </div>
          </div>
        </div>
        <hr />
        <div className="mt16" />
        <div className="order_r">
          <div className="gryClr">Total</div>
          <div>
            {planName
              ? upgradePlans &&
                upgradePlans.MonthlyForm &&
                upgradePlans.MonthlyForm.Total
              : upgradePlans &&
                upgradePlans.AnnualForm &&
                upgradePlans.AnnualForm.Total}
          </div>
        </div>
        <div className="mt40" />
        <Button
          onClick={() => submit(isSelectedMethod.StripeId)}
          className="w-100 blubtn"
        >
          {props.data.package && props.data.package
            ? `Buy ${props.data.package.Name}`
            : "Upgrade to Premium"}
        </Button>
      </Col>
    </>
  );
};

export default AddCardForm;
