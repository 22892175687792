import React from "react";
import ContentLoader from "react-content-loader";

const MembersPageLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 180 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="17%" y="220" rx="3" ry="3" width="5%" height="26" />
      <rect x="72.6%" y="220" rx="3" ry="3" width="10%" height="36" />

      <rect x="17%" y="320" rx="4" ry="4" width="66%" height="68" />
      <rect x="17%" y="390" rx="4" ry="4" width="66%" height="68" />
      <rect x="17%" y="460" rx="4" ry="4" width="66%" height="68" />

      <rect x="17%" y="530" rx="4" ry="4" width="66%" height="68" />
      <rect x="17%" y="600" rx="4" ry="4" width="66%" height="68" />
      <rect x="17%" y="670" rx="4" ry="4" width="66%" height="68" />
      <rect x="17%" y="740" rx="4" ry="4" width="66%" height="68" />
      <rect x="17%" y="810" rx="4" ry="4" width="66%" height="68" />
      {/* <rect x="17%" y="824" rx="4" ry="4" width="65%" height="68" /> */}
    </ContentLoader>
  );
};

export default MembersPageLoader;
