import React, { useEffect, useState } from 'react'
import DropdownV2 from '../../Theme/DropdownV2';
import { Dropdown } from 'react-bootstrap';
import HighgroundDatePicker from '../../test/TestComponent';
import { setactioncentredata } from '../../../redux/actionCentre/actions';
import { useDispatch } from 'react-redux';
import CreateTaskModal from '../../PriceCalculator/modals/CreateTaskModal';

const AssessmentOverview = () => {

  const dispatch = useDispatch();
  return (
    <div className="w-45 mx-auto pt-5">
      <div className="f-16 f-600">CIS Critical Security Controls (v8)</div>
      <div className="f-grey">
        The CIS Critical Security Controls (CIS Controls) are a prescriptive,
        prioritized, and simplified set of best practices called 'safegaurds'
        that each require you to do one thing. This simplified cybersecurity
        approach is proven to help you defend against today's top threats. They
        include foundational security measures that you can use to achieve
        essential hygiene and create an on-ramp to comply with PCI DSS, HIPAA,
        GDPR, and other industry regulations
      </div>
      <div className="f-darkgrey mt-3">Resources</div>

      <div className="f-blue mt-3">https://www.cisecurity.org/controls</div>
      <div className="f-blue mt-3">
        https://www.cisecurity.org/insights/white-papers/cis-community-defense-model-2-0
      </div>

      <div className="bg-grey radius-8 border-parent">
        <div className="d-flex align-items-center f-12 f-darkgrey p-2">
          <img
            src="/images/settings-v2/settingwheel.svg"
            width={10}
            alt="cis-controls"
            className="mr-2"
          />
          Configuration
        </div>
        <div className="border-item d-flex align-items-center justify-content-between p-3">
          <div>
            <div className="f-14 f-500">Implementation Groups</div>
            <div className="f-12 f-darkgrey">
              Select which implementation groups you want to assess yourself
              against <u>Learn more</u>
            </div>
          </div>
          <div>
            <DropdownV2
              fieldId={"Id"}
              fieldValue={"Name"}
              selected={{ Id: "1", Name: "Group 1" }}
              placeholder="Select"
              onClick={(id, val) => {}}
              data={[
                {
                  Id: "1",
                  Name: "Group 1",
                },
                {
                  Id: "2",
                  Name: "Groups 1 + 2",
                },
                {
                  Id: "3",
                  Name: "Groups 1 + 2 + 3",
                },
              ]}
            />
          </div>
        </div>

        <div className="border-item d-flex align-items-center justify-content-between p-3">
          <div>
            <div className="f-14 f-500">Implementation Groups</div>
            <div className="f-12 f-darkgrey">
              Select which implementation groups you want to assess yourself
              against <u>Learn more</u>
            </div>
          </div>
          <div>
            <DropdownV2
              fieldId={"Id"}
              fieldValue={"Name"}
              selected={{ Id: "1", Name: "Group 1" }}
              placeholder="Select"
              onClick={(id, val) => {}}
              data={[
                {
                  Id: "1",
                  Name: "Group 1",
                },
                {
                  Id: "2",
                  Name: "Groups 1 + 2",
                },
                {
                  Id: "3",
                  Name: "Groups 1 + 2 + 3",
                },
              ]}
            />
          </div>
        </div>
      </div>

      <div className="f-500 f-darkgrey my-3">Assessment Details</div>

      <div className="w-100 d-flex">
        <div className="w-25 f-500 f-grey">Performed by</div>
        <div className="w-75">
          <UserSelectionDropdown selectedUserList={[]} />
        </div>
      </div>

      <div className="w-100 d-flex mt-3">
        <div className="w-25 f-500 f-grey">Shared with</div>
        <div className="w-75">
          <UserSelectionDropdown selectedUserList={[]} placeholder="Share" />
        </div>
      </div>

      <div className="w-100 d-flex mt-3">
        <div className="w-25 f-500 f-grey">Start Date</div>
        <div className="w-auto">
          <HighgroundDatePicker
            selected={""}
            placeholder={"Choose date"}
            icon={"/images/attack-surface/date-icon.svg"}
            onChange={(e) => {}}
            id={"bhfgvdcwmv"}
            isError={false}
          />
        </div>
      </div>

      <div className="w-100 d-flex mt-3">
        <div className="w-25 f-500 f-grey">Completion Date</div>
        <div className="w-auto">
          <HighgroundDatePicker
            selected={""}
            placeholder={"Choose date"}
            icon={"/images/attack-surface/date-icon.svg"}
            onChange={(e) => {}}
            id={"bhfgvrvwmv"}
            isError={false}
          />
        </div>
      </div>

      <div className="f-500 f-darkgrey mt-4">Last Audit Details</div>

      <div className="w-100 d-flex mt-3">
        <div className="w-25 f-500 f-grey">Last audit</div>
        <div className="w-auto">
          <HighgroundDatePicker
            selected={""}
            placeholder={"Choose date"}
            icon={"/images/attack-surface/date-icon.svg"}
            onChange={(e) => {}}
            id={"bhfgvdcvdwmv"}
            isError={false}
          />
        </div>
      </div>

      <div className="w-100 d-flex mt-3">
        <div className="w-25 f-500 f-grey">Performed by</div>
        <div className="w-auto">
          <input
            type="text"
            className="bg-transparent border-none"
            placeholder="Add"
          />
        </div>
      </div>

      <div className="w-100 d-flex mt-3">
        <div className="w-25 f-500 f-grey">Next Audit</div>
        <div className="w-auto">
          <HighgroundDatePicker
            selected={""}
            placeholder={"Choose date"}
            icon={"/images/attack-surface/date-icon.svg"}
            onChange={(e) => {}}
            id={"bhfgvdccdwmv"}
            isError={false}
          />
        </div>

        <div
          className="f-grey f-500 mr-2 pointer"
          onClick={() => {
            dispatch(
              setactioncentredata({
                refreshCreateTaskModalModule: true,
              })
            );
            const drag = document.getElementById("create-task-modal");
            // drag.style.transform = "translate(0px, 0px) ";
            drag.style.display = "block";
          }}
        >
          <img
            src="/images/msp/secops-create-task-icon.svg"
            alt=""
            className="mr-3"
          />
          Create task
        </div>
      </div>

      <div className="p-5" />
      <CreateTaskModal />
    </div>
  );
}

export default AssessmentOverview


const list= [
    {
        name:"User 1",
    },
    {
        name:"User 2",
    },
    {
        name:"User 3",
    },
    {
        name:"User 4",
    },
    {
        name:"User 5",
    },
]
const UserSelectionDropdown = ({
  userList = list,
  selectedUserList = [],
  placeholder = "Add",
  onUpdate = () => {},
}) => {
  const [searchString, setSearchString] = useState("");
  const [selectedUsers, setSelectedUsers] = useState(selectedUserList);
  useEffect(() => {
    console.log("updating")
    setSelectedUsers(selectedUserList);
  }, [selectedUserList]);

  return (
    <Dropdown
      alignRight
      className="transparennt-wrapper-dropdown"
      onToggle={(e) => {}}
    >
      <Dropdown.Toggle className="d-flex align-items-center w-100">
        <div className="w-100 d-flex ">
          <div className="w-auto d-flex flex-wrap">
            {selectedUsers?.map((item, index) => {
              return (
                <>
                  <div
                    className="d-flex align-items-center p-1 px-2 m-1"
                    style={{
                      background: "#EBEDF1",
                      borderRadius: "100px",
                    }}
                  >
                    <img
                      src="https://source.unsplash.com/random/50×50"
                      width={17}
                      height={17}
                      alt=""
                      className="mr-2 rounded-full"
                    />
                    <div className="f-12 f-grey">{item.name}</div>
                    <img
                      src="/images/attack-surface/cross-icon.svg"
                      alt=""
                      className="ml-2"
                      onClick={() => {
                        const temp = [...selectedUsers];
                        temp.splice(index, 1);
                        setSelectedUsers(temp);
                        onUpdate(temp);
                      }}
                    />
                  </div>
                </>
              );
            })}
            <input
            type="text"
            className="bg-transparent border-none"
            placeholder={placeholder}
            value={searchString}
            onChange={(e) => {
              setSearchString(e.target.value);
            }}
          />
          </div>
          
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="pointer px-2 w-100"
        style={{
          minWidth: "200px",
        }}
      >
        {userList?.map((item, index) => {
          const isSelected =
            selectedUsers?.findIndex((user) => user.name === item.name) > -1;
          return (
            <>
              <div
                className="d-flex align-items-center mb-2 f-grey"
                onClick={(e) => {
                    console.log("clicked")
                  const temp = [...selectedUsers];
                  temp.push(item);
                  setSelectedUsers(temp);
                  onUpdate(temp);

                  e.target.closest(".transparennt-wrapper-dropdown").click();
                }}
              >
                <img
                  src="/images/security-assessment/grey-tick-icon.svg"
                  alt=""
                  className="mr-2"
                  style={{
                    visibility: `${isSelected ? "visible" : "hidden"}`,
                  }}
                />
                <div className="d-flex align-items-center">
                  <img
                    src="https://source.unsplash.com/random/50×50"
                    width={17}
                    height={17}
                    alt=""
                    className="mr-2 rounded-full"
                  />
                  <div className="f-12 f-grey">{item.name}</div>
                </div>
              </div>
            </>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};