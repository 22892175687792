import React from 'react'
import { useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Header from '../Header';
import { Col, Row } from 'react-bootstrap';
import GovernaceHomePage from './GovernaceHomePage';

const GovernaceResilence = () => {
    const history = useHistory();
    const userDetails = useSelector((state) => state.user?.userDetail);
    const isMenuCollapse = useSelector((state) => state.global.menuCollapse);
  return (
    <Row className={`fullHeight m-0 `} fluid>
    <Col
      xs={12}
      className="px-0"
      style={{
        height: "100%",
      }}
    >
      <div
        className="dashboard fullHeight "
        style={{  padding: "0 1em", background: "inherit" }}
      >
        <header
          className="d-flex justify-content-between navbar header-bottom-shadow"
          style={{
            paddingTop: "1em",
            background: "#FFFFFF",
            position: "fixed",
            width: `${isMenuCollapse ? "92.25%" : "83.25%"}`, // "",
            zIndex: 6,
            marginLeft: "-0.9rem",
            paddingLeft: "2rem",
          }}
        >
          <h1 className="main_header ml-4 d-flex">Governance & Resilience</h1>

          <Header />
        </header>

        <div className="p-5"> </div>
        <Route
            exact
            path={`/governance-resilence`}
            render={(props) => (
                        <GovernaceHomePage/>
            )}
          />
          <Route
            exact
            path={`/governance-resilence/xyz`}
            render={(props) => (
           <div>XYX</div>
            )}
          />
         
      </div>
    </Col>
  </Row>
  )
}

export default GovernaceResilence