import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Row,
  Form,
  Col,
  InputGroup,
  Tab,
  Tabs,
} from "react-bootstrap";

import { Radar } from "react-chartjs-2";
import { postRequest as SubmitQuoteModalData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import "./style.scss";
import Loader from "../../Common/loader";
import { GetSubmitQuoteData } from "../../../Utils/commonApi";
import { CallToast } from "./CustomToast";
import { WhiteLoader } from "../../Theme/APILoaders";
import { TrackUser } from "../../../Utils/SegmentFunctions";
const SubmitQuoteModal = (props) => {
  const { authTokens } = useAuth();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const { tabs, activeTab, setValues } = props;
  const [quoteData, setQuoteData] = useState("");
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState("email");
  const [time, setTime] = useState("12am- 2am");
  const [radarData, setRadarData] = useState({});
  const [description, setDescription] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },

    tooltips: {
      enabled: false,
    },
    scale: {
      pointLabels: {
        fontSize: 12,
        callback: function (val, i) {
          // const dat = [35, 35, 35, 35, 15];
          // const vv = val + ` - ${dat[i]}`;
          return val;
        },
      },
      ticks: {
        beginAtZero: true,
        stepSize: 10,
        display: false,
        backdropColor: "rgba(0, 0, 0, 0)",
        // callback: function () {
        //   return "";
        // },
      },
    },
    elements: {
      point: {
        radius: 0.2,
        hoverRadius: 0,
      },
    },
  };
  const staticlist = [
    {
      title: "Identify",
      SKUs: "",
      points: quoteData?.CurrentPostureVM?.CurrentPosture?.Identity,
      amt: "£47.50/mo",
    },
    {
      title: "Protect",
      SKUs: "",
      points: quoteData?.CurrentPostureVM?.CurrentPosture?.Protect,
      amt: "£31.25/mo",
    },
    {
      title: "Detect",
      SKUs: "",
      points: quoteData?.CurrentPostureVM?.CurrentPosture?.Detect,
      amt: "£20/mo",
    },
    {
      title: "Respond",
      SKUs: "",
      points: quoteData?.CurrentPostureVM?.CurrentPosture?.Respond,
      amt: "£3.60/mo",
    },
    {
      title: "Recover",
      SKUs: "",
      points: quoteData?.CurrentPostureVM?.CurrentPosture?.Recover,
      amt: "£3.60/mo",
    },
  ];

  const [lists, setLists] = useState([]);

  const isCloseModal = () => {
    props.isClose();
  };

  useEffect(() => {
    if (props.isOpen) {
      setLoading(true);
      GetSubmitQuoteData(
        authTokens,
        225,
        setValues.state.tabs[activeTab]?.ScenarioHeader?.objId_Scenario
      )
        .then((res) => {
          if (!res.mr.Success) return false;
          setQuoteData(res);
          setLoading(false);
          setEmail(res?.Details);
          let new_list = [
            res?.ScenarioVM?.Nist_Identify,
            res?.ScenarioVM?.Nist_Protect,
            res?.ScenarioVM?.Nist_Detect,
            res?.ScenarioVM?.Nist_Respond,
            res?.ScenarioVM?.Nist_Recover,
          ];
          let points = [
            res?.CurrentPostureVM?.CurrentPosture?.Identity,
            res?.CurrentPostureVM?.CurrentPosture?.Protect,
            res?.CurrentPostureVM?.CurrentPosture?.Detect,
            res?.CurrentPostureVM?.CurrentPosture?.Respond,
            res?.CurrentPostureVM?.CurrentPosture?.Recover,
          ];
          let _lists = [...staticlist];
          for (let i = 0; i < _lists?.length; i++) {
            const all_sku =
              new_list[i]?.length > 0
                ? new_list[i].map((item) => item?.Sku).join(",")
                : "";

            const total_price =
              new_list[i]?.length > 0
                ? new_list[i].reduce((a, b) => a + b?.MonthlyPrice, 0)
                : "";
            _lists[i].SKUs = all_sku;
            _lists[i].points = points[i];
            _lists[i].amt = total_price ? `£${total_price.toFixed(2)}/mo` : "";
          }
          setLists(_lists);
          // Setting radar Data------------------------
          setTimeout(() => {
            setRadarData({
              labels: ["Identity", "Protect", "Detect", "Respond", "Recover"],
              datasets: [
                {
                  data: [
                    res?.ScenarioVM?.ScenarioHeader?.InspectScore,
                    res?.ScenarioVM?.ScenarioHeader?.ProtectScore,
                    res?.ScenarioVM?.ScenarioHeader?.DetectScore,
                    res?.ScenarioVM?.ScenarioHeader?.RespondScore,
                    res?.ScenarioVM?.ScenarioHeader?.RecoverScore,
                  ],
                  datalabels: {
                    display: false,
                  },
                  backgroundColor: "rgba(0, 143, 212, 0.1)",
                  borderColor: "rgba(0, 143, 212, 1)",
                  pointBackgroundColor: "rgba(0, 143, 212, 1)",
                  borderWidth: 0.5,
                  pointLabelFontSize: 0,
                  scaleShowLabels: false,
                  label: "Current Posture",
                  // pointRadius: 0
                },
                {
                  data: [
                    res?.CurrentPostureVM?.CurrentPosture?.Identity,
                    res?.CurrentPostureVM?.CurrentPosture?.Protect,
                    res?.CurrentPostureVM?.CurrentPosture?.Detect,
                    res?.CurrentPostureVM?.CurrentPosture?.Respond,
                    res?.CurrentPostureVM?.CurrentPosture?.Recover,
                  ],
                  datalabels: {
                    display: false,
                  },
                  backgroundColor: "rgba(255, 172, 44, .4)",
                  borderColor: "rgba(255, 172,44, 1)",
                  pointBackgroundColor: "rgba(255, 172,44, 1)",
                  borderWidth: 0.5,
                  borderDash: [8],
                  pointLabelFontSize: 0,
                  scaleShowLabels: false,
                  label: "Current Posture",
                  // pointRadius: 0
                },
              ],
            });
          }, 200);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [props.isOpen]);

  // {"objId_Scenario":3189213,"PreferredContact":"Phone","Details":9874563210,"Time":"2am-4am"}

  function SubmitData() {
    setSubmitting(true);
    const postData = {
      objId_Scenario:
        setValues.state.tabs[activeTab]?.ScenarioHeader?.objId_Scenario,
      PreferredContact: contact === "email" ? "Email" : "Phone",
      Details: contact === "email" ? email : phone,
      Time: time,
      description: description,
    };

    const RequestedData = {
      InputType: "PricingScenario",
      InputValue: JSON.stringify(postData),
      InputTarget: "Quote", //        JSON.stringify("Quote"),
      TargetAddress: contact === "email" ? "Email Address" : "Mobile No",
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    };

    SubmitQuoteModalData(authTokens, "PrintAndShareModal", RequestedData)
      .then((res) => {
        setSubmitting(false);
        if (!res.Success) {
          CallToast("Something went wrong", false);
        }
        if (res.Success) {
          TrackUser("Managed Services product quote / call requested");
          CallToast("The Quote enquiry has been submitted successfully", false);
          props.isClose();
          setRadarData({});
          setEmail("");
          setPhone("");
          setDescription("");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false);
      });
  }

  return (
    <Modal
      show={props.isOpen}
      dialogClassName="modal-50w"
      className="integrationModal  centerModal dashboardModal submitQueryModalChart"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header className="text-center ">
        <h1 className="w-100 mb-0 mt-0"> Submit Quote Enquiry</h1>

        <span
          className="cursor-pointer"
          onClick={() => {
            props.isClose();
            setRadarData({});
          }}
        >
          <img src="/images/icons/close.svg" alt="" />
        </span>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="quote-modal-loader">
            <Loader />
          </div>
        ) : (
          <>
            <Row className="m-0">
              <Col md={7} className="p-0">
                <p className="clrgry mt-3 mb-1">Details</p>
                <p className="mb-0">
                  Users:
                  {
                    quoteData?.ScenarioVM?.ScenarioHeader?.UserAndDeviceCount
                      ?.NoOfUsers
                  }
                  , Workstations:
                  {
                    quoteData?.ScenarioVM?.ScenarioHeader?.UserAndDeviceCount
                      ?.NoOfWorkstation
                  }
                  , Servers:
                  {
                    quoteData?.ScenarioVM?.ScenarioHeader?.UserAndDeviceCount
                      ?.NoOfServices
                  }
                </p>
                <p>
                  Mobile Devices:
                  {
                    quoteData?.ScenarioVM?.ScenarioHeader?.UserAndDeviceCount
                      ?.NoOfMobileDevices
                  }
                  , Domains:
                  {
                    quoteData?.ScenarioVM?.ScenarioHeader?.UserAndDeviceCount
                      ?.NoOfDomains
                  }
                </p>
                <div className="grid-container mt-2">
                  <div className="d-flex align-items-center">
                    <div
                      className="circleDiv mr-2"
                      style={{ background: "#40D88E" }}
                    />
                    <div className="txt-blue font-16">
                      {quoteData?.ScenarioVM?.ScenarioHeader?.Name}
                    </div>
                  </div>
                  <div className="clrgry">Points</div>
                  <div className="clrgry">Amount</div>
                </div>

                <hr />
                {/* {lists.map((val, i) => (
                  <div className="grid-container mb-2" key={i}>
                    <div>
                      {val.title} &nbsp; {val.SKUs}
                    </div>
                    <div className="clrgreen">{val.points}</div>
                    <div>{val.amt}</div>
                  </div>
                ))} */}

                {quoteData?.ScenarioVM?.ScenarioHeader?.SecurityPackSummary_List?.map(
                  (val, i) => {
                    return (
                      <>
                        {(val.Label || val.Points > 0) && (
                          <div className="grid-container mb-2" key={i}>
                            <div>
                              {val?.Label}
                              {/* &nbsp; {val.SKUs} */}
                            </div>
                            <div className="clrgreen">{val.Points}</div>
                            <div>
                              {tabs[activeTab].ScenarioHeader?.BillingPeriod
                                ? val?.Amount_Year_Text
                                : val?.Amount_Month_Text}
                            </div>
                          </div>
                        )}
                      </>
                    );
                  }
                )}

                <hr />
                <div className="grid-container2">
                  <div>Total</div>
                  <div className="text-right txt-blue mr-3">
                    £
                    {tabs[activeTab].ScenarioHeader?.BillingPeriod
                      ? quoteData?.ScenarioVM?.ScenarioHeader?.AnnualPrice
                      : quoteData?.ScenarioVM?.ScenarioHeader?.MonthlyPrice}
                    /
                    {tabs[activeTab].ScenarioHeader?.BillingPeriod
                      ? "year"
                      : "mo"}
                  </div>
                </div>
              </Col>
              <Col md={5} className="p-0">
                <div className="submitqueryChartWrapper mt-4">
                  <div className="submitqueryChart">
                    <Radar data={radarData} options={options} />
                  </div>
                </div>
              </Col>
            </Row>
            <hr />
            <Row className="m-0">
              <Col md={12} className="p-0">
                <p className="fnttitle mt-3">Prefered contact method</p>

                <Tabs
                  defaultActiveKey="email"
                  transition={false}
                  id="noanim-tab-example"
                  className="mb-3"
                  onSelect={(e) => setContact(e)}
                >
                  <Tab eventKey="email" title="Email">
                    <div className="mb-4">
                      <Form.Group>
                        <InputGroup className="mt-2 grey-select ">
                          <InputGroup.Prepend>
                            <InputGroup.Text className="greyText inptLbl">
                              Email:
                            </InputGroup.Text>
                          </InputGroup.Prepend>

                          <Form.Control
                            as="input"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                      {/* <Form.Group>
                        <InputGroup
                          className={`grey-select grey-select2 mt-16px`}
                        >
                          <InputGroup.Prepend>
                            <InputGroup.Text>Time</InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control as="select" name="" className="bgNone">
                            {quoteData?.Time?.map((time) => {
                              return <option value={time}>{time}</option>;
                            })}
                         
                          </Form.Control>
                        </InputGroup>
                      </Form.Group>

                      <Form.Control
                        className="grey-textarea mt-3"
                        placeholder="Add description..."
                        as="textarea"
                        rows={5}
                      /> */}
                    </div>
                  </Tab>
                  <Tab eventKey="phone" title="Phone">
                    <div className="mb-4">
                      <Form.Group>
                        <InputGroup className="mt-2 grey-select ">
                          <InputGroup.Prepend>
                            <InputGroup.Text className="greyText inptLbl">
                              Phone:
                            </InputGroup.Text>
                          </InputGroup.Prepend>

                          <Form.Control
                            as="input"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                      {/* <Form.Group>
                        <InputGroup
                          className={`grey-select grey-select2 mt-16px`}
                        >
                          <InputGroup.Prepend>
                            <InputGroup.Text>Time</InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control as="select" name="" className="bgNone">
                            {quoteData?.Time?.map((time) => {
                              return <option value={time}>{time}</option>;
                            })}
                          </Form.Control>
                        </InputGroup>
                      </Form.Group>

                      <Form.Control
                        className="grey-textarea mt-3"
                        placeholder="Add description..."
                        as="textarea"
                        rows={5}
                      /> */}
                    </div>
                  </Tab>
                </Tabs>

                <Form.Group>
                  <InputGroup className={`grey-select grey-select2 mt-16px`}>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Time</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      as="select"
                      name=""
                      className="bgNone"
                      onChange={(e) => setTime(e.target.value)}
                      // value={quoteData?.Time[5]}
                    >
                      {quoteData?.Time?.map((time, i) => {
                        return (
                          <option key={i} value={time}>
                            {time}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </InputGroup>
                </Form.Group>

                <Form.Control
                  className="grey-textarea mt-3"
                  placeholder="Add description..."
                  as="textarea"
                  rows={5}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Row className="m-0" style={{ justifyContent: "space-between" }}>
          <Col md={12} className="p-0 text-right">
            <Button
              type="submit"
              style={{ height: "47px", padding: "13px 23px" }}
              className="float-right bluebtnn w-100"
              onClick={() => {
                // props.isClose();
                SubmitData();
              }}
            >
              {submitting ? (
                <>
                  <WhiteLoader />
                  Submitting...
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default SubmitQuoteModal;
