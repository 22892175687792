import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Dropdown } from "react-bootstrap";
import "./mspclients.scss";

const MSPClientFilters = (props) => {
  
  const { children ,headersList ,updatedHeaderList} = props;
  const [filteredMenuItems, setfilteredMenuItems] = useState(false);
  const [shownList, setShownList] = useState([]);
  const [hiddenList, setHiddenList] = useState([]);
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEndShown(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      shownList,
      result.source.index,
      result.destination.index
    );
    setShownList(
      items.map((item, index) => {
        return {
          ...item,
          SortOrder: index + 1,
        };
      })
    );
    updatedHeaderList([
      ...items.map((item, index) => {
        return {
          ...item,
          SortOrder: index + 1,
        };
      }),
      ...hiddenList,
    ]);
  }
  function onDragEndHidden(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      hiddenList,
      result.source.index,
      result.destination.index
    );
    setHiddenList(items);
  }

useEffect(() => {
  
  setShownList([...headersList]?.filter((item) => item.Visibility === true));
  setHiddenList([...headersList]?.filter((item) => item.Visibility === false));
}, [headersList])


  useEffect(() => {
   
    let dragdropdown = document.getElementById("drag-drop-dropdown");
    if (!dragdropdown) return;
    dragdropdown.classList.add("d-none");
    function CloseDraggableDropdown(e) {
      const DraggableDropdownParent =
        document.getElementById("dropdown-parent");
      const DraggableDropdown = document.getElementById("drag-drop-dropdown");

      // const Parent2 = document.getElementById("parent-2");

      if (
        e.target !== DraggableDropdownParent &&
        !DraggableDropdownParent?.contains(e.target)
      ) {
        if (e.target.id === "rename-div") {
          return null;
        } else {
          setfilteredMenuItems(false);

          DraggableDropdown && DraggableDropdown.classList.add("d-none");
        }
      }
    }

    document.addEventListener("click", CloseDraggableDropdown);

    return () => {
      document.removeEventListener("click", CloseDraggableDropdown);
    };
  }, []);
  return (
    <div className="mx-2 clients-filter-dropdown action_center">
      <>
        <Dropdown
          id="dropdown-parent"
          className="table-drop-down edit-section-dropdown position-relative mt-1"
        >
          <Dropdown.Toggle
            className={`section-list-toggle`}
            onClick={() => {
              console.log("toggle clicked");
              const dragdropdown =
                document.getElementById("drag-drop-dropdown");
              dragdropdown.classList.toggle("d-none");
            }}
          >
            <div className="navtab" id="low">
              
              {children}
            </div>
          </Dropdown.Toggle>
          <div
            id="drag-drop-dropdown"
            className={`dropdown_menu_list drop-drag-issue position-absolute     
             
             ${filteredMenuItems ? "w-320" : "w-240"}
              
              `}
          >
            {!filteredMenuItems ? (<>
            <>{props?.dropDownUpperUI}</>
              <div className="" id="rename-div">
                <div
                  className="d-flex align-items-center f-grey pointer p-3"
                  onClick={() => {
                    setfilteredMenuItems(true);
                  }}
                  id="rename-div"
                >
                  <img
                    alt=""
                    src="/images/attack-surface/column-icon.svg"
                    className="mr-2"
                  />
                  Columns settings
                  <span className="mr-3"></span>
                  <img
                    alt=""
                    src="/images/attack-surface/chevron-right.svg"
                    className="ml-5"
                  />
                </div>
                
              </div>
              </>
            ) : (
              <>
                {/* Shown Items */}
                <div className="m-3"></div>
                <span className="list_item_header">Shown:</span>
                <DragDropContext onDragEnd={onDragEndShown}>
                  <Droppable
                    direction="vertical"
                    droppableId="droppableSection"
                  >
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          maxHeight: "215px",
                          overflowY: "auto",
                        }}
                        className="hide-scrollbar"
                        // style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {shownList &&
                          shownList
                            .filter((item) => item.Visibility === true)
                            .map((section, i) => (
                              <Draggable
                                key={section?.FieldKey}
                                draggableId={section?.FieldKey}
                                index={i}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="list_item d-flex justify-content-between  px-2"
                                    key={i}
                                    index={i}
                                  >
                                    <>
                                      <div
                                        className="d-inline-flex w-75"
                                        onClick={() => {}}
                                        style={{
                                          alignItems: "center",
                                          paddingTop: "3px",
                                        }}
                                      >
                                        <img
                                          alt=""
                                          src="/images/actioncenter/contextmenu.svg"
                                          className="pr-2"
                                          style={{ cursor: "grab" }}
                                          onClick={(e) => {}}
                                        />
                                        {section?.FieldName}
                                      </div>

                                      <div
                                        id="rename-div"
                                        style={{
                                          float: "right",
                                        }}
                                        className="position-relative sideBarDropdown complianceManagerDropdown actioncenterdropdown"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                          let updated_shown = [...shownList];
                                          let removed_item =
                                            {...updated_shown.splice(i, 1)[0]};
                                          removed_item.Visibility = false;
                                          setShownList(
                                            [...updated_shown].map((item, index) => {
                                              return {
                                                ...item,
                                                SortOrder: index + 1,
                                              };
                                            })
                                          );
                                            updatedHeaderList([
                                              ...updated_shown.map(
                                                (item, index) => {
                                                  return {
                                                    ...item,
                                                    SortOrder: index + 1,
                                                  };
                                                }
                                              ),
                                              ...hiddenList,
                                              {...removed_item},
                                            ]);
                                          setHiddenList([
                                            ...hiddenList,
                                            { ...removed_item },
                                          ]);
                                        }}
                                      >
                                        <img
                                          id="rename-div"
                                          alt=""
                                          src="/images/attack-surface/shown-icon.svg"
                                          className="pr-2 float-right mt-2"
                                        />
                                      </div>
                                    </>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                {/* Hidden Items */}
                <div className="m-3"></div>
                <span className="list_item_header mt-3">Hidden:</span>
                <DragDropContext onDragEnd={onDragEndHidden}>
                  <Droppable
                    direction="vertical"
                    droppableId="droppableSection"
                  >
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          maxHeight: "215px",
                          overflowY: "auto",
                        }}
                        className="hide-scrollbar"
                        // style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {hiddenList &&
                          hiddenList
                            .filter((item) => item.Visibility === false)
                            .map((section, i) => (
                              <Draggable
                                key={section?.FieldKey}
                                draggableId={section?.FieldKey}
                                index={i}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="list_item d-flex justify-content-between  px-2"
                                    key={i}
                                    index={i}
                                  >
                                    <>
                                      <div
                                        className="d-inline-flex w-75"
                                        onClick={() => {}}
                                        style={{
                                          alignItems: "center",
                                          paddingTop: "3px",
                                        }}
                                      >
                                        <img
                                          alt=""
                                          src="/images/actioncenter/contextmenu.svg"
                                          className="pr-2"
                                          style={{ cursor: "grab" }}
                                        />
                                        {section.FieldName}
                                      </div>

                                      <div
                                        style={{
                                          float: "right",
                                        }}
                                        className="position-relative sideBarDropdown complianceManagerDropdown actioncenterdropdown"
                                      >
                                        <img
                                          id="rename-div"
                                          alt=""
                                          src="/images/attack-surface/hidden-icon.svg"
                                          className="pr-2 float-right mt-2"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            let updated_hidden = [
                                              ...hiddenList,
                                            ];
                                            let removed_item =
                                              {...updated_hidden.splice(i, 1)[0]};
                                            removed_item.Visibility = true;
                                            setHiddenList(updated_hidden);
                                            let updated_shown = [
                                              ...shownList,
                                              { ...removed_item },
                                            ];
                                            updatedHeaderList([
                                              ...updated_shown.map(
                                                (item, index) => {
                                                  return {
                                                    ...item,
                                                    SortOrder: index + 1,
                                                  };
                                                }
                                              ),
                                              ...updated_hidden,
                                            ]);
                                            setShownList(
                                              updated_shown.map(
                                                (item, index) => {
                                                  return {
                                                    ...item,
                                                    SortOrder: index + 1,
                                                  };
                                                }
                                              )
                                            );
                                          }}
                                        />
                                      </div>
                                    </>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </>
            )}
          </div>
        </Dropdown>
      </>
    </div>
  );
};

export default MSPClientFilters;

const headersList = [
  {
    SortOrder: 1,
    FieldName: "Client name",
    FieldKey: "asset_id",
    Visibility: true,
  },
  {
    SortOrder: 2,
    FieldName: "CyberCompliance",
    FieldKey: "asset_name",
    Visibility: true,
  },
  {
    SortOrder: 3,
    FieldName: "Cyber Score",
    FieldKey: "hostname",
    Visibility: true,
  },
  {
    SortOrder: 4,
    FieldName: "CyberThreat Level",
    FieldKey: "manufacturer",
    Visibility: true,
  },
  {
    SortOrder: 5,
    FieldName: "Active Threats",
    FieldKey: "model",
    Visibility: true,
  },
  {
    SortOrder: 6,
    FieldName: "Active Risks",
    FieldKey: "serial",
    Visibility: true,
  },
  {
    SortOrder: 7,
    FieldName: "Risky Users",
    FieldKey: "operating_system",
    Visibility: true,
  },
  {
    SortOrder: 8,
    FieldName: "Cyber investment",
    FieldKey: "operating_system_feature_build",
    Visibility: true,
  },
  {
    SortOrder: 9,
    FieldName: "Cyber ROI",
    FieldKey: "ip_address",
    Visibility: true,
  },
  {
    SortOrder: 10,
    FieldName: "Cyber savings",
    FieldKey: "mac_address",
    Visibility: true,
  },
];
