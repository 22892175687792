import React, { useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { getAPIData } from "../../../../Utils/commonApi";
import ImageWithInitials from "../../../../Utils/ImageWithInitials";
import { useAuth } from "../../../context/Auth";
import { DeleteModal } from "../../../DeleteModal/DeleteModal";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import HighgroundDatePicker from "../../../test/TestComponent";
import { GreyLoader } from "../../../Theme/APILoaders";
// import "./supplier-profiler.scss";

const AuditModal = ({ show, hideModal }) => {
  const { authTokens } = useAuth();
  const [auditList, setAuditList] = useState([]);
  const [auditDetails, setAuditDetails] = useState([]);
  const [selectedAuditId, setSelectedAuditId] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState(false)

  useEffect(() => {
    if (show) {
      setLoading(true);
      getAuditList();
    }
  }, [show]);

  async function getAuditList() {
    await getAPIData("659", authTokens)
      .then((response) => {
        setAuditList(response?.MSPSecOpsAudit_List);
        setSelectedAuditId(response?.MSPSecOpsAudit_List[0]?.ObjectId);
        if (response?.MSPSecOpsAudit_List[0]?.ObjectId) {
          getAuditDetails(response?.MSPSecOpsAudit_List[0]?.ObjectId);
        }
      })
      .catch((error) => {
        console.log(error);
        CallToast("Something went wrong", false, false, "v2style");
        setLoading(false);
      });
  }

  async function getAuditDetails(id) {
    setLoading(true);
    await getAPIData("660", authTokens, { optionEnum1: id })
      .then((response) => {
        setAuditDetails(response);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        CallToast("Something went wrong", false, false, "v2style");
        setLoading(false);
      });
  }
  async function deleteAudit(objectId) {
    await getAPIData(`${784}`, authTokens, {
      optionEnum1: objectId,
      optionEnum2: 0,
    })
      .then((response) => {
        if (response?.Success) {
          CallToast("Deleted Successfully", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  async function printAudit() {
    await getAPIData(`${691}`, authTokens, {
        optionEnum1: Number(localStorage.getItem("auditId"))
    })
        .then((response) => {
            if (response?.mr?.Success) {
                window.open(response?.FileURL);
                setPrintLoading(false)
            }
        })
        .catch((err) => {
            console.log(err);
            CallToast("Something went wrong", false, false, "v2style");
        });
}
  return (
    <Modal
      show={show}
      dialogClassName="empty-modal "
      className=" supplier-assessment d-flex justify-content-center  hide-scrollbar"
      aria-labelledby=" "
      onHide={hideModal}
      centered
      scrollable
      // style={{width: '1200px'}}
    >
      <Modal.Body
        className="p-0 hide-scrollbar"
        style={{ width: "1200px", overflowY: "unset" }}
      >
        {
          <div
            className="supplier-profiler profilingModal d-flex"
            id="modal-profiling"
          >
            <div className="w-100">
              <div
                className="w-100 position-sticky d-flex align-items-center justify-content-between border-bottom p-2 px-4 bg-white border-left "
                style={{
                  zIndex: "3",
                }}
              >
                <div className="d-flex align-items-center f-500 f-black bg-white">
                  Audit History
                </div>

                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    {/* <Dropdown className="device-dropdown"> */}
                    <Dropdown className="hg-white-dropdown user-detail-dropdown supplier-menu">
                      <Dropdown.Toggle className="custom-dropdown-toggle ">
                        <img alt="" src="/images/horizontal-dots.svg" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className=" mt-1 "
                        style={{ width: "200px" }}
                      >
                        <div
                          className="drop-item p-2 pl-3 my-1 pointer f-grey"
                          onClick={(e) => {
                            //   duplicateTemplate(null, 1);
                            e.target.closest(".hg-white-dropdown").click();
                            setPrintLoading(true);
                            printAudit();
                          }}
                        >
                          <div className="d-flex align-items-center">
                              {
                                  printLoading ? <div className="msp-service-update-loader ml-2 mr-1"></div> : <></>
                              }
                              Print
                          </div>
                        </div>

                        <div
                          className="d-flex align-items-center justify-content-between drop-item f-danger p-2 pl-3 my-1 pointer"
                          onClick={(e) => {
                            //   if (selectedTemplateObject.IsUserDefined) {
                            setShowDeleteModal(true);
                            //     setDeleteSentQuestionnaireId(questionId);
                            //   } else {
                            //     CallToast(
                            //       "Cannot delete default template.",
                            //       false,
                            //       false,
                            //       "v2style"
                            //     );
                            //   }
                            e.target.closest(".hg-white-dropdown").click();
                          }}
                        >
                          Delete
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <img
                    src="/images/attack-surface/cross-icon.svg"
                    alt=""
                    width={16}
                    className="pointer"
                    onClick={hideModal}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-between ">
                <div
                  className="w-22 hide-scrollbar height_block_scroll_left"
                  style={{
                    // padding: "16px",
                    overflowY: "scroll",
                    position: "relative",
                  }}
                >
                  <div>
                    {/* <div className="bg-hover-grey m-3 p-2 radius-4">
                      <div className="f-14 f-500 f-grey"> May 22 2023</div>
                      <div className="f-12 f-grey d-flex">
                        <img
                          alt=""
                          src={"/images/supply-chain/profiling-icon.svg"}
                          className="mr-2"
                        />
                        <div className="w-100">Jane Cooper</div>{" "}
                      </div>
                    </div> */}
                    {auditList?.map((aud, i) => {
                      return (
                        <div
                          className="pointer bg-hover-grey m-3 p-2 radius-4"
                          onClick={() => {
                            getAuditDetails(aud?.ObjectId);
                            setSelectedAuditId(aud?.ObjectId);
                          }}
                        >
                          <div className="f-14 f-500 f-grey">
                            {aud?.StartDate_Text}
                          </div>
                          <div className="f-12 f-grey d-flex">
                            <img
                              alt=""
                              width={16}
                              src={aud?.PerformedByLogo}
                              className=" rounded-full bg-darkgrey mr-2"
                            />
                            <div className="w-100">{aud?.PerformedBy_Text}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div
                  className={`${"w-100"} d-flex flex-column align-items-center border-left height_block_scroll hide-scrollbar`}
                  style={{
                    overflowY: "scroll",
                    padding: "30px",
                  }}
                >
                  {loading ? (
                    <span className="d-inline-flex align-items-center justify-content-center h-100 w-100">
                      <GreyLoader width={30} />
                      Loading...
                    </span>
                  ) : (
                    <div
                      className="d-flex flex-column"
                      style={{ width: "640px", gap: "12px" }}
                    >
                      <div className="f-16 f-600">Audit Details</div>

                      <div
                        className="w-100 d-flex flex-column"
                        style={{ gap: "8px" }}
                      >
                        <div className="f-500 f-darkgrey">Details</div>
                        <div
                          className="f-grey"
                          style={{
                            gap: "4px",
                            display: "grid",
                            gridTemplateColumns: "20% 80%",
                            alignItems: "center",
                          }}
                        >
                          <div>Performed by</div>
                          <div
                            className="d-flex"
                            // style={{ paddingLeft: "5px" }}
                          >
                            <img
                              alt=""
                              width={16}
                              src={auditDetails?.PerformedByLogo}
                              className=" rounded-full bg-darkgrey mr-2"
                            />
                            <div className="w-100">
                              {auditDetails?.PerformedBy_Text}
                            </div>
                          </div>
                        </div>
                        <div
                          className="f-grey"
                          style={{
                            gap: "4px",
                            display: "grid",
                            gridTemplateColumns: "20% 60%",
                            alignItems: "center",
                          }}
                        >
                          <div>Start Date</div>
                          <div className="w-55  ">
                            <div
                              className="d-flex align-items-center hide-focus-outline"
                              tabIndex={0}
                            >
                              <img
                                alt=""
                                className="mr-1"
                                src="/images/attack-surface/date-icon.svg"
                              />

                              <input
                                disabled
                                tabIndex={-1}
                                type="text"
                                placeholder={`Choose date`}
                                className="border-none pl-2 bg-transparent-imp w-100"
                                value={auditDetails?.StartDate ?? ""}
                              />
                            </div>

                            {/* <HighgroundDatePicker
                              selected={auditDetails?.StartDate ?? ""}
                              placeholder={"Choose "}
                              icon={"/images/attack-surface/date-icon.svg"}
                              onChange={(e) => {
                                // setAuditDetails({
                                //   ...auditDetails,
                                //   StartDate: e,
                                //   StartDateError: false,
                                // });
                              }}
                              isError={auditDetails?.StartDateError}
                            /> */}
                          </div>
                        </div>
                        <div
                          className="f-grey"
                          style={{
                            gap: "4px",
                            display: "grid",
                            gridTemplateColumns: "20% 60%",
                            alignItems: "center",
                          }}
                        >
                          <div>Completion Date</div>
                          <div className="w-55  ">
                            <div
                              className="d-flex align-items-center hide-focus-outline"
                              tabIndex={0}
                            >
                              <img
                                alt=""
                                className="mr-1"
                                src="/images/attack-surface/date-icon.svg"
                              />

                              <input
                                disabled
                                tabIndex={-1}
                                type="text"
                                placeholder={`Choose date`}
                                className="border-none pl-2 bg-transparent-imp w-100"
                                value={auditDetails?.CompletionDate ?? ""}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="f-grey"
                          style={{
                            gap: "4px",
                            display: "grid",
                            gridTemplateColumns: "20% 60%",
                            alignItems: "center",
                          }}
                        >
                          <div>Next Audit</div>
                          <div className="w-55  ">
                            <div
                              className="d-flex align-items-center hide-focus-outline"
                              tabIndex={0}
                            >
                              <img
                                alt=""
                                className="mr-1"
                                src="/images/attack-surface/date-icon.svg"
                              />

                              <input
                                disabled
                                tabIndex={-1}
                                type="text"
                                placeholder={`Choose date`}
                                className="border-none pl-2 bg-transparent-imp w-100"
                                value={auditDetails?.NextAuditDate ?? ""}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="w-100 d-flex flex-column">
                        <div className="f-500 f-darkgrey">Audit Summary</div>
                        <textarea
                          placeholder="Type your message here..."
                          className="w-100 p-2 border-grey radius-4 mt-2"
                          rows={6}
                          value={auditDetails?.AuditSummary}
                          onChange={(e) => {
                            // setAuditDetails({
                            //   ...auditDetails,
                            //   AuditSummary: e.target.value,
                            // });
                          }}
                        ></textarea>
                      </div>

                      <div className="w-100 d-flex flex-column">
                        <div className="f-500 f-darkgrey">Actions</div>
                        <div
                          className="bg-grey"
                          style={{
                            borderRadius: "8px",
                          }}
                        >
                          {auditDetails?.ActionTaskList?.map(
                            (action, index) => {
                              return (
                                <div
                                  className={`d-flex flex-row `}
                                  style={{
                                    justifyContent: "space-between",
                                    padding: "12px 16px 12px 16px",
                                    borderBottom:
                                      index ===
                                      auditDetails?.ActionTaskList?.length - 2
                                        ? "1px solid #ebedf1"
                                        : "none",
                                  }}
                                >
                                  <div className="d-flex">
                                    <img
                                      alt=""
                                      width={16}
                                      src="/images/empty-circle.svg"
                                      className=" rounded-full  mr-2"
                                    />
                                    <div>{action.Title}</div>
                                  </div>
                                  <div className="d-flex f-grey f-12">
                                    <div>{action?.DueDate}</div>
                                    <div className="ml-2">
                                      {action?.OwnerInitails ? (
                                        <ImageWithInitials
                                          initials={action?.OwnerInitails}
                                          width={16}
                                          background={"purple"}
                                        />
                                      ) : (
                                        <img
                                          alt=""
                                          src={action?.OwnerImage}
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                            borderRadius: "10px",
                                            objectFit: "contain",
                                          }}
                                        />
                                      )}
                                    </div>

                                    <div
                                      className={`ml-2 px-2 rounded-full d-flex align-items-center f-12 f-500 pointer `}
                                      style={{
                                        color: action.Status_Colour,
                                        backgroundColor: "#EDEDED",
                                        display: "inline-flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "8px",
                                          height: "8px",
                                          borderRadius: "50%",
                                          backgroundColor: action.Status_Colour,
                                          marginRight: "8px",
                                        }}
                                      />
                                      {action.Status_Text}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <DeleteModal
              show={showDeleteModal}
              hideModal={() => setShowDeleteModal(false)}
              deleteMessage={"You are about to delete an audit."}
              deleteType={"deleteSentQuestionnaire"}
              deleteSentQuestionnaire={() => {
                deleteAudit(selectedAuditId);
              }}
            />
          </div>
        }
      </Modal.Body>
    </Modal>
  );
};

export default AuditModal;
