import React, { useEffect, useState } from "react";
import General from "./General";
import Subscriptions from "./Subscriptions";
import Notifications from "./Notifications";
import Rewards from "./Rewards";
import UsersAndDevices from "./UsersAndDevices";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import queryString from "query-string";
import "./Settings.css";
import "../../main.scss";
import Header from "../Header";
import Billing from "./Billing";
import ToastMessage from "../ToastMessage";

const SystemSettings = (props) => {
  const params = queryString.parse(props.location.search);
  let tabRedirect = localStorage.getItem("redirectTab")
  const [activeTab, setActiveTab] = useState(tabRedirect == 'true' ? 'subscriptions' : tabRedirect == 'billing' ? 'billing' : 'general');
  const [toastMsg, setToastMsg] =  useState({
      show: false,
      msg: false,
    });
  useEffect(() => {
    if (params.tab && params.tab !== activeTab) {
      setActiveTab(params.tab);
    }
  }, [params.tab]);

  return (
    <Row className="fullHeight">
     {toastMsg.show && (
          <ToastMessage hide={true} message={'Removed Successfully' } />
      )}
      <Col xs={12} className="px-0">
        <div className="dashboard fullHeight">
          <header
            className="d-flex justify-content-between navbar sticky-top pb-0"
            style={{ paddingTop: "1em", background: "#FFFFFF" }}
          >
            <h1>System Settings</h1>
            <Header />
          </header>
          <Tabs
            className="integration_tabs"
            activeKey={activeTab}
            transition={false}
            style={{ paddingTop: "5%" }}
            onSelect={(k) => {
              setActiveTab(k)
              localStorage.setItem("redirectTab", false);
            }}
          >
            <Tab eventKey="general" title="General">
              <div className="mt40px">
                <General />
              </div>
            </Tab>
            <Tab eventKey="subscriptions" title="Subscriptions">
              <div className="mt40px">
                <Subscriptions />
              </div>
            </Tab>
            <Tab eventKey="rewards" title="Rewards">
              <div className="mt40px">
                <Rewards />
              </div>
            </Tab>
            <Tab eventKey="billing" title="Billing">
              <div className="mt40px">
                {activeTab == "billing" && (
                  <Billing setKey={setActiveTab} setToast={setToastMsg} />
                )}
              </div>
              {/* <Row style={{ paddingTop: "1.5em" }}></Row> */}
            </Tab>
            <Tab eventKey="notifications" title="Notifications">
              <div className="mt40px">
                <Notifications pageEnum="100" isResponse={true} />
              </div>
            </Tab>
            <Tab eventKey="users-and-devices" title="Users & Devices">
              <div className="mt40px">
                <UsersAndDevices setKey={setActiveTab} />
              </div>
            </Tab>
          </Tabs>
        </div>
      </Col>
    </Row>
  );
};
export default SystemSettings;
