import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import SlidingModal from "../../CustomModal/SlidingModal";
import SetDefaultIntegrationModal from "./SetDefaultIntegrationModal";
import "./mspIntegrations.scss";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import ConnectIntegrationModal from "./ConnectIntegrationModal";
// import { CallToast } from "../../";
import Loader from "../../Common/loader";
import { TrackUser } from "../../../Utils/SegmentFunctions";

const IntegrationV2Modal = ({
  show,
  hideModal,
  parentID,
  integrationId,
  refreshData,
}) => {
  const [showSetDefaultModal, setShowSetDefaultModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [disconnecting, setDisconnecting] = useState(false);
  const [showDisconnect, setShowDisconnect] = useState(false);
  const [showConnectModal, setShowConnectModal] = useState(false);

  const { authTokens } = useAuth();
  const [loading, setLoading] = useState(false);
  async function getModalData() {
    await getAPIData("65", authTokens, {
      OptionEnum1: integrationId,
      OptionEnum2: 2,
    })
      .then((response) => {
        setLoading(false);
        if (response?.mr?.Success) {
          setModalData(response);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  async function disconnectIntegration() {
    setDisconnecting(true);
    await getAPIData("65", authTokens, {
      OptionEnum1: integrationId,
      OptionEnum2: 6,
    })
      .then((response) => {
        setDisconnecting(false);
        setShowDisconnect(false);
        if (response?.Success) {
          window.localStorage.setItem(
            "user_MessageObj",
            JSON.stringify(response?.Message)
          );
          if (refreshData) refreshData();
        
          hideModal();
          CallToast(
            "Integration disconnected successfully",
            false,
            false,
            "v2style"
          );
          TrackUser("Tool disconnected", {
            type:modalData?.IntegrationDetail?.IntegrationName,
          });
        }
      })
      .catch((err) => {
        setDisconnecting(false);
        console.log(err);
      });
  }

  useEffect(() => {
    if (show) {
      setShowDisconnect(false);
      setDisconnecting(false);
      setLoading(true);
      getModalData();
    }
  }, [show]);
  return (
    <SlidingModal
      show={show}
      parentID={parentID}
      id="0015sqxw"
      width={"28%"}
      onHide={hideModal}
    >
      <SlidingModal.Header className="">
        <div className="w-100 position-relative">
          <img
            alt=""
            className=""
            src={`/images/attack-surface/integrations/largeIcons/${integrationId}.webp`}
            style={{
              width: "inherit",
              borderBottom: "1px solid #ebecf1",
            }}
          />
          <img
            alt=""
            className="position-absolute pointer"
            src="/images/attack-surface/cross-icon.svg"
            onClick={hideModal}
            style={{
              right: "15px",
              top: "21px",
              width: "14px",
            }}
          />
        </div>
      </SlidingModal.Header>
      <SlidingModal.Body className="">
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center w-100 position-absolute"
            style={{
              top: "50%",
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <div className="f-12 f-grey ml-3 my-3">
              {modalData?.IntegrationDetail?.TechnologyTitle}{" "}
            </div>
            <div className="f-16 f-600 f-black ml-3 mb-3">
              {modalData?.IntegrationDetail?.IntegrationName}{" "}
            </div>
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="d-flex align-items-center justify-content-center f-blue f-500 border-right w-50 ml-3 pointer">
                <a
                  href={
                    modalData?.IntegrationDetail?.IntegrationURL ??
                    "http://null.com"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Developer's site
                  <img
                    alt=""
                    className="ml-1"
                    src="/images/msp/integrations/blue-goto.svg"
                  />
                </a>
              </div>
              <div className="d-flex align-items-center justify-content-center w-50 f-grey f-500 pointer">
                <a
                  href={
                    modalData?.IntegrationDetail?.Integration_KB_URL ??
                    "http://null.com"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Details
                  <img
                    alt=""
                    className="ml-1"
                    src="/images/msp/integrations/grey-info.svg"
                  />
                </a>
              </div>
            </div>

            <hr style={{ borderTop: "1px solid #EBEDF1" }} />
            {/* Clients data */}
            <div className="bg-grey radius-4 p-3 mx-2 d-none">
              <div className="f-darkgrey f-500 mb-3">Data</div>
              <div className="d-flex align-items-center w-100 mb-2">
                <div className="f-grey w-50">Mapped Clients</div>
                <div className="f-black w-50">11</div>
              </div>
              <div className="d-flex align-items-center w-100">
                <div className="f-grey w-50">Unmapped Clients</div>
                <div className="f-black w-50">5</div>
              </div>
            </div>
            {/* View clients button */}
            <div className="bg-grey radius-4 d-flex-- d-none align-items-center justify-content-center pointer f-grey f-500 mx-2 mt-2 p-2">
              View Clients
              <img
                alt=""
                className="ml-2"
                src="/images/msp/integrations/chevron-right.svg"
              />
            </div>
            {/* Default tools */}
            {/* <div className="mx-3 mt-3">
              <div className="d-flex align-items-center f-black mb-2">
                <img
                  alt=""
                  src="/images/msp/integrations/blue-plug-connected.svg"
                  className="mr-2"
                />
                Default Patch Management Tool
              </div>
              <div className="d-flex align-items-center f-black mb-2">
                <img
                  alt=""
                  src="/images/msp/integrations/blue-plug-connected.svg"
                  className="mr-2"
                />
                Default RMM Tool
              </div>
              <Button
                className="hg-blue-btn w-100 mt-1 d-flex align-items-center justify-content-center"
                onClick={() => {
                  setShowSetDefaultModal(true);
                }}
              >
                Set as default
                <img
                  alt=""
                  src="/images/msp/integrations/white-plug-pending.svg"
                  className="ml-2"
                />
              </Button>
            </div> */}

            {/* <hr style={{ borderTop: "1px solid #EBEDF1" }} className="mx-2" /> */}
            {/* Sync details */}
            <div className="mx-3">
              <div className="f-500 f-darkgrey mb-3">Sync</div>
              <div className="d-flex align-items-center w-100 mb-2">
                <div className="w-50 f-grey">Status</div>
                <div className="f-black w-50">
                  <img
                    alt=""
                    width={14}
                    height={14}
                    className="mr-2"
                    src={modalData?.IntegrationStatus?.TitleIconURL ?? "xxx"}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = "/images/no-image.png";
                    }}
                  />
                  {modalData?.IntegrationStatus?.Title}
                </div>
              </div>
              <div className="d-flex align-items-center w-100 mb-2">
                <div className="w-50 f-grey">Last Synced</div>
                <div className="f-black w-50">
                  {modalData?.IntegrationStatus?.LastSync
                    ? modalData?.IntegrationStatus?.LastSync?.toString()?.split(
                        " "
                      )?.[0]
                    : ""}
                </div>
              </div>
              <div className="d-flex align-items-center w-100 mb-2">
                <div className="w-50 f-grey">Next Sync</div>
                <div className="f-black w-50">
                  {modalData?.IntegrationStatus?.NextSync
                    ? modalData?.IntegrationStatus?.NextSync?.toString()?.split(
                        " "
                      )?.[0]
                    : ""}
                </div>
              </div>
            </div>
            <hr style={{ borderTop: "1px solid #EBEDF1" }} className="mx-2" />
            {/* Connection details */}
            <div className="mx-3">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="f-darkgrey f-500">Connection details</div>
                <div
                  className="f-blue f-500 pointer"
                  onClick={() => {
                    setShowConnectModal(true);
                  }}
                >
                  Edit
                </div>
              </div>

              {modalData?.ConnectionStrings?.map(
                (connectionDetail, connectionIndex) => {
                  return (
                    <div className="d-flex align-items-start w-100 mb-2">
                      <div className="w-50 f-grey">
                        {connectionDetail?.Label}
                      </div>
                      {connectionDetail?.ShowText ? (
                        <div
                          className="f-black w-50"
                          style={{
                            overflowWrap: "anywhere",
                          }}
                        >
                          {connectionDetail?.KeyValue}
                        </div>
                      ) : (
                        <div className="f-black w-50">• • • • • • • •</div>
                      )}
                    </div>
                  );
                }
              )}
            </div>

            {/* Disconnect Integration UI*/}
            <div
              className={`   ${
                showDisconnect ? "show d-flex" : ""
              } disconnectv2_banner     flex-column align-items-center radius-8 borderGrey`}
              style={{
                position: "fixed",
                bottom: "2%",
                right: "6%",
                width: "86%",
                zIndex: 1000,
                background: "white",
                border: "1px solid #ebecf1",
              }}
            >
              <div className="position-relative w-fit-content pt-3">
                <img
                  alt=""
                  width={48}
                  height={48}
                  className="radius-8 borderGrey mb-2"
                  src={`/images/attack-surface/integrations/${integrationId}.webp`}
                />
                <img
                  src="/images/attack-surface/disconnect-plug.svg"
                  alt=""
                  className="position-absolute"
                  style={{
                    bottom: "2px",
                    right: "-8px",
                  }}
                />
              </div>
              <div className="f-16 f-500 text-center mb-2">
                Disconnect integration
              </div>
              <div className="f-darkgrey text-center w-75">
                Are you sure that you want to disconnect this integration?{" "}
              </div>
              <hr className="mb-0" />
              <div className="p-3 w-100">
                <Button
                  className="hg-red-btn radius-2 w-100 mb-2"
                  disabled={disconnecting}
                  onClick={() => {
                    disconnectIntegration();
                  }}
                >
                  {disconnecting ? "Please wait..." : "Disconnect"}
                </Button>
                <Button
                  className="hg-cancel-btn radius-2 w-100"
                  onClick={() => {
                    setShowDisconnect(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>

            <ConnectIntegrationModal
              show={showConnectModal}
              hideModal={() => setShowConnectModal(false)}
              integrationId={integrationId}
              isEdit={true}
              refreshData={getModalData}
            />
          </>
        )}
      </SlidingModal.Body>
      <SlidingModal.Footer className="p-3 position-sticky w-100 border-top">
        <Button
          className="hg-cancel-btn radius-2 w-100"
          onClick={() => {
            setShowDisconnect(true);
          }}
        >
          Disconnect
        </Button>
      </SlidingModal.Footer>
      <SetDefaultIntegrationModal
        show={showSetDefaultModal}
        hideModal={() => setShowSetDefaultModal(false)}
      />
    </SlidingModal>
  );
};

export default IntegrationV2Modal;
