import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import "./toastsV2.scss";
export const ToastsV2 = ({
  pendingText,
  successText,
  done,
  showToast,
  initialText = "Please wait...",
}) => {
  return (
    <div
      className={`promise-v2-toast p-3 ${
        showToast ? "showToast" : "hideToas t"
      } ${done ? "hi de To ast-" : ""} `}
    >
      {!done && (
        <div className="d-flex align-items-center">
          <Spinner
            animation="border"
            variant="light"
            size="sm"
            className="mr-3"
          />

          <div>{initialText} </div>
        </div>
      )}

      {done && (
        <div className="d-flex align-items-start">
          <img
            alt=""
            src="/images/settings-v2/white-tick.svg"
            className="mr-2 mt-2"
          />
          <div className="success-textt">{successText}</div>
        </div>
      )}
    </div>
  );
};
