import React from "react";
import { Dropdown } from "react-bootstrap";

const ComingSoonDropdown = (props) => {
  return (
    <Dropdown className=" comingsoon-dropdown w-100" onToggle={() => {}}>
      <Dropdown.Toggle
        className="d-flex custom-dropdown-toggle hide-after w-100 "
        style={{ padding: 0 }}
      >
        <img
          alt=""
          src="/images/supply-chain/grey-plane.svg"
          className="mr-3 pointer"
        />
        {props.title && <div className="f-darkgrey">{props.title}</div>}
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="d-flex flex-column"
        style={{
          width: " 296px",
          padding: "12px",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <div className="d-flex text-center">
          <img alt="" src="/images/supply-chain/grey-plane.svg" width={32} />
          <span
            className="cursor-pointer"
            style={{
              position: "absolute",
              right: "8px",
              top: "8px",
              padding: "4px",
            }}
            onClick={(e) => {
              e.target.closest(".comingsoon-dropdown").click();
            }}
          >
            <img
              width={15}
              src="/images/attack-surface/cross-icon.svg"
              alt=""
            />
          </span>
        </div>

        <div className="d-flex flex-column align-items-center">
          <div className=" f-16 fontBold">Coming soon</div>
          <div className="f-darkgrey text-center">
            Chat with your suppliers will be coming soon
          </div>
        </div>

        <div
          className="pointer f-grey bg-grey revoke-btn"
          onClick={(e) => {
            e.target.closest(".comingsoon-dropdown").click();
          }}
        >
          Close
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ComingSoonDropdown;
