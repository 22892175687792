import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Form, Modal, Nav, Tab } from "react-bootstrap";
// import "../secOps.scss";
import "../MSPv2/secOps/secOps.scss";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import { getAPIData, postData, postJsonData } from "../../Utils/commonApi";
import { useAuth } from "../context/Auth";
import ImageWithInitials from "../../Utils/ImageWithInitials";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GreyLoader } from "../Theme/APILoaders";
const ChooseSupplierModal = ({
  show,
  hideModal,
  selectedTemplate,
  titleTemplate,
}) => {
  const { authTokens } = useAuth();
  const [inputValue, setInputValue] = useState("");
  const [suppliers, setSuppliers] = useState([]);
  const [chosenSupp, setChosenSupp] = useState({});
  const history = useHistory();
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (show) {
      setLoading(true);
      getSuppliers();
    }
  }, [show]);

  async function getSuppliers() {
    await getAPIData(305, authTokens)
      .then((response) => {
        setLoading(false);
        if (response?.mr?.Success) {
          setSuppliers(response.Suppliers);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function sendQuestionnaire(supp) {
    console.log(supp);
    setLoading(true);
    await postData(authTokens, "SendQuestionnaireForProfiling", {
      Suppliers: JSON.stringify([supp]),
      Title: titleTemplate ?? "",
      FormTemplateId: selectedTemplate, //supp?.objId_Customer, //supplier id
    })
      .then((response) => {
        console.log(response);
        setLoading(false);
        if (response?.Success) {
          history.push("/supplier-profiler/profiling", {
            backUrl: `${window.location.pathname}?${new URLSearchParams(
              window.location.search
            ).toString()}`,
            isContributorView: false,
            isTemplate: false,
            questionId: response.EntityId,
          });
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  return (
    <Modal
      show={show}
      dialogClassName="secops-cert-modal"
      className="   "
      aria-labelledby=" "
      onHide={hideModal}
      centered
      scrollable
    >
      <Modal.Header className="text-center">
        <div
          className="w-100 mb-0 font-bold-14 title d-flex justify-content-center"
          style={{ fontWeight: "600" }}
        >
          <div className="f-darkgrey">Templates</div>
          <div className="f-darkgrey mr-2 ml-2">/</div>
          <div>Choose Supplier</div>
        </div>

        <span
          className="cursor-pointer"
          onClick={() => {
            hideModal();
          }}
        >
          <img width={15} src="/images/attack-surface/cross-icon.svg" alt="" />
        </span>
      </Modal.Header>
      <Modal.Body
        className=" transparent-scroller d-flex flex-column"
        style={{
          padding: "16px 24px",
          gap: "8px",
          // height: "172px",
          width: "100%",
        }}
      >
        {
          <>
            <div>
              <>
                <div
                  className="w-100 f-grey d-flex align-items-center bg-grey"
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #EBEDF1",
                    padding: "4px",
                    marginBottom: "8px",
                  }}
                >
                  <img
                    alt=""
                    className=""
                    src="/images/calculator/search.svg"
                  />
                  <input
                    type="text"
                    className={` bg-transparent w-100 border-none pl-2`}
                    placeholder="Search"
                    value={inputValue}
                    onChange={(e) => {
                      setInputValue(e.target.value);
                    }}
                  />
                </div>
                <div className="w-100">
                  {loading ? (
                    <span className="d-inline-flex align-items-center justify-content-center h-100 w-100">
                      <GreyLoader width={30} />
                      Loading...
                    </span>
                  ) : (
                    <>
                      {suppliers
                        ?.filter((filterItem) => {
                          return filterItem?.SupplierName?.toLowerCase().includes(
                            inputValue?.toLowerCase()
                          );
                        })
                        ?.map((r) => {
                          return (
                            <div
                              className="item cursor-pointer"
                              style={{
                                gap: "8px",
                                alignItems: "center",
                                padding: "8px",
                                alignSelf: "stretch",
                              }}
                              onClick={() => {
                                setChosenSupp(r);
                                setInputValue(r.SupplierName);
                                sendQuestionnaire(r);
                              }}
                            >
                              <div
                                className="d-flex"
                                style={{ gap: "8px", alignItems: "center" }}
                              >
                                {r?.showInitials ? (
                                  <div className="rounded-full w-fit-content h-fit-content position-relative ">
                                    <ImageWithInitials
                                      initials={r?.initials}
                                      width={25}
                                      background={"purple"}
                                    />
                                  </div>
                                ) : (
                                  <div className="rounded-full w-fit-content h-fit-content position-relative ">
                                    <img
                                      width={25}
                                      src={`${r?.SupplierLogo}`}
                                      className="rounded-full"
                                      alt=""
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src =
                                          "/images/msp/default-cert-icon.svg";
                                      }}
                                    />
                                  </div>
                                )}
                                <div className="d-flex">{r.SupplierName}</div>
                              </div>
                            </div>
                          );
                        })}
                    </>
                  )}
                </div>

                <div className="p-5" />
              </>
            </div>
          </>
        }
      </Modal.Body>
    </Modal>
  );
};

export default ChooseSupplierModal;
