
import React, { useEffect } from "react";
import { Button, Dropdown, Form, InputGroup } from "react-bootstrap";
import SlidingModal from "../../CustomModal/SlidingModal";
import { useState } from "react";
import Loader from "../../Common/loader";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";

import "../../AttackSurface/common.scss";
import "../mspMembersAccess/mspMembersAccess.scss"
import { getAPIData, postData, postJsonData, postRequest } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import ImageWithInitials from "../../../Utils/ImageWithInitials";
import MspAccessModalToolTip from "../mspAccessModalTooltip";
import MultiSelectDropdown from "../../MSPv2/secOps/trainingCertificates/MultiSelectDropdown";

const MspMemberAccessRoleModal = ({ show, hideModal, parentID, accessLevelId, refreshData }) => {
  const { authTokens } = useAuth();

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [adding, setAdding] = useState(false);

  const [accessSettings, setAccessSettings] = useState([]);
  const [moduleGroups, setModuleGroups] = useState([]);
  const [role, setRole] = useState({});
  const [users, setUsers] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedMembersList, setSelectedMembersList] = useState([]);



  useEffect(() => {
    if (show) {
      setLoading(true);
      getAccessSettings();
      getMembersDetails();
    }
  }, [show]);

  async function getAccessSettings() {
    await getAPIData("562", authTokens, {
      optionEnum1: accessLevelId ? accessLevelId : 0
    }).then((response) => {
      if (response.mr.Success) {
        setRole(response.data?.role);
        setAccessSettings(response.data?.permissions);
        let permissions = response.data?.permissions?.map((set)=>{
           return set.moduleGroup;
        });
        let tempGroup = [...new Set(permissions)];
        setModuleGroups(tempGroup);
        setUsers(response.data?.existingUsers);
        setLoading(false);
      }
    });
  }

  function changeAccessDropdown(obj, name){
    let newSettings = accessSettings.map(setting=>{
      if(setting.moduleName === name){
        setting.selected_AccessState = obj;
      }
      return setting;
    });
    setAccessSettings(newSettings);
  }

  async function deleteAccessLevel(){
    setDeleting(true);
    await postRequest(authTokens, "DeleteAccessRole", {
          objId_AccessRole: accessLevelId
      }
    )
      .then((response) => {
        setDeleting(false);
        if (response?.Success) {
          CallToast("Access role deleted successfully", false, false, "v2style");

          hideModal();
          if (refreshData) {
            refreshData();
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  async function sendRequest(newAccess) {
    setSaving(true);
    let accessObject = {
      role: role,
      permissions: accessSettings,
      existingUsers: users
    };
    if(accessObject?.role?.RoleName && accessObject?.role?.RoleName.trim() !== ""){
      await postJsonData(authTokens, "UpsertAccessRole", 
     accessObject
   )
     .then((response) => {
       setSaving(false);
       if (response?.Success) {
         CallToast("Access role updated successfully", false, false, "v2style");

         hideModal();
         if (refreshData) {
           refreshData();
         }
       } else {
         CallToast(response.Message, false, false, "v2style");
       }
     })
     .catch(() => {
       CallToast("Something went wrong", false, false, "v2style");
     });
   }
   else{
     CallToast("Access role cannot be empty", false, false, "v2style");
     setSaving(false);

   }
  }

  async function getMembersDetails(openTeamModal = null) {
    await getAPIData("564", authTokens, {
      optionEnum1: 0,
    }).then((response) => {
      if (response.mr.Success) {
        setMembers(response?.users);
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  async function inviteMember(){
    setAdding(true);
    let list = [];
    selectedMembersList.map(member=>{
      list.push({Key: member.objId_PSAMember, Value: member.userName });
    })
    await postData(authTokens, "Invite_MSPTeamMember", 
      {
        objId_AccessRole : role?.objId_AccessRole,
        obj_List: JSON.stringify(list),
      }
    )
      .then((response) => {
        setAdding(false);
        if (response?.Success) {
          CallToast("Member invited successfully", false, false, "v2style");

          hideModal();
          if (refreshData) {
            refreshData();
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }
  async function handleDeleteUser(userId) {
    await getAPIData("570", authTokens, {
      optionEnum1: userId,
      optionEnum99: 5,
    }).then((response) => {
      if (response.Success) {
        getMembersDetails();
        CallToast("Member deleted successfully", false, false, "v2style");
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  return (
    <SlidingModal
      show={show}
      className="common-big-modal memberAccessModal"
      parentID={parentID}
      id="ounsqxw"
      width={"28%"}
      onHide={hideModal}
    >
      <SlidingModal.Header className="text-center d-flex p-3 border-bottom">
        <div
          className="w-100 mb-0 font-bold-14 title"
          style={{ fontWeight: "600" }}
        >
          {accessLevelId > 0 ? role?.RoleName : 'Add Access Role'}
        </div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img src="/images/attack-surface/cross-icon.svg" alt="" />
        </span>
      </SlidingModal.Header>
      <SlidingModal.Body className={`msp-service-add-edit-modal    ${true}`}>
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center w-100 "
            style={{
              height: "85vh",
            }}
          >
            <Loader />
          </div>
        ) : (
          <div className="m-24px">
          <div className={`asset-name-wrapper   d-flex align-items-center p-2 radius-4`}>
            <input
              tabIndex={0}
              type="text"
              placeholder={`Name: Type `}
              className="flex-grow-1 pl-3 pr-2"
              value={role?.RoleName}
              onChange={(e) => {
                setRole({
                  ...role,
                  RoleName: e.target.value,
                })
              }}
            />
            </div>
            <div className={`asset-name-wrapper d-flex align-items-center p-2 radius-4 mt8px `}>
            <input
              tabIndex={0}
              type="text"
              placeholder={`Description: Type `}
              className="flex-grow-1 pl-3 pr-2"
              value={role?.RoleDescription}
              onChange={(e) => {
                setRole({
                  ...role,
                  RoleDescription: e.target.value,
                })
              }}
            />
            </div>
            {users && (
              <div className="border-top mt-24px">
                  <div className="f-12 f-darkgrey mt-16px" >Assigned users</div>
                            <div className={`bg-grey d-flex align-items-center radius-4 grey-border   flex-grow-1 mr-1 ${"highlight-input-with-grey-border"}`}>
                              <div className="bg-grey w-100 border-none pl-2 my-1" tabIndex={-1} >
                                    <MultiSelectDropdown
                                      data={members}
                                      selected={selectedMembersList}
                                      placeholder={"Add members"}
                                      placeholderIcon={<img
                                        alt=""
                                        className="mr-2"
                                        src="/images/msp/integrations/add-user-icon-msp.svg"
                                      />}
                                      onChange={(val) => {
                                        const mergedArray = [...selectedMembersList];

                                        for (let obj of val) {
                                          const existingObj = mergedArray.find(item => item.objId_PSAMember === obj.objId_PSAMember);
                                          if (!existingObj) {
                                            mergedArray.push(obj);
                                          }
                                        }
                                        setSelectedMembersList(val);
                                      }}
                                  />
                                </div>
                                <div className="w-fit-content dropdown-grey-theme score-question-dropdown ml-3 w-70" style={{alignSelf: 'flex-end', marginBottom: '5px'}}>
                                {selectedMembersList.length> 0 && <Button className="hg-cancel-btn" onClick={()=>{inviteMember()}}>{adding ? "Adding..." : "Add"}</Button>}
                                </div>

                            </div>
                {users.map(item=>{
                  return (<div>
                    <div className="d-flex align-items-center justify-content-between my-1 pointer radius-4 bg-hover-grey hover-on-show-parent p-2 px-3">
                      <div className="d-flex align-items-center w-100 " style={{ justifyContent: "space-between" }}>
                        <div className="d-flex align-items-center  ">
                          {item.showInitials ? ( 
                                      <ImageWithInitials
                                        initials={item.initials}
                                        width={30}
                                        background={"purple"}
                                      />
                                    ) : (
                                      <img
                                      alt=""
                                      src={`/images/${item.ImageURL}`}
                                      className=""
                                      style={{
                                        width: "30px",
                                        borderRadius: "50%",
                                        objectFit: "contain",
                                      }}
                                    />
                                )
                                  }
                            <div className="ml-2">
                              <div className="f-500 f-grey">{item.fullName}</div>
                            </div>
                        </div>
                        <div className="hover-on-show-child" onClick={()=>{handleDeleteUser(item.objId_UserSetting)}}>
                        <img src="/images/attack-surface/cross-icon.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>)
                })}
              </div>
            )} 
            <div className="border-top mt-24px">
              {moduleGroups.map((group,index)=>{
                return (<>
                  <div className="f-12 f-darkgrey mt-16px" key={`${group} + ${index}`}>{group}</div>
                    {
                      accessSettings.map((setting, index)=>{
                          if(setting.moduleGroup === group){
                            return (
                            <div className="d-flex flex-row justify-content-between  align-items-center">
                              <div className="d-flex flex-row align-items-center">
                                <div key={`${setting.moduleName} + ${index}`}>{setting.moduleName}</div>
                                <div className="w-12 tooltip-parent position-relative">
                                <img
                                  alt=""
                                  src="/images/supply-chain/dark-info-icon.svg"
                                  className="mr-2 ml-2 w-100 "
                                /> 
                                <div className="tooltip-item position-absolute" style={{width: '150px', zIndex: 101}}>
                                  <MspAccessModalToolTip
                                  data={{
                                    Description: setting.moduleDescription,
                                  }}/>
                                </div>
                              </div>
                                </div>
                               
                                
                              <Dropdown
                                    className="hg-white-dropdown user-detail-dropdown"
                                    onToggle={() => {
                                    }}
                                  >
                                    <Dropdown.Toggle className="custom-dropdown-toggle f-gray-imp">
                                      {setting.selected_AccessState ? setting.selected_AccessState.stateName : 'No Access'}
                                      
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {setting.accessStates.map((el, index)=>{
                                            return (<div
                                            key={el.stateName + index}
                                          className="p-2 pl-3 my-1 pointer f-grey"
                                          style={{ 
                                            'display': 'grid','grid-template-columns': '10% 90%',
                                          }}
                                          onClick={(e) => {
                                            changeAccessDropdown(el, setting.moduleName);
                                            e.target.closest(".hg-white-dropdown").click();
                                          }}
                                        >
                                          <div>
                                          {setting.selected_AccessState?.stateName === el.stateName ? 
                                          <img
                                          alt=""
                                          src="/images/settings-v2/check-icon.svg"
                                          className="mr-2"
                                          />
                                         : <></>
                                        } 
                                        </div>
                                          <div className="ml-2">
                                            {el.stateName}
                                          <div className="smallGreyText text-ellipsis f-darkgrey">{el.description}</div>
                                          </div>
                                          
                                        </div>)
                                      })}
                                    </Dropdown.Menu>
                                  </Dropdown>
                            </div>);
                          }
                      
                      })
                    }
                  </>);
                })
              }
             
            </div>
          </div>
        )}
            
      </SlidingModal.Body>
      <SlidingModal.Footer className="p-3 position-sticky w-100 border-top">
        <div className="d-flex align-items-center justify-content-between">
          <div>{accessLevelId > 0 && (
              <div
                className="d-flex align-items-center f-500 f-grey pointer"
                onClick={() => {
                  deleteAccessLevel();
                }}
              >
                <img
                  alt=""
                  className="mx-2"
                  src="/images/actioncenter/delete.svg"
                />
                {deleting ? "Deleting..." : "Delete"}
              </div>
              )}
          </div>
          

          <div className="d-flex justify-content-end">
            <Button className="hg-cancel-btn mr-2" onClick={hideModal}>
              Cancel
            </Button>
            <Button disabled={false} className="hg-blue-btn" onClick={() => {sendRequest()}}>
              {saving ? (
                <>
                  Saving..
                </>
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </div>
      </SlidingModal.Footer>
    </SlidingModal>
  );
};

export default MspMemberAccessRoleModal;
