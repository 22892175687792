import { USER_DETAIL } from "./constants";

export const initState = {
  userDetail: {
    cyberScoreValue: 0,
    cyberThreatValue: 0,
    cyberScoreRefreshing: false,
    cyberThreatRefreshing: false,
    newCyberScoreupdated: false,
  },
};

const UserReducer = (state = initState, action) => {
  switch (action.type) {
    case USER_DETAIL:
      return { ...state, userDetail: { ...state.userDetail, ...action.data } };

    default:
      return state;
  }
};

export default UserReducer;
