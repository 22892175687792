import React, { Fragment, useState } from "react";
import { Row, Col, Table } from "react-bootstrap";
import Header from "../Header";
import Button from "../Theme/button";
import Input from "../Theme/input";
import Search from "../Theme/searchinput";
import Dropdown from "../Theme/dropdown";
import Dropdownlabel from "../Theme/dropdownwithlabel";
import InputPassword from "../Theme/InputPassword";
import DropdownMulti from "../Theme/dropdowmulti";
import DatePicker from "../Theme/datepicker";
import SwitchButton from "../Theme/switchbutton";
import CollapseTable from "../Theme/collapsetable";
import LabelledInput, {
  PrefixComponent,
  SuffixComponent,
} from "../Theme/LabelledInput";
import LabelledInputPassword, {
  PrefixPasswordInput,
} from "../Theme/LabelledInputPassword";
import LabelledInputNumber from "../Theme/LabelledInputNumber";
const PersonalProfile = () => {
  const [theme, setTheme] = useState({
    body: "",
  });

  const [txtarea, setTxtArea] = useState("");
  const [txtarea2, setTxtArea2] = useState("");
  const [select1, setselect1] = useState();
  const [select2, setselect2] = useState({ id: 12, name: "Jane Cooper" });
  const [select3, setselect3] = useState([]);
  const [value, onChange] = useState("");
  const [switchVal, setSwitchVal] = useState(false);
  const [switchValOne, setSwitchValOne] = useState(false);

  const handleSubmit = () => {
    // set color in localstorage
    localStorage.setItem("theme", JSON.stringify(theme));
    window.location.reload();
  };

  return (
    <Fragment>
      <Row className="fullHeight">
        <Col xs={12} className="px-0">
          <div className="dashboard fullHeight">
            <header
              className="d-flex justify-content-between navbar sticky-top pb-0"
              style={{ paddingTop: "1em", background: "#FFFFFF" }}
            >
              <h1>Design System</h1>
              <Header />
            </header>
            <h3> Buttons and Links</h3>
            {/* larger button */}
            <p>1.1 button/fixed size/primary/large</p>
            <Button
              className="mr-4 button-primary large"
              onClick={() => console.log("large")}
            >
              Save
            </Button>
            <Button
              disabled={true}
              className="mr-4 button-primary large"
              onClick={() => console.log("large disable")}
            >
              Save
            </Button>{" "}
            <br />
            <br />
            {/* primary button */}
            <p>1.2 button/fixed size/primary</p>
            <Button
              className="mr-4 button-primary"
              onClick={() => console.log("primary")}
            >
              Continue
            </Button>
            <Button
              disabled={true}
              className="mr-4 button-primary"
              onClick={() => console.log("primary disable")}
            >
              Continue
            </Button>{" "}
            <br />
            <br />
            {/* Flexible Primary Button  */}
            <p>1.3 button/flexible/primary</p>
            <Button
              className="mr-4 button-primary flex"
              onClick={() => console.log("primary flexible")}
            >
              Save changes
            </Button>
            <Button
              disabled={true}
              className="mr-4 button-primary flex"
              onClick={() => console.log("primary flexible disabled")}
            >
              Save changes
            </Button>{" "}
            <br />
            <br />
            {/* Flexible Primary Small Button  */}
            <p>1.4 button/flexible/primary/small</p>
            <Button
              className="mr-4 button-primary small"
              onClick={() => console.log("primary flexible small")}
            >
              Send
            </Button>
            <Button
              disabled={true}
              className="mr-4 button-primary flex small"
              onClick={() => console.log("primary flexible small disabled")}
            >
              Send
            </Button>
            <br />
            <br />
            <br />
            <h3> 2. secondary button</h3>
            {/* larger button */}
            <p>2.1 button/fixed size/secondary/large</p>
            <Button
              className="mr-4 secondary-button"
              onClick={() => console.log("secondary-button")}
            >
              Cancel
            </Button>
            <Button
              disabled={true}
              className="mr-4 secondary-button"
              onClick={() => console.log("secondary-button disable")}
            >
              Cancel
            </Button>{" "}
            <br />
            <br />
            {/* Secondary button flexible */}
            <p>2.2 button/flexible/secondary</p>
            <Button
              className="mr-4 secondary-button flex"
              onClick={() => console.log("secondary-button flex")}
            >
              Perform Risk Assessment
            </Button>
            <Button
              disabled={true}
              className="mr-4 secondary-button flex"
              onClick={() => console.log("secondary-button flex disable")}
            >
              Perform Risk Assessment
            </Button>{" "}
            <br />
            <br />
            {/* Secondary button flexible  Icon*/}
            <p>2.3 button/flexible/secondary/icon</p>
            <Button
              className="mr-4 secondary-button flex icon"
              onClick={() => console.log("secondary-button flex")}
              image={"/images/theme/icons/upload.svg"}
              hoverImage={"/images/theme/hovericons/upload.svg"}
              icon={true}
            >
              Upload File
            </Button>
            <Button
              disabled={true}
              className="mr-4 secondary-button flex icon"
              image={"/images/theme/icons/upload.svg"}
              hoverImage={"/images/theme/hovericons/upload.svg"}
              icon={true}
              onClick={() => console.log("secondary-button flex disable")}
            >
              Upload File
            </Button>
            <br />
            <br />
            {/* Secondary button flexible  Icon*/}
            <p>2.3 button/flexible/secondary/icon</p>
            <Button
              className="mr-4 additional-button small"
              onClick={() => console.log("secondary-button flex")}
            >
              Change
            </Button>
            <Button
              disabled={true}
              className="mr-4 additional-button small"
              onClick={() => console.log("secondary-button flex disable")}
            >
              Change
            </Button>
            <br />
            <br />
            {/* Secondary button flexible  Icon*/}
            <p>3.2 button/flexible/additional/color</p>
            <Button
              className="mr-4 additional-button-color small"
              onClick={() => console.log("secondary-button flex")}
            >
              Change
            </Button>
            <Button
              disabled={true}
              className="mr-4 additional-button-color small"
              onClick={() => console.log("secondary-button flex disable")}
            >
              Change
            </Button>
            <br />
            <br />
            <h3>Inputs</h3>
            <p>1.1input/text</p>
            <Row>
              <Col xs={4}>
                <Input
                  onChange={(e) => console.log(e.target.value)}
                  name="job"
                  type="text"
                  id="#jobchange"
                  label="Job Position:"
                />
              </Col>
              <Col xs={4}>
                <Input readonly={true} label="Phone:" value="+7 126-224-34" />
              </Col>

              <Col xs={4}>
                <Input
                  readonly={true}
                  button={true}
                  label="Phone:"
                  value="+7 126-224-34"
                >
                  <Button
                    className="mr-4 additional-button small"
                    onClick={() => alert("Read Only With Button")}
                  >
                    Change
                  </Button>
                </Input>
              </Col>
            </Row>
            <br />
            <br />
            <p>1.2 input/text/without label/center</p>
            <Row>
              <Col xs={4}>
                <Input
                  onChange={(e) => console.log(e.target.value)}
                  name="job"
                  type="text"
                  textcenter={true}
                />
              </Col>
            </Row>
            <br />
            <br />
            <p>1.3 input/text/area</p>
            <Row>
              <Col xs={4}>
                <textarea
                  placeholder="Your note"
                  className={`custom_textarea-group`}
                  id="w3review"
                  name="w3review"
                  rows="5"
                  cols="50"
                  value={txtarea}
                  onChange={(e) => setTxtArea(e.target.value)}
                />
              </Col>
            </Row>
            <br />
            <br />
            <p>1.4 input password</p>
            <Row>
              <Col xs={4}>
                <InputPassword
                  onChange={(e) => console.log(e.target.value)}
                  name="job"
                  type="text"
                />
              </Col>
              <Col xs={4}>
                <InputPassword
                  onChange={(e) => console.log(e.target.value)}
                  name="job"
                  type="text"
                >
                  <Button
                    className=" additional-button small"
                    onClick={() => console.log("secondary-button flex")}
                  >
                    Change
                  </Button>
                </InputPassword>
              </Col>
            </Row>
            <br />
            <br />
            <p>1.5 input/text/area/large</p>
            <Row>
              <Col xs={4}>
                <textarea
                  placeholder="Your note"
                  className={`custom_textarea-group`}
                  id="w3review"
                  name="w3review"
                  rows="15"
                  cols="50"
                  value={txtarea2}
                  onChange={(e) => setTxtArea2(e.target.value)}
                />
              </Col>
            </Row>
            <h3>Search Input</h3>
            <p>2.1 input/search</p>
            <Row>
              <Col xs={4}>
                <Search
                  onChange={(e) => console.log(e.target.value)}
                  name="job"
                  type="text"
                  id="#search"
                  placeholder="Search..."
                  className="test"
                />
              </Col>
            </Row>
            <h3>3. Date picker input</h3>
            <p>3.1 Date picker input</p>
            <Row>
              <Col xs={4}>
                <div style={{ width: "500px" }}>
                  <DatePicker
                    label={"Date: "}
                    singleDatePicker={true}
                    startDate={"10/18/1984"}
                  />
                </div>
              </Col>
            </Row>
            <br />
            <br />
            <p>3.2 Date picker range input</p>
            <Row>
              <Col xs={4}>
                <div style={{ width: "500px" }}>
                  <DatePicker
                    label={"Date range: "}
                    startDate={"4/18/2022"}
                    endDate={"5/18/2022"}
                  />
                </div>
              </Col>
            </Row>
            <br />
            <br />
            <h3>3. Select input</h3>
            <p>3.1 input/select</p>
            <Row>
              <Col xs={4}>
                <div style={{ width: "500px" }}>
                  <Dropdownlabel
                    fieldId={"id"}
                    fieldValue={"name"}
                    selected={select2}
                    placeholder="Choose..."
                    label={"Engineer:"}
                    // showid={true}
                    onClick={(id, val) => {
                      setselect2({ id, name: val });
                    }}
                    data={[
                      { id: 12, name: "Jane Cooper" },
                      { id: 112, name: "Mike Cooper" },
                      { id: 124, name: "Andrew Cooper" },
                      { id: 125, name: "Thomas Cooper" },
                      { id: 126, name: "Methew Cooper" },
                      { id: 127, name: "Denis Cooper" },
                      { id: 128, name: "Daniel Cooper" },
                    ]}
                  />
                </div>
              </Col>
            </Row>
            <br />
            <br />
            <p>3.2 input/select/flexible/small</p>
            <Row>
              <Col xs={4}>
                <div style={{ width: "200px" }}>
                  <Dropdown
                    fieldId={"id"}
                    fieldValue={"category"}
                    selected={select1}
                    placeholder="Choose..."
                    //  showid={true}
                    onClick={(id, val) => {
                      setselect1({ id, category: val });
                    }}
                    data={[
                      { id: 12, category: "Category 1" },
                      { id: 112, category: "Category 2" },
                      { id: 124, category: "Category 3" },
                    ]}
                  />
                </div>
              </Col>
            </Row>
            <br />
            <br />
            <p>3.2 input/select/multiple</p>
            <Row>
              <Col xs={4}>
                <div style={{ width: "500px" }}>
                  <DropdownMulti
                    fieldId={"id"}
                    fieldValue={"name"}
                    selected={select3}
                    placeholder="Choose..."
                    onClick={(id, val) => {
                      const selectedValues = [...select3];
                      if (
                        selectedValues.findIndex((object) => {
                          return object.id === id;
                        }) == -1
                      ) {
                        selectedValues.push({ id, name: val });
                      } else {
                        selectedValues.splice(
                          selectedValues.findIndex((object) => {
                            return object.id === id;
                          }),
                          1
                        );
                      }
                      setselect3(selectedValues);
                    }}
                    data={[
                      { id: 12, name: "Jane Cooper" },
                      { id: 112, name: "Mike Cooper" },
                      { id: 124, name: "Andrew Cooper" },
                      { id: 125, name: "Thomas Cooper" },
                      { id: 126, name: "Methew Cooper" },
                      { id: 127, name: "Denis Cooper" },
                      { id: 128, name: "Daniel Cooper" },
                    ]}
                  />
                </div>
              </Col>
            </Row>
            <br />
            <br />
            <p>Destructive Button</p>
            <Row>
              <Col xs={2}>
                <div style={{ width: "500px" }}>
                  <SwitchButton
                    id={"switch_toggle_button"}
                    label={"Enable / Disable"}
                    value={switchValOne}
                    onChange={(val) => setSwitchValOne(val)}
                  />
                </div>
              </Col>
              <Col xs={4}>
                <div style={{ width: "500px" }}>
                  <SwitchButton
                    id={"switch_toggle_two_button"}
                    label={"Option 1"}
                    labeltwo={"Option 2"}
                    value={switchVal}
                    onChange={(val) => setSwitchVal(val)}
                  />
                </div>
              </Col>
            </Row>
            <br />
            <br />
            <h3>Collpsable Table</h3>
            <Row>
              <Col xs={12}>
                <CollapseTable>
                  <Table className="w-100">
                    <thead>
                      <tr>
                        <th>Monitored type</th>
                        <th>User</th>
                        <th>Engineer</th>
                        <th>Date found</th>
                        <th>Status</th>
                        <th>Pii hit</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Domain</td>
                        <td>
                          mark@mark`s{" "}
                          <span className="clrgrey ml-3">MacBook Pro</span>
                        </td>
                        <td className="font14">
                          <img
                            alt=""
                            className="profile_image"
                            src="https://hguksouthinboundreports.blob.core.windows.net/customer-objid-2390644/thumbnail-user-profile-image-usersettings-objid-2896813?sv=2018-03-28&amp;sr=c&amp;sig=E1hAE%2B0rPwsqSUw%2FhF07i9v6dztCtoQEMCn9nBeQfzE%3D&amp;se=2022-05-14T11%3A01%3A41Z&amp;sp=r"
                          />
                          Wade Warren
                        </td>
                        <td>11/25/2019</td>
                        <td className="clrgreen">Open</td>
                        <td>2</td>
                        <td>
                          <Button
                            className="mr-4 secondary-button flex icon"
                            onClick={() => console.log("secondary-button flex")}
                            image={"/images/theme/icons/upload.svg"}
                            hoverImage={"/images/theme/hovericons/upload.svg"}
                            icon={true}
                          >
                            Download
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td>Domain</td>
                        <td>
                          mark@mark`s{" "}
                          <span className="clrgrey ml-3">MacBook Pro</span>
                        </td>
                        <td className="font14">
                          <img
                            alt=""
                            className="profile_image"
                            src="https://hguksouthinboundreports.blob.core.windows.net/customer-objid-2390644/thumbnail-user-profile-image-usersettings-objid-2896813?sv=2018-03-28&amp;sr=c&amp;sig=E1hAE%2B0rPwsqSUw%2FhF07i9v6dztCtoQEMCn9nBeQfzE%3D&amp;se=2022-05-14T11%3A01%3A41Z&amp;sp=r"
                          />
                          Wade Warren
                        </td>
                        <td>11/25/2019</td>
                        <td className="clrgreen">Open</td>
                        <td>2</td>
                        <td>
                          <Button
                            className="mr-4 secondary-button flex icon"
                            onClick={() => console.log("secondary-button flex")}
                            image={"/images/theme/icons/upload.svg"}
                            hoverImage={"/images/theme/hovericons/upload.svg"}
                            icon={true}
                          >
                            Download
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td>Domain</td>
                        <td>
                          mark@mark`s{" "}
                          <span className="clrgrey ml-3">MacBook Pro</span>
                        </td>
                        <td className="font14">
                          <img
                            alt=""
                            className="profile_image"
                            src="https://hguksouthinboundreports.blob.core.windows.net/customer-objid-2390644/thumbnail-user-profile-image-usersettings-objid-2896813?sv=2018-03-28&amp;sr=c&amp;sig=E1hAE%2B0rPwsqSUw%2FhF07i9v6dztCtoQEMCn9nBeQfzE%3D&amp;se=2022-05-14T11%3A01%3A41Z&amp;sp=r"
                          />
                          Wade Warren
                        </td>
                        <td>11/25/2019</td>
                        <td className="clrgreen">Open</td>
                        <td>2</td>
                        <td>
                          <Button
                            className="mr-4 secondary-button flex icon"
                            onClick={() => console.log("secondary-button flex")}
                            image={"/images/theme/icons/upload.svg"}
                            hoverImage={"/images/theme/hovericons/upload.svg"}
                            icon={true}
                          >
                            Download
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td>Domain</td>
                        <td>
                          mark@mark`s{" "}
                          <span className="clrgrey ml-3">MacBook Pro</span>
                        </td>
                        <td className="font14">
                          <img
                            alt=""
                            className="profile_image"
                            src="https://hguksouthinboundreports.blob.core.windows.net/customer-objid-2390644/thumbnail-user-profile-image-usersettings-objid-2896813?sv=2018-03-28&amp;sr=c&amp;sig=E1hAE%2B0rPwsqSUw%2FhF07i9v6dztCtoQEMCn9nBeQfzE%3D&amp;se=2022-05-14T11%3A01%3A41Z&amp;sp=r"
                          />
                          Wade Warren
                        </td>
                        <td>11/25/2019</td>
                        <td className="clrgreen">Open</td>
                        <td>2</td>
                        <td>
                          <Button
                            className="mr-4 secondary-button flex icon"
                            onClick={() => console.log("secondary-button flex")}
                            image={"/images/theme/icons/upload.svg"}
                            hoverImage={"/images/theme/hovericons/upload.svg"}
                            icon={true}
                          >
                            Download
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CollapseTable>
              </Col>
            </Row>
            {/* labelled Input */}
            <br />
            <br />
            <p>Labelled Inputs with Buttons</p>
            <Row>
              <Col xs={12}>
                <LabelledInput
                  PrefixComponent={<PrefixComponent />}
                  // SuffixComponent={<SuffixComponent />}
                  placeholder="Placeholder"
                  error={true}
                  // disabled={true}
                />
              </Col>
            </Row>
            <br />
            <br />
            <p>Labelled Input Password</p>
            <Row>
              <Col xs={12}>
                <LabelledInputPassword
                  PrefixComponent={<PrefixPasswordInput />}
                  // SuffixComponent={<SuffixComponent />}
                  placeholder="Password"
                  // error={true}
                  // disabled={true}
                />
              </Col>
            </Row>
            <br />
            <br />
            <p>Labelled Input Number</p>
            <Row>
              <Col xs={12}>
                <LabelledInputNumber
                  PrefixComponent={<PrefixComponent />}
                  // SuffixComponent={<SuffixComponent />}
                  placeholder="Placeholder"
                  // error={true}
                  // disabled={true}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default PersonalProfile;
