import React from "react";
import "./supplyComplianceManager.scss";

const InfoSecTable = ({ data, goTo, accessLevel }) => {
  return (
    <div className="infosec-table hg-border radius-16 p-3 w-100">
      <div className="grid-parent pb-1 pt-3 px-2">
        <div className="f-12 f-500 f-blue pl-3">
          Policy Name{" "}
          <img
            alt=""
            className="ml-2"
            src="/images/supply-chain/blue-chevron-down.svg"
          />{" "}
        </div>
        <div className="f-12 f-500 f-darkgrey">Revision</div>
        <div className="f-12 f-500 f-darkgrey">Last Reviewed</div>
        <div className="f-12 f-500 f-darkgrey">Reviewed By</div>
        <div className="f-12 f-500 f-darkgrey">Status</div>
        <div className="f-12 f-500 f-darkgrey">Classification</div>
        <div className="f-12 f-500 f-darkgrey">Approved By</div>
        <div></div>
      </div>
      <hr style={{ borderTop: "1px solid #EBEDF1" }} />
      {data?.map((item, index) => {
        return (
          <div
            className="grid-parent grey-background p-4 pointer"
            onClick={() => {
              // goTo(item?.Col3, "CMQuestion", {
              //   pageEnum: 408,
              //   accessLevel: accessLevel,
              //   entityId: item?.EntityId,
              // });
            }}
          >
            <div>{item?.Col1} </div>
            <div>{item?.Col2}</div>
            <div>{item?.Col3}</div>
            <div>{item?.Col4}</div>
            <div className="f-warnin g">{item?.Col5}</div>
            <div className="d-flex">{item?.Col6}</div>
            <div className="d-flex">{item?.Col7}</div>
            <div className="d-flex align-items-center justify-content-end">
              <img
                alt=""
                className="mr-3 pointer"
                src="/images/supply-chain/cloud-print.svg"
              />
              {/* <img
                alt=""
                className="mr-3 pointer"
                src="/images/horizontal-dots.svg"
              /> */}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default InfoSecTable;
