import React, { useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ImageWithInitials from "../../Utils/ImageWithInitials";
import { TrackUser } from "../../Utils/SegmentFunctions";
import { TrimString } from "../../Utils/StringTrimmer";
import { useAuth } from "../context/Auth";
import "./insights.scss";
import "../Header/header-v2.scss";
import ModalDynamic from "../ModalDynamic/ModalDynamic";
import IncidentResponseForm from "../IncidentResponseForm/IncidentResponseForm";
import SupportTicketV2 from "../settingsV2/supportTicketV2/SupportTicketV2";

const InsightsBodyHeader = () => {
  const history = useHistory();
  const { setAuth } = useAuth();
  const [supportTicketV2Modal, setSupportTicketV2Modal] = useState(false);

  const [securityIncidentModal, setsecurityIncidentModal] = useState(false);
  const userDetails = useSelector((state) => state?.user?.userDetail);
  const [selectedEnviornment, setSelectedEnviornment] = useState("");
  return (
    <div className="w-100 insights-body-header border-bottom">
      <div className="w-100 py-2 pl-4 d-flex align-items-center">
        <img
          alt=""
          src="/images/attack-surface/insights-env-icon.svg"
          className="mr-2"
        />
        {/* header input + side dropdown */}
        <div className="d-flex align-items-center radius-2 grey-border w-100 p-1">
          <div className="border-right mr-2">
            <Dropdown className="insights-env-dropdown">
              <Dropdown.Toggle>
                <div className="d-flex align-items-center">
                  <div className="f-12 f-500 f-grey">No environment</div>
                  <img
                    alt=""
                    className="ml-2"
                    src="/images/attack-surface/insights-chevron-down.svg"
                  />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="f-12 f-darkgrey ml-3">Choose environment</div>
                <div
                  className="f-500 f-grey pl-2 pointer py-1 drop-item"
                  onClick={(e) => {
                    e.target.closest(".insights-env-dropdown").click();
                  }}
                >
                  <img
                    alt=""
                    className="mr-2"
                    src="/images/attack-surface/insights-tick.svg"
                  />
                  Staging
                </div>
                <div
                  className="f-500 f-grey pl-2 pointer  py-1 drop-item"
                  onClick={(e) => {
                    e.target.closest(".insights-env-dropdown").click();
                  }}
                >
                  <img
                    alt=""
                    className="mr-2"
                    src="/images/attack-surface/insights-tick.svg"
                  />
                  Production
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div style={{ width: "inherit" }}>
            <input
              type="text"
              placeholder="Search.."
              className="border-none bg-transparent w-100"
            />
          </div>
        </div>

        {/* Right side header content */}
        {/* Build/ Production/ Deploy options */}
        <div className="d-flex align-items-center">
          <Button className="hg-cancel-btn w-max-content mx-2">
            Return to Build
          </Button>
          <Button className="hg-blue-btn d-flex">
            <img
              alt=""
              src="/images/attack-surface/insights-white-thunder.svg"
              className="mx-1"
            />{" "}
            Publish
          </Button>
        </div>

        {/* Save text and icon */}
        <div className="p-1 border-right" style={{ height: "17px" }}></div>
        <div className="d-flex align-items-center pointer f-grey mx-2 f-500  mx-3">
          <img
            alt=""
            src="/images/attack-surface/insights-save-icon.svg"
            className="mr-2"
          />
          Save
        </div>

        {/* Edit icon */}
        <div className="d-flex align-items-center pointer ml-2">
          <img
            src="/images/attack-surface/insights-edit-pencil.svg"
            alt=""
            className=""
          />
        </div>

        {/* Dropdown menu for options */}
        <div className="header-v2">
          <div
            style={{ marginLeft: "0.3em", marginTop: "0.3em" }}
            className="sideBarDropdown supportDropdown"
          >
            <Dropdown
              onToggle={(isOpen) => {
                if (isOpen) {
                  setsecurityIncidentModal(false);
                }
              }}
            >
              <Dropdown.Toggle className="test-userflow">
                <img alt="" src="/images/settings-v2/horizontal-dots.svg" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  href="https://kb.highground.io"
                  target="_blank"
                  onClick={() => TrackUser("Knowledge Base clicked")}
                >
                  <img
                    alt=""
                    src="/images/settings-v2/knowledgebase.svg"
                    className="mr-2 v2-item-color"
                    width={16}
                    height={17}
                  />{" "}
                  <span className="f-grey-imp">Knowledgebase</span>
                </Dropdown.Item>
                <Dropdown.Item
                  type="button"
                  onClick={(event) => {
                    TrackUser("Support Ticket Clicked");
                    // setSupportTicketV2Modal(true);
                    window.Intercom('showNewMessage');
                  }}
                >
                  <img
                    alt=""
                    src="/images/settings-v2/contactsupport.svg"
                    className="mr-2 v2-item-color"
                    width={16}
                    height={17}
                  />{" "}
                  <span className="f-grey-imp">Contact Support</span>
                </Dropdown.Item>
                <Dropdown.Item
                  type="button"
                  onClick={(event) => {
                    // setSupportState(event);
                    // setSupportCounter(supportCounter + 1);
                    TrackUser("Join Slack community clicked");
                    // window.open(
                    //   "https://join.slack.com/t/highgroundio/shared_invite/zt-19tfj8ujn-_rHexr3mFsH~aI~q7Y47Sg",
                    //   "_blank"
                    // );
                    window.open(
                      "https://chat.whatsapp.com/C0W3c8N0owfCQeof5auWjG",
                      "_blank"
                    );
                  }}
                >
                  <img
                    alt=""
                    src="/images/settings-v2/askcommunity.svg"
                    className="mr-2 v2-item-color"
                    width={16}
                    height={17}
                  />{" "}
                  <span className="f-grey-imp"> Join our MSP Community</span>
                </Dropdown.Item>
                {/* <hr className="mb-1 m-0" />
                <Dropdown.Item onClick={() => setsecurityIncidentModal(true)}>
                  <img
                    alt=""
                    src="/images/settings-v2/securityincident.svg"
                    className="mr-2 security-incident"
                    width={16}
                    height={17}
                  />
                  <span
                    className="f-grey-imp"
                    style={{
                      marginLeft: "3px",
                    }}
                  >
                    Security Incident?
                  </span>
                </Dropdown.Item> */}
                <hr className="mt-1 m-0 mb-1" />
                <Dropdown.Item
                  className=""
                  onClick={() => history.push("/settings/v2/company-settings")}
                >
                  <img
                    alt=""
                    src="/images/settings-v2/settingwheel.svg"
                    className="mr-2 v2-item-color"
                    width={16}
                    height={17}
                  />
                  <span
                    className="f-grey-imp"
                    style={{
                      marginLeft: "3px",
                    }}
                  >
                    System Settings
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        {/* User Logo and dropdown */}
        <div className="header-v2">
          <div
            style={{ marginLeft: "" }}
            className="sideBarDropdown supportDropdown  header-logo-dropdown"
          >
            <Dropdown>
              <Dropdown.Toggle>
                {userDetails.Avatar == "No URL" ? (
                  <ImageWithInitials
                    width={31}
                    background="purple"
                    initials={userDetails?.Initials}
                  />
                ) : (
                  <img
                    alt=""
                    src={userDetails?.Avatar}
                    className="thumbnail-pic"
                  />
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item disabled={true}>
                  <div className="d-flex justify-content-between align-items-center ">
                    <div className="d-flex align-items-center">
                      {userDetails.Avatar == "No URL" ? (
                        <ImageWithInitials
                          width={31}
                          background="purple"
                          initials={userDetails?.Initials}
                        />
                      ) : (
                        <img
                          alt=""
                          src={userDetails?.Avatar}
                          className="thumbnail-pic"
                        />
                      )}

                      <div className="ml-2">
                        <p className="title m-0">
                          {TrimString(userDetails.FullName, 17)}
                        </p>
                        <p className="subtitle m-0">
                          {TrimString(userDetails.UserName, 17)}
                        </p>
                      </div>
                    </div>
                    <div>
                      {/* <img alt="" src="/images/chevron-right.svg" /> */}
                    </div>
                  </div>
                </Dropdown.Item>
                <hr className="m-0" />
                <Dropdown.Item
                  type="button"
                  className="mt-1 mb-1"
                  onClick={() => history.push("/settings/v2/my_profile")}
                >
                  <img
                    alt=""
                    src="/images/settings-v2/settingwheel.svg"
                    className="mr-2 v2-item-color"
                  />
                  <span className="f-grey-imp" style={{ paddingTop: "2px" }}>
                    {" "}
                    Personal Settings
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  type="button"
                  onClick={() => {
                    TrackUser("User Signed out");

                    window.localStorage.clear();
                    history.push({
                      pathname: "/login",
                    });
                    setAuth(false);
                  }}
                >
                  <img
                    alt=""
                    src="/images/settings-v2/logout.svg"
                    className="mr-2 v2-item-color"
                  />
                  <span className="f-grey-imp"> Logout</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      {securityIncidentModal ? (
        <ModalDynamic
          setShow={true}
          modalData={<IncidentResponseForm />}
          modalHeader={"Report an Incident"}
        />
      ) : (
        ""
      )}

      <SupportTicketV2
        show={supportTicketV2Modal}
        hideModal={() => setSupportTicketV2Modal(false)}
      />
    </div>
  );
};

export default InsightsBodyHeader;
