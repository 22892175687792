import React from "react";
import { Button, Col, Dropdown, Nav, Row, Tab } from "react-bootstrap";
import Header from "../Header";
import { useSelector } from "react-redux";
import AttackSurfaceTooltip from "../AttackSurface/AssetsList/AttackSurfaceTooltip";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ExploreMappingModal from "./ExploreMappingModal";
import { useState } from "react";
import moment from "moment";
import "./assessment-styling.scss";
const SecurityAssessments = () => {
  const [defaultSelectedTab, setDefaultSelectedTab] = useState("myassessments");

  const [selectedTab, setSelectedTab] = useState("");
  const userDetails = useSelector((state) => state.user?.userDetail);
  const isMenuCollapse = useSelector((state) => state?.global?.menuCollapse);

  const [showExploreMappingModal, setShowExploreMappingModal] = useState(false);

  const history = useHistory();
  return (
    <Row
      className="security-assessment-wrapper"
      style={{ height: "max-content" }}
    >
      <Col xs={12}>
        <div>
          <header
            className="d-flex justify-content-between navbar sticky-top pb-0"
            style={{
              paddingTop: ` ${
                userDetails?.AccountVerified === false ||
                userDetails?.ShowConfirmVerified === true
                  ? "3em"
                  : "0.8em"
              } `,
              background: "#FFFFFF",
              position: "fixed",
              width: `${isMenuCollapse ? "92.25%" : "83.25%"}`,
              zIndex: 6,
              // marginLeft: "-0.9rem",
              paddingLeft: "2rem",
              // borderBottom: `1px solid #EBEDF1`,
              paddingBottom: "8px",
            }}
          >
            <h3 className="f-16 f-600">Security Assessments</h3>

            <Header />
          </header>
        </div>

        <div style={{ paddingTop: "5%" }}></div>

        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey={defaultSelectedTab}
        >
          <Row>
            <Col
              sm={12}
              style={{
                height: "51px",
              }}
              className={`tab-header border-bottom justify-content-between align-items-end  ${"d-flex"}  `}
            >
              {/* Home screen left part */}
              <div style={{ marginLeft: "15px" }}>
                <Nav
                  variant="pills"
                  className="flex-row"
                  onSelect={(e) => {
                    setSelectedTab(e);
                  }}
                >
                  <Nav.Item>
                    <Nav.Link eventKey="myassessments" onClick={() => {}}>
                      My Assessments
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item id="">
                    <Nav.Link eventKey="received" onClick={() => {}}>
                      Received
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>

              {/* Right side items at top bar */}
              <div className="d-flex align-items-center p-1">
                {selectedTab === "received" && (
                  <>
                    <div className="d-flex align-items-center radius-4 bg-grey highlight-input p-2 pr-5">
                      <img
                        src="/images/attack-surface/search-icon.svg"
                        alt=""
                        className="m"
                      />

                      <input
                        type="text"
                        placeholder="Search.."
                        className="border-none bg-transparent pl-1"
                      />
                    </div>

                    <Button className="hg-cancel-btn ml-2">Filter</Button>
                  </>
                )}
              </div>
            </Col>
            <Col sm={12}>
              <Tab.Content>
                {/* Suppliers */}
                <Tab.Pane eventKey="myassessments">
                  <div className="main-wrapper px-5">
                    <div
                      className="bg-grey radius-8 mb-4 mt-3"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                      }}
                    >
                      <div
                        className="border-right p-3"
                        onClick={() => {
                          setShowExploreMappingModal(true);
                        }}
                      >
                        <div className="d-flex align-items-center f-500 f-darkgrey mb-3">
                          <img
                            src="/images/security-assessment/note-icon.svg"
                            alt=""
                            className="mr-2"
                          />
                          Perform Security Self Assessments
                        </div>
                        <div className="f-18 f-500 mb-2">
                          Self assess and align to the most <br />
                          common security frameworks
                        </div>

                        <div className="f-grey f-500 d-flex align-items-center">
                          Learn more
                          <img
                            src="/images/supply-chain/redirect-icon.svg"
                            alt=""
                            className="ml-2"
                          />
                        </div>
                      </div>
                      <div className="border-right p-3">
                        <div className="d-flex align-items-center f-500 f-purple mb-3">
                          <img
                            src="/images/security-assessment/blue-refresh.svg"
                            alt=""
                            className="mr-2"
                          />
                          Similar answers
                        </div>
                        <div className="f-18 f-500 mb-2">
                          Save time and reduce effort by using <br /> similar
                          answers across frameworks
                        </div>

                        <div className="f-grey f-500 d-flex align-items-center">
                          Learn more
                          <img
                            src="/images/supply-chain/redirect-icon.svg"
                            alt=""
                            className="ml-2"
                          />
                        </div>
                      </div>
                      <div className="p-3">
                        <div className="d-flex align-items-center f-500 f-darkgrey mb-3">
                          <img
                            src="/images/security-assessment/tree-icon.svg"
                            alt=""
                            className="mr-2"
                          />
                          Framework Control Mappings
                        </div>

                        <div className="border1 radius-4 w-100">
                          <div className="border-bottom d-flex">
                            <input
                              type="text"
                              placeholder="Choose framework..."
                              className="border-none bg-transparent pl-1 w-100 m-2 flex-1"
                            />
                          </div>
                          <div className="border-bottom d-flex">
                            <input
                              type="text"
                              placeholder="Choose framework..."
                              className="border-none bg-transparent pl-1 w-100 m-2 flex-1"
                            />
                          </div>
                          <div className="d-flex">
                            <Button className="hg-blue-btn flex-1 m-2">
                              Find
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="bg-grey radius-8"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                      }}
                      onClick={() => {
                        history.push("/assessment-frameworks");
                      }}
                    >
                      {[1, 2, 3, 4, 5, 6].map((item, itemIndex) => {
                        return (
                          <div
                            className={`p-3
              ${showRightBorder(itemIndex) ? "border-right" : ""}
              ${showBottomBorder(itemIndex, 6) ? "border-bottom" : ""}
              
              `}
                          >
                            <div className="f-16 f-500 mb-2">
                              NIST Cybersecurity Framework
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="f-12 f-grey">Last audit</div>
                              <div className="f-12 f-darkgrey">22 Jul 2024</div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-1">
                              <div className="f-12 f-grey">Next audit</div>
                              <div className="f-12 f-danger">
                                <img
                                  src="/images/msp/secops_expired.svg"
                                  alt=""
                                  width={11}
                                  className="mr-2"
                                />
                                22 Sep 2022
                              </div>
                            </div>
                            <div className="w-100 d-flex align-items-center">
                              <AttackSurfaceTooltip
                                toolTipStyle={{
                                  top: "16px",
                                }}
                                className={"w-100"}
                                content={
                                  <div>
                                    <div className="d-flex align-items-start justify-content-between">
                                      <div
                                        className="d-flex align-items-start  "
                                        style={{
                                          color: "#FFFFFFA3",
                                        }}
                                      >
                                        <div
                                          className="rounded-full mr-1 mt-1"
                                          style={{
                                            width: "10px",
                                            height: "10px",
                                            background: "#008FD4",
                                          }}
                                        />
                                        Completed
                                      </div>
                                      <div className="f-white">54%</div>
                                    </div>
                                    <div className="d-flex align-items-start justify-content-between">
                                      <div
                                        className="d-flex align-items-start  "
                                        style={{
                                          color: "#FFFFFFA3",
                                        }}
                                      >
                                        <div
                                          className="rounded-full mr-1 mt-1"
                                          style={{
                                            width: "10px",
                                            height: "10px",
                                            background: "#5244B3",
                                          }}
                                        />
                                        Avaialble similar answers
                                      </div>
                                      <div className="f-white">+10%</div>
                                    </div>
                                  </div>
                                }
                              >
                                <DualProgressBar barOne={54} barTwo={10} />
                              </AttackSurfaceTooltip>

                              <div className="f-darkgrey mx-2">54%</div>
                              <div className="f-purple">+21%</div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Tab.Pane>

                {/* Clients */}

                <Tab.Pane eventKey="received">
                  <div className="p-5">
                    <div className="bg-grey radius-8 border-parent profiler-table">
                      <div
                        className="  p-2 border-item"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "60% 15% 15% 10%",
                        }}
                      >
                        <div className="pl-4">Title</div>
                        <div>Client</div>
                        <div>Sent</div>

                        <div></div>
                      </div>

                      {[1, 2, 3, 4, 5, 6].map(() => {
                        return (
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "60% 15% 15% 10%",
                            }}
                            className=" p-2 border-item bg-hover-dark-grey pointer hover-on-show-parent hg-progress-wrap"
                          >
                            {/* Title */}
                            <div className="d-flex align-items-center p-2">
                              <div className="supplier-popover-parent">
                                <img
                                  alt=""
                                  src={
                                    "/images/supply-chain/question-list-icon.svg"
                                  }
                                  className="mr-2"
                                />
                              </div>
                              <div>
                                <p className="m-0 f-14 f-500">
                                  {"Pablo Escobar"}
                                </p>
                                <div className="d-flex align-items-center">
                                  <div
                                    className="flex align-items-center"
                                    style={{ width: "182px" }}
                                  >
                                    <progress min="0" value={50} max="100" />
                                  </div>
                                  <div className="f-500 f-darkgrey ml-2">
                                    {50}%
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Client */}
                            <div className="f-grey d-flex align-items-center f-black f-500">
                              <img
                                alt=""
                                src={
                                  null ??
                                  "/images/settings-v2/highground-dp.svg"
                                }
                                width={24}
                                height={24}
                                className="mr-2 rounded-full"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src =
                                    "/images/settings-v2/highground-dp.svg";
                                }}
                              />
                              {"Gurlej Akhtar"}
                            </div>

                            {/* Sent */}
                            <div className="f-grey d-flex align-items-center f-black f-500">
                              {moment().format("DD/MM/YYYY HH:mm")}
                            </div>

                            <div className="d-flex align-items-center justify-content-center">
                              <div
                                className={`menu-dropdown w-fit-content  hover-on-show-child`}
                                onClick={(e) => e.stopPropagation()}
                              >
                                <Dropdown className="device-dropdown">
                                  <Dropdown.Toggle>
                                    <img
                                      alt=""
                                      src="/images/horizontal-dots.svg"
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <div>
                                      <Row>
                                        <Col xs={12} className=" pointer ">
                                          <div
                                            className="drop-item"
                                            onClick={(e) => {
                                              e.target
                                                .closest(".device-dropdown")
                                                .click();
                                            }}
                                          >
                                            Send reminder
                                          </div>
                                        </Col>
                                        <Col xs={12} className="  pointer ">
                                          <div
                                            className="drop-item"
                                            onClick={(e) => {
                                              e.target
                                                .closest(".device-dropdown")
                                                .click();
                                              // setShowQuestionnaireModal(true);
                                            }}
                                          >
                                            View {/* Edit */}
                                          </div>
                                        </Col>

                                        <Col xs={12} className=" pointer ">
                                          <div
                                            className="drop-item"
                                            onClick={(e) => {
                                              e.target
                                                .closest(".device-dropdown")
                                                .click();
                                            }}
                                          >
                                            Delete
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Col>

      <ExploreMappingModal
        show={showExploreMappingModal}
        hideModal={() => {
          setShowExploreMappingModal(false);
        }}
      />
    </Row>
  );
};

export default SecurityAssessments;

function showRightBorder(_index) {
  if ((_index + 1) % 3 === 0) {
    return false;
  } else {
    return true;
  }
}

function showBottomBorder(_index, length) {
  const row = Math.floor(_index / 3);
  const column = _index % 3;

  return !(row === Math.floor(length / 3) - 1) && (row === 0 || column === 0);
}

export const DualProgressBar = ({
  barOne = 30,
  barTwo = 50,
  barOneColor = "#008FD4",
  barTwoColor = "#5244B3",
}) => {
  return (
    <div
      className="d-flex align-items-center w-100 rounded-full pointer"
      style={{
        background: "#EBEDF1",
        height: "5px",
        overflow: "hidden",
      }}
    >
      <div
        className=""
        style={{
          width: `${barOne}%`,
          background: barOneColor,
          borderTopLeftRadius: "100px",
          borderBottomLeftRadius: "100px",
          height: "5px",
        }}
      ></div>
      {barTwo > 0 && (
        <div
          className=" "
          style={{
            width: `${barTwo}%`,
            background: barTwoColor,
            borderTopRightRadius: "100px",
            borderBottomRightRadius: "100px",
            height: "5px",
            marginLeft: "1px",
          }}
        ></div>
      )}
    </div>
  );
};
