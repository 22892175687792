import React, { useState, useEffect } from "react";
import {  Modal, Form,   } from "react-bootstrap";
import { getAPIData, postData,   } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import DropdownField from "../../Theme/dropdown2";
 
import Loader from "../../Common/loader";

const SupplyChainNotificationsModal = ({ show, hideModal }) => {
  const { authTokens } = useAuth();
  const [isVisible, setIsVisible] = useState(true);
  const [resetting, setResetting] = useState(false)

useEffect(() => {
  if(show){
setLoading(true)
    getNotificationSettings()
  }
   
}, [show])

async function handleSave(notifName, notifValue) {
  await postData(authTokens, "SubmitNotificationSettings", {
    SettingOwner: 0,
    OwnerType: 1,
    NotificationName: notifName,
    NotificationValue: notifValue,
  })
    .then((response) => {
      if (response?.Success) {
        CallToast("Updated successfully", false, false, "v2style");
      } else {
        CallToast(response?.Message, false, false, "v2style");
      }
    })
    .catch((err) => {});
}
const DropdownOptions = [
  { id: 0, category: "Disabled" },
  { id: 1, category: "Mobile App" },
  { id: 2, category: "Email" },
  { id: 3, category: "App and Email" },
];


  const [loading, setLoading] = useState(false);
  const [selected1, setSelected1] = useState({
    id: 0,
    category: "Disabled",
  });
  const [selected2, setSelected2] = useState({
    id: 0,
    category: "Disabled",
  });
  const [selected3, setSelected3] = useState({
    id: 0,
    category: "Disabled",
  });
  const [selected4, setSelected4] = useState({
    id: 0,
    category: "Disabled",
  });
  const [selected5, setSelected5] = useState({
    id: 0,
    category: "Disabled",
  });
  const [selected6, setSelected6] = useState({
    id: 0,
    category: "Disabled",
  });
  const [selected7, setSelected7] = useState({
    id: 0,
    category: "Disabled",
  });
 

  async function getNotificationSettings() {
    await getAPIData(103, authTokens).then((response) => {
      setLoading(false);
      if (response?.mr?.Success){
        setIsVisible(response?.NotificationSettings?.SCMNotification);
        setSelected1(
          DropdownOptions.filter(
            (item) =>
              item.id ===
              response?.NotificationSettings?.SCMNewPotentialSupplier
          )[0]
        );
        setSelected2(
          DropdownOptions.filter(
            (item) =>
              item.id ===
              response?.NotificationSettings?.SCMSupplierRequest
          )[0]
        );
        setSelected3(
          DropdownOptions.filter(
            (item) =>
              item.id ===
              response?.NotificationSettings?.SCMClientSuspended
          )[0]
        );
        setSelected4(
          DropdownOptions.filter(
            (item) =>
              item.id ===
              response?.NotificationSettings?.SCMSupplierSuspended
          )[0]
        );
        setSelected5(
          DropdownOptions.filter(
            (item) =>
              item.id ===
              response?.NotificationSettings?.SCMComplianceProfileHealth
          )[0]
        );
        setSelected6(
          DropdownOptions.filter(
            (item) =>
              item.id ===
              response?.NotificationSettings?.SCMChatMessage
          )[0]
        );
        setSelected7(
          DropdownOptions.filter(
            (item) =>
              item.id ===
              response?.NotificationSettings?.SCMQuestionnaireComments
          )[0]
        );

      }else{


        CallToast(response?.mr?.Message, false, false, "v2style");



      }})}

      function ResetUserNotificationSettings() {
        setResetting(true)

        getAPIData("266", authTokens, ).then((response) => {
          setResetting(false)
          if (response?.Success) {
            getNotificationSettings();
            
          } else {
            setResetting(false)
            CallToast(response?.Message, false, false, "v2style");
          }
        });




        // getAPIData("199", authTokens, { optionEnum1: num }).then((response) => {
        //   if (response.Success) {
        //     getNotificationSettings();
            
        //   } else {
        //     CallToast("Something went wrong", false, false, "v2style");
        //   }
        // });
      }
    
  return (
    <Modal
      // show={true}
      show={show}
      dialogClassName="cyberscore-notification-modal"
      className=" "
      aria-labelledby="cyberscore-notification-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      {" "}
      <Modal.Header className="text-center ">
        {false && (
          <span
            className="cursor-pointer"
            onClick={() => {
              //   setPage("home");
            }}
          >
            <img src="/images/leftarrow.png" alt="" />
          </span>
        )}

        <div className="w-100 mb-0 font-bold-14" style={{ fontWeight: "600" }}>
          Supply Chain Monitoring Notifications
        </div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img src="/images/icons/close.svg" alt="" />
        </span>
      </Modal.Header>
      <Modal.Body className="p-4 hide-scrollbar">
        {loading && (
          <div
            style={{
              position: "absolute",
              top: "40%",
              left: 0,
              right: 0,
              margin: "auto",
            }}
          >
            <Loader />
          </div>
        )}
        {!loading && (
          <>
            <div className="title">
              Choose how you would like to receive notifications from your
              supply chain monitoring
            </div>
            <div className="d-flex justify-content-between mt-3 mb-2">
              <div>Allow notification from supply chain monitoring</div>
              <div>
                <Form.Group
                  controlId={"cyberscore-notification-check"}
                  className=""
                >
                  <Form.Check
                    type="switch"
                    id="cyberscore-notification-toggle"
                    className="m-0 scale-switch switch-shadow-none"
                    checked={isVisible}
                    onChange={(e) => {
                      setIsVisible(e.target.checked);
                      handleSave("SCMNotification", Number(e.target.checked));
                    }}
                  />
                </Form.Group>
              </div>
            </div>

            {/* dropdowns values */}
            {isVisible && (
              <>
                <div className="d-flex justify-content-between mt-3 mb-2">
                  <div className="title">New potential Suppliers</div>
                  <div className="score-modal-dropdown">
                    <DropdownField
                      fieldId={"id"}
                      fieldValue={"category"}
                      selected={selected1}
                      placeholder="App and Email"
                      //  showid={true}
                      onClick={(id, val) => {
                        handleSave("SCMNewPotentialSupplier", id);

                        setSelected1({ id, category: val });
                      }}
                      data={[
                        { id: 0, category: "Disabled" },
                        { id: 1, category: "Mobile App" },
                        { id: 2, category: "Email" },
                        { id: 3, category: "App and Email" },
                      ]}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-3 mb-2">
                  <div className="title">New Supplier Requests</div>
                  <div className="score-modal-dropdown">
                    <DropdownField
                      fieldId={"id"}
                      fieldValue={"category"}
                      selected={selected2}
                      placeholder="App and Email"
                      //  showid={true}
                      onClick={(id, val) => {
                        handleSave("SCMSupplierRequest", id);

                        setSelected2({ id, category: val });
                      }}
                      data={[
                        { id: 0, category: "Disabled" },
                        { id: 1, category: "Mobile App" },
                        { id: 2, category: "Email" },
                        { id: 3, category: "App and Email" },
                      ]}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-3 mb-2">
                  <div className="title">Client Suspend / Remove Actions</div>
                  <div className="score-modal-dropdown">
                    <DropdownField
                      fieldId={"id"}
                      fieldValue={"category"}
                      selected={selected3}
                      placeholder="App and Email"
                      //  showid={true}
                      onClick={(id, val) => {
                        handleSave("SCMClientSuspended", id);

                        setSelected3({ id, category: val });
                      }}
                      data={[
                        { id: 0, category: "Disabled" },
                        { id: 1, category: "Mobile App" },
                        { id: 2, category: "Email" },
                        { id: 3, category: "App and Email" },
                      ]}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-3 mb-2">
                  <div className="title">Supplier Suspend / Remove Actions</div>
                  <div className="score-modal-dropdown">
                    <DropdownField
                      fieldId={"id"}
                      fieldValue={"category"}
                      selected={selected4}
                      placeholder="App and Email"
                      //  showid={true}
                      onClick={(id, val) => {
                        handleSave("SCMSupplierSuspended", id);

                        setSelected4({ id, category: val });
                      }}
                      data={[
                        { id: 0, category: "Disabled" },
                        { id: 1, category: "Mobile App" },
                        { id: 2, category: "Email" },
                        { id: 3, category: "App and Email" },
                      ]}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-3 mb-2">
                  <div className="title">Compliance Profile Health</div>
                  <div className="score-modal-dropdown">
                    <DropdownField
                      fieldId={"id"}
                      fieldValue={"category"}
                      selected={selected5}
                      placeholder="App and Email"
                      //  showid={true}
                      onClick={(id, val) => {
                        handleSave("SCMComplianceProfileHealth", id);

                        setSelected5({ id, category: val });
                      }}
                      data={[
                        { id: 0, category: "Disabled" },
                        { id: 1, category: "Mobile App" },
                        { id: 2, category: "Email" },
                        { id: 3, category: "App and Email" },
                      ]}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-3 mb-2">
                  <div className="title">Chat Messages</div>
                  <div className="score-modal-dropdown">
                    <DropdownField
                      fieldId={"id"}
                      fieldValue={"category"}
                      selected={selected6}
                      placeholder="App and Email"
                      //  showid={true}
                      onClick={(id, val) => {
                        handleSave("SCMChatMessage", id);

                        setSelected6({ id, category: val });
                      }}
                      data={[
                        { id: 0, category: "Disabled" },
                        { id: 1, category: "Mobile App" },
                        { id: 2, category: "Email" },
                        { id: 3, category: "App and Email" },
                      ]}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-3 mb-2">
                  <div className="title">Questionnaire comments</div>
                  <div className="score-modal-dropdown">
                    <DropdownField
                      fieldId={"id"}
                      fieldValue={"category"}
                      selected={selected7}
                      placeholder="App and Email"
                      //  showid={true}
                      onClick={(id, val) => {
                        handleSave("SCMQuestionnaireComments", id);

                        setSelected7({ id, category: val });
                      }}
                      data={[
                        { id: 0, category: "Disabled" },
                        { id: 1, category: "Mobile App" },
                        { id: 2, category: "Email" },
                        { id: 3, category: "App and Email" },
                      ]}
                    />
                  </div>
                </div>

                <div className="p-5" />
                <div className="p-3" />
              </>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between align-items-center">
          {true && (
            <div
              className="reset-to-default pointer"
              onClick={() => {
                ResetUserNotificationSettings();
              }}
            >
              <img alt="" src="/images/refresh.png" className="mr-2" />
              {resetting ? <span>Resetting..</span> : <span>Reset</span>}
            </div>
          )}
          <div>
            {/* <Button className="cancel-btn  " onClick={hideModal}>
              Cancel
            </Button>
            <Button className="update-btn " onClick={hideModal}>
              Update
            </Button> */}
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SupplyChainNotificationsModal;
