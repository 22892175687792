import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Form, Button } from "react-bootstrap";
import { getAPIData, postData, postRequest } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import DropdownField from "../../Theme/dropdown2";
// import CustomSlider from "./CustomSlider";
import Loader from "../../Common/loader";

const AttackSurfaceNotificationsModal = ({show,hideModal}) => {
    const [loading, setLoading] = useState(false)
    const [selected1, setSelected1] = useState({
        id: 5,
        category: "Disabled",
      });
      const [selected2, setSelected2] = useState({
        id: 5,
        category: "Disabled",
      });
  return (
    <Modal
      // show={true}
      show={show}
      dialogClassName="cyberscore-notification-modal"
      className=" "
      aria-labelledby="cyberscore-notification-modal"
      centered
      scrollable
      onHide={hideModal}
    > <Modal.Header className="text-center ">
    {false && (
      <span
        className="cursor-pointer"
        onClick={() => {
        //   setPage("home");
        }}
      >
        <img src="/images/leftarrow.png" alt="" />
      </span>
    )}

    <div className="w-100 mb-0 font-bold-14" style={{ fontWeight: "600" }}>
    Attack Surface Notifications
    </div>

    <span className="cursor-pointer" onClick={hideModal}>
      <img src="/images/icons/close.svg" alt="" />
    </span>
  </Modal.Header>
  <Modal.Body className="p-4">
        {loading && (
          <div
            style={{
              position: "absolute",
              top: "40%",
              left: 0,
              right: 0,
              margin: "auto",
            }}
          >
            <Loader />
          </div>
        )}
        {!loading && (
          <>
           <div className="title">
           Choose how you would like to receive notifications from your attack surface
                </div>
                <div className="d-flex justify-content-between mt-3 mb-2">
                  <div>
                  Allow notification from attack surface
                  </div>
                  <div>
                    <Form.Group
                      controlId={"cyberscore-notification-check"}
                      className=""
                    >
                      <Form.Check
                        type="switch"
                        id="cyberscore-notification-toggle"
                        className="m-0 scale-switch switch-shadow-none"
                        // checked={isVisible}
                        onChange={(e) => {
                         
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>


                {/* dropdowns values */}

                <div className="d-flex justify-content-between mt-3 mb-2">
                      <div className="title">Insights</div>
                      <div className="score-modal-dropdown">
                        <DropdownField
                          fieldId={"id"}
                          fieldValue={"category"}
                          selected={selected1}
                          placeholder="App and Email"
                          //  showid={true}
                          onClick={(id, val) => {
                            
                          }}
                          data={[
                            { id: 0, category: "Disabled" },
                            { id: 1, category: "Mobile App" },
                            { id: 2, category: "Email" },
                            { id: 3, category: "App and Email" },
                          ]}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-3 mb-2">
                      <div className="title">Frequency</div>
                      <div className="score-modal-dropdown">
                        <DropdownField
                          fieldId={"id"}
                          fieldValue={"category"}
                          selected={selected2}
                          placeholder="App and Email"
                          //  showid={true}
                          onClick={(id, val) => {
                            
                          }}
                          data={[
                            { id: 0, category: "Disabled" },
                            { id: 1, category: "Mobile App" },
                            { id: 2, category: "Email" },
                            { id: 3, category: "App and Email" },
                          ]}
                        />
                      </div>
                    </div>
          
          
          
          
          
          
          
          </>)}
          </Modal.Body>




  <Modal.Footer>
        <div className="d-flex justify-content-between align-items-center">
          {true && (
            <div
              className="reset-to-default pointer"
              onClick={() => {
               
              }}
            >
              <img alt="" src="/images/refresh.png" className="mr-2" />
              <span>Reset</span>
            </div>
          )}
          <div>
            <Button className="cancel-btn  " onClick={hideModal}>
              Cancel
            </Button>
            <Button className="update-btn " onClick={hideModal}>
              Update
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default AttackSurfaceNotificationsModal