import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import "./mspSubscriptionAccounts.scss";
import { getPageDataApi, postData } from "../../../Utils/commonApi";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { useAuth } from "../../context/Auth";
import { throttle } from "lodash";
const MspAccountSubscriptions = ({accountId}) => {

const {authTokens}= useAuth()
const [subscriptionData, setSubscriptionData] = useState({
  
})
 useEffect(() => {
  getPageData()
 }, [])
 
  function getPageData( ) {
    return new Promise((resolve, reject) => {
      getPageDataApi("GetSubscriptionTabAnalytics", authTokens, {
        objId_Customer:accountId,
        optionEnum98:accountId
      })
        .then((response) => {
          // CallToast("Deleted successfully", false, false, "v2style");
          setSubscriptionData(response?.detail)
          resolve(response);
        
        })
        .catch((err) => {
          reject("Something went wrong");
        });
    });
  }

  const SearchInput = useRef();
  const [pageLoading, setPageLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [searchInput, setSearchInput] = useState(false);
  const [timeoutId, setTimeoutId] = useState();
  const [headersList, setHeadersList] = useState([]);
  const [fetchingNewInvoices, setFetchingNewInvoices] = useState(false);
  const [InvoiceData, setInvoiceData] = useState({});
  const [showSubscriptionBillingModal, setShowSubscriptionBillingModal] =
    useState(false);
  const [deleteInvoiceModal, setDeleteInvoiceModal] = useState({
    show: false,
    deleteType: "deleteCyberInsurancePolicy",
    deleteId: "",
  });
  const [pageData, setPageData] = useState({});
  const [sortData, setSortData] = useState({
    sortItem: "Client_Name",
    sortOrder: 0,
    sorting: false,
  });
  function sortRecords(_fieldKey) {
    let _sortOrder = 0;
    if (sortData.sortItem === _fieldKey) {
      _sortOrder = sortData.sortOrder === 0 ? 1 : 0;
    } else {
      _sortOrder = 0;
    }

    setSortData({
      sortItem: _fieldKey,
      sortOrder: _sortOrder,
      sorting: true,
    });

    // getPageData({
    //   ClientNumber: 0,
    //   sortItem: _fieldKey,
    //   sortOrder: _sortOrder,
    // }).then(() => {
    //   setSortData({
    //     sortItem: _fieldKey,
    //     sortOrder: _sortOrder,
    //     sorting: false,
    //   });
    // });
  }
  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchString(value);

    // Clear the previous timeout
    clearTimeout(timeoutId);

    // Set a new timeout to call the API after 1 second
    let _timeoutId = setTimeout(() => {
      getPageData({
        searchString: value,
      });
    }, 800);
    setTimeoutId(_timeoutId);
  };


  // {"SearchCriterias":[{"criteriaEnum":1,"SearchParams":["12/30/2023 8:33:07 PM","3/30/2024 8:33:07 PM"]},{"criteriaEnum":2,"SearchParams":["14262","16253"]},{"criteriaEnum":0,"SearchParams":["32655858","65965863"]},{"criteriaEnum":4,"SearchParams":["0","100"]}],}
  // public enum SearchCriteriaEnum
  // {
  //     Supplier,         //........0
  //     InvoiceDate,      //........1
  //     Categories,       //........2
  //     SubCategories,    //........3
  //     Amount            //........4
  // }
  const [updatingTableData, setUpdatingTableData] = useState(false);
  const [financePageNumber, setfinancePageNumber] = useState(1);
  const [financeFilters, setfinanceFilters] = useState([]);
  const [financeData, setfinanceData] = useState({});
  const [financeNextPageLoading, setfinanceNextPageLoading] = useState(false);
  const [financeInvoiceData, setfinanceInvoiceData] = useState();
  function getPageData(kwargs = {}) {
    setUpdatingTableData(true);
    setfinancePageNumber(kwargs?.financePageNumber ?? 1);
    let nextPageLoading = kwargs?.nextPage;
    // let filters = getListViewFilters(kwargs);
    // setfinanceFilters(filters);
    return new Promise((resolve, reject) => {
      getPageDataApi("GetSubscriptionListing", authTokens, {
        Filters: kwargs?.filters
          ? 'JSON.stringify({ SearchCriterias: "filters" })'
          : JSON.stringify({ SearchCriterias: financeFilters }),
        searchString: kwargs?.searchString ?? searchString,
        sortColumn: kwargs?.sortColumn ?? "",
        sortOrder: kwargs?.sortOrder ?? 0,
        pageNo: kwargs?.financePageNumber ?? 1,
        pageSize: 20,
      })
        .then((response) => {
          setPageLoading(false);
          setUpdatingTableData(false);
          try {
            if (response?.mr?.Success) {
              if(nextPageLoading){
                setPageData({
                  ...response?.detail,
                  SubscriptionInvoices: [...pageData?.SubscriptionInvoices,...response?.detail?.SubscriptionInvoices]   ,
                  menuSorting: response?.detail?.Visibility?.map((item) => ({
                    ...item,
                    Visibility: item?.ColumnVisibility,
                    SortOrder: item?.ColumnSortOrder,
                    FieldKey: item?.ColumnName?.replaceAll(" ", ""),
                    FieldName: item?.ColumnName,
                  })),
                });

              }else{
                setPageData({
                  ...response?.detail,
                  menuSorting: response?.detail?.Visibility?.map((item) => ({
                    ...item,
                    Visibility: item?.ColumnVisibility,
                    SortOrder: item?.ColumnSortOrder,
                    FieldKey: item?.ColumnName?.replaceAll(" ", ""),
                    FieldName: item?.ColumnName,
                  })),
                });

              }
              resolve("Success");
            } else {
              CallToast("Something went wrong", false, false, "v2style");
            }
          } catch (e) {
            console.log(e, "errrro");
          }
        })
        .catch((err) => {
          reject("Something went wrong");
        });
    });
  }

  function UpdateSubscriptionViewSorting(e) {
    setUpdatingTableData(true);
    // getPageDataApi("UpdateSortingVisibility", authTokens, {
    postData(authTokens, "UpdateSortingVisibility", {
      SortingJson: JSON.stringify(e),
      CustomerId: pageData?.clientId,
      SortSettingsArea: 3,
    }).then(() => {
      getPageData();
    });
  }

  useEffect(() => {
    setPageLoading(true);
    getPageData();
  }, []);

  const [financeActiveFilters, setFinanceActiveFilters] = useState([]);

  const [financeFilterOptions] = useState([
    {
      filterName: "Amount",
      filterItemIcon: "/images/msp/msp-subcategory-filter-icon.svg",
      filterData: {
        filterType: "amount",
        fromAmount: "",
        toAmount: "",
      },
    },
    {
      filterName: "Invoice Date",
      filterItemIcon: "/images/msp/msp-date-picker-icon.svg",
      filterData: {
        filterType: "date",
        selectedDateRange: {
          startDate: null, // new Date(),
          endDate: null, //new Date(),
          key: "selection",
        },
      },
    },
    // {
    //   filterName: "Supplier",
    //   filterItemIcon: "/images/msp/msp-amount-filter-icon.svg",
    //   filterData: {
    //     filterType: "supplier",
    //     filterList: [],
    //   },
    // },

    // {
    //   filterName: "Expires",
    //   filterItemIcon: "/images/msp/msp-date-picker-icon.svg",
    //   filterData: {
    //     filterType: "expires",
    //     selectedDateRange: {
    //       startDate: null, // new Date(),
    //       endDate: null, //new Date(),
    //       key: "selection",
    //     },
    //   },
    // },
    // {
    //   filterName: "Status",
    //   filterItemIcon: "/images/msp/msp-amount-filter-icon.svg",
    //   filterData: {
    //     filterType: "status",
    //     filterList: [],
    //   },
    // },
  ]);

  function createEditInvoice(_invoice) {
    return new Promise((resolve, reject) => {
      postData(authTokens, "CreateUpdateSubscriptionInvoice", {
        invoice: JSON.stringify(_invoice),
      })
        .then((response) => {
          CallToast("Updated successfully", false, false, "v2style");
          
          resolve(response);
        })
        .catch((err) => {
          reject("Something went wrong");
        });
      });
  }
  function deleteInvoice(_invoiceId) {
    return new Promise((resolve, reject) => {
      getPageDataApi("ArchiveSubscriptionInvoice", authTokens, {
        objId_invoice: _invoiceId,
      })
        .then((response) => {
          CallToast("Deleted successfully", false, false, "v2style");
          resolve(response);
          getPageData();
        })
        .catch((err) => {
          reject("Something went wrong");
        });
    });
  }


  const throttledFetchData = throttle(getMoreInvoices, 1000);
  async function getMoreInvoices(e) {
    try {
      if (
        e.target.clientHeight + e.target.scrollTop + 20 >=
        e.target.scrollHeight
      ) {
        let element = document.getElementById("mainBody");
        element.removeEventListener("scroll", throttledFetchData);

        setFetchingNewInvoices(true);

        getPageData({
          financePageNumber:financePageNumber+1,
          nextPage:true
        }).then(() => {
          setFetchingNewInvoices(false);
        });

        console.log("You've reached the end of the screen!");
      }
    } catch (error) {
      console.log("errror aa gya", error);
    }
  }

  useEffect(() => {
    var element = document.getElementById("mainBody");
    element.removeEventListener("scroll", throttledFetchData);
    element.addEventListener("scroll", throttledFetchData);

    return () => {
      element.removeEventListener("scroll", throttledFetchData);
    };
  }, [pageData, fetchingNewInvoices]);

  return (
    <div className="w-70 mx-auto msp-account-subscription">
      <div className="f-16 f-600 my-3">Subscription and Billing</div>

      <div className="d-flex align-items-center justify-content-between mt-3 mb-2">
        <div>Active subscription</div>
        <Button className="hg-blue-btn">Manage Subscription</Button>
      </div>

      <div className="bg-grey border-parent radius-8">
        <div className="border-item d-flex align-items-center justify-content-between p-3">
          <div className="f-500 f-black">Subscription</div>
          <div>Up to 100 clients, 10 prospects</div>
          <div>$295 /month</div>
        </div>
        <div className="border-item d-flex align-items-center justify-content-between p-3">
          <div className="f-500 f-black">Premium Licences</div>
          <div>11 x $10 /month</div>
          <div>$110 /month</div>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-between mt-3 mb-2">
        <div>Trial</div>
        <Button className="hg-blue-btn">Add Trial</Button>
      </div>
      <div className="bg-grey border-parent radius-8">
        <div className="border-item d-flex align-items-center justify-content-between p-3">
          <div className="f-500 f-black">Free Trial</div>
          <div>1 Jan, 2024 - 14 Jan, 2024</div>
          <div>14 of 14 days</div>
        </div>
        <div className="border-item d-flex align-items-center justify-content-between p-3">
          <div className="f-500 f-black">Extended Trial</div>
          <div>1 Jan, 2024 - 14 Jan, 2024</div>
          <div>16 of 26 days</div>
        </div>
      </div>

      <div className="f-14 f-500 mb-3 mt-4">Usage</div>
      <div className="d-flex align-items-center justify-content-between bg-grey radius-8 p-3">
        <div className="f-500 f-black">Launch Promo</div>
        <div className="f-black">Free Premium licenses</div>
      </div>

      <div className="f-14 f-500 mb-3 mt-4">Usage</div>
      <div className="d-flex bg-grey">
        <div className="p-3 flex-1 border-right">
          <div className="f-grey f-500">Clients</div>
          <div className="f-black f-18 f-500 my-2">59</div>
        </div>
        <div className="p-3 flex-1 border-right">
          <div className="f-grey f-500">Prospect Licenses</div>
          <div className="f-black f-18 f-500 my-2">8 of 10</div>
        </div>
        <div className="p-3 flex-1  border-right">
          <div className="f-grey f-500">Standard Licenses</div>
          <div className="f-black f-18 f-500 my-2">28 of 100</div>
        </div>
        <div className="p-3 flex-1  ">
          <div className="f-grey f-500">Premium Licences</div>
          <div className="f-black f-18 f-500 my-2">11</div>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-between mt-3 mb-2">
        <div>Billing</div>
        <div className="d-flex align-items-center">
        <div className="mx-2 d-flex align-items-center">
                  <img
                    alt=""
                    src="/images/attack-surface/search-icon.svg"
                    className="pointer"
                    onClick={() => {
                      setSearchInput(true);
                      SearchInput.current.focus();
                    }}
                  />
                  <input
                    type="text"
                    placeholder="Search.."
                    ref={SearchInput}
                    value={searchString}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    onBlur={() => {}}
                    className={`${
                      searchInput
                        ? "animated-search-input-open"
                        : "animated-search-input-close"
                    } pl-2`}
                    autoFocus
                  />
                  <img
                    alt=""
                    style={{
                      visibility: searchString !== "" ? "visible" : "hidden",
                    }}
                    src="/images/settings-v2/header-search-cross.svg"
                    className="pointer"
                    onClick={() => {
                      handleInputChange({ target: { value: "" } });
                    }}
                  />
                </div>

        {/* <Button className="hg-blue-btn">Add</Button> */}
        </div>
      </div>

      <div className="bg-grey radius-8 border-parent">
        {pageData?.SubscriptionInvoices?.map(() => {
          return (
            <div className="border-item p-3 billing-table">
              <div>April 20, 2021</div>
              <div className="f-grey">Starter, Premium Licences</div>
              <div>
                <div
                  className="w-fit-content f-12 f-500"
                  style={{
                    borderRadius: "20px",
                    color: "#fff",
                    background: "#40D88E",
                    padding: "1px 7px",
                  }}
                >
                  Paid
                </div>
              </div>
              <div>£ 1928</div>
              <div>
                <Dropdown className={`transparennt-wrapper-dropdown `}>
                  <Dropdown.Toggle>
                    <img
                      alt=""
                      src="/images/supply-chain/horizontal-dots.svg"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu></Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MspAccountSubscriptions;
