import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { FiCheck, FiX } from "react-icons/fi";
import CircularProgressBar from "../CircularProgressBar";

const ToastMessage = (props) => {

      toast.dark(
      <span>
       { !props.hide && <FiX style={{ marginRight: "10px", color: "#f36060" }} /> }
        <span style={{ width: "500px", color: "#f36060" }}>
         { props.message }
        </span>
        <span className="float-right">
          <CircularProgressBar durationInSec="30" />
        </span>
      </span>
    );

  return (
    <div className="spinnerContainer">
      <ToastContainer
        position="bottom-center"
        style={ { width: '390px' } }
        autoClose={2800}
        hideProgressBar
      />
    </div>
  );
};

export default ToastMessage;
