import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import "./settingsSideNav.scss";
import { Dropdown } from "react-bootstrap";
import { TrimString } from "../../Utils/StringTrimmer";
import { TrackUser } from "../../Utils/SegmentFunctions";
import ImageWithInitials from "../../Utils/ImageWithInitials";
const SettingsV2Header = () => {
  const [search, setSearch] = useState("");
  const [dropDownvisible, setDropDownvisible] = useState(false);
  // const [NoOfPagesSearched, setNoOfPagesSearched] = useState(0);
  const history = useHistory();
  const userDetails = useSelector((state) => state?.user?.userDetail);
  const SuggestedPages = [
    {
      pageName: "My profile",
      pageURL: "/settings/v2/my_profile",
      Image: "/images/settings-nav/my_profile.svg",
    },
    {
      pageName: "Notifications",
      pageURL: "/settings/v2/notifications",
      Image: "/images/settings-nav/notifications.svg",
    },
    {
      pageName: "Company settings",
      pageURL: "/settings/v2/company-settings",
      Image: "/images/settings-nav/company_settings.svg",
    },
    {
      pageName: "Billing",
      pageURL: "/settings/v2/billing",
      Image: "/images/settings-nav/billing.svg",
    },
  ];

  const SearchablePages = [
    {
      pageName: "Company settings > Company Name",
      pageURL: "/settings/v2/company-settings",
      Image: "/images/settings-nav/company_settings.svg",
    },
    {
      pageName: "Company settings",
      pageURL: "/settings/v2/company-settings",
      Image: "/images/settings-nav/company_settings.svg",
    },
    {
      pageName: "Company settings > Industry",
      pageURL: "/settings/v2/company-settings",
      Image: "/images/settings-nav/company_settings.svg",
    },
    {
      pageName: "Company settings > Employee",
      pageURL: "/settings/v2/company-settings",
      Image: "/images/settings-nav/company_settings.svg",
    },
    {
      pageName: "Company settings > Address",
      pageURL: "/settings/v2/company-settings",
      Image: "/images/settings-nav/company_settings.svg",
    },
    {
      pageName: "Company settings > Language",
      pageURL: "/settings/v2/company-settings",
      Image: "/images/settings-nav/company_settings.svg",
    },
    {
      pageName: "Company settings > Timezone",
      pageURL: "/settings/v2/company-settings",
      Image: "/images/settings-nav/company_settings.svg",
    },
    {
      pageName: "My profile > Name",
      pageURL: "/settings/v2/my_profile",
      Image: "/images/settings-nav/my_profile.svg",
    },
    {
      pageName: "My profile",
      pageURL: "/settings/v2/my_profile",
      Image: "/images/settings-nav/my_profile.svg",
    },
    {
      pageName: "My profile > Email",
      pageURL: "/settings/v2/my_profile",
      Image: "/images/settings-nav/my_profile.svg",
    },
    {
      pageName: "My profile > Role",
      pageURL: "/settings/v2/my_profile",
      Image: "/images/settings-nav/my_profile.svg",
    },
    {
      pageName: "My profile > Reset MFA",
      pageURL: "/settings/v2/my_profile",
      Image: "/images/settings-nav/my_profile.svg",
    },
    {
      pageName: "My profile > Reset Password",
      pageURL: "/settings/v2/my_profile",
      Image: "/images/settings-nav/my_profile.svg",
    },
    {
      pageName: "Notifications > Allow Notifications",
      pageURL: "/settings/v2/notifications",
      Image: "/images/settings-nav/notifications.svg",
    },
    {
      pageName: "Notifications",
      pageURL: "/settings/v2/notifications",
      Image: "/images/settings-nav/notifications.svg",
    },
    {
      pageName: "Notifications > Action Centre Notifications",
      pageURL: "/settings/v2/notifications",
      Image: "/images/settings-nav/notifications.svg",
    },
    {
      pageName: "Notifications > CyberScore Notifications",
      pageURL: "/settings/v2/notifications",
      Image: "/images/settings-nav/notifications.svg",
    },
    {
      pageName: "Notifications > CyberThreatLevel Notifications",
      pageURL: "/settings/v2/notifications",
      Image: "/images/settings-nav/notifications.svg",
    },
    {
      pageName: "Notifications > CyberCompliance Notifications",
      pageURL: "/settings/v2/notifications",
      Image: "/images/settings-nav/notifications.svg",
    },
    {
      pageName: "Billing > Active Subscriptions",
      pageURL: "/settings/v2/billing",
      Image: "/images/settings-nav/billing.svg",
    },
    {
      pageName: "Billing",
      pageURL: "/settings/v2/billing",
      Image: "/images/settings-nav/billing.svg",
    },
    {
      pageName: "Billing > Payment Methods",
      pageURL: "/settings/v2/billing",
      Image: "/images/settings-nav/billing.svg",
    },
    {
      pageName: "Billing > Add Payment Method",
      pageURL: "/settings/v2/billing",
      Image: "/images/settings-nav/billing.svg",
    },
    {
      pageName: "Billing > Billing History",
      pageURL: "/settings/v2/billing",
      Image: "/images/settings-nav/billing.svg",
    },
    {
      pageName: "C-suite report > Send Report",
      Image: "/images/settings-nav/c-suite.svg",
      pageURL: "/settings/v2/c-suite",
    },
    {
      pageName: "C-suite report",
      Image: "/images/settings-nav/c-suite.svg",
      pageURL: "/settings/v2/c-suite",
    },
    {
      pageName: "C-suite report > Schedule Report",
      Image: "/images/settings-nav/c-suite.svg",
      pageURL: "/settings/v2/c-suite",
    },
    {
      pageName: "Active sessions > All Devices",
      Image: "/images/settings-nav/active_sessions.svg",
      pageURL: "/settings/v2/active_sessions",
    },
    {
      pageName: "Active sessions",
      Image: "/images/settings-nav/active_sessions.svg",
      pageURL: "/settings/v2/active_sessions",
    },
    {
      pageName: "Active sessions > Send Notifications",
      Image: "/images/settings-nav/active_sessions.svg",
      pageURL: "/settings/v2/active_sessions",
    },
    {
      pageName: "Active sessions > Remove Devices",
      Image: "/images/settings-nav/active_sessions.svg",
      pageURL: "/settings/v2/active_sessions",
    },
    {
      pageName: "Active sessions > Log out sessions",
      Image: "/images/settings-nav/active_sessions.svg",
      pageURL: "/settings/v2/active_sessions",
    },
    {
      pageName: "Members > Add Members",
      Image: "/images/settings-nav/members.svg",
      pageURL: "/settings/v2/members",
    },
    {
      pageName: "Members > Users",
      Image: "/images/settings-nav/members.svg",
      pageURL: "/settings/v2/members",
    },
    {
      pageName: "Members > User Details",
      Image: "/images/settings-nav/members.svg",
      pageURL: "/settings/v2/members",
    },
    {
      pageName: "Subscriptions > Subscription Plans",
      Image: "/images/settings-nav/subscriptions.svg",
      pageURL: "/settings/v2/subscriptions",
    },
    {
      pageName: "Subscriptions > Integration Packs",
      Image: "/images/settings-nav/subscriptions.svg",
      pageURL: "/settings/v2/subscriptions",
    },
    {
      pageName: "Action Centre > Integrations",
      Image: "/images/settings-nav/action_centre.svg",
      pageURL: "/settings/v2/action_centre",
    },
    {
      pageName: "Action Centre > Task Creation",
      Image: "/images/settings-nav/action_centre.svg",
      pageURL: "/settings/v2/action_centre",
    },
    {
      pageName: "Action Centre",
      Image: "/images/settings-nav/action_centre.svg",
      pageURL: "/settings/v2/action_centre",
    },
    {
      pageName: "Reset Settings",
      Image: "/images/settings-nav/reset_settings.svg",
      pageURL: "/settings/v2/reset_settings",
    },
    {
      pageName: "Team",
      Image: "/images/settings-nav/members.svg",
      pageURL: "/settings/v2/team",
    },
    // {
    //   pageName: "Rewards",
    //   Image: "/images/settings-nav/rewards.svg",
    //   pageURL: "/settings/v2/rewards",
    // },







  ];

  const NoOfPagesSearched = SearchablePages.filter(
    (val) => search && val.pageName.toLowerCase().includes(search.toLowerCase())
  ).length;
  return (
    <div className="settings-v2-header d-flex align-items-center p-2 justify-content-between">
      {/* right side header */}

      <div className="d-flex align-items-center ">
        <img
          alt=""
          src="/images/settings-v2/logo.svg"
          className="mr-3 pointer"
          onClick={() => {
            history.push(`/${userDetails?.Role?.toLowerCase()}/dashboard`);
          }}
        />
        <div className="header-title d-flex align-items-center mr-4">
          Settings
        </div>
      </div>
      {/* search bar */}
      <div className="d-flex align-items-center">
        <div className="search-bar-v2">
          <img
            alt=""
            src="/images/settings-v2/search-icon.svg"
            className="mr-2"
          />
          <input
            type="text"
            placeholder="Search Settings"
            className="search-bar-v2-input"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            autoComplete="new-password"
            onFocus={() => setDropDownvisible(true)}
            onBlur={() => setTimeout(() => setDropDownvisible(false), 300)}
          />
          {search && (
            <img
              alt=""
              src="/images/settings-v2/header-search-cross.svg"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                setDropDownvisible(false);

                setSearch("");
              }}
            />
          )}
          {/* Search drop down */}
          <div
            className={`search-v2-Dropdown ${dropDownvisible ? "" : "d-none"}`}
          >
            <div className="suggested-dropdown">
              <div className="suggestion my-2 ml-2">
                {search == "" && "Suggestions"}
                {NoOfPagesSearched == 1 && "1 result"}
                {NoOfPagesSearched > 1 && `${NoOfPagesSearched} results`}
                {search &&
                  NoOfPagesSearched == 0 &&
                  `No results for the “${search}”  request`}
              </div>

              {search && NoOfPagesSearched == 0 && (
                <div className="suggestion mb-2 mt-3 ml-2">Suggestions</div>
              )}

              {(!search || NoOfPagesSearched == 0) &&
                SuggestedPages.map((page, index) => {
                  return (
                    <div
                      className="d-flex align-items-center pl-3 py-1 suggested-page"
                      key={index}
                      onClick={() => {
                        history.push(page.pageURL);
                      }}
                    >
                      <img alt="" src={page.Image} className="mb-1 mr-2" />
                      <div className="suggested-page-name d-flex align-items-center">
                        {page.pageName}
                      </div>
                    </div>
                  );
                })}

              {search &&
                SearchablePages.filter((val) =>
                  val.pageName.toLowerCase().includes(search.toLowerCase())
                ).map((page, index) => {
                  return (
                    <div
                      className="d-flex align-items-center pl-3 py-1 suggested-page"
                      key={index}
                      onClick={() => {
                        history.push(page.pageURL);
                        setSearch("");
                      }}
                    >
                      <img alt="" src={page.Image} className="mb-1 mr-2" />
                      <div className="suggested-page-name d-flex align-items-center">
                        {page.pageName}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        {/* left side header */}
        <div className="d-flex align-items-center">
          <Link to={`/${userDetails?.Role?.toLowerCase()}/dashboard`}>
            <div className="d-flex align-items-center">
              <div className="f-grey mr-1 d-flex align-items-center mt-2-">
                Back to Highground
              </div>
              <div className="d-flex align-items-center">
                <img
                  alt=""
                  src="/images/settings-v2/sendto.svg"
                  className="mr-3"
                />
              </div>
            </div>
          </Link>

          {/* User logo with drop down */}

          <div
            style={{ marginLeft: "" }}
            className="sideBarDropdown- supportDropdown  header-logo-dropdown"
          >
            <Dropdown>
              <Dropdown.Toggle>
                {userDetails.Avatar == "No URL" ? (
                  <ImageWithInitials
                    width={31}
                    background="purple"
                    initials={userDetails?.Initials}
                  />
                ) : (
                  <img
                    alt=""
                    src={userDetails?.Avatar}
                    className="thumbnail-pic"
                  />
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  disabled={true}
                  style={{ opacity: "1 !important" }}
                >
                  <div className="d-flex justify-content-between align-items-center ">
                    <div className="d-flex align-items-center">
                      {userDetails.Avatar == "No URL" ? (
                        <ImageWithInitials
                          width={31}
                          background="purple"
                          initials={userDetails?.Initials}
                        />
                      ) : (
                        <img
                          alt=""
                          src={userDetails?.Avatar}
                          className="thumbnail-pic"
                        />
                      )}

                      <div className="ml-2">
                        <p className="title m-0">
                          {TrimString(userDetails?.FullName, 17)}
                        </p>
                        <p
                          className="subtitle m-0"
                          style={{ color: "#9499aa" }}
                        >
                          {TrimString(userDetails?.UserName, 17)}
                        </p>
                      </div>
                    </div>
                    <div>{/* <img src="/images/chevron-right.svg" /> */}</div>
                  </div>
                </Dropdown.Item>
                <hr className="m-0" />
                <Dropdown.Item
                  type="button"
                  className="mt-1 mb-1"
                  onClick={() => history.push("/settings/v2/my_profile")}
                >
                  <img
                    alt=""
                    src="/images/settings-v2/settingwheel.svg"
                    className="mr-2 v2-item-color"
                  />
                  <span
                    className="f-grey-imp"
                    style={{
                      paddingTop: "2px",
                    }}
                  >
                    Personal Settings
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  type="button"
                  onClick={() => {
                    TrackUser("User Signed out");
                    setTimeout(() => history.push("/logout"), 100);
                    // history.push("/logout")
                  }}
                >
                  <img
                    alt=""
                    src="/images/settings-v2/logout.svg"
                    className="mr-2 v2-item-color"
                  />
                  <span className="f-grey-imp">Logout</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsV2Header;
