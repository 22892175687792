import React, { useState, useEffect, Fragment } from "react";
import { useAuth } from "../context/Auth";
import IntegrationBlock from "./IntegrationBlock";
import MyIntegrationBlock from "./MyIntegrationBlock";
import IntegrationsModal from "./IntegrationsModal";
import SuggestIntegrationModal from "./SuggestIntegrationModal";
import { getAPIData } from "../../Utils/commonApi";
import {
  Row,
  Col,
  Tabs,
  Tab,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import "./Integrations.css";
import "../../main.scss";
import TicketsView from "../Support/TicketsView/TicketsView";
import ParentChildModal from "../ModalDynamic/ParentChildModal";
import IncidentResponseForm from "../IncidentResponseForm/IncidentResponseForm";
import ConnectToolModal from "./ConnectToolModal";

import FailDialog from "../Spinner/FailDialog";
import { Link } from "react-router-dom";
 import Header from "../Header";
import ContentLoader from "react-content-loader";
import { useSelector } from "react-redux";
import { CallToast } from "../PriceCalculator/modals/CustomToast";

const IntegrationsIndex = (props) => {
  const { authTokens } = useAuth();
  const isMenuCollapse = useSelector((state) => state?.global?.menuCollapse);
  const userDetails = useSelector((state) => state.user?.userDetail);
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [technologies, setTechnologies] = useState(0);
  const [myIntegrations, setMyIntegrations] = useState(0);
  const [supportCounter] = useState(0);
  const [technologyData, setTechnologyData] = useState(false);
  const [integration, setIntegration] = useState(false);
  const [showDemo, setShowDemo] = useState(false);
  const [suggestIntegration, setSuggestIntegration] = useState(false);
  const [suggestModalShow, setSuggestModalShow] = useState(false);
  const [filter, setFilter] = useState("");
  const [connectToolDetail, setConnectToolDetail] = useState(false);
  const [connectModalShow, setConnectModalShow] = useState(false);
  const [alertShow] = useState(false);

  useEffect(() => {
    getTechnologiesData();
    getMyIntegrationsData();
  }, []);

  useEffect(() => {
    if (supportCounter > 1) {
      setSupportModalOpen(false);
      setInterval(() => {
        setSupportModalOpen(true);
      }, 100);
    }
  }, [supportCounter]);

  async function getTechnologiesData() {
    const response = await getAPIData("61", authTokens);
    const isTab = localStorage.getItem("redirectTab");
    if (isTab != "false") {
      response.IntegrationList.map((val) => {
        if (val.TechnologyTitle == isTab) {
          getIntegrationDataByIdApi(
            val.TechnologyTitle,
            val.SortOrder,
            val.IntegrationId
          );
        }
      });
    }

    setTechnologies(response);
    setShowDemo(response.ShowDemo);
  }

  async function getMyIntegrationsData() {
    const response = await getAPIData("61", authTokens, {
      OptionEnum1: "1",
    });
    setMyIntegrations(response);
  }

  function setSupportState(event) {
    event.preventDefault();
    setSupportModalOpen(true);
  }
  // Setup to update Upvote count-START:

  const [integrationIdData, setIntegrationIdData] = useState({
    title: "",
    order: "",
    id: "",
  });

  // Setup to update Upvote count-STOP:

  async function getIntegrationDataById(title, order, id) {
    setTechnologyData({ title: title, order: order, id: id });
    const integration = await getAPIData("64", authTokens, {
      OptionEnum1: order,
      OptionEnum2: id,
    });
    setIntegration(integration.IntegrationList);
  }

  async function getIntegrationDataByIdApi(title, order, id) {
    setTechnologyData({ title: title, order: order, id: id });
    const integration = await getAPIData("64", authTokens, {
      OptionEnum1: order,
      OptionEnum2: id,
    });
    setIntegration(integration.IntegrationList);
  }

  async function getSuggestIntegration(order) {
    const suggest_integration = await getAPIData("66", authTokens, {
      OptionEnum1: order,
    });
    setSuggestIntegration(suggest_integration);
    setSuggestModalShow(true);
  }

  async function getIntegrationStateDetail(id, button_function) {
    if (button_function != 0) {
      const response = await getAPIData("65", authTokens, {
        OptionEnum1: id,
        OptionEnum2: button_function,
      });
      
      if (button_function == 1 || button_function == 2) {
        setConnectToolDetail(response);
        setConnectModalShow(true);
      } else if (button_function == 4) {
        if (response.Success == true) {
          getIntegrationDataById(
            integrationIdData.title,
            integrationIdData.order,
            integrationIdData.id
          );
          return response;
        } else {
          CallToast("Already upvoted", false, false, "v2style");
          // setAlertShow(response);
          // setTimeout(() => {
          //   setAlertShow(false);
          // }, 3000);
        }
      }
    }
  }

  // if (technologies != 0) {
  return (
    <React.Fragment>
      {alertShow && <FailDialog text={alertShow.Message} />}
      <Row className="fullHeight">
        <Col xs={12} className="px-0">
          <div className="dashboard fullHeight">
            <header
              className="d-flex justify-content-between navbar  pb-0"
              style={{
                paddingTop: ` ${"1em"} `,
                background: "#FFFFFF",
                position: "fixed",
                width: `${isMenuCollapse ? "92.25%" : "83.25%"}`,
                zIndex: 6,
                marginLeft: "-0.9rem",
                paddingLeft: "2rem",
              }}
            >
              <h1>Integrations</h1>

              <Header />
            </header>
            {technologies != 0 ? (
              integration ? (
                <Fragment>
                  <div className="d-flex" style={{ marginTop: "8%" }}>
                    <span
                      className="cursor-pointer d-flex"
                      onClick={() => setIntegration("")}
                    >
                      <img
                        src="/images/arrow-left.svg"
                        className="pr-1"
                        alt=""
                      />
                      <p className="link_textCol">Back</p>
                    </span>
                    <p className="integrationTitle">{technologyData.title}</p>
                  </div>
                  <InputGroup className="searchInput pt-4">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <img
                          alt=""
                          src="/images/mag-icon.svg"
                          style={{
                            display: "inline-block",
                            paddingRight: "1em",
                          }}
                        />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      id="inlineFormInputGroup"
                      placeholder="Search..."
                      onChange={(e) => setFilter(e.target.value)}
                      onBlur={(e) => {
                        window.analytics.track(
                          `${technologyData.title} tool search used`,
                          {
                            UserFullName: userDetails?.FullName,
                            // UserFirstName: userDetails?.FirstName,
                            UserEmail: userDetails?.UserName,
                          }
                        );
                      }}
                    />
                  </InputGroup>

                  {/* Connect Tool banner--START */}

                  <div className="d-f l e x d-none align-items-center justify-content-between p-2 bg-white mr-3 mt-3 hg-border radius-8 w-100">
                    <div className="d-flex align-items-center">
                      <img
                        alt=""
                        width={30}
                        height={30}
                        className="mr-3"
                        src="/images/TechnologyType/NinjaRMM_SmallIcon.svg"
                      />

                      <div>
                        <p className="font-weight-bold m-0">
                          <img
                            alt=""
                            src="/images/attack-surface/plug.svg"
                            className="mr-2"
                          />
                          Available Integration, connect it to HighGround?
                        </p>
                        <p className="f-12 f-darkgrey">
                          Get your data into HighGround to get verified data
                        </p>
                      </div>
                    </div>
                    <Button
                      className="hg-blue-btn"
                      onClick={() => {
                        // getIntegrationStateDetail(
                        //   tool?.SelectedTool?.obj_IntegrationId
                        // );
                      }}
                    >
                      Connect
                    </Button>
                  </div>

                  {/* Connect Tool STOP */}

                  <h1 style={{ marginTop: "40px" }}>My Tool</h1>
                  <Row style={{ paddingTop: "1.5em" }}>
                    {integration
                      .filter((option) => {
                        return (
                          option.IntegrationAvailability == 0 &&
                          option.IntegrationName.toLowerCase().includes(
                            filter.toLowerCase()
                          )
                        );
                      })
                      .map((option) => (
                        <MyIntegrationBlock
                          data={option}
                          detail={() =>
                            getIntegrationStateDetail(
                              option.IntegrationId,
                              option.ButtonFunction
                            )
                          }
                        />
                      ))}
                  </Row>
                  <h1 style={{ marginTop: "40px" }}>Available Integrations</h1>
                  <p className="settings_emailSubHeader">
                    Get started by connecting your {technologyData.title} Tool
                  </p>
                  <Row style={{ paddingTop: "1.5em" }}>
                    {integration
                      .filter((option, index) => {
                        return (
                          option.IntegrationAvailability == 1 &&
                          option.IntegrationName.toLowerCase().includes(
                            filter.toLowerCase()
                          )
                        );
                      })
                      .map((option, optionIndex) => {
                        return (
                          <MyIntegrationBlock
                            data={option}
                            detail={() =>
                              getIntegrationStateDetail(
                                option.IntegrationId,
                                option.ButtonFunction
                              )
                            }
                          />
                        );
                      })}
                  </Row>
                  <h1 style={{ marginTop: "40px" }}>Upcoming Integrations</h1>
                  <p className="settings_emailSubHeader">
                    Vote for your favorite tool
                  </p>
                  <Row style={{ paddingTop: "1.5em" }}>
                    {integration
                      .filter((option) => {
                        return (
                          option.IntegrationAvailability == 2 &&
                          option.IntegrationName.toLowerCase().includes(
                            filter.toLowerCase()
                          )
                        );
                      })
                      .map((option) => {
                        return option.IntegrationState !=
                          "Suggest Integration" ? (
                          <MyIntegrationBlock
                            data={option}
                            detail={() =>
                              getIntegrationStateDetail(
                                option.IntegrationId,
                                option.ButtonFunction
                              )
                            }
                          />
                        ) : (
                          <Col
                            key={option.SortOrder}
                            xs={12}
                            md={6}
                            lg={4}
                            className="mb-4"
                            sort={option.SortOrder}
                            onClick={() => {
                              window.analytics.track(
                                `${technologyData.title} tool suggest integration clicked`,
                                {
                                  UserFullName: userDetails?.FullName,
                                  // UserFirstName: userDetails?.FirstName,
                                  UserEmail: userDetails?.UserName,
                                }
                              );
                            }}
                          >
                            <div
                              onClick={() =>
                                getSuggestIntegration(option.SortOrder)
                              }
                              className="suggestIntegration h-100 d-flex justify-content-center cursor-pointer"
                            >
                              <span className="align-self-center cursor-pointer">
                                <img
                                  src={option.IntegrationStateIconURL}
                                  alt=""
                                />
                                <p
                                  style={{
                                    color: option.IntegrationStateColour,
                                  }}
                                >
                                  {option.IntegrationState}
                                </p>
                              </span>
                            </div>
                          </Col>
                        );
                      })}
                  </Row>
                </Fragment>
              ) : (
                <Fragment>
                  <Tabs
                    className="integration_tabs"
                    defaultActiveKey="technologies"
                    transition={false}
                    style={{ marginTop: "8%" }}
                  >
                    <Tab eventKey="technologies" title="Technologies">
                      <div className="cybercare_container d-flex justify-content-between p16px mt24px">
                        <img
                          src="images/icons/connect.svg"
                          className="mr16px"
                          alt=""
                        />
                        <div>
                          <div className="mb8px heading2">
                            Connected Integrations:
                            {technologies.IntegrationsConnected} of
                            {technologies.IntegrationsAvailable}
                          </div>
                          <span className="linkGreyText">
                            {technologies?.Integration_Title1}.
                            {technologies?.Integration_Title2?.toLowerCase().includes(
                              "premium plan"
                            )
                              ? technologies?.Integration_Title2?.split(" ")
                                  .slice(0, -2)
                                  .join(" ")
                              : ""}
                            {technologies?.Integration_Title2 && (
                              <Link
                                to={technologies.Integration_TitleLink}
                                className="txt-blue cursor-pointer"
                              >
                                &nbsp;Premium Plan
                              </Link>
                            )}
                          </span>
                        </div>

                        <div className="ml-auto align-self-center d-flex">
                          {!technologies?.HideButtons && (
                            <>
                              <Link
                                to="/system_settings?tab=rewards"
                                className="mr16px"
                              >
                                <div
                                  className="light-blue-btn cursor-pointer"
                                  style={{ width: "167px" }}
                                >
                                  Get Free Integrations
                                </div>
                              </Link>
                              <Link to="/system_settings?tab=subscriptions">
                                <Button
                                  className="btn-blue"
                                  style={{ width: "137px" }}
                                >
                                  Buy Integrations
                                </Button>
                              </Link>
                            </>
                          )}
                        </div>
                      </div>
                      <Row className="px-2" style={{ paddingTop: "1.5em" }}>
                        {technologies &&
                          technologies?.IntegrationList?.map((val) => (
                            <Col
                              xs={12}
                              key={val.SortOrder}
                              md={6}
                              lg={6}
                              className="mb-4 pr-2 pl-2 cursor-pointer"
                              sort={val.SortOrder}
                            >
                              <IntegrationBlock
                                data={val}
                                handleClick={() => {
                                  // {val.TechnologyTitle}
                                  window.analytics.track(`Tool clicked`, {
                                    UserFullName: userDetails?.FullName,

                                    email: userDetails?.UserName,
                                    type: val?.TechnologyTitle,
                                  });
                                  getIntegrationDataById(
                                    val.TechnologyTitle,
                                    val.SortOrder,
                                    val.IntegrationId
                                  );
                                  setIntegrationIdData({
                                    title: val.TechnologyTitle,
                                    order: val.SortOrder,
                                    id: val.IntegrationId,
                                  });
                                }}
                              />
                            </Col>
                          ))}
                      </Row>
                    </Tab>
                    <Tab eventKey="my-integrations" title="My Integrations">
                      <div className="cybercare_container d-flex justify-content-between p16px mt24px">
                        <img
                          src="images/icons/connect.svg"
                          className="mr16px"
                          alt=""
                        />
                        <div>
                          <div className="mb8px heading2">
                            Connected Integrations:
                            {technologies.IntegrationsConnected} of
                            {technologies.IntegrationsAvailable}
                          </div>
                          <span className="linkGreyText">
                            {technologies?.Integration_Title1}.
                            {technologies?.Integration_Title2?.toLowerCase().includes(
                              "premium plan"
                            )
                              ? technologies?.Integration_Title2?.split(" ")
                                  .slice(0, -2)
                                  .join(" ")
                              : ""}
                            {technologies?.Integration_Title2 && (
                              <Link
                                to={technologies.Integration_TitleLink}
                                className="txt-blue cursor-pointer"
                              >
                                &nbsp;Premium Plan
                              </Link>
                            )}
                          </span>
                        </div>
                        <div className="ml-auto align-self-center d-flex">
                          {!technologies?.HideButtons && (
                            <>
                              <Link
                                to="/system_settings?tab=rewards"
                                className="mr16px"
                              >
                                <div
                                  className="light-blue-btn cursor-pointer"
                                  style={{ width: "167px" }}
                                >
                                  Get Free Integrations
                                </div>
                              </Link>
                              <Link to="/system_settings?tab=subscriptions">
                                <Button
                                  className="btn-blue"
                                  style={{ width: "137px" }}
                                >
                                  Buy Integrations
                                </Button>
                              </Link>
                            </>
                          )}
                        </div>
                      </div>
                      <Row className="px-2" style={{ paddingTop: "1.5em" }}>
                        {myIntegrations
                          ? myIntegrations.IntegrationList.map((val) => (
                              <MyIntegrationBlock data={val} showTitle={true} />
                            ))
                          : ""}
                      </Row>
                    </Tab>
                  </Tabs>
                </Fragment>
              )
            ) : (
              <ContentLoader
                speed={2}
                width={"100%"}
                height={"100%"}
                viewBox="0 0 1480 800"
                backgroundColor="#F6F8FB"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="4" y="100" rx="12" ry="12" width="1440" height="78" />

                <rect x="4" y="200" rx="12" ry="12" width="710" height="168" />
                <rect
                  x="730"
                  y="200"
                  rx="12"
                  ry="12"
                  width="710"
                  height="168"
                />

                <rect x="4" y="390" rx="12" ry="12" width="710" height="168" />
                <rect
                  x="730"
                  y="390"
                  rx="12"
                  ry="12"
                  width="710"
                  height="168"
                />

                <rect x="4" y="580" rx="12" ry="12" width="710" height="168" />
                <rect
                  x="730"
                  y="580"
                  rx="12"
                  ry="12"
                  width="710"
                  height="168"
                />
              </ContentLoader>
            )}
          </div>
        </Col>
        {supportModalOpen ? (
          <ParentChildModal
            ParentComponent={<TicketsView />}
            ChildComponent={<IncidentResponseForm type={"support"} />}
            buttonText={"+ Create Ticket"}
            modalHeader={"Support Tickets"}
            childHeader={"Create Ticket"}
            setShow={true}
            showButton={true}
          />
        ) : (
          ""
        )}
        {suggestIntegration && (
          <SuggestIntegrationModal
            data={suggestIntegration}
            show={suggestModalShow}
            onHide={() => setSuggestModalShow(false)}
          />
        )}
        {connectToolDetail && (
          <ConnectToolModal
            data={connectToolDetail}
            show={connectModalShow}
            onHide={() => setConnectModalShow(false)}
            handleIntegration={() =>
              getIntegrationDataById(
                technologyData.title,
                technologyData.order,
                technologyData.id
              )
            }
          />
        )}
      </Row>
      {showDemo && <IntegrationsModal />}
    </React.Fragment>
  );

  // } else {
  //   return <LoadingSpinner />;
  // }
};
export default IntegrationsIndex;
