import React, { useState, useEffect} from "react";
import {
  Button,
  Form,
  
  Row,
  Col,

} from "react-bootstrap";
import { getAPIData, postRequest } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";

import Loader from "../../../Common/loader";
import "../actionCentreModals.scss";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";

const Task = ({ closeModal }) => {
  const { authTokens } = useAuth();

 
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  function LoadPageData() {
    getAPIData("239", authTokens).then((response) => {
      setData(response);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    LoadPageData();
  }, []);

  const handleChange = (name, value) => {
    postRequest(authTokens, "SubmitNotificationSettings", {
      // SettingOwner: 1,
      OwnerType: 0,
      NotificationName: `SO${name}`,
      NotificationValue: value,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((response) => {
        if (response.Success) {
          CallToast("Updated successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  };

  function ResetTaskCreationSettings() {
    getAPIData("240", authTokens).then((response) => {
      if (response.Success) {
        LoadPageData();
        // CallToast("Updated successfully", false, false, "v2style");
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }
  return (
    <Row className="m-0">
      {loading && (
        <Col md={12} className="loader-custom">
          <Loader />
        </Col>
      )}
      <Col md={12} className="p-0 mt-1">
        <div className=" mt-3">
          {data
            // .slice(0, data?.length > 0 && data[0].Hidden == 0 ? 2 : 1)
            .map((taskData, taskIndex) => {
              return (
                <>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <div className="">
                      <span className="titletext">
                        {taskData?.SettingGroupTitle}
                      </span>
                      <p className="titletextgrey">
                        {taskData?.SettingSubTitle}
                      </p>
                    </div>

                    <div className="d-flex align-items-center crsrpoint switchbig">
                      <Form.Check
                        type="switch"
                        id={`switchnitification${taskIndex}`}
                        checked={taskData?.SettingStatus}
                        className={`m-0  `}
                        onClick={() => {
                          let updated_data = [...data];

                          updated_data[taskIndex].SettingStatus = 
                            !taskData?.SettingStatus
                          ;
                          setData(updated_data);
                          handleChange(
                            taskData?.SettingGroupTitle.replaceAll(" ", ""),
                            Number(taskData?.SettingStatus)
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="pl-3 pt-3">
                    {taskData?.SettingStatus && (
                      <>
                        {taskData?.ActionCenterSubSettings?.map(
                          (subsetting, subsettingINdex) => (
                            <div
                              className="mr8px position-relative mt-3 mb-3 flex-fill"
                              key={subsettingINdex}
                            >
                              <Form.Group
                                controlId={"check" + subsetting?.SettingId}
                                className="round-checkbox blue-checkbox checkboxstyle"
                              >
                                <Form.Check
                                  type="checkbox"
                                  name={"check" + subsetting?.SettingId}
                                  label=""
                                  checked={subsetting?.SettingValue}
                                  onChange={(e) => {
                                    let updated_data = [...data];

                                    updated_data[
                                      taskIndex
                                    ].ActionCenterSubSettings[
                                      subsettingINdex
                                    ].SettingValue = e.target.checked;
                                    setData(updated_data);
                                    handleChange(
                                      subsetting?.SettingName.replaceAll(
                                        " ",
                                        ""
                                      ),
                                      Number(e.target.checked)
                                    );
                                  }}
                                  // defaultChecked={elmt.StripeId}
                                />
                              </Form.Group>
                              <span className="d-inline-block ml-4 pl-2">
                                {subsetting?.SettingName}
                              </span>
                            </div>
                          )
                        )}
                      </>
                    )}

                  </div>
                  {taskIndex === 0 &&
                   

                    true && <hr className="m-0 mb-3" />}
                </>
              );
            })}

         
        </div>
      </Col>
      <div className="modal_custom_footer">
        <div
          className="crsrpoint mt-2"
          onClick={() => {
           
            ResetTaskCreationSettings();
          }}
        >
          <img alt="" src="/images/actioncenter/reset.svg" height="15" />
          <span style={{ color: "#616778" }}>&nbsp; Reset</span>
        </div>
        <div>
          <Button
            variant="primary"
            type="submit"
            className="float-right securityBtnmodal d-none"
            onClick={closeModal}
          >
            Update
          </Button>
          <Button
            variant="primary"
            type="submit"
            className="float-right action_cancel_btn mr-2 d-none"
            onClick={closeModal}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Row>
  );
};

export default Task;
