import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { getAPIData, postData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import CyberCompliance from "../../CyberCompliance/CyberCompliance";
// import CyberScore from "../../CyberScore/CyberScore";
import CyberScore2 from "../../CyberScore/CyberScore2";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
// import CompanySettingsDropdown from "../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import NormalDropdown from "../../settingsV2/companySettings/companysettingsdrodown/NormalDropdown";
import CustomSlider from "../../settingsV2/notifications/CustomSlider";
import { WhiteLoader } from "../../Theme/APILoaders";
import ThreatLevel from "../../ThreatLevel/ThreatLevel";
import "./addComplianceProfileModal.scss";
import Loader from "../../Common/loader";
import SupplyPopUp from "../SupplyPopUp/SupplyPopUp";
const AddComplianceProfileModal = ({
  show,
  hideModal,
  complianceProfileId,
  refreshData,
}) => {


  const { authTokens } = useAuth();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  // <------------ Pages List------------>
  // cyberscore
  // cyberthreatlevel
  // cybercompliance
  // riskyusers
  // activethreats
  // activerisks
  // cyberinvestment

  // cyberessentials
  // incidentresponseplan
  // infosecpolicies
  // riskassessments
  // bcdrplan
  // annualcyberreviews

  const [page, setPage] = useState("homepage");
  const [header, setHeader] = useState("Add Compliance Profile");
  const [complianceNameError, setComplianceNameError] = useState("");
  let imageSource = {
    0: "/images/supply-chain/compliance-modal/empty-state.svg",
    1: "/images/supply-chain/compliance-modal/unfilled-state.svg",
    2: "/images/supply-chain/compliance-modal/filled-state.svg",
  };

  const [cyberScoreData, setCyberScoreData] = useState({
    // ObjectId: 0,
    // Section: 0,
    // SectionHasValue: true,
    // SectionValue: 0,
    // ScoreAttestation: 3,
    // SelectionOption1: null,
    // SelectionOption2: null,
    // SelectionOption3: null,
    // SelectedOption1: null,
    // SelectedOption2: null,
    // SelectedOption3: null,
  });
  const [cyberThreatLevelData, setCyberThreatLevelData] = useState({
    // ObjectId: 0,
    // Section: 1,
    // SectionHasValue: true,
    // SectionValue: 0,
    // ScoreAttestation: 3,
    // SelectionOption1: null,
    // SelectionOption2: null,
    // SelectionOption3: null,
    // SelectedOption1: null,
    // SelectedOption2: null,
    // SelectedOption3: null,
  });
  const [cyberComplianceData, setCyberComplianceData] = useState({
    // ObjectId: 0,
    // Section: 2,
    // SectionHasValue: true,
    // SectionValue: 0,
    // ScoreAttestation: 3,
    // SelectionOption1: null,
    // SelectionOption2: null,
    // SelectionOption3: null,
    // SelectedOption1: null,
    // SelectedOption2: null,
    // SelectedOption3: null,
  });
  const [riskyUsersData, setRiskyUsersData] = useState({
    // ObjectId: 0,
    // Section: 3,
    // SectionHasValue: true,
    // SectionValue: 0,
    // ScoreAttestation: 3,
    // SelectionOption1: null,
    // SelectionOption2: null,
    // SelectionOption3: null,
    // SelectedOption1: null,
    // SelectedOption2: null,
    // SelectedOption3: null,
  });
  const [activeThreatsData, setActiveThreatsData] = useState({
    // ObjectId: 0,
    // Section: 4,
    // SectionHasValue: true,
    // SectionValue: 0,
    // ScoreAttestation: 3,
    // SelectionOption1: null,
    // SelectionOption2: null,
    // SelectionOption3: null,
    // SelectedOption1: null,
    // SelectedOption2: null,
    // SelectedOption3: null,
  });
  const [activeRisksData, setActiveRisksData] = useState({
    // ObjectId: 0,
    // Section: 5,
    // SectionHasValue: true,
    // SectionValue: 0,
    // ScoreAttestation: 3,
    // SelectionOption1: null,
    // SelectionOption2: null,
    // SelectionOption3: null,
    // SelectedOption1: null,
    // SelectedOption2: null,
    // SelectedOption3: null,
  });
  const [cyberInvestmentData, setCyberInvestmentData] = useState({
    // ObjectId: 0,
    // Section: 6,
    // SectionHasValue: true,
    // SectionValue: 0,
    // ScoreAttestation: 3,
    // SelectionOption1: null,
    // SelectionOption2: null,
    // SelectionOption3: null,
    // SelectedOption1: null,
    // SelectedOption2: null,
    // SelectedOption3: null,
  });
  const [cyberEssentialsData, setCyberEssentialsData] = useState({
    // ObjectId: 0,
    // Section: 7,
    // SectionHasValue: true,
    // SectionValue: 0,
    // ScoreAttestation: 0,
    // SelectionOption1: [
    //   {
    //     Id: 3347862,
    //     value: "Plus",
    //     SectionDDLName: "CyberEssentialsCertificationLevel",
    //     SectionDDLType: "DropDown",
    //     SortOrder: 1,
    //   },
    // ],
    // SelectionOption2: [
    //   {
    //     Id: 3347863,
    //     value: "Up to date",
    //     SectionDDLName: "CyberEssentialsCertificationStatus",
    //     SectionDDLType: "DropDown",
    //     SortOrder: 1,
    //   },
    // ],
    // SelectionOption3: [
    //   {
    //     Id: 3347864,
    //     value: "Quarterly",
    //     SectionDDLName: "CyberEssentialsAuditFrequency",
    //     SectionDDLType: "DropDown",
    //     SortOrder: 1,
    //   },
    // ],
    // SelectedOption1: null,
    // SelectedOption2: null,
    // SelectedOption3: null,
  });
  const [incidenceResponsePlanData, setIncidenceResponsePlanData] = useState({
    // ObjectId: 0,
    // Section: 8,
    // SectionHasValue: false,
    // SectionValue: 0,
    // ScoreAttestation: 0,
    // SelectionOption1: [
    //   {
    //     Id: 3348560,
    //     value: "Incident Response Vision & Mission",
    //     SectionDDLName: "IncidentResponsePlanDocs",
    //     SectionDDLType: "CheckBox",
    //     SortOrder: 1,
    //   },
    //   {
    //     Id: 3348561,
    //     value: "Internet Acceptable Use Policy",
    //     SectionDDLName: "IncidentResponsePlanDocs",
    //     SectionDDLType: "CheckBox",
    //     SortOrder: 2,
    //   },
    // ],
    // SelectionOption2: [
    //   {
    //     Id: 3347868,
    //     value: "Monthly",
    //     SectionDDLName: "IncidentResponseReview",
    //     SectionDDLType: "DropDown",
    //     SortOrder: 1,
    //   },
    // ],
    // SelectionOption3: [],
    // SelectedOption1: [3348560, 3348561],
    // SelectedOption2: [3347868],
    // SelectedOption3: null,
  });

  const [infosecPoliciesData, setInfosecPoliciesData] = useState({
    // ObjectId: 0,
    // Section: 9,
    // SectionHasValue: false,
    // SectionValue: 0,
    // ScoreAttestation: 0,
    // SelectionOption1: [
    //   {
    //     Id: 3347871,
    //     value: "IT Acceptable Use Policy",
    //     SectionDDLName: "RecommendedPolicies",
    //     SectionDDLType: "CheckBox",
    //     SortOrder: 1,
    //   },
    //   {
    //     Id: 3347872,
    //     value: "Remote Access Policy",
    //     SectionDDLName: "RecommendedPolicies",
    //     SectionDDLType: "CheckBox",
    //     SortOrder: 2,
    //   },
    //   {
    //     Id: 3347873,
    //     value: "Remote Working Policies",
    //     SectionDDLName: "RecommendedPolicies",
    //     SectionDDLType: "CheckBox",
    //     SortOrder: 3,
    //   },
    //   {
    //     Id: 3347874,
    //     value: "Mobile Device Policy",
    //     SectionDDLName: "RecommendedPolicies",
    //     SectionDDLType: "CheckBox",
    //     SortOrder: 4,
    //   },
    //   {
    //     Id: 3347875,
    //     value: "Internet Accepatable Use",
    //     SectionDDLName: "RecommendedPolicies",
    //     SectionDDLType: "CheckBox",
    //     SortOrder: 5,
    //   },
    // ],
    // SelectionOption2: [
    //   {
    //     Id: 3347876,
    //     value: "Password Policy",
    //     SectionDDLName: "CyberEssentialsPolicies",
    //     SectionDDLType: "CheckBox",
    //     SortOrder: 1,
    //   },
    //   {
    //     Id: 3347877,
    //     value: "Work From Home Policy",
    //     SectionDDLName: "CyberEssentialsPolicies",
    //     SectionDDLType: "CheckBox",
    //     SortOrder: 2,
    //   },
    //   {
    //     Id: 3347878,
    //     value: "Mobile Device Register",
    //     SectionDDLName: "CyberEssentialsPolicies",
    //     SectionDDLType: "CheckBox",
    //     SortOrder: 3,
    //   },
    //   {
    //     Id: 3347879,
    //     value: "User Account Creation",
    //     SectionDDLName: "CyberEssentialsPolicies",
    //     SectionDDLType: "CheckBox",
    //     SortOrder: 4,
    //   },
    //   {
    //     Id: 3347880,
    //     value: "Firewall Documentation",
    //     SectionDDLName: "CyberEssentialsPolicies",
    //     SectionDDLType: "CheckBox",
    //     SortOrder: 5,
    //   },
    //   {
    //     Id: 3347881,
    //     value: "Mobile Device Policy",
    //     SectionDDLName: "CyberEssentialsPolicies",
    //     SectionDDLType: "CheckBox",
    //     SortOrder: 6,
    //   },
    // ],
    // SelectionOption3: [],
    // SelectedOption1: null,
    // SelectedOption2: null,
    // SelectedOption3: null,
  });

  const [riskAssessmentsData, setRiskAssessmentsData] = useState({
    // ObjectId: 0,
    // Section: 10,
    // SectionHasValue: false,
    // SectionValue: 0,
    // ScoreAttestation: 0,
    // SelectionOption1: [
    //   {
    //     Id: 3347882,
    //     value: "Monthly",
    //     SectionDDLName: "RiskAssessmentsFrequency",
    //     SectionDDLType: "DropDown",
    //     SortOrder: 1,
    //   },
    // ],
    // SelectionOption2: [],
    // SelectionOption3: [],
    // SelectedOption1: null,
    // SelectedOption2: null,
    // SelectedOption3: null,
  });
  const [bcdrPlanData, setBcdrPlanData] = useState({
    // ObjectId: 0,
    // Section: 11,
    // SectionHasValue: false,
    // SectionValue: 0,
    // ScoreAttestation: 0,
    // SelectionOption1: [
    //   {
    //     Id: 3347883,
    //     value: "Up to date",
    //     SectionDDLName: "ITBCDRPlan",
    //     SectionDDLType: "DropDown",
    //     SortOrder: 1,
    //   },
    // ],
    // SelectionOption2: [
    //   {
    //     Id: 3347884,
    //     value: "Monthly",
    //     SectionDDLName: "TestRecoveryFrequency",
    //     SectionDDLType: "DropDown",
    //     SortOrder: 2,
    //   },
    // ],
    // SelectionOption3: [],
    // SelectedOption1: null,
    // SelectedOption2: null,
    // SelectedOption3: null,
  });
  const [annualCyberReviewsData, setAnnualCyberReviewsData] = useState({
    // ObjectId: 0,
    // Section: 12,
    // SectionHasValue: false,
    // SectionValue: 0,
    // ScoreAttestation: 0,
    // SelectionOption1: [
    //   {
    //     Id: 3347885,
    //     value: "Annual",
    //     SectionDDLName: "AnnualCyberReviewsFrequency",
    //     SectionDDLType: "DropDown",
    //     SortOrder: 1,
    //   },
    // ],
    // SelectionOption2: [],
    // SelectionOption3: [],
    // SelectedOption1: null,
    // SelectedOption2: null,
    // SelectedOption3: null,
  });

  const [attackSurfaceData, setAttackSurfaceData] = useState({});

  function ResetModalState() {
    setCyberScoreData({});
    setCyberThreatLevelData({});
    setCyberComplianceData({});
    setRiskyUsersData({});
    setActiveThreatsData({});
    setActiveRisksData({});
    setCyberInvestmentData({});
    setCyberEssentialsData({});
    setIncidenceResponsePlanData({});
    setInfosecPoliciesData({});
    setRiskAssessmentsData({});
    setBcdrPlanData({});
    setAnnualCyberReviewsData({});
    setAttackSurfaceData({});
  }

  // API SETUP ================================================================
  const [sendNotificationStatus, setSendNotificationStatus] = useState(false);
  const [objectId, setObjectId] = useState(0);
  const [complianceProfileName, setComplianceProfileName] = useState("");
  useEffect(() => {
    if (show) {
      setSaving(false);
      setObjectId(complianceProfileId ?? 0);
      setComplianceNameError("");
      setComplianceProfileName("");
      setSendNotificationStatus(false);
      setPage("homepage");
      if (complianceProfileId > 0) {
        getComplianceProfileData();
        setHeader("Edit Compliance Profile");
      } else {
        ResetModalState();
        setHeader("Add Compliance Profile");
      }
    }
  }, [show, complianceProfileId]);

  function fillData(sectionId, data) {
    switch (sectionId) {
      case 0:
        setCyberScoreData(data);
        break;
      case 1:
        setCyberThreatLevelData(data);
        break;
      case 2:
        setCyberComplianceData(data);
        break;
      case 3:
        setRiskyUsersData(data);
        break;
      case 4:
        setActiveThreatsData(data);
        break;
      case 5:
        setActiveRisksData(data);
        break;
      case 6:
        setCyberInvestmentData(data);
        break;
      case 7:
        setCyberEssentialsData(data);
        break;
      case 8:
        setIncidenceResponsePlanData(data);
        break;
      case 9:
        setInfosecPoliciesData(data);
        break;
      case 10:
        setRiskAssessmentsData(data);
        break;
      case 11:
        setBcdrPlanData(data);
        break;
      case 12:
        setAnnualCyberReviewsData(data);
        break;
      case 13:
        setAttackSurfaceData(data);
        break;

      default:
        console.log(`Section ID doesnt exist`);
    }
  }

  const allSectionData = [


    cyberScoreData,
    cyberThreatLevelData,
    cyberComplianceData,
    riskyUsersData,
    activeThreatsData,
    activeRisksData,
    cyberInvestmentData,
    cyberEssentialsData,
    incidenceResponsePlanData,
    infosecPoliciesData,
    riskAssessmentsData,
    bcdrPlanData,
    annualCyberReviewsData,
    attackSurfaceData,
  ];

  async function createEditComplianceProfile() {
    setSaving(true);
    await postData(authTokens, "CreateComplianceProfile", {
      ObjectId: objectId,
      ProfileName: complianceProfileName,
      ProfileSection: JSON.stringify(allSectionData?.filter((item)=>Object.keys(item)?.length >0)),
      SendNotifications: sendNotificationStatus,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((response) => {
        setSaving(false);

        if (response.Success) {
          if (complianceProfileId > 0) {
            CallToast("Updated successfully", false, false, "v2style");
          } else {
            CallToast("Profile created successfully", false, false, "v2style");
          }

          hideModal();
          setComplianceNameError("");
          setComplianceProfileName("");
          if (refreshData) {
            refreshData();
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        setSaving(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // Function to Update Compliance Profile
  async function updateComplianceProfile(page) {
    let sectionData = getCurrentPageData(page);

    setSaving(true);
    await postData(authTokens, "CreateComplianceProfile", {
      ObjectId: objectId,
      ProfileName: complianceProfileName,
      ProfileSection: JSON.stringify([sectionData]),
      SendNotifications: sendNotificationStatus,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((response) => {
        if (response?.Success) {
          CallToast("Updated successfully", false, false, "v2style");
          setSaving(false);
          goBackToHomePage();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
          setSaving(false);
        }
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, "v2style");
        setSaving(false);
      });
  }

  // Function to get compliance Profile Data
  async function getComplianceProfileData() {
    setLoading(true);
    await getAPIData("306", authTokens, {
      optionEnum1: complianceProfileId,
    })
      .then((response) => {
        setLoading(false);
        if (response?.mr?.Success) {
          setComplianceProfileName(response?.ProfileName);
          setSendNotificationStatus(response?.SendNotifications);
          if (response?.ProfileSection?.length > 0) {
            for (let i = 0; i < response?.ProfileSection?.length; i++) {
              fillData(
                response?.ProfileSection[i].Section,
                response?.ProfileSection[i]
              );
            }
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        setLoading(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // Function to get compliance section wise Data

  async function getComplianceSectionData(sectionID = 0, sectionObjectID = 0) {
    setLoading(true);
    await getAPIData("307", authTokens, {
      optionEnum1: sectionID,
      optionEnum2: sectionObjectID,
    })
      .then((response) => {
        if (response?.mr?.Success) {
          const { mr, ...rest } = response;
          fillData(sectionID, rest);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  // API SETUP END----xxxxx xxxxxxxx xxxxxxxxxxx xxxxxxxxxx xxxxxxxxxx xxxxxxxx xxxxxx
  function ToggleAccordion(e, work = true) {
    // if (!work) return;
    var parent = e.target.closest(".point");
    var panel = parent.querySelector(".panel");
    if (panel?.style?.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }

  // get data for current Page
  function getCurrentPageData(page) {
    switch (page) {
      case "cyberscore":
        return cyberScoreData;

      case "cyberthreatlevel":
        return cyberThreatLevelData;
      case "cybercompliance":
        return cyberComplianceData;

      case "riskyusers":
        return riskyUsersData;
      case "activethreats":
        return activeThreatsData;

      case "activerisks":
        return activeRisksData;
      case "cyberinvestment":
        return cyberInvestmentData;

      case "cyberessentials":
        return cyberEssentialsData;
      case "incidentresponseplan":
        return incidenceResponsePlanData;

      case "infosecpolicies":
        return infosecPoliciesData;
      case "riskassessments":
        return riskAssessmentsData;

      case "bcdrplan":
        return bcdrPlanData;
      case "annualcyberreviews":
        return annualCyberReviewsData;

      case "attackSurface":
        return attackSurfaceData;

      default:
        return {};
    }
  }

  // Toggle the Selection In Attack Surface
  function toggleSelectedList(list1, list2, selectedkeyList, selectionkeyList) {
    let selectedLength = 0;
    let TotalLength = 0;
    for (let i = 0; i < list1.length; i++) {
      selectedLength += Number(list1[i]?.length ?? 0);
      TotalLength += Number(list2[i]?.length ?? 0);
    }

    if (
      (selectedLength === 0 || selectedLength > 0) &&
      selectedLength < TotalLength
    ) {
      let attack_data = { ...attackSurfaceData };
      for (let i = 0; i < list1.length; i++) {
        let all_Ids = attack_data[selectionkeyList[i]].map((item) => {
          return item?.Id;
        });
        attack_data[selectedkeyList[i]] = all_Ids;
      }
      setAttackSurfaceData(attack_data);
    } else {
      let attack_data = { ...attackSurfaceData };
      for (let i = 0; i < list1.length; i++) {
        attack_data[selectedkeyList[i]] = [];
      }
      setAttackSurfaceData(attack_data);
    }
  }

  function goBackToHomePage() {
    if (page === "homepage") {
      hideModal();
      setComplianceNameError("");
      setComplianceProfileName("");
    } else {
      if (complianceProfileId > 0) {
        setPage("homepage");
        getComplianceProfileData();
        setHeader("Edit Compliance Profile");
      } else {
        setPage("homepage");
        setHeader("Add Compliance Profile");
      }
    }
  }

  return (
    <Modal
      show={show}
      dialogClassName="add-compliance-profile-modal"
      className=" "
      aria-labelledby="add-compliance-profile-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Header className="text-center">
        {page !== "homepage" && (
          <img
            alt=""
            className="pointer"
            src="/images/supply-chain/chevron-left.svg"
            onClick={() => {
              goBackToHomePage();
            }}
          />
        )}

        <div className="w-100 mb-0 f-600">{header}</div>

        <span
          className="cursor-pointer"
          onClick={() => {
            hideModal();
            setComplianceNameError("");
            setComplianceProfileName("");
          }}
        >
          <img alt="" src="/images/icons/close.svg" className="close-icon" />
        </span>
      </Modal.Header>
      <Modal.Body className="hide-scrollbar">
        {loading ? (
          <div className="loader d-flex align-items-center justify-content-center h-100 w-100">
            <Loader />
          </div>
        ) : (
          <>
            {page === "homepage" && (
              <div className="homepage">
                <div>Name</div>
                <input
                  id="compliance-profile-name"
                  className="complaince-profile-input w-100"
                  type="text"
                  placeholder="Compliance profile name"
                  value={complianceProfileName}
                  onChange={(e) => {
                    const inputText = e.target.value;
                    const regex = /^[a-zA-Z\s]*$/; // Regex pattern allowing only characters and spaces

                    if (regex.test(inputText)) {
                      setComplianceProfileName(inputText);
                      setComplianceNameError("");
                    } else {
                      //  setComplianceNameError("Only characters and spaces are allowed.");
                    }
                  }}
                />
                {complianceNameError && (
                  <span className="f-12 f-danger ml-1">
                    Please fill compliance profile name
                  </span>
                )}

                {/* notification toggle */}
                <div className="notifications d-flex justify-content-between mt-3">
                  <div className="d-flex align-items-start">
                    <img
                      alt=""
                      src="/images/supply-chain/notification-icon.svg"
                      className="mt-1 mr-2"
                    />
                    <div className="w-75">
                      <p className="f-500 m-0">Notifications</p>
                      <p className="f-12 f-grey">
                        Get notifications when suppliers associated with this
                        compliance profile become non-compliant
                      </p>
                    </div>
                  </div>
                  <div>
                    <Form.Group controlId={"check"} className="scale-switch">
                      <Form.Check
                        type="switch"
                        id="switch-ques"
                        className="m-0 scale-switch switch-shadow-none"
                        checked={sendNotificationStatus}
                        onChange={(e) => {
                          setSendNotificationStatus(e.target.checked);
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>
                {/* Threshold header */}
                <div className="threshold-header mt-3 mx-2">
                  <p className="m-0 f-500 f-grey">
                    <strong>Thresholds</strong>
                  </p>
                  <p className="f-12 f-darkgrey">
                    Set compliance requirements for suppliers associated with
                    this compliance profile. Suppliers will be flagged as
                    ‘non-compliant’ if they fall outside of these paramaters.
                  </p>
                </div>
                {/* list start */}
                {/* Cyber Score */}
                <div
                  className="complaince-profile-option p-3 mt-2"
                  onClick={() => {
                    getComplianceSectionData(0, cyberScoreData?.ObjectId ?? 0);
                    setPage("cyberscore");
                    setHeader("CyberScore");
                  }}
                >
                  <p className="f-500 f-black m-0">CyberScore</p>
                  <p className="f-14 f-grey">
                    {complianceProfileId > 0 || cyberScoreData?.SectionHasValue
                      ? `${cyberScoreData?.SectionValue ?? 0}${
                          cyberScoreData?.SelectionSummary
                            ? `, ${cyberScoreData?.SelectionSummary}`
                            : " "
                        }`
                      : "Max value"}
                  </p>
                </div>
                <div
                  className="complaince-profile-option p-3"
                  onClick={() => {
                    getComplianceSectionData(
                      1,
                      cyberThreatLevelData?.ObjectId ?? 0
                    );
                    setPage("cyberthreatlevel");
                    setHeader("CyberThreat Level");
                  }}
                >
                  <p className="f-500 f-black m-0">CyberThreat Level</p>
                  <p className="f-14 f-grey">
                    {complianceProfileId > 0 ||
                    cyberThreatLevelData?.SectionHasValue
                      ? `${cyberThreatLevelData?.SectionValue ?? 0}${
                          cyberThreatLevelData?.SelectionSummary
                            ? `, ${cyberThreatLevelData?.SelectionSummary}`
                            : " "
                        }`
                      : "Max value"}
                  </p>
                </div>
                <div
                  className="complaince-profile-option p-3"
                  onClick={() => {
                    getComplianceSectionData(
                      2,
                      cyberComplianceData?.ObjectId ?? 0
                    );
                    setPage("cybercompliance");
                    setHeader("CyberCompliance");
                  }}
                >
                  <p className="f-500 f-black m-0">CyberCompliance</p>
                  <p className="f-14 f-grey">
                    {complianceProfileId > 0 ||
                    cyberComplianceData?.SectionHasValue
                      ? `${cyberComplianceData?.SectionValue ?? 0}${
                          cyberComplianceData?.SelectionSummary
                            ? `, ${cyberComplianceData?.SelectionSummary}`
                            : " "
                        }`
                      : "Max value"}
                  </p>
                </div>
                <div
                  className="complaince-profile-option p-3"
                  onClick={() => {
                    getComplianceSectionData(3, riskyUsersData?.ObjectId ?? 0);
                    setPage("riskyusers");
                    setHeader("Risky Users");
                  }}
                >
                  <p className="f-500 f-black m-0">Risky Users</p>
                  <p className="f-14 f-grey">
                    {complianceProfileId > 0 || riskyUsersData?.SectionHasValue
                      ? `${riskyUsersData?.SectionValue ?? 0}${
                          riskyUsersData?.SelectionSummary
                            ? `, ${riskyUsersData?.SelectionSummary}`
                            : " "
                        }`
                      : "Max value"}
                  </p>
                </div>
                <div
                  className="complaince-profile-option p-3"
                  onClick={() => {
                    getComplianceSectionData(
                      4,
                      activeThreatsData?.ObjectId ?? 0
                    );
                    setPage("activethreats");
                    setHeader("Active Threats");
                  }}
                >
                  <p className="f-500 f-black m-0">Active Threats</p>
                  <p className="f-14 f-grey">
                    {complianceProfileId > 0 ||
                    activeThreatsData?.SectionHasValue
                      ? `${activeThreatsData?.SectionValue ?? 0}${
                          activeThreatsData?.SelectionSummary
                            ? `, ${activeThreatsData?.SelectionSummary}`
                            : " "
                        }`
                      : "Max value"}
                  </p>
                </div>
                <div
                  className="complaince-profile-option p-3"
                  onClick={() => {
                    getComplianceSectionData(5, activeRisksData?.ObjectId ?? 0);
                    setPage("activerisks");
                    setHeader("Active Risks");
                  }}
                >
                  <p className="f-500 f-black m-0">Active Risks</p>
                  <p className="f-14 f-grey">
                    {complianceProfileId > 0 || activeRisksData?.SectionHasValue
                      ? `${activeRisksData?.SectionValue ?? 0}${
                          activeRisksData?.SelectionSummary
                            ? `, ${activeRisksData?.SelectionSummary}`
                            : " "
                        }`
                      : "Min value"}
                  </p>
                </div>
                <div
                  className="complaince-profile-option p-3"
                  onClick={() => {
                    getComplianceSectionData(
                      6,
                      cyberInvestmentData?.ObjectId ?? 0
                    );
                    setPage("cyberinvestment");
                    setHeader("CyberInvestment");
                  }}
                >
                  <p className="f-500 f-black m-0">CyberInvestment</p>
                  <p className="f-14 f-grey">
                    {complianceProfileId > 0 ||
                    cyberInvestmentData?.SectionHasValue
                      ? `£${cyberInvestmentData?.SectionValue ?? 0}${
                          cyberInvestmentData?.SelectionSummary
                            ? `, ${cyberInvestmentData?.SelectionSummary}`
                            : ""
                        }`
                      : "Min value"}
                  </p>
                </div>

                {/* Required health header */}
                <div className="my-2 mx-2">
                  <p className="m-0 f-grey">
                    <strong>Required Health & Audit Frequency</strong>
                  </p>
                  <p className="f-12 f-darkgrey">
                    Set the desired update frequency for compliance manager
                    components
                  </p>
                </div>
                <div
                  className="complaince-profile-option p-3"
                  onClick={() => {
                    getComplianceSectionData(
                      7,
                      cyberEssentialsData?.ObjectId ?? 0
                    );
                    setPage("cyberessentials");
                    setHeader("Cyber Essentials");
                  }}
                >
                  <p className="f-500 f-black m-0">Cyber Essentials</p>
                  <p className="f-14 f-grey">
                    {complianceProfileId > 0
                      ? cyberEssentialsData?.SelectionSummary
                      : "Min value"}
                  </p>
                </div>
                <div
                  className="complaince-profile-option p-3"
                  onClick={() => {
                    getComplianceSectionData(
                      8,
                      incidenceResponsePlanData?.ObjectId ?? 0
                    );
                    setPage("incidentresponseplan");
                    setHeader("Incident Response Plan");
                  }}
                >
                  <p className="f-500 f-black m-0">Incident Response Plan</p>
                  <p className="f-14 f-grey">
                    {complianceProfileId > 0
                      ? incidenceResponsePlanData?.SelectionSummary
                      : "Min value"}
                  </p>
                </div>
                <div
                  className="complaince-profile-option p-3"
                  onClick={() => {
                    getComplianceSectionData(
                      9,
                      infosecPoliciesData?.ObjectId ?? 0
                    );
                    setPage("infosecpolicies");
                    setHeader("InfoSec Policies");
                  }}
                >
                  <p className="f-500 f-black m-0">InfoSec Policies</p>
                  <p className="f-14 f-grey">
                    {complianceProfileId > 0
                      ? infosecPoliciesData?.SelectionSummary
                      : "Min value"}
                  </p>
                </div>
                <div
                  className="complaince-profile-option p-3"
                  onClick={() => {
                    getComplianceSectionData(
                      10,
                      riskAssessmentsData?.ObjectId ?? 0
                    );
                    setPage("riskassessments");
                    setHeader("Risk Assessments");
                  }}
                >
                  <p className="f-500 f-black m-0">Risk Assessments</p>
                  <p className="f-14 f-grey">
                    {complianceProfileId > 0
                      ? riskAssessmentsData?.SelectionSummary
                      : "Min value"}
                  </p>
                </div>
                <div
                  className="complaince-profile-option p-3"
                  onClick={() => {
                    getComplianceSectionData(11, bcdrPlanData?.ObjectId ?? 0);
                    setPage("bcdrplan");
                    setHeader("BCDR Plan");
                  }}
                >
                  <p className="f-500 f-black m-0">BCDR Plan</p>
                  <p className="f-14 f-grey">
                    {complianceProfileId > 0
                      ? bcdrPlanData?.SelectionSummary
                      : "Min value"}
                  </p>
                </div>
                <div
                  className="complaince-profile-option p-3"
                  onClick={() => {
                    getComplianceSectionData(
                      12,
                      annualCyberReviewsData?.ObjectId ?? 0
                    );
                    setPage("annualcyberreviews");
                    setHeader("Annual Cyber Reviews");
                  }}
                >
                  <p className="f-500 f-black m-0">Annual Cyber Reviews</p>
                  <p className="f-14 f-grey">
                    {complianceProfileId > 0
                      ? annualCyberReviewsData?.SelectionSummary
                      : "Min value"}
                  </p>
                </div>

                <div
                  className="complaince-profile-option p-3"
                  onClick={() => {
                    getComplianceSectionData(
                      13,
                      attackSurfaceData?.ObjectId ?? 0
                    );
                    setPage("attackSurface");
                    setHeader("Attack Surface");
                  }}
                >
                  <p className="f-500 f-black m-0">Attack Surface</p>
                  <p className="f-14 f-grey">
                    {complianceProfileId > 0
                      ? attackSurfaceData?.SelectionSummary ?? ""
                      : "Min value"}
                  </p>
                </div>
              </div>
            )}
            {page == "cyberscore" && (
              <div className="cyberscore">
                <div className="head d-flex align-items-center justify-content-center position-relative">
                  <div className="graph">
                    <CyberScore2
                      key={0}
                      size="small"
                      scoreNumber={cyberScoreData?.SectionValue}
                      dataStroke={18}
                      bottomText={false}
                      Width="180"
                      Height="180"
                      hideLabels={false}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-start my-2">
                  <div>
                    <p className="f-black m-0 f-500">CyberScore Thresholds</p>
                    <p className="f-grey f-12 w-75">
                      Set the minimum CyberScore and data attestation level that
                      suppliers must maintain.
                    </p>
                  </div>
                  <div>
                    <Form.Group controlId={"check"} className="scale-switch">
                      <Form.Check
                        type="switch"
                        id="switch-ques"
                        className="m-0 scale-switch switch-shadow-none"
                        checked={cyberScoreData?.SectionHasValue}
                        onChange={(e) => {
                          setCyberScoreData({
                            ...cyberScoreData,
                            SectionHasValue: e.target.checked,
                          });
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>
                {cyberScoreData?.SectionHasValue && (
                  <>
                    <div className="slider-wrapper d-flex align-items-center justify-content-between">
                      <CustomSlider
                        min={1}
                        max={100}
                        step={1}
                        id="cyber-score-notification-slider"
                        onChange={(e) => {
                          // setCyberScore(e.target.value);
                        }}
                        onMouseUp={(e) => {
                          setCyberScoreData({
                            ...cyberScoreData,
                            SectionValue: e.target.value,
                          });
                        }}
                        defaultValue={cyberScoreData?.SectionValue}
                      />
                      <div className="d-flex align-items-center bg-grey radius-2 ml-2 flex-grow-1 p-2 btn-typ ">
                        <img
                          alt=""
                          src="/images/supply-chain/minValue-icon.svg"
                          className="mr-2"
                        />
                        <div className="f-500 f-black">
                          Min {cyberScoreData?.SectionValue}
                        </div>
                      </div>
                    </div>
                    <div className="data-attestation d-flex align-items-center justify-content-between mt-3">
                      <div>
                        <p className="f-black m-0 f-500 d-flex">
                          Data Attestation
                          <SupplyPopUp
                            header={"Data Attestation"}
                            dataList={Object.keys(
                              cyberScoreData?.DataAttestationInfo
                            )?.map((permission, permissionIndex) => {
                              return {
                                title: permission,
                                subtitle:
                                  cyberScoreData?.DataAttestationInfo[
                                    permission
                                  ],
                              };
                            })}
                          >
                            <img
                              alt=""
                              src="/images/supply-chain/dark-info-icon.svg"
                              className="ml-2"
                            />
                          </SupplyPopUp>
                        </p>
                        <p className="f-14 f-grey"> Choose verification data</p>
                      </div>
                      <div>
                        <NormalDropdown
                          fieldId={"id"}
                          placeholder={"Choose.."}
                          fieldValue={"category"}
                          selected={
                            cyberScoreData?.ScoreAttestation < 2
                              ? null
                              : cyberScoreData?.ScoreAttestation
                          }
                          data={[
                            // { id: 0, category: "Not answered" },
                            // { id: 1, category: "No capability" },
                            { id: 2, category: "Unverified" },
                            { id: 3, category: "Verified" },
                            { id: 4, category: "Part-Verified" },
                          ]}
                          onClick={(id, val) => {
                            setCyberScoreData({
                              ...cyberScoreData,
                              ScoreAttestation: id,
                              SelectionSummary: val,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            {page == "cyberthreatlevel" && (
              <div className="cyberscore">
                <div className="head d-flex align-items-center justify-content-center position-relative">
                  <div className="graph left-17rem">
                    <ThreatLevel
                      padding={false}
                      threatHeight="160px"
                      threatText={50}
                      removeBottom={true}
                      threatNumber={Math.ceil(
                        mapRange(cyberThreatLevelData?.SectionValue)
                      )}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-start my-2">
                  <div>
                    <p className="f-black m-0 f-500">
                      CyberThreat Level Thresholds
                    </p>
                    <p className="f-grey f-12 w-75">
                      Set the maximum CyberThreat Level and data attestation
                      level that suppliers must maintain.
                    </p>
                  </div>
                  <div>
                    <Form.Group controlId={"check"} className="scale-switch">
                      <Form.Check
                        type="switch"
                        id="switch-ques"
                        className="m-0 scale-switch switch-shadow-none"
                        checked={cyberThreatLevelData?.SectionHasValue}
                        onChange={(e) => {
                          setCyberThreatLevelData({
                            ...cyberThreatLevelData,

                            SectionHasValue: e.target.checked,
                          });
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>

                {cyberThreatLevelData?.SectionHasValue && (
                  <>
                    <div className="slider-wrapper d-flex align-items-center justify-content-between">
                      <CustomSlider
                        min={1}
                        max={145}
                        step={1}
                        id="cyber-score-notification-slider"
                        onChange={(e) => {}}
                        onMouseUp={(e) => {
                          setCyberThreatLevelData({
                            ...cyberThreatLevelData,

                            SectionValue: e.target.value,
                          });
                        }}
                        // onMouseUp={
                        // }
                        defaultValue={cyberThreatLevelData?.SectionValue}
                      />
                      <div className="d-flex align-items-center bg-grey radius-2 ml-2 flex-grow-1 p-2 btn-typ ">
                        <img
                          alt=""
                          src="/images/supply-chain/minValue-icon.svg"
                          className="mr-2"
                        />
                        <div
                          className="f-500 f-black"
                          style={{ width: "inherit" }}
                        >
                          Max {cyberThreatLevelData?.SectionValue}
                        </div>
                      </div>
                    </div>
                    <div className="data-attestation d-flex align-items-center justify-content-between mt-3">
                      <div>
                        <p className="f-black m-0 f-500 d-flex">
                          Data Attestation
                          <SupplyPopUp
                            header={"Data Attestation"}
                            dataList={Object.keys(
                              cyberThreatLevelData?.DataAttestationInfo
                            )?.map((permission, permissionIndex) => {
                              return {
                                title: permission,
                                subtitle:
                                  cyberThreatLevelData?.DataAttestationInfo[
                                    permission
                                  ],
                              };
                            })}
                          >
                            <img
                              alt=""
                              src="/images/supply-chain/dark-info-icon.svg"
                              className="ml-2"
                            />
                          </SupplyPopUp>
                        </p>
                        <p className="f-14 f-grey"> Choose verification data</p>
                      </div>
                      <div>
                        <NormalDropdown
                          fieldId={"id"}
                          fieldValue={"category"}
                          placeholder={"Choose.."}
                          selected={
                            cyberThreatLevelData?.ScoreAttestation < 2
                              ? null
                              : cyberThreatLevelData?.ScoreAttestation
                          }
                          data={[
                            // { id: 0, category: "Not answered" },
                            // { id: 1, category: "No capability" },
                            { id: 2, category: "Unverified" },
                            { id: 3, category: "Verified" },
                            { id: 4, category: "Part-Verified" },
                          ]}
                          onClick={(id, val) => {
                            setCyberThreatLevelData({
                              ...cyberThreatLevelData,
                              ScoreAttestation: id,
                              SelectionSummary: val,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            {page == "cybercompliance" && (
              <div className="cyberscore">
                <div className="head d-flex align-items-center justify-content-center position-relative">
                  <div className="graph complianceGraph ">
                    <CyberCompliance
                      ccNumber={cyberComplianceData?.SectionValue}
                      dataStroke={20}
                      fontSize={22}
                      id="cyber-comliance"
                      wh={180}
                      whpx="180px"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-start my-2">
                  <div>
                    <p className="f-black m-0 f-500">
                      CyberCompliance Thresholds
                    </p>
                    <p className="f-grey f-12 w-75">
                      Set the minimum CyberCompliance Level and data attestation
                      level that suppliers must maintain.
                    </p>
                  </div>
                  <div>
                    <Form.Group controlId={"check"} className="scale-switch">
                      <Form.Check
                        type="switch"
                        id="switch-ques"
                        className="m-0 scale-switch switch-shadow-none"
                        checked={cyberComplianceData?.SectionHasValue}
                        onChange={(e) => {
                          setCyberComplianceData({
                            ...cyberComplianceData,
                            SectionHasValue: e.target.checked,
                          });
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>

                {cyberComplianceData?.SectionHasValue && (
                  <>
                    <div className="slider-wrapper d-flex align-items-center justify-content-between">
                      <CustomSlider
                        min={1}
                        max={100}
                        step={1}
                        id="cyber-score-notification-slider"
                        onChange={(e) => {}}
                        onMouseUp={(e) => {
                          setCyberComplianceData({
                            ...cyberComplianceData,
                            SectionValue: e.target.value,
                          });
                        }}
                        defaultValue={cyberComplianceData?.SectionValue}
                      />
                      <div className="d-flex align-items-center bg-grey radius-2 ml-2 flex-grow-1 p-2 btn-typ ">
                        <img
                          alt=""
                          src="/images/supply-chain/minValue-icon.svg"
                          className="mr-2"
                        />
                        <div className="f-500 f-black">
                          Min {cyberComplianceData?.SectionValue}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center  mt-4 ml-2 f-12 f-grey">
                      <img
                        className="mr-2"
                        alt=""
                        src="/images/supply-chain/grey-info.svg"
                      />
                      <div>
                        This KPI cannot support verified data, as it must be
                        defined by the supplier
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            {page == "riskyusers" && (
              <div className="cyberscore">
                <div className="head d-flex flex-column align-items-center justify-content-center position-relative">
                  {/* <div className="graph "> */}
                  <div className="f-black f-500">
                    <img
                      alt=""
                      src="/images/supply-chain/riskyusers.svg"
                      className="mr-2"
                    />
                    {riskyUsersData?.SectionValue}%
                  </div>
                  <img
                    alt=""
                    src="/images/supply-chain/blue-underscore.svg"
                    className="mr-2 my-2"
                  />
                  <div className="f-grey">Risky Users</div>
                  {/* </div> */}
                </div>
                <div className="d-flex justify-content-between align-items-start my-2">
                  <div>
                    <p className="f-black m-0 f-500">Risky Users Thresholds</p>
                    <p className="f-grey f-12 w-75">
                      Set the maximum percentage of risky users in a suppliers
                      organisation and a data attestation level that suppliers
                      must maintain.
                    </p>
                  </div>
                  <div>
                    <Form.Group controlId={"check"} className="scale-switch">
                      <Form.Check
                        type="switch"
                        id="switch-ques"
                        className="m-0 scale-switch switch-shadow-none"
                        checked={riskyUsersData?.SectionHasValue}
                        onChange={(e) => {
                          setRiskyUsersData({
                            ...riskyUsersData,
                            SectionHasValue: e.target.checked,
                          });
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>
                {riskyUsersData?.SectionHasValue && (
                  <>
                    <div className="slider-wrapper d-flex align-items-center justify-content-between">
                      <CustomSlider
                        min={1}
                        max={100}
                        step={1}
                        id="cyber-score-notification-slider"
                        onChange={(e) => {}}
                        onMouseUp={(e) => {
                          setRiskyUsersData({
                            ...riskyUsersData,
                            SectionValue: e.target.value,
                          });
                        }}
                        defaultValue={riskyUsersData?.SectionValue}
                      />
                      <div className="d-flex align-items-center bg-grey radius-2 ml-2 flex-grow-1 p-2 btn-typ ">
                        <img
                          alt=""
                          src="/images/supply-chain/minValue-icon.svg"
                          className="mr-2"
                        />
                        <div className="f-500 f-black">
                          Max {riskyUsersData?.SectionValue}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center  mt-4 ml-2 f-12 f-grey">
                      <img
                        alt=""
                        className="mr-2"
                        src="/images/supply-chain/grey-info.svg"
                      />
                      <div>
                        This KPI requires verified data, which requires the
                        supplier to integrate their tools
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            {page == "activethreats" && (
              <div className="cyberscore">
                <div className="head d-flex flex-column align-items-center justify-content-center position-relative">
                  <div className="f-black f-500">
                    <img
                      alt=""
                      src="/images/supply-chain/activethreats.svg"
                      className="mr-2"
                    />
                    {activeThreatsData?.SectionValue}
                  </div>
                  <img
                    alt=""
                    src="/images/supply-chain/blue-underscore.svg"
                    className="mr-2 my-2"
                  />
                  <div className="f-grey">Active Threats</div>
                </div>
                <div className="d-flex justify-content-between align-items-start my-2">
                  <div>
                    <p className="f-black m-0 f-500">
                      Active Threats Thresholds
                    </p>
                    <p className="f-grey f-12 w-75">
                      Set the maximum quantity of Active Threats a supplier can
                      have.
                    </p>
                  </div>
                  <div>
                    <Form.Group controlId={"check"} className="scale-switch">
                      <Form.Check
                        type="switch"
                        id="switch-ques"
                        className="m-0 scale-switch switch-shadow-none"
                        checked={activeThreatsData?.SectionHasValue}
                        onChange={(e) => {
                          setActiveThreatsData({
                            ...activeThreatsData,
                            SectionHasValue: e.target.checked,
                          });
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>

                {activeThreatsData?.SectionHasValue && (
                  <>
                    <div className="slider-wrapper d-flex align-items-center justify-content-between">
                      <CustomSlider
                        min={1}
                        max={100}
                        step={1}
                        id="cyber-score-notification-slider"
                        onChange={(e) => {
                          setActiveThreatsData({
                            ...activeThreatsData,
                            SectionValue: e.target.value,
                          });
                        }}
                        defaultValue={activeThreatsData?.SectionValue}
                      />
                      <div className="d-flex align-items-center bg-grey radius-2 ml-2 flex-grow-1 p-2 btn-typ ">
                        <img
                          alt=""
                          src="/images/supply-chain/minValue-icon.svg"
                          className="mr-2"
                        />
                        <div className="f-500 f-black">
                          Max {activeThreatsData?.SectionValue}
                        </div>
                      </div>
                    </div>
                    <div className="data-attestation d-flex align-items-center justify-content-between mt-3">
                      <div>
                        <p className="f-black m-0 f-500 d-flex">
                          Data Attestation
                          <SupplyPopUp
                            header={"Data Attestation"}
                            dataList={Object.keys(
                              activeThreatsData?.DataAttestationInfo
                            )?.map((permission, permissionIndex) => {
                              return {
                                title: permission,
                                subtitle:
                                  activeThreatsData?.DataAttestationInfo[
                                    permission
                                  ],
                              };
                            })}
                          >
                            <img
                              alt=""
                              src="/images/supply-chain/dark-info-icon.svg"
                              className="ml-2"
                            />
                          </SupplyPopUp>
                        </p>
                        <p className="f-14 f-grey"> Choose verification data</p>
                      </div>
                      {console.log(
                        "activethreats",
                        activeThreatsData?.ScoreAttestation < 2
                          ? null
                          : activeThreatsData?.ScoreAttestation
                      )}
                      <div>
                        <NormalDropdown
                          fieldId={"id"}
                          fieldValue={"category"}
                          placeholder={"Choose.."}
                          selected={
                            activeThreatsData?.ScoreAttestation < 2
                              ? null
                              : activeThreatsData?.ScoreAttestation
                          }
                          data={[
                            // { id: 0, category: "Not answered" },
                            // { id: 1, category: "No capability" },
                            { id: 2, category: "Unverified" },
                            { id: 3, category: "Verified" },
                            { id: 4, category: "Part-Verified" },
                          ]}
                          onClick={(id, val) => {
                            setActiveThreatsData({
                              ...activeThreatsData,
                              ScoreAttestation: id,
                              SelectionSummary: val,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            {page == "activerisks" && (
              <div className="cyberscore">
                <div className="head d-flex flex-column align-items-center justify-content-center position-relative">
                  <div className="f-black f-500">
                    <img
                      alt=""
                      src="/images/supply-chain/activerisks.svg"
                      className="mr-2"
                    />
                    {activeRisksData?.SectionValue}
                  </div>
                  <img
                    alt=""
                    src="/images/supply-chain/blue-underscore.svg"
                    className="mr-2 my-2"
                  />
                  <div className="f-grey">Active Risks</div>
                </div>
                <div className="d-flex justify-content-between align-items-start my-2">
                  <div>
                    <p className="f-black m-0 f-500">Active Risks Thresholds</p>
                    <p className="f-grey f-12 w-75">
                      Set the maximum quantity of Active Risks a supplier can
                      have.
                    </p>
                  </div>
                  <div>
                    <Form.Group controlId={"check"} className="scale-switch">
                      <Form.Check
                        type="switch"
                        id="switch-ques"
                        className="m-0 scale-switch switch-shadow-none"
                        checked={activeRisksData?.SectionHasValue}
                        onChange={(e) => {
                          setActiveRisksData({
                            ...activeRisksData,
                            SectionHasValue: e.target.checked,
                          });
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>

                {activeRisksData?.SectionHasValue && (
                  <>
                    <div className="slider-wrapper d-flex align-items-center justify-content-between">
                      <CustomSlider
                        min={1}
                        max={100}
                        step={1}
                        id="cyber-score-notification-slider"
                        onChange={(e) => {
                          setActiveRisksData({
                            ...activeRisksData,
                            SectionValue: e.target.value,
                          });
                        }}
                        defaultValue={activeRisksData?.SectionValue}
                      />
                      <div className="d-flex align-items-center bg-grey radius-2 ml-2 flex-grow-1 p-2 btn-typ ">
                        <img
                          alt=""
                          src="/images/supply-chain/minValue-icon.svg"
                          className="mr-2"
                        />
                        <div className="f-500 f-black">
                          Max {activeRisksData?.SectionValue}
                        </div>
                      </div>
                    </div>
                    <div className="data-attestation d-flex align-items-center justify-content-between mt-3">
                      <div>
                        <p className="f-black m-0 f-500 d-flex">
                          Data Attestation
                          <SupplyPopUp
                            header={"Data Attestation"}
                            dataList={Object.keys(
                              activeRisksData?.DataAttestationInfo
                            )?.map((permission, permissionIndex) => {
                              return {
                                title: permission,
                                subtitle:
                                  activeRisksData?.DataAttestationInfo[
                                    permission
                                  ],
                              };
                            })}
                          >
                            <img
                              alt=""
                              src="/images/supply-chain/dark-info-icon.svg"
                              className="ml-2"
                            />
                          </SupplyPopUp>
                        </p>
                        <p className="f-14 f-grey"> Choose verification data</p>
                      </div>
                      <div>
                        <NormalDropdown
                          fieldId={"id"}
                          fieldValue={"category"}
                          placeholder={"Choose.."}
                          selected={
                            activeRisksData?.ScoreAttestation < 2
                              ? null
                              : activeRisksData?.ScoreAttestation
                          }
                          data={[
                            // { id: 0, category: "Not answered" },
                            // { id: 1, category: "No capability" },
                            { id: 2, category: "Unverified" },
                            { id: 3, category: "Verified" },
                            { id: 4, category: "Part-Verified" },
                          ]}
                          onClick={(id, val) => {
                            setActiveRisksData({
                              ...activeRisksData,
                              ScoreAttestation: id,
                              SelectionSummary: val,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            {page == "cyberinvestment" && (
              <div className="cyberscore">
                <div className="head d-flex flex-column align-items-center justify-content-center position-relative">
                  {/* <div className="graph "> */}
                  <div className="f-black f-500">
                    <img
                      alt=""
                      src="/images/supply-chain/cyberinvestment.svg"
                      className="mr-2"
                    />
                    £{cyberInvestmentData?.SectionValue}
                  </div>
                  <img
                    alt=""
                    src="/images/supply-chain/blue-underscore.svg"
                    className="mr-2 my-2"
                  />
                  <div className="f-grey">Cyber Investment</div>
                  {/* </div> */}
                </div>
                <div className="d-flex justify-content-between align-items-start my-2">
                  <div>
                    <p className="f-black m-0 f-500">
                      CyberInvestment Thresholds
                    </p>
                    <p className="f-grey f-12 w-75">
                      Set the minimum amount of Cyber Investment this supplier
                      must make on an annual basis.
                    </p>
                  </div>
                  <div>
                    <Form.Group controlId={"check"} className="scale-switch">
                      <Form.Check
                        type="switch"
                        id="switch-ques"
                        className="m-0 scale-switch switch-shadow-none"
                        checked={cyberInvestmentData?.SectionHasValue}
                        onChange={(e) => {
                          setCyberInvestmentData({
                            ...cyberInvestmentData,
                            SectionHasValue: e.target.checked,
                          });
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>

                {cyberInvestmentData?.SectionHasValue && (
                  <>
                    <div className="slider-wrapper d-flex align-items-center justify-content-between">
                      {/* <CustomSlider
                        min={1}
                        max={100}
                        step={1}
                        id="cyber-score-notification-slider"
                        onChange={(e) => {
                          setCyberInvestmentData({
                            ...cyberInvestmentData,
                            SectionValue: e.target.value,
                          });
                        }}
                        defaultValue={cyberInvestmentData?.SectionValue}
                      /> */}
                      <div
                        className={`supplier-input-wrapper bg-grey w-50  d-flex align-items-center p-2 radius-4    ${true} `}
                      >
                        {/* <div className="f-darkgrey">Email:</div> */}
                        <input
                          tabIndex={0}
                          type="text"
                          placeholder="Cyber Investment value"
                          className="flex-grow-1 pl-1 pr-2 border-none bg-transparent py-1"
                          value={
                            cyberInvestmentData?.SectionValue === 0
                              ? ""
                              : cyberInvestmentData?.SectionValue
                          }
                          onChange={(e) => {
                            if (e.target.value.length > 11) return;
                            let value = e.target.value
                              .replace(/[!@#$%^&*,]/g, "")
                              .replace(/\D/g, "")
                              .replaceAll(".", "")
                              .replaceAll(",", "")
                              .replaceAll("/", "")
                              .replaceAll("-", "")
                              .replaceAll("+", "")
                              .replaceAll(" ", "");
                            setCyberInvestmentData({
                              ...cyberInvestmentData,
                              SectionValue: value,
                            });
                          }}
                        />
                      </div>
                      <div className="d-flex align-items-center bg-grey radius-2 ml-2 flex-grow-1 p-2 ">
                        <img
                          alt=""
                          src="/images/supply-chain/minValue-icon.svg"
                          className="mr-2"
                        />
                        <div className="f-500 f-black w-max-content">
                          Min £{cyberInvestmentData?.SectionValue}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center  mt-4 ml-2 f-12 f-grey">
                      <img
                        alt=""
                        src="/images/supply-chain/grey-info.svg"
                        className="mr-2"
                      />
                      <div>
                        This KPI does not support verified data, as it must be
                        defined by the supplier
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            {page == "cyberessentials" && (
              <div className="cyberessentials">
                <div className="d-flex justify-content-between align-items-start my-2 mb-3">
                  <div>
                    <p className="f-black m-0 f-500">
                      Cyber Essentials preferences
                    </p>
                    <p className="f-grey f-12 ">
                      Set required settings for this data
                    </p>
                  </div>
                  <div>
                    <Form.Group controlId={"check"} className="scale-switch">
                      <Form.Check
                        type="switch"
                        id="switch-ques"
                        className="m-0 scale-switch switch-shadow-none"
                        checked={cyberEssentialsData?.SectionHasValue}
                        onChange={(e) => {
                          setCyberEssentialsData({
                            ...cyberEssentialsData,
                            SectionHasValue: e.target.checked,
                          });
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>

                {cyberEssentialsData?.SectionHasValue && (
                  <>
                    <div className="d-flex justify-content-between align-items-start my-2 mb-3">
                      <div>
                        <p className="f-black m-0 f-500">Certification Level</p>
                        <p className="f-grey f-12 ">
                          Choose required certification level
                        </p>
                      </div>
                      <div>
                        <NormalDropdown
                          placeholder="Choose"
                          fieldId={"Id"}
                          fieldValue={"value"}
                          selected={cyberEssentialsData?.SelectedOption1?.[0]}
                          data={cyberEssentialsData?.SelectionOption1}
                          onClick={(id, val) => {
                            setCyberEssentialsData({
                              ...cyberEssentialsData,
                              SelectedOption1: [id],
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-start my-2 mb-3">
                      <div>
                        <p className="f-black m-0 f-500">
                          Certification Status
                        </p>
                        <p className="f-grey f-12 ">
                          Choose required certification status
                        </p>
                      </div>
                      <div>
                        <NormalDropdown
                          placeholder="Choose"
                          fieldId={"Id"}
                          fieldValue={"value"}
                          selected={cyberEssentialsData?.SelectedOption2?.[0]}
                          data={cyberEssentialsData?.SelectionOption2}
                          onClick={(id, val) => {
                            setCyberEssentialsData({
                              ...cyberEssentialsData,
                              SelectedOption2: [id],
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-start my-2 mb-3">
                      <div>
                        <p className="f-black m-0 f-500">Audit Frequency</p>
                        <p className="f-grey f-12 ">
                          Choose required audit frequency
                        </p>
                      </div>
                      <div>
                        <NormalDropdown
                          placeholder="Choose"
                          fieldId={"Id"}
                          fieldValue={"value"}
                          selected={cyberEssentialsData?.SelectedOption3?.[0]}
                          data={cyberEssentialsData?.SelectionOption3}
                          onClick={(id, val) => {
                            setCyberEssentialsData({
                              ...cyberEssentialsData,
                              SelectedOption3: [id],
                            });
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            {page == "incidentresponseplan" && (
              <div className="cyberessentials">
                <div className="d-flex justify-content-between align-items-start my-2 mb-3">
                  <div>
                    <p className="f-black m-0 f-500">
                      Incident Response Plan preferences
                    </p>
                    <p className="f-grey f-12 ">
                      Set required settings for this data
                    </p>
                  </div>
                  <div>
                    <Form.Group controlId={"check"} className="scale-switch">
                      <Form.Check
                        type="switch"
                        id="switch-ques"
                        className="m-0 scale-switch switch-shadow-none"
                        checked={incidenceResponsePlanData?.SectionHasValue}
                        onChange={(e) => {
                          setIncidenceResponsePlanData({
                            ...incidenceResponsePlanData,
                            SectionHasValue: e.target.checked,
                          });
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>

                {incidenceResponsePlanData?.SectionHasValue && (
                  <>
                    <div className="d-flex flex-column  align-items-start my-2 mb-3">
                      <div>
                        <p className="f-black m-0 f-500">
                          Incident Response Plan Docs
                        </p>
                        <p className="f-grey f-12 ">
                          Choose required IR Plan Docs
                        </p>
                      </div>

                      {incidenceResponsePlanData?.SelectionOption1?.map(
                        (item, index) => {
                          return (
                            <div className="d-flex align-items-center my-2">
                              <div className="mb-4">
                                <Form.Group
                                  controlId={"check1" + index}
                                  className="round-checkbox blue-checkbox checkboxstyle"
                                >
                                  <Form.Check
                                    type="checkbox"
                                    name={"check" + 1}
                                    label=""
                                    checked={
                                      incidenceResponsePlanData?.SelectedOption1?.findIndex(
                                        (dataItem) => dataItem === item?.Id
                                      ) > -1
                                    }
                                    onChange={(e) => {
                                      let Index =
                                        incidenceResponsePlanData?.SelectedOption1?.findIndex(
                                          (dataItem) => dataItem === item?.Id
                                        );

                                      if (Index > -1) {
                                        let updated_data = {
                                          ...incidenceResponsePlanData,
                                        };
                                        updated_data.SelectedOption1.splice(
                                          Index,
                                          1
                                        );
                                        setIncidenceResponsePlanData(
                                          updated_data
                                        );
                                      } else {
                                        let updated_data = {
                                          ...incidenceResponsePlanData,
                                        };
                                        if (
                                          updated_data.SelectedOption1 === null
                                        ) {
                                          updated_data.SelectedOption1 = [];
                                        }

                                        updated_data.SelectedOption1.push(
                                          item?.Id
                                        );
                                        setIncidenceResponsePlanData(
                                          updated_data
                                        );
                                      }
                                    }}
                                    // defaultChecked={elmt.StripeId}
                                  />
                                </Form.Group>
                              </div>
                              <div className="f-500 ml-3">
                                {" "}
                                {item?.value
                                  ?.replaceAll("ampersand", "&")
                                  ?.replaceAll("fwdslash", "/")}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>{" "}
                    <div className="d-flex justify-content-between align-items-start my-2 mb-3">
                      <div>
                        <p className="f-black m-0 f-500">
                          Incident Response Review
                        </p>
                        <p className="f-grey f-12 ">
                          Choose required Incident Response Plan review
                          frequency
                        </p>
                      </div>
                      <div>
                        <NormalDropdown
                          placeholder="Choose"
                          fieldId={"Id"}
                          fieldValue={"value"}
                          selected={
                            incidenceResponsePlanData?.SelectedOption2?.[0]
                          }
                          data={incidenceResponsePlanData?.SelectionOption2}
                          onClick={(id, val) => {
                            setIncidenceResponsePlanData({
                              ...incidenceResponsePlanData,
                              SelectedOption2: [id],
                            });
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            {page == "infosecpolicies" && (
              <div className="cyberessentials pl-2">
                <div className="d-flex justify-content-between align-items-start my-2 mb-3">
                  <div>
                    <p className="f-black m-0 f-500">
                      Choose required InfoSec Policies
                    </p>
                    <p className="f-grey f-12 ">
                      Set required settings for this data
                    </p>
                  </div>
                  <div>
                    <Form.Group controlId={"check"} className="scale-switch">
                      <Form.Check
                        type="switch"
                        id="switch-ques"
                        className="m-0 scale-switch switch-shadow-none"
                        checked={infosecPoliciesData?.SectionHasValue}
                        onChange={(e) => {
                          setInfosecPoliciesData({
                            ...infosecPoliciesData,

                            SectionHasValue: e.target.checked,
                          });
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>

                {infosecPoliciesData?.SectionHasValue && (
                  <>
                    <div className="d-flex flex-column  align-items-start my-2 mb-3">
                      <p className="f-grey f-12 ml-3 mt-4 mb-0">
                        Recommended Policies
                      </p>

                      <div className="d-flex flex-wrap p-2">
                        {infosecPoliciesData?.SelectionOption1?.map(
                          (item, index) => {
                            return (
                              <div className="d-flex align-items-center my-2 w-50 pr-2">
                                <div className="mb-4">
                                  <Form.Group
                                    controlId={"check" + index}
                                    className="round-checkbox blue-checkbox checkboxstyle"
                                  >
                                    <Form.Check
                                      type="checkbox"
                                      name={"check"}
                                      label=""
                                      checked={
                                        infosecPoliciesData?.SelectedOption1?.findIndex(
                                          (dataItem) => dataItem === item?.Id
                                        ) > -1
                                      }
                                      onChange={() => {}}
                                      onClick={(e) => {
                                        let Index =
                                          infosecPoliciesData?.SelectedOption1?.findIndex(
                                            (dataItem) => dataItem === item?.Id
                                          );

                                        if (Index > -1) {
                                          let updated_data = {
                                            ...infosecPoliciesData,
                                          };
                                          updated_data.SelectedOption1.splice(
                                            Index,
                                            1
                                          );
                                          setInfosecPoliciesData(updated_data);
                                        } else {
                                          let updated_data = {
                                            ...infosecPoliciesData,
                                          };
                                          if (
                                            updated_data.SelectedOption1 ===
                                            null
                                          ) {
                                            updated_data.SelectedOption1 = [];
                                          }

                                          updated_data.SelectedOption1.push(
                                            item?.Id
                                          );
                                          setInfosecPoliciesData(updated_data);
                                        }
                                      }}
                                      // defaultChecked={elmt.StripeId}
                                    />
                                  </Form.Group>
                                </div>
                                <div className="f-500 ml-3">
                                  {item?.SectionDDLName}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>

                    <div className="d-flex flex-column  align-items-start my-2 mb-3">
                      <p className="f-grey f-12 ml-3 mt-4 mb-0">
                        Cyber Essentials Policies
                      </p>

                      <div className="d-flex flex-wrap p-2">
                        {infosecPoliciesData?.SelectionOption2?.map(
                          (item, index) => {
                            return (
                              <div className="d-flex align-items-center my-2 w-50 pr-2">
                                <div className="mb-4">
                                  <Form.Group
                                    controlId={"check" + index + item}
                                    className="round-checkbox blue-checkbox checkboxstyle"
                                  >
                                    <Form.Check
                                      type="checkbox"
                                      name={"check"}
                                      label=""
                                      checked={
                                        infosecPoliciesData?.SelectedOption2?.findIndex(
                                          (dataItem) => dataItem === item?.Id
                                        ) > -1
                                      }
                                      onChange={(e) => {}}
                                      onClick={(e) => {
                                        let Index =
                                          infosecPoliciesData?.SelectedOption2?.findIndex(
                                            (dataItem) => dataItem === item?.Id
                                          );

                                        if (Index > -1) {
                                          let updated_data = {
                                            ...infosecPoliciesData,
                                          };
                                          updated_data.SelectedOption2.splice(
                                            Index,
                                            1
                                          );
                                          setInfosecPoliciesData(updated_data);
                                        } else {
                                          let updated_data = {
                                            ...infosecPoliciesData,
                                          };
                                          if (
                                            updated_data.SelectedOption2 ===
                                            null
                                          ) {
                                            updated_data.SelectedOption2 = [];
                                          }

                                          updated_data.SelectedOption2.push(
                                            item?.Id
                                          );
                                          setInfosecPoliciesData(updated_data);
                                        }
                                      }}
                                    />
                                  </Form.Group>
                                </div>
                                <div className="f-500 ml-3">
                                  {item?.SectionDDLName}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            {page == "riskassessments" && (
              <div className="cyberessentials">
                <div className="d-flex justify-content-between align-items-start my-2 mb-3">
                  <div>
                    <p className="f-black m-0 f-500">
                      Risk Assessments preferences
                    </p>
                    <p className="f-grey f-12 ">
                      Set required settings for this data
                    </p>
                  </div>
                  <div>
                    <Form.Group controlId={"check"} className="scale-switch">
                      <Form.Check
                        type="switch"
                        id="switch-ques"
                        className="m-0 scale-switch switch-shadow-none"
                        checked={riskAssessmentsData?.SectionHasValue}
                        onChange={(e) => {
                          setRiskAssessmentsData({
                            ...riskAssessmentsData,
                            SectionHasValue: e.target.checked,
                          });
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>

                {riskAssessmentsData?.SectionHasValue && (
                  <>
                    <div className="d-flex justify-content-between align-items-start my-2 mb-3 mt-3">
                      <div>
                        <p className="f-black m-0 f-500">
                          Risk Assessments frequency
                        </p>
                        <p className="f-grey f-12 ">
                          Choose required Risk Assessments frequency
                        </p>
                      </div>
                      <div>
                        <NormalDropdown
                          placeholder="Choose"
                          fieldId={"Id"}
                          fieldValue={"value"}
                          selected={riskAssessmentsData?.SelectedOption1?.[0]}
                          data={riskAssessmentsData?.SelectionOption1}
                          onClick={(id, val) => {
                            setRiskAssessmentsData({
                              ...riskAssessmentsData,
                              SelectedOption1: [id],
                            });
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            {page == "bcdrplan" && (
              <div className="cyberessentials">
                <div className="d-flex justify-content-between align-items-start my-2 mb-3">
                  <div>
                    <p className="f-black m-0 f-500">BCDR Plan preferences</p>
                    <p className="f-grey f-12 ">
                      Set required settings for this data
                    </p>
                  </div>
                  <div>
                    <Form.Group controlId={"check"} className="scale-switch">
                      <Form.Check
                        type="switch"
                        id="switch-ques"
                        className="m-0 scale-switch switch-shadow-none"
                        checked={bcdrPlanData?.SectionHasValue}
                        onChange={(e) => {
                          setBcdrPlanData({
                            ...bcdrPlanData,
                            SectionHasValue: e.target.checked,
                          });
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>

                {bcdrPlanData?.SectionHasValue && (
                  <>
                    <div className="d-flex justify-content-between align-items-start my-2 mb-3 mt-3">
                      <div>
                        <p className="f-black m-0 f-500">IT BCDR Plan</p>
                        <p className="f-grey f-12 ">
                          Choose required IT BCDR Plan status
                        </p>
                      </div>
                      <div>
                        <NormalDropdown
                          placeholder="Choose"
                          fieldId={"Id"}
                          fieldValue={"value"}
                          selected={bcdrPlanData?.SelectedOption1?.[0]}
                          data={bcdrPlanData?.SelectionOption1}
                          onClick={(id, val) => {
                            setBcdrPlanData({
                              ...bcdrPlanData,
                              SelectedOption1: [id],
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-start my-2 mb-3 mt-3">
                      <div>
                        <p className="f-black m-0 f-500">
                          Test Recovery Frequency
                        </p>
                        <p className="f-grey f-12 ">
                          Choose required Test Recovery frequency
                        </p>
                      </div>
                      <div>
                        <NormalDropdown
                          placeholder="Choose"
                          fieldId={"Id"}
                          fieldValue={"value"}
                          selected={bcdrPlanData?.SelectedOption2?.[0]}
                          data={bcdrPlanData?.SelectionOption2}
                          onClick={(id, val) => {
                            setBcdrPlanData({
                              ...bcdrPlanData,
                              SelectedOption2: [id],
                            });
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            {page == "annualcyberreviews" && (
              <div className="cyberessentials">
                <div className="d-flex justify-content-between align-items-start my-2 mb-3">
                  <div>
                    <p className="f-black m-0 f-500">
                      Annual Cyber Reviews preferences
                    </p>
                    <p className="f-grey f-12 ">
                      Set required settings for this data
                    </p>
                  </div>
                  <div>
                    <Form.Group controlId={"check"} className="scale-switch">
                      <Form.Check
                        type="switch"
                        id="switch-ques"
                        className="m-0 scale-switch switch-shadow-none"
                        checked={annualCyberReviewsData?.SectionHasValue}
                        onChange={(e) => {
                          setAnnualCyberReviewsData({
                            ...annualCyberReviewsData,
                            SectionHasValue: e.target.checked,
                          });
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>

                {annualCyberReviewsData?.SectionHasValue && (
                  <>
                    <div className="d-flex justify-content-between align-items-start my-2 mb-3 mt-3">
                      <div>
                        <p className="f-black m-0 f-500">
                          Annual Cyber Review Frequency
                        </p>
                        <p className="f-grey f-12 ">
                          Choose required Annual Cyber Review frequency
                        </p>
                      </div>
                      <div>
                        <NormalDropdown
                          placeholder="Choose"
                          fieldId={"Id"}
                          fieldValue={"value"}
                          selected={
                            annualCyberReviewsData?.SelectedOption1?.[0]
                          }
                          data={annualCyberReviewsData?.SelectionOption1}
                          onClick={(id, val) => {
                            setAnnualCyberReviewsData({
                              ...annualCyberReviewsData,
                              SelectedOption1: [id],
                            });
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}

            {/* For Attack surface */}
            {page === "attackSurface" && (
              <div className="cyberessentials">
                <div className="f-12 f-grey my-2">Insights</div>
                <div className="d-flex justify-content-between align-items-start my-2 mb-3 w-100">
                  <div className="w-75">
                    <p className="f-black m-0 f-500">
                      Max active security insights
                    </p>
                    <p className="f-grey f-12 ">
                      Set the minimum amount of Active Security Insights the
                      supplier can have any any time.
                    </p>
                  </div>
                  <div className="w-auto">
                    <NormalDropdown
                      placeholder="Choose"
                      fieldId={"Id"}
                      fieldValue={"value"}
                      selected={attackSurfaceData?.SelectedOption1?.[0]}
                      data={attackSurfaceData?.SelectionOption1}
                      onClick={(id, val) => {
                        setAttackSurfaceData({
                          ...attackSurfaceData,
                          SelectedOption1: [id],
                        });
                      }}
                    />
                    {/* <Form.Group controlId={"check"} className="scale-switch">
                      <Form.Check
                        type="switch"
                        id="switch-ques"
                        className="m-0 scale-switch switch-shadow-none"
                        checked={annualCyberReviewsData?.SectionHasValue}
                        onChange={(e) => {
                          setAnnualCyberReviewsData({
                            ...annualCyberReviewsData,
                            SectionHasValue: e.target.checked,
                          });
                        }}
                      />
                    </Form.Group> */}
                  </div>
                </div>

                <>
                  <div className="w-100 my-2 mb-3 mt-3">
                    <div className="f-12 f-grey my-2">Performed sections</div>

                    <div>
                      <div
                        className="point "
                        onClick={(e) => ToggleAccordion(e, false)}
                      >
                        <div className="d-flex align-items-center justify-content-between pointer">
                          <div className="d-flex align-items-center my-2">
                            <div className="mb-4">
                              <Form.Group
                                controlId={"check1" + "CDSA"}
                                className="round-checkbox blue-checkbox checkboxstyle d-none"
                              >
                                <Form.Check
                                  type="checkbox"
                                  name={"check" + "CDSA"}
                                  label=""
                                  // checked={

                                  // }
                                  onChange={(e) => {}}
                                />
                              </Form.Group>
                            </div>
                            <img
                              width={19}
                              height={19}
                              src={
                                imageSource[
                                  AttackSurfaceItemStatusforAll(
                                    [
                                      attackSurfaceData?.SelectedOption2,
                                      attackSurfaceData?.SelectedOption3,
                                      attackSurfaceData?.SelectedOption4,
                                    ],
                                    [
                                      attackSurfaceData?.SelectionOption2,

                                      attackSurfaceData?.SelectionOption3,

                                      attackSurfaceData?.SelectionOption4,
                                    ]
                                  )
                                ]
                              }
                              alt=""
                              className=""
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleSelectedList(
                                  [
                                    attackSurfaceData?.SelectedOption2,
                                    attackSurfaceData?.SelectedOption3,
                                    attackSurfaceData?.SelectedOption4,
                                  ],
                                  [
                                    attackSurfaceData?.SelectionOption2,

                                    attackSurfaceData?.SelectionOption3,

                                    attackSurfaceData?.SelectionOption4,
                                  ],
                                  [
                                    "SelectedOption2",
                                    "SelectedOption3",
                                    "SelectedOption4",
                                  ],
                                  [
                                    "SelectionOption2",
                                    "SelectionOption3",
                                    "SelectionOption4",
                                  ]
                                );
                              }}
                            />
                            <div className="f-500 ml-2 pt-1">CDSA</div>
                          </div>
                          <img
                            alt=""
                            className=""
                            src="/images/big-chevron-down.svg"
                          />
                        </div>
                        <div
                          className="panel"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {/* CDSA Tools */}
                          <div className="f-12 f-grey my-2">Tools</div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption2?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={
                                          "check" + "ToolsCDSA" + index
                                        }
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption2?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption2?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption2.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption2 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption2 =
                                                  [];
                                              }

                                              updated_data.SelectedOption2.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                          {/* CDSA Services */}
                          <div className="f-12 f-grey my-2">Services</div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption3?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={"check" + "Services" + index}
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption3?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption3?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption3.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption3 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption3 =
                                                  [];
                                              }

                                              updated_data.SelectedOption3.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                          {/* CDSA Capabilities */}
                          <div className="f-12 f-grey my-2">Capabilities</div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption4?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={
                                          "check" + "CapabilitesCDSA" + index
                                        }
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption4?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption4?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption4.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption4 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption4 =
                                                  [];
                                              }

                                              updated_data.SelectedOption4.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* ASSETS LIST */}
                    <div>
                      <div
                        className="point "
                        onClick={(e) => ToggleAccordion(e, false)}
                      >
                        <div className="d-flex align-items-center justify-content-between pointer">
                          <div className="d-flex align-items-center my-2">
                            <div className="mb-4">
                              <Form.Group
                                controlId={"check1" + "CDSA"}
                                className="round-checkbox blue-checkbox checkboxstyle d-none"
                              >
                                <Form.Check
                                  type="checkbox"
                                  name={"check" + "CDSA"}
                                  label=""
                                  // checked={

                                  // }
                                  onChange={(e) => {}}
                                />
                              </Form.Group>
                            </div>
                            <img
                              width={19}
                              height={19}
                              src={
                                imageSource[
                                  AttackSurfaceItemStatusforAll(
                                    [attackSurfaceData?.SelectedOption5],
                                    [attackSurfaceData?.SelectionOption5]
                                  )
                                ]
                              }
                              alt=""
                              className=""
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleSelectedList(
                                  [attackSurfaceData?.SelectedOption5],
                                  [attackSurfaceData?.SelectionOption5],
                                  ["SelectedOption5"],
                                  ["SelectionOption5"]
                                );
                              }}
                            />
                            <div className="f-500 ml-2 pt-1">Assets List</div>
                          </div>
                          <img
                            alt=""
                            className=""
                            src="/images/big-chevron-down.svg"
                          />
                        </div>
                        <div
                          className="panel"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {/* CDSA Tools */}
                          <div className="f-12 f-grey my-2"></div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption5?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={
                                          "check" + "AsseytsList" + index
                                        }
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption5?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption5?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption5.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption5 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption5 =
                                                  [];
                                              }

                                              updated_data.SelectedOption5.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Internal Networks */}
                    <div>
                      <div
                        className="point "
                        onClick={(e) => ToggleAccordion(e, false)}
                      >
                        <div className="d-flex align-items-center justify-content-between pointer">
                          <div className="d-flex align-items-center my-2">
                            <div className="mb-4">
                              <Form.Group
                                controlId={"check1" + "CDSA"}
                                className="round-checkbox blue-checkbox checkboxstyle d-none"
                              >
                                <Form.Check
                                  type="checkbox"
                                  name={"check" + "CDSA"}
                                  label=""
                                  // checked={

                                  // }
                                  onChange={(e) => {}}
                                />
                              </Form.Group>
                            </div>

                            <img
                              width={19}
                              height={19}
                              src={
                                imageSource[
                                  AttackSurfaceItemStatusforAll(
                                    [
                                      attackSurfaceData?.SelectedOption6,
                                      attackSurfaceData?.SelectedOption18,
                                    ],
                                    [
                                      attackSurfaceData?.SelectionOption6,
                                      attackSurfaceData?.SelectionOption18,
                                    ]
                                  )
                                ]
                              }
                              alt=""
                              className=""
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleSelectedList(
                                  [
                                    attackSurfaceData?.SelectedOption6,
                                    attackSurfaceData?.SelectedOption18,
                                  ],
                                  [
                                    attackSurfaceData?.SelectionOption6,
                                    attackSurfaceData?.SelectionOption18,
                                  ],
                                  ["SelectedOption6", "SelectedOption18"],
                                  ["SelectionOption6", "SelectionOption18"]
                                );
                              }}
                            />
                            <div className="f-500 ml-2 pt-1">
                              Internal Networks
                            </div>
                          </div>
                          <img
                            alt=""
                            className=""
                            src="/images/big-chevron-down.svg"
                          />
                        </div>
                        <div
                          className="panel"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {/* Base Line Questions for Networks */}
                          <div className="f-12 f-grey my-2"></div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption18?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={
                                          "check" +
                                          "CapabilitesCDSAQues" +
                                          index
                                        }
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption18?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption18?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption18.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption18 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption18 =
                                                  [];
                                              }

                                              updated_data.SelectedOption18.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>

                          <div className="f-12 f-grey my-2">Network Assets</div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption6?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={
                                          "check" + "internalNetworkss" + index
                                        }
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption6?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption6?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption6.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption6 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption6 =
                                                  [];
                                              }

                                              updated_data.SelectedOption6.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ---- */}
                    {/* Directory Services */}
                    <div>
                      <div
                        className="point "
                        onClick={(e) => ToggleAccordion(e, false)}
                      >
                        <div className="d-flex align-items-center justify-content-between pointer">
                          <div className="d-flex align-items-center my-2">
                            <div className="mb-4">
                              <Form.Group
                                controlId={"check1" + "CDSA"}
                                className="round-checkbox blue-checkbox checkboxstyle d-none"
                              >
                                <Form.Check
                                  type="checkbox"
                                  name={"check" + "CDSA"}
                                  label=""
                                  // checked={

                                  // }
                                  onChange={(e) => {}}
                                />
                              </Form.Group>
                            </div>
                            <img
                              width={19}
                              height={19}
                              src={
                                imageSource[
                                  AttackSurfaceItemStatusforAll(
                                    [
                                      attackSurfaceData?.SelectedOption7,
                                      attackSurfaceData?.SelectedOption19,
                                    ],
                                    [
                                      attackSurfaceData?.SelectionOption7,
                                      attackSurfaceData?.SelectionOption19,
                                    ]
                                  )
                                ]
                              }
                              alt=""
                              className=""
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleSelectedList(
                                  [
                                    attackSurfaceData?.SelectedOption7,
                                    attackSurfaceData?.SelectedOption19,
                                  ],
                                  [
                                    attackSurfaceData?.SelectionOption7,
                                    attackSurfaceData?.SelectionOption19,
                                  ],
                                  ["SelectedOption7", "SelectedOption19"],
                                  ["SelectionOption7", "SelectionOption19"]
                                );
                              }}
                            />
                            <div className="f-500 ml-2 pt-1">
                              Directory Services
                            </div>
                          </div>
                          <img
                            alt=""
                            className=""
                            src="/images/big-chevron-down.svg"
                          />
                        </div>
                        <div
                          className="panel"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {/* Base Line Questions for Directory Services */}
                          <div className="f-12 f-grey my-2"></div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption19?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={
                                          "check" +
                                          "CapabilitesCDSAQuets" +
                                          index
                                        }
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption19?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption19?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption19.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption19 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption19 =
                                                  [];
                                              }

                                              updated_data.SelectedOption19.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div className="f-12 f-grey my-2">
                            Directory Services Assets
                          </div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption7?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={
                                          "check" + "directoryservices" + index
                                        }
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption7?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption7?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption7.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption7 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption7 =
                                                  [];
                                              }

                                              updated_data.SelectedOption7.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ---- */}
                    {/* Email */}
                    <div>
                      <div
                        className="point "
                        onClick={(e) => ToggleAccordion(e, false)}
                      >
                        <div className="d-flex align-items-center justify-content-between pointer">
                          <div className="d-flex align-items-center my-2">
                            <div className="mb-4">
                              <Form.Group
                                controlId={"check1" + "CDSA"}
                                className="round-checkbox blue-checkbox checkboxstyle d-none"
                              >
                                <Form.Check
                                  type="checkbox"
                                  name={"check" + "CDSA"}
                                  label=""
                                  // checked={

                                  // }
                                  onChange={(e) => {}}
                                />
                              </Form.Group>
                            </div>
                            <img
                              width={19}
                              height={19}
                              src={
                                imageSource[
                                  AttackSurfaceItemStatusforAll(
                                    [
                                      attackSurfaceData?.SelectedOption8,
                                      attackSurfaceData?.SelectedOption20,
                                    ],
                                    [
                                      attackSurfaceData?.SelectionOption8,
                                      attackSurfaceData?.SelectionOption20,
                                    ]
                                  )
                                ]
                              }
                              alt=""
                              className=""
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleSelectedList(
                                  [
                                    attackSurfaceData?.SelectedOption8,
                                    attackSurfaceData?.SelectedOption20,
                                  ],
                                  [
                                    attackSurfaceData?.SelectionOption8,
                                    attackSurfaceData?.SelectionOption20,
                                  ],
                                  ["SelectedOption8", "SelectedOption20"],
                                  ["SelectionOption8", "SelectionOption20"]
                                );
                              }}
                            />
                            <div className="f-500 ml-2 pt-1">Email</div>
                          </div>
                          <img
                            alt=""
                            className=""
                            src="/images/big-chevron-down.svg"
                          />
                        </div>
                        <div
                          className="panel"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className="f-12 f-grey my-2"></div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption20?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={
                                          "check" + "emailCDSAQuets" + index
                                        }
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption20?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption20?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption20.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption20 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption20 =
                                                  [];
                                              }

                                              updated_data.SelectedOption20.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div className="f-12 f-grey my-2">Email assets</div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption8?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={"check" + "emailp" + index}
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption8?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption8?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption8.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption8 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption8 =
                                                  [];
                                              }

                                              updated_data.SelectedOption8.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ---- */}
                    {/* File Sharing */}
                    <div>
                      <div
                        className="point "
                        onClick={(e) => ToggleAccordion(e, false)}
                      >
                        <div className="d-flex align-items-center justify-content-between pointer">
                          <div className="d-flex align-items-center my-2">
                            <div className="mb-4">
                              <Form.Group
                                controlId={"check1" + "CDSA"}
                                className="round-checkbox blue-checkbox checkboxstyle d-none"
                              >
                                <Form.Check
                                  type="checkbox"
                                  name={"check" + "CDSA"}
                                  label=""
                                  // checked={

                                  // }
                                  onChange={(e) => {}}
                                />
                              </Form.Group>
                            </div>
                            <img
                              width={19}
                              height={19}
                              src={
                                imageSource[
                                  AttackSurfaceItemStatusforAll(
                                    [
                                      attackSurfaceData?.SelectedOption9,
                                      attackSurfaceData?.SelectedOption21,
                                    ],
                                    [
                                      attackSurfaceData?.SelectionOption9,
                                      attackSurfaceData?.SelectionOption21,
                                    ]
                                  )
                                ]
                              }
                              alt=""
                              className=""
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleSelectedList(
                                  [
                                    attackSurfaceData?.SelectedOption9,
                                    attackSurfaceData?.SelectedOption21,
                                  ],
                                  [
                                    attackSurfaceData?.SelectionOption9,
                                    attackSurfaceData?.SelectionOption21,
                                  ],
                                  ["SelectedOption9", "SelectedOption21"],
                                  ["SelectionOption9", "SelectionOption21"]
                                );
                              }}
                            />
                            <div className="f-500 ml-2 pt-1">File Sharing</div>
                          </div>
                          <img
                            alt=""
                            className=""
                            src="/images/big-chevron-down.svg"
                          />
                        </div>
                        <div
                          className="panel"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className="f-12 f-grey my-2"></div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption21?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={
                                          "check" + "fshareCDSAQuets" + index
                                        }
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption21?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption21?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption21.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption21 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption21 =
                                                  [];
                                              }

                                              updated_data.SelectedOption21.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div className="f-12 f-grey my-2">
                            File sharing assets
                          </div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption9?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={
                                          "check" + "filesharing" + index
                                        }
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption9?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption9?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption9.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption9 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption9 =
                                                  [];
                                              }

                                              updated_data.SelectedOption9.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ---- */}
                    {/*Cloud Services*/}
                    <div>
                      <div
                        className="point "
                        onClick={(e) => ToggleAccordion(e, false)}
                      >
                        <div className="d-flex align-items-center justify-content-between pointer">
                          <div className="d-flex align-items-center my-2">
                            <div className="mb-4">
                              <Form.Group
                                controlId={"check1" + "CDSA"}
                                className="round-checkbox blue-checkbox checkboxstyle d-none"
                              >
                                <Form.Check
                                  type="checkbox"
                                  name={"check" + "CDSA"}
                                  label=""
                                  // checked={

                                  // }
                                  onChange={(e) => {}}
                                />
                              </Form.Group>
                            </div>
                            <img
                              width={19}
                              height={19}
                              src={
                                imageSource[
                                  AttackSurfaceItemStatusforAll(
                                    [
                                      attackSurfaceData?.SelectedOption10,
                                      attackSurfaceData?.SelectedOption22,
                                    ],
                                    [
                                      attackSurfaceData?.SelectionOption10,
                                      attackSurfaceData?.SelectionOption22,
                                    ]
                                  )
                                ]
                              }
                              alt=""
                              className=""
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleSelectedList(
                                  [
                                    attackSurfaceData?.SelectedOption10,
                                    attackSurfaceData?.SelectedOption22,
                                  ],
                                  [
                                    attackSurfaceData?.SelectionOption10,
                                    attackSurfaceData?.SelectionOption22,
                                  ],
                                  ["SelectedOption10", "SelectedOption22"],
                                  ["SelectionOption10", "SelectionOption22"]
                                );
                              }}
                            />
                            <div className="f-500 ml-2 pt-1">
                              Cloud Services
                            </div>
                          </div>
                          <img
                            alt=""
                            className=""
                            src="/images/big-chevron-down.svg"
                          />
                        </div>
                        <div
                          className="panel"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className="f-12 f-grey my-2"></div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption22?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={
                                          "check" + "cloudserCDSAQuets" + index
                                        }
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption22?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption22?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption22.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption22 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption22 =
                                                  [];
                                              }

                                              updated_data.SelectedOption22.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div className="f-12 f-grey my-2">
                            Cloud services assets
                          </div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption10?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={
                                          "check" + "couldsevices" + index
                                        }
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption10?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption10?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption10.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption10 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption10 =
                                                  [];
                                              }

                                              updated_data.SelectedOption10.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ---- */}
                    {/* Communications */}
                    <div>
                      <div
                        className="point "
                        onClick={(e) => ToggleAccordion(e, false)}
                      >
                        <div className="d-flex align-items-center justify-content-between pointer">
                          <div className="d-flex align-items-center my-2">
                            <div className="mb-4">
                              <Form.Group
                                controlId={"check1" + "CDSA"}
                                className="round-checkbox blue-checkbox checkboxstyle d-none"
                              >
                                <Form.Check
                                  type="checkbox"
                                  name={"check" + "CDSA"}
                                  label=""
                                  // checked={

                                  // }
                                  onChange={(e) => {}}
                                />
                              </Form.Group>
                            </div>
                            <img
                              width={19}
                              height={19}
                              src={
                                imageSource[
                                  AttackSurfaceItemStatusforAll(
                                    [
                                      attackSurfaceData?.SelectedOption11,
                                      attackSurfaceData?.SelectedOption23,
                                    ],
                                    [
                                      attackSurfaceData?.SelectionOption11,
                                      attackSurfaceData?.SelectionOption23,
                                    ]
                                  )
                                ]
                              }
                              alt=""
                              className=""
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleSelectedList(
                                  [
                                    attackSurfaceData?.SelectedOption11,
                                    attackSurfaceData?.SelectedOption23,
                                  ],
                                  [
                                    attackSurfaceData?.SelectionOption11,
                                    attackSurfaceData?.SelectionOption23,
                                  ],
                                  ["SelectedOption11", "SelectedOption23"],
                                  ["SelectionOption11", "SelectionOption23"]
                                );
                              }}
                            />
                            <div className="f-500 ml-2 pt-1">
                              Communications
                            </div>
                          </div>
                          <img
                            alt=""
                            className=""
                            src="/images/big-chevron-down.svg"
                          />
                        </div>
                        <div
                          className="panel"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className="f-12 f-grey my-2"></div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption23?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={
                                          "check" + "commasCDSAQuets" + index
                                        }
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption23?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption23?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption23.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption23 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption23 =
                                                  [];
                                              }

                                              updated_data.SelectedOption23.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div className="f-12 f-grey my-2">
                            Communication assets
                          </div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption11?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={
                                          "check" + "Communications" + index
                                        }
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption11?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption11?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption11.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption11 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption11 =
                                                  [];
                                              }

                                              updated_data.SelectedOption11.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ---- */}
                    {/* Backup & Disaster Recovery*/}
                    <div>
                      <div
                        className="point "
                        onClick={(e) => ToggleAccordion(e, false)}
                      >
                        <div className="d-flex align-items-center justify-content-between pointer">
                          <div className="d-flex align-items-center my-2">
                            <div className="mb-4">
                              <Form.Group
                                controlId={"check1" + "CDSA"}
                                className="round-checkbox blue-checkbox checkboxstyle d-none"
                              >
                                <Form.Check
                                  type="checkbox"
                                  name={"check" + "CDSA"}
                                  label=""
                                  onChange={(e) => {}}
                                />
                              </Form.Group>
                            </div>
                            <img
                              width={19}
                              height={19}
                              src={
                                imageSource[
                                  AttackSurfaceItemStatusforAll(
                                    [
                                      attackSurfaceData?.SelectedOption12,
                                      attackSurfaceData?.SelectedOption24,
                                    ],
                                    [
                                      attackSurfaceData?.SelectionOption12,
                                      attackSurfaceData?.SelectionOption24,
                                    ]
                                  )
                                ]
                              }
                              alt=""
                              className=""
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleSelectedList(
                                  [
                                    attackSurfaceData?.SelectedOption12,
                                    attackSurfaceData?.SelectedOption24,
                                  ],
                                  [
                                    attackSurfaceData?.SelectionOption12,
                                    attackSurfaceData?.SelectionOption24,
                                  ],
                                  ["SelectedOption12", "SelectedOption24"],
                                  ["SelectionOption12", "SelectionOption24"]
                                );
                              }}
                            />
                            <div className="f-500 ml-2 pt-1">
                              Backup & Disaster Recovery
                            </div>
                          </div>
                          <img
                            alt=""
                            className=""
                            src="/images/big-chevron-down.svg"
                          />
                        </div>
                        <div
                          className="panel"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className="f-12 f-grey my-2"></div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption24?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={
                                          "check" + "backupdCDSAQuets" + index
                                        }
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption24?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption24?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption24.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption24 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption24 =
                                                  [];
                                              }

                                              updated_data.SelectedOption24.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div className="f-12 f-grey my-2">
                            Backup & Disaster Recovery assets
                          </div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption12?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={
                                          "check" +
                                          "BackupDisasterRecovery" +
                                          index
                                        }
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption12?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption12?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption12.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption12 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption12 =
                                                  [];
                                              }

                                              updated_data.SelectedOption12.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ---- */}
                    {/* Organisation*/}
                    <div>
                      <div
                        className="point "
                        onClick={(e) => ToggleAccordion(e, false)}
                      >
                        <div className="d-flex align-items-center justify-content-between pointer">
                          <div className="d-flex align-items-center my-2">
                            <div className="mb-4">
                              <Form.Group
                                controlId={"check1" + "CDSA"}
                                className="round-checkbox blue-checkbox checkboxstyle d-none"
                              >
                                <Form.Check
                                  type="checkbox"
                                  name={"check" + "CDSA"}
                                  label=""
                                  // checked={

                                  // }
                                  onChange={(e) => {}}
                                />
                              </Form.Group>
                            </div>
                            <img
                              width={19}
                              height={19}
                              src={
                                imageSource[
                                  AttackSurfaceItemStatusforAll(
                                    [attackSurfaceData?.SelectedOption13],
                                    [attackSurfaceData?.SelectionOption13]
                                  )
                                ]
                              }
                              alt=""
                              className=""
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleSelectedList(
                                  [attackSurfaceData?.SelectedOption13],
                                  [attackSurfaceData?.SelectionOption13],
                                  ["SelectedOption13"],
                                  ["SelectionOption13"]
                                );
                              }}
                            />
                            <div className="f-500 ml-2 pt-1">Organisation</div>
                          </div>
                          <img
                            alt=""
                            className=""
                            src="/images/big-chevron-down.svg"
                          />
                        </div>
                        <div
                          className="panel"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className="f-12 f-grey my-2"></div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption13?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={
                                          "check" + "organisation" + index
                                        }
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption13?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption13?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption13.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption13 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption13 =
                                                  [];
                                              }

                                              updated_data.SelectedOption13.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ---- */}
                    {/*Corporate Social*/}
                    <div>
                      <div
                        className="point "
                        onClick={(e) => ToggleAccordion(e, false)}
                      >
                        <div className="d-flex align-items-center justify-content-between pointer">
                          <div className="d-flex align-items-center my-2">
                            <div className="mb-4">
                              <Form.Group
                                controlId={"check1" + "CDSA"}
                                className="round-checkbox blue-checkbox checkboxstyle d-none"
                              >
                                <Form.Check
                                  type="checkbox"
                                  name={"check" + "CDSA"}
                                  label=""
                                  // checked={

                                  // }
                                  onChange={(e) => {}}
                                />
                              </Form.Group>
                            </div>
                            <img
                              width={19}
                              height={19}
                              src={
                                imageSource[
                                  AttackSurfaceItemStatusforAll(
                                    [
                                      attackSurfaceData?.SelectedOption14,
                                      attackSurfaceData?.SelectedOption25,
                                    ],
                                    [
                                      attackSurfaceData?.SelectionOption14,
                                      attackSurfaceData?.SelectionOption25,
                                    ]
                                  )
                                ]
                              }
                              alt=""
                              className=""
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleSelectedList(
                                  [
                                    attackSurfaceData?.SelectedOption14,
                                    attackSurfaceData?.SelectedOption25,
                                  ],
                                  [
                                    attackSurfaceData?.SelectionOption14,
                                    attackSurfaceData?.SelectionOption25,
                                  ],
                                  ["SelectedOption14", "SelectedOption25"],
                                  ["SelectionOption14", "SelectionOption25"]
                                );
                              }}
                            />
                            <div className="f-500 ml-2 pt-1">
                              Corporate Social
                            </div>
                          </div>
                          <img
                            alt=""
                            className=""
                            src="/images/big-chevron-down.svg"
                          />
                        </div>
                        <div
                          className="panel"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className="f-12 f-grey my-2"></div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption25?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={
                                          "check" + "corpsocialQuets" + index
                                        }
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption25?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption25?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption25.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption25 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption25 =
                                                  [];
                                              }

                                              updated_data.SelectedOption25.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div className="f-12 f-grey my-2">
                            Corporate Social assets
                          </div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption14?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={
                                          "check" + "corporatesocial" + index
                                        }
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption14?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption14?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption14.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption14 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption14 =
                                                  [];
                                              }

                                              updated_data.SelectedOption14.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ---- */}
                    {/* Authentications*/}
                    <div>
                      <div
                        className="point "
                        onClick={(e) => ToggleAccordion(e, false)}
                      >
                        <div className="d-flex align-items-center justify-content-between pointer">
                          <div className="d-flex align-items-center my-2">
                            <div className="mb-4">
                              <Form.Group
                                controlId={"check1" + "CDSA"}
                                className="round-checkbox blue-checkbox checkboxstyle d-none"
                              >
                                <Form.Check
                                  type="checkbox"
                                  name={"check" + "CDSA"}
                                  label=""
                                  // checked={

                                  // }
                                  onChange={(e) => {}}
                                />
                              </Form.Group>
                            </div>
                            <img
                              width={19}
                              height={19}
                              src={
                                imageSource[
                                  AttackSurfaceItemStatusforAll(
                                    [
                                      attackSurfaceData?.SelectedOption15,
                                      attackSurfaceData?.SelectedOption26,
                                    ],
                                    [
                                      attackSurfaceData?.SelectionOption15,
                                      attackSurfaceData?.SelectionOption26,
                                    ]
                                  )
                                ]
                              }
                              alt=""
                              className=""
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleSelectedList(
                                  [
                                    attackSurfaceData?.SelectedOption15,
                                    attackSurfaceData?.SelectedOption26,
                                  ],
                                  [
                                    attackSurfaceData?.SelectionOption15,
                                    attackSurfaceData?.SelectionOption26,
                                  ],
                                  ["SelectedOption15", "SelectedOption26"],
                                  ["SelectionOption15", "SelectionOption26"]
                                );
                              }}
                            />
                            <div className="f-500 ml-2 pt-1">
                              Authentications
                            </div>
                          </div>
                          <img
                            alt=""
                            className=""
                            src="/images/big-chevron-down.svg"
                          />
                        </div>
                        <div
                          className="panel"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className="f-12 f-grey my-2"></div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption26?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={
                                          "check" + "cUthensyQuets" + index
                                        }
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption26?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption26?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption26.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption26 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption26 =
                                                  [];
                                              }

                                              updated_data.SelectedOption26.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div className="f-12 f-grey my-2">
                            Authentication assets
                          </div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption15?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={
                                          "check" + "authenication" + index
                                        }
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption15?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption15?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption15.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption15 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption15 =
                                                  [];
                                              }

                                              updated_data.SelectedOption15.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ---- */}
                    {/* Web Services*/}
                    <div>
                      <div
                        className="point "
                        onClick={(e) => ToggleAccordion(e, false)}
                      >
                        <div className="d-flex align-items-center justify-content-between pointer">
                          <div className="d-flex align-items-center my-2">
                            <div className="mb-4">
                              <Form.Group
                                controlId={"check1" + "CDSA"}
                                className="round-checkbox blue-checkbox checkboxstyle d-none"
                              >
                                <Form.Check
                                  type="checkbox"
                                  name={"check" + "CDSA"}
                                  label=""
                                  // checked={

                                  // }
                                  onChange={(e) => {}}
                                />
                              </Form.Group>
                            </div>
                            <img
                              width={19}
                              height={19}
                              src={
                                imageSource[
                                  AttackSurfaceItemStatusforAll(
                                    [
                                      attackSurfaceData?.SelectedOption16,
                                      attackSurfaceData?.SelectedOption27,
                                    ],
                                    [
                                      attackSurfaceData?.SelectionOption16,
                                      attackSurfaceData?.SelectionOption27,
                                    ]
                                  )
                                ]
                              }
                              alt=""
                              className=""
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleSelectedList(
                                  [
                                    attackSurfaceData?.SelectedOption16,
                                    attackSurfaceData?.SelectedOption27,
                                  ],
                                  [
                                    attackSurfaceData?.SelectionOption16,
                                    attackSurfaceData?.SelectionOption27,
                                  ],
                                  ["SelectedOption16", "SelectedOption27"],
                                  ["SelectionOption16", "SelectionOption27"]
                                );
                              }}
                            />
                            <div className="f-500 ml-2 pt-1">Web Services</div>
                          </div>
                          <img
                            alt=""
                            className=""
                            src="/images/big-chevron-down.svg"
                          />
                        </div>
                        <div
                          className="panel"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className="f-12 f-grey my-2"></div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption27?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={
                                          "check" + "webthensyQuets" + index
                                        }
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption27?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption27?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption27.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption27 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption27 =
                                                  [];
                                              }

                                              updated_data.SelectedOption27.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>

                          <div className="f-12 f-grey my-2">
                            Web services assets
                          </div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption16?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={
                                          "check" + "webervies" + index
                                        }
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption16?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption16?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption16.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption16 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption16 =
                                                  [];
                                              }

                                              updated_data.SelectedOption16.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ---- */}
                    {/* Supplier*/}
                    <div>
                      <div
                        className="point "
                        onClick={(e) => ToggleAccordion(e, false)}
                      >
                        <div className="d-flex align-items-center justify-content-between pointer">
                          <div className="d-flex align-items-center my-2">
                            <div className="mb-4">
                              <Form.Group
                                controlId={"check1" + "CDSA"}
                                className="round-checkbox blue-checkbox checkboxstyle d-none"
                              >
                                <Form.Check
                                  type="checkbox"
                                  name={"check" + "CDSA"}
                                  label=""
                                  // checked={

                                  // }
                                  onChange={(e) => {}}
                                />
                              </Form.Group>
                            </div>

                            <img
                              width={19}
                              height={19}
                              src={
                                imageSource[
                                  AttackSurfaceItemStatusforAll(
                                    [attackSurfaceData?.SelectedOption17],
                                    [attackSurfaceData?.SelectionOption17]
                                  )
                                ]
                              }
                              alt=""
                              className=""
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleSelectedList(
                                  [attackSurfaceData?.SelectedOption17],
                                  [attackSurfaceData?.SelectionOption17],
                                  ["SelectedOption17"],
                                  ["SelectionOption17"]
                                );
                              }}
                            />
                            <div className="f-500 ml-2 pt-1">Suppliers</div>
                          </div>
                          <img
                            alt=""
                            className=""
                            src="/images/big-chevron-down.svg"
                          />
                        </div>
                        <div
                          className="panel"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className="f-12 f-grey my-2"></div>
                          <div className="d-flex flex-wrap p-2">
                            {attackSurfaceData?.SelectionOption17?.map(
                              (item, index) => {
                                return (
                                  <div className="d-flex align-items-center my-2 w-50">
                                    <div className="mb-4">
                                      <Form.Group
                                        controlId={
                                          "check" + "suppliers" + index
                                        }
                                        className="round-checkbox blue-checkbox checkboxstyle"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          name={"check"}
                                          label=""
                                          checked={
                                            attackSurfaceData?.SelectedOption17?.findIndex(
                                              (dataItem) =>
                                                dataItem === item?.Id
                                            ) > -1
                                          }
                                          onChange={() => {}}
                                          onClick={(e) => {
                                            let Index =
                                              attackSurfaceData?.SelectedOption17?.findIndex(
                                                (dataItem) =>
                                                  dataItem === item?.Id
                                              );

                                            if (Index > -1) {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              updated_data.SelectedOption17.splice(
                                                Index,
                                                1
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            } else {
                                              let updated_data = {
                                                ...attackSurfaceData,
                                              };
                                              if (
                                                updated_data.SelectedOption17 ===
                                                null
                                              ) {
                                                updated_data.SelectedOption17 =
                                                  [];
                                              }

                                              updated_data.SelectedOption17.push(
                                                item?.Id
                                              );
                                              setAttackSurfaceData(
                                                updated_data
                                              );
                                            }
                                          }}
                                          // defaultChecked={elmt.StripeId}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="f-500 ml-3">
                                      {cleanText(item?.value)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ---- */}
                  </div>
                </>
              </div>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex align-items-enter justify-content-end p-2">
        <Button
          className="hg-cancel-btn"
          onClick={() => {
            goBackToHomePage();
          }}
        >
          {page === "homepage" && "Cancel"}
          {page !== "homepage" && complianceProfileId === 0 && "Back"}
          {page !== "homepage" && complianceProfileId > 0 && "Back"}
        </Button>
        {page === "homepage" && (
          <Button
            className="hg-blue-btn"
            onClick={() => {
              if (page === "homepage") {
                if (complianceProfileName === "") {
                  let input_field = document.getElementById(
                    "compliance-profile-name"
                  );
                  input_field.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "nearest",
                  });
                  setComplianceNameError("ssdsdsdsd");
                  return;
                }
                createEditComplianceProfile();
              } else {
                setPage("homepage");
                setHeader("Add Compliance Profile");
              }
            }}
          >
            {saving ? (
              <>
                <WhiteLoader width={13} />
                Saving..
              </>
            ) : (
              "Save"
            )}
          </Button>
        )}

        {/* Update Compliance profile Button */}
        {page !== "homepage" && complianceProfileId > 0 && (
          <Button
            className="hg-blue-btn"
            onClick={() => {
              updateComplianceProfile(page);
            }}
          >
            {saving ? (
              <>
                <WhiteLoader width={13} />
                Updating..
              </>
            ) : (
              "Update"
            )}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AddComplianceProfileModal;

function AttackSurfaceItemStatusforAll(list1, list2) {
  let selectedLength = 0;
  let TotalLength = 0;
  for (let i = 0; i < list1.length; i++) {
    selectedLength += Number(list1[i]?.length ?? 0);
    TotalLength += Number(list2[i]?.length ?? 0);
  }

  if (selectedLength > 0 && TotalLength > 0) {
    if (selectedLength === TotalLength) {
      return 2;
    } else if (selectedLength < TotalLength) {
      return 1;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
}

function cleanText(text) {
  if (text === null || text === undefined) return "";

  let clean_text = text
    ?.trim()

    ?.replaceAll("_", " ")

    ?.replaceAll(",", "")
    ?.replaceAll("plus", "+")
    ?.replaceAll("fwdslash", "/")
    ?.replaceAll("ampersand", "&")
    ?.replaceAll("openbracet", "(")
    ?.replaceAll("closebracet", ")");

  return clean_text;
}


function mapRange(value, from_min=0, from_max=145, to_min=0, to_max=100){


  // """
  // Maps a value from the range [from_min, from_max] to the range [to_min, to_max]
  // """
  return (value - from_min) / (from_max - from_min) * (to_max - to_min) + to_min


}

