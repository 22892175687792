import React, { useEffect,   useState } from "react";
import { Button,   Dropdown,   Modal,  } from "react-bootstrap";
import "../secOps.scss";
import CompanySettingsDropdown from "../../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import { getAPIData, postData,   } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import ImageWithInitials from "../../../../Utils/ImageWithInitials";
import MultiSelectDropdown from "./MultiSelectDropdown";
import MspMemberAccessRoleModal from "../../../MSPSettingsv2/mspMembersAccess/MspMemberAccessRoleModal";
import { TrimString } from "../../../../Utils/StringTrimmer";

const AddMemberModal = ({ show, hideModal, refreshData, showMemberAccessModal, hideCreateOption }) => {
  const { authTokens } = useAuth();
  const [members, setMembers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState();
  const [selectedMembersList, setSelectedMembersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAccessLevelModal, setShowAccessLevelModal] = useState(false);
  const [IsDropdownOpen, setIsDropdownOpen] = useState(false)
  useEffect(() => {
    if (show) {
      getMembersDetails(0, "");
      getAccessRolesData();
    }
  }, [show]);

  async function getMembersDetails(option1, option2) {
    await getAPIData("564", authTokens, {
      optionEnum1: option1,
      optionStrEnum1: option2
    }).then((response) => {
      if (response.mr.Success) {
        setMembers(response?.users);
        console.log(response.users);
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  async function getAccessRolesData(){
    await getAPIData("564", authTokens, {
      optionEnum1: "1",
    }).then((response) => {
      if (response.mr.Success) {
        setRoles(response.roles);
      }
    });
  }

  async function inviteMember(){
    setLoading(true);
    let list = [];
    selectedMembersList.map(member=>{
      list.push({Key: member.objId_PSAMember, Value: member.userName });
    })
    if(list.length > 0){
      await postData(authTokens, "Invite_MSPTeamMember", 
      {
        objId_AccessRole : selectedRole.objId_AccessRole,
        obj_List: JSON.stringify(list),
      }
    )
      .then((response) => {
        setLoading(false);
        if (response?.Success) {
          CallToast("Member invited successfully", false, false, "v2style");

          hideModal();
          setSelectedMembersList([]);
          if (refreshData) {
            refreshData();
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
    }else {
      setLoading(false);
      CallToast("Choose member to invite", false, false, "v2style");

    }
    
  }

  return (
    <Modal
      show={show}
      dialogClassName="msp-add-member-modal "
      aria-labelledby=" "
      onHide={hideModal}
      centered
      scrollable
    >
      <Modal.Header className="text-center">
        <div
          className="w-100 mb-0 font-bold-14 title"
          style={{ fontWeight: "600" }}
        >
          Add Member
        </div>

        <span
          className="cursor-pointer"
          onClick={() => {
            setSelectedMembersList([]);
            hideModal();
          }}
        >
          <img width={15} src="/images/attack-surface/cross-icon.svg" alt="" />
        </span>
      </Modal.Header>
      <Modal.Body className="p-2 hide-scrollbar">
        <div className="d-flex ">
          <div
            className={` d-flex align-items-center radius-4 grey-border   flex-grow-1 mr-1 ${"highlight-input-with-grey-border"}`}
          >
            <div
              className="bg-transparent w-100 border-none pl-2"
              tabIndex={-1}
            >
              <MultiSelectDropdown
                placeholder={"Enter name or email"}
                data={members}
                selected={selectedMembersList}
                onChange={(val) => {
                  const mergedArray = [...selectedMembersList];

                  for (let obj of val) {
                    const existingObj = mergedArray.find(
                      (item) => item.objId_PSAMember === obj.objId_PSAMember
                    );
                    if (!existingObj) {
                      mergedArray.push(obj);
                    }
                  }
                  setSelectedMembersList(val);
                }}
              />
            </div>

            <div
              className="w-fit-content dropdown-grey-theme score-question-dropdown ml-3 w-70"
              style={{ alignSelf: "flex-end" }}
            >
              <Dropdown
                className="hg-white-dropdown user-detail-dropdown hide-scrollbar"
                onToggle={(e) => {
                  setIsDropdownOpen(e);
                }}
              >
                <Dropdown.Toggle
                  className={`custom-dropdown-toggle f-gray-imp ${
                    IsDropdownOpen ? "opened" : ""
                  }`}
                >
                  {selectedRole ? selectedRole?.RoleName : "Select"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {!hideCreateOption && (
                    <div
                      className="p-2 my-1 pointer f-darkgrey"
                      style={{
                        display: "grid",
                        "grid-template-columns": "15% 85%",
                      }}
                      onClick={(e) => {
                        setShowAccessLevelModal(true);
                        e.target.closest(".hg-white-dropdown").click();
                      }}
                    >
                      <img
                        alt=""
                        src="/images/settings-v2/lightgrey-plus-icon.svg"
                        className="mr-2"
                      />
                      <div>Create Access</div>
                    </div>
                  )}
                  {roles.map((el) => {
                    return (
                      <div
                        className="p-2 my-1 pointer f-grey"
                        style={{
                          display: "grid",
                          "grid-template-columns": "15% 85%",
                        }}
                        onClick={(e) => {
                          setSelectedRole(el);
                          e.target.closest(".hg-white-dropdown").click();
                        }}
                      >
                        <div>
                          {selectedRole?.RoleName === el?.RoleName ? (
                            <img
                              alt=""
                              src="/images/settings-v2/check-icon.svg"
                              className="mr-2"
                            />
                          ) : (
                            <></>
                          )}
                        </div>

                        <div>
                          {TrimString(el?.RoleName,30)}
                          <div className="smallGreyText text-ellipsis f-darkgrey" title={el.RoleDescription}>
                            {TrimString(el.RoleDescription,40)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <Button
            className={
              (selectedMembersList.length > 0 && selectedRole?.RoleName)
                ? "hg-blue-btn"
                : "hg-cancel-btn"
            }
            disabled={
              (selectedMembersList.length > 0 && selectedRole?.RoleName)
              ? false
              : true
            }
            style={{ height: "fit-content", alignSelf: "end" }}
            onClick={() => {
              inviteMember();
            }}
          >
            {loading ? "Inviting..." : "Invite"}{" "}
          </Button>
        </div>
        <div className="d-flex align-items-center justify-content-between my-2 mx-1">
          <div className="f-grey">Imported members</div>
          <div className="w-fit-content dropdown-grey-theme score-question-dropdown xx ml-3 w-70">
            <CompanySettingsDropdown
              fieldId={"id"}
              fieldValue={"cat"}
              selected={{ id: 1, cat: "All" }}
              placeholder="Select"
              //  showid={true}
              onClick={(id, val) => {}}
              data={[{ id: 1, cat: "All" }]}
            />
          </div>
        </div>
        {members.map((item, index) => {
          console.log(item);
          return (
            <div className="d-flex align-items-center justify-content-between my-1 pointer radius-4 bg-hover-grey hover-on-show-parent p-2 px-3">
              <div className="d-flex align-items-center  ">
                {item.showInitials ? (
                  <ImageWithInitials
                    initials={item.initials}
                    width={30}
                    background={"purple"}
                  />
                ) : (
                  <img
                    alt=""
                    src={`/images/${item.ImageURL}`}
                    className=""
                    style={{
                      width: "30px",
                      borderRadius: "50%",
                      objectFit: "contain",
                    }}
                  />
                )}
                <div className="ml-2">
                  <div className="f-500 f-grey">{item.fullName}</div>
                  <div className="f-12 f-darkgrey">{item.userName}</div>
                </div>
              </div>
              <div
                className="hover-on-show-child f-grey"
                onClick={() => {
                  let newList = [...selectedMembersList];
                  const existingObj = newList.find(
                    (it) => it.objId_PSAMember === item.objId_PSAMember
                  );

                  if (!existingObj) {
                    newList.push(item);
                  }
                  setSelectedMembersList(newList);
                }}
              >
                <img
                  src="/images/msp/grey-plus-icon.svg"
                  alt=""
                  width={10}
                  className="mr-2"
                />
                Add
              </div>
            </div>
          );
        })}
        {members.length == 0 && (
          <div className="f-grey d-flex justify-content-center align-items-center">
            No members exist
          </div>
        )}

        <div className="p-3" />
        {showAccessLevelModal && (
          <MspMemberAccessRoleModal
            show={showAccessLevelModal}
            hideModal={() => {
              setShowAccessLevelModal(false);
            }}
            parentID="msp-main-screen-for-integration-modal"
            refreshData={() => {
              getAccessRolesData();
            }}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddMemberModal;
