import React, { useEffect, useState } from 'react'
import { Button, Modal, Nav, Tab } from 'react-bootstrap';
import { useAuth } from '../context/Auth';
import { getAPIData, postData } from '../../Utils/commonApi';
import axios from 'axios';
import { CallToast } from '../PriceCalculator/modals/CustomToast';
import Loader from "../Common/loader";
import { UploadFileCircularProgress } from '../MSPv2/secOps/SecOps';
const SecurityAssessmentAddEditModal = ({show, hideModal,refreshData,selectedFile}) => {

    const uploadRef = React.useRef();
    const { authTokens } = useAuth();
    const [selectedTab, setSelectedTab] = useState(0);
    const [saving, setSaving] = useState(false);
  
    const [loading, setLoading] = useState(false);
  
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadingFileList, setUploadingFileList] = useState([]);
  
    const [uploadedFile, setUploadedFile] = useState(null);
      const [errorData, setErrorData] = useState({
      errMessage:"",
      errIcon:""
    })
  
    const [fileData, setFileData] = useState({
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      ObjectId: 0,
      FileLink: "",
      File: "",
      IsLink: "",
      Description: "",
    });
  
    async function getModalData() {
      await getAPIData(`${760}`, authTokens, {
        optionEnum1: 0,
      })
        .then((response) => {
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  
    useEffect(() => {
      if (show) {
        // setLoading(true);
        getModalData();
        setSelectedTab(0);
        setFileData({
          ObjectId: selectedFile?.ObjectId ?? 0,
          FileLink: selectedFile?.FileURLOrLink ?? "",
          File: selectedFile?.File ?? "",
          FileName: selectedFile?.FileName ?? "",
          
  
           
          IsLink: selectedFile?.IsLink ?? "",
          Description: selectedFile?.Description ?? "",
  
        });
        if(selectedFile?.IsLink===false && (selectedFile?.FileURLOrLink || selectedFile?.ActualFileName||selectedFile?.FileName)){
  setUploadedFile({
    FileURLOrLink:selectedFile?.FileURLOrLink,
    ActualFileName:selectedFile?.ActualFileName,
    FileSize:selectedFile?.FileSize,
  
  })
        }else{
          setUploadedFile(null);
  
        }
  
        if(selectedFile?.IsLink===true){
          setSelectedTab(1);}else{
            setSelectedTab(0);
          }
      }
    }, [show]);
  
    const handleUpload = async (event) => {
      event.preventDefault();
      const file = event.target.files[0];
      setUploadingFileList(event.target.files);
  
      const allowedFileTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "text/plain",
      ];
  
      if (file && allowedFileTypes.includes(file.type)) {
        const reader = new FileReader();
  
        reader.onloadend = async () => {
          const base64Data = reader.result;
          const formData = new FormData();
          formData.append("UploadFile", file);
          formData.append("fileType", 0);
          formData.append(
            "accessKey",
            JSON.parse(localStorage.getItem("user_MessageObj"))
          );
  
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_API_BASE_URL}/CC_API/UploadSupplierProfilingFile`,
              // "https://hg-dev-ui-ac-api.azurewebsites.net/CC_API/UploadSupplierProfilingFile",
              formData,
              {
                headers: {
                  Authorization: `Bearer ${authTokens}`,
                },
                onUploadProgress: (progressEvent) => {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setUploadProgress(percentCompleted);
                },
              }
            );
  
            if (response) {
              CallToast("Uploaded successfully", false, false, "v2style");
              setUploadedFile(response?.data);
              setErrorData({ ...errorData, errMessage: "" });
            } else {
              console.error("Something went wrong");
            }
  
            setUploadProgress(0);
          } catch (error) {
            console.error("Upload failed:", error);
            if (error?.code === "ERR_NETWORK") {
                          setErrorData({
                            errMessage:
                              "There was a connection error during uploading. Please try again.",
                            errIcon: "/images/msp/wifi-error.svg",
                          });
                        }
          }
        };
  
        reader.readAsDataURL(file);
      } else {
        console.error(
          "Invalid file type. Please upload a PDF, DOC, DOX, or TXT file."
        );
      }
    };
  
    function downloadFile(url, filename) {
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  
    async function handleSave() {
      setSaving(true);
      await postData(authTokens, "CreateSupplierProfilingFile", {
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        ObjectId: selectedFile?.ObjectId ?? 0,
        FileLink: uploadedFile?.FileURLOrLink ?? fileData.FileLink,
        // File: fileData.File,
        IsLink: uploadedFile === null,
        optionEnum1: 12,
        Description: fileData.Description,
        FileName: fileData.FileName,
        ActualFileName: uploadedFile?.ActualFileName,
        objId_SendFormQuestionnaire: 4019582,
      })
        .then((response) => {
          setSaving(false);
          hideModal();
          refreshData();
          CallToast("File saved successfully", false, false, "v2style");
        })
        .catch((err) => {
          setSaving(false);
          console.log(err);
        });
    }
  





  return (
    <Modal
      show={show}
      dialogClassName="secops-cert-modal"
      className="   "
      aria-labelledby=" "
      onHide={hideModal}
      centered
      scrollable
    >
      <Modal.Header className="text-center">
        <div
          className="w-100 mb-0 font-bold-14 title"
          style={{ fontWeight: "600" }}
        >
          {selectedFile?.ObjectId > 0 ? "Edit" : "Add"} file
        </div>

        <span
          className="cursor-pointer"
          onClick={() => {
            hideModal();
          }}
        >
          <img width={15} src="/images/attack-surface/cross-icon.svg" alt="" />
        </span>
      </Modal.Header>
      <Modal.Body className=" pt-3 pl-3 pr-2 transparent-scroller">
        {loading ? (
          <div className="d-flex align-items-center justify-content-center w-100 h-100">
            <Loader />
          </div>
        ) : (
          <>
            {/* <div
              className={`d-flex align-items-center radius-4 grey-border p-2 flex-grow-1  highlight-input-with-grey-border ${
                false ? "input-error" : ""
              }`}
            >
              <div className="f-darkgrey">Name:</div>

              <input
                type="text"
                className="bg-transparent w-100 border-none pl-2"
                placeholder="File name"
                value={fileData.FileName}
                onChange={(e) => {
                  setFileData({ ...fileData, FileName: e.target.value });
                }}
              />
            </div> */}

            

            {true && (
              <>
                <Tab.Container
                  onSelect={(v) => {
                    setSelectedTab(Number(v));
                  }}
                  id="left-tabs-example"
                  defaultActiveKey={selectedTab}
                >
                  <div
                    className="scenarioName bg-white d-flex align-items-start    pt-2  "
                    style={{ top: "101px", zIndex: 2 }}
                    id="navHeader"
                  >
                    <Nav.Item key={0} className={"  mt-2"}>
                      <Nav.Link
                        eventKey={0}
                        className={`${selectedTab === 0 && "active"} mt-`}
                        onClick={() => {}}
                        disabled={fileData.IsLink === true}
                      >
                        Recent
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item key={1} className={"  mt-2"}>
                      <Nav.Link
                        eventKey={1}
                        className={`${selectedTab === 1 && "active"} mt-`}
                        onClick={() => {}}
                        disabled={fileData.IsLink === true}
                      >
                        Upload File
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item key={2} className={"  mt-2"}>
                      <Nav.Link
                        disabled={!(uploadedFile === null)}
                        eventKey={2}
                        className={`${selectedTab === 2 && "active"} mt-`}
                        onClick={() => {}}
                      >
                        Link
                      </Nav.Link>
                    </Nav.Item>
                  </div>

                  <Tab.Content className="mt-4">
                    <Tab.Pane eventKey={0}>
                      <div className="d-flex align-items-center radius-4 bg-grey highlight-input p-2 mb-2">
                        <img
                          src="/images/attack-surface/search-icon.svg"
                          alt=""
                          className="m"
                        />

                        <input
                          type="text"
                          placeholder="Search.."
                          className="border-none bg-transparent pl-1"
                        />
                      </div>

                      <div className="radius-8 bg-grey border-parent">
                        {[1, 2, 3, 4, 5].map((item, itemIndex) => {
                          return (
                            <>
                              <div className="border-item p-3 d-flex align-items-center">
                                <img
                                  src={
                                    itemIndex === 0
                                      ? "/images/msp/profiler-link-icon.svg"
                                      : "/images/msp/profiler-file-icon.svg"
                                  }
                                  alt="file"
                                  className="mr-2"
                                />
                                <div>
                                  <div className="f-500">File</div>
                                  <div className="f-12 f-darkgrey">
                                    Dignissim ullamcorper cursus suspendisse
                                    urna proin dis proin...
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey={1}>
                      {errorData?.errMessage !== "" && (
                        <div
                          onClick={() => {}}
                          className="d-flex align-items-center justify-content-between bg-warning-light p-3 radius-8 mb-2 "
                        >
                          <div className="d-flex align-items-center f-black f-500">
                            <img
                              alt=""
                              src={errorData?.errIcon}
                              className="mr-2"
                            />

                            {errorData?.errMessage}
                          </div>
                          <div
                            className="d-flex align-items-center f-black f-500 pointer mr-2"
                            onClick={() => {
                              setErrorData({ ...errorData, errMessage: "" });
                            }}
                          >
                            Dismiss
                          </div>
                        </div>
                      )}

                      {uploadedFile ? (
                        <div className="radius-4 bg-grey p-3  d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <img
                              src="/images/msp/upload-icon-placeholder.svg"
                              alt=""
                              className="mr-2"
                            />
                            <div>
                              <div className="f-500">
                                {uploadedFile?.ActualFileName}{" "}
                              </div>
                              <div className="f-darkgrey">
                                {uploadedFile?.FileSize}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-item-center">
                            <img
                              src="/images/msp/policy-download-icon.svg"
                              alt=""
                              className="mx-2 pointer"
                              onClick={() => {
                                downloadFile(
                                  uploadedFile?.FileURLOrLink,
                                  uploadedFile?.ActualFileName
                                );
                              }}
                            />
                            <img
                              src="/images/actioncenter/delete.svg"
                              alt=""
                              className="mx-2 pointer"
                              onClick={() => {
                                setUploadedFile(null);
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="radius-4 border-dashed d-flex flex-column align-items-center justify-content-center w-100 p-3 mb-3">
                          {uploadProgress > 0 && uploadProgress < 100 ? (
                            <>
                              <UploadFileCircularProgress
                                progress={100 - uploadProgress}
                              />

                              <div className="f-grey my-2 w-fit-content">
                                {uploadingFileList?.[0]?.name}{" "}
                              </div>

                              <div className="f-darkgrey my-2 w-fit-content">
                                Uploading... {uploadProgress}%
                              </div>

                              <Button
                                className="hg-cancel-btn"
                                onClick={() => {
                                  uploadRef.current.click();
                                }}
                              >
                                Cancel
                              </Button>
                            </>
                          ) : (
                            <>
                              <img
                                width={20}
                                src="/images/msp/framework-upload-icon.svg"
                                alt=""
                              />
                              <div className="f-grey mt-2 w-fit-content">
                                Upload file or browse file on your computer
                              </div>
                              <div className="f-darkgrey my-2 w-fit-content">
                                Format pdf, doc, dox, txt
                              </div>
                              <Button
                                className="hg-cancel-btn"
                                onClick={() => {
                                  uploadRef.current.click();
                                }}
                              >
                                Browse file
                              </Button>
                            </>
                          )}
                        </div>
                      )}



<textarea
              className={`d-flex align-items-center radius-4 grey-border p-2 w-100 mt-2 highlight-input-with-grey-border ${
                false ? "input-error" : ""
              }`}
              placeholder="Description"
              rows={6}
              value={fileData.Description}
              onChange={(e) => {
                setFileData({ ...fileData, Description: e.target.value });
              }}
            ></textarea>
                    </Tab.Pane>
                    <Tab.Pane eventKey={2}>
                      <div
                        className={`d-flex align-items-center radius-4 highlight-input-with-grey-border p-2 flex-grow-1 mr-1 ${true} mb-2`}
                      >
                        <div className="f-darkgrey">Name:</div>

                        <input
                          type="text"
                          placeholder="Type..."
                          className="pl-2 bg-transparent border-none flex-grow-1"
                        //   value={fileData?.FileLink1}
                        //   onChange={(e) => {
                        //     setFileData({
                        //       ...fileData,
                        //       FileLink: e.target.value,
                        //       IsLink: !(e.target.value === ""),
                        //     });
                        //   }}
                        />
                      </div>
                      <div
                        className={`d-flex align-items-center radius-4 highlight-input-with-grey-border p-2 flex-grow-1 mr-1 ${true}`}
                      >
                        <div className="f-darkgrey">Link:</div>

                        <input
                          type="text"
                          placeholder="Type..."
                          className="pl-2 bg-transparent border-none flex-grow-1"
                          value={fileData.FileLink}
                          onChange={(e) => {
                            setFileData({
                              ...fileData,
                              FileLink: e.target.value,
                              IsLink: !(e.target.value === ""),
                            });
                          }}
                        />
                      </div>





                      <textarea
              className={`d-flex align-items-center radius-4 grey-border p-2 w-100 mt-2 highlight-input-with-grey-border ${
                false ? "input-error" : ""
              }`}
              placeholder="Description"
              rows={6}
              value={fileData.Description}
              onChange={(e) => {
                setFileData({ ...fileData, Description: e.target.value });
              }}
            ></textarea>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </>
            )}
          </>
        )}
        <input
          type="file"
          accept=".pdf,.doc,.dox,.txt"
          onChange={(e) => handleUpload(e)}
          className="d-none"
          ref={uploadRef}
          multiple
        />
      </Modal.Body>

      <Modal.Footer className="p-2 d-flex align-items-center justify-content-end">
        <Button className="hg-cancel-btn mr-2" onClick={hideModal}>
          Cancel
        </Button>
        <Button
          className="hg-blue-btn"
          disabled={
            saving ||
            (fileData.FileLink === "" && uploadedFile === null) ||
            fileData.FileName === "" ||
            fileData.Description === ""
          }
          onClick={handleSave}
        >
          {saving
            ? "Saving.."
            : `${selectedFile?.ObjectId > 0 ? "Edit" : "Add"} file`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SecurityAssessmentAddEditModal