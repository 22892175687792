import React from "react";
import { Dropdown } from "react-bootstrap";
import { TrimString } from "../../../../Utils/StringTrimmer";
import "./dropdown.scss";

const NormalDropdown = (props) => {
  let selectedItem = props.data?.filter(
    (item) => item[props.fieldId] === props.selected
  )?.[0]?.[props.fieldValue];
  return (
    <Dropdown className="custom_drop-down">
      <Dropdown.Toggle className={`${props.selected ? "selected" : ""}`}>
        <div className="selected-option">
          {props?.selected || props?.selected === 0 ? (
            TrimString(selectedItem, 54)
          ) : (
            <span className="drop-placeholder">{props?.placeholder}</span>
          )}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {props?.data?.map((val) => (
          <div
            className={`dropdown_item ${
              props?.selected && props?.selected == val[props.fieldId]
                ? "selected"
                : ""
            }`}
            onClick={(e) => {
              props.onClick(val[props.fieldId], val[props.fieldValue], e);
              {
                !props.controlClosing &&
                  e.target.closest(".custom_drop-down").click();
              }
            }}
            key={val[props.fieldId]}
            data-id={val[props.fieldId]}
            data-value={val[props.fieldValue]}
          >
            {TrimString(val[props.fieldValue], 54)}
            {(props?.selected || props?.selected === 0) &&
            props.selected == val[props.fieldId] ? (
              <img
                src={"/images/settings-v2/black-tick.svg"}
                className="float-right tick"
                alt=""
              />
            ) : (
              ""
            )}
          </div>
        ))}
        <div className="w-100">{props.extraElement}</div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NormalDropdown;

export const NormalDropdownForQuestionnaireModal = (props) => {
  let selectedItem = props.data?.filter(
    (item) => item[props.fieldId].indexOf(props.selected) !== -1
  )?.[0]?.[props.fieldValue];

  return (
    <Dropdown className="custom_drop-down">
      <Dropdown.Toggle className={`${props.selected ? "selected" : ""}`}>
        <div className="selected-option">
          {props?.selected || props?.selected === 0 ? (
            TrimString(selectedItem, 54)
          ) : (
            <span className="drop-placeholder">{props?.placeholder}</span>
          )}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {props?.data?.map((val) => (
          <div
            className={`dropdown_item ${
              val[props.fieldId].indexOf(props.selected) !== -1
                ? "selected"
                : ""
            }`}
            onClick={(e) => {
              props.onClick(val[props.fieldId], val[props.fieldValue], e);
              {
                !props.controlClosing &&
                  e.target.closest(".custom_drop-down").click();
              }
            }}
            key={val[props.fieldId]}
            data-id={val[props.fieldId]}
            data-value={val[props.fieldValue]}
          >
            {TrimString(val[props.fieldValue], 54)}
            {val[props.fieldId].indexOf(props.selected) !== -1 ? (
              <img
                src={"/images/settings-v2/black-tick.svg"}
                className="float-right tick"
                alt=""
              />
            ) : (
              ""
            )}
          </div>
        ))}
        <div className="w-100">{props.extraElement}</div>
      </Dropdown.Menu>
    </Dropdown>
  );
};
