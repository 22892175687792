import React, {useState} from 'react';
import { Spinner } from 'react-bootstrap';
import './Spinner.css';
import 'loaders.css';
import Loader from "react-loaders";

const LoadingSpinner = (props) => {
    const padding = props.padding || "0px";
    const type = props.type || "ball-pulse-rise";
    const backgroundText = props.backgroundText

    return (
        <div className="spinnerContainer">
            <div className="fullHeight flexCenterAlign">
                {
                    type === "bootstrap" ? 
                        <div className="spinnerBackground">
                            <Spinner animation="border" variant="primary"/>
                            <p>{backgroundText}</p>    
                        </div>
                    :
                        <Loader color=" #d4008f" type={type} style={{paddingTop: padding}} />
                }
            </div>
        </div>
    );
}

export default LoadingSpinner;